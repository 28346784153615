/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProgramPosition } from './ProgramPosition';
import {
    ProgramPositionFromJSON,
    ProgramPositionFromJSONTyped,
    ProgramPositionToJSON,
} from './ProgramPosition';
import type { ProgramReference } from './ProgramReference';
import {
    ProgramReferenceFromJSON,
    ProgramReferenceFromJSONTyped,
    ProgramReferenceToJSON,
} from './ProgramReference';

/**
 * 
 * @export
 * @interface ProgramWithPosition
 */
export interface ProgramWithPosition {
    /**
     * 
     * @type {ProgramReference}
     * @memberof ProgramWithPosition
     */
    program?: ProgramReference;
    /**
     * user defined index for a customer
     * @type {number}
     * @memberof ProgramWithPosition
     */
    customerIndex?: number;
    /**
     * 
     * @type {ProgramPosition}
     * @memberof ProgramWithPosition
     */
    programPosition?: ProgramPosition | null;
}

/**
 * Check if a given object implements the ProgramWithPosition interface.
 */
export function instanceOfProgramWithPosition(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProgramWithPositionFromJSON(json: any): ProgramWithPosition {
    return ProgramWithPositionFromJSONTyped(json, false);
}

export function ProgramWithPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramWithPosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'program': !exists(json, 'program') ? undefined : ProgramReferenceFromJSON(json['program']),
        'customerIndex': !exists(json, 'customerIndex') ? undefined : json['customerIndex'],
        'programPosition': !exists(json, 'programPosition') ? undefined : ProgramPositionFromJSON(json['programPosition']),
    };
}

export function ProgramWithPositionToJSON(value?: ProgramWithPosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'program': ProgramReferenceToJSON(value.program),
        'customerIndex': value.customerIndex,
        'programPosition': ProgramPositionToJSON(value.programPosition),
    };
}

