import React, { FC, ReactElement, useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { Box } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, LaundryGroupReference, MachinesApi, PairingInfo, PairingType } from 'src/service/backend/api'
import { ClientError } from 'src/service/backend/error/ClientError'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  MachinePairGetViewModel,
  MachinePairManualPostViewModel,
  MachinePairPostViewModel,
  mapMachinePairPostViewModelToMachinesPairingBody,
} from 'src/service/view-model/machine/MachinePairViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { validPairingId } from 'src/ui-shared/base/form/validation/Validators'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'
import { MachinePairGetForm } from 'src/ui/page/common/machine/pair-machine/MachinePairGetForm'
import { MachinePairManualForm } from 'src/ui/page/common/machine/pair-machine/MachinePairManualForm'
import { MachinePairPrefilledForm } from 'src/ui/page/common/machine/pair-machine/MachinePairPrefilledForm'

interface Props {
  setOpenModal: (value: boolean) => void
}

export const MachinePair: FC<Props> = ({ setOpenModal }): ReactElement => {
  enum PairingStep {
    INITIAL,
    PREFILLED,
    MANUAL,
  }

  const navigate = useNavigate()
  const translate = useTranslate()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const machinesApi = new MachinesApi(httpConfiguration)

  const [loading, setLoading] = useState<boolean>(false)
  const [pairingStep, setPairingStep] = useState<PairingStep>(PairingStep.INITIAL)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [pairingId, setPairingId] = useState<string>('')
  const [pairingInfo, setPairingInfo] = useState<PairingInfo | undefined>()
  const [selectedLaundryGroup, setSelectedLaundryGroup] = useState<LaundryGroupReference | undefined>()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const machinePairGetSubmit = (viewModel: MachinePairGetViewModel) => {
    const pairingId = viewModel.pairingId
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!pairingId || validPairingId()(pairingId) !== undefined) {
      setErrorMessage(translate('validation.valid.pairingCode'))
      return
    }

    const laundryGroupRef = viewModel.laundryGroupRef

    setErrorMessage(null)
    setLoading(true)

    machinesApi
      .machinesPairingGet(pairingId)
      .then((data) => {
        const pairingInfo: PairingInfo = data

        setPairingId(pairingId)
        setPairingInfo(pairingInfo)
        setSelectedLaundryGroup(laundryGroupRef)

        if (pairingInfo.type == PairingType.PREFILLED && pairingInfo.prefilledInfo) {
          setPairingStep(PairingStep.PREFILLED)
        } else if (pairingInfo.type == PairingType.MANUAL && pairingInfo.manualInfo) {
          setPairingStep(PairingStep.MANUAL)
        } else {
          setErrorMessage('Unknown paring type ' + pairingInfo.type)
          setPairingStep(PairingStep.INITIAL)
        }

        setLoading(false)
      })
      .catch((err) => {
        let errorMessage = undefined
        const error = err as Error
        if (error instanceof ClientError) {
          const clientError = error as ClientError
          if (clientError.statusCode === 404) {
            errorMessage = translate('pairingRequestNotFound')
          }
        }
        if (errorMessage === undefined) {
          errorMessage = errorMapper(err, translate)
        }
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setPairingStep(PairingStep.INITIAL)
        setLoading(false)
      })
  }

  const machinePairPostSubmit = (
    machinePairPostViewModel: MachinePairPostViewModel | undefined,
    machinePairManualPostViewModel: MachinePairManualPostViewModel | undefined,
  ) => {
    setLoading(true)

    const machinesPairBody = mapMachinePairPostViewModelToMachinesPairingBody(
      machinePairPostViewModel,
      machinePairManualPostViewModel,
      pairingId,
    )

    machinesApi
      .machinesPairingPost(machinesPairBody)
      .then((machine) => {
        const successMessage = translate('notification.success.machinePaired')
        showSnackbar(successMessage, 'success')
        setIsSuccess(true)
        setLoading(false)
        setTimeout(() => {
          setOpenModal(false)
          navigate(`/${appId}/machines/${machine.id}/view/${MachineTab.OVERVIEW}`)
        }, 2000)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLoading(false)
      })
  }

  const machinePairPrefilledPostSubmit = (machinePairPost: MachinePairPostViewModel) => {
    machinePairPostSubmit(machinePairPost, undefined)
  }

  const machinePairManualPostSubmit = (machinePairManualPost: MachinePairManualPostViewModel) => {
    machinePairPostSubmit(undefined, machinePairManualPost)
  }

  const getStepForm = (step: PairingStep): ReactElement | undefined => {
    switch (step) {
      case PairingStep.INITIAL:
        return <MachinePairGetForm setOpenModal={setOpenModal} onSubmit={machinePairGetSubmit} />

      case PairingStep.PREFILLED:
        return (
          <MachinePairPrefilledForm
            isSuccess={isSuccess}
            setOpenModal={setOpenModal}
            onSubmit={machinePairPrefilledPostSubmit}
            laundryGroupRef={selectedLaundryGroup!}
            prefilledPairingInfo={pairingInfo!.prefilledInfo!}
          />
        )

      case PairingStep.MANUAL:
        return (
          <MachinePairManualForm
            isSuccess={isSuccess}
            setOpenModal={setOpenModal}
            onSubmit={machinePairManualPostSubmit}
            laundryGroupRef={selectedLaundryGroup!}
            manualPairingInfo={pairingInfo!.manualInfo!}
          />
        )
    }
    return undefined
  }

  return (
    <Box px={3} pb={3}>
      <LoadingIndicator loading={loading} />
      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      {getStepForm(pairingStep)}
    </Box>
  )
}
