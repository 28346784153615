/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManufacturerModelReference
 */
export interface ManufacturerModelReference {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerModelReference
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerModelReference
     */
    readonly productNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerModelReference
     */
    readonly modelName: string;
}

/**
 * Check if a given object implements the ManufacturerModelReference interface.
 */
export function instanceOfManufacturerModelReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "productNumber" in value;
    isInstance = isInstance && "modelName" in value;

    return isInstance;
}

export function ManufacturerModelReferenceFromJSON(json: any): ManufacturerModelReference {
    return ManufacturerModelReferenceFromJSONTyped(json, false);
}

export function ManufacturerModelReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManufacturerModelReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'productNumber': json['productNumber'],
        'modelName': json['modelName'],
    };
}

export function ManufacturerModelReferenceToJSON(value?: ManufacturerModelReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
    };
}

