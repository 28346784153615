/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Region } from './Region';
import {
    RegionFromJSON,
    RegionFromJSONTyped,
    RegionToJSON,
} from './Region';

/**
 * 
 * @export
 * @interface RegionData
 */
export interface RegionData {
    /**
     * 
     * @type {Region}
     * @memberof RegionData
     */
    region: Region;
    /**
     * Unit of Weight ('kg' | 'lbs')
     * @type {string}
     * @memberof RegionData
     */
    weight: string;
    /**
     * Unit of Temperatures ('В°C' | 'В°F')
     * @type {string}
     * @memberof RegionData
     */
    temperature: string;
}

/**
 * Check if a given object implements the RegionData interface.
 */
export function instanceOfRegionData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "temperature" in value;

    return isInstance;
}

export function RegionDataFromJSON(json: any): RegionData {
    return RegionDataFromJSONTyped(json, false);
}

export function RegionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegionData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'region': RegionFromJSON(json['region']),
        'weight': json['weight'],
        'temperature': json['temperature'],
    };
}

export function RegionDataToJSON(value?: RegionData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region': RegionToJSON(value.region),
        'weight': value.weight,
        'temperature': value.temperature,
    };
}

