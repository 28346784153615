import React, { PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { EnvironmentContext } from 'src/env/EnvironmentContext'
import { BackendUserProfile, RegionData } from 'src/service/backend/api'
import { SupportedLocale } from 'src/service/view-model/base/localization/SupportedLocale'
import { FakeLoginService } from 'src/user/FakeLoginService'
import { KeycloakService } from 'src/user/KeycloakService'
import { mapRegionToLocale } from 'src/user/RegionDataUtils'
import { DEFAULT_USER, DEFAULT_USER_LOCALE, DEFAULT_USER_REGION_DATA, User } from 'src/user/User'

const defaultUserContextValue: UserContextValue = {
  user: DEFAULT_USER,
  changeLocale: (_: string) => {},
  updateUser: (_: BackendUserProfile) => {},
  changeToken: (_: string) => {},
}

interface UserContextValue {
  user: User
  changeLocale: (locale: string) => void
  updateUser: (user: BackendUserProfile | User) => void
  changeToken: (newToken: string) => void
}

const UserContext = React.createContext<UserContextValue>(defaultUserContextValue)

export const useUserLocale = (): string => {
  const { user } = useContext(UserContext)
  return user.locale ? user.locale : DEFAULT_USER_LOCALE
}

export const useUserRegionData = (): RegionData => {
  const { user } = useContext(UserContext)
  return getUserRegionDataOrDefault(user)
}

export const useUserRegionLocale = (): SupportedLocale => {
  const { user } = useContext(UserContext)
  const regionData = getUserRegionDataOrDefault(user)
  return mapRegionToLocale(regionData.region)
}

const getUserRegionDataOrDefault = (user: User) => {
  return user.regionData ?? DEFAULT_USER_REGION_DATA
}

export const useUserFullName = (): string => {
  const { user } = useContext(UserContext)
  return `${user.firstName} ${user.lastName}`
}

export const useUser = (): User => {
  const { user } = useContext(UserContext)
  return user
}

const UserContextProvider = ({ children }: PropsWithChildren): ReactElement => {
  // state
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState<User>(DEFAULT_USER)

  // context
  const { keycloakConfigFileUrl, backendUrl, fakeLogin } = useContext(EnvironmentContext)

  useEffect(() => {
    const fetchUserDetails = async () => {
      let authenticationPromise: Promise<User>
      if (!fakeLogin) {
        const onTokenRefreshed = (newToken: string): void => {
          changeToken(newToken)
        }
        authenticationPromise = KeycloakService.initKeycloakAndGetUser(keycloakConfigFileUrl, onTokenRefreshed)
      } else {
        console.warn('Fake login is enabled')
        authenticationPromise = FakeLoginService.initFakeLogin()
      }

      authenticationPromise
        .then((loggedInUser) => {
          // console.debug('logged in user', loggedInUser)
          setUser(loggedInUser)
          setIsLoading(false)
        })
        .catch((err) => {
          const errorMessage = 'Failed to initialize authentication and get user'
          console.error(errorMessage, err)
          alert(errorMessage + ': ' + err)
          setIsLoading(false)
        })

      await authenticationPromise
    }

    fetchUserDetails()
  }, [keycloakConfigFileUrl, backendUrl, fakeLogin])

  const changeLocale = useCallback((newLocale: string) => {
    setUser((previousUser: User) => {
      const updatedUser: User = { ...previousUser, locale: newLocale }
      return updatedUser
    })
  }, [])

  const changeToken = useCallback((newToken: string) => {
    setUser((previousUser: User) => {
      const updatedUser: User = { ...previousUser, token: newToken }
      return updatedUser
    })
  }, [])

  const updateUser = useCallback((newUser: BackendUserProfile) => {
    setUser((previousUser: User) => {
      const updatedUser: User = { ...previousUser, ...newUser }
      return updatedUser
    })
  }, [])

  const contextValue: UserContextValue = useMemo(
    () => ({ user, changeLocale, updateUser, changeToken }),
    [user, changeLocale, updateUser, changeToken],
  )

  return <UserContext.Provider value={contextValue}>{!isLoading && children}</UserContext.Provider>
}

export { UserContext, UserContextProvider }
