import localEnv from './local-environment-dev.json'

export interface ApplicationConfig {
  backendUrl: string
  keycloakConfigFileUrl: string
  fakeLogin: boolean
  homeIframeUrl: string
  googleAnalyticsTrackingId?: string
  environmentId: string
}

const loadEnvironment = async (): Promise<ApplicationConfig> => {
  if (isDevMode()) {
    return localEnv
  }

  return fetch('/environment/environment.json').then((response) => response.json())
}

export const EnvironmentService = {
  loadEnvironment,
}

export const isDevMode = (): boolean => {
  return import.meta.env.DEV
}
