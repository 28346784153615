import React, { ReactElement, useMemo } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { Box } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { AmountTextField, AmountTextFieldProps } from 'src/ui-shared/base/form/control/AmountTextField'
import { FinalFormInput } from 'src/ui-shared/base/form/control/Index'

type TextFieldType = 'password' | 'text' | 'email'

type TextFieldPropsToBeExcluded = FinalFormInput | 'onChange'

type TextFieldProps = Omit<AmountTextFieldProps, TextFieldPropsToBeExcluded> & {
  validate?: any
  name: string
  type?: TextFieldType
}

const CustomAmountTextField = (props: FieldRenderProps<string, HTMLInputElement>): ReactElement => {
  const {
    input: { name, onChange: onChangeFinalForm, onFocus, onBlur, value, type = 'text' },
    meta,
    ...rest
  } = props

  const muiTextFieldProps = rest as AmountTextFieldProps
  const { helperText, ...restMuiTextFieldProps } = muiTextFieldProps

  const { touched, error: errorObject } = meta
  const translate = useTranslate()
  const error = errorObject && translate(errorObject.errorKey, errorObject.params)
  const invalid = Boolean(touched && error)

  const onChangeMemo = useMemo<(optionValue: any) => void>(
    () =>
      (optionValue: any): void => {
        onChangeFinalForm(optionValue)
      },
    [onChangeFinalForm],
  )

  return (
    <Box minHeight="80px">
      <AmountTextField
        {...(restMuiTextFieldProps as any)}
        helperText={invalid && error}
        error={invalid}
        onChange={onChangeMemo}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        type={type}
      />
    </Box>
  )
}
export const AmountTextFieldValidate = (props: TextFieldProps): ReactElement => (
  <Field component={CustomAmountTextField} {...props} />
)
