import {
  ConsumptionChartFactType,
  ConsumptionChartGroupBy,
  ConsumptionCostChartFactType,
  MachineCategory,
} from 'src/service/backend/api'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'
import { ChartSettings, DEFAULT_CHART_SETTINGS } from 'src/service/view-model/base/chart/ChartViewModel'

export interface ConsumptionTotalsChartSettings extends ChartSettings {
  groupBy: ConsumptionChartGroupBy
  factType: ConsumptionChartFactType
  organizationId?: string
  laundryGroupId?: string
  laundryId?: string
  machineId?: string
  machineCategory?: MachineCategory
}

export const DEFAULT_CONSUMPTION_TOTALS_CHART_SETTINGS: ConsumptionTotalsChartSettings = {
  ...DEFAULT_CHART_SETTINGS,
  groupBy: ConsumptionChartGroupBy.TOTAL,
  factType: ConsumptionChartFactType.ENERGY,
}

export const DEFAULT_CONSUMPTION_COST_CHART_SETTINGS: ConsumptionCostChartSettings = {
  ...DEFAULT_CHART_SETTINGS,
  groupBy: ConsumptionChartGroupBy.TOTAL,
  factType: ConsumptionCostChartFactType.COMBINED,
}

export interface ConsumptionCostChartSettings extends ChartSettings {
  groupBy: ConsumptionChartGroupBy
  factType: ConsumptionCostChartFactType
  organizationId?: string
  laundryGroupId?: string
  laundryId?: string
  machineId?: string
  machineCategory?: MachineCategory
}

export interface ConsumptionChartGroupByObject {
  id: ConsumptionChartGroupBy
  name: string
}

export interface ConsumptionCostChartFactTypeObject {
  id: ConsumptionCostChartFactType
  name: string
}

export const CONSUMPTION_CHART_GROUP_BY_OBJECTS: ConsumptionChartGroupByObject[] = [
  {
    id: ConsumptionChartGroupBy.TOTAL,
    name: 'total',
  },
  {
    id: ConsumptionChartGroupBy.ORGANIZATION,
    name: 'organization',
  },
  {
    id: ConsumptionChartGroupBy.LAUNDRY_GROUP,
    name: 'laundryGroup',
  },
  {
    id: ConsumptionChartGroupBy.LAUNDRY,
    name: 'laundry',
  },
  {
    id: ConsumptionChartGroupBy.MACHINE,
    name: 'machine',
  },
  {
    id: ConsumptionChartGroupBy.MACHINE_CATEGORY,
    name: 'machineCategory',
  },
]

export const CONSUMPTION_CHART_COST_FACTTYPE_OBJECTS: ConsumptionCostChartFactTypeObject[] = [
  {
    id: ConsumptionCostChartFactType.COMBINED,
    name: 'consumptionCombined',
  },
  {
    id: ConsumptionCostChartFactType.WATER,
    name: 'consumptionWater',
  },
  {
    id: ConsumptionCostChartFactType.ENERGY,
    name: 'consumptionEnergy',
  },
]

export const getConsumptionGroupByObject = (
  consumptionGroupBy: ConsumptionChartGroupBy | undefined,
): ConsumptionChartGroupByObject | undefined => {
  return findReferenceObject(CONSUMPTION_CHART_GROUP_BY_OBJECTS, consumptionGroupBy)
}

export const getConsumptionCostFactTypeObject = (
  consumptionCostChartFactType: ConsumptionCostChartFactType | undefined,
): ConsumptionCostChartFactTypeObject | undefined => {
  return findReferenceObject(CONSUMPTION_CHART_COST_FACTTYPE_OBJECTS, consumptionCostChartFactType)
}
