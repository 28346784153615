import React, { ReactElement, useEffect } from 'react'
import { ThemeProvider, styled } from '@mui/material'
import { ReactComponent as LogoImage } from 'src/assets/logo-easyset.svg'
import { ES_THEME } from 'src/theme/Theme'
import { Layout } from 'src/ui/layout/main-layout/Layout'

const EsLogo = styled(LogoImage)(({ theme }) => ({
  height: '26.06px',
  width: '140px',
  [theme.breakpoints.up('md')]: {
    width: '165px',
  },
}))

export const EsLayout = (): ReactElement => {
  useEffect(() => {
    document.title = 'easySet'
  }, [])

  return (
    <ThemeProvider theme={ES_THEME}>
      <Layout appLogo={<EsLogo />} enableActiveOrganizationSelector />
    </ThemeProvider>
  )
}
