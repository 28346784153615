/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectivityType } from './ConnectivityType';
import {
    ConnectivityTypeFromJSON,
    ConnectivityTypeFromJSONTyped,
    ConnectivityTypeToJSON,
} from './ConnectivityType';
import type { MachinePairingInfoManufacturerModel } from './MachinePairingInfoManufacturerModel';
import {
    MachinePairingInfoManufacturerModelFromJSON,
    MachinePairingInfoManufacturerModelFromJSONTyped,
    MachinePairingInfoManufacturerModelToJSON,
} from './MachinePairingInfoManufacturerModel';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';

/**
 * 
 * @export
 * @interface MachinePairingInfo
 */
export interface MachinePairingInfo {
    /**
     * 
     * @type {string}
     * @memberof MachinePairingInfo
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MachinePairingInfo
     */
    readonly thingName: string;
    /**
     * 
     * @type {ConnectivityType}
     * @memberof MachinePairingInfo
     */
    connectivityType: ConnectivityType;
    /**
     * 
     * @type {MachinePairingInfoManufacturerModel}
     * @memberof MachinePairingInfo
     */
    manufacturerModel: MachinePairingInfoManufacturerModel;
    /**
     * 
     * @type {MachineType}
     * @memberof MachinePairingInfo
     */
    type: MachineType;
    /**
     * 
     * @type {string}
     * @memberof MachinePairingInfo
     */
    readonly serialNumber: string;
}

/**
 * Check if a given object implements the MachinePairingInfo interface.
 */
export function instanceOfMachinePairingInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "thingName" in value;
    isInstance = isInstance && "connectivityType" in value;
    isInstance = isInstance && "manufacturerModel" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "serialNumber" in value;

    return isInstance;
}

export function MachinePairingInfoFromJSON(json: any): MachinePairingInfo {
    return MachinePairingInfoFromJSONTyped(json, false);
}

export function MachinePairingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachinePairingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'thingName': json['thingName'],
        'connectivityType': ConnectivityTypeFromJSON(json['connectivityType']),
        'manufacturerModel': MachinePairingInfoManufacturerModelFromJSON(json['manufacturerModel']),
        'type': MachineTypeFromJSON(json['type']),
        'serialNumber': json['serialNumber'],
    };
}

export function MachinePairingInfoToJSON(value?: MachinePairingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connectivityType': ConnectivityTypeToJSON(value.connectivityType),
        'manufacturerModel': MachinePairingInfoManufacturerModelToJSON(value.manufacturerModel),
        'type': MachineTypeToJSON(value.type),
    };
}

