/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Permission } from './Permission';
import {
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
} from './Permission';
import type { Role } from './Role';
import {
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface RoleDetail
 */
export interface RoleDetail {
    /**
     * 
     * @type {Role}
     * @memberof RoleDetail
     */
    role: Role;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof RoleDetail
     */
    permissions: Array<Permission>;
}

/**
 * Check if a given object implements the RoleDetail interface.
 */
export function instanceOfRoleDetail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function RoleDetailFromJSON(json: any): RoleDetail {
    return RoleDetailFromJSONTyped(json, false);
}

export function RoleDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': RoleFromJSON(json['role']),
        'permissions': ((json['permissions'] as Array<any>).map(PermissionFromJSON)),
    };
}

export function RoleDetailToJSON(value?: RoleDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': RoleToJSON(value.role),
        'permissions': ((value.permissions as Array<any>).map(PermissionToJSON)),
    };
}

