import React, { FC, ReactElement } from 'react'
import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LaundryGroup, PaymentMethod, Permission } from 'src/service/backend/api/models'
import { getLocaleName } from 'src/service/view-model/base/localization/Locales'
import { getTimezoneObject } from 'src/service/view-model/base/localization/Timezones'
import { getCurrencyName } from 'src/service/view-model/base/payment/Currencies'
import { isOrganizationTypeServicePartner } from 'src/service/view-model/organization/Organizations'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { AddressSection } from 'src/ui-shared/form/address-section/AddressSection'
import { DeleteIcon } from 'src/ui-shared/icons/Icons'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

interface Props {
  laundryGroup: LaundryGroup
  navigateToEditPage: () => void
  removeModal: boolean
  setRemoveModal: (value: boolean) => void
  onRemoveHandler: () => void
}

export const LaundryGroupOverviewTab: FC<Props> = ({
  laundryGroup,
  navigateToEditPage,
  onRemoveHandler,
  removeModal,
  setRemoveModal,
}): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const appId = useAppId()
  const user = useUser()

  const canEdit = hasPermission(user, Permission.LAUNDRY_GROUP_WRITE)
  useHotKeysForm()

  // JSX
  const editButton = (
    <>
      <Button id="editButton" variant="contained" color="primary" size="large" onClick={navigateToEditPage}>
        {translate('button.edit')}
      </Button>
    </>
  )

  const editAction = canEdit ? editButton : <></>

  const deleteButton = (
    <Button
      startIcon={<DeleteIcon />}
      variant="outlined"
      color="primary"
      size="large"
      className={sharedClasses.ButtonMargin}
      onClick={() => setRemoveModal(true)}
    >
      {translate('button.delete')}
    </Button>
  )

  const deleteAction = canEdit ? deleteButton : <></>

  return (
    <>
      <Box pt={2}>
        <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
          <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
            <DetailsTextField value={laundryGroup.name} name={'name'} label={translate('laundryGroupName')} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField name={'wlNumber'} value={laundryGroup.wlNumber} label={translate('wlNumber')} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField
              value={laundryGroup.organization.name}
              label={translate('organization')}
              link={
                isOrganizationTypeServicePartner(laundryGroup.organization.type)
                  ? `/${appId}/service-partners/${laundryGroup.organization.id}/view`
                  : `/${appId}/organizations/${laundryGroup.organization.id}/view`
              }
            />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField name={'phoneNumber'} value={laundryGroup.phoneNumber} label={translate('phonenumber')} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField value={laundryGroup.emails?.join(', ') || ''} label={translate('email')} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField
              name={'locale'}
              value={getLocaleName(laundryGroup.locale)}
              label={translate('language')}
            />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField
              name={'timezone'}
              value={getTimezoneObject(laundryGroup.timeZone)?.name || laundryGroup.timeZone}
              label={translate('timezone')}
            />
          </Grid>
        </Grid>
        <Divider className={sharedClasses.Divider} />
        <AddressSection
          address={laundryGroup.siteAddress ? laundryGroup.siteAddress : laundryGroup.billingAddress}
          titleKey="siteAddress"
        />
        <Divider className={sharedClasses.Divider} />
        <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField name={'iban'} value={laundryGroup.iban} label={translate('iban')} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField
              name={'ibanHolderName'}
              value={laundryGroup.ibanHolderName}
              label={translate('ibanHolderName')}
            />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS} lg={6}>
            <DetailsTextField
              name={'laundryGroup'}
              value={laundryGroup.externalPayoutReference}
              label={translate('laundryGroup.externalPayoutReference')}
            />
          </Grid>
        </Grid>
        <Divider className={sharedClasses.Divider} />
        <AddressSection
          address={laundryGroup.billingAddress}
          addressType={'billing'}
          titleKey="addressBilling"
          hideCoordinates
          hideFields={Boolean(!laundryGroup.siteAddress)}
        >
          <FormControlLabel
            control={<Checkbox color="primary" checked={Boolean(!laundryGroup.siteAddress)} disabled />}
            label={translate('useSiteAddressForBilling')}
          />
        </AddressSection>
        <Divider className={sharedClasses.Divider} />
        <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField
              name={'currency'}
              value={getCurrencyName(laundryGroup.currency)}
              label={translate('currency')}
            />
          </Grid>
          {appId === AppId.WASH_MASTER && (
            <Grid item {...ITEM_BREAKPOINTS}>
              <DetailsTextField
                name={'paymethod'}
                value={
                  laundryGroup.washmaster?.enabledPaymentMethod === PaymentMethod.DIRECTPAY
                    ? translate('directPay')
                    : translate('prepaid')
                }
                label={translate('washmaster.paymentMethod')}
              />
            </Grid>
          )}
          <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
            <DetailsTextField name={'notes'} value={laundryGroup.notes} multiline label={translate('notes')} />
          </Grid>
        </Grid>
        <Divider className={sharedClasses.Divider} />
        <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
          <Grid item {...ITEM_BREAKPOINTS} alignItems={'end'}>
            <Typography className={sharedClasses.SectionTitle}>{translate('features')}</Typography>
            <FormControl disabled component="fieldset">
              <FormGroup>
                {appId === AppId.WASH_MASTER && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={laundryGroup.washmaster?.publicLaundryGroup}
                          disabled
                          name="publicLaundryGroup"
                        />
                      }
                      label={translate('publicLaundryGroup')}
                    />
                  </>
                )}
                {appId === AppId.SERVICE_MASTER && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={laundryGroup.servicemaster?.usageReportsEnabled}
                          disabled
                          name="usageReportsEnabled"
                        />
                      }
                      label={translate('usageReportsEnabled')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={laundryGroup.servicemaster?.utilizationReportsEnabled}
                          disabled
                          name="utilizationReportsEnabled"
                        />
                      }
                      label={translate('utilizationReportsEnabled')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={laundryGroup.servicemaster?.hygieneMonitoringEnabled}
                          disabled
                          name="hygieneMonitoringEnabled"
                        />
                      }
                      label={translate('hygieneMonitoringEnabled')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={laundryGroup.servicemaster?.directCareEnabled}
                          disabled
                          name="directCareEnabled"
                        />
                      }
                      label={translate('directCareEnabled')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={laundryGroup.servicemaster?.customProgramsEnabled}
                          disabled
                          name="customProgramsEnabled"
                        />
                      }
                      label={translate('customProgramsEnabled')}
                    />
                  </>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={laundryGroup.washmaster?.payPerUseEnabled}
                      disabled
                      name="payPerUseEnabled"
                    />
                  }
                  label={translate('payPerUseEnabled')}
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={3} display="flex" justifyContent="space-between">
          <Box>{deleteAction}</Box>
          <Box display="flex" justifyContent="flex-end">
            {editAction}
          </Box>
        </Box>
      </Box>

      <ConfirmationModalDialog
        titleKey="deleteLaundryGroup"
        confirmationKey="button.delete"
        open={removeModal}
        onConfirm={onRemoveHandler}
        onCancel={() => setRemoveModal(false)}
      >
        {translate('deleteConfirm', laundryGroup.name)}
      </ConfirmationModalDialog>
    </>
  )
}
