import React, { ReactElement } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { TextFieldProps } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { FinalFormInput } from 'src/ui-shared/base/form/control/Index'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

type TextFieldType = 'password' | 'text' | 'email'

type TextFieldPropsToBeExcluded = FinalFormInput | 'variant'

type CustomTextFieldProps = Omit<TextFieldProps, TextFieldPropsToBeExcluded> & {
  validate?: any
  name: string
  type?: TextFieldType
}

const CustomTextField = (props: FieldRenderProps<string, HTMLInputElement>): ReactElement => {
  const {
    input: { name, onChange, onFocus, onBlur, value, type = 'text' },
    meta,
    ...rest
  } = props

  const muiTextFieldProps = rest as TextFieldProps
  const { helperText, ...restMuiTextFieldProps } = muiTextFieldProps

  const { touched, error: errorObject } = meta
  const translate = useTranslate()
  const error = errorObject && translate(errorObject.errorKey, errorObject.params)
  const invalid = Boolean(touched && error)

  return (
    <TextFieldDefault
      {...(restMuiTextFieldProps as any)}
      fullWidth
      helperText={invalid && error}
      error={invalid}
      onChange={onChange}
      name={name}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      type={type}
      autoComplete="off"
    />
  )
}
export const TextField = (props: CustomTextFieldProps): ReactElement => <Field component={CustomTextField} {...props} />
