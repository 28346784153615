import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useAppId } from 'src/app/AppProvider'
import {
  getIsOnboardingSeenFromStorage,
  storeIsOnboardingSeenToStorage,
} from 'src/service/local-storage/LocalStorageService'
import { OnboardingSlideshow, SlideItem } from 'src/ui/layout/onboarding-slideshow/OnboardingSlideshow'
import { useUser } from 'src/user/UserContext'

interface Props {
  slides: SlideItem[]
}

export const OnboardingSection = ({ slides }: Props): ReactElement => {
  const user = useUser()
  const navigate = useNavigate()
  const appId = useAppId()

  // url query params
  const [searchParams] = useSearchParams()

  //state
  const [seenOnboarding, setSeenOnboarding] = useState<boolean>(getIsOnboardingSeenFromStorage(user.email))

  useEffect(() => {
    const onboardingSearchParam = searchParams.get('onboarding')
    const onboarding = onboardingSearchParam === 'true'

    if ((onboardingSearchParam && onboarding) || !seenOnboarding) {
      openOnboardingSlideshow()
    }
  }, [searchParams])

  const openOnboardingSlideshow = () => {
    storeIsOnboardingSeenToStorage(user.email)
    setSeenOnboarding(false)
  }

  const closeOnboardingSlideshow = () => {
    setSeenOnboarding(true)
    navigate(`/${appId}`)
  }

  return (
    <OnboardingSlideshow
      slides={slides}
      key={seenOnboarding.toString()}
      open={!seenOnboarding}
      onClose={closeOnboardingSlideshow}
    />
  )
}
