import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  LaundryGroup,
  LaundryGroupSubscription,
  SubscriptionsLaundryGroupsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ServicePackagePlanAdditionalOptionTable } from 'src/ui/layout-page/service-package-plan/details/ServicePackagePlanAdditionalOptionTable'
import { ServicePackagePlanTable } from 'src/ui/layout-page/service-package-plan/details/ServicePackagePlanTable'

interface Props {
  laundryGroup: LaundryGroup
}

export const LaundryGroupServicePackagePlanTab: FC<Props> = ({ laundryGroup }): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const subscriptionLaundryGroupsApi = new SubscriptionsLaundryGroupsApi(httpConfiguration)

  const [laundryGroupSubscription, setLaundryGroupSubscription] = useState<LaundryGroupSubscription | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // load data
  useEffect(() => {
    setLoading(true)

    subscriptionLaundryGroupsApi
      .subscriptionsLaundrygroupsLaundryGroupIdGet(laundryGroup.id)
      .then((data) => {
        setLaundryGroupSubscription(data)
        setErrorMessage(null)
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLaundryGroupSubscription(null)
        setLoading(false)
      })
  }, [laundryGroup])

  return (
    <>
      <LoadingIndicator loading={loading} />

      {errorMessage && <ErrorMessage message={errorMessage} />}

      {laundryGroupSubscription && (
        <>
          <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
            <Grid item xs={12}>
              <Typography className={sharedClasses.SectionTitle}>{translate('servicePackagePlans')}</Typography>
            </Grid>
            <ServicePackagePlanTable laundryGroupSubscription={laundryGroupSubscription} />
          </Grid>
          <ServicePackagePlanAdditionalOptionTable
            laundryGroupSubscription={laundryGroupSubscription}
            showPricing={false}
          />
        </>
      )}
    </>
  )
}
