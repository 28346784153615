/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PriceListType {
    INTEGRATED = 'INTEGRATED',
    EXTERNAL = 'EXTERNAL'
}


export function PriceListTypeFromJSON(json: any): PriceListType {
    return PriceListTypeFromJSONTyped(json, false);
}

export function PriceListTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceListType {
    return json as PriceListType;
}

export function PriceListTypeToJSON(value?: PriceListType | null): any {
    return value as any;
}

