import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Box, IconButton, Paper, TableCell, TableRow } from '@mui/material'
import { FileDownload } from '@mui/icons-material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ApiResponse, Configuration, InvoiceApi, LaundryGroup, LaundryGroupInvoice } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getRemoveAcceptHeaderInit } from 'src/service/backend/http/HttpUtils'
import { downloadFile, getDownloadFilename } from 'src/service/utils/FileDownloadUtils'
import { formatAmountForLocale } from 'src/service/utils/NumberFormatUtils'
import { Data } from 'src/service/view-model/base/Data'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { DataTable } from 'src/ui-shared/table/DataTable'
import { DEFAULT_DATA, HeadCellsWidth, TableData, getDefaultTableSettings } from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData } from 'src/ui-shared/table/Table.utils'
import { useUserRegionLocale } from 'src/user/UserContext'

interface InvoiceHeadCells extends HeadCellsWidth {
  id: keyof LaundryGroupInvoice | 'download'
  label: string
}

const headCells: InvoiceHeadCells[] = [
  {
    id: 'billingMonth',
    label: 'billingMonth',
    noSort: true,
  },
  {
    id: 'currency',
    label: 'currency',
    noSort: true,
  },
  {
    id: 'amount',
    label: 'amount',
    noSort: true,
  },
  {
    id: 'payoutReference',
    label: 'payoutReference',
    noSort: true,
  },
  {
    id: 'download',
    label: '',
    width: 10,
    noSort: true,
  },
]

interface Props {
  laundryGroup: LaundryGroup
}

export const LaundryGroupInvoicesTab: FC<Props> = ({ laundryGroup }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()
  const regionLocale = useUserRegionLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const invoiceApi = new InvoiceApi(httpConfiguration)

  const [data, setData] = useState<TableData<LaundryGroupInvoice>>(DEFAULT_DATA)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // load data
  useEffect(() => {
    let active = true
    setLoading(true)

    invoiceApi
      .invoicesLaundryGroupIdGet(laundryGroup.id)
      .then((data) => {
        if (active) {
          setData(mapData(data as Data<any>))
          setErrorMessage(null)
          setLoading(false)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [laundryGroup])

  const downloadInvoicePdf = (invoice: LaundryGroupInvoice) => {
    let filename: string | null = null

    invoiceApi
      .invoicesLaundryGroupIdBillingMonthPdfGetRaw(
        {
          laundryGroupId: laundryGroup.id,
          billingMonth: invoice.billingMonth,
        },
        getRemoveAcceptHeaderInit(),
      )
      .then(async (response: ApiResponse<Blob>) => {
        filename = getDownloadFilename(response.raw)
        return response.value()
      })
      .then((blob: Blob) => {
        downloadFile(blob, filename)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  // JSX
  const nonEmptyRows = data.data.map((item, index) => {
    return (
      <TableRow className={tableClasses.tableRow} key={index} style={{ cursor: 'default' }}>
        <TableCell>{item.billingMonth}</TableCell>
        <TableCell>{item.currency}</TableCell>
        <TableCell>{formatAmountForLocale(item.amount, regionLocale, item.currency)}</TableCell>
        <TableCell>{item.payoutReference}</TableCell>
        <TableCell style={{ paddingTop: '0px', paddingBottom: '0px' }}>
          <IconButton color="primary" onClick={downloadInvoicePdf.bind(this, item)}>
            <FileDownload />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  })

  return (
    <Box pt={2}>
      <Paper elevation={0}>
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

        <DataTable
          headCells={headCells}
          data={data}
          nonEmptyRows={nonEmptyRows}
          tableSettings={getDefaultTableSettings()}
          setTableSettings={() => undefined}
          loading={loading}
          translate={translate}
          withPagination={false}
        />
      </Paper>
    </Box>
  )
}
