/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FhMonitoringEntry,
  Language,
  MachineCategory,
  PageableFhMonitoringEntries,
} from '../models';
import {
    FhMonitoringEntryFromJSON,
    FhMonitoringEntryToJSON,
    LanguageFromJSON,
    LanguageToJSON,
    MachineCategoryFromJSON,
    MachineCategoryToJSON,
    PageableFhMonitoringEntriesFromJSON,
    PageableFhMonitoringEntriesToJSON,
} from '../models';

export interface FhMonitoringsCsvGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
}

export interface FhMonitoringsDefinitionsCsvGetRequest {
    language: Language;
}

export interface FhMonitoringsDefinitionsCsvPutRequest {
    body?: Blob;
}

export interface FhMonitoringsGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
    machineCategory?: MachineCategory;
    errorCode?: string;
}

export interface FhMonitoringsMessageIdTimestampGetRequest {
    messageId: string;
    timestamp: number;
}

/**
 * 
 */
export class FHMonitoringsApi extends runtime.BaseAPI {

    /**
     * Returns a CSV file of F/H monitoring entries
     */
    async fhMonitoringsCsvGetRaw(requestParameters: FhMonitoringsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling fhMonitoringsCsvGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling fhMonitoringsCsvGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fh-monitorings/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Returns a CSV file of F/H monitoring entries
     */
    async fhMonitoringsCsvGet(startTimeFrom: Date, startTimeTo: Date, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.fhMonitoringsCsvGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a CSV file of F/H monitoring definition entries
     */
    async fhMonitoringsDefinitionsCsvGetRaw(requestParameters: FhMonitoringsDefinitionsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling fhMonitoringsDefinitionsCsvGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fh-monitorings/definitions/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Returns a CSV file of F/H monitoring definition entries
     */
    async fhMonitoringsDefinitionsCsvGet(language: Language, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.fhMonitoringsDefinitionsCsvGetRaw({ language: language }, initOverrides);
        return await response.value();
    }

    /**
     * Update F/H monitoring definition entries
     */
    async fhMonitoringsDefinitionsCsvPutRaw(requestParameters: FhMonitoringsDefinitionsCsvPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/csv';

        const response = await this.request({
            path: `/fh-monitorings/definitions/csv`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update F/H monitoring definition entries
     */
    async fhMonitoringsDefinitionsCsvPut(body?: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.fhMonitoringsDefinitionsCsvPutRaw({ body: body }, initOverrides);
    }

    /**
     * List FH Monitoring entries
     */
    async fhMonitoringsGetRaw(requestParameters: FhMonitoringsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableFhMonitoringEntries>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling fhMonitoringsGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling fhMonitoringsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        if (requestParameters.machineCategory !== undefined) {
            queryParameters['machineCategory'] = requestParameters.machineCategory;
        }

        if (requestParameters.errorCode !== undefined) {
            queryParameters['errorCode'] = requestParameters.errorCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fh-monitorings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableFhMonitoringEntriesFromJSON(jsonValue));
    }

    /**
     * List FH Monitoring entries
     */
    async fhMonitoringsGet(startTimeFrom: Date, startTimeTo: Date, size?: number, page?: number, sort?: string, search?: string, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, machineCategory?: MachineCategory, errorCode?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableFhMonitoringEntries> {
        const response = await this.fhMonitoringsGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, size: size, page: page, sort: sort, search: search, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId, machineCategory: machineCategory, errorCode: errorCode }, initOverrides);
        return await response.value();
    }

    /**
     * Get a single machine error
     */
    async fhMonitoringsMessageIdTimestampGetRaw(requestParameters: FhMonitoringsMessageIdTimestampGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FhMonitoringEntry>> {
        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId','Required parameter requestParameters.messageId was null or undefined when calling fhMonitoringsMessageIdTimestampGet.');
        }

        if (requestParameters.timestamp === null || requestParameters.timestamp === undefined) {
            throw new runtime.RequiredError('timestamp','Required parameter requestParameters.timestamp was null or undefined when calling fhMonitoringsMessageIdTimestampGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fh-monitorings/{messageId}/{timestamp}`.replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))).replace(`{${"timestamp"}}`, encodeURIComponent(String(requestParameters.timestamp))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FhMonitoringEntryFromJSON(jsonValue));
    }

    /**
     * Get a single machine error
     */
    async fhMonitoringsMessageIdTimestampGet(messageId: string, timestamp: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FhMonitoringEntry> {
        const response = await this.fhMonitoringsMessageIdTimestampGetRaw({ messageId: messageId, timestamp: timestamp }, initOverrides);
        return await response.value();
    }

}
