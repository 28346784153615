import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useNavigate } from 'react-router'
import { Divider, Grid, IconButton, Typography, styled } from '@mui/material'
import { Save } from '@mui/icons-material'
import { Box } from '@mui/system'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, RolesApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  CheckboxData,
  RolePermissionViewModel,
  toRoleDetailViewModel,
  toViewModel,
} from 'src/service/view-model/role-permission/RolePermissionViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { CheckboxField } from 'src/ui-shared/base/form/control/CheckboxField'
import { usePrompt } from 'src/ui-shared/base/hooks/usePrompt'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
}))

export const RolePermissionEditPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const navigate = useNavigate()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const rolesApi = new RolesApi(httpConfiguration)

  const appId = useAppId()

  // state
  const [loading, setLoading] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [rolePermissionViewModel, setRolePermissionViewModel] = useState<RolePermissionViewModel>({})
  const [dirty, setDirty] = useState<boolean>(false)

  usePrompt(translate('notification.form.unsaved.changes'), dirty)

  // handle events
  const navigateBack = () => {
    navigate(`/${appId}/roles`)
  }

  useEffect(() => {
    let active = true

    rolesApi
      .rolesGet()
      .then((roleDetails) => {
        if (!active) return
        setRolePermissionViewModel(toViewModel(roleDetails))
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [])

  const submitForm = (roleKey: string, permissions: CheckboxData) => {
    rolesApi
      .rolesPut(toRoleDetailViewModel(roleKey, permissions))
      .then((value) => {
        const singleRoleDetail = toViewModel([value])
        setRolePermissionViewModel({
          ...rolePermissionViewModel,
          ...singleRoleDetail,
        })
        showSnackbar(translate('rolesAndPermissions.update.success', roleKey), 'success')
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setDirty(false)
        setErrorMessage(errorMessage)
      })
  }

  return (
    <ScreenLayout actions={<></>} actionsWidth={50} onBack={navigateBack} title={translate('rolesAndPermissions')}>
      <LoadingIndicator loading={loading} />
      {errorMessage ? (
        <ErrorMessage message={errorMessage} />
      ) : (
        Object.entries(rolePermissionViewModel).map(([key, value]) => {
          return (
            <Box key={key}>
              <Form<CheckboxData>
                onSubmit={(data) => submitForm(key, data)}
                initialValues={value}
                render={({ handleSubmit, submitting, pristine }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box mt={3} gap={'1rem'} display="flex" justifyContent="flex-start">
                        <Typography variant="h5">{key}</Typography>
                        <IconButtonStyled type={'submit'} disabled={submitting || pristine}>
                          <Save />
                        </IconButtonStyled>
                      </Box>
                      <Grid container className={sharedClasses.GridWithTextField}>
                        {Object.keys(value).map((key) => {
                          return (
                            <Grid key={key} item lg={2}>
                              <CheckboxField label={key} name={key} />
                            </Grid>
                          )
                        })}
                      </Grid>
                      <FormSpy
                        subscription={{ dirty: true }}
                        onChange={(props) => {
                          setTimeout(() => setDirty(props.dirty), 0)
                        }}
                      />
                    </form>
                  )
                }}
              ></Form>
              <Divider className={sharedClasses.Divider} />
            </Box>
          )
        })
      )}
    </ScreenLayout>
  )
}
