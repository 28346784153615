import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { Divider, Grid } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LaundryGroupPriceListExternal } from 'src/service/backend/api/models'
import {
  LaundryGroupPriceListExternalViewModel,
  createLaundryGroupPriceListExternalViewModel,
} from 'src/service/view-model/laundry-group/LaundryGroupPriceListViewModel'
import { getDrumSizeLabel, getMachineTypeName } from 'src/service/view-model/machine/Machines'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import {
  composeValidators,
  required,
  validAmount,
  validBillingIncrement,
  validProgramTime,
} from 'src/ui-shared/base/form/validation/Validators'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { CurrencyAutoCompleteValidate } from 'src/ui-shared/form/control/CurrencyAutoCompleteValidate'

interface Props {
  priceList: LaundryGroupPriceListExternal | null | undefined
  submitForm: (values: LaundryGroupPriceListExternalViewModel) => void
  setDirty: (value: boolean) => void
}

export const LaundryGroupPriceListExternalEdit: FC<Props> = ({ priceList, submitForm, setDirty }): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()

  const [laundryGroupPriceListExternalViewModel, setLaundryGroupPriceListExternalViewModel] =
    useState<LaundryGroupPriceListExternalViewModel>(createLaundryGroupPriceListExternalViewModel())

  const maxProgramTime = 400

  useEffect(() => {
    if (priceList) {
      const laundryGroupPriceListExternalViewModel = createLaundryGroupPriceListExternalViewModel(priceList)
      setLaundryGroupPriceListExternalViewModel(laundryGroupPriceListExternalViewModel)
    }
  }, [priceList])

  return (
    <Form<LaundryGroupPriceListExternalViewModel>
      initialValues={laundryGroupPriceListExternalViewModel}
      onSubmit={submitForm}
      render={({ handleSubmit, submitting, values }) => {
        return (
          <form onSubmit={handleSubmit} id="laundryGroupPriceListExternalForm">
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item xs={3}>
                <DetailsTextField
                  value={getMachineTypeName(priceList?.machineType, translate)}
                  label={translate('assignedMachineType')}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={translate('member.pricelist')}
                  type="text"
                  name="name"
                  fullWidth
                  disabled={submitting}
                  validate={required()}
                />
              </Grid>
              <Grid item xs={2}>
                <DetailsTextField
                  value={getDrumSizeLabel(priceList?.drumSize, priceList?.drumSizeUnit)}
                  label={translate('member.machinesize')}
                />
              </Grid>
              <Grid item xs={3}>
                <CurrencyAutoCompleteValidate name="currency" disabled={true} validate={required()} />
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item xs={6}>
                <TextField
                  label={translate('priceStandard')}
                  type="text"
                  name="regularAmount"
                  fullWidth
                  disabled={submitting}
                  validate={composeValidators(required(), validAmount())}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={translate('priceHappyHour')}
                  type="text"
                  name="happyHourAmount"
                  fullWidth
                  disabled={submitting}
                  validate={composeValidators(required(), validAmount())}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={translate('maxProgramTime')}
                  type="text"
                  name="maxProgramTime"
                  fullWidth
                  disabled={submitting}
                  validate={composeValidators(required(), validProgramTime(undefined, maxProgramTime))}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  key={values.regularAmount}
                  label={translate('billingIncrement')}
                  type="text"
                  name="amountPerUnit"
                  fullWidth
                  disabled={submitting}
                  validate={composeValidators(required(), validAmount(), validBillingIncrement(values.regularAmount))}
                />
              </Grid>
            </Grid>
            <FormSpy
              subscription={{ dirty: true }}
              onChange={(props) => {
                setTimeout(() => setDirty(props.dirty), 0)
              }}
            />
          </form>
        )
      }}
    />
  )
}
