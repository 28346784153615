/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachinePairingInfoManufacturerModel
 */
export interface MachinePairingInfoManufacturerModel {
    /**
     * 
     * @type {string}
     * @memberof MachinePairingInfoManufacturerModel
     */
    modelName: string;
    /**
     * 
     * @type {string}
     * @memberof MachinePairingInfoManufacturerModel
     */
    productNumber: string;
    /**
     * 
     * @type {string}
     * @memberof MachinePairingInfoManufacturerModel
     */
    manufacturerName: string;
}

/**
 * Check if a given object implements the MachinePairingInfoManufacturerModel interface.
 */
export function instanceOfMachinePairingInfoManufacturerModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modelName" in value;
    isInstance = isInstance && "productNumber" in value;
    isInstance = isInstance && "manufacturerName" in value;

    return isInstance;
}

export function MachinePairingInfoManufacturerModelFromJSON(json: any): MachinePairingInfoManufacturerModel {
    return MachinePairingInfoManufacturerModelFromJSONTyped(json, false);
}

export function MachinePairingInfoManufacturerModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachinePairingInfoManufacturerModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelName': json['modelName'],
        'productNumber': json['productNumber'],
        'manufacturerName': json['manufacturerName'],
    };
}

export function MachinePairingInfoManufacturerModelToJSON(value?: MachinePairingInfoManufacturerModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelName': value.modelName,
        'productNumber': value.productNumber,
        'manufacturerName': value.manufacturerName,
    };
}

