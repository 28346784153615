/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IssueCommonState {
    REQUESTED = 'REQUESTED',
    OPEN = 'OPEN',
    IN_PROGRESS = 'IN_PROGRESS',
    CLOSED = 'CLOSED'
}


export function IssueCommonStateFromJSON(json: any): IssueCommonState {
    return IssueCommonStateFromJSONTyped(json, false);
}

export function IssueCommonStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueCommonState {
    return json as IssueCommonState;
}

export function IssueCommonStateToJSON(value?: IssueCommonState | null): any {
    return value as any;
}

