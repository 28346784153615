/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClientType } from './ClientType';
import {
    ClientTypeFromJSON,
    ClientTypeFromJSONTyped,
    ClientTypeToJSON,
} from './ClientType';

/**
 * 
 * @export
 * @interface MeInitializeRequest
 */
export interface MeInitializeRequest {
    /**
     * a unique id of android/ios or for webapps just "webapp"
     * @type {string}
     * @memberof MeInitializeRequest
     */
    deviceId: string;
    /**
     * 
     * @type {ClientType}
     * @memberof MeInitializeRequest
     */
    clientType: ClientType;
    /**
     * The Version of the Mobile-/Web-App
     * @type {string}
     * @memberof MeInitializeRequest
     */
    appVersion: string;
    /**
     * Custom device name of the users device
     * @type {string}
     * @memberof MeInitializeRequest
     */
    deviceName?: string;
    /**
     * When user allows to send Notifications
     * @type {string}
     * @memberof MeInitializeRequest
     */
    firebaseToken?: string | null;
    /**
     * Manufacturer of the device (when available)
     * @type {string}
     * @memberof MeInitializeRequest
     */
    manufacturer?: string | null;
    /**
     * Model of the device (when available)
     * @type {string}
     * @memberof MeInitializeRequest
     */
    model?: string | null;
    /**
     * OS Name of the device (when available)
     * @type {string}
     * @memberof MeInitializeRequest
     */
    os?: string | null;
    /**
     * OS Version of the device (when available)
     * @type {string}
     * @memberof MeInitializeRequest
     */
    osVersion?: string | null;
    /**
     * Any further meta data describing a device (when available)
     * @type {string}
     * @memberof MeInitializeRequest
     */
    meta?: string | null;
}

/**
 * Check if a given object implements the MeInitializeRequest interface.
 */
export function instanceOfMeInitializeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deviceId" in value;
    isInstance = isInstance && "clientType" in value;
    isInstance = isInstance && "appVersion" in value;

    return isInstance;
}

export function MeInitializeRequestFromJSON(json: any): MeInitializeRequest {
    return MeInitializeRequestFromJSONTyped(json, false);
}

export function MeInitializeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeInitializeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['deviceId'],
        'clientType': ClientTypeFromJSON(json['clientType']),
        'appVersion': json['appVersion'],
        'deviceName': !exists(json, 'deviceName') ? undefined : json['deviceName'],
        'firebaseToken': !exists(json, 'firebaseToken') ? undefined : json['firebaseToken'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'os': !exists(json, 'os') ? undefined : json['os'],
        'osVersion': !exists(json, 'osVersion') ? undefined : json['osVersion'],
        'meta': !exists(json, 'meta') ? undefined : json['meta'],
    };
}

export function MeInitializeRequestToJSON(value?: MeInitializeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'clientType': ClientTypeToJSON(value.clientType),
        'appVersion': value.appVersion,
        'deviceName': value.deviceName,
        'firebaseToken': value.firebaseToken,
        'manufacturer': value.manufacturer,
        'model': value.model,
        'os': value.os,
        'osVersion': value.osVersion,
        'meta': value.meta,
    };
}

