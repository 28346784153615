import React, { ReactElement } from 'react'
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  Validate,
  ValidationRule,
} from 'react-hook-form'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  getActiveValueLabel,
  getDefaultValueLabel,
  getHintOrError,
} from 'src/ui-shared/base/form/control-hook-form/HookForm'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

interface TextFieldWrapperProps<T extends FieldValues, FieldPropertyName extends Path<T>> {
  name: FieldPropertyName
  control: Control<T, any>
  label: string
  activeValue?: string
  defaultValue?: string
  validate?:
    | Validate<PathValue<T, FieldPropertyName>, T>
    | Record<string, Validate<PathValue<T, FieldPropertyName>, T>>
    | undefined
  required?: ValidationRule<boolean>
  disabled?: boolean
  hintText?: string
  onChangeEventListener?: (field: ControllerRenderProps<T, FieldPropertyName>, fieldState: ControllerFieldState) => void
  multiline?: boolean
  rows?: number
}

export const TextFieldHookForm = <T extends FieldValues, FieldPropertyName extends Path<T>>({
  control,
  name,
  label,
  activeValue,
  defaultValue,
  validate,
  required,
  disabled,
  hintText,
  onChangeEventListener,
  multiline = false,
  rows,
}: TextFieldWrapperProps<T, FieldPropertyName>): ReactElement => {
  const translate = useTranslate()

  const handleOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: ControllerRenderProps<T, FieldPropertyName>,
    fieldState: ControllerFieldState,
  ) => {
    field.onChange(event.target.value)

    if (onChangeEventListener) {
      onChangeEventListener(field, fieldState)
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: validate, required: required }}
      render={({ field, fieldState }) => {
        const hint = getHintOrError(fieldState, translate, hintText)
        const errorState = !!fieldState.error

        const value = field.value

        let labelToUse = label
        if (activeValue !== undefined && activeValue !== value) {
          labelToUse = getActiveValueLabel(translate, label, activeValue)
        }

        if (defaultValue !== undefined && defaultValue !== value) {
          labelToUse += getDefaultValueLabel(translate, label, defaultValue)
        }

        return (
          <>
            <TextFieldDefault
              label={labelToUse}
              {...field}
              value={value}
              onChange={(event) => handleOnChange(event, field, fieldState)}
              disabled={disabled}
              variant="filled"
              multiline={multiline}
              rows={rows}
              helperText={hint}
              error={errorState}
            />
          </>
        )
      }}
    />
  )
}
