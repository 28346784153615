import React, { useState } from 'react'
import { Box, IconButton, TextFieldProps } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue'
import moment from 'moment'
import { useTranslate } from 'src/i18n/useMessageSource'
import { formatDateForLocale } from 'src/service/utils/DateFormatUtils'
import { getEndOfWeekMoment, getStartOfWeekUTC } from 'src/service/utils/MomentUtils'
import { getLocaleForDatePicker } from 'src/service/view-model/base/localization/Locales'
import { DatePickerDefault } from 'src/ui-shared/base/form/control/DatePickerDefault'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { useUserLocale, useUserRegionLocale } from 'src/user/UserContext'

interface WeekDayPickerProps {
  onChange: (date: Date | null) => void
  startDate?: Date
  delay?: number
}

export const WeekPicker: React.FC<WeekDayPickerProps> = ({ onChange, delay = 300, startDate }) => {
  const { classes: textFieldClasses } = useTextFieldStyles()

  const translate = useTranslate()
  const locale = useUserLocale()
  const regionLocale = useUserRegionLocale()

  const [startOfWeekDate, setStartOfWeekDate] = useState<Date>(
    startDate ? getStartOfWeekUTC(startDate) : getStartOfWeekUTC(new Date()),
  )
  const [timeoutState, setTimeoutState] = useState<any>(null)

  const decrementWeek = () => {
    const newWeek = moment(startOfWeekDate).subtract(7, 'days').toDate()
    setStartOfWeekDate(newWeek)

    debounceFunction(newWeek)
  }

  const incrementWeek = () => {
    const newWeek = moment(startOfWeekDate).add(7, 'days').toDate()
    setStartOfWeekDate(newWeek)

    debounceFunction(newWeek)
  }

  const debounceFunction = (newWeek: Date) => {
    if (timeoutState) {
      clearTimeout(timeoutState)
    }

    setTimeoutState(
      setTimeout(() => {
        onChange(newWeek)
      }, delay),
    )
  }

  const changeCalendarDay = (date: Date | null, context: PickerChangeHandlerContext<any>) => {
    if (!date || context.validationError) {
      return
    }

    const startOfWeek = moment(date).startOf('isoWeek').toDate()
    setStartOfWeekDate(startOfWeek)
    onChange(startOfWeek)
  }

  const startDateMoment = moment(startOfWeekDate)
  const startDateFormatted = formatDateForLocale(startDateMoment.toDate(), regionLocale)
  const endDateFormatted = formatDateForLocale(getEndOfWeekMoment(startDateMoment).toDate(), regionLocale)

  return (
    <Box>
      <Box flexDirection="row" display="flex">
        <Box display="flex" alignItems="center">
          <IconButton onClick={decrementWeek}>
            <ArrowBackIos htmlColor="rgba(0, 0, 0, 0.7)" />
          </IconButton>
        </Box>
        <Box>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={getLocaleForDatePicker(locale, regionLocale)}
          >
            <DatePickerDefault
              locale={regionLocale}
              label={translate('selectWeek')}
              views={['day']}
              format="dd.MM.yyyy"
              value={startOfWeekDate}
              onChange={changeCalendarDay}
              textFieldClassName={textFieldClasses.TextFieldSmall}
              textField={(textFieldProps: TextFieldProps) => (
                <TextFieldDefault
                  {...textFieldProps}
                  onKeyDown={(e) => e.preventDefault()}
                  value={`${startDateFormatted} - ${endDateFormatted}`}
                />
              )}
              selectedSections={null}
            />
          </LocalizationProvider>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton onClick={incrementWeek}>
            <ArrowForwardIos htmlColor="rgba(0, 0, 0, 0.7)" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
