import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Divider, Grid, Hidden, Paper } from '@mui/material'
import { ViewTimelineOutlined } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { StyledTab } from 'src/ui-shared/base/tab-panel/StyledTab'
import { StyledTabs } from 'src/ui-shared/base/tab-panel/StyledTabs'
import { TabPanel } from 'src/ui-shared/base/tab-panel/TabPanel'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { InsertChartOutlinedIcon } from 'src/ui-shared/icons/Icons'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { UtilizationCompareMachineGroupsChart } from 'src/ui/page/sm/utilization/list/UtilizationCompareMachineGroupsChart'
import { UtilizationDailyUsageChart } from 'src/ui/page/sm/utilization/list/UtilizationDailyUsageChart'
import { UtilizationTab } from 'src/ui/page/sm/utilization/list/UtilizationTab'

export const UtilizationListPage = (): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  const navigate = useNavigate()
  const appId = useAppId()
  const { tabNameParam } = useParams()
  const tabName = tabNameParam ? getEnumFromString(tabNameParam, UtilizationTab) : UtilizationTab.DAILY_USAGE

  const [activeTab, setActiveTab] = useState<UtilizationTab>(UtilizationTab.DAILY_USAGE)

  useEffect(() => {
    setActiveTab(tabName)
  }, [])

  // handle events
  const handleTabChange = (event: React.SyntheticEvent, newValue: UtilizationTab) => {
    const pathToNavigate = `/${appId}/utilization/view/${newValue}`
    navigate(pathToNavigate, { replace: true })
    setActiveTab(newValue)
  }

  return (
    <ScreenLayout title={translate('utilization')} actionsWidth={170} actions={<></>}>
      <Paper elevation={0}>
        <Grid container className={sharedClasses.TabsContainer}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <StyledTabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={handleTabChange}
            >
              <StyledTab
                icon={<ViewTimelineOutlined />}
                iconPosition={'start'}
                value={UtilizationTab.DAILY_USAGE}
                label={<Hidden mdDown>{translate('dailyUsage')}</Hidden>}
              />
              <StyledTab
                icon={<InsertChartOutlinedIcon />}
                iconPosition={'start'}
                value={UtilizationTab.COMPARE_MACHINE_GROUPS}
                label={<Hidden mdDown>{translate('compareMachineGroups')}</Hidden>}
              />
            </StyledTabs>
          </Grid>
        </Grid>
        <Divider />
        <TabPanel value={activeTab} index={UtilizationTab.DAILY_USAGE}>
          <UtilizationDailyUsageChart />
        </TabPanel>
        <TabPanel value={activeTab} index={UtilizationTab.COMPARE_MACHINE_GROUPS}>
          <UtilizationCompareMachineGroupsChart />
        </TabPanel>
      </Paper>
    </ScreenLayout>
  )
}
