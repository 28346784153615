import React, { ReactElement } from 'react'
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  Validate,
} from 'react-hook-form'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  getActiveValueLabel,
  getDefaultValueLabel,
  getHintOrError,
} from 'src/ui-shared/base/form/control-hook-form/HookForm'
import { StyledTextFieldExtraSmall } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

interface NumberTextFieldHookFormProps<T extends FieldValues, FieldPropertyName extends Path<T>> {
  name: FieldPropertyName
  control: Control<T, any>
  label: string
  activeValue?: number
  defaultValue?: number
  decimal?: boolean
  validate?:
    | Validate<PathValue<T, FieldPropertyName> | string | null, T>
    | Record<string, Validate<PathValue<T, FieldPropertyName> | string | null, T>>
    | undefined
  disabled?: boolean
  smallTextField?: boolean
  hintText?: string
  onChangeEventListener?: (field: ControllerRenderProps<T, FieldPropertyName>, fieldState: ControllerFieldState) => void
}

export const NumberTextFieldHookForm = <T extends FieldValues, FieldPropertyName extends Path<T>>({
  control,
  name,
  label,
  activeValue,
  defaultValue,
  validate,
  disabled,
  decimal = false,
  smallTextField = false,
  hintText,
  onChangeEventListener,
}: NumberTextFieldHookFormProps<T, FieldPropertyName>): ReactElement => {
  const translate = useTranslate()
  const isNumberRegex = /^-?\d+\.?\d*$/
  const numberWithDot = /^-?\d+\.$/

  const changeEventListener = (
    field: ControllerRenderProps<T, FieldPropertyName>,
    fieldState: ControllerFieldState,
  ) => {
    if (onChangeEventListener) {
      onChangeEventListener(field, fieldState)
    }
  }

  const handleOnChange = (
    value: string,
    field: ControllerRenderProps<T, FieldPropertyName>,
    fieldState: ControllerFieldState,
  ) => {
    const parseValueToNumber = decimal ? parseFloat(value) : parseInt(value)

    // if value is empty string return null
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (value === '' || value === undefined || value === null) {
      field.onChange(null)
      changeEventListener(field, fieldState)
      return
    }

    // if decimal and the value is x. where x is number, let the user continue typing to x.x
    if (decimal && numberWithDot.test(value)) {
      field.onChange(value)

      changeEventListener(field, fieldState)
      return
    }

    // returns -0 as string so the user can continue typing to -0.23 (parseInt(-0) return 0)
    if (value === '-0') {
      field.onChange(value)

      changeEventListener(field, fieldState)
      return
    }

    // if value is number pass the parsed number
    if (isNumberRegex.test(value)) {
      field.onChange(parseValueToNumber)

      changeEventListener(field, fieldState)
      return
    }

    field.onChange(value)

    changeEventListener(field, fieldState)
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: validate }}
      render={({ field, fieldState }) => {
        const hint = getHintOrError(fieldState, translate, hintText)
        const errorState = !!fieldState.error

        const value = field.value

        let labelToUse = label
        if (activeValue !== undefined && activeValue !== value) {
          labelToUse = getActiveValueLabel(translate, label, activeValue)
        }

        if (defaultValue !== undefined && defaultValue !== value) {
          labelToUse += getDefaultValueLabel(translate, label, defaultValue)
        }
        return (
          <>
            {smallTextField ? (
              <StyledTextFieldExtraSmall
                label={labelToUse}
                {...field}
                value={value}
                disabled={disabled}
                onChange={(event) => handleOnChange(event.target.value, field, fieldState)}
                helperText={hint}
                error={errorState}
              />
            ) : (
              <TextFieldDefault
                label={labelToUse}
                {...field}
                value={value}
                disabled={disabled}
                onChange={(event) => handleOnChange(event.target.value, field, fieldState)}
                variant="filled"
                helperText={hint}
                error={errorState}
              />
            )}
          </>
        )
      }}
    />
  )
}
