import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Box, ListItem, ListItemButton, ListItemText, styled } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { useIsIntEnvironment, useIsProdEnvironment } from 'src/env/EnvironmentContext'
import { useTranslate } from 'src/i18n/useMessageSource'
import { AboutUsDialog } from 'src/ui/layout/about-us-dialog/AboutUsDialog'
import { MenuContent } from 'src/ui/layout/appbar-menu/MenuContent'
import { StyledDivider, StyledMenuItem } from 'src/ui/layout/appbar-menu/MenuStyle'
import { hasAnyApp } from 'src/user/RoleCheck'
import { getUserLanguageForLinks } from 'src/user/SupportedLanguage'
import { useUser } from 'src/user/UserContext'

const SectionLabel = styled(ListItem)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(1),
  '& .MuiTypography-root ': {
    fontWeight: 600,
  },
}))

export const HelpMenu: React.FC = () => {
  const translate = useTranslate()
  const user = useUser()
  const navigate = useNavigate()
  const appId = useAppId()
  const isProdEnvironment = useIsProdEnvironment()
  const isIntEnvironment = useIsIntEnvironment()

  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null)
  const [aboutDialogOpen, setAboutDialogOpen] = useState<boolean>(false)

  const open = Boolean(anchorEl)

  const openMenu = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const language = getUserLanguageForLinks(user.locale, true, false)

  const faqUrl = isProdEnvironment
    ? `https://help.smart.schulthess.ch/${appId}/${language}/`
    : isIntEnvironment
    ? `https://help.int.smart.schulthess.ch/${appId}/${language}/`
    : `https://help.dev.smart.schulthess.ch/${appId}/${language}/`

  const faqWasherUrl = `https://www.schulthess.ch/${language}/service/faq/`
  const faqDryerUrl = `https://www.schulthess.ch/${language}/service/faq/dryer`

  const tipsUrl = `https://www.schulthess.ch/${language}/tips`

  const navigateToOnboardingTutorial = () => {
    closeMenu()
    navigate({
      pathname: `/${appId}`,
      search: `?onboarding=true`,
    })
  }

  const navigateToFeedbackForm = () => {
    closeMenu()
    navigate({
      pathname: `/${appId}`,
      search: `?feedback=true`,
    })
  }

  const termsUrl = `https://www.schulthess.ch/${language}/washmaster/B2Bterms`
  const privacyPolicyUrl = `https://www.schulthess.ch/${language}/disclaimer/`

  const showAboutUsDialog = () => {
    closeMenu()
    setAboutDialogOpen(true)
  }

  return (
    <>
      <StyledMenuItem active={open ? 1 : 0} onClick={openMenu}>
        <HelpOutline />
      </StyledMenuItem>
      <MenuContent open={open} anchorEl={anchorEl} handleClose={closeMenu}>
        <Box p={2} width={'280px'}>
          {hasAnyApp(user) ? (
            <Box>
              <SectionLabel>
                <ListItemText>{translate('infoCenter')}</ListItemText>
              </SectionLabel>

              {appId === AppId.SERVICE_MASTER ? (
                <ListItemButton component="a" href={faqUrl} target={'_blank'}>
                  {translate('faqServicemaster')}
                </ListItemButton>
              ) : appId === AppId.EASY_SET ? (
                <ListItemButton component="a" href={faqUrl} target={'_blank'}>
                  {translate('faqEasyset')}
                </ListItemButton>
              ) : (
                <ListItemButton component="a" href={faqUrl} target={'_blank'}>
                  {translate('faqWashmaster')}
                </ListItemButton>
              )}

              <ListItemButton component="a" href={faqWasherUrl} target={'_blank'}>
                {translate('faqSmagWasher')}
              </ListItemButton>

              <ListItemButton component="a" href={faqDryerUrl} target={'_blank'}>
                {translate('faqSmagDryer')}
              </ListItemButton>

              <ListItemButton component="a" href={tipsUrl} target={'_blank'}>
                {translate('tips')}
              </ListItemButton>
              <ListItemButton component="a" onClick={navigateToOnboardingTutorial}>
                {translate('onboardingTutorial')}
              </ListItemButton>
              <ListItemButton component="a" onClick={navigateToFeedbackForm}>
                {translate('sendFeedback')}
              </ListItemButton>
              <StyledDivider />
            </Box>
          ) : null}
          <SectionLabel>
            <ListItemText>{translate('legal')}</ListItemText>
          </SectionLabel>

          <ListItemButton component="a" href={termsUrl} target={'_blank'}>
            {translate('termsAndConditions')}
          </ListItemButton>

          <ListItemButton component="a" href={privacyPolicyUrl} target={'_blank'}>
            {translate('privacyPolicy')}
          </ListItemButton>

          <ListItemButton component="a" onClick={showAboutUsDialog}>
            {translate('about')}
          </ListItemButton>
        </Box>
      </MenuContent>

      {aboutDialogOpen ? (
        <AboutUsDialog aboutDialogOpen={aboutDialogOpen} setAboutDialogOpen={setAboutDialogOpen} />
      ) : null}
    </>
  )
}
