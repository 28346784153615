import React, { ReactElement, useMemo } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { Box, FormHelperText } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { AsyncAutoComplete, AsyncAutocompleteProps } from 'src/ui-shared/base/form/control/AsyncAutoComplete'

interface AsyncAutoCompletePropsField<T> extends AsyncAutocompleteProps<T> {
  validate?: any
}
type ExposeAsyncAutoCompleteFieldProps<T> = Omit<AsyncAutoCompletePropsField<T>, 'onChange'>

const CustomAutoComplete = <T,>(props: FieldRenderProps<T>): ReactElement => {
  const {
    input: { name, value, onChange: onChangeFinalForm },
    meta,
    ...rest
  } = props

  const { children, render, ...autoCompleteProps } = rest

  const { touched, error: errorObject } = meta

  const translate = useTranslate()

  const error = errorObject && translate(errorObject.errorKey, errorObject.params)

  const invalid = Boolean(touched && error)

  const onChangeMemo = useMemo<(optionValue: T) => void>(
    () =>
      (optionValue: T): void => {
        onChangeFinalForm(optionValue)
      },
    [onChangeFinalForm],
  )

  const finalAutoCompleteProps = {
    ...autoCompleteProps,
    onChange: onChangeMemo,
    name: name,
    error: invalid,
    value,
  } as AsyncAutocompleteProps<T>

  return (
    <Box>
      <AsyncAutoComplete {...finalAutoCompleteProps} />
      {invalid && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}

export const AsyncAutoCompleteValidate = <T,>(props: ExposeAsyncAutoCompleteFieldProps<T>): ReactElement => (
  <Field component={CustomAutoComplete} {...props} />
)
