import React from 'react'
import { Chip } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

export interface MultipleChoiceAutoCompleteProps {
  onChange: (option: any) => void
  options: any[]
  name: string
  value: any[]
  disabled: boolean
  error?: boolean
  label: string
  labelFieldName: string
}

export const MultipleChoiceAutoComplete: React.FC<MultipleChoiceAutoCompleteProps> = ({
  name,
  disabled,
  options,
  onChange,
  value,
  error,
  label,
  labelFieldName,
}) => {
  const onHandleChange = (event: React.SyntheticEvent, object: Record<string, any> | null) => {
    onChange(object)
  }

  return (
    <Autocomplete
      openOnFocus={true}
      onChange={onHandleChange}
      value={value}
      multiple
      getOptionLabel={(option) => option[labelFieldName] || ''}
      options={options}
      disabled={disabled}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option[labelFieldName]} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => <TextFieldDefault name={name} error={error} {...params} label={label} />}
    />
  )
}
