/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineTag
 */
export interface MachineTag {
    /**
     * 
     * @type {string}
     * @memberof MachineTag
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MachineTag
     */
    contentUrl: string;
}

/**
 * Check if a given object implements the MachineTag interface.
 */
export function instanceOfMachineTag(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "contentUrl" in value;

    return isInstance;
}

export function MachineTagFromJSON(json: any): MachineTag {
    return MachineTagFromJSONTyped(json, false);
}

export function MachineTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'contentUrl': json['contentUrl'],
    };
}

export function MachineTagToJSON(value?: MachineTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contentUrl': value.contentUrl,
    };
}

