/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettingBooleanField
 */
export interface SettingBooleanField {
    /**
     * 
     * @type {boolean}
     * @memberof SettingBooleanField
     */
    readonly activeData?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingBooleanField
     */
    data?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingBooleanField
     */
    readonly factoryDefaultData?: boolean;
    /**
     * This text is shown when GenericField.showInOverview is true and data is true
     * @type {string}
     * @memberof SettingBooleanField
     */
    overviewLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingBooleanField
     */
    showGroup?: string;
    /**
     * List of groups which can be hidden
     * @type {Array<string>}
     * @memberof SettingBooleanField
     */
    hideGroups?: Array<string>;
}

/**
 * Check if a given object implements the SettingBooleanField interface.
 */
export function instanceOfSettingBooleanField(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SettingBooleanFieldFromJSON(json: any): SettingBooleanField {
    return SettingBooleanFieldFromJSONTyped(json, false);
}

export function SettingBooleanFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingBooleanField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeData': !exists(json, 'activeData') ? undefined : json['activeData'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'factoryDefaultData': !exists(json, 'factoryDefaultData') ? undefined : json['factoryDefaultData'],
        'overviewLabel': !exists(json, 'overviewLabel') ? undefined : json['overviewLabel'],
        'showGroup': !exists(json, 'showGroup') ? undefined : json['showGroup'],
        'hideGroups': !exists(json, 'hideGroups') ? undefined : json['hideGroups'],
    };
}

export function SettingBooleanFieldToJSON(value?: SettingBooleanField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'overviewLabel': value.overviewLabel,
        'showGroup': value.showGroup,
        'hideGroups': value.hideGroups,
    };
}

