import React, { FC, ReactElement, SetStateAction, useContext, useEffect, useState } from 'react'
import { Paper, TableCell, TableRow } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, Machine, ProgramInstalled, ProgramType, ProgramsMachineApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocaleOptional } from 'src/service/utils/DateFormatUtils'
import { formatTemperatureOptional } from 'src/service/utils/NumberFormatUtils'
import { Data } from 'src/service/view-model/base/Data'
import { getProgramTypeName } from 'src/service/view-model/program/ProgramViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DataTable } from 'src/ui-shared/table/DataTable'
import { DEFAULT_DATA, TableData, tableSettingsSort } from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { ProgramInstalledTableSettings } from 'src/ui/page/common/machine/details/MachineProgramTab'
import { PROGRAM_TABLE_HEAD_CELLS } from 'src/ui/page/es/program/list/ProgramTable'
import { useUserRegionLocale } from 'src/user/UserContext'

const headCells = PROGRAM_TABLE_HEAD_CELLS

interface Props {
  machine: Machine
  tableSettings: ProgramInstalledTableSettings
  setTableSettings: React.Dispatch<SetStateAction<ProgramInstalledTableSettings>>
  navigateToItem: (programId?: string, programType?: ProgramType) => void
}

export const MachineProgramTable: FC<Props> = ({
  machine,
  tableSettings,
  setTableSettings,
  navigateToItem,
}): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const translate = useTranslate()
  const regionLocale = useUserRegionLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programsMachineApi = new ProgramsMachineApi(httpConfiguration)

  const [data, setData] = useState<TableData<ProgramInstalled>>(DEFAULT_DATA)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)

  // load data
  useEffect(() => {
    let active = true

    setLoading(true)

    programsMachineApi
      .machinesMachineIdProgramsGet(
        machine.id,
        tableSettings.size,
        tableSettings.page,
        tableSettingsSort(tableSettings),
        tableSettings.search,
        tableSettings.programType,
      )
      .then((data) => {
        if (active) {
          updateTableSettingsFromData(data as Data<any>, tableSettings)
          setTableSettings(tableSettings)

          setData(mapData(data as Data<any>))
          setErrorMessage(null)
          setLoading(false)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [tableSettings])

  // JSX

  const getFilter = (_headCellId: string) => {
    // TODO PST, 2023-08-22: enable program filters (name, temp, etc) when API implements them
    // return getProgramTableFilters(headCellId, translate, tableSettings, setTableSettings)
    return undefined
  }

  const nonEmptyRows = data.data.map((item) => {
    const programMetadata = item.program.metaData
    return (
      <TableRow
        className={tableClasses.tableRow}
        key={programMetadata.id}
        onClick={navigateToItem.bind(this, programMetadata.id, programMetadata.programType)}
      >
        <TableCell width="7%">{programMetadata.programIndex}</TableCell>
        <TableCell width="7%">
          {formatTemperatureOptional(programMetadata.temperature, programMetadata.temperatureUnit)}
        </TableCell>
        <TableCell>{programMetadata.programName}</TableCell>
        <TableCell>{programMetadata.programMode}</TableCell>
        <TableCell>{getProgramTypeName(programMetadata.programType, translate)}</TableCell>
        <TableCell width="15%">{formatDateTimeForLocaleOptional(programMetadata.lastModified, regionLocale)}</TableCell>
      </TableRow>
    )
  })

  return (
    <Paper elevation={0} sx={{ mt: 2 }}>
      <TableSearchForm handleSearch={handleSearch} inputSearchValue={inputSearchValue} />

      {errorMessage && <ErrorMessage message={errorMessage} />}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        getFilter={getFilter}
        loading={loading}
        translate={translate}
      />
    </Paper>
  )
}
