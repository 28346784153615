import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import Button from '@mui/material/Button'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import {
  Configuration,
  IssueAction,
  IssueCreatorType,
  IssueEntry,
  IssueStateData,
  IssuesApi,
  Permission,
  ServiceType,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  formatDateForLocaleOptional,
  formatDateTimeForLocale,
  formatDateTimeForLocaleOptional,
  formatTime,
} from 'src/service/utils/DateFormatUtils'
import { getErrorSeverityName } from 'src/service/view-model/error-log/ErrorLogViewModel'
import { getMachineNameAndSerialNumber } from 'src/service/view-model/machine/Machines'
import { isOrganizationTypeServicePartner } from 'src/service/view-model/organization/Organizations'
import {
  TIME_SLOT_EVENING,
  TIME_SLOT_MORNING,
  TIME_SLOT_PERIOD,
  getIssueCreatorTypeLabel,
  getTimeSlotPeriodLabel,
} from 'src/service/view-model/service-request/ServiceRequestViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { EMPTY_UUID } from 'src/ui-shared/constants/Constants'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ErrorSeverityIcon } from 'src/ui-shared/error-severity/ErrorSeverityIcon'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryGroupTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupTab'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'
import { LaundryUserDetailsTab } from 'src/ui/page/wm/laundry-user/details/LaundryUserDetailsTab'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser, useUserRegionLocale } from 'src/user/UserContext'

export const ServiceRequestDetailsPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()

  const user = useUser()
  const { serviceRequestId } = useRequiredParams(['serviceRequestId'])
  const appId = useAppId()
  const navigate = useNavigate()
  const location = useLocation()
  const regionLocale = useUserRegionLocale()
  const showSnackbar = useShowSnackbar()

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack

  const theme = useTheme()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const issuesApi = new IssuesApi(httpConfiguration)

  // state
  const [serviceRequest, setServiceRequest] = useState<IssueEntry | null>(null)
  const [actionModal, setActionModal] = useState<boolean>(false)
  const [actionToBeMade, setActionToBeMade] = useState<IssueAction | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  // derived state
  const issueCreator = serviceRequest?.issueCreator
  const issueCreatorName = issueCreator ? `${issueCreator.firstName} ${issueCreator.lastName}` : ''
  const isLaundryUserCreator = serviceRequest?.issueCreatorType === IssueCreatorType.LAUNDRY_USER

  useHotKeysForm()

  // load data
  useEffect(() => {
    setLoading(true)
    issuesApi
      .issuesIssueIdGet(serviceRequestId)
      .then((data) => {
        setServiceRequest(data)
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLoading(false)
      })
  }, [serviceRequestId])

  // events
  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/service-request`)
  }

  const onActionSubmitHandler = (): void => {
    if (actionToBeMade && serviceRequest) {
      issuesApi
        .issuesIssueIdActionKeyPut(serviceRequestId, actionToBeMade.key, serviceRequest)
        .then(() => {
          navigateBack()
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
        })
    }
    setActionModal(false)
  }

  const openActionModal = (action: IssueAction) => {
    setActionModal(true)
    setActionToBeMade(action)
  }

  // render
  const checkSelectedTimeSlot = (timeSlotPeriod: TIME_SLOT_PERIOD): boolean => {
    let timeFrom = ''
    let timeTo = ''

    if (serviceRequest?.callbackTimeSlotFrom && serviceRequest.callbackTimeSlotTo) {
      timeFrom = formatTime(serviceRequest.callbackTimeSlotFrom)
      timeTo = formatTime(serviceRequest.callbackTimeSlotTo)
    }
    const timeSlot = `${timeFrom} - ${timeTo}`
    return timeSlotPeriod === timeSlot
  }

  const getStatusChip = (stateData?: IssueStateData): ReactElement | null => {
    let chip: ReactElement | null = null
    const bgColor = stateData?.color ? stateData.color : theme.palette.primary.main
    if (stateData) {
      chip = (
        <Chip sx={{ backgroundColor: bgColor, color: theme.palette.getContrastText(bgColor) }} label={stateData.text} />
      )
    }
    return chip
  }

  const renderTitle = (): string => {
    let title = ''
    if (serviceRequest?.ticketKey) {
      title = translate('serviceRequestDetails', serviceRequest.ticketKey)
    }
    return title
  }

  const displayActionButtons = serviceRequest?.actions.map((action) => {
    return (
      <Button
        key={action.key}
        variant="contained"
        color="primary"
        size="large"
        sx={{ ml: 2, mb: 1 }}
        onClick={() => openActionModal(action)}
      >
        {action.name}
      </Button>
    )
  })

  const serviceRequestActions = hasPermission(user, Permission.SERVICE_REQUEST_WRITE) ? displayActionButtons : <></>

  return (
    <ScreenLayout title={renderTitle()} onBack={navigateBack} actionsWidth={50}>
      <Paper elevation={0}>
        <LoadingIndicator loading={loading} />
        <Divider />
        {errorMessage && <ErrorMessage message={errorMessage} />}
        {serviceRequest && (
          <Box pt={2}>
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  value={formatDateTimeForLocale(serviceRequest.creationTimestamp, regionLocale)}
                  label={translate('serviceRequest.created')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  value={formatDateTimeForLocaleOptional(serviceRequest.closingTimestamp, regionLocale)}
                  label={translate('serviceRequest.closing')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  value={getErrorSeverityName(serviceRequest.errorSeverity, translate)}
                  label={translate('error.severity')}
                  inputProps={{
                    startAdornment: (
                      <Box mt={3} mr={1}>
                        <ErrorSeverityIcon errorSeverity={serviceRequest.errorSeverity} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('status')}
                  inputProps={{
                    startAdornment: <Box mt={3}>{getStatusChip(serviceRequest.issueState)}</Box>,
                  }}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('issueCreatorType')}
                  value={getIssueCreatorTypeLabel(serviceRequest.issueCreatorType, translate)}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('issueCreator')}
                  value={issueCreatorName}
                  link={
                    isLaundryUserCreator
                      ? `/${AppId.WASH_MASTER}/laundry-users/${issueCreator?.id}/view/${LaundryUserDetailsTab.OVERVIEW}`
                      : ''
                  }
                />
              </Grid>
            </Grid>
            <Divider className={sharedClasses.Divider} />
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item {...ITEM_BREAKPOINTS} md={12}>
                <FormControl component="fieldset" disabled fullWidth>
                  <FormLabel component="legend">
                    <Typography variant="subtitle2">{translate('serviceType')}</Typography>
                  </FormLabel>
                  <Stack direction="row" justifyContent="space-between" spacing={1} paddingTop={1} paddingRight={2}>
                    <FormControlLabel
                      control={<Radio checked={serviceRequest.serviceType === ServiceType.CALLBACK} />}
                      label={translate('serviceType.callback')}
                    />
                    <FormControlLabel
                      control={<Radio checked={serviceRequest.serviceType === ServiceType.ONSITE} />}
                      label={translate('serviceType.onsite')}
                    />
                    <FormControlLabel
                      control={<Radio checked={serviceRequest.serviceType === ServiceType.MACHINE_ERROR} />}
                      label={translate('serviceType.machineError')}
                    />
                  </Stack>
                </FormControl>
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={3}>
                <DetailsTextField value={serviceRequest.contactName} label={translate('serviceRequest.contact')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={3}>
                <DetailsTextField
                  value={serviceRequest.contactPhoneNumber}
                  label={translate('serviceRequest.contactNmb')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                <DetailsTextField
                  value={serviceRequest.category?.name}
                  label={translate('serviceRequest.issueCategory')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                <DetailsTextField
                  multiline={true}
                  value={serviceRequest.description}
                  label={translate('serviceRequest.description')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={3}>
                <DetailsTextField
                  value={formatDateForLocaleOptional(serviceRequest.callbackTimeSlotFrom, regionLocale)}
                  label={translate('serviceRequest.callbackDate')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={4}>
                <FormControl component="fieldset" disabled fullWidth>
                  <FormLabel component="legend">
                    <Typography variant="subtitle2" paddingLeft={1}>
                      {translate('serviceRequest.callbackTime')}
                    </Typography>
                  </FormLabel>
                  <Stack direction="row" spacing={2} paddingLeft={1} paddingTop={1}>
                    <FormControlLabel
                      control={<Radio checked={checkSelectedTimeSlot(TIME_SLOT_MORNING)} />}
                      label={getTimeSlotPeriodLabel(TIME_SLOT_MORNING, regionLocale)}
                    />
                    <FormControlLabel
                      control={<Radio checked={checkSelectedTimeSlot(TIME_SLOT_EVENING)} />}
                      label={getTimeSlotPeriodLabel(TIME_SLOT_EVENING, regionLocale)}
                    />
                  </Stack>
                </FormControl>
              </Grid>
            </Grid>

            <Divider className={sharedClasses.Divider} />
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('organization')}
                  value={serviceRequest.organization?.name}
                  link={
                    isOrganizationTypeServicePartner(serviceRequest.organization?.type)
                      ? `/${appId}/service-partners/${serviceRequest.organization?.id}/view`
                      : `/${appId}/organizations/${serviceRequest.organization?.id}/view`
                  }
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('laundryGroup')}
                  value={serviceRequest.laundryGroup?.name}
                  link={`/${appId}/laundry-groups/${serviceRequest.laundryGroup?.id}/view/${LaundryGroupTab.OVERVIEW}`}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('laundry')}
                  value={serviceRequest.laundry?.name}
                  link={`/${appId}/laundries/${serviceRequest.laundry?.id}/view`}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('machine') + ' / ' + translate('serialNumber')}
                  value={getMachineNameAndSerialNumber(serviceRequest.machine)}
                  link={
                    serviceRequest.machine.id !== EMPTY_UUID
                      ? `/${appId}/machines/${serviceRequest.machine.id}/view/${MachineTab.OVERVIEW}`
                      : undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container display={'flex'} justifyContent={'flex-end'}>
              {serviceRequestActions}
            </Grid>
          </Box>
        )}
      </Paper>
      <ConfirmationModalDialog
        titleKey={actionToBeMade ? actionToBeMade.name : ''}
        titleTranslated
        confirmationKey={actionToBeMade ? actionToBeMade.name : ''}
        confirmationTranslated
        open={actionModal}
        onConfirm={onActionSubmitHandler}
        onCancel={() => setActionModal(false)}
      >
        {translate('serviceRequestActionConfirm')}
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
