import React, { ReactElement, Suspense, useCallback, useContext, useEffect, useState } from 'react'
import { Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { DashboardTopLaundryGroupsWidget } from './DashboardTopLaundryGroupsWidget'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  ChartData,
  ChartType,
  Configuration,
  LaundryGroupReference,
  ReportWashMasterApi,
  UsageChartFactType,
  UsageChartGroupBy,
} from 'src/service/backend/api'
import { ReportWashMasterApiMock } from 'src/service/backend/api-mock/ReportWashMasterApiMock'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  EMPTY_CHART_DATA_PROMISE,
  NOT_READY_CHART_DATA_PROMISE,
  groupDatasetsForDoughnutChart,
} from 'src/service/view-model/base/chart/Chart.utils'
import {
  DEFAULT_USAGE_CHART_SETTINGS,
  UsageChartSettings,
} from 'src/service/view-model/usage-chart/UsageChartViewModel'
import { GridItemBreakpointsObject } from 'src/ui-shared/constants/GridItem.const'
import { LaundryAutocomplete } from 'src/ui-shared/form/control/LaundryAutocomplete'
import { DemoDataSwitch } from 'src/ui-shared/icons/DemoDataSwitch'
import { DashboardPeriodType, getFromToForPeriodTypeFromNow } from 'src/ui/page/wm/index/DashboardPeriodTypeUtils'

const AsyncChart = React.lazy(() => import('src/ui-shared/chart/Chart').then(({ Chart }) => ({ default: Chart })))

export interface DashboardUsageChartSettings extends Omit<UsageChartSettings, 'startDateFrom' | 'startDateTo'> {
  startDateFrom: Date | null
  startDateTo: Date | null
}

const DEFAULT_DASHBOARD_USAGE_CHART_SETTINGS: DashboardUsageChartSettings = {
  ...DEFAULT_USAGE_CHART_SETTINGS,
}

export const DashboardWm = (): ReactElement => {
  // NOTE mock data / demo data for dashboard is intentional
  const [useMockData, setUseMockData] = useState<boolean>(false)

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportsApi = useMockData ? new ReportWashMasterApiMock() : new ReportWashMasterApi(httpConfiguration)

  const translate = useTranslate()

  const activeOrganization = useActiveOrganization()

  const [usageChartSettings, setUsageChartSettings] = useState<DashboardUsageChartSettings>({
    ...DEFAULT_DASHBOARD_USAGE_CHART_SETTINGS,
    startDateFrom: null,
    startDateTo: null,
  })

  const [laundryUsageChartSettings, setLaundryUsageChartSettings] = useState<DashboardUsageChartSettings>({
    ...DEFAULT_DASHBOARD_USAGE_CHART_SETTINGS,
    startDateFrom: null,
    startDateTo: null,
  })

  // laundryGroup will be undefined during loading of all laundry groups, null if none selected after loading
  const [laundryGroup, setLaundryGroup] = useState<LaundryGroupReference | undefined | null>(undefined)
  const [periodType, setPeriodType] = useState<DashboardPeriodType>(DashboardPeriodType.MONTH)

  const USAGE_COUNT_BY_MACHINE_CATEGORY_CHART_SETTINGS: UsageChartSettings = {
    ...DEFAULT_USAGE_CHART_SETTINGS,
    groupBy: UsageChartGroupBy.MACHINE_CATEGORY,
    factType: UsageChartFactType.COUNT,
  }

  const USAGE_AMOUNT_SUM_TOTAL_CHART_SETTINGS: UsageChartSettings = {
    ...DEFAULT_USAGE_CHART_SETTINGS,
    groupBy: UsageChartGroupBy.TOTAL,
    factType: UsageChartFactType.AMOUNT_SUM,
  }

  const USAGE_AMOUNT_BY_LAUNDRY_CHART_SETTINGS: UsageChartSettings = {
    ...DEFAULT_USAGE_CHART_SETTINGS,
    groupBy: UsageChartGroupBy.LAUNDRY,
    factType: UsageChartFactType.AMOUNT_SUM,
  }

  const loadChartCount = useCallback((): Promise<ChartData> => {
    if (!usageChartSettings.startDateFrom || !usageChartSettings.startDateTo) {
      return NOT_READY_CHART_DATA_PROMISE
    }

    const dateStartTimeFrom = new Date(usageChartSettings.startDateFrom)
    const dateStartTimeTo = new Date(usageChartSettings.startDateTo)

    const usageChartSettingsCount = USAGE_COUNT_BY_MACHINE_CATEGORY_CHART_SETTINGS

    return reportsApi.reportWmUsagesChartGet(
      usageChartSettingsCount.groupBy,
      usageChartSettingsCount.factType,
      usageChartSettings.chartScale,
      dateStartTimeFrom,
      dateStartTimeTo,
      undefined,
      activeOrganization?.id,
      undefined,
      usageChartSettings.laundryId,
      usageChartSettings.machineId,
    )
  }, [usageChartSettings, activeOrganization])

  const loadChartAmountTotal = useCallback((): Promise<ChartData> => {
    if (!usageChartSettings.startDateFrom || !usageChartSettings.startDateTo) {
      return NOT_READY_CHART_DATA_PROMISE
    }

    const dateStartTimeFrom = new Date(usageChartSettings.startDateFrom)
    const dateStartTimeTo = new Date(usageChartSettings.startDateTo)

    const usageChartSettingsAmount = USAGE_AMOUNT_SUM_TOTAL_CHART_SETTINGS

    return reportsApi.reportWmUsagesChartGet(
      usageChartSettingsAmount.groupBy,
      usageChartSettingsAmount.factType,
      usageChartSettings.chartScale,
      dateStartTimeFrom,
      dateStartTimeTo,
      undefined,
      activeOrganization?.id,
      undefined,
      usageChartSettings.laundryId,
      usageChartSettings.machineId,
    )
  }, [usageChartSettings, activeOrganization])

  const loadChartAmountByLaundryGroup = useCallback((): Promise<ChartData> => {
    if (laundryGroup === undefined) {
      // laundry groups still loading
      // do not resolve until there is laundryGroup
      return NOT_READY_CHART_DATA_PROMISE
    }

    if (laundryGroup === null) {
      // laundry groups finished loading but none selected
      return EMPTY_CHART_DATA_PROMISE
    }

    if (!laundryUsageChartSettings.startDateFrom || !laundryUsageChartSettings.startDateTo) {
      return NOT_READY_CHART_DATA_PROMISE
    }

    const dateStartTimeFrom = new Date(laundryUsageChartSettings.startDateFrom)
    const dateStartTimeTo = new Date(laundryUsageChartSettings.startDateTo)

    const usageChartSettingsAmountByLanudryGroup = USAGE_AMOUNT_BY_LAUNDRY_CHART_SETTINGS

    const chartType: ChartType = ChartType.DOUGHNUT

    return reportsApi
      .reportWmUsagesChartGet(
        usageChartSettingsAmountByLanudryGroup.groupBy,
        usageChartSettingsAmountByLanudryGroup.factType,
        laundryUsageChartSettings.chartScale,
        dateStartTimeFrom,
        dateStartTimeTo,
        chartType,
        activeOrganization?.id,
        laundryUsageChartSettings.laundryGroupId,
        laundryUsageChartSettings.laundryId,
        laundryUsageChartSettings.machineId,
      )
      .then((data) => {
        return groupDatasetsForDoughnutChart(data)
      })
  }, [laundryUsageChartSettings, activeOrganization])

  const DASHBOARD_ITEMS_BREAKPOINTS: GridItemBreakpointsObject = {
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
  }

  const handleDashboardPeriodTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newPeriodType: DashboardPeriodType | null,
  ) => {
    if (newPeriodType !== null) {
      setPeriodType(newPeriodType)
    }
  }

  // update start and end date when period changes
  useEffect(() => {
    const dates = getFromToForPeriodTypeFromNow(periodType)
    const updater = (prev: DashboardUsageChartSettings): DashboardUsageChartSettings => {
      return {
        ...prev,
        startDateFrom: dates[0],
        startDateTo: dates[1],
      }
    }
    setUsageChartSettings(updater)
    setLaundryUsageChartSettings(updater)
  }, [periodType])

  // update laundryGroupId in usage settings when selected laundry group changes
  useEffect(() => {
    setLaundryUsageChartSettings((prev) => ({ ...prev, laundryGroupId: laundryGroup?.id }))
  }, [laundryGroup])

  const CHART_HEIGHT = 250
  return (
    <>
      <Suspense fallback={<div style={{ textAlign: 'center' }}>{translate('autocompleteLoading')}</div>}>
        <Grid container spacing={3} pt={1}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h2">{translate('appDashboard', 'washMaster')}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} display={'flex'} justifyContent="flex-end" alignItems="flex-end">
            <DemoDataSwitch useMockData={useMockData} setUseMockData={setUseMockData} />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            display={'flex'}
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ paddingTop: '10px !important' }}
          >
            <ToggleButtonGroup value={periodType} onChange={handleDashboardPeriodTypeChange} exclusive size="small">
              <ToggleButton value={DashboardPeriodType.MONTH}>{translate('lastMonth')}</ToggleButton>
              <ToggleButton value={DashboardPeriodType.HALF_YEAR}>{translate('lastNmonths', 6)}</ToggleButton>
              <ToggleButton value={DashboardPeriodType.YEAR}>{translate('lastYear')}</ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Grid item {...DASHBOARD_ITEMS_BREAKPOINTS} mt={1}>
            <Typography variant="h4" align="center">
              {translate('usageByMachineType')}
            </Typography>
            <AsyncChart
              loadChart={loadChartCount}
              chartSettings={usageChartSettings}
              hideTitle
              heightOverride={CHART_HEIGHT}
            />
          </Grid>
          <Grid item {...DASHBOARD_ITEMS_BREAKPOINTS} mt={1}>
            <Typography variant="h4" align="center">
              {translate('totalAmount')}
            </Typography>

            <AsyncChart
              loadChart={loadChartAmountTotal}
              chartSettings={usageChartSettings}
              hideTitle
              heightOverride={CHART_HEIGHT}
            />
          </Grid>

          <Grid item {...DASHBOARD_ITEMS_BREAKPOINTS} mt={1}>
            <Typography variant="h4" mb={2} align="center">
              {translate('amountByLaundry')}
            </Typography>
            <LaundryAutocomplete
              laundryGroup={laundryGroup}
              setLaundryGroup={setLaundryGroup}
              preselectFirstLaundryGroup
              limitToActiveOrganization
            />
            <AsyncChart
              loadChart={loadChartAmountByLaundryGroup}
              chartSettings={laundryUsageChartSettings}
              hideTitle
              heightOverride={CHART_HEIGHT}
            />
          </Grid>
          <Grid item {...DASHBOARD_ITEMS_BREAKPOINTS} mt={1}>
            <DashboardTopLaundryGroupsWidget
              useMockData={useMockData}
              // fromDate={new Date(laundryUsageChartSettings.startDateFrom)}
              // toDate={new Date(laundryUsageChartSettings.startDateTo)}
              dashboardPeriodType={periodType}
              limitToActiveOrganization={true}
            />
          </Grid>
        </Grid>
      </Suspense>
    </>
  )
}
