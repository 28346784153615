/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DoorStatusType {
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    OPENED = 'OPENED',
    CLOSED_UNLOCKED = 'CLOSED_UNLOCKED',
    CLOSED_LOCKED = 'CLOSED_LOCKED'
}


export function DoorStatusTypeFromJSON(json: any): DoorStatusType {
    return DoorStatusTypeFromJSONTyped(json, false);
}

export function DoorStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoorStatusType {
    return json as DoorStatusType;
}

export function DoorStatusTypeToJSON(value?: DoorStatusType | null): any {
    return value as any;
}

