import React, { FC, ReactElement } from 'react'
import { Breakpoint, Button, ButtonProps, Tooltip, Typography, styled, useMediaQuery, useTheme } from '@mui/material'

export const ListingButton = styled(Button)(() => ({
  marginLeft: 8,
  marginRight: 8,
  fontWeight: 600,
}))

export interface ListingButtonProp extends ButtonProps {
  label: string
  icon: ReactElement
  smallScreenBreakpoint?: Breakpoint
  iconTooltip?: string
}

export const ListingButtonWithIcon: FC<ListingButtonProp> = ({
  label,
  icon,
  smallScreenBreakpoint = 'sm',
  iconTooltip,
  ...buttonProps
}): ReactElement => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down(smallScreenBreakpoint))
  const buttonMinWidth = (theme.components?.MuiButton?.styleOverrides?.root as any).minWidth

  return (
    <Tooltip title={smallScreen && <Typography variant="body2">{label}</Typography>} placement="bottom">
      <ListingButton
        variant="outlined"
        color="primary"
        startIcon={!smallScreen ? icon : null}
        {...buttonProps}
        sx={{
          minWidth: smallScreen ? '5rem' : buttonMinWidth,
          maxHeight: '3.2rem',
          lineHeight: '1rem',
          ...buttonProps.sx,
        }}
      >
        {!smallScreen ? label : icon}
      </ListingButton>
    </Tooltip>
  )
}
