import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { HygieneTable } from 'src/ui/page/sm/hygiene/list/HygieneTable'

export const HygieneListPage = (): ReactElement => {
  const navigate = useNavigate()
  const appId = useAppId()

  const translate = useTranslate()

  const handleRowClick = (hygieneId?: string) => {
    navigate(`/${appId}/hygiene/${hygieneId}/view`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  return (
    <ScreenLayout title={translate('hygiene')}>
      <HygieneTable navigateToItem={handleRowClick} />
    </ScreenLayout>
  )
}
