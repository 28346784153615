/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProgramModuleSettings } from './ProgramModuleSettings';
import {
    ProgramModuleSettingsFromJSON,
    ProgramModuleSettingsFromJSONTyped,
    ProgramModuleSettingsToJSON,
} from './ProgramModuleSettings';
import type { ProgramModuleTemplateAvailableTemplatesInner } from './ProgramModuleTemplateAvailableTemplatesInner';
import {
    ProgramModuleTemplateAvailableTemplatesInnerFromJSON,
    ProgramModuleTemplateAvailableTemplatesInnerFromJSONTyped,
    ProgramModuleTemplateAvailableTemplatesInnerToJSON,
} from './ProgramModuleTemplateAvailableTemplatesInner';

/**
 * 
 * @export
 * @interface ProgramModuleTemplate
 */
export interface ProgramModuleTemplate {
    /**
     * 
     * @type {Array<ProgramModuleSettings>}
     * @memberof ProgramModuleTemplate
     */
    availableModules?: Array<ProgramModuleSettings>;
    /**
     * 
     * @type {Array<ProgramModuleTemplateAvailableTemplatesInner>}
     * @memberof ProgramModuleTemplate
     */
    availableTemplates?: Array<ProgramModuleTemplateAvailableTemplatesInner>;
}

/**
 * Check if a given object implements the ProgramModuleTemplate interface.
 */
export function instanceOfProgramModuleTemplate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProgramModuleTemplateFromJSON(json: any): ProgramModuleTemplate {
    return ProgramModuleTemplateFromJSONTyped(json, false);
}

export function ProgramModuleTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramModuleTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableModules': !exists(json, 'availableModules') ? undefined : ((json['availableModules'] as Array<any>).map(ProgramModuleSettingsFromJSON)),
        'availableTemplates': !exists(json, 'availableTemplates') ? undefined : ((json['availableTemplates'] as Array<any>).map(ProgramModuleTemplateAvailableTemplatesInnerFromJSON)),
    };
}

export function ProgramModuleTemplateToJSON(value?: ProgramModuleTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'availableModules': value.availableModules === undefined ? undefined : ((value.availableModules as Array<any>).map(ProgramModuleSettingsToJSON)),
        'availableTemplates': value.availableTemplates === undefined ? undefined : ((value.availableTemplates as Array<any>).map(ProgramModuleTemplateAvailableTemplatesInnerToJSON)),
    };
}

