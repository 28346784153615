export interface TextLength {
  key: 'ZIP' | 'S' | 'M' | 'L' | 'XL' | 'newsTitle' | 'newsContent' | 'serviceRequestDescription' | 'programDescription'
  value: number
}

export type EditMode = 'view' | 'edit' | 'copy' | 'create'

export type ViewMode = 'modal' | 'page'

export type FileFormat = 'csv' | 'xlsx' | 'xls' | 'WPB'

export const EMPTY_UUID = '00000000-0000-0000-0000-000000000000'

export const TEXT_LENGTH = {
  ZIP: {
    key: 'ZIP',
    value: 10,
  },
  S: {
    key: 'S',
    value: 50,
  },
  M: {
    key: 'M',
    value: 256,
  },
  L: {
    key: 'L',
    value: 1000,
  },
  XL: {
    key: 'XL',
    value: 5000,
  },
  NEWS_TITLE: {
    key: 'newsTitle',
    value: 40,
  },
  NEWS_CONTENT: {
    key: 'newsContent',
    value: 1000,
  },
  SERVICE_REQUEST_DESCRIPTION: {
    key: 'serviceRequestDescription',
    value: 500,
  },
  PROGRAM_DESCRIPTION: {
    key: 'programDescription',
    value: 100,
  },
} as const
