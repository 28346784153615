/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IssueCategoryReference
 */
export interface IssueCategoryReference {
    /**
     * Fragment key for translation
     * @type {string}
     * @memberof IssueCategoryReference
     */
    key: string;
    /**
     * Name of the issue category
     * @type {string}
     * @memberof IssueCategoryReference
     */
    readonly name: string;
}

/**
 * Check if a given object implements the IssueCategoryReference interface.
 */
export function instanceOfIssueCategoryReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function IssueCategoryReferenceFromJSON(json: any): IssueCategoryReference {
    return IssueCategoryReferenceFromJSONTyped(json, false);
}

export function IssueCategoryReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueCategoryReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
    };
}

export function IssueCategoryReferenceToJSON(value?: IssueCategoryReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
    };
}

