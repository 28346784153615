/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaundryGroupSingleCalendarEntry } from './LaundryGroupSingleCalendarEntry';
import {
    LaundryGroupSingleCalendarEntryFromJSON,
    LaundryGroupSingleCalendarEntryFromJSONTyped,
    LaundryGroupSingleCalendarEntryToJSON,
} from './LaundryGroupSingleCalendarEntry';

/**
 * 
 * @export
 * @interface LaundryGroupSingleCalendarDay
 */
export interface LaundryGroupSingleCalendarDay {
    /**
     * 
     * @type {Date}
     * @memberof LaundryGroupSingleCalendarDay
     */
    startDate: Date;
    /**
     * 
     * @type {Array<LaundryGroupSingleCalendarEntry>}
     * @memberof LaundryGroupSingleCalendarDay
     */
    entries: Array<LaundryGroupSingleCalendarEntry>;
}

/**
 * Check if a given object implements the LaundryGroupSingleCalendarDay interface.
 */
export function instanceOfLaundryGroupSingleCalendarDay(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "entries" in value;

    return isInstance;
}

export function LaundryGroupSingleCalendarDayFromJSON(json: any): LaundryGroupSingleCalendarDay {
    return LaundryGroupSingleCalendarDayFromJSONTyped(json, false);
}

export function LaundryGroupSingleCalendarDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupSingleCalendarDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': (new Date(json['startDate'])),
        'entries': ((json['entries'] as Array<any>).map(LaundryGroupSingleCalendarEntryFromJSON)),
    };
}

export function LaundryGroupSingleCalendarDayToJSON(value?: LaundryGroupSingleCalendarDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': (value.startDate.toISOString().substr(0,10)),
        'entries': ((value.entries as Array<any>).map(LaundryGroupSingleCalendarEntryToJSON)),
    };
}

