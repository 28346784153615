/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HygieneMonitoringInformation } from './HygieneMonitoringInformation';
import {
    HygieneMonitoringInformationFromJSON,
    HygieneMonitoringInformationFromJSONTyped,
    HygieneMonitoringInformationToJSON,
} from './HygieneMonitoringInformation';
import type { TimeseriesData } from './TimeseriesData';
import {
    TimeseriesDataFromJSON,
    TimeseriesDataFromJSONTyped,
    TimeseriesDataToJSON,
} from './TimeseriesData';

/**
 * 
 * @export
 * @interface HygieneMonitoringDetail
 */
export interface HygieneMonitoringDetail {
    /**
     * 
     * @type {HygieneMonitoringInformation}
     * @memberof HygieneMonitoringDetail
     */
    information: HygieneMonitoringInformation;
    /**
     * contains multiple timeseries charts data (Temperature/Water View, Relais View etc.)
     * @type {Array<TimeseriesData>}
     * @memberof HygieneMonitoringDetail
     */
    data: Array<TimeseriesData>;
}

/**
 * Check if a given object implements the HygieneMonitoringDetail interface.
 */
export function instanceOfHygieneMonitoringDetail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "information" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function HygieneMonitoringDetailFromJSON(json: any): HygieneMonitoringDetail {
    return HygieneMonitoringDetailFromJSONTyped(json, false);
}

export function HygieneMonitoringDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): HygieneMonitoringDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'information': HygieneMonitoringInformationFromJSON(json['information']),
        'data': ((json['data'] as Array<any>).map(TimeseriesDataFromJSON)),
    };
}

export function HygieneMonitoringDetailToJSON(value?: HygieneMonitoringDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'information': HygieneMonitoringInformationToJSON(value.information),
        'data': ((value.data as Array<any>).map(TimeseriesDataToJSON)),
    };
}

