/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChartData,
  DashboardIssuesCountList,
  IssueCommonState,
  IssuesChartGroupBy,
} from '../models';
import {
    ChartDataFromJSON,
    ChartDataToJSON,
    DashboardIssuesCountListFromJSON,
    DashboardIssuesCountListToJSON,
    IssueCommonStateFromJSON,
    IssueCommonStateToJSON,
    IssuesChartGroupByFromJSON,
    IssuesChartGroupByToJSON,
} from '../models';

export interface DashboardSmIssuesChartGetRequest {
    groupBy: IssuesChartGroupBy;
    createdFrom?: Date;
    createdTo?: Date;
    closedFrom?: Date;
    closedTo?: Date;
    issueCommonStates?: Array<IssueCommonState>;
    organizationId?: string;
}

export interface DashboardSmIssuesCountGetRequest {
    groupBy: IssuesChartGroupBy;
    createdFrom?: Date;
    createdTo?: Date;
    closedFrom?: Date;
    closedTo?: Date;
    issueCommonStates?: Array<IssueCommonState>;
    organizationId?: string;
}

/**
 * 
 */
export class DashboardIssuesApi extends runtime.BaseAPI {

    /**
     * e.g. To Query a Chart of all requested/open/in-progress Issues overall time \\ do not send any from/to -> Backend will assume all time \\ but select the issuesStates you like to show
     * Doughnut-Chart of Issues
     */
    async dashboardSmIssuesChartGetRaw(requestParameters: DashboardSmIssuesChartGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChartData>> {
        if (requestParameters.groupBy === null || requestParameters.groupBy === undefined) {
            throw new runtime.RequiredError('groupBy','Required parameter requestParameters.groupBy was null or undefined when calling dashboardSmIssuesChartGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.createdFrom !== undefined) {
            queryParameters['createdFrom'] = this.toIsoStringLocalTimezone(requestParameters.createdFrom as any);
        }

        if (requestParameters.createdTo !== undefined) {
            queryParameters['createdTo'] = this.toIsoStringLocalTimezone(requestParameters.createdTo as any);
        }

        if (requestParameters.closedFrom !== undefined) {
            queryParameters['closedFrom'] = this.toIsoStringLocalTimezone(requestParameters.closedFrom as any);
        }

        if (requestParameters.closedTo !== undefined) {
            queryParameters['closedTo'] = this.toIsoStringLocalTimezone(requestParameters.closedTo as any);
        }

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        if (requestParameters.issueCommonStates) {
            queryParameters['issueCommonStates'] = requestParameters.issueCommonStates.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboard/sm/issues/chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChartDataFromJSON(jsonValue));
    }

    /**
     * e.g. To Query a Chart of all requested/open/in-progress Issues overall time \\ do not send any from/to -> Backend will assume all time \\ but select the issuesStates you like to show
     * Doughnut-Chart of Issues
     */
    async dashboardSmIssuesChartGet(groupBy: IssuesChartGroupBy, createdFrom?: Date, createdTo?: Date, closedFrom?: Date, closedTo?: Date, issueCommonStates?: Array<IssueCommonState>, organizationId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChartData> {
        const response = await this.dashboardSmIssuesChartGetRaw({ groupBy: groupBy, createdFrom: createdFrom, createdTo: createdTo, closedFrom: closedFrom, closedTo: closedTo, issueCommonStates: issueCommonStates, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     * e.g. To Query a Count of all newly created Issues of last month \\ send according createdFrom/createdTo within the month you like \\ but do not send a filter for Issue States to get all \"created\"
     * Numbers of Issues within time-range
     */
    async dashboardSmIssuesCountGetRaw(requestParameters: DashboardSmIssuesCountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardIssuesCountList>> {
        if (requestParameters.groupBy === null || requestParameters.groupBy === undefined) {
            throw new runtime.RequiredError('groupBy','Required parameter requestParameters.groupBy was null or undefined when calling dashboardSmIssuesCountGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.createdFrom !== undefined) {
            queryParameters['createdFrom'] = this.toIsoStringLocalTimezone(requestParameters.createdFrom as any);
        }

        if (requestParameters.createdTo !== undefined) {
            queryParameters['createdTo'] = this.toIsoStringLocalTimezone(requestParameters.createdTo as any);
        }

        if (requestParameters.closedFrom !== undefined) {
            queryParameters['closedFrom'] = this.toIsoStringLocalTimezone(requestParameters.closedFrom as any);
        }

        if (requestParameters.closedTo !== undefined) {
            queryParameters['closedTo'] = this.toIsoStringLocalTimezone(requestParameters.closedTo as any);
        }

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        if (requestParameters.issueCommonStates) {
            queryParameters['issueCommonStates'] = requestParameters.issueCommonStates.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboard/sm/issues/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardIssuesCountListFromJSON(jsonValue));
    }

    /**
     * e.g. To Query a Count of all newly created Issues of last month \\ send according createdFrom/createdTo within the month you like \\ but do not send a filter for Issue States to get all \"created\"
     * Numbers of Issues within time-range
     */
    async dashboardSmIssuesCountGet(groupBy: IssuesChartGroupBy, createdFrom?: Date, createdTo?: Date, closedFrom?: Date, closedTo?: Date, issueCommonStates?: Array<IssueCommonState>, organizationId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardIssuesCountList> {
        const response = await this.dashboardSmIssuesCountGetRaw({ groupBy: groupBy, createdFrom: createdFrom, createdTo: createdTo, closedFrom: closedFrom, closedTo: closedTo, issueCommonStates: issueCommonStates, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

}
