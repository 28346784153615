/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MachineParametersStateCheckType {
    POWER = 'POWER',
    RUNTIME_SIGNAL = 'RUNTIME_SIGNAL',
    TIMER = 'TIMER'
}


export function MachineParametersStateCheckTypeFromJSON(json: any): MachineParametersStateCheckType {
    return MachineParametersStateCheckTypeFromJSONTyped(json, false);
}

export function MachineParametersStateCheckTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineParametersStateCheckType {
    return json as MachineParametersStateCheckType;
}

export function MachineParametersStateCheckTypeToJSON(value?: MachineParametersStateCheckType | null): any {
    return value as any;
}

