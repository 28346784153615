/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManualMachineInfo } from './ManualMachineInfo';
import {
    ManualMachineInfoFromJSON,
    ManualMachineInfoFromJSONTyped,
    ManualMachineInfoToJSON,
} from './ManualMachineInfo';

/**
 * 
 * @export
 * @interface ManualFollowerPairingBody
 */
export interface ManualFollowerPairingBody {
    /**
     * 
     * @type {Array<ManualMachineInfo>}
     * @memberof ManualFollowerPairingBody
     */
    followers: Array<ManualMachineInfo>;
}

/**
 * Check if a given object implements the ManualFollowerPairingBody interface.
 */
export function instanceOfManualFollowerPairingBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "followers" in value;

    return isInstance;
}

export function ManualFollowerPairingBodyFromJSON(json: any): ManualFollowerPairingBody {
    return ManualFollowerPairingBodyFromJSONTyped(json, false);
}

export function ManualFollowerPairingBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualFollowerPairingBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'followers': ((json['followers'] as Array<any>).map(ManualMachineInfoFromJSON)),
    };
}

export function ManualFollowerPairingBodyToJSON(value?: ManualFollowerPairingBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'followers': ((value.followers as Array<any>).map(ManualMachineInfoToJSON)),
    };
}

