/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SettingSelectFieldOption } from './SettingSelectFieldOption';
import {
    SettingSelectFieldOptionFromJSON,
    SettingSelectFieldOptionFromJSONTyped,
    SettingSelectFieldOptionToJSON,
} from './SettingSelectFieldOption';

/**
 * 
 * @export
 * @interface SettingSelectField
 */
export interface SettingSelectField {
    /**
     * 
     * @type {string}
     * @memberof SettingSelectField
     */
    readonly activeData?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingSelectField
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingSelectField
     */
    readonly factoryDefaultData?: string;
    /**
     * 
     * @type {Array<SettingSelectFieldOption>}
     * @memberof SettingSelectField
     */
    options?: Array<SettingSelectFieldOption>;
}

/**
 * Check if a given object implements the SettingSelectField interface.
 */
export function instanceOfSettingSelectField(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SettingSelectFieldFromJSON(json: any): SettingSelectField {
    return SettingSelectFieldFromJSONTyped(json, false);
}

export function SettingSelectFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingSelectField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeData': !exists(json, 'activeData') ? undefined : json['activeData'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'factoryDefaultData': !exists(json, 'factoryDefaultData') ? undefined : json['factoryDefaultData'],
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(SettingSelectFieldOptionFromJSON)),
    };
}

export function SettingSelectFieldToJSON(value?: SettingSelectField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(SettingSelectFieldOptionToJSON)),
    };
}

