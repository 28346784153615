/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InstallProgramGroupRequest,
  MachineType,
  PageableProgramGroups,
  ProgramGroup,
  ProgramGroupCopyRequest,
} from '../models';
import {
    InstallProgramGroupRequestFromJSON,
    InstallProgramGroupRequestToJSON,
    MachineTypeFromJSON,
    MachineTypeToJSON,
    PageableProgramGroupsFromJSON,
    PageableProgramGroupsToJSON,
    ProgramGroupFromJSON,
    ProgramGroupToJSON,
    ProgramGroupCopyRequestFromJSON,
    ProgramGroupCopyRequestToJSON,
} from '../models';

export interface LaundrygroupsLaundryGroupIdProgramsInstallProgramGroupPostRequest {
    laundryGroupId: string;
    installProgramGroupRequest?: InstallProgramGroupRequest;
}

export interface MachinesMachineIdProgramsInstallProgramGroupPostRequest {
    machineId: string;
    installProgramGroupRequest?: InstallProgramGroupRequest;
}

export interface ProgramGroupsCopyPostRequest {
    programGroupCopyRequest?: ProgramGroupCopyRequest;
}

export interface ProgramGroupsGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    organizationId?: string;
    machineType?: MachineType;
}

export interface ProgramGroupsPostRequest {
    programGroup?: ProgramGroup;
}

export interface ProgramGroupsProgramGroupIdDeleteRequest {
    programGroupId: string;
}

export interface ProgramGroupsProgramGroupIdExportGetRequest {
    programGroupId: string;
    drumSize: number | null;
}

export interface ProgramGroupsProgramGroupIdGetRequest {
    programGroupId: string;
}

export interface ProgramGroupsProgramGroupIdPutRequest {
    programGroupId: string;
    programGroup?: ProgramGroup;
}

export interface ProgramGroupsTemplateGetRequest {
    organizationId: string;
    machineType: MachineType;
}

/**
 * 
 */
export class ProgramGroupsApi extends runtime.BaseAPI {

    /**
     * Install a Program Group on all related Machines in a Laundry Group
     */
    async laundrygroupsLaundryGroupIdProgramsInstallProgramGroupPostRaw(requestParameters: LaundrygroupsLaundryGroupIdProgramsInstallProgramGroupPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdProgramsInstallProgramGroupPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/programs/install-program-group`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstallProgramGroupRequestToJSON(requestParameters.installProgramGroupRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Install a Program Group on all related Machines in a Laundry Group
     */
    async laundrygroupsLaundryGroupIdProgramsInstallProgramGroupPost(laundryGroupId: string, installProgramGroupRequest?: InstallProgramGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.laundrygroupsLaundryGroupIdProgramsInstallProgramGroupPostRaw({ laundryGroupId: laundryGroupId, installProgramGroupRequest: installProgramGroupRequest }, initOverrides);
    }

    /**
     * Install a Program Group on the Machine
     */
    async machinesMachineIdProgramsInstallProgramGroupPostRaw(requestParameters: MachinesMachineIdProgramsInstallProgramGroupPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdProgramsInstallProgramGroupPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines/{machineId}/programs/install-program-group`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstallProgramGroupRequestToJSON(requestParameters.installProgramGroupRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Install a Program Group on the Machine
     */
    async machinesMachineIdProgramsInstallProgramGroupPost(machineId: string, installProgramGroupRequest?: InstallProgramGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.machinesMachineIdProgramsInstallProgramGroupPostRaw({ machineId: machineId, installProgramGroupRequest: installProgramGroupRequest }, initOverrides);
    }

    /**
     * Copy a Program Group to organization
     */
    async programGroupsCopyPostRaw(requestParameters: ProgramGroupsCopyPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/program-groups/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProgramGroupCopyRequestToJSON(requestParameters.programGroupCopyRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Copy a Program Group to organization
     */
    async programGroupsCopyPost(programGroupCopyRequest?: ProgramGroupCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.programGroupsCopyPostRaw({ programGroupCopyRequest: programGroupCopyRequest }, initOverrides);
    }

    /**
     * List Program Groups
     */
    async programGroupsGetRaw(requestParameters: ProgramGroupsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableProgramGroups>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.machineType !== undefined) {
            queryParameters['machineType'] = requestParameters.machineType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/program-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableProgramGroupsFromJSON(jsonValue));
    }

    /**
     * List Program Groups
     */
    async programGroupsGet(size?: number, page?: number, sort?: string, search?: string, organizationId?: string, machineType?: MachineType, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableProgramGroups> {
        const response = await this.programGroupsGetRaw({ size: size, page: page, sort: sort, search: search, organizationId: organizationId, machineType: machineType }, initOverrides);
        return await response.value();
    }

    /**
     * Create a Program Group
     */
    async programGroupsPostRaw(requestParameters: ProgramGroupsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramGroup>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/program-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProgramGroupToJSON(requestParameters.programGroup),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramGroupFromJSON(jsonValue));
    }

    /**
     * Create a Program Group
     */
    async programGroupsPost(programGroup?: ProgramGroup, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramGroup> {
        const response = await this.programGroupsPostRaw({ programGroup: programGroup }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a Program Group
     */
    async programGroupsProgramGroupIdDeleteRaw(requestParameters: ProgramGroupsProgramGroupIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.programGroupId === null || requestParameters.programGroupId === undefined) {
            throw new runtime.RequiredError('programGroupId','Required parameter requestParameters.programGroupId was null or undefined when calling programGroupsProgramGroupIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/program-groups/{programGroupId}`.replace(`{${"programGroupId"}}`, encodeURIComponent(String(requestParameters.programGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Program Group
     */
    async programGroupsProgramGroupIdDelete(programGroupId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.programGroupsProgramGroupIdDeleteRaw({ programGroupId: programGroupId }, initOverrides);
    }

    /**
     * Export Program Group into binary format
     */
    async programGroupsProgramGroupIdExportGetRaw(requestParameters: ProgramGroupsProgramGroupIdExportGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.programGroupId === null || requestParameters.programGroupId === undefined) {
            throw new runtime.RequiredError('programGroupId','Required parameter requestParameters.programGroupId was null or undefined when calling programGroupsProgramGroupIdExportGet.');
        }

        if (requestParameters.drumSize === null || requestParameters.drumSize === undefined) {
            throw new runtime.RequiredError('drumSize','Required parameter requestParameters.drumSize was null or undefined when calling programGroupsProgramGroupIdExportGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.drumSize !== undefined) {
            queryParameters['drumSize'] = requestParameters.drumSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/program-groups/{programGroupId}/export`.replace(`{${"programGroupId"}}`, encodeURIComponent(String(requestParameters.programGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export Program Group into binary format
     */
    async programGroupsProgramGroupIdExportGet(programGroupId: string, drumSize: number | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.programGroupsProgramGroupIdExportGetRaw({ programGroupId: programGroupId, drumSize: drumSize }, initOverrides);
        return await response.value();
    }

    /**
     * Get a Program Group
     */
    async programGroupsProgramGroupIdGetRaw(requestParameters: ProgramGroupsProgramGroupIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramGroup>> {
        if (requestParameters.programGroupId === null || requestParameters.programGroupId === undefined) {
            throw new runtime.RequiredError('programGroupId','Required parameter requestParameters.programGroupId was null or undefined when calling programGroupsProgramGroupIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/program-groups/{programGroupId}`.replace(`{${"programGroupId"}}`, encodeURIComponent(String(requestParameters.programGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramGroupFromJSON(jsonValue));
    }

    /**
     * Get a Program Group
     */
    async programGroupsProgramGroupIdGet(programGroupId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramGroup> {
        const response = await this.programGroupsProgramGroupIdGetRaw({ programGroupId: programGroupId }, initOverrides);
        return await response.value();
    }

    /**
     * Update a Program Group
     */
    async programGroupsProgramGroupIdPutRaw(requestParameters: ProgramGroupsProgramGroupIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramGroup>> {
        if (requestParameters.programGroupId === null || requestParameters.programGroupId === undefined) {
            throw new runtime.RequiredError('programGroupId','Required parameter requestParameters.programGroupId was null or undefined when calling programGroupsProgramGroupIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/program-groups/{programGroupId}`.replace(`{${"programGroupId"}}`, encodeURIComponent(String(requestParameters.programGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProgramGroupToJSON(requestParameters.programGroup),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramGroupFromJSON(jsonValue));
    }

    /**
     * Update a Program Group
     */
    async programGroupsProgramGroupIdPut(programGroupId: string, programGroup?: ProgramGroup, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramGroup> {
        const response = await this.programGroupsProgramGroupIdPutRaw({ programGroupId: programGroupId, programGroup: programGroup }, initOverrides);
        return await response.value();
    }

    /**
     * Get a template for a new Program Group with empty positions
     */
    async programGroupsTemplateGetRaw(requestParameters: ProgramGroupsTemplateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramGroup>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling programGroupsTemplateGet.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling programGroupsTemplateGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.machineType !== undefined) {
            queryParameters['machineType'] = requestParameters.machineType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/program-groups/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramGroupFromJSON(jsonValue));
    }

    /**
     * Get a template for a new Program Group with empty positions
     */
    async programGroupsTemplateGet(organizationId: string, machineType: MachineType, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramGroup> {
        const response = await this.programGroupsTemplateGetRaw({ organizationId: organizationId, machineType: machineType }, initOverrides);
        return await response.value();
    }

}
