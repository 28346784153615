import { Organization, OrganizationReference, OrganizationType, TimeZone } from 'src/service/backend/api'
import {
  ViewModelWithAddress,
  loadAddressViewModel,
  mapAddress,
} from 'src/service/view-model/base/address/AddressViewModel'
import { LOCALES, LocaleObject, getLocaleObject } from 'src/service/view-model/base/localization/Locales'
import { TimezoneObject, getTimezoneObject } from 'src/service/view-model/base/localization/Timezones'

export interface OrganizationViewModel extends ViewModelWithAddress {
  id: string
  ifsCustomerNumber: string
  vatNumber?: string | null
  name: string
  phoneNumber: string
  email?: string | null
  supportEmail?: string | null
  ticketingSystemAvailable: boolean
  timeZone?: TimezoneObject
  locale?: LocaleObject
  type?: OrganizationType | null
  servicePartner?: OrganizationReference
}

const createEmptyViewModel = () => {
  const viewModel: OrganizationViewModel = {
    id: '',
    ifsCustomerNumber: '',
    name: '',
    phoneNumber: '',
    email: null,
    supportEmail: null,
    ticketingSystemAvailable: false,
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    city: '',
    latitude: '',
    longitude: '',
  }
  return viewModel
}

export const createOrganizationViewModel = (organization?: Organization): OrganizationViewModel => {
  let viewModel: OrganizationViewModel
  if (!organization) {
    viewModel = createEmptyViewModel()
  } else {
    const { address, servicemaster, locale, timeZone, ...restProps } = organization
    viewModel = {
      ...restProps,
      ...loadAddressViewModel(address),
      locale: getLocaleObject(locale),
      timeZone: getTimezoneObject(timeZone),
      servicePartner: servicemaster?.servicePartner,
      ticketingSystemAvailable: servicemaster?.ticketingSystemAvailable || false,
    }
  }
  return viewModel
}

export const mapViewModelToOrganization = (viewModel: OrganizationViewModel): Organization => {
  const { timeZone, locale, type, servicePartner, ticketingSystemAvailable, ...restProps } = viewModel
  const localeObject = locale as LocaleObject | undefined
  const timezoneObject = timeZone as TimezoneObject

  const organization: Organization = {
    timeZone: timezoneObject.code as TimeZone,
    address: mapAddress(viewModel),
    locale: localeObject ? localeObject.code : LOCALES[0].code,
    servicemaster: {
      servicePartner: servicePartner || undefined,
      ticketingSystemAvailable,
    },
    type: type as OrganizationType,
    ...restProps,
  }

  return organization
}
