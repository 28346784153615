/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChartData } from './ChartData';
import {
    ChartDataFromJSON,
    ChartDataFromJSONTyped,
    ChartDataToJSON,
} from './ChartData';
import type { LaundryReference } from './LaundryReference';
import {
    LaundryReferenceFromJSON,
    LaundryReferenceFromJSONTyped,
    LaundryReferenceToJSON,
} from './LaundryReference';

/**
 * 
 * @export
 * @interface DashboardLaundryStateOverview
 */
export interface DashboardLaundryStateOverview {
    /**
     * 
     * @type {LaundryReference}
     * @memberof DashboardLaundryStateOverview
     */
    laundry: LaundryReference;
    /**
     * 
     * @type {ChartData}
     * @memberof DashboardLaundryStateOverview
     */
    chart: ChartData;
}

/**
 * Check if a given object implements the DashboardLaundryStateOverview interface.
 */
export function instanceOfDashboardLaundryStateOverview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "laundry" in value;
    isInstance = isInstance && "chart" in value;

    return isInstance;
}

export function DashboardLaundryStateOverviewFromJSON(json: any): DashboardLaundryStateOverview {
    return DashboardLaundryStateOverviewFromJSONTyped(json, false);
}

export function DashboardLaundryStateOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardLaundryStateOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'laundry': LaundryReferenceFromJSON(json['laundry']),
        'chart': ChartDataFromJSON(json['chart']),
    };
}

export function DashboardLaundryStateOverviewToJSON(value?: DashboardLaundryStateOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'laundry': LaundryReferenceToJSON(value.laundry),
        'chart': ChartDataToJSON(value.chart),
    };
}

