import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router'
import { Add } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Permission } from 'src/service/backend/api/models'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryGroupTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupTab'
import { LaundryGroupTable } from 'src/ui/page/common/laundry-group/list/LaundryGroupTable'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const LaundryGroupListPage = (): ReactElement => {
  const translate = useTranslate()

  const navigate = useNavigate()
  const appId = useAppId()
  const user = useUser()

  const hasWritePermission = hasPermission(user, Permission.LAUNDRY_GROUP_WRITE)

  const handleRowClick = (laundryGroupId?: string) => {
    navigate(`/${appId}/laundry-groups/${laundryGroupId}/view/${LaundryGroupTab.OVERVIEW}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleCreateButtonClick = () => {
    navigate(`/${appId}/laundry-groups/create`)
  }

  const createNewAction = (
    <ListingButton onClick={handleCreateButtonClick} variant="outlined" color="primary" startIcon={<Add />}>
      {translate('createNew')}
    </ListingButton>
  )

  const actions = hasWritePermission ? createNewAction : <></>

  return (
    <ScreenLayout title={translate('laundryGroups')} actions={actions}>
      <LaundryGroupTable navigateToItem={handleRowClick}></LaundryGroupTable>
    </ScreenLayout>
  )
}
