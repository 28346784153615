import { Laundry, LaundryGroupReference, OrganizationReference } from 'src/service/backend/api/models'
import {
  ViewModelWithAddress,
  loadAddressViewModel,
  mapAddress,
} from 'src/service/view-model/base/address/AddressViewModel'

export interface LaundryViewModel extends ViewModelWithAddress {
  id: string
  name: string
  laundryGroup?: LaundryGroupReference
  organization?: OrganizationReference
  wifiSSID?: string
  wifiSecret?: string
  notes?: string
  posTerminalIds: string[]
  terminalSerialNumber?: string | null
  terminalPassword?: string | null
}

export const mapLaundryToLaundryViewModel = (laundry: Laundry): LaundryViewModel => {
  const viewModelWithAddress = loadAddressViewModel(laundry.address)

  return {
    ...viewModelWithAddress,
    id: laundry.id,
    name: laundry.name || '',
    laundryGroup: laundry.laundryGroup,
    organization: laundry.organization,
    notes: laundry.notes || '',
    wifiSSID: laundry.wifiSSID,
    wifiSecret: laundry.wifiSecret,
    terminalSerialNumber: laundry.washmaster?.terminalSerialNumber,
    terminalPassword: laundry.washmaster?.terminalPassword,
    posTerminalIds: laundry.washmaster?.posTerminalIds || [],
  }
}

export const mapLaundryViewModelToLaundry = (viewModel: LaundryViewModel): Laundry => {
  const laundryGroupRef = viewModel.laundryGroup as LaundryGroupReference
  return {
    id: viewModel.id,
    name: viewModel.name,
    wifiSSID: viewModel.wifiSSID,
    wifiSecret: viewModel.wifiSecret,
    notes: viewModel.notes,
    address: mapAddress(viewModel),
    laundryGroup: laundryGroupRef,
    // TODO pst: remove this once the backend fixes the bug
    organization: { id: laundryGroupRef.id, name: '' },
    washmaster: {
      terminalSerialNumber: viewModel.terminalSerialNumber,
      terminalPassword: viewModel.terminalPassword,
      posTerminalIds: viewModel.posTerminalIds,
    },
  } as Laundry
}

export const createLaundryViewModel = (laundry?: Laundry): LaundryViewModel => {
  if (laundry) {
    return mapLaundryToLaundryViewModel(laundry)
  }
  return {
    id: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    city: '',
    latitude: '',
    longitude: '',
    countryCode: undefined,
    laundryGroup: undefined,
    organization: undefined,
    posTerminalIds: [],
  }
}
