import React, { FC, ReactElement } from 'react'
import { Icon } from '@mdi/react'
import { Box, Grid } from '@mui/material'
import { mdiRepeat, mdiWrench } from '@mdi/js'
import moment from 'moment'
import { useTranslate } from 'src/i18n/useMessageSource'
import { CalendarEntry, CalendarEntryType, OpeningType } from 'src/service/backend/api'
import { formatTimeForLocale, formatTimeStringForLocale } from 'src/service/utils/DateFormatUtils'
import {
  CalendarEntrySingleDay,
  CalendarEntrySlot,
  SlotAndDay,
} from 'src/service/view-model/base/calendar/Calendar.const'
import { getUserName } from 'src/service/view-model/laundry-user/LaundryUserUtils'
import { WrapperTooltip } from 'src/ui-shared/base/tooltip/WrapperTooltip'
import { calendarStyles } from 'src/ui-shared/calendar/Calendar.style'
import { useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  day: CalendarEntrySingleDay
  dayTitlesOnly: boolean
  selectSlotAndDay: (slot: SlotAndDay) => void
}

export const CalendarDay: FC<Props> = ({ day, dayTitlesOnly, selectSlotAndDay }): ReactElement => {
  const translate = useTranslate()
  const { classes } = calendarStyles()
  const regionLocale = useUserRegionLocale()

  // derived state
  const dayMoment = moment(new Date(day.dayOfWeek))
  const dayAndDayName = dayMoment.format('DD dddd')
  const dayLongText = dayMoment.format('DD.MM.YYYY')
  const dayNameArray = dayAndDayName.split(' ')
  const dayOfMonth = dayNameArray[0]
  const dayName = dayNameArray[1]?.toLowerCase()

  const getSlotClass = (slot: CalendarEntrySlot) => {
    const calendarEntry: CalendarEntry | undefined = slot.calendarEntry

    let result

    // Handling openingTypes
    const openingType = slot.laundryCalendar?.openingType
    if (openingType === OpeningType.FREE) {
      result = classes.daySlotOpen
    } else if (openingType === OpeningType.AD_HOC) {
      result = classes.daySlotAdhoc
    } else {
      // all other opening types are shown as blocked
      result = classes.daySlotBlocked
    }

    // if it has reservation
    if (calendarEntry) {
      const entryType = calendarEntry.calendarEntryType
      if (entryType === CalendarEntryType.ACTIVE_USAGE) {
        result = classes.daySlotMachineUsage
      } else if (entryType === CalendarEntryType.AD_HOC_RESERVATION) {
        result = classes.daySlotAdhocReservation
      } else if (entryType === CalendarEntryType.RESERVATION) {
        result = classes.daySlotReservation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      } else if (entryType === CalendarEntryType.MAINTENANCE) {
        result = classes.daySlotMaintenance
      }

      // Handling ActiveUser
      if (slot.isActiveUser && entryType !== CalendarEntryType.ACTIVE_USAGE) {
        result = classes.daySlotReservationSelectedUser
      }
    }

    return result
  }

  const getSlotText = (slot: CalendarEntrySlot, long: boolean) => {
    const calendarEntry: CalendarEntry | undefined = slot.calendarEntry

    let result

    // if it has reservation
    if (calendarEntry) {
      const entryType = calendarEntry.calendarEntryType
      if (entryType === CalendarEntryType.ACTIVE_USAGE) {
        result = translate('activeUsage')
      } else if (entryType === CalendarEntryType.AD_HOC_RESERVATION) {
        result = 'Ad-Hoc reservation'
      } else if (entryType === CalendarEntryType.RESERVATION) {
        result = translate('userReservation')
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      } else if (entryType === CalendarEntryType.MAINTENANCE) {
        result = translate('maintenance')
      }

      // if there is user, that has priority override text
      if (calendarEntry.laundryUser?.id) {
        const userFullName = getUserName(calendarEntry.laundryUser)
        result = userFullName ? userFullName : 'Unknown user'
      }

      if (long) {
        let timeText =
          ' ' +
          formatTimeForLocale(calendarEntry.startTime, regionLocale) +
          ' - ' +
          formatTimeForLocale(calendarEntry.endTime, regionLocale)

        if (calendarEntry.recurringReservationId) {
          timeText += ' (' + translate('recurring') + ')'
        }

        result += ' ' + timeText
      }
    }
    return result
  }

  // render
  const renderIcon = (slot: CalendarEntrySlot) => {
    const calendarEntry: CalendarEntry | undefined = slot.calendarEntry

    let result
    if (calendarEntry) {
      const entryType = calendarEntry.calendarEntryType
      if (entryType === CalendarEntryType.MAINTENANCE) {
        result = <Icon className={classes.slotIcon} path={mdiWrench} size={0.7} />
      } else if (calendarEntry.recurringReservationId) {
        result = (
          <Icon
            className={slot.isActiveUser ? classes.slotIconReservationSelectedUser : classes.slotIconReservation}
            path={mdiRepeat}
            size={0.7}
          />
        )
      }
    }
    return result
  }

  const slotEntries = day.entries

  const slotRenders = slotEntries.map((slot) => {
    const slotText = getSlotText(slot, false)
    const slotTextLong = getSlotText(slot, true)
    return (
      <WrapperTooltip
        key={slot.startTime}
        toolTipText={slotTextLong}
        component={
          <Box
            className={getSlotClass(slot)}
            key={slot.startTime}
            my={0.5}
            onClick={selectSlotAndDay.bind(this, { slot, dayOfWeek: day.dayOfWeek } as SlotAndDay)}
          >
            <span className={classes.slotSpan}>
              {formatTimeStringForLocale(slot.startTime, regionLocale)} {slotText}
            </span>
            {renderIcon(slot)}
          </Box>
        }
      />
    )
  })

  return (
    <>
      <Grid item xs>
        {dayTitlesOnly ? (
          <Box className={classes.dayTitle} title={dayLongText}>
            {dayOfMonth} {translate(dayName)}
          </Box>
        ) : (
          <div className={classes.daySlots}>{slotRenders}</div>
        )}
      </Grid>
    </>
  )
}
