import React, { ReactElement } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { Checkbox, FormControlLabel, SwitchProps } from '@mui/material'
import { FinalFormInput } from 'src/ui-shared/base/form/control/Index'

type SwitchFieldPropsToBeExcluded = FinalFormInput | 'onFocus' | 'onBlur'
type SwitchFieldProps = Omit<SwitchProps, SwitchFieldPropsToBeExcluded> & {
  name: string
  label: string
  additonalOnChange?: (value: boolean, previous: boolean) => void
}

const CustomCheckbox = (props: FieldRenderProps<boolean, HTMLButtonElement>): ReactElement => {
  const { input, label, disabled, additonalOnChange } = props
  const { value, onChange, onFocus, onBlur } = input
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          checked={Boolean(value)}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          onChange={(event) => {
            onChange(event.target.checked)
            if (additonalOnChange) {
              additonalOnChange(event.target.checked, input)
            }
          }}
        />
      }
    />
  )
}

export const CheckboxField = (props: SwitchFieldProps): ReactElement => <Field component={CustomCheckbox} {...props} />
