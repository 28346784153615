/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrganizationType,
  Role,
  RoleDetail,
  RoleReference,
} from '../models';
import {
    OrganizationTypeFromJSON,
    OrganizationTypeToJSON,
    RoleFromJSON,
    RoleToJSON,
    RoleDetailFromJSON,
    RoleDetailToJSON,
    RoleReferenceFromJSON,
    RoleReferenceToJSON,
} from '../models';

export interface RolesAvailableGetRequest {
    organizationId?: string;
}

export interface RolesGetRequest {
    type?: Array<OrganizationType>;
}

export interface RolesPutRequest {
    roleDetail: RoleDetail;
}

export interface RolesRefGetRequest {
    search?: string;
    type?: Array<OrganizationType>;
}

/**
 * 
 */
export class RolesApi extends runtime.BaseAPI {

    /**
     * List available Roles for member update/invitation to a specific Organization
     */
    async rolesAvailableGetRaw(requestParameters: RolesAvailableGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Role>>> {
        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/roles/available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleFromJSON));
    }

    /**
     * List available Roles for member update/invitation to a specific Organization
     */
    async rolesAvailableGet(organizationId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Role>> {
        const response = await this.rolesAvailableGetRaw({ organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     * List Roles
     */
    async rolesGetRaw(requestParameters: RolesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoleDetail>>> {
        const queryParameters: any = {};

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleDetailFromJSON));
    }

    /**
     * List Roles
     */
    async rolesGet(type?: Array<OrganizationType>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoleDetail>> {
        const response = await this.rolesGetRaw({ type: type }, initOverrides);
        return await response.value();
    }

    /**
     * Update a Role
     */
    async rolesPutRaw(requestParameters: RolesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleDetail>> {
        if (requestParameters.roleDetail === null || requestParameters.roleDetail === undefined) {
            throw new runtime.RequiredError('roleDetail','Required parameter requestParameters.roleDetail was null or undefined when calling rolesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/roles`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoleDetailToJSON(requestParameters.roleDetail),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDetailFromJSON(jsonValue));
    }

    /**
     * Update a Role
     */
    async rolesPut(roleDetail: RoleDetail, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleDetail> {
        const response = await this.rolesPutRaw({ roleDetail: roleDetail }, initOverrides);
        return await response.value();
    }

    /**
     * List Roles References
     */
    async rolesRefGetRaw(requestParameters: RolesRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoleReference>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/roles/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleReferenceFromJSON));
    }

    /**
     * List Roles References
     */
    async rolesRefGet(search?: string, type?: Array<OrganizationType>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoleReference>> {
        const response = await this.rolesRefGetRaw({ search: search, type: type }, initOverrides);
        return await response.value();
    }

}
