/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalendarConfig
 */
export interface CalendarConfig {
    /**
     * 
     * @type {boolean}
     * @memberof CalendarConfig
     */
    blockUsageOnPublicHoliday: boolean;
    /**
     * [hours]
     * @type {number}
     * @memberof CalendarConfig
     */
    maxReservationHoursPerMonth: number;
    /**
     * [hours]
     * @type {number}
     * @memberof CalendarConfig
     */
    maxReservationTime: number;
}

/**
 * Check if a given object implements the CalendarConfig interface.
 */
export function instanceOfCalendarConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "blockUsageOnPublicHoliday" in value;
    isInstance = isInstance && "maxReservationHoursPerMonth" in value;
    isInstance = isInstance && "maxReservationTime" in value;

    return isInstance;
}

export function CalendarConfigFromJSON(json: any): CalendarConfig {
    return CalendarConfigFromJSONTyped(json, false);
}

export function CalendarConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blockUsageOnPublicHoliday': json['blockUsageOnPublicHoliday'],
        'maxReservationHoursPerMonth': json['maxReservationHoursPerMonth'],
        'maxReservationTime': json['maxReservationTime'],
    };
}

export function CalendarConfigToJSON(value?: CalendarConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blockUsageOnPublicHoliday': value.blockUsageOnPublicHoliday,
        'maxReservationHoursPerMonth': value.maxReservationHoursPerMonth,
        'maxReservationTime': value.maxReservationTime,
    };
}

