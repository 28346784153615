import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Box, Button, Card, Grid } from '@mui/material'
import Alert from '@mui/material/Alert'
import { EmailOutlined, Print } from '@mui/icons-material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  ApiResponse,
  Configuration,
  Currency,
  LaundryUser,
  LaundryUsersPrepaidApi,
  LaundryUsersPrepaidBankTransferApi,
  PrepaidBalance,
  PrepaidBankTransferDownloadRequest,
  PrepaidBankTransferMailingConfirmRequest,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getRemoveAcceptHeaderInit } from 'src/service/backend/http/HttpUtils'
import { downloadFile, getDownloadFilename } from 'src/service/utils/FileDownloadUtils'
import { getCountryName } from 'src/service/view-model/base/country/Countries'
import {
  LaundryUserGenerateBankSlipViewModel,
  mapLaundryUserGenerateBankSlipViewModelToPrepaidBankTransferDownloadRequest,
  mapLaundryUserGenerateBankSlipViewModelToPrepaidBankTransferMailingConfirmRequest,
} from 'src/service/view-model/laundry-user/LaundryUserGenerateBankSlipViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import {
  composeValidators,
  required,
  validAmount,
  validAmountMax,
  validAmountMin,
} from 'src/ui-shared/base/form/validation/Validators'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { useUserLocale } from 'src/user/UserContext'

interface Props {
  laundryUser: LaundryUser
  setOpenModal: (value: boolean) => void
}

export const LaundryUserGenerateBankSlipDialog: FC<Props> = ({ laundryUser, setOpenModal }): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()
  const showSnackbar = useShowSnackbar()

  const userLocale = useUserLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryUsersPrepaidApi = new LaundryUsersPrepaidApi(httpConfiguration)
  const laundryUsersPrepaidBankTransferApi = new LaundryUsersPrepaidBankTransferApi(httpConfiguration)

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [balance, setBalance] = useState<PrepaidBalance | null>(null)

  const defaultViewModel: LaundryUserGenerateBankSlipViewModel = {
    amount: undefined,
  }
  const minAmount = 10
  const maxAmount = 200

  useEffect(() => {
    loadBalance()
  }, [laundryUser])

  // laundry user balance is needed to get the correct currency for the topup
  const loadBalance = () => {
    let active = true
    setBalance(null)

    setErrorMessage(null)
    setLoading(true)

    laundryUsersPrepaidApi
      .laundryusersLaundryUserIdPrepaidBalanceGet(laundryUser.id)
      .then((data) => {
        if (active) {
          // post-condition check if the balance returned is for the requested user
          if (data.laundryUserId === laundryUser.id) {
            setBalance(data)
            setErrorMessage(null)
            setLoading(false)
          } else {
            throw new Error('Balance is not for the selected laundry user')
          }
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setBalance(null)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }

  const submitForm = (viewModel: LaundryUserGenerateBankSlipViewModel) => {
    setErrorMessage(null)
    setLoading(true)

    const currency = balance?.currency as Currency

    const action = viewModel.action
    const sendByEmail: boolean = action === 'email'

    let requestPromise: Promise<any>
    if (sendByEmail) {
      // send bank transfer document in email attachment
      const emailRequest: PrepaidBankTransferMailingConfirmRequest =
        mapLaundryUserGenerateBankSlipViewModelToPrepaidBankTransferMailingConfirmRequest(viewModel, currency)

      requestPromise = laundryUsersPrepaidBankTransferApi.laundryusersLaundryUserIdPrepaidTopUpBanktransferEmailPostRaw(
        {
          laundryUserId: laundryUser.id,
          prepaidBankTransferEmailRequest: emailRequest,
        },
      )

      requestPromise.then((response: ApiResponse<void>) => {
        if (!response.raw.ok) {
          console.error('The bank slip was not sent succesfully')
        }
        setOpenModal(false)
        setLoading(false)
        const email = laundryUser.email
        const message = translate('generateBankSlipSent', email)
        showSnackbar(message, 'success')
      })
    } else {
      // download bank transfer document as PDF
      const downloadRequest: PrepaidBankTransferDownloadRequest =
        mapLaundryUserGenerateBankSlipViewModelToPrepaidBankTransferDownloadRequest(viewModel, currency)

      requestPromise =
        laundryUsersPrepaidBankTransferApi.laundryusersLaundryUserIdPrepaidTopUpBanktransferDownloadPostRaw(
          {
            laundryUserId: laundryUser.id,
            prepaidBankTransferDownloadRequest: downloadRequest,
          },
          getRemoveAcceptHeaderInit(),
        )

      let filename: string | null = null

      requestPromise
        .then((response: ApiResponse<Blob>) => {
          filename = getDownloadFilename(response.raw)
          return response.value()
        })
        .then((blob: Blob) => {
          setOpenModal(false)
          setLoading(false)
          const message = translate('generateBankSlipSuccess')
          showSnackbar(message, 'success')

          downloadFile(blob, filename, true)
        })
    }

    requestPromise.catch((err) => {
      const errorMessage = errorMapper(err, translate)
      console.error(errorMessage, err)
      setErrorMessage(errorMessage)
      setLoading(false)
    })
  }

  return (
    <Box px={3} pb={3}>
      <Box mt={2}>
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <Alert severity={'warning'}>{translate('generateBankSlipWarning')}</Alert>
        )}

        <br />
        <Grid container spacing={6}>
          <Grid item xs={3}>
            <Box p={1}>{translate('recipient')}:</Box>
          </Grid>
          <Grid item xs={9}>
            <Card variant="outlined">
              <Box p={1}>
                <strong>{laundryUser.firstName + ' ' + laundryUser.lastName}</strong>
                <br />
                {laundryUser.address.addressLine1 ? (
                  <>
                    {laundryUser.address.addressLine1}
                    <br />
                  </>
                ) : null}
                {laundryUser.address.addressLine2 ? (
                  <>
                    {laundryUser.address.addressLine2}
                    <br />
                  </>
                ) : null}
                {laundryUser.address.city ? (
                  <>
                    {laundryUser.address.city}
                    <br />
                  </>
                ) : null}
                {getCountryName(laundryUser.address.countryCode, userLocale)}
                <br />
                <br />

                {laundryUser.email ? (
                  <>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <EmailOutlined />
                      </Grid>
                      <Grid item>&nbsp;{laundryUser.email}</Grid>
                    </Grid>
                  </>
                ) : null}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <LoadingIndicator loading={loading} />

      <Box mt={2}>
        <Form<LaundryUserGenerateBankSlipViewModel>
          initialValues={defaultViewModel}
          onSubmit={submitForm}
          render={({ handleSubmit, form, submitting, pristine }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="amount"
                      label={translate('amount')}
                      fullWidth
                      autoFocus
                      validate={composeValidators(
                        required(),
                        validAmount('validation.valid.amount'),
                        validAmountMax(undefined, maxAmount),
                        validAmountMin(undefined, minAmount),
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DetailsTextField name="currency" value={balance?.currency || ''} label={translate('currency')} />
                  </Grid>
                </Grid>
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    color="primary"
                    size="large"
                    className={sharedClasses.ButtonMargin}
                    onClick={() => setOpenModal(false)}
                  >
                    {translate('button.cancel')}
                  </Button>

                  <Button
                    startIcon={<Print />}
                    variant="outlined"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={pristine || submitting}
                    onClick={() => {
                      form.change('action', 'download')
                    }}
                  >
                    {translate('button.downloadBankSlip')}
                  </Button>
                  <Box ml={2}>
                    <Button
                      startIcon={<EmailOutlined />}
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={pristine || submitting}
                      onClick={() => {
                        form.change('action', 'email')
                      }}
                    >
                      {translate('button.sendBankSlip')}
                    </Button>
                  </Box>
                </Box>
              </form>
            )
          }}
        />
      </Box>
    </Box>
  )
}
