import moment from 'moment'
import {
  CalendarEntry,
  CalendarEntryType,
  LaundryGroupRecurringCalendarDay,
  LaundryUserReference,
  RecurringMachineReservation,
} from 'src/service/backend/api'
import { MachineCategory, MachineReference, MachineType } from 'src/service/backend/api/models'
import { formatMomentToDateTime, formatMomentToTime, getDayOfWeekNumber } from 'src/service/utils/MomentUtils'
import {
  CALENDAR_ENTRY_TYPE_PRIORITY,
  CalendarEntrySingleDay,
  CalendarEntrySlot,
} from 'src/service/view-model/base/calendar/Calendar.const'
import { EMPTY_UUID } from 'src/ui-shared/constants/Constants'

/**
 * Accepts array of type CalendarEntry, and sorts the array by CalendarEntryType and startTime
 * Returns new sorted array of type CalendarEntry
 * @param {Array<CalendarEntry>} calendarEntries - initial CalendarEntry array
 * @return {Array<CalendarEntry>} - new sorted CalendarEntry array by CalendarEntryType
 *
 */
export const sortCalendarEntriesByType = (calendarEntries: Array<CalendarEntry>): Array<CalendarEntry> => {
  const copiedEntriesArray = [...calendarEntries]
  const sortedCalendarEntries = copiedEntriesArray.sort(entryTypeAndStartTimeComparator)
  return sortedCalendarEntries
}

/**
 * Accepts first day of week, and initializes Map which has all of the days of the week as key, and array of CalendarEntries as values
 * Returns Map initialized with all of days of the week as keys, and empty arrays of type CalendarEntry as values
 * @param {Date} startDate - first day of week ( ex: Monday )
 * @return {Map<string,Array<CalendarEntry>} - Map with all week days as keys, and empty arrays of type CalendarEntry as values
 *
 */
export const getDaysOfWeek = (startDate: Date): Map<string, Array<CalendarEntry>> => {
  const daysOfWeek = new Map<string, Array<CalendarEntry>>()
  const startDateMoment = moment(startDate).subtract(1, 'day')
  for (let i = 0; i < 7; i++) {
    const currentWeekDayDate = startDateMoment.add(1, 'day').format('yyyy-MM-DD')
    daysOfWeek.set(currentWeekDayDate, [])
  }
  return daysOfWeek
}

/**
 * Accepts startDate (firstDay of week) and calendarEntries, fills the empty arrays of getDaysOfWeek Map with CalendarEntry
 * if the startTime of CalendarEntry is the same day as the key of the Map
 * Final we get Map which has the days of the week as values, and all of the arrays of CalendarEntry for that belong to that day
 * @param {Date} startDate - first day of week ( ex: Monday )
 * @param {CalendarEntry} calendarEntries - CalendarEntries which are returned from the back-end API
 * @return {Map<string,Array<CalendarEntry>} - Map with all week days as keys, and arrays of CalendarEntry as values
 *
 */
export const initializeDaysOfWeekMap = (
  startDate: Date,
  calendarEntries: CalendarEntry[],
): Map<string, Array<CalendarEntry>> => {
  const daysOfWeek = getDaysOfWeek(startDate)
  calendarEntries.forEach((entry) => {
    const calendarEntryDateStartTime = moment(entry.startTime).format('yyyy-MM-DD')
    const calendarEntryDateEndTime = moment(entry.endTime).format('yyyy-MM-DD')

    if (daysOfWeek.has(calendarEntryDateStartTime)) {
      daysOfWeek.get(calendarEntryDateStartTime)?.push(entry)
    }

    if (calendarEntryDateStartTime !== calendarEntryDateEndTime) {
      // calendar entry that spans 2 days
      if (daysOfWeek.has(calendarEntryDateEndTime)) {
        daysOfWeek.get(calendarEntryDateEndTime)?.push(entry)
      }
    }
  })
  return daysOfWeek
}

/**
 * Accepts startDate (firstDay of week) and array calendarEntries, creates slots for all of the calendarEntries and for all of the
 * days of the week, returns array of type CalendarEntrySingleDay which holds 7 objects for all of the weekdays and their
 * corresponding slots
 * @param {Date} startDate - date
 * @param {Array<CalendarEntry>} calendarEntries - CalendarEntries which are returned from the back-end API
 * @return {Array<CalendarEntrySingleDay>} - Array of type CalendarEntrySingleDay which holds 7 objects for all of the weekdays and
 * their corresponding slots
 *
 */
export const slotifyEntries = (startDate: Date, calendarEntries: CalendarEntry[]): CalendarEntrySingleDay[] => {
  const daysOfWeekMap = initializeDaysOfWeekMap(startDate, calendarEntries)
  const slotifiedEntriesArray: CalendarEntrySingleDay[] = []
  daysOfWeekMap.forEach((value, key) => {
    const transformedToSlots = transformToSlots(value, key)
    const slotifiedObject = {
      dayOfWeek: key,
      entries: transformedToSlots,
    }
    slotifiedEntriesArray.push(slotifiedObject)
  })
  return slotifiedEntriesArray
}

/**
 * Creates CalendarEntrySlot given startTime and calendarEntry, calendarEntry can be null, which the function creates empty
 * CalendarEntrySlot, returns CalendarEntrySlot which is type combination of CalendarEntry and LaundryGroupRecurringCalendarEntry
 *
 * @param {Date} startDate -
 * @param {CalendarEntry?} calendarEntry
 * @return {CalendarEntrySlot} - returns CalendarEntrySlot which is type combination of CalendarEntry and
 * LaundryGroupRecurringCalendarEntry
 *
 */
export const createCalendarEntrySlot = (startTime: Date, calendarEntry?: CalendarEntry): CalendarEntrySlot => {
  let result: CalendarEntrySlot

  const startTimeMoment = moment(startTime)
  const formattedStartTime = formatMomentToTime(startTimeMoment)
  const endTimeMoment = startTimeMoment.clone().add(30, 'minute')
  const formattedEndTime = formatMomentToTime(endTimeMoment)

  if (calendarEntry) {
    const calendarEntrySlot: CalendarEntrySlot = {
      startTime: formattedStartTime,
      endTime: formattedEndTime,
      duration: 30,
      calendarEntry,
    }
    result = calendarEntrySlot
  } else {
    const emptyCalendarEntrySlot: CalendarEntrySlot = {
      startTime: formattedStartTime,
      endTime: formattedEndTime,
      duration: 30,
    }
    result = emptyCalendarEntrySlot
  }

  return result
}

/**
 * Transforms CalendarEntries array to CalendarEntrySlot array, this is the function which creates the slots for calendarEntry
 * and maps it to CalendarEntrySlot
 *
 * @param {Array<CalendarEntry>} calendarEntries
 * @return {Array<CalendarEntrySlot>}
 *
 */
export const transformToSlots = (calendarEntries: CalendarEntry[], dayOfWeek: string): CalendarEntrySlot[] => {
  const calendarEntriesByHour = new Map<string, CalendarEntry>()
  calendarEntries.forEach((item) => {
    const date = moment(item.startTime)
    const endTime = moment(item.endTime)
    while (date.isBefore(endTime)) {
      const time: string = formatMomentToDateTime(date)
      calendarEntriesByHour.set(time, item)
      date.add(30, 'minute')
    }
  })

  let index = 0

  // when we loop the exact day, we have less than 48 30 minute slots in days when the clock changes
  // and 50 slots when DST adds 1 hour
  const startTime = moment(dayOfWeek + ' 00:00:00')
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)

  const dateAndTime = startTime.clone()

  const endDate = startTime.clone().add('1', 'day')

  const slots = []
  while (!endDate.isSame(dateAndTime, 'day')) {
    index++
    if (index > 50) {
      throw Error('Slotification for more than 50 slots: ' + index)
    }

    const currentHour = formatMomentToDateTime(dateAndTime)

    let calendarEntry
    if (calendarEntriesByHour.has(currentHour)) {
      calendarEntry = calendarEntriesByHour.get(currentHour)!
    }

    const slot = createCalendarEntrySlot(dateAndTime.toDate(), calendarEntry)

    slots.push(slot)
    startTime.add(30, 'minute')
    dateAndTime.add(30, 'minute')
  }

  if (!(slots.length === 48 || slots.length === 46 || slots.length === 50)) {
    throw new Error(
      'Slotification of calendar entries for a single day should have 48 (or 46 and 50 when clock changes due to DST) slots but was: ' +
        slots.length,
    )
  }
  return slots
}

/**
 * Combines the recurring calendar from LaundryGroup with the SingleCalendar from the Machines(May be used for LaundryUsers too)
 * and returns Array of type CalendarEntrySingleDay which has the slots for all of the days of the week.
 * @param {Array<LaundryGroupRecurringCalendarDay>} recurringCalendar - RecurringCalendar from LaundryGroup
 * @param {Array<CalendarEntrySingleDay>} singleCalendar - Already slotified singleCalendar for all of the weekdays for Machine
 * @return {Array<CalendarEntrySingleDay>}
 *
 */
export const combineRecurringAndSingleCalendar = (
  recurringCalendar: LaundryGroupRecurringCalendarDay[],
  singleCalendar: CalendarEntrySingleDay[],
): CalendarEntrySingleDay[] => {
  const combinedCalendarArray = singleCalendar.map((item, index) => {
    const recurringCalendarDayEntries = recurringCalendar[index].entries
    const singleCalendarDayEntries = item.entries

    const combinedEntries = singleCalendarDayEntries.map((item, index) => {
      const recurringCalendarEntry = recurringCalendarDayEntries[index]
      const combinedEntry: CalendarEntrySlot = {
        ...item,
        laundryCalendar: recurringCalendarEntry,
      }
      return combinedEntry
    })

    const combinedCalendarSingleDay: CalendarEntrySingleDay = {
      dayOfWeek: item.dayOfWeek,
      entries: combinedEntries,
    }

    return combinedCalendarSingleDay
  })

  return combinedCalendarArray
}

export const appendIsActiveUserPropertyToEntries = (
  laundryUserId: string,
  calendarEntries: CalendarEntrySingleDay[],
): CalendarEntrySingleDay[] => {
  const changedWeek = calendarEntries.map((week) => {
    const changedEntries = week.entries.map((entry) => {
      const newEntry: CalendarEntrySlot = {
        ...entry,
        isActiveUser: laundryUserId === entry.calendarEntry?.laundryUser?.id,
      }
      return newEntry
    })

    const changedCalendarEntrySingleDay: CalendarEntrySingleDay = {
      dayOfWeek: week.dayOfWeek,
      entries: changedEntries,
    }

    return changedCalendarEntrySingleDay
  })
  return changedWeek
}

export const filterActiveUsageEntries = (calendarEntries: CalendarEntry[]): CalendarEntry[] => {
  const filteredCalendarEntries = calendarEntries.filter(
    (entry) => entry.calendarEntryType !== CalendarEntryType.ACTIVE_USAGE,
  )
  return filteredCalendarEntries
}

export const mapCalendarEntrySlotToCalendarEntry = (
  weekDayDate: string,
  startTime: string,
  endTime: string,
  calendarEntryType: CalendarEntryType,
  machineId: string,
  laundryUser?: LaundryUserReference,
): CalendarEntry => {
  const [newStartDate, newEndDate] = getNewStartTimeAndEndTime(weekDayDate, startTime, endTime)

  // TODO pst: check why we need machine reference here
  const machineReference: MachineReference = {
    id: machineId,
    name: '',
    category: MachineCategory.WA,
    type: MachineType.SMAG_STANDARD_WA,
    serialNumber: '',
  }

  const calendarEntry: CalendarEntry = {
    startTime: newStartDate,
    endTime: newEndDate,
    machine: machineReference,
    laundryUser,
    calendarEntryType,
    id: EMPTY_UUID,
  }
  return calendarEntry
}

export const mapCalendarEntrySlotToRecurringMachineReservation = (
  weekDayDate: string,
  startTime: string,
  endTime: string,
  machineId: string,
  laundryUser: LaundryUserReference,
): RecurringMachineReservation => {
  const dayOfWeek = getDayOfWeekNumber(weekDayDate)

  const recurringMachineReservation: RecurringMachineReservation = {
    dayOfWeek,
    id: EMPTY_UUID,
    laundryUserId: laundryUser.id,
    machineId: machineId,
    endTime,
    startTime,
  }

  return recurringMachineReservation
}

const getNewStartTimeAndEndTime = (weekDayDate: string, startTime: string, endTime: string): Date[] => {
  const calendarEntrySlotStartTimeArray = startTime.split(':')
  const calendarEntrySlotEndTimeArray = endTime.split(':')

  const startTimeHours = parseInt(calendarEntrySlotStartTimeArray[0])
  const startTimeMinutes = parseInt(calendarEntrySlotStartTimeArray[1])
  const endTimeHours = parseInt(calendarEntrySlotEndTimeArray[0])
  const endTimeMinutes = parseInt(calendarEntrySlotEndTimeArray[1])

  const weekDayDateMoment = moment(weekDayDate)
  const newStartTime = weekDayDateMoment.set({ hour: startTimeHours, minute: startTimeMinutes }).toDate()
  let newEndTime = weekDayDateMoment.set({ hour: endTimeHours, minute: endTimeMinutes }).toDate()

  if (endTime === '00:00') {
    newEndTime = moment(newEndTime).add(1, 'day').toDate()
  }

  return [newStartTime, newEndTime]
}

// Compares two calendar entries first by CalendarEntryType priority, then by startTime
export const entryTypeAndStartTimeComparator = (entryA: CalendarEntry, entryB: CalendarEntry): number => {
  const entryTypeA = entryA.calendarEntryType
  const entryTypeB = entryB.calendarEntryType

  const priorityA = CALENDAR_ENTRY_TYPE_PRIORITY.get(entryTypeA)
  const priorityB = CALENDAR_ENTRY_TYPE_PRIORITY.get(entryTypeB)

  const entryStartTimeA = entryA.startTime
  const entryStartTimeB = entryB.startTime

  let result = 0

  if (priorityA === undefined || priorityB === undefined) {
    console.warn(`Priority for entry type ${entryTypeA} or ${entryTypeB} was not found`)
    result = 0
  } else if (priorityA !== priorityB) {
    result = priorityA - priorityB
  } else {
    result = entryStartTimeA.getTime() - entryStartTimeB.getTime()
  }

  return result
}
