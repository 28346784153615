/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';
import type { LaundryWashmaster } from './LaundryWashmaster';
import {
    LaundryWashmasterFromJSON,
    LaundryWashmasterFromJSONTyped,
    LaundryWashmasterToJSON,
} from './LaundryWashmaster';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';

/**
 * 
 * @export
 * @interface Laundry
 */
export interface Laundry {
    /**
     * 
     * @type {string}
     * @memberof Laundry
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Laundry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Laundry
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Laundry
     */
    wifiSSID?: string;
    /**
     * 
     * @type {string}
     * @memberof Laundry
     */
    wifiSecret?: string;
    /**
     * 
     * @type {string}
     * @memberof Laundry
     */
    notes?: string;
    /**
     * 
     * @type {Address}
     * @memberof Laundry
     */
    address: Address;
    /**
     * 
     * @type {LaundryGroupReference}
     * @memberof Laundry
     */
    laundryGroup: LaundryGroupReference;
    /**
     * 
     * @type {OrganizationReference}
     * @memberof Laundry
     */
    organization?: OrganizationReference;
    /**
     * 
     * @type {LaundryWashmaster}
     * @memberof Laundry
     */
    washmaster?: LaundryWashmaster | null;
}

/**
 * Check if a given object implements the Laundry interface.
 */
export function instanceOfLaundry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "laundryGroup" in value;

    return isInstance;
}

export function LaundryFromJSON(json: any): Laundry {
    return LaundryFromJSONTyped(json, false);
}

export function LaundryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Laundry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'wifiSSID': !exists(json, 'wifiSSID') ? undefined : json['wifiSSID'],
        'wifiSecret': !exists(json, 'wifiSecret') ? undefined : json['wifiSecret'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'address': AddressFromJSON(json['address']),
        'laundryGroup': LaundryGroupReferenceFromJSON(json['laundryGroup']),
        'organization': !exists(json, 'organization') ? undefined : OrganizationReferenceFromJSON(json['organization']),
        'washmaster': !exists(json, 'washmaster') ? undefined : LaundryWashmasterFromJSON(json['washmaster']),
    };
}

export function LaundryToJSON(value?: Laundry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'phoneNumber': value.phoneNumber,
        'wifiSSID': value.wifiSSID,
        'wifiSecret': value.wifiSecret,
        'notes': value.notes,
        'address': AddressToJSON(value.address),
        'laundryGroup': LaundryGroupReferenceToJSON(value.laundryGroup),
        'organization': OrganizationReferenceToJSON(value.organization),
        'washmaster': LaundryWashmasterToJSON(value.washmaster),
    };
}

