import React, { useState } from 'react'
import { FilledTextFieldProps, Grid, InputAdornment } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { isValid } from 'date-fns'
import { TIME_FORMAT_12_APPENDED } from 'src/service/utils/DateFnsFormatsConstants'
import {
  formatTime,
  formatTime12Hours,
  formatTimeStringFromFormatPatternTo24Hour,
  formatTimeStringTo12Hour,
  formatTimeStringTo24Hour,
} from 'src/service/utils/DateFormatUtils'
import { DateUtils } from 'src/service/utils/DateUtils'
import { StyledIconButton, useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

type PropsToExclude = 'onChange' | 'value' | 'variant'

type TextFieldPropsClean = Omit<FilledTextFieldProps, PropsToExclude>

export interface TimeTextFieldProps extends TextFieldPropsClean {
  value: string | undefined
  twelveHourFormat?: boolean
  onChange: (value: any) => void
  blank?: boolean
  blankValue?: string
}

// formats the given time string to 24 hour string
// if formatting the date with pattern 12:00 AM or 12:00AM is successful
// if the formatting fails, then the function returns the initial passed string
const optionalFormatTimeStringTo24Hour = (timeAsString: string): string => {
  let formattedTime

  // try format 12:00 AM pattern to 00:00
  try {
    formattedTime = formatTimeStringTo24Hour(timeAsString)
  } catch (_error) {
    // try format to 12:00AM to 00:00
    try {
      formattedTime = formatTimeStringFromFormatPatternTo24Hour(timeAsString, TIME_FORMAT_12_APPENDED)
      // assign the initial string if the time can't be formatted to 24 hour
    } catch (_error2) {
      formattedTime = timeAsString
    }
  }
  return formattedTime
}

const parse12HourTime = (time: string): Date => {
  const parsedDateTime = DateUtils.parse12HTime(time)

  // if the parsed date is invalid, parse it to 12:00AM format
  return isValid(parsedDateTime) ? parsedDateTime : DateUtils.parseDateWithFormat(time, TIME_FORMAT_12_APPENDED)
}

export const TimeTextField: React.FC<TimeTextFieldProps> = ({
  value,
  onChange,
  blank,
  blankValue,
  disabled,
  twelveHourFormat,
  ...restProps
}) => {
  const { classes: textFieldClasses } = useTextFieldStyles()

  let initialTime = value ? value : '00:00'

  try {
    initialTime = twelveHourFormat ? formatTimeStringTo12Hour(initialTime) : initialTime
  } catch (e) {
    console.warn(`Initial time: ${initialTime} is not valid time`)
  }

  const [timeState, setTimeState] = useState<string>(initialTime)

  const handleChangeTimeToParent = (timeAsString: string) => {
    const formattedTime = optionalFormatTimeStringTo24Hour(timeAsString)

    // parent component always uses ISO standard for time (00 - 23)
    onChange(formattedTime)
  }

  const incrementTime = () => {
    const timeAsDate = twelveHourFormat ? parse12HourTime(timeState) : DateUtils.parseTime(timeState)
    const incrementedTime = DateUtils.increment(timeAsDate, { minutes: 30 })
    const timeAsString = twelveHourFormat ? formatTime12Hours(incrementedTime) : formatTime(incrementedTime)

    setTimeState(timeAsString)

    // parent component always uses ISO standard for time (00 - 23)
    handleChangeTimeToParent(timeAsString)
  }

  const decrementTime = () => {
    const timeAsDate = twelveHourFormat ? parse12HourTime(timeState) : DateUtils.parseTime(timeState)
    const decrementedTime = DateUtils.subtract(timeAsDate, { minutes: 30 })
    const timeAsString = twelveHourFormat ? formatTime12Hours(decrementedTime) : formatTime(decrementedTime)

    setTimeState(timeAsString)

    // parent component always uses ISO standard for time (00 - 23)
    handleChangeTimeToParent(timeAsString)
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    const isoTime = optionalFormatTimeStringTo24Hour(value)
    setTimeState(value)
    handleChangeTimeToParent(isoTime)
  }

  const regex = twelveHourFormat ? /^(1[0-2]|0?[1-9]):[0-5][0-9]\s?(AM|PM)$/ : /^([0-1]?[0-9]|2[0-3]):(30|00)$/
  const isTextInvalid = !regex.test(timeState)

  const getBlankValue = () => {
    let blankValueFormatted = ''
    try {
      if (blankValue) {
        blankValueFormatted = twelveHourFormat ? formatTimeStringTo12Hour(blankValue) : blankValue
      }
    } catch (e) {
      console.warn(`The time ${blankValue} is not valid time`)
    }

    return blankValueFormatted
  }

  return (
    <TextFieldDefault
      value={blank ? getBlankValue() : timeState}
      className={textFieldClasses.TextFieldSmall}
      onChange={handleOnChange}
      disabled={disabled}
      {...restProps}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Grid container direction="column">
              <Grid item>
                <StyledIconButton disabled={isTextInvalid || disabled} onClick={incrementTime}>
                  <ExpandLessIcon />
                </StyledIconButton>
              </Grid>
              <Grid item>
                <StyledIconButton disabled={isTextInvalid || disabled} onClick={decrementTime}>
                  <ExpandMoreIcon />
                </StyledIconButton>
              </Grid>
            </Grid>
          </InputAdornment>
        ),
      }}
    />
  )
}
