import React, { PropsWithChildren } from 'react'
import { Tooltip, Typography } from '@mui/material'

interface DisabledWarningTooltipProps extends PropsWithChildren {
  message?: string | null
  disabled: boolean
}

export const DisabledWarningTooltip: React.FC<DisabledWarningTooltipProps> = ({ children, message, disabled }) => {
  return (
    <Tooltip
      title={<Typography variant="body2">{message}</Typography>}
      disableHoverListener={disabled}
      disableTouchListener
      disableFocusListener
      disableInteractive
    >
      <div>{children}</div>
    </Tooltip>
  )
}
