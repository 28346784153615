/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Role } from './Role';
import {
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface RoleReference
 */
export interface RoleReference {
    /**
     * 
     * @type {Role}
     * @memberof RoleReference
     */
    role: Role;
}

/**
 * Check if a given object implements the RoleReference interface.
 */
export function instanceOfRoleReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function RoleReferenceFromJSON(json: any): RoleReference {
    return RoleReferenceFromJSONTyped(json, false);
}

export function RoleReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': RoleFromJSON(json['role']),
    };
}

export function RoleReferenceToJSON(value?: RoleReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': RoleToJSON(value.role),
    };
}

