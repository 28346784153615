import {
  HMResult,
  HMState,
  HygieneMonitoringDetail,
  MachineCategory,
  MachineType,
  OrganizationType,
  TimeseriesValueType,
} from 'src/service/backend/api/models'

export const MOCK_HM: HygieneMonitoringDetail = {
  information: {
    id: 'ef32ab80-8cfe-4e81-9a62-1b5c72bee082',
    organization: {
      id: '3c8ef526-d84f-423e-b8c3-cbdf3f406b76',
      name: 'Demo Company',
      type: OrganizationType.COMPANY,
    },
    laundryGroup: {
      id: '257c3b4b-d7e4-47fb-bdc5-40aa0aadbb0e',
      name: 'Demo Laundry Group',
    },
    laundry: {
      id: '3ea93cce-30f6-49c6-b666-1dfd704ad644',
      name: 'Demo Laundry',
      laundryGroup: {
        id: '257c3b4b-d7e4-47fb-bdc5-40aa0aadbb0e',
        name: 'Demo Laundry Group',
      },
    },
    machine: {
      id: 'ffc35db9-72ae-4c1f-9e49-9154de5070ce',
      name: 'Demo Machine 2',
      category: MachineCategory.WT,
      type: MachineType.SMAG_STANDARD_WT,
      serialNumber: '32019488772',
    },
    startTime: new Date('2023-03-20T09:13:25Z'),
    state: HMState.FINISHED,
    result: HMResult.FAILED,
    failedReason: 'Temp not reached',
    programName: 'Cotton 90',
  },
  data: [
    {
      title: 'Water Temperature',
      datasets: [
        {
          label: 'T°',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T12:53:53Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:53:53Z'),
              endTime: new Date('2023-02-14T12:54:03Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:03Z'),
              endTime: new Date('2023-02-14T12:54:13Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T12:54:13Z'),
              endTime: new Date('2023-02-14T12:54:23Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T12:54:23Z'),
              endTime: new Date('2023-02-14T12:55:03Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T12:55:03Z'),
              endTime: new Date('2023-02-14T12:55:13Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T12:55:13Z'),
              endTime: new Date('2023-02-14T12:55:23Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T12:55:23Z'),
              endTime: new Date('2023-02-14T12:55:33Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T12:55:33Z'),
              endTime: new Date('2023-02-14T12:55:43Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:55:43Z'),
              endTime: new Date('2023-02-14T12:55:53Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T12:55:53Z'),
              endTime: new Date('2023-02-14T12:56:03Z'),
              valueState: null,
              valueNumber: 28,
            },
            {
              startTime: new Date('2023-02-14T12:56:03Z'),
              endTime: new Date('2023-02-14T12:56:13Z'),
              valueState: null,
              valueNumber: 29,
            },
            {
              startTime: new Date('2023-02-14T12:56:13Z'),
              endTime: new Date('2023-02-14T12:56:23Z'),
              valueState: null,
              valueNumber: 29,
            },
            {
              startTime: new Date('2023-02-14T12:56:23Z'),
              endTime: new Date('2023-02-14T12:56:33Z'),
              valueState: null,
              valueNumber: 30,
            },
            {
              startTime: new Date('2023-02-14T12:56:33Z'),
              endTime: new Date('2023-02-14T12:56:43Z'),
              valueState: null,
              valueNumber: 31,
            },
            {
              startTime: new Date('2023-02-14T12:56:43Z'),
              endTime: new Date('2023-02-14T12:56:53Z'),
              valueState: null,
              valueNumber: 31,
            },
            {
              startTime: new Date('2023-02-14T12:56:53Z'),
              endTime: new Date('2023-02-14T12:57:13Z'),
              valueState: null,
              valueNumber: 32,
            },
            {
              startTime: new Date('2023-02-14T12:57:13Z'),
              endTime: new Date('2023-02-14T12:57:23Z'),
              valueState: null,
              valueNumber: 32,
            },
            {
              startTime: new Date('2023-02-14T12:57:23Z'),
              endTime: new Date('2023-02-14T12:57:43Z'),
              valueState: null,
              valueNumber: 33,
            },
            {
              startTime: new Date('2023-02-14T12:57:43Z'),
              endTime: new Date('2023-02-14T12:57:53Z'),
              valueState: null,
              valueNumber: 33,
            },
            {
              startTime: new Date('2023-02-14T12:57:53Z'),
              endTime: new Date('2023-02-14T12:58:03Z'),
              valueState: null,
              valueNumber: 34,
            },
            {
              startTime: new Date('2023-02-14T12:58:03Z'),
              endTime: new Date('2023-02-14T12:58:13Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T12:58:13Z'),
              endTime: new Date('2023-02-14T12:58:23Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T12:58:23Z'),
              endTime: new Date('2023-02-14T12:59:03Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T12:59:03Z'),
              endTime: new Date('2023-02-14T12:59:13Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T12:59:13Z'),
              endTime: new Date('2023-02-14T12:59:23Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T12:59:23Z'),
              endTime: new Date('2023-02-14T12:59:33Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T12:59:33Z'),
              endTime: new Date('2023-02-14T12:59:43Z'),
              valueState: null,
              valueNumber: 38,
            },
            {
              startTime: new Date('2023-02-14T12:59:43Z'),
              endTime: new Date('2023-02-14T12:59:53Z'),
              valueState: null,
              valueNumber: 38,
            },
            {
              startTime: new Date('2023-02-14T12:59:53Z'),
              endTime: new Date('2023-02-14T13:00:13Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:00:13Z'),
              endTime: new Date('2023-02-14T13:00:23Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:00:23Z'),
              endTime: new Date('2023-02-14T13:00:33Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:00:33Z'),
              endTime: new Date('2023-02-14T13:00:43Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:00:43Z'),
              endTime: new Date('2023-02-14T13:00:53Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:00:53Z'),
              endTime: new Date('2023-02-14T13:01:03Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:01:03Z'),
              endTime: new Date('2023-02-14T13:01:13Z'),
              valueState: null,
              valueNumber: 38,
            },
            {
              startTime: new Date('2023-02-14T13:01:13Z'),
              endTime: new Date('2023-02-14T13:01:23Z'),
              valueState: null,
              valueNumber: 38,
            },
            {
              startTime: new Date('2023-02-14T13:01:23Z'),
              endTime: new Date('2023-02-14T13:01:33Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:01:33Z'),
              endTime: new Date('2023-02-14T13:01:43Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:01:43Z'),
              endTime: new Date('2023-02-14T13:04:23Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:04:23Z'),
              endTime: new Date('2023-02-14T13:04:33Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:04:33Z'),
              endTime: new Date('2023-02-14T13:14:03Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:14:03Z'),
              endTime: new Date('2023-02-14T13:14:13Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:14:13Z'),
              endTime: new Date('2023-02-14T13:14:23Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:14:23Z'),
              endTime: new Date('2023-02-14T13:14:33Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:14:33Z'),
              endTime: new Date('2023-02-14T13:14:43Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:14:43Z'),
              endTime: new Date('2023-02-14T13:14:53Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:14:53Z'),
              endTime: new Date('2023-02-14T13:15:03Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:15:03Z'),
              endTime: new Date('2023-02-14T13:15:23Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:15:23Z'),
              endTime: new Date('2023-02-14T13:15:33Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:15:33Z'),
              endTime: new Date('2023-02-14T13:20:53Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:20:53Z'),
              endTime: new Date('2023-02-14T13:21:03Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:21:03Z'),
              endTime: new Date('2023-02-14T13:22:03Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:22:03Z'),
              endTime: new Date('2023-02-14T13:22:13Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:22:13Z'),
              endTime: new Date('2023-02-14T13:22:23Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:22:23Z'),
              endTime: new Date('2023-02-14T13:22:33Z'),
              valueState: null,
              valueNumber: 33,
            },
            {
              startTime: new Date('2023-02-14T13:22:33Z'),
              endTime: new Date('2023-02-14T13:22:43Z'),
              valueState: null,
              valueNumber: 30,
            },
            {
              startTime: new Date('2023-02-14T13:22:43Z'),
              endTime: new Date('2023-02-14T13:22:53Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T13:22:53Z'),
              endTime: new Date('2023-02-14T13:23:03Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:23:03Z'),
              endTime: new Date('2023-02-14T13:23:13Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:23:13Z'),
              endTime: new Date('2023-02-14T13:23:43Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T13:23:43Z'),
              endTime: new Date('2023-02-14T13:23:53Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T13:23:53Z'),
              endTime: new Date('2023-02-14T13:24:03Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T13:24:03Z'),
              endTime: new Date('2023-02-14T13:24:43Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T13:24:43Z'),
              endTime: new Date('2023-02-14T13:24:53Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T13:24:53Z'),
              endTime: new Date('2023-02-14T13:25:13Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:25:13Z'),
              endTime: new Date('2023-02-14T13:25:23Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:25:23Z'),
              endTime: new Date('2023-02-14T13:33:13Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:33:13Z'),
              endTime: new Date('2023-02-14T13:33:23Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:33:23Z'),
              endTime: new Date('2023-02-14T13:33:53Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T13:33:53Z'),
              endTime: new Date('2023-02-14T13:34:03Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T13:34:03Z'),
              endTime: new Date('2023-02-14T13:34:13Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:34:13Z'),
              endTime: new Date('2023-02-14T13:34:23Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:34:23Z'),
              endTime: new Date('2023-02-14T13:34:33Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T13:34:33Z'),
              endTime: new Date('2023-02-14T13:34:43Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T13:34:43Z'),
              endTime: new Date('2023-02-14T13:34:53Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T13:34:53Z'),
              endTime: new Date('2023-02-14T13:36:23Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T13:36:23Z'),
              endTime: new Date('2023-02-14T13:36:33Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T13:36:33Z'),
              endTime: new Date('2023-02-14T13:40:23Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T13:40:23Z'),
              endTime: new Date('2023-02-14T13:40:33Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T13:40:33Z'),
              endTime: new Date('2023-02-14T13:40:43Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T13:40:43Z'),
              endTime: new Date('2023-02-14T13:40:53Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T13:40:53Z'),
              endTime: new Date('2023-02-14T13:46:53Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T13:46:53Z'),
              endTime: new Date('2023-02-14T13:47:03Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T13:47:03Z'),
              endTime: new Date('2023-02-14T13:48:23Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:48:23Z'),
              endTime: new Date('2023-02-14T13:48:33Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:48:33Z'),
              endTime: new Date('2023-02-14T13:50:23Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:50:23Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 26,
            },
          ],
        },
        {
          label: 'T° Setpoint',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:23Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:50:23Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 40,
            },
          ],
        },
      ],
      valueStates: [],
      annotations: undefined,
    },
    {
      title: 'Water Level',
      datasets: [
        {
          label: 'mm',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T12:53:43Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:43Z'),
              endTime: new Date('2023-02-14T12:53:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:48Z'),
              endTime: new Date('2023-02-14T12:53:53Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T12:53:53Z'),
              endTime: new Date('2023-02-14T12:53:58Z'),
              valueState: null,
              valueNumber: 31,
            },
            {
              startTime: new Date('2023-02-14T12:53:58Z'),
              endTime: new Date('2023-02-14T12:54:03Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T12:54:03Z'),
              endTime: new Date('2023-02-14T12:54:08Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T12:54:08Z'),
              endTime: new Date('2023-02-14T12:54:13Z'),
              valueState: null,
              valueNumber: 41,
            },
            {
              startTime: new Date('2023-02-14T12:54:13Z'),
              endTime: new Date('2023-02-14T12:54:18Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T12:54:18Z'),
              endTime: new Date('2023-02-14T12:54:23Z'),
              valueState: null,
              valueNumber: 45,
            },
            {
              startTime: new Date('2023-02-14T12:54:23Z'),
              endTime: new Date('2023-02-14T12:54:28Z'),
              valueState: null,
              valueNumber: 59,
            },
            {
              startTime: new Date('2023-02-14T12:54:28Z'),
              endTime: new Date('2023-02-14T12:54:33Z'),
              valueState: null,
              valueNumber: 54,
            },
            {
              startTime: new Date('2023-02-14T12:54:33Z'),
              endTime: new Date('2023-02-14T12:54:38Z'),
              valueState: null,
              valueNumber: 60,
            },
            {
              startTime: new Date('2023-02-14T12:54:38Z'),
              endTime: new Date('2023-02-14T12:54:43Z'),
              valueState: null,
              valueNumber: 61,
            },
            {
              startTime: new Date('2023-02-14T12:54:43Z'),
              endTime: new Date('2023-02-14T12:54:48Z'),
              valueState: null,
              valueNumber: 59,
            },
            {
              startTime: new Date('2023-02-14T12:54:48Z'),
              endTime: new Date('2023-02-14T12:54:53Z'),
              valueState: null,
              valueNumber: 69,
            },
            {
              startTime: new Date('2023-02-14T12:54:53Z'),
              endTime: new Date('2023-02-14T12:54:58Z'),
              valueState: null,
              valueNumber: 63,
            },
            {
              startTime: new Date('2023-02-14T12:54:58Z'),
              endTime: new Date('2023-02-14T12:55:03Z'),
              valueState: null,
              valueNumber: 65,
            },
            {
              startTime: new Date('2023-02-14T12:55:03Z'),
              endTime: new Date('2023-02-14T12:55:08Z'),
              valueState: null,
              valueNumber: 67,
            },
            {
              startTime: new Date('2023-02-14T12:55:08Z'),
              endTime: new Date('2023-02-14T12:55:13Z'),
              valueState: null,
              valueNumber: 69,
            },
            {
              startTime: new Date('2023-02-14T12:55:13Z'),
              endTime: new Date('2023-02-14T12:55:18Z'),
              valueState: null,
              valueNumber: 72,
            },
            {
              startTime: new Date('2023-02-14T12:55:18Z'),
              endTime: new Date('2023-02-14T12:55:23Z'),
              valueState: null,
              valueNumber: 70,
            },
            {
              startTime: new Date('2023-02-14T12:55:23Z'),
              endTime: new Date('2023-02-14T12:55:28Z'),
              valueState: null,
              valueNumber: 69,
            },
            {
              startTime: new Date('2023-02-14T12:55:28Z'),
              endTime: new Date('2023-02-14T12:55:33Z'),
              valueState: null,
              valueNumber: 63,
            },
            {
              startTime: new Date('2023-02-14T12:55:33Z'),
              endTime: new Date('2023-02-14T12:55:38Z'),
              valueState: null,
              valueNumber: 72,
            },
            {
              startTime: new Date('2023-02-14T12:55:38Z'),
              endTime: new Date('2023-02-14T12:55:43Z'),
              valueState: null,
              valueNumber: 71,
            },
            {
              startTime: new Date('2023-02-14T12:55:43Z'),
              endTime: new Date('2023-02-14T12:55:48Z'),
              valueState: null,
              valueNumber: 67,
            },
            {
              startTime: new Date('2023-02-14T12:55:48Z'),
              endTime: new Date('2023-02-14T12:55:53Z'),
              valueState: null,
              valueNumber: 63,
            },
            {
              startTime: new Date('2023-02-14T12:55:53Z'),
              endTime: new Date('2023-02-14T12:55:58Z'),
              valueState: null,
              valueNumber: 70,
            },
            {
              startTime: new Date('2023-02-14T12:55:58Z'),
              endTime: new Date('2023-02-14T12:56:03Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T12:56:03Z'),
              endTime: new Date('2023-02-14T12:56:08Z'),
              valueState: null,
              valueNumber: 69,
            },
            {
              startTime: new Date('2023-02-14T12:56:08Z'),
              endTime: new Date('2023-02-14T12:56:13Z'),
              valueState: null,
              valueNumber: 73,
            },
            {
              startTime: new Date('2023-02-14T12:56:13Z'),
              endTime: new Date('2023-02-14T12:56:18Z'),
              valueState: null,
              valueNumber: 64,
            },
            {
              startTime: new Date('2023-02-14T12:56:18Z'),
              endTime: new Date('2023-02-14T12:56:23Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T12:56:23Z'),
              endTime: new Date('2023-02-14T12:56:28Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T12:56:28Z'),
              endTime: new Date('2023-02-14T12:56:33Z'),
              valueState: null,
              valueNumber: 72,
            },
            {
              startTime: new Date('2023-02-14T12:56:33Z'),
              endTime: new Date('2023-02-14T12:56:38Z'),
              valueState: null,
              valueNumber: 68,
            },
            {
              startTime: new Date('2023-02-14T12:56:38Z'),
              endTime: new Date('2023-02-14T12:56:43Z'),
              valueState: null,
              valueNumber: 70,
            },
            {
              startTime: new Date('2023-02-14T12:56:43Z'),
              endTime: new Date('2023-02-14T12:56:48Z'),
              valueState: null,
              valueNumber: 68,
            },
            {
              startTime: new Date('2023-02-14T12:56:48Z'),
              endTime: new Date('2023-02-14T12:56:53Z'),
              valueState: null,
              valueNumber: 70,
            },
            {
              startTime: new Date('2023-02-14T12:56:53Z'),
              endTime: new Date('2023-02-14T12:56:58Z'),
              valueState: null,
              valueNumber: 82,
            },
            {
              startTime: new Date('2023-02-14T12:56:58Z'),
              endTime: new Date('2023-02-14T12:57:03Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T12:57:03Z'),
              endTime: new Date('2023-02-14T12:57:08Z'),
              valueState: null,
              valueNumber: 80,
            },
            {
              startTime: new Date('2023-02-14T12:57:08Z'),
              endTime: new Date('2023-02-14T12:57:13Z'),
              valueState: null,
              valueNumber: 72,
            },
            {
              startTime: new Date('2023-02-14T12:57:13Z'),
              endTime: new Date('2023-02-14T12:57:18Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T12:57:18Z'),
              endTime: new Date('2023-02-14T12:57:23Z'),
              valueState: null,
              valueNumber: 70,
            },
            {
              startTime: new Date('2023-02-14T12:57:23Z'),
              endTime: new Date('2023-02-14T12:57:28Z'),
              valueState: null,
              valueNumber: 72,
            },
            {
              startTime: new Date('2023-02-14T12:57:28Z'),
              endTime: new Date('2023-02-14T12:57:33Z'),
              valueState: null,
              valueNumber: 68,
            },
            {
              startTime: new Date('2023-02-14T12:57:33Z'),
              endTime: new Date('2023-02-14T12:57:38Z'),
              valueState: null,
              valueNumber: 71,
            },
            {
              startTime: new Date('2023-02-14T12:57:38Z'),
              endTime: new Date('2023-02-14T12:57:43Z'),
              valueState: null,
              valueNumber: 73,
            },
            {
              startTime: new Date('2023-02-14T12:57:43Z'),
              endTime: new Date('2023-02-14T12:57:48Z'),
              valueState: null,
              valueNumber: 70,
            },
            {
              startTime: new Date('2023-02-14T12:57:48Z'),
              endTime: new Date('2023-02-14T12:57:53Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T12:57:53Z'),
              endTime: new Date('2023-02-14T12:57:58Z'),
              valueState: null,
              valueNumber: 69,
            },
            {
              startTime: new Date('2023-02-14T12:57:58Z'),
              endTime: new Date('2023-02-14T12:58:03Z'),
              valueState: null,
              valueNumber: 73,
            },
            {
              startTime: new Date('2023-02-14T12:58:03Z'),
              endTime: new Date('2023-02-14T12:58:08Z'),
              valueState: null,
              valueNumber: 69,
            },
            {
              startTime: new Date('2023-02-14T12:58:08Z'),
              endTime: new Date('2023-02-14T12:58:13Z'),
              valueState: null,
              valueNumber: 71,
            },
            {
              startTime: new Date('2023-02-14T12:58:13Z'),
              endTime: new Date('2023-02-14T12:58:23Z'),
              valueState: null,
              valueNumber: 70,
            },
            {
              startTime: new Date('2023-02-14T12:58:23Z'),
              endTime: new Date('2023-02-14T12:58:28Z'),
              valueState: null,
              valueNumber: 70,
            },
            {
              startTime: new Date('2023-02-14T12:58:28Z'),
              endTime: new Date('2023-02-14T12:58:33Z'),
              valueState: null,
              valueNumber: 68,
            },
            {
              startTime: new Date('2023-02-14T12:58:33Z'),
              endTime: new Date('2023-02-14T12:58:38Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T12:58:38Z'),
              endTime: new Date('2023-02-14T12:58:43Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T12:58:43Z'),
              endTime: new Date('2023-02-14T12:58:48Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T12:58:48Z'),
              endTime: new Date('2023-02-14T12:58:53Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T12:58:53Z'),
              endTime: new Date('2023-02-14T12:58:58Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T12:58:58Z'),
              endTime: new Date('2023-02-14T12:59:03Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T12:59:03Z'),
              endTime: new Date('2023-02-14T12:59:08Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T12:59:08Z'),
              endTime: new Date('2023-02-14T12:59:13Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T12:59:13Z'),
              endTime: new Date('2023-02-14T12:59:18Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T12:59:18Z'),
              endTime: new Date('2023-02-14T12:59:23Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T12:59:23Z'),
              endTime: new Date('2023-02-14T12:59:28Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T12:59:28Z'),
              endTime: new Date('2023-02-14T12:59:33Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T12:59:33Z'),
              endTime: new Date('2023-02-14T12:59:38Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T12:59:38Z'),
              endTime: new Date('2023-02-14T12:59:43Z'),
              valueState: null,
              valueNumber: 83,
            },
            {
              startTime: new Date('2023-02-14T12:59:43Z'),
              endTime: new Date('2023-02-14T12:59:48Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T12:59:48Z'),
              endTime: new Date('2023-02-14T12:59:53Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T12:59:53Z'),
              endTime: new Date('2023-02-14T12:59:58Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T12:59:58Z'),
              endTime: new Date('2023-02-14T13:00:03Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:00:03Z'),
              endTime: new Date('2023-02-14T13:00:08Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:00:08Z'),
              endTime: new Date('2023-02-14T13:00:13Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:00:13Z'),
              endTime: new Date('2023-02-14T13:00:18Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:00:18Z'),
              endTime: new Date('2023-02-14T13:00:23Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T13:00:23Z'),
              endTime: new Date('2023-02-14T13:00:28Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:00:28Z'),
              endTime: new Date('2023-02-14T13:00:33Z'),
              valueState: null,
              valueNumber: 80,
            },
            {
              startTime: new Date('2023-02-14T13:00:33Z'),
              endTime: new Date('2023-02-14T13:00:38Z'),
              valueState: null,
              valueNumber: 80,
            },
            {
              startTime: new Date('2023-02-14T13:00:38Z'),
              endTime: new Date('2023-02-14T13:00:43Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:00:43Z'),
              endTime: new Date('2023-02-14T13:00:48Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:00:48Z'),
              endTime: new Date('2023-02-14T13:00:53Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:00:53Z'),
              endTime: new Date('2023-02-14T13:00:58Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:00:58Z'),
              endTime: new Date('2023-02-14T13:01:03Z'),
              valueState: null,
              valueNumber: 83,
            },
            {
              startTime: new Date('2023-02-14T13:01:03Z'),
              endTime: new Date('2023-02-14T13:01:08Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:01:08Z'),
              endTime: new Date('2023-02-14T13:01:13Z'),
              valueState: null,
              valueNumber: 83,
            },
            {
              startTime: new Date('2023-02-14T13:01:13Z'),
              endTime: new Date('2023-02-14T13:01:18Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:01:18Z'),
              endTime: new Date('2023-02-14T13:01:23Z'),
              valueState: null,
              valueNumber: 83,
            },
            {
              startTime: new Date('2023-02-14T13:01:23Z'),
              endTime: new Date('2023-02-14T13:01:28Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:01:28Z'),
              endTime: new Date('2023-02-14T13:01:33Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:01:33Z'),
              endTime: new Date('2023-02-14T13:01:38Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:01:38Z'),
              endTime: new Date('2023-02-14T13:01:43Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:01:43Z'),
              endTime: new Date('2023-02-14T13:01:48Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:01:48Z'),
              endTime: new Date('2023-02-14T13:01:53Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:01:53Z'),
              endTime: new Date('2023-02-14T13:01:58Z'),
              valueState: null,
              valueNumber: 84,
            },
            {
              startTime: new Date('2023-02-14T13:01:58Z'),
              endTime: new Date('2023-02-14T13:02:03Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:02:03Z'),
              endTime: new Date('2023-02-14T13:02:08Z'),
              valueState: null,
              valueNumber: 81,
            },
            {
              startTime: new Date('2023-02-14T13:02:08Z'),
              endTime: new Date('2023-02-14T13:02:13Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:02:13Z'),
              endTime: new Date('2023-02-14T13:02:18Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:02:18Z'),
              endTime: new Date('2023-02-14T13:02:23Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:02:23Z'),
              endTime: new Date('2023-02-14T13:02:28Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:02:28Z'),
              endTime: new Date('2023-02-14T13:02:33Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:02:33Z'),
              endTime: new Date('2023-02-14T13:02:38Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:02:38Z'),
              endTime: new Date('2023-02-14T13:02:43Z'),
              valueState: null,
              valueNumber: 80,
            },
            {
              startTime: new Date('2023-02-14T13:02:43Z'),
              endTime: new Date('2023-02-14T13:02:48Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:02:48Z'),
              endTime: new Date('2023-02-14T13:02:53Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T13:02:53Z'),
              endTime: new Date('2023-02-14T13:02:58Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:02:58Z'),
              endTime: new Date('2023-02-14T13:03:03Z'),
              valueState: null,
              valueNumber: 80,
            },
            {
              startTime: new Date('2023-02-14T13:03:03Z'),
              endTime: new Date('2023-02-14T13:03:08Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:03:08Z'),
              endTime: new Date('2023-02-14T13:03:13Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:03:13Z'),
              endTime: new Date('2023-02-14T13:03:18Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T13:03:18Z'),
              endTime: new Date('2023-02-14T13:03:23Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:03:23Z'),
              endTime: new Date('2023-02-14T13:03:28Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:03:28Z'),
              endTime: new Date('2023-02-14T13:03:33Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:03:33Z'),
              endTime: new Date('2023-02-14T13:03:38Z'),
              valueState: null,
              valueNumber: 80,
            },
            {
              startTime: new Date('2023-02-14T13:03:38Z'),
              endTime: new Date('2023-02-14T13:03:43Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:03:43Z'),
              endTime: new Date('2023-02-14T13:03:48Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:03:48Z'),
              endTime: new Date('2023-02-14T13:03:53Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:03:53Z'),
              endTime: new Date('2023-02-14T13:03:58Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:03:58Z'),
              endTime: new Date('2023-02-14T13:04:03Z'),
              valueState: null,
              valueNumber: 94,
            },
            {
              startTime: new Date('2023-02-14T13:04:03Z'),
              endTime: new Date('2023-02-14T13:04:08Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T13:04:08Z'),
              endTime: new Date('2023-02-14T13:04:13Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:04:13Z'),
              endTime: new Date('2023-02-14T13:04:18Z'),
              valueState: null,
              valueNumber: 83,
            },
            {
              startTime: new Date('2023-02-14T13:04:18Z'),
              endTime: new Date('2023-02-14T13:04:23Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:04:23Z'),
              endTime: new Date('2023-02-14T13:04:28Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:04:28Z'),
              endTime: new Date('2023-02-14T13:04:33Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:04:33Z'),
              endTime: new Date('2023-02-14T13:04:38Z'),
              valueState: null,
              valueNumber: 95,
            },
            {
              startTime: new Date('2023-02-14T13:04:38Z'),
              endTime: new Date('2023-02-14T13:04:43Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T13:04:43Z'),
              endTime: new Date('2023-02-14T13:04:48Z'),
              valueState: null,
              valueNumber: 80,
            },
            {
              startTime: new Date('2023-02-14T13:04:48Z'),
              endTime: new Date('2023-02-14T13:04:53Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:04:53Z'),
              endTime: new Date('2023-02-14T13:04:58Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:04:58Z'),
              endTime: new Date('2023-02-14T13:05:03Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:05:03Z'),
              endTime: new Date('2023-02-14T13:05:08Z'),
              valueState: null,
              valueNumber: 81,
            },
            {
              startTime: new Date('2023-02-14T13:05:08Z'),
              endTime: new Date('2023-02-14T13:05:13Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:05:13Z'),
              endTime: new Date('2023-02-14T13:05:18Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:05:18Z'),
              endTime: new Date('2023-02-14T13:05:23Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:05:23Z'),
              endTime: new Date('2023-02-14T13:05:28Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:05:28Z'),
              endTime: new Date('2023-02-14T13:05:33Z'),
              valueState: null,
              valueNumber: 93,
            },
            {
              startTime: new Date('2023-02-14T13:05:33Z'),
              endTime: new Date('2023-02-14T13:05:38Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:05:38Z'),
              endTime: new Date('2023-02-14T13:05:43Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:05:43Z'),
              endTime: new Date('2023-02-14T13:05:48Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:05:48Z'),
              endTime: new Date('2023-02-14T13:05:53Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:05:53Z'),
              endTime: new Date('2023-02-14T13:05:58Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T13:05:58Z'),
              endTime: new Date('2023-02-14T13:06:03Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:06:03Z'),
              endTime: new Date('2023-02-14T13:06:08Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T13:06:08Z'),
              endTime: new Date('2023-02-14T13:06:13Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:06:13Z'),
              endTime: new Date('2023-02-14T13:06:18Z'),
              valueState: null,
              valueNumber: 81,
            },
            {
              startTime: new Date('2023-02-14T13:06:18Z'),
              endTime: new Date('2023-02-14T13:06:23Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:06:23Z'),
              endTime: new Date('2023-02-14T13:06:28Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:06:28Z'),
              endTime: new Date('2023-02-14T13:06:33Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:06:33Z'),
              endTime: new Date('2023-02-14T13:06:38Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:06:38Z'),
              endTime: new Date('2023-02-14T13:06:43Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:06:43Z'),
              endTime: new Date('2023-02-14T13:06:48Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:06:48Z'),
              endTime: new Date('2023-02-14T13:06:53Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:06:53Z'),
              endTime: new Date('2023-02-14T13:06:58Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:06:58Z'),
              endTime: new Date('2023-02-14T13:07:03Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:07:03Z'),
              endTime: new Date('2023-02-14T13:07:08Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:07:08Z'),
              endTime: new Date('2023-02-14T13:07:13Z'),
              valueState: null,
              valueNumber: 81,
            },
            {
              startTime: new Date('2023-02-14T13:07:13Z'),
              endTime: new Date('2023-02-14T13:07:18Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:07:18Z'),
              endTime: new Date('2023-02-14T13:07:23Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:07:23Z'),
              endTime: new Date('2023-02-14T13:07:28Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:07:28Z'),
              endTime: new Date('2023-02-14T13:07:33Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:07:33Z'),
              endTime: new Date('2023-02-14T13:07:38Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:07:38Z'),
              endTime: new Date('2023-02-14T13:07:43Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:07:43Z'),
              endTime: new Date('2023-02-14T13:07:48Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:07:48Z'),
              endTime: new Date('2023-02-14T13:07:53Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T13:07:53Z'),
              endTime: new Date('2023-02-14T13:07:58Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:07:58Z'),
              endTime: new Date('2023-02-14T13:08:03Z'),
              valueState: null,
              valueNumber: 84,
            },
            {
              startTime: new Date('2023-02-14T13:08:03Z'),
              endTime: new Date('2023-02-14T13:08:08Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T13:08:08Z'),
              endTime: new Date('2023-02-14T13:08:13Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:08:13Z'),
              endTime: new Date('2023-02-14T13:08:18Z'),
              valueState: null,
              valueNumber: 83,
            },
            {
              startTime: new Date('2023-02-14T13:08:18Z'),
              endTime: new Date('2023-02-14T13:08:23Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:08:23Z'),
              endTime: new Date('2023-02-14T13:08:28Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:08:28Z'),
              endTime: new Date('2023-02-14T13:08:33Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:08:33Z'),
              endTime: new Date('2023-02-14T13:08:38Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:08:38Z'),
              endTime: new Date('2023-02-14T13:08:43Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:08:43Z'),
              endTime: new Date('2023-02-14T13:08:48Z'),
              valueState: null,
              valueNumber: 81,
            },
            {
              startTime: new Date('2023-02-14T13:08:48Z'),
              endTime: new Date('2023-02-14T13:08:53Z'),
              valueState: null,
              valueNumber: 94,
            },
            {
              startTime: new Date('2023-02-14T13:08:53Z'),
              endTime: new Date('2023-02-14T13:08:58Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:08:58Z'),
              endTime: new Date('2023-02-14T13:09:03Z'),
              valueState: null,
              valueNumber: 80,
            },
            {
              startTime: new Date('2023-02-14T13:09:03Z'),
              endTime: new Date('2023-02-14T13:09:08Z'),
              valueState: null,
              valueNumber: 81,
            },
            {
              startTime: new Date('2023-02-14T13:09:08Z'),
              endTime: new Date('2023-02-14T13:09:13Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:09:13Z'),
              endTime: new Date('2023-02-14T13:09:18Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:09:18Z'),
              endTime: new Date('2023-02-14T13:09:23Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:09:23Z'),
              endTime: new Date('2023-02-14T13:09:28Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:09:28Z'),
              endTime: new Date('2023-02-14T13:09:33Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:09:33Z'),
              endTime: new Date('2023-02-14T13:09:38Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:09:38Z'),
              endTime: new Date('2023-02-14T13:09:43Z'),
              valueState: null,
              valueNumber: 81,
            },
            {
              startTime: new Date('2023-02-14T13:09:43Z'),
              endTime: new Date('2023-02-14T13:09:48Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:09:48Z'),
              endTime: new Date('2023-02-14T13:09:53Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:09:53Z'),
              endTime: new Date('2023-02-14T13:09:58Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:09:58Z'),
              endTime: new Date('2023-02-14T13:10:03Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:10:03Z'),
              endTime: new Date('2023-02-14T13:10:08Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:10:08Z'),
              endTime: new Date('2023-02-14T13:10:13Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:10:13Z'),
              endTime: new Date('2023-02-14T13:10:18Z'),
              valueState: null,
              valueNumber: 81,
            },
            {
              startTime: new Date('2023-02-14T13:10:18Z'),
              endTime: new Date('2023-02-14T13:10:23Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:10:23Z'),
              endTime: new Date('2023-02-14T13:10:28Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T13:10:28Z'),
              endTime: new Date('2023-02-14T13:10:33Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T13:10:33Z'),
              endTime: new Date('2023-02-14T13:10:38Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T13:10:38Z'),
              endTime: new Date('2023-02-14T13:10:43Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T13:10:43Z'),
              endTime: new Date('2023-02-14T13:10:48Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:10:48Z'),
              endTime: new Date('2023-02-14T13:10:53Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T13:10:53Z'),
              endTime: new Date('2023-02-14T13:10:58Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:10:58Z'),
              endTime: new Date('2023-02-14T13:11:03Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:11:03Z'),
              endTime: new Date('2023-02-14T13:11:08Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T13:11:08Z'),
              endTime: new Date('2023-02-14T13:11:13Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:11:13Z'),
              endTime: new Date('2023-02-14T13:11:18Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:11:18Z'),
              endTime: new Date('2023-02-14T13:11:23Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:11:23Z'),
              endTime: new Date('2023-02-14T13:11:28Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:11:28Z'),
              endTime: new Date('2023-02-14T13:11:33Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:11:33Z'),
              endTime: new Date('2023-02-14T13:11:38Z'),
              valueState: null,
              valueNumber: 73,
            },
            {
              startTime: new Date('2023-02-14T13:11:38Z'),
              endTime: new Date('2023-02-14T13:11:43Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:11:43Z'),
              endTime: new Date('2023-02-14T13:11:48Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:11:48Z'),
              endTime: new Date('2023-02-14T13:11:53Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:11:53Z'),
              endTime: new Date('2023-02-14T13:11:58Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:11:58Z'),
              endTime: new Date('2023-02-14T13:12:03Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:12:03Z'),
              endTime: new Date('2023-02-14T13:12:08Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:12:08Z'),
              endTime: new Date('2023-02-14T13:12:13Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:12:13Z'),
              endTime: new Date('2023-02-14T13:12:18Z'),
              valueState: null,
              valueNumber: 83,
            },
            {
              startTime: new Date('2023-02-14T13:12:18Z'),
              endTime: new Date('2023-02-14T13:12:23Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:12:23Z'),
              endTime: new Date('2023-02-14T13:12:28Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:12:28Z'),
              endTime: new Date('2023-02-14T13:12:33Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T13:12:33Z'),
              endTime: new Date('2023-02-14T13:12:38Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:12:38Z'),
              endTime: new Date('2023-02-14T13:12:43Z'),
              valueState: null,
              valueNumber: 80,
            },
            {
              startTime: new Date('2023-02-14T13:12:43Z'),
              endTime: new Date('2023-02-14T13:12:48Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:12:48Z'),
              endTime: new Date('2023-02-14T13:12:53Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:12:53Z'),
              endTime: new Date('2023-02-14T13:12:58Z'),
              valueState: null,
              valueNumber: 93,
            },
            {
              startTime: new Date('2023-02-14T13:12:58Z'),
              endTime: new Date('2023-02-14T13:13:03Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:13:03Z'),
              endTime: new Date('2023-02-14T13:13:08Z'),
              valueState: null,
              valueNumber: 80,
            },
            {
              startTime: new Date('2023-02-14T13:13:08Z'),
              endTime: new Date('2023-02-14T13:13:13Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:13:13Z'),
              endTime: new Date('2023-02-14T13:13:18Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:13:18Z'),
              endTime: new Date('2023-02-14T13:13:23Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:13:23Z'),
              endTime: new Date('2023-02-14T13:13:28Z'),
              valueState: null,
              valueNumber: 84,
            },
            {
              startTime: new Date('2023-02-14T13:13:28Z'),
              endTime: new Date('2023-02-14T13:13:33Z'),
              valueState: null,
              valueNumber: 93,
            },
            {
              startTime: new Date('2023-02-14T13:13:33Z'),
              endTime: new Date('2023-02-14T13:13:38Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T13:13:38Z'),
              endTime: new Date('2023-02-14T13:13:43Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:13:43Z'),
              endTime: new Date('2023-02-14T13:13:48Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:13:48Z'),
              endTime: new Date('2023-02-14T13:13:53Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T13:13:53Z'),
              endTime: new Date('2023-02-14T13:13:58Z'),
              valueState: null,
              valueNumber: 83,
            },
            {
              startTime: new Date('2023-02-14T13:13:58Z'),
              endTime: new Date('2023-02-14T13:14:03Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T13:14:03Z'),
              endTime: new Date('2023-02-14T13:14:08Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:14:08Z'),
              endTime: new Date('2023-02-14T13:14:13Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T13:14:13Z'),
              endTime: new Date('2023-02-14T13:14:18Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:14:18Z'),
              endTime: new Date('2023-02-14T13:14:23Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:14:23Z'),
              endTime: new Date('2023-02-14T13:14:28Z'),
              valueState: null,
              valueNumber: 77,
            },
            {
              startTime: new Date('2023-02-14T13:14:28Z'),
              endTime: new Date('2023-02-14T13:14:33Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T13:14:33Z'),
              endTime: new Date('2023-02-14T13:14:38Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:14:38Z'),
              endTime: new Date('2023-02-14T13:14:43Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T13:14:43Z'),
              endTime: new Date('2023-02-14T13:14:48Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:14:48Z'),
              endTime: new Date('2023-02-14T13:14:53Z'),
              valueState: null,
              valueNumber: 81,
            },
            {
              startTime: new Date('2023-02-14T13:14:53Z'),
              endTime: new Date('2023-02-14T13:14:58Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:14:58Z'),
              endTime: new Date('2023-02-14T13:15:03Z'),
              valueState: null,
              valueNumber: 74,
            },
            {
              startTime: new Date('2023-02-14T13:15:03Z'),
              endTime: new Date('2023-02-14T13:15:08Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:15:08Z'),
              endTime: new Date('2023-02-14T13:15:13Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:15:13Z'),
              endTime: new Date('2023-02-14T13:16:28Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:28Z'),
              endTime: new Date('2023-02-14T13:16:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:33Z'),
              endTime: new Date('2023-02-14T13:16:38Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T13:16:38Z'),
              endTime: new Date('2023-02-14T13:16:43Z'),
              valueState: null,
              valueNumber: 29,
            },
            {
              startTime: new Date('2023-02-14T13:16:43Z'),
              endTime: new Date('2023-02-14T13:16:48Z'),
              valueState: null,
              valueNumber: 32,
            },
            {
              startTime: new Date('2023-02-14T13:16:48Z'),
              endTime: new Date('2023-02-14T13:16:53Z'),
              valueState: null,
              valueNumber: 33,
            },
            {
              startTime: new Date('2023-02-14T13:16:53Z'),
              endTime: new Date('2023-02-14T13:16:58Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:16:58Z'),
              endTime: new Date('2023-02-14T13:17:03Z'),
              valueState: null,
              valueNumber: 38,
            },
            {
              startTime: new Date('2023-02-14T13:17:03Z'),
              endTime: new Date('2023-02-14T13:17:08Z'),
              valueState: null,
              valueNumber: 38,
            },
            {
              startTime: new Date('2023-02-14T13:17:08Z'),
              endTime: new Date('2023-02-14T13:17:13Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:17:13Z'),
              endTime: new Date('2023-02-14T13:17:18Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T13:17:18Z'),
              endTime: new Date('2023-02-14T13:17:23Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:17:23Z'),
              endTime: new Date('2023-02-14T13:17:28Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:17:28Z'),
              endTime: new Date('2023-02-14T13:17:33Z'),
              valueState: null,
              valueNumber: 11,
            },
            {
              startTime: new Date('2023-02-14T13:17:33Z'),
              endTime: new Date('2023-02-14T13:17:38Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:17:38Z'),
              endTime: new Date('2023-02-14T13:17:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:17:58Z'),
              endTime: new Date('2023-02-14T13:18:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:18:03Z'),
              endTime: new Date('2023-02-14T13:18:08Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:18:08Z'),
              endTime: new Date('2023-02-14T13:18:13Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:18:13Z'),
              endTime: new Date('2023-02-14T13:18:18Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:18:18Z'),
              endTime: new Date('2023-02-14T13:18:23Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:18:23Z'),
              endTime: new Date('2023-02-14T13:18:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:18:33Z'),
              endTime: new Date('2023-02-14T13:18:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:18:38Z'),
              endTime: new Date('2023-02-14T13:18:58Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:18:58Z'),
              endTime: new Date('2023-02-14T13:19:03Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:19:03Z'),
              endTime: new Date('2023-02-14T13:19:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:19:58Z'),
              endTime: new Date('2023-02-14T13:20:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:20:03Z'),
              endTime: new Date('2023-02-14T13:20:08Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:20:08Z'),
              endTime: new Date('2023-02-14T13:20:13Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:20:13Z'),
              endTime: new Date('2023-02-14T13:20:18Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:20:18Z'),
              endTime: new Date('2023-02-14T13:20:23Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:20:23Z'),
              endTime: new Date('2023-02-14T13:20:28Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:20:28Z'),
              endTime: new Date('2023-02-14T13:20:33Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:20:33Z'),
              endTime: new Date('2023-02-14T13:20:38Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:20:38Z'),
              endTime: new Date('2023-02-14T13:20:43Z'),
              valueState: null,
              valueNumber: 12,
            },
            {
              startTime: new Date('2023-02-14T13:20:43Z'),
              endTime: new Date('2023-02-14T13:20:48Z'),
              valueState: null,
              valueNumber: 33,
            },
            {
              startTime: new Date('2023-02-14T13:20:48Z'),
              endTime: new Date('2023-02-14T13:20:53Z'),
              valueState: null,
              valueNumber: 29,
            },
            {
              startTime: new Date('2023-02-14T13:20:53Z'),
              endTime: new Date('2023-02-14T13:20:58Z'),
              valueState: null,
              valueNumber: 15,
            },
            {
              startTime: new Date('2023-02-14T13:20:58Z'),
              endTime: new Date('2023-02-14T13:21:08Z'),
              valueState: null,
              valueNumber: 14,
            },
            {
              startTime: new Date('2023-02-14T13:21:08Z'),
              endTime: new Date('2023-02-14T13:21:13Z'),
              valueState: null,
              valueNumber: 14,
            },
            {
              startTime: new Date('2023-02-14T13:21:13Z'),
              endTime: new Date('2023-02-14T13:21:48Z'),
              valueState: null,
              valueNumber: 13,
            },
            {
              startTime: new Date('2023-02-14T13:21:48Z'),
              endTime: new Date('2023-02-14T13:21:53Z'),
              valueState: null,
              valueNumber: 13,
            },
            {
              startTime: new Date('2023-02-14T13:21:53Z'),
              endTime: new Date('2023-02-14T13:21:58Z'),
              valueState: null,
              valueNumber: 12,
            },
            {
              startTime: new Date('2023-02-14T13:21:58Z'),
              endTime: new Date('2023-02-14T13:22:03Z'),
              valueState: null,
              valueNumber: 10,
            },
            {
              startTime: new Date('2023-02-14T13:22:03Z'),
              endTime: new Date('2023-02-14T13:22:08Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:22:08Z'),
              endTime: new Date('2023-02-14T13:22:13Z'),
              valueState: null,
              valueNumber: 30,
            },
            {
              startTime: new Date('2023-02-14T13:22:13Z'),
              endTime: new Date('2023-02-14T13:22:18Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:22:18Z'),
              endTime: new Date('2023-02-14T13:22:23Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:22:23Z'),
              endTime: new Date('2023-02-14T13:22:28Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:22:28Z'),
              endTime: new Date('2023-02-14T13:22:33Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:22:33Z'),
              endTime: new Date('2023-02-14T13:22:38Z'),
              valueState: null,
              valueNumber: 38,
            },
            {
              startTime: new Date('2023-02-14T13:22:38Z'),
              endTime: new Date('2023-02-14T13:22:43Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:22:43Z'),
              endTime: new Date('2023-02-14T13:22:48Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:22:48Z'),
              endTime: new Date('2023-02-14T13:22:53Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:22:53Z'),
              endTime: new Date('2023-02-14T13:22:58Z'),
              valueState: null,
              valueNumber: 44,
            },
            {
              startTime: new Date('2023-02-14T13:22:58Z'),
              endTime: new Date('2023-02-14T13:23:03Z'),
              valueState: null,
              valueNumber: 43,
            },
            {
              startTime: new Date('2023-02-14T13:23:03Z'),
              endTime: new Date('2023-02-14T13:23:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:08Z'),
              endTime: new Date('2023-02-14T13:23:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:13Z'),
              endTime: new Date('2023-02-14T13:23:18Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:23:18Z'),
              endTime: new Date('2023-02-14T13:23:23Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:23:23Z'),
              endTime: new Date('2023-02-14T13:23:28Z'),
              valueState: null,
              valueNumber: 59,
            },
            {
              startTime: new Date('2023-02-14T13:23:28Z'),
              endTime: new Date('2023-02-14T13:23:33Z'),
              valueState: null,
              valueNumber: 63,
            },
            {
              startTime: new Date('2023-02-14T13:23:33Z'),
              endTime: new Date('2023-02-14T13:23:38Z'),
              valueState: null,
              valueNumber: 67,
            },
            {
              startTime: new Date('2023-02-14T13:23:38Z'),
              endTime: new Date('2023-02-14T13:23:43Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:23:43Z'),
              endTime: new Date('2023-02-14T13:23:48Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:23:48Z'),
              endTime: new Date('2023-02-14T13:23:53Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:23:53Z'),
              endTime: new Date('2023-02-14T13:23:58Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:23:58Z'),
              endTime: new Date('2023-02-14T13:24:03Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:24:03Z'),
              endTime: new Date('2023-02-14T13:24:08Z'),
              valueState: null,
              valueNumber: 94,
            },
            {
              startTime: new Date('2023-02-14T13:24:08Z'),
              endTime: new Date('2023-02-14T13:24:13Z'),
              valueState: null,
              valueNumber: 103,
            },
            {
              startTime: new Date('2023-02-14T13:24:13Z'),
              endTime: new Date('2023-02-14T13:24:18Z'),
              valueState: null,
              valueNumber: 106,
            },
            {
              startTime: new Date('2023-02-14T13:24:18Z'),
              endTime: new Date('2023-02-14T13:24:23Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:24:23Z'),
              endTime: new Date('2023-02-14T13:24:28Z'),
              valueState: null,
              valueNumber: 106,
            },
            {
              startTime: new Date('2023-02-14T13:24:28Z'),
              endTime: new Date('2023-02-14T13:24:33Z'),
              valueState: null,
              valueNumber: 114,
            },
            {
              startTime: new Date('2023-02-14T13:24:33Z'),
              endTime: new Date('2023-02-14T13:24:38Z'),
              valueState: null,
              valueNumber: 135,
            },
            {
              startTime: new Date('2023-02-14T13:24:38Z'),
              endTime: new Date('2023-02-14T13:24:43Z'),
              valueState: null,
              valueNumber: 117,
            },
            {
              startTime: new Date('2023-02-14T13:24:43Z'),
              endTime: new Date('2023-02-14T13:24:48Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:24:48Z'),
              endTime: new Date('2023-02-14T13:24:53Z'),
              valueState: null,
              valueNumber: 116,
            },
            {
              startTime: new Date('2023-02-14T13:24:53Z'),
              endTime: new Date('2023-02-14T13:24:58Z'),
              valueState: null,
              valueNumber: 119,
            },
            {
              startTime: new Date('2023-02-14T13:24:58Z'),
              endTime: new Date('2023-02-14T13:25:03Z'),
              valueState: null,
              valueNumber: 115,
            },
            {
              startTime: new Date('2023-02-14T13:25:03Z'),
              endTime: new Date('2023-02-14T13:25:08Z'),
              valueState: null,
              valueNumber: 116,
            },
            {
              startTime: new Date('2023-02-14T13:25:08Z'),
              endTime: new Date('2023-02-14T13:25:13Z'),
              valueState: null,
              valueNumber: 114,
            },
            {
              startTime: new Date('2023-02-14T13:25:13Z'),
              endTime: new Date('2023-02-14T13:25:18Z'),
              valueState: null,
              valueNumber: 117,
            },
            {
              startTime: new Date('2023-02-14T13:25:18Z'),
              endTime: new Date('2023-02-14T13:25:23Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:25:23Z'),
              endTime: new Date('2023-02-14T13:25:28Z'),
              valueState: null,
              valueNumber: 115,
            },
            {
              startTime: new Date('2023-02-14T13:25:28Z'),
              endTime: new Date('2023-02-14T13:25:33Z'),
              valueState: null,
              valueNumber: 126,
            },
            {
              startTime: new Date('2023-02-14T13:25:33Z'),
              endTime: new Date('2023-02-14T13:25:38Z'),
              valueState: null,
              valueNumber: 113,
            },
            {
              startTime: new Date('2023-02-14T13:25:38Z'),
              endTime: new Date('2023-02-14T13:25:43Z'),
              valueState: null,
              valueNumber: 120,
            },
            {
              startTime: new Date('2023-02-14T13:25:43Z'),
              endTime: new Date('2023-02-14T13:25:48Z'),
              valueState: null,
              valueNumber: 118,
            },
            {
              startTime: new Date('2023-02-14T13:25:48Z'),
              endTime: new Date('2023-02-14T13:25:53Z'),
              valueState: null,
              valueNumber: 119,
            },
            {
              startTime: new Date('2023-02-14T13:25:53Z'),
              endTime: new Date('2023-02-14T13:25:58Z'),
              valueState: null,
              valueNumber: 122,
            },
            {
              startTime: new Date('2023-02-14T13:25:58Z'),
              endTime: new Date('2023-02-14T13:26:03Z'),
              valueState: null,
              valueNumber: 116,
            },
            {
              startTime: new Date('2023-02-14T13:26:03Z'),
              endTime: new Date('2023-02-14T13:26:08Z'),
              valueState: null,
              valueNumber: 122,
            },
            {
              startTime: new Date('2023-02-14T13:26:08Z'),
              endTime: new Date('2023-02-14T13:26:13Z'),
              valueState: null,
              valueNumber: 117,
            },
            {
              startTime: new Date('2023-02-14T13:26:13Z'),
              endTime: new Date('2023-02-14T13:26:18Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:26:18Z'),
              endTime: new Date('2023-02-14T13:26:23Z'),
              valueState: null,
              valueNumber: 114,
            },
            {
              startTime: new Date('2023-02-14T13:26:23Z'),
              endTime: new Date('2023-02-14T13:26:28Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:26:28Z'),
              endTime: new Date('2023-02-14T13:26:33Z'),
              valueState: null,
              valueNumber: 114,
            },
            {
              startTime: new Date('2023-02-14T13:26:33Z'),
              endTime: new Date('2023-02-14T13:26:38Z'),
              valueState: null,
              valueNumber: 119,
            },
            {
              startTime: new Date('2023-02-14T13:26:38Z'),
              endTime: new Date('2023-02-14T13:26:43Z'),
              valueState: null,
              valueNumber: 113,
            },
            {
              startTime: new Date('2023-02-14T13:26:43Z'),
              endTime: new Date('2023-02-14T13:26:48Z'),
              valueState: null,
              valueNumber: 112,
            },
            {
              startTime: new Date('2023-02-14T13:26:48Z'),
              endTime: new Date('2023-02-14T13:26:53Z'),
              valueState: null,
              valueNumber: 123,
            },
            {
              startTime: new Date('2023-02-14T13:26:53Z'),
              endTime: new Date('2023-02-14T13:26:58Z'),
              valueState: null,
              valueNumber: 116,
            },
            {
              startTime: new Date('2023-02-14T13:26:58Z'),
              endTime: new Date('2023-02-14T13:27:03Z'),
              valueState: null,
              valueNumber: 127,
            },
            {
              startTime: new Date('2023-02-14T13:27:03Z'),
              endTime: new Date('2023-02-14T13:27:08Z'),
              valueState: null,
              valueNumber: 114,
            },
            {
              startTime: new Date('2023-02-14T13:27:08Z'),
              endTime: new Date('2023-02-14T13:27:13Z'),
              valueState: null,
              valueNumber: 94,
            },
            {
              startTime: new Date('2023-02-14T13:27:13Z'),
              endTime: new Date('2023-02-14T13:27:18Z'),
              valueState: null,
              valueNumber: 70,
            },
            {
              startTime: new Date('2023-02-14T13:27:18Z'),
              endTime: new Date('2023-02-14T13:27:23Z'),
              valueState: null,
              valueNumber: 43,
            },
            {
              startTime: new Date('2023-02-14T13:27:23Z'),
              endTime: new Date('2023-02-14T13:27:28Z'),
              valueState: null,
              valueNumber: 8,
            },
            {
              startTime: new Date('2023-02-14T13:27:28Z'),
              endTime: new Date('2023-02-14T13:28:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:38Z'),
              endTime: new Date('2023-02-14T13:28:43Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:43Z'),
              endTime: new Date('2023-02-14T13:28:48Z'),
              valueState: null,
              valueNumber: 20,
            },
            {
              startTime: new Date('2023-02-14T13:28:48Z'),
              endTime: new Date('2023-02-14T13:28:53Z'),
              valueState: null,
              valueNumber: 31,
            },
            {
              startTime: new Date('2023-02-14T13:28:53Z'),
              endTime: new Date('2023-02-14T13:28:58Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:28:58Z'),
              endTime: new Date('2023-02-14T13:29:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:29:03Z'),
              endTime: new Date('2023-02-14T13:29:08Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:29:08Z'),
              endTime: new Date('2023-02-14T13:29:13Z'),
              valueState: null,
              valueNumber: 10,
            },
            {
              startTime: new Date('2023-02-14T13:29:13Z'),
              endTime: new Date('2023-02-14T13:29:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:29:38Z'),
              endTime: new Date('2023-02-14T13:29:43Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:29:43Z'),
              endTime: new Date('2023-02-14T13:29:48Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T13:29:48Z'),
              endTime: new Date('2023-02-14T13:29:53Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:29:53Z'),
              endTime: new Date('2023-02-14T13:29:58Z'),
              valueState: null,
              valueNumber: 15,
            },
            {
              startTime: new Date('2023-02-14T13:29:58Z'),
              endTime: new Date('2023-02-14T13:30:18Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:30:18Z'),
              endTime: new Date('2023-02-14T13:30:23Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:30:23Z'),
              endTime: new Date('2023-02-14T13:30:38Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:30:38Z'),
              endTime: new Date('2023-02-14T13:30:43Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:30:43Z'),
              endTime: new Date('2023-02-14T13:31:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:31:38Z'),
              endTime: new Date('2023-02-14T13:31:43Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:31:43Z'),
              endTime: new Date('2023-02-14T13:31:48Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:31:48Z'),
              endTime: new Date('2023-02-14T13:31:53Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:31:53Z'),
              endTime: new Date('2023-02-14T13:31:58Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:31:58Z'),
              endTime: new Date('2023-02-14T13:32:03Z'),
              valueState: null,
              valueNumber: 4,
            },
            {
              startTime: new Date('2023-02-14T13:32:03Z'),
              endTime: new Date('2023-02-14T13:32:08Z'),
              valueState: null,
              valueNumber: 18,
            },
            {
              startTime: new Date('2023-02-14T13:32:08Z'),
              endTime: new Date('2023-02-14T13:32:13Z'),
              valueState: null,
              valueNumber: 11,
            },
            {
              startTime: new Date('2023-02-14T13:32:13Z'),
              endTime: new Date('2023-02-14T13:32:18Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:32:18Z'),
              endTime: new Date('2023-02-14T13:32:23Z'),
              valueState: null,
              valueNumber: 11,
            },
            {
              startTime: new Date('2023-02-14T13:32:23Z'),
              endTime: new Date('2023-02-14T13:32:28Z'),
              valueState: null,
              valueNumber: 12,
            },
            {
              startTime: new Date('2023-02-14T13:32:28Z'),
              endTime: new Date('2023-02-14T13:32:33Z'),
              valueState: null,
              valueNumber: 12,
            },
            {
              startTime: new Date('2023-02-14T13:32:33Z'),
              endTime: new Date('2023-02-14T13:33:38Z'),
              valueState: null,
              valueNumber: 13,
            },
            {
              startTime: new Date('2023-02-14T13:33:38Z'),
              endTime: new Date('2023-02-14T13:33:43Z'),
              valueState: null,
              valueNumber: 13,
            },
            {
              startTime: new Date('2023-02-14T13:33:43Z'),
              endTime: new Date('2023-02-14T13:33:48Z'),
              valueState: null,
              valueNumber: 11,
            },
            {
              startTime: new Date('2023-02-14T13:33:48Z'),
              endTime: new Date('2023-02-14T13:33:53Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:33:53Z'),
              endTime: new Date('2023-02-14T13:33:58Z'),
              valueState: null,
              valueNumber: 30,
            },
            {
              startTime: new Date('2023-02-14T13:33:58Z'),
              endTime: new Date('2023-02-14T13:34:03Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:34:03Z'),
              endTime: new Date('2023-02-14T13:34:08Z'),
              valueState: null,
              valueNumber: 41,
            },
            {
              startTime: new Date('2023-02-14T13:34:08Z'),
              endTime: new Date('2023-02-14T13:34:13Z'),
              valueState: null,
              valueNumber: 41,
            },
            {
              startTime: new Date('2023-02-14T13:34:13Z'),
              endTime: new Date('2023-02-14T13:34:18Z'),
              valueState: null,
              valueNumber: 38,
            },
            {
              startTime: new Date('2023-02-14T13:34:18Z'),
              endTime: new Date('2023-02-14T13:34:23Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:34:23Z'),
              endTime: new Date('2023-02-14T13:34:28Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:34:28Z'),
              endTime: new Date('2023-02-14T13:34:33Z'),
              valueState: null,
              valueNumber: 42,
            },
            {
              startTime: new Date('2023-02-14T13:34:33Z'),
              endTime: new Date('2023-02-14T13:34:38Z'),
              valueState: null,
              valueNumber: 42,
            },
            {
              startTime: new Date('2023-02-14T13:34:38Z'),
              endTime: new Date('2023-02-14T13:34:43Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:34:43Z'),
              endTime: new Date('2023-02-14T13:34:48Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:34:48Z'),
              endTime: new Date('2023-02-14T13:34:53Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:53Z'),
              endTime: new Date('2023-02-14T13:34:58Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:58Z'),
              endTime: new Date('2023-02-14T13:35:03Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:35:03Z'),
              endTime: new Date('2023-02-14T13:35:08Z'),
              valueState: null,
              valueNumber: 58,
            },
            {
              startTime: new Date('2023-02-14T13:35:08Z'),
              endTime: new Date('2023-02-14T13:35:13Z'),
              valueState: null,
              valueNumber: 62,
            },
            {
              startTime: new Date('2023-02-14T13:35:13Z'),
              endTime: new Date('2023-02-14T13:35:18Z'),
              valueState: null,
              valueNumber: 64,
            },
            {
              startTime: new Date('2023-02-14T13:35:18Z'),
              endTime: new Date('2023-02-14T13:35:23Z'),
              valueState: null,
              valueNumber: 68,
            },
            {
              startTime: new Date('2023-02-14T13:35:23Z'),
              endTime: new Date('2023-02-14T13:35:28Z'),
              valueState: null,
              valueNumber: 76,
            },
            {
              startTime: new Date('2023-02-14T13:35:28Z'),
              endTime: new Date('2023-02-14T13:35:33Z'),
              valueState: null,
              valueNumber: 78,
            },
            {
              startTime: new Date('2023-02-14T13:35:33Z'),
              endTime: new Date('2023-02-14T13:35:38Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:35:38Z'),
              endTime: new Date('2023-02-14T13:35:43Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:35:43Z'),
              endTime: new Date('2023-02-14T13:35:48Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:35:48Z'),
              endTime: new Date('2023-02-14T13:35:53Z'),
              valueState: null,
              valueNumber: 93,
            },
            {
              startTime: new Date('2023-02-14T13:35:53Z'),
              endTime: new Date('2023-02-14T13:35:58Z'),
              valueState: null,
              valueNumber: 105,
            },
            {
              startTime: new Date('2023-02-14T13:35:58Z'),
              endTime: new Date('2023-02-14T13:36:03Z'),
              valueState: null,
              valueNumber: 109,
            },
            {
              startTime: new Date('2023-02-14T13:36:03Z'),
              endTime: new Date('2023-02-14T13:36:08Z'),
              valueState: null,
              valueNumber: 104,
            },
            {
              startTime: new Date('2023-02-14T13:36:08Z'),
              endTime: new Date('2023-02-14T13:36:13Z'),
              valueState: null,
              valueNumber: 106,
            },
            {
              startTime: new Date('2023-02-14T13:36:13Z'),
              endTime: new Date('2023-02-14T13:36:18Z'),
              valueState: null,
              valueNumber: 104,
            },
            {
              startTime: new Date('2023-02-14T13:36:18Z'),
              endTime: new Date('2023-02-14T13:36:23Z'),
              valueState: null,
              valueNumber: 116,
            },
            {
              startTime: new Date('2023-02-14T13:36:23Z'),
              endTime: new Date('2023-02-14T13:36:28Z'),
              valueState: null,
              valueNumber: 113,
            },
            {
              startTime: new Date('2023-02-14T13:36:28Z'),
              endTime: new Date('2023-02-14T13:36:33Z'),
              valueState: null,
              valueNumber: 122,
            },
            {
              startTime: new Date('2023-02-14T13:36:33Z'),
              endTime: new Date('2023-02-14T13:36:38Z'),
              valueState: null,
              valueNumber: 106,
            },
            {
              startTime: new Date('2023-02-14T13:36:38Z'),
              endTime: new Date('2023-02-14T13:36:43Z'),
              valueState: null,
              valueNumber: 113,
            },
            {
              startTime: new Date('2023-02-14T13:36:43Z'),
              endTime: new Date('2023-02-14T13:36:48Z'),
              valueState: null,
              valueNumber: 109,
            },
            {
              startTime: new Date('2023-02-14T13:36:48Z'),
              endTime: new Date('2023-02-14T13:36:53Z'),
              valueState: null,
              valueNumber: 112,
            },
            {
              startTime: new Date('2023-02-14T13:36:53Z'),
              endTime: new Date('2023-02-14T13:36:58Z'),
              valueState: null,
              valueNumber: 113,
            },
            {
              startTime: new Date('2023-02-14T13:36:58Z'),
              endTime: new Date('2023-02-14T13:37:03Z'),
              valueState: null,
              valueNumber: 111,
            },
            {
              startTime: new Date('2023-02-14T13:37:03Z'),
              endTime: new Date('2023-02-14T13:37:08Z'),
              valueState: null,
              valueNumber: 120,
            },
            {
              startTime: new Date('2023-02-14T13:37:08Z'),
              endTime: new Date('2023-02-14T13:37:13Z'),
              valueState: null,
              valueNumber: 111,
            },
            {
              startTime: new Date('2023-02-14T13:37:13Z'),
              endTime: new Date('2023-02-14T13:37:18Z'),
              valueState: null,
              valueNumber: 125,
            },
            {
              startTime: new Date('2023-02-14T13:37:18Z'),
              endTime: new Date('2023-02-14T13:37:23Z'),
              valueState: null,
              valueNumber: 107,
            },
            {
              startTime: new Date('2023-02-14T13:37:23Z'),
              endTime: new Date('2023-02-14T13:37:28Z'),
              valueState: null,
              valueNumber: 109,
            },
            {
              startTime: new Date('2023-02-14T13:37:28Z'),
              endTime: new Date('2023-02-14T13:37:33Z'),
              valueState: null,
              valueNumber: 107,
            },
            {
              startTime: new Date('2023-02-14T13:37:33Z'),
              endTime: new Date('2023-02-14T13:37:43Z'),
              valueState: null,
              valueNumber: 109,
            },
            {
              startTime: new Date('2023-02-14T13:37:43Z'),
              endTime: new Date('2023-02-14T13:37:48Z'),
              valueState: null,
              valueNumber: 109,
            },
            {
              startTime: new Date('2023-02-14T13:37:48Z'),
              endTime: new Date('2023-02-14T13:37:53Z'),
              valueState: null,
              valueNumber: 110,
            },
            {
              startTime: new Date('2023-02-14T13:37:53Z'),
              endTime: new Date('2023-02-14T13:37:58Z'),
              valueState: null,
              valueNumber: 107,
            },
            {
              startTime: new Date('2023-02-14T13:37:58Z'),
              endTime: new Date('2023-02-14T13:38:03Z'),
              valueState: null,
              valueNumber: 124,
            },
            {
              startTime: new Date('2023-02-14T13:38:03Z'),
              endTime: new Date('2023-02-14T13:38:08Z'),
              valueState: null,
              valueNumber: 107,
            },
            {
              startTime: new Date('2023-02-14T13:38:08Z'),
              endTime: new Date('2023-02-14T13:38:13Z'),
              valueState: null,
              valueNumber: 119,
            },
            {
              startTime: new Date('2023-02-14T13:38:13Z'),
              endTime: new Date('2023-02-14T13:38:18Z'),
              valueState: null,
              valueNumber: 108,
            },
            {
              startTime: new Date('2023-02-14T13:38:18Z'),
              endTime: new Date('2023-02-14T13:38:23Z'),
              valueState: null,
              valueNumber: 113,
            },
            {
              startTime: new Date('2023-02-14T13:38:23Z'),
              endTime: new Date('2023-02-14T13:38:28Z'),
              valueState: null,
              valueNumber: 108,
            },
            {
              startTime: new Date('2023-02-14T13:38:28Z'),
              endTime: new Date('2023-02-14T13:38:33Z'),
              valueState: null,
              valueNumber: 109,
            },
            {
              startTime: new Date('2023-02-14T13:38:33Z'),
              endTime: new Date('2023-02-14T13:38:38Z'),
              valueState: null,
              valueNumber: 114,
            },
            {
              startTime: new Date('2023-02-14T13:38:38Z'),
              endTime: new Date('2023-02-14T13:38:43Z'),
              valueState: null,
              valueNumber: 107,
            },
            {
              startTime: new Date('2023-02-14T13:38:43Z'),
              endTime: new Date('2023-02-14T13:38:48Z'),
              valueState: null,
              valueNumber: 119,
            },
            {
              startTime: new Date('2023-02-14T13:38:48Z'),
              endTime: new Date('2023-02-14T13:38:53Z'),
              valueState: null,
              valueNumber: 106,
            },
            {
              startTime: new Date('2023-02-14T13:38:53Z'),
              endTime: new Date('2023-02-14T13:38:58Z'),
              valueState: null,
              valueNumber: 121,
            },
            {
              startTime: new Date('2023-02-14T13:38:58Z'),
              endTime: new Date('2023-02-14T13:39:03Z'),
              valueState: null,
              valueNumber: 108,
            },
            {
              startTime: new Date('2023-02-14T13:39:03Z'),
              endTime: new Date('2023-02-14T13:39:08Z'),
              valueState: null,
              valueNumber: 114,
            },
            {
              startTime: new Date('2023-02-14T13:39:08Z'),
              endTime: new Date('2023-02-14T13:39:13Z'),
              valueState: null,
              valueNumber: 106,
            },
            {
              startTime: new Date('2023-02-14T13:39:13Z'),
              endTime: new Date('2023-02-14T13:39:18Z'),
              valueState: null,
              valueNumber: 107,
            },
            {
              startTime: new Date('2023-02-14T13:39:18Z'),
              endTime: new Date('2023-02-14T13:39:23Z'),
              valueState: null,
              valueNumber: 107,
            },
            {
              startTime: new Date('2023-02-14T13:39:23Z'),
              endTime: new Date('2023-02-14T13:39:28Z'),
              valueState: null,
              valueNumber: 96,
            },
            {
              startTime: new Date('2023-02-14T13:39:28Z'),
              endTime: new Date('2023-02-14T13:39:33Z'),
              valueState: null,
              valueNumber: 68,
            },
            {
              startTime: new Date('2023-02-14T13:39:33Z'),
              endTime: new Date('2023-02-14T13:39:38Z'),
              valueState: null,
              valueNumber: 43,
            },
            {
              startTime: new Date('2023-02-14T13:39:38Z'),
              endTime: new Date('2023-02-14T13:39:43Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:39:43Z'),
              endTime: new Date('2023-02-14T13:40:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:53Z'),
              endTime: new Date('2023-02-14T13:40:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:58Z'),
              endTime: new Date('2023-02-14T13:41:03Z'),
              valueState: null,
              valueNumber: 18,
            },
            {
              startTime: new Date('2023-02-14T13:41:03Z'),
              endTime: new Date('2023-02-14T13:41:08Z'),
              valueState: null,
              valueNumber: 30,
            },
            {
              startTime: new Date('2023-02-14T13:41:08Z'),
              endTime: new Date('2023-02-14T13:41:13Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:41:13Z'),
              endTime: new Date('2023-02-14T13:41:18Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:41:18Z'),
              endTime: new Date('2023-02-14T13:41:23Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:41:23Z'),
              endTime: new Date('2023-02-14T13:41:28Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:41:28Z'),
              endTime: new Date('2023-02-14T13:41:33Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:41:33Z'),
              endTime: new Date('2023-02-14T13:41:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:41:38Z'),
              endTime: new Date('2023-02-14T13:41:43Z'),
              valueState: null,
              valueNumber: 8,
            },
            {
              startTime: new Date('2023-02-14T13:41:43Z'),
              endTime: new Date('2023-02-14T13:41:48Z'),
              valueState: null,
              valueNumber: 11,
            },
            {
              startTime: new Date('2023-02-14T13:41:48Z'),
              endTime: new Date('2023-02-14T13:42:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:42:13Z'),
              endTime: new Date('2023-02-14T13:42:18Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:42:18Z'),
              endTime: new Date('2023-02-14T13:42:23Z'),
              valueState: null,
              valueNumber: 4,
            },
            {
              startTime: new Date('2023-02-14T13:42:23Z'),
              endTime: new Date('2023-02-14T13:42:28Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:42:28Z'),
              endTime: new Date('2023-02-14T13:42:33Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:42:33Z'),
              endTime: new Date('2023-02-14T13:42:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:42:53Z'),
              endTime: new Date('2023-02-14T13:42:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:42:58Z'),
              endTime: new Date('2023-02-14T13:43:08Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:43:08Z'),
              endTime: new Date('2023-02-14T13:43:13Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:43:13Z'),
              endTime: new Date('2023-02-14T13:44:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:44:13Z'),
              endTime: new Date('2023-02-14T13:44:18Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:44:18Z'),
              endTime: new Date('2023-02-14T13:44:23Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:44:23Z'),
              endTime: new Date('2023-02-14T13:44:28Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:44:28Z'),
              endTime: new Date('2023-02-14T13:44:33Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:44:33Z'),
              endTime: new Date('2023-02-14T13:44:38Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:44:38Z'),
              endTime: new Date('2023-02-14T13:44:43Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:44:43Z'),
              endTime: new Date('2023-02-14T13:44:48Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:44:48Z'),
              endTime: new Date('2023-02-14T13:44:53Z'),
              valueState: null,
              valueNumber: 8,
            },
            {
              startTime: new Date('2023-02-14T13:44:53Z'),
              endTime: new Date('2023-02-14T13:44:58Z'),
              valueState: null,
              valueNumber: 15,
            },
            {
              startTime: new Date('2023-02-14T13:44:58Z'),
              endTime: new Date('2023-02-14T13:45:18Z'),
              valueState: null,
              valueNumber: 13,
            },
            {
              startTime: new Date('2023-02-14T13:45:18Z'),
              endTime: new Date('2023-02-14T13:45:23Z'),
              valueState: null,
              valueNumber: 13,
            },
            {
              startTime: new Date('2023-02-14T13:45:23Z'),
              endTime: new Date('2023-02-14T13:45:28Z'),
              valueState: null,
              valueNumber: 16,
            },
            {
              startTime: new Date('2023-02-14T13:45:28Z'),
              endTime: new Date('2023-02-14T13:45:33Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T13:45:33Z'),
              endTime: new Date('2023-02-14T13:45:38Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T13:45:38Z'),
              endTime: new Date('2023-02-14T13:45:43Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:45:43Z'),
              endTime: new Date('2023-02-14T13:45:48Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:45:48Z'),
              endTime: new Date('2023-02-14T13:45:53Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:45:53Z'),
              endTime: new Date('2023-02-14T13:45:58Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:45:58Z'),
              endTime: new Date('2023-02-14T13:46:03Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T13:46:03Z'),
              endTime: new Date('2023-02-14T13:46:08Z'),
              valueState: null,
              valueNumber: 29,
            },
            {
              startTime: new Date('2023-02-14T13:46:08Z'),
              endTime: new Date('2023-02-14T13:46:13Z'),
              valueState: null,
              valueNumber: 31,
            },
            {
              startTime: new Date('2023-02-14T13:46:13Z'),
              endTime: new Date('2023-02-14T13:46:18Z'),
              valueState: null,
              valueNumber: 33,
            },
            {
              startTime: new Date('2023-02-14T13:46:18Z'),
              endTime: new Date('2023-02-14T13:46:23Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:46:23Z'),
              endTime: new Date('2023-02-14T13:46:28Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:46:28Z'),
              endTime: new Date('2023-02-14T13:46:48Z'),
              valueState: null,
              valueNumber: 34,
            },
            {
              startTime: new Date('2023-02-14T13:46:48Z'),
              endTime: new Date('2023-02-14T13:46:53Z'),
              valueState: null,
              valueNumber: 34,
            },
            {
              startTime: new Date('2023-02-14T13:46:53Z'),
              endTime: new Date('2023-02-14T13:47:08Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:47:08Z'),
              endTime: new Date('2023-02-14T13:47:13Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:47:13Z'),
              endTime: new Date('2023-02-14T13:47:23Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:47:23Z'),
              endTime: new Date('2023-02-14T13:47:28Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:47:28Z'),
              endTime: new Date('2023-02-14T13:47:33Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:47:33Z'),
              endTime: new Date('2023-02-14T13:47:43Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:47:43Z'),
              endTime: new Date('2023-02-14T13:47:48Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:47:48Z'),
              endTime: new Date('2023-02-14T13:48:58Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:48:58Z'),
              endTime: new Date('2023-02-14T13:49:03Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:49:03Z'),
              endTime: new Date('2023-02-14T13:49:38Z'),
              valueState: null,
              valueNumber: 34,
            },
            {
              startTime: new Date('2023-02-14T13:49:38Z'),
              endTime: new Date('2023-02-14T13:49:43Z'),
              valueState: null,
              valueNumber: 34,
            },
            {
              startTime: new Date('2023-02-14T13:49:43Z'),
              endTime: new Date('2023-02-14T13:49:48Z'),
              valueState: null,
              valueNumber: 30,
            },
            {
              startTime: new Date('2023-02-14T13:49:48Z'),
              endTime: new Date('2023-02-14T13:49:53Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:49:53Z'),
              endTime: new Date('2023-02-14T13:49:58Z'),
              valueState: null,
              valueNumber: 20,
            },
            {
              startTime: new Date('2023-02-14T13:49:58Z'),
              endTime: new Date('2023-02-14T13:50:03Z'),
              valueState: null,
              valueNumber: 15,
            },
            {
              startTime: new Date('2023-02-14T13:50:03Z'),
              endTime: new Date('2023-02-14T13:50:08Z'),
              valueState: null,
              valueNumber: 11,
            },
            {
              startTime: new Date('2023-02-14T13:50:08Z'),
              endTime: new Date('2023-02-14T13:50:13Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:50:13Z'),
              endTime: new Date('2023-02-14T13:50:18Z'),
              valueState: null,
              valueNumber: 4,
            },
            {
              startTime: new Date('2023-02-14T13:50:18Z'),
              endTime: new Date('2023-02-14T13:50:23Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:50:23Z'),
              endTime: new Date('2023-02-14T13:50:28Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:28Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'mm Setpoint',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T12:54:48Z'),
              valueState: null,
              valueNumber: 113,
            },
            {
              startTime: new Date('2023-02-14T12:54:48Z'),
              endTime: new Date('2023-02-14T12:54:53Z'),
              valueState: null,
              valueNumber: 113,
            },
            {
              startTime: new Date('2023-02-14T12:54:53Z'),
              endTime: new Date('2023-02-14T13:21:53Z'),
              valueState: null,
              valueNumber: 73,
            },
            {
              startTime: new Date('2023-02-14T13:21:53Z'),
              endTime: new Date('2023-02-14T13:21:58Z'),
              valueState: null,
              valueNumber: 73,
            },
            {
              startTime: new Date('2023-02-14T13:21:58Z'),
              endTime: new Date('2023-02-14T13:50:28Z'),
              valueState: null,
              valueNumber: 113,
            },
            {
              startTime: new Date('2023-02-14T13:50:28Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 113,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 113,
            },
          ],
        },
      ],
      valueStates: [],
      annotations: undefined,
    },
    {
      title: 'Spin Speed',
      datasets: [
        {
          label: 'rpm',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T12:53:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:30Z'),
              endTime: new Date('2023-02-14T12:53:31Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:31Z'),
              endTime: new Date('2023-02-14T12:53:32Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T12:53:32Z'),
              endTime: new Date('2023-02-14T12:53:33Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T12:53:33Z'),
              endTime: new Date('2023-02-14T12:53:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:37Z'),
              endTime: new Date('2023-02-14T12:53:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:38Z'),
              endTime: new Date('2023-02-14T12:53:41Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T12:53:41Z'),
              endTime: new Date('2023-02-14T12:53:42Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T12:53:42Z'),
              endTime: new Date('2023-02-14T12:53:43Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:53:43Z'),
              endTime: new Date('2023-02-14T12:53:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:48Z'),
              endTime: new Date('2023-02-14T12:53:49Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:49Z'),
              endTime: new Date('2023-02-14T12:53:50Z'),
              valueState: null,
              valueNumber: 32,
            },
            {
              startTime: new Date('2023-02-14T12:53:50Z'),
              endTime: new Date('2023-02-14T12:53:51Z'),
              valueState: null,
              valueNumber: 32,
            },
            {
              startTime: new Date('2023-02-14T12:53:51Z'),
              endTime: new Date('2023-02-14T12:53:52Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:53:52Z'),
              endTime: new Date('2023-02-14T12:53:53Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T12:53:53Z'),
              endTime: new Date('2023-02-14T12:53:54Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:53:54Z'),
              endTime: new Date('2023-02-14T12:53:55Z'),
              valueState: null,
              valueNumber: 20,
            },
            {
              startTime: new Date('2023-02-14T12:53:55Z'),
              endTime: new Date('2023-02-14T12:53:56Z'),
              valueState: null,
              valueNumber: 20,
            },
            {
              startTime: new Date('2023-02-14T12:53:56Z'),
              endTime: new Date('2023-02-14T12:54:01Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:01Z'),
              endTime: new Date('2023-02-14T12:54:02Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:02Z'),
              endTime: new Date('2023-02-14T12:54:05Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:05Z'),
              endTime: new Date('2023-02-14T12:54:06Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:06Z'),
              endTime: new Date('2023-02-14T12:54:07Z'),
              valueState: null,
              valueNumber: 8,
            },
            {
              startTime: new Date('2023-02-14T12:54:07Z'),
              endTime: new Date('2023-02-14T12:54:12Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:12Z'),
              endTime: new Date('2023-02-14T12:54:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:13Z'),
              endTime: new Date('2023-02-14T12:54:14Z'),
              valueState: null,
              valueNumber: 29,
            },
            {
              startTime: new Date('2023-02-14T12:54:14Z'),
              endTime: new Date('2023-02-14T12:54:15Z'),
              valueState: null,
              valueNumber: 29,
            },
            {
              startTime: new Date('2023-02-14T12:54:15Z'),
              endTime: new Date('2023-02-14T12:54:16Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:16Z'),
              endTime: new Date('2023-02-14T12:54:18Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T12:54:18Z'),
              endTime: new Date('2023-02-14T12:54:19Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T12:54:19Z'),
              endTime: new Date('2023-02-14T12:54:24Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:24Z'),
              endTime: new Date('2023-02-14T12:54:25Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:25Z'),
              endTime: new Date('2023-02-14T12:54:26Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T12:54:26Z'),
              endTime: new Date('2023-02-14T12:54:27Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:27Z'),
              endTime: new Date('2023-02-14T12:54:28Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:28Z'),
              endTime: new Date('2023-02-14T12:54:29Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T12:54:29Z'),
              endTime: new Date('2023-02-14T12:54:30Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T12:54:30Z'),
              endTime: new Date('2023-02-14T12:54:31Z'),
              valueState: null,
              valueNumber: 14,
            },
            {
              startTime: new Date('2023-02-14T12:54:31Z'),
              endTime: new Date('2023-02-14T12:54:32Z'),
              valueState: null,
              valueNumber: 14,
            },
            {
              startTime: new Date('2023-02-14T12:54:32Z'),
              endTime: new Date('2023-02-14T12:54:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:37Z'),
              endTime: new Date('2023-02-14T12:54:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:38Z'),
              endTime: new Date('2023-02-14T12:54:39Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:39Z'),
              endTime: new Date('2023-02-14T12:54:40Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T12:54:40Z'),
              endTime: new Date('2023-02-14T12:54:41Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:41Z'),
              endTime: new Date('2023-02-14T12:54:42Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:42Z'),
              endTime: new Date('2023-02-14T12:54:43Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T12:54:43Z'),
              endTime: new Date('2023-02-14T12:54:47Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:47Z'),
              endTime: new Date('2023-02-14T12:54:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:48Z'),
              endTime: new Date('2023-02-14T12:54:49Z'),
              valueState: null,
              valueNumber: 28,
            },
            {
              startTime: new Date('2023-02-14T12:54:49Z'),
              endTime: new Date('2023-02-14T12:54:50Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T12:54:50Z'),
              endTime: new Date('2023-02-14T12:54:51Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T12:54:51Z'),
              endTime: new Date('2023-02-14T12:54:52Z'),
              valueState: null,
              valueNumber: 8,
            },
            {
              startTime: new Date('2023-02-14T12:54:52Z'),
              endTime: new Date('2023-02-14T12:54:53Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T12:54:53Z'),
              endTime: new Date('2023-02-14T12:54:54Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:54:54Z'),
              endTime: new Date('2023-02-14T12:54:55Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:54:55Z'),
              endTime: new Date('2023-02-14T12:54:56Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:54:56Z'),
              endTime: new Date('2023-02-14T12:54:57Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:54:57Z'),
              endTime: new Date('2023-02-14T12:54:58Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:54:58Z'),
              endTime: new Date('2023-02-14T12:54:59Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:54:59Z'),
              endTime: new Date('2023-02-14T12:55:00Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:00Z'),
              endTime: new Date('2023-02-14T12:55:01Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T12:55:01Z'),
              endTime: new Date('2023-02-14T12:55:02Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:02Z'),
              endTime: new Date('2023-02-14T12:55:03Z'),
              valueState: null,
              valueNumber: 28,
            },
            {
              startTime: new Date('2023-02-14T12:55:03Z'),
              endTime: new Date('2023-02-14T12:55:05Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:05Z'),
              endTime: new Date('2023-02-14T12:55:06Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:06Z'),
              endTime: new Date('2023-02-14T12:55:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:07Z'),
              endTime: new Date('2023-02-14T12:55:08Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:08Z'),
              endTime: new Date('2023-02-14T12:55:09Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:09Z'),
              endTime: new Date('2023-02-14T12:55:10Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:55:10Z'),
              endTime: new Date('2023-02-14T12:55:11Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:55:11Z'),
              endTime: new Date('2023-02-14T12:55:12Z'),
              valueState: null,
              valueNumber: 21,
            },
            {
              startTime: new Date('2023-02-14T12:55:12Z'),
              endTime: new Date('2023-02-14T12:55:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:13Z'),
              endTime: new Date('2023-02-14T12:55:14Z'),
              valueState: null,
              valueNumber: 38,
            },
            {
              startTime: new Date('2023-02-14T12:55:14Z'),
              endTime: new Date('2023-02-14T12:55:15Z'),
              valueState: null,
              valueNumber: 38,
            },
            {
              startTime: new Date('2023-02-14T12:55:15Z'),
              endTime: new Date('2023-02-14T12:55:16Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:16Z'),
              endTime: new Date('2023-02-14T12:55:17Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:17Z'),
              endTime: new Date('2023-02-14T12:55:18Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:18Z'),
              endTime: new Date('2023-02-14T12:55:20Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:20Z'),
              endTime: new Date('2023-02-14T12:55:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:21Z'),
              endTime: new Date('2023-02-14T12:55:22Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:55:22Z'),
              endTime: new Date('2023-02-14T12:55:23Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T12:55:23Z'),
              endTime: new Date('2023-02-14T12:55:24Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:24Z'),
              endTime: new Date('2023-02-14T12:55:25Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:25Z'),
              endTime: new Date('2023-02-14T12:55:26Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T12:55:26Z'),
              endTime: new Date('2023-02-14T12:55:27Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:27Z'),
              endTime: new Date('2023-02-14T12:55:29Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:29Z'),
              endTime: new Date('2023-02-14T12:55:30Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:30Z'),
              endTime: new Date('2023-02-14T12:55:31Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:55:31Z'),
              endTime: new Date('2023-02-14T12:55:32Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:32Z'),
              endTime: new Date('2023-02-14T12:55:33Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:55:33Z'),
              endTime: new Date('2023-02-14T12:55:35Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:35Z'),
              endTime: new Date('2023-02-14T12:55:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:36Z'),
              endTime: new Date('2023-02-14T12:55:37Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:55:37Z'),
              endTime: new Date('2023-02-14T12:55:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:38Z'),
              endTime: new Date('2023-02-14T12:55:41Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:41Z'),
              endTime: new Date('2023-02-14T12:55:42Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:42Z'),
              endTime: new Date('2023-02-14T12:55:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:43Z'),
              endTime: new Date('2023-02-14T12:55:44Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T12:55:44Z'),
              endTime: new Date('2023-02-14T12:55:45Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T12:55:45Z'),
              endTime: new Date('2023-02-14T12:55:46Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:46Z'),
              endTime: new Date('2023-02-14T12:55:47Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T12:55:47Z'),
              endTime: new Date('2023-02-14T12:55:49Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:49Z'),
              endTime: new Date('2023-02-14T12:55:50Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:50Z'),
              endTime: new Date('2023-02-14T12:55:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:51Z'),
              endTime: new Date('2023-02-14T12:55:52Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T12:55:52Z'),
              endTime: new Date('2023-02-14T12:55:53Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:53Z'),
              endTime: new Date('2023-02-14T12:55:54Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:54Z'),
              endTime: new Date('2023-02-14T12:55:55Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:55:55Z'),
              endTime: new Date('2023-02-14T12:55:56Z'),
              valueState: null,
              valueNumber: 21,
            },
            {
              startTime: new Date('2023-02-14T12:55:56Z'),
              endTime: new Date('2023-02-14T12:55:57Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:57Z'),
              endTime: new Date('2023-02-14T12:55:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:58Z'),
              endTime: new Date('2023-02-14T12:55:59Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T12:55:59Z'),
              endTime: new Date('2023-02-14T12:56:00Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:56:00Z'),
              endTime: new Date('2023-02-14T12:56:01Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:01Z'),
              endTime: new Date('2023-02-14T12:56:02Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:56:02Z'),
              endTime: new Date('2023-02-14T12:56:03Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:56:03Z'),
              endTime: new Date('2023-02-14T12:56:04Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:04Z'),
              endTime: new Date('2023-02-14T12:56:05Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:05Z'),
              endTime: new Date('2023-02-14T12:56:06Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:06Z'),
              endTime: new Date('2023-02-14T12:56:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:07Z'),
              endTime: new Date('2023-02-14T12:56:08Z'),
              valueState: null,
              valueNumber: 8,
            },
            {
              startTime: new Date('2023-02-14T12:56:08Z'),
              endTime: new Date('2023-02-14T12:56:09Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:09Z'),
              endTime: new Date('2023-02-14T12:56:10Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:10Z'),
              endTime: new Date('2023-02-14T12:56:11Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:56:11Z'),
              endTime: new Date('2023-02-14T12:56:12Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:12Z'),
              endTime: new Date('2023-02-14T12:56:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:13Z'),
              endTime: new Date('2023-02-14T12:56:14Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:14Z'),
              endTime: new Date('2023-02-14T12:56:15Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:15Z'),
              endTime: new Date('2023-02-14T12:56:16Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:16Z'),
              endTime: new Date('2023-02-14T12:56:17Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:56:17Z'),
              endTime: new Date('2023-02-14T12:56:18Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:18Z'),
              endTime: new Date('2023-02-14T12:56:20Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:20Z'),
              endTime: new Date('2023-02-14T12:56:21Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:21Z'),
              endTime: new Date('2023-02-14T12:56:22Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T12:56:22Z'),
              endTime: new Date('2023-02-14T12:56:24Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:56:24Z'),
              endTime: new Date('2023-02-14T12:56:25Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:56:25Z'),
              endTime: new Date('2023-02-14T12:56:26Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:26Z'),
              endTime: new Date('2023-02-14T12:56:27Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:27Z'),
              endTime: new Date('2023-02-14T12:56:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:29Z'),
              endTime: new Date('2023-02-14T12:56:30Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:30Z'),
              endTime: new Date('2023-02-14T12:56:31Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T12:56:31Z'),
              endTime: new Date('2023-02-14T12:56:32Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T12:56:32Z'),
              endTime: new Date('2023-02-14T12:56:33Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:56:33Z'),
              endTime: new Date('2023-02-14T12:56:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:34Z'),
              endTime: new Date('2023-02-14T12:56:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:35Z'),
              endTime: new Date('2023-02-14T12:56:36Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:36Z'),
              endTime: new Date('2023-02-14T12:56:37Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:37Z'),
              endTime: new Date('2023-02-14T12:56:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:38Z'),
              endTime: new Date('2023-02-14T12:56:39Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:39Z'),
              endTime: new Date('2023-02-14T12:56:40Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:40Z'),
              endTime: new Date('2023-02-14T12:56:41Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T12:56:41Z'),
              endTime: new Date('2023-02-14T12:56:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:42Z'),
              endTime: new Date('2023-02-14T12:56:43Z'),
              valueState: null,
              valueNumber: 38,
            },
            {
              startTime: new Date('2023-02-14T12:56:43Z'),
              endTime: new Date('2023-02-14T12:56:47Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:47Z'),
              endTime: new Date('2023-02-14T12:56:48Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:48Z'),
              endTime: new Date('2023-02-14T12:56:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:49Z'),
              endTime: new Date('2023-02-14T12:56:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:50Z'),
              endTime: new Date('2023-02-14T12:56:51Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:51Z'),
              endTime: new Date('2023-02-14T12:56:52Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T12:56:52Z'),
              endTime: new Date('2023-02-14T12:56:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:53Z'),
              endTime: new Date('2023-02-14T12:56:54Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:54Z'),
              endTime: new Date('2023-02-14T12:56:55Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T12:56:55Z'),
              endTime: new Date('2023-02-14T12:56:56Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:56Z'),
              endTime: new Date('2023-02-14T12:56:57Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:56:57Z'),
              endTime: new Date('2023-02-14T12:57:01Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:01Z'),
              endTime: new Date('2023-02-14T12:57:02Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:02Z'),
              endTime: new Date('2023-02-14T12:57:04Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:04Z'),
              endTime: new Date('2023-02-14T12:57:05Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:05Z'),
              endTime: new Date('2023-02-14T12:57:08Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:57:08Z'),
              endTime: new Date('2023-02-14T12:57:09Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:57:09Z'),
              endTime: new Date('2023-02-14T12:57:10Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:10Z'),
              endTime: new Date('2023-02-14T12:57:11Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:57:11Z'),
              endTime: new Date('2023-02-14T12:57:12Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:57:12Z'),
              endTime: new Date('2023-02-14T12:57:13Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:57:13Z'),
              endTime: new Date('2023-02-14T12:57:14Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:57:14Z'),
              endTime: new Date('2023-02-14T12:57:15Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T12:57:15Z'),
              endTime: new Date('2023-02-14T12:57:16Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T12:57:16Z'),
              endTime: new Date('2023-02-14T12:57:17Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T12:57:17Z'),
              endTime: new Date('2023-02-14T12:57:18Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:57:18Z'),
              endTime: new Date('2023-02-14T12:57:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:19Z'),
              endTime: new Date('2023-02-14T12:57:20Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:57:20Z'),
              endTime: new Date('2023-02-14T12:57:21Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:57:21Z'),
              endTime: new Date('2023-02-14T12:57:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:22Z'),
              endTime: new Date('2023-02-14T12:57:23Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:23Z'),
              endTime: new Date('2023-02-14T12:57:24Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:57:24Z'),
              endTime: new Date('2023-02-14T12:57:25Z'),
              valueState: null,
              valueNumber: 41,
            },
            {
              startTime: new Date('2023-02-14T12:57:25Z'),
              endTime: new Date('2023-02-14T12:57:26Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:26Z'),
              endTime: new Date('2023-02-14T12:57:27Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:27Z'),
              endTime: new Date('2023-02-14T12:57:28Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T12:57:28Z'),
              endTime: new Date('2023-02-14T12:57:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:29Z'),
              endTime: new Date('2023-02-14T12:57:30Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:57:30Z'),
              endTime: new Date('2023-02-14T12:57:31Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:57:31Z'),
              endTime: new Date('2023-02-14T12:57:32Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:57:32Z'),
              endTime: new Date('2023-02-14T12:57:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:34Z'),
              endTime: new Date('2023-02-14T12:57:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:35Z'),
              endTime: new Date('2023-02-14T12:57:36Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T12:57:36Z'),
              endTime: new Date('2023-02-14T12:57:37Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T12:57:37Z'),
              endTime: new Date('2023-02-14T12:57:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:38Z'),
              endTime: new Date('2023-02-14T12:57:39Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T12:57:39Z'),
              endTime: new Date('2023-02-14T12:57:40Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:40Z'),
              endTime: new Date('2023-02-14T12:57:41Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:57:41Z'),
              endTime: new Date('2023-02-14T12:57:42Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:57:42Z'),
              endTime: new Date('2023-02-14T12:57:43Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:57:43Z'),
              endTime: new Date('2023-02-14T12:57:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:44Z'),
              endTime: new Date('2023-02-14T12:57:45Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:57:45Z'),
              endTime: new Date('2023-02-14T12:57:46Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:57:46Z'),
              endTime: new Date('2023-02-14T12:57:47Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:57:47Z'),
              endTime: new Date('2023-02-14T12:57:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:48Z'),
              endTime: new Date('2023-02-14T12:57:49Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:49Z'),
              endTime: new Date('2023-02-14T12:57:50Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:57:50Z'),
              endTime: new Date('2023-02-14T12:57:52Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:52Z'),
              endTime: new Date('2023-02-14T12:57:53Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:53Z'),
              endTime: new Date('2023-02-14T12:57:56Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:57:56Z'),
              endTime: new Date('2023-02-14T12:57:57Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:57:57Z'),
              endTime: new Date('2023-02-14T12:57:58Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:58Z'),
              endTime: new Date('2023-02-14T12:57:59Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T12:57:59Z'),
              endTime: new Date('2023-02-14T12:58:00Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T12:58:00Z'),
              endTime: new Date('2023-02-14T12:58:01Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T12:58:01Z'),
              endTime: new Date('2023-02-14T12:58:02Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:02Z'),
              endTime: new Date('2023-02-14T12:58:03Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:03Z'),
              endTime: new Date('2023-02-14T12:58:08Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:08Z'),
              endTime: new Date('2023-02-14T12:58:09Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:09Z'),
              endTime: new Date('2023-02-14T12:58:10Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T12:58:10Z'),
              endTime: new Date('2023-02-14T12:58:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:11Z'),
              endTime: new Date('2023-02-14T12:58:12Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T12:58:12Z'),
              endTime: new Date('2023-02-14T12:58:14Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:14Z'),
              endTime: new Date('2023-02-14T12:58:15Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:15Z'),
              endTime: new Date('2023-02-14T12:58:16Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:16Z'),
              endTime: new Date('2023-02-14T12:58:17Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:17Z'),
              endTime: new Date('2023-02-14T12:58:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:18Z'),
              endTime: new Date('2023-02-14T12:58:19Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:19Z'),
              endTime: new Date('2023-02-14T12:58:20Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:20Z'),
              endTime: new Date('2023-02-14T12:58:21Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T12:58:21Z'),
              endTime: new Date('2023-02-14T12:58:22Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:22Z'),
              endTime: new Date('2023-02-14T12:58:23Z'),
              valueState: null,
              valueNumber: 33,
            },
            {
              startTime: new Date('2023-02-14T12:58:23Z'),
              endTime: new Date('2023-02-14T12:58:24Z'),
              valueState: null,
              valueNumber: 33,
            },
            {
              startTime: new Date('2023-02-14T12:58:24Z'),
              endTime: new Date('2023-02-14T12:58:25Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:58:25Z'),
              endTime: new Date('2023-02-14T12:58:26Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:26Z'),
              endTime: new Date('2023-02-14T12:58:27Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:27Z'),
              endTime: new Date('2023-02-14T12:58:28Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:28Z'),
              endTime: new Date('2023-02-14T12:58:30Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:30Z'),
              endTime: new Date('2023-02-14T12:58:31Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:31Z'),
              endTime: new Date('2023-02-14T12:58:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:33Z'),
              endTime: new Date('2023-02-14T12:58:34Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:34Z'),
              endTime: new Date('2023-02-14T12:58:35Z'),
              valueState: null,
              valueNumber: 53,
            },
            {
              startTime: new Date('2023-02-14T12:58:35Z'),
              endTime: new Date('2023-02-14T12:58:36Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:36Z'),
              endTime: new Date('2023-02-14T12:58:37Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:37Z'),
              endTime: new Date('2023-02-14T12:58:39Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:39Z'),
              endTime: new Date('2023-02-14T12:58:40Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:40Z'),
              endTime: new Date('2023-02-14T12:58:41Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:58:41Z'),
              endTime: new Date('2023-02-14T12:58:42Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:42Z'),
              endTime: new Date('2023-02-14T12:58:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:43Z'),
              endTime: new Date('2023-02-14T12:58:44Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:44Z'),
              endTime: new Date('2023-02-14T12:58:45Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:45Z'),
              endTime: new Date('2023-02-14T12:58:46Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T12:58:46Z'),
              endTime: new Date('2023-02-14T12:58:47Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T12:58:47Z'),
              endTime: new Date('2023-02-14T12:58:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:51Z'),
              endTime: new Date('2023-02-14T12:58:52Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:52Z'),
              endTime: new Date('2023-02-14T12:58:53Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:53Z'),
              endTime: new Date('2023-02-14T12:58:54Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T12:58:54Z'),
              endTime: new Date('2023-02-14T12:58:55Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:55Z'),
              endTime: new Date('2023-02-14T12:58:56Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:56Z'),
              endTime: new Date('2023-02-14T12:58:57Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T12:58:57Z'),
              endTime: new Date('2023-02-14T12:58:59Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:58:59Z'),
              endTime: new Date('2023-02-14T12:59:00Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:59:00Z'),
              endTime: new Date('2023-02-14T12:59:01Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:01Z'),
              endTime: new Date('2023-02-14T12:59:02Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:02Z'),
              endTime: new Date('2023-02-14T12:59:03Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:59:03Z'),
              endTime: new Date('2023-02-14T12:59:04Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:04Z'),
              endTime: new Date('2023-02-14T12:59:05Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T12:59:05Z'),
              endTime: new Date('2023-02-14T12:59:06Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:06Z'),
              endTime: new Date('2023-02-14T12:59:07Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:07Z'),
              endTime: new Date('2023-02-14T12:59:08Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T12:59:08Z'),
              endTime: new Date('2023-02-14T12:59:09Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:09Z'),
              endTime: new Date('2023-02-14T12:59:12Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:59:12Z'),
              endTime: new Date('2023-02-14T12:59:13Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:59:13Z'),
              endTime: new Date('2023-02-14T12:59:14Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:14Z'),
              endTime: new Date('2023-02-14T12:59:15Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:59:15Z'),
              endTime: new Date('2023-02-14T12:59:16Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:59:16Z'),
              endTime: new Date('2023-02-14T12:59:17Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T12:59:17Z'),
              endTime: new Date('2023-02-14T12:59:18Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:18Z'),
              endTime: new Date('2023-02-14T12:59:19Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:59:19Z'),
              endTime: new Date('2023-02-14T12:59:20Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:20Z'),
              endTime: new Date('2023-02-14T12:59:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:21Z'),
              endTime: new Date('2023-02-14T12:59:22Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T12:59:22Z'),
              endTime: new Date('2023-02-14T12:59:23Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:59:23Z'),
              endTime: new Date('2023-02-14T12:59:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:24Z'),
              endTime: new Date('2023-02-14T12:59:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:25Z'),
              endTime: new Date('2023-02-14T12:59:26Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:59:26Z'),
              endTime: new Date('2023-02-14T12:59:27Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:59:27Z'),
              endTime: new Date('2023-02-14T12:59:28Z'),
              valueState: null,
              valueNumber: 4,
            },
            {
              startTime: new Date('2023-02-14T12:59:28Z'),
              endTime: new Date('2023-02-14T12:59:29Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:29Z'),
              endTime: new Date('2023-02-14T12:59:30Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T12:59:30Z'),
              endTime: new Date('2023-02-14T12:59:31Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T12:59:31Z'),
              endTime: new Date('2023-02-14T12:59:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:34Z'),
              endTime: new Date('2023-02-14T12:59:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:35Z'),
              endTime: new Date('2023-02-14T12:59:36Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:59:36Z'),
              endTime: new Date('2023-02-14T12:59:37Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:59:37Z'),
              endTime: new Date('2023-02-14T12:59:38Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:59:38Z'),
              endTime: new Date('2023-02-14T12:59:39Z'),
              valueState: null,
              valueNumber: 4,
            },
            {
              startTime: new Date('2023-02-14T12:59:39Z'),
              endTime: new Date('2023-02-14T12:59:40Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T12:59:40Z'),
              endTime: new Date('2023-02-14T12:59:41Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T12:59:41Z'),
              endTime: new Date('2023-02-14T12:59:48Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:48Z'),
              endTime: new Date('2023-02-14T12:59:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:49Z'),
              endTime: new Date('2023-02-14T12:59:50Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T12:59:50Z'),
              endTime: new Date('2023-02-14T12:59:51Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:51Z'),
              endTime: new Date('2023-02-14T12:59:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:52Z'),
              endTime: new Date('2023-02-14T12:59:53Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T12:59:53Z'),
              endTime: new Date('2023-02-14T12:59:54Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:59:54Z'),
              endTime: new Date('2023-02-14T12:59:55Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T12:59:55Z'),
              endTime: new Date('2023-02-14T12:59:58Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:59:58Z'),
              endTime: new Date('2023-02-14T12:59:59Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T12:59:59Z'),
              endTime: new Date('2023-02-14T13:00:00Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:00:00Z'),
              endTime: new Date('2023-02-14T13:00:01Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:00:01Z'),
              endTime: new Date('2023-02-14T13:00:02Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:00:02Z'),
              endTime: new Date('2023-02-14T13:00:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:03Z'),
              endTime: new Date('2023-02-14T13:00:04Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:00:04Z'),
              endTime: new Date('2023-02-14T13:00:05Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:05Z'),
              endTime: new Date('2023-02-14T13:00:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:07Z'),
              endTime: new Date('2023-02-14T13:00:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:08Z'),
              endTime: new Date('2023-02-14T13:00:09Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:00:09Z'),
              endTime: new Date('2023-02-14T13:00:10Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:10Z'),
              endTime: new Date('2023-02-14T13:00:11Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:11Z'),
              endTime: new Date('2023-02-14T13:00:12Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:12Z'),
              endTime: new Date('2023-02-14T13:00:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:13Z'),
              endTime: new Date('2023-02-14T13:00:14Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:14Z'),
              endTime: new Date('2023-02-14T13:00:16Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:00:16Z'),
              endTime: new Date('2023-02-14T13:00:17Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:00:17Z'),
              endTime: new Date('2023-02-14T13:00:18Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:00:18Z'),
              endTime: new Date('2023-02-14T13:00:19Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:19Z'),
              endTime: new Date('2023-02-14T13:00:20Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:20Z'),
              endTime: new Date('2023-02-14T13:00:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:21Z'),
              endTime: new Date('2023-02-14T13:00:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:22Z'),
              endTime: new Date('2023-02-14T13:00:23Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:23Z'),
              endTime: new Date('2023-02-14T13:00:24Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:00:24Z'),
              endTime: new Date('2023-02-14T13:00:25Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:00:25Z'),
              endTime: new Date('2023-02-14T13:00:26Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:00:26Z'),
              endTime: new Date('2023-02-14T13:00:27Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:27Z'),
              endTime: new Date('2023-02-14T13:00:28Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:28Z'),
              endTime: new Date('2023-02-14T13:00:29Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:29Z'),
              endTime: new Date('2023-02-14T13:00:30Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:30Z'),
              endTime: new Date('2023-02-14T13:00:31Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:31Z'),
              endTime: new Date('2023-02-14T13:00:32Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:32Z'),
              endTime: new Date('2023-02-14T13:00:33Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:33Z'),
              endTime: new Date('2023-02-14T13:00:34Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:00:34Z'),
              endTime: new Date('2023-02-14T13:00:35Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:35Z'),
              endTime: new Date('2023-02-14T13:00:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:36Z'),
              endTime: new Date('2023-02-14T13:00:37Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:00:37Z'),
              endTime: new Date('2023-02-14T13:00:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:38Z'),
              endTime: new Date('2023-02-14T13:00:39Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:39Z'),
              endTime: new Date('2023-02-14T13:00:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:43Z'),
              endTime: new Date('2023-02-14T13:00:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:44Z'),
              endTime: new Date('2023-02-14T13:00:45Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:00:45Z'),
              endTime: new Date('2023-02-14T13:00:46Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:00:46Z'),
              endTime: new Date('2023-02-14T13:00:47Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:47Z'),
              endTime: new Date('2023-02-14T13:00:48Z'),
              valueState: null,
              valueNumber: 45,
            },
            {
              startTime: new Date('2023-02-14T13:00:48Z'),
              endTime: new Date('2023-02-14T13:00:50Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:50Z'),
              endTime: new Date('2023-02-14T13:00:51Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:51Z'),
              endTime: new Date('2023-02-14T13:00:52Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:52Z'),
              endTime: new Date('2023-02-14T13:00:53Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:00:53Z'),
              endTime: new Date('2023-02-14T13:00:55Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:55Z'),
              endTime: new Date('2023-02-14T13:00:56Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:56Z'),
              endTime: new Date('2023-02-14T13:00:57Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:00:57Z'),
              endTime: new Date('2023-02-14T13:00:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:58Z'),
              endTime: new Date('2023-02-14T13:00:59Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:00:59Z'),
              endTime: new Date('2023-02-14T13:01:05Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:05Z'),
              endTime: new Date('2023-02-14T13:01:06Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:06Z'),
              endTime: new Date('2023-02-14T13:01:07Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:01:07Z'),
              endTime: new Date('2023-02-14T13:01:08Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:08Z'),
              endTime: new Date('2023-02-14T13:01:09Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:09Z'),
              endTime: new Date('2023-02-14T13:01:10Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:01:10Z'),
              endTime: new Date('2023-02-14T13:01:14Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:14Z'),
              endTime: new Date('2023-02-14T13:01:15Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:15Z'),
              endTime: new Date('2023-02-14T13:01:17Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:01:17Z'),
              endTime: new Date('2023-02-14T13:01:18Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:01:18Z'),
              endTime: new Date('2023-02-14T13:01:19Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:01:19Z'),
              endTime: new Date('2023-02-14T13:01:20Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:20Z'),
              endTime: new Date('2023-02-14T13:01:21Z'),
              valueState: null,
              valueNumber: 17,
            },
            {
              startTime: new Date('2023-02-14T13:01:21Z'),
              endTime: new Date('2023-02-14T13:01:25Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:01:25Z'),
              endTime: new Date('2023-02-14T13:01:26Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:01:26Z'),
              endTime: new Date('2023-02-14T13:01:28Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:28Z'),
              endTime: new Date('2023-02-14T13:01:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:29Z'),
              endTime: new Date('2023-02-14T13:01:30Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T13:01:30Z'),
              endTime: new Date('2023-02-14T13:01:31Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:31Z'),
              endTime: new Date('2023-02-14T13:01:32Z'),
              valueState: null,
              valueNumber: 45,
            },
            {
              startTime: new Date('2023-02-14T13:01:32Z'),
              endTime: new Date('2023-02-14T13:01:33Z'),
              valueState: null,
              valueNumber: 45,
            },
            {
              startTime: new Date('2023-02-14T13:01:33Z'),
              endTime: new Date('2023-02-14T13:01:34Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:01:34Z'),
              endTime: new Date('2023-02-14T13:01:35Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:01:35Z'),
              endTime: new Date('2023-02-14T13:01:36Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:36Z'),
              endTime: new Date('2023-02-14T13:01:37Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:01:37Z'),
              endTime: new Date('2023-02-14T13:01:38Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:01:38Z'),
              endTime: new Date('2023-02-14T13:01:39Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:01:39Z'),
              endTime: new Date('2023-02-14T13:01:40Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:40Z'),
              endTime: new Date('2023-02-14T13:01:41Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:01:41Z'),
              endTime: new Date('2023-02-14T13:01:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:42Z'),
              endTime: new Date('2023-02-14T13:01:43Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:43Z'),
              endTime: new Date('2023-02-14T13:01:44Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:01:44Z'),
              endTime: new Date('2023-02-14T13:01:45Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:45Z'),
              endTime: new Date('2023-02-14T13:01:46Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:46Z'),
              endTime: new Date('2023-02-14T13:01:47Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:01:47Z'),
              endTime: new Date('2023-02-14T13:01:48Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:01:48Z'),
              endTime: new Date('2023-02-14T13:01:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:49Z'),
              endTime: new Date('2023-02-14T13:01:50Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:01:50Z'),
              endTime: new Date('2023-02-14T13:01:51Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:01:51Z'),
              endTime: new Date('2023-02-14T13:01:52Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:01:52Z'),
              endTime: new Date('2023-02-14T13:01:53Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:01:53Z'),
              endTime: new Date('2023-02-14T13:01:54Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:54Z'),
              endTime: new Date('2023-02-14T13:01:55Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:01:55Z'),
              endTime: new Date('2023-02-14T13:01:56Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:01:56Z'),
              endTime: new Date('2023-02-14T13:01:57Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:01:57Z'),
              endTime: new Date('2023-02-14T13:01:58Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:01:58Z'),
              endTime: new Date('2023-02-14T13:01:59Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:01:59Z'),
              endTime: new Date('2023-02-14T13:02:01Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:02:01Z'),
              endTime: new Date('2023-02-14T13:02:02Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:02:02Z'),
              endTime: new Date('2023-02-14T13:02:03Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:02:03Z'),
              endTime: new Date('2023-02-14T13:02:04Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:04Z'),
              endTime: new Date('2023-02-14T13:02:05Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:05Z'),
              endTime: new Date('2023-02-14T13:02:06Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T13:02:06Z'),
              endTime: new Date('2023-02-14T13:02:07Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:02:07Z'),
              endTime: new Date('2023-02-14T13:02:08Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:02:08Z'),
              endTime: new Date('2023-02-14T13:02:09Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:09Z'),
              endTime: new Date('2023-02-14T13:02:10Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:02:10Z'),
              endTime: new Date('2023-02-14T13:02:11Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:02:11Z'),
              endTime: new Date('2023-02-14T13:02:12Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:12Z'),
              endTime: new Date('2023-02-14T13:02:13Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:02:13Z'),
              endTime: new Date('2023-02-14T13:02:14Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T13:02:14Z'),
              endTime: new Date('2023-02-14T13:02:15Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:15Z'),
              endTime: new Date('2023-02-14T13:02:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:16Z'),
              endTime: new Date('2023-02-14T13:02:17Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:02:17Z'),
              endTime: new Date('2023-02-14T13:02:18Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:02:18Z'),
              endTime: new Date('2023-02-14T13:02:19Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:02:19Z'),
              endTime: new Date('2023-02-14T13:02:20Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:02:20Z'),
              endTime: new Date('2023-02-14T13:02:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:21Z'),
              endTime: new Date('2023-02-14T13:02:24Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:02:24Z'),
              endTime: new Date('2023-02-14T13:02:25Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:02:25Z'),
              endTime: new Date('2023-02-14T13:02:26Z'),
              valueState: null,
              valueNumber: 8,
            },
            {
              startTime: new Date('2023-02-14T13:02:26Z'),
              endTime: new Date('2023-02-14T13:02:27Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:27Z'),
              endTime: new Date('2023-02-14T13:02:28Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:28Z'),
              endTime: new Date('2023-02-14T13:02:31Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:31Z'),
              endTime: new Date('2023-02-14T13:02:32Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:32Z'),
              endTime: new Date('2023-02-14T13:02:33Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:02:33Z'),
              endTime: new Date('2023-02-14T13:02:34Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:02:34Z'),
              endTime: new Date('2023-02-14T13:02:35Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:02:35Z'),
              endTime: new Date('2023-02-14T13:02:36Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:02:36Z'),
              endTime: new Date('2023-02-14T13:02:37Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:02:37Z'),
              endTime: new Date('2023-02-14T13:02:38Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:02:38Z'),
              endTime: new Date('2023-02-14T13:02:39Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:39Z'),
              endTime: new Date('2023-02-14T13:02:40Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:02:40Z'),
              endTime: new Date('2023-02-14T13:02:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:43Z'),
              endTime: new Date('2023-02-14T13:02:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:44Z'),
              endTime: new Date('2023-02-14T13:02:45Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:02:45Z'),
              endTime: new Date('2023-02-14T13:02:47Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:47Z'),
              endTime: new Date('2023-02-14T13:02:48Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:48Z'),
              endTime: new Date('2023-02-14T13:02:49Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:02:49Z'),
              endTime: new Date('2023-02-14T13:02:50Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:02:50Z'),
              endTime: new Date('2023-02-14T13:02:51Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:02:51Z'),
              endTime: new Date('2023-02-14T13:02:52Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:52Z'),
              endTime: new Date('2023-02-14T13:02:53Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:53Z'),
              endTime: new Date('2023-02-14T13:02:54Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:02:54Z'),
              endTime: new Date('2023-02-14T13:02:55Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:02:55Z'),
              endTime: new Date('2023-02-14T13:02:56Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:02:56Z'),
              endTime: new Date('2023-02-14T13:02:57Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:57Z'),
              endTime: new Date('2023-02-14T13:02:58Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:58Z'),
              endTime: new Date('2023-02-14T13:02:59Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T13:02:59Z'),
              endTime: new Date('2023-02-14T13:03:00Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:00Z'),
              endTime: new Date('2023-02-14T13:03:01Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:03:01Z'),
              endTime: new Date('2023-02-14T13:03:02Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:02Z'),
              endTime: new Date('2023-02-14T13:03:03Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:03Z'),
              endTime: new Date('2023-02-14T13:03:04Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:04Z'),
              endTime: new Date('2023-02-14T13:03:05Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:05Z'),
              endTime: new Date('2023-02-14T13:03:06Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:03:06Z'),
              endTime: new Date('2023-02-14T13:03:07Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:03:07Z'),
              endTime: new Date('2023-02-14T13:03:08Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:03:08Z'),
              endTime: new Date('2023-02-14T13:03:09Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:09Z'),
              endTime: new Date('2023-02-14T13:03:10Z'),
              valueState: null,
              valueNumber: 4,
            },
            {
              startTime: new Date('2023-02-14T13:03:10Z'),
              endTime: new Date('2023-02-14T13:03:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:11Z'),
              endTime: new Date('2023-02-14T13:03:12Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:12Z'),
              endTime: new Date('2023-02-14T13:03:13Z'),
              valueState: null,
              valueNumber: 52,
            },
            {
              startTime: new Date('2023-02-14T13:03:13Z'),
              endTime: new Date('2023-02-14T13:03:14Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:14Z'),
              endTime: new Date('2023-02-14T13:03:15Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:15Z'),
              endTime: new Date('2023-02-14T13:03:16Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:16Z'),
              endTime: new Date('2023-02-14T13:03:17Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:17Z'),
              endTime: new Date('2023-02-14T13:03:18Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:18Z'),
              endTime: new Date('2023-02-14T13:03:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:19Z'),
              endTime: new Date('2023-02-14T13:03:20Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:20Z'),
              endTime: new Date('2023-02-14T13:03:21Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:03:21Z'),
              endTime: new Date('2023-02-14T13:03:22Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:03:22Z'),
              endTime: new Date('2023-02-14T13:03:23Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:23Z'),
              endTime: new Date('2023-02-14T13:03:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:24Z'),
              endTime: new Date('2023-02-14T13:03:25Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:03:25Z'),
              endTime: new Date('2023-02-14T13:03:26Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:26Z'),
              endTime: new Date('2023-02-14T13:03:27Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:03:27Z'),
              endTime: new Date('2023-02-14T13:03:28Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:03:28Z'),
              endTime: new Date('2023-02-14T13:03:29Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:29Z'),
              endTime: new Date('2023-02-14T13:03:30Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:30Z'),
              endTime: new Date('2023-02-14T13:03:31Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:03:31Z'),
              endTime: new Date('2023-02-14T13:03:32Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:03:32Z'),
              endTime: new Date('2023-02-14T13:03:40Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:40Z'),
              endTime: new Date('2023-02-14T13:03:41Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:41Z'),
              endTime: new Date('2023-02-14T13:03:42Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T13:03:42Z'),
              endTime: new Date('2023-02-14T13:03:43Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:43Z'),
              endTime: new Date('2023-02-14T13:03:44Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:03:44Z'),
              endTime: new Date('2023-02-14T13:03:45Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:45Z'),
              endTime: new Date('2023-02-14T13:03:46Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:46Z'),
              endTime: new Date('2023-02-14T13:03:47Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:47Z'),
              endTime: new Date('2023-02-14T13:03:48Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:03:48Z'),
              endTime: new Date('2023-02-14T13:03:49Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:03:49Z'),
              endTime: new Date('2023-02-14T13:03:50Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:03:50Z'),
              endTime: new Date('2023-02-14T13:03:51Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:03:51Z'),
              endTime: new Date('2023-02-14T13:03:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:59Z'),
              endTime: new Date('2023-02-14T13:04:00Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:00Z'),
              endTime: new Date('2023-02-14T13:04:01Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:04:01Z'),
              endTime: new Date('2023-02-14T13:04:04Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:04:04Z'),
              endTime: new Date('2023-02-14T13:04:05Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:04:05Z'),
              endTime: new Date('2023-02-14T13:04:06Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:04:06Z'),
              endTime: new Date('2023-02-14T13:04:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:04:07Z'),
              endTime: new Date('2023-02-14T13:04:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:04:08Z'),
              endTime: new Date('2023-02-14T13:04:09Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:04:09Z'),
              endTime: new Date('2023-02-14T13:04:10Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:04:10Z'),
              endTime: new Date('2023-02-14T13:04:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:16Z'),
              endTime: new Date('2023-02-14T13:04:17Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:17Z'),
              endTime: new Date('2023-02-14T13:04:18Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:04:18Z'),
              endTime: new Date('2023-02-14T13:04:21Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:04:21Z'),
              endTime: new Date('2023-02-14T13:04:22Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:04:22Z'),
              endTime: new Date('2023-02-14T13:04:23Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:04:23Z'),
              endTime: new Date('2023-02-14T13:04:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:04:25Z'),
              endTime: new Date('2023-02-14T13:04:26Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:04:26Z'),
              endTime: new Date('2023-02-14T13:04:27Z'),
              valueState: null,
              valueNumber: 24,
            },
            {
              startTime: new Date('2023-02-14T13:04:27Z'),
              endTime: new Date('2023-02-14T13:04:35Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:35Z'),
              endTime: new Date('2023-02-14T13:04:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:36Z'),
              endTime: new Date('2023-02-14T13:04:37Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:04:37Z'),
              endTime: new Date('2023-02-14T13:04:38Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:04:38Z'),
              endTime: new Date('2023-02-14T13:04:39Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:04:39Z'),
              endTime: new Date('2023-02-14T13:04:43Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:04:43Z'),
              endTime: new Date('2023-02-14T13:04:44Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:04:44Z'),
              endTime: new Date('2023-02-14T13:04:45Z'),
              valueState: null,
              valueNumber: 4,
            },
            {
              startTime: new Date('2023-02-14T13:04:45Z'),
              endTime: new Date('2023-02-14T13:04:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:52Z'),
              endTime: new Date('2023-02-14T13:04:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:53Z'),
              endTime: new Date('2023-02-14T13:04:54Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:04:54Z'),
              endTime: new Date('2023-02-14T13:04:55Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:04:55Z'),
              endTime: new Date('2023-02-14T13:04:56Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:04:56Z'),
              endTime: new Date('2023-02-14T13:04:57Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:04:57Z'),
              endTime: new Date('2023-02-14T13:05:01Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:05:01Z'),
              endTime: new Date('2023-02-14T13:05:02Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:05:02Z'),
              endTime: new Date('2023-02-14T13:05:03Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:05:03Z'),
              endTime: new Date('2023-02-14T13:05:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:11Z'),
              endTime: new Date('2023-02-14T13:05:12Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:12Z'),
              endTime: new Date('2023-02-14T13:05:13Z'),
              valueState: null,
              valueNumber: 45,
            },
            {
              startTime: new Date('2023-02-14T13:05:13Z'),
              endTime: new Date('2023-02-14T13:05:14Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:05:14Z'),
              endTime: new Date('2023-02-14T13:05:15Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:05:15Z'),
              endTime: new Date('2023-02-14T13:05:16Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:05:16Z'),
              endTime: new Date('2023-02-14T13:05:17Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:17Z'),
              endTime: new Date('2023-02-14T13:05:18Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:05:18Z'),
              endTime: new Date('2023-02-14T13:05:19Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:05:19Z'),
              endTime: new Date('2023-02-14T13:05:20Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:05:20Z'),
              endTime: new Date('2023-02-14T13:05:21Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:05:21Z'),
              endTime: new Date('2023-02-14T13:05:29Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:29Z'),
              endTime: new Date('2023-02-14T13:05:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:30Z'),
              endTime: new Date('2023-02-14T13:05:31Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:05:31Z'),
              endTime: new Date('2023-02-14T13:05:32Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:05:32Z'),
              endTime: new Date('2023-02-14T13:05:33Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:05:33Z'),
              endTime: new Date('2023-02-14T13:05:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:34Z'),
              endTime: new Date('2023-02-14T13:05:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:35Z'),
              endTime: new Date('2023-02-14T13:05:36Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:05:36Z'),
              endTime: new Date('2023-02-14T13:05:37Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:37Z'),
              endTime: new Date('2023-02-14T13:05:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:38Z'),
              endTime: new Date('2023-02-14T13:05:39Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:05:39Z'),
              endTime: new Date('2023-02-14T13:05:40Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:05:40Z'),
              endTime: new Date('2023-02-14T13:05:41Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:41Z'),
              endTime: new Date('2023-02-14T13:05:42Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:05:42Z'),
              endTime: new Date('2023-02-14T13:05:43Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:05:43Z'),
              endTime: new Date('2023-02-14T13:05:44Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:05:44Z'),
              endTime: new Date('2023-02-14T13:05:45Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:05:45Z'),
              endTime: new Date('2023-02-14T13:05:46Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:05:46Z'),
              endTime: new Date('2023-02-14T13:05:47Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:05:47Z'),
              endTime: new Date('2023-02-14T13:05:48Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:48Z'),
              endTime: new Date('2023-02-14T13:05:49Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:05:49Z'),
              endTime: new Date('2023-02-14T13:05:50Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:05:50Z'),
              endTime: new Date('2023-02-14T13:05:51Z'),
              valueState: null,
              valueNumber: 20,
            },
            {
              startTime: new Date('2023-02-14T13:05:51Z'),
              endTime: new Date('2023-02-14T13:05:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:52Z'),
              endTime: new Date('2023-02-14T13:05:53Z'),
              valueState: null,
              valueNumber: 34,
            },
            {
              startTime: new Date('2023-02-14T13:05:53Z'),
              endTime: new Date('2023-02-14T13:05:54Z'),
              valueState: null,
              valueNumber: 34,
            },
            {
              startTime: new Date('2023-02-14T13:05:54Z'),
              endTime: new Date('2023-02-14T13:05:55Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:05:55Z'),
              endTime: new Date('2023-02-14T13:05:56Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:05:56Z'),
              endTime: new Date('2023-02-14T13:05:59Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:59Z'),
              endTime: new Date('2023-02-14T13:06:00Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:00Z'),
              endTime: new Date('2023-02-14T13:06:01Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:01Z'),
              endTime: new Date('2023-02-14T13:06:02Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:06:02Z'),
              endTime: new Date('2023-02-14T13:06:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:03Z'),
              endTime: new Date('2023-02-14T13:06:04Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:04Z'),
              endTime: new Date('2023-02-14T13:06:05Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:05Z'),
              endTime: new Date('2023-02-14T13:06:06Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:06Z'),
              endTime: new Date('2023-02-14T13:06:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:07Z'),
              endTime: new Date('2023-02-14T13:06:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:08Z'),
              endTime: new Date('2023-02-14T13:06:09Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:09Z'),
              endTime: new Date('2023-02-14T13:06:10Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:10Z'),
              endTime: new Date('2023-02-14T13:06:12Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:12Z'),
              endTime: new Date('2023-02-14T13:06:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:13Z'),
              endTime: new Date('2023-02-14T13:06:14Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:06:14Z'),
              endTime: new Date('2023-02-14T13:06:15Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:15Z'),
              endTime: new Date('2023-02-14T13:06:16Z'),
              valueState: null,
              valueNumber: 54,
            },
            {
              startTime: new Date('2023-02-14T13:06:16Z'),
              endTime: new Date('2023-02-14T13:06:17Z'),
              valueState: null,
              valueNumber: 54,
            },
            {
              startTime: new Date('2023-02-14T13:06:17Z'),
              endTime: new Date('2023-02-14T13:06:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:18Z'),
              endTime: new Date('2023-02-14T13:06:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:19Z'),
              endTime: new Date('2023-02-14T13:06:20Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:20Z'),
              endTime: new Date('2023-02-14T13:06:23Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:23Z'),
              endTime: new Date('2023-02-14T13:06:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:24Z'),
              endTime: new Date('2023-02-14T13:06:25Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:25Z'),
              endTime: new Date('2023-02-14T13:06:26Z'),
              valueState: null,
              valueNumber: 8,
            },
            {
              startTime: new Date('2023-02-14T13:06:26Z'),
              endTime: new Date('2023-02-14T13:06:27Z'),
              valueState: null,
              valueNumber: 8,
            },
            {
              startTime: new Date('2023-02-14T13:06:27Z'),
              endTime: new Date('2023-02-14T13:06:28Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:28Z'),
              endTime: new Date('2023-02-14T13:06:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:29Z'),
              endTime: new Date('2023-02-14T13:06:30Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:06:30Z'),
              endTime: new Date('2023-02-14T13:06:31Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:06:31Z'),
              endTime: new Date('2023-02-14T13:06:32Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:06:32Z'),
              endTime: new Date('2023-02-14T13:06:33Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:33Z'),
              endTime: new Date('2023-02-14T13:06:34Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:34Z'),
              endTime: new Date('2023-02-14T13:06:35Z'),
              valueState: null,
              valueNumber: 21,
            },
            {
              startTime: new Date('2023-02-14T13:06:35Z'),
              endTime: new Date('2023-02-14T13:06:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:36Z'),
              endTime: new Date('2023-02-14T13:06:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:37Z'),
              endTime: new Date('2023-02-14T13:06:38Z'),
              valueState: null,
              valueNumber: 35,
            },
            {
              startTime: new Date('2023-02-14T13:06:38Z'),
              endTime: new Date('2023-02-14T13:06:39Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:06:39Z'),
              endTime: new Date('2023-02-14T13:06:40Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:40Z'),
              endTime: new Date('2023-02-14T13:06:41Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:06:41Z'),
              endTime: new Date('2023-02-14T13:06:42Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:06:42Z'),
              endTime: new Date('2023-02-14T13:06:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:43Z'),
              endTime: new Date('2023-02-14T13:06:44Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:44Z'),
              endTime: new Date('2023-02-14T13:06:45Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:45Z'),
              endTime: new Date('2023-02-14T13:06:46Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:06:46Z'),
              endTime: new Date('2023-02-14T13:06:47Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:06:47Z'),
              endTime: new Date('2023-02-14T13:06:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:48Z'),
              endTime: new Date('2023-02-14T13:06:49Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:06:49Z'),
              endTime: new Date('2023-02-14T13:06:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:50Z'),
              endTime: new Date('2023-02-14T13:06:51Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:51Z'),
              endTime: new Date('2023-02-14T13:06:52Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:52Z'),
              endTime: new Date('2023-02-14T13:06:53Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:06:53Z'),
              endTime: new Date('2023-02-14T13:06:54Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:54Z'),
              endTime: new Date('2023-02-14T13:06:55Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:55Z'),
              endTime: new Date('2023-02-14T13:06:56Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:56Z'),
              endTime: new Date('2023-02-14T13:06:57Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:06:57Z'),
              endTime: new Date('2023-02-14T13:06:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:58Z'),
              endTime: new Date('2023-02-14T13:06:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:59Z'),
              endTime: new Date('2023-02-14T13:07:00Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:07:00Z'),
              endTime: new Date('2023-02-14T13:07:01Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:07:01Z'),
              endTime: new Date('2023-02-14T13:07:02Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:07:02Z'),
              endTime: new Date('2023-02-14T13:07:03Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:07:03Z'),
              endTime: new Date('2023-02-14T13:07:04Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:04Z'),
              endTime: new Date('2023-02-14T13:07:05Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:07:05Z'),
              endTime: new Date('2023-02-14T13:07:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:07Z'),
              endTime: new Date('2023-02-14T13:07:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:08Z'),
              endTime: new Date('2023-02-14T13:07:09Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:07:09Z'),
              endTime: new Date('2023-02-14T13:07:10Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:07:10Z'),
              endTime: new Date('2023-02-14T13:07:11Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:07:11Z'),
              endTime: new Date('2023-02-14T13:07:12Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:07:12Z'),
              endTime: new Date('2023-02-14T13:07:13Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:07:13Z'),
              endTime: new Date('2023-02-14T13:07:16Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:07:16Z'),
              endTime: new Date('2023-02-14T13:07:17Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:07:17Z'),
              endTime: new Date('2023-02-14T13:07:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:18Z'),
              endTime: new Date('2023-02-14T13:07:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:19Z'),
              endTime: new Date('2023-02-14T13:07:20Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:07:20Z'),
              endTime: new Date('2023-02-14T13:07:21Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:07:21Z'),
              endTime: new Date('2023-02-14T13:07:22Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:07:22Z'),
              endTime: new Date('2023-02-14T13:07:23Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:23Z'),
              endTime: new Date('2023-02-14T13:07:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:24Z'),
              endTime: new Date('2023-02-14T13:07:25Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:07:25Z'),
              endTime: new Date('2023-02-14T13:07:26Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:07:26Z'),
              endTime: new Date('2023-02-14T13:07:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:29Z'),
              endTime: new Date('2023-02-14T13:07:30Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:30Z'),
              endTime: new Date('2023-02-14T13:07:31Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:07:31Z'),
              endTime: new Date('2023-02-14T13:07:32Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:07:32Z'),
              endTime: new Date('2023-02-14T13:07:39Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:07:39Z'),
              endTime: new Date('2023-02-14T13:07:40Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:07:40Z'),
              endTime: new Date('2023-02-14T13:07:41Z'),
              valueState: null,
              valueNumber: 53,
            },
            {
              startTime: new Date('2023-02-14T13:07:41Z'),
              endTime: new Date('2023-02-14T13:07:42Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:07:42Z'),
              endTime: new Date('2023-02-14T13:07:43Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:07:43Z'),
              endTime: new Date('2023-02-14T13:07:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:44Z'),
              endTime: new Date('2023-02-14T13:07:45Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:45Z'),
              endTime: new Date('2023-02-14T13:07:46Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:07:46Z'),
              endTime: new Date('2023-02-14T13:07:47Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:47Z'),
              endTime: new Date('2023-02-14T13:07:48Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:07:48Z'),
              endTime: new Date('2023-02-14T13:07:49Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:07:49Z'),
              endTime: new Date('2023-02-14T13:07:50Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:07:50Z'),
              endTime: new Date('2023-02-14T13:07:57Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:07:57Z'),
              endTime: new Date('2023-02-14T13:07:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:07:58Z'),
              endTime: new Date('2023-02-14T13:07:59Z'),
              valueState: null,
              valueNumber: 14,
            },
            {
              startTime: new Date('2023-02-14T13:07:59Z'),
              endTime: new Date('2023-02-14T13:08:00Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:00Z'),
              endTime: new Date('2023-02-14T13:08:03Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:08:03Z'),
              endTime: new Date('2023-02-14T13:08:04Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:08:04Z'),
              endTime: new Date('2023-02-14T13:08:05Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:05Z'),
              endTime: new Date('2023-02-14T13:08:06Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:08:06Z'),
              endTime: new Date('2023-02-14T13:08:07Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:08:07Z'),
              endTime: new Date('2023-02-14T13:08:15Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:08:15Z'),
              endTime: new Date('2023-02-14T13:08:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:08:16Z'),
              endTime: new Date('2023-02-14T13:08:17Z'),
              valueState: null,
              valueNumber: 45,
            },
            {
              startTime: new Date('2023-02-14T13:08:17Z'),
              endTime: new Date('2023-02-14T13:08:19Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:08:19Z'),
              endTime: new Date('2023-02-14T13:08:20Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:08:20Z'),
              endTime: new Date('2023-02-14T13:08:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:21Z'),
              endTime: new Date('2023-02-14T13:08:22Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:08:22Z'),
              endTime: new Date('2023-02-14T13:08:23Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:23Z'),
              endTime: new Date('2023-02-14T13:08:24Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:08:24Z'),
              endTime: new Date('2023-02-14T13:08:25Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:08:25Z'),
              endTime: new Date('2023-02-14T13:08:26Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:08:26Z'),
              endTime: new Date('2023-02-14T13:08:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:08:32Z'),
              endTime: new Date('2023-02-14T13:08:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:08:33Z'),
              endTime: new Date('2023-02-14T13:08:34Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:08:34Z'),
              endTime: new Date('2023-02-14T13:08:35Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:08:35Z'),
              endTime: new Date('2023-02-14T13:08:36Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:08:36Z'),
              endTime: new Date('2023-02-14T13:08:37Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:37Z'),
              endTime: new Date('2023-02-14T13:08:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:38Z'),
              endTime: new Date('2023-02-14T13:08:40Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:08:40Z'),
              endTime: new Date('2023-02-14T13:08:41Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:08:41Z'),
              endTime: new Date('2023-02-14T13:08:42Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:42Z'),
              endTime: new Date('2023-02-14T13:08:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:43Z'),
              endTime: new Date('2023-02-14T13:08:44Z'),
              valueState: null,
              valueNumber: 42,
            },
            {
              startTime: new Date('2023-02-14T13:08:44Z'),
              endTime: new Date('2023-02-14T13:08:51Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:08:51Z'),
              endTime: new Date('2023-02-14T13:08:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:08:52Z'),
              endTime: new Date('2023-02-14T13:08:53Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:08:53Z'),
              endTime: new Date('2023-02-14T13:08:54Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:08:54Z'),
              endTime: new Date('2023-02-14T13:08:58Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:58Z'),
              endTime: new Date('2023-02-14T13:08:59Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:59Z'),
              endTime: new Date('2023-02-14T13:09:00Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:00Z'),
              endTime: new Date('2023-02-14T13:09:01Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:01Z'),
              endTime: new Date('2023-02-14T13:09:02Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:09:02Z'),
              endTime: new Date('2023-02-14T13:09:10Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:10Z'),
              endTime: new Date('2023-02-14T13:09:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:11Z'),
              endTime: new Date('2023-02-14T13:09:12Z'),
              valueState: null,
              valueNumber: 52,
            },
            {
              startTime: new Date('2023-02-14T13:09:12Z'),
              endTime: new Date('2023-02-14T13:09:13Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:09:13Z'),
              endTime: new Date('2023-02-14T13:09:14Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:14Z'),
              endTime: new Date('2023-02-14T13:09:15Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:15Z'),
              endTime: new Date('2023-02-14T13:09:16Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:09:16Z'),
              endTime: new Date('2023-02-14T13:09:17Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:17Z'),
              endTime: new Date('2023-02-14T13:09:18Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:18Z'),
              endTime: new Date('2023-02-14T13:09:19Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:09:19Z'),
              endTime: new Date('2023-02-14T13:09:20Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:09:20Z'),
              endTime: new Date('2023-02-14T13:09:27Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:27Z'),
              endTime: new Date('2023-02-14T13:09:28Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:28Z'),
              endTime: new Date('2023-02-14T13:09:29Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:09:29Z'),
              endTime: new Date('2023-02-14T13:09:30Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:09:30Z'),
              endTime: new Date('2023-02-14T13:09:31Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:31Z'),
              endTime: new Date('2023-02-14T13:09:32Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:32Z'),
              endTime: new Date('2023-02-14T13:09:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:34Z'),
              endTime: new Date('2023-02-14T13:09:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:35Z'),
              endTime: new Date('2023-02-14T13:09:36Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:09:36Z'),
              endTime: new Date('2023-02-14T13:09:37Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:37Z'),
              endTime: new Date('2023-02-14T13:09:38Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:38Z'),
              endTime: new Date('2023-02-14T13:09:39Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:09:39Z'),
              endTime: new Date('2023-02-14T13:09:40Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:40Z'),
              endTime: new Date('2023-02-14T13:09:41Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:09:41Z'),
              endTime: new Date('2023-02-14T13:09:42Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:42Z'),
              endTime: new Date('2023-02-14T13:09:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:43Z'),
              endTime: new Date('2023-02-14T13:09:45Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:45Z'),
              endTime: new Date('2023-02-14T13:09:46Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:46Z'),
              endTime: new Date('2023-02-14T13:09:47Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:47Z'),
              endTime: new Date('2023-02-14T13:09:48Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:48Z'),
              endTime: new Date('2023-02-14T13:09:49Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:49Z'),
              endTime: new Date('2023-02-14T13:09:50Z'),
              valueState: null,
              valueNumber: 4,
            },
            {
              startTime: new Date('2023-02-14T13:09:50Z'),
              endTime: new Date('2023-02-14T13:09:51Z'),
              valueState: null,
              valueNumber: 4,
            },
            {
              startTime: new Date('2023-02-14T13:09:51Z'),
              endTime: new Date('2023-02-14T13:09:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:52Z'),
              endTime: new Date('2023-02-14T13:09:53Z'),
              valueState: null,
              valueNumber: 52,
            },
            {
              startTime: new Date('2023-02-14T13:09:53Z'),
              endTime: new Date('2023-02-14T13:09:54Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:54Z'),
              endTime: new Date('2023-02-14T13:09:55Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:09:55Z'),
              endTime: new Date('2023-02-14T13:09:56Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:09:56Z'),
              endTime: new Date('2023-02-14T13:09:57Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:09:57Z'),
              endTime: new Date('2023-02-14T13:09:58Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:09:58Z'),
              endTime: new Date('2023-02-14T13:10:00Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:00Z'),
              endTime: new Date('2023-02-14T13:10:01Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:01Z'),
              endTime: new Date('2023-02-14T13:10:02Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:10:02Z'),
              endTime: new Date('2023-02-14T13:10:03Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:10:03Z'),
              endTime: new Date('2023-02-14T13:10:04Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:10:04Z'),
              endTime: new Date('2023-02-14T13:10:05Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:05Z'),
              endTime: new Date('2023-02-14T13:10:06Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:06Z'),
              endTime: new Date('2023-02-14T13:10:07Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:10:07Z'),
              endTime: new Date('2023-02-14T13:10:08Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:10:08Z'),
              endTime: new Date('2023-02-14T13:10:10Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:10Z'),
              endTime: new Date('2023-02-14T13:10:11Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:11Z'),
              endTime: new Date('2023-02-14T13:10:12Z'),
              valueState: null,
              valueNumber: 21,
            },
            {
              startTime: new Date('2023-02-14T13:10:12Z'),
              endTime: new Date('2023-02-14T13:10:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:13Z'),
              endTime: new Date('2023-02-14T13:10:14Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:10:14Z'),
              endTime: new Date('2023-02-14T13:10:15Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:10:15Z'),
              endTime: new Date('2023-02-14T13:10:16Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:10:16Z'),
              endTime: new Date('2023-02-14T13:10:17Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:10:17Z'),
              endTime: new Date('2023-02-14T13:10:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:18Z'),
              endTime: new Date('2023-02-14T13:10:19Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:10:19Z'),
              endTime: new Date('2023-02-14T13:10:20Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:10:20Z'),
              endTime: new Date('2023-02-14T13:10:21Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:10:21Z'),
              endTime: new Date('2023-02-14T13:10:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:22Z'),
              endTime: new Date('2023-02-14T13:10:23Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:10:23Z'),
              endTime: new Date('2023-02-14T13:10:24Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:24Z'),
              endTime: new Date('2023-02-14T13:10:25Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:25Z'),
              endTime: new Date('2023-02-14T13:10:26Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:10:26Z'),
              endTime: new Date('2023-02-14T13:10:31Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:10:31Z'),
              endTime: new Date('2023-02-14T13:10:32Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:10:32Z'),
              endTime: new Date('2023-02-14T13:10:33Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:33Z'),
              endTime: new Date('2023-02-14T13:10:34Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:10:34Z'),
              endTime: new Date('2023-02-14T13:10:35Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:10:35Z'),
              endTime: new Date('2023-02-14T13:10:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:36Z'),
              endTime: new Date('2023-02-14T13:10:37Z'),
              valueState: null,
              valueNumber: 56,
            },
            {
              startTime: new Date('2023-02-14T13:10:37Z'),
              endTime: new Date('2023-02-14T13:10:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:38Z'),
              endTime: new Date('2023-02-14T13:10:39Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:39Z'),
              endTime: new Date('2023-02-14T13:10:42Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:10:42Z'),
              endTime: new Date('2023-02-14T13:10:43Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:10:43Z'),
              endTime: new Date('2023-02-14T13:10:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:44Z'),
              endTime: new Date('2023-02-14T13:10:45Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:45Z'),
              endTime: new Date('2023-02-14T13:10:46Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:10:46Z'),
              endTime: new Date('2023-02-14T13:10:47Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:10:47Z'),
              endTime: new Date('2023-02-14T13:10:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:49Z'),
              endTime: new Date('2023-02-14T13:10:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:50Z'),
              endTime: new Date('2023-02-14T13:10:51Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:10:51Z'),
              endTime: new Date('2023-02-14T13:10:52Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:52Z'),
              endTime: new Date('2023-02-14T13:10:54Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:10:54Z'),
              endTime: new Date('2023-02-14T13:10:55Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:10:55Z'),
              endTime: new Date('2023-02-14T13:10:56Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:10:56Z'),
              endTime: new Date('2023-02-14T13:10:57Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:57Z'),
              endTime: new Date('2023-02-14T13:10:58Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:10:58Z'),
              endTime: new Date('2023-02-14T13:11:00Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:11:00Z'),
              endTime: new Date('2023-02-14T13:11:01Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:11:01Z'),
              endTime: new Date('2023-02-14T13:11:02Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:11:02Z'),
              endTime: new Date('2023-02-14T13:11:03Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:11:03Z'),
              endTime: new Date('2023-02-14T13:11:04Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:04Z'),
              endTime: new Date('2023-02-14T13:11:05Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:11:05Z'),
              endTime: new Date('2023-02-14T13:11:06Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:11:06Z'),
              endTime: new Date('2023-02-14T13:11:07Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:11:07Z'),
              endTime: new Date('2023-02-14T13:11:08Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:11:08Z'),
              endTime: new Date('2023-02-14T13:11:09Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:11:09Z'),
              endTime: new Date('2023-02-14T13:11:10Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:11:10Z'),
              endTime: new Date('2023-02-14T13:11:11Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:11:11Z'),
              endTime: new Date('2023-02-14T13:11:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:13Z'),
              endTime: new Date('2023-02-14T13:11:14Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:14Z'),
              endTime: new Date('2023-02-14T13:11:15Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:11:15Z'),
              endTime: new Date('2023-02-14T13:11:17Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:17Z'),
              endTime: new Date('2023-02-14T13:11:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:18Z'),
              endTime: new Date('2023-02-14T13:11:19Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:11:19Z'),
              endTime: new Date('2023-02-14T13:11:20Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:11:20Z'),
              endTime: new Date('2023-02-14T13:11:21Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:11:21Z'),
              endTime: new Date('2023-02-14T13:11:23Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:11:23Z'),
              endTime: new Date('2023-02-14T13:11:24Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:11:24Z'),
              endTime: new Date('2023-02-14T13:11:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:25Z'),
              endTime: new Date('2023-02-14T13:11:26Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:26Z'),
              endTime: new Date('2023-02-14T13:11:27Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:11:27Z'),
              endTime: new Date('2023-02-14T13:11:28Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:11:28Z'),
              endTime: new Date('2023-02-14T13:11:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:29Z'),
              endTime: new Date('2023-02-14T13:11:30Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:11:30Z'),
              endTime: new Date('2023-02-14T13:11:31Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:11:31Z'),
              endTime: new Date('2023-02-14T13:11:32Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:11:32Z'),
              endTime: new Date('2023-02-14T13:11:33Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:11:33Z'),
              endTime: new Date('2023-02-14T13:11:36Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:36Z'),
              endTime: new Date('2023-02-14T13:11:37Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:37Z'),
              endTime: new Date('2023-02-14T13:11:38Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:11:38Z'),
              endTime: new Date('2023-02-14T13:11:39Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:11:39Z'),
              endTime: new Date('2023-02-14T13:11:40Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:11:40Z'),
              endTime: new Date('2023-02-14T13:11:41Z'),
              valueState: null,
              valueNumber: 41,
            },
            {
              startTime: new Date('2023-02-14T13:11:41Z'),
              endTime: new Date('2023-02-14T13:11:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:11:42Z'),
              endTime: new Date('2023-02-14T13:11:43Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T13:11:43Z'),
              endTime: new Date('2023-02-14T13:11:44Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:11:44Z'),
              endTime: new Date('2023-02-14T13:11:45Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:11:45Z'),
              endTime: new Date('2023-02-14T13:11:46Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:46Z'),
              endTime: new Date('2023-02-14T13:11:47Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:47Z'),
              endTime: new Date('2023-02-14T13:11:48Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:11:48Z'),
              endTime: new Date('2023-02-14T13:11:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:50Z'),
              endTime: new Date('2023-02-14T13:11:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:51Z'),
              endTime: new Date('2023-02-14T13:11:52Z'),
              valueState: null,
              valueNumber: 20,
            },
            {
              startTime: new Date('2023-02-14T13:11:52Z'),
              endTime: new Date('2023-02-14T13:12:00Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:00Z'),
              endTime: new Date('2023-02-14T13:12:01Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:01Z'),
              endTime: new Date('2023-02-14T13:12:02Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:12:02Z'),
              endTime: new Date('2023-02-14T13:12:03Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:03Z'),
              endTime: new Date('2023-02-14T13:12:04Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:12:04Z'),
              endTime: new Date('2023-02-14T13:12:05Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:12:05Z'),
              endTime: new Date('2023-02-14T13:12:06Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:12:06Z'),
              endTime: new Date('2023-02-14T13:12:08Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:12:08Z'),
              endTime: new Date('2023-02-14T13:12:09Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:12:09Z'),
              endTime: new Date('2023-02-14T13:12:10Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:12:10Z'),
              endTime: new Date('2023-02-14T13:12:11Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:12:11Z'),
              endTime: new Date('2023-02-14T13:12:17Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:17Z'),
              endTime: new Date('2023-02-14T13:12:18Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:18Z'),
              endTime: new Date('2023-02-14T13:12:19Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:12:19Z'),
              endTime: new Date('2023-02-14T13:12:20Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:12:20Z'),
              endTime: new Date('2023-02-14T13:12:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:21Z'),
              endTime: new Date('2023-02-14T13:12:22Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:12:22Z'),
              endTime: new Date('2023-02-14T13:12:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:24Z'),
              endTime: new Date('2023-02-14T13:12:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:25Z'),
              endTime: new Date('2023-02-14T13:12:26Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:12:26Z'),
              endTime: new Date('2023-02-14T13:12:27Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:27Z'),
              endTime: new Date('2023-02-14T13:12:28Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T13:12:28Z'),
              endTime: new Date('2023-02-14T13:12:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:36Z'),
              endTime: new Date('2023-02-14T13:12:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:37Z'),
              endTime: new Date('2023-02-14T13:12:38Z'),
              valueState: null,
              valueNumber: 45,
            },
            {
              startTime: new Date('2023-02-14T13:12:38Z'),
              endTime: new Date('2023-02-14T13:12:40Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:12:40Z'),
              endTime: new Date('2023-02-14T13:12:41Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:12:41Z'),
              endTime: new Date('2023-02-14T13:12:45Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:45Z'),
              endTime: new Date('2023-02-14T13:12:46Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:46Z'),
              endTime: new Date('2023-02-14T13:12:47Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:12:47Z'),
              endTime: new Date('2023-02-14T13:12:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:53Z'),
              endTime: new Date('2023-02-14T13:12:54Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:54Z'),
              endTime: new Date('2023-02-14T13:12:55Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:12:55Z'),
              endTime: new Date('2023-02-14T13:12:56Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:12:56Z'),
              endTime: new Date('2023-02-14T13:12:57Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:12:57Z'),
              endTime: new Date('2023-02-14T13:13:02Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:13:02Z'),
              endTime: new Date('2023-02-14T13:13:03Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:13:03Z'),
              endTime: new Date('2023-02-14T13:13:04Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:13:04Z'),
              endTime: new Date('2023-02-14T13:13:12Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:12Z'),
              endTime: new Date('2023-02-14T13:13:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:13Z'),
              endTime: new Date('2023-02-14T13:13:14Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:13:14Z'),
              endTime: new Date('2023-02-14T13:13:15Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:13:15Z'),
              endTime: new Date('2023-02-14T13:13:16Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:13:16Z'),
              endTime: new Date('2023-02-14T13:13:17Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:17Z'),
              endTime: new Date('2023-02-14T13:13:18Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:13:18Z'),
              endTime: new Date('2023-02-14T13:13:19Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:13:19Z'),
              endTime: new Date('2023-02-14T13:13:20Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:20Z'),
              endTime: new Date('2023-02-14T13:13:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:21Z'),
              endTime: new Date('2023-02-14T13:13:22Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:13:22Z'),
              endTime: new Date('2023-02-14T13:13:31Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:31Z'),
              endTime: new Date('2023-02-14T13:13:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:32Z'),
              endTime: new Date('2023-02-14T13:13:33Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:13:33Z'),
              endTime: new Date('2023-02-14T13:13:36Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:36Z'),
              endTime: new Date('2023-02-14T13:13:37Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:37Z'),
              endTime: new Date('2023-02-14T13:13:38Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:13:38Z'),
              endTime: new Date('2023-02-14T13:13:40Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:40Z'),
              endTime: new Date('2023-02-14T13:13:41Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:41Z'),
              endTime: new Date('2023-02-14T13:13:42Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:13:42Z'),
              endTime: new Date('2023-02-14T13:13:43Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:13:43Z'),
              endTime: new Date('2023-02-14T13:13:44Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:13:44Z'),
              endTime: new Date('2023-02-14T13:13:48Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:48Z'),
              endTime: new Date('2023-02-14T13:13:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:49Z'),
              endTime: new Date('2023-02-14T13:13:50Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:13:50Z'),
              endTime: new Date('2023-02-14T13:13:51Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:13:51Z'),
              endTime: new Date('2023-02-14T13:13:52Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T13:13:52Z'),
              endTime: new Date('2023-02-14T13:13:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:53Z'),
              endTime: new Date('2023-02-14T13:13:54Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:54Z'),
              endTime: new Date('2023-02-14T13:13:55Z'),
              valueState: null,
              valueNumber: 30,
            },
            {
              startTime: new Date('2023-02-14T13:13:55Z'),
              endTime: new Date('2023-02-14T13:13:56Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:13:56Z'),
              endTime: new Date('2023-02-14T13:13:57Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:13:57Z'),
              endTime: new Date('2023-02-14T13:13:59Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:13:59Z'),
              endTime: new Date('2023-02-14T13:14:00Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:00Z'),
              endTime: new Date('2023-02-14T13:14:01Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:14:01Z'),
              endTime: new Date('2023-02-14T13:14:02Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:02Z'),
              endTime: new Date('2023-02-14T13:14:03Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:14:03Z'),
              endTime: new Date('2023-02-14T13:14:04Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:14:04Z'),
              endTime: new Date('2023-02-14T13:14:05Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:05Z'),
              endTime: new Date('2023-02-14T13:14:06Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:14:06Z'),
              endTime: new Date('2023-02-14T13:14:07Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:07Z'),
              endTime: new Date('2023-02-14T13:14:09Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:09Z'),
              endTime: new Date('2023-02-14T13:14:10Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:10Z'),
              endTime: new Date('2023-02-14T13:14:11Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:14:11Z'),
              endTime: new Date('2023-02-14T13:14:13Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:13Z'),
              endTime: new Date('2023-02-14T13:14:14Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:14Z'),
              endTime: new Date('2023-02-14T13:14:15Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:14:15Z'),
              endTime: new Date('2023-02-14T13:14:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:16Z'),
              endTime: new Date('2023-02-14T13:14:17Z'),
              valueState: null,
              valueNumber: 52,
            },
            {
              startTime: new Date('2023-02-14T13:14:17Z'),
              endTime: new Date('2023-02-14T13:14:18Z'),
              valueState: null,
              valueNumber: 52,
            },
            {
              startTime: new Date('2023-02-14T13:14:18Z'),
              endTime: new Date('2023-02-14T13:14:19Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:19Z'),
              endTime: new Date('2023-02-14T13:14:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:21Z'),
              endTime: new Date('2023-02-14T13:14:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:22Z'),
              endTime: new Date('2023-02-14T13:14:24Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:24Z'),
              endTime: new Date('2023-02-14T13:14:25Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:25Z'),
              endTime: new Date('2023-02-14T13:14:26Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:14:26Z'),
              endTime: new Date('2023-02-14T13:14:27Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:14:27Z'),
              endTime: new Date('2023-02-14T13:14:28Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:14:28Z'),
              endTime: new Date('2023-02-14T13:14:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:29Z'),
              endTime: new Date('2023-02-14T13:14:33Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:33Z'),
              endTime: new Date('2023-02-14T13:14:34Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:34Z'),
              endTime: new Date('2023-02-14T13:14:35Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:14:35Z'),
              endTime: new Date('2023-02-14T13:14:36Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:14:36Z'),
              endTime: new Date('2023-02-14T13:14:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:37Z'),
              endTime: new Date('2023-02-14T13:14:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:38Z'),
              endTime: new Date('2023-02-14T13:14:39Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T13:14:39Z'),
              endTime: new Date('2023-02-14T13:14:42Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:42Z'),
              endTime: new Date('2023-02-14T13:14:43Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:43Z'),
              endTime: new Date('2023-02-14T13:14:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:44Z'),
              endTime: new Date('2023-02-14T13:14:45Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:45Z'),
              endTime: new Date('2023-02-14T13:14:46Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:14:46Z'),
              endTime: new Date('2023-02-14T13:14:47Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:14:47Z'),
              endTime: new Date('2023-02-14T13:14:48Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:14:48Z'),
              endTime: new Date('2023-02-14T13:14:49Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:49Z'),
              endTime: new Date('2023-02-14T13:14:50Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:14:50Z'),
              endTime: new Date('2023-02-14T13:14:51Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:14:51Z'),
              endTime: new Date('2023-02-14T13:14:52Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:52Z'),
              endTime: new Date('2023-02-14T13:14:54Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:14:54Z'),
              endTime: new Date('2023-02-14T13:14:55Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:14:55Z'),
              endTime: new Date('2023-02-14T13:14:56Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:14:56Z'),
              endTime: new Date('2023-02-14T13:16:10Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:10Z'),
              endTime: new Date('2023-02-14T13:16:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:11Z'),
              endTime: new Date('2023-02-14T13:16:12Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:16:12Z'),
              endTime: new Date('2023-02-14T13:16:13Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:16:13Z'),
              endTime: new Date('2023-02-14T13:16:14Z'),
              valueState: null,
              valueNumber: 54,
            },
            {
              startTime: new Date('2023-02-14T13:16:14Z'),
              endTime: new Date('2023-02-14T13:16:15Z'),
              valueState: null,
              valueNumber: 54,
            },
            {
              startTime: new Date('2023-02-14T13:16:15Z'),
              endTime: new Date('2023-02-14T13:16:16Z'),
              valueState: null,
              valueNumber: 11,
            },
            {
              startTime: new Date('2023-02-14T13:16:16Z'),
              endTime: new Date('2023-02-14T13:16:17Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:17Z'),
              endTime: new Date('2023-02-14T13:16:19Z'),
              valueState: null,
              valueNumber: 54,
            },
            {
              startTime: new Date('2023-02-14T13:16:19Z'),
              endTime: new Date('2023-02-14T13:16:20Z'),
              valueState: null,
              valueNumber: 54,
            },
            {
              startTime: new Date('2023-02-14T13:16:20Z'),
              endTime: new Date('2023-02-14T13:16:21Z'),
              valueState: null,
              valueNumber: 56,
            },
            {
              startTime: new Date('2023-02-14T13:16:21Z'),
              endTime: new Date('2023-02-14T13:16:22Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:16:22Z'),
              endTime: new Date('2023-02-14T13:16:24Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:24Z'),
              endTime: new Date('2023-02-14T13:16:25Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:25Z'),
              endTime: new Date('2023-02-14T13:16:26Z'),
              valueState: null,
              valueNumber: 34,
            },
            {
              startTime: new Date('2023-02-14T13:16:26Z'),
              endTime: new Date('2023-02-14T13:16:27Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:16:27Z'),
              endTime: new Date('2023-02-14T13:16:28Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:16:28Z'),
              endTime: new Date('2023-02-14T13:16:29Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:16:29Z'),
              endTime: new Date('2023-02-14T13:16:30Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:16:30Z'),
              endTime: new Date('2023-02-14T13:16:31Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:16:31Z'),
              endTime: new Date('2023-02-14T13:16:32Z'),
              valueState: null,
              valueNumber: 83,
            },
            {
              startTime: new Date('2023-02-14T13:16:32Z'),
              endTime: new Date('2023-02-14T13:16:33Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:16:33Z'),
              endTime: new Date('2023-02-14T13:16:34Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:16:34Z'),
              endTime: new Date('2023-02-14T13:16:35Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:16:35Z'),
              endTime: new Date('2023-02-14T13:16:36Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:16:36Z'),
              endTime: new Date('2023-02-14T13:16:37Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:16:37Z'),
              endTime: new Date('2023-02-14T13:16:38Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:16:38Z'),
              endTime: new Date('2023-02-14T13:16:39Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:16:39Z'),
              endTime: new Date('2023-02-14T13:16:40Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:16:40Z'),
              endTime: new Date('2023-02-14T13:16:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:42Z'),
              endTime: new Date('2023-02-14T13:16:43Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:43Z'),
              endTime: new Date('2023-02-14T13:16:44Z'),
              valueState: null,
              valueNumber: 43,
            },
            {
              startTime: new Date('2023-02-14T13:16:44Z'),
              endTime: new Date('2023-02-14T13:16:45Z'),
              valueState: null,
              valueNumber: 83,
            },
            {
              startTime: new Date('2023-02-14T13:16:45Z'),
              endTime: new Date('2023-02-14T13:16:46Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:16:46Z'),
              endTime: new Date('2023-02-14T13:16:47Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:16:47Z'),
              endTime: new Date('2023-02-14T13:16:48Z'),
              valueState: null,
              valueNumber: 84,
            },
            {
              startTime: new Date('2023-02-14T13:16:48Z'),
              endTime: new Date('2023-02-14T13:16:49Z'),
              valueState: null,
              valueNumber: 82,
            },
            {
              startTime: new Date('2023-02-14T13:16:49Z'),
              endTime: new Date('2023-02-14T13:16:50Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:16:50Z'),
              endTime: new Date('2023-02-14T13:16:51Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:16:51Z'),
              endTime: new Date('2023-02-14T13:16:52Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:16:52Z'),
              endTime: new Date('2023-02-14T13:16:53Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:16:53Z'),
              endTime: new Date('2023-02-14T13:16:54Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:16:54Z'),
              endTime: new Date('2023-02-14T13:16:55Z'),
              valueState: null,
              valueNumber: 82,
            },
            {
              startTime: new Date('2023-02-14T13:16:55Z'),
              endTime: new Date('2023-02-14T13:16:56Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:16:56Z'),
              endTime: new Date('2023-02-14T13:16:57Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:16:57Z'),
              endTime: new Date('2023-02-14T13:16:58Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:16:58Z'),
              endTime: new Date('2023-02-14T13:16:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:59Z'),
              endTime: new Date('2023-02-14T13:17:00Z'),
              valueState: null,
              valueNumber: 52,
            },
            {
              startTime: new Date('2023-02-14T13:17:00Z'),
              endTime: new Date('2023-02-14T13:17:01Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:17:01Z'),
              endTime: new Date('2023-02-14T13:17:02Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:17:02Z'),
              endTime: new Date('2023-02-14T13:17:03Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:17:03Z'),
              endTime: new Date('2023-02-14T13:17:04Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:17:04Z'),
              endTime: new Date('2023-02-14T13:17:06Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:17:06Z'),
              endTime: new Date('2023-02-14T13:17:07Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:17:07Z'),
              endTime: new Date('2023-02-14T13:17:08Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:17:08Z'),
              endTime: new Date('2023-02-14T13:17:10Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:17:10Z'),
              endTime: new Date('2023-02-14T13:17:11Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:17:11Z'),
              endTime: new Date('2023-02-14T13:17:12Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:17:12Z'),
              endTime: new Date('2023-02-14T13:17:13Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:17:13Z'),
              endTime: new Date('2023-02-14T13:17:14Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:17:14Z'),
              endTime: new Date('2023-02-14T13:17:15Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:17:15Z'),
              endTime: new Date('2023-02-14T13:17:16Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:17:16Z'),
              endTime: new Date('2023-02-14T13:17:19Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:17:19Z'),
              endTime: new Date('2023-02-14T13:17:20Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:17:20Z'),
              endTime: new Date('2023-02-14T13:17:21Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:17:21Z'),
              endTime: new Date('2023-02-14T13:17:22Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:17:22Z'),
              endTime: new Date('2023-02-14T13:17:23Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:17:23Z'),
              endTime: new Date('2023-02-14T13:17:24Z'),
              valueState: null,
              valueNumber: 114,
            },
            {
              startTime: new Date('2023-02-14T13:17:24Z'),
              endTime: new Date('2023-02-14T13:17:25Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:17:25Z'),
              endTime: new Date('2023-02-14T13:17:26Z'),
              valueState: null,
              valueNumber: 132,
            },
            {
              startTime: new Date('2023-02-14T13:17:26Z'),
              endTime: new Date('2023-02-14T13:17:27Z'),
              valueState: null,
              valueNumber: 133,
            },
            {
              startTime: new Date('2023-02-14T13:17:27Z'),
              endTime: new Date('2023-02-14T13:17:30Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:17:30Z'),
              endTime: new Date('2023-02-14T13:17:31Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:17:31Z'),
              endTime: new Date('2023-02-14T13:17:34Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:17:34Z'),
              endTime: new Date('2023-02-14T13:17:35Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:17:35Z'),
              endTime: new Date('2023-02-14T13:17:36Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:17:36Z'),
              endTime: new Date('2023-02-14T13:17:37Z'),
              valueState: null,
              valueNumber: 109,
            },
            {
              startTime: new Date('2023-02-14T13:17:37Z'),
              endTime: new Date('2023-02-14T13:17:39Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:17:39Z'),
              endTime: new Date('2023-02-14T13:17:40Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:17:40Z'),
              endTime: new Date('2023-02-14T13:17:41Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:17:41Z'),
              endTime: new Date('2023-02-14T13:17:42Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:17:42Z'),
              endTime: new Date('2023-02-14T13:17:45Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:17:45Z'),
              endTime: new Date('2023-02-14T13:17:46Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:17:46Z'),
              endTime: new Date('2023-02-14T13:17:49Z'),
              valueState: null,
              valueNumber: 101,
            },
            {
              startTime: new Date('2023-02-14T13:17:49Z'),
              endTime: new Date('2023-02-14T13:17:50Z'),
              valueState: null,
              valueNumber: 101,
            },
            {
              startTime: new Date('2023-02-14T13:17:50Z'),
              endTime: new Date('2023-02-14T13:17:51Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:17:51Z'),
              endTime: new Date('2023-02-14T13:17:52Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:17:52Z'),
              endTime: new Date('2023-02-14T13:17:53Z'),
              valueState: null,
              valueNumber: 109,
            },
            {
              startTime: new Date('2023-02-14T13:17:53Z'),
              endTime: new Date('2023-02-14T13:17:54Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:17:54Z'),
              endTime: new Date('2023-02-14T13:17:55Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:17:55Z'),
              endTime: new Date('2023-02-14T13:17:56Z'),
              valueState: null,
              valueNumber: 148,
            },
            {
              startTime: new Date('2023-02-14T13:17:56Z'),
              endTime: new Date('2023-02-14T13:17:57Z'),
              valueState: null,
              valueNumber: 152,
            },
            {
              startTime: new Date('2023-02-14T13:17:57Z'),
              endTime: new Date('2023-02-14T13:17:58Z'),
              valueState: null,
              valueNumber: 152,
            },
            {
              startTime: new Date('2023-02-14T13:17:58Z'),
              endTime: new Date('2023-02-14T13:18:00Z'),
              valueState: null,
              valueNumber: 151,
            },
            {
              startTime: new Date('2023-02-14T13:18:00Z'),
              endTime: new Date('2023-02-14T13:18:01Z'),
              valueState: null,
              valueNumber: 151,
            },
            {
              startTime: new Date('2023-02-14T13:18:01Z'),
              endTime: new Date('2023-02-14T13:18:02Z'),
              valueState: null,
              valueNumber: 161,
            },
            {
              startTime: new Date('2023-02-14T13:18:02Z'),
              endTime: new Date('2023-02-14T13:18:03Z'),
              valueState: null,
              valueNumber: 209,
            },
            {
              startTime: new Date('2023-02-14T13:18:03Z'),
              endTime: new Date('2023-02-14T13:18:04Z'),
              valueState: null,
              valueNumber: 209,
            },
            {
              startTime: new Date('2023-02-14T13:18:04Z'),
              endTime: new Date('2023-02-14T13:18:05Z'),
              valueState: null,
              valueNumber: 242,
            },
            {
              startTime: new Date('2023-02-14T13:18:05Z'),
              endTime: new Date('2023-02-14T13:18:06Z'),
              valueState: null,
              valueNumber: 248,
            },
            {
              startTime: new Date('2023-02-14T13:18:06Z'),
              endTime: new Date('2023-02-14T13:18:07Z'),
              valueState: null,
              valueNumber: 254,
            },
            {
              startTime: new Date('2023-02-14T13:18:07Z'),
              endTime: new Date('2023-02-14T13:18:08Z'),
              valueState: null,
              valueNumber: 260,
            },
            {
              startTime: new Date('2023-02-14T13:18:08Z'),
              endTime: new Date('2023-02-14T13:18:09Z'),
              valueState: null,
              valueNumber: 267,
            },
            {
              startTime: new Date('2023-02-14T13:18:09Z'),
              endTime: new Date('2023-02-14T13:18:10Z'),
              valueState: null,
              valueNumber: 267,
            },
            {
              startTime: new Date('2023-02-14T13:18:10Z'),
              endTime: new Date('2023-02-14T13:18:11Z'),
              valueState: null,
              valueNumber: 272,
            },
            {
              startTime: new Date('2023-02-14T13:18:11Z'),
              endTime: new Date('2023-02-14T13:18:12Z'),
              valueState: null,
              valueNumber: 278,
            },
            {
              startTime: new Date('2023-02-14T13:18:12Z'),
              endTime: new Date('2023-02-14T13:18:13Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:18:13Z'),
              endTime: new Date('2023-02-14T13:18:14Z'),
              valueState: null,
              valueNumber: 282,
            },
            {
              startTime: new Date('2023-02-14T13:18:14Z'),
              endTime: new Date('2023-02-14T13:18:15Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:18:15Z'),
              endTime: new Date('2023-02-14T13:18:16Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:18:16Z'),
              endTime: new Date('2023-02-14T13:18:17Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:18:17Z'),
              endTime: new Date('2023-02-14T13:18:18Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:18:18Z'),
              endTime: new Date('2023-02-14T13:18:19Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:18:19Z'),
              endTime: new Date('2023-02-14T13:18:20Z'),
              valueState: null,
              valueNumber: 279,
            },
            {
              startTime: new Date('2023-02-14T13:18:20Z'),
              endTime: new Date('2023-02-14T13:18:22Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:18:22Z'),
              endTime: new Date('2023-02-14T13:18:23Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:18:23Z'),
              endTime: new Date('2023-02-14T13:18:24Z'),
              valueState: null,
              valueNumber: 287,
            },
            {
              startTime: new Date('2023-02-14T13:18:24Z'),
              endTime: new Date('2023-02-14T13:18:25Z'),
              valueState: null,
              valueNumber: 294,
            },
            {
              startTime: new Date('2023-02-14T13:18:25Z'),
              endTime: new Date('2023-02-14T13:18:26Z'),
              valueState: null,
              valueNumber: 300,
            },
            {
              startTime: new Date('2023-02-14T13:18:26Z'),
              endTime: new Date('2023-02-14T13:18:27Z'),
              valueState: null,
              valueNumber: 300,
            },
            {
              startTime: new Date('2023-02-14T13:18:27Z'),
              endTime: new Date('2023-02-14T13:18:28Z'),
              valueState: null,
              valueNumber: 306,
            },
            {
              startTime: new Date('2023-02-14T13:18:28Z'),
              endTime: new Date('2023-02-14T13:18:29Z'),
              valueState: null,
              valueNumber: 312,
            },
            {
              startTime: new Date('2023-02-14T13:18:29Z'),
              endTime: new Date('2023-02-14T13:18:30Z'),
              valueState: null,
              valueNumber: 319,
            },
            {
              startTime: new Date('2023-02-14T13:18:30Z'),
              endTime: new Date('2023-02-14T13:18:31Z'),
              valueState: null,
              valueNumber: 324,
            },
            {
              startTime: new Date('2023-02-14T13:18:31Z'),
              endTime: new Date('2023-02-14T13:18:32Z'),
              valueState: null,
              valueNumber: 324,
            },
            {
              startTime: new Date('2023-02-14T13:18:32Z'),
              endTime: new Date('2023-02-14T13:18:33Z'),
              valueState: null,
              valueNumber: 331,
            },
            {
              startTime: new Date('2023-02-14T13:18:33Z'),
              endTime: new Date('2023-02-14T13:18:34Z'),
              valueState: null,
              valueNumber: 337,
            },
            {
              startTime: new Date('2023-02-14T13:18:34Z'),
              endTime: new Date('2023-02-14T13:18:35Z'),
              valueState: null,
              valueNumber: 343,
            },
            {
              startTime: new Date('2023-02-14T13:18:35Z'),
              endTime: new Date('2023-02-14T13:18:36Z'),
              valueState: null,
              valueNumber: 349,
            },
            {
              startTime: new Date('2023-02-14T13:18:36Z'),
              endTime: new Date('2023-02-14T13:18:37Z'),
              valueState: null,
              valueNumber: 355,
            },
            {
              startTime: new Date('2023-02-14T13:18:37Z'),
              endTime: new Date('2023-02-14T13:18:38Z'),
              valueState: null,
              valueNumber: 355,
            },
            {
              startTime: new Date('2023-02-14T13:18:38Z'),
              endTime: new Date('2023-02-14T13:18:39Z'),
              valueState: null,
              valueNumber: 361,
            },
            {
              startTime: new Date('2023-02-14T13:18:39Z'),
              endTime: new Date('2023-02-14T13:18:40Z'),
              valueState: null,
              valueNumber: 366,
            },
            {
              startTime: new Date('2023-02-14T13:18:40Z'),
              endTime: new Date('2023-02-14T13:18:41Z'),
              valueState: null,
              valueNumber: 373,
            },
            {
              startTime: new Date('2023-02-14T13:18:41Z'),
              endTime: new Date('2023-02-14T13:18:42Z'),
              valueState: null,
              valueNumber: 373,
            },
            {
              startTime: new Date('2023-02-14T13:18:42Z'),
              endTime: new Date('2023-02-14T13:18:43Z'),
              valueState: null,
              valueNumber: 378,
            },
            {
              startTime: new Date('2023-02-14T13:18:43Z'),
              endTime: new Date('2023-02-14T13:18:44Z'),
              valueState: null,
              valueNumber: 385,
            },
            {
              startTime: new Date('2023-02-14T13:18:44Z'),
              endTime: new Date('2023-02-14T13:18:45Z'),
              valueState: null,
              valueNumber: 391,
            },
            {
              startTime: new Date('2023-02-14T13:18:45Z'),
              endTime: new Date('2023-02-14T13:18:46Z'),
              valueState: null,
              valueNumber: 397,
            },
            {
              startTime: new Date('2023-02-14T13:18:46Z'),
              endTime: new Date('2023-02-14T13:18:47Z'),
              valueState: null,
              valueNumber: 397,
            },
            {
              startTime: new Date('2023-02-14T13:18:47Z'),
              endTime: new Date('2023-02-14T13:18:48Z'),
              valueState: null,
              valueNumber: 403,
            },
            {
              startTime: new Date('2023-02-14T13:18:48Z'),
              endTime: new Date('2023-02-14T13:18:49Z'),
              valueState: null,
              valueNumber: 409,
            },
            {
              startTime: new Date('2023-02-14T13:18:49Z'),
              endTime: new Date('2023-02-14T13:18:50Z'),
              valueState: null,
              valueNumber: 414,
            },
            {
              startTime: new Date('2023-02-14T13:18:50Z'),
              endTime: new Date('2023-02-14T13:18:51Z'),
              valueState: null,
              valueNumber: 421,
            },
            {
              startTime: new Date('2023-02-14T13:18:51Z'),
              endTime: new Date('2023-02-14T13:18:52Z'),
              valueState: null,
              valueNumber: 426,
            },
            {
              startTime: new Date('2023-02-14T13:18:52Z'),
              endTime: new Date('2023-02-14T13:18:53Z'),
              valueState: null,
              valueNumber: 426,
            },
            {
              startTime: new Date('2023-02-14T13:18:53Z'),
              endTime: new Date('2023-02-14T13:18:54Z'),
              valueState: null,
              valueNumber: 433,
            },
            {
              startTime: new Date('2023-02-14T13:18:54Z'),
              endTime: new Date('2023-02-14T13:18:55Z'),
              valueState: null,
              valueNumber: 439,
            },
            {
              startTime: new Date('2023-02-14T13:18:55Z'),
              endTime: new Date('2023-02-14T13:18:56Z'),
              valueState: null,
              valueNumber: 445,
            },
            {
              startTime: new Date('2023-02-14T13:18:56Z'),
              endTime: new Date('2023-02-14T13:18:58Z'),
              valueState: null,
              valueNumber: 451,
            },
            {
              startTime: new Date('2023-02-14T13:18:58Z'),
              endTime: new Date('2023-02-14T13:18:59Z'),
              valueState: null,
              valueNumber: 451,
            },
            {
              startTime: new Date('2023-02-14T13:18:59Z'),
              endTime: new Date('2023-02-14T13:19:00Z'),
              valueState: null,
              valueNumber: 425,
            },
            {
              startTime: new Date('2023-02-14T13:19:00Z'),
              endTime: new Date('2023-02-14T13:19:01Z'),
              valueState: null,
              valueNumber: 389,
            },
            {
              startTime: new Date('2023-02-14T13:19:01Z'),
              endTime: new Date('2023-02-14T13:19:02Z'),
              valueState: null,
              valueNumber: 350,
            },
            {
              startTime: new Date('2023-02-14T13:19:02Z'),
              endTime: new Date('2023-02-14T13:19:03Z'),
              valueState: null,
              valueNumber: 309,
            },
            {
              startTime: new Date('2023-02-14T13:19:03Z'),
              endTime: new Date('2023-02-14T13:19:04Z'),
              valueState: null,
              valueNumber: 309,
            },
            {
              startTime: new Date('2023-02-14T13:19:04Z'),
              endTime: new Date('2023-02-14T13:19:05Z'),
              valueState: null,
              valueNumber: 263,
            },
            {
              startTime: new Date('2023-02-14T13:19:05Z'),
              endTime: new Date('2023-02-14T13:19:06Z'),
              valueState: null,
              valueNumber: 212,
            },
            {
              startTime: new Date('2023-02-14T13:19:06Z'),
              endTime: new Date('2023-02-14T13:19:07Z'),
              valueState: null,
              valueNumber: 212,
            },
            {
              startTime: new Date('2023-02-14T13:19:07Z'),
              endTime: new Date('2023-02-14T13:19:08Z'),
              valueState: null,
              valueNumber: 155,
            },
            {
              startTime: new Date('2023-02-14T13:19:08Z'),
              endTime: new Date('2023-02-14T13:19:09Z'),
              valueState: null,
              valueNumber: 96,
            },
            {
              startTime: new Date('2023-02-14T13:19:09Z'),
              endTime: new Date('2023-02-14T13:19:11Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:19:11Z'),
              endTime: new Date('2023-02-14T13:19:12Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:19:12Z'),
              endTime: new Date('2023-02-14T13:19:13Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:19:13Z'),
              endTime: new Date('2023-02-14T13:19:14Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:19:14Z'),
              endTime: new Date('2023-02-14T13:19:15Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:19:15Z'),
              endTime: new Date('2023-02-14T13:19:17Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:19:17Z'),
              endTime: new Date('2023-02-14T13:19:18Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:19:18Z'),
              endTime: new Date('2023-02-14T13:19:19Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:19:19Z'),
              endTime: new Date('2023-02-14T13:19:20Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:19:20Z'),
              endTime: new Date('2023-02-14T13:19:21Z'),
              valueState: null,
              valueNumber: 116,
            },
            {
              startTime: new Date('2023-02-14T13:19:21Z'),
              endTime: new Date('2023-02-14T13:19:22Z'),
              valueState: null,
              valueNumber: 133,
            },
            {
              startTime: new Date('2023-02-14T13:19:22Z'),
              endTime: new Date('2023-02-14T13:19:23Z'),
              valueState: null,
              valueNumber: 133,
            },
            {
              startTime: new Date('2023-02-14T13:19:23Z'),
              endTime: new Date('2023-02-14T13:19:24Z'),
              valueState: null,
              valueNumber: 132,
            },
            {
              startTime: new Date('2023-02-14T13:19:24Z'),
              endTime: new Date('2023-02-14T13:19:25Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:19:25Z'),
              endTime: new Date('2023-02-14T13:19:26Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:19:26Z'),
              endTime: new Date('2023-02-14T13:19:28Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:19:28Z'),
              endTime: new Date('2023-02-14T13:19:29Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:19:29Z'),
              endTime: new Date('2023-02-14T13:19:30Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:19:30Z'),
              endTime: new Date('2023-02-14T13:19:31Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:19:31Z'),
              endTime: new Date('2023-02-14T13:19:32Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:19:32Z'),
              endTime: new Date('2023-02-14T13:19:33Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:19:33Z'),
              endTime: new Date('2023-02-14T13:19:34Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:19:34Z'),
              endTime: new Date('2023-02-14T13:19:35Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:19:35Z'),
              endTime: new Date('2023-02-14T13:19:36Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:19:36Z'),
              endTime: new Date('2023-02-14T13:19:41Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:19:41Z'),
              endTime: new Date('2023-02-14T13:19:42Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:19:42Z'),
              endTime: new Date('2023-02-14T13:19:43Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:19:43Z'),
              endTime: new Date('2023-02-14T13:19:44Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:19:44Z'),
              endTime: new Date('2023-02-14T13:19:45Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:19:45Z'),
              endTime: new Date('2023-02-14T13:19:54Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:19:54Z'),
              endTime: new Date('2023-02-14T13:19:55Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:19:55Z'),
              endTime: new Date('2023-02-14T13:19:56Z'),
              valueState: null,
              valueNumber: 102,
            },
            {
              startTime: new Date('2023-02-14T13:19:56Z'),
              endTime: new Date('2023-02-14T13:19:57Z'),
              valueState: null,
              valueNumber: 144,
            },
            {
              startTime: new Date('2023-02-14T13:19:57Z'),
              endTime: new Date('2023-02-14T13:19:58Z'),
              valueState: null,
              valueNumber: 195,
            },
            {
              startTime: new Date('2023-02-14T13:19:58Z'),
              endTime: new Date('2023-02-14T13:19:59Z'),
              valueState: null,
              valueNumber: 195,
            },
            {
              startTime: new Date('2023-02-14T13:19:59Z'),
              endTime: new Date('2023-02-14T13:20:00Z'),
              valueState: null,
              valueNumber: 245,
            },
            {
              startTime: new Date('2023-02-14T13:20:00Z'),
              endTime: new Date('2023-02-14T13:20:01Z'),
              valueState: null,
              valueNumber: 294,
            },
            {
              startTime: new Date('2023-02-14T13:20:01Z'),
              endTime: new Date('2023-02-14T13:20:02Z'),
              valueState: null,
              valueNumber: 345,
            },
            {
              startTime: new Date('2023-02-14T13:20:02Z'),
              endTime: new Date('2023-02-14T13:20:03Z'),
              valueState: null,
              valueNumber: 394,
            },
            {
              startTime: new Date('2023-02-14T13:20:03Z'),
              endTime: new Date('2023-02-14T13:20:04Z'),
              valueState: null,
              valueNumber: 443,
            },
            {
              startTime: new Date('2023-02-14T13:20:04Z'),
              endTime: new Date('2023-02-14T13:20:05Z'),
              valueState: null,
              valueNumber: 443,
            },
            {
              startTime: new Date('2023-02-14T13:20:05Z'),
              endTime: new Date('2023-02-14T13:20:06Z'),
              valueState: null,
              valueNumber: 492,
            },
            {
              startTime: new Date('2023-02-14T13:20:06Z'),
              endTime: new Date('2023-02-14T13:20:07Z'),
              valueState: null,
              valueNumber: 509,
            },
            {
              startTime: new Date('2023-02-14T13:20:07Z'),
              endTime: new Date('2023-02-14T13:20:08Z'),
              valueState: null,
              valueNumber: 507,
            },
            {
              startTime: new Date('2023-02-14T13:20:08Z'),
              endTime: new Date('2023-02-14T13:20:09Z'),
              valueState: null,
              valueNumber: 506,
            },
            {
              startTime: new Date('2023-02-14T13:20:09Z'),
              endTime: new Date('2023-02-14T13:20:10Z'),
              valueState: null,
              valueNumber: 506,
            },
            {
              startTime: new Date('2023-02-14T13:20:10Z'),
              endTime: new Date('2023-02-14T13:20:11Z'),
              valueState: null,
              valueNumber: 504,
            },
            {
              startTime: new Date('2023-02-14T13:20:11Z'),
              endTime: new Date('2023-02-14T13:20:12Z'),
              valueState: null,
              valueNumber: 503,
            },
            {
              startTime: new Date('2023-02-14T13:20:12Z'),
              endTime: new Date('2023-02-14T13:20:13Z'),
              valueState: null,
              valueNumber: 502,
            },
            {
              startTime: new Date('2023-02-14T13:20:13Z'),
              endTime: new Date('2023-02-14T13:20:14Z'),
              valueState: null,
              valueNumber: 501,
            },
            {
              startTime: new Date('2023-02-14T13:20:14Z'),
              endTime: new Date('2023-02-14T13:20:15Z'),
              valueState: null,
              valueNumber: 501,
            },
            {
              startTime: new Date('2023-02-14T13:20:15Z'),
              endTime: new Date('2023-02-14T13:20:16Z'),
              valueState: null,
              valueNumber: 503,
            },
            {
              startTime: new Date('2023-02-14T13:20:16Z'),
              endTime: new Date('2023-02-14T13:20:17Z'),
              valueState: null,
              valueNumber: 546,
            },
            {
              startTime: new Date('2023-02-14T13:20:17Z'),
              endTime: new Date('2023-02-14T13:20:18Z'),
              valueState: null,
              valueNumber: 597,
            },
            {
              startTime: new Date('2023-02-14T13:20:18Z'),
              endTime: new Date('2023-02-14T13:20:19Z'),
              valueState: null,
              valueNumber: 648,
            },
            {
              startTime: new Date('2023-02-14T13:20:19Z'),
              endTime: new Date('2023-02-14T13:20:20Z'),
              valueState: null,
              valueNumber: 697,
            },
            {
              startTime: new Date('2023-02-14T13:20:20Z'),
              endTime: new Date('2023-02-14T13:20:21Z'),
              valueState: null,
              valueNumber: 697,
            },
            {
              startTime: new Date('2023-02-14T13:20:21Z'),
              endTime: new Date('2023-02-14T13:20:22Z'),
              valueState: null,
              valueNumber: 705,
            },
            {
              startTime: new Date('2023-02-14T13:20:22Z'),
              endTime: new Date('2023-02-14T13:20:23Z'),
              valueState: null,
              valueNumber: 705,
            },
            {
              startTime: new Date('2023-02-14T13:20:23Z'),
              endTime: new Date('2023-02-14T13:20:24Z'),
              valueState: null,
              valueNumber: 704,
            },
            {
              startTime: new Date('2023-02-14T13:20:24Z'),
              endTime: new Date('2023-02-14T13:20:25Z'),
              valueState: null,
              valueNumber: 702,
            },
            {
              startTime: new Date('2023-02-14T13:20:25Z'),
              endTime: new Date('2023-02-14T13:20:29Z'),
              valueState: null,
              valueNumber: 701,
            },
            {
              startTime: new Date('2023-02-14T13:20:29Z'),
              endTime: new Date('2023-02-14T13:20:30Z'),
              valueState: null,
              valueNumber: 701,
            },
            {
              startTime: new Date('2023-02-14T13:20:30Z'),
              endTime: new Date('2023-02-14T13:20:31Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:20:31Z'),
              endTime: new Date('2023-02-14T13:20:32Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:20:32Z'),
              endTime: new Date('2023-02-14T13:20:33Z'),
              valueState: null,
              valueNumber: 724,
            },
            {
              startTime: new Date('2023-02-14T13:20:33Z'),
              endTime: new Date('2023-02-14T13:20:34Z'),
              valueState: null,
              valueNumber: 774,
            },
            {
              startTime: new Date('2023-02-14T13:20:34Z'),
              endTime: new Date('2023-02-14T13:20:35Z'),
              valueState: null,
              valueNumber: 824,
            },
            {
              startTime: new Date('2023-02-14T13:20:35Z'),
              endTime: new Date('2023-02-14T13:20:36Z'),
              valueState: null,
              valueNumber: 874,
            },
            {
              startTime: new Date('2023-02-14T13:20:36Z'),
              endTime: new Date('2023-02-14T13:20:37Z'),
              valueState: null,
              valueNumber: 874,
            },
            {
              startTime: new Date('2023-02-14T13:20:37Z'),
              endTime: new Date('2023-02-14T13:20:38Z'),
              valueState: null,
              valueNumber: 923,
            },
            {
              startTime: new Date('2023-02-14T13:20:38Z'),
              endTime: new Date('2023-02-14T13:20:39Z'),
              valueState: null,
              valueNumber: 971,
            },
            {
              startTime: new Date('2023-02-14T13:20:39Z'),
              endTime: new Date('2023-02-14T13:20:40Z'),
              valueState: null,
              valueNumber: 1004,
            },
            {
              startTime: new Date('2023-02-14T13:20:40Z'),
              endTime: new Date('2023-02-14T13:20:41Z'),
              valueState: null,
              valueNumber: 1004,
            },
            {
              startTime: new Date('2023-02-14T13:20:41Z'),
              endTime: new Date('2023-02-14T13:20:42Z'),
              valueState: null,
              valueNumber: 1003,
            },
            {
              startTime: new Date('2023-02-14T13:20:42Z'),
              endTime: new Date('2023-02-14T13:20:43Z'),
              valueState: null,
              valueNumber: 1003,
            },
            {
              startTime: new Date('2023-02-14T13:20:43Z'),
              endTime: new Date('2023-02-14T13:20:44Z'),
              valueState: null,
              valueNumber: 1001,
            },
            {
              startTime: new Date('2023-02-14T13:20:44Z'),
              endTime: new Date('2023-02-14T13:20:45Z'),
              valueState: null,
              valueNumber: 1001,
            },
            {
              startTime: new Date('2023-02-14T13:20:45Z'),
              endTime: new Date('2023-02-14T13:20:46Z'),
              valueState: null,
              valueNumber: 1002,
            },
            {
              startTime: new Date('2023-02-14T13:20:46Z'),
              endTime: new Date('2023-02-14T13:20:48Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:20:48Z'),
              endTime: new Date('2023-02-14T13:20:49Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:20:49Z'),
              endTime: new Date('2023-02-14T13:20:50Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:20:50Z'),
              endTime: new Date('2023-02-14T13:20:52Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:20:52Z'),
              endTime: new Date('2023-02-14T13:20:53Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:20:53Z'),
              endTime: new Date('2023-02-14T13:20:54Z'),
              valueState: null,
              valueNumber: 1001,
            },
            {
              startTime: new Date('2023-02-14T13:20:54Z'),
              endTime: new Date('2023-02-14T13:20:55Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:20:55Z'),
              endTime: new Date('2023-02-14T13:20:56Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:20:56Z'),
              endTime: new Date('2023-02-14T13:20:59Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:20:59Z'),
              endTime: new Date('2023-02-14T13:21:00Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:00Z'),
              endTime: new Date('2023-02-14T13:21:03Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:03Z'),
              endTime: new Date('2023-02-14T13:21:04Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:04Z'),
              endTime: new Date('2023-02-14T13:21:07Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:07Z'),
              endTime: new Date('2023-02-14T13:21:08Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:08Z'),
              endTime: new Date('2023-02-14T13:21:09Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:09Z'),
              endTime: new Date('2023-02-14T13:21:10Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:10Z'),
              endTime: new Date('2023-02-14T13:21:14Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:14Z'),
              endTime: new Date('2023-02-14T13:21:15Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:15Z'),
              endTime: new Date('2023-02-14T13:21:16Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:16Z'),
              endTime: new Date('2023-02-14T13:21:17Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:17Z'),
              endTime: new Date('2023-02-14T13:21:20Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:20Z'),
              endTime: new Date('2023-02-14T13:21:21Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:21Z'),
              endTime: new Date('2023-02-14T13:21:22Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:22Z'),
              endTime: new Date('2023-02-14T13:21:23Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:23Z'),
              endTime: new Date('2023-02-14T13:21:27Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:27Z'),
              endTime: new Date('2023-02-14T13:21:28Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:28Z'),
              endTime: new Date('2023-02-14T13:21:30Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:30Z'),
              endTime: new Date('2023-02-14T13:21:31Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:31Z'),
              endTime: new Date('2023-02-14T13:21:32Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:32Z'),
              endTime: new Date('2023-02-14T13:21:33Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:33Z'),
              endTime: new Date('2023-02-14T13:21:36Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:36Z'),
              endTime: new Date('2023-02-14T13:21:37Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:37Z'),
              endTime: new Date('2023-02-14T13:21:39Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:39Z'),
              endTime: new Date('2023-02-14T13:21:40Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:40Z'),
              endTime: new Date('2023-02-14T13:21:43Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:43Z'),
              endTime: new Date('2023-02-14T13:21:44Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:44Z'),
              endTime: new Date('2023-02-14T13:21:45Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:45Z'),
              endTime: new Date('2023-02-14T13:21:48Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:48Z'),
              endTime: new Date('2023-02-14T13:21:49Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:49Z'),
              endTime: new Date('2023-02-14T13:21:52Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:52Z'),
              endTime: new Date('2023-02-14T13:21:53Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:53Z'),
              endTime: new Date('2023-02-14T13:21:55Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:55Z'),
              endTime: new Date('2023-02-14T13:21:56Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:21:56Z'),
              endTime: new Date('2023-02-14T13:21:57Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:57Z'),
              endTime: new Date('2023-02-14T13:21:58Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:58Z'),
              endTime: new Date('2023-02-14T13:21:59Z'),
              valueState: null,
              valueNumber: 967,
            },
            {
              startTime: new Date('2023-02-14T13:21:59Z'),
              endTime: new Date('2023-02-14T13:22:00Z'),
              valueState: null,
              valueNumber: 930,
            },
            {
              startTime: new Date('2023-02-14T13:22:00Z'),
              endTime: new Date('2023-02-14T13:22:01Z'),
              valueState: null,
              valueNumber: 890,
            },
            {
              startTime: new Date('2023-02-14T13:22:01Z'),
              endTime: new Date('2023-02-14T13:22:02Z'),
              valueState: null,
              valueNumber: 850,
            },
            {
              startTime: new Date('2023-02-14T13:22:02Z'),
              endTime: new Date('2023-02-14T13:22:03Z'),
              valueState: null,
              valueNumber: 850,
            },
            {
              startTime: new Date('2023-02-14T13:22:03Z'),
              endTime: new Date('2023-02-14T13:22:04Z'),
              valueState: null,
              valueNumber: 809,
            },
            {
              startTime: new Date('2023-02-14T13:22:04Z'),
              endTime: new Date('2023-02-14T13:22:05Z'),
              valueState: null,
              valueNumber: 768,
            },
            {
              startTime: new Date('2023-02-14T13:22:05Z'),
              endTime: new Date('2023-02-14T13:22:06Z'),
              valueState: null,
              valueNumber: 727,
            },
            {
              startTime: new Date('2023-02-14T13:22:06Z'),
              endTime: new Date('2023-02-14T13:22:07Z'),
              valueState: null,
              valueNumber: 682,
            },
            {
              startTime: new Date('2023-02-14T13:22:07Z'),
              endTime: new Date('2023-02-14T13:22:08Z'),
              valueState: null,
              valueNumber: 682,
            },
            {
              startTime: new Date('2023-02-14T13:22:08Z'),
              endTime: new Date('2023-02-14T13:22:09Z'),
              valueState: null,
              valueNumber: 648,
            },
            {
              startTime: new Date('2023-02-14T13:22:09Z'),
              endTime: new Date('2023-02-14T13:22:10Z'),
              valueState: null,
              valueNumber: 648,
            },
            {
              startTime: new Date('2023-02-14T13:22:10Z'),
              endTime: new Date('2023-02-14T13:22:11Z'),
              valueState: null,
              valueNumber: 599,
            },
            {
              startTime: new Date('2023-02-14T13:22:11Z'),
              endTime: new Date('2023-02-14T13:22:12Z'),
              valueState: null,
              valueNumber: 558,
            },
            {
              startTime: new Date('2023-02-14T13:22:12Z'),
              endTime: new Date('2023-02-14T13:22:13Z'),
              valueState: null,
              valueNumber: 518,
            },
            {
              startTime: new Date('2023-02-14T13:22:13Z'),
              endTime: new Date('2023-02-14T13:22:14Z'),
              valueState: null,
              valueNumber: 477,
            },
            {
              startTime: new Date('2023-02-14T13:22:14Z'),
              endTime: new Date('2023-02-14T13:22:15Z'),
              valueState: null,
              valueNumber: 435,
            },
            {
              startTime: new Date('2023-02-14T13:22:15Z'),
              endTime: new Date('2023-02-14T13:22:16Z'),
              valueState: null,
              valueNumber: 435,
            },
            {
              startTime: new Date('2023-02-14T13:22:16Z'),
              endTime: new Date('2023-02-14T13:22:17Z'),
              valueState: null,
              valueNumber: 391,
            },
            {
              startTime: new Date('2023-02-14T13:22:17Z'),
              endTime: new Date('2023-02-14T13:22:18Z'),
              valueState: null,
              valueNumber: 344,
            },
            {
              startTime: new Date('2023-02-14T13:22:18Z'),
              endTime: new Date('2023-02-14T13:22:19Z'),
              valueState: null,
              valueNumber: 297,
            },
            {
              startTime: new Date('2023-02-14T13:22:19Z'),
              endTime: new Date('2023-02-14T13:22:20Z'),
              valueState: null,
              valueNumber: 297,
            },
            {
              startTime: new Date('2023-02-14T13:22:20Z'),
              endTime: new Date('2023-02-14T13:22:21Z'),
              valueState: null,
              valueNumber: 255,
            },
            {
              startTime: new Date('2023-02-14T13:22:21Z'),
              endTime: new Date('2023-02-14T13:22:22Z'),
              valueState: null,
              valueNumber: 195,
            },
            {
              startTime: new Date('2023-02-14T13:22:22Z'),
              endTime: new Date('2023-02-14T13:22:23Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:22:23Z'),
              endTime: new Date('2023-02-14T13:22:24Z'),
              valueState: null,
              valueNumber: 65,
            },
            {
              startTime: new Date('2023-02-14T13:22:24Z'),
              endTime: new Date('2023-02-14T13:22:25Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:25Z'),
              endTime: new Date('2023-02-14T13:22:26Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:26Z'),
              endTime: new Date('2023-02-14T13:22:27Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:22:27Z'),
              endTime: new Date('2023-02-14T13:22:28Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:28Z'),
              endTime: new Date('2023-02-14T13:22:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:29Z'),
              endTime: new Date('2023-02-14T13:22:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:30Z'),
              endTime: new Date('2023-02-14T13:22:31Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:31Z'),
              endTime: new Date('2023-02-14T13:22:32Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:22:32Z'),
              endTime: new Date('2023-02-14T13:22:33Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:22:33Z'),
              endTime: new Date('2023-02-14T13:22:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:34Z'),
              endTime: new Date('2023-02-14T13:22:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:35Z'),
              endTime: new Date('2023-02-14T13:22:36Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:22:36Z'),
              endTime: new Date('2023-02-14T13:22:37Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:37Z'),
              endTime: new Date('2023-02-14T13:22:38Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:22:38Z'),
              endTime: new Date('2023-02-14T13:22:39Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:39Z'),
              endTime: new Date('2023-02-14T13:22:40Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:40Z'),
              endTime: new Date('2023-02-14T13:22:41Z'),
              valueState: null,
              valueNumber: 38,
            },
            {
              startTime: new Date('2023-02-14T13:22:41Z'),
              endTime: new Date('2023-02-14T13:22:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:42Z'),
              endTime: new Date('2023-02-14T13:22:43Z'),
              valueState: null,
              valueNumber: 29,
            },
            {
              startTime: new Date('2023-02-14T13:22:43Z'),
              endTime: new Date('2023-02-14T13:22:44Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:22:44Z'),
              endTime: new Date('2023-02-14T13:22:45Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:22:45Z'),
              endTime: new Date('2023-02-14T13:22:46Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:22:46Z'),
              endTime: new Date('2023-02-14T13:22:48Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:22:48Z'),
              endTime: new Date('2023-02-14T13:22:49Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:22:49Z'),
              endTime: new Date('2023-02-14T13:22:50Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:22:50Z'),
              endTime: new Date('2023-02-14T13:22:51Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:22:51Z'),
              endTime: new Date('2023-02-14T13:22:52Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:22:52Z'),
              endTime: new Date('2023-02-14T13:22:53Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:22:53Z'),
              endTime: new Date('2023-02-14T13:22:54Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:54Z'),
              endTime: new Date('2023-02-14T13:22:55Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:22:55Z'),
              endTime: new Date('2023-02-14T13:22:58Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:22:58Z'),
              endTime: new Date('2023-02-14T13:22:59Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:22:59Z'),
              endTime: new Date('2023-02-14T13:23:02Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:02Z'),
              endTime: new Date('2023-02-14T13:23:03Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:03Z'),
              endTime: new Date('2023-02-14T13:23:04Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:23:04Z'),
              endTime: new Date('2023-02-14T13:23:05Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:05Z'),
              endTime: new Date('2023-02-14T13:23:06Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:23:06Z'),
              endTime: new Date('2023-02-14T13:23:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:07Z'),
              endTime: new Date('2023-02-14T13:23:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:08Z'),
              endTime: new Date('2023-02-14T13:23:09Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:23:09Z'),
              endTime: new Date('2023-02-14T13:23:13Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:23:13Z'),
              endTime: new Date('2023-02-14T13:23:14Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:23:14Z'),
              endTime: new Date('2023-02-14T13:23:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:16Z'),
              endTime: new Date('2023-02-14T13:23:17Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:17Z'),
              endTime: new Date('2023-02-14T13:23:18Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:23:18Z'),
              endTime: new Date('2023-02-14T13:23:20Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:20Z'),
              endTime: new Date('2023-02-14T13:23:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:21Z'),
              endTime: new Date('2023-02-14T13:23:22Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:23:22Z'),
              endTime: new Date('2023-02-14T13:23:23Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:23:23Z'),
              endTime: new Date('2023-02-14T13:23:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:24Z'),
              endTime: new Date('2023-02-14T13:23:25Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:23:25Z'),
              endTime: new Date('2023-02-14T13:23:26Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:23:26Z'),
              endTime: new Date('2023-02-14T13:23:27Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:27Z'),
              endTime: new Date('2023-02-14T13:23:28Z'),
              valueState: null,
              valueNumber: 28,
            },
            {
              startTime: new Date('2023-02-14T13:23:28Z'),
              endTime: new Date('2023-02-14T13:23:32Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:32Z'),
              endTime: new Date('2023-02-14T13:23:33Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:33Z'),
              endTime: new Date('2023-02-14T13:23:34Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:23:34Z'),
              endTime: new Date('2023-02-14T13:23:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:35Z'),
              endTime: new Date('2023-02-14T13:23:36Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:36Z'),
              endTime: new Date('2023-02-14T13:23:37Z'),
              valueState: null,
              valueNumber: 21,
            },
            {
              startTime: new Date('2023-02-14T13:23:37Z'),
              endTime: new Date('2023-02-14T13:23:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:38Z'),
              endTime: new Date('2023-02-14T13:23:39Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:23:39Z'),
              endTime: new Date('2023-02-14T13:23:40Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:23:40Z'),
              endTime: new Date('2023-02-14T13:23:41Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:23:41Z'),
              endTime: new Date('2023-02-14T13:23:42Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:23:42Z'),
              endTime: new Date('2023-02-14T13:23:45Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:45Z'),
              endTime: new Date('2023-02-14T13:23:46Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:46Z'),
              endTime: new Date('2023-02-14T13:23:47Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:23:47Z'),
              endTime: new Date('2023-02-14T13:23:48Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:23:48Z'),
              endTime: new Date('2023-02-14T13:23:49Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:49Z'),
              endTime: new Date('2023-02-14T13:23:50Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:50Z'),
              endTime: new Date('2023-02-14T13:23:52Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:52Z'),
              endTime: new Date('2023-02-14T13:23:53Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:53Z'),
              endTime: new Date('2023-02-14T13:23:55Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:23:55Z'),
              endTime: new Date('2023-02-14T13:23:56Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:23:56Z'),
              endTime: new Date('2023-02-14T13:23:57Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:23:57Z'),
              endTime: new Date('2023-02-14T13:23:58Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:23:58Z'),
              endTime: new Date('2023-02-14T13:23:59Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:23:59Z'),
              endTime: new Date('2023-02-14T13:24:00Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:24:00Z'),
              endTime: new Date('2023-02-14T13:24:01Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:01Z'),
              endTime: new Date('2023-02-14T13:24:02Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:24:02Z'),
              endTime: new Date('2023-02-14T13:24:03Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:03Z'),
              endTime: new Date('2023-02-14T13:24:05Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:05Z'),
              endTime: new Date('2023-02-14T13:24:06Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:06Z'),
              endTime: new Date('2023-02-14T13:24:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:07Z'),
              endTime: new Date('2023-02-14T13:24:08Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:24:08Z'),
              endTime: new Date('2023-02-14T13:24:09Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:24:09Z'),
              endTime: new Date('2023-02-14T13:24:10Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:24:10Z'),
              endTime: new Date('2023-02-14T13:24:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:11Z'),
              endTime: new Date('2023-02-14T13:24:12Z'),
              valueState: null,
              valueNumber: 20,
            },
            {
              startTime: new Date('2023-02-14T13:24:12Z'),
              endTime: new Date('2023-02-14T13:24:15Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:15Z'),
              endTime: new Date('2023-02-14T13:24:16Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:16Z'),
              endTime: new Date('2023-02-14T13:24:17Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:17Z'),
              endTime: new Date('2023-02-14T13:24:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:18Z'),
              endTime: new Date('2023-02-14T13:24:19Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:19Z'),
              endTime: new Date('2023-02-14T13:24:20Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:20Z'),
              endTime: new Date('2023-02-14T13:24:21Z'),
              valueState: null,
              valueNumber: 20,
            },
            {
              startTime: new Date('2023-02-14T13:24:21Z'),
              endTime: new Date('2023-02-14T13:24:22Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:22Z'),
              endTime: new Date('2023-02-14T13:24:23Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:23Z'),
              endTime: new Date('2023-02-14T13:24:24Z'),
              valueState: null,
              valueNumber: 41,
            },
            {
              startTime: new Date('2023-02-14T13:24:24Z'),
              endTime: new Date('2023-02-14T13:24:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:25Z'),
              endTime: new Date('2023-02-14T13:24:26Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:26Z'),
              endTime: new Date('2023-02-14T13:24:27Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:24:27Z'),
              endTime: new Date('2023-02-14T13:24:28Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:24:28Z'),
              endTime: new Date('2023-02-14T13:24:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:29Z'),
              endTime: new Date('2023-02-14T13:24:30Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:30Z'),
              endTime: new Date('2023-02-14T13:24:31Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:31Z'),
              endTime: new Date('2023-02-14T13:24:32Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:24:32Z'),
              endTime: new Date('2023-02-14T13:24:33Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:24:33Z'),
              endTime: new Date('2023-02-14T13:24:34Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:34Z'),
              endTime: new Date('2023-02-14T13:24:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:35Z'),
              endTime: new Date('2023-02-14T13:24:36Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:36Z'),
              endTime: new Date('2023-02-14T13:24:37Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:37Z'),
              endTime: new Date('2023-02-14T13:24:42Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:42Z'),
              endTime: new Date('2023-02-14T13:24:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:43Z'),
              endTime: new Date('2023-02-14T13:24:44Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:24:44Z'),
              endTime: new Date('2023-02-14T13:24:45Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:45Z'),
              endTime: new Date('2023-02-14T13:24:46Z'),
              valueState: null,
              valueNumber: 54,
            },
            {
              startTime: new Date('2023-02-14T13:24:46Z'),
              endTime: new Date('2023-02-14T13:24:47Z'),
              valueState: null,
              valueNumber: 54,
            },
            {
              startTime: new Date('2023-02-14T13:24:47Z'),
              endTime: new Date('2023-02-14T13:24:48Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:48Z'),
              endTime: new Date('2023-02-14T13:24:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:50Z'),
              endTime: new Date('2023-02-14T13:24:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:51Z'),
              endTime: new Date('2023-02-14T13:24:53Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:53Z'),
              endTime: new Date('2023-02-14T13:24:54Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:54Z'),
              endTime: new Date('2023-02-14T13:24:55Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:24:55Z'),
              endTime: new Date('2023-02-14T13:24:56Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:24:56Z'),
              endTime: new Date('2023-02-14T13:24:57Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:24:57Z'),
              endTime: new Date('2023-02-14T13:24:58Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:58Z'),
              endTime: new Date('2023-02-14T13:24:59Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:24:59Z'),
              endTime: new Date('2023-02-14T13:25:00Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:25:00Z'),
              endTime: new Date('2023-02-14T13:25:02Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:02Z'),
              endTime: new Date('2023-02-14T13:25:03Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:03Z'),
              endTime: new Date('2023-02-14T13:25:04Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:25:04Z'),
              endTime: new Date('2023-02-14T13:25:05Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T13:25:05Z'),
              endTime: new Date('2023-02-14T13:25:06Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:06Z'),
              endTime: new Date('2023-02-14T13:25:07Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:07Z'),
              endTime: new Date('2023-02-14T13:25:08Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:25:08Z'),
              endTime: new Date('2023-02-14T13:25:09Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:25:09Z'),
              endTime: new Date('2023-02-14T13:25:12Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:12Z'),
              endTime: new Date('2023-02-14T13:25:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:13Z'),
              endTime: new Date('2023-02-14T13:25:15Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:25:15Z'),
              endTime: new Date('2023-02-14T13:25:16Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:25:16Z'),
              endTime: new Date('2023-02-14T13:25:17Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:25:17Z'),
              endTime: new Date('2023-02-14T13:25:18Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:18Z'),
              endTime: new Date('2023-02-14T13:25:19Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:19Z'),
              endTime: new Date('2023-02-14T13:25:20Z'),
              valueState: null,
              valueNumber: 44,
            },
            {
              startTime: new Date('2023-02-14T13:25:20Z'),
              endTime: new Date('2023-02-14T13:25:21Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:25:21Z'),
              endTime: new Date('2023-02-14T13:25:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:25Z'),
              endTime: new Date('2023-02-14T13:25:26Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:26Z'),
              endTime: new Date('2023-02-14T13:25:27Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:25:27Z'),
              endTime: new Date('2023-02-14T13:25:28Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:25:28Z'),
              endTime: new Date('2023-02-14T13:25:29Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:25:29Z'),
              endTime: new Date('2023-02-14T13:25:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:30Z'),
              endTime: new Date('2023-02-14T13:25:31Z'),
              valueState: null,
              valueNumber: 52,
            },
            {
              startTime: new Date('2023-02-14T13:25:31Z'),
              endTime: new Date('2023-02-14T13:25:32Z'),
              valueState: null,
              valueNumber: 52,
            },
            {
              startTime: new Date('2023-02-14T13:25:32Z'),
              endTime: new Date('2023-02-14T13:25:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:34Z'),
              endTime: new Date('2023-02-14T13:25:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:35Z'),
              endTime: new Date('2023-02-14T13:25:38Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:25:38Z'),
              endTime: new Date('2023-02-14T13:25:39Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:25:39Z'),
              endTime: new Date('2023-02-14T13:25:40Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:25:40Z'),
              endTime: new Date('2023-02-14T13:25:41Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:25:41Z'),
              endTime: new Date('2023-02-14T13:25:42Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:25:42Z'),
              endTime: new Date('2023-02-14T13:25:43Z'),
              valueState: null,
              valueNumber: 52,
            },
            {
              startTime: new Date('2023-02-14T13:25:43Z'),
              endTime: new Date('2023-02-14T13:25:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:44Z'),
              endTime: new Date('2023-02-14T13:25:45Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:25:45Z'),
              endTime: new Date('2023-02-14T13:25:46Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:25:46Z'),
              endTime: new Date('2023-02-14T13:25:48Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:48Z'),
              endTime: new Date('2023-02-14T13:25:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:49Z'),
              endTime: new Date('2023-02-14T13:25:50Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:25:50Z'),
              endTime: new Date('2023-02-14T13:25:51Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:51Z'),
              endTime: new Date('2023-02-14T13:25:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:52Z'),
              endTime: new Date('2023-02-14T13:25:53Z'),
              valueState: null,
              valueNumber: 14,
            },
            {
              startTime: new Date('2023-02-14T13:25:53Z'),
              endTime: new Date('2023-02-14T13:25:54Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:54Z'),
              endTime: new Date('2023-02-14T13:25:55Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:55Z'),
              endTime: new Date('2023-02-14T13:25:56Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:25:56Z'),
              endTime: new Date('2023-02-14T13:25:57Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:25:57Z'),
              endTime: new Date('2023-02-14T13:25:58Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:58Z'),
              endTime: new Date('2023-02-14T13:25:59Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:59Z'),
              endTime: new Date('2023-02-14T13:26:00Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:26:00Z'),
              endTime: new Date('2023-02-14T13:26:01Z'),
              valueState: null,
              valueNumber: 4,
            },
            {
              startTime: new Date('2023-02-14T13:26:01Z'),
              endTime: new Date('2023-02-14T13:26:02Z'),
              valueState: null,
              valueNumber: 4,
            },
            {
              startTime: new Date('2023-02-14T13:26:02Z'),
              endTime: new Date('2023-02-14T13:26:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:03Z'),
              endTime: new Date('2023-02-14T13:26:04Z'),
              valueState: null,
              valueNumber: 44,
            },
            {
              startTime: new Date('2023-02-14T13:26:04Z'),
              endTime: new Date('2023-02-14T13:26:05Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:26:05Z'),
              endTime: new Date('2023-02-14T13:26:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:07Z'),
              endTime: new Date('2023-02-14T13:26:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:08Z'),
              endTime: new Date('2023-02-14T13:26:09Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:26:09Z'),
              endTime: new Date('2023-02-14T13:26:10Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:10Z'),
              endTime: new Date('2023-02-14T13:26:11Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:26:11Z'),
              endTime: new Date('2023-02-14T13:26:12Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:26:12Z'),
              endTime: new Date('2023-02-14T13:26:13Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:26:13Z'),
              endTime: new Date('2023-02-14T13:26:14Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:14Z'),
              endTime: new Date('2023-02-14T13:26:15Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:26:15Z'),
              endTime: new Date('2023-02-14T13:26:16Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:26:16Z'),
              endTime: new Date('2023-02-14T13:26:17Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:26:17Z'),
              endTime: new Date('2023-02-14T13:26:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:18Z'),
              endTime: new Date('2023-02-14T13:26:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:19Z'),
              endTime: new Date('2023-02-14T13:26:20Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:26:20Z'),
              endTime: new Date('2023-02-14T13:26:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:21Z'),
              endTime: new Date('2023-02-14T13:26:22Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:26:22Z'),
              endTime: new Date('2023-02-14T13:26:23Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:26:23Z'),
              endTime: new Date('2023-02-14T13:26:24Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:26:24Z'),
              endTime: new Date('2023-02-14T13:26:25Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:25Z'),
              endTime: new Date('2023-02-14T13:26:26Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:26Z'),
              endTime: new Date('2023-02-14T13:26:28Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:28Z'),
              endTime: new Date('2023-02-14T13:26:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:29Z'),
              endTime: new Date('2023-02-14T13:26:31Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:26:31Z'),
              endTime: new Date('2023-02-14T13:26:32Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:26:32Z'),
              endTime: new Date('2023-02-14T13:26:33Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:26:33Z'),
              endTime: new Date('2023-02-14T13:26:34Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:26:34Z'),
              endTime: new Date('2023-02-14T13:26:35Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:35Z'),
              endTime: new Date('2023-02-14T13:26:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:36Z'),
              endTime: new Date('2023-02-14T13:26:37Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T13:26:37Z'),
              endTime: new Date('2023-02-14T13:26:38Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:26:38Z'),
              endTime: new Date('2023-02-14T13:26:39Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:26:39Z'),
              endTime: new Date('2023-02-14T13:26:41Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:41Z'),
              endTime: new Date('2023-02-14T13:26:42Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:42Z'),
              endTime: new Date('2023-02-14T13:26:43Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:26:43Z'),
              endTime: new Date('2023-02-14T13:26:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:44Z'),
              endTime: new Date('2023-02-14T13:26:45Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T13:26:45Z'),
              endTime: new Date('2023-02-14T13:26:46Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:46Z'),
              endTime: new Date('2023-02-14T13:26:47Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:47Z'),
              endTime: new Date('2023-02-14T13:26:48Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T13:26:48Z'),
              endTime: new Date('2023-02-14T13:26:49Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T13:26:49Z'),
              endTime: new Date('2023-02-14T13:26:50Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:26:50Z'),
              endTime: new Date('2023-02-14T13:26:51Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:26:51Z'),
              endTime: new Date('2023-02-14T13:26:52Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:26:52Z'),
              endTime: new Date('2023-02-14T13:26:54Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:54Z'),
              endTime: new Date('2023-02-14T13:26:55Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:55Z'),
              endTime: new Date('2023-02-14T13:26:56Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:26:56Z'),
              endTime: new Date('2023-02-14T13:26:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:58Z'),
              endTime: new Date('2023-02-14T13:26:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:59Z'),
              endTime: new Date('2023-02-14T13:27:00Z'),
              valueState: null,
              valueNumber: 52,
            },
            {
              startTime: new Date('2023-02-14T13:27:00Z'),
              endTime: new Date('2023-02-14T13:27:01Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:27:01Z'),
              endTime: new Date('2023-02-14T13:27:02Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:27:02Z'),
              endTime: new Date('2023-02-14T13:27:03Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:27:03Z'),
              endTime: new Date('2023-02-14T13:28:23Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:23Z'),
              endTime: new Date('2023-02-14T13:28:24Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:24Z'),
              endTime: new Date('2023-02-14T13:28:25Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:28:25Z'),
              endTime: new Date('2023-02-14T13:28:26Z'),
              valueState: null,
              valueNumber: 56,
            },
            {
              startTime: new Date('2023-02-14T13:28:26Z'),
              endTime: new Date('2023-02-14T13:28:27Z'),
              valueState: null,
              valueNumber: 54,
            },
            {
              startTime: new Date('2023-02-14T13:28:27Z'),
              endTime: new Date('2023-02-14T13:28:28Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:28:28Z'),
              endTime: new Date('2023-02-14T13:28:29Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:28:29Z'),
              endTime: new Date('2023-02-14T13:28:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:30Z'),
              endTime: new Date('2023-02-14T13:28:31Z'),
              valueState: null,
              valueNumber: 41,
            },
            {
              startTime: new Date('2023-02-14T13:28:31Z'),
              endTime: new Date('2023-02-14T13:28:32Z'),
              valueState: null,
              valueNumber: 54,
            },
            {
              startTime: new Date('2023-02-14T13:28:32Z'),
              endTime: new Date('2023-02-14T13:28:33Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:28:33Z'),
              endTime: new Date('2023-02-14T13:28:34Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:28:34Z'),
              endTime: new Date('2023-02-14T13:28:35Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:28:35Z'),
              endTime: new Date('2023-02-14T13:28:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:37Z'),
              endTime: new Date('2023-02-14T13:28:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:38Z'),
              endTime: new Date('2023-02-14T13:28:39Z'),
              valueState: null,
              valueNumber: 44,
            },
            {
              startTime: new Date('2023-02-14T13:28:39Z'),
              endTime: new Date('2023-02-14T13:28:40Z'),
              valueState: null,
              valueNumber: 82,
            },
            {
              startTime: new Date('2023-02-14T13:28:40Z'),
              endTime: new Date('2023-02-14T13:28:41Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:28:41Z'),
              endTime: new Date('2023-02-14T13:28:42Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:28:42Z'),
              endTime: new Date('2023-02-14T13:28:43Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:28:43Z'),
              endTime: new Date('2023-02-14T13:28:44Z'),
              valueState: null,
              valueNumber: 84,
            },
            {
              startTime: new Date('2023-02-14T13:28:44Z'),
              endTime: new Date('2023-02-14T13:28:46Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:28:46Z'),
              endTime: new Date('2023-02-14T13:28:47Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:28:47Z'),
              endTime: new Date('2023-02-14T13:28:48Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:28:48Z'),
              endTime: new Date('2023-02-14T13:28:51Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:28:51Z'),
              endTime: new Date('2023-02-14T13:28:52Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:28:52Z'),
              endTime: new Date('2023-02-14T13:28:53Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:28:53Z'),
              endTime: new Date('2023-02-14T13:28:54Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:28:54Z'),
              endTime: new Date('2023-02-14T13:28:55Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:28:55Z'),
              endTime: new Date('2023-02-14T13:28:56Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:28:56Z'),
              endTime: new Date('2023-02-14T13:28:57Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:28:57Z'),
              endTime: new Date('2023-02-14T13:28:58Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:28:58Z'),
              endTime: new Date('2023-02-14T13:28:59Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:28:59Z'),
              endTime: new Date('2023-02-14T13:29:00Z'),
              valueState: null,
              valueNumber: 95,
            },
            {
              startTime: new Date('2023-02-14T13:29:00Z'),
              endTime: new Date('2023-02-14T13:29:01Z'),
              valueState: null,
              valueNumber: 95,
            },
            {
              startTime: new Date('2023-02-14T13:29:01Z'),
              endTime: new Date('2023-02-14T13:29:02Z'),
              valueState: null,
              valueNumber: 113,
            },
            {
              startTime: new Date('2023-02-14T13:29:02Z'),
              endTime: new Date('2023-02-14T13:29:03Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:29:03Z'),
              endTime: new Date('2023-02-14T13:29:04Z'),
              valueState: null,
              valueNumber: 132,
            },
            {
              startTime: new Date('2023-02-14T13:29:04Z'),
              endTime: new Date('2023-02-14T13:29:05Z'),
              valueState: null,
              valueNumber: 133,
            },
            {
              startTime: new Date('2023-02-14T13:29:05Z'),
              endTime: new Date('2023-02-14T13:29:06Z'),
              valueState: null,
              valueNumber: 133,
            },
            {
              startTime: new Date('2023-02-14T13:29:06Z'),
              endTime: new Date('2023-02-14T13:29:07Z'),
              valueState: null,
              valueNumber: 132,
            },
            {
              startTime: new Date('2023-02-14T13:29:07Z'),
              endTime: new Date('2023-02-14T13:29:08Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:29:08Z'),
              endTime: new Date('2023-02-14T13:29:09Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:29:09Z'),
              endTime: new Date('2023-02-14T13:29:11Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:29:11Z'),
              endTime: new Date('2023-02-14T13:29:12Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:29:12Z'),
              endTime: new Date('2023-02-14T13:29:13Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:29:13Z'),
              endTime: new Date('2023-02-14T13:29:14Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:29:14Z'),
              endTime: new Date('2023-02-14T13:29:15Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:29:15Z'),
              endTime: new Date('2023-02-14T13:29:16Z'),
              valueState: null,
              valueNumber: 110,
            },
            {
              startTime: new Date('2023-02-14T13:29:16Z'),
              endTime: new Date('2023-02-14T13:29:17Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:29:17Z'),
              endTime: new Date('2023-02-14T13:29:18Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:29:18Z'),
              endTime: new Date('2023-02-14T13:29:19Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:29:19Z'),
              endTime: new Date('2023-02-14T13:29:20Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:29:20Z'),
              endTime: new Date('2023-02-14T13:29:30Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:29:30Z'),
              endTime: new Date('2023-02-14T13:29:31Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:29:31Z'),
              endTime: new Date('2023-02-14T13:29:32Z'),
              valueState: null,
              valueNumber: 108,
            },
            {
              startTime: new Date('2023-02-14T13:29:32Z'),
              endTime: new Date('2023-02-14T13:29:33Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:29:33Z'),
              endTime: new Date('2023-02-14T13:29:34Z'),
              valueState: null,
              valueNumber: 148,
            },
            {
              startTime: new Date('2023-02-14T13:29:34Z'),
              endTime: new Date('2023-02-14T13:29:37Z'),
              valueState: null,
              valueNumber: 152,
            },
            {
              startTime: new Date('2023-02-14T13:29:37Z'),
              endTime: new Date('2023-02-14T13:29:38Z'),
              valueState: null,
              valueNumber: 152,
            },
            {
              startTime: new Date('2023-02-14T13:29:38Z'),
              endTime: new Date('2023-02-14T13:29:39Z'),
              valueState: null,
              valueNumber: 151,
            },
            {
              startTime: new Date('2023-02-14T13:29:39Z'),
              endTime: new Date('2023-02-14T13:29:40Z'),
              valueState: null,
              valueNumber: 161,
            },
            {
              startTime: new Date('2023-02-14T13:29:40Z'),
              endTime: new Date('2023-02-14T13:29:41Z'),
              valueState: null,
              valueNumber: 161,
            },
            {
              startTime: new Date('2023-02-14T13:29:41Z'),
              endTime: new Date('2023-02-14T13:29:42Z'),
              valueState: null,
              valueNumber: 208,
            },
            {
              startTime: new Date('2023-02-14T13:29:42Z'),
              endTime: new Date('2023-02-14T13:29:43Z'),
              valueState: null,
              valueNumber: 244,
            },
            {
              startTime: new Date('2023-02-14T13:29:43Z'),
              endTime: new Date('2023-02-14T13:29:44Z'),
              valueState: null,
              valueNumber: 250,
            },
            {
              startTime: new Date('2023-02-14T13:29:44Z'),
              endTime: new Date('2023-02-14T13:29:45Z'),
              valueState: null,
              valueNumber: 250,
            },
            {
              startTime: new Date('2023-02-14T13:29:45Z'),
              endTime: new Date('2023-02-14T13:29:46Z'),
              valueState: null,
              valueNumber: 256,
            },
            {
              startTime: new Date('2023-02-14T13:29:46Z'),
              endTime: new Date('2023-02-14T13:29:47Z'),
              valueState: null,
              valueNumber: 261,
            },
            {
              startTime: new Date('2023-02-14T13:29:47Z'),
              endTime: new Date('2023-02-14T13:29:48Z'),
              valueState: null,
              valueNumber: 265,
            },
            {
              startTime: new Date('2023-02-14T13:29:48Z'),
              endTime: new Date('2023-02-14T13:29:49Z'),
              valueState: null,
              valueNumber: 273,
            },
            {
              startTime: new Date('2023-02-14T13:29:49Z'),
              endTime: new Date('2023-02-14T13:29:50Z'),
              valueState: null,
              valueNumber: 277,
            },
            {
              startTime: new Date('2023-02-14T13:29:50Z'),
              endTime: new Date('2023-02-14T13:29:51Z'),
              valueState: null,
              valueNumber: 277,
            },
            {
              startTime: new Date('2023-02-14T13:29:51Z'),
              endTime: new Date('2023-02-14T13:29:52Z'),
              valueState: null,
              valueNumber: 283,
            },
            {
              startTime: new Date('2023-02-14T13:29:52Z'),
              endTime: new Date('2023-02-14T13:29:53Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:29:53Z'),
              endTime: new Date('2023-02-14T13:29:54Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:29:54Z'),
              endTime: new Date('2023-02-14T13:29:55Z'),
              valueState: null,
              valueNumber: 282,
            },
            {
              startTime: new Date('2023-02-14T13:29:55Z'),
              endTime: new Date('2023-02-14T13:29:56Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:29:56Z'),
              endTime: new Date('2023-02-14T13:29:57Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:29:57Z'),
              endTime: new Date('2023-02-14T13:29:58Z'),
              valueState: null,
              valueNumber: 279,
            },
            {
              startTime: new Date('2023-02-14T13:29:58Z'),
              endTime: new Date('2023-02-14T13:29:59Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:29:59Z'),
              endTime: new Date('2023-02-14T13:30:00Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:30:00Z'),
              endTime: new Date('2023-02-14T13:30:01Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:30:01Z'),
              endTime: new Date('2023-02-14T13:30:02Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:30:02Z'),
              endTime: new Date('2023-02-14T13:30:03Z'),
              valueState: null,
              valueNumber: 289,
            },
            {
              startTime: new Date('2023-02-14T13:30:03Z'),
              endTime: new Date('2023-02-14T13:30:04Z'),
              valueState: null,
              valueNumber: 295,
            },
            {
              startTime: new Date('2023-02-14T13:30:04Z'),
              endTime: new Date('2023-02-14T13:30:05Z'),
              valueState: null,
              valueNumber: 300,
            },
            {
              startTime: new Date('2023-02-14T13:30:05Z'),
              endTime: new Date('2023-02-14T13:30:06Z'),
              valueState: null,
              valueNumber: 307,
            },
            {
              startTime: new Date('2023-02-14T13:30:06Z'),
              endTime: new Date('2023-02-14T13:30:07Z'),
              valueState: null,
              valueNumber: 313,
            },
            {
              startTime: new Date('2023-02-14T13:30:07Z'),
              endTime: new Date('2023-02-14T13:30:08Z'),
              valueState: null,
              valueNumber: 313,
            },
            {
              startTime: new Date('2023-02-14T13:30:08Z'),
              endTime: new Date('2023-02-14T13:30:09Z'),
              valueState: null,
              valueNumber: 317,
            },
            {
              startTime: new Date('2023-02-14T13:30:09Z'),
              endTime: new Date('2023-02-14T13:30:10Z'),
              valueState: null,
              valueNumber: 325,
            },
            {
              startTime: new Date('2023-02-14T13:30:10Z'),
              endTime: new Date('2023-02-14T13:30:11Z'),
              valueState: null,
              valueNumber: 329,
            },
            {
              startTime: new Date('2023-02-14T13:30:11Z'),
              endTime: new Date('2023-02-14T13:30:12Z'),
              valueState: null,
              valueNumber: 337,
            },
            {
              startTime: new Date('2023-02-14T13:30:12Z'),
              endTime: new Date('2023-02-14T13:30:13Z'),
              valueState: null,
              valueNumber: 337,
            },
            {
              startTime: new Date('2023-02-14T13:30:13Z'),
              endTime: new Date('2023-02-14T13:30:14Z'),
              valueState: null,
              valueNumber: 343,
            },
            {
              startTime: new Date('2023-02-14T13:30:14Z'),
              endTime: new Date('2023-02-14T13:30:15Z'),
              valueState: null,
              valueNumber: 349,
            },
            {
              startTime: new Date('2023-02-14T13:30:15Z'),
              endTime: new Date('2023-02-14T13:30:16Z'),
              valueState: null,
              valueNumber: 355,
            },
            {
              startTime: new Date('2023-02-14T13:30:16Z'),
              endTime: new Date('2023-02-14T13:30:17Z'),
              valueState: null,
              valueNumber: 360,
            },
            {
              startTime: new Date('2023-02-14T13:30:17Z'),
              endTime: new Date('2023-02-14T13:30:18Z'),
              valueState: null,
              valueNumber: 366,
            },
            {
              startTime: new Date('2023-02-14T13:30:18Z'),
              endTime: new Date('2023-02-14T13:30:19Z'),
              valueState: null,
              valueNumber: 366,
            },
            {
              startTime: new Date('2023-02-14T13:30:19Z'),
              endTime: new Date('2023-02-14T13:30:20Z'),
              valueState: null,
              valueNumber: 373,
            },
            {
              startTime: new Date('2023-02-14T13:30:20Z'),
              endTime: new Date('2023-02-14T13:30:21Z'),
              valueState: null,
              valueNumber: 379,
            },
            {
              startTime: new Date('2023-02-14T13:30:21Z'),
              endTime: new Date('2023-02-14T13:30:22Z'),
              valueState: null,
              valueNumber: 384,
            },
            {
              startTime: new Date('2023-02-14T13:30:22Z'),
              endTime: new Date('2023-02-14T13:30:23Z'),
              valueState: null,
              valueNumber: 384,
            },
            {
              startTime: new Date('2023-02-14T13:30:23Z'),
              endTime: new Date('2023-02-14T13:30:24Z'),
              valueState: null,
              valueNumber: 390,
            },
            {
              startTime: new Date('2023-02-14T13:30:24Z'),
              endTime: new Date('2023-02-14T13:30:25Z'),
              valueState: null,
              valueNumber: 396,
            },
            {
              startTime: new Date('2023-02-14T13:30:25Z'),
              endTime: new Date('2023-02-14T13:30:26Z'),
              valueState: null,
              valueNumber: 402,
            },
            {
              startTime: new Date('2023-02-14T13:30:26Z'),
              endTime: new Date('2023-02-14T13:30:27Z'),
              valueState: null,
              valueNumber: 408,
            },
            {
              startTime: new Date('2023-02-14T13:30:27Z'),
              endTime: new Date('2023-02-14T13:30:28Z'),
              valueState: null,
              valueNumber: 408,
            },
            {
              startTime: new Date('2023-02-14T13:30:28Z'),
              endTime: new Date('2023-02-14T13:30:29Z'),
              valueState: null,
              valueNumber: 415,
            },
            {
              startTime: new Date('2023-02-14T13:30:29Z'),
              endTime: new Date('2023-02-14T13:30:30Z'),
              valueState: null,
              valueNumber: 420,
            },
            {
              startTime: new Date('2023-02-14T13:30:30Z'),
              endTime: new Date('2023-02-14T13:30:31Z'),
              valueState: null,
              valueNumber: 427,
            },
            {
              startTime: new Date('2023-02-14T13:30:31Z'),
              endTime: new Date('2023-02-14T13:30:32Z'),
              valueState: null,
              valueNumber: 432,
            },
            {
              startTime: new Date('2023-02-14T13:30:32Z'),
              endTime: new Date('2023-02-14T13:30:33Z'),
              valueState: null,
              valueNumber: 438,
            },
            {
              startTime: new Date('2023-02-14T13:30:33Z'),
              endTime: new Date('2023-02-14T13:30:34Z'),
              valueState: null,
              valueNumber: 438,
            },
            {
              startTime: new Date('2023-02-14T13:30:34Z'),
              endTime: new Date('2023-02-14T13:30:35Z'),
              valueState: null,
              valueNumber: 445,
            },
            {
              startTime: new Date('2023-02-14T13:30:35Z'),
              endTime: new Date('2023-02-14T13:30:36Z'),
              valueState: null,
              valueNumber: 450,
            },
            {
              startTime: new Date('2023-02-14T13:30:36Z'),
              endTime: new Date('2023-02-14T13:30:37Z'),
              valueState: null,
              valueNumber: 450,
            },
            {
              startTime: new Date('2023-02-14T13:30:37Z'),
              endTime: new Date('2023-02-14T13:30:38Z'),
              valueState: null,
              valueNumber: 427,
            },
            {
              startTime: new Date('2023-02-14T13:30:38Z'),
              endTime: new Date('2023-02-14T13:30:39Z'),
              valueState: null,
              valueNumber: 427,
            },
            {
              startTime: new Date('2023-02-14T13:30:39Z'),
              endTime: new Date('2023-02-14T13:30:40Z'),
              valueState: null,
              valueNumber: 391,
            },
            {
              startTime: new Date('2023-02-14T13:30:40Z'),
              endTime: new Date('2023-02-14T13:30:41Z'),
              valueState: null,
              valueNumber: 353,
            },
            {
              startTime: new Date('2023-02-14T13:30:41Z'),
              endTime: new Date('2023-02-14T13:30:42Z'),
              valueState: null,
              valueNumber: 314,
            },
            {
              startTime: new Date('2023-02-14T13:30:42Z'),
              endTime: new Date('2023-02-14T13:30:43Z'),
              valueState: null,
              valueNumber: 314,
            },
            {
              startTime: new Date('2023-02-14T13:30:43Z'),
              endTime: new Date('2023-02-14T13:30:44Z'),
              valueState: null,
              valueNumber: 272,
            },
            {
              startTime: new Date('2023-02-14T13:30:44Z'),
              endTime: new Date('2023-02-14T13:30:45Z'),
              valueState: null,
              valueNumber: 226,
            },
            {
              startTime: new Date('2023-02-14T13:30:45Z'),
              endTime: new Date('2023-02-14T13:30:46Z'),
              valueState: null,
              valueNumber: 174,
            },
            {
              startTime: new Date('2023-02-14T13:30:46Z'),
              endTime: new Date('2023-02-14T13:30:47Z'),
              valueState: null,
              valueNumber: 117,
            },
            {
              startTime: new Date('2023-02-14T13:30:47Z'),
              endTime: new Date('2023-02-14T13:30:48Z'),
              valueState: null,
              valueNumber: 117,
            },
            {
              startTime: new Date('2023-02-14T13:30:48Z'),
              endTime: new Date('2023-02-14T13:30:49Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:30:49Z'),
              endTime: new Date('2023-02-14T13:30:50Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:30:50Z'),
              endTime: new Date('2023-02-14T13:30:51Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:30:51Z'),
              endTime: new Date('2023-02-14T13:30:52Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:30:52Z'),
              endTime: new Date('2023-02-14T13:30:53Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:30:53Z'),
              endTime: new Date('2023-02-14T13:30:54Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:30:54Z'),
              endTime: new Date('2023-02-14T13:30:55Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:30:55Z'),
              endTime: new Date('2023-02-14T13:30:57Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:30:57Z'),
              endTime: new Date('2023-02-14T13:30:58Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:30:58Z'),
              endTime: new Date('2023-02-14T13:30:59Z'),
              valueState: null,
              valueNumber: 95,
            },
            {
              startTime: new Date('2023-02-14T13:30:59Z'),
              endTime: new Date('2023-02-14T13:31:00Z'),
              valueState: null,
              valueNumber: 112,
            },
            {
              startTime: new Date('2023-02-14T13:31:00Z'),
              endTime: new Date('2023-02-14T13:31:01Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:31:01Z'),
              endTime: new Date('2023-02-14T13:31:02Z'),
              valueState: null,
              valueNumber: 133,
            },
            {
              startTime: new Date('2023-02-14T13:31:02Z'),
              endTime: new Date('2023-02-14T13:31:05Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:31:05Z'),
              endTime: new Date('2023-02-14T13:31:06Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:31:06Z'),
              endTime: new Date('2023-02-14T13:31:09Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:31:09Z'),
              endTime: new Date('2023-02-14T13:31:10Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:31:10Z'),
              endTime: new Date('2023-02-14T13:31:11Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:31:11Z'),
              endTime: new Date('2023-02-14T13:31:12Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:31:12Z'),
              endTime: new Date('2023-02-14T13:31:13Z'),
              valueState: null,
              valueNumber: 103,
            },
            {
              startTime: new Date('2023-02-14T13:31:13Z'),
              endTime: new Date('2023-02-14T13:31:14Z'),
              valueState: null,
              valueNumber: 103,
            },
            {
              startTime: new Date('2023-02-14T13:31:14Z'),
              endTime: new Date('2023-02-14T13:31:15Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:31:15Z'),
              endTime: new Date('2023-02-14T13:31:16Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:31:16Z'),
              endTime: new Date('2023-02-14T13:31:23Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:31:23Z'),
              endTime: new Date('2023-02-14T13:31:24Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:31:24Z'),
              endTime: new Date('2023-02-14T13:31:34Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:31:34Z'),
              endTime: new Date('2023-02-14T13:31:35Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:31:35Z'),
              endTime: new Date('2023-02-14T13:31:36Z'),
              valueState: null,
              valueNumber: 107,
            },
            {
              startTime: new Date('2023-02-14T13:31:36Z'),
              endTime: new Date('2023-02-14T13:31:37Z'),
              valueState: null,
              valueNumber: 152,
            },
            {
              startTime: new Date('2023-02-14T13:31:37Z'),
              endTime: new Date('2023-02-14T13:31:38Z'),
              valueState: null,
              valueNumber: 203,
            },
            {
              startTime: new Date('2023-02-14T13:31:38Z'),
              endTime: new Date('2023-02-14T13:31:39Z'),
              valueState: null,
              valueNumber: 253,
            },
            {
              startTime: new Date('2023-02-14T13:31:39Z'),
              endTime: new Date('2023-02-14T13:31:40Z'),
              valueState: null,
              valueNumber: 303,
            },
            {
              startTime: new Date('2023-02-14T13:31:40Z'),
              endTime: new Date('2023-02-14T13:31:41Z'),
              valueState: null,
              valueNumber: 303,
            },
            {
              startTime: new Date('2023-02-14T13:31:41Z'),
              endTime: new Date('2023-02-14T13:31:42Z'),
              valueState: null,
              valueNumber: 353,
            },
            {
              startTime: new Date('2023-02-14T13:31:42Z'),
              endTime: new Date('2023-02-14T13:31:43Z'),
              valueState: null,
              valueNumber: 402,
            },
            {
              startTime: new Date('2023-02-14T13:31:43Z'),
              endTime: new Date('2023-02-14T13:31:44Z'),
              valueState: null,
              valueNumber: 451,
            },
            {
              startTime: new Date('2023-02-14T13:31:44Z'),
              endTime: new Date('2023-02-14T13:31:45Z'),
              valueState: null,
              valueNumber: 451,
            },
            {
              startTime: new Date('2023-02-14T13:31:45Z'),
              endTime: new Date('2023-02-14T13:31:46Z'),
              valueState: null,
              valueNumber: 498,
            },
            {
              startTime: new Date('2023-02-14T13:31:46Z'),
              endTime: new Date('2023-02-14T13:31:47Z'),
              valueState: null,
              valueNumber: 509,
            },
            {
              startTime: new Date('2023-02-14T13:31:47Z'),
              endTime: new Date('2023-02-14T13:31:48Z'),
              valueState: null,
              valueNumber: 508,
            },
            {
              startTime: new Date('2023-02-14T13:31:48Z'),
              endTime: new Date('2023-02-14T13:31:49Z'),
              valueState: null,
              valueNumber: 505,
            },
            {
              startTime: new Date('2023-02-14T13:31:49Z'),
              endTime: new Date('2023-02-14T13:31:50Z'),
              valueState: null,
              valueNumber: 505,
            },
            {
              startTime: new Date('2023-02-14T13:31:50Z'),
              endTime: new Date('2023-02-14T13:31:51Z'),
              valueState: null,
              valueNumber: 503,
            },
            {
              startTime: new Date('2023-02-14T13:31:51Z'),
              endTime: new Date('2023-02-14T13:31:54Z'),
              valueState: null,
              valueNumber: 502,
            },
            {
              startTime: new Date('2023-02-14T13:31:54Z'),
              endTime: new Date('2023-02-14T13:31:55Z'),
              valueState: null,
              valueNumber: 502,
            },
            {
              startTime: new Date('2023-02-14T13:31:55Z'),
              endTime: new Date('2023-02-14T13:31:56Z'),
              valueState: null,
              valueNumber: 503,
            },
            {
              startTime: new Date('2023-02-14T13:31:56Z'),
              endTime: new Date('2023-02-14T13:31:57Z'),
              valueState: null,
              valueNumber: 547,
            },
            {
              startTime: new Date('2023-02-14T13:31:57Z'),
              endTime: new Date('2023-02-14T13:31:58Z'),
              valueState: null,
              valueNumber: 598,
            },
            {
              startTime: new Date('2023-02-14T13:31:58Z'),
              endTime: new Date('2023-02-14T13:31:59Z'),
              valueState: null,
              valueNumber: 648,
            },
            {
              startTime: new Date('2023-02-14T13:31:59Z'),
              endTime: new Date('2023-02-14T13:32:00Z'),
              valueState: null,
              valueNumber: 698,
            },
            {
              startTime: new Date('2023-02-14T13:32:00Z'),
              endTime: new Date('2023-02-14T13:32:01Z'),
              valueState: null,
              valueNumber: 698,
            },
            {
              startTime: new Date('2023-02-14T13:32:01Z'),
              endTime: new Date('2023-02-14T13:32:02Z'),
              valueState: null,
              valueNumber: 706,
            },
            {
              startTime: new Date('2023-02-14T13:32:02Z'),
              endTime: new Date('2023-02-14T13:32:03Z'),
              valueState: null,
              valueNumber: 704,
            },
            {
              startTime: new Date('2023-02-14T13:32:03Z'),
              endTime: new Date('2023-02-14T13:32:04Z'),
              valueState: null,
              valueNumber: 703,
            },
            {
              startTime: new Date('2023-02-14T13:32:04Z'),
              endTime: new Date('2023-02-14T13:32:07Z'),
              valueState: null,
              valueNumber: 702,
            },
            {
              startTime: new Date('2023-02-14T13:32:07Z'),
              endTime: new Date('2023-02-14T13:32:08Z'),
              valueState: null,
              valueNumber: 702,
            },
            {
              startTime: new Date('2023-02-14T13:32:08Z'),
              endTime: new Date('2023-02-14T13:32:09Z'),
              valueState: null,
              valueNumber: 701,
            },
            {
              startTime: new Date('2023-02-14T13:32:09Z'),
              endTime: new Date('2023-02-14T13:32:11Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:32:11Z'),
              endTime: new Date('2023-02-14T13:32:12Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:32:12Z'),
              endTime: new Date('2023-02-14T13:32:13Z'),
              valueState: null,
              valueNumber: 724,
            },
            {
              startTime: new Date('2023-02-14T13:32:13Z'),
              endTime: new Date('2023-02-14T13:32:14Z'),
              valueState: null,
              valueNumber: 773,
            },
            {
              startTime: new Date('2023-02-14T13:32:14Z'),
              endTime: new Date('2023-02-14T13:32:15Z'),
              valueState: null,
              valueNumber: 823,
            },
            {
              startTime: new Date('2023-02-14T13:32:15Z'),
              endTime: new Date('2023-02-14T13:32:16Z'),
              valueState: null,
              valueNumber: 873,
            },
            {
              startTime: new Date('2023-02-14T13:32:16Z'),
              endTime: new Date('2023-02-14T13:32:17Z'),
              valueState: null,
              valueNumber: 873,
            },
            {
              startTime: new Date('2023-02-14T13:32:17Z'),
              endTime: new Date('2023-02-14T13:32:18Z'),
              valueState: null,
              valueNumber: 921,
            },
            {
              startTime: new Date('2023-02-14T13:32:18Z'),
              endTime: new Date('2023-02-14T13:32:19Z'),
              valueState: null,
              valueNumber: 971,
            },
            {
              startTime: new Date('2023-02-14T13:32:19Z'),
              endTime: new Date('2023-02-14T13:32:20Z'),
              valueState: null,
              valueNumber: 1004,
            },
            {
              startTime: new Date('2023-02-14T13:32:20Z'),
              endTime: new Date('2023-02-14T13:32:21Z'),
              valueState: null,
              valueNumber: 1005,
            },
            {
              startTime: new Date('2023-02-14T13:32:21Z'),
              endTime: new Date('2023-02-14T13:32:22Z'),
              valueState: null,
              valueNumber: 1004,
            },
            {
              startTime: new Date('2023-02-14T13:32:22Z'),
              endTime: new Date('2023-02-14T13:32:23Z'),
              valueState: null,
              valueNumber: 1002,
            },
            {
              startTime: new Date('2023-02-14T13:32:23Z'),
              endTime: new Date('2023-02-14T13:32:24Z'),
              valueState: null,
              valueNumber: 1002,
            },
            {
              startTime: new Date('2023-02-14T13:32:24Z'),
              endTime: new Date('2023-02-14T13:32:26Z'),
              valueState: null,
              valueNumber: 1001,
            },
            {
              startTime: new Date('2023-02-14T13:32:26Z'),
              endTime: new Date('2023-02-14T13:32:27Z'),
              valueState: null,
              valueNumber: 1001,
            },
            {
              startTime: new Date('2023-02-14T13:32:27Z'),
              endTime: new Date('2023-02-14T13:32:34Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:32:34Z'),
              endTime: new Date('2023-02-14T13:32:35Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:32:35Z'),
              endTime: new Date('2023-02-14T13:32:36Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:32:36Z'),
              endTime: new Date('2023-02-14T13:32:37Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:32:37Z'),
              endTime: new Date('2023-02-14T13:32:39Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:32:39Z'),
              endTime: new Date('2023-02-14T13:32:40Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:32:40Z'),
              endTime: new Date('2023-02-14T13:32:42Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:32:42Z'),
              endTime: new Date('2023-02-14T13:32:43Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:32:43Z'),
              endTime: new Date('2023-02-14T13:32:45Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:32:45Z'),
              endTime: new Date('2023-02-14T13:32:46Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:32:46Z'),
              endTime: new Date('2023-02-14T13:32:49Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:32:49Z'),
              endTime: new Date('2023-02-14T13:32:50Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:32:50Z'),
              endTime: new Date('2023-02-14T13:32:52Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:32:52Z'),
              endTime: new Date('2023-02-14T13:32:53Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:32:53Z'),
              endTime: new Date('2023-02-14T13:32:54Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:32:54Z'),
              endTime: new Date('2023-02-14T13:32:55Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:32:55Z'),
              endTime: new Date('2023-02-14T13:32:58Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:32:58Z'),
              endTime: new Date('2023-02-14T13:32:59Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:32:59Z'),
              endTime: new Date('2023-02-14T13:33:00Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:00Z'),
              endTime: new Date('2023-02-14T13:33:01Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:01Z'),
              endTime: new Date('2023-02-14T13:33:04Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:04Z'),
              endTime: new Date('2023-02-14T13:33:05Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:05Z'),
              endTime: new Date('2023-02-14T13:33:06Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:06Z'),
              endTime: new Date('2023-02-14T13:33:07Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:07Z'),
              endTime: new Date('2023-02-14T13:33:09Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:09Z'),
              endTime: new Date('2023-02-14T13:33:10Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:10Z'),
              endTime: new Date('2023-02-14T13:33:12Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:12Z'),
              endTime: new Date('2023-02-14T13:33:13Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:13Z'),
              endTime: new Date('2023-02-14T13:33:16Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:16Z'),
              endTime: new Date('2023-02-14T13:33:17Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:17Z'),
              endTime: new Date('2023-02-14T13:33:18Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:18Z'),
              endTime: new Date('2023-02-14T13:33:19Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:19Z'),
              endTime: new Date('2023-02-14T13:33:21Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:21Z'),
              endTime: new Date('2023-02-14T13:33:22Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:22Z'),
              endTime: new Date('2023-02-14T13:33:25Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:25Z'),
              endTime: new Date('2023-02-14T13:33:26Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:26Z'),
              endTime: new Date('2023-02-14T13:33:27Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:27Z'),
              endTime: new Date('2023-02-14T13:33:28Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:28Z'),
              endTime: new Date('2023-02-14T13:33:30Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:30Z'),
              endTime: new Date('2023-02-14T13:33:31Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:31Z'),
              endTime: new Date('2023-02-14T13:33:33Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:33Z'),
              endTime: new Date('2023-02-14T13:33:34Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:34Z'),
              endTime: new Date('2023-02-14T13:33:36Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:36Z'),
              endTime: new Date('2023-02-14T13:33:37Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:33:37Z'),
              endTime: new Date('2023-02-14T13:33:39Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:39Z'),
              endTime: new Date('2023-02-14T13:33:40Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:40Z'),
              endTime: new Date('2023-02-14T13:33:41Z'),
              valueState: null,
              valueNumber: 969,
            },
            {
              startTime: new Date('2023-02-14T13:33:41Z'),
              endTime: new Date('2023-02-14T13:33:42Z'),
              valueState: null,
              valueNumber: 942,
            },
            {
              startTime: new Date('2023-02-14T13:33:42Z'),
              endTime: new Date('2023-02-14T13:33:43Z'),
              valueState: null,
              valueNumber: 942,
            },
            {
              startTime: new Date('2023-02-14T13:33:43Z'),
              endTime: new Date('2023-02-14T13:33:44Z'),
              valueState: null,
              valueNumber: 903,
            },
            {
              startTime: new Date('2023-02-14T13:33:44Z'),
              endTime: new Date('2023-02-14T13:33:45Z'),
              valueState: null,
              valueNumber: 870,
            },
            {
              startTime: new Date('2023-02-14T13:33:45Z'),
              endTime: new Date('2023-02-14T13:33:46Z'),
              valueState: null,
              valueNumber: 837,
            },
            {
              startTime: new Date('2023-02-14T13:33:46Z'),
              endTime: new Date('2023-02-14T13:33:47Z'),
              valueState: null,
              valueNumber: 802,
            },
            {
              startTime: new Date('2023-02-14T13:33:47Z'),
              endTime: new Date('2023-02-14T13:33:48Z'),
              valueState: null,
              valueNumber: 765,
            },
            {
              startTime: new Date('2023-02-14T13:33:48Z'),
              endTime: new Date('2023-02-14T13:33:49Z'),
              valueState: null,
              valueNumber: 765,
            },
            {
              startTime: new Date('2023-02-14T13:33:49Z'),
              endTime: new Date('2023-02-14T13:33:50Z'),
              valueState: null,
              valueNumber: 729,
            },
            {
              startTime: new Date('2023-02-14T13:33:50Z'),
              endTime: new Date('2023-02-14T13:33:51Z'),
              valueState: null,
              valueNumber: 692,
            },
            {
              startTime: new Date('2023-02-14T13:33:51Z'),
              endTime: new Date('2023-02-14T13:33:52Z'),
              valueState: null,
              valueNumber: 650,
            },
            {
              startTime: new Date('2023-02-14T13:33:52Z'),
              endTime: new Date('2023-02-14T13:33:53Z'),
              valueState: null,
              valueNumber: 618,
            },
            {
              startTime: new Date('2023-02-14T13:33:53Z'),
              endTime: new Date('2023-02-14T13:33:54Z'),
              valueState: null,
              valueNumber: 618,
            },
            {
              startTime: new Date('2023-02-14T13:33:54Z'),
              endTime: new Date('2023-02-14T13:33:55Z'),
              valueState: null,
              valueNumber: 575,
            },
            {
              startTime: new Date('2023-02-14T13:33:55Z'),
              endTime: new Date('2023-02-14T13:33:56Z'),
              valueState: null,
              valueNumber: 536,
            },
            {
              startTime: new Date('2023-02-14T13:33:56Z'),
              endTime: new Date('2023-02-14T13:33:57Z'),
              valueState: null,
              valueNumber: 495,
            },
            {
              startTime: new Date('2023-02-14T13:33:57Z'),
              endTime: new Date('2023-02-14T13:33:58Z'),
              valueState: null,
              valueNumber: 454,
            },
            {
              startTime: new Date('2023-02-14T13:33:58Z'),
              endTime: new Date('2023-02-14T13:33:59Z'),
              valueState: null,
              valueNumber: 454,
            },
            {
              startTime: new Date('2023-02-14T13:33:59Z'),
              endTime: new Date('2023-02-14T13:34:00Z'),
              valueState: null,
              valueNumber: 410,
            },
            {
              startTime: new Date('2023-02-14T13:34:00Z'),
              endTime: new Date('2023-02-14T13:34:01Z'),
              valueState: null,
              valueNumber: 363,
            },
            {
              startTime: new Date('2023-02-14T13:34:01Z'),
              endTime: new Date('2023-02-14T13:34:02Z'),
              valueState: null,
              valueNumber: 314,
            },
            {
              startTime: new Date('2023-02-14T13:34:02Z'),
              endTime: new Date('2023-02-14T13:34:03Z'),
              valueState: null,
              valueNumber: 314,
            },
            {
              startTime: new Date('2023-02-14T13:34:03Z'),
              endTime: new Date('2023-02-14T13:34:04Z'),
              valueState: null,
              valueNumber: 267,
            },
            {
              startTime: new Date('2023-02-14T13:34:04Z'),
              endTime: new Date('2023-02-14T13:34:05Z'),
              valueState: null,
              valueNumber: 229,
            },
            {
              startTime: new Date('2023-02-14T13:34:05Z'),
              endTime: new Date('2023-02-14T13:34:06Z'),
              valueState: null,
              valueNumber: 169,
            },
            {
              startTime: new Date('2023-02-14T13:34:06Z'),
              endTime: new Date('2023-02-14T13:34:07Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:34:07Z'),
              endTime: new Date('2023-02-14T13:34:08Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:34:08Z'),
              endTime: new Date('2023-02-14T13:34:09Z'),
              valueState: null,
              valueNumber: 29,
            },
            {
              startTime: new Date('2023-02-14T13:34:09Z'),
              endTime: new Date('2023-02-14T13:34:10Z'),
              valueState: null,
              valueNumber: 70,
            },
            {
              startTime: new Date('2023-02-14T13:34:10Z'),
              endTime: new Date('2023-02-14T13:34:11Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:11Z'),
              endTime: new Date('2023-02-14T13:34:12Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:12Z'),
              endTime: new Date('2023-02-14T13:34:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:13Z'),
              endTime: new Date('2023-02-14T13:34:14Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:14Z'),
              endTime: new Date('2023-02-14T13:34:15Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:34:15Z'),
              endTime: new Date('2023-02-14T13:34:16Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:34:16Z'),
              endTime: new Date('2023-02-14T13:34:17Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:17Z'),
              endTime: new Date('2023-02-14T13:34:18Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:18Z'),
              endTime: new Date('2023-02-14T13:34:19Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:34:19Z'),
              endTime: new Date('2023-02-14T13:34:21Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:21Z'),
              endTime: new Date('2023-02-14T13:34:22Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:22Z'),
              endTime: new Date('2023-02-14T13:34:23Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:34:23Z'),
              endTime: new Date('2023-02-14T13:34:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:25Z'),
              endTime: new Date('2023-02-14T13:34:26Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:26Z'),
              endTime: new Date('2023-02-14T13:34:27Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:34:27Z'),
              endTime: new Date('2023-02-14T13:34:28Z'),
              valueState: null,
              valueNumber: 8,
            },
            {
              startTime: new Date('2023-02-14T13:34:28Z'),
              endTime: new Date('2023-02-14T13:34:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:29Z'),
              endTime: new Date('2023-02-14T13:34:30Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:30Z'),
              endTime: new Date('2023-02-14T13:34:31Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:31Z'),
              endTime: new Date('2023-02-14T13:34:32Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:32Z'),
              endTime: new Date('2023-02-14T13:34:35Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:35Z'),
              endTime: new Date('2023-02-14T13:34:36Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:36Z'),
              endTime: new Date('2023-02-14T13:34:37Z'),
              valueState: null,
              valueNumber: 21,
            },
            {
              startTime: new Date('2023-02-14T13:34:37Z'),
              endTime: new Date('2023-02-14T13:34:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:38Z'),
              endTime: new Date('2023-02-14T13:34:39Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:34:39Z'),
              endTime: new Date('2023-02-14T13:34:40Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:34:40Z'),
              endTime: new Date('2023-02-14T13:34:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:44Z'),
              endTime: new Date('2023-02-14T13:34:45Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:45Z'),
              endTime: new Date('2023-02-14T13:34:46Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:46Z'),
              endTime: new Date('2023-02-14T13:34:47Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:34:47Z'),
              endTime: new Date('2023-02-14T13:34:48Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:34:48Z'),
              endTime: new Date('2023-02-14T13:34:49Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:34:49Z'),
              endTime: new Date('2023-02-14T13:34:50Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:50Z'),
              endTime: new Date('2023-02-14T13:34:51Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:34:51Z'),
              endTime: new Date('2023-02-14T13:34:52Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:34:52Z'),
              endTime: new Date('2023-02-14T13:34:53Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:53Z'),
              endTime: new Date('2023-02-14T13:34:54Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:54Z'),
              endTime: new Date('2023-02-14T13:34:55Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:55Z'),
              endTime: new Date('2023-02-14T13:34:56Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:56Z'),
              endTime: new Date('2023-02-14T13:34:58Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:58Z'),
              endTime: new Date('2023-02-14T13:34:59Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:34:59Z'),
              endTime: new Date('2023-02-14T13:35:00Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:00Z'),
              endTime: new Date('2023-02-14T13:35:01Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:01Z'),
              endTime: new Date('2023-02-14T13:35:02Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:35:02Z'),
              endTime: new Date('2023-02-14T13:35:03Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:35:03Z'),
              endTime: new Date('2023-02-14T13:35:04Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:04Z'),
              endTime: new Date('2023-02-14T13:35:05Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:05Z'),
              endTime: new Date('2023-02-14T13:35:06Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:35:06Z'),
              endTime: new Date('2023-02-14T13:35:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:07Z'),
              endTime: new Date('2023-02-14T13:35:08Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:35:08Z'),
              endTime: new Date('2023-02-14T13:35:09Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:35:09Z'),
              endTime: new Date('2023-02-14T13:35:10Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:35:10Z'),
              endTime: new Date('2023-02-14T13:35:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:11Z'),
              endTime: new Date('2023-02-14T13:35:12Z'),
              valueState: null,
              valueNumber: 8,
            },
            {
              startTime: new Date('2023-02-14T13:35:12Z'),
              endTime: new Date('2023-02-14T13:35:13Z'),
              valueState: null,
              valueNumber: 8,
            },
            {
              startTime: new Date('2023-02-14T13:35:13Z'),
              endTime: new Date('2023-02-14T13:35:14Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:35:14Z'),
              endTime: new Date('2023-02-14T13:35:15Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:15Z'),
              endTime: new Date('2023-02-14T13:35:16Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:35:16Z'),
              endTime: new Date('2023-02-14T13:35:17Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:35:17Z'),
              endTime: new Date('2023-02-14T13:35:20Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:20Z'),
              endTime: new Date('2023-02-14T13:35:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:21Z'),
              endTime: new Date('2023-02-14T13:35:22Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:35:22Z'),
              endTime: new Date('2023-02-14T13:35:23Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:23Z'),
              endTime: new Date('2023-02-14T13:35:24Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:35:24Z'),
              endTime: new Date('2023-02-14T13:35:26Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:35:26Z'),
              endTime: new Date('2023-02-14T13:35:27Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:35:27Z'),
              endTime: new Date('2023-02-14T13:35:31Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:35:31Z'),
              endTime: new Date('2023-02-14T13:35:32Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:35:32Z'),
              endTime: new Date('2023-02-14T13:35:33Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:35:33Z'),
              endTime: new Date('2023-02-14T13:35:34Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:34Z'),
              endTime: new Date('2023-02-14T13:35:35Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:35Z'),
              endTime: new Date('2023-02-14T13:35:36Z'),
              valueState: null,
              valueNumber: 45,
            },
            {
              startTime: new Date('2023-02-14T13:35:36Z'),
              endTime: new Date('2023-02-14T13:35:37Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:35:37Z'),
              endTime: new Date('2023-02-14T13:35:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:38Z'),
              endTime: new Date('2023-02-14T13:35:39Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:35:39Z'),
              endTime: new Date('2023-02-14T13:35:41Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:41Z'),
              endTime: new Date('2023-02-14T13:35:42Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:42Z'),
              endTime: new Date('2023-02-14T13:35:43Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:35:43Z'),
              endTime: new Date('2023-02-14T13:35:45Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:45Z'),
              endTime: new Date('2023-02-14T13:35:46Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:46Z'),
              endTime: new Date('2023-02-14T13:35:47Z'),
              valueState: null,
              valueNumber: 53,
            },
            {
              startTime: new Date('2023-02-14T13:35:47Z'),
              endTime: new Date('2023-02-14T13:35:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:50Z'),
              endTime: new Date('2023-02-14T13:35:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:51Z'),
              endTime: new Date('2023-02-14T13:35:52Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:35:52Z'),
              endTime: new Date('2023-02-14T13:35:53Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:53Z'),
              endTime: new Date('2023-02-14T13:35:54Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:35:54Z'),
              endTime: new Date('2023-02-14T13:35:55Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:35:55Z'),
              endTime: new Date('2023-02-14T13:35:56Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:35:56Z'),
              endTime: new Date('2023-02-14T13:35:57Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:35:57Z'),
              endTime: new Date('2023-02-14T13:35:58Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:35:58Z'),
              endTime: new Date('2023-02-14T13:36:00Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:00Z'),
              endTime: new Date('2023-02-14T13:36:01Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:01Z'),
              endTime: new Date('2023-02-14T13:36:02Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:36:02Z'),
              endTime: new Date('2023-02-14T13:36:03Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:36:03Z'),
              endTime: new Date('2023-02-14T13:36:04Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:04Z'),
              endTime: new Date('2023-02-14T13:36:05Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:05Z'),
              endTime: new Date('2023-02-14T13:36:06Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:36:06Z'),
              endTime: new Date('2023-02-14T13:36:07Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:07Z'),
              endTime: new Date('2023-02-14T13:36:08Z'),
              valueState: null,
              valueNumber: 25,
            },
            {
              startTime: new Date('2023-02-14T13:36:08Z'),
              endTime: new Date('2023-02-14T13:36:09Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:09Z'),
              endTime: new Date('2023-02-14T13:36:10Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:10Z'),
              endTime: new Date('2023-02-14T13:36:11Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:11Z'),
              endTime: new Date('2023-02-14T13:36:12Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:12Z'),
              endTime: new Date('2023-02-14T13:36:15Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:15Z'),
              endTime: new Date('2023-02-14T13:36:16Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:16Z'),
              endTime: new Date('2023-02-14T13:36:17Z'),
              valueState: null,
              valueNumber: 5,
            },
            {
              startTime: new Date('2023-02-14T13:36:17Z'),
              endTime: new Date('2023-02-14T13:36:18Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:18Z'),
              endTime: new Date('2023-02-14T13:36:19Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:36:19Z'),
              endTime: new Date('2023-02-14T13:36:20Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:36:20Z'),
              endTime: new Date('2023-02-14T13:36:21Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:21Z'),
              endTime: new Date('2023-02-14T13:36:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:22Z'),
              endTime: new Date('2023-02-14T13:36:23Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:23Z'),
              endTime: new Date('2023-02-14T13:36:24Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:24Z'),
              endTime: new Date('2023-02-14T13:36:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:25Z'),
              endTime: new Date('2023-02-14T13:36:26Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:26Z'),
              endTime: new Date('2023-02-14T13:36:27Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:27Z'),
              endTime: new Date('2023-02-14T13:36:28Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:28Z'),
              endTime: new Date('2023-02-14T13:36:29Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:36:29Z'),
              endTime: new Date('2023-02-14T13:36:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:30Z'),
              endTime: new Date('2023-02-14T13:36:31Z'),
              valueState: null,
              valueNumber: 51,
            },
            {
              startTime: new Date('2023-02-14T13:36:31Z'),
              endTime: new Date('2023-02-14T13:36:34Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:36:34Z'),
              endTime: new Date('2023-02-14T13:36:35Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:36:35Z'),
              endTime: new Date('2023-02-14T13:36:36Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:36Z'),
              endTime: new Date('2023-02-14T13:36:37Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:36:37Z'),
              endTime: new Date('2023-02-14T13:36:38Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:38Z'),
              endTime: new Date('2023-02-14T13:36:39Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:39Z'),
              endTime: new Date('2023-02-14T13:36:40Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:36:40Z'),
              endTime: new Date('2023-02-14T13:36:41Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:41Z'),
              endTime: new Date('2023-02-14T13:36:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:42Z'),
              endTime: new Date('2023-02-14T13:36:43Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:36:43Z'),
              endTime: new Date('2023-02-14T13:36:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:44Z'),
              endTime: new Date('2023-02-14T13:36:45Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:45Z'),
              endTime: new Date('2023-02-14T13:36:46Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:46Z'),
              endTime: new Date('2023-02-14T13:36:47Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:36:47Z'),
              endTime: new Date('2023-02-14T13:36:48Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:48Z'),
              endTime: new Date('2023-02-14T13:36:49Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:49Z'),
              endTime: new Date('2023-02-14T13:36:50Z'),
              valueState: null,
              valueNumber: 41,
            },
            {
              startTime: new Date('2023-02-14T13:36:50Z'),
              endTime: new Date('2023-02-14T13:36:51Z'),
              valueState: null,
              valueNumber: 41,
            },
            {
              startTime: new Date('2023-02-14T13:36:51Z'),
              endTime: new Date('2023-02-14T13:36:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:52Z'),
              endTime: new Date('2023-02-14T13:36:53Z'),
              valueState: null,
              valueNumber: 16,
            },
            {
              startTime: new Date('2023-02-14T13:36:53Z'),
              endTime: new Date('2023-02-14T13:36:55Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:55Z'),
              endTime: new Date('2023-02-14T13:36:56Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:56Z'),
              endTime: new Date('2023-02-14T13:36:57Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:36:57Z'),
              endTime: new Date('2023-02-14T13:36:58Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:36:58Z'),
              endTime: new Date('2023-02-14T13:36:59Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:36:59Z'),
              endTime: new Date('2023-02-14T13:37:00Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:37:00Z'),
              endTime: new Date('2023-02-14T13:37:01Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:37:01Z'),
              endTime: new Date('2023-02-14T13:37:02Z'),
              valueState: null,
              valueNumber: 23,
            },
            {
              startTime: new Date('2023-02-14T13:37:02Z'),
              endTime: new Date('2023-02-14T13:37:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:03Z'),
              endTime: new Date('2023-02-14T13:37:04Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:37:04Z'),
              endTime: new Date('2023-02-14T13:37:05Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:37:05Z'),
              endTime: new Date('2023-02-14T13:37:06Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:37:06Z'),
              endTime: new Date('2023-02-14T13:37:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:07Z'),
              endTime: new Date('2023-02-14T13:37:08Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:37:08Z'),
              endTime: new Date('2023-02-14T13:37:09Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:09Z'),
              endTime: new Date('2023-02-14T13:37:10Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:37:10Z'),
              endTime: new Date('2023-02-14T13:37:11Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:37:11Z'),
              endTime: new Date('2023-02-14T13:37:12Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:12Z'),
              endTime: new Date('2023-02-14T13:37:13Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:37:13Z'),
              endTime: new Date('2023-02-14T13:37:14Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:14Z'),
              endTime: new Date('2023-02-14T13:37:15Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:15Z'),
              endTime: new Date('2023-02-14T13:37:16Z'),
              valueState: null,
              valueNumber: 52,
            },
            {
              startTime: new Date('2023-02-14T13:37:16Z'),
              endTime: new Date('2023-02-14T13:37:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:21Z'),
              endTime: new Date('2023-02-14T13:37:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:22Z'),
              endTime: new Date('2023-02-14T13:37:23Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:37:23Z'),
              endTime: new Date('2023-02-14T13:37:24Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:37:24Z'),
              endTime: new Date('2023-02-14T13:37:25Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:37:25Z'),
              endTime: new Date('2023-02-14T13:37:26Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:26Z'),
              endTime: new Date('2023-02-14T13:37:30Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:37:30Z'),
              endTime: new Date('2023-02-14T13:37:31Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:37:31Z'),
              endTime: new Date('2023-02-14T13:37:32Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:32Z'),
              endTime: new Date('2023-02-14T13:37:33Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:37:33Z'),
              endTime: new Date('2023-02-14T13:37:34Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:37:34Z'),
              endTime: new Date('2023-02-14T13:37:35Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:37:35Z'),
              endTime: new Date('2023-02-14T13:37:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:36Z'),
              endTime: new Date('2023-02-14T13:37:37Z'),
              valueState: null,
              valueNumber: 20,
            },
            {
              startTime: new Date('2023-02-14T13:37:37Z'),
              endTime: new Date('2023-02-14T13:37:39Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:37:39Z'),
              endTime: new Date('2023-02-14T13:37:40Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:37:40Z'),
              endTime: new Date('2023-02-14T13:37:41Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:41Z'),
              endTime: new Date('2023-02-14T13:37:42Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:37:42Z'),
              endTime: new Date('2023-02-14T13:37:43Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:37:43Z'),
              endTime: new Date('2023-02-14T13:37:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:44Z'),
              endTime: new Date('2023-02-14T13:37:45Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:45Z'),
              endTime: new Date('2023-02-14T13:37:46Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T13:37:46Z'),
              endTime: new Date('2023-02-14T13:37:47Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:47Z'),
              endTime: new Date('2023-02-14T13:37:48Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:37:48Z'),
              endTime: new Date('2023-02-14T13:37:49Z'),
              valueState: null,
              valueNumber: 36,
            },
            {
              startTime: new Date('2023-02-14T13:37:49Z'),
              endTime: new Date('2023-02-14T13:37:50Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:37:50Z'),
              endTime: new Date('2023-02-14T13:37:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:51Z'),
              endTime: new Date('2023-02-14T13:37:55Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:37:55Z'),
              endTime: new Date('2023-02-14T13:37:56Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:37:56Z'),
              endTime: new Date('2023-02-14T13:37:57Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:37:57Z'),
              endTime: new Date('2023-02-14T13:37:58Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:37:58Z'),
              endTime: new Date('2023-02-14T13:37:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:59Z'),
              endTime: new Date('2023-02-14T13:38:00Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:00Z'),
              endTime: new Date('2023-02-14T13:38:04Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:04Z'),
              endTime: new Date('2023-02-14T13:38:05Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:05Z'),
              endTime: new Date('2023-02-14T13:38:06Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:06Z'),
              endTime: new Date('2023-02-14T13:38:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:07Z'),
              endTime: new Date('2023-02-14T13:38:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:08Z'),
              endTime: new Date('2023-02-14T13:38:09Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:09Z'),
              endTime: new Date('2023-02-14T13:38:10Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:10Z'),
              endTime: new Date('2023-02-14T13:38:11Z'),
              valueState: null,
              valueNumber: 57,
            },
            {
              startTime: new Date('2023-02-14T13:38:11Z'),
              endTime: new Date('2023-02-14T13:38:12Z'),
              valueState: null,
              valueNumber: 57,
            },
            {
              startTime: new Date('2023-02-14T13:38:12Z'),
              endTime: new Date('2023-02-14T13:38:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:13Z'),
              endTime: new Date('2023-02-14T13:38:14Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:38:14Z'),
              endTime: new Date('2023-02-14T13:38:15Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:15Z'),
              endTime: new Date('2023-02-14T13:38:16Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:16Z'),
              endTime: new Date('2023-02-14T13:38:17Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:38:17Z'),
              endTime: new Date('2023-02-14T13:38:18Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:38:18Z'),
              endTime: new Date('2023-02-14T13:38:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:19Z'),
              endTime: new Date('2023-02-14T13:38:20Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:20Z'),
              endTime: new Date('2023-02-14T13:38:21Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:21Z'),
              endTime: new Date('2023-02-14T13:38:22Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:38:22Z'),
              endTime: new Date('2023-02-14T13:38:25Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:25Z'),
              endTime: new Date('2023-02-14T13:38:26Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:26Z'),
              endTime: new Date('2023-02-14T13:38:27Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:38:27Z'),
              endTime: new Date('2023-02-14T13:38:28Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:38:28Z'),
              endTime: new Date('2023-02-14T13:38:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:29Z'),
              endTime: new Date('2023-02-14T13:38:30Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:30Z'),
              endTime: new Date('2023-02-14T13:38:31Z'),
              valueState: null,
              valueNumber: 22,
            },
            {
              startTime: new Date('2023-02-14T13:38:31Z'),
              endTime: new Date('2023-02-14T13:38:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:32Z'),
              endTime: new Date('2023-02-14T13:38:33Z'),
              valueState: null,
              valueNumber: 37,
            },
            {
              startTime: new Date('2023-02-14T13:38:33Z'),
              endTime: new Date('2023-02-14T13:38:34Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:38:34Z'),
              endTime: new Date('2023-02-14T13:38:35Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:38:35Z'),
              endTime: new Date('2023-02-14T13:38:36Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:36Z'),
              endTime: new Date('2023-02-14T13:38:37Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:37Z'),
              endTime: new Date('2023-02-14T13:38:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:38Z'),
              endTime: new Date('2023-02-14T13:38:40Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:40Z'),
              endTime: new Date('2023-02-14T13:38:41Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:41Z'),
              endTime: new Date('2023-02-14T13:38:42Z'),
              valueState: null,
              valueNumber: 7,
            },
            {
              startTime: new Date('2023-02-14T13:38:42Z'),
              endTime: new Date('2023-02-14T13:38:43Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:43Z'),
              endTime: new Date('2023-02-14T13:38:44Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:44Z'),
              endTime: new Date('2023-02-14T13:38:45Z'),
              valueState: null,
              valueNumber: 45,
            },
            {
              startTime: new Date('2023-02-14T13:38:45Z'),
              endTime: new Date('2023-02-14T13:38:46Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:46Z'),
              endTime: new Date('2023-02-14T13:38:47Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:47Z'),
              endTime: new Date('2023-02-14T13:38:48Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:48Z'),
              endTime: new Date('2023-02-14T13:38:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:49Z'),
              endTime: new Date('2023-02-14T13:38:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:50Z'),
              endTime: new Date('2023-02-14T13:38:51Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:51Z'),
              endTime: new Date('2023-02-14T13:38:52Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:38:52Z'),
              endTime: new Date('2023-02-14T13:38:53Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:38:53Z'),
              endTime: new Date('2023-02-14T13:38:54Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:38:54Z'),
              endTime: new Date('2023-02-14T13:38:55Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:55Z'),
              endTime: new Date('2023-02-14T13:38:56Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:56Z'),
              endTime: new Date('2023-02-14T13:38:57Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:57Z'),
              endTime: new Date('2023-02-14T13:38:58Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:38:58Z'),
              endTime: new Date('2023-02-14T13:38:59Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:38:59Z'),
              endTime: new Date('2023-02-14T13:39:00Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:39:00Z'),
              endTime: new Date('2023-02-14T13:39:01Z'),
              valueState: null,
              valueNumber: 47,
            },
            {
              startTime: new Date('2023-02-14T13:39:01Z'),
              endTime: new Date('2023-02-14T13:39:02Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:39:02Z'),
              endTime: new Date('2023-02-14T13:39:03Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:39:03Z'),
              endTime: new Date('2023-02-14T13:39:04Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:39:04Z'),
              endTime: new Date('2023-02-14T13:39:05Z'),
              valueState: null,
              valueNumber: 2,
            },
            {
              startTime: new Date('2023-02-14T13:39:05Z'),
              endTime: new Date('2023-02-14T13:39:06Z'),
              valueState: null,
              valueNumber: 6,
            },
            {
              startTime: new Date('2023-02-14T13:39:06Z'),
              endTime: new Date('2023-02-14T13:39:07Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:39:07Z'),
              endTime: new Date('2023-02-14T13:39:09Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:39:09Z'),
              endTime: new Date('2023-02-14T13:39:10Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:39:10Z'),
              endTime: new Date('2023-02-14T13:39:11Z'),
              valueState: null,
              valueNumber: 50,
            },
            {
              startTime: new Date('2023-02-14T13:39:11Z'),
              endTime: new Date('2023-02-14T13:39:12Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:39:12Z'),
              endTime: new Date('2023-02-14T13:39:13Z'),
              valueState: null,
              valueNumber: 48,
            },
            {
              startTime: new Date('2023-02-14T13:39:13Z'),
              endTime: new Date('2023-02-14T13:39:14Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:39:14Z'),
              endTime: new Date('2023-02-14T13:39:15Z'),
              valueState: null,
              valueNumber: 40,
            },
            {
              startTime: new Date('2023-02-14T13:39:15Z'),
              endTime: new Date('2023-02-14T13:39:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:39:16Z'),
              endTime: new Date('2023-02-14T13:39:17Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:39:17Z'),
              endTime: new Date('2023-02-14T13:39:18Z'),
              valueState: null,
              valueNumber: 15,
            },
            {
              startTime: new Date('2023-02-14T13:39:18Z'),
              endTime: new Date('2023-02-14T13:39:19Z'),
              valueState: null,
              valueNumber: 39,
            },
            {
              startTime: new Date('2023-02-14T13:39:19Z'),
              endTime: new Date('2023-02-14T13:40:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:38Z'),
              endTime: new Date('2023-02-14T13:40:39Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:39Z'),
              endTime: new Date('2023-02-14T13:40:40Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:40:40Z'),
              endTime: new Date('2023-02-14T13:40:41Z'),
              valueState: null,
              valueNumber: 52,
            },
            {
              startTime: new Date('2023-02-14T13:40:41Z'),
              endTime: new Date('2023-02-14T13:40:42Z'),
              valueState: null,
              valueNumber: 53,
            },
            {
              startTime: new Date('2023-02-14T13:40:42Z'),
              endTime: new Date('2023-02-14T13:40:43Z'),
              valueState: null,
              valueNumber: 53,
            },
            {
              startTime: new Date('2023-02-14T13:40:43Z'),
              endTime: new Date('2023-02-14T13:40:44Z'),
              valueState: null,
              valueNumber: 27,
            },
            {
              startTime: new Date('2023-02-14T13:40:44Z'),
              endTime: new Date('2023-02-14T13:40:45Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:45Z'),
              endTime: new Date('2023-02-14T13:40:46Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:46Z'),
              endTime: new Date('2023-02-14T13:40:47Z'),
              valueState: null,
              valueNumber: 46,
            },
            {
              startTime: new Date('2023-02-14T13:40:47Z'),
              endTime: new Date('2023-02-14T13:40:48Z'),
              valueState: null,
              valueNumber: 54,
            },
            {
              startTime: new Date('2023-02-14T13:40:48Z'),
              endTime: new Date('2023-02-14T13:40:49Z'),
              valueState: null,
              valueNumber: 53,
            },
            {
              startTime: new Date('2023-02-14T13:40:49Z'),
              endTime: new Date('2023-02-14T13:40:50Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:40:50Z'),
              endTime: new Date('2023-02-14T13:40:51Z'),
              valueState: null,
              valueNumber: 3,
            },
            {
              startTime: new Date('2023-02-14T13:40:51Z'),
              endTime: new Date('2023-02-14T13:40:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:52Z'),
              endTime: new Date('2023-02-14T13:40:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:53Z'),
              endTime: new Date('2023-02-14T13:40:54Z'),
              valueState: null,
              valueNumber: 34,
            },
            {
              startTime: new Date('2023-02-14T13:40:54Z'),
              endTime: new Date('2023-02-14T13:40:55Z'),
              valueState: null,
              valueNumber: 34,
            },
            {
              startTime: new Date('2023-02-14T13:40:55Z'),
              endTime: new Date('2023-02-14T13:40:56Z'),
              valueState: null,
              valueNumber: 79,
            },
            {
              startTime: new Date('2023-02-14T13:40:56Z'),
              endTime: new Date('2023-02-14T13:40:57Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:40:57Z'),
              endTime: new Date('2023-02-14T13:40:58Z'),
              valueState: null,
              valueNumber: 82,
            },
            {
              startTime: new Date('2023-02-14T13:40:58Z'),
              endTime: new Date('2023-02-14T13:40:59Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:40:59Z'),
              endTime: new Date('2023-02-14T13:41:00Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:41:00Z'),
              endTime: new Date('2023-02-14T13:41:01Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:41:01Z'),
              endTime: new Date('2023-02-14T13:41:02Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:41:02Z'),
              endTime: new Date('2023-02-14T13:41:03Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:41:03Z'),
              endTime: new Date('2023-02-14T13:41:04Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:41:04Z'),
              endTime: new Date('2023-02-14T13:41:05Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:41:05Z'),
              endTime: new Date('2023-02-14T13:41:06Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:41:06Z'),
              endTime: new Date('2023-02-14T13:41:07Z'),
              valueState: null,
              valueNumber: 56,
            },
            {
              startTime: new Date('2023-02-14T13:41:07Z'),
              endTime: new Date('2023-02-14T13:41:08Z'),
              valueState: null,
              valueNumber: 18,
            },
            {
              startTime: new Date('2023-02-14T13:41:08Z'),
              endTime: new Date('2023-02-14T13:41:09Z'),
              valueState: null,
              valueNumber: 18,
            },
            {
              startTime: new Date('2023-02-14T13:41:09Z'),
              endTime: new Date('2023-02-14T13:41:10Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:41:10Z'),
              endTime: new Date('2023-02-14T13:41:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:41:11Z'),
              endTime: new Date('2023-02-14T13:41:12Z'),
              valueState: null,
              valueNumber: 34,
            },
            {
              startTime: new Date('2023-02-14T13:41:12Z'),
              endTime: new Date('2023-02-14T13:41:13Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:41:13Z'),
              endTime: new Date('2023-02-14T13:41:14Z'),
              valueState: null,
              valueNumber: 75,
            },
            {
              startTime: new Date('2023-02-14T13:41:14Z'),
              endTime: new Date('2023-02-14T13:41:15Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:41:15Z'),
              endTime: new Date('2023-02-14T13:41:16Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:41:16Z'),
              endTime: new Date('2023-02-14T13:41:18Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:41:18Z'),
              endTime: new Date('2023-02-14T13:41:19Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:41:19Z'),
              endTime: new Date('2023-02-14T13:41:20Z'),
              valueState: null,
              valueNumber: 85,
            },
            {
              startTime: new Date('2023-02-14T13:41:20Z'),
              endTime: new Date('2023-02-14T13:41:21Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:41:21Z'),
              endTime: new Date('2023-02-14T13:41:22Z'),
              valueState: null,
              valueNumber: 87,
            },
            {
              startTime: new Date('2023-02-14T13:41:22Z'),
              endTime: new Date('2023-02-14T13:41:23Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:41:23Z'),
              endTime: new Date('2023-02-14T13:41:24Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:41:24Z'),
              endTime: new Date('2023-02-14T13:41:25Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:41:25Z'),
              endTime: new Date('2023-02-14T13:41:26Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:41:26Z'),
              endTime: new Date('2023-02-14T13:41:27Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:41:27Z'),
              endTime: new Date('2023-02-14T13:41:28Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:41:28Z'),
              endTime: new Date('2023-02-14T13:41:29Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:41:29Z'),
              endTime: new Date('2023-02-14T13:41:30Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:41:30Z'),
              endTime: new Date('2023-02-14T13:41:31Z'),
              valueState: null,
              valueNumber: 91,
            },
            {
              startTime: new Date('2023-02-14T13:41:31Z'),
              endTime: new Date('2023-02-14T13:41:32Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:41:32Z'),
              endTime: new Date('2023-02-14T13:41:33Z'),
              valueState: null,
              valueNumber: 94,
            },
            {
              startTime: new Date('2023-02-14T13:41:33Z'),
              endTime: new Date('2023-02-14T13:41:34Z'),
              valueState: null,
              valueNumber: 111,
            },
            {
              startTime: new Date('2023-02-14T13:41:34Z'),
              endTime: new Date('2023-02-14T13:41:35Z'),
              valueState: null,
              valueNumber: 111,
            },
            {
              startTime: new Date('2023-02-14T13:41:35Z'),
              endTime: new Date('2023-02-14T13:41:36Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:41:36Z'),
              endTime: new Date('2023-02-14T13:41:37Z'),
              valueState: null,
              valueNumber: 134,
            },
            {
              startTime: new Date('2023-02-14T13:41:37Z'),
              endTime: new Date('2023-02-14T13:41:40Z'),
              valueState: null,
              valueNumber: 132,
            },
            {
              startTime: new Date('2023-02-14T13:41:40Z'),
              endTime: new Date('2023-02-14T13:41:41Z'),
              valueState: null,
              valueNumber: 132,
            },
            {
              startTime: new Date('2023-02-14T13:41:41Z'),
              endTime: new Date('2023-02-14T13:41:43Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:41:43Z'),
              endTime: new Date('2023-02-14T13:41:44Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:41:44Z'),
              endTime: new Date('2023-02-14T13:41:45Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:41:45Z'),
              endTime: new Date('2023-02-14T13:41:46Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:41:46Z'),
              endTime: new Date('2023-02-14T13:41:47Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:41:47Z'),
              endTime: new Date('2023-02-14T13:41:48Z'),
              valueState: null,
              valueNumber: 118,
            },
            {
              startTime: new Date('2023-02-14T13:41:48Z'),
              endTime: new Date('2023-02-14T13:41:49Z'),
              valueState: null,
              valueNumber: 118,
            },
            {
              startTime: new Date('2023-02-14T13:41:49Z'),
              endTime: new Date('2023-02-14T13:41:50Z'),
              valueState: null,
              valueNumber: 96,
            },
            {
              startTime: new Date('2023-02-14T13:41:50Z'),
              endTime: new Date('2023-02-14T13:41:51Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:41:51Z'),
              endTime: new Date('2023-02-14T13:41:54Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:41:54Z'),
              endTime: new Date('2023-02-14T13:41:55Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:41:55Z'),
              endTime: new Date('2023-02-14T13:41:56Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:41:56Z'),
              endTime: new Date('2023-02-14T13:41:57Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:41:57Z'),
              endTime: new Date('2023-02-14T13:42:03Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:42:03Z'),
              endTime: new Date('2023-02-14T13:42:04Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:42:04Z'),
              endTime: new Date('2023-02-14T13:42:05Z'),
              valueState: null,
              valueNumber: 107,
            },
            {
              startTime: new Date('2023-02-14T13:42:05Z'),
              endTime: new Date('2023-02-14T13:42:06Z'),
              valueState: null,
              valueNumber: 125,
            },
            {
              startTime: new Date('2023-02-14T13:42:06Z'),
              endTime: new Date('2023-02-14T13:42:07Z'),
              valueState: null,
              valueNumber: 145,
            },
            {
              startTime: new Date('2023-02-14T13:42:07Z'),
              endTime: new Date('2023-02-14T13:42:09Z'),
              valueState: null,
              valueNumber: 152,
            },
            {
              startTime: new Date('2023-02-14T13:42:09Z'),
              endTime: new Date('2023-02-14T13:42:10Z'),
              valueState: null,
              valueNumber: 152,
            },
            {
              startTime: new Date('2023-02-14T13:42:10Z'),
              endTime: new Date('2023-02-14T13:42:11Z'),
              valueState: null,
              valueNumber: 151,
            },
            {
              startTime: new Date('2023-02-14T13:42:11Z'),
              endTime: new Date('2023-02-14T13:42:12Z'),
              valueState: null,
              valueNumber: 150,
            },
            {
              startTime: new Date('2023-02-14T13:42:12Z'),
              endTime: new Date('2023-02-14T13:42:13Z'),
              valueState: null,
              valueNumber: 156,
            },
            {
              startTime: new Date('2023-02-14T13:42:13Z'),
              endTime: new Date('2023-02-14T13:42:14Z'),
              valueState: null,
              valueNumber: 200,
            },
            {
              startTime: new Date('2023-02-14T13:42:14Z'),
              endTime: new Date('2023-02-14T13:42:15Z'),
              valueState: null,
              valueNumber: 200,
            },
            {
              startTime: new Date('2023-02-14T13:42:15Z'),
              endTime: new Date('2023-02-14T13:42:16Z'),
              valueState: null,
              valueNumber: 240,
            },
            {
              startTime: new Date('2023-02-14T13:42:16Z'),
              endTime: new Date('2023-02-14T13:42:17Z'),
              valueState: null,
              valueNumber: 248,
            },
            {
              startTime: new Date('2023-02-14T13:42:17Z'),
              endTime: new Date('2023-02-14T13:42:18Z'),
              valueState: null,
              valueNumber: 254,
            },
            {
              startTime: new Date('2023-02-14T13:42:18Z'),
              endTime: new Date('2023-02-14T13:42:19Z'),
              valueState: null,
              valueNumber: 254,
            },
            {
              startTime: new Date('2023-02-14T13:42:19Z'),
              endTime: new Date('2023-02-14T13:42:20Z'),
              valueState: null,
              valueNumber: 259,
            },
            {
              startTime: new Date('2023-02-14T13:42:20Z'),
              endTime: new Date('2023-02-14T13:42:21Z'),
              valueState: null,
              valueNumber: 265,
            },
            {
              startTime: new Date('2023-02-14T13:42:21Z'),
              endTime: new Date('2023-02-14T13:42:22Z'),
              valueState: null,
              valueNumber: 271,
            },
            {
              startTime: new Date('2023-02-14T13:42:22Z'),
              endTime: new Date('2023-02-14T13:42:23Z'),
              valueState: null,
              valueNumber: 271,
            },
            {
              startTime: new Date('2023-02-14T13:42:23Z'),
              endTime: new Date('2023-02-14T13:42:24Z'),
              valueState: null,
              valueNumber: 276,
            },
            {
              startTime: new Date('2023-02-14T13:42:24Z'),
              endTime: new Date('2023-02-14T13:42:25Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:42:25Z'),
              endTime: new Date('2023-02-14T13:42:26Z'),
              valueState: null,
              valueNumber: 283,
            },
            {
              startTime: new Date('2023-02-14T13:42:26Z'),
              endTime: new Date('2023-02-14T13:42:27Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:42:27Z'),
              endTime: new Date('2023-02-14T13:42:28Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:42:28Z'),
              endTime: new Date('2023-02-14T13:42:29Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:42:29Z'),
              endTime: new Date('2023-02-14T13:42:30Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:42:30Z'),
              endTime: new Date('2023-02-14T13:42:31Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:42:31Z'),
              endTime: new Date('2023-02-14T13:42:32Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:42:32Z'),
              endTime: new Date('2023-02-14T13:42:33Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:42:33Z'),
              endTime: new Date('2023-02-14T13:42:34Z'),
              valueState: null,
              valueNumber: 281,
            },
            {
              startTime: new Date('2023-02-14T13:42:34Z'),
              endTime: new Date('2023-02-14T13:42:35Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:42:35Z'),
              endTime: new Date('2023-02-14T13:42:36Z'),
              valueState: null,
              valueNumber: 286,
            },
            {
              startTime: new Date('2023-02-14T13:42:36Z'),
              endTime: new Date('2023-02-14T13:42:37Z'),
              valueState: null,
              valueNumber: 293,
            },
            {
              startTime: new Date('2023-02-14T13:42:37Z'),
              endTime: new Date('2023-02-14T13:42:38Z'),
              valueState: null,
              valueNumber: 300,
            },
            {
              startTime: new Date('2023-02-14T13:42:38Z'),
              endTime: new Date('2023-02-14T13:42:39Z'),
              valueState: null,
              valueNumber: 300,
            },
            {
              startTime: new Date('2023-02-14T13:42:39Z'),
              endTime: new Date('2023-02-14T13:42:40Z'),
              valueState: null,
              valueNumber: 306,
            },
            {
              startTime: new Date('2023-02-14T13:42:40Z'),
              endTime: new Date('2023-02-14T13:42:41Z'),
              valueState: null,
              valueNumber: 311,
            },
            {
              startTime: new Date('2023-02-14T13:42:41Z'),
              endTime: new Date('2023-02-14T13:42:42Z'),
              valueState: null,
              valueNumber: 317,
            },
            {
              startTime: new Date('2023-02-14T13:42:42Z'),
              endTime: new Date('2023-02-14T13:42:43Z'),
              valueState: null,
              valueNumber: 324,
            },
            {
              startTime: new Date('2023-02-14T13:42:43Z'),
              endTime: new Date('2023-02-14T13:42:44Z'),
              valueState: null,
              valueNumber: 329,
            },
            {
              startTime: new Date('2023-02-14T13:42:44Z'),
              endTime: new Date('2023-02-14T13:42:45Z'),
              valueState: null,
              valueNumber: 329,
            },
            {
              startTime: new Date('2023-02-14T13:42:45Z'),
              endTime: new Date('2023-02-14T13:42:46Z'),
              valueState: null,
              valueNumber: 335,
            },
            {
              startTime: new Date('2023-02-14T13:42:46Z'),
              endTime: new Date('2023-02-14T13:42:47Z'),
              valueState: null,
              valueNumber: 342,
            },
            {
              startTime: new Date('2023-02-14T13:42:47Z'),
              endTime: new Date('2023-02-14T13:42:48Z'),
              valueState: null,
              valueNumber: 348,
            },
            {
              startTime: new Date('2023-02-14T13:42:48Z'),
              endTime: new Date('2023-02-14T13:42:49Z'),
              valueState: null,
              valueNumber: 348,
            },
            {
              startTime: new Date('2023-02-14T13:42:49Z'),
              endTime: new Date('2023-02-14T13:42:50Z'),
              valueState: null,
              valueNumber: 354,
            },
            {
              startTime: new Date('2023-02-14T13:42:50Z'),
              endTime: new Date('2023-02-14T13:42:51Z'),
              valueState: null,
              valueNumber: 359,
            },
            {
              startTime: new Date('2023-02-14T13:42:51Z'),
              endTime: new Date('2023-02-14T13:42:52Z'),
              valueState: null,
              valueNumber: 365,
            },
            {
              startTime: new Date('2023-02-14T13:42:52Z'),
              endTime: new Date('2023-02-14T13:42:53Z'),
              valueState: null,
              valueNumber: 372,
            },
            {
              startTime: new Date('2023-02-14T13:42:53Z'),
              endTime: new Date('2023-02-14T13:42:54Z'),
              valueState: null,
              valueNumber: 372,
            },
            {
              startTime: new Date('2023-02-14T13:42:54Z'),
              endTime: new Date('2023-02-14T13:42:55Z'),
              valueState: null,
              valueNumber: 378,
            },
            {
              startTime: new Date('2023-02-14T13:42:55Z'),
              endTime: new Date('2023-02-14T13:42:56Z'),
              valueState: null,
              valueNumber: 383,
            },
            {
              startTime: new Date('2023-02-14T13:42:56Z'),
              endTime: new Date('2023-02-14T13:42:57Z'),
              valueState: null,
              valueNumber: 390,
            },
            {
              startTime: new Date('2023-02-14T13:42:57Z'),
              endTime: new Date('2023-02-14T13:42:58Z'),
              valueState: null,
              valueNumber: 396,
            },
            {
              startTime: new Date('2023-02-14T13:42:58Z'),
              endTime: new Date('2023-02-14T13:42:59Z'),
              valueState: null,
              valueNumber: 402,
            },
            {
              startTime: new Date('2023-02-14T13:42:59Z'),
              endTime: new Date('2023-02-14T13:43:00Z'),
              valueState: null,
              valueNumber: 402,
            },
            {
              startTime: new Date('2023-02-14T13:43:00Z'),
              endTime: new Date('2023-02-14T13:43:01Z'),
              valueState: null,
              valueNumber: 407,
            },
            {
              startTime: new Date('2023-02-14T13:43:01Z'),
              endTime: new Date('2023-02-14T13:43:02Z'),
              valueState: null,
              valueNumber: 413,
            },
            {
              startTime: new Date('2023-02-14T13:43:02Z'),
              endTime: new Date('2023-02-14T13:43:03Z'),
              valueState: null,
              valueNumber: 420,
            },
            {
              startTime: new Date('2023-02-14T13:43:03Z'),
              endTime: new Date('2023-02-14T13:43:04Z'),
              valueState: null,
              valueNumber: 426,
            },
            {
              startTime: new Date('2023-02-14T13:43:04Z'),
              endTime: new Date('2023-02-14T13:43:05Z'),
              valueState: null,
              valueNumber: 432,
            },
            {
              startTime: new Date('2023-02-14T13:43:05Z'),
              endTime: new Date('2023-02-14T13:43:06Z'),
              valueState: null,
              valueNumber: 432,
            },
            {
              startTime: new Date('2023-02-14T13:43:06Z'),
              endTime: new Date('2023-02-14T13:43:07Z'),
              valueState: null,
              valueNumber: 438,
            },
            {
              startTime: new Date('2023-02-14T13:43:07Z'),
              endTime: new Date('2023-02-14T13:43:08Z'),
              valueState: null,
              valueNumber: 444,
            },
            {
              startTime: new Date('2023-02-14T13:43:08Z'),
              endTime: new Date('2023-02-14T13:43:09Z'),
              valueState: null,
              valueNumber: 450,
            },
            {
              startTime: new Date('2023-02-14T13:43:09Z'),
              endTime: new Date('2023-02-14T13:43:10Z'),
              valueState: null,
              valueNumber: 450,
            },
            {
              startTime: new Date('2023-02-14T13:43:10Z'),
              endTime: new Date('2023-02-14T13:43:11Z'),
              valueState: null,
              valueNumber: 449,
            },
            {
              startTime: new Date('2023-02-14T13:43:11Z'),
              endTime: new Date('2023-02-14T13:43:12Z'),
              valueState: null,
              valueNumber: 414,
            },
            {
              startTime: new Date('2023-02-14T13:43:12Z'),
              endTime: new Date('2023-02-14T13:43:13Z'),
              valueState: null,
              valueNumber: 378,
            },
            {
              startTime: new Date('2023-02-14T13:43:13Z'),
              endTime: new Date('2023-02-14T13:43:14Z'),
              valueState: null,
              valueNumber: 341,
            },
            {
              startTime: new Date('2023-02-14T13:43:14Z'),
              endTime: new Date('2023-02-14T13:43:15Z'),
              valueState: null,
              valueNumber: 301,
            },
            {
              startTime: new Date('2023-02-14T13:43:15Z'),
              endTime: new Date('2023-02-14T13:43:16Z'),
              valueState: null,
              valueNumber: 301,
            },
            {
              startTime: new Date('2023-02-14T13:43:16Z'),
              endTime: new Date('2023-02-14T13:43:17Z'),
              valueState: null,
              valueNumber: 258,
            },
            {
              startTime: new Date('2023-02-14T13:43:17Z'),
              endTime: new Date('2023-02-14T13:43:18Z'),
              valueState: null,
              valueNumber: 209,
            },
            {
              startTime: new Date('2023-02-14T13:43:18Z'),
              endTime: new Date('2023-02-14T13:43:19Z'),
              valueState: null,
              valueNumber: 157,
            },
            {
              startTime: new Date('2023-02-14T13:43:19Z'),
              endTime: new Date('2023-02-14T13:43:20Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:43:20Z'),
              endTime: new Date('2023-02-14T13:43:21Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:43:21Z'),
              endTime: new Date('2023-02-14T13:43:22Z'),
              valueState: null,
              valueNumber: 88,
            },
            {
              startTime: new Date('2023-02-14T13:43:22Z'),
              endTime: new Date('2023-02-14T13:43:23Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:43:23Z'),
              endTime: new Date('2023-02-14T13:43:24Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:43:24Z'),
              endTime: new Date('2023-02-14T13:43:25Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:43:25Z'),
              endTime: new Date('2023-02-14T13:43:28Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:43:28Z'),
              endTime: new Date('2023-02-14T13:43:29Z'),
              valueState: null,
              valueNumber: 89,
            },
            {
              startTime: new Date('2023-02-14T13:43:29Z'),
              endTime: new Date('2023-02-14T13:43:30Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:43:30Z'),
              endTime: new Date('2023-02-14T13:43:31Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:43:31Z'),
              endTime: new Date('2023-02-14T13:43:32Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:43:32Z'),
              endTime: new Date('2023-02-14T13:43:33Z'),
              valueState: null,
              valueNumber: 114,
            },
            {
              startTime: new Date('2023-02-14T13:43:33Z'),
              endTime: new Date('2023-02-14T13:43:34Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:43:34Z'),
              endTime: new Date('2023-02-14T13:43:36Z'),
              valueState: null,
              valueNumber: 132,
            },
            {
              startTime: new Date('2023-02-14T13:43:36Z'),
              endTime: new Date('2023-02-14T13:43:37Z'),
              valueState: null,
              valueNumber: 132,
            },
            {
              startTime: new Date('2023-02-14T13:43:37Z'),
              endTime: new Date('2023-02-14T13:43:38Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:43:38Z'),
              endTime: new Date('2023-02-14T13:43:39Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:43:39Z'),
              endTime: new Date('2023-02-14T13:43:40Z'),
              valueState: null,
              valueNumber: 131,
            },
            {
              startTime: new Date('2023-02-14T13:43:40Z'),
              endTime: new Date('2023-02-14T13:43:43Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:43:43Z'),
              endTime: new Date('2023-02-14T13:43:44Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:43:44Z'),
              endTime: new Date('2023-02-14T13:43:45Z'),
              valueState: null,
              valueNumber: 129,
            },
            {
              startTime: new Date('2023-02-14T13:43:45Z'),
              endTime: new Date('2023-02-14T13:43:46Z'),
              valueState: null,
              valueNumber: 107,
            },
            {
              startTime: new Date('2023-02-14T13:43:46Z'),
              endTime: new Date('2023-02-14T13:43:47Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:43:47Z'),
              endTime: new Date('2023-02-14T13:43:48Z'),
              valueState: null,
              valueNumber: 97,
            },
            {
              startTime: new Date('2023-02-14T13:43:48Z'),
              endTime: new Date('2023-02-14T13:43:49Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:43:49Z'),
              endTime: new Date('2023-02-14T13:43:50Z'),
              valueState: null,
              valueNumber: 98,
            },
            {
              startTime: new Date('2023-02-14T13:43:50Z'),
              endTime: new Date('2023-02-14T13:43:56Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:43:56Z'),
              endTime: new Date('2023-02-14T13:43:57Z'),
              valueState: null,
              valueNumber: 99,
            },
            {
              startTime: new Date('2023-02-14T13:43:57Z'),
              endTime: new Date('2023-02-14T13:44:07Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:44:07Z'),
              endTime: new Date('2023-02-14T13:44:08Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:44:08Z'),
              endTime: new Date('2023-02-14T13:44:09Z'),
              valueState: null,
              valueNumber: 119,
            },
            {
              startTime: new Date('2023-02-14T13:44:09Z'),
              endTime: new Date('2023-02-14T13:44:10Z'),
              valueState: null,
              valueNumber: 170,
            },
            {
              startTime: new Date('2023-02-14T13:44:10Z'),
              endTime: new Date('2023-02-14T13:44:11Z'),
              valueState: null,
              valueNumber: 220,
            },
            {
              startTime: new Date('2023-02-14T13:44:11Z'),
              endTime: new Date('2023-02-14T13:44:12Z'),
              valueState: null,
              valueNumber: 270,
            },
            {
              startTime: new Date('2023-02-14T13:44:12Z'),
              endTime: new Date('2023-02-14T13:44:13Z'),
              valueState: null,
              valueNumber: 322,
            },
            {
              startTime: new Date('2023-02-14T13:44:13Z'),
              endTime: new Date('2023-02-14T13:44:14Z'),
              valueState: null,
              valueNumber: 322,
            },
            {
              startTime: new Date('2023-02-14T13:44:14Z'),
              endTime: new Date('2023-02-14T13:44:15Z'),
              valueState: null,
              valueNumber: 370,
            },
            {
              startTime: new Date('2023-02-14T13:44:15Z'),
              endTime: new Date('2023-02-14T13:44:16Z'),
              valueState: null,
              valueNumber: 419,
            },
            {
              startTime: new Date('2023-02-14T13:44:16Z'),
              endTime: new Date('2023-02-14T13:44:17Z'),
              valueState: null,
              valueNumber: 419,
            },
            {
              startTime: new Date('2023-02-14T13:44:17Z'),
              endTime: new Date('2023-02-14T13:44:18Z'),
              valueState: null,
              valueNumber: 468,
            },
            {
              startTime: new Date('2023-02-14T13:44:18Z'),
              endTime: new Date('2023-02-14T13:44:19Z'),
              valueState: null,
              valueNumber: 507,
            },
            {
              startTime: new Date('2023-02-14T13:44:19Z'),
              endTime: new Date('2023-02-14T13:44:20Z'),
              valueState: null,
              valueNumber: 509,
            },
            {
              startTime: new Date('2023-02-14T13:44:20Z'),
              endTime: new Date('2023-02-14T13:44:21Z'),
              valueState: null,
              valueNumber: 507,
            },
            {
              startTime: new Date('2023-02-14T13:44:21Z'),
              endTime: new Date('2023-02-14T13:44:22Z'),
              valueState: null,
              valueNumber: 505,
            },
            {
              startTime: new Date('2023-02-14T13:44:22Z'),
              endTime: new Date('2023-02-14T13:44:23Z'),
              valueState: null,
              valueNumber: 505,
            },
            {
              startTime: new Date('2023-02-14T13:44:23Z'),
              endTime: new Date('2023-02-14T13:44:24Z'),
              valueState: null,
              valueNumber: 503,
            },
            {
              startTime: new Date('2023-02-14T13:44:24Z'),
              endTime: new Date('2023-02-14T13:44:25Z'),
              valueState: null,
              valueNumber: 503,
            },
            {
              startTime: new Date('2023-02-14T13:44:25Z'),
              endTime: new Date('2023-02-14T13:44:26Z'),
              valueState: null,
              valueNumber: 502,
            },
            {
              startTime: new Date('2023-02-14T13:44:26Z'),
              endTime: new Date('2023-02-14T13:44:27Z'),
              valueState: null,
              valueNumber: 514,
            },
            {
              startTime: new Date('2023-02-14T13:44:27Z'),
              endTime: new Date('2023-02-14T13:44:28Z'),
              valueState: null,
              valueNumber: 514,
            },
            {
              startTime: new Date('2023-02-14T13:44:28Z'),
              endTime: new Date('2023-02-14T13:44:29Z'),
              valueState: null,
              valueNumber: 563,
            },
            {
              startTime: new Date('2023-02-14T13:44:29Z'),
              endTime: new Date('2023-02-14T13:44:30Z'),
              valueState: null,
              valueNumber: 614,
            },
            {
              startTime: new Date('2023-02-14T13:44:30Z'),
              endTime: new Date('2023-02-14T13:44:31Z'),
              valueState: null,
              valueNumber: 665,
            },
            {
              startTime: new Date('2023-02-14T13:44:31Z'),
              endTime: new Date('2023-02-14T13:44:32Z'),
              valueState: null,
              valueNumber: 704,
            },
            {
              startTime: new Date('2023-02-14T13:44:32Z'),
              endTime: new Date('2023-02-14T13:44:33Z'),
              valueState: null,
              valueNumber: 704,
            },
            {
              startTime: new Date('2023-02-14T13:44:33Z'),
              endTime: new Date('2023-02-14T13:44:34Z'),
              valueState: null,
              valueNumber: 706,
            },
            {
              startTime: new Date('2023-02-14T13:44:34Z'),
              endTime: new Date('2023-02-14T13:44:35Z'),
              valueState: null,
              valueNumber: 704,
            },
            {
              startTime: new Date('2023-02-14T13:44:35Z'),
              endTime: new Date('2023-02-14T13:44:36Z'),
              valueState: null,
              valueNumber: 703,
            },
            {
              startTime: new Date('2023-02-14T13:44:36Z'),
              endTime: new Date('2023-02-14T13:44:37Z'),
              valueState: null,
              valueNumber: 702,
            },
            {
              startTime: new Date('2023-02-14T13:44:37Z'),
              endTime: new Date('2023-02-14T13:44:39Z'),
              valueState: null,
              valueNumber: 701,
            },
            {
              startTime: new Date('2023-02-14T13:44:39Z'),
              endTime: new Date('2023-02-14T13:44:40Z'),
              valueState: null,
              valueNumber: 701,
            },
            {
              startTime: new Date('2023-02-14T13:44:40Z'),
              endTime: new Date('2023-02-14T13:44:41Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:44:41Z'),
              endTime: new Date('2023-02-14T13:44:42Z'),
              valueState: null,
              valueNumber: 701,
            },
            {
              startTime: new Date('2023-02-14T13:44:42Z'),
              endTime: new Date('2023-02-14T13:44:43Z'),
              valueState: null,
              valueNumber: 701,
            },
            {
              startTime: new Date('2023-02-14T13:44:43Z'),
              endTime: new Date('2023-02-14T13:44:44Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:44:44Z'),
              endTime: new Date('2023-02-14T13:44:45Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:44:45Z'),
              endTime: new Date('2023-02-14T13:44:46Z'),
              valueState: null,
              valueNumber: 731,
            },
            {
              startTime: new Date('2023-02-14T13:44:46Z'),
              endTime: new Date('2023-02-14T13:44:47Z'),
              valueState: null,
              valueNumber: 782,
            },
            {
              startTime: new Date('2023-02-14T13:44:47Z'),
              endTime: new Date('2023-02-14T13:44:48Z'),
              valueState: null,
              valueNumber: 832,
            },
            {
              startTime: new Date('2023-02-14T13:44:48Z'),
              endTime: new Date('2023-02-14T13:44:49Z'),
              valueState: null,
              valueNumber: 880,
            },
            {
              startTime: new Date('2023-02-14T13:44:49Z'),
              endTime: new Date('2023-02-14T13:44:50Z'),
              valueState: null,
              valueNumber: 880,
            },
            {
              startTime: new Date('2023-02-14T13:44:50Z'),
              endTime: new Date('2023-02-14T13:44:51Z'),
              valueState: null,
              valueNumber: 930,
            },
            {
              startTime: new Date('2023-02-14T13:44:51Z'),
              endTime: new Date('2023-02-14T13:44:52Z'),
              valueState: null,
              valueNumber: 980,
            },
            {
              startTime: new Date('2023-02-14T13:44:52Z'),
              endTime: new Date('2023-02-14T13:44:53Z'),
              valueState: null,
              valueNumber: 1006,
            },
            {
              startTime: new Date('2023-02-14T13:44:53Z'),
              endTime: new Date('2023-02-14T13:44:54Z'),
              valueState: null,
              valueNumber: 1004,
            },
            {
              startTime: new Date('2023-02-14T13:44:54Z'),
              endTime: new Date('2023-02-14T13:44:55Z'),
              valueState: null,
              valueNumber: 1004,
            },
            {
              startTime: new Date('2023-02-14T13:44:55Z'),
              endTime: new Date('2023-02-14T13:44:56Z'),
              valueState: null,
              valueNumber: 1003,
            },
            {
              startTime: new Date('2023-02-14T13:44:56Z'),
              endTime: new Date('2023-02-14T13:44:57Z'),
              valueState: null,
              valueNumber: 1003,
            },
            {
              startTime: new Date('2023-02-14T13:44:57Z'),
              endTime: new Date('2023-02-14T13:44:58Z'),
              valueState: null,
              valueNumber: 1002,
            },
            {
              startTime: new Date('2023-02-14T13:44:58Z'),
              endTime: new Date('2023-02-14T13:45:01Z'),
              valueState: null,
              valueNumber: 1001,
            },
            {
              startTime: new Date('2023-02-14T13:45:01Z'),
              endTime: new Date('2023-02-14T13:45:02Z'),
              valueState: null,
              valueNumber: 1001,
            },
            {
              startTime: new Date('2023-02-14T13:45:02Z'),
              endTime: new Date('2023-02-14T13:45:08Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:45:08Z'),
              endTime: new Date('2023-02-14T13:45:09Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:45:09Z'),
              endTime: new Date('2023-02-14T13:45:10Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:45:10Z'),
              endTime: new Date('2023-02-14T13:45:11Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:45:11Z'),
              endTime: new Date('2023-02-14T13:45:13Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:45:13Z'),
              endTime: new Date('2023-02-14T13:45:14Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:45:14Z'),
              endTime: new Date('2023-02-14T13:45:16Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:45:16Z'),
              endTime: new Date('2023-02-14T13:45:17Z'),
              valueState: null,
              valueNumber: 999,
            },
            {
              startTime: new Date('2023-02-14T13:45:17Z'),
              endTime: new Date('2023-02-14T13:45:19Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:45:19Z'),
              endTime: new Date('2023-02-14T13:45:20Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:45:20Z'),
              endTime: new Date('2023-02-14T13:45:21Z'),
              valueState: null,
              valueNumber: 1030,
            },
            {
              startTime: new Date('2023-02-14T13:45:21Z'),
              endTime: new Date('2023-02-14T13:45:22Z'),
              valueState: null,
              valueNumber: 1068,
            },
            {
              startTime: new Date('2023-02-14T13:45:22Z'),
              endTime: new Date('2023-02-14T13:45:23Z'),
              valueState: null,
              valueNumber: 1068,
            },
            {
              startTime: new Date('2023-02-14T13:45:23Z'),
              endTime: new Date('2023-02-14T13:45:24Z'),
              valueState: null,
              valueNumber: 1105,
            },
            {
              startTime: new Date('2023-02-14T13:45:24Z'),
              endTime: new Date('2023-02-14T13:45:25Z'),
              valueState: null,
              valueNumber: 1142,
            },
            {
              startTime: new Date('2023-02-14T13:45:25Z'),
              endTime: new Date('2023-02-14T13:45:26Z'),
              valueState: null,
              valueNumber: 1179,
            },
            {
              startTime: new Date('2023-02-14T13:45:26Z'),
              endTime: new Date('2023-02-14T13:45:27Z'),
              valueState: null,
              valueNumber: 1179,
            },
            {
              startTime: new Date('2023-02-14T13:45:27Z'),
              endTime: new Date('2023-02-14T13:45:28Z'),
              valueState: null,
              valueNumber: 1216,
            },
            {
              startTime: new Date('2023-02-14T13:45:28Z'),
              endTime: new Date('2023-02-14T13:45:29Z'),
              valueState: null,
              valueNumber: 1252,
            },
            {
              startTime: new Date('2023-02-14T13:45:29Z'),
              endTime: new Date('2023-02-14T13:45:30Z'),
              valueState: null,
              valueNumber: 1288,
            },
            {
              startTime: new Date('2023-02-14T13:45:30Z'),
              endTime: new Date('2023-02-14T13:45:32Z'),
              valueState: null,
              valueNumber: 1303,
            },
            {
              startTime: new Date('2023-02-14T13:45:32Z'),
              endTime: new Date('2023-02-14T13:45:33Z'),
              valueState: null,
              valueNumber: 1303,
            },
            {
              startTime: new Date('2023-02-14T13:45:33Z'),
              endTime: new Date('2023-02-14T13:45:34Z'),
              valueState: null,
              valueNumber: 1302,
            },
            {
              startTime: new Date('2023-02-14T13:45:34Z'),
              endTime: new Date('2023-02-14T13:45:37Z'),
              valueState: null,
              valueNumber: 1301,
            },
            {
              startTime: new Date('2023-02-14T13:45:37Z'),
              endTime: new Date('2023-02-14T13:45:38Z'),
              valueState: null,
              valueNumber: 1301,
            },
            {
              startTime: new Date('2023-02-14T13:45:38Z'),
              endTime: new Date('2023-02-14T13:45:41Z'),
              valueState: null,
              valueNumber: 1300,
            },
            {
              startTime: new Date('2023-02-14T13:45:41Z'),
              endTime: new Date('2023-02-14T13:45:42Z'),
              valueState: null,
              valueNumber: 1300,
            },
            {
              startTime: new Date('2023-02-14T13:45:42Z'),
              endTime: new Date('2023-02-14T13:45:43Z'),
              valueState: null,
              valueNumber: 1299,
            },
            {
              startTime: new Date('2023-02-14T13:45:43Z'),
              endTime: new Date('2023-02-14T13:45:44Z'),
              valueState: null,
              valueNumber: 1299,
            },
            {
              startTime: new Date('2023-02-14T13:45:44Z'),
              endTime: new Date('2023-02-14T13:45:46Z'),
              valueState: null,
              valueNumber: 1300,
            },
            {
              startTime: new Date('2023-02-14T13:45:46Z'),
              endTime: new Date('2023-02-14T13:45:47Z'),
              valueState: null,
              valueNumber: 1300,
            },
            {
              startTime: new Date('2023-02-14T13:45:47Z'),
              endTime: new Date('2023-02-14T13:45:49Z'),
              valueState: null,
              valueNumber: 1299,
            },
            {
              startTime: new Date('2023-02-14T13:45:49Z'),
              endTime: new Date('2023-02-14T13:45:50Z'),
              valueState: null,
              valueNumber: 1299,
            },
            {
              startTime: new Date('2023-02-14T13:45:50Z'),
              endTime: new Date('2023-02-14T13:45:51Z'),
              valueState: null,
              valueNumber: 1300,
            },
            {
              startTime: new Date('2023-02-14T13:45:51Z'),
              endTime: new Date('2023-02-14T13:45:52Z'),
              valueState: null,
              valueNumber: 1299,
            },
            {
              startTime: new Date('2023-02-14T13:45:52Z'),
              endTime: new Date('2023-02-14T13:45:53Z'),
              valueState: null,
              valueNumber: 1299,
            },
            {
              startTime: new Date('2023-02-14T13:45:53Z'),
              endTime: new Date('2023-02-14T13:45:54Z'),
              valueState: null,
              valueNumber: 1300,
            },
            {
              startTime: new Date('2023-02-14T13:45:54Z'),
              endTime: new Date('2023-02-14T13:45:55Z'),
              valueState: null,
              valueNumber: 1300,
            },
            {
              startTime: new Date('2023-02-14T13:45:55Z'),
              endTime: new Date('2023-02-14T13:45:56Z'),
              valueState: null,
              valueNumber: 1301,
            },
            {
              startTime: new Date('2023-02-14T13:45:56Z'),
              endTime: new Date('2023-02-14T13:45:57Z'),
              valueState: null,
              valueNumber: 1315,
            },
            {
              startTime: new Date('2023-02-14T13:45:57Z'),
              endTime: new Date('2023-02-14T13:45:58Z'),
              valueState: null,
              valueNumber: 1326,
            },
            {
              startTime: new Date('2023-02-14T13:45:58Z'),
              endTime: new Date('2023-02-14T13:45:59Z'),
              valueState: null,
              valueNumber: 1338,
            },
            {
              startTime: new Date('2023-02-14T13:45:59Z'),
              endTime: new Date('2023-02-14T13:46:00Z'),
              valueState: null,
              valueNumber: 1351,
            },
            {
              startTime: new Date('2023-02-14T13:46:00Z'),
              endTime: new Date('2023-02-14T13:46:01Z'),
              valueState: null,
              valueNumber: 1351,
            },
            {
              startTime: new Date('2023-02-14T13:46:01Z'),
              endTime: new Date('2023-02-14T13:46:02Z'),
              valueState: null,
              valueNumber: 1364,
            },
            {
              startTime: new Date('2023-02-14T13:46:02Z'),
              endTime: new Date('2023-02-14T13:46:03Z'),
              valueState: null,
              valueNumber: 1375,
            },
            {
              startTime: new Date('2023-02-14T13:46:03Z'),
              endTime: new Date('2023-02-14T13:46:04Z'),
              valueState: null,
              valueNumber: 1375,
            },
            {
              startTime: new Date('2023-02-14T13:46:04Z'),
              endTime: new Date('2023-02-14T13:46:05Z'),
              valueState: null,
              valueNumber: 1387,
            },
            {
              startTime: new Date('2023-02-14T13:46:05Z'),
              endTime: new Date('2023-02-14T13:46:06Z'),
              valueState: null,
              valueNumber: 1399,
            },
            {
              startTime: new Date('2023-02-14T13:46:06Z'),
              endTime: new Date('2023-02-14T13:46:07Z'),
              valueState: null,
              valueNumber: 1412,
            },
            {
              startTime: new Date('2023-02-14T13:46:07Z'),
              endTime: new Date('2023-02-14T13:46:08Z'),
              valueState: null,
              valueNumber: 1424,
            },
            {
              startTime: new Date('2023-02-14T13:46:08Z'),
              endTime: new Date('2023-02-14T13:46:09Z'),
              valueState: null,
              valueNumber: 1435,
            },
            {
              startTime: new Date('2023-02-14T13:46:09Z'),
              endTime: new Date('2023-02-14T13:46:10Z'),
              valueState: null,
              valueNumber: 1435,
            },
            {
              startTime: new Date('2023-02-14T13:46:10Z'),
              endTime: new Date('2023-02-14T13:46:11Z'),
              valueState: null,
              valueNumber: 1448,
            },
            {
              startTime: new Date('2023-02-14T13:46:11Z'),
              endTime: new Date('2023-02-14T13:46:12Z'),
              valueState: null,
              valueNumber: 1461,
            },
            {
              startTime: new Date('2023-02-14T13:46:12Z'),
              endTime: new Date('2023-02-14T13:46:13Z'),
              valueState: null,
              valueNumber: 1472,
            },
            {
              startTime: new Date('2023-02-14T13:46:13Z'),
              endTime: new Date('2023-02-14T13:46:14Z'),
              valueState: null,
              valueNumber: 1484,
            },
            {
              startTime: new Date('2023-02-14T13:46:14Z'),
              endTime: new Date('2023-02-14T13:46:15Z'),
              valueState: null,
              valueNumber: 1496,
            },
            {
              startTime: new Date('2023-02-14T13:46:15Z'),
              endTime: new Date('2023-02-14T13:46:16Z'),
              valueState: null,
              valueNumber: 1496,
            },
            {
              startTime: new Date('2023-02-14T13:46:16Z'),
              endTime: new Date('2023-02-14T13:46:17Z'),
              valueState: null,
              valueNumber: 1500,
            },
            {
              startTime: new Date('2023-02-14T13:46:17Z'),
              endTime: new Date('2023-02-14T13:46:18Z'),
              valueState: null,
              valueNumber: 1501,
            },
            {
              startTime: new Date('2023-02-14T13:46:18Z'),
              endTime: new Date('2023-02-14T13:46:22Z'),
              valueState: null,
              valueNumber: 1500,
            },
            {
              startTime: new Date('2023-02-14T13:46:22Z'),
              endTime: new Date('2023-02-14T13:46:23Z'),
              valueState: null,
              valueNumber: 1500,
            },
            {
              startTime: new Date('2023-02-14T13:46:23Z'),
              endTime: new Date('2023-02-14T13:46:24Z'),
              valueState: null,
              valueNumber: 1499,
            },
            {
              startTime: new Date('2023-02-14T13:46:24Z'),
              endTime: new Date('2023-02-14T13:46:25Z'),
              valueState: null,
              valueNumber: 1500,
            },
            {
              startTime: new Date('2023-02-14T13:46:25Z'),
              endTime: new Date('2023-02-14T13:46:26Z'),
              valueState: null,
              valueNumber: 1500,
            },
            {
              startTime: new Date('2023-02-14T13:46:26Z'),
              endTime: new Date('2023-02-14T13:46:27Z'),
              valueState: null,
              valueNumber: 1501,
            },
            {
              startTime: new Date('2023-02-14T13:46:27Z'),
              endTime: new Date('2023-02-14T13:46:28Z'),
              valueState: null,
              valueNumber: 1503,
            },
            {
              startTime: new Date('2023-02-14T13:46:28Z'),
              endTime: new Date('2023-02-14T13:46:29Z'),
              valueState: null,
              valueNumber: 1506,
            },
            {
              startTime: new Date('2023-02-14T13:46:29Z'),
              endTime: new Date('2023-02-14T13:46:30Z'),
              valueState: null,
              valueNumber: 1508,
            },
            {
              startTime: new Date('2023-02-14T13:46:30Z'),
              endTime: new Date('2023-02-14T13:46:31Z'),
              valueState: null,
              valueNumber: 1511,
            },
            {
              startTime: new Date('2023-02-14T13:46:31Z'),
              endTime: new Date('2023-02-14T13:46:32Z'),
              valueState: null,
              valueNumber: 1511,
            },
            {
              startTime: new Date('2023-02-14T13:46:32Z'),
              endTime: new Date('2023-02-14T13:46:33Z'),
              valueState: null,
              valueNumber: 1513,
            },
            {
              startTime: new Date('2023-02-14T13:46:33Z'),
              endTime: new Date('2023-02-14T13:46:34Z'),
              valueState: null,
              valueNumber: 1516,
            },
            {
              startTime: new Date('2023-02-14T13:46:34Z'),
              endTime: new Date('2023-02-14T13:46:35Z'),
              valueState: null,
              valueNumber: 1517,
            },
            {
              startTime: new Date('2023-02-14T13:46:35Z'),
              endTime: new Date('2023-02-14T13:46:36Z'),
              valueState: null,
              valueNumber: 1521,
            },
            {
              startTime: new Date('2023-02-14T13:46:36Z'),
              endTime: new Date('2023-02-14T13:46:37Z'),
              valueState: null,
              valueNumber: 1523,
            },
            {
              startTime: new Date('2023-02-14T13:46:37Z'),
              endTime: new Date('2023-02-14T13:46:38Z'),
              valueState: null,
              valueNumber: 1523,
            },
            {
              startTime: new Date('2023-02-14T13:46:38Z'),
              endTime: new Date('2023-02-14T13:46:39Z'),
              valueState: null,
              valueNumber: 1525,
            },
            {
              startTime: new Date('2023-02-14T13:46:39Z'),
              endTime: new Date('2023-02-14T13:46:40Z'),
              valueState: null,
              valueNumber: 1527,
            },
            {
              startTime: new Date('2023-02-14T13:46:40Z'),
              endTime: new Date('2023-02-14T13:46:41Z'),
              valueState: null,
              valueNumber: 1530,
            },
            {
              startTime: new Date('2023-02-14T13:46:41Z'),
              endTime: new Date('2023-02-14T13:46:42Z'),
              valueState: null,
              valueNumber: 1532,
            },
            {
              startTime: new Date('2023-02-14T13:46:42Z'),
              endTime: new Date('2023-02-14T13:46:43Z'),
              valueState: null,
              valueNumber: 1532,
            },
            {
              startTime: new Date('2023-02-14T13:46:43Z'),
              endTime: new Date('2023-02-14T13:46:44Z'),
              valueState: null,
              valueNumber: 1535,
            },
            {
              startTime: new Date('2023-02-14T13:46:44Z'),
              endTime: new Date('2023-02-14T13:46:45Z'),
              valueState: null,
              valueNumber: 1538,
            },
            {
              startTime: new Date('2023-02-14T13:46:45Z'),
              endTime: new Date('2023-02-14T13:46:46Z'),
              valueState: null,
              valueNumber: 1540,
            },
            {
              startTime: new Date('2023-02-14T13:46:46Z'),
              endTime: new Date('2023-02-14T13:46:47Z'),
              valueState: null,
              valueNumber: 1542,
            },
            {
              startTime: new Date('2023-02-14T13:46:47Z'),
              endTime: new Date('2023-02-14T13:46:48Z'),
              valueState: null,
              valueNumber: 1544,
            },
            {
              startTime: new Date('2023-02-14T13:46:48Z'),
              endTime: new Date('2023-02-14T13:46:49Z'),
              valueState: null,
              valueNumber: 1544,
            },
            {
              startTime: new Date('2023-02-14T13:46:49Z'),
              endTime: new Date('2023-02-14T13:46:50Z'),
              valueState: null,
              valueNumber: 1547,
            },
            {
              startTime: new Date('2023-02-14T13:46:50Z'),
              endTime: new Date('2023-02-14T13:46:51Z'),
              valueState: null,
              valueNumber: 1550,
            },
            {
              startTime: new Date('2023-02-14T13:46:51Z'),
              endTime: new Date('2023-02-14T13:46:52Z'),
              valueState: null,
              valueNumber: 1551,
            },
            {
              startTime: new Date('2023-02-14T13:46:52Z'),
              endTime: new Date('2023-02-14T13:46:53Z'),
              valueState: null,
              valueNumber: 1551,
            },
            {
              startTime: new Date('2023-02-14T13:46:53Z'),
              endTime: new Date('2023-02-14T13:46:54Z'),
              valueState: null,
              valueNumber: 1554,
            },
            {
              startTime: new Date('2023-02-14T13:46:54Z'),
              endTime: new Date('2023-02-14T13:46:55Z'),
              valueState: null,
              valueNumber: 1557,
            },
            {
              startTime: new Date('2023-02-14T13:46:55Z'),
              endTime: new Date('2023-02-14T13:46:56Z'),
              valueState: null,
              valueNumber: 1559,
            },
            {
              startTime: new Date('2023-02-14T13:46:56Z'),
              endTime: new Date('2023-02-14T13:46:57Z'),
              valueState: null,
              valueNumber: 1562,
            },
            {
              startTime: new Date('2023-02-14T13:46:57Z'),
              endTime: new Date('2023-02-14T13:46:58Z'),
              valueState: null,
              valueNumber: 1562,
            },
            {
              startTime: new Date('2023-02-14T13:46:58Z'),
              endTime: new Date('2023-02-14T13:46:59Z'),
              valueState: null,
              valueNumber: 1564,
            },
            {
              startTime: new Date('2023-02-14T13:46:59Z'),
              endTime: new Date('2023-02-14T13:47:00Z'),
              valueState: null,
              valueNumber: 1566,
            },
            {
              startTime: new Date('2023-02-14T13:47:00Z'),
              endTime: new Date('2023-02-14T13:47:01Z'),
              valueState: null,
              valueNumber: 1569,
            },
            {
              startTime: new Date('2023-02-14T13:47:01Z'),
              endTime: new Date('2023-02-14T13:47:02Z'),
              valueState: null,
              valueNumber: 1571,
            },
            {
              startTime: new Date('2023-02-14T13:47:02Z'),
              endTime: new Date('2023-02-14T13:47:03Z'),
              valueState: null,
              valueNumber: 1573,
            },
            {
              startTime: new Date('2023-02-14T13:47:03Z'),
              endTime: new Date('2023-02-14T13:47:04Z'),
              valueState: null,
              valueNumber: 1573,
            },
            {
              startTime: new Date('2023-02-14T13:47:04Z'),
              endTime: new Date('2023-02-14T13:47:05Z'),
              valueState: null,
              valueNumber: 1576,
            },
            {
              startTime: new Date('2023-02-14T13:47:05Z'),
              endTime: new Date('2023-02-14T13:47:06Z'),
              valueState: null,
              valueNumber: 1579,
            },
            {
              startTime: new Date('2023-02-14T13:47:06Z'),
              endTime: new Date('2023-02-14T13:47:07Z'),
              valueState: null,
              valueNumber: 1581,
            },
            {
              startTime: new Date('2023-02-14T13:47:07Z'),
              endTime: new Date('2023-02-14T13:47:08Z'),
              valueState: null,
              valueNumber: 1583,
            },
            {
              startTime: new Date('2023-02-14T13:47:08Z'),
              endTime: new Date('2023-02-14T13:47:09Z'),
              valueState: null,
              valueNumber: 1586,
            },
            {
              startTime: new Date('2023-02-14T13:47:09Z'),
              endTime: new Date('2023-02-14T13:47:10Z'),
              valueState: null,
              valueNumber: 1586,
            },
            {
              startTime: new Date('2023-02-14T13:47:10Z'),
              endTime: new Date('2023-02-14T13:47:11Z'),
              valueState: null,
              valueNumber: 1588,
            },
            {
              startTime: new Date('2023-02-14T13:47:11Z'),
              endTime: new Date('2023-02-14T13:47:12Z'),
              valueState: null,
              valueNumber: 1590,
            },
            {
              startTime: new Date('2023-02-14T13:47:12Z'),
              endTime: new Date('2023-02-14T13:47:13Z'),
              valueState: null,
              valueNumber: 1593,
            },
            {
              startTime: new Date('2023-02-14T13:47:13Z'),
              endTime: new Date('2023-02-14T13:47:14Z'),
              valueState: null,
              valueNumber: 1595,
            },
            {
              startTime: new Date('2023-02-14T13:47:14Z'),
              endTime: new Date('2023-02-14T13:47:15Z'),
              valueState: null,
              valueNumber: 1597,
            },
            {
              startTime: new Date('2023-02-14T13:47:15Z'),
              endTime: new Date('2023-02-14T13:47:16Z'),
              valueState: null,
              valueNumber: 1597,
            },
            {
              startTime: new Date('2023-02-14T13:47:16Z'),
              endTime: new Date('2023-02-14T13:47:20Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:20Z'),
              endTime: new Date('2023-02-14T13:47:21Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:21Z'),
              endTime: new Date('2023-02-14T13:47:22Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:22Z'),
              endTime: new Date('2023-02-14T13:47:23Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:23Z'),
              endTime: new Date('2023-02-14T13:47:24Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:24Z'),
              endTime: new Date('2023-02-14T13:47:26Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:26Z'),
              endTime: new Date('2023-02-14T13:47:27Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:27Z'),
              endTime: new Date('2023-02-14T13:47:28Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:28Z'),
              endTime: new Date('2023-02-14T13:47:29Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:29Z'),
              endTime: new Date('2023-02-14T13:47:31Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:31Z'),
              endTime: new Date('2023-02-14T13:47:32Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:32Z'),
              endTime: new Date('2023-02-14T13:47:33Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:33Z'),
              endTime: new Date('2023-02-14T13:47:34Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:34Z'),
              endTime: new Date('2023-02-14T13:47:35Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:35Z'),
              endTime: new Date('2023-02-14T13:47:36Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:36Z'),
              endTime: new Date('2023-02-14T13:47:38Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:38Z'),
              endTime: new Date('2023-02-14T13:47:39Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:39Z'),
              endTime: new Date('2023-02-14T13:47:40Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:40Z'),
              endTime: new Date('2023-02-14T13:47:41Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:41Z'),
              endTime: new Date('2023-02-14T13:47:42Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:42Z'),
              endTime: new Date('2023-02-14T13:47:44Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:44Z'),
              endTime: new Date('2023-02-14T13:47:45Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:45Z'),
              endTime: new Date('2023-02-14T13:47:46Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:46Z'),
              endTime: new Date('2023-02-14T13:47:48Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:48Z'),
              endTime: new Date('2023-02-14T13:47:49Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:49Z'),
              endTime: new Date('2023-02-14T13:47:50Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:50Z'),
              endTime: new Date('2023-02-14T13:47:51Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:51Z'),
              endTime: new Date('2023-02-14T13:47:52Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:52Z'),
              endTime: new Date('2023-02-14T13:47:53Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:53Z'),
              endTime: new Date('2023-02-14T13:47:55Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:55Z'),
              endTime: new Date('2023-02-14T13:47:56Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:56Z'),
              endTime: new Date('2023-02-14T13:47:57Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:47:57Z'),
              endTime: new Date('2023-02-14T13:47:58Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:58Z'),
              endTime: new Date('2023-02-14T13:47:59Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:47:59Z'),
              endTime: new Date('2023-02-14T13:48:01Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:01Z'),
              endTime: new Date('2023-02-14T13:48:02Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:02Z'),
              endTime: new Date('2023-02-14T13:48:03Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:03Z'),
              endTime: new Date('2023-02-14T13:48:05Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:05Z'),
              endTime: new Date('2023-02-14T13:48:06Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:06Z'),
              endTime: new Date('2023-02-14T13:48:07Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:07Z'),
              endTime: new Date('2023-02-14T13:48:08Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:08Z'),
              endTime: new Date('2023-02-14T13:48:09Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:09Z'),
              endTime: new Date('2023-02-14T13:48:10Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:10Z'),
              endTime: new Date('2023-02-14T13:48:11Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:11Z'),
              endTime: new Date('2023-02-14T13:48:13Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:13Z'),
              endTime: new Date('2023-02-14T13:48:14Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:14Z'),
              endTime: new Date('2023-02-14T13:48:15Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:15Z'),
              endTime: new Date('2023-02-14T13:48:18Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:18Z'),
              endTime: new Date('2023-02-14T13:48:19Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:19Z'),
              endTime: new Date('2023-02-14T13:48:20Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:20Z'),
              endTime: new Date('2023-02-14T13:48:21Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:21Z'),
              endTime: new Date('2023-02-14T13:48:22Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:22Z'),
              endTime: new Date('2023-02-14T13:48:23Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:23Z'),
              endTime: new Date('2023-02-14T13:48:25Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:25Z'),
              endTime: new Date('2023-02-14T13:48:26Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:26Z'),
              endTime: new Date('2023-02-14T13:48:27Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:27Z'),
              endTime: new Date('2023-02-14T13:48:28Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:28Z'),
              endTime: new Date('2023-02-14T13:48:29Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:29Z'),
              endTime: new Date('2023-02-14T13:48:31Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:31Z'),
              endTime: new Date('2023-02-14T13:48:32Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:32Z'),
              endTime: new Date('2023-02-14T13:48:33Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:33Z'),
              endTime: new Date('2023-02-14T13:48:34Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:34Z'),
              endTime: new Date('2023-02-14T13:48:35Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:35Z'),
              endTime: new Date('2023-02-14T13:48:37Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:37Z'),
              endTime: new Date('2023-02-14T13:48:38Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:38Z'),
              endTime: new Date('2023-02-14T13:48:39Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:39Z'),
              endTime: new Date('2023-02-14T13:48:41Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:41Z'),
              endTime: new Date('2023-02-14T13:48:42Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:42Z'),
              endTime: new Date('2023-02-14T13:48:44Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:44Z'),
              endTime: new Date('2023-02-14T13:48:45Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:45Z'),
              endTime: new Date('2023-02-14T13:48:46Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:46Z'),
              endTime: new Date('2023-02-14T13:48:47Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:47Z'),
              endTime: new Date('2023-02-14T13:48:48Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:48Z'),
              endTime: new Date('2023-02-14T13:48:49Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:49Z'),
              endTime: new Date('2023-02-14T13:48:50Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:50Z'),
              endTime: new Date('2023-02-14T13:48:51Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:51Z'),
              endTime: new Date('2023-02-14T13:48:53Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:53Z'),
              endTime: new Date('2023-02-14T13:48:54Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:54Z'),
              endTime: new Date('2023-02-14T13:48:55Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:55Z'),
              endTime: new Date('2023-02-14T13:48:56Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:56Z'),
              endTime: new Date('2023-02-14T13:48:57Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:48:57Z'),
              endTime: new Date('2023-02-14T13:48:59Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:48:59Z'),
              endTime: new Date('2023-02-14T13:49:00Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:00Z'),
              endTime: new Date('2023-02-14T13:49:01Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:01Z'),
              endTime: new Date('2023-02-14T13:49:02Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:02Z'),
              endTime: new Date('2023-02-14T13:49:03Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:03Z'),
              endTime: new Date('2023-02-14T13:49:04Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:04Z'),
              endTime: new Date('2023-02-14T13:49:06Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:06Z'),
              endTime: new Date('2023-02-14T13:49:07Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:07Z'),
              endTime: new Date('2023-02-14T13:49:08Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:08Z'),
              endTime: new Date('2023-02-14T13:49:09Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:09Z'),
              endTime: new Date('2023-02-14T13:49:11Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:11Z'),
              endTime: new Date('2023-02-14T13:49:12Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:12Z'),
              endTime: new Date('2023-02-14T13:49:13Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:13Z'),
              endTime: new Date('2023-02-14T13:49:14Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:14Z'),
              endTime: new Date('2023-02-14T13:49:15Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:15Z'),
              endTime: new Date('2023-02-14T13:49:16Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:16Z'),
              endTime: new Date('2023-02-14T13:49:17Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:17Z'),
              endTime: new Date('2023-02-14T13:49:18Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:18Z'),
              endTime: new Date('2023-02-14T13:49:19Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:19Z'),
              endTime: new Date('2023-02-14T13:49:20Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:20Z'),
              endTime: new Date('2023-02-14T13:49:22Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:22Z'),
              endTime: new Date('2023-02-14T13:49:23Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:23Z'),
              endTime: new Date('2023-02-14T13:49:26Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:26Z'),
              endTime: new Date('2023-02-14T13:49:27Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:27Z'),
              endTime: new Date('2023-02-14T13:49:28Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:28Z'),
              endTime: new Date('2023-02-14T13:49:29Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:29Z'),
              endTime: new Date('2023-02-14T13:49:30Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:30Z'),
              endTime: new Date('2023-02-14T13:49:31Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:31Z'),
              endTime: new Date('2023-02-14T13:49:32Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:32Z'),
              endTime: new Date('2023-02-14T13:49:33Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:33Z'),
              endTime: new Date('2023-02-14T13:49:34Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:34Z'),
              endTime: new Date('2023-02-14T13:49:35Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:35Z'),
              endTime: new Date('2023-02-14T13:49:37Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:37Z'),
              endTime: new Date('2023-02-14T13:49:38Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:38Z'),
              endTime: new Date('2023-02-14T13:49:39Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:39Z'),
              endTime: new Date('2023-02-14T13:49:40Z'),
              valueState: null,
              valueNumber: 1599,
            },
            {
              startTime: new Date('2023-02-14T13:49:40Z'),
              endTime: new Date('2023-02-14T13:49:41Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:41Z'),
              endTime: new Date('2023-02-14T13:49:42Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:42Z'),
              endTime: new Date('2023-02-14T13:49:43Z'),
              valueState: null,
              valueNumber: 1546,
            },
            {
              startTime: new Date('2023-02-14T13:49:43Z'),
              endTime: new Date('2023-02-14T13:49:44Z'),
              valueState: null,
              valueNumber: 1503,
            },
            {
              startTime: new Date('2023-02-14T13:49:44Z'),
              endTime: new Date('2023-02-14T13:49:45Z'),
              valueState: null,
              valueNumber: 1463,
            },
            {
              startTime: new Date('2023-02-14T13:49:45Z'),
              endTime: new Date('2023-02-14T13:49:46Z'),
              valueState: null,
              valueNumber: 1420,
            },
            {
              startTime: new Date('2023-02-14T13:49:46Z'),
              endTime: new Date('2023-02-14T13:49:47Z'),
              valueState: null,
              valueNumber: 1380,
            },
            {
              startTime: new Date('2023-02-14T13:49:47Z'),
              endTime: new Date('2023-02-14T13:49:48Z'),
              valueState: null,
              valueNumber: 1380,
            },
            {
              startTime: new Date('2023-02-14T13:49:48Z'),
              endTime: new Date('2023-02-14T13:49:49Z'),
              valueState: null,
              valueNumber: 1338,
            },
            {
              startTime: new Date('2023-02-14T13:49:49Z'),
              endTime: new Date('2023-02-14T13:49:50Z'),
              valueState: null,
              valueNumber: 1303,
            },
            {
              startTime: new Date('2023-02-14T13:49:50Z'),
              endTime: new Date('2023-02-14T13:49:51Z'),
              valueState: null,
              valueNumber: 1264,
            },
            {
              startTime: new Date('2023-02-14T13:49:51Z'),
              endTime: new Date('2023-02-14T13:49:52Z'),
              valueState: null,
              valueNumber: 1229,
            },
            {
              startTime: new Date('2023-02-14T13:49:52Z'),
              endTime: new Date('2023-02-14T13:49:53Z'),
              valueState: null,
              valueNumber: 1229,
            },
            {
              startTime: new Date('2023-02-14T13:49:53Z'),
              endTime: new Date('2023-02-14T13:49:54Z'),
              valueState: null,
              valueNumber: 1192,
            },
            {
              startTime: new Date('2023-02-14T13:49:54Z'),
              endTime: new Date('2023-02-14T13:49:55Z'),
              valueState: null,
              valueNumber: 1154,
            },
            {
              startTime: new Date('2023-02-14T13:49:55Z'),
              endTime: new Date('2023-02-14T13:49:56Z'),
              valueState: null,
              valueNumber: 1120,
            },
            {
              startTime: new Date('2023-02-14T13:49:56Z'),
              endTime: new Date('2023-02-14T13:49:57Z'),
              valueState: null,
              valueNumber: 1087,
            },
            {
              startTime: new Date('2023-02-14T13:49:57Z'),
              endTime: new Date('2023-02-14T13:49:58Z'),
              valueState: null,
              valueNumber: 1051,
            },
            {
              startTime: new Date('2023-02-14T13:49:58Z'),
              endTime: new Date('2023-02-14T13:49:59Z'),
              valueState: null,
              valueNumber: 1051,
            },
            {
              startTime: new Date('2023-02-14T13:49:59Z'),
              endTime: new Date('2023-02-14T13:50:00Z'),
              valueState: null,
              valueNumber: 1016,
            },
            {
              startTime: new Date('2023-02-14T13:50:00Z'),
              endTime: new Date('2023-02-14T13:50:01Z'),
              valueState: null,
              valueNumber: 984,
            },
            {
              startTime: new Date('2023-02-14T13:50:01Z'),
              endTime: new Date('2023-02-14T13:50:02Z'),
              valueState: null,
              valueNumber: 952,
            },
            {
              startTime: new Date('2023-02-14T13:50:02Z'),
              endTime: new Date('2023-02-14T13:50:03Z'),
              valueState: null,
              valueNumber: 917,
            },
            {
              startTime: new Date('2023-02-14T13:50:03Z'),
              endTime: new Date('2023-02-14T13:50:04Z'),
              valueState: null,
              valueNumber: 885,
            },
            {
              startTime: new Date('2023-02-14T13:50:04Z'),
              endTime: new Date('2023-02-14T13:50:05Z'),
              valueState: null,
              valueNumber: 885,
            },
            {
              startTime: new Date('2023-02-14T13:50:05Z'),
              endTime: new Date('2023-02-14T13:50:06Z'),
              valueState: null,
              valueNumber: 854,
            },
            {
              startTime: new Date('2023-02-14T13:50:06Z'),
              endTime: new Date('2023-02-14T13:50:07Z'),
              valueState: null,
              valueNumber: 821,
            },
            {
              startTime: new Date('2023-02-14T13:50:07Z'),
              endTime: new Date('2023-02-14T13:50:08Z'),
              valueState: null,
              valueNumber: 821,
            },
            {
              startTime: new Date('2023-02-14T13:50:08Z'),
              endTime: new Date('2023-02-14T13:50:09Z'),
              valueState: null,
              valueNumber: 788,
            },
            {
              startTime: new Date('2023-02-14T13:50:09Z'),
              endTime: new Date('2023-02-14T13:50:10Z'),
              valueState: null,
              valueNumber: 757,
            },
            {
              startTime: new Date('2023-02-14T13:50:10Z'),
              endTime: new Date('2023-02-14T13:50:11Z'),
              valueState: null,
              valueNumber: 724,
            },
            {
              startTime: new Date('2023-02-14T13:50:11Z'),
              endTime: new Date('2023-02-14T13:50:12Z'),
              valueState: null,
              valueNumber: 691,
            },
            {
              startTime: new Date('2023-02-14T13:50:12Z'),
              endTime: new Date('2023-02-14T13:50:13Z'),
              valueState: null,
              valueNumber: 658,
            },
            {
              startTime: new Date('2023-02-14T13:50:13Z'),
              endTime: new Date('2023-02-14T13:50:14Z'),
              valueState: null,
              valueNumber: 658,
            },
            {
              startTime: new Date('2023-02-14T13:50:14Z'),
              endTime: new Date('2023-02-14T13:50:15Z'),
              valueState: null,
              valueNumber: 628,
            },
            {
              startTime: new Date('2023-02-14T13:50:15Z'),
              endTime: new Date('2023-02-14T13:50:16Z'),
              valueState: null,
              valueNumber: 594,
            },
            {
              startTime: new Date('2023-02-14T13:50:16Z'),
              endTime: new Date('2023-02-14T13:50:17Z'),
              valueState: null,
              valueNumber: 559,
            },
            {
              startTime: new Date('2023-02-14T13:50:17Z'),
              endTime: new Date('2023-02-14T13:50:18Z'),
              valueState: null,
              valueNumber: 525,
            },
            {
              startTime: new Date('2023-02-14T13:50:18Z'),
              endTime: new Date('2023-02-14T13:50:19Z'),
              valueState: null,
              valueNumber: 491,
            },
            {
              startTime: new Date('2023-02-14T13:50:19Z'),
              endTime: new Date('2023-02-14T13:50:20Z'),
              valueState: null,
              valueNumber: 491,
            },
            {
              startTime: new Date('2023-02-14T13:50:20Z'),
              endTime: new Date('2023-02-14T13:50:21Z'),
              valueState: null,
              valueNumber: 455,
            },
            {
              startTime: new Date('2023-02-14T13:50:21Z'),
              endTime: new Date('2023-02-14T13:50:22Z'),
              valueState: null,
              valueNumber: 418,
            },
            {
              startTime: new Date('2023-02-14T13:50:22Z'),
              endTime: new Date('2023-02-14T13:50:23Z'),
              valueState: null,
              valueNumber: 380,
            },
            {
              startTime: new Date('2023-02-14T13:50:23Z'),
              endTime: new Date('2023-02-14T13:50:24Z'),
              valueState: null,
              valueNumber: 341,
            },
            {
              startTime: new Date('2023-02-14T13:50:24Z'),
              endTime: new Date('2023-02-14T13:50:25Z'),
              valueState: null,
              valueNumber: 297,
            },
            {
              startTime: new Date('2023-02-14T13:50:25Z'),
              endTime: new Date('2023-02-14T13:50:26Z'),
              valueState: null,
              valueNumber: 297,
            },
            {
              startTime: new Date('2023-02-14T13:50:26Z'),
              endTime: new Date('2023-02-14T13:50:27Z'),
              valueState: null,
              valueNumber: 252,
            },
            {
              startTime: new Date('2023-02-14T13:50:27Z'),
              endTime: new Date('2023-02-14T13:50:28Z'),
              valueState: null,
              valueNumber: 200,
            },
            {
              startTime: new Date('2023-02-14T13:50:28Z'),
              endTime: new Date('2023-02-14T13:50:29Z'),
              valueState: null,
              valueNumber: 140,
            },
            {
              startTime: new Date('2023-02-14T13:50:29Z'),
              endTime: new Date('2023-02-14T13:50:30Z'),
              valueState: null,
              valueNumber: 58,
            },
            {
              startTime: new Date('2023-02-14T13:50:30Z'),
              endTime: new Date('2023-02-14T13:50:31Z'),
              valueState: null,
              valueNumber: 58,
            },
            {
              startTime: new Date('2023-02-14T13:50:31Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'rpm Setpoint',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T12:53:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:37Z'),
              endTime: new Date('2023-02-14T12:53:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:38Z'),
              endTime: new Date('2023-02-14T12:53:42Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:53:42Z'),
              endTime: new Date('2023-02-14T12:53:43Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:53:43Z'),
              endTime: new Date('2023-02-14T12:53:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:48Z'),
              endTime: new Date('2023-02-14T12:53:49Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:49Z'),
              endTime: new Date('2023-02-14T12:53:54Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:53:54Z'),
              endTime: new Date('2023-02-14T12:53:55Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:53:55Z'),
              endTime: new Date('2023-02-14T12:53:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:59Z'),
              endTime: new Date('2023-02-14T12:54:00Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:00Z'),
              endTime: new Date('2023-02-14T12:54:05Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:05Z'),
              endTime: new Date('2023-02-14T12:54:06Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:06Z'),
              endTime: new Date('2023-02-14T12:54:12Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:12Z'),
              endTime: new Date('2023-02-14T12:54:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:13Z'),
              endTime: new Date('2023-02-14T12:54:18Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:18Z'),
              endTime: new Date('2023-02-14T12:54:19Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:19Z'),
              endTime: new Date('2023-02-14T12:54:24Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:24Z'),
              endTime: new Date('2023-02-14T12:54:25Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:25Z'),
              endTime: new Date('2023-02-14T12:54:30Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:30Z'),
              endTime: new Date('2023-02-14T12:54:31Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:31Z'),
              endTime: new Date('2023-02-14T12:54:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:36Z'),
              endTime: new Date('2023-02-14T12:54:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:37Z'),
              endTime: new Date('2023-02-14T12:54:41Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:41Z'),
              endTime: new Date('2023-02-14T12:54:42Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:42Z'),
              endTime: new Date('2023-02-14T12:54:47Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:47Z'),
              endTime: new Date('2023-02-14T12:54:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:48Z'),
              endTime: new Date('2023-02-14T12:54:49Z'),
              valueState: null,
              valueNumber: 26,
            },
            {
              startTime: new Date('2023-02-14T12:54:49Z'),
              endTime: new Date('2023-02-14T12:54:50Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:50Z'),
              endTime: new Date('2023-02-14T12:54:51Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:54:51Z'),
              endTime: new Date('2023-02-14T12:55:00Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:00Z'),
              endTime: new Date('2023-02-14T12:55:01Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:01Z'),
              endTime: new Date('2023-02-14T12:55:02Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:02Z'),
              endTime: new Date('2023-02-14T12:55:10Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:10Z'),
              endTime: new Date('2023-02-14T12:55:11Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:11Z'),
              endTime: new Date('2023-02-14T12:55:12Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:12Z'),
              endTime: new Date('2023-02-14T12:55:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:13Z'),
              endTime: new Date('2023-02-14T12:55:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:21Z'),
              endTime: new Date('2023-02-14T12:55:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:22Z'),
              endTime: new Date('2023-02-14T12:55:23Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:23Z'),
              endTime: new Date('2023-02-14T12:55:24Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:24Z'),
              endTime: new Date('2023-02-14T12:55:32Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:32Z'),
              endTime: new Date('2023-02-14T12:55:33Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:33Z'),
              endTime: new Date('2023-02-14T12:55:34Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:34Z'),
              endTime: new Date('2023-02-14T12:55:35Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:35Z'),
              endTime: new Date('2023-02-14T12:55:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:43Z'),
              endTime: new Date('2023-02-14T12:55:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:44Z'),
              endTime: new Date('2023-02-14T12:55:45Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:45Z'),
              endTime: new Date('2023-02-14T12:55:46Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:46Z'),
              endTime: new Date('2023-02-14T12:55:54Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:54Z'),
              endTime: new Date('2023-02-14T12:55:55Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:55:55Z'),
              endTime: new Date('2023-02-14T12:55:57Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:57Z'),
              endTime: new Date('2023-02-14T12:55:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:58Z'),
              endTime: new Date('2023-02-14T12:56:05Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:05Z'),
              endTime: new Date('2023-02-14T12:56:06Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:06Z'),
              endTime: new Date('2023-02-14T12:56:08Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:08Z'),
              endTime: new Date('2023-02-14T12:56:09Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:09Z'),
              endTime: new Date('2023-02-14T12:56:17Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:17Z'),
              endTime: new Date('2023-02-14T12:56:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:18Z'),
              endTime: new Date('2023-02-14T12:56:19Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:19Z'),
              endTime: new Date('2023-02-14T12:56:20Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:20Z'),
              endTime: new Date('2023-02-14T12:56:28Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:28Z'),
              endTime: new Date('2023-02-14T12:56:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:29Z'),
              endTime: new Date('2023-02-14T12:56:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:30Z'),
              endTime: new Date('2023-02-14T12:56:31Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:31Z'),
              endTime: new Date('2023-02-14T12:56:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:38Z'),
              endTime: new Date('2023-02-14T12:56:39Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:39Z'),
              endTime: new Date('2023-02-14T12:56:41Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:41Z'),
              endTime: new Date('2023-02-14T12:56:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:42Z'),
              endTime: new Date('2023-02-14T12:56:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:50Z'),
              endTime: new Date('2023-02-14T12:56:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:56:51Z'),
              endTime: new Date('2023-02-14T12:56:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:52Z'),
              endTime: new Date('2023-02-14T12:56:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:56:53Z'),
              endTime: new Date('2023-02-14T12:57:01Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:01Z'),
              endTime: new Date('2023-02-14T12:57:02Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:02Z'),
              endTime: new Date('2023-02-14T12:57:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:03Z'),
              endTime: new Date('2023-02-14T12:57:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:13Z'),
              endTime: new Date('2023-02-14T12:57:14Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:14Z'),
              endTime: new Date('2023-02-14T12:57:15Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:15Z'),
              endTime: new Date('2023-02-14T12:57:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:24Z'),
              endTime: new Date('2023-02-14T12:57:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:25Z'),
              endTime: new Date('2023-02-14T12:57:26Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:26Z'),
              endTime: new Date('2023-02-14T12:57:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:34Z'),
              endTime: new Date('2023-02-14T12:57:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:35Z'),
              endTime: new Date('2023-02-14T12:57:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:37Z'),
              endTime: new Date('2023-02-14T12:57:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:38Z'),
              endTime: new Date('2023-02-14T12:57:45Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:45Z'),
              endTime: new Date('2023-02-14T12:57:46Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:46Z'),
              endTime: new Date('2023-02-14T12:57:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:48Z'),
              endTime: new Date('2023-02-14T12:57:49Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:49Z'),
              endTime: new Date('2023-02-14T12:57:57Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:57Z'),
              endTime: new Date('2023-02-14T12:57:58Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:57:58Z'),
              endTime: new Date('2023-02-14T12:57:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:57:59Z'),
              endTime: new Date('2023-02-14T12:58:00Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:00Z'),
              endTime: new Date('2023-02-14T12:58:09Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:09Z'),
              endTime: new Date('2023-02-14T12:58:10Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:10Z'),
              endTime: new Date('2023-02-14T12:58:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:11Z'),
              endTime: new Date('2023-02-14T12:58:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:19Z'),
              endTime: new Date('2023-02-14T12:58:20Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:20Z'),
              endTime: new Date('2023-02-14T12:58:21Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:21Z'),
              endTime: new Date('2023-02-14T12:58:22Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:22Z'),
              endTime: new Date('2023-02-14T12:58:30Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:30Z'),
              endTime: new Date('2023-02-14T12:58:31Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:31Z'),
              endTime: new Date('2023-02-14T12:58:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:33Z'),
              endTime: new Date('2023-02-14T12:58:34Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:34Z'),
              endTime: new Date('2023-02-14T12:58:42Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:42Z'),
              endTime: new Date('2023-02-14T12:58:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:43Z'),
              endTime: new Date('2023-02-14T12:58:44Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:44Z'),
              endTime: new Date('2023-02-14T12:58:53Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:53Z'),
              endTime: new Date('2023-02-14T12:58:54Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:58:54Z'),
              endTime: new Date('2023-02-14T12:58:55Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:58:55Z'),
              endTime: new Date('2023-02-14T12:59:03Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:03Z'),
              endTime: new Date('2023-02-14T12:59:04Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:04Z'),
              endTime: new Date('2023-02-14T12:59:06Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:06Z'),
              endTime: new Date('2023-02-14T12:59:07Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:07Z'),
              endTime: new Date('2023-02-14T12:59:14Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:14Z'),
              endTime: new Date('2023-02-14T12:59:15Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:15Z'),
              endTime: new Date('2023-02-14T12:59:17Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:17Z'),
              endTime: new Date('2023-02-14T12:59:18Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:18Z'),
              endTime: new Date('2023-02-14T12:59:26Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:26Z'),
              endTime: new Date('2023-02-14T12:59:27Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:27Z'),
              endTime: new Date('2023-02-14T12:59:28Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:28Z'),
              endTime: new Date('2023-02-14T12:59:37Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:37Z'),
              endTime: new Date('2023-02-14T12:59:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:38Z'),
              endTime: new Date('2023-02-14T12:59:39Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:39Z'),
              endTime: new Date('2023-02-14T12:59:47Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:47Z'),
              endTime: new Date('2023-02-14T12:59:48Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:48Z'),
              endTime: new Date('2023-02-14T12:59:51Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:51Z'),
              endTime: new Date('2023-02-14T12:59:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:59:52Z'),
              endTime: new Date('2023-02-14T12:59:59Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T12:59:59Z'),
              endTime: new Date('2023-02-14T13:00:00Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:00Z'),
              endTime: new Date('2023-02-14T13:00:02Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:02Z'),
              endTime: new Date('2023-02-14T13:00:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:03Z'),
              endTime: new Date('2023-02-14T13:00:11Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:11Z'),
              endTime: new Date('2023-02-14T13:00:12Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:12Z'),
              endTime: new Date('2023-02-14T13:00:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:13Z'),
              endTime: new Date('2023-02-14T13:00:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:22Z'),
              endTime: new Date('2023-02-14T13:00:23Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:23Z'),
              endTime: new Date('2023-02-14T13:00:24Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:24Z'),
              endTime: new Date('2023-02-14T13:00:33Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:33Z'),
              endTime: new Date('2023-02-14T13:00:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:34Z'),
              endTime: new Date('2023-02-14T13:00:35Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:35Z'),
              endTime: new Date('2023-02-14T13:00:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:36Z'),
              endTime: new Date('2023-02-14T13:00:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:43Z'),
              endTime: new Date('2023-02-14T13:00:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:44Z'),
              endTime: new Date('2023-02-14T13:00:46Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:46Z'),
              endTime: new Date('2023-02-14T13:00:47Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:47Z'),
              endTime: new Date('2023-02-14T13:00:54Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:54Z'),
              endTime: new Date('2023-02-14T13:00:55Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:00:55Z'),
              endTime: new Date('2023-02-14T13:00:57Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:57Z'),
              endTime: new Date('2023-02-14T13:00:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:00:58Z'),
              endTime: new Date('2023-02-14T13:01:06Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:06Z'),
              endTime: new Date('2023-02-14T13:01:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:07Z'),
              endTime: new Date('2023-02-14T13:01:08Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:08Z'),
              endTime: new Date('2023-02-14T13:01:09Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:09Z'),
              endTime: new Date('2023-02-14T13:01:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:18Z'),
              endTime: new Date('2023-02-14T13:01:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:19Z'),
              endTime: new Date('2023-02-14T13:01:20Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:20Z'),
              endTime: new Date('2023-02-14T13:01:28Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:28Z'),
              endTime: new Date('2023-02-14T13:01:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:29Z'),
              endTime: new Date('2023-02-14T13:01:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:30Z'),
              endTime: new Date('2023-02-14T13:01:31Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:31Z'),
              endTime: new Date('2023-02-14T13:01:39Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:39Z'),
              endTime: new Date('2023-02-14T13:01:40Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:40Z'),
              endTime: new Date('2023-02-14T13:01:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:42Z'),
              endTime: new Date('2023-02-14T13:01:43Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:43Z'),
              endTime: new Date('2023-02-14T13:01:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:50Z'),
              endTime: new Date('2023-02-14T13:01:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:01:51Z'),
              endTime: new Date('2023-02-14T13:01:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:52Z'),
              endTime: new Date('2023-02-14T13:01:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:01:53Z'),
              endTime: new Date('2023-02-14T13:02:02Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:02Z'),
              endTime: new Date('2023-02-14T13:02:03Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:03Z'),
              endTime: new Date('2023-02-14T13:02:04Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:04Z'),
              endTime: new Date('2023-02-14T13:02:12Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:12Z'),
              endTime: new Date('2023-02-14T13:02:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:13Z'),
              endTime: new Date('2023-02-14T13:02:15Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:15Z'),
              endTime: new Date('2023-02-14T13:02:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:16Z'),
              endTime: new Date('2023-02-14T13:02:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:24Z'),
              endTime: new Date('2023-02-14T13:02:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:25Z'),
              endTime: new Date('2023-02-14T13:02:26Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:26Z'),
              endTime: new Date('2023-02-14T13:02:27Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:27Z'),
              endTime: new Date('2023-02-14T13:02:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:35Z'),
              endTime: new Date('2023-02-14T13:02:36Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:36Z'),
              endTime: new Date('2023-02-14T13:02:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:37Z'),
              endTime: new Date('2023-02-14T13:02:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:38Z'),
              endTime: new Date('2023-02-14T13:02:46Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:46Z'),
              endTime: new Date('2023-02-14T13:02:47Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:47Z'),
              endTime: new Date('2023-02-14T13:02:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:48Z'),
              endTime: new Date('2023-02-14T13:02:49Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:49Z'),
              endTime: new Date('2023-02-14T13:02:56Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:56Z'),
              endTime: new Date('2023-02-14T13:02:57Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:02:57Z'),
              endTime: new Date('2023-02-14T13:02:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:02:59Z'),
              endTime: new Date('2023-02-14T13:03:00Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:00Z'),
              endTime: new Date('2023-02-14T13:03:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:08Z'),
              endTime: new Date('2023-02-14T13:03:09Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:09Z'),
              endTime: new Date('2023-02-14T13:03:10Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:10Z'),
              endTime: new Date('2023-02-14T13:03:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:11Z'),
              endTime: new Date('2023-02-14T13:03:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:19Z'),
              endTime: new Date('2023-02-14T13:03:20Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:20Z'),
              endTime: new Date('2023-02-14T13:03:21Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:21Z'),
              endTime: new Date('2023-02-14T13:03:30Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:30Z'),
              endTime: new Date('2023-02-14T13:03:31Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:31Z'),
              endTime: new Date('2023-02-14T13:03:39Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:39Z'),
              endTime: new Date('2023-02-14T13:03:40Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:40Z'),
              endTime: new Date('2023-02-14T13:03:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:49Z'),
              endTime: new Date('2023-02-14T13:03:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:03:50Z'),
              endTime: new Date('2023-02-14T13:03:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:03:59Z'),
              endTime: new Date('2023-02-14T13:04:00Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:00Z'),
              endTime: new Date('2023-02-14T13:04:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:04:07Z'),
              endTime: new Date('2023-02-14T13:04:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:04:08Z'),
              endTime: new Date('2023-02-14T13:04:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:16Z'),
              endTime: new Date('2023-02-14T13:04:17Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:17Z'),
              endTime: new Date('2023-02-14T13:04:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:04:25Z'),
              endTime: new Date('2023-02-14T13:04:26Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:04:26Z'),
              endTime: new Date('2023-02-14T13:04:34Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:34Z'),
              endTime: new Date('2023-02-14T13:04:35Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:35Z'),
              endTime: new Date('2023-02-14T13:04:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:04:43Z'),
              endTime: new Date('2023-02-14T13:04:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:04:44Z'),
              endTime: new Date('2023-02-14T13:04:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:52Z'),
              endTime: new Date('2023-02-14T13:04:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:04:53Z'),
              endTime: new Date('2023-02-14T13:05:02Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:02Z'),
              endTime: new Date('2023-02-14T13:05:03Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:03Z'),
              endTime: new Date('2023-02-14T13:05:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:11Z'),
              endTime: new Date('2023-02-14T13:05:12Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:12Z'),
              endTime: new Date('2023-02-14T13:05:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:19Z'),
              endTime: new Date('2023-02-14T13:05:20Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:20Z'),
              endTime: new Date('2023-02-14T13:05:28Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:28Z'),
              endTime: new Date('2023-02-14T13:05:29Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:29Z'),
              endTime: new Date('2023-02-14T13:05:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:38Z'),
              endTime: new Date('2023-02-14T13:05:39Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:39Z'),
              endTime: new Date('2023-02-14T13:05:40Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:40Z'),
              endTime: new Date('2023-02-14T13:05:41Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:41Z'),
              endTime: new Date('2023-02-14T13:05:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:49Z'),
              endTime: new Date('2023-02-14T13:05:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:05:50Z'),
              endTime: new Date('2023-02-14T13:05:51Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:51Z'),
              endTime: new Date('2023-02-14T13:05:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:05:52Z'),
              endTime: new Date('2023-02-14T13:06:00Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:00Z'),
              endTime: new Date('2023-02-14T13:06:01Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:01Z'),
              endTime: new Date('2023-02-14T13:06:02Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:02Z'),
              endTime: new Date('2023-02-14T13:06:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:03Z'),
              endTime: new Date('2023-02-14T13:06:12Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:12Z'),
              endTime: new Date('2023-02-14T13:06:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:13Z'),
              endTime: new Date('2023-02-14T13:06:14Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:14Z'),
              endTime: new Date('2023-02-14T13:06:23Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:23Z'),
              endTime: new Date('2023-02-14T13:06:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:24Z'),
              endTime: new Date('2023-02-14T13:06:25Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:25Z'),
              endTime: new Date('2023-02-14T13:06:33Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:33Z'),
              endTime: new Date('2023-02-14T13:06:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:34Z'),
              endTime: new Date('2023-02-14T13:06:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:36Z'),
              endTime: new Date('2023-02-14T13:06:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:37Z'),
              endTime: new Date('2023-02-14T13:06:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:44Z'),
              endTime: new Date('2023-02-14T13:06:45Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:45Z'),
              endTime: new Date('2023-02-14T13:06:47Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:47Z'),
              endTime: new Date('2023-02-14T13:06:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:48Z'),
              endTime: new Date('2023-02-14T13:06:56Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:56Z'),
              endTime: new Date('2023-02-14T13:06:57Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:06:57Z'),
              endTime: new Date('2023-02-14T13:06:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:06:58Z'),
              endTime: new Date('2023-02-14T13:07:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:07Z'),
              endTime: new Date('2023-02-14T13:07:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:08Z'),
              endTime: new Date('2023-02-14T13:07:09Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:07:09Z'),
              endTime: new Date('2023-02-14T13:07:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:19Z'),
              endTime: new Date('2023-02-14T13:07:20Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:20Z'),
              endTime: new Date('2023-02-14T13:07:21Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:07:21Z'),
              endTime: new Date('2023-02-14T13:07:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:29Z'),
              endTime: new Date('2023-02-14T13:07:30Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:30Z'),
              endTime: new Date('2023-02-14T13:07:39Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:07:39Z'),
              endTime: new Date('2023-02-14T13:07:40Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:07:40Z'),
              endTime: new Date('2023-02-14T13:07:48Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:48Z'),
              endTime: new Date('2023-02-14T13:07:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:07:49Z'),
              endTime: new Date('2023-02-14T13:07:56Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:07:56Z'),
              endTime: new Date('2023-02-14T13:07:57Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:07:57Z'),
              endTime: new Date('2023-02-14T13:08:05Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:05Z'),
              endTime: new Date('2023-02-14T13:08:06Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:06Z'),
              endTime: new Date('2023-02-14T13:08:15Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:08:15Z'),
              endTime: new Date('2023-02-14T13:08:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:08:16Z'),
              endTime: new Date('2023-02-14T13:08:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:24Z'),
              endTime: new Date('2023-02-14T13:08:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:25Z'),
              endTime: new Date('2023-02-14T13:08:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:08:32Z'),
              endTime: new Date('2023-02-14T13:08:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:08:33Z'),
              endTime: new Date('2023-02-14T13:08:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:43Z'),
              endTime: new Date('2023-02-14T13:08:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:08:44Z'),
              endTime: new Date('2023-02-14T13:08:51Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:08:51Z'),
              endTime: new Date('2023-02-14T13:08:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:08:52Z'),
              endTime: new Date('2023-02-14T13:09:00Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:00Z'),
              endTime: new Date('2023-02-14T13:09:01Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:01Z'),
              endTime: new Date('2023-02-14T13:09:09Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:09Z'),
              endTime: new Date('2023-02-14T13:09:10Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:10Z'),
              endTime: new Date('2023-02-14T13:09:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:18Z'),
              endTime: new Date('2023-02-14T13:09:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:19Z'),
              endTime: new Date('2023-02-14T13:09:27Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:27Z'),
              endTime: new Date('2023-02-14T13:09:28Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:28Z'),
              endTime: new Date('2023-02-14T13:09:36Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:36Z'),
              endTime: new Date('2023-02-14T13:09:37Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:37Z'),
              endTime: new Date('2023-02-14T13:09:39Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:39Z'),
              endTime: new Date('2023-02-14T13:09:40Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:40Z'),
              endTime: new Date('2023-02-14T13:09:48Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:48Z'),
              endTime: new Date('2023-02-14T13:09:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:49Z'),
              endTime: new Date('2023-02-14T13:09:50Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:09:50Z'),
              endTime: new Date('2023-02-14T13:09:59Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:09:59Z'),
              endTime: new Date('2023-02-14T13:10:00Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:00Z'),
              endTime: new Date('2023-02-14T13:10:01Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:01Z'),
              endTime: new Date('2023-02-14T13:10:02Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:02Z'),
              endTime: new Date('2023-02-14T13:10:09Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:09Z'),
              endTime: new Date('2023-02-14T13:10:10Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:10Z'),
              endTime: new Date('2023-02-14T13:10:12Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:12Z'),
              endTime: new Date('2023-02-14T13:10:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:13Z'),
              endTime: new Date('2023-02-14T13:10:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:21Z'),
              endTime: new Date('2023-02-14T13:10:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:22Z'),
              endTime: new Date('2023-02-14T13:10:23Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:23Z'),
              endTime: new Date('2023-02-14T13:10:24Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:24Z'),
              endTime: new Date('2023-02-14T13:10:32Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:32Z'),
              endTime: new Date('2023-02-14T13:10:33Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:33Z'),
              endTime: new Date('2023-02-14T13:10:34Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:34Z'),
              endTime: new Date('2023-02-14T13:10:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:43Z'),
              endTime: new Date('2023-02-14T13:10:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:44Z'),
              endTime: new Date('2023-02-14T13:10:45Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:45Z'),
              endTime: new Date('2023-02-14T13:10:46Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:46Z'),
              endTime: new Date('2023-02-14T13:10:55Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:55Z'),
              endTime: new Date('2023-02-14T13:10:56Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:10:56Z'),
              endTime: new Date('2023-02-14T13:10:57Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:10:57Z'),
              endTime: new Date('2023-02-14T13:11:05Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:05Z'),
              endTime: new Date('2023-02-14T13:11:06Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:06Z'),
              endTime: new Date('2023-02-14T13:11:07Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:11:07Z'),
              endTime: new Date('2023-02-14T13:11:08Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:11:08Z'),
              endTime: new Date('2023-02-14T13:11:16Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:16Z'),
              endTime: new Date('2023-02-14T13:11:17Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:17Z'),
              endTime: new Date('2023-02-14T13:11:19Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:11:19Z'),
              endTime: new Date('2023-02-14T13:11:20Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:11:20Z'),
              endTime: new Date('2023-02-14T13:11:28Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:28Z'),
              endTime: new Date('2023-02-14T13:11:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:29Z'),
              endTime: new Date('2023-02-14T13:11:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:11:30Z'),
              endTime: new Date('2023-02-14T13:11:31Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:11:31Z'),
              endTime: new Date('2023-02-14T13:11:40Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:40Z'),
              endTime: new Date('2023-02-14T13:11:41Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:41Z'),
              endTime: new Date('2023-02-14T13:11:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:11:42Z'),
              endTime: new Date('2023-02-14T13:11:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:50Z'),
              endTime: new Date('2023-02-14T13:11:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:11:51Z'),
              endTime: new Date('2023-02-14T13:11:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:11:59Z'),
              endTime: new Date('2023-02-14T13:12:00Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:00Z'),
              endTime: new Date('2023-02-14T13:12:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:08Z'),
              endTime: new Date('2023-02-14T13:12:09Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:09Z'),
              endTime: new Date('2023-02-14T13:12:17Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:17Z'),
              endTime: new Date('2023-02-14T13:12:18Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:18Z'),
              endTime: new Date('2023-02-14T13:12:26Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:26Z'),
              endTime: new Date('2023-02-14T13:12:27Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:27Z'),
              endTime: new Date('2023-02-14T13:12:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:36Z'),
              endTime: new Date('2023-02-14T13:12:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:37Z'),
              endTime: new Date('2023-02-14T13:12:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:44Z'),
              endTime: new Date('2023-02-14T13:12:45Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:12:45Z'),
              endTime: new Date('2023-02-14T13:12:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:53Z'),
              endTime: new Date('2023-02-14T13:12:54Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:12:54Z'),
              endTime: new Date('2023-02-14T13:13:03Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:03Z'),
              endTime: new Date('2023-02-14T13:13:04Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:04Z'),
              endTime: new Date('2023-02-14T13:13:12Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:12Z'),
              endTime: new Date('2023-02-14T13:13:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:13Z'),
              endTime: new Date('2023-02-14T13:13:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:21Z'),
              endTime: new Date('2023-02-14T13:13:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:22Z'),
              endTime: new Date('2023-02-14T13:13:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:30Z'),
              endTime: new Date('2023-02-14T13:13:31Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:31Z'),
              endTime: new Date('2023-02-14T13:13:40Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:40Z'),
              endTime: new Date('2023-02-14T13:13:41Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:41Z'),
              endTime: new Date('2023-02-14T13:13:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:42Z'),
              endTime: new Date('2023-02-14T13:13:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:50Z'),
              endTime: new Date('2023-02-14T13:13:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:13:51Z'),
              endTime: new Date('2023-02-14T13:13:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:53Z'),
              endTime: new Date('2023-02-14T13:13:54Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:13:54Z'),
              endTime: new Date('2023-02-14T13:14:01Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:01Z'),
              endTime: new Date('2023-02-14T13:14:02Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:02Z'),
              endTime: new Date('2023-02-14T13:14:04Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:04Z'),
              endTime: new Date('2023-02-14T13:14:05Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:05Z'),
              endTime: new Date('2023-02-14T13:14:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:13Z'),
              endTime: new Date('2023-02-14T13:14:14Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:14Z'),
              endTime: new Date('2023-02-14T13:14:15Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:15Z'),
              endTime: new Date('2023-02-14T13:14:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:24Z'),
              endTime: new Date('2023-02-14T13:14:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:25Z'),
              endTime: new Date('2023-02-14T13:14:26Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:26Z'),
              endTime: new Date('2023-02-14T13:14:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:35Z'),
              endTime: new Date('2023-02-14T13:14:36Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:36Z'),
              endTime: new Date('2023-02-14T13:14:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:37Z'),
              endTime: new Date('2023-02-14T13:14:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:38Z'),
              endTime: new Date('2023-02-14T13:14:45Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:45Z'),
              endTime: new Date('2023-02-14T13:14:46Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:46Z'),
              endTime: new Date('2023-02-14T13:14:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:48Z'),
              endTime: new Date('2023-02-14T13:14:49Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:14:49Z'),
              endTime: new Date('2023-02-14T13:14:55Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:55Z'),
              endTime: new Date('2023-02-14T13:14:56Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:14:56Z'),
              endTime: new Date('2023-02-14T13:16:09Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:09Z'),
              endTime: new Date('2023-02-14T13:16:10Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:10Z'),
              endTime: new Date('2023-02-14T13:16:13Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:16:13Z'),
              endTime: new Date('2023-02-14T13:16:14Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:16:14Z'),
              endTime: new Date('2023-02-14T13:16:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:16Z'),
              endTime: new Date('2023-02-14T13:16:17Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:17Z'),
              endTime: new Date('2023-02-14T13:16:20Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:16:20Z'),
              endTime: new Date('2023-02-14T13:16:21Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:16:21Z'),
              endTime: new Date('2023-02-14T13:16:24Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:24Z'),
              endTime: new Date('2023-02-14T13:16:25Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:25Z'),
              endTime: new Date('2023-02-14T13:16:33Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:16:33Z'),
              endTime: new Date('2023-02-14T13:16:34Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:16:34Z'),
              endTime: new Date('2023-02-14T13:16:36Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:16:36Z'),
              endTime: new Date('2023-02-14T13:16:37Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:16:37Z'),
              endTime: new Date('2023-02-14T13:16:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:42Z'),
              endTime: new Date('2023-02-14T13:16:43Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:43Z'),
              endTime: new Date('2023-02-14T13:16:50Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:16:50Z'),
              endTime: new Date('2023-02-14T13:16:51Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:16:51Z'),
              endTime: new Date('2023-02-14T13:16:53Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:16:53Z'),
              endTime: new Date('2023-02-14T13:16:54Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:16:54Z'),
              endTime: new Date('2023-02-14T13:16:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:58Z'),
              endTime: new Date('2023-02-14T13:16:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:16:59Z'),
              endTime: new Date('2023-02-14T13:17:07Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:17:07Z'),
              endTime: new Date('2023-02-14T13:17:08Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:17:08Z'),
              endTime: new Date('2023-02-14T13:17:20Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:17:20Z'),
              endTime: new Date('2023-02-14T13:17:21Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:17:21Z'),
              endTime: new Date('2023-02-14T13:17:35Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:17:35Z'),
              endTime: new Date('2023-02-14T13:17:36Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:17:36Z'),
              endTime: new Date('2023-02-14T13:17:51Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:17:51Z'),
              endTime: new Date('2023-02-14T13:17:52Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:17:52Z'),
              endTime: new Date('2023-02-14T13:18:00Z'),
              valueState: null,
              valueNumber: 150,
            },
            {
              startTime: new Date('2023-02-14T13:18:00Z'),
              endTime: new Date('2023-02-14T13:18:01Z'),
              valueState: null,
              valueNumber: 150,
            },
            {
              startTime: new Date('2023-02-14T13:18:01Z'),
              endTime: new Date('2023-02-14T13:18:04Z'),
              valueState: null,
              valueNumber: 240,
            },
            {
              startTime: new Date('2023-02-14T13:18:04Z'),
              endTime: new Date('2023-02-14T13:18:05Z'),
              valueState: null,
              valueNumber: 240,
            },
            {
              startTime: new Date('2023-02-14T13:18:05Z'),
              endTime: new Date('2023-02-14T13:18:21Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:18:21Z'),
              endTime: new Date('2023-02-14T13:18:22Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:18:22Z'),
              endTime: new Date('2023-02-14T13:18:58Z'),
              valueState: null,
              valueNumber: 450,
            },
            {
              startTime: new Date('2023-02-14T13:18:58Z'),
              endTime: new Date('2023-02-14T13:18:59Z'),
              valueState: null,
              valueNumber: 450,
            },
            {
              startTime: new Date('2023-02-14T13:18:59Z'),
              endTime: new Date('2023-02-14T13:19:18Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:19:18Z'),
              endTime: new Date('2023-02-14T13:19:19Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:19:19Z'),
              endTime: new Date('2023-02-14T13:19:33Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:19:33Z'),
              endTime: new Date('2023-02-14T13:19:34Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:19:34Z'),
              endTime: new Date('2023-02-14T13:19:55Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:19:55Z'),
              endTime: new Date('2023-02-14T13:19:56Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:19:56Z'),
              endTime: new Date('2023-02-14T13:20:15Z'),
              valueState: null,
              valueNumber: 500,
            },
            {
              startTime: new Date('2023-02-14T13:20:15Z'),
              endTime: new Date('2023-02-14T13:20:16Z'),
              valueState: null,
              valueNumber: 500,
            },
            {
              startTime: new Date('2023-02-14T13:20:16Z'),
              endTime: new Date('2023-02-14T13:20:31Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:20:31Z'),
              endTime: new Date('2023-02-14T13:20:32Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:20:32Z'),
              endTime: new Date('2023-02-14T13:21:57Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:57Z'),
              endTime: new Date('2023-02-14T13:21:58Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:21:58Z'),
              endTime: new Date('2023-02-14T13:22:05Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:05Z'),
              endTime: new Date('2023-02-14T13:22:06Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:06Z'),
              endTime: new Date('2023-02-14T13:22:08Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:08Z'),
              endTime: new Date('2023-02-14T13:22:09Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:09Z'),
              endTime: new Date('2023-02-14T13:22:17Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:17Z'),
              endTime: new Date('2023-02-14T13:22:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:18Z'),
              endTime: new Date('2023-02-14T13:22:20Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:20Z'),
              endTime: new Date('2023-02-14T13:22:21Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:21Z'),
              endTime: new Date('2023-02-14T13:22:28Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:28Z'),
              endTime: new Date('2023-02-14T13:22:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:29Z'),
              endTime: new Date('2023-02-14T13:22:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:30Z'),
              endTime: new Date('2023-02-14T13:22:31Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:31Z'),
              endTime: new Date('2023-02-14T13:22:40Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:40Z'),
              endTime: new Date('2023-02-14T13:22:41Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:41Z'),
              endTime: new Date('2023-02-14T13:22:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:42Z'),
              endTime: new Date('2023-02-14T13:22:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:50Z'),
              endTime: new Date('2023-02-14T13:22:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:22:51Z'),
              endTime: new Date('2023-02-14T13:22:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:53Z'),
              endTime: new Date('2023-02-14T13:22:54Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:22:54Z'),
              endTime: new Date('2023-02-14T13:23:01Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:01Z'),
              endTime: new Date('2023-02-14T13:23:02Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:02Z'),
              endTime: new Date('2023-02-14T13:23:04Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:04Z'),
              endTime: new Date('2023-02-14T13:23:05Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:05Z'),
              endTime: new Date('2023-02-14T13:23:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:13Z'),
              endTime: new Date('2023-02-14T13:23:14Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:14Z'),
              endTime: new Date('2023-02-14T13:23:15Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:15Z'),
              endTime: new Date('2023-02-14T13:23:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:16Z'),
              endTime: new Date('2023-02-14T13:23:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:24Z'),
              endTime: new Date('2023-02-14T13:23:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:25Z'),
              endTime: new Date('2023-02-14T13:23:26Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:26Z'),
              endTime: new Date('2023-02-14T13:23:27Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:27Z'),
              endTime: new Date('2023-02-14T13:23:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:35Z'),
              endTime: new Date('2023-02-14T13:23:36Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:36Z'),
              endTime: new Date('2023-02-14T13:23:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:37Z'),
              endTime: new Date('2023-02-14T13:23:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:38Z'),
              endTime: new Date('2023-02-14T13:23:46Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:46Z'),
              endTime: new Date('2023-02-14T13:23:47Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:47Z'),
              endTime: new Date('2023-02-14T13:23:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:48Z'),
              endTime: new Date('2023-02-14T13:23:49Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:49Z'),
              endTime: new Date('2023-02-14T13:23:57Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:57Z'),
              endTime: new Date('2023-02-14T13:23:58Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:23:58Z'),
              endTime: new Date('2023-02-14T13:23:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:23:59Z'),
              endTime: new Date('2023-02-14T13:24:00Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:00Z'),
              endTime: new Date('2023-02-14T13:24:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:08Z'),
              endTime: new Date('2023-02-14T13:24:09Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:09Z'),
              endTime: new Date('2023-02-14T13:24:10Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:10Z'),
              endTime: new Date('2023-02-14T13:24:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:11Z'),
              endTime: new Date('2023-02-14T13:24:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:18Z'),
              endTime: new Date('2023-02-14T13:24:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:19Z'),
              endTime: new Date('2023-02-14T13:24:22Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:22Z'),
              endTime: new Date('2023-02-14T13:24:23Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:23Z'),
              endTime: new Date('2023-02-14T13:24:30Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:30Z'),
              endTime: new Date('2023-02-14T13:24:31Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:31Z'),
              endTime: new Date('2023-02-14T13:24:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:33Z'),
              endTime: new Date('2023-02-14T13:24:34Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:34Z'),
              endTime: new Date('2023-02-14T13:24:42Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:42Z'),
              endTime: new Date('2023-02-14T13:24:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:43Z'),
              endTime: new Date('2023-02-14T13:24:44Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:44Z'),
              endTime: new Date('2023-02-14T13:24:53Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:53Z'),
              endTime: new Date('2023-02-14T13:24:54Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:24:54Z'),
              endTime: new Date('2023-02-14T13:24:55Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:24:55Z'),
              endTime: new Date('2023-02-14T13:25:03Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:03Z'),
              endTime: new Date('2023-02-14T13:25:04Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:04Z'),
              endTime: new Date('2023-02-14T13:25:06Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:06Z'),
              endTime: new Date('2023-02-14T13:25:07Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:07Z'),
              endTime: new Date('2023-02-14T13:25:15Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:15Z'),
              endTime: new Date('2023-02-14T13:25:16Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:16Z'),
              endTime: new Date('2023-02-14T13:25:17Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:17Z'),
              endTime: new Date('2023-02-14T13:25:18Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:18Z'),
              endTime: new Date('2023-02-14T13:25:26Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:26Z'),
              endTime: new Date('2023-02-14T13:25:27Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:27Z'),
              endTime: new Date('2023-02-14T13:25:29Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:29Z'),
              endTime: new Date('2023-02-14T13:25:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:30Z'),
              endTime: new Date('2023-02-14T13:25:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:38Z'),
              endTime: new Date('2023-02-14T13:25:39Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:39Z'),
              endTime: new Date('2023-02-14T13:25:40Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:40Z'),
              endTime: new Date('2023-02-14T13:25:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:49Z'),
              endTime: new Date('2023-02-14T13:25:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:50Z'),
              endTime: new Date('2023-02-14T13:25:51Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:25:51Z'),
              endTime: new Date('2023-02-14T13:25:59Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:25:59Z'),
              endTime: new Date('2023-02-14T13:26:00Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:00Z'),
              endTime: new Date('2023-02-14T13:26:02Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:02Z'),
              endTime: new Date('2023-02-14T13:26:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:03Z'),
              endTime: new Date('2023-02-14T13:26:10Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:10Z'),
              endTime: new Date('2023-02-14T13:26:11Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:11Z'),
              endTime: new Date('2023-02-14T13:26:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:13Z'),
              endTime: new Date('2023-02-14T13:26:14Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:14Z'),
              endTime: new Date('2023-02-14T13:26:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:22Z'),
              endTime: new Date('2023-02-14T13:26:23Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:23Z'),
              endTime: new Date('2023-02-14T13:26:24Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:24Z'),
              endTime: new Date('2023-02-14T13:26:33Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:33Z'),
              endTime: new Date('2023-02-14T13:26:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:34Z'),
              endTime: new Date('2023-02-14T13:26:35Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:35Z'),
              endTime: new Date('2023-02-14T13:26:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:43Z'),
              endTime: new Date('2023-02-14T13:26:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:44Z'),
              endTime: new Date('2023-02-14T13:26:46Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:46Z'),
              endTime: new Date('2023-02-14T13:26:47Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:47Z'),
              endTime: new Date('2023-02-14T13:26:55Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:55Z'),
              endTime: new Date('2023-02-14T13:26:56Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:26:56Z'),
              endTime: new Date('2023-02-14T13:26:57Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:57Z'),
              endTime: new Date('2023-02-14T13:26:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:26:58Z'),
              endTime: new Date('2023-02-14T13:27:00Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:27:00Z'),
              endTime: new Date('2023-02-14T13:27:01Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:27:01Z'),
              endTime: new Date('2023-02-14T13:28:22Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:22Z'),
              endTime: new Date('2023-02-14T13:28:23Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:23Z'),
              endTime: new Date('2023-02-14T13:28:26Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:28:26Z'),
              endTime: new Date('2023-02-14T13:28:27Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:28:27Z'),
              endTime: new Date('2023-02-14T13:28:29Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:29Z'),
              endTime: new Date('2023-02-14T13:28:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:30Z'),
              endTime: new Date('2023-02-14T13:28:33Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:28:33Z'),
              endTime: new Date('2023-02-14T13:28:34Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:28:34Z'),
              endTime: new Date('2023-02-14T13:28:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:37Z'),
              endTime: new Date('2023-02-14T13:28:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:28:38Z'),
              endTime: new Date('2023-02-14T13:28:45Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:28:45Z'),
              endTime: new Date('2023-02-14T13:28:46Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:28:46Z'),
              endTime: new Date('2023-02-14T13:28:58Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:28:58Z'),
              endTime: new Date('2023-02-14T13:28:59Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:28:59Z'),
              endTime: new Date('2023-02-14T13:29:13Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:29:13Z'),
              endTime: new Date('2023-02-14T13:29:14Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:29:14Z'),
              endTime: new Date('2023-02-14T13:29:30Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:29:30Z'),
              endTime: new Date('2023-02-14T13:29:31Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:29:31Z'),
              endTime: new Date('2023-02-14T13:29:38Z'),
              valueState: null,
              valueNumber: 150,
            },
            {
              startTime: new Date('2023-02-14T13:29:38Z'),
              endTime: new Date('2023-02-14T13:29:39Z'),
              valueState: null,
              valueNumber: 150,
            },
            {
              startTime: new Date('2023-02-14T13:29:39Z'),
              endTime: new Date('2023-02-14T13:29:42Z'),
              valueState: null,
              valueNumber: 240,
            },
            {
              startTime: new Date('2023-02-14T13:29:42Z'),
              endTime: new Date('2023-02-14T13:29:43Z'),
              valueState: null,
              valueNumber: 240,
            },
            {
              startTime: new Date('2023-02-14T13:29:43Z'),
              endTime: new Date('2023-02-14T13:29:59Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:29:59Z'),
              endTime: new Date('2023-02-14T13:30:00Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:30:00Z'),
              endTime: new Date('2023-02-14T13:30:36Z'),
              valueState: null,
              valueNumber: 450,
            },
            {
              startTime: new Date('2023-02-14T13:30:36Z'),
              endTime: new Date('2023-02-14T13:30:37Z'),
              valueState: null,
              valueNumber: 450,
            },
            {
              startTime: new Date('2023-02-14T13:30:37Z'),
              endTime: new Date('2023-02-14T13:30:56Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:30:56Z'),
              endTime: new Date('2023-02-14T13:30:57Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:30:57Z'),
              endTime: new Date('2023-02-14T13:31:11Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:31:11Z'),
              endTime: new Date('2023-02-14T13:31:12Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:31:12Z'),
              endTime: new Date('2023-02-14T13:31:33Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:31:33Z'),
              endTime: new Date('2023-02-14T13:31:34Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:31:34Z'),
              endTime: new Date('2023-02-14T13:31:55Z'),
              valueState: null,
              valueNumber: 500,
            },
            {
              startTime: new Date('2023-02-14T13:31:55Z'),
              endTime: new Date('2023-02-14T13:31:56Z'),
              valueState: null,
              valueNumber: 500,
            },
            {
              startTime: new Date('2023-02-14T13:31:56Z'),
              endTime: new Date('2023-02-14T13:32:11Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:32:11Z'),
              endTime: new Date('2023-02-14T13:32:12Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:32:12Z'),
              endTime: new Date('2023-02-14T13:33:39Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:39Z'),
              endTime: new Date('2023-02-14T13:33:40Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:33:40Z'),
              endTime: new Date('2023-02-14T13:33:41Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:33:41Z'),
              endTime: new Date('2023-02-14T13:33:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:33:42Z'),
              endTime: new Date('2023-02-14T13:33:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:33:50Z'),
              endTime: new Date('2023-02-14T13:33:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:33:51Z'),
              endTime: new Date('2023-02-14T13:33:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:33:53Z'),
              endTime: new Date('2023-02-14T13:33:54Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:33:54Z'),
              endTime: new Date('2023-02-14T13:34:01Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:01Z'),
              endTime: new Date('2023-02-14T13:34:02Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:02Z'),
              endTime: new Date('2023-02-14T13:34:04Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:04Z'),
              endTime: new Date('2023-02-14T13:34:05Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:05Z'),
              endTime: new Date('2023-02-14T13:34:13Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:13Z'),
              endTime: new Date('2023-02-14T13:34:14Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:14Z'),
              endTime: new Date('2023-02-14T13:34:15Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:15Z'),
              endTime: new Date('2023-02-14T13:34:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:16Z'),
              endTime: new Date('2023-02-14T13:34:24Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:24Z'),
              endTime: new Date('2023-02-14T13:34:25Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:25Z'),
              endTime: new Date('2023-02-14T13:34:26Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:26Z'),
              endTime: new Date('2023-02-14T13:34:27Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:27Z'),
              endTime: new Date('2023-02-14T13:34:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:34Z'),
              endTime: new Date('2023-02-14T13:34:35Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:35Z'),
              endTime: new Date('2023-02-14T13:34:37Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:37Z'),
              endTime: new Date('2023-02-14T13:34:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:38Z'),
              endTime: new Date('2023-02-14T13:34:46Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:46Z'),
              endTime: new Date('2023-02-14T13:34:47Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:47Z'),
              endTime: new Date('2023-02-14T13:34:49Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:49Z'),
              endTime: new Date('2023-02-14T13:34:50Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:34:50Z'),
              endTime: new Date('2023-02-14T13:34:58Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:58Z'),
              endTime: new Date('2023-02-14T13:34:59Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:34:59Z'),
              endTime: new Date('2023-02-14T13:35:00Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:00Z'),
              endTime: new Date('2023-02-14T13:35:09Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:09Z'),
              endTime: new Date('2023-02-14T13:35:10Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:10Z'),
              endTime: new Date('2023-02-14T13:35:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:11Z'),
              endTime: new Date('2023-02-14T13:35:21Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:21Z'),
              endTime: new Date('2023-02-14T13:35:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:22Z'),
              endTime: new Date('2023-02-14T13:35:23Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:23Z'),
              endTime: new Date('2023-02-14T13:35:31Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:31Z'),
              endTime: new Date('2023-02-14T13:35:32Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:32Z'),
              endTime: new Date('2023-02-14T13:35:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:33Z'),
              endTime: new Date('2023-02-14T13:35:34Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:34Z'),
              endTime: new Date('2023-02-14T13:35:42Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:42Z'),
              endTime: new Date('2023-02-14T13:35:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:43Z'),
              endTime: new Date('2023-02-14T13:35:45Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:45Z'),
              endTime: new Date('2023-02-14T13:35:46Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:46Z'),
              endTime: new Date('2023-02-14T13:35:53Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:53Z'),
              endTime: new Date('2023-02-14T13:35:54Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:35:54Z'),
              endTime: new Date('2023-02-14T13:35:55Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:55Z'),
              endTime: new Date('2023-02-14T13:35:56Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:35:56Z'),
              endTime: new Date('2023-02-14T13:36:05Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:05Z'),
              endTime: new Date('2023-02-14T13:36:06Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:06Z'),
              endTime: new Date('2023-02-14T13:36:07Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:07Z'),
              endTime: new Date('2023-02-14T13:36:15Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:15Z'),
              endTime: new Date('2023-02-14T13:36:16Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:16Z'),
              endTime: new Date('2023-02-14T13:36:17Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:17Z'),
              endTime: new Date('2023-02-14T13:36:18Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:18Z'),
              endTime: new Date('2023-02-14T13:36:26Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:26Z'),
              endTime: new Date('2023-02-14T13:36:27Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:27Z'),
              endTime: new Date('2023-02-14T13:36:29Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:29Z'),
              endTime: new Date('2023-02-14T13:36:30Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:30Z'),
              endTime: new Date('2023-02-14T13:36:38Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:38Z'),
              endTime: new Date('2023-02-14T13:36:39Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:39Z'),
              endTime: new Date('2023-02-14T13:36:40Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:40Z'),
              endTime: new Date('2023-02-14T13:36:49Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:49Z'),
              endTime: new Date('2023-02-14T13:36:50Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:36:50Z'),
              endTime: new Date('2023-02-14T13:36:51Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:51Z'),
              endTime: new Date('2023-02-14T13:36:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:36:52Z'),
              endTime: new Date('2023-02-14T13:37:00Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:00Z'),
              endTime: new Date('2023-02-14T13:37:01Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:01Z'),
              endTime: new Date('2023-02-14T13:37:02Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:02Z'),
              endTime: new Date('2023-02-14T13:37:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:03Z'),
              endTime: new Date('2023-02-14T13:37:11Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:11Z'),
              endTime: new Date('2023-02-14T13:37:12Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:12Z'),
              endTime: new Date('2023-02-14T13:37:13Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:13Z'),
              endTime: new Date('2023-02-14T13:37:14Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:14Z'),
              endTime: new Date('2023-02-14T13:37:22Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:22Z'),
              endTime: new Date('2023-02-14T13:37:23Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:23Z'),
              endTime: new Date('2023-02-14T13:37:24Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:24Z'),
              endTime: new Date('2023-02-14T13:37:25Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:25Z'),
              endTime: new Date('2023-02-14T13:37:33Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:33Z'),
              endTime: new Date('2023-02-14T13:37:34Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:34Z'),
              endTime: new Date('2023-02-14T13:37:35Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:35Z'),
              endTime: new Date('2023-02-14T13:37:36Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:36Z'),
              endTime: new Date('2023-02-14T13:37:43Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:43Z'),
              endTime: new Date('2023-02-14T13:37:44Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:44Z'),
              endTime: new Date('2023-02-14T13:37:46Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:46Z'),
              endTime: new Date('2023-02-14T13:37:47Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:47Z'),
              endTime: new Date('2023-02-14T13:37:55Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:55Z'),
              endTime: new Date('2023-02-14T13:37:56Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:37:56Z'),
              endTime: new Date('2023-02-14T13:37:58Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:58Z'),
              endTime: new Date('2023-02-14T13:37:59Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:37:59Z'),
              endTime: new Date('2023-02-14T13:38:07Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:07Z'),
              endTime: new Date('2023-02-14T13:38:08Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:08Z'),
              endTime: new Date('2023-02-14T13:38:09Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:09Z'),
              endTime: new Date('2023-02-14T13:38:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:18Z'),
              endTime: new Date('2023-02-14T13:38:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:19Z'),
              endTime: new Date('2023-02-14T13:38:20Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:20Z'),
              endTime: new Date('2023-02-14T13:38:21Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:21Z'),
              endTime: new Date('2023-02-14T13:38:28Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:28Z'),
              endTime: new Date('2023-02-14T13:38:29Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:29Z'),
              endTime: new Date('2023-02-14T13:38:31Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:31Z'),
              endTime: new Date('2023-02-14T13:38:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:32Z'),
              endTime: new Date('2023-02-14T13:38:40Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:40Z'),
              endTime: new Date('2023-02-14T13:38:41Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:41Z'),
              endTime: new Date('2023-02-14T13:38:42Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:42Z'),
              endTime: new Date('2023-02-14T13:38:43Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:43Z'),
              endTime: new Date('2023-02-14T13:38:51Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:51Z'),
              endTime: new Date('2023-02-14T13:38:52Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:38:52Z'),
              endTime: new Date('2023-02-14T13:38:54Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:54Z'),
              endTime: new Date('2023-02-14T13:38:55Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:38:55Z'),
              endTime: new Date('2023-02-14T13:39:02Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:39:02Z'),
              endTime: new Date('2023-02-14T13:39:03Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:39:03Z'),
              endTime: new Date('2023-02-14T13:39:04Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:39:04Z'),
              endTime: new Date('2023-02-14T13:39:05Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:39:05Z'),
              endTime: new Date('2023-02-14T13:39:14Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:39:14Z'),
              endTime: new Date('2023-02-14T13:39:15Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:39:15Z'),
              endTime: new Date('2023-02-14T13:39:16Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:39:16Z'),
              endTime: new Date('2023-02-14T13:39:18Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:39:18Z'),
              endTime: new Date('2023-02-14T13:39:19Z'),
              valueState: null,
              valueNumber: 49,
            },
            {
              startTime: new Date('2023-02-14T13:39:19Z'),
              endTime: new Date('2023-02-14T13:40:38Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:38Z'),
              endTime: new Date('2023-02-14T13:40:39Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:39Z'),
              endTime: new Date('2023-02-14T13:40:42Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:40:42Z'),
              endTime: new Date('2023-02-14T13:40:43Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:40:43Z'),
              endTime: new Date('2023-02-14T13:40:44Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:44Z'),
              endTime: new Date('2023-02-14T13:40:45Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:45Z'),
              endTime: new Date('2023-02-14T13:40:48Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:40:48Z'),
              endTime: new Date('2023-02-14T13:40:49Z'),
              valueState: null,
              valueNumber: 55,
            },
            {
              startTime: new Date('2023-02-14T13:40:49Z'),
              endTime: new Date('2023-02-14T13:40:52Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:52Z'),
              endTime: new Date('2023-02-14T13:40:53Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:40:53Z'),
              endTime: new Date('2023-02-14T13:41:01Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:41:01Z'),
              endTime: new Date('2023-02-14T13:41:02Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:41:02Z'),
              endTime: new Date('2023-02-14T13:41:05Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:41:05Z'),
              endTime: new Date('2023-02-14T13:41:06Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:41:06Z'),
              endTime: new Date('2023-02-14T13:41:10Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:41:10Z'),
              endTime: new Date('2023-02-14T13:41:11Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:41:11Z'),
              endTime: new Date('2023-02-14T13:41:19Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:41:19Z'),
              endTime: new Date('2023-02-14T13:41:20Z'),
              valueState: null,
              valueNumber: 86,
            },
            {
              startTime: new Date('2023-02-14T13:41:20Z'),
              endTime: new Date('2023-02-14T13:41:31Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:41:31Z'),
              endTime: new Date('2023-02-14T13:41:32Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:41:32Z'),
              endTime: new Date('2023-02-14T13:41:46Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:41:46Z'),
              endTime: new Date('2023-02-14T13:41:47Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:41:47Z'),
              endTime: new Date('2023-02-14T13:42:02Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:42:02Z'),
              endTime: new Date('2023-02-14T13:42:03Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:42:03Z'),
              endTime: new Date('2023-02-14T13:42:11Z'),
              valueState: null,
              valueNumber: 150,
            },
            {
              startTime: new Date('2023-02-14T13:42:11Z'),
              endTime: new Date('2023-02-14T13:42:12Z'),
              valueState: null,
              valueNumber: 150,
            },
            {
              startTime: new Date('2023-02-14T13:42:12Z'),
              endTime: new Date('2023-02-14T13:42:15Z'),
              valueState: null,
              valueNumber: 240,
            },
            {
              startTime: new Date('2023-02-14T13:42:15Z'),
              endTime: new Date('2023-02-14T13:42:16Z'),
              valueState: null,
              valueNumber: 240,
            },
            {
              startTime: new Date('2023-02-14T13:42:16Z'),
              endTime: new Date('2023-02-14T13:42:33Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:42:33Z'),
              endTime: new Date('2023-02-14T13:42:34Z'),
              valueState: null,
              valueNumber: 280,
            },
            {
              startTime: new Date('2023-02-14T13:42:34Z'),
              endTime: new Date('2023-02-14T13:43:10Z'),
              valueState: null,
              valueNumber: 450,
            },
            {
              startTime: new Date('2023-02-14T13:43:10Z'),
              endTime: new Date('2023-02-14T13:43:11Z'),
              valueState: null,
              valueNumber: 450,
            },
            {
              startTime: new Date('2023-02-14T13:43:11Z'),
              endTime: new Date('2023-02-14T13:43:29Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:43:29Z'),
              endTime: new Date('2023-02-14T13:43:30Z'),
              valueState: null,
              valueNumber: 90,
            },
            {
              startTime: new Date('2023-02-14T13:43:30Z'),
              endTime: new Date('2023-02-14T13:43:44Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:43:44Z'),
              endTime: new Date('2023-02-14T13:43:45Z'),
              valueState: null,
              valueNumber: 130,
            },
            {
              startTime: new Date('2023-02-14T13:43:45Z'),
              endTime: new Date('2023-02-14T13:44:06Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:44:06Z'),
              endTime: new Date('2023-02-14T13:44:07Z'),
              valueState: null,
              valueNumber: 100,
            },
            {
              startTime: new Date('2023-02-14T13:44:07Z'),
              endTime: new Date('2023-02-14T13:44:25Z'),
              valueState: null,
              valueNumber: 500,
            },
            {
              startTime: new Date('2023-02-14T13:44:25Z'),
              endTime: new Date('2023-02-14T13:44:26Z'),
              valueState: null,
              valueNumber: 500,
            },
            {
              startTime: new Date('2023-02-14T13:44:26Z'),
              endTime: new Date('2023-02-14T13:44:44Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:44:44Z'),
              endTime: new Date('2023-02-14T13:44:45Z'),
              valueState: null,
              valueNumber: 700,
            },
            {
              startTime: new Date('2023-02-14T13:44:45Z'),
              endTime: new Date('2023-02-14T13:45:19Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:45:19Z'),
              endTime: new Date('2023-02-14T13:45:20Z'),
              valueState: null,
              valueNumber: 1000,
            },
            {
              startTime: new Date('2023-02-14T13:45:20Z'),
              endTime: new Date('2023-02-14T13:45:53Z'),
              valueState: null,
              valueNumber: 1300,
            },
            {
              startTime: new Date('2023-02-14T13:45:53Z'),
              endTime: new Date('2023-02-14T13:45:54Z'),
              valueState: null,
              valueNumber: 1300,
            },
            {
              startTime: new Date('2023-02-14T13:45:54Z'),
              endTime: new Date('2023-02-14T13:46:24Z'),
              valueState: null,
              valueNumber: 1500,
            },
            {
              startTime: new Date('2023-02-14T13:46:24Z'),
              endTime: new Date('2023-02-14T13:46:25Z'),
              valueState: null,
              valueNumber: 1500,
            },
            {
              startTime: new Date('2023-02-14T13:46:25Z'),
              endTime: new Date('2023-02-14T13:49:40Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:40Z'),
              endTime: new Date('2023-02-14T13:49:41Z'),
              valueState: null,
              valueNumber: 1600,
            },
            {
              startTime: new Date('2023-02-14T13:49:41Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
      ],
      valueStates: [],
      annotations: undefined,
    },
    {
      title: 'Valve',
      datasets: [
        {
          label: 'V1',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T12:53:46Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:46Z'),
              endTime: new Date('2023-02-14T12:53:47Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:47Z'),
              endTime: new Date('2023-02-14T12:55:02Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T12:55:02Z'),
              endTime: new Date('2023-02-14T12:55:03Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T12:55:03Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'V2',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'V3',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'V4',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'V5',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'V6',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
      ],
      valueStates: [],
      annotations: undefined,
    },
    {
      title: 'Relay',
      datasets: [
        {
          label: 'R1',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T12:55:02Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:02Z'),
              endTime: new Date('2023-02-14T12:55:03Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:55:03Z'),
              endTime: new Date('2023-02-14T13:00:32Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:00:32Z'),
              endTime: new Date('2023-02-14T13:00:33Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T13:00:33Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R2',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R3',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R4',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R5',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R6',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R7',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R8',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R9',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R10',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R11',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
      ],
      valueStates: [],
      annotations: undefined,
    },
    {
      title: 'Relay',
      datasets: [
        {
          label: 'R1',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R2',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R3',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R4',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R5',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R6',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R7',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R8',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R9',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R10',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
        {
          label: 'R11',
          unit: null,
          valueType: TimeseriesValueType.NUMBER,
          values: [
            {
              startTime: new Date('2023-02-14T12:53:23Z'),
              endTime: new Date('2023-02-14T12:53:47Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:47Z'),
              endTime: new Date('2023-02-14T12:53:48Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T12:53:48Z'),
              endTime: new Date('2023-02-14T12:53:52Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T12:53:52Z'),
              endTime: new Date('2023-02-14T12:53:53Z'),
              valueState: null,
              valueNumber: 1,
            },
            {
              startTime: new Date('2023-02-14T12:53:53Z'),
              endTime: new Date('2023-02-14T13:50:32Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:32Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
            {
              startTime: new Date('2023-02-14T13:50:33Z'),
              endTime: new Date('2023-02-14T13:50:33Z'),
              valueState: null,
              valueNumber: 0,
            },
          ],
        },
      ],
      valueStates: [],
      annotations: undefined,
    },
  ],
}
