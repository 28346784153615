import React, { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { calendarLegendStyles } from 'src/ui-shared/calendar/Calendar.style'

export const LaundryGroupCalendarLegend = (): ReactElement => {
  const { classes } = calendarLegendStyles()
  const translate = useTranslate()
  const appId = useAppId()

  const noAdHockOpeningType = appId === AppId.SERVICE_MASTER
  const noPriceConditionOptions = appId === AppId.SERVICE_MASTER

  return (
    <Box className={classes.boxMargin} display="flex" flexDirection="row" alignItems="center">
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box display="span" className={classes.openSlot}></Box>
        <Typography className={classes.textStyle} variant="body2">
          {appId === AppId.SERVICE_MASTER ? translate('laundryOpen') : translate('freeSlot')}
        </Typography>
      </Box>

      {noAdHockOpeningType ? null : (
        <Box display="flex" flexDirection="row" alignItems="center" className={classes.legendItemPadding}>
          <Box className={classes.adHocSlot}></Box>
          <Typography className={classes.textStyle} variant="body2">
            {translate('adHocSlot')}
          </Typography>
        </Box>
      )}
      <Box display="flex" flexDirection="row" alignItems="center" className={classes.legendItemPadding}>
        <Box className={classes.blockedSlot}></Box>
        <Typography className={classes.textStyle} variant="body2">
          {appId === AppId.SERVICE_MASTER ? translate('laundryClosed') : translate('blockedSlot')}
        </Typography>
      </Box>
      {noPriceConditionOptions ? null : (
        <Box display="flex" flexDirection="row" alignItems="center" className={classes.legendItemPadding}>
          <Box className={classes.happyHourSlot}>%</Box>
          <Typography className={classes.textStyle} variant="body2">
            {translate('happyHourSlot')}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
