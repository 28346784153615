/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LaundryUserType {
    CARD_ONLY_USER = 'CARD_ONLY_USER',
    APP_USER = 'APP_USER',
    APPCLIP_USER = 'APPCLIP_USER',
    INSTANTAPP_USER = 'INSTANTAPP_USER',
    APP_POS_USER = 'APP_POS_USER'
}


export function LaundryUserTypeFromJSON(json: any): LaundryUserType {
    return LaundryUserTypeFromJSONTyped(json, false);
}

export function LaundryUserTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryUserType {
    return json as LaundryUserType;
}

export function LaundryUserTypeToJSON(value?: LaundryUserType | null): any {
    return value as any;
}

