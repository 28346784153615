import React, { Key, ReactElement } from 'react'
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  Validate,
  ValidationRule,
} from 'react-hook-form'
import { Autocomplete, Box } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'
import { getActiveValueLabel, renderHelperText } from 'src/ui-shared/base/form/control-hook-form/HookForm'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

// Used if we want to get only the valueFieldName from the autocomplete
interface TransformObject<T> {
  labelFieldName: keyof T
  valueFieldName: keyof T
  translateLabel?: boolean
}

interface AutocompleteWrapperProps<T extends FieldValues, TOption, FieldPropertyName extends Path<T>> {
  name: FieldPropertyName
  options: TOption[]
  control: Control<T, any>
  label: string
  activeValue?: string
  validate?:
    | Validate<PathValue<T, FieldPropertyName>, T>
    | Record<string, Validate<PathValue<T, FieldPropertyName>, T>>
    | undefined
  required?: ValidationRule<boolean>
  disabled?: boolean
  disableClearable?: boolean
  freeSolo?: boolean
  transform?: TransformObject<TOption>
  hintText?: string
  onChangeEventListener?: (field: ControllerRenderProps<T, FieldPropertyName>, fieldState: ControllerFieldState) => void
}

export const AutocompleteHookForm = <T extends FieldValues, TOption, FieldPropertyName extends Path<T>>({
  control,
  name,
  label,
  activeValue,
  validate,
  required,
  options,
  disabled,
  disableClearable,
  freeSolo,
  transform,
  hintText,
  onChangeEventListener,
}: AutocompleteWrapperProps<T, TOption, FieldPropertyName>): ReactElement => {
  const translate = useTranslate()

  const handleOnChange = (
    _event: unknown,
    value: TOption | NonNullable<string | TOption> | (string | TOption)[] | null,
    field: ControllerRenderProps<T, FieldPropertyName>,
    fieldState: ControllerFieldState,
  ) => {
    // @ts-ignore
    const transformedValue = transform && value ? value[transform.valueFieldName] : value
    field.onChange(transformedValue)

    if (onChangeEventListener) {
      onChangeEventListener(field, fieldState)
    }
  }

  const renderOptionLabel = (): ((option: string | TOption) => string) | undefined => {
    if (transform) {
      return (option) => {
        let label = typeof option === 'string' ? option : (option[transform.labelFieldName] as unknown as string)
        if (transform.translateLabel) {
          label = translate(label)
        }
        return label
      }
    }
    return undefined
  }

  const getOptionSelected = (option: TOption, value: TOption) => {
    if (transform) {
      return option[transform.valueFieldName] === value
    }
    return option === value
  }

  const getSelectedOption = (option?: TOption) => {
    if (transform && option) {
      return options.find((item) => item[transform.valueFieldName] === option)
    }
    return options.find((item) => item === option)
  }

  const renderOptions = ():
    | ((props: React.HTMLAttributes<HTMLLIElement>, option: TOption) => React.ReactNode)
    | undefined => {
    if (transform) {
      const renderLabel = renderOptionLabel()!
      return function AutocompleteOptionComponent(props, option) {
        return (
          <>
            {/* TODO pst, 2023-04-18, check types here */}
            <li {...props} key={option[transform.valueFieldName] as unknown as Key}>
              {renderLabel(option)}
            </li>
          </>
        )
      }
    }
    return undefined
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: validate, required: required }}
      render={({ field, fieldState }) => {
        const value = field.value

        let labelToUse = label
        // if (activeValue !== undefined && activeValue !== value) {
        if (activeValue !== undefined) {
          // @ts-ignore
          const activeValueLabel = findReferenceObject(options, activeValue, 'data') as SettingSelectFieldOption

          if (activeValueLabel) {
            labelToUse = getActiveValueLabel(translate, label, activeValueLabel.label)
          } else {
            labelToUse = getActiveValueLabel(translate, label, activeValue)
          }
        }

        return (
          <Box>
            <Autocomplete
              multiple={false}
              freeSolo={freeSolo}
              disabled={disabled}
              options={options}
              {...field}
              onChange={(_event, value) => handleOnChange(_event, value, field, fieldState)}
              renderInput={(params) => (
                <TextFieldDefault variant="filled" {...params} label={labelToUse} error={!!fieldState.error} />
              )}
              disableClearable={disableClearable}
              isOptionEqualToValue={getOptionSelected}
              getOptionLabel={renderOptionLabel()}
              value={getSelectedOption(field.value)}
              renderOption={renderOptions()}
            />
            {renderHelperText(fieldState, translate, hintText)}
          </Box>
        )
      }}
    />
  )
}
