/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Colors } from './Colors';
import {
    ColorsFromJSON,
    ColorsFromJSONTyped,
    ColorsToJSON,
} from './Colors';
import type { ServicePackagePricing } from './ServicePackagePricing';
import {
    ServicePackagePricingFromJSON,
    ServicePackagePricingFromJSONTyped,
    ServicePackagePricingToJSON,
} from './ServicePackagePricing';

/**
 * 
 * @export
 * @interface ServicePackageOption
 */
export interface ServicePackageOption {
    /**
     * 
     * @type {string}
     * @memberof ServicePackageOption
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServicePackageOption
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof ServicePackageOption
     */
    readonly description: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServicePackageOption
     */
    activated: boolean;
    /**
     * 
     * @type {ServicePackagePricing}
     * @memberof ServicePackageOption
     */
    pricing: ServicePackagePricing | null;
    /**
     * 
     * @type {Colors}
     * @memberof ServicePackageOption
     */
    colors: Colors | null;
}

/**
 * Check if a given object implements the ServicePackageOption interface.
 */
export function instanceOfServicePackageOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "activated" in value;
    isInstance = isInstance && "pricing" in value;
    isInstance = isInstance && "colors" in value;

    return isInstance;
}

export function ServicePackageOptionFromJSON(json: any): ServicePackageOption {
    return ServicePackageOptionFromJSONTyped(json, false);
}

export function ServicePackageOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServicePackageOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'activated': json['activated'],
        'pricing': ServicePackagePricingFromJSON(json['pricing']),
        'colors': ColorsFromJSON(json['colors']),
    };
}

export function ServicePackageOptionToJSON(value?: ServicePackageOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'activated': value.activated,
        'pricing': ServicePackagePricingToJSON(value.pricing),
        'colors': ColorsToJSON(value.colors),
    };
}

