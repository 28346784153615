import { isAfter, isValid, parse } from 'date-fns'
import { ValidateIBANResult, ValidationErrorsIBAN, validateIBAN } from 'ibantools'
import { TEXT_LENGTH, TextLength } from 'src/ui-shared/constants/Constants'

const VALID = undefined

type ValidationSuccess = typeof VALID

export type ValidationError = {
  errorKey: string
  params?: (string | number | undefined)[]
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
export type ValidationOutput = ValidationError | ValidationSuccess

type ValidatorFunc = (value: string & number) => ValidationOutput
type ValidatorBetweenFunc = (firstValue: string & number, secondValue: string & number) => ValidationOutput
type ValidatorArgs = Array<string | number | undefined>
type ComposeValidatorsFuncReturn = (value: string | undefined) => ValidatorFunc

export const composeValidators =
  (...validators: Array<ValidatorFunc | ValidatorBetweenFunc>): ComposeValidatorsFuncReturn =>
  (...args: ValidatorArgs) =>
    validators.reduce((error: any, validator: any) => error || validator(...args), undefined)

export const required =
  (errorKey = 'validation.required') =>
  (value: string | undefined): ValidationOutput => {
    const isArray = Array.isArray(value)
    if (value && !isArray) {
      return VALID
    } else if (value && isArray) {
      return value.length > 0 ? VALID : { errorKey }
    }
    return { errorKey }
  }

const realNumberRegex = /^-?[0-9]\d*(\.\d+)?$/

const isEmpty = (value: string | undefined | null) => {
  return value === undefined || value === null || value.toString().trim() === ''
}

export const validNumber =
  (errorKey = 'validation.valid.number') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    if (realNumberRegex.test(value)) {
      return VALID
    }
    return {
      errorKey,
    }
  }

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isValidEmail = (value: string | undefined): boolean => (value ? emailRegex.test(value) : false)

export const areAllEmailsValid = (emails: string[]): boolean => {
  let allValid = true
  for (const index in emails) {
    const email = emails[index]
    if (!isValidEmail(email)) {
      console.log(email + ' is not a valid email')
      allValid = false
    }
  }
  return allValid
}
export const validEmail =
  (errorKey = 'validation.email') =>
  (value: string | string[] | undefined): ValidationOutput => {
    if (!value) {
      return VALID
    }

    if (typeof value === 'string') {
      // single email
      if (isValidEmail(value)) {
        return VALID
      }
    } else {
      // array of emails
      if (areAllEmailsValid(value)) {
        return VALID
      }
    }

    return {
      errorKey,
    }
  }

const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi

export const validUrl =
  (errorKey = 'validation.valid.url') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    if (urlRegex.test(value)) {
      return VALID
    }

    return {
      errorKey,
    }
  }

const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/

export const validPhoneNumber =
  (errorKey = 'validation.valid.phoneNumber') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    if (!value.startsWith('+')) {
      return {
        errorKey: 'validation.valid.phoneNumber.prefix',
      }
    }
    if (phoneRegex.test(value)) {
      return VALID
    }
    return {
      errorKey,
    }
  }

export const validDate =
  (errorKey = 'validation.date.invalid') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    const valueAsDate = parse(value, 'dd.MM.yyyy', 0)
    return isValid(valueAsDate) ? VALID : { errorKey }
  }

export const dateIsAfterToday =
  (errorKey = 'validation.date.after.today') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    const valueAsDate = parse(value, 'dd.MM.yyyy', 0)
    const today = new Date()
    return isAfter(valueAsDate, today) ? VALID : { errorKey }
  }

export const validTime =
  (errorKey = 'validation.time.valid') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    const valueAsDate = parse(value, 'dd.MM.yyyy HH:mm', 0)
    return isValid(valueAsDate) ? VALID : { errorKey }
  }

export const maxChar =
  (maxLength: TextLength = TEXT_LENGTH.M) =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    const errorKey = `validation.max.char.${maxLength.key}`
    const allowedChars = maxLength.value

    if (value.length > allowedChars) {
      return { errorKey }
    } else {
      return VALID
    }
  }

// Accepts only 1 or 2 digits before . , and 5 to 7 digits after .
const coordinatesRegex = /^[-]?[0-9]{1,3}([.,][0-9]{1,7})?$/

export const validCoordinates =
  (minLength: number, maxLength: number, errorKey = 'validation.coordinates.valid') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }

    if (coordinatesRegex.test(value)) {
      const intValue = parseInt(value)
      if (intValue >= minLength && intValue <= maxLength) {
        return VALID
      }
    }
    return {
      errorKey,
      params: [minLength, maxLength],
    }
  }

export const validZipCode =
  (errorKey = 'validation.zipCode.valid') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    // no zip code validation for numbers, UK zip codes have letters
    return VALID
  }

export const validZipCodeSize =
  (errorKey = 'validation.zipCode.size') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    if (value.length <= 10) {
      return VALID
    }
    return {
      errorKey,
    }
  }

const numberRegex = /^[0-9]*$/

export const validWlNumber =
  (errorKey = 'validation.wlNumber') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    if (numberRegex.test(value)) {
      return VALID
    }
    return {
      errorKey,
    }
  }

const ibanRegex =
  /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/

export const validIban =
  (errorKey = 'validation.valid.iban') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    if (ibanRegex.test(value)) {
      return VALID
    }
    return {
      errorKey,
    }
  }

export const validIbanChecksum =
  (errorKey = 'validation.valid.iban') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }

    const valueWithoutSpaces = value.replace(/\s/g, '')
    const ibanValidationResult: ValidateIBANResult = validateIBAN(valueWithoutSpaces)
    if (ibanValidationResult.valid) {
      return VALID
    }

    ibanValidationResult.errorCodes.forEach((code) => {
      if (code === ValidationErrorsIBAN.WrongIBANChecksum || code === ValidationErrorsIBAN.ChecksumNotNumber) {
        errorKey = 'validation.valid.ibanChecksum'
      }
    })

    return {
      errorKey,
    }
  }

const rfidCardRegex = /^\d{8}$/

export const validRFIDCard =
  (errorKey = 'validation.valid.rfidCard') =>
  (value: string | undefined): ValidationOutput => {
    if (typeof value === 'object') {
      return VALID
    }
    if (!value || isEmpty(value)) {
      return VALID
    }
    if (rfidCardRegex.test(value)) {
      return VALID
    }
    return {
      errorKey,
    }
  }

const slotTimeRegex = /^([0-1]?[0-9]|2[0-3]):(30|00)$/

export const validSlotTime =
  (errorKey = 'validation.valid.slotTime') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }

    if (slotTimeRegex.test(value)) {
      return VALID
    }
    return {
      errorKey,
    }
  }
export const validSlotEndTime =
  (errorKey = 'validation.valid.slotEndTime') =>
  (endTime: string, values: any): ValidationOutput => {
    if (Date.parse('01/01/2011 ' + values.startTime) < Date.parse('01/01/2011 ' + endTime) || endTime === '00:00') {
      return VALID
    }
    return {
      errorKey,
    }
  }

export const validBillingIncrement =
  (maxAmount: string, errorKey = 'validation.valid.increment') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    const floatVal = parseFloat(value)
    const maxVal = parseFloat(maxAmount)
    if (!isNaN(floatVal) && floatVal >= 0 && floatVal <= maxVal) {
      return VALID
    }

    return {
      errorKey,
      params: [maxVal],
    }
  }

const pricesRegex = /^[0-9]+(\.[0-9]{1,2})?$/

export const validAmount =
  (errorKey = 'validation.valid.price') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }

    if (pricesRegex.test(value)) {
      return VALID
    }

    return {
      errorKey,
    }
  }

const positiveOrNegativePricesRegex = /^(-)?[0-9]+(\.[0-9]{1,2})?$/
export const validPositiveOrNegativeAmount =
  (errorKey = 'validation.valid.price') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }

    if (positiveOrNegativePricesRegex.test(value)) {
      return VALID
    }
    return {
      errorKey,
    }
  }

export const validAmountMax =
  (errorKey = 'validation.valid.amountMax', maxValue: number) =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }

    const intVal = parseInt(value)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (intVal !== undefined && !isNaN(intVal) && intVal <= maxValue) {
      return VALID
    }

    return {
      errorKey,
      params: [maxValue],
    }
  }

export const validAmountMin =
  (errorKey = 'validation.valid.amountMin', minValue: number) =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }

    const intVal = parseInt(value)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (intVal !== undefined && !isNaN(intVal) && intVal >= minValue) {
      return VALID
    }

    return {
      errorKey,
      params: [minValue],
    }
  }
const programTimeRegex = /^\d+$/

export const validProgramTime =
  (errorKey = 'validation.valid.programTime', maxProgramTime: number) =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }

    if (programTimeRegex.test(value)) {
      if (parseInt(value) >= 0 && parseInt(value) <= maxProgramTime) {
        return VALID
      }
      return { errorKey }
    }
    return {
      errorKey,
    }
  }

const pairingIdRegex = /^\d{6}$/
const pairingMacRegex = /^([0-9A-Fa-f]{2}[.:-]?){5}([0-9A-Fa-f]{2})$/i

export const validPairingId =
  (errorKey = 'validation.valid.pairingCode') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }

    if (pairingIdRegex.test(value) || pairingMacRegex.test(value)) {
      return VALID
    }
    return {
      errorKey,
    }
  }

const washMasterTagPrefix = 'https://app.washmaster.ch/tag/'
export const validTagPrefix =
  (errorKey = 'validation.valid.tag') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    if (value.startsWith(washMasterTagPrefix)) {
      return VALID
    }

    return {
      errorKey,
    }
  }

const uuidLength = 36
export const validTagLength =
  (errorKey = 'validation.valid.tagLength') =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    const requiredLength = washMasterTagPrefix.length + uuidLength
    if (value.trim().length === requiredLength) {
      return VALID
    }

    return {
      errorKey,
    }
  }

export const validNumberRange =
  (minLength: number, maxLength: number, errorKey: string) =>
  (value: string | undefined): ValidationOutput => {
    if (!value || isEmpty(value)) {
      return VALID
    }
    const parsedNumber = parseInt(value)
    if (parsedNumber >= minLength && parsedNumber <= maxLength) {
      return VALID
    }

    return {
      errorKey,
      params: [maxLength],
    }
  }
