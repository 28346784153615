import React, { PropsWithChildren, ReactElement } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

const StyledCloseIcon = styled(CloseIcon)({
  cursor: 'pointer',
  float: 'right',
  marginTop: '5px',
  width: '20px',
})

interface ModalDialogProps extends PropsWithChildren {
  open: boolean
  onClose?: () => void
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  fullWidth?: boolean
  withCloseIcon?: boolean
  title: string
  minHeight?: number
}

export const ModalDialog = ({
  children,
  maxWidth = 'md',
  open,
  title,
  onClose,
  fullWidth = true,
  withCloseIcon = true,
  minHeight,
}: ModalDialogProps): ReactElement => {
  return (
    <Dialog
      scroll="paper"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      sx={
        minHeight
          ? {
              '& .MuiDialog-paper': {
                minHeight: 'calc(' + minHeight + '% - 64px)',
              },
            }
          : undefined
      }
    >
      <DialogTitle>
        {withCloseIcon && <StyledCloseIcon onClick={onClose} />}
        <span>{title}</span>
      </DialogTitle>
      {children}
    </Dialog>
  )
}
