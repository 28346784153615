import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Alert, Grid, Typography } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  ChartData,
  Configuration,
  DashboardLaundryStateOverviewApi,
  DashboardLaundryStateOverviews,
  LaundryGroupReference,
} from 'src/service/backend/api'
import { DashboardLaundryStateOverviewApiMock } from 'src/service/backend/api-mock/DashboardLaundryStateOverviewApiMock.'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { groupDatasetsForDoughnutChart } from 'src/service/view-model/base/chart/Chart.utils'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { LaundryAutocomplete } from 'src/ui-shared/form/control/LaundryAutocomplete'
import { DashboardLoadingIndicator } from 'src/ui/page/sm/index/DashboardLoadingIndicator'

const AsyncChart = React.lazy(() => import('src/ui-shared/chart/Chart').then(({ Chart }) => ({ default: Chart })))

interface Props {
  useMockData: boolean
}

export const DashboardLaundryStateWidget = ({ useMockData }: Props): ReactElement => {
  const translate = useTranslate()

  // api
  const httpConfiguration: Configuration = useContext(HttpContext)
  const dlsoApi = useMockData
    ? new DashboardLaundryStateOverviewApiMock()
    : new DashboardLaundryStateOverviewApi(httpConfiguration)

  // state
  const [dlsOverviews, setDlsOverviews] = useState<DashboardLaundryStateOverviews | null>(null)
  const [dlsOverviewsErrorMessage, setDlsOverviewsErrorMessage] = useState<string | null>(null)
  // laundryGroup will be undefined during loading of all laundry groups, null if none selected after loading
  const [laundryGroup, setLaundryGroup] = useState<LaundryGroupReference | undefined | null>(undefined)

  // load data function
  const loadDashboardLaundryStateOverviews = () => {
    if (laundryGroup === undefined) {
      // laundry groups still loading
      return
    }

    if (laundryGroup) {
      setDlsOverviews(null)
      setDlsOverviewsErrorMessage(null)
      dlsoApi
        .dashboardSmLaundryStateOverviewGet(laundryGroup.id)
        .then((data) => {
          setDlsOverviews(data)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setDlsOverviews(null)
          setDlsOverviewsErrorMessage(errorMessage)
        })
    } else {
      // laundry groups loaded but none selected
      setDlsOverviews({ laundryCharts: [] })
    }
  }

  // load data on mount
  useEffect(() => {
    loadDashboardLaundryStateOverviews()
  }, [laundryGroup, useMockData])

  const CHART_COLORS = ['#CE0F69', '#7a7a7a', '#9B91C1', '#745BA3', '#9e9e9e', '#F3F3F3', '#000000']
  return (
    <>
      <Typography variant="h4" mb={2}>
        {translate('laundryStateOverview')}
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <LaundryAutocomplete
            laundryGroup={laundryGroup}
            setLaundryGroup={setLaundryGroup}
            preselectFirstLaundryGroup
            limitToActiveOrganization
          />
        </Grid>
      </Grid>
      {dlsOverviewsErrorMessage ? (
        <ErrorMessage message={dlsOverviewsErrorMessage} />
      ) : dlsOverviews ? (
        <Grid container mt={2} mb={2} spacing={1}>
          {dlsOverviews.laundryCharts.map((laundryChart) => {
            const loadChart = (): Promise<ChartData> => {
              return new Promise((resolve, reject) => {
                const groupedChartData = groupDatasetsForDoughnutChart(laundryChart.chart, true)
                resolve(groupedChartData)
              })
            }
            return (
              <Grid item xs={3} key={laundryChart.laundry.id}>
                <Typography variant="h6" align="center">
                  {laundryChart.laundry.name}
                </Typography>
                <AsyncChart
                  loadChart={loadChart}
                  chartSettings={undefined}
                  hideTitle
                  heightOverride={170}
                  defaultChartColors={CHART_COLORS}
                />
              </Grid>
            )
          })}

          {dlsOverviews.laundryCharts.length === 0 ? (
            <Grid container>
              <Grid item xs={6} pl={1}>
                <Alert severity={'warning'}>{translate('table.noDataFound')}</Alert>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <DashboardLoadingIndicator />
      )}
    </>
  )
}
