import { MachineCategory, UtilizationChartFactType, UtilizationChartGroupBy } from 'src/service/backend/api'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'
import { ChartSettingsWithOrganization, DEFAULT_CHART_SETTINGS } from 'src/service/view-model/base/chart/ChartViewModel'

export interface ProgramUsageChartSettings extends ChartSettingsWithOrganization {
  groupBy: UtilizationChartGroupBy
  factType: UtilizationChartFactType
  laundryGroupId?: string
  laundryId?: string
  machineId?: string
  machineCategory?: MachineCategory
  programMode?: string
  programName?: string
  temperature?: number
}

export const DEFAULT_PROGRAM_USAGE_CHART_SETTINGS: ProgramUsageChartSettings = {
  ...DEFAULT_CHART_SETTINGS,
  groupBy: UtilizationChartGroupBy.TOTAL,
  factType: UtilizationChartFactType.COUNT,
}

export interface ProgramUsageChartGroupByObject {
  id: UtilizationChartGroupBy
  name: string
}

export interface ProgramUsageChartFactTypeObject {
  id: UtilizationChartFactType
  name: string
}

export const PROGRAM_USAGE_CHART_GROUPBY_OBJECTS: ProgramUsageChartGroupByObject[] = [
  {
    id: UtilizationChartGroupBy.TOTAL,
    name: 'total',
  },
  {
    id: UtilizationChartGroupBy.ORGANIZATION,
    name: 'organization',
  },
  {
    id: UtilizationChartGroupBy.LAUNDRY_GROUP,
    name: 'laundryGroup',
  },
  {
    id: UtilizationChartGroupBy.LAUNDRY,
    name: 'laundry',
  },
  {
    id: UtilizationChartGroupBy.MACHINE,
    name: 'machine',
  },
  {
    id: UtilizationChartGroupBy.MACHINE_CATEGORY,
    name: 'machineCategory',
  },
  {
    id: UtilizationChartGroupBy.PROGRAM,
    name: 'program',
  },
]

export const getProgramUsageGroupByObject = (
  programUsageGroupBy: UtilizationChartGroupBy | undefined,
): ProgramUsageChartGroupByObject | undefined => {
  return findReferenceObject(PROGRAM_USAGE_CHART_GROUPBY_OBJECTS, programUsageGroupBy)
}

export const PROGRAM_USAGE_CHART_FACTTYPE_OBJECTS: ProgramUsageChartFactTypeObject[] = [
  {
    id: UtilizationChartFactType.COUNT,
    name: 'numOfProgramUsages',
  },
]

export const OUTPUT_CHART_FACTTYPE_OBJECTS: ProgramUsageChartFactTypeObject[] = [
  {
    id: UtilizationChartFactType.OUTPUT,
    name: 'output',
  },
]

export const COMPARE_MACHINE_CHART_FACTTYPE_OBJECTS: ProgramUsageChartFactTypeObject[] = [
  {
    id: UtilizationChartFactType.UTILIZATION,
    name: 'utilization',
  },
]

export const getProgramUsageFactTypeObject = (
  programUsageFactType: UtilizationChartFactType | undefined,
  isUtilizationChart: boolean,
  isOutputChart: boolean,
): ProgramUsageChartFactTypeObject | undefined => {
  if (!programUsageFactType) {
    return undefined
  }

  let factTypeObjects: ProgramUsageChartFactTypeObject[]
  if (isUtilizationChart) {
    factTypeObjects = COMPARE_MACHINE_CHART_FACTTYPE_OBJECTS
  } else if (isOutputChart) {
    factTypeObjects = OUTPUT_CHART_FACTTYPE_OBJECTS
  } else {
    factTypeObjects = PROGRAM_USAGE_CHART_FACTTYPE_OBJECTS
  }

  return findReferenceObject(factTypeObjects, programUsageFactType)
}
