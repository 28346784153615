import moment from 'moment'
import { formatDateTime, formatTime } from 'src/service/utils/DateFormatUtils'

export const getStartOfWeekUTC = (anchorDate: Date): Date => {
  const anchorMoment = moment(anchorDate)
  const startOfWeek = anchorMoment.startOf('isoWeek')
  const resultDate = startOfWeek.toDate()
  const resultUTC = new Date(Date.UTC(resultDate.getFullYear(), resultDate.getMonth(), resultDate.getDate()))
  return resultUTC
}

export const getDateUTC = (date: Date): Date => {
  const resultUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return resultUTC
}

export const getStartOfWeek = (anchorDate: Date): Date => {
  return moment(anchorDate).startOf('isoWeek').toDate()
}

export const getEndOfWeek = (anchorDate: Date): Date => {
  return moment(anchorDate).endOf('isoWeek').toDate()
}

export const getEndOfWeekMoment = (dateMoment: moment.Moment): moment.Moment => {
  return dateMoment.endOf('isoWeek')
}

export const getWeekBefore = (anchorDate: Date): Date => {
  return getWeeksBefore(anchorDate, 1)
}

export const getWeeksBefore = (anchorDate: Date, weeks: number): Date => {
  return moment(anchorDate)
    .subtract(7 * weeks, 'days')
    .toDate()
}

export const getEndOfDay = (anchorDate: Date): Date => {
  return moment(anchorDate).endOf('day').toDate()
}

export const formatMomentToTime = (moment: moment.Moment): string => {
  const time = formatTime(moment.toDate())
  return time
}
export const formatMomentToDateTime = (moment: moment.Moment): string => {
  const time = formatDateTime(moment.toDate())
  return time
}

export const getLastMonth = (anchorDate: Date): Date => {
  return getLastMonths(anchorDate, 1)
}

export const getLastMonths = (anchorDate: Date, monthsToSubstract: number): Date => {
  return moment(anchorDate).subtract(monthsToSubstract, 'month').toDate()
}

export const getTomorrow = (anchorDate: Date): Date => {
  return moment(anchorDate).add(1, 'days').toDate()
}

export const getDayOfWeekNumber = (dayOfWeek: string): number => {
  const dayOfWeekMoment = moment(dayOfWeek)
  return dayOfWeekMoment.isoWeekday()
}

export const getFirstDayOfYearDate = (date: Date): Date => {
  const firstDayOfYearMoment = moment(date).startOf('year')
  const firstDayOfYearDate = firstDayOfYearMoment.toDate()
  return firstDayOfYearDate
}

export const getLastDayOfYearDate = (date: Date): Date => {
  const lastDayOfYearMoment = moment(date).endOf('year')
  const lastDayOfYearDate = lastDayOfYearMoment.toDate()
  return lastDayOfYearDate
}

const getTimeOnlyDate = (time: string): Date => {
  const timeDate = moment('1970-01-01 ' + time)
  return timeDate.toDate()
}

export const calculateDuration = (startTime: string, endTime: string): number => {
  const startTimeDate = getTimeOnlyDate(startTime)
  const endTimeDate = getTimeOnlyDate(endTime)

  const startTimeMoment = moment(startTimeDate)
  const endTimeMoment = moment(endTimeDate)

  if (endTime === '00:00') {
    endTimeMoment.add(1, 'day')
  }

  const duration = endTimeMoment.diff(startTimeMoment, 'minutes')

  return duration
}

export const removeTimeFromDate = (date: Date): Date => {
  const newDate = new Date(date.getTime())
  newDate.setHours(0)
  newDate.setMinutes(0)
  newDate.setSeconds(0)
  newDate.setMilliseconds(0)
  return newDate
}

export const removeTimeFromDateEndOfDay = (date: Date): Date => {
  const newDate = new Date(date.getTime())
  newDate.setHours(23)
  newDate.setMinutes(59)
  newDate.setSeconds(59)
  newDate.setMilliseconds(0)
  return newDate
}
