import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material'
import Button from '@mui/material/Button'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  Configuration,
  MachineType,
  PageablePrograms,
  Program,
  ProgramType,
  ProgramsApi,
  ProgramsMachineApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocaleOptional } from 'src/service/utils/DateFormatUtils'
import { formatTemperatureOptional } from 'src/service/utils/NumberFormatUtils'
import { Data } from 'src/service/view-model/base/Data'
import { PROGRAM_TYPES, getProgramTypeName } from 'src/service/view-model/program/ProgramViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import {
  displayTableHeaders,
  displayTablePagination,
  displayTableRows,
  displayTableSelectedRows,
} from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettings,
  getDefaultTableSettings,
  tableSettingsSort,
} from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { useUserRegionLocale } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'metaData.programIndex',
    label: 'programInfo.index',
  },
  {
    id: 'metaData.temperature',
    label: 'temperature',
  },
  {
    id: 'metaData.programName',
    label: 'programInfo.programName',
  },
  {
    id: 'programType',
    label: 'programInfo.type',
    noSort: true,
  },
  {
    id: 'lastModified',
    label: 'programInfo.lastUpdate',
    noSort: true,
  },
]

export const ResponsiveTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '380px',
  [theme.breakpoints.down('lg')]: {
    height: '300px',
  },
  [theme.breakpoints.down('md')]: {
    height: '250px',
  },
}))

interface ProgramTableSettings extends TableSettings {
  orderBy: string
  programType?: ProgramType
}

export interface Props {
  open: boolean
  titleKey: string
  confirmationKey: string
  onCancel: () => void
  allPositions?: number
  availablePositions?: number
  addSelectedRows: (programs: Program[]) => void
  machineId?: string
  machineType: MachineType
  programType?: ProgramType
  multipleSelect?: boolean
}

export const ProgramAddModal: FC<Props> = ({
  open,
  titleKey,
  confirmationKey,
  onCancel,
  allPositions,
  availablePositions,
  addSelectedRows,
  machineId,
  machineType,
  programType,
  multipleSelect = false,
}): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const regionLocale = useUserRegionLocale()

  const activeOrganization = useActiveOrganization()
  if (!activeOrganization && !machineId) {
    console.log('$Error', activeOrganization, machineId)
    throw Error('Organization must be selected')
  }

  const organizationId = activeOrganization?.id

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programsApi = new ProgramsApi(httpConfiguration)
  const programsMachineApi = new ProgramsMachineApi(httpConfiguration)

  const [data, setData] = useState<TableData<Program>>(DEFAULT_DATA)
  const [tableSettings, setTableSettings] = useState<ProgramTableSettings>({
    ...getDefaultTableSettings(),
    programType: programType,
    orderBy: 'metaData.programIndex',
    orderDir: 'asc',
  })
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set())
  const [remainingPositionsCount, setRemainingPositionsCount] = useState<number | undefined>(availablePositions)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)

  // load data on state change
  useEffect(() => {
    let active = true
    handleClearSelectedRows()
    handleResetRemainingPositionsCount()

    setLoading(true)
    setErrorMessage(null)

    let programs: Promise<PageablePrograms>

    if (machineId) {
      programs = programsMachineApi
        .machinesMachineIdProgramsGet(
          machineId,
          tableSettings.size,
          tableSettings.page,
          tableSettingsSort(tableSettings).replaceAll('metaData.', ''),
          tableSettings.search,
          tableSettings.programType,
        )
        .then((result) => ({
          result: result.result.map((p) => p.program),
          page: result.page,
        }))
    } else {
      programs = programsApi.programsGet(
        organizationId!,
        machineType,
        tableSettings.size,
        tableSettings.page,
        tableSettingsSort(tableSettings),
        tableSettings.search,
        tableSettings.programType,
      )
    }

    programs
      .then((data) => {
        if (active) {
          updateTableSettingsFromData(data as Data<any>, tableSettings)
          setTableSettings(tableSettings)

          setData(mapData(data as Data<any>))
          setLoading(false)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [tableSettings, activeOrganization])

  // handle events
  const handleClearSelectedRows = () => {
    if (selectedRows.size > 0) {
      setSelectedRows(new Set())
    }
  }

  const handleResetRemainingPositionsCount = () => {
    if (availablePositions) {
      setRemainingPositionsCount(availablePositions)
    }
  }

  const handleSelectRow = (selectedRow: Program, checked: boolean) => {
    const setOfSelectedRows = new Set(selectedRows)
    const programMetadata = selectedRow.metaData
    const isStandardProgram = programMetadata.programType === ProgramType.STANDARD
    const haveRemainingPositions = remainingPositionsCount !== undefined ? remainingPositionsCount > 0 : true
    const canSelectProgram = isStandardProgram || haveRemainingPositions

    // single program select
    if (!multipleSelect) {
      setOfSelectedRows.clear()
    }

    if (checked && canSelectProgram) {
      setOfSelectedRows.add(programMetadata.id)

      // decrement counter for remainingPositions if custom program is selected
      if (!isStandardProgram && remainingPositionsCount !== undefined) {
        setRemainingPositionsCount(remainingPositionsCount - 1)
      }
    } else if (!checked) {
      setOfSelectedRows.delete(programMetadata.id)

      // increment counter for remainingPositions if custom program is unselected
      if (!isStandardProgram && remainingPositionsCount !== undefined) {
        setRemainingPositionsCount(remainingPositionsCount + 1)
      }
    }

    setSelectedRows(setOfSelectedRows)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedRowsMap = new Set(selectedRows)
      let customProgramsOnPage = 0

      data.data.forEach((item) => {
        const programMetadata = item.metaData

        newSelectedRowsMap.add(programMetadata.id)
        if (programMetadata.programType === ProgramType.CUSTOM) {
          customProgramsOnPage++
        }
      })

      // check if custom programs on page are less than available positions on machine
      if (remainingPositionsCount !== undefined && customProgramsOnPage <= remainingPositionsCount) {
        const remainingPositions = availablePositions! - customProgramsOnPage
        setRemainingPositionsCount(remainingPositions)
        setSelectedRows(newSelectedRowsMap)
      }
      // TODO DAV 2023-05-04 else maybe show warning?

      return
    }

    handleResetRemainingPositionsCount()
    setSelectedRows(new Set())
  }

  const isRowSelected = (selectedRow: Program) => {
    return selectedRows.has(selectedRow.metaData.id)
  }

  const handleSubmit = () => {
    if (selectedRows.size > 0) {
      // get programs from pageable table data returned, since there is no option to select multiple programs from different pages
      const selectedPrograms = data.data.filter((item) => isRowSelected(item))
      addSelectedRows(selectedPrograms)
      onCancel()
    }
  }

  const getFilter = (headCellId: string): ReactElement | undefined => {
    let filter
    if (headCellId === 'programType' && !programType) {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByProgramType')}
          label={translate('programInfo.type')}
          filter={'programType'}
          labelFieldName="name"
          valueFieldName="type"
          options={PROGRAM_TYPES.map((item) => {
            return { type: item.type, name: translate(item.name) }
          })}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }
    return filter
  }

  // JSX
  const nonEmptyRows = data.data.map((item, index) => {
    const programMetadata = item.metaData

    return (
      <TableRow
        className={tableClasses.tableRow}
        key={index}
        selected={isRowSelected(item)}
        onClick={() => handleSelectRow(item, !isRowSelected(item))}
      >
        {multipleSelect && (
          <>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                onChange={(_, checked) => handleSelectRow(item, checked)}
                onClick={(e) => e.stopPropagation()}
                checked={isRowSelected(item)}
              />
            </TableCell>
          </>
        )}
        <TableCell>{programMetadata.programIndex}</TableCell>
        <TableCell>{formatTemperatureOptional(programMetadata.temperature, programMetadata.temperatureUnit)}</TableCell>
        <TableCell>{programMetadata.programName}</TableCell>
        <TableCell>{getProgramTypeName(programMetadata.programType, translate)}</TableCell>
        <TableCell>{formatDateTimeForLocaleOptional(programMetadata.lastModified, regionLocale)}</TableCell>
      </TableRow>
    )
  })

  // generic JSX
  const displayTable = (
    <>
      {remainingPositionsCount !== undefined && allPositions !== undefined && (
        <DialogContentText>{translate('availablePositions', remainingPositionsCount, allPositions)}</DialogContentText>
      )}
      {remainingPositionsCount === 0 && (
        <ErrorMessage message={translate('noAvailablePositions')} severity={'warning'} />
      )}
      <TableSearchForm handleSearch={handleSearch} inputSearchValue={inputSearchValue} />
      <ResponsiveTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {multipleSelect && data.totalElements > 0 && (
                <TableCell key="selectable" sx={{ padding: '0 0 0 4px' }}>
                  <Checkbox
                    color="primary"
                    indeterminate={selectedRows.size > 0 && selectedRows.size < data.data.length}
                    checked={selectedRows.size > 0 && selectedRows.size === data.data.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              {displayTableHeaders(headCells, tableSettings, setTableSettings, translate, getFilter)}
            </TableRow>
          </TableHead>
          <TableBody>{displayTableRows(data, loading, headCells.length, nonEmptyRows)}</TableBody>
        </Table>
      </ResponsiveTableContainer>
      <Box display={'flex'} justifyContent={'space-between'}>
        {displayTableSelectedRows(selectedRows.size, translate)}
        {displayTablePagination(data.totalElements, tableSettings, setTableSettings, translate)}
      </Box>
    </>
  )

  return (
    <ModalDialog open={open} onClose={onCancel} title={translate(titleKey)} maxWidth="md">
      <DialogContent>{errorMessage ? <ErrorMessage message={errorMessage} /> : displayTable}</DialogContent>
      <DialogActions>
        <Box mt={2} p={2} display="flex" justifyContent="flex-end">
          <Button variant="text" color="primary" size="large" className={sharedClasses.ButtonMargin} onClick={onCancel}>
            {translate('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={selectedRows.size === 0}
            onClick={handleSubmit}
          >
            {translate(confirmationKey)}
          </Button>
        </Box>
      </DialogActions>
    </ModalDialog>
  )
}
