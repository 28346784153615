import { ManufacturerModel, ManufacturerReference } from 'src/service/backend/api'

export interface ManufacturerModelViewModel {
  name: string
  manufacturer: ManufacturerReference | string
  productNumber: string
}

export const mapViewModelToManufacturerModel = (viewModel: ManufacturerModelViewModel): ManufacturerModel => {
  return {
    modelName: viewModel.name,
    manufacturer: viewModel.manufacturer,
    productNumber: viewModel.productNumber,
  } as ManufacturerModel
}

export const createManufacturerModelViewModel = (manufacturerModel?: ManufacturerModel): ManufacturerModelViewModel => {
  if (!manufacturerModel) {
    return {
      name: '',
      productNumber: '',
      manufacturer: '',
    }
  }

  return {
    name: manufacturerModel.modelName,
    productNumber: manufacturerModel.productNumber,
    manufacturer: manufacturerModel.manufacturer,
  }
}
