/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CalendarEntryType {
    RESERVATION = 'RESERVATION',
    MAINTENANCE = 'MAINTENANCE',
    ACTIVE_USAGE = 'ACTIVE_USAGE',
    AD_HOC_RESERVATION = 'AD_HOC_RESERVATION'
}


export function CalendarEntryTypeFromJSON(json: any): CalendarEntryType {
    return CalendarEntryTypeFromJSONTyped(json, false);
}

export function CalendarEntryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEntryType {
    return json as CalendarEntryType;
}

export function CalendarEntryTypeToJSON(value?: CalendarEntryType | null): any {
    return value as any;
}

