import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Chip, Paper, SxProps, TableCell, TableRow, Theme } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Colors,
  Configuration,
  LaundryGroupSubscriptionOverview,
  SubscriptionsLaundryGroupsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { Data, OrderBy } from 'src/service/view-model/base/Data'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettings,
  getDefaultTableSettings,
  tableSettingsSort,
} from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'

const headCells: HeadCells[] = [
  {
    id: 'name',
    label: 'subscription.name',
  },
  {
    id: 'organization',
    label: 'organization',
  },
  {
    id: 'wlNumber',
    label: 'wlNumber',
  },
  {
    id: 'servicePackage',
    label: 'subscription.servicePackage',
    noSort: true,
  },
]

interface LaundryGroupSubscriptionTableSettings extends TableSettings {
  orderBy: OrderBy<keyof LaundryGroupSubscriptionOverview>
}

interface Props {
  navigateToItem: (laundryGroupId?: string) => void
}

export const ServicePackagePlanLaundryGroupTable: React.FC<Props> = ({ navigateToItem }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const translate = useTranslate()
  const location = useLocation()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const subscriptionLaundryGroupsApi = new SubscriptionsLaundryGroupsApi(httpConfiguration)

  // state
  const [data, setData] = useState<TableData<LaundryGroupSubscriptionOverview>>(DEFAULT_DATA)
  const defaultTableSettings: LaundryGroupSubscriptionTableSettings = getDefaultTableSettings()
  const [tableSettings, setTableSettings] = useState<LaundryGroupSubscriptionTableSettings>(defaultTableSettings)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // load data
  useEffect(() => {
    let active = true
    setLoading(true)

    subscriptionLaundryGroupsApi
      .subscriptionsLaundrygroupsGet(tableSettings.size, tableSettings.page, tableSettingsSort(tableSettings))
      .then((data) => {
        if (active) {
          updateTableSettingsFromData(data as Data<any>, tableSettings)
          setTableSettings(tableSettings)

          setData(mapData(data as Data<any>))
          setErrorMessage(null)
          setLoading(false)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [tableSettings])

  const getChipStyling = (color?: Colors | null): SxProps<Theme> | undefined => {
    if (color) {
      const backgroundColor = `#${color.bgColor}`
      const fontColor = `#${color.fgColor}`

      return { backgroundColor: backgroundColor, color: fontColor }
    }
  }

  // JSX
  const nonEmptyRows = data.data.map((item) => {
    return (
      <TableRow className={tableClasses.tableRow} key={item.id} onClick={navigateToItem.bind(this, item.id)}>
        <TableCell>
          <TruncatedTooltip maxChars={35} value={item.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={30} value={item.organization.name} />
        </TableCell>
        <TableCell>{item.wlNumber}</TableCell>
        <TableCell style={{ paddingTop: '0px', paddingBottom: '0px' }}>
          <Chip
            sx={getChipStyling(item.activatedServicePackagePlan.colors)}
            label={item.activatedServicePackagePlan.name}
          />
        </TableCell>
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  return (
    <Paper elevation={0}>
      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        loading={loading}
        translate={translate}
      />
    </Paper>
  )
}
