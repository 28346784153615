import React, { ReactElement, useContext, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { useNavigate } from 'react-router'
import { Box, Button, Divider, Grid, Paper } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  Configuration,
  LaundriesApi,
  LaundryReference,
  MachinesApi,
  Manufacturer,
  ManufacturerModelReference,
  ManufacturerModelsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  MachineUnconnectedViewModel,
  mapMachineUnconnectedViewModelToMachine,
} from 'src/service/view-model/machine/MachineViewModel'
import { getOfflineMachineTypesAsOptions } from 'src/service/view-model/machine/Machines'
import { AsyncAutoCompleteValidate } from 'src/ui-shared/base/form/control/AsyncAutoCompleteValidate'
import { AutoCompleteField } from 'src/ui-shared/base/form/control/AutoCompleteField'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { required } from 'src/ui-shared/base/form/validation/Validators'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { usePrompt } from 'src/ui-shared/base/hooks/usePrompt'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

export const MachineUnconnectedCreatePage = (): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  const navigate = useNavigate()
  const appId = useAppId()
  const activeOrganization = useActiveOrganization()

  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const manufacturersApi = new ManufacturerModelsApi(httpConfiguration)
  const machinesApi = new MachinesApi(httpConfiguration)
  const laundriesApi = new LaundriesApi(httpConfiguration)

  const [loading, setLoading] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  useHotKeysForm()

  usePrompt(translate('notification.form.unsaved.changes'), dirty)

  // load data
  const laundriesSearch = (searchText: string): Promise<LaundryReference[]> => {
    const organizationId = activeOrganization?.id
    const organizationIds = organizationId ? [organizationId] : undefined
    return laundriesApi.laundriesRefGet(undefined, searchText, undefined, organizationIds)
  }

  const manufacturersSearch = (searchText: string): Promise<Manufacturer[]> => {
    return manufacturersApi.manufacturersRefGet(undefined, searchText)
  }

  const manufacturerModelsSearch = (
    searchText: string,
    manufacturerId?: string,
  ): Promise<ManufacturerModelReference[]> => {
    if (manufacturerId) {
      return manufacturersApi.manufacturerModelsRefGet(undefined, searchText, manufacturerId)
    }
    return Promise.resolve([])
  }

  const onSubmitSuccess = () => {
    setDirty(false)
    setLoading(false)
    navigateBack()
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  // events
  const navigateBack = () => {
    navigate(`/${appId}/machines`)
  }

  const submitForm = (values: Partial<MachineUnconnectedViewModel>) => {
    setLoading(true)
    setDirty(false)

    const viewModel = values as MachineUnconnectedViewModel
    const machine = mapMachineUnconnectedViewModelToMachine(viewModel)

    machinesApi
      .machinesPost(machine)
      .then(() => {
        onSubmitSuccess()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  return (
    <ScreenLayout title={translate('createUnconnectedAsset')} onBack={navigateBack} actionsWidth={50}>
      <Paper elevation={0}>
        <LoadingIndicator loading={loading} />
        <Divider />
        <Box pt={2}>
          <Form<Partial<MachineUnconnectedViewModel>>
            onSubmit={submitForm}
            render={({ handleSubmit, submitting, pristine, values, form }) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                    <Grid item {...ITEM_BREAKPOINTS}>
                      <TextField
                        disabled={submitting}
                        name="name"
                        fullWidth
                        label={translate('machineName')}
                        validate={required()}
                      />
                    </Grid>
                    <Grid item {...ITEM_BREAKPOINTS}>
                      <TextField
                        disabled={submitting}
                        name="serialNumber"
                        fullWidth
                        label={translate('serialNumber')}
                        validate={required()}
                      />
                    </Grid>
                    <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                      <AutoCompleteField
                        disabled={submitting}
                        label={translate('member.machinetype')}
                        name="type"
                        options={getOfflineMachineTypesAsOptions(translate)}
                        validate={required()}
                      />
                    </Grid>
                    <Grid item {...ITEM_BREAKPOINTS}>
                      <AsyncAutoCompleteValidate
                        disabled={submitting}
                        name="manufacturer"
                        validate={required()}
                        label={translate('manufacturer')}
                        delay={300}
                        labelFieldName="name"
                        loadOptionsFunction={manufacturersSearch}
                      />
                      <OnChange name="manufacturer">
                        {() => {
                          setTimeout(() => {
                            if (!values.manufacturer) {
                              form.change('manufacturerModel', undefined)
                            }
                          }, 0)
                        }}
                      </OnChange>
                    </Grid>
                    <Grid item {...ITEM_BREAKPOINTS}>
                      <AsyncAutoCompleteValidate
                        key={`${values.manufacturer?.id}`}
                        disabled={submitting || !values.manufacturer}
                        name="manufacturerModel"
                        validate={required()}
                        label={translate('manufacturerModel')}
                        delay={300}
                        labelFieldName="modelName"
                        getOptionLabel={(item) => {
                          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                          if (!item) {
                            return ''
                          }
                          const modelRef = item as ManufacturerModelReference
                          return modelRef.modelName + ' (' + modelRef.productNumber + ')'
                        }}
                        minCharsForSearch={0}
                        loadOptionsFunction={(search) => manufacturerModelsSearch(search, values.manufacturer?.id)}
                      />
                    </Grid>
                    <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                      <AsyncAutoCompleteValidate
                        disabled={submitting}
                        name="laundryRef"
                        validate={required()}
                        label={translate('laundry')}
                        delay={300}
                        labelFieldName="name"
                        loadOptionsFunction={laundriesSearch}
                      />
                    </Grid>
                  </Grid>
                  <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                    <TextField
                      disabled={submitting}
                      label={translate('notes')}
                      type="text"
                      name="notes"
                      fullWidth
                      multiline
                      maxRows={4}
                    />
                  </Grid>
                  <Divider className={sharedClasses.Divider} />
                  <Box mt={3} display="flex" justifyContent="flex-end">
                    <Button
                      id="cancelButton"
                      variant="text"
                      color="primary"
                      size="large"
                      className={sharedClasses.ButtonMargin}
                      onClick={navigateBack}
                    >
                      {translate('button.cancel')}
                    </Button>
                    <Button
                      id="submitButton"
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={submitting || pristine}
                    >
                      {translate('button.save')}
                    </Button>
                  </Box>
                  <FormSpy
                    subscription={{ dirty: true }}
                    onChange={(props) => {
                      setTimeout(() => setDirty(props.dirty), 0)
                    }}
                  />
                </form>
              )
            }}
          />
        </Box>
      </Paper>
    </ScreenLayout>
  )
}
