/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionType {
    TOP_UP_PREPAID = 'TOP_UP_PREPAID',
    MANUAL_PREPAID_ADJUSTMENT = 'MANUAL_PREPAID_ADJUSTMENT',
    REFUND_PREPAID = 'REFUND_PREPAID',
    PAYMENT = 'PAYMENT',
    REFUND_PAYMENT = 'REFUND_PAYMENT',
    PAYMENT_METHOD_REGISTER = 'PAYMENT_METHOD_REGISTER'
}


export function TransactionTypeFromJSON(json: any): TransactionType {
    return TransactionTypeFromJSONTyped(json, false);
}

export function TransactionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionType {
    return json as TransactionType;
}

export function TransactionTypeToJSON(value?: TransactionType | null): any {
    return value as any;
}

