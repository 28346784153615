import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Divider, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { Edit } from '@mui/icons-material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, LaundryUser, LaundryUsersPrepaidApi, Permission, PrepaidBalance } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatAmountForLocale } from 'src/service/utils/NumberFormatUtils'
import { ButtonWithTooltip } from 'src/ui-shared/base/button/Buttons'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { LaundryUserPaymentManualAdjustment } from 'src/ui/page/wm/laundry-user/details/LaundryUserPaymentManualAdjustment'
import { LaundryUserPaymentTopup } from 'src/ui/page/wm/laundry-user/details/LaundryUserPaymentTopup'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser, useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  laundryUser: LaundryUser
  setErrorMessage: (message: string | null) => void
}

export const LaundryUserPaymentTab: FC<Props> = ({ laundryUser, setErrorMessage }): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const user = useUser()
  const regionLocale = useUserRegionLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryUserPrepaidApi = new LaundryUsersPrepaidApi(httpConfiguration)

  const [openAdjustModal, setOpenAdjustModal] = useState<boolean>(false)
  const [openTopupModal, setOpenTopupModal] = useState<boolean>(false)
  const [balance, setBalance] = useState<PrepaidBalance | null>(null)

  const canEdit = hasPermission(user, Permission.PREPAID_BALANCE_ADJUST)
  const canTopup = hasPermission(user, Permission.PREPAID_BALANCE_TOPUP)

  const loadBalance = () => {
    let active = true
    setBalance(null)

    setErrorMessage(null)

    laundryUserPrepaidApi
      .laundryusersLaundryUserIdPrepaidBalanceGet(laundryUser.id)
      .then((data) => {
        if (active) {
          // post-condition check if the balance returned is for the requested user
          if (data.laundryUserId === laundryUser.id) {
            setBalance(data)
            setErrorMessage(null)
          } else {
            throw new Error('Balance is not for the selected laundry user')
          }
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setBalance(null)
      })

    return () => {
      active = false
    }
  }

  useEffect(() => {
    loadBalance()
  }, [])

  // handle events
  const handleAdjustClick = () => {
    setOpenAdjustModal(true)
  }

  const handleTopupClick = () => {
    setOpenTopupModal(true)
  }

  // JSX
  const adjustButton = (
    <ButtonWithTooltip
      startIcon={<Edit />}
      variant="outlined"
      color="primary"
      size="large"
      onClick={handleAdjustClick}
      disabled={!balance}
      title={!laundryUser.assignedLaundryGroups.length ? translate('userNotAssignedToLaundryGroup') : undefined}
    >
      {translate('button.manualBalanceAdjustment')}
    </ButtonWithTooltip>
  )

  const topupButton = (
    <ButtonWithTooltip
      variant="contained"
      color="primary"
      size="large"
      className={sharedClasses.ButtonMargin}
      onClick={handleTopupClick}
      disabled={!balance || !laundryUser.assignedLaundryGroups.length}
      title={!laundryUser.assignedLaundryGroups.length ? translate('userNotAssignedToLaundryGroup') : undefined}
    >
      {translate('button.topup')}
    </ButtonWithTooltip>
  )

  const editAction = canEdit ? adjustButton : <></>
  const topupAction = canTopup ? topupButton : <></>

  return (
    <>
      <Box pt={2}>
        <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
          <Grid item xs={12}>
            <DetailsTextField
              value={laundryUser.firstName + ' ' + laundryUser.lastName}
              label={translate('user.name')}
            />
          </Grid>
          <Grid item xs={12}>
            <DetailsTextField
              value={formatAmountForLocale(balance?.balance, regionLocale, balance?.currency)}
              label={translate('balance')}
              inputProps={{ style: { fontWeight: 'bold' } }}
            />
          </Grid>
        </Grid>
        <Divider className={sharedClasses.Divider} />
        <Box pt={2} display="flex" justifyContent="space-between">
          <Box> {editAction}</Box>
          <Box display="flex" justifyContent="flex-end">
            {topupAction}
          </Box>
        </Box>
      </Box>

      {/* Manual Adjustment Dialog */}
      {balance ? (
        <ModalDialog
          open={openAdjustModal}
          onClose={() => setOpenAdjustModal(false)}
          title={translate('button.manualBalanceAdjustment')}
          maxWidth="xs"
        >
          <LaundryUserPaymentManualAdjustment
            setOpenModal={setOpenAdjustModal}
            loadBalance={loadBalance}
            laundryUser={laundryUser}
            balance={balance as PrepaidBalance}
          />
        </ModalDialog>
      ) : null}

      {/* Topup Dialog */}
      {balance ? (
        <ModalDialog
          open={openTopupModal}
          onClose={() => setOpenTopupModal(false)}
          title={translate('button.topup')}
          maxWidth="xs"
        >
          <LaundryUserPaymentTopup
            setOpenModal={setOpenTopupModal}
            loadBalance={loadBalance}
            laundryUser={laundryUser}
            balance={balance as PrepaidBalance}
          />
        </ModalDialog>
      ) : null}
    </>
  )
}
