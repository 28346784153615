import React, { FC, ReactElement } from 'react'
import { Box } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Machine } from 'src/service/backend/api'
import { getMachineTypeName, isProgramCompatibleMachineType } from 'src/service/view-model/machine/Machines'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { ProgramInstallationTable } from 'src/ui-shared/program/ProgramInstallationTable'

interface Props {
  machine: Machine
}

export const MachineProgramInstallationTab: FC<Props> = ({ machine }): ReactElement => {
  const translate = useTranslate()

  const isProgramCompatibleMachine = isProgramCompatibleMachineType(machine.status?.programInstallationStatus)
  const machineNotAssigned = !machine.laundryGroup
  const showError = machineNotAssigned || !isProgramCompatibleMachine

  const getErrorMessage = () => {
    let error: ReactElement | undefined = undefined
    if (!isProgramCompatibleMachine) {
      error = (
        <ErrorMessage
          severity={'info'}
          message={translate('programIncompatibleMachine', getMachineTypeName(machine.type, translate))}
        />
      )
    } else if (machineNotAssigned) {
      error = <ErrorMessage severity="error" message={translate('machineNotAssignedToLg')} />
    }
    return error
  }

  return (
    <>
      {showError ? (
        getErrorMessage()
      ) : (
        <Box pt={2}>
          {machine.laundryGroup?.id && (
            <ProgramInstallationTable laundryGroupId={machine.laundryGroup.id} machine={machine} />
          )}
        </Box>
      )}
    </>
  )
}
