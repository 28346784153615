import {
  TimeseriesAnnotations,
  TimeseriesData,
  TimeseriesDataset,
  TimeseriesValueState,
  TimeseriesValueType,
} from 'src/service/backend/api'
import { generateDatasetColor } from 'src/service/view-model/base/chart/Chart.utils'
import {
  ChartSeriesDataApex,
  ChartSeriesDataset,
  ChartSeriesDatasetApex,
} from 'src/service/view-model/base/chart/ChartViewModel'
import { ApexLineType } from 'src/ui-shared/chart/ChartSeries.const'

/**
 * Converts API TimeseriesData into ApexChart  ApexAxisChartSeries.
 * @param timeSeriesData the timeseries from API
 * @returns ApexAxisChartSeries
 */
export const mapTimeSeriesDataToApexAxisChartSeries = (timeSeriesData: TimeseriesData): ApexAxisChartSeries => {
  const { datasets, valueStates } = timeSeriesData
  const chartSeries = mapTimeSeriesDatasets(datasets)

  return generateApexAxisChartSeries(chartSeries, valueStates)
}

/**
 * Converts API TimeseriesData into ApexChart  ApexAxisChartSeries.
 * Maps TimeSeriesDatasets to ChartSeries (key-value map of valueState and ChartSeriesDataApex)/
 *
 * Exported for the unit test.
 *
 * @param datasets the datasets from API
 * @returns  ApexAxisChartSeries
 */
export const mapTimeSeriesDatasets = (datasets: TimeseriesDataset[]): Map<string, Array<ChartSeriesDataApex>> => {
  const chartSeries = new Map<string, Array<ChartSeriesDataApex>>()

  datasets.map((item) => {
    const chartSeriesDatasets = mapTimeSeriesDataset(item)

    chartSeriesDatasets.map((chartSeriesDataset) => {
      const valueState =
        chartSeriesDataset.valueState !== undefined ? chartSeriesDataset.valueState! : chartSeriesDataset.data.x
      if (chartSeries.has(valueState)) {
        // map already has previous data for the value state, append
        const chartSeriesData = chartSeries.get(valueState)
        const data = chartSeriesData!.concat(chartSeriesDataset.data)
        chartSeries.set(valueState, data)
      } else {
        // first time the value state is added in the map
        chartSeries.set(valueState, [chartSeriesDataset.data])
      }
    })
  })

  return chartSeries
}

/**
 * Map each TimeSeriesDataset to ChartSeriesDataset.
 * Exported for the unit test.
 *
 * @param timeSeriesDataset the dataset from API
 * @returns one ore more ChartSeriesDataset
 */
export const mapTimeSeriesDataset = (timeSeriesDataset: TimeseriesDataset): ChartSeriesDataset[] => {
  const chartSeriesDataset: ChartSeriesDataset[] = []
  const values = timeSeriesDataset.values
  const valueType = timeSeriesDataset.valueType
  const label = timeSeriesDataset.label

  values.map((item) => {
    if (valueType === TimeseriesValueType.STATE) {
      const chartSeriesDataApex: ChartSeriesDataApex = {
        x: label,
        y: [item.startTime.getTime(), item.endTime?.getTime()],
      }
      chartSeriesDataset.push({ valueState: item.valueState!, data: chartSeriesDataApex })
    }
  })

  return chartSeriesDataset
}

/**
 * Get final ApexAxisChartSeries chart from ChartSeries.
 * Exported for the unit test.
 
 * @param chartSeries map with value state string as key and dataset as value
 * @param valueStates the global map for all datasets that provides the colors
 * @returns ApexAxisChartSeries
 */
export const generateApexAxisChartSeries = (
  chartSeries: Map<string, Array<ChartSeriesDataApex>>,
  valueStates: TimeseriesValueState[],
): ApexAxisChartSeries => {
  const apexChartSeries: ApexAxisChartSeries = []

  chartSeries.forEach((chartSeriesData, key) => {
    const valueState = valueStates.find((item) => {
      return item.valueState === key
    })

    if (valueState) {
      const chartObject: ChartSeriesDatasetApex = {
        name: valueState.label,
        color: valueState.color,
        data: chartSeriesData,
      }
      apexChartSeries.push(chartObject)
    }
  })

  return apexChartSeries
}

export const mapNumberTimeSeriesDataToApexAxisChartSeries = (
  timeseriesData: TimeseriesData,
  chartColors: string[],
  lineType?: ApexLineType | ApexLineType[],
): ApexAxisChartSeries => {
  const result: ApexAxisChartSeries = []

  timeseriesData.datasets.forEach((dataset, index) => {
    // apexchart timeseries requires array where each item is array with x value and y value
    const data: number[][] = []
    dataset.values.forEach((value) => {
      const x = value.startTime.getTime()
      const y = value.valueNumber!
      data.push([x, y])
    })

    result.push({
      name: dataset.label,
      color: generateDatasetColor(index, chartColors),
      type: lineType ? lineType[index] : undefined,
      data: data,
    })
  })

  return result
}

/**
 * Maps Timeseries Annotations from API to Apex Charts Annotations.
 * @param timeseriesAnnotations the annotations from API
 * @returns Apex Charts Annotations
 */
export const mapTimeseriesAnnotationsToApexAnnoations = (
  timeseriesAnnotations?: TimeseriesAnnotations,
): ApexAnnotations => {
  const result: ApexAnnotations = {}

  if (timeseriesAnnotations && timeseriesAnnotations.yAxis && timeseriesAnnotations.yAxis.length > 0) {
    const apexYAxisAnnoations: YAxisAnnotations[] = []
    timeseriesAnnotations.yAxis.forEach((yAxisAnnotationFromApi) => {
      const apexAnnotation: YAxisAnnotations = {
        y: yAxisAnnotationFromApi.yValue,
        borderColor: yAxisAnnotationFromApi.color,
        opacity: 1,
        strokeDashArray: 2,
        label: {
          borderColor: yAxisAnnotationFromApi.color,
          borderWidth: 0,
          text: yAxisAnnotationFromApi.label,
          offsetY: 7,
          style: {
            background: 'rgba(255, 255, 255, 0.85)',
            padding: {
              top: 0,
              bottom: 3,
            },
          },
        },
      }
      apexYAxisAnnoations.push(apexAnnotation)
    })

    result.yaxis = apexYAxisAnnoations
  }

  return result
}

/**
 * If there are datasets with only zero values in the array of timeseries data, new array will be returned where the datasets with zero values are skipped.
 * If there are no datasets with only zero values, the original array is returned (memory optimization).
 *
 * @param timeseriesDatas array of TimeseriesData to check for datasets with only zero values
 * @returns the original array if there are no datasets with only 0 values, or new array with the datasets with 0 values filtered out
 */
export const filterTimeseriesDataWithZeroValue = (timeseriesDatas: TimeseriesData[]): TimeseriesData[] => {
  const result: TimeseriesData[] = []

  let changed = false

  for (const timeseriesData of timeseriesDatas) {
    const originalDatasets = timeseriesData.datasets
    const filteredDatasets = filterDatasetsWithZeroValue(originalDatasets)

    if (filteredDatasets !== originalDatasets) {
      const modifiedTimeseriesData: TimeseriesData = {
        ...timeseriesData,
        datasets: filteredDatasets,
      }
      result.push(modifiedTimeseriesData)
      changed = true
    } else {
      result.push(timeseriesData)
    }
  }

  // if nothing was changed, return original
  if (!changed) {
    return timeseriesDatas
  }

  return result
}

/**
 * If there are datasets with only zero values in the array of datasets, new array will be returned where the datasets with zero values are skipped.
 * If there are no datasets with only zero values, the original array is returned (memory optimization).
 *
 * @param datasets array of TimeseriesDataset
 * @returns the original array if there are no datasets with only 0 values, or new array with the datasets with 0 values filtered out
 */
const filterDatasetsWithZeroValue = (datasets: TimeseriesDataset[]): TimeseriesDataset[] => {
  const result: TimeseriesDataset[] = []
  datasets.forEach((dataset) => {
    const hasOnlyZeroValues = isDatasetWithZeroValues(dataset)

    if (!hasOnlyZeroValues) {
      result.push(dataset)
    }
  })

  // if nothing was filtered, return original
  if (result.length === datasets.length) {
    return datasets
  }

  return result
}

/**
 * Checks if the datasets contains only 0 values.
 * @param dataset the dataset to check
 * @returns true if all values in the dataset are 0, or the data set does not have any value, false otherwise
 */
const isDatasetWithZeroValues = (dataset: TimeseriesDataset): boolean => {
  for (const value of dataset.values) {
    if (value.valueNumber) {
      return false
    }
  }

  return true
}
