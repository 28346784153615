import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Paper, TableCell, TableRow } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, OrganizationsApi } from 'src/service/backend/api'
import { Address, Country, Organization } from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { Data, OrderBy } from 'src/service/view-model/base/Data'
import { getAddress, getCity } from 'src/service/view-model/base/address/AddressUtils'
import { COUNTRIES, getCountryName } from 'src/service/view-model/base/country/Countries'
import {
  MEMBER_ORGANIZATION_TYPES,
  SERVICER_ORGANIZATION_TYPES,
} from 'src/service/view-model/organization/Organizations'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettings,
  getDefaultTableSettings,
  tableSettingsSort,
} from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { useUserLocale } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'name',
    label: 'organization.name',
  },
  {
    id: 'ifsCustomerNumber',
    label: 'customerId',
  },
  {
    id: 'address.addressLine1',
    label: 'address',
  },
  {
    id: 'address.city',
    label: 'city',
  },
  {
    id: 'address.countryCode',
    label: 'country',
  },
]

interface OrganizationTableSettings extends TableSettings {
  orderBy: OrderBy<keyof Organization | keyof Address>
  countryCode?: string
}

interface Props {
  navigateToItem: (organizationId?: string) => void
  isServicePartner?: boolean
}

export const OrganizationTable: React.FC<Props> = ({ navigateToItem, isServicePartner }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  const location = useLocation()

  const translate = useTranslate()
  const userLocale = useUserLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const organizationsApi = new OrganizationsApi(httpConfiguration)

  // state
  const [data, setData] = useState<TableData<Organization>>(DEFAULT_DATA)
  const defaultTableSettings: OrganizationTableSettings = getDefaultTableSettings()
  const [tableSettings, setTableSettings] = useState<OrganizationTableSettings>(defaultTableSettings)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)

  const organizationTypeFilter = isServicePartner ? SERVICER_ORGANIZATION_TYPES : MEMBER_ORGANIZATION_TYPES

  // reactivity

  // load data on state change
  useEffect(() => {
    let active = true

    let countryObject: Country | undefined = undefined
    if (tableSettings.countryCode) {
      countryObject = tableSettings.countryCode as Country
    }

    setLoading(true)

    organizationsApi
      .organizationsGet(
        tableSettings.size,
        tableSettings.page,
        tableSettingsSort(tableSettings),
        tableSettings.search,
        undefined,
        countryObject,
        organizationTypeFilter,
        [],
      )
      .then((data) => {
        if (active) {
          updateTableSettingsFromData(data as Data<any>, tableSettings)
          setTableSettings(tableSettings)

          setData(mapData(data as Data<any>))
          setErrorMessage(null)
          setLoading(false)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [tableSettings])

  // JSX
  const nonEmptyRows = data.data.map((item) => {
    return (
      <TableRow className={tableClasses.tableRow} key={item.id} onClick={navigateToItem.bind(this, item.id)}>
        <TableCell>
          <TruncatedTooltip maxChars={35} value={item.name} />
        </TableCell>
        <TableCell>{item.ifsCustomerNumber}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={30} value={getAddress(item.address)} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={30} value={getCity(item.address)} />
        </TableCell>
        <TableCell>{getCountryName(item.address.countryCode, userLocale)}</TableCell>
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string): ReactElement | undefined => {
    let filter
    if (headCellId === 'address.countryCode') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByCountry')}
          label={translate('country')}
          filter={'countryCode'}
          labelFieldName="name"
          valueFieldName="code"
          options={COUNTRIES}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }
    return filter
  }

  return (
    <Paper elevation={0}>
      <TableSearchForm handleSearch={handleSearch} inputSearchValue={inputSearchValue} />

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        getFilter={getFilter}
        loading={loading}
        translate={translate}
      />
    </Paper>
  )
}
