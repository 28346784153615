export interface SupportedLanguage {
  label: string
  value: string
}

export const ENGLISH: SupportedLanguage = { label: 'English', value: 'en' }
export const GERMAN: SupportedLanguage = { label: 'German', value: 'de' }
export const FRENCH: SupportedLanguage = { label: 'French', value: 'fr' }
export const ITALIAN: SupportedLanguage = { label: 'Italian', value: 'it' }
export const SPANISH: SupportedLanguage = { label: 'Spanish', value: 'es' }

export const SUPPORTED_LANGUAGES: SupportedLanguage[] = [ENGLISH, GERMAN, FRENCH, ITALIAN]

export const SUPPORTED_LOCALES: string[] = SUPPORTED_LANGUAGES.map((lang) => lang.value)

export function getSupportedLanguage(key: string): SupportedLanguage {
  switch (key) {
    case ENGLISH.value:
      return ENGLISH
    case GERMAN.value:
      return GERMAN
    case FRENCH.value:
      return FRENCH
    case ITALIAN.value:
      return ITALIAN
    case SPANISH.value:
      return SPANISH
  }

  throw new Error('Unsupported language key: ' + key)
}

export const getUserLanguage = (locale: string): string => {
  const userLocale = locale.toLowerCase()
  const userLanguage = userLocale.length > 2 ? userLocale.substring(0, 2) : userLocale
  return userLanguage
}

export const getUserLanguageForLinks = (locale: string, itSupported: boolean, esSupported: boolean): string => {
  const userLanguage = getUserLanguage(locale)
  let language
  if (userLanguage === 'en') {
    language = 'en'
  } else if (userLanguage === 'de') {
    language = 'de'
  } else if (userLanguage === 'fr') {
    language = 'fr'
  } else if (itSupported && userLanguage === 'it') {
    language = 'it'
  } else if (esSupported && userLanguage === 'es') {
    language = 'es'
  } else {
    language = 'en'
  }

  return language
}
