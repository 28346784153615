import React, { FC, ReactElement, useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { Box, Button, Grid } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, TransactionsApi, WMUsage } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  TransactionRefundViewModel,
  mapTransactionRefundViewModelToRefundTransaction,
  mapUsageToTransactionRefundViewModel,
} from 'src/service/view-model/laundry-user/TransactionRefundViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { composeValidators, required, validAmount, validAmountMax } from 'src/ui-shared/base/form/validation/Validators'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  usage: WMUsage
  setOpenModal: (value: boolean) => void
  handleReloadDataOnSubmit: () => void
}

export const TransactionRefundModal: FC<Props> = ({ usage, setOpenModal, handleReloadDataOnSubmit }): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()
  const showSnackbar = useShowSnackbar()
  const regionLocale = useUserRegionLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const transactionsApi = new TransactionsApi(httpConfiguration)

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const defaultViewModel = mapUsageToTransactionRefundViewModel(usage, regionLocale)

  const refundSubmit = (values: TransactionRefundViewModel) => {
    setErrorMessage(null)
    setLoading(true)
    let active = true

    const refundTransaction = mapTransactionRefundViewModelToRefundTransaction(values, usage)

    transactionsApi
      .transactionsTransactionIdRefundPost(usage.transactionId, refundTransaction)
      .then((_data) => {
        setLoading(false)
        if (active) {
          setOpenModal(false)
          showSnackbar(translate('transactionRefundSuccess'), 'success')
          handleReloadDataOnSubmit()
        }
      })
      .catch((err) => {
        setLoading(false)
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })

    return () => {
      active = false
    }
  }

  return (
    <Box px={3} pb={3}>
      <LoadingIndicator loading={loading} />
      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
      <Box mt={2}>
        <Form<TransactionRefundViewModel>
          initialValues={defaultViewModel}
          onSubmit={refundSubmit}
          render={({ handleSubmit, submitting, pristine }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField name="transactionId" label={translate('transactionId')} fullWidth disabled />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField name="date" label={translate('date')} fullWidth disabled />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField name="laundryUser" label={translate('laundryUser')} fullWidth disabled />
                  </Grid>
                  <Grid item xs={6}>
                    {/* Amount will be editable in future, that is why it is a TextField with validators */}
                    <TextField
                      name="amount"
                      label={translate('amount')}
                      fullWidth
                      validate={composeValidators(
                        required(),
                        validAmount('validation.valid.amount'),
                        validAmountMax(undefined, usage.amount),
                      )}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailsTextField value={defaultViewModel.currency} label={translate('currency')} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={submitting}
                      name="reason"
                      label={translate('reason')}
                      validate={required()}
                      autoFocus
                    />
                  </Grid>
                </Grid>
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    color="primary"
                    size="large"
                    className={sharedClasses.ButtonMargin}
                    onClick={() => setOpenModal(false)}
                  >
                    {translate('button.cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    {translate('transactionRefund')}
                  </Button>
                </Box>
              </form>
            )
          }}
        />
      </Box>
    </Box>
  )
}
