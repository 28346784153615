/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineReference } from './MachineReference';
import {
    MachineReferenceFromJSON,
    MachineReferenceFromJSONTyped,
    MachineReferenceToJSON,
} from './MachineReference';

/**
 * 
 * @export
 * @interface LaundryGroupLog
 */
export interface LaundryGroupLog {
    /**
     * 
     * @type {string}
     * @memberof LaundryGroupLog
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof LaundryGroupLog
     */
    time: Date;
    /**
     * 
     * @type {MachineReference}
     * @memberof LaundryGroupLog
     */
    machine?: MachineReference;
}

/**
 * Check if a given object implements the LaundryGroupLog interface.
 */
export function instanceOfLaundryGroupLog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "time" in value;

    return isInstance;
}

export function LaundryGroupLogFromJSON(json: any): LaundryGroupLog {
    return LaundryGroupLogFromJSONTyped(json, false);
}

export function LaundryGroupLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'time': (new Date(json['time'])),
        'machine': !exists(json, 'machine') ? undefined : MachineReferenceFromJSON(json['machine']),
    };
}

export function LaundryGroupLogToJSON(value?: LaundryGroupLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'time': (value.time.toISOString()),
        'machine': MachineReferenceToJSON(value.machine),
    };
}

