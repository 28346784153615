// returns all variations keys for object as strings (object.key1, object.key2, object.key2.subkey2)
export const getDeepKeys = (obj: Record<string, any>): string[] => {
  let keys: string[] = []
  for (const key in obj) {
    keys.push(key)
    if (typeof obj[key] === 'object') {
      const subkeys = getDeepKeys(obj[key])
      keys = keys.concat(subkeys.map((subkey) => key + '.' + subkey))
    }
  }
  return keys
}
/**
 * Cast string value to appropriate enum type,
 * if string value is invalid or does not exist as value in enum list
 * then throw Error
 *
 * @param stringVal string value
 * @param enumType enum type
 * @returns {Object} result value with enumType
 */
export const getEnumFromString = <T extends Record<string, string>>(
  stringVal: string | undefined,
  enumType: T,
): T[keyof T] => {
  const enumValues = Object.values(enumType)

  if (!stringVal) {
    throw new Error(`Invalid string value passed`)
  }

  if (!enumValues.includes(stringVal)) {
    throw new Error(`Invalid value '${stringVal}' for enum type`)
  }

  return stringVal as T[keyof T]
}
