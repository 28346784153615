import { AppId } from 'src/app/AppId'
import { OrganizationReference, OrganizationType } from 'src/service/backend/api'
import { EMPTY_UUID } from 'src/ui-shared/constants/Constants'

export const EMPTY_ORGANIZATION_REF: OrganizationReference = {
  id: EMPTY_UUID,
  name: '-',
}

export const SERVICER_ORGANIZATION_TYPES = [OrganizationType.PARTNER, OrganizationType.SCHULTHESS]
export const MEMBER_ORGANIZATION_TYPES = [OrganizationType.COMPANY]

export const getOrganizationTypesByUser = (isServicer: boolean | undefined): OrganizationType[] => {
  return isServicer ? SERVICER_ORGANIZATION_TYPES : MEMBER_ORGANIZATION_TYPES
}

export const isOrganizationTypeServicePartner = (type?: OrganizationType | null): boolean => {
  return SERVICER_ORGANIZATION_TYPES.some((item) => item === type)
}

export const getOrganizationTypesForApp = (appId: AppId): OrganizationType[] => {
  const isServicePartner = appId !== AppId.WASH_MASTER
  const organizationTypeFilter = isServicePartner ? SERVICER_ORGANIZATION_TYPES : MEMBER_ORGANIZATION_TYPES
  return organizationTypeFilter
}
