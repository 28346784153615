import { LaundryGroupReference, LaundryReference, Machine } from 'src/service/backend/api'
import {
  ConnectivityType,
  MachineCategory,
  MachineConnectivityType,
  MachineInlineStatus,
  MachineProducts,
  MachineTag,
  MachineType,
  MachineWashmaster,
  ManufacturerModelReference,
  ManufacturerReference,
} from 'src/service/backend/api/models'

export const mapMachineToRemoveTagMachineViewModel = (machine: Machine): Machine => {
  const newMachine = Object.assign({}, machine)
  if (newMachine.washmaster !== null && newMachine.washmaster !== undefined) {
    newMachine.washmaster.tag = null
  }
  return newMachine
}
export interface MachineViewModel {
  id: string
  thingName: string
  connectivityType: string
  name: string
  manufacturer: ManufacturerReference | string
  manufacturerModel: ManufacturerModelReference | string
  type: string
  category: MachineCategory | string
  serialNumber: string

  laundryGroupRef?: LaundryGroupReference
  laundryRef?: LaundryReference | string

  status: MachineInlineStatus | string
  notes: string

  products?: MachineProducts
  washmaster: {
    tag: MachineTag | string
    freeUsage: boolean
  }
}

export interface MachineUnconnectedViewModel {
  name: string
  serialNumber: string
  type: MachineType
  manufacturer: ManufacturerReference
  manufacturerModel: ManufacturerModelReference
  laundryRef: LaundryReference
  notes: string
}

export const mapMachineToMachineViewModel = (machine: Machine): MachineViewModel => {
  return {
    id: machine.id,
    thingName: machine.thingName || '',
    connectivityType: machine.connectivityType || '',
    name: machine.name || '',
    manufacturer: machine.manufacturer || '',
    manufacturerModel: machine.manufacturerModel,
    type: machine.type,
    category: machine.category,
    serialNumber: machine.serialNumber,

    laundryGroupRef: machine.laundryGroup,
    laundryRef: machine.laundry,

    status: machine.status || '',
    notes: machine.notes || '',

    products: machine.products,
    washmaster: {
      tag: machine.washmaster?.tag || '',
      freeUsage: machine.washmaster?.freeUsage || false,
    },
  }
}

export const mapMachineUnconnectedViewModelToMachine = (viewModel: MachineUnconnectedViewModel): Machine => {
  const machine: Machine = {
    // not used (removed at generated apis)
    id: '',
    // not used (removed at generated apis)
    category: MachineCategory.WA,
    serialNumber: viewModel.serialNumber,
    laundry: viewModel.laundryRef,
    manufacturerModel: viewModel.manufacturerModel,
    name: viewModel.name,
    type: viewModel.type,
    manufacturer: viewModel.manufacturer,
    notes: viewModel.notes,
    machineConnectivityType: MachineConnectivityType.UNCONNECTED,
  }
  return machine
}

export const createMachineViewModel = (machine?: Machine): MachineViewModel => {
  if (machine) {
    return mapMachineToMachineViewModel(machine)
  }

  return {
    id: '',
    thingName: '',
    connectivityType: '',
    name: '',
    manufacturer: '',
    manufacturerModel: '',
    type: '',
    category: '',
    serialNumber: '',

    laundryGroupRef: undefined,
    laundryRef: '',

    status: '',
    notes: '',

    products: {
      serviceMasterEnabled: false,
      washMasterEnabled: false,
    },
    washmaster: {
      tag: '',
      freeUsage: false,
    },
  }
}

export const mapMachineViewModelToMachine = (machineViewModel: MachineViewModel): Machine => {
  const tag = machineViewModel.washmaster.tag as MachineTag

  return {
    id: machineViewModel.id,
    thingName: machineViewModel.thingName || undefined,
    connectivityType: machineViewModel.connectivityType ? ConnectivityType.AWS_IOT : undefined,
    name: machineViewModel.name,
    manufacturer: machineViewModel.manufacturer as ManufacturerReference,
    manufacturerModel: machineViewModel.manufacturerModel as ManufacturerModelReference,
    type: machineViewModel.type as MachineType,
    category: machineViewModel.category as MachineCategory,
    serialNumber: machineViewModel.serialNumber,
    laundry: machineViewModel.laundryRef as LaundryReference,
    notes: machineViewModel.notes || undefined,

    products: {
      washMasterEnabled: machineViewModel.products?.washMasterEnabled || false,
      serviceMasterEnabled: machineViewModel.products?.serviceMasterEnabled || false,
    },
    washmaster: {
      freeUsage: machineViewModel.washmaster.freeUsage,
      tag: tag.contentUrl ? tag : null,
    } as MachineWashmaster,
  }
}
