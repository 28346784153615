export const truncateText = (maxChars: number, text?: string | null): string => {
  if (text !== undefined && text !== null && text !== '') {
    const length = getTextLength(text)
    const truncatedText = text.substr(0, maxChars - 3)
    return length <= maxChars ? text : truncatedText + '...'
  }
  return ''
}

export function getTextLength(text?: string | null): number {
  if (text !== undefined && text !== null && text !== '') {
    return text.length
  }

  return 0
}
