import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Button, Checkbox, FormControlLabel, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, OrganizationsApi } from 'src/service/backend/api'
import { Organization, OrganizationType, Permission } from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getLocaleName } from 'src/service/view-model/base/localization/Locales'
import { getTimezoneObject } from 'src/service/view-model/base/localization/Timezones'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { AddressSection } from 'src/ui-shared/form/address-section/AddressSection'
import { DeleteIcon } from 'src/ui-shared/icons/Icons'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

function getOrganizationTypeLabel(type?: OrganizationType | null): string {
  let result: string | undefined = undefined
  if (type) {
    if (type === OrganizationType.COMPANY) {
      result = 'organization.typeCompany'
    } else if (type === OrganizationType.PARTNER) {
      result = 'organization.typePartner'
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (type === OrganizationType.SCHULTHESS) {
      result = 'organization.typeSchulthess'
    }
  }

  if (!result) {
    result = ''
  }
  return result
}

interface Props {
  isServicePartner?: boolean
}

export const OrganizationDetailsPage: FC<Props> = ({ isServicePartner }): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()

  const navigate = useNavigate()
  const location = useLocation()
  const appId = useAppId()
  const user = useUser()
  const showSnackbar = useShowSnackbar()

  const { organizationId } = useRequiredParams(['organizationId'])

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack

  const httpConfiguration: Configuration = useContext(HttpContext)
  const organizationsApi = new OrganizationsApi(httpConfiguration)

  const [organization, setOrganization] = useState<Organization | null>(null)
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const screenRoute = isServicePartner ? 'service-partners' : 'organizations'
  const canEdit = hasPermission(user, Permission.ORGANIZATION_WRITE)

  useHotKeysForm()

  // load data
  useEffect(() => {
    organizationsApi
      .organizationsOrganizationIdGet(organizationId, [])
      .then((organization) => {
        setOrganization(organization)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }, [])

  // handle events
  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/${screenRoute}`)
  }

  const onRemoveHandler = (): void => {
    if (organization) {
      organizationsApi
        .organizationsOrganizationIdDelete(organization.id)
        .then(() => {
          navigateBack()
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
        })
      setRemoveModal(false)
    }
  }

  const navigateToEditPage = () => {
    navigate(`/${appId}/${screenRoute}/${organizationId}/edit`)
  }

  // JSX
  const editButton = (
    <Button id="editButton" variant="contained" color="primary" size="large" onClick={navigateToEditPage}>
      {translate('button.edit')}
    </Button>
  )

  const editAction = canEdit ? editButton : <></>

  const deleteButton = (
    <Button
      startIcon={<DeleteIcon />}
      variant="outlined"
      color="primary"
      size="large"
      sx={{ mr: 2 }}
      onClick={() => setRemoveModal(true)}
    >
      {translate('button.delete')}
    </Button>
  )

  const deleteAction = canEdit ? deleteButton : <></>

  // render
  return (
    <ScreenLayout
      title={organization?.name ? organization.name : ''}
      onBack={navigateBack}
      actions={<></>}
      actionsWidth={50}
    >
      <Paper elevation={0}>
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <Box pt={2}>
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={organization?.name} label={translate('company.name')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={organization?.ifsCustomerNumber} label={translate('company.id')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                <DetailsTextField value={organization?.email} label={translate('email')} />
              </Grid>
              {isServicePartner && (
                <>
                  <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                    <DetailsTextField value={organization?.supportEmail} label={translate('supportEmail')} />
                  </Grid>
                  <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={Boolean(organization?.servicemaster?.ticketingSystemAvailable)}
                          disabled
                          name="ticketingSystemAvailable"
                        />
                      }
                      label={translate('ticketingSystemAvailable')}
                    />
                  </Grid>
                </>
              )}
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                <DetailsTextField value={organization?.phoneNumber} label={translate('phonenumber')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={getLocaleName(organization?.locale)} label={translate('language')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  value={getTimezoneObject(organization?.timeZone)?.name || organization?.timeZone}
                  label={translate('timezone')}
                />
              </Grid>
            </Grid>

            <Divider />

            <AddressSection address={organization?.address} />

            <Divider />

            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              {!isServicePartner && (
                <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                  <DetailsTextField
                    value={organization?.servicemaster?.servicePartner?.name}
                    label={translate('servicePartner')}
                    link={`/${appId}/service-partners/${organization?.servicemaster?.servicePartner?.id}/view`}
                  />
                </Grid>
              )}

              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                <DetailsTextField value={organization?.vatNumber} label={translate('company.vatnumber')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                <DetailsTextField
                  value={translate(getOrganizationTypeLabel(organization?.type))}
                  label={translate('company.type')}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mb: 1 }} />
            <Box pt={2} display="flex" justifyContent={isServicePartner ? 'space-between' : 'end'}>
              {isServicePartner && <Box>{deleteAction}</Box>}
              <Box display="flex" justifyContent="flex-end">
                {editAction}
              </Box>
            </Box>
          </Box>
        )}
      </Paper>

      <ConfirmationModalDialog
        titleKey="deleteServicePartner"
        confirmationKey="button.delete"
        open={removeModal}
        onConfirm={onRemoveHandler}
        onCancel={() => setRemoveModal(false)}
      >
        {translate('deleteConfirm', organization?.name)}
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
