import React, { FC, useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { EnvironmentContext } from 'src/env/EnvironmentContext'

interface Props {
  googleAnalyticsTitle: string
  children: React.ReactNode
}

const capitalizeText = (text: string): string => {
  const capitalizedText = text[0].toUpperCase() + text.substring(1)
  return capitalizedText
}

const getTabPageTitle = (path: string): string | undefined => {
  const textToSearch = '/view/'
  const textToSearchLength = textToSearch.length
  const startIndexOfTextToSearch = path.indexOf(textToSearch)

  if (startIndexOfTextToSearch === -1) {
    return undefined
  }

  const tabName = path.substring(startIndexOfTextToSearch + textToSearchLength, path.length)
  const tabNameWithoutDash = tabName.replace('-', ' ')
  const capitalizedTabName = capitalizeText(tabNameWithoutDash)

  return capitalizedTabName
}

// if tabname exists it returns the pagetitle with tabname, if it doesn't exists it returns the original title
const insertTabNameToPageTitle = (googleAnalyticsTitle: string, path: string): string => {
  const tabName = getTabPageTitle(path)

  if (!tabName || tabName === 'Overview') {
    return googleAnalyticsTitle
  }

  const newPageTitle = googleAnalyticsTitle + ' ' + tabName
  return newPageTitle
}

export const GoogleAnalyticsPageViewEvent: FC<Props> = ({
  googleAnalyticsTitle,
  children,
}): React.ReactElement | null => {
  const location = useLocation()
  const UUID_REGEX = /\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/

  const { googleAnalyticsTrackingId } = useContext(EnvironmentContext)
  useEffect(() => {
    // removes UUID for entity from URL
    const locationPath = location.pathname.replace(UUID_REGEX, '')

    const pageTitle = insertTabNameToPageTitle(googleAnalyticsTitle, locationPath)

    if (!googleAnalyticsTrackingId) {
      console.debug('GA disabled, event ignored', pageTitle)
      return
    }

    try {
      // console.debug('Logging GA event', googleAnalyticsTrackingId, pageTitle)

      // @ts-ignore
      if (window.gtag && typeof window.gtag === 'function') {
        // @ts-ignore
        window.gtag('event', 'page_view', {
          page_title: pageTitle,
          page_location: locationPath,
          page_path: locationPath,
          send_to: googleAnalyticsTrackingId,
        })
      }
    } catch (err) {
      console.warn('Error sending GA event', pageTitle, locationPath, err)
    }
  }, [location.pathname])
  return <>{children}</>
}
