import { TranslateFunction } from 'src/i18n/useMessageSource'
import { Step } from 'src/service/backend/api'
import { getReferenceName } from 'src/service/view-model/base/Reference.utils'

export interface WashingStepObject {
  step: string
  name: string
}

export const WASHING_STEPS: WashingStepObject[] = [
  { step: Step.NOT_AVAILABLE, name: 'notAvailable' },
  { step: Step.SOAKING, name: 'soaking' },
  { step: Step.PREWASH_FIRST, name: 'preWashFirst' },
  { step: Step.PREWASH_SECOND, name: 'preWashSecond' },
  { step: Step.PREWASH, name: 'preWash' },
  { step: Step.WASHING, name: 'washing' },
  { step: Step.PRE_RINSING, name: 'preRinsing' },
  { step: Step.RINSING, name: 'rinsing' },
  { step: Step.EXTRA_RINSING, name: 'extraRinsing' },
  { step: Step.FINISHING, name: 'finishing' },
  { step: Step.RINSING_STOP, name: 'rinsingStop' },
  { step: Step.DRAINING, name: 'draining' },
  { step: Step.SPINNING, name: 'spinning' },
  { step: Step.DEWRINKLING, name: 'dewrinkling' },
  { step: Step.LOOSENING_UP, name: 'looseningUp' },
  { step: Step.FORWARDING, name: 'forwarding' },
  { step: Step.CANCELLING, name: 'cancelling' },
  { step: Step.FOAM_CONTROL, name: 'foamControl' },
  { step: Step.HEATER_CHECK, name: 'heaterCheck' },
  { step: Step.DRYING, name: 'drying' },
  { step: Step.ADDITIONAL_DRYING, name: 'additionalDrying' },
  { step: Step.EXTRA_DRYING, name: 'extraDrying' },
  { step: Step.TIMED_DRYING, name: 'timedDrying' },
  { step: Step.SELF_CLEANING, name: 'selfCleaning' },
  { step: Step.COOL_DOWN, name: 'coolDown' },
  { step: Step.CRINKLE_CARE, name: 'crinkleCare' },
  { step: Step.END, name: 'end' },
]

export const getWashingStepName = (washingStepName: string | undefined, translate: TranslateFunction): string => {
  return getReferenceName(WASHING_STEPS, translate, washingStepName, 'step')
}
