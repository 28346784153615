import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useNavigate, useParams } from 'react-router'
import { Alert, Button, Divider, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, ManufacturerModel, ManufacturerModelsApi, ManufacturerReference } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  ManufacturerModelViewModel,
  createManufacturerModelViewModel,
  mapViewModelToManufacturerModel,
} from 'src/service/view-model/manufacturer-model/ManufacturerModelViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { AsyncAutoCompleteValidate } from 'src/ui-shared/base/form/control/AsyncAutoCompleteValidate'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { required } from 'src/ui-shared/base/form/validation/Validators'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { usePrompt } from 'src/ui-shared/base/hooks/usePrompt'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

export const ManufacturerModelCreatePage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()
  const navigate = useNavigate()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const manufacturerModelsApi = new ManufacturerModelsApi(httpConfiguration)

  // url params
  const { manufacturerModelId } = useParams()
  const editMode = Boolean(manufacturerModelId)

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [manufacturerModelViewModel, setManufacturerModelViewModel] = useState<ManufacturerModelViewModel>(
    createManufacturerModelViewModel(),
  )
  const [readonly, setReadonly] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  const manufacturerGroupSearch = (search: string): Promise<ManufacturerReference[]> => {
    return new Promise<ManufacturerReference[]>((resolve, reject) => {
      manufacturerModelsApi
        .manufacturersRefGet(undefined, search)
        .then((manufacturers) => resolve(manufacturers.map((manufacturer) => manufacturer as ManufacturerReference)))
        .catch((err) => reject(err))
    })
  }

  useHotKeysForm()

  usePrompt(translate('notification.form.unsaved.changes'), dirty)

  useEffect(() => {
    let active = true

    setLoading(true)
    if (editMode) {
      manufacturerModelsApi
        .manufacturerModelsManufacturerModelIdGet(manufacturerModelId!)
        .then((manufacturerModel) => {
          if (!active) return
          setReadonly(!manufacturerModel.editable)
          setManufacturerModelViewModel(createManufacturerModelViewModel(manufacturerModel))
          setLoading(false)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
    return () => {
      active = false
    }
  }, [editMode, manufacturerModelId])

  // handle events
  const updateOrInsert = (manufacturerModel: ManufacturerModel): Promise<ManufacturerModel> => {
    if (editMode) {
      return manufacturerModelsApi.manufacturerModelsManufacturerModelIdPut(manufacturerModelId!, manufacturerModel)
    } else {
      return manufacturerModelsApi.manufacturerModelsPost(manufacturerModel)
    }
  }

  const navigateBack = () => {
    if (manufacturerModelId) {
      navigate(`/${appId}/manufacturer-models/${manufacturerModelId}/view`)
    } else {
      navigate(`/${appId}/manufacturer-models`)
    }
  }

  const handleOnCancel = () => {
    navigateBack()
  }

  const onSubmitSuccess = () => {
    setDirty(false)
    setLoading(false)

    navigateBack()
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  const submitForm = async (viewModel: ManufacturerModelViewModel) => {
    setLoading(true)
    setDirty(false)

    const manufacturerModel = mapViewModelToManufacturerModel(viewModel)
    return updateOrInsert(manufacturerModel)
      .then(() => onSubmitSuccess())
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  return (
    <ScreenLayout
      title={manufacturerModelId ? manufacturerModelViewModel.name : translate('createManufacturerModel')}
      onBack={navigateBack}
      actions={<></>}
      actionsWidth={50}
    >
      <LoadingIndicator loading={loading} />
      <Paper elevation={0}>
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <Box>
            <Form<ManufacturerModelViewModel>
              initialValues={manufacturerModelViewModel}
              onSubmit={submitForm}
              render={({ handleSubmit, submitting, pristine }) => {
                return (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      {readonly ? (
                        <Grid item md={12} lg={12}>
                          <Alert severity={'warning'}>{translate('manufacturerModel.nonEditable')}</Alert>
                        </Grid>
                      ) : null}
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TextField
                          label={translate('manufacturerModel.name')}
                          type="text"
                          name="name"
                          fullWidth
                          autoFocus={!editMode}
                          disabled={submitting || readonly}
                          validate={required()}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TextField
                          label={translate('manufacturerProductNumber')}
                          type="text"
                          name="productNumber"
                          fullWidth
                          autoFocus={!editMode}
                          disabled={submitting || readonly}
                          validate={required()}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS} lg={12} md={12}>
                        <AsyncAutoCompleteValidate
                          disabled={submitting || readonly}
                          name="manufacturer"
                          validate={required()}
                          label={translate('manufacturer')}
                          delay={300}
                          labelFieldName="name"
                          loadOptionsFunction={manufacturerGroupSearch}
                        />
                      </Grid>
                    </Grid>
                    <Divider className={sharedClasses.Divider} />
                    <Box mt={3} display="flex" justifyContent="flex-end">
                      <Button
                        id="cancelButton"
                        variant="text"
                        color="primary"
                        size="large"
                        className={sharedClasses.ButtonMargin}
                        onClick={handleOnCancel}
                      >
                        {translate('button.cancel')}
                      </Button>
                      <Button
                        id="submitButton"
                        disabled={(submitting || pristine) && readonly}
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                      >
                        {translate('button.save')}
                      </Button>
                    </Box>
                    <FormSpy
                      subscription={{ dirty: true }}
                      onChange={(props) => {
                        setTimeout(() => setDirty(props.dirty), 0)
                      }}
                    />
                  </form>
                )
              }}
            />
          </Box>
        )}
      </Paper>
    </ScreenLayout>
  )
}
