import React, { FC, ReactElement } from 'react'
import { Box, Grid } from '@mui/material'
import { LaundryGroupRecurringCalendarEntry } from 'src/service/backend/api'
import { LaundryGroupRecurringCalendarDay } from 'src/service/backend/api/models/LaundryGroupRecurringCalendarDay'
import { styleDayTitles, styleLegend } from 'src/ui-shared/calendar/Calendar.style'
import { LaundryGroupCalendarDay } from 'src/ui/page/common/laundry-group/details/LaundryGroupCalendarDay'
import { LaundryGroupCalendarLegend } from 'src/ui/page/common/laundry-group/details/LaundryGroupCalendarLegend'

interface Props {
  week: LaundryGroupRecurringCalendarDay[]
  updateRecurringCalendar: (values: LaundryGroupRecurringCalendarEntry) => Promise<void>
}

export const LaundryGroupCalendarWeek: FC<Props> = ({ week, updateRecurringCalendar }): ReactElement => {
  const dayRenders = week.map((item) => (
    <LaundryGroupCalendarDay
      dayTitlesOnly={false}
      updateRecurringCalendar={updateRecurringCalendar}
      day={item}
      key={item.dayOfWeek}
    />
  ))

  const dayRendersTitle = week.map((item) => (
    <LaundryGroupCalendarDay
      dayTitlesOnly={true}
      updateRecurringCalendar={updateRecurringCalendar}
      day={item}
      key={item.dayOfWeek}
    />
  ))

  return (
    <Box>
      <Box id="wmCalendar" pt={2} pb={2}>
        {/* Day names */}
        <Grid id="wmCalendarTitles" container spacing={1} style={styleDayTitles}>
          {dayRendersTitle}
        </Grid>
        {/* Slots for all week days */}
        <Grid id="wmCalendarSlots" container spacing={2}>
          {dayRenders}
        </Grid>
      </Box>

      {/* Legend */}
      <Box id="wmCalendarLegend" ml={-3} mr={-3} style={styleLegend}>
        {dayRenders.length !== 0 ? <LaundryGroupCalendarLegend /> : null}
      </Box>
    </Box>
  )
}
