import React, { FC, PropsWithChildren, ReactElement, useState } from 'react'
import {
  Box,
  DialogActions,
  DialogContent,
  Hidden,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import Button from '@mui/material/Button'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ModuleLiquidDetergentSettings } from 'src/service/backend/api'
import {
  LiquidDetergentViewModel,
  mapLiquidDetergentStepsToLiquidDetergentStepsViewModel,
  mapLiquidDetergentStepsViewModelToLiquidDetergentSteps,
} from 'src/service/view-model/program/LiquidDetergentViewModel'
import { getWashingStepName } from 'src/service/view-model/washing-step/WashingStep'
import { StyledTextFieldExtraSmall } from 'src/ui-shared/base/form/control/TextField.style'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { HeadCells } from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'

const headCells: HeadCells[] = [
  {
    id: 'stepNumber',
    label: 'liquidDetergent.step',
    noSort: true,
  },
  {
    id: 'displayStep',
    label: 'liquidDetergent.stepName',
    noSort: true,
  },
  {
    id: 'detergentPumpA',
    label: 'liquidDetergent.detergentPumpA',
    noSort: true,
  },
  {
    id: 'detergentPumpB',
    label: 'liquidDetergent.detergentPumpB',
    noSort: true,
  },
  {
    id: 'dosageA',
    label: 'liquidDetergent.dosageA',
    noSort: true,
  },
  {
    id: 'dosageB',
    label: 'liquidDetergent.dosageB',
    noSort: true,
  },
  {
    id: 'rinsingInTime',
    label: 'liquidDetergent.rinsingInTime',
    noSort: true,
  },
]

interface Props extends PropsWithChildren {
  open: boolean
  onCancel: () => void
  onConfirm: (steps: Array<ModuleLiquidDetergentSettings>) => Promise<any>
  liquidDetergentSteps: Array<ModuleLiquidDetergentSettings>
}

export const MachineProgramLiquidDetergentEditModal: FC<Props> = ({
  open,
  onCancel,
  onConfirm,
  liquidDetergentSteps,
}): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()

  // state
  const [liquidDetergentStepsViewModel, setLiquidDetergentStepsViewModel] = useState<LiquidDetergentViewModel[]>(
    mapLiquidDetergentStepsToLiquidDetergentStepsViewModel(liquidDetergentSteps),
  )
  const [detergentStepErrorIndex, setDetergentStepErrorIndex] = useState<number | null>(null)
  const [detergentStepErrorField, setDetergentStepErrorField] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  // derived state
  const detergentPumpMax = 14
  const dosageMax = 120

  type inputFields = Pick<
    LiquidDetergentViewModel,
    'detergentPumpA' | 'detergentPumpB' | 'dosageA' | 'dosageB' | 'rinsingInTime'
  >

  const validateDetergentInput = (index: number, value: string, field: string, maxValue: number) => {
    const detergentInputRegex = /^[0-9]+(\.[0-9]{1,2})?$/
    if (!detergentInputRegex.test(value) || parseInt(value) > maxValue) {
      setDetergentStepErrorIndex(index)
      setDetergentStepErrorField(field)
    } else {
      setDetergentStepErrorIndex(null)
      setDetergentStepErrorField(null)
    }
  }

  const onInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: keyof inputFields,
    maxValue: typeof detergentPumpMax | typeof dosageMax,
  ) => {
    const index = parseInt(event.target.name)
    const newDetergentSteps = [...liquidDetergentStepsViewModel]
    newDetergentSteps[index][field] = event.target.value
    setLiquidDetergentStepsViewModel(newDetergentSteps)
    validateDetergentInput(index, event.target.value, field, maxValue)
  }

  const errorFieldCheck = (index: number, field: keyof inputFields) => {
    return detergentStepErrorIndex === index && detergentStepErrorField === field
  }

  const helperTextShow = (
    index: number,
    field: keyof inputFields,
    maxValue: typeof detergentPumpMax | typeof dosageMax,
  ) => {
    let helperText = ''
    if (errorFieldCheck(index, field)) {
      helperText = translate('validation.valid.numberFromTo', 0, maxValue)
    }
    return helperText
  }

  const handleSubmit = () => {
    const steps = mapLiquidDetergentStepsViewModelToLiquidDetergentSteps(liquidDetergentStepsViewModel)
    setLoading(true)
    onConfirm(steps).finally(() => {
      setLoading(false)
    })
  }

  // JSX
  const displayEditableDetergentRows =
    liquidDetergentStepsViewModel.length > 0 ? (
      liquidDetergentStepsViewModel.map((item, index) => {
        return (
          <TableRow className={tableClasses.tableRow} key={index} style={{ cursor: 'default' }}>
            <TableCell>{item.stepNumber}</TableCell>
            <TableCell>{getWashingStepName(item.displayStep, translate)}</TableCell>
            <TableCell>
              <StyledTextFieldExtraSmall
                value={item.detergentPumpA}
                name={index.toString()}
                disabled={loading}
                onChange={(e) => onInputChange(e, 'detergentPumpA', detergentPumpMax)}
                error={errorFieldCheck(index, 'detergentPumpA')}
                helperText={helperTextShow(index, 'detergentPumpA', detergentPumpMax)}
              />
            </TableCell>
            <TableCell>
              <StyledTextFieldExtraSmall
                value={item.detergentPumpB}
                name={index.toString()}
                disabled={loading}
                onChange={(e) => onInputChange(e, 'detergentPumpB', detergentPumpMax)}
                error={errorFieldCheck(index, 'detergentPumpB')}
                helperText={helperTextShow(index, 'detergentPumpB', detergentPumpMax)}
              />
            </TableCell>
            <TableCell>
              <StyledTextFieldExtraSmall
                value={item.dosageA}
                name={index.toString()}
                disabled={loading}
                onChange={(e) => onInputChange(e, 'dosageA', dosageMax)}
                error={errorFieldCheck(index, 'dosageA')}
                helperText={helperTextShow(index, 'dosageA', dosageMax)}
                InputProps={{
                  endAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="end">s</InputAdornment>
                    </Hidden>
                  ),
                }}
              />
            </TableCell>
            <TableCell>
              <StyledTextFieldExtraSmall
                value={item.dosageB}
                name={index.toString()}
                disabled={loading}
                onChange={(e) => onInputChange(e, 'dosageB', dosageMax)}
                error={errorFieldCheck(index, 'dosageB')}
                helperText={helperTextShow(index, 'dosageB', dosageMax)}
                InputProps={{
                  endAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="end">s</InputAdornment>
                    </Hidden>
                  ),
                }}
              />
            </TableCell>
            <TableCell>
              <StyledTextFieldExtraSmall
                value={item.rinsingInTime}
                name={index.toString()}
                disabled={loading}
                onChange={(e) => onInputChange(e, 'rinsingInTime', dosageMax)}
                error={errorFieldCheck(index, 'rinsingInTime')}
                helperText={helperTextShow(index, 'rinsingInTime', dosageMax)}
                InputProps={{
                  endAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="end">s</InputAdornment>
                    </Hidden>
                  ),
                }}
              />
            </TableCell>
          </TableRow>
        )
      })
    ) : (
      <TableEmpty colspan={headCells.length} />
    )

  const displayHeaders = headCells.map((item) => {
    return <TableCell key={item.id}>{translate(item.label)}</TableCell>
  })

  const displayTable = (
    <Paper elevation={0}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>{displayHeaders}</TableRow>
          </TableHead>
          <TableBody>{displayEditableDetergentRows}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )

  return (
    <ModalDialog open={open} onClose={onCancel} title={translate('editLiquidDetergentSteps')} maxWidth="md">
      <LoadingIndicator loading={loading} />
      <DialogContent>{displayTable}</DialogContent>
      <DialogActions>
        <Box mt={2} p={2} display="flex" justifyContent="flex-end">
          <Button variant="text" color="primary" size="large" className={sharedClasses.ButtonMargin} onClick={onCancel}>
            {translate('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={liquidDetergentSteps.length === 0 || loading}
            onClick={handleSubmit}
          >
            {translate('button.save')}
          </Button>
        </Box>
      </DialogActions>
    </ModalDialog>
  )
}
