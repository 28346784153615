import React, { FC, ReactElement } from 'react'
import { Box, Grid } from '@mui/material'
import locale from 'date-fns/locale/en-GB'
import { CalendarEntrySingleDay, SlotAndDay } from 'src/service/view-model/base/calendar/Calendar.const'
import { styleDayTitles, styleLegend } from 'src/ui-shared/calendar/Calendar.style'
import { CalendarDay } from 'src/ui-shared/calendar/CalendarDay'

interface Props {
  week: CalendarEntrySingleDay[]
  selectSlotAndDay: (slot: SlotAndDay) => void
  legend?: () => ReactElement
}

if (locale.options) {
  locale.options.weekStartsOn = 1
}

const CalendarFnc: FC<Props> = ({ week, selectSlotAndDay, legend }): ReactElement => {
  const dayRendersTitle = week.map((item) => (
    <CalendarDay day={item} dayTitlesOnly={true} selectSlotAndDay={selectSlotAndDay} key={item.dayOfWeek} />
  ))

  const dayRenders = week.map((item) => (
    <CalendarDay day={item} dayTitlesOnly={false} selectSlotAndDay={selectSlotAndDay} key={item.dayOfWeek} />
  ))

  return (
    <Box>
      <Box id="wmCalendar" pt={2} pb={2}>
        {/* Day names */}
        <Grid id="wmCalendarTitles" container spacing={1} style={styleDayTitles}>
          {dayRendersTitle}
        </Grid>
        {/* Slots for all week days */}
        <Grid id="wmCalendarSlots" container spacing={2}>
          {dayRenders}
        </Grid>
      </Box>

      {/* Legend */}
      <Box id="wmCalendarLegend" ml={-3} mr={-3} style={styleLegend}>
        {dayRenders.length !== 0 ? (legend ? legend() : null) : null}
      </Box>
    </Box>
  )
}

export const Calendar = React.memo(CalendarFnc)
