/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimeseriesAnnotations } from './TimeseriesAnnotations';
import {
    TimeseriesAnnotationsFromJSON,
    TimeseriesAnnotationsFromJSONTyped,
    TimeseriesAnnotationsToJSON,
} from './TimeseriesAnnotations';
import type { TimeseriesDataset } from './TimeseriesDataset';
import {
    TimeseriesDatasetFromJSON,
    TimeseriesDatasetFromJSONTyped,
    TimeseriesDatasetToJSON,
} from './TimeseriesDataset';
import type { TimeseriesValueState } from './TimeseriesValueState';
import {
    TimeseriesValueStateFromJSON,
    TimeseriesValueStateFromJSONTyped,
    TimeseriesValueStateToJSON,
} from './TimeseriesValueState';

/**
 * 
 * @export
 * @interface TimeseriesData
 */
export interface TimeseriesData {
    /**
     * 
     * @type {string}
     * @memberof TimeseriesData
     */
    title?: string;
    /**
     * contains the datasets with values
     * @type {Array<TimeseriesDataset>}
     * @memberof TimeseriesData
     */
    datasets: Array<TimeseriesDataset>;
    /**
     * contains the possible machine utilization states with translation and color
     * @type {Array<TimeseriesValueState>}
     * @memberof TimeseriesData
     */
    valueStates: Array<TimeseriesValueState>;
    /**
     * 
     * @type {TimeseriesAnnotations}
     * @memberof TimeseriesData
     */
    annotations?: TimeseriesAnnotations;
}

/**
 * Check if a given object implements the TimeseriesData interface.
 */
export function instanceOfTimeseriesData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "datasets" in value;
    isInstance = isInstance && "valueStates" in value;

    return isInstance;
}

export function TimeseriesDataFromJSON(json: any): TimeseriesData {
    return TimeseriesDataFromJSONTyped(json, false);
}

export function TimeseriesDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeseriesData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'datasets': ((json['datasets'] as Array<any>).map(TimeseriesDatasetFromJSON)),
        'valueStates': ((json['valueStates'] as Array<any>).map(TimeseriesValueStateFromJSON)),
        'annotations': !exists(json, 'annotations') ? undefined : TimeseriesAnnotationsFromJSON(json['annotations']),
    };
}

export function TimeseriesDataToJSON(value?: TimeseriesData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'datasets': ((value.datasets as Array<any>).map(TimeseriesDatasetToJSON)),
        'valueStates': ((value.valueStates as Array<any>).map(TimeseriesValueStateToJSON)),
        'annotations': TimeseriesAnnotationsToJSON(value.annotations),
    };
}

