import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { Box, Divider, MenuItem, Paper } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  ApiResponse,
  Configuration,
  LaundryGroupPriceList,
  LaundryGroupPriceListsApi,
  LaundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeDeleteRequest,
  LaundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypePutRequest,
  MachineType,
  Permission,
  PriceListType,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { MenuButton } from 'src/ui-shared/base/button/MenuButton'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryGroupPriceListExternalView } from 'src/ui/page/common/laundry-group/price-list/LaundryGroupPriceListExternalView'
import { LaundryGroupPriceListInternalView } from 'src/ui/page/common/laundry-group/price-list/LaundryGroupPriceListInternalView'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const LaundryGroupPriceListPage = (): ReactElement => {
  const { laundryGroupId, machineTypeParam } = useRequiredParams(['laundryGroupId', 'machineTypeParam'])
  const [searchParams] = useSearchParams()
  const drumSize = searchParams.get('drumSize')
  const drumSizeNumber: number | undefined = drumSize ? Number(drumSize) : undefined
  const machineType = getEnumFromString(machineTypeParam, MachineType)

  const navigate = useNavigate()
  const user = useUser()
  const translate = useTranslate()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupPriceListsApi = new LaundryGroupPriceListsApi(httpConfiguration)

  const [laundryGroupPriceList, setLaundryGroupPriceList] = useState<LaundryGroupPriceList | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  const [showPricelistResetConfirmation, setShowPricelistResetConfirmation] = useState(false)
  const [showToZeroPriceConfirmation, setShowToZeroPriceConfirmation] = useState(false)

  useHotKeysForm()

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  const navigateBack = () => {
    navigate(`/${appId}/laundry-groups/${laundryGroupId}/view/prices`)
  }

  const navigateToEditPage = () => {
    navigate(
      `/${appId}/laundry-groups/${laundryGroupId}/view/prices/${machineType}/edit` +
        (drumSizeNumber ? '?drumSize=' + drumSizeNumber.toString() : ''),
    )
  }

  const resetPriceListConfirmation = () => {
    setShowPricelistResetConfirmation(true)
  }

  const loadData = (): void => {
    setLoading(true)
    laundryGroupPriceListsApi
      .laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeGet(laundryGroupId, machineType, drumSizeNumber)
      .then((data) => {
        setLaundryGroupPriceList(data)
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLoading(false)
      })
  }

  const resetPriceList = () => {
    setShowPricelistResetConfirmation(false)
    setLoading(true)

    const request: LaundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeDeleteRequest = {
      laundryGroupId: laundryGroupId,
      machineType: machineType,
      drumSize: drumSizeNumber,
    }

    laundryGroupPriceListsApi
      .laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeDeleteRaw(request)
      .then((response: ApiResponse<void>) => {
        if (response.raw.status === 205) {
          setLaundryGroupPriceList(null)
          loadData()
        } else {
          navigateBack()
        }
      })
      .catch((err) => {
        setLoading(false)
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  const setToZeroPriceConfirmation = () => {
    setShowToZeroPriceConfirmation(true)
  }

  const setToZeroPrice = () => {
    setShowToZeroPriceConfirmation(false)
    setLoading(true)

    if (laundryGroupPriceList && laundryGroupPriceList.integrated) {
      const integrated = laundryGroupPriceList.integrated
      integrated.prices.forEach((price) => {
        price.regularAmount = 0
        price.happyHourAmount = 0
      })

      const request: LaundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypePutRequest = {
        laundryGroupId: laundryGroupId,
        machineType: machineType,
        drumSize: drumSizeNumber,
        laundryGroupPriceList: laundryGroupPriceList,
      }

      laundryGroupPriceListsApi
        .laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypePutRaw(request)
        .then(() => {
          loadData()
        })
        .catch((err) => {
          setLoading(false)
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          onSubmitReject(errorMessage)
        })
    }
  }

  const editAction = (
    <>
      <ListingButton id="editButton" onClick={navigateToEditPage} variant="outlined" color="primary">
        {translate('button.edit')}
      </ListingButton>

      <MenuButton>
        <MenuItem onClick={resetPriceListConfirmation}>{translate('button.resetPricelist')}</MenuItem>
        <MenuItem
          onClick={setToZeroPriceConfirmation}
          disabled={!(laundryGroupPriceList && laundryGroupPriceList.integrated)}
        >
          {translate('setToZeroPrice')}
        </MenuItem>
      </MenuButton>
    </>
  )

  const actions = hasPermission(user, Permission.PRICE_LIST_WRITE) ? editAction : <></>

  useEffect(loadData, [])

  const pageTitle =
    laundryGroupPriceList?.priceListType === PriceListType.INTEGRATED
      ? laundryGroupPriceList.integrated?.name
      : laundryGroupPriceList?.external?.name || ''

  return (
    <>
      <ScreenLayout title={pageTitle ? pageTitle : ''} onBack={navigateBack} actions={actions}>
        <Paper elevation={0}>
          <LoadingIndicator loading={loading} />
          <Divider />
          {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
          <Box pt={2}>
            {laundryGroupPriceList ? (
              laundryGroupPriceList.priceListType === PriceListType.INTEGRATED ? (
                <LaundryGroupPriceListInternalView priceList={laundryGroupPriceList.integrated} />
              ) : (
                <LaundryGroupPriceListExternalView priceList={laundryGroupPriceList.external} />
              )
            ) : null}
          </Box>

          <ConfirmationModalDialog
            titleKey="resetPricelistConfirmationTitle"
            confirmationKey="button.resetPricelist"
            open={showPricelistResetConfirmation}
            onConfirm={resetPriceList}
            onCancel={() => setShowPricelistResetConfirmation(false)}
          >
            {translate('resetPricelistConfirmation')}
          </ConfirmationModalDialog>

          <ConfirmationModalDialog
            titleKey="setToZeroPrice"
            confirmationKey="setToZeroPrice"
            open={showToZeroPriceConfirmation}
            onConfirm={setToZeroPrice}
            onCancel={() => setShowToZeroPriceConfirmation(false)}
          >
            {translate('setToZeroPriceConfirmation')}
          </ConfirmationModalDialog>
        </Paper>
      </ScreenLayout>
    </>
  )
}
