/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentMethod {
    PREPAID = 'PREPAID',
    DIRECTPAY = 'DIRECTPAY',
    FREE = 'FREE',
    REFUND = 'REFUND'
}


export function PaymentMethodFromJSON(json: any): PaymentMethod {
    return PaymentMethodFromJSONTyped(json, false);
}

export function PaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethod {
    return json as PaymentMethod;
}

export function PaymentMethodToJSON(value?: PaymentMethod | null): any {
    return value as any;
}

