import React, { ReactElement, useMemo } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { Box, FormHelperText } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { TimezoneObject } from 'src/service/view-model/base/localization/Timezones'
import { TimezoneAutoComplete, TimezoneAutoCompleteProps } from 'src/ui-shared/form/control/TimezoneAutoComplete'

interface TimezoneAutoCompleteField extends TimezoneAutoCompleteProps {
  validate?: any
}

type ExposeTimezoneAutoCompleteFieldProps = Omit<TimezoneAutoCompleteField, 'onChange' | 'value'>

type AutoCompleteFieldValueType = TimezoneObject | undefined

const CustomTimezoneAutoComplete = (props: FieldRenderProps<AutoCompleteFieldValueType>): ReactElement => {
  const {
    input: { name, value, onChange: onChangeFinalForm },
    meta,
    disabled,
    ...rest
  } = props

  const { children, render, ...autoCompleteProps } = rest

  const { touched, error: errorObject } = meta

  const translate = useTranslate()

  const error = errorObject && translate(errorObject.errorKey, errorObject.params)

  const invalid = Boolean(touched && error)

  const onChangeMemo = useMemo<(optionValue: AutoCompleteFieldValueType) => void>(
    () =>
      (optionValue: AutoCompleteFieldValueType): void => {
        onChangeFinalForm(optionValue)
      },
    [onChangeFinalForm],
  )

  const finalAutoCompleteProps = {
    ...autoCompleteProps,
    onChange: onChangeMemo,
    disabled,
    name: name,
    error: invalid,
    value,
  } as TimezoneAutoCompleteProps

  return (
    <Box>
      <TimezoneAutoComplete {...finalAutoCompleteProps} />
      {invalid && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}

export const TimezoneAutoCompleteValidate = (props: ExposeTimezoneAutoCompleteFieldProps): ReactElement => (
  <Field component={CustomTimezoneAutoComplete} {...props} />
)
