import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Icon, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ActiveOrganizationContext } from 'src/organization/ActiveOrganizationProvider'
import { OrganizationReference } from 'src/service/backend/api'
import { getActiveOrganizationFromStorage } from 'src/service/local-storage/LocalStorageService'
import { truncateText } from 'src/service/utils/TextUtils'
import { FilterListIcon } from 'src/ui-shared/icons/Icons'
import { StyledMenuItem } from 'src/ui/layout/appbar-menu/MenuStyle'
import { SelectOrganizationDialog } from 'src/ui/layout/organization-selector/SelectOrganizationDialog'

interface Props {
  organizationIsMandatory?: boolean
}

export const ActiveOrganizationSelector: FC<Props> = ({ organizationIsMandatory }): ReactElement => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { activeOrganization, setActiveOrganizationId, organizations } = useContext(ActiveOrganizationContext)

  // state
  const [changeOrganizationModal, setChangeOrganizationModal] = useState<boolean>(false)

  const activeOrganizationName = smallScreen ? truncateText(8, activeOrganization?.name) : activeOrganization?.name

  // load data on state change
  useEffect(() => {
    // do not pre-select active organization from storage if there is one already selected (from url)
    if (activeOrganization !== undefined) {
      return
    }

    if (organizations === undefined) {
      // organizations are not loaded yet, ignore
      return
    }

    // handle mandatory organization on top level
    if (organizations.length === 0 && organizationIsMandatory) {
      throw Error('Active organization is mandatory, but organizations are empty')
    }

    // check if we have organizations
    if (organizations.length >= 1) {
      let orgToSelect: string | undefined

      // try to select the last organization the user used (if any)
      const lastUsedActiveOrgId = getActiveOrganizationFromStorage()
      if (lastUsedActiveOrgId) {
        orgToSelect = lastUsedActiveOrgId
      }

      if (orgToSelect) {
        setActiveOrganizationId(orgToSelect)
      }
    }
  }, [organizations])

  const changeActiveOrganization = (newActiveOrganization?: OrganizationReference) => {
    setActiveOrganizationId(newActiveOrganization?.id)
    setChangeOrganizationModal(false)
  }

  const openOrgModal = () => {
    setChangeOrganizationModal(true)
  }

  return (
    <>
      <StyledMenuItem active={0} onClick={openOrgModal}>
        {!activeOrganization ? (
          <FilterListIcon />
        ) : (
          <Typography
            variant="h6"
            color="white"
            sx={{
              maxWidth: 300,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              background: '#00000066',
              borderRadius: 3,
              paddingRight: smallScreen ? 0.5 : 1,
              paddingLeft: smallScreen ? 0.5 : 1,
            }}
          >
            <Link underline="none" color="white" style={{ cursor: 'pointer' }}>
              <Icon sx={{ marginRight: 1, marginBottom: '-2px' }}>
                <FilterListIcon />
              </Icon>
              {activeOrganizationName}
            </Link>
          </Typography>
        )}
      </StyledMenuItem>

      {changeOrganizationModal && (
        <SelectOrganizationDialog
          open={changeOrganizationModal}
          setOpen={setChangeOrganizationModal}
          onConfirm={changeActiveOrganization}
          showNoneOrganizationOption={!organizationIsMandatory}
          preselectedOrganization={activeOrganization ?? undefined}
          displayActions={false}
          modalTitle={'activeOrganization'}
        />
      )}
    </>
  )
}
