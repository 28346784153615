import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Box, Divider, Paper } from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, LaundriesApi, Laundry, Permission } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { AddressSection } from 'src/ui-shared/form/address-section/AddressSection'
import { DeleteIcon } from 'src/ui-shared/icons/Icons'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryGroupTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupTab'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const LaundryDetailsPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()

  const navigate = useNavigate()
  const { laundryId } = useRequiredParams(['laundryId'])
  const appId = useAppId()
  const location = useLocation()
  const showSnackbar = useShowSnackbar()
  const user = useUser()

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundriesApi = new LaundriesApi(httpConfiguration)

  // state
  const [laundry, setLaundry] = useState<Laundry | null>(null)
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const canEdit = hasPermission(user, Permission.LAUNDRY_WRITE)

  useHotKeysForm()

  // load data
  useEffect(() => {
    laundriesApi
      .laundriesLaundryIdGet(laundryId)
      .then((laundry) => {
        setLaundry(laundry)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }, [laundryId])

  // handle events
  const onRemoveHandler = (): void => {
    if (laundry) {
      laundriesApi
        .laundriesLaundryIdDelete(laundryId)
        .then(() => {
          navigateBack()
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
        })
    }
    setRemoveModal(false)
  }

  const navigateToEditPage = () => {
    navigate(`/${appId}/laundries/${laundryId}/edit`)
  }

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/laundries`)
  }

  const displayPosTerminalIds = (ids: string[] | undefined): string => {
    return ids ? ids.join(', ') : ''
  }

  // JSX
  const editButton = (
    <Button id="editButton" variant="contained" color="primary" size="large" onClick={navigateToEditPage}>
      {translate('button.edit')}
    </Button>
  )

  const editAction = canEdit ? editButton : <></>

  const deleteButton = (
    <Button
      startIcon={<DeleteIcon />}
      variant="outlined"
      color="primary"
      size="large"
      className={sharedClasses.ButtonMargin}
      onClick={() => setRemoveModal(true)}
    >
      {translate('button.delete')}
    </Button>
  )

  const deleteAction = canEdit ? deleteButton : <></>

  return (
    <ScreenLayout title={laundry?.name ? laundry.name : ''} onBack={navigateBack} actionsWidth={50}>
      <Paper elevation={0}>
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <Box pt={2}>
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={laundry?.name} label={translate('laundry.name')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  value={laundry?.laundryGroup.name}
                  label={translate('laundryGroupName')}
                  link={`/${appId}/laundry-groups/${laundry?.laundryGroup.id}/view/${LaundryGroupTab.OVERVIEW}`}
                />
              </Grid>
            </Grid>
            <Divider className={sharedClasses.Divider} />
            <AddressSection address={laundry?.address} />
            <Divider className={sharedClasses.Divider} />
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                <DetailsTextField value={laundry?.notes} label={translate('notes')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={laundry?.wifiSSID} label={translate('laundry.wifiSSID')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={laundry?.wifiSecret} label={translate('laundry.wifiSecret')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  value={laundry?.washmaster?.terminalSerialNumber}
                  label={translate('terminalSerialNumber')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={laundry?.washmaster?.terminalPassword} label={translate('terminalPassword')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                <DetailsTextField
                  value={displayPosTerminalIds(laundry?.washmaster?.posTerminalIds)}
                  label={translate('posTerminalIds')}
                />
              </Grid>
            </Grid>
            <Divider className={sharedClasses.Divider} />
            <Box pt={2} display="flex" justifyContent="space-between">
              <Box>{deleteAction}</Box>
              <Box display="flex" justifyContent="flex-end">
                {editAction}
              </Box>
            </Box>
          </Box>
        )}
      </Paper>
      <ConfirmationModalDialog
        titleKey="deleteLaundry"
        confirmationKey="button.delete"
        open={removeModal}
        onConfirm={onRemoveHandler}
        onCancel={() => setRemoveModal(false)}
      >
        {translate('deleteConfirm', laundry?.name)}
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
