import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, LaundryGroup, LaundryGroupsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  LaundryGroupGenericSettingsViewModel,
  createLaundryGroupGenericSettingsViewModel,
  mapLaundryGroupGenericSettingsViewModelToLaundryGroupSettings,
} from 'src/service/view-model/laundry-group/LaundryGroupGenericSettingsViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { composeValidators, required, validNumberRange } from 'src/ui-shared/base/form/validation/Validators'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

interface Props {
  laundryGroup: LaundryGroup
  closeModal: (successMessage?: string) => void
}

export const LaundryGroupGenericConfig: FC<Props> = ({ laundryGroup, closeModal }): ReactElement => {
  const { id } = laundryGroup

  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupApi = new LaundryGroupsApi(httpConfiguration)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [viewModel, setViewModel] = useState<LaundryGroupGenericSettingsViewModel>(
    createLaundryGroupGenericSettingsViewModel(),
  )

  useEffect(() => {
    let active = true
    setLoading(true)

    laundryGroupApi
      .laundrygroupsLaundryGroupIdSettingsGet(id)
      .then((settings) => {
        if (!active) return
        setViewModel(createLaundryGroupGenericSettingsViewModel(settings))
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })

    return () => {
      active = false
    }
  }, [])

  // handle events
  const submitForm = (viewModel: LaundryGroupGenericSettingsViewModel) => {
    laundryGroupApi
      .laundrygroupsLaundryGroupIdSettingsPut(
        id,
        mapLaundryGroupGenericSettingsViewModelToLaundryGroupSettings(viewModel),
      )
      .then(() => {
        closeModal(translate('laundryGroupGenericSettingsChanged'))
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  return (
    <Box p={3}>
      <LoadingIndicator loading={loading} />
      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
      <Form<LaundryGroupGenericSettingsViewModel>
        initialValues={viewModel}
        onSubmit={submitForm}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="revenueChangeLimit"
                      label={translate('revenueChangeLimit')}
                      validate={composeValidators(
                        required(),
                        validNumberRange(-100, 0, 'validation.valid.negativePercentage'),
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="badTrendUtilizationLimit"
                      label={translate('badTrendUtilizationLimit')}
                      validate={composeValidators(
                        required(),
                        validNumberRange(-100, 0, 'validation.valid.negativePercentage'),
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="dailyGoalUtilizationLimit"
                      label={translate('dailyGoalUtilizationLimit')}
                      validate={composeValidators(
                        required(),
                        validNumberRange(0, 100, 'validation.valid.positivePercentage'),
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  color="primary"
                  size="large"
                  className={sharedClasses.ButtonMargin}
                  onClick={() => closeModal()}
                >
                  {translate('button.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={submitting || pristine}
                >
                  {translate('apply')}
                </Button>
              </Box>
            </form>
          )
        }}
      />
    </Box>
  )
}
