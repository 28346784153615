/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';
import type { ProgramType } from './ProgramType';
import {
    ProgramTypeFromJSON,
    ProgramTypeFromJSONTyped,
    ProgramTypeToJSON,
} from './ProgramType';

/**
 * all fields cannot be changed, if programType is STANDARD (programIndex 1-399)
 * @export
 * @interface ProgramMetaData
 */
export interface ProgramMetaData {
    /**
     * 
     * @type {string}
     * @memberof ProgramMetaData
     */
    readonly id: string;
    /**
     * 
     * @type {OrganizationReference}
     * @memberof ProgramMetaData
     */
    organization?: OrganizationReference;
    /**
     * 
     * @type {MachineType}
     * @memberof ProgramMetaData
     */
    machineType: MachineType;
    /**
     * master program Index (1-399 reserved for programType STANDARD, 400+ for CUSTOM)
     * @type {number}
     * @memberof ProgramMetaData
     */
    programIndex: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramMetaData
     */
    programName: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramMetaData
     */
    programMode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProgramMetaData
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProgramMetaData
     */
    temperature?: number | null;
    /**
     * Unit of Temperatures ('В°C' | 'В°F')
     * @type {string}
     * @memberof ProgramMetaData
     */
    temperatureUnit?: string;
    /**
     * 
     * @type {ProgramType}
     * @memberof ProgramMetaData
     */
    programType: ProgramType;
    /**
     * 
     * @type {Date}
     * @memberof ProgramMetaData
     */
    readonly lastModified?: Date;
}

/**
 * Check if a given object implements the ProgramMetaData interface.
 */
export function instanceOfProgramMetaData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "machineType" in value;
    isInstance = isInstance && "programIndex" in value;
    isInstance = isInstance && "programName" in value;
    isInstance = isInstance && "programType" in value;

    return isInstance;
}

export function ProgramMetaDataFromJSON(json: any): ProgramMetaData {
    return ProgramMetaDataFromJSONTyped(json, false);
}

export function ProgramMetaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramMetaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'organization': !exists(json, 'organization') ? undefined : OrganizationReferenceFromJSON(json['organization']),
        'machineType': MachineTypeFromJSON(json['machineType']),
        'programIndex': json['programIndex'],
        'programName': json['programName'],
        'programMode': !exists(json, 'programMode') ? undefined : json['programMode'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'temperature': !exists(json, 'temperature') ? undefined : json['temperature'],
        'temperatureUnit': !exists(json, 'temperatureUnit') ? undefined : json['temperatureUnit'],
        'programType': ProgramTypeFromJSON(json['programType']),
        'lastModified': !exists(json, 'lastModified') ? undefined : (new Date(json['lastModified'])),
    };
}

export function ProgramMetaDataToJSON(value?: ProgramMetaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization': OrganizationReferenceToJSON(value.organization),
        'machineType': MachineTypeToJSON(value.machineType),
        'programIndex': value.programIndex,
        'programName': value.programName,
        'programMode': value.programMode,
        'description': value.description,
        'temperature': value.temperature,
        'temperatureUnit': value.temperatureUnit,
        'programType': ProgramTypeToJSON(value.programType),
    };
}

