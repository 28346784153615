import { TranslateFunction } from 'src/i18n/useMessageSource'
import {
  IssueCategoryReference,
  IssueCreatorType,
  IssueEntry,
  IssueStateData,
  LaundryGroupReference,
  LaundryReference,
  MachineReference,
  OrganizationReference,
  ServiceType,
} from 'src/service/backend/api'
import { formatDate, formatTimeStringForLocale } from 'src/service/utils/DateFormatUtils'
import { DateUtils } from 'src/service/utils/DateUtils'
import { SupportedLocale } from 'src/service/view-model/base/localization/SupportedLocale'

export const TIME_SLOT_MORNING = '08:00 - 12:00'
export const TIME_SLOT_EVENING = '13:00 - 17:00'
export type TIME_SLOT_PERIOD = typeof TIME_SLOT_MORNING | typeof TIME_SLOT_EVENING

type Period = 'from' | 'to'

export interface ServiceRequestViewModel {
  id: string
  ticketKey: string
  creationTimestamp: Date
  closingTimestamp?: Date | null
  serviceType: ServiceType
  category?: IssueCategoryReference
  callbackTimeSlot?: TIME_SLOT_PERIOD
  callbackDate: Date
  description: string
  contactName?: string
  contactPhoneNumber?: string
  organizationRef: OrganizationReference | null
  laundryGroupRef: LaundryGroupReference | null
  laundryRef: LaundryReference | null
  machineRef: MachineReference | null
}

export const mapServiceRequestViewModelToServiceRequest = (viewModel: ServiceRequestViewModel): IssueEntry => {
  const issueEntry: IssueEntry = {
    id: viewModel.id,
    ticketKey: viewModel.ticketKey,
    creationTimestamp: viewModel.creationTimestamp,
    closingTimestamp: viewModel.closingTimestamp,
    issueState: {} as IssueStateData,
    errorSeverity: undefined,
    serviceType: viewModel.serviceType,
    category: viewModel.category,
    description: viewModel.description,
    contactName: viewModel.contactName,
    contactPhoneNumber: viewModel.contactPhoneNumber || null,
    callbackTimeSlotFrom: getCallbackDateTime(viewModel.callbackDate, viewModel.callbackTimeSlot!, 'from'),
    callbackTimeSlotTo: getCallbackDateTime(viewModel.callbackDate, viewModel.callbackTimeSlot!, 'to'),
    organization: viewModel.organizationRef || undefined,
    laundryGroup: viewModel.laundryGroupRef || undefined,
    laundry: viewModel.laundryRef || undefined,
    machine: viewModel.machineRef!,
    actions: [],
  }
  return issueEntry
}

export const createServiceRequestViewModel = (): ServiceRequestViewModel => {
  const now = new Date()
  return {
    id: '',
    ticketKey: '',
    creationTimestamp: now,
    closingTimestamp: null,
    serviceType: ServiceType.ONSITE,
    category: undefined,
    callbackTimeSlot: TIME_SLOT_MORNING,
    callbackDate: now,
    description: '',
    contactName: '',
    contactPhoneNumber: '',
    organizationRef: null,
    laundryGroupRef: null,
    laundryRef: null,
    machineRef: null,
  }
}

const getCallbackDateTime = (callbackDate: Date, timeSlot: TIME_SLOT_PERIOD, period: Period): Date => {
  const dateString = formatDate(callbackDate)
  let timeString
  if (period === 'from') {
    timeString = timeSlot.split(' - ')[0]
  } else {
    timeString = timeSlot.split(' - ')[1]
  }
  const dateTime = DateUtils.parseDateTime(dateString + ' ' + timeString)

  return dateTime
}

export const getIssueCreatorTypeLabel = (
  issueCreatorType: IssueCreatorType | undefined,
  translate: TranslateFunction,
): string => {
  if (!issueCreatorType) {
    return ''
  }

  let key = issueCreatorType.toString()
  if (issueCreatorType === IssueCreatorType.BACKEND_USER) {
    key = 'backendUser'
  } else if (issueCreatorType === IssueCreatorType.LAUNDRY_USER) {
    key = 'laundryUser'
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (issueCreatorType === IssueCreatorType.SYSTEM) {
    key = 'system'
  }

  return translate(key)
}

export const getTimeSlotPeriodLabel = (timeSlot: TIME_SLOT_PERIOD, regionLocale: SupportedLocale): string => {
  const [firstHour, secondHour] = timeSlot.split('-')

  const firstHourTrimmed = firstHour.trim()
  const secondHourTrimmed = secondHour.trim()

  const firstHourFormatted = formatTimeStringForLocale(firstHourTrimmed, regionLocale)
  const secondHourFormatted = formatTimeStringForLocale(secondHourTrimmed, regionLocale)

  return `${firstHourFormatted} - ${secondHourFormatted}`
}
