/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HygieneMonitoringDetail,
  PageableHygieneMonitoringInformation,
} from '../models';
import {
    HygieneMonitoringDetailFromJSON,
    HygieneMonitoringDetailToJSON,
    PageableHygieneMonitoringInformationFromJSON,
    PageableHygieneMonitoringInformationToJSON,
} from '../models';

export interface ReportSmHygieneMonitoringsGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    size?: number;
    page?: number;
    sort?: string;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
}

export interface ReportSmHygieneMonitoringsHmIdGetRequest {
    hmId: string;
}

export interface ReportSmHygieneMonitoringsHmIdPdfGetRequest {
    hmId: string;
}

/**
 * 
 */
export class ReportHygieneMonitoringsApi extends runtime.BaseAPI {

    /**
     * Download the public key of the PDF signature
     */
    async pubPdfSignaturePublicKeyGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/pdf/signature-public-key`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download the public key of the PDF signature
     */
    async pubPdfSignaturePublicKeyGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.pubPdfSignaturePublicKeyGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * List available Hygiene-monitorings
     */
    async reportSmHygieneMonitoringsGetRaw(requestParameters: ReportSmHygieneMonitoringsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableHygieneMonitoringInformation>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportSmHygieneMonitoringsGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportSmHygieneMonitoringsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/hygiene-monitorings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableHygieneMonitoringInformationFromJSON(jsonValue));
    }

    /**
     * List available Hygiene-monitorings
     */
    async reportSmHygieneMonitoringsGet(startTimeFrom: Date, startTimeTo: Date, size?: number, page?: number, sort?: string, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableHygieneMonitoringInformation> {
        const response = await this.reportSmHygieneMonitoringsGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, size: size, page: page, sort: sort, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId }, initOverrides);
        return await response.value();
    }

    /**
     * Get hygiene-monitoring Detail
     */
    async reportSmHygieneMonitoringsHmIdGetRaw(requestParameters: ReportSmHygieneMonitoringsHmIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HygieneMonitoringDetail>> {
        if (requestParameters.hmId === null || requestParameters.hmId === undefined) {
            throw new runtime.RequiredError('hmId','Required parameter requestParameters.hmId was null or undefined when calling reportSmHygieneMonitoringsHmIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/hygiene-monitorings/{hmId}`.replace(`{${"hmId"}}`, encodeURIComponent(String(requestParameters.hmId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HygieneMonitoringDetailFromJSON(jsonValue));
    }

    /**
     * Get hygiene-monitoring Detail
     */
    async reportSmHygieneMonitoringsHmIdGet(hmId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HygieneMonitoringDetail> {
        const response = await this.reportSmHygieneMonitoringsHmIdGetRaw({ hmId: hmId }, initOverrides);
        return await response.value();
    }

    /**
     * Download the PDF-Certificate
     */
    async reportSmHygieneMonitoringsHmIdPdfGetRaw(requestParameters: ReportSmHygieneMonitoringsHmIdPdfGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.hmId === null || requestParameters.hmId === undefined) {
            throw new runtime.RequiredError('hmId','Required parameter requestParameters.hmId was null or undefined when calling reportSmHygieneMonitoringsHmIdPdfGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/hygiene-monitorings/{hmId}/pdf`.replace(`{${"hmId"}}`, encodeURIComponent(String(requestParameters.hmId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download the PDF-Certificate
     */
    async reportSmHygieneMonitoringsHmIdPdfGet(hmId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.reportSmHygieneMonitoringsHmIdPdfGetRaw({ hmId: hmId }, initOverrides);
        return await response.value();
    }

}
