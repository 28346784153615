/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HMResult } from './HMResult';
import {
    HMResultFromJSON,
    HMResultFromJSONTyped,
    HMResultToJSON,
} from './HMResult';
import type { HMState } from './HMState';
import {
    HMStateFromJSON,
    HMStateFromJSONTyped,
    HMStateToJSON,
} from './HMState';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';
import type { LaundryReference } from './LaundryReference';
import {
    LaundryReferenceFromJSON,
    LaundryReferenceFromJSONTyped,
    LaundryReferenceToJSON,
} from './LaundryReference';
import type { MachineReference } from './MachineReference';
import {
    MachineReferenceFromJSON,
    MachineReferenceFromJSONTyped,
    MachineReferenceToJSON,
} from './MachineReference';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';

/**
 * 
 * @export
 * @interface HygieneMonitoringInformation
 */
export interface HygieneMonitoringInformation {
    /**
     * 
     * @type {string}
     * @memberof HygieneMonitoringInformation
     */
    readonly id: string;
    /**
     * 
     * @type {OrganizationReference}
     * @memberof HygieneMonitoringInformation
     */
    organization: OrganizationReference;
    /**
     * 
     * @type {LaundryGroupReference}
     * @memberof HygieneMonitoringInformation
     */
    laundryGroup: LaundryGroupReference;
    /**
     * 
     * @type {LaundryReference}
     * @memberof HygieneMonitoringInformation
     */
    laundry: LaundryReference;
    /**
     * 
     * @type {MachineReference}
     * @memberof HygieneMonitoringInformation
     */
    machine: MachineReference;
    /**
     * 
     * @type {Date}
     * @memberof HygieneMonitoringInformation
     */
    startTime: Date;
    /**
     * Contains Name, Temperature and Mode
     * @type {string}
     * @memberof HygieneMonitoringInformation
     */
    programName?: string;
    /**
     * 
     * @type {HMState}
     * @memberof HygieneMonitoringInformation
     */
    state: HMState;
    /**
     * 
     * @type {HMResult}
     * @memberof HygieneMonitoringInformation
     */
    result?: HMResult;
    /**
     * 
     * @type {string}
     * @memberof HygieneMonitoringInformation
     */
    failedReason?: string | null;
}

/**
 * Check if a given object implements the HygieneMonitoringInformation interface.
 */
export function instanceOfHygieneMonitoringInformation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "laundryGroup" in value;
    isInstance = isInstance && "laundry" in value;
    isInstance = isInstance && "machine" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function HygieneMonitoringInformationFromJSON(json: any): HygieneMonitoringInformation {
    return HygieneMonitoringInformationFromJSONTyped(json, false);
}

export function HygieneMonitoringInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): HygieneMonitoringInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'organization': OrganizationReferenceFromJSON(json['organization']),
        'laundryGroup': LaundryGroupReferenceFromJSON(json['laundryGroup']),
        'laundry': LaundryReferenceFromJSON(json['laundry']),
        'machine': MachineReferenceFromJSON(json['machine']),
        'startTime': (new Date(json['startTime'])),
        'programName': !exists(json, 'programName') ? undefined : json['programName'],
        'state': HMStateFromJSON(json['state']),
        'result': !exists(json, 'result') ? undefined : HMResultFromJSON(json['result']),
        'failedReason': !exists(json, 'failedReason') ? undefined : json['failedReason'],
    };
}

export function HygieneMonitoringInformationToJSON(value?: HygieneMonitoringInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization': OrganizationReferenceToJSON(value.organization),
        'laundryGroup': LaundryGroupReferenceToJSON(value.laundryGroup),
        'laundry': LaundryReferenceToJSON(value.laundry),
        'machine': MachineReferenceToJSON(value.machine),
        'startTime': (value.startTime.toISOString()),
        'programName': value.programName,
        'state': HMStateToJSON(value.state),
        'result': HMResultToJSON(value.result),
        'failedReason': value.failedReason,
    };
}

