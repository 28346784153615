import React, { ReactElement } from 'react'
import { Box, CircularProgress, TableCell, TableRow } from '@mui/material'
import { useTableStyles } from 'src/ui-shared/table/Table.style'

interface Props {
  colspan: number
}

export const TableLoading: React.FC<Props> = ({ colspan }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  return (
    <TableRow>
      <TableCell className={tableClasses.noData} colSpan={colspan}>
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" size={30} />
        </Box>
      </TableCell>
    </TableRow>
  )
}
