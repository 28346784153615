import queryString from 'query-string'
import { DateUtils } from 'src/service/utils/DateUtils'

export const queryStringUtils = {
  stringifyUrl: (url: string, object: Record<string, any>): string => {
    const params: Record<string, any> = {}

    // convert dates to ISO String
    for (const key in object) {
      const value = object[key]
      params[key] = value instanceof Date ? value.toISOString() : value
    }

    const stringifiedQuery = queryString.stringifyUrl(
      { url, query: params },
      { encode: true, skipNull: true, skipEmptyString: true, sort: false },
    )
    return stringifiedQuery
  },

  parse: (query: string): queryString.ParsedQuery<string | number | boolean | undefined> => {
    const queryObject = queryString.parse(query, { parseNumbers: true, parseBooleans: true, decode: true })

    // converts ISO string dates to date objects
    for (const key in queryObject) {
      const value = queryObject[key]

      if (value) {
        if (typeof value === 'string') {
          const stringValue = value as string
          // format example 2023-06-10T00:00:00+00:00

          if (DateUtils.isValidISODateForm(stringValue)) {
            const isQueryDateValid = Date.parse(stringValue)
            let date: Date | undefined
            if (!isNaN(isQueryDateValid)) {
              date = new Date(stringValue)
            }

            // @ts-ignore
            queryObject[key] = date
          }
        }

        // if search value was "001" it was transformed to 1 which then was
        // still transformed to a string using .toString() but the prefix
        // "00" was never returned
        if (key == 'search') {
          const queryObjectRaw = queryString.parse(query, { parseNumbers: false, parseBooleans: false, decode: true })
          queryObject[key] = queryObjectRaw[key]
        }
      }
    }

    return queryObject
  },

  removeQueryParams: (url: string): string => {
    const pos = url.indexOf('?')
    const result = url.substring(0, pos)
    return result
  },
}
