import { ApexOptions } from 'apexcharts'
import { TimeseriesData } from 'src/service/backend/api/models'

export interface ChartTimelineResult {
  timeseriesData: TimeseriesData
  fromDate: Date
  toDate: Date
}

export const DEFAULT_TIMESERIES_DATA: TimeseriesData = {
  datasets: [],
  valueStates: [],
}

export type ApexLineCurveType = 'smooth' | 'straight' | 'stepline'
export type ApexLineType = 'line' | 'area' | 'bar'

export const DEFAULT_CHART_SERIES_OPTIONS: ApexOptions = {
  chart: {
    toolbar: {
      show: true,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
      },
      autoSelected: 'pan',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 2,
    curve: 'smooth',
    fill: {
      opacity: 1,
    },
  },
  fill: {
    type: 'solid',
    opacity: 1,
  },
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeUTC: false,
      datetimeFormatter: {
        hour: 'HH:mm',
        minute: 'HH:mm',
        second: 'HH:mm',
        month: 'dd MMM',
      },
    },
  },
  legend: {
    position: 'bottom',
  },
}

export type ApexTitleStyle = {
  color?: string
  fontSize?: string
  fontWeight?: string | number
  fontFamily?: string
  cssClass?: string
}

export const APEX_CHART_TITLE_STYLE: ApexTitleStyle = {
  color: undefined,
  fontSize: '13px',
  fontFamily: 'Blinker,Roboto,Helvetica,Arial,sans-serif',
  fontWeight: 400,
  cssClass: 'apexcharts-yaxis-title',
}

export type AxisFormater = (val: number | undefined | null, opts?: any) => string | string[]

export const NO_DECIMAL_FORMATTER: AxisFormater = function (val) {
  if (val === undefined || val === null) {
    return ''
  }
  // do not show decimals in Y axis
  return val.toFixed(0)
}
