import { Currency, RefundTransaction, WMUsage } from 'src/service/backend/api/models'
import { formatDateTimeForLocale } from 'src/service/utils/DateFormatUtils'
import { formatAmount } from 'src/service/utils/NumberFormatUtils'
import { SupportedLocale } from 'src/service/view-model/base/localization/SupportedLocale'
import { getUserName } from 'src/service/view-model/laundry-user/LaundryUserUtils'

export interface TransactionRefundViewModel {
  transactionId: string
  laundryUser: string
  date: string
  amount: string
  currency: Currency
  reason?: string
}

export const mapTransactionRefundViewModelToRefundTransaction = (
  viewModel: TransactionRefundViewModel,
  usage: WMUsage,
): RefundTransaction => {
  const refundTransaction: RefundTransaction = {
    amount: usage.amount,
    currency: usage.currency,
    reason: viewModel.reason || '',
  }

  return refundTransaction
}

export const mapUsageToTransactionRefundViewModel = (
  usage: WMUsage,
  regionLocale: SupportedLocale,
): TransactionRefundViewModel => {
  const viewModel: TransactionRefundViewModel = {
    transactionId: usage.transactionId,
    date: formatDateTimeForLocale(usage.startTime, regionLocale),
    laundryUser: getUserName(usage.laundryUser),
    amount: formatAmount(usage.amount),
    currency: usage.currency,
    reason: '',
  }

  return viewModel
}
