import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import { RestartAlt } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import {
  Configuration,
  ModuleLiquidDetergentSettings,
  Permission,
  ProgramInstalled,
  ProgramLiquidDetergentUpdate,
  ProgramType,
  ProgramsMachineApi,
  SettingField,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatTemperatureOptional } from 'src/service/utils/NumberFormatUtils'
import { getMachineTypeName } from 'src/service/view-model/machine/Machines'
import {
  getProgramSettingsHeadCells,
  getSettingsTableRowValues,
} from 'src/service/view-model/program/ProgramModulesUtils'
import { getProgramTemperatureLabel, getProgramTypeName } from 'src/service/view-model/program/ProgramViewModel'
import { ButtonWithTooltip } from 'src/ui-shared/base/button/Buttons'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { DeleteIcon } from 'src/ui-shared/icons/Icons'
import { HeadCells } from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'
import { TableLoading } from 'src/ui-shared/table/TableLoading'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'
import { MachineProgramLiquidDetergentEditModal } from 'src/ui/page/common/machine/program/MachineProgramLiquidDetergentEditModal'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'stepNumber',
    label: 'module.stepNumber',
    noSort: true,
  },
  {
    id: 'name',
    label: 'module.name',
    noSort: true,
  },
]

export const MachineProgramDetailsPage = (): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const location = useLocation()
  const navigate = useNavigate()
  const appId = useAppId()
  const user = useUser()

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack

  const { machineId, programId } = useRequiredParams(['machineId', 'programId'])

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programsMachineApi = new ProgramsMachineApi(httpConfiguration)

  // state
  const [programInstalled, setProgramInstalled] = useState<ProgramInstalled | null>(null)
  const [removeCustomProgramModal, setRemoveCustomProgramModal] = useState<boolean>(false)
  const [resetIndividualSettingsModal, setResetIndividualSettingsModal] = useState<boolean>(false)
  const [editIndividualSettingsModal, setEditIndividualSettingsModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  // derived
  const programMetadata = programInstalled?.program.metaData
  const programType = programMetadata?.programType
  const programSteps = programInstalled?.program.details?.steps
  const liquidDetergentSteps = programInstalled?.liquidDetergentSteps
  const moduleStepHeadCells = useMemo(() => getProgramSettingsHeadCells(programSteps), [programSteps])

  // load data
  useEffect(() => {
    setLoading(true)
    setErrorMessage(null)

    programsMachineApi
      .machinesMachineIdProgramsProgramIdGet(programId, machineId)
      .then((data) => {
        setProgramInstalled(data)
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLoading(false)
      })
  }, [programId])

  // handlers
  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/machines/${machineId}/view/${MachineTab.PROGRAMS}`)
  }

  const resetLiquidDetergentsToDefault = () => {
    setLoading(true)

    programsMachineApi
      .machinesMachineIdProgramsProgramIdLiquidDetergentDelete(machineId, programId)
      .then((data) => {
        setProgramInstalled(data)
        setLoading(false)
        showSnackbar(translate('programResetSuccess'), 'success')
        setResetIndividualSettingsModal(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
        setResetIndividualSettingsModal(false)
        setLoading(false)
      })
  }

  const handleDeleteCustomProgram = () => {
    if (programInstalled) {
      programsMachineApi
        .machinesMachineIdProgramsProgramIdDelete(programId, machineId)
        .then(() => {
          navigateBack()
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
          setRemoveCustomProgramModal(false)
        })
    }
  }

  const handleUpdateLiquidDetergentSettings = (updatedSteps: Array<ModuleLiquidDetergentSettings>) => {
    setLoading(true)

    const programLiquidDetergentUpdate: ProgramLiquidDetergentUpdate = {
      liquidDetergentSteps: updatedSteps,
    }

    return programsMachineApi
      .machinesMachineIdProgramsProgramIdLiquidDetergentPut(machineId, programId, programLiquidDetergentUpdate)
      .then((data) => {
        setProgramInstalled(data)
        setEditIndividualSettingsModal(false)
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
        setLoading(false)
        return err
      })
  }

  // JSX
  const displayModuleStepsActions = (
    <>
      {/* CUSTOM programs can only be deleted in SM or edited in ES */}
      {programMetadata?.programType === ProgramType.CUSTOM && (
        <>
          <Button
            id={'resetToDefault'}
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => setRemoveCustomProgramModal(true)}
            startIcon={<DeleteIcon />}
          >
            {translate('button.delete')}
          </Button>
        </>
      )}

      {/* STANDARD programs can be reset to default or edit liquid detergent steps */}
      {programMetadata?.programType === ProgramType.STANDARD && (
        <>
          <Button
            id={'resetToDefault'}
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => setResetIndividualSettingsModal(true)}
            startIcon={<RestartAlt />}
          >
            {translate('button.resetToDefault')}
          </Button>
          <ButtonWithTooltip
            id={'edit'}
            variant={'contained'}
            color="primary"
            size="large"
            onClick={() => setEditIndividualSettingsModal(true)}
            disabled={!liquidDetergentSteps || liquidDetergentSteps.length === 0}
            title={
              !liquidDetergentSteps || liquidDetergentSteps.length === 0 ? translate('table.noDataFound') : undefined
            }
          >
            {translate(programType === ProgramType.STANDARD ? 'editLiquidDetergentSteps' : 'button.edit')}
          </ButtonWithTooltip>
        </>
      )}
    </>
  )

  const displayHeaders = (
    <>
      {/* main head cells */}
      {headCells.map((item) => (
        <TableCell key={item.id}>{translate(item.label)}</TableCell>
      ))}

      {/* module step dynamic head cells */}
      {moduleStepHeadCells.map((item) => (
        <TableCell key={item.id}>{item.label}</TableCell>
      ))}
    </>
  )

  const displayModuleStepTableCells = (programSettings: SettingField[]): ReactElement => {
    const tableRowValues = getSettingsTableRowValues(moduleStepHeadCells, programSettings)

    return (
      <>
        {tableRowValues.map((tableValue, index) => (
          <TableCell key={index}>{tableValue}</TableCell>
        ))}
      </>
    )
  }

  const displayRows =
    programSteps !== undefined && programSteps.length > 0 ? (
      programSteps.map((item, index) => {
        return (
          <TableRow key={index} className={tableClasses.tableRow} style={{ cursor: 'default' }}>
            <TableCell>{item.stepNumber}</TableCell>
            <TableCell width={'50%'}>{item.moduleLabel}</TableCell>
            {displayModuleStepTableCells(item.settings)}
          </TableRow>
        )
      })
    ) : (
      <TableEmpty colspan={headCells.length + moduleStepHeadCells.length} />
    )

  const renderTitle = (): string => {
    let title = ''

    if (programMetadata) {
      const messageKey = programMetadata.programType.toLowerCase() + 'ProgramDetails'

      title = translate(
        messageKey,
        programMetadata.programIndex,
        getProgramTemperatureLabel(programMetadata.temperature, programMetadata.temperatureUnit),
        programMetadata.programName,
      )
    }

    return title
  }

  return (
    <ScreenLayout title={renderTitle()} onBack={navigateBack} actions={<></>}>
      <Paper elevation={0}>
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <Box pt={2}>
            {/* program metadata info */}
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                <DetailsTextField value={programMetadata?.programName} label={translate('programInfo.name')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                <DetailsTextField value={programMetadata?.programMode} label={translate('programInfo.mode')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                <DetailsTextField
                  value={getProgramTypeName(programMetadata?.programType, translate)}
                  label={translate('programInfo.type')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                <DetailsTextField
                  value={getMachineTypeName(programMetadata?.machineType, translate)}
                  label={translate('programInfo.machineType')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                <DetailsTextField
                  value={formatTemperatureOptional(programMetadata?.temperature)}
                  label={translate('temperatureWithUnit', programMetadata?.temperatureUnit ?? undefined)}
                />
              </Grid>
            </Grid>

            <Divider className={sharedClasses.Divider} />

            {/* module steps table */}
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography className={sharedClasses.SectionTitle}>
                  {programSteps !== undefined && programSteps.length > 0 ? translate('moduleSettings') : null}
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <Box display={'flex'} justifyContent={'flex-end'} flexWrap={'wrap'} gap={1}>
                  {hasPermission(user, Permission.CUSTOM_PROGRAM_WRITE) && displayModuleStepsActions}
                </Box>
              </Grid>

              {/* hide complete table if there are no steps returned from backend */}
              {programSteps !== undefined && programSteps.length > 0 ? (
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>{displayHeaders}</TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableLoading colspan={headCells.length + moduleStepHeadCells.length} />
                        ) : (
                          displayRows
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        )}
      </Paper>

      {removeCustomProgramModal && (
        <ConfirmationModalDialog
          titleKey="deleteCustomProgram"
          confirmationKey="button.delete"
          open={removeCustomProgramModal}
          onConfirm={handleDeleteCustomProgram}
          onCancel={() => setRemoveCustomProgramModal(false)}
        >
          {translate('deleteConfirm', programMetadata?.programName)}
        </ConfirmationModalDialog>
      )}

      {resetIndividualSettingsModal && (
        <ConfirmationModalDialog
          titleKey="resetProgramSettings"
          confirmationKey="button.resetToDefault"
          open={resetIndividualSettingsModal}
          onConfirm={resetLiquidDetergentsToDefault}
          onCancel={() => setResetIndividualSettingsModal(false)}
        >
          {translate('resetProgramConfirm', programMetadata?.programName)}
        </ConfirmationModalDialog>
      )}

      {editIndividualSettingsModal && liquidDetergentSteps && (
        <MachineProgramLiquidDetergentEditModal
          open={editIndividualSettingsModal}
          onConfirm={handleUpdateLiquidDetergentSettings}
          onCancel={() => setEditIndividualSettingsModal(false)}
          liquidDetergentSteps={liquidDetergentSteps}
        />
      )}
    </ScreenLayout>
  )
}
