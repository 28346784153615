import React, { ReactElement, memo, useEffect, useMemo, useState } from 'react'
import { UseFormReturn, useFieldArray } from 'react-hook-form'
import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItemButton,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  Add,
  ArrowDownwardOutlined,
  ArrowDropDownOutlined,
  ArrowUpwardOutlined,
  ContentCopyOutlined,
  DeleteOutlineOutlined,
  OpenInNew,
} from '@mui/icons-material'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Program,
  ProgramModuleSettings,
  ProgramModuleTemplate,
  ProgramModuleTemplateAvailableTemplatesInner,
  SettingField,
} from 'src/service/backend/api'
import {
  getProgramSettingsHeadCells,
  getSettingsTableRowValues,
} from 'src/service/view-model/program/ProgramModulesUtils'
import { groupModuleTemplatesByCategory } from 'src/service/view-model/program/ProgramUtils'
import { filterHiddenSettingFieldInputs } from 'src/service/view-model/setting-field/SettingVisibilityUtils'
import { MenuButton } from 'src/ui-shared/base/button/MenuButton'
import { useForceRender } from 'src/ui-shared/base/hooks/useForceRender'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { EditMode } from 'src/ui-shared/constants/Constants'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { HeadCells } from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'
import { ProgramModuleFields } from 'src/ui/page/es/program/details/ProgramModuleFields'

interface Props {
  programForm: UseFormReturn<Program, any>
  availableModulesAndBlocks: ProgramModuleTemplate
  loading: boolean
  mode: EditMode
}

const ModulesSectionBox = styled(Box)(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  height: '100%',
}))

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  paddingLeft: theme.spacing(0.25),
  paddingRight: theme.spacing(0.25),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}))

const StyledTableCell = styled(TableCell)(() => ({
  padding: '2px 8px',
}))

const StyledHeadCell = styled(StyledTableCell)(() => ({
  top: '-17px',
}))

export const StyledNestedMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
}))

export const StyledParentMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  opacity: '1 !important',
}))

const headCells: HeadCells[] = [
  {
    id: 'reorder',
    label: '',
    noSort: true,
  },
  {
    id: 'name',
    label: 'module.name',
    noSort: true,
  },
]

export const ProgramModulesTab = memo(function ProgramModulesTabInner({
  mode,
  programForm,
  loading,
  availableModulesAndBlocks,
}: Props): ReactElement {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()
  const { classes: tableClasses } = useTableStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const { fields, insert, remove, swap, update } = useFieldArray({
    control: programForm.control,
    name: 'details.steps',
  })

  const forceRender = useForceRender()

  // states
  const [selectedModuleIndex, setSelectedModuleIndex] = useState<number>(fields.length > 0 ? 0 : -1)
  const [moduleIndexForRemoval, setModuleIndexForRemoval] = useState<number | null>(null)

  // derived state
  const selectedModule = selectedModuleIndex !== -1 && fields[selectedModuleIndex]
  const formDisabled = mode === 'view'
  const program = programForm.getValues()
  const programSteps = program.details?.steps
  const programSettingsHeadCells = useMemo(() => getProgramSettingsHeadCells(programSteps), [programSteps])

  const availableModules = availableModulesAndBlocks.availableModules ?? []
  const availableModuleBlocks = availableModulesAndBlocks.availableTemplates ?? []

  const availableModuleBlocksGroupedByCategory: Record<
    string,
    ProgramModuleTemplateAvailableTemplatesInner[] | undefined
  > = groupModuleTemplatesByCategory(availableModuleBlocks)

  // select the first editable module after load
  useEffect(() => {
    let indexOfFirstEditableRow: number | null = null
    for (let index = 0; index < fields.length; index++) {
      const allFieldsReadOnly = areAllFieldsReadOnly(index)
      if (!allFieldsReadOnly) {
        indexOfFirstEditableRow = index
        break
      }
    }

    if (indexOfFirstEditableRow) {
      handleSelectRow(indexOfFirstEditableRow)
    }
  }, [])

  // handle events
  const changePriorityAndSortSteps = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
    increment: boolean,
  ) => {
    event.stopPropagation()

    // if increment and the element is not first
    if (increment && index > 0 && index < fields.length) {
      swap(index, index - 1)
      setSelectedModuleIndex(index - 1)
    }

    // if decrement and the element is not last
    if (!increment && index >= 0 && index < fields.length - 1) {
      swap(index, index + 1)
      setSelectedModuleIndex(index + 1)
    }
  }

  const handleCopyStep = (index: number) => {
    const selectedStep: ProgramModuleSettings = { ...fields[index] }
    insert(index, selectedStep)
  }

  const handleAddModuleBlock = (moduleBlock: ProgramModuleTemplateAvailableTemplatesInner) => {
    const nextModuleIndex = selectedModuleIndex + 1

    const steps = moduleBlock.steps ?? []

    if (steps.length > 0) {
      steps.forEach((item) => {
        maybeUpdateToWashModuleTemperature(item, program)
      })

      insert(nextModuleIndex, steps)
      setSelectedModuleIndex(nextModuleIndex)
    }
  }

  const handleAddModule = (step: ProgramModuleSettings) => {
    const indexForPlacementOfModule = selectedModuleIndex + 1

    // check if the module is washing module and prefill temperature from the program settings
    // this is workaround to avoid modeling links between field values in the API and frontend
    // it is needed just for this case currently
    maybeUpdateToWashModuleTemperature(step, program)

    insert(indexForPlacementOfModule, step)
    setSelectedModuleIndex(indexForPlacementOfModule)
  }

  const maybeUpdateToWashModuleTemperature = (step: ProgramModuleSettings, program: Program) => {
    // update the initial temperature of the "To wash" module from the main program temperature
    if (step.moduleId === 'washingModule') {
      const washTemperatureField = step.settings.find((s) => s.settingId === 'washTemperature')
      if (washTemperatureField && washTemperatureField.numberField) {
        const programMainTemperature = program.metaData.temperature
        if (programMainTemperature) {
          washTemperatureField.numberField.data = programMainTemperature
        }
      }
    }
  }

  const handleDeleteStep = () => {
    remove(selectedModuleIndex)
    setModuleIndexForRemoval(null)
  }

  const handleOpenDeleteStepModal = (index: number) => {
    setModuleIndexForRemoval(index)
  }

  const handleSelectRow = (moduleIndex: number) => {
    setSelectedModuleIndex(moduleIndex)
  }

  const displayProgramSettingsTableCells = (programSettings: SettingField[], color?: string): ReactElement => {
    const tableRowValues = getSettingsTableRowValues(programSettingsHeadCells, programSettings)

    return (
      <>
        {tableRowValues.map((tableValue, index) => (
          <StyledTableCell sx={{ color: color }} key={index}>
            {tableValue}
          </StyledTableCell>
        ))}
      </>
    )
  }

  // render
  const renderModuleTitle = (moduleIndex: number, programModuleSettings?: ProgramModuleSettings): string => {
    let title = ''

    if (programModuleSettings) {
      const position = moduleIndex + 1
      const label = programModuleSettings.moduleLabel || ''
      title = `${position}. ${label}`
    }

    return title
  }

  const getModuleTableHeaders = (): ReactElement[] => {
    const headCellsJsx: ReactElement[] = []

    // position
    headCellsJsx.push(<StyledHeadCell key="#">#</StyledHeadCell>)

    // main head cells jsx
    headCells
      .map((item) => <StyledHeadCell key={item.id}>{translate(item.label)}</StyledHeadCell>)
      .forEach((item) => headCellsJsx.push(item))

    // program settings head cells
    programSettingsHeadCells
      .map((item) => <StyledHeadCell key={item.id}>{item.label}</StyledHeadCell>)
      .forEach((jsxElement) => headCellsJsx.push(jsxElement))

    // action head cell
    headCellsJsx.push(<StyledHeadCell key={'action'}></StyledHeadCell>)

    return headCellsJsx
  }

  const headCellsLength = getModuleTableHeaders().length

  const hasModuleErrors = (moduleIndex: number): boolean => {
    const visibleFields = filterHiddenSettingFieldInputs(fields[moduleIndex].settings, false)
    const visibleFieldIndexes = visibleFields.map((item) => item.fieldIndex)
    return visibleFieldIndexes.some(
      (fieldIndex) => programForm.getFieldState(`details.steps.${moduleIndex}.settings.${fieldIndex}`).error,
    )
  }

  const areAllFieldsReadOnly = (moduleIndex: number): boolean => {
    const visibleFields = filterHiddenSettingFieldInputs(fields[moduleIndex].settings, false)
    const result = visibleFields.every((item) => item.readOnly)
    return result
  }

  const getModuleTableTextColor = (hasErrors: boolean, allFieldsReadOnly: boolean): string | undefined => {
    if (hasErrors) {
      return 'error.main'
    } else if (allFieldsReadOnly && program.details?.fixed && mode !== 'view') {
      return 'text.disabled'
    }
  }

  const nonEmptyModuleRows = fields.map((item, index) => {
    const hasErrors = hasModuleErrors(index)
    const allFieldsReadOnly = areAllFieldsReadOnly(index)

    const color = getModuleTableTextColor(hasErrors, allFieldsReadOnly)
    return (
      <TableRow
        key={index}
        className={tableClasses.tableRow}
        selected={index === selectedModuleIndex}
        onClick={() => handleSelectRow(index)}
      >
        <StyledTableCell sx={{ color: color }}>{index + 1}</StyledTableCell>
        <StyledTableCell style={{ paddingTop: '0px', paddingBottom: '0px' }}>
          <IconButtonStyled
            disabled={index === 0 || formDisabled || loading || program.details?.fixed}
            onClick={(event) => changePriorityAndSortSteps(event, index, true)}
          >
            <ArrowUpwardOutlined fontSize="small" />
          </IconButtonStyled>
          <IconButtonStyled
            disabled={index === fields.length - 1 || formDisabled || loading || program.details?.fixed}
            onClick={(event) => changePriorityAndSortSteps(event, index, false)}
          >
            <ArrowDownwardOutlined fontSize="small" />
          </IconButtonStyled>
        </StyledTableCell>
        <StyledTableCell sx={{ color: color }}>{item.moduleLabel}</StyledTableCell>
        {displayProgramSettingsTableCells(item.settings, color)}
        <StyledTableCell style={{ paddingTop: '0px', paddingBottom: '0px', textAlign: 'right' }}>
          <IconButtonStyled
            disabled={formDisabled || loading || program.details?.fixed}
            onClick={() => handleCopyStep(index)}
          >
            <ContentCopyOutlined fontSize="small" />
          </IconButtonStyled>
          <IconButtonStyled
            disabled={formDisabled || loading || program.details?.fixed}
            onClick={() => handleOpenDeleteStepModal(index)}
          >
            <DeleteOutlineOutlined fontSize="small" />
          </IconButtonStyled>
        </StyledTableCell>
      </TableRow>
    )
  })

  const displayModuleTable = (
    <TableContainer sx={{ pt: 2, maxHeight: '100vh' }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>{getModuleTableHeaders()}</TableRow>
        </TableHead>
        <TableBody>
          {nonEmptyModuleRows.length > 0 ? nonEmptyModuleRows : <TableEmpty colspan={headCellsLength} />}
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <Box pt={2}>
      <Stack
        direction={smallScreen ? 'column' : 'row'}
        divider={
          <Divider
            orientation={smallScreen ? 'horizontal' : 'vertical'}
            sx={{ height: smallScreen ? '100%' : '100vh' }}
            flexItem
          />
        }
        spacing={2}
      >
        {/* Left section - module list with title and actions */}
        <ModulesSectionBox>
          <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" align="center">
                {translate('moduleList')}
              </Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <MenuButton
                  disabled={availableModuleBlocks.length === 0 || formDisabled || loading || program.details?.fixed}
                  variant="text"
                  startIcon={<Add />}
                  endIcon={<ArrowDropDownOutlined />}
                  menuTitle={translate('addTemplate')}
                  disableTooltipTitle={formDisabled ? translate('switchToEditMode') : translate('noTemplate')}
                >
                  {Object.keys(availableModuleBlocksGroupedByCategory).map((categoryLabel, index) => {
                    const items = availableModuleBlocksGroupedByCategory[categoryLabel]
                    const isLast = index >= Object.keys(availableModuleBlocksGroupedByCategory).length - 1
                    return (
                      <>
                        {categoryLabel !== '' ? (
                          <StyledParentMenuItem onClick={() => {}} key={categoryLabel} disabled={true}>
                            {categoryLabel}
                          </StyledParentMenuItem>
                        ) : null}

                        {items?.map((item) => (
                          <StyledNestedMenuItem onClick={() => handleAddModuleBlock(item)} key={item.label}>
                            {item.label}
                          </StyledNestedMenuItem>
                        ))}

                        {isLast ? <Box pt={1} /> : <Divider />}
                      </>
                    )
                  })}
                </MenuButton>

                <MenuButton
                  disabled={availableModules.length === 0 || formDisabled || loading || program.details?.fixed}
                  variant="text"
                  startIcon={<Add />}
                  endIcon={<ArrowDropDownOutlined />}
                  menuTitle={translate('addModule')}
                  disableTooltipTitle={formDisabled ? translate('switchToEditMode') : translate('noModules')}
                >
                  {availableModules.map((item) => (
                    <MenuItem onClick={() => handleAddModule(item)} key={item.moduleId}>
                      {item.moduleLabel}
                    </MenuItem>
                  ))}
                </MenuButton>
              </Stack>
            </Stack>
          </Box>
          {displayModuleTable}
        </ModulesSectionBox>

        {/* Right section - selected module details with help instructions */}
        <ModulesSectionBox>
          {selectedModule && (
            <>
              <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h4" align="center">
                    {selectedModule.moduleLabel}
                  </Typography>
                  <MenuButton
                    variant="text"
                    startIcon={<OpenInNew />}
                    endIcon={<ArrowDropDownOutlined />}
                    disabled={!selectedModule.documentation || selectedModule.documentation.length === 0}
                    disableTooltipTitle={translate('noHelpInstruction')}
                    menuTitle={translate('helpInstructions')}
                  >
                    {selectedModule.documentation?.map((item, index) => (
                      <ListItemButton component="a" href={item.url} target={'_blank'} key={index}>
                        {item.name}
                      </ListItemButton>
                    ))}
                  </MenuButton>
                </Stack>
              </Box>
              <Box>
                <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                  <ProgramModuleFields
                    programForm={programForm}
                    selectedModule={selectedModule}
                    selectedModuleIndex={selectedModuleIndex}
                    updateModule={update}
                    forceRenderParent={forceRender}
                    disabled={formDisabled || loading}
                  />
                </Grid>
              </Box>
            </>
          )}
        </ModulesSectionBox>
      </Stack>

      {moduleIndexForRemoval !== null && (
        <ConfirmationModalDialog
          titleKey="deleteModule"
          confirmationKey="button.delete"
          open={true}
          onConfirm={handleDeleteStep}
          onCancel={() => setModuleIndexForRemoval(null)}
        >
          {translate('deleteConfirm', renderModuleTitle(moduleIndexForRemoval, fields[moduleIndexForRemoval]))}
        </ConfirmationModalDialog>
      )}
    </Box>
  )
})
