import { CodeAndName } from 'src/service/view-model/base/CodeAndName'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'

export interface CurrencyObject extends CodeAndName {
  group: string
}

const group1 = ''

export const CURRENCIES: CurrencyObject[] = [
  { code: 'CHF', name: 'Swiss Franc (CHF)', group: group1 },
  { code: 'EUR', name: 'Euro (EUR)', group: group1 },
  { code: 'GBP', name: 'British Pound Sterling (GBP)', group: group1 },
  { code: 'USD', name: 'United States Dollar (USD)', group: group1 },
]

export const getCurrencyObject = (currencyCode: string | undefined): CurrencyObject | undefined => {
  return findReferenceObject(CURRENCIES, currencyCode, 'code')
}

export const getCurrencyName = (currencyCode: string | undefined): string => {
  const currencyObject = getCurrencyObject(currencyCode)
  return currencyObject ? currencyObject.name : ''
}
