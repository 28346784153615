/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RegionData } from './RegionData';
import {
    RegionDataFromJSON,
    RegionDataFromJSONTyped,
    RegionDataToJSON,
} from './RegionData';
import type { Role } from './Role';
import {
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface BackendUserProfile
 */
export interface BackendUserProfile {
    /**
     * 
     * @type {string}
     * @memberof BackendUserProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BackendUserProfile
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof BackendUserProfile
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof BackendUserProfile
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BackendUserProfile
     */
    locale: string;
    /**
     * globally blocked
     * @type {boolean}
     * @memberof BackendUserProfile
     */
    readonly blocked: boolean;
    /**
     * 
     * @type {Array<Role>}
     * @memberof BackendUserProfile
     */
    globalRoles: Array<Role>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof BackendUserProfile
     */
    memberRolesAll: Array<Role>;
    /**
     * 
     * @type {RegionData}
     * @memberof BackendUserProfile
     */
    regionData?: RegionData;
    /**
     * 
     * @type {boolean}
     * @memberof BackendUserProfile
     */
    allowsAdvertisements?: boolean;
}

/**
 * Check if a given object implements the BackendUserProfile interface.
 */
export function instanceOfBackendUserProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "locale" in value;
    isInstance = isInstance && "blocked" in value;
    isInstance = isInstance && "globalRoles" in value;
    isInstance = isInstance && "memberRolesAll" in value;

    return isInstance;
}

export function BackendUserProfileFromJSON(json: any): BackendUserProfile {
    return BackendUserProfileFromJSONTyped(json, false);
}

export function BackendUserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackendUserProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'locale': json['locale'],
        'blocked': json['blocked'],
        'globalRoles': ((json['globalRoles'] as Array<any>).map(RoleFromJSON)),
        'memberRolesAll': ((json['memberRolesAll'] as Array<any>).map(RoleFromJSON)),
        'regionData': !exists(json, 'regionData') ? undefined : RegionDataFromJSON(json['regionData']),
        'allowsAdvertisements': !exists(json, 'allowsAdvertisements') ? undefined : json['allowsAdvertisements'],
    };
}

export function BackendUserProfileToJSON(value?: BackendUserProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'locale': value.locale,
        'globalRoles': ((value.globalRoles as Array<any>).map(RoleToJSON)),
        'memberRolesAll': ((value.memberRolesAll as Array<any>).map(RoleToJSON)),
        'regionData': RegionDataToJSON(value.regionData),
        'allowsAdvertisements': value.allowsAdvertisements,
    };
}

