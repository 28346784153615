import React, { FC, ReactElement, useContext, useState } from 'react'
import { Box, Button, Chip, Grid, useTheme } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import FormatListNumberedRtlOutlined from '@mui/icons-material/FormatListNumberedRtlOutlined'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, Machine, MachineAction, MachinesApi, Program } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ProgramAddModal } from 'src/ui-shared/program/ProgramAddModal'

interface Props {
  machine: Machine
}

export const MachineLabActionsTab: FC<Props> = ({ machine }): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  const showSnackbar = useShowSnackbar()

  const theme = useTheme()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const machinesApi = new MachinesApi(httpConfiguration)

  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null)
  const [programSelectModal, setProgramSelectModal] = useState<boolean>(false)

  const hasStartEnduranceTestAction = machine.actions?.includes(MachineAction.LAB_MACHINE_START_ENDURANCE_TEST)
  const hasSelectAndStartProgramAction = machine.actions?.includes(MachineAction.LAB_MACHINE_SELECT_AND_START_PROGRAM)
  const hasCancelAction = machine.actions?.includes(MachineAction.LAB_MACHINE_CANCEL)

  const onActionStartHandler = () => {
    if (!hasStartEnduranceTestAction) {
      return
    }

    machinesApi
      .machinesMachineIdLabStartEnduranceTestPost(machine.id)
      .then(() => {
        showSnackbar(translate('machineEnduranceTestStartSuccessMessage'), 'success')
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  const handleOpenProgramSelectModal = () => {
    setProgramSelectModal(true)
  }

  const handleCloseProgramSelectModal = () => {
    setProgramSelectModal(false)
  }

  const handleSelectProgram = (programs: Program[]) => {
    setSelectedProgram(programs[0])
  }

  const handleUnselectProgram = () => {
    setSelectedProgram(null)
  }

  const onActionSelectAndStartHandler = () => {
    if (!hasSelectAndStartProgramAction || !selectedProgram) {
      return
    }

    machinesApi
      .machinesMachineIdLabSelectAndStartProgramPost(machine.id, {
        programIndex: selectedProgram.metaData.programIndex,
      })
      .then(() => {
        showSnackbar(translate('machineEnduranceTestProgramStartedSuccessMessage'), 'success')
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  const onActionCancelHandler = () => {
    if (!hasCancelAction) {
      return
    }

    machinesApi
      .machinesMachineIdLabCancelProgramPost(machine.id)
      .then(() => {
        showSnackbar(translate('machineEnduranceTestCancelSuccessMessage'), 'success')
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  const formatProgram = (program: Program): string => {
    const { programMode, temperature, temperatureUnit, programIndex, programName } = program.metaData
    return `[${programIndex}] (${temperature}${temperatureUnit}) ${programName} / ${programMode}`
  }

  return (
    <>
      {hasStartEnduranceTestAction && (
        <Box mt={3} mb={3}>
          <Grid item {...ITEM_BREAKPOINTS}>
            <Button
              startIcon={<PlayArrowIcon />}
              variant="contained"
              color="primary"
              size="large"
              className={sharedClasses.ButtonMargin}
              sx={{ mb: 1 }}
              onClick={onActionStartHandler}
            >
              {translate('startEnduranceTestButtonLabel')}
            </Button>
          </Grid>
        </Box>
      )}
      {hasSelectAndStartProgramAction && (
        <Box mt={3} mb={3}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item>
              <Button
                startIcon={<FormatListNumberedRtlOutlined />}
                variant={selectedProgram ? 'outlined' : 'contained'}
                color="primary"
                size="large"
                className={sharedClasses.ButtonMargin}
                sx={{ mb: 1 }}
                onClick={handleOpenProgramSelectModal}
              >
                {translate('enduranceTestSelectProgramButtonLabel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<PlaylistPlayIcon />}
                variant="contained"
                color="primary"
                size="large"
                className={sharedClasses.ButtonMargin}
                sx={{ mb: 1 }}
                disabled={!selectedProgram}
                onClick={onActionSelectAndStartHandler}
              >
                {translate('enduranceTestSelectAndStartButtonLabel')}
              </Button>
            </Grid>
            {selectedProgram && (
              <Grid item>
                <Chip
                  sx={{
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.getContrastText(theme.palette.primary.light),
                  }}
                  label={formatProgram(selectedProgram)}
                  color="secondary"
                  onDelete={handleUnselectProgram}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      {hasCancelAction && (
        <Box mt={3} mb={3}>
          <Grid item {...ITEM_BREAKPOINTS}>
            <Button
              startIcon={<CancelIcon />}
              variant="contained"
              color="primary"
              size="large"
              className={sharedClasses.ButtonMargin}
              sx={{ mb: 1 }}
              onClick={onActionCancelHandler}
            >
              {translate('enduranceTestCancelButtonLabel')}
            </Button>
          </Grid>
        </Box>
      )}

      {programSelectModal && (
        <ProgramAddModal
          open={true}
          titleKey="enduranceSelectProgramModalTitle"
          confirmationKey="button.select"
          onCancel={handleCloseProgramSelectModal}
          addSelectedRows={handleSelectProgram}
          machineId={machine.id}
          machineType={machine.type}
          multipleSelect={false}
        />
      )}
    </>
  )
}
