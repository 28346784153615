/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachinePairingInfo } from './MachinePairingInfo';
import {
    MachinePairingInfoFromJSON,
    MachinePairingInfoFromJSONTyped,
    MachinePairingInfoToJSON,
} from './MachinePairingInfo';
import type { ManualPairingInfo } from './ManualPairingInfo';
import {
    ManualPairingInfoFromJSON,
    ManualPairingInfoFromJSONTyped,
    ManualPairingInfoToJSON,
} from './ManualPairingInfo';
import type { PairingType } from './PairingType';
import {
    PairingTypeFromJSON,
    PairingTypeFromJSONTyped,
    PairingTypeToJSON,
} from './PairingType';

/**
 * 
 * @export
 * @interface PairingInfo
 */
export interface PairingInfo {
    /**
     * 
     * @type {PairingType}
     * @memberof PairingInfo
     */
    type: PairingType;
    /**
     * 
     * @type {MachinePairingInfo}
     * @memberof PairingInfo
     */
    prefilledInfo?: MachinePairingInfo;
    /**
     * 
     * @type {ManualPairingInfo}
     * @memberof PairingInfo
     */
    manualInfo?: ManualPairingInfo;
}

/**
 * Check if a given object implements the PairingInfo interface.
 */
export function instanceOfPairingInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function PairingInfoFromJSON(json: any): PairingInfo {
    return PairingInfoFromJSONTyped(json, false);
}

export function PairingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PairingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': PairingTypeFromJSON(json['type']),
        'prefilledInfo': !exists(json, 'prefilledInfo') ? undefined : MachinePairingInfoFromJSON(json['prefilledInfo']),
        'manualInfo': !exists(json, 'manualInfo') ? undefined : ManualPairingInfoFromJSON(json['manualInfo']),
    };
}

export function PairingInfoToJSON(value?: PairingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': PairingTypeToJSON(value.type),
        'prefilledInfo': MachinePairingInfoToJSON(value.prefilledInfo),
        'manualInfo': ManualPairingInfoToJSON(value.manualInfo),
    };
}

