import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { Button, Divider, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  AppApi,
  Configuration,
  UserReactionCategoryReference,
  UserReactionRequest,
  UserReactionType,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { AutoCompleteField } from 'src/ui-shared/base/form/control/AutoCompleteField'
import { Option } from 'src/ui-shared/base/form/control/Index'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { composeValidators, maxChar, required } from 'src/ui-shared/base/form/validation/Validators'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { TEXT_LENGTH } from 'src/ui-shared/constants/Constants'

interface FeedbackFormViewModel {
  reactionType: UserReactionType
  category: string | undefined
  message: string
}

export const FeedbackForm = (): ReactElement => {
  const navigate = useNavigate()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const translate = useTranslate()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const appApi = new AppApi(httpConfiguration)

  const initialValues: FeedbackFormViewModel = {
    reactionType: UserReactionType.FEEDBACK,
    category: undefined,
    message: '',
  }

  // url query params
  const [searchParams] = useSearchParams()

  // url params
  useEffect(() => {
    const feedbackParam = searchParams.get('feedback')
    const toShow = feedbackParam === 'true'

    if (toShow) {
      setShow(toShow)
    }
  }, [searchParams])

  //state
  const [show, setShow] = useState<boolean>(false)
  const [categories, setCategories] = useState<UserReactionCategoryReference[]>([])

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // derived state
  const categoryOptions = categories.map((categoryReference) => {
    const option: Option = {
      label: categoryReference.name,
      value: categoryReference.key,
    }
    return option
  })

  // load categories
  const loadCategories = () => {
    setLoading(true)
    setErrorMessage(null)

    appApi
      .appReactionsCategoriesUserReactionTypeGet(initialValues.reactionType)
      .then((data) => {
        setCategories(data)
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (show) {
      loadCategories()
    }
  }, [show])

  // handle events
  const close = () => {
    setShow(false)
    navigate(`/${appId}`)
  }

  const submitForm = async (values: FeedbackFormViewModel) => {
    setLoading(true)

    const category: UserReactionCategoryReference | undefined = findReferenceObject(categories, values.category, 'key')
    if (!category) {
      onSubmitReject('client-error-precondition-required')
      return
    }

    const reactionType: UserReactionType = values.reactionType
    const userReactionRequest: UserReactionRequest = {
      category: category,
      reactionType: reactionType,
      message: values.message,
    }

    return appApi
      .appReactionsPost(userReactionRequest)
      .then((_data) => {
        onSubmitSuccess()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  // event handling
  const onSubmitSuccess = () => {
    showSnackbar(translate('sendFeedbackSuccess'), 'success')
    setLoading(false)
    close()
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }
  return (
    <ModalDialog open={show} onClose={close} title={translate('sendFeedbackReportProblem')} maxWidth="md">
      <Form<FeedbackFormViewModel>
        initialValues={initialValues}
        onSubmit={submitForm}
        render={({ handleSubmit, submitting, pristine, invalid }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Box px={3} mb={2}>
                <LoadingIndicator loading={loading} />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p>{translate('sendFeedbackInfo')}</p>
                  </Grid>

                  <Grid item xs={12}>
                    {errorMessage ? (
                      <ErrorMessage message={errorMessage} />
                    ) : (
                      <AutoCompleteField
                        label={translate('category')}
                        name="category"
                        fullWidth
                        disabled={submitting}
                        options={categoryOptions}
                        validate={required()}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={translate('message')}
                      type="text"
                      name="message"
                      multiline
                      rows={7}
                      fullWidth
                      disabled={submitting}
                      validate={composeValidators(required(), maxChar(TEXT_LENGTH.L))}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider />

              <Box pt={1} pb={1} display="flex" justifyContent="flex-end">
                <Button variant="text" color="primary" size="large" onClick={close} sx={{ m: 1 }}>
                  {translate('button.cancel')}
                </Button>

                <Button
                  id="submitButton"
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={submitting || pristine || invalid}
                  sx={{ m: 1 }}
                >
                  {translate('sendFeedback')}
                </Button>
              </Box>
            </form>
          )
        }}
      />
    </ModalDialog>
  )
}
