import React, { PropsWithChildren, memo, useContext, useEffect, useState } from 'react'
import { ApplicationConfig, EnvironmentService } from 'src/env/EnvironmentService'

const defaultApplicationConfig: ApplicationConfig = {
  backendUrl: '',
  keycloakConfigFileUrl: '',
  fakeLogin: false,
  homeIframeUrl: '',
  environmentId: '',
}

export const useIsProdEnvironment = (): boolean => {
  const { environmentId } = useContext(EnvironmentContext)
  // hardcoded prod environment id, no need for enums yet
  return environmentId === 'prod'
}

export const useIsIntEnvironment = (): boolean => {
  const { environmentId } = useContext(EnvironmentContext)
  return environmentId === 'int'
}

const EnvironmentContext = React.createContext<ApplicationConfig>(defaultApplicationConfig)

const EnvironmentContextProvider = memo(function EnvironmentContextProvider({ children }: PropsWithChildren) {
  const [applicationConfig, setApplicationConfig] = useState<ApplicationConfig>(defaultApplicationConfig)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchAndSetAppConfig = async (): Promise<void> => {
      try {
        const appConfig = await EnvironmentService.loadEnvironment()
        setApplicationConfig(appConfig)
      } finally {
        setIsLoading(false)
      }
    }

    fetchAndSetAppConfig()
  }, [])

  return <EnvironmentContext.Provider value={applicationConfig}>{!isLoading && children}</EnvironmentContext.Provider>
})
export { EnvironmentContext, EnvironmentContextProvider }
