/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ModuleType {
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    WASHING_MECHANICS = 'WASHING_MECHANICS',
    DETERGENTS = 'DETERGENTS',
    WASHING = 'WASHING',
    COOL_DOWN = 'COOL_DOWN',
    OUTLET = 'OUTLET',
    RINSING = 'RINSING',
    SPINNING = 'SPINNING',
    TURBIDITY = 'TURBIDITY',
    MECHANICS = 'MECHANICS',
    HEATING_TEST = 'HEATING_TEST',
    DRYING = 'DRYING',
    TIMED_DRYING = 'TIMED_DRYING',
    TIME = 'TIME',
    GOTO = 'GOTO',
    CRINKLE_CARE = 'CRINKLE_CARE',
    TEST = 'TEST',
    END = 'END',
    SUBPROGRAM = 'SUBPROGRAM',
    PARAMETER = 'PARAMETER'
}


export function ModuleTypeFromJSON(json: any): ModuleType {
    return ModuleTypeFromJSONTyped(json, false);
}

export function ModuleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModuleType {
    return json as ModuleType;
}

export function ModuleTypeToJSON(value?: ModuleType | null): any {
    return value as any;
}

