import { Locale } from 'date-fns'
import { de, enGB, fr, it } from 'date-fns/locale'
import { CodeAndName } from 'src/service/view-model/base/CodeAndName'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'
import { SupportedLocale } from 'src/service/view-model/base/localization/SupportedLocale'

export interface LocaleObject extends CodeAndName {
  group: string
}

interface LocaleDatePickerMap {
  [key: string]: Locale
}

const group1 = ''
const other = 'Other'

export const LOCALES: LocaleObject[] = [
  { name: 'German', code: 'de', group: group1 },
  { name: 'French', code: 'fr', group: group1 },
  { name: 'Italian', code: 'it', group: group1 },
  { name: 'English', code: 'en', group: group1 },

  { name: 'Albanian', code: 'sq', group: other },
  { name: 'Arabic', code: 'ar', group: other },
  { name: 'Belarusian', code: 'be', group: other },
  { name: 'Bulgarian', code: 'bg', group: other },
  { name: 'Catalan', code: 'ca', group: other },
  { name: 'Chinese', code: 'zh', group: other },
  { name: 'Croatian', code: 'hr', group: other },
  { name: 'Czech', code: 'cs', group: other },
  { name: 'Danish', code: 'da', group: other },
  { name: 'Dutch', code: 'nl', group: other },
  { name: 'Estonian', code: 'et', group: other },
  { name: 'Finnish', code: 'fi', group: other },

  { name: 'Greek', code: 'el', group: other },
  { name: 'Hebrew', code: 'iw', group: other },
  { name: 'Hungarian', code: 'hu', group: other },
  { name: 'Icelandic', code: 'is', group: other },
  { name: 'Indonesian', code: 'in', group: other },
  { name: 'Irish', code: 'ga', group: other },

  { name: 'Japanese', code: 'ja', group: other },
  { name: 'Korean', code: 'ko', group: other },
  { name: 'Latvian', code: 'lv', group: other },
  { name: 'Lithuanian', code: 'lt', group: other },
  { name: 'Macedonian', code: 'mk', group: other },
  { name: 'Malay', code: 'ms', group: other },
  { name: 'Maltese', code: 'mt', group: other },
  { name: 'Norwegian', code: 'no', group: other },
  { name: 'Polish', code: 'pl', group: other },
  { name: 'Portuguese', code: 'pt', group: other },
  { name: 'Romanian', code: 'ro', group: other },
  { name: 'Russian', code: 'ru', group: other },
  { name: 'Serbian', code: 'sr', group: other },
  { name: 'Slovak', code: 'sk', group: other },
  { name: 'Slovenian', code: 'sl', group: other },
  { name: 'Spanish', code: 'es', group: other },
  { name: 'Swedish', code: 'sv', group: other },
  { name: 'Thai', code: 'th', group: other },
  { name: 'Turkish', code: 'tr', group: other },
  { name: 'Ukrainian', code: 'uk', group: other },
  { name: 'Vietnamese', code: 'vi', group: other },
]

const LOCALE_DATEPICKER_MAP: LocaleDatePickerMap = {
  en: enGB,
  de: de,
  fr: fr,
  it: it,
}

export const getLocaleObject = (localeCode: string | undefined): LocaleObject | undefined => {
  return findReferenceObject(LOCALES, localeCode, 'code')
}

export const getLocaleName = (localeCode: string | undefined): string => {
  if (localeCode?.length !== 2) {
    localeCode = localeCode?.substring(0, 2)
  }
  const localeObject = getLocaleObject(localeCode)
  return localeObject ? localeObject.name : ''
}

export const getMainLocales = (): LocaleObject[] => {
  const mainLocales = LOCALES.filter((locale) => locale.group === group1)
  return mainLocales
}

const updateLocaleFromDateFnsForRegionLocale = (givenLocale: Locale, regionLocale: SupportedLocale): Locale => {
  const locale: Locale = {
    ...givenLocale,
  }

  if (regionLocale === 'en-US') {
    locale.options = {
      weekStartsOn: 0,
    }
  }

  return locale
}

export const getLocaleForDatePicker = (languageLocaleString: string, regionLocale: SupportedLocale): Locale => {
  let locale = LOCALE_DATEPICKER_MAP[languageLocaleString]
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!locale) {
    console.warn("The selected locale doesn't exists, selected default 'en'")
    locale = enGB
  }

  const updatedLocale = updateLocaleFromDateFnsForRegionLocale(locale, regionLocale)
  return updatedLocale
}
