import { CodeAndName } from 'src/service/view-model/base/CodeAndName'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'
import { COUNTRIES_DE } from 'src/service/view-model/base/country/CountriesDe'
import { COUNTRIES_FR } from 'src/service/view-model/base/country/CountriesFr'
import { COUNTRIES_IT } from 'src/service/view-model/base/country/CountriesIt'

export interface CountryObject extends CodeAndName {
  group: string
}

const group1 = ''
const group2 = 'Other'

export const COUNTRIES: CountryObject[] = [
  { name: 'Switzerland', code: 'CH', group: group1 },
  { name: 'Germany', code: 'DE', group: group1 },
  { name: 'Austria', code: 'AT', group: group1 },
  { name: 'United Kingdom', code: 'GB', group: group1 },
  { name: 'Albania', code: 'AL', group: group2 },
  { name: 'American Samoa', code: 'AS', group: group2 },
  { name: 'Armenia', code: 'AM', group: group2 },
  { name: 'Australia', code: 'AU', group: group2 },
  { name: 'Azerbaijan', code: 'AZ', group: group2 },
  { name: 'Belarus', code: 'BY', group: group2 },
  { name: 'Belgium', code: 'BE', group: group2 },
  { name: 'Bosnia and Herzegovina', code: 'BA', group: group2 },
  { name: 'British Indian Ocean Territory', code: 'IO', group: group2 },
  { name: 'Bulgaria', code: 'BG', group: group2 },
  { name: 'Canada', code: 'CA', group: group2 },
  { name: 'China', code: 'CN', group: group2 },
  { name: 'Christmas Island', code: 'CX', group: group2 },
  { name: 'Cocos (Keeling) Islands', code: 'CC', group: group2 },
  { name: 'Croatia', code: 'HR', group: group2 },
  { name: 'Cyprus', code: 'CY', group: group2 },
  { name: 'Czech Republic', code: 'CZ', group: group2 },
  { name: 'Denmark', code: 'DK', group: group2 },
  { name: 'Ecuador', code: 'EC', group: group2 },
  { name: 'El Salvador', code: 'SV', group: group2 },
  { name: 'Estonia', code: 'EE', group: group2 },
  { name: 'Finland', code: 'FI', group: group2 },
  { name: 'France', code: 'FR', group: group2 },
  { name: 'French Guiana', code: 'GF', group: group2 },
  { name: 'Georgia', code: 'GE', group: group2 },
  { name: 'Gibraltar', code: 'GI', group: group2 },
  { name: 'Greece', code: 'GR', group: group2 },
  { name: 'Guadeloupe', code: 'GP', group: group2 },
  { name: 'Guam', code: 'GU', group: group2 },
  { name: 'Guernsey', code: 'GG', group: group2 },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM', group: group2 },
  { name: 'Hong Kong', code: 'HK', group: group2 },
  { name: 'Hungary', code: 'HU', group: group2 },
  { name: 'Iceland', code: 'IS', group: group2 },
  { name: 'Ireland', code: 'IE', group: group2 },
  { name: 'Isle of Man', code: 'IM', group: group2 },
  { name: 'Italy', code: 'IT', group: group2 },
  { name: 'Japan', code: 'JP', group: group2 },
  { name: 'Jersey', code: 'JE', group: group2 },
  { name: 'Kazakhstan', code: 'KZ', group: group2 },
  { name: 'Latvia', code: 'LV', group: group2 },
  { name: 'Liechtenstein', code: 'LI', group: group2 },
  { name: 'Lithuania', code: 'LT', group: group2 },
  { name: 'Luxembourg', code: 'LU', group: group2 },
  { name: 'North Macedonia', code: 'MK', group: group2 },
  { name: 'Malta', code: 'MT', group: group2 },
  { name: 'Marshall Islands', code: 'MH', group: group2 },
  { name: 'Martinique', code: 'MQ', group: group2 },
  { name: 'Mayotte', code: 'YT', group: group2 },
  { name: 'Micronesia, Federated States of', code: 'FM', group: group2 },
  { name: 'Moldova, Republic of', code: 'MD', group: group2 },
  { name: 'Monaco', code: 'MC', group: group2 },
  { name: 'Nauru', code: 'NR', group: group2 },
  { name: 'Netherlands', code: 'NL', group: group2 },
  { name: 'New Zealand', code: 'NZ', group: group2 },
  { name: 'Niue', code: 'NU', group: group2 },
  { name: 'Norfolk Island', code: 'NF', group: group2 },
  { name: 'Northern Mariana Islands', code: 'MP', group: group2 },
  { name: 'Norway', code: 'NO', group: group2 },
  { name: 'Palau', code: 'PW', group: group2 },
  { name: 'Pitcairn', code: 'PN', group: group2 },
  { name: 'Poland', code: 'PL', group: group2 },
  { name: 'Portugal', code: 'PT', group: group2 },
  { name: 'Puerto Rico', code: 'PR', group: group2 },
  { name: 'Reunion', code: 'RE', group: group2 },
  { name: 'Romania', code: 'RO', group: group2 },
  { name: 'Russian Federation', code: 'RU', group: group2 },
  { name: 'Saint Pierre and Miquelon', code: 'PM', group: group2 },
  { name: 'San Marino', code: 'SM', group: group2 },
  { name: 'Slovakia', code: 'SK', group: group2 },
  { name: 'Slovenia', code: 'SI', group: group2 },
  { name: 'Spain', code: 'ES', group: group2 },
  { name: 'Sweden', code: 'SE', group: group2 },
  { name: 'Timor-Leste', code: 'TL', group: group2 },
  { name: 'Tokelau', code: 'TK', group: group2 },
  { name: 'Turkey', code: 'TR', group: group2 },
  { name: 'Turks and Caicos Islands', code: 'TC', group: group2 },
  { name: 'Ukraine', code: 'UA', group: group2 },
  { name: 'United States', code: 'US', group: group2 },
  { name: 'Virgin Islands, U.S.', code: 'VI', group: group2 },
]

export const getCountryObject = (countryCode: string | undefined): CountryObject | undefined => {
  return findReferenceObject(COUNTRIES, countryCode, 'code')
}

export const getCountryName = (countryCode: string | undefined, userLocale: string): string => {
  let name

  // check for user locale specific country name
  if (countryCode) {
    const userLocaleUppercase = userLocale ? userLocale.toLocaleUpperCase() : null
    if (userLocaleUppercase) {
      let localCountryNames
      if (userLocaleUppercase === 'DE') {
        localCountryNames = COUNTRIES_DE
      } else if (userLocaleUppercase === 'FR') {
        localCountryNames = COUNTRIES_FR
      } else if (userLocaleUppercase === 'IT') {
        localCountryNames = COUNTRIES_IT
      }

      if (localCountryNames) {
        name = localCountryNames[countryCode]
      }
    }
  }

  // use english name as fallback
  if (!name) {
    const countryObject = getCountryObject(countryCode)
    name = countryObject ? countryObject.name : ''
  }

  return name
}
