import { storeTablePageSizeToStorage } from 'src/service/local-storage/LocalStorageService'
import { TableSettings } from 'src/ui-shared/table/Table.const'

export function handlePageSizeChange(
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  tableSettings: TableSettings,
  setTableSettings: React.Dispatch<React.SetStateAction<any>>,
): void {
  const pageSize = parseInt(event.target.value, 10)

  storeTablePageSizeToStorage(pageSize)

  setTableSettings({
    ...tableSettings,
    size: pageSize,
    page: 0,
  })
}

export function handlePageChange(
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number,
  tableSettings: TableSettings,
  setTableSettings: React.Dispatch<React.SetStateAction<any>>,
): void {
  setTableSettings({
    ...tableSettings,
    page: newPage,
  })
}

export function handleSortChange(
  event: React.MouseEvent<unknown>,
  property: keyof any,
  tableSettings: TableSettings,
  setTableSettings: React.Dispatch<React.SetStateAction<any>>,
): void {
  const isAsc = tableSettings.orderBy === property && tableSettings.orderDir === 'asc'
  setTableSettings({
    ...tableSettings,
    orderDir: isAsc ? 'desc' : 'asc',
    orderBy: property,
  })
}
