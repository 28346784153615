/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface LaundryGroupWashmaster
 */
export interface LaundryGroupWashmaster {
    /**
     * 
     * @type {boolean}
     * @memberof LaundryGroupWashmaster
     */
    readonly washmasterEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LaundryGroupWashmaster
     */
    publicLaundryGroup: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LaundryGroupWashmaster
     */
    calendarEnabled: boolean;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof LaundryGroupWashmaster
     */
    enabledPaymentMethod: PaymentMethod;
    /**
     * Operator can inform Laundry Users via News Section / Push notifications
     * @type {boolean}
     * @memberof LaundryGroupWashmaster
     */
    newsEnabled: boolean;
    /**
     * Operator can view detailed / advanced reports
     * @type {boolean}
     * @memberof LaundryGroupWashmaster
     */
    advancedReportsEnabled: boolean;
    /**
     * Special push Notifications are sent to the users
     * @type {boolean}
     * @memberof LaundryGroupWashmaster
     */
    pushNotificationsEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LaundryGroupWashmaster
     */
    payPerUseEnabled: boolean;
}

/**
 * Check if a given object implements the LaundryGroupWashmaster interface.
 */
export function instanceOfLaundryGroupWashmaster(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "washmasterEnabled" in value;
    isInstance = isInstance && "publicLaundryGroup" in value;
    isInstance = isInstance && "calendarEnabled" in value;
    isInstance = isInstance && "enabledPaymentMethod" in value;
    isInstance = isInstance && "newsEnabled" in value;
    isInstance = isInstance && "advancedReportsEnabled" in value;
    isInstance = isInstance && "pushNotificationsEnabled" in value;
    isInstance = isInstance && "payPerUseEnabled" in value;

    return isInstance;
}

export function LaundryGroupWashmasterFromJSON(json: any): LaundryGroupWashmaster {
    return LaundryGroupWashmasterFromJSONTyped(json, false);
}

export function LaundryGroupWashmasterFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupWashmaster {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'washmasterEnabled': json['washmasterEnabled'],
        'publicLaundryGroup': json['publicLaundryGroup'],
        'calendarEnabled': json['calendarEnabled'],
        'enabledPaymentMethod': PaymentMethodFromJSON(json['enabledPaymentMethod']),
        'newsEnabled': json['newsEnabled'],
        'advancedReportsEnabled': json['advancedReportsEnabled'],
        'pushNotificationsEnabled': json['pushNotificationsEnabled'],
        'payPerUseEnabled': json['payPerUseEnabled'],
    };
}

export function LaundryGroupWashmasterToJSON(value?: LaundryGroupWashmaster | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'publicLaundryGroup': value.publicLaundryGroup,
        'calendarEnabled': value.calendarEnabled,
        'enabledPaymentMethod': PaymentMethodToJSON(value.enabledPaymentMethod),
        'newsEnabled': value.newsEnabled,
        'advancedReportsEnabled': value.advancedReportsEnabled,
        'pushNotificationsEnabled': value.pushNotificationsEnabled,
        'payPerUseEnabled': value.payPerUseEnabled,
    };
}

