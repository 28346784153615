/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SettingField } from './SettingField';
import {
    SettingFieldFromJSON,
    SettingFieldFromJSONTyped,
    SettingFieldToJSON,
} from './SettingField';

/**
 * 
 * @export
 * @interface SettingGroupField
 */
export interface SettingGroupField {
    /**
     * 
     * @type {boolean}
     * @memberof SettingGroupField
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingGroupField
     */
    expandable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingGroupField
     */
    expanded?: boolean;
    /**
     * 
     * @type {Array<SettingField>}
     * @memberof SettingGroupField
     */
    fields?: Array<SettingField>;
}

/**
 * Check if a given object implements the SettingGroupField interface.
 */
export function instanceOfSettingGroupField(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SettingGroupFieldFromJSON(json: any): SettingGroupField {
    return SettingGroupFieldFromJSONTyped(json, false);
}

export function SettingGroupFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingGroupField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'expandable': !exists(json, 'expandable') ? undefined : json['expandable'],
        'expanded': !exists(json, 'expanded') ? undefined : json['expanded'],
        'fields': !exists(json, 'fields') ? undefined : ((json['fields'] as Array<any>).map(SettingFieldFromJSON)),
    };
}

export function SettingGroupFieldToJSON(value?: SettingGroupField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hidden': value.hidden,
        'expandable': value.expandable,
        'expanded': value.expanded,
        'fields': value.fields === undefined ? undefined : ((value.fields as Array<any>).map(SettingFieldToJSON)),
    };
}

