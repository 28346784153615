/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettingFieldValidation
 */
export interface SettingFieldValidation {
    /**
     * 
     * @type {boolean}
     * @memberof SettingFieldValidation
     */
    required?: boolean;
    /**
     * valid on text, number
     * @type {number}
     * @memberof SettingFieldValidation
     */
    min?: number;
    /**
     * valid on text, number
     * @type {number}
     * @memberof SettingFieldValidation
     */
    max?: number;
    /**
     * valid on number
     * @type {boolean}
     * @memberof SettingFieldValidation
     */
    allowZero?: boolean;
}

/**
 * Check if a given object implements the SettingFieldValidation interface.
 */
export function instanceOfSettingFieldValidation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SettingFieldValidationFromJSON(json: any): SettingFieldValidation {
    return SettingFieldValidationFromJSONTyped(json, false);
}

export function SettingFieldValidationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingFieldValidation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'required': !exists(json, 'required') ? undefined : json['required'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'allowZero': !exists(json, 'allowZero') ? undefined : json['allowZero'],
    };
}

export function SettingFieldValidationToJSON(value?: SettingFieldValidation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'required': value.required,
        'min': value.min,
        'max': value.max,
        'allowZero': value.allowZero,
    };
}

