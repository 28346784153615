import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useTranslate } from 'src/i18n/useMessageSource'

export const NoNotifications: React.FC = () => {
  const translate = useTranslate()
  return (
    <Stack direction="row" spacing={2} p={2} pt={0}>
      <CheckCircleOutlineIcon sx={{ color: '#999', fontSize: '60px' }} />
      <Box>
        <Typography color={'#999'} fontSize={'1.3rem'} fontWeight={600}>
          {translate('allCaughtUp')}
        </Typography>
        <Typography color={'#999'} fontSize={'1rem'}>
          {translate('noNotifications')}
        </Typography>
      </Box>
    </Stack>
  )
}
