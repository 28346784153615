/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DashboardLaundryStateOverviews,
} from '../models';
import {
    DashboardLaundryStateOverviewsFromJSON,
    DashboardLaundryStateOverviewsToJSON,
} from '../models';

export interface DashboardSmLaundryStateOverviewGetRequest {
    laundryGroupId: string;
}

/**
 * 
 */
export class DashboardLaundryStateOverviewApi extends runtime.BaseAPI {

    /**
     * Returns multiple Doughnut Charts per Laundry in a selected LaundryGroup \\ The Doughnut contains MachineUtilizationStates of the Machines in it when there is no open Issue \\ or MachineUtilizationState.Error when there is a open Issue on a Machine
     * Overview of Laundries to see some states
     */
    async dashboardSmLaundryStateOverviewGetRaw(requestParameters: DashboardSmLaundryStateOverviewGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardLaundryStateOverviews>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling dashboardSmLaundryStateOverviewGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboard/sm/laundry-state-overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardLaundryStateOverviewsFromJSON(jsonValue));
    }

    /**
     * Returns multiple Doughnut Charts per Laundry in a selected LaundryGroup \\ The Doughnut contains MachineUtilizationStates of the Machines in it when there is no open Issue \\ or MachineUtilizationState.Error when there is a open Issue on a Machine
     * Overview of Laundries to see some states
     */
    async dashboardSmLaundryStateOverviewGet(laundryGroupId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardLaundryStateOverviews> {
        const response = await this.dashboardSmLaundryStateOverviewGetRaw({ laundryGroupId: laundryGroupId }, initOverrides);
        return await response.value();
    }

}
