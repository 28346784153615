/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConsumptionChartGroupBy {
    TOTAL = 'TOTAL',
    ORGANIZATION = 'ORGANIZATION',
    LAUNDRY_GROUP = 'LAUNDRY_GROUP',
    LAUNDRY = 'LAUNDRY',
    MACHINE = 'MACHINE',
    MACHINE_CATEGORY = 'MACHINE_CATEGORY'
}


export function ConsumptionChartGroupByFromJSON(json: any): ConsumptionChartGroupBy {
    return ConsumptionChartGroupByFromJSONTyped(json, false);
}

export function ConsumptionChartGroupByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumptionChartGroupBy {
    return json as ConsumptionChartGroupBy;
}

export function ConsumptionChartGroupByToJSON(value?: ConsumptionChartGroupBy | null): any {
    return value as any;
}

