import { OrganizationReference } from 'src/service/backend/api'
import { getTablePageSizeFromStorage } from 'src/service/local-storage/LocalStorageService'
import { OrderBy, OrderDir } from 'src/service/view-model/base/Data'
import { DataSettings } from 'src/service/view-model/base/DataSettings'

export const PAGE_SIZES = [10, 25, 100]
export const MIN_PAGE_SIZE = PAGE_SIZES[0]
export const MAX_PAGE_SIZE = PAGE_SIZES[PAGE_SIZES.length - 1]
const DEFAULT_PAGE_SIZE = PAGE_SIZES[0]

export interface HeadCells {
  id: string
  label: string
  noSort?: boolean
}

export interface HeadCellsWidth extends HeadCells {
  width?: number
}

export interface TableSettings extends DataSettings {
  size: number
  page: number
  orderBy: OrderBy<any>
  orderDir: OrderDir
  search: string
}

export interface TableSettingsWithOrganization extends TableSettings {
  organizationId?: string
}

const DEFAULT_TABLE_SETTINGS: TableSettings = {
  size: DEFAULT_PAGE_SIZE,
  page: 0,
  orderDir: 'asc',
  orderBy: 'name',
  search: '',
}

export interface TableData<T> {
  data: T[]
  totalElements: number
}

export const DEFAULT_DATA: TableData<any> = { data: [], totalElements: -1 }

export function tableSettingsSort(t: TableSettings): string {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return t.orderBy && t.orderDir ? t.orderBy + ',' + t.orderDir : ''
}

function getDefaultTablePageSize(): number {
  const pageSize = getTablePageSizeFromStorage() || DEFAULT_PAGE_SIZE
  return pageSize
}

export function getDefaultTableSettings(): TableSettings {
  return {
    ...DEFAULT_TABLE_SETTINGS,
    size: getDefaultTablePageSize(),
  }
}

export function withOrganization(defaultTableSettings: any, activeOrganization?: OrganizationReference | null): any {
  return {
    ...defaultTableSettings,
    organizationId: activeOrganization?.id,
  }
}
