import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  ChartData,
  Configuration,
  DashboardIssuesApi,
  DashboardIssuesCountList,
  IssueCommonState,
  IssuesChartGroupBy,
} from 'src/service/backend/api'
import { DashboardIssuesApiMock } from 'src/service/backend/api-mock/DashboardIssuesApiMock'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { removeTimeFromDate, removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'
import { groupDatasetsForDoughnutChart } from 'src/service/view-model/base/chart/Chart.utils'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DashboardLoadingIndicator } from 'src/ui/page/sm/index/DashboardLoadingIndicator'

const AsyncChart = React.lazy(() => import('src/ui-shared/chart/Chart').then(({ Chart }) => ({ default: Chart })))

const OPEN_ISSUE_STATE = [IssueCommonState.REQUESTED, IssueCommonState.OPEN, IssueCommonState.IN_PROGRESS]

interface Props {
  useMockData: boolean
}

export const DashboardIssuesWidget = ({ useMockData }: Props): ReactElement => {
  const translate = useTranslate()
  const theme = useTheme()
  const activeOrganization = useActiveOrganization()

  // api
  const httpConfiguration: Configuration = useContext(HttpContext)
  const dashboardIssuesApi = useMockData ? new DashboardIssuesApiMock() : new DashboardIssuesApi(httpConfiguration)

  // state
  const [issueCountResult, setIssueCountResult] = useState<DashboardIssuesCountList | null>(null)
  const [issueCountResultErrorMessage, setIssueCountResultErrorMessage] = useState<string | null>(null)

  // derived state
  const activeOrganizationId = activeOrganization?.id

  const loadIssuesCountChart = (): Promise<ChartData> => {
    return dashboardIssuesApi
      .dashboardSmIssuesChartGet(
        IssuesChartGroupBy.ISSUE_STATE,
        undefined,
        undefined,
        undefined,
        undefined,
        OPEN_ISSUE_STATE,
        activeOrganizationId,
      )
      .then((data) => {
        return groupDatasetsForDoughnutChart(data)
      })
  }

  const loadIssuesCount = () => {
    const now = new Date()
    const createdFrom = removeTimeFromDate(now)
    const createdTo = removeTimeFromDateEndOfDay(now)

    setIssueCountResultErrorMessage(null)

    dashboardIssuesApi
      .dashboardSmIssuesCountGet(
        IssuesChartGroupBy.TOTAL,
        createdFrom,
        createdTo,
        undefined,
        undefined,
        undefined,
        activeOrganizationId,
      )
      .then((data) => {
        setIssueCountResult(data)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setIssueCountResult(null)
        setIssueCountResultErrorMessage(errorMessage)
      })
  }

  // load data on mount
  useEffect(() => {
    loadIssuesCount()
  }, [useMockData])

  const CHART_COLORS = ['#63B200', '#9E9E9E', theme.palette.primary.main]

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Typography variant="h4" align="center" ml={2}>
          {translate('openRequests')}
        </Typography>
        <AsyncChart
          loadChart={loadIssuesCountChart}
          chartSettings={undefined}
          hideTitle
          heightOverride={230}
          defaultChartColors={CHART_COLORS}
        />
      </Grid>

      <Grid item xs={4}>
        <Box ml={2}>
          <Typography variant="h4" align="center">
            {translate('newRequestsToday')}
          </Typography>
        </Box>

        {issueCountResultErrorMessage ? (
          <ErrorMessage message={issueCountResultErrorMessage} />
        ) : issueCountResult?.data ? (
          <>
            <Typography variant="h4" align="center" sx={{ fontSize: 180, color: theme.palette.primary.main }}>
              {issueCountResult.data.length > 0 ? issueCountResult.data[0].count : '-'}
            </Typography>
          </>
        ) : (
          <DashboardLoadingIndicator />
        )}
      </Grid>
    </Grid>
  )
}
