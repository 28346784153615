import { TranslateFunction } from 'src/i18n/useMessageSource'
import { ReportTopUpType } from 'src/service/backend/api/models/ReportTopUpType'
import { findReferenceObject, getReferenceName } from 'src/service/view-model/base/Reference.utils'

export interface ReportTopUpTypeObject {
  id: ReportTopUpType
  name: string
}

export const REPORT_TOP_UP_TYPES_OBJECTS: ReportTopUpTypeObject[] = [
  {
    id: ReportTopUpType.TOP_UP_PREPAID_BY_ESR,
    name: 'topUpPrepaidEsr',
  },
  {
    id: ReportTopUpType.TOP_UP_PREPAID_BY_DATATRANS,
    name: 'topUpPrepaidDatatrans',
  },
  {
    id: ReportTopUpType.TOP_UP_PREPAID_BY_OPERATOR,
    name: 'topUpPrepaidOperator',
  },
  {
    id: ReportTopUpType.TOP_UP_PREPAID_BY_SEPA,
    name: 'topUpPrepaidSepa',
  },
  {
    id: ReportTopUpType.TOP_UP_PREPAID_BY_SWISS_QR,
    name: 'topUpPrepaidSwissQr',
  },
  {
    id: ReportTopUpType.UNSUCCESSFUL_TOP_UP_BY_ESR,
    name: 'topUpPrepaidEsrFailed',
  },
  {
    id: ReportTopUpType.UNSUCCESSFUL_TOP_UP_BY_SEPA,
    name: 'topUpPrepaidSepaFailed',
  },
  {
    id: ReportTopUpType.UNSUCCESSFUL_TOP_UP_SWISS_QR,
    name: 'topUpPrepaidSwissQrFailed',
  },
  {
    id: ReportTopUpType.REFUND_PREPAID_BY_DATATRANS,
    name: 'topUpPrepaidRefundByDatatrans',
  },
  {
    id: ReportTopUpType.REFUND_PREPAID_BY_OPERATOR,
    name: 'topUpPrepaidRefundByOperator',
  },
  {
    id: ReportTopUpType.REFUND_PREPAID_BY_BANKTRANSFER,
    name: 'topUpPrepaidRefundByBanktransfer',
  },
  {
    id: ReportTopUpType.MANUAL_ADJUSTMENT,
    name: 'topUpPrepaidManualAdjustment',
  },
]

export const getReportTopUpTypeName = (
  reportTopUpType: ReportTopUpType | undefined,
  translate: TranslateFunction,
): string => {
  return getReferenceName(REPORT_TOP_UP_TYPES_OBJECTS, translate, reportTopUpType)
}

export const getReportTopUpTypeObject = (
  reportTopUpType: ReportTopUpType | undefined,
): ReportTopUpTypeObject | undefined => {
  return findReferenceObject(REPORT_TOP_UP_TYPES_OBJECTS, reportTopUpType)
}
