import { TranslateFunction } from 'src/i18n/useMessageSource'
import {
  LaundryGroupPriceList,
  MachineCategory,
  MachineConnectivityType,
  MachineReference,
  MachineType,
  PriceListType,
  ProgramInstallationStatus,
  ProgramsEditableMachineType,
} from 'src/service/backend/api/models'
import { formatWeight } from 'src/service/utils/NumberFormatUtils'
import { findReferenceObject, getReferenceName } from 'src/service/view-model/base/Reference.utils'
import { Option } from 'src/ui-shared/base/form/control/Index'

export interface MachineTypeObject {
  type: string
  name: string
}

export const OFFLINE_MACHINES: MachineTypeObject[] = [
  { type: MachineType.OTHERS_IRON_ASSET, name: 'OTHERS_IRON_ASSET' },
  { type: MachineType.OTHERS_WA_ASSET, name: 'OTHERS_WA_ASSET' },
  { type: MachineType.OTHERS_WT_ASSET, name: 'OTHERS_WT_ASSET' },
  { type: MachineType.OTHERS_FIXED_ASSET, name: 'OTHERS_FIXED_ASSET' },
]

export const ONLINE_MACHINES: MachineTypeObject[] = [
  { type: MachineType.SMAG_STANDARD_WA, name: 'SMAG_STANDARD_WA' },
  { type: MachineType.SMAG_STANDARD_WT, name: 'SMAG_STANDARD_WT' },
  { type: MachineType.SMAG_PROFESSIONAL_WA, name: 'SMAG_PROFESSIONAL_WA' },
  { type: MachineType.SMAG_PROFESSIONAL_WT, name: 'SMAG_PROFESSIONAL_WT' },
  { type: MachineType.SMAG_PROFESSIONAL_WT20, name: 'SMAG_PROFESSIONAL_WT20' },
  { type: MachineType.SMAG_PROFESSIONAL_WE20, name: 'SMAG_PROFESSIONAL_WE20' },
  { type: MachineType.SMAG_PROFESSIONAL_WE20_24_33, name: 'SMAG_PROFESSIONAL_WE20_24_33' },
  { type: MachineType.SMAG_PROFESSIONAL_WT20_24_33, name: 'SMAG_PROFESSIONAL_WT20_24_33' },
  { type: MachineType.SMAG_STANDARD_WA24, name: 'SMAG_STANDARD_WA24' },
  { type: MachineType.SMAG_PROFESSIONAL_WA24, name: 'SMAG_PROFESSIONAL_WA24' },

  { type: MachineType.OTHERS_STANDARD_WA, name: 'OTHERS_STANDARD_WA' },
  { type: MachineType.OTHERS_STANDARD_WT, name: 'OTHERS_STANDARD_WT' },
  { type: MachineType.OTHERS_STANDARD_RLWT, name: 'OTHERS_STANDARD_RLWT' },
  { type: MachineType.OTHERS_STANDARD_WAT, name: 'OTHERS_STANDARD_WAT' },

  { type: MachineType.SMAG_DOS, name: 'SMAG_DOS' },
  { type: MachineType.GMP_ROT_IRON, name: 'GMP_ROT_IRON' },
]
export const MACHINES: MachineTypeObject[] = [...ONLINE_MACHINES, ...OFFLINE_MACHINES]

export const getMachineTypeName = (machineType: string | undefined, translate: TranslateFunction): string => {
  return getReferenceName(MACHINES, translate, machineType, 'type')
}

export const getMachineTypeNameWithTechSuffix = (
  machineType: string | undefined,
  translate: TranslateFunction,
): string => {
  const machineTypeName = getMachineTypeName(machineType, translate)
  if (!machineType) {
    return machineTypeName
  }
  const suffix = machineType.toString().replace('SMAG_PROFESSIONAL_', '').replace('SMAG_STANDARD_', '')
  const result = machineTypeName + ' (' + suffix + ')'
  return result
}

export interface MachineCategoryObject {
  id: MachineCategory
  name: string
}

export const MACHINE_CATEGORIES: MachineCategoryObject[] = [
  {
    id: MachineCategory.WA,
    name: 'washingMachine',
  },
  {
    id: MachineCategory.WT,
    name: 'dryer',
  },
  {
    id: MachineCategory.WAT,
    name: 'combinedMachine',
  },
  {
    id: MachineCategory.RLWT,
    name: 'OTHERS_STANDARD_RLWT',
  },
]

export const getMachineCategoryName = (machineCategory: string | undefined, translate: TranslateFunction): string => {
  return getReferenceName(MACHINE_CATEGORIES, translate, machineCategory)
}

export const getDrumSize = (list: LaundryGroupPriceList): number | undefined => {
  let result: number | null | undefined
  if (list.priceListType === PriceListType.EXTERNAL) {
    result = list.external?.drumSize
  } else {
    result = list.integrated?.drumSize
  }
  return result ? result : undefined
}

export const getDrumSizeLabel = (drumSize: number | null | undefined, unit: string | undefined): string => {
  return drumSize ? formatWeight(drumSize, unit) : ''
}

export const getMachineTypesAsOptions = (types: MachineType[], translate?: TranslateFunction): Option[] => {
  return types.map((type) => {
    const item = findReferenceObject(MACHINES, type, 'type')
    let result: Option
    if (item) {
      result = {
        label: translate ? translate(item.name) : item.name,
        value: item.type,
      }
    } else {
      // type not found in MACHINES
      result = {
        label: types.toString(),
        value: type,
      }
    }
    return result
  })
}

const getMachineTypeObjectsAsOptions = (types: MachineTypeObject[], translate?: TranslateFunction): Option[] => {
  return types.map((item) => {
    const result: Option = {
      label: translate ? translate(item.name) : item.name,
      value: item.type,
    }
    return result
  })
}

export const getOfflineMachineTypesAsOptions = (translate?: TranslateFunction): Option[] => {
  return getMachineTypeObjectsAsOptions(OFFLINE_MACHINES, translate)
}

export const getOnlineMachineTypesAsOptions = (translate?: TranslateFunction): Option[] => {
  return getMachineTypeObjectsAsOptions(ONLINE_MACHINES, translate)
}

export const getMachineCategoriesAsOptions = (translate?: TranslateFunction): Option[] => {
  return MACHINE_CATEGORIES.map((item) => {
    const result: Option = {
      label: translate ? translate(item.name) : item.name,
      value: item.id,
    }
    return result
  })
}

export interface MachineConnectivityTypeObject {
  id: string
  name: string
}

export const MACHINE_CONNECTIVITY_TYPE: MachineConnectivityTypeObject[] = [
  {
    id: MachineConnectivityType.INTEGRATED,
    name: 'machineConnectivity.integrated',
  },
  {
    id: MachineConnectivityType.EXTERNAL,
    name: 'machineConnectivity.external',
  },
  {
    id: MachineConnectivityType.UNCONNECTED,
    name: 'machineConnectivity.unconnected',
  },
]

export const CONNECTED_MACHINE_TYPES = [MachineConnectivityType.INTEGRATED, MachineConnectivityType.EXTERNAL]

export const isConnectedMachineType = (machineConnectivityType: MachineConnectivityType | undefined): boolean => {
  if (!machineConnectivityType) {
    return false
  }
  return machineConnectivityType !== MachineConnectivityType.UNCONNECTED
}

export const isProgramCompatibleMachineType = (programInstallationStatus?: ProgramInstallationStatus): boolean => {
  if (!programInstallationStatus) {
    return false
  }
  return programInstallationStatus !== ProgramInstallationStatus.INCOMPATIBLE
}

export const isExternalMachineType = (machineConnectivityType: MachineConnectivityType | undefined): boolean => {
  if (!machineConnectivityType) {
    return false
  }
  return machineConnectivityType === MachineConnectivityType.EXTERNAL
}

export const isIntegratedMachineType = (machineConnectivityType: MachineConnectivityType | undefined): boolean => {
  if (!machineConnectivityType) {
    return false
  }
  return machineConnectivityType === MachineConnectivityType.INTEGRATED
}

export const getMachineConnectivityTypeName = (
  machineConnectivityType: MachineConnectivityType | undefined,
  translate: TranslateFunction,
): string => {
  return getReferenceName(MACHINE_CONNECTIVITY_TYPE, translate, machineConnectivityType)
}

export const getMachineNameAndSerialNumber = (machine?: MachineReference | null): string | undefined => {
  let machineNameAndSerialNumber
  if (machine) {
    if (machine.serialNumber && machine.serialNumber !== '0') {
      machineNameAndSerialNumber = `${machine.name} / ${machine.serialNumber}`
    } else {
      machineNameAndSerialNumber = machine.name
    }
  }
  return machineNameAndSerialNumber
}

export const getMachineOptionLabel = (option?: MachineReference): string => {
  let optionLabel = ''
  if (option) {
    optionLabel = `${option.name} (${option.serialNumber})`
  }
  return optionLabel
}

export const findEditableMachineType = (
  machineTypes: ProgramsEditableMachineType[],
  machineType: MachineType,
): ProgramsEditableMachineType | undefined => {
  return machineTypes.find((item) => item.machineType === machineType)
}

export const getDrumSizesForEditableMachineType = (editableMachineType?: ProgramsEditableMachineType): number[] => {
  return editableMachineType?.drumSizes || []
}

export const getDrumSizeUnitForEditableMachineType = (
  editableMachineType?: ProgramsEditableMachineType,
): string | undefined => {
  return editableMachineType?.drumSizeUnit
}
