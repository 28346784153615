import React, { ReactElement } from 'react'
import { Box, Tooltip, Typography, styled } from '@mui/material'
import {
  AccountBalance,
  Add,
  ArrowBackIos,
  Article,
  CalendarMonth,
  CloudUploadOutlined,
  Create,
  DeleteForever,
  Edit,
  FilterList,
  Forum,
  InsertChartOutlined,
  LocationOn,
  Save,
  Search,
  Sell,
  Settings,
  ShoppingBag,
} from '@mui/icons-material'
import { ReactComponent as EsLogoImage } from 'src/assets/logo-easyset-icon-dark.svg'

export const CreateIcon = Create
export const CalendarMonthIcon = CalendarMonth
export const CloudUploadOutlinedIcon = CloudUploadOutlined
export const AccountBalanceIcon = AccountBalance
export const ArrowBackIcon = ArrowBackIos
export const ArticleIcon = Article
export const EditIcon = Edit
export const DeleteIcon = DeleteForever
export const ForumIcon = Forum
export const SaveIcon = Save
export const AddIcon = Add
export const FilterListIcon = FilterList
export const SearchIcon = Search
export const SellIcon = Sell
export const SettingsIcon = Settings
export const ShoppingBagIcon = ShoppingBag
export const LocationOnIcon = LocationOn
export const InsertChartOutlinedIcon = InsertChartOutlined

export const IconContainer = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(2),
  display: 'flex',
  alignSelf: 'center',
  justifyContent: 'center',
}))

export const EsLogoIcon = styled(EsLogoImage)(({ theme }) => ({
  marginBottom: '20px',
  fill: theme.palette.primary.main,
}))

interface Props {
  icon: ReactElement
  tooltip: string
}

export const IconWithTooltip = ({ icon, tooltip }: Props): ReactElement | null => {
  return (
    <IconContainer>
      <Tooltip title={<Typography variant="body2">{tooltip}</Typography>} placement="bottom">
        <span>{icon}</span>
      </Tooltip>
    </IconContainer>
  )
}
