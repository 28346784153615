import { isBefore } from 'date-fns'
import { ONE_DAY, ONE_SECOND } from 'src/service/utils/DateConstants'
import { JSON_DATE_DESERIALIZER, JSON_DATE_SERIALIZER } from 'src/service/utils/JsonUtils'

export interface Draft<T> {
  payload: string | T
  username: string
  dateUpdated: Date
}

export interface DraftStatus {
  hasDraft: boolean
  isExpired?: boolean
}

// store draft every 30 seconds
export const DRAFT_SAVE_INTERVAL_MS = 30 * ONE_SECOND

// keep drafts for 3 days
const DRAFT_MAX_AGE_MS = 3 * ONE_DAY

export const getDraftStatus = (storageKey: string): DraftStatus => {
  const draft = getDraftFromStorage(storageKey, false)

  const result: DraftStatus = {
    hasDraft: draft ? true : false,
    isExpired: draft ? isDraftExpired(draft) : undefined,
  }

  return result
}

const getDraftStringFromStorage = (storageKey: string): string | null => {
  const draftVersion = localStorage.getItem(storageKey)
  return draftVersion
}

// read
export const getDraftFromStorage = <T>(storageKey: string, deserializePayload: boolean): Draft<T> | undefined => {
  const draftVersionString = getDraftStringFromStorage(storageKey)
  let draft: Draft<T> | undefined

  if (draftVersionString) {
    // parse draft
    draft = JSON.parse(draftVersionString, JSON_DATE_DESERIALIZER) as Draft<T>

    // parse payload in draft
    if (deserializePayload) {
      if (typeof draft.payload === 'string') {
        const payload = JSON.parse(draft.payload, JSON_DATE_DESERIALIZER) as T
        draft.payload = payload
      }
    }
  }

  return draft
}

// write
export const saveDraftToStorage = <T>(
  storageKey: string,
  newPayload: T,
  previousPayloadJSON: string,
  username: string,
): string | undefined => {
  const newPayloadJSON = JSON.stringify(newPayload, JSON_DATE_SERIALIZER)

  // compare previous payload and new payload to determine if changed
  if (previousPayloadJSON !== newPayloadJSON) {
    const draft: Draft<T> = {
      payload: newPayloadJSON,
      username: username,
      dateUpdated: new Date(),
    }
    const storageValue = JSON.stringify(draft, JSON_DATE_SERIALIZER)

    saveDraftStringToStorage(storageKey, storageValue)

    return newPayloadJSON
  }

  return undefined
}

const saveDraftStringToStorage = (storageKey: string, storageValue: string) => {
  localStorage.setItem(storageKey, storageValue)
}

// remove

const isDraftExpired = (draft: Draft<any>) => {
  const draftExpireTimestamp = new Date().getTime() - DRAFT_MAX_AGE_MS
  const draftExpireDate = new Date(draftExpireTimestamp)
  return isBefore(draft.dateUpdated, draftExpireDate)
}

export const removeDraftFromStorage = (storageKey: string): void => {
  localStorage.removeItem(storageKey)
}
