import React, { FC, PropsWithChildren, ReactElement } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Address } from 'src/service/backend/api'
import { getCountryName } from 'src/service/view-model/base/country/Countries'
import { LocationButton } from 'src/ui-shared/base/button/LocationButton'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { useUserLocale } from 'src/user/UserContext'

export type AddressType = 'billing' | 'site'

interface Props extends PropsWithChildren {
  address: Address | undefined
  hideCoordinates?: boolean
  addressType?: AddressType
  titleKey?: string
  hideFields?: boolean
}

export const AddressSection: FC<Props> = ({
  address,
  hideCoordinates,
  addressType,
  titleKey,
  hideFields,
  children,
}): ReactElement => {
  const translate = useTranslate()
  const userLocale = useUserLocale()
  const { classes: sharedClasses } = useSharedStyles()

  const labelSuffix = addressType === 'billing' ? 'Billing' : ''

  return (
    <Grid container spacing={2} className={sharedClasses.GridWithTextField} alignItems="flex-end">
      {/* provide title and children in single row for spacing reasons */}
      {titleKey || children ? (
        <Grid item sm={12}>
          <>
            {titleKey ? <Typography className={sharedClasses.SectionTitle}>{translate(titleKey)}</Typography> : null}
            {children ? children : null}
          </>
        </Grid>
      ) : null}
      {hideFields ? null : (
        <>
          <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
            <DetailsTextField value={address?.addressLine1} label={translate(`address1${labelSuffix}`)} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
            <DetailsTextField value={address?.addressLine2} label={translate(`address2${labelSuffix}`)} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField value={address?.zipCode} label={translate(`zipCode${labelSuffix}`)} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField value={address?.city} label={translate(`city${labelSuffix}`)} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
            <DetailsTextField
              value={getCountryName(address?.countryCode, userLocale)}
              label={translate(`country${labelSuffix}`)}
            />
          </Grid>
          {!hideCoordinates ? (
            <>
              <Grid item {...ITEM_BREAKPOINTS} md={3} lg={3}>
                <DetailsTextField value={address?.latitude} label={translate(`latitude${labelSuffix}`)} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} md={3} lg={3}>
                <DetailsTextField value={address?.longitude} label={translate(`longitude${labelSuffix}`)} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <LocationButton latitude={address?.latitude} longitude={address?.longitude} />
              </Grid>
            </>
          ) : null}
        </>
      )}
    </Grid>
  )
}
