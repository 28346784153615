import { ChartScale } from 'src/service/backend/api'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'

export interface ChartScaleObject {
  id: ChartScale
  name: string
}

export const CHART_SCALE_OBJECTS: ChartScaleObject[] = [
  {
    id: ChartScale.DATE,
    name: 'day',
  },
  {
    id: ChartScale.MONTH,
    name: 'month',
  },
  {
    id: ChartScale.YEAR,
    name: 'year',
  },
]

export const getChartScaleObject = (chartScale: ChartScale | undefined): ChartScaleObject | undefined => {
  return findReferenceObject(CHART_SCALE_OBJECTS, chartScale)
}

export const CHART_COLOR_PALETTE = ['#9e9e9e', '#7a7a7a', '#000000', '#DFB59F']
