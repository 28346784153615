/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Colors } from './Colors';
import {
    ColorsFromJSON,
    ColorsFromJSONTyped,
    ColorsToJSON,
} from './Colors';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';
import type { LaundryGroupStatisticsDataData } from './LaundryGroupStatisticsDataData';
import {
    LaundryGroupStatisticsDataDataFromJSON,
    LaundryGroupStatisticsDataDataFromJSONTyped,
    LaundryGroupStatisticsDataDataToJSON,
} from './LaundryGroupStatisticsDataData';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';

/**
 * 
 * @export
 * @interface LaundryGroupStatisticsData
 */
export interface LaundryGroupStatisticsData {
    /**
     * 
     * @type {Colors}
     * @memberof LaundryGroupStatisticsData
     */
    colors?: Colors | null;
    /**
     * 
     * @type {OrganizationReference}
     * @memberof LaundryGroupStatisticsData
     */
    organization: OrganizationReference;
    /**
     * 
     * @type {LaundryGroupReference}
     * @memberof LaundryGroupStatisticsData
     */
    laundryGroup: LaundryGroupReference;
    /**
     * 
     * @type {LaundryGroupStatisticsDataData}
     * @memberof LaundryGroupStatisticsData
     */
    data: LaundryGroupStatisticsDataData;
}

/**
 * Check if a given object implements the LaundryGroupStatisticsData interface.
 */
export function instanceOfLaundryGroupStatisticsData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "laundryGroup" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function LaundryGroupStatisticsDataFromJSON(json: any): LaundryGroupStatisticsData {
    return LaundryGroupStatisticsDataFromJSONTyped(json, false);
}

export function LaundryGroupStatisticsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupStatisticsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'colors': !exists(json, 'colors') ? undefined : ColorsFromJSON(json['colors']),
        'organization': OrganizationReferenceFromJSON(json['organization']),
        'laundryGroup': LaundryGroupReferenceFromJSON(json['laundryGroup']),
        'data': LaundryGroupStatisticsDataDataFromJSON(json['data']),
    };
}

export function LaundryGroupStatisticsDataToJSON(value?: LaundryGroupStatisticsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'colors': ColorsToJSON(value.colors),
        'organization': OrganizationReferenceToJSON(value.organization),
        'laundryGroup': LaundryGroupReferenceToJSON(value.laundryGroup),
        'data': LaundryGroupStatisticsDataDataToJSON(value.data),
    };
}

