import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { makeStyles } from 'tss-react/mui'
import { Button, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { FormApi } from 'final-form'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LaundryGroupPriceListElementIntegrated, LaundryGroupPriceListIntegrated } from 'src/service/backend/api'
import { formatAmount } from 'src/service/utils/NumberFormatUtils'
import {
  LaundryGroupPriceListInternalElementViewModel,
  LaundryGroupPriceListInternalViewModel,
  createLaundryGroupPriceListInternalViewModel,
} from 'src/service/view-model/laundry-group/LaundryGroupPriceListViewModel'
import { getDrumSizeLabel, getMachineTypeName } from 'src/service/view-model/machine/Machines'
import { AmountTextFieldValidate } from 'src/ui-shared/base/form/control/AmountTextFieldValidate'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { StyledTextFieldExtraSmall } from 'src/ui-shared/base/form/control/TextField.style'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { required, validPositiveOrNegativeAmount } from 'src/ui-shared/base/form/validation/Validators'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { CurrencyAutoCompleteValidate } from 'src/ui-shared/form/control/CurrencyAutoCompleteValidate'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'
import { PRICE_LIST_INTERNAL_HEADCELLS } from 'src/ui/page/common/laundry-group/price-list/LaundryGroupPriceListInternalView'

const customStyles = makeStyles()(() => {
  return {
    CustomCell: {
      paddingLeft: '16px',
      padding: '0px',
    },
  }
})

interface AdjustPricesModel {
  regularPriceChange: string
  happyHourPriceChange: string
}

interface HeadCells {
  id: keyof LaundryGroupPriceListElementIntegrated | string
  label: string
}

const headCells: HeadCells[] = PRICE_LIST_INTERNAL_HEADCELLS

interface Props {
  priceList: LaundryGroupPriceListIntegrated | null | undefined
  submitForm: (values: LaundryGroupPriceListInternalViewModel) => void
  setDirty: (value: boolean) => void
}

export const LaundryGroupPriceListInternalEdit: FC<Props> = ({ priceList, submitForm, setDirty }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const { classes: sharedClasses } = useSharedStyles()

  const { classes } = customStyles()

  const translate = useTranslate()

  const [laundryGroupPriceListInternalViewModel, setLaundryGroupPriceListInternalViewModel] =
    useState<LaundryGroupPriceListInternalViewModel>(createLaundryGroupPriceListInternalViewModel())

  const [laundryGroupInternalPricesViewModel, setLaundryGroupInternalPricesViewModel] = useState<
    LaundryGroupPriceListInternalElementViewModel[]
  >([])

  const [standardPriceErrorHandler, setStandardPriceErrorHandler] = useState<number | null>(null)
  const [happyHourPriceErrorHandler, setHappyHourPriceErrorHandler] = useState<number | null>(null)

  const [adjustPrices, setAdjustPrices] = useState<AdjustPricesModel>({
    regularPriceChange: '0.00',
    happyHourPriceChange: '0.00',
  })

  const onAdjustPricesHandler = (
    pricesModel: AdjustPricesModel,
    form: FormApi<AdjustPricesModel, Partial<AdjustPricesModel>>,
  ) => {
    setDirty(true)

    laundryGroupInternalPricesViewModel.forEach((priceElement) => {
      if (priceElement.regularAmount && priceElement.happyHourAmount) {
        let totalStandardPrice =
          parseFloat(priceElement.regularAmount) + parseFloat(pricesModel.regularPriceChange) || 0
        let totalHappyHourPrice =
          parseFloat(priceElement.happyHourAmount) + parseFloat(pricesModel.happyHourPriceChange) || 0
        totalStandardPrice = totalStandardPrice < 0 ? 0 : totalStandardPrice
        totalHappyHourPrice = totalHappyHourPrice < 0 ? 0 : totalHappyHourPrice
        priceElement.regularAmount = formatAmount(totalStandardPrice)
        priceElement.happyHourAmount = formatAmount(totalHappyHourPrice)
      }
    })
    setLaundryGroupInternalPricesViewModel(laundryGroupInternalPricesViewModel)
    setAdjustPrices({
      regularPriceChange: '0.00',
      happyHourPriceChange: '0.00',
    })
    form.reset()
  }

  const onHappyHourPriceItemChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setDirty(true)
    const index = parseInt(event.target.name)
    const newViewModel = [...laundryGroupInternalPricesViewModel]
    newViewModel[index].happyHourAmount = event.target.value
    setLaundryGroupInternalPricesViewModel(newViewModel)
    validatePriceInput(index, event.target.value, true)
  }

  const onRegularPriceItemChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setDirty(true)
    const index = parseInt(event.target.name)
    const newViewModel = [...laundryGroupInternalPricesViewModel]
    newViewModel[index].regularAmount = event.target.value
    setLaundryGroupInternalPricesViewModel(newViewModel)
    validatePriceInput(index, event.target.value, false)
  }

  const validatePriceInput = (index: number, value: string, happyHour?: boolean) => {
    const pricesRegex = /^[0-9]+(\.[0-9]{1,2})?$/
    if (!pricesRegex.test(value)) {
      happyHour ? setHappyHourPriceErrorHandler(index) : setStandardPriceErrorHandler(index)
    } else {
      happyHour ? setHappyHourPriceErrorHandler(null) : setStandardPriceErrorHandler(null)
    }
  }

  useEffect(() => {
    if (priceList) {
      const laundryGroupPriceListInternalViewModel = createLaundryGroupPriceListInternalViewModel(priceList)
      setLaundryGroupPriceListInternalViewModel(laundryGroupPriceListInternalViewModel)
      setLaundryGroupInternalPricesViewModel(laundryGroupPriceListInternalViewModel.prices)
    }
  }, [priceList])

  // JSX
  const displayRows =
    laundryGroupInternalPricesViewModel.length > 0 ? (
      laundryGroupInternalPricesViewModel.map((item, index) => {
        return (
          <TableRow className={tableClasses.tableRow} key={'price' + item.index}>
            <TableCell className={classes.CustomCell} width="5%">
              {item.index}
            </TableCell>
            <TableCell className={classes.CustomCell} width="60%">
              {item.name}
            </TableCell>
            <TableCell className={classes.CustomCell} width="5%">
              {priceList?.currency}
            </TableCell>
            <TableCell className={classes.CustomCell} width="15%">
              <StyledTextFieldExtraSmall
                value={item.regularAmount}
                name={index.toString()}
                onChange={onRegularPriceItemChanged.bind(this)}
                error={standardPriceErrorHandler === index}
                helperText={standardPriceErrorHandler === index ? translate('validation.valid.price') : ''}
              />
            </TableCell>
            <TableCell className={classes.CustomCell} width="15%">
              <StyledTextFieldExtraSmall
                value={item.happyHourAmount}
                name={index.toString()}
                onChange={onHappyHourPriceItemChanged.bind(this)}
                error={happyHourPriceErrorHandler === index}
                helperText={happyHourPriceErrorHandler === index ? translate('validation.valid.price') : ''}
              />
            </TableCell>
          </TableRow>
        )
      })
    ) : (
      <TableEmpty colspan={headCells.length} />
    )

  const displayHeaders = headCells.map((item) => {
    return <TableCell key={item.id}>{translate(item.label)}</TableCell>
  })

  const displayTable = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{displayHeaders}</TableRow>
        </TableHead>
        <TableBody>{displayRows}</TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <>
      <Form<LaundryGroupPriceListInternalViewModel>
        initialValues={laundryGroupPriceListInternalViewModel}
        onSubmit={submitForm}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} id="laundryGroupPriceListInternalForm">
              <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                <Grid item xs={3}>
                  <DetailsTextField
                    value={getMachineTypeName(laundryGroupPriceListInternalViewModel.machineType, translate)}
                    label={translate('assignedMachineType')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label={translate('member.pricelist')}
                    type="text"
                    name="name"
                    fullWidth
                    disabled={submitting}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DetailsTextField
                    value={getDrumSizeLabel(priceList?.drumSize, priceList?.drumSizeUnit)}
                    label={translate('member.machinesize')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CurrencyAutoCompleteValidate name="currency" disabled={true} validate={required()} />
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
      <Divider className={sharedClasses.Divider} />

      <Form<AdjustPricesModel>
        initialValues={adjustPrices}
        onSubmit={onAdjustPricesHandler}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2} alignItems="center" className={sharedClasses.GridWithTextField}>
                <Grid item xs={4}>
                  <AmountTextFieldValidate
                    label={translate('standardPriceChange')}
                    name="regularPriceChange"
                    validate={validPositiveOrNegativeAmount()}
                    disabled={submitting}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <AmountTextFieldValidate
                    label={translate('happyHourPriceChange')}
                    name="happyHourPriceChange"
                    validate={validPositiveOrNegativeAmount()}
                    disabled={submitting}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button type="submit" variant="contained" color="primary">
                    {translate('button.adjustValues')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
      <Divider className={sharedClasses.Divider} />
      {displayTable}
    </>
  )
}
