/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface LaundryGroupStatisticsDataData
 */
export interface LaundryGroupStatisticsDataData {
    /**
     * 
     * @type {Currency}
     * @memberof LaundryGroupStatisticsDataData
     */
    currency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof LaundryGroupStatisticsDataData
     */
    readonly revenueAbsolute?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LaundryGroupStatisticsDataData
     */
    readonly revenuePercentageChange?: number | null;
}

/**
 * Check if a given object implements the LaundryGroupStatisticsDataData interface.
 */
export function instanceOfLaundryGroupStatisticsDataData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LaundryGroupStatisticsDataDataFromJSON(json: any): LaundryGroupStatisticsDataData {
    return LaundryGroupStatisticsDataDataFromJSONTyped(json, false);
}

export function LaundryGroupStatisticsDataDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupStatisticsDataData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': !exists(json, 'currency') ? undefined : CurrencyFromJSON(json['currency']),
        'revenueAbsolute': !exists(json, 'revenueAbsolute') ? undefined : json['revenueAbsolute'],
        'revenuePercentageChange': !exists(json, 'revenuePercentageChange') ? undefined : json['revenuePercentageChange'],
    };
}

export function LaundryGroupStatisticsDataDataToJSON(value?: LaundryGroupStatisticsDataData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': CurrencyToJSON(value.currency),
    };
}

