import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Paper, TableCell, TableRow } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, ManufacturerModel, ManufacturerModelsApi, ManufacturerReference } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { Data } from 'src/service/view-model/base/Data'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettings,
  getDefaultTableSettings,
  tableSettingsSort,
} from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'

const headCells: HeadCells[] = [
  {
    id: 'name',
    label: 'organization.name',
  },
  {
    id: 'manufacturer',
    label: 'manufacturer',
  },
  {
    id: 'productNumber',
    label: 'manufacturerProductNumber',
  },
]

interface ManufacturerModelTableSettings extends TableSettings {
  manufacturerId?: string
  productNumber?: string
}

interface Props {
  navigateToItem: (organizationId?: string) => void
}

export const ManufacturerModelTable: FC<Props> = ({ navigateToItem }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  const location = useLocation()

  const translate = useTranslate()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const manufacturerModelsApi = new ManufacturerModelsApi(httpConfiguration)

  // state
  const [data, setData] = useState<TableData<ManufacturerModel>>(DEFAULT_DATA)
  const defaultTableSettings: ManufacturerModelTableSettings = getDefaultTableSettings()
  const [tableSettings, setTableSettings] = useState<ManufacturerModelTableSettings>(defaultTableSettings)

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [manufacturers, setManufacturers] = useState<ManufacturerReference[]>([])

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)

  useEffect(() => {
    manufacturerModelsApi.manufacturersRefGet().then((data) => {
      const manufacturerReferences = data.map((item) => item as ManufacturerReference)
      setManufacturers(manufacturerReferences)
    })
  }, [])

  useEffect(() => {
    let active = true

    setLoading(true)

    manufacturerModelsApi
      .manufacturerModelsGet(
        tableSettings.size,
        tableSettings.page,
        tableSettingsSort(tableSettings),
        tableSettings.search,
        tableSettings.manufacturerId,
      )
      .then((pageableManufacturerModels) => {
        if (!active) return
        const data: Data<any> = pageableManufacturerModels as Data<any>
        updateTableSettingsFromData(data, tableSettings)
        setTableSettings(tableSettings)

        setData(mapData(data))
        setErrorMessage(null)
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [tableSettings])

  const getManufacturer = (manufacturer: ManufacturerReference) => {
    return manufacturer.name
  }

  // JSX
  const nonEmptyRows = data.data.map((item) => {
    return (
      <TableRow className={tableClasses.tableRow} key={item.id} onClick={navigateToItem.bind(this, item.id)}>
        <TableCell>
          <TruncatedTooltip maxChars={35} value={item.modelName} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={35} value={getManufacturer(item.manufacturer)} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={30} value={item.productNumber} />
        </TableCell>
      </TableRow>
    )
  })

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string): ReactElement | undefined => {
    let filter
    if (headCellId === 'manufacturer') {
      filter = (
        <TableFilterAutocomplete
          options={manufacturers}
          filter={'manufacturerId'}
          title={translate('filterByManufacturer')}
          label={translate('manufacturers')}
          labelFieldName={'name'}
          valueFieldName={'id'}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }
    return filter
  }

  return (
    <Paper elevation={0}>
      <TableSearchForm inputSearchValue={inputSearchValue} handleSearch={handleSearch} />

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        loading={loading}
        getFilter={getFilter}
        translate={translate}
      />
    </Paper>
  )
}
