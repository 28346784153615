import { ReportWashMasterApi } from 'src/service/backend/api'
import {
  ChartData,
  ChartScale,
  ChartType,
  Currency,
  DatatransPaymentMethod,
  MachineCategory,
  PaymentMethod,
  UsageChartFactType,
  UsageChartGroupBy,
} from 'src/service/backend/api/models'
import { InitOverrideFunction } from 'src/service/backend/api/runtime'

const usageByMachineCategoryLastMonth =
  '{"title":"Usages","xValues":["April 2023","May 2023"],"chartType":"BAR","datasets":[{"label":"WA","yUnit":"Count","lineType":null,"color":null,"yValues":[24,4]},{"label":"WT","yUnit":"Count","lineType":null,"color":null,"yValues":[160,24]}]}'
const usageByMachineCategoryLast6Months =
  '{"title":"Usages","xValues":["November 2022","December 2022","January 2023","February 2023","March 2023","April 2023","May 2023"],"chartType":"BAR","datasets":[{"label":"WA","yUnit":"Count","lineType":null,"color":null,"yValues":[4,6,14,9,5,24,4]},{"label":"WT","yUnit":"Count","lineType":null,"color":null,"yValues":[73,1,86,28,21,160,24]}]}'
const usageByMachineCategoryLastYear =
  '{"title":"Usages","xValues":["May 2022","June 2022","July 2022","August 2022","September 2022","October 2022","November 2022","December 2022","January 2023","February 2023","March 2023","April 2023","May 2023"],"chartType":"BAR","datasets":[{"label":"RLWT","yUnit":"Count","lineType":null,"color":null,"yValues":[0,25,2,0,7,0,0,0,0,0,0,0,0]},{"label":"WA","yUnit":"Count","lineType":null,"color":null,"yValues":[8,17,23,3,6,2,4,6,14,9,5,24,4]},{"label":"WT","yUnit":"Count","lineType":null,"color":null,"yValues":[28,37,26,5,11,17,73,1,86,28,21,160,24]}]}'

const usageTotalLast6Months =
  '{"title":"Amount","xValues":["May 2022","June 2022","July 2022","August 2022","September 2022","October 2022","November 2022","December 2022","January 2023","February 2023","March 2023","April 2023","May 2023"],"chartType":"BAR","datasets":[{"label":"total (CHF)","yUnit":"Amount Sum","lineType":null,"color":null,"yValues":[18.90,52.10,67.50,11.40,12.80,3.50,50.40,2.90,13.40,1.50,0.60,3.50,0.10]},{"label":"total (EUR)","yUnit":"Amount Sum","lineType":null,"color":null,"yValues":[7.50,0,0,0,0,0,2.20,0,26.00,2.90,2.10,35.70,6.10]}]}'
const usageTotalLastMonth =
  '{"title":"Amount","xValues":["April 2023","May 2023"],"chartType":"BAR","datasets":[{"label":"total (CHF)","yUnit":"Amount Sum","lineType":null,"color":null,"yValues":[3.50,0.10]},{"label":"total (EUR)","yUnit":"Amount Sum","lineType":null,"color":null,"yValues":[35.70,6.10]}]}'
const usageTotalLastYear =
  '{"title":"Amount","xValues":["May 2022","June 2022","July 2022","August 2022","September 2022","October 2022","November 2022","December 2022","January 2023","February 2023","March 2023","April 2023","May 2023"],"chartType":"BAR","datasets":[{"label":"total (CHF)","yUnit":"Amount Sum","lineType":null,"color":null,"yValues":[18.90,52.10,67.50,11.40,12.80,3.50,50.40,2.90,13.40,1.50,0.60,3.50,0.10]},{"label":"total (EUR)","yUnit":"Amount Sum","lineType":null,"color":null,"yValues":[7.50,0,0,0,0,0,2.20,0,26.00,2.90,2.10,35.70,6.10]}]}'

const mocked = (data: ChartData): Promise<ChartData> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data)
    }, 400)
  })
}

export class ReportWashMasterApiMock extends ReportWashMasterApi {
  reportWmUsagesChartGet(
    groupBy: UsageChartGroupBy,
    factType: UsageChartFactType,
    scale: ChartScale,
    startTimeFrom: Date,
    startTimeTo: Date,
    chartType?: ChartType,
    organizationId?: string,
    laundryGroupId?: string,
    laundryId?: string,
    machineId?: string,
    laundryUserId?: string,
    currency?: Currency,
    paymentMethod?: PaymentMethod,
    datatransPaymentMethod?: DatatransPaymentMethod,
    machineCategory?: MachineCategory,
    initOverrides?: RequestInit | InitOverrideFunction,
  ): Promise<ChartData> {
    let json = ''

    const moreMonths = startTimeFrom.getMonth() !== new Date().getMonth() - 1

    if (laundryGroupId) {
      if (groupBy === UsageChartGroupBy.TOTAL) {
        json =
          '{"title":"Amount","xValues":["April 2023","May 2023"],"chartType":"BAR","datasets":[{"label":"total (CHF)","yUnit":"Amount Sum","lineType":null,"color":null,"yValues":[3.50,0.10]},{"label":"total (EUR)","yUnit":"Amount Sum","lineType":null,"color":null,"yValues":[35.70,6.10]}]}'
      } else {
        json =
          '{"title":"Amount","xValues":["April 2023","May 2023"],"chartType":"DOUGHNUT","datasets":[{"label":"Demo Laundry Room (CHF)","yUnit":"Amount Sum","lineType":null,"color":null,"yValues":[160.20,0]}]}'
      }
    } else {
      if (groupBy === UsageChartGroupBy.MACHINE_CATEGORY) {
        if (moreMonths) {
          json = usageByMachineCategoryLast6Months
        } else {
          json = usageByMachineCategoryLastMonth
        }
      } else if (groupBy === UsageChartGroupBy.TOTAL) {
        if (moreMonths) {
          json = usageTotalLast6Months
        } else {
          json = usageTotalLastMonth
        }
      }
    }

    return mocked(JSON.parse(json) as ChartData)
  }
}
