import { Permission, RoleDetail } from 'src/service/backend/api'

export interface RolePermissionViewModel {
  [key: string]: CheckboxData
}

export type CheckboxData = {
  [key: string]: boolean
}

const toPermissionCheckbox = (permissions: Permission[]): CheckboxData => {
  const initialPermissionCheckbox: CheckboxData = Object.values(Permission)
    .map((permission) => ({ [permission]: false }) as CheckboxData)
    .reduce((acc, currVal) => Object.assign(acc, currVal), {})

  return permissions.reduce((acc, currValue) => Object.assign(acc, { [currValue]: true }), initialPermissionCheckbox)
}

export const toViewModel = (roleDetails: RoleDetail[]): RolePermissionViewModel => {
  return roleDetails.reduce(
    (acc, currVal) => Object.assign(acc, { [currVal.role.roleKey]: toPermissionCheckbox(currVal.permissions) }),
    {},
  )
}

const toPermissions = (checkboxData: CheckboxData): Permission[] => {
  return Object.entries(checkboxData)
    .filter(([, value]) => value)
    .map(([key]) => key as Permission)
}

export const toRoleDetailViewModel = (roleKey: string, permissions: CheckboxData): RoleDetail => {
  return {
    role: {
      roleKey,
    },
    permissions: toPermissions(permissions),
  }
}
