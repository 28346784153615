import React, { ReactElement } from 'react'
import { Icon } from '@mdi/react'
import { Box, Typography } from '@mui/material'
import { mdiRepeat } from '@mdi/js'
import { useTranslate } from 'src/i18n/useMessageSource'
import { calendarLegendStyles } from 'src/ui-shared/calendar/Calendar.style'

export const MachineCalendarLegend = (): ReactElement => {
  const { classes } = calendarLegendStyles()
  const translate = useTranslate()

  return (
    <Box className={classes.boxMargin} display="flex" flexDirection="row" alignItems="center">
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box display="span" className={classes.openSlot}></Box>
        <Typography className={classes.textStyle} variant="body2">
          {translate('freeSlot')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" className={classes.legendItemPadding}>
        <Box className={classes.adHocSlot}></Box>
        <Typography className={classes.textStyle} variant="body2">
          {translate('adHocSlot')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" className={classes.legendItemPadding}>
        <Box className={classes.machineUsage}></Box>
        <Typography className={classes.textStyle} variant="body2">
          {translate('machineUsage')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" className={classes.legendItemPadding}>
        <Box className={classes.userReservation}></Box>
        <Typography className={classes.textStyle} variant="body2">
          {translate('userReservation')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" className={classes.legendItemPadding}>
        <Box className={classes.blockedSlot}></Box>
        <Typography className={classes.textStyle} variant="body2">
          {translate('blockedSlot')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" className={classes.legendItemPadding}>
        <Icon path={mdiRepeat} size={0.85} />
        <Typography className={classes.textStyle} variant="body2">
          {translate('recurring')}
        </Typography>
      </Box>
    </Box>
  )
}
