/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UsageChartFactType {
    COUNT = 'COUNT',
    AMOUNT_SUM = 'AMOUNT_SUM'
}


export function UsageChartFactTypeFromJSON(json: any): UsageChartFactType {
    return UsageChartFactTypeFromJSONTyped(json, false);
}

export function UsageChartFactTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsageChartFactType {
    return json as UsageChartFactType;
}

export function UsageChartFactTypeToJSON(value?: UsageChartFactType | null): any {
    return value as any;
}

