import { Invitation, Member, MemberRolesUpdate, OrganizationReference, Role } from 'src/service/backend/api/models'

export interface MemberInvitationViewModel {
  email: string
  role: string
  organizationRef?: OrganizationReference | string
}

export const createMemberInvitationViewModel = (): MemberInvitationViewModel => {
  return {
    email: '',
    role: '',
    organizationRef: '',
  }
}

export const mapMemberInvitationViewModelToInvitation = (
  memberInvitationViewModel: MemberInvitationViewModel,
): Invitation => {
  const organization = memberInvitationViewModel.organizationRef as OrganizationReference

  // TODO pst: check, maybe not working
  const memberRole: Role = {
    roleKey: memberInvitationViewModel.role,
  }

  const invitation: Invitation = {
    id: '',
    token: '',
    validity: new Date(),
    expired: false,
    email: memberInvitationViewModel.email,
    organization: organization,
    proposedMemberRole: memberRole,
  }

  return invitation
}

export interface MemberViewModel {
  firstName: string
  lastName: string
  email: string
  locale: string
  blocked: boolean
  organization: string
  roles: string[]
  memberSince: Date
}

export const mapMemberToMemberViewModel = (member: Member): MemberViewModel => {
  return {
    firstName: member.backendUser.firstName,
    lastName: member.backendUser.lastName,
    email: member.backendUser.email,
    locale: member.backendUser.locale,
    blocked: member.backendUser.blocked,
    organization: member.organization.name || member.organization.id,
    // TODO pst: check, probably not working
    roles: getMemberRolesNames(member.memberRoles),
    memberSince: member.memberSince,
  }
}

export const createMemberViewModel = (member?: Member): MemberViewModel => {
  if (member) {
    return mapMemberToMemberViewModel(member)
  }
  return {
    firstName: '',
    lastName: '',
    email: '',
    locale: '',
    blocked: false,
    organization: '',
    roles: [],
    memberSince: new Date(),
  }
}

function mapRoles(roles: Array<string>): Array<Role> {
  const resultRoles: Array<Role> = roles.map((role: string) => {
    const r: Role = {
      roleKey: role,
      name: role,
    }
    return r
  })
  return resultRoles
}

export function mapMemberRolesUpdate(newRoles: Array<string>): MemberRolesUpdate {
  const mappedRoles = mapRoles(newRoles)

  const result: MemberRolesUpdate = {
    memberRoles: mappedRoles,
  }

  return result
}

export function getMemberRolesName(member: Member): string {
  // TODO pst: check for translation of role name
  return getMemberRolesNames(member.memberRoles).join(', ')
}

export function getMemberRolesNames(roles: Role[]): string[] {
  // TODO pst: check for translation of role name
  return roles.map((role) => role.name || role.roleKey)
}

export function getInvitationRoleName(invitation: Invitation): string {
  // TODO dav: check for translation of role name
  const name = invitation.proposedMemberRole.name
  return name ? name : ''
}
