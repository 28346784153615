/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IssueCategoryReference,
  IssueEntry,
  PageableIssueEntries,
} from '../models';
import {
    IssueCategoryReferenceFromJSON,
    IssueCategoryReferenceToJSON,
    IssueEntryFromJSON,
    IssueEntryToJSON,
    PageableIssueEntriesFromJSON,
    PageableIssueEntriesToJSON,
} from '../models';

export interface IssuesGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
}

export interface IssuesIssueIdActionKeyPutRequest {
    issueId: string;
    actionKey: string;
    issueEntry?: IssueEntry;
}

export interface IssuesIssueIdGetRequest {
    issueId: string;
}

export interface IssuesPostRequest {
    issueEntry?: IssueEntry;
}

/**
 * 
 */
export class IssuesApi extends runtime.BaseAPI {

    /**
     * List predefined issue category reference
     */
    async issuesCategoriesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IssueCategoryReference>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/issues/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IssueCategoryReferenceFromJSON));
    }

    /**
     * List predefined issue category reference
     */
    async issuesCategoriesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IssueCategoryReference>> {
        const response = await this.issuesCategoriesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * List issues / service requests
     */
    async issuesGetRaw(requestParameters: IssuesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableIssueEntries>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling issuesGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling issuesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/issues`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableIssueEntriesFromJSON(jsonValue));
    }

    /**
     * List issues / service requests
     */
    async issuesGet(startTimeFrom: Date, startTimeTo: Date, size?: number, page?: number, sort?: string, search?: string, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableIssueEntries> {
        const response = await this.issuesGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, size: size, page: page, sort: sort, search: search, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId }, initOverrides);
        return await response.value();
    }

    /**
     * Update a issue / service request
     */
    async issuesIssueIdActionKeyPutRaw(requestParameters: IssuesIssueIdActionKeyPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IssueEntry>> {
        if (requestParameters.issueId === null || requestParameters.issueId === undefined) {
            throw new runtime.RequiredError('issueId','Required parameter requestParameters.issueId was null or undefined when calling issuesIssueIdActionKeyPut.');
        }

        if (requestParameters.actionKey === null || requestParameters.actionKey === undefined) {
            throw new runtime.RequiredError('actionKey','Required parameter requestParameters.actionKey was null or undefined when calling issuesIssueIdActionKeyPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/issues/{issueId}/{actionKey}`.replace(`{${"issueId"}}`, encodeURIComponent(String(requestParameters.issueId))).replace(`{${"actionKey"}}`, encodeURIComponent(String(requestParameters.actionKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IssueEntryToJSON(requestParameters.issueEntry),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueEntryFromJSON(jsonValue));
    }

    /**
     * Update a issue / service request
     */
    async issuesIssueIdActionKeyPut(issueId: string, actionKey: string, issueEntry?: IssueEntry, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IssueEntry> {
        const response = await this.issuesIssueIdActionKeyPutRaw({ issueId: issueId, actionKey: actionKey, issueEntry: issueEntry }, initOverrides);
        return await response.value();
    }

    /**
     * Get a single issue / service request
     */
    async issuesIssueIdGetRaw(requestParameters: IssuesIssueIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IssueEntry>> {
        if (requestParameters.issueId === null || requestParameters.issueId === undefined) {
            throw new runtime.RequiredError('issueId','Required parameter requestParameters.issueId was null or undefined when calling issuesIssueIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/issues/{issueId}`.replace(`{${"issueId"}}`, encodeURIComponent(String(requestParameters.issueId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueEntryFromJSON(jsonValue));
    }

    /**
     * Get a single issue / service request
     */
    async issuesIssueIdGet(issueId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IssueEntry> {
        const response = await this.issuesIssueIdGetRaw({ issueId: issueId }, initOverrides);
        return await response.value();
    }

    /**
     * Create an issue / service request
     */
    async issuesPostRaw(requestParameters: IssuesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IssueEntry>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/issues`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueEntryToJSON(requestParameters.issueEntry),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueEntryFromJSON(jsonValue));
    }

    /**
     * Create an issue / service request
     */
    async issuesPost(issueEntry?: IssueEntry, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IssueEntry> {
        const response = await this.issuesPostRaw({ issueEntry: issueEntry }, initOverrides);
        return await response.value();
    }

}
