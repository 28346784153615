import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Icon } from '@mdi/react'
import { Divider, Grid, Hidden, Paper } from '@mui/material'
import { GetApp } from '@mui/icons-material'
import { mdiWeight } from '@mdi/js'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { ApiResponse, Configuration, ReportUsagesApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getRemoveAcceptHeaderInit } from 'src/service/backend/http/HttpUtils'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { downloadFile, getDownloadFilename } from 'src/service/utils/FileDownloadUtils'
import { getLastMonth, removeTimeFromDate, removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { StyledTab } from 'src/ui-shared/base/tab-panel/StyledTab'
import { StyledTabs } from 'src/ui-shared/base/tab-panel/StyledTabs'
import { TabPanel } from 'src/ui-shared/base/tab-panel/TabPanel'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ArticleIcon, InsertChartOutlinedIcon } from 'src/ui-shared/icons/Icons'
import { getDefaultTableSettings, withOrganization } from 'src/ui-shared/table/Table.const'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { ProgramUsageChart } from 'src/ui/page/sm/program-usage/list/ProgramUsageChart'
import { ProgramUsageTab } from 'src/ui/page/sm/program-usage/list/ProgramUsageTab'
import { ProgramUsageTable, ProgramUsageTableSettings } from 'src/ui/page/sm/program-usage/list/ProgramUsageTable'

const dateNow = removeTimeFromDateEndOfDay(new Date())
const dateLastMonth = removeTimeFromDate(getLastMonth(dateNow))

export const ProgramUsageListPage = (): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()
  const navigate = useNavigate()
  const appId = useAppId()
  const activeOrganization = useActiveOrganization()

  const showSnackbar = useShowSnackbar()
  const { tabNameParam } = useParams()
  const tabName = tabNameParam ? getEnumFromString(tabNameParam, ProgramUsageTab) : ProgramUsageTab.DATA

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportApi = new ReportUsagesApi(httpConfiguration)

  const [activeTab, setActiveTab] = useState<ProgramUsageTab>(ProgramUsageTab.DATA)
  const defaultTableSettings: ProgramUsageTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'startTime',
    orderDir: 'desc',
    startTimeFrom: dateLastMonth.toISOString(),
    startTimeTo: dateNow.toISOString(),
  }
  const [tableSettings, setTableSettings] = useState<ProgramUsageTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )

  // load data
  useEffect(() => {
    setActiveTab(tabName)
  }, [])

  const handleExportButtonClick = async () => {
    if (!tableSettings.startTimeFrom || !tableSettings.startTimeTo) {
      return
    }
    const dateStartTimeFrom: Date = new Date(tableSettings.startTimeFrom)
    const dateStartTimeTo: Date = new Date(tableSettings.startTimeTo)

    let filename: string | null
    reportApi
      .reportSmUsagesCsvGetRaw(
        {
          startTimeFrom: dateStartTimeFrom,
          startTimeTo: dateStartTimeTo,
          organizationId: tableSettings.organizationId,
          laundryGroupId: tableSettings.laundryGroupId,
          laundryId: tableSettings.laundryId,
          machineId: tableSettings.machineId,
          machineCategory: tableSettings.machineCategory,
        },
        getRemoveAcceptHeaderInit(),
      )
      .then(async (response: ApiResponse<Blob>) => {
        filename = getDownloadFilename(response.raw)
        return response.value()
      })
      .then((blob: Blob) => {
        downloadFile(blob, filename)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  // handle events
  const handleTabChange = (event: React.SyntheticEvent, newValue: ProgramUsageTab) => {
    const pathToNavigate = `/${appId}/program-usages/view/${newValue}`
    navigate(pathToNavigate, { replace: true })
    setActiveTab(newValue)
  }

  return (
    <>
      <ScreenLayout
        title={translate('programUsages')}
        actionsWidth={170}
        actions={
          <>
            {activeTab === 'data' ? (
              <ListingButton
                onClick={handleExportButtonClick}
                variant="outlined"
                color="primary"
                startIcon={<GetApp />}
                disabled={!tableSettings.startTimeFrom || !tableSettings.startTimeTo}
              >
                {translate('exportCSV')}
              </ListingButton>
            ) : null}
          </>
        }
      >
        <Paper elevation={0}>
          <Grid container className={sharedClasses.TabsContainer}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <StyledTabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={handleTabChange}
              >
                <StyledTab
                  icon={<ArticleIcon />}
                  iconPosition={'start'}
                  value={ProgramUsageTab.DATA}
                  label={<Hidden mdDown>{translate('data')}</Hidden>}
                />
                <StyledTab
                  icon={<InsertChartOutlinedIcon />}
                  iconPosition={'start'}
                  value={ProgramUsageTab.DASHBOARD}
                  label={<Hidden mdDown>{translate('dashboard')}</Hidden>}
                />
                <StyledTab
                  icon={<Icon path={mdiWeight} size={1} />}
                  iconPosition={'start'}
                  value={ProgramUsageTab.OUTPUT}
                  label={<Hidden mdDown>{translate('output')}</Hidden>}
                />
              </StyledTabs>
            </Grid>
          </Grid>
          <Divider />
          <TabPanel value={activeTab} index={ProgramUsageTab.DATA}>
            <ProgramUsageTable
              tableSettings={tableSettings}
              setTableSettings={setTableSettings}
              defaultTableSettings={defaultTableSettings}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={ProgramUsageTab.DASHBOARD}>
            <ProgramUsageChart />
          </TabPanel>
          <TabPanel value={activeTab} index={ProgramUsageTab.OUTPUT}>
            <ProgramUsageChart isOutputChart />
          </TabPanel>
        </Paper>
      </ScreenLayout>
    </>
  )
}
