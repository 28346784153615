import { ChartData, ChartScale, TimeseriesAnnotations } from 'src/service/backend/api'
import { getLastMonth, removeTimeFromDate, removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'
import { DataSettings } from 'src/service/view-model/base/DataSettings'

export interface ChartSettings extends DataSettings {
  chartScale: ChartScale
  startDateFrom: Date
  startDateTo: Date
}

export type ChartSettingsWithoutScale = Pick<ChartSettings, 'startDateFrom' | 'startDateTo'>

export interface ChartSettingsWithOrganization extends ChartSettings {
  organizationId?: string
}

export const DEFAULT_CHART_TIME_RANGE_SETTINGS: ChartSettingsWithoutScale = {
  startDateFrom: removeTimeFromDate(getLastMonth(new Date())),
  startDateTo: removeTimeFromDateEndOfDay(new Date()),
}

export const DEFAULT_CHART_SETTINGS: ChartSettings = {
  chartScale: ChartScale.MONTH,
  startDateFrom: removeTimeFromDate(getLastMonth(new Date())),
  startDateTo: removeTimeFromDateEndOfDay(new Date()),
}

export interface ChartSeriesDataset {
  valueState?: string
  data: ChartSeriesDataApex
}

export interface ChartSeriesDataApex {
  x: string
  y: (number | undefined)[]
}

export interface ChartSeriesDatasetApex {
  name: string
  data: Array<ChartSeriesDataApex>
  color: string
}

export interface ChartDataWithAnnotations extends ChartData {
  annotations?: TimeseriesAnnotations
}
