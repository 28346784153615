/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Currency,
  InvoiceCorrectionRequest,
  InvoiceSendEmailRequest,
  LaundryGroupInvoice,
  PageableLaundryGroupInvoices,
} from '../models';
import {
    CurrencyFromJSON,
    CurrencyToJSON,
    InvoiceCorrectionRequestFromJSON,
    InvoiceCorrectionRequestToJSON,
    InvoiceSendEmailRequestFromJSON,
    InvoiceSendEmailRequestToJSON,
    LaundryGroupInvoiceFromJSON,
    LaundryGroupInvoiceToJSON,
    PageableLaundryGroupInvoicesFromJSON,
    PageableLaundryGroupInvoicesToJSON,
} from '../models';

export interface InvoicesErpGenerateBillingMonthCurrencyPostRequest {
    billingMonth: string;
    currency: Currency;
}

export interface InvoicesGenerateBillingMonthPostRequest {
    billingMonth: string;
}

export interface InvoicesLaundryGroupIdBillingMonthCorrectionPostRequest {
    laundryGroupId: string;
    billingMonth: string;
    invoiceCorrectionRequest?: InvoiceCorrectionRequest;
}

export interface InvoicesLaundryGroupIdBillingMonthGeneratePostRequest {
    laundryGroupId: string;
    billingMonth: string;
}

export interface InvoicesLaundryGroupIdBillingMonthGetRequest {
    laundryGroupId: string;
    billingMonth: string;
}

export interface InvoicesLaundryGroupIdBillingMonthPdfGetRequest {
    laundryGroupId: string;
    billingMonth: string;
}

export interface InvoicesLaundryGroupIdBillingMonthPdfpreviewGetRequest {
    laundryGroupId: string;
    billingMonth: string;
}

export interface InvoicesLaundryGroupIdBillingMonthSendEmailPostRequest {
    laundryGroupId: string;
    billingMonth: string;
    invoiceSendEmailRequest?: InvoiceSendEmailRequest;
}

export interface InvoicesLaundryGroupIdGetRequest {
    laundryGroupId: string;
}

export interface InvoicesProcessPendingBillingMonthPostRequest {
    billingMonth: string;
}

export interface InvoicesReportMonthlyBillingMonthPostRequest {
    billingMonth: string;
    invoiceSendEmailRequest?: InvoiceSendEmailRequest;
}

export interface InvoicesReportMonthlySalesBillingMonthPostRequest {
    billingMonth: string;
    invoiceSendEmailRequest?: InvoiceSendEmailRequest;
}

/**
 * 
 */
export class InvoiceApi extends runtime.BaseAPI {

    /**
     * Generate the monthly CSV file for the ERP system
     */
    async invoicesErpGenerateBillingMonthCurrencyPostRaw(requestParameters: InvoicesErpGenerateBillingMonthCurrencyPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billingMonth === null || requestParameters.billingMonth === undefined) {
            throw new runtime.RequiredError('billingMonth','Required parameter requestParameters.billingMonth was null or undefined when calling invoicesErpGenerateBillingMonthCurrencyPost.');
        }

        if (requestParameters.currency === null || requestParameters.currency === undefined) {
            throw new runtime.RequiredError('currency','Required parameter requestParameters.currency was null or undefined when calling invoicesErpGenerateBillingMonthCurrencyPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invoices/erp/generate/{billingMonth}/{currency}`.replace(`{${"billingMonth"}}`, encodeURIComponent(String(requestParameters.billingMonth))).replace(`{${"currency"}}`, encodeURIComponent(String(requestParameters.currency))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Generate the monthly CSV file for the ERP system
     */
    async invoicesErpGenerateBillingMonthCurrencyPost(billingMonth: string, currency: Currency, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invoicesErpGenerateBillingMonthCurrencyPostRaw({ billingMonth: billingMonth, currency: currency }, initOverrides);
    }

    /**
     * - only valid for current or future billingMonths  - if invoice existing and State Pending, modify positions, leave existing corrections  - if invoice not existing create new one  - aggregates data for invoices of billingMonth  - invoice state = pending
     * Generate or Update the invoices of all Laundry Groups
     */
    async invoicesGenerateBillingMonthPostRaw(requestParameters: InvoicesGenerateBillingMonthPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billingMonth === null || requestParameters.billingMonth === undefined) {
            throw new runtime.RequiredError('billingMonth','Required parameter requestParameters.billingMonth was null or undefined when calling invoicesGenerateBillingMonthPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invoices/generate/{billingMonth}`.replace(`{${"billingMonth"}}`, encodeURIComponent(String(requestParameters.billingMonth))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * - only valid for current or future billingMonths  - if invoice existing and State Pending, modify positions, leave existing corrections  - if invoice not existing create new one  - aggregates data for invoices of billingMonth  - invoice state = pending
     * Generate or Update the invoices of all Laundry Groups
     */
    async invoicesGenerateBillingMonthPost(billingMonth: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invoicesGenerateBillingMonthPostRaw({ billingMonth: billingMonth }, initOverrides);
    }

    /**
     * contains body with correction amount and additionalInfo, only valid for upcoming billingMonth periods or PENDING invoices  if invoice not yet existing, new invoice is created (same as /generate)
     * Update the Invoice with a correction, only valid for upcoming billingMonths (current and following months) or when InvoiceState.PENDING
     */
    async invoicesLaundryGroupIdBillingMonthCorrectionPostRaw(requestParameters: InvoicesLaundryGroupIdBillingMonthCorrectionPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling invoicesLaundryGroupIdBillingMonthCorrectionPost.');
        }

        if (requestParameters.billingMonth === null || requestParameters.billingMonth === undefined) {
            throw new runtime.RequiredError('billingMonth','Required parameter requestParameters.billingMonth was null or undefined when calling invoicesLaundryGroupIdBillingMonthCorrectionPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invoices/{laundryGroupId}/{billingMonth}/correction`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))).replace(`{${"billingMonth"}}`, encodeURIComponent(String(requestParameters.billingMonth))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceCorrectionRequestToJSON(requestParameters.invoiceCorrectionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * contains body with correction amount and additionalInfo, only valid for upcoming billingMonth periods or PENDING invoices  if invoice not yet existing, new invoice is created (same as /generate)
     * Update the Invoice with a correction, only valid for upcoming billingMonths (current and following months) or when InvoiceState.PENDING
     */
    async invoicesLaundryGroupIdBillingMonthCorrectionPost(laundryGroupId: string, billingMonth: string, invoiceCorrectionRequest?: InvoiceCorrectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invoicesLaundryGroupIdBillingMonthCorrectionPostRaw({ laundryGroupId: laundryGroupId, billingMonth: billingMonth, invoiceCorrectionRequest: invoiceCorrectionRequest }, initOverrides);
    }

    /**
     * - only valid for current or future billingMonths  - if invoice existing and State Pending, modify positions, leave existing corrections  - if invoice not existing create new one  - aggregates data for invoices of billingMonth  - invoice state = pending
     * Generate or Update an Invoice with latest settlement Data, created in Pending State (only valid for current or future billingMonths)
     */
    async invoicesLaundryGroupIdBillingMonthGeneratePostRaw(requestParameters: InvoicesLaundryGroupIdBillingMonthGeneratePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling invoicesLaundryGroupIdBillingMonthGeneratePost.');
        }

        if (requestParameters.billingMonth === null || requestParameters.billingMonth === undefined) {
            throw new runtime.RequiredError('billingMonth','Required parameter requestParameters.billingMonth was null or undefined when calling invoicesLaundryGroupIdBillingMonthGeneratePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invoices/{laundryGroupId}/{billingMonth}/generate`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))).replace(`{${"billingMonth"}}`, encodeURIComponent(String(requestParameters.billingMonth))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * - only valid for current or future billingMonths  - if invoice existing and State Pending, modify positions, leave existing corrections  - if invoice not existing create new one  - aggregates data for invoices of billingMonth  - invoice state = pending
     * Generate or Update an Invoice with latest settlement Data, created in Pending State (only valid for current or future billingMonths)
     */
    async invoicesLaundryGroupIdBillingMonthGeneratePost(laundryGroupId: string, billingMonth: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invoicesLaundryGroupIdBillingMonthGeneratePostRaw({ laundryGroupId: laundryGroupId, billingMonth: billingMonth }, initOverrides);
    }

    /**
     * Get invoice of billingMonth
     */
    async invoicesLaundryGroupIdBillingMonthGetRaw(requestParameters: InvoicesLaundryGroupIdBillingMonthGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupInvoice>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling invoicesLaundryGroupIdBillingMonthGet.');
        }

        if (requestParameters.billingMonth === null || requestParameters.billingMonth === undefined) {
            throw new runtime.RequiredError('billingMonth','Required parameter requestParameters.billingMonth was null or undefined when calling invoicesLaundryGroupIdBillingMonthGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invoices/{laundryGroupId}/{billingMonth}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))).replace(`{${"billingMonth"}}`, encodeURIComponent(String(requestParameters.billingMonth))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupInvoiceFromJSON(jsonValue));
    }

    /**
     * Get invoice of billingMonth
     */
    async invoicesLaundryGroupIdBillingMonthGet(laundryGroupId: string, billingMonth: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupInvoice> {
        const response = await this.invoicesLaundryGroupIdBillingMonthGetRaw({ laundryGroupId: laundryGroupId, billingMonth: billingMonth }, initOverrides);
        return await response.value();
    }

    /**
     * Download a Stored Invoice PDF
     */
    async invoicesLaundryGroupIdBillingMonthPdfGetRaw(requestParameters: InvoicesLaundryGroupIdBillingMonthPdfGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling invoicesLaundryGroupIdBillingMonthPdfGet.');
        }

        if (requestParameters.billingMonth === null || requestParameters.billingMonth === undefined) {
            throw new runtime.RequiredError('billingMonth','Required parameter requestParameters.billingMonth was null or undefined when calling invoicesLaundryGroupIdBillingMonthPdfGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invoices/{laundryGroupId}/{billingMonth}/pdf`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))).replace(`{${"billingMonth"}}`, encodeURIComponent(String(requestParameters.billingMonth))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download a Stored Invoice PDF
     */
    async invoicesLaundryGroupIdBillingMonthPdfGet(laundryGroupId: string, billingMonth: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.invoicesLaundryGroupIdBillingMonthPdfGetRaw({ laundryGroupId: laundryGroupId, billingMonth: billingMonth }, initOverrides);
        return await response.value();
    }

    /**
     * - when iban missing -> PDF contains \'MISSING Data\' replacement  - delivers pdf from data without saving  - if invoice not existing  new one is created or updated (/generate)  - generate pdf (not saved) and return it
     * Download a Preview Invoice
     */
    async invoicesLaundryGroupIdBillingMonthPdfpreviewGetRaw(requestParameters: InvoicesLaundryGroupIdBillingMonthPdfpreviewGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling invoicesLaundryGroupIdBillingMonthPdfpreviewGet.');
        }

        if (requestParameters.billingMonth === null || requestParameters.billingMonth === undefined) {
            throw new runtime.RequiredError('billingMonth','Required parameter requestParameters.billingMonth was null or undefined when calling invoicesLaundryGroupIdBillingMonthPdfpreviewGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invoices/{laundryGroupId}/{billingMonth}/pdfpreview`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))).replace(`{${"billingMonth"}}`, encodeURIComponent(String(requestParameters.billingMonth))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * - when iban missing -> PDF contains \'MISSING Data\' replacement  - delivers pdf from data without saving  - if invoice not existing  new one is created or updated (/generate)  - generate pdf (not saved) and return it
     * Download a Preview Invoice
     */
    async invoicesLaundryGroupIdBillingMonthPdfpreviewGet(laundryGroupId: string, billingMonth: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.invoicesLaundryGroupIdBillingMonthPdfpreviewGetRaw({ laundryGroupId: laundryGroupId, billingMonth: billingMonth }, initOverrides);
        return await response.value();
    }

    /**
     * - only when InvoiceState.successful
     * Send the Invoice via E-Mail to laundryGroup.email address or when optional field email supplied to this address
     */
    async invoicesLaundryGroupIdBillingMonthSendEmailPostRaw(requestParameters: InvoicesLaundryGroupIdBillingMonthSendEmailPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling invoicesLaundryGroupIdBillingMonthSendEmailPost.');
        }

        if (requestParameters.billingMonth === null || requestParameters.billingMonth === undefined) {
            throw new runtime.RequiredError('billingMonth','Required parameter requestParameters.billingMonth was null or undefined when calling invoicesLaundryGroupIdBillingMonthSendEmailPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invoices/{laundryGroupId}/{billingMonth}/send-email`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))).replace(`{${"billingMonth"}}`, encodeURIComponent(String(requestParameters.billingMonth))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceSendEmailRequestToJSON(requestParameters.invoiceSendEmailRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * - only when InvoiceState.successful
     * Send the Invoice via E-Mail to laundryGroup.email address or when optional field email supplied to this address
     */
    async invoicesLaundryGroupIdBillingMonthSendEmailPost(laundryGroupId: string, billingMonth: string, invoiceSendEmailRequest?: InvoiceSendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invoicesLaundryGroupIdBillingMonthSendEmailPostRaw({ laundryGroupId: laundryGroupId, billingMonth: billingMonth, invoiceSendEmailRequest: invoiceSendEmailRequest }, initOverrides);
    }

    /**
     * Deliver a list of available invoices / billingMonths
     */
    async invoicesLaundryGroupIdGetRaw(requestParameters: InvoicesLaundryGroupIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableLaundryGroupInvoices>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling invoicesLaundryGroupIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invoices/{laundryGroupId}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableLaundryGroupInvoicesFromJSON(jsonValue));
    }

    /**
     * Deliver a list of available invoices / billingMonths
     */
    async invoicesLaundryGroupIdGet(laundryGroupId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableLaundryGroupInvoices> {
        const response = await this.invoicesLaundryGroupIdGetRaw({ laundryGroupId: laundryGroupId }, initOverrides);
        return await response.value();
    }

    /**
     * Reprocess all pending invoices of billing month
     */
    async invoicesProcessPendingBillingMonthPostRaw(requestParameters: InvoicesProcessPendingBillingMonthPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billingMonth === null || requestParameters.billingMonth === undefined) {
            throw new runtime.RequiredError('billingMonth','Required parameter requestParameters.billingMonth was null or undefined when calling invoicesProcessPendingBillingMonthPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invoices/process-pending/{billingMonth}`.replace(`{${"billingMonth"}}`, encodeURIComponent(String(requestParameters.billingMonth))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reprocess all pending invoices of billing month
     */
    async invoicesProcessPendingBillingMonthPost(billingMonth: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invoicesProcessPendingBillingMonthPostRaw({ billingMonth: billingMonth }, initOverrides);
    }

    /**
     * Send the finance report via email to the predefined mail address or when the optional field email is supplied to this address
     */
    async invoicesReportMonthlyBillingMonthPostRaw(requestParameters: InvoicesReportMonthlyBillingMonthPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billingMonth === null || requestParameters.billingMonth === undefined) {
            throw new runtime.RequiredError('billingMonth','Required parameter requestParameters.billingMonth was null or undefined when calling invoicesReportMonthlyBillingMonthPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invoices/report/monthly/{billingMonth}`.replace(`{${"billingMonth"}}`, encodeURIComponent(String(requestParameters.billingMonth))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceSendEmailRequestToJSON(requestParameters.invoiceSendEmailRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send the finance report via email to the predefined mail address or when the optional field email is supplied to this address
     */
    async invoicesReportMonthlyBillingMonthPost(billingMonth: string, invoiceSendEmailRequest?: InvoiceSendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invoicesReportMonthlyBillingMonthPostRaw({ billingMonth: billingMonth, invoiceSendEmailRequest: invoiceSendEmailRequest }, initOverrides);
    }

    /**
     * Send the monthly sales data report via email to the predefined mail address or when the optional field email is supplied to this address
     */
    async invoicesReportMonthlySalesBillingMonthPostRaw(requestParameters: InvoicesReportMonthlySalesBillingMonthPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billingMonth === null || requestParameters.billingMonth === undefined) {
            throw new runtime.RequiredError('billingMonth','Required parameter requestParameters.billingMonth was null or undefined when calling invoicesReportMonthlySalesBillingMonthPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invoices/report/monthly/sales/{billingMonth}`.replace(`{${"billingMonth"}}`, encodeURIComponent(String(requestParameters.billingMonth))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceSendEmailRequestToJSON(requestParameters.invoiceSendEmailRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send the monthly sales data report via email to the predefined mail address or when the optional field email is supplied to this address
     */
    async invoicesReportMonthlySalesBillingMonthPost(billingMonth: string, invoiceSendEmailRequest?: InvoiceSendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invoicesReportMonthlySalesBillingMonthPostRaw({ billingMonth: billingMonth, invoiceSendEmailRequest: invoiceSendEmailRequest }, initOverrides);
    }

}
