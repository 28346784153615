/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RegionData } from './RegionData';
import {
    RegionDataFromJSON,
    RegionDataFromJSONTyped,
    RegionDataToJSON,
} from './RegionData';

/**
 * 
 * @export
 * @interface BackendUserProfileWrite
 */
export interface BackendUserProfileWrite {
    /**
     * 
     * @type {string}
     * @memberof BackendUserProfileWrite
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BackendUserProfileWrite
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof BackendUserProfileWrite
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof BackendUserProfileWrite
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BackendUserProfileWrite
     */
    locale: string;
    /**
     * 
     * @type {RegionData}
     * @memberof BackendUserProfileWrite
     */
    regionData?: RegionData;
    /**
     * 
     * @type {boolean}
     * @memberof BackendUserProfileWrite
     */
    allowsAdvertisements?: boolean;
}

/**
 * Check if a given object implements the BackendUserProfileWrite interface.
 */
export function instanceOfBackendUserProfileWrite(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "locale" in value;

    return isInstance;
}

export function BackendUserProfileWriteFromJSON(json: any): BackendUserProfileWrite {
    return BackendUserProfileWriteFromJSONTyped(json, false);
}

export function BackendUserProfileWriteFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackendUserProfileWrite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'locale': json['locale'],
        'regionData': !exists(json, 'regionData') ? undefined : RegionDataFromJSON(json['regionData']),
        'allowsAdvertisements': !exists(json, 'allowsAdvertisements') ? undefined : json['allowsAdvertisements'],
    };
}

export function BackendUserProfileWriteToJSON(value?: BackendUserProfileWrite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'locale': value.locale,
        'regionData': RegionDataToJSON(value.regionData),
        'allowsAdvertisements': value.allowsAdvertisements,
    };
}

