import React, { FC, ReactElement, useState } from 'react'
import { Outlet } from 'react-router'
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Slide,
  Toolbar,
  styled,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from '@mui/material'
import { Apps, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, Menu } from '@mui/icons-material'
import { Stack } from '@mui/system'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { Permission } from 'src/service/backend/api/models/Permission'
import {
  getIsDrawerMinimizedFromStorage,
  storeIsDrawerMinimizedToStorage,
} from 'src/service/local-storage/LocalStorageService'
import { SCROLL_OPTIONS } from 'src/ui-shared/constants/PageScroll.const'
import { AppSwitcher } from 'src/ui/layout/app-switcher/AppSwitcher'
import { HelpMenu } from 'src/ui/layout/appbar-menu/HelpMenu'
import { NotificationMenu } from 'src/ui/layout/appbar-menu/NotificationMenu'
import { ProfileMenu } from 'src/ui/layout/appbar-menu/ProfileMenu'
import { ServicePackagePlanMenu } from 'src/ui/layout/appbar-menu/ServicePackagePlanMenu'
import { ActiveOrganizationSelector } from 'src/ui/layout/organization-selector/ActiveOrganizationSelector'
import { Sidebar } from 'src/ui/layout/sidebar/Sidebar'
import { hasAnyApp, hasMoreThanOneApp, hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  minHeight: theme.spacing(7),
  backgroundColor: theme.palette.primary.main,
  color: 'white',
}))

const LeftSideItemsBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}))

const RightSideItemsStack = styled(Stack)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  minHeight: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    '& .MuiMenuItem-root': {
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: theme.spacing(7),
    },
  },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  padding: theme.spacing(1),
}))

const MainContent = styled(Box)(({ theme }) => ({
  width: '100vh',
  height: '100vh',
  position: 'relative',
  flexGrow: 1,
  paddingTop: theme.spacing(8),
  // prevent horizontal scrollbar on mobile
  [theme.breakpoints.down('sm')]: {
    overflow: 'scroll',
  },
}))

interface DrawerProps {
  drawerwidth: number
  scrolltrigger: number
}

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'sx' })<DrawerProps>(
  ({ drawerwidth, scrolltrigger, theme }) => ({
    width: drawerwidth,
    flexShrink: 0,
    backgroundColor: theme.palette.menu.backgroundColor,
    '& .MuiDrawer-paper': {
      width: drawerwidth,
      boxSizing: 'border-box',
      overflowX: 'hidden',
      backgroundColor: theme.palette.menu.backgroundColor,
    },
    '& .MuiToolbar-root': {
      backgroundColor: theme.palette.menu.backgroundColor,
      minHeight: scrolltrigger ? '0px' : '',
    },
    '@media print': {
      display: 'none',
    },
  }),
)

const SmallScreenDrawer = styled(Drawer)(({ theme }) => ({
  width: '285px',
  zIndex: theme.zIndex.drawer + 2,
  '& .MuiDrawer-paper': {
    width: '285px',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    backgroundColor: theme.palette.menu.backgroundColor,
  },
}))

const MinimizeButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  paddingRight: theme.spacing(1),
}))

interface Props {
  appLogo: ReactElement
  enableActiveOrganizationSelector?: boolean
  selectFirstOrganizationAsActive?: boolean
  organizationIsMandatory?: boolean
}

export const Layout: FC<Props> = ({
  appLogo,
  enableActiveOrganizationSelector,
  selectFirstOrganizationAsActive,
  organizationIsMandatory,
}): ReactElement => {
  const theme = useTheme()
  const user = useUser()
  const appId = useAppId()
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [minimizedDrawer, setMinimizedDrawer] = useState<boolean>(getIsDrawerMinimizedFromStorage())
  const [openAppSwitcher, setOpenAppSwitcher] = useState<boolean>(false)

  const drawerWidth = minimizedDrawer ? 58 : 350

  const userHasMoreThanOneApp = hasMoreThanOneApp(user)
  const hasPermissionPartMaster = hasPermission(user, Permission.PART_MASTER_READ)
  const hasPermissionWashqulator = hasPermission(user, Permission.WASHQLATOR_READ)
  const hasDiscoverLinks = hasPermissionPartMaster || hasPermissionWashqulator

  const showAppSwitcher = userHasMoreThanOneApp || hasDiscoverLinks
  const scrollTrigger = useScrollTrigger(SCROLL_OPTIONS)

  const minimizeOrMaximizeDrawer = () => {
    storeIsDrawerMinimizedToStorage(!minimizedDrawer)
    setMinimizedDrawer(!minimizedDrawer)
  }

  const openOrCloseDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const handleCloseAppSwitcher = () => {
    setOpenAppSwitcher(false)
  }

  const handleOpenAppSwitcher = () => {
    setOpenAppSwitcher(true)
  }

  return (
    <Box display="flex">
      <Box display="flex">
        <Slide appear={false} direction="down" in={!scrollTrigger}>
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} elevation={0}>
            <StyledToolbar disableGutters>
              {/* Left side of appbar */}
              <LeftSideItemsBox>
                {smallScreen ? (
                  // show hamburger menu button
                  <StyledIconButton onClick={openOrCloseDrawer}>
                    <Menu />
                  </StyledIconButton>
                ) : showAppSwitcher ? (
                  // show app switcher button
                  <StyledIconButton onClick={handleOpenAppSwitcher}>
                    <Apps />
                  </StyledIconButton>
                ) : null}

                {!smallScreen && appLogo}
              </LeftSideItemsBox>

              {/* Right side of appbar */}
              <RightSideItemsStack direction="row">
                {/* selector for organization */}
                {enableActiveOrganizationSelector ? (
                  <ActiveOrganizationSelector organizationIsMandatory={organizationIsMandatory} />
                ) : null}

                {appId === AppId.WASH_MASTER ? <ServicePackagePlanMenu /> : null}

                <HelpMenu />

                {hasAnyApp(user) ? <NotificationMenu /> : null}

                <ProfileMenu />
              </RightSideItemsStack>
            </StyledToolbar>
          </AppBar>
        </Slide>

        {smallScreen ? (
          <SmallScreenDrawer open={openDrawer} anchor="left" onClose={openOrCloseDrawer}>
            <StyledToolbar disableGutters sx={{ height: theme.spacing(8) }}>
              {showAppSwitcher ? (
                <StyledIconButton onClick={handleOpenAppSwitcher}>
                  <Apps />
                </StyledIconButton>
              ) : null}
              {appLogo}
            </StyledToolbar>
            <Sidebar minimizedDrawer={false} closeDrawer={openOrCloseDrawer} />
          </SmallScreenDrawer>
        ) : (
          <StyledDrawer variant="permanent" drawerwidth={drawerWidth} scrolltrigger={scrollTrigger ? 1 : 0}>
            <StyledToolbar disableGutters />

            <Sidebar minimizedDrawer={minimizedDrawer} />

            <MinimizeButtonBox>
              <IconButton color="primary" onClick={minimizeOrMaximizeDrawer}>
                {minimizedDrawer ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft />}
              </IconButton>
            </MinimizeButtonBox>
          </StyledDrawer>
        )}

        <AppSwitcher open={openAppSwitcher} handleClose={handleCloseAppSwitcher} />
      </Box>

      <MainContent>
        <Outlet />
      </MainContent>
    </Box>
  )
}
