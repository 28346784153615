/**
 * Check equality of two sets by shallow comparing values within
 *
 * @param set1 first set
 * @param set2 second set
 * @returns {boolean} result
 */
export const areEqual = <T>(set1: Set<T>, set2: Set<T>): boolean => {
  if (set1.size !== set2.size) {
    return false
  }

  const merged = new Set([...set1, ...set2])
  return merged.size == set1.size && merged.size == set2.size
}
