/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineReference } from './MachineReference';
import {
    MachineReferenceFromJSON,
    MachineReferenceFromJSONTyped,
    MachineReferenceToJSON,
} from './MachineReference';
import type { ModuleLiquidDetergentSettings } from './ModuleLiquidDetergentSettings';
import {
    ModuleLiquidDetergentSettingsFromJSON,
    ModuleLiquidDetergentSettingsFromJSONTyped,
    ModuleLiquidDetergentSettingsToJSON,
} from './ModuleLiquidDetergentSettings';
import type { Program } from './Program';
import {
    ProgramFromJSON,
    ProgramFromJSONTyped,
    ProgramToJSON,
} from './Program';

/**
 * 
 * @export
 * @interface ProgramInstalled
 */
export interface ProgramInstalled {
    /**
     * 
     * @type {MachineReference}
     * @memberof ProgramInstalled
     */
    machine: MachineReference;
    /**
     * 
     * @type {Program}
     * @memberof ProgramInstalled
     */
    program: Program;
    /**
     * 
     * @type {Array<ModuleLiquidDetergentSettings>}
     * @memberof ProgramInstalled
     */
    liquidDetergentSteps?: Array<ModuleLiquidDetergentSettings>;
}

/**
 * Check if a given object implements the ProgramInstalled interface.
 */
export function instanceOfProgramInstalled(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "machine" in value;
    isInstance = isInstance && "program" in value;

    return isInstance;
}

export function ProgramInstalledFromJSON(json: any): ProgramInstalled {
    return ProgramInstalledFromJSONTyped(json, false);
}

export function ProgramInstalledFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramInstalled {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machine': MachineReferenceFromJSON(json['machine']),
        'program': ProgramFromJSON(json['program']),
        'liquidDetergentSteps': !exists(json, 'liquidDetergentSteps') ? undefined : ((json['liquidDetergentSteps'] as Array<any>).map(ModuleLiquidDetergentSettingsFromJSON)),
    };
}

export function ProgramInstalledToJSON(value?: ProgramInstalled | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machine': MachineReferenceToJSON(value.machine),
        'program': ProgramToJSON(value.program),
        'liquidDetergentSteps': value.liquidDetergentSteps === undefined ? undefined : ((value.liquidDetergentSteps as Array<any>).map(ModuleLiquidDetergentSettingsToJSON)),
    };
}

