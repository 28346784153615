import { SettingField, SettingFieldType, SettingSelectField } from 'src/service/backend/api'

const GROUP_END_ID = 'END'

export const isGroupStart = (settingField: SettingField): boolean => {
  return settingField.fieldType === SettingFieldType.GROUP && settingField.settingId !== GROUP_END_ID
}

export const isGroupEnd = (settingField: SettingField): boolean => {
  return settingField.fieldType === SettingFieldType.GROUP && settingField.settingId === GROUP_END_ID
}

// Transfer object used for preserving the fieldIndex in the fields
export interface SettingFieldWithIndex extends SettingField {
  fieldIndex: number
}

export const getFieldLabel = (field: SettingField): string => {
  const max = field.validation?.max
  const min = field.validation?.min
  const allowZero = field.validation?.allowZero
  const unit = field.unit

  let label: string = field.label

  const infinity = '\u221E'
  if (max != null && min != null && allowZero != null) {
    label = `${label} [0, ${min} - ${max}]`
  } else if (max != null && min != null) {
    label = `${label} [${min} - ${max}]`
  } else if (max != null && allowZero != null) {
    label = `${label} [0, ${infinity} - ${max}]`
  } else if (max != null) {
    label = `${label} [${infinity} - ${max}]`
  } else if (min != null && allowZero != null) {
    label = `${label} [0, ${min} - ${infinity}]`
  } else if (min != null) {
    label = `${label} [${min} - ${infinity}]`
  }

  if (unit != null) {
    label += ` (${field.unit})`
  }

  return label
}

/**
 * Function that returns the value for given setting field by it's label.
 *
 * @param label the label of the field
 * @param fields list of setting fields
 * @returns {string} the value of the field
 */
export const getFieldValueByLabel = (label: string, fields: SettingField[]): string => {
  const settingField = fields.find((setting) => setting.label === label && setting.showInOverview)

  let tableCellValue = ''

  if (settingField) {
    const fieldType = settingField.fieldType
    if (fieldType === SettingFieldType.NUMBER) {
      tableCellValue = settingField.numberField?.data?.toString() ?? ''
    } else if (fieldType === SettingFieldType.TEXT) {
      tableCellValue = settingField.textField?.data ?? ''
    } else if (fieldType === SettingFieldType.SELECT) {
      tableCellValue = getSelectedFieldValueLabel(settingField.selectField)
    } else if (fieldType === SettingFieldType.BOOLEAN) {
      const booleanField = settingField.booleanField
      const showTableValue = booleanField?.data ?? false

      if (showTableValue) {
        tableCellValue = booleanField?.overviewLabel ?? ''
      }
    }
  }

  return tableCellValue
}

const getSelectedFieldValueLabel = (selectField: SettingSelectField | undefined): string => {
  if (!selectField) {
    return ''
  }

  const selectedValue = selectField.data
  const selectedOption = selectField.options?.find((item) => item.data === selectedValue)
  return selectedOption?.label ?? ''
}
