import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { useTranslate } from 'src/i18n/useMessageSource'
import { COUNTRIES, CountryObject, getCountryName } from 'src/service/view-model/base/country/Countries'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { useUserLocale } from 'src/user/UserContext'

export interface CountryAutoCompleteProps {
  onChange: (option: CountryObject | undefined) => void
  name: string
  value: CountryObject | undefined
  disabled: boolean
  error?: boolean
}

export const CountryAutoComplete: React.FC<CountryAutoCompleteProps> = ({
  onChange,
  value,
  name,
  disabled = false,
  error,
}) => {
  const translate = useTranslate()
  const userLocale = useUserLocale()

  const onHandleChange = (event: React.SyntheticEvent, object: CountryObject | null) => {
    onChange(object ? object : undefined)
  }

  return (
    <Autocomplete
      id="country-autocomplete"
      openOnFocus={true}
      options={COUNTRIES}
      getOptionLabel={(option) => (option.code ? getCountryName(option.code, userLocale) : '')}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      autoHighlight
      disableClearable={false}
      multiple={false}
      freeSolo={false}
      onChange={onHandleChange}
      disabled={disabled}
      value={value}
      groupBy={(option) => option.group}
      renderInput={(params) => <TextFieldDefault name={name} error={error} {...params} label={translate('country')} />}
    />
  )
}
