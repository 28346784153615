/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Currency of Standard ISO4217
 * @export
 * @enum {string}
 */
export enum Currency {
    ALL = 'ALL',
    AMD = 'AMD',
    AUD = 'AUD',
    AZN = 'AZN',
    BAM = 'BAM',
    BGN = 'BGN',
    BYN = 'BYN',
    CAD = 'CAD',
    CHF = 'CHF',
    CNY = 'CNY',
    CZK = 'CZK',
    DKK = 'DKK',
    EUR = 'EUR',
    GBP = 'GBP',
    GEL = 'GEL',
    GIP = 'GIP',
    HKD = 'HKD',
    HRK = 'HRK',
    HUF = 'HUF',
    ISK = 'ISK',
    JPY = 'JPY',
    KZT = 'KZT',
    MDL = 'MDL',
    MKD = 'MKD',
    NOK = 'NOK',
    NZD = 'NZD',
    PLN = 'PLN',
    RON = 'RON',
    RSD = 'RSD',
    RUB = 'RUB',
    SEK = 'SEK',
    TRY = 'TRY',
    UAH = 'UAH',
    USD = 'USD'
}


export function CurrencyFromJSON(json: any): Currency {
    return CurrencyFromJSONTyped(json, false);
}

export function CurrencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Currency {
    return json as Currency;
}

export function CurrencyToJSON(value?: Currency | null): any {
    return value as any;
}

