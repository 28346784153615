import { makeStyles } from 'tss-react/mui'

const defaultSlotStyle: Record<any, string> = {
  cursor: 'pointer',
  borderRadius: '5px',
  textAlign: 'left',
  padding: '0px 6px',
  height: '24px',
  lineHeight: '24px',
  position: 'relative',
  overflow: 'hidden',
  textOverflow: 'clip',
  overflowWrap: 'anywhere',
  wordBreak: 'break-all',
}

const defaultSlotMarkerStyle: Record<any, any> = {
  marginTop: 1.5,
  width: '25px',
  height: '17px',
  textAlign: 'center',
  borderRadius: '16px',
  float: 'right',
}

const defaultSlotIcon: Record<any, any> = {
  position: 'absolute',
  top: '3px',
  right: '5px',
}

export const calendarStyles = makeStyles()(() => {
  return {
    dayTitle: {
      textAlign: 'center',
      textSizeAdjust: '2',
      fontWeight: 'bold',
    },
    daySlots: {
      textAlign: 'left',
    },
    slotSpan: {
      /* display: 'inline-block', */
      verticalAlign: 'middle',
      lineHeight: 'normal',
    },
    slotIcon: defaultSlotIcon,

    // day slot styles based on opening type
    daySlotBlocked: {
      ...defaultSlotStyle,
      backgroundColor: '#E8E8E8',
      color: '#9E9E9E',
      cursor: 'default',
    },
    daySlotMaintenance: {
      ...defaultSlotStyle,
      backgroundColor: '#E8E8E8',
      color: '#333333',
      cursor: 'default',
    },
    daySlotOpen: {
      ...defaultSlotStyle,
      backgroundColor: '#FFFFFF',
      color: '#9E9E9E',
      border: '1px solid #ccc',
      lineHeight: '22px',
    },
    daySlotAdhoc: {
      ...defaultSlotStyle,
      backgroundColor: '#F9F9F9',
      color: '#9E9E9E',
      border: '2px solid #666666',
      lineHeight: '20px',
      cursor: 'default',
    },

    //  day slot styles based on calendar entry (reservation)
    daySlotMachineUsage: {
      ...defaultSlotStyle,
      backgroundColor: '#333333',
      color: '#FFFFFF',
      cursor: 'default',
    },
    daySlotReservation: {
      ...defaultSlotStyle,
      backgroundColor: '#777777',
      color: '#FFFFFF',
    },
    slotIconReservation: {
      ...defaultSlotIcon,
      backgroundColor: '#777777d4',
    },
    daySlotAdhocReservation: {
      ...defaultSlotStyle,
      backgroundColor: '#666666',
      color: '#FFFFFF',
      cursor: 'default',
    },
    daySlotReservationSelectedUser: {
      ...defaultSlotStyle,
      backgroundColor: '#CE0F69',
      color: '#FFFFFF',
    },
    slotIconReservationSelectedUser: {
      ...defaultSlotIcon,
      // semi transparent background color for the icon to cover the text if overlapping
      backgroundColor: '#CE0F69d4',
    },

    // laundry group recurring calendar styles
    happyHourSlotMarker: {
      ...defaultSlotMarkerStyle,
      color: '#CE0F69',
      backgroundColor: '#FDCDE0',
      lineHeight: '18px',
    },
  }
})

const defaultLegendStyle: Record<any, string> = {
  width: '18px',
  height: '18px',
  minWidth: '18px',
  minHeight: '18px',
  textAlign: 'left',
  borderRadius: '16px',
}

export const calendarLegendStyles = makeStyles()((theme) => {
  return {
    openSlot: {
      ...defaultLegendStyle,
      border: '1px solid #ccc',
      backgroundColor: '#FFFFFF',
    },
    adHocSlot: {
      ...defaultLegendStyle,
      border: '2px solid #666666',
      backgroundColor: '#F9F9F9',
    },
    machineUsage: {
      ...defaultLegendStyle,
      backgroundColor: '#333333',
    },
    userReservation: {
      ...defaultLegendStyle,
      backgroundColor: '#777777',
    },
    selectedUserReservation: {
      ...defaultLegendStyle,
      backgroundColor: '#CE0F69',
    },
    blockedSlot: {
      ...defaultLegendStyle,
      backgroundColor: '#D7D5D5',
    },
    happyHourSlot: {
      color: '#CE0F69',
      width: '25px',
      height: '18px',
      minWidth: '25px',
      minHeight: '18px',
      textAlign: 'center',
      borderRadius: '16px',
      backgroundColor: '#FDCDE0',
      lineHeight: '18px',
    },
    textStyle: {
      paddingLeft: '8px',
      fontSize: '0.775rem',
    },
    boxMargin: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      borderTop: '2px solid #ccc',
    },
    legendItemPadding: {
      marginLeft: theme.spacing(2),
    },
  }
})

export const styleDayTitles: React.CSSProperties = {
  position: 'sticky',
  top: '0',
  zIndex: 1000,
  background: '#FFF',
}

export const styleLegend: React.CSSProperties = {
  position: 'sticky',
  bottom: '0',
  zIndex: 1000,
  background: '#FFF',
}
