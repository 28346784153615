/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Applications } from './Applications';
import {
    ApplicationsFromJSON,
    ApplicationsFromJSONTyped,
    ApplicationsToJSON,
} from './Applications';
import type { InitializeResultType } from './InitializeResultType';
import {
    InitializeResultTypeFromJSON,
    InitializeResultTypeFromJSONTyped,
    InitializeResultTypeToJSON,
} from './InitializeResultType';
import type { MeInitializeResponseData } from './MeInitializeResponseData';
import {
    MeInitializeResponseDataFromJSON,
    MeInitializeResponseDataFromJSONTyped,
    MeInitializeResponseDataToJSON,
} from './MeInitializeResponseData';
import type { Permission } from './Permission';
import {
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
} from './Permission';
import type { RegionData } from './RegionData';
import {
    RegionDataFromJSON,
    RegionDataFromJSONTyped,
    RegionDataToJSON,
} from './RegionData';

/**
 * Result of initialization
 * @export
 * @interface MeInitializeResponse
 */
export interface MeInitializeResponse {
    /**
     * 
     * @type {InitializeResultType}
     * @memberof MeInitializeResponse
     */
    result: InitializeResultType;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof MeInitializeResponse
     */
    permissions: Array<Permission>;
    /**
     * 
     * @type {Applications}
     * @memberof MeInitializeResponse
     */
    applications: Applications;
    /**
     * 
     * @type {RegionData}
     * @memberof MeInitializeResponse
     */
    regionData?: RegionData;
    /**
     * 
     * @type {MeInitializeResponseData}
     * @memberof MeInitializeResponse
     */
    data?: MeInitializeResponseData | null;
}

/**
 * Check if a given object implements the MeInitializeResponse interface.
 */
export function instanceOfMeInitializeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "permissions" in value;
    isInstance = isInstance && "applications" in value;

    return isInstance;
}

export function MeInitializeResponseFromJSON(json: any): MeInitializeResponse {
    return MeInitializeResponseFromJSONTyped(json, false);
}

export function MeInitializeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeInitializeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': InitializeResultTypeFromJSON(json['result']),
        'permissions': ((json['permissions'] as Array<any>).map(PermissionFromJSON)),
        'applications': ApplicationsFromJSON(json['applications']),
        'regionData': !exists(json, 'regionData') ? undefined : RegionDataFromJSON(json['regionData']),
        'data': !exists(json, 'data') ? undefined : MeInitializeResponseDataFromJSON(json['data']),
    };
}

export function MeInitializeResponseToJSON(value?: MeInitializeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': InitializeResultTypeToJSON(value.result),
        'permissions': ((value.permissions as Array<any>).map(PermissionToJSON)),
        'applications': ApplicationsToJSON(value.applications),
        'regionData': RegionDataToJSON(value.regionData),
        'data': MeInitializeResponseDataToJSON(value.data),
    };
}

