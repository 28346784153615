import React, { ReactElement } from 'react'
import { Box } from '@mui/material'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'

export const DashboardLoadingIndicator = (): ReactElement => {
  return (
    <Box sx={{ position: 'relative', width: '100%', height: '200px' }}>
      <LoadingIndicator loading sx={{ top: '50%', left: '50%' }} />
    </Box>
  )
}
