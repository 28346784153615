import React, { ReactElement, useEffect, useRef, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { TranslateFunction } from 'src/i18n/useMessageSource'
import { TableFilterPopup, TableFilterPopupWithClose } from 'src/ui-shared/table/TableFilterPopup'
import { TableFilterTextFieldDefault } from 'src/ui-shared/table/TableFilterTextFieldDefault'

interface Props<T extends Record<string, any>, K extends keyof T> {
  options: T[]
  filter: string
  title: string
  label: string
  labelFieldName: K
  valueFieldName: K
  getOptionLabel?: (option: T) => string
  tableSettings: any
  setTableSettings: (value: any) => void
  onItemSelected?: (item?: T) => void
  translate?: TranslateFunction
}

export const TableFilterAutocomplete = <T extends Record<string, any>>({
  options,
  filter,
  title,
  label,
  labelFieldName,
  valueFieldName,
  getOptionLabel,
  tableSettings,
  setTableSettings,
  onItemSelected,
  translate,
}: Props<T, keyof T>): ReactElement => {
  const popupRef = useRef<TableFilterPopupWithClose | null>(null)

  // state
  const [timeoutState, setTimeoutState] = useState<NodeJS.Timeout | null>(null)
  const [selectedOptionId, setSelectedOptionId] = useState<string | undefined>(tableSettings[filter])

  const getOptionValue = (option?: T): string | undefined => option && (option as any)[valueFieldName]

  const findOption = options.find((option) => getOptionValue(option) === selectedOptionId)
  const selectedOption = findOption ? findOption : undefined

  useEffect(() => {
    setSelectedOptionId(tableSettings[filter])
  }, [tableSettings])

  // handle events
  const handleSelectedOption = (_: React.SyntheticEvent, newOption: T | null) => {
    const transformedValue = getOptionValue(newOption ?? undefined)
    setSelectedOptionId(transformedValue)

    if (timeoutState) {
      clearTimeout(timeoutState)
    }

    setTimeoutState(
      setTimeout(() => {
        setTableSettings({
          ...tableSettings,
          [filter]: transformedValue,
          page: 0,
        })

        if (onItemSelected) {
          onItemSelected(newOption ?? undefined)
        }
      }, 300),
    )
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (newOption !== undefined) {
      popupRef.current?.close()
    }
  }

  const getOptionLabelInternal = (option: T): string => {
    if (getOptionLabel) {
      return getOptionLabel(option)
    } else {
      const label = (option as any)[labelFieldName]
      if (translate) {
        return translate(label)
      }
      return label
    }
  }

  return (
    <TableFilterPopup ref={popupRef} title={title} active={Boolean(tableSettings[filter])}>
      <Autocomplete
        openOnFocus={true}
        options={options}
        value={selectedOption}
        getOptionLabel={getOptionLabelInternal}
        style={{ width: 300 }}
        onChange={handleSelectedOption}
        renderInput={(params) => <TableFilterTextFieldDefault {...params} label={label} />}
      />
    </TableFilterPopup>
  )
}
