import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import {
  Alert,
  Box,
  Divider,
  Grid,
  ListItemIcon,
  MenuItem,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material'
import { GetApp } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import {
  ApiResponse,
  Configuration,
  HygieneMonitoringDetail,
  ReportHygieneMonitoringsApi,
  TimeseriesData,
} from 'src/service/backend/api'
import { ReportHygieneMonitoringsApiMock } from 'src/service/backend/api-mock/ReportHygieneMonitoringsApiMock'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getRemoveAcceptHeaderInit } from 'src/service/backend/http/HttpUtils'
import { formatDateTimeForLocale } from 'src/service/utils/DateFormatUtils'
import { downloadFile, getDownloadFilename } from 'src/service/utils/FileDownloadUtils'
import {
  ChartSettingsWithoutScale,
  DEFAULT_CHART_TIME_RANGE_SETTINGS,
} from 'src/service/view-model/base/chart/ChartViewModel'
import { getHygieneStateName } from 'src/service/view-model/hygiene/HygineViewModel'
import { getMachineNameAndSerialNumber } from 'src/service/view-model/machine/Machines'
import { isOrganizationTypeServicePartner } from 'src/service/view-model/organization/Organizations'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { MenuButton } from 'src/ui-shared/base/button/MenuButton'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ApexLineCurveType, ApexLineType, ChartTimelineResult } from 'src/ui-shared/chart/ChartSeries.const'
import { ChartRelativeTimeSettings, getColoredCustomTooltip } from 'src/ui-shared/chart/ChartSeriesTooltipColored'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryGroupTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupTab'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'
import { HygieneResultStatusChip, HygieneStateIcon } from 'src/ui/page/sm/hygiene/HygieneDataDisplay'
import {
  combineHygieneCharts,
  filterHygieneDatasetsWithZeroValue,
  getHygieneWaterChartYAxis,
} from 'src/ui/page/sm/hygiene/details/HygieneChart.utils'
import { DashboardLoadingIndicator } from 'src/ui/page/sm/index/DashboardLoadingIndicator'
import { useUserLocale, useUserRegionLocale } from 'src/user/UserContext'

const AsyncChartSeriesNumber = React.lazy(() =>
  import('src/ui-shared/chart/ChartSeriesNumber').then(({ ChartSeriesNumber }) => ({ default: ChartSeriesNumber })),
)

export const HygieneDetailsPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const { hygieneId } = useRequiredParams(['hygieneId'])
  const appId = useAppId()
  const navigate = useNavigate()
  const location = useLocation()
  const locale = useUserLocale()
  const theme = useTheme()
  const regionLocale = useUserRegionLocale()

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack

  // NOTE mock data / demo data for hygiene monitoring is intentional
  const useMockData = hygieneId.startsWith('000')

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportHygieneMonitoringApi = useMockData
    ? new ReportHygieneMonitoringsApiMock(httpConfiguration)
    : new ReportHygieneMonitoringsApi(httpConfiguration)

  //state
  const [hygiene, setHygiene] = useState<HygieneMonitoringDetail | null>(null)
  const [hygieneChartSettings] = useState<ChartSettingsWithoutScale>(DEFAULT_CHART_TIME_RANGE_SETTINGS)
  const [combinedChartView, setCombinedChartView] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  //derived state
  const hygieneInformation = hygiene?.information
  const chartRelativeTimeSettings: ChartRelativeTimeSettings | undefined = hygieneInformation && {
    locale: locale,
    startDate: hygieneInformation.startTime,
  }

  useHotKeysForm()

  // load data
  const loadChart = (timeSeriesData: TimeseriesData): Promise<ChartTimelineResult> => {
    const dateStartTimeFrom = new Date(hygieneChartSettings.startDateFrom)
    const dateStartTimeTo = new Date(hygieneChartSettings.startDateTo)

    return new Promise((resolve) => {
      resolve({
        timeseriesData: timeSeriesData,
        fromDate: dateStartTimeFrom,
        toDate: dateStartTimeTo,
      } as ChartTimelineResult)
    })
  }

  useEffect(() => {
    reportHygieneMonitoringApi
      .reportSmHygieneMonitoringsHmIdGet(hygieneId)
      .then((data) => {
        setHygiene(data)
        setErrorMessage(null)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setHygiene(null)
      })
  }, [hygieneId, useMockData])

  // events
  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/hygiene`)
  }

  const renderTitle = (): string => {
    let title = ''
    const program = hygieneInformation?.programName || ''
    const machineName = getMachineNameAndSerialNumber(hygieneInformation?.machine)
    if (hygiene && hygieneInformation) {
      title = `${translate('hygiene')}: ${program ? program + ' - ' : ''}${machineName}`
    }
    return title
  }

  const handleChangeChartView = (event: React.MouseEvent<HTMLElement>, combinedView?: boolean | null) => {
    if (combinedView !== null && combinedView !== undefined) {
      setCombinedChartView(combinedView)
    }
  }

  const handleDownloadCertificate = () => {
    let filename: string | null
    reportHygieneMonitoringApi
      .reportSmHygieneMonitoringsHmIdPdfGetRaw({ hmId: hygieneId }, getRemoveAcceptHeaderInit())
      .then(async (response: ApiResponse<Blob>) => {
        filename = getDownloadFilename(response.raw)
        return response.value()
      })
      .then((blob: Blob) => {
        downloadFile(blob, filename)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  const handleDownloadPublicCertificate = () => {
    let filename: string | null
    reportHygieneMonitoringApi
      .pubPdfSignaturePublicKeyGetRaw(getRemoveAcceptHeaderInit())
      .then(async (response: ApiResponse<Blob>) => {
        filename = getDownloadFilename(response.raw)
        return response.value()
      })
      .then((blob: Blob) => {
        downloadFile(blob, filename)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  const downloadCertificateButton = (
    <>
      {useMockData ? (
        <ListingButton
          href="/hygiene-monitoring-example.pdf"
          variant="outlined"
          color="primary"
          startIcon={<GetApp />}
          sx={{ mt: { xs: 1, sm: 0 } }}
        >
          {translate('downloadCertificate')}
        </ListingButton>
      ) : (
        <ListingButton
          onClick={handleDownloadCertificate}
          variant="outlined"
          color="primary"
          startIcon={<GetApp />}
          sx={{ mt: { xs: 1, sm: 0 } }}
        >
          {translate('downloadCertificate')}
        </ListingButton>
      )}
      <MenuButton sx={{ mt: { xs: 1, sm: 0 } }}>
        <MenuItem onClick={handleDownloadPublicCertificate}>
          <ListItemIcon>
            <GetApp fontSize="small" />
          </ListItemIcon>

          {translate('downloadPublicCertificate')}
        </MenuItem>
      </MenuButton>
    </>
  )

  // JSX
  const displayHygieneCharts = (hygiene: HygieneMonitoringDetail, combined: boolean) => {
    let timeseriesDataIndex: number[] = []

    if (!combined) {
      const waterTempChartPosition = 0
      const waterLevelChartPosition = 1
      timeseriesDataIndex = [waterTempChartPosition, waterLevelChartPosition]
    }

    const combinedCharts = combineHygieneCharts(
      !combined ? hygiene : filterHygieneDatasetsWithZeroValue(hygiene, 3),
      timeseriesDataIndex,
    )

    return (
      <>
        {combinedCharts.map((timeSeriesData, i) => {
          let customYAxis: ApexYAxis[] | undefined = undefined
          let lineCurve: ApexLineCurveType[] | undefined = undefined
          let lineDash: number[] | undefined = undefined
          let lineType: ApexLineType[] | undefined = undefined
          let chartColors: string[] | undefined = undefined
          let showMarkers = true
          let showDataLabels = false

          // custom style for WaterTemp - WaterLevel and combined chart
          if (i === 0) {
            // custom style for WaterTemp - WaterLevel chart
            // line 0 -> water temp
            // line 1 -> water temp target
            // line 2 -> water level
            // line 3 -> water level target
            lineDash = [0, 18, 0, 18]
            lineType = ['line', 'line', 'area', 'line']
            chartColors = ['#9e9e9e', '#7a7a7a', '#B6A8CE', theme.palette.primary.main]
            lineCurve = ['straight', 'stepline', 'straight', 'stepline']
            showMarkers = false

            if (combined) {
              // line 4 -> rpm
              lineCurve.push('straight')
              lineType.push('line')
              lineDash.push(0)
              chartColors.push(theme.palette.primary.main)

              // line 5 -> rpm setpoint
              lineCurve.push('stepline')
              lineType.push('line')
              lineDash.push(18)

              // default values for other datasets in combined chart
              for (let i = 0; i < timeSeriesData.datasets.length - 6; i++) {
                lineDash.push(0)
                lineType.push('line')
                lineCurve.push('stepline')
              }
            }

            // custom Y axis for WaterTemp, WaterLevel, Spin Speed datasets
            customYAxis = getHygieneWaterChartYAxis(timeSeriesData.datasets, combined)
          } else if (i === 1) {
            // custom style for Spin speed
            // actual RPM with straight lines, target RPM with stepline
            // line 0 -> rpm
            // line 1 -> target rpm
            lineCurve = ['straight', 'stepline']
            showMarkers = false
          }
          // custom style for rest of charts
          else if (i > 1) {
            // digital on / off lines for the relay charts
            lineCurve = ['stepline']
            showDataLabels = true
          }

          return (
            <Grid item {...ITEM_BREAKPOINTS} md={12} lg={combined ? 12 : 6} key={i}>
              <Typography variant="h6" align="center">
                {timeSeriesData.title}
              </Typography>
              <AsyncChartSeriesNumber
                loadChart={() => loadChart(timeSeriesData)}
                chartSettings={hygieneChartSettings}
                hideXAxis={true}
                showMarkers={showMarkers}
                showDataLabels={showDataLabels}
                customYAxis={customYAxis}
                customTooltip={getColoredCustomTooltip(regionLocale, chartRelativeTimeSettings)}
                chartColors={chartColors}
                lineDash={lineDash}
                lineCurve={lineCurve}
                lineType={lineType}
              />
            </Grid>
          )
        })}
      </>
    )
  }

  return (
    <ScreenLayout title={renderTitle()} onBack={navigateBack} actionsWidth={50} actions={downloadCertificateButton}>
      <Paper elevation={0}>
        {useMockData ? (
          <Box mt={2}>
            <Alert severity={'warning'}>{translate('demoData')}</Alert>
          </Box>
        ) : null}
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : hygiene && hygieneInformation ? (
          <Box pt={2}>
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={hygieneInformation.programName} label={translate('program')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  value={formatDateTimeForLocale(hygieneInformation.startTime, regionLocale)}
                  label={translate('startTime')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  value={getHygieneStateName(hygieneInformation.state, undefined, translate)}
                  label={translate('state')}
                  inputProps={{
                    startAdornment: (
                      <Box mt={3} mr={1}>
                        <HygieneStateIcon hygieneState={hygieneInformation.state} result={hygieneInformation.result} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('result')}
                  inputProps={{
                    startAdornment: (
                      <Box mt={2} mr={1}>
                        <HygieneResultStatusChip result={hygieneInformation.result} translate={translate} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              {hygieneInformation.failedReason ? (
                <Grid item xs={12}>
                  <DetailsTextField
                    label={translate('reason')}
                    value={hygieneInformation.failedReason}
                    multiline
                    sx={{ overflow: 'visible' }}
                  />
                </Grid>
              ) : null}
            </Grid>

            <Divider className={sharedClasses.Divider} />

            <Grid container spacing={0} className={sharedClasses.GridWithTextField}>
              <Grid item xs={12} textAlign={'right'}>
                <ToggleButtonGroup value={combinedChartView} onChange={handleChangeChartView} exclusive size="small">
                  <ToggleButton value={false}>{translate('separate')}</ToggleButton>
                  <ToggleButton value={true}>{translate('combined')}</ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              {displayHygieneCharts(hygiene, combinedChartView)}

              {hygiene.data.length === 0 ? (
                <Grid container>
                  <Grid item xs={6} pl={1}>
                    <Alert severity={'warning'}>{translate('table.noDataFound')}</Alert>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>

            <Divider className={sharedClasses.Divider} />

            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('organization')}
                  value={hygieneInformation.organization.name}
                  link={
                    isOrganizationTypeServicePartner(hygieneInformation.organization.type)
                      ? `/${appId}/service-partners/${hygieneInformation.organization.id}/view`
                      : `/${appId}/organizations/${hygieneInformation.organization.id}/view`
                  }
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('laundryGroup')}
                  value={hygieneInformation.laundryGroup.name}
                  link={`/${appId}/laundry-groups/${hygieneInformation.laundryGroup.id}/view/${LaundryGroupTab.OVERVIEW}`}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('laundry')}
                  value={hygieneInformation.laundry.name}
                  link={`/${appId}/laundries/${hygieneInformation.laundry.id}/view`}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('machine') + ' / ' + translate('serialNumber')}
                  value={getMachineNameAndSerialNumber(hygieneInformation.machine)}
                  link={`/${appId}/machines/${hygieneInformation.machine.id}/view/${MachineTab.OVERVIEW}`}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <DashboardLoadingIndicator />
        )}
      </Paper>
    </ScreenLayout>
  )
}
