import React from 'react'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'

interface CheckboxData {
  [key: string]: boolean
}

interface CheckBoxProps {
  value?: CheckboxData
  onChange: (option: any) => void
  label?: string
  disabled?: boolean
}

export const CheckboxGroup: React.FC<CheckBoxProps> = ({ value, onChange, label, disabled }) => {
  const translate = useTranslate()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, [event.target.name]: event.target.checked })
  }

  return (
    <div>
      <FormControl component="fieldset">
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <FormGroup>
          {value
            ? Object.keys(value).map((key) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={value[key as keyof CheckboxData]}
                        key={key}
                        onChange={handleChange}
                        name={key}
                        disabled={disabled ? disabled : false}
                      />
                    }
                    label={translate(key)}
                  />
                )
              })
            : null}
        </FormGroup>
      </FormControl>
    </div>
  )
}
