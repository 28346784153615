import { SettingBooleanField, SettingField, SettingFieldType, SettingSelectField } from 'src/service/backend/api'
import { SettingFieldWithIndex, isGroupEnd, isGroupStart } from 'src/service/view-model/setting-field/SettingFieldUtils'
import { SettingGroupFieldWithDefault } from 'src/service/view-model/setting-field/SettingVisibilityDefaultUtils'

/**
 * Applies the initial hidden / shown state to the groups based on the state of the
 * select or boolean fields that can show or hide groups.
 * @param fields the setting fields.
 */
export const hideOrShowGroupsForAllSelectOrBooleanFields = (fields: SettingField[]): void => {
  // find all select fields
  const selectFields = fields.filter((item) => item.fieldType === SettingFieldType.SELECT)

  // apply hide / show for all select fields
  for (let i = 0; i < selectFields.length; i++) {
    const selectField = selectFields[i]
    hideOrShowGroupsForSelectField(fields, selectField.selectField)
  }

  // find all boolean fields
  const booleanFields = fields.filter((item) => item.fieldType === SettingFieldType.BOOLEAN)

  // apply hide / show for all boolean fields
  for (let i = 0; i < booleanFields.length; i++) {
    const booleanField = booleanFields[i]
    hideOrShowGroupsForBooleanField(fields, booleanField.booleanField)
  }
}

/**
 * Given settings (fields), and selectField, it checks if the select field manipulates groups,
 * if it does, it changes the groups visibility, and returns the array of the updated settings (fields)
 * if the select field is a regular (doesn't manipulate groups), it returns undefined
 *
 * @param fields the settings (fields).
 * @param selectField the select field.
 * @returns new array of the changed settings (fields) or undefined if nothing is changed.
 */
export const hideOrShowGroupsForSelectField = (
  fields: SettingField[],
  selectField?: SettingSelectField,
): SettingField[] | undefined => {
  if (!selectField) {
    return undefined
  }

  const selectedOption = selectField.options?.find((item) => item.data === selectField.data)

  let updatedFields: SettingField[] | undefined = undefined

  if (selectedOption && (selectedOption.showGroup || selectedOption.hideGroups)) {
    const showGroups = selectedOption.showGroup ? [selectedOption.showGroup] : []
    const hideGroups = selectedOption.hideGroups ? selectedOption.hideGroups : []

    updatedFields = hideOrShowGroups(fields, hideGroups, showGroups)
  }

  // when the selected option is cleared or it is null from the backend
  if (selectedOption == null) {
    const isGroupSelect = selectField.options?.some((item) => item.showGroup || item.hideGroups)

    if (isGroupSelect) {
      updatedFields = hideOrShowGroupToDefaultForSelectField(fields, selectField)
    }
  }

  return updatedFields
}

/**
 * Given settings (fields), and booleanField, it checks if the boolean field manipulates groups,
 * if it does, it changes the groups visibility, and returns the array of the updated settings (fields)
 * if the boolean field is a regular (doesn't manipulate groups), it returns undefined
 *
 * @param fields the settings (fields).
 * @param booleanField the boolean field.
 * @returns new array of the changed settings (fields) or undefined if nothing is changed.
 */
export const hideOrShowGroupsForBooleanField = (
  fields: SettingField[],
  booleanField?: SettingBooleanField,
): SettingField[] | undefined => {
  if (!booleanField) {
    return undefined
  }

  if (booleanField.showGroup) {
    const booleanFieldValue = booleanField.data

    let hideGroups = booleanFieldValue ? booleanField.hideGroups : [booleanField.showGroup]
    let showGroups = booleanFieldValue ? [booleanField.showGroup] : booleanField.hideGroups

    hideGroups = hideGroups ?? []
    showGroups = showGroups ?? []

    const updatedFields = hideOrShowGroups(fields, hideGroups, showGroups)
    return updatedFields
  }
}

/**
 * Given settings (fields), and selectField, it updates the group fields for that select field which manipulates
 * the groups to the default ones.
 *
 * @param fields the settings (fields).
 * @param selectField the select field.
 * @returns new array of the changed settings (fields) or undefined if nothing is changed.
 */
const hideOrShowGroupToDefaultForSelectField = (
  fields: SettingField[],
  selectField?: SettingSelectField,
): SettingField[] => {
  const selectFieldGroupsToShowOrHide = new Set<string>()
  const copiedFields = [...fields]

  selectField?.options?.forEach((item) => {
    if (item.hideGroups) {
      const hideGroups = item.hideGroups

      hideGroups.forEach((hideGroup) => selectFieldGroupsToShowOrHide.add(hideGroup))
    }

    if (item.showGroup) {
      selectFieldGroupsToShowOrHide.add(item.showGroup)
    }
  })

  copiedFields.forEach((item, index) => {
    const groupExistsInSelectGroups = selectFieldGroupsToShowOrHide.has(item.settingId)
    if (isGroupStart(item) && groupExistsInSelectGroups) {
      const groupSubarray = copiedFields.slice(index)
      const hide = (item.groupField as SettingGroupFieldWithDefault).defaultHidden
      hideOrShowGroupAndSubgroups(groupSubarray, hide)
    }
  })

  return copiedFields
}

// --- generic methods for showing / hiding groups / subgroups

/**
 * Given settings, changes the settings to show the groups that are passed as parameter, and hides the ones
 * that are passed as parameter
 * Returns new array of the settings (fields) with the corresponding changes to group fields
 * Exported only for tests, not used externally.
 *
 * @param fields the settings (fields).
 * @param hideGroups the groups that need to be hidden.
 * @param showGroups the groups that need to be shown.
 * @returns {SettingField[]} new array of the changed settings (fields).
 */
export const hideOrShowGroups = (
  fields: SettingField[],
  hideGroups: string[],
  showGroups: string[],
): SettingField[] | undefined => {
  const fieldsCopy = [...fields]
  let atLeastOneGroupShownOrHidden = false

  for (let i = 1; i < fieldsCopy.length; i++) {
    const item = fieldsCopy[i]
    if (isGroupStart(item) && item.groupField) {
      const includedInShowGroups = showGroups.includes(item.settingId)
      const includedInHideGroups = hideGroups.includes(item.settingId)

      // if we need to show or hide the group and it's subgroups
      if (includedInShowGroups || includedInHideGroups) {
        // make sure that the setting is not in both show and hide groups
        if (includedInShowGroups && includedInHideGroups) {
          throw new Error('Setting ' + item.settingId + ' is in both showGroups and hideGroups')
        }

        const groupSubarray = fieldsCopy.slice(i)
        const hide = includedInHideGroups
        hideOrShowGroupAndSubgroups(groupSubarray, hide)
        atLeastOneGroupShownOrHidden = true
      }
    }
  }

  return atLeastOneGroupShownOrHidden ? fieldsCopy : undefined
}

/**
 * Accept subarray of fields starting with the group that we want to hide.
 * Hides or shows the subgroups and the group in the field
 * for that given group are hidden or shown corresponding to the passed parameter hide.
 * IMPORTANT: Mutates the objects in the given array
 *
 * @param fieldsWithSubgroups the sub array of settings (fields).
 * @param hide parameter for hiding or showing the groups and the subgroups
 */
const hideOrShowGroupAndSubgroups = (fieldsWithSubgroups: SettingField[], hide: boolean) => {
  const groupAndSubgroupsStack: SettingField[] = []

  // push first item (the first selected group for hiding / showing)
  groupAndSubgroupsStack.push(fieldsWithSubgroups[0])

  // start with the second element
  for (let i = 1; i < fieldsWithSubgroups.length; i++) {
    // if the stack is empty, all the endings for the group and subgroups were found
    if (groupAndSubgroupsStack.length === 0) {
      break
    }

    const field = fieldsWithSubgroups[i]

    if (field.fieldType === SettingFieldType.GROUP) {
      // if end of group is found, pop the group and (hide/show) it
      if (isGroupEnd(field)) {
        // end of existing group
        const groupStart = groupAndSubgroupsStack.pop()

        if (groupStart && groupStart.groupField) {
          groupStart.groupField.hidden = hide
        }
      } else {
        // start of new group, push it in the stack
        groupAndSubgroupsStack.push(field)
      }
    }
  }
}

// --- filtering of hidden fields

/**
 * Filters settings (fields) which are not shown on the screen (ex: fields which are hidden)
 * @param fields the settings (fields).
 * @param returnGroups true to return groups as well
 * @returns {SettingFieldWithIndex} new array of the filtered settings, with additional field index property.
 */
export const filterHiddenSettingFieldInputs = (
  fields: SettingField[],
  returnGroups: boolean,
): SettingFieldWithIndex[] => {
  const filteredFields: SettingFieldWithIndex[] = []

  const groupsStack: SettingField[] = []

  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]
    const fieldType = field.fieldType

    let shouldAddItem = true

    if (fieldType === SettingFieldType.GROUP) {
      if (isGroupStart(field)) {
        groupsStack.push(field)
      } else if (isGroupEnd(field)) {
        groupsStack.pop()
      }
    } else {
      // normal field

      // check if it is part of hidden group
      const lastGroup = groupsStack.length > 0 ? groupsStack.at(groupsStack.length - 1) : undefined
      const lastGroupHidden = lastGroup ? lastGroup.groupField?.hidden : false

      if (lastGroupHidden) {
        shouldAddItem = false
      }
    }

    // check if we don't need groups and the item is group
    if (!returnGroups && field.fieldType === SettingFieldType.GROUP) {
      // we don't need groups, skip the group item
      shouldAddItem = false
    }

    if (shouldAddItem) {
      filteredFields.push({ ...field, fieldIndex: i })
    }
  }

  return filteredFields
}
