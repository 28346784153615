/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChartData,
  ChartScale,
  ChartType,
  Currency,
  DatatransPaymentMethod,
  LaundryGroupReference,
  LaundryReferenceSimple,
  LaundryUserReference,
  MachineCategory,
  MachineReference,
  OrganizationReference,
  PageableTopUps,
  PageableTopUpsGrouped,
  PageableUsagesGrouped,
  PageableWMUsages,
  PaymentMethod,
  ReportTopUpType,
  TopUpChartFactType,
  TopUpChartGroupBy,
  TopUpGroupBy,
  UsageChartFactType,
  UsageChartGroupBy,
  UsageGroupBy,
} from '../models';
import {
    ChartDataFromJSON,
    ChartDataToJSON,
    ChartScaleFromJSON,
    ChartScaleToJSON,
    ChartTypeFromJSON,
    ChartTypeToJSON,
    CurrencyFromJSON,
    CurrencyToJSON,
    DatatransPaymentMethodFromJSON,
    DatatransPaymentMethodToJSON,
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceToJSON,
    LaundryReferenceSimpleFromJSON,
    LaundryReferenceSimpleToJSON,
    LaundryUserReferenceFromJSON,
    LaundryUserReferenceToJSON,
    MachineCategoryFromJSON,
    MachineCategoryToJSON,
    MachineReferenceFromJSON,
    MachineReferenceToJSON,
    OrganizationReferenceFromJSON,
    OrganizationReferenceToJSON,
    PageableTopUpsFromJSON,
    PageableTopUpsToJSON,
    PageableTopUpsGroupedFromJSON,
    PageableTopUpsGroupedToJSON,
    PageableUsagesGroupedFromJSON,
    PageableUsagesGroupedToJSON,
    PageableWMUsagesFromJSON,
    PageableWMUsagesToJSON,
    PaymentMethodFromJSON,
    PaymentMethodToJSON,
    ReportTopUpTypeFromJSON,
    ReportTopUpTypeToJSON,
    TopUpChartFactTypeFromJSON,
    TopUpChartFactTypeToJSON,
    TopUpChartGroupByFromJSON,
    TopUpChartGroupByToJSON,
    TopUpGroupByFromJSON,
    TopUpGroupByToJSON,
    UsageChartFactTypeFromJSON,
    UsageChartFactTypeToJSON,
    UsageChartGroupByFromJSON,
    UsageChartGroupByToJSON,
    UsageGroupByFromJSON,
    UsageGroupByToJSON,
} from '../models';

export interface ReportWmTopUpsChartGetRequest {
    groupBy: TopUpChartGroupBy;
    factType: TopUpChartFactType;
    scale: ChartScale;
    settledDateTimeFrom: Date;
    settledDateTimeTo: Date;
    chartType?: ChartType;
    currency?: Currency;
    reportTopUpType?: ReportTopUpType;
    laundryUserId?: string;
    datatransPaymentMethod?: DatatransPaymentMethod;
}

export interface ReportWmTopUpsCsvGetRequest {
    settledDateTimeFrom: Date;
    settledDateTimeTo: Date;
    currency?: Currency;
    reportTopUpType?: ReportTopUpType;
    laundryUserId?: string;
    bankingReference?: string;
    datatransPaymentMethod?: DatatransPaymentMethod;
}

export interface ReportWmTopUpsGetRequest {
    settledDateTimeFrom: Date;
    settledDateTimeTo: Date;
    size?: number;
    page?: number;
    sort?: string;
    currency?: Currency;
    reportTopUpType?: ReportTopUpType;
    laundryUserId?: string;
    bankingReference?: string;
    datatransPaymentMethod?: DatatransPaymentMethod;
}

export interface ReportWmTopUpsGroupedGetRequest {
    groupBy: Array<TopUpGroupBy>;
    settledDateTimeFrom: Date;
    settledDateTimeTo: Date;
    size?: number;
    page?: number;
    sort?: string;
    laundryUserId?: string;
    currency?: Currency;
    reportTopUpType?: ReportTopUpType;
}

export interface ReportWmTopUpsLaundryusersRefGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    search?: string;
}

export interface ReportWmUsagesChartGetRequest {
    groupBy: UsageChartGroupBy;
    factType: UsageChartFactType;
    scale: ChartScale;
    startTimeFrom: Date;
    startTimeTo: Date;
    chartType?: ChartType;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
    laundryUserId?: string;
    currency?: Currency;
    paymentMethod?: PaymentMethod;
    datatransPaymentMethod?: DatatransPaymentMethod;
    machineCategory?: MachineCategory;
}

export interface ReportWmUsagesCsvGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
    laundryUserId?: string;
    currency?: Currency;
    paymentMethod?: PaymentMethod;
    datatransPaymentMethod?: DatatransPaymentMethod;
    machineCategory?: MachineCategory;
}

export interface ReportWmUsagesGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    size?: number;
    page?: number;
    sort?: string;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
    laundryUserId?: string;
    currency?: Currency;
    paymentMethod?: PaymentMethod;
    datatransPaymentMethod?: DatatransPaymentMethod;
    machineCategory?: MachineCategory;
}

export interface ReportWmUsagesGroupedGetRequest {
    groupBy: Array<UsageGroupBy>;
    startTimeFrom: Date;
    startTimeTo: Date;
    size?: number;
    page?: number;
    sort?: string;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
    laundryUserId?: string;
    currency?: Currency;
    paymentMethod?: PaymentMethod;
    datatransPaymentMethod?: DatatransPaymentMethod;
    machineCategory?: MachineCategory;
}

export interface ReportWmUsagesLaundriesRefGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    search?: string;
    laundryGroupId?: Array<string>;
    organizationId?: Array<string>;
}

export interface ReportWmUsagesLaundrygroupsRefGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    search?: string;
    organizationId?: Array<string>;
}

export interface ReportWmUsagesLaundryusersRefGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    search?: string;
    laundryGroupId?: Array<string>;
}

export interface ReportWmUsagesMachinesRefGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    search?: string;
    laundryId?: Array<string>;
    laundryGroupId?: Array<string>;
    organizationId?: Array<string>;
}

export interface ReportWmUsagesOrganizationsRefGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    search?: string;
}

/**
 * 
 */
export class ReportWashMasterApi extends runtime.BaseAPI {

    /**
     * List Charting data for TopUps
     */
    async reportWmTopUpsChartGetRaw(requestParameters: ReportWmTopUpsChartGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChartData>> {
        if (requestParameters.groupBy === null || requestParameters.groupBy === undefined) {
            throw new runtime.RequiredError('groupBy','Required parameter requestParameters.groupBy was null or undefined when calling reportWmTopUpsChartGet.');
        }

        if (requestParameters.factType === null || requestParameters.factType === undefined) {
            throw new runtime.RequiredError('factType','Required parameter requestParameters.factType was null or undefined when calling reportWmTopUpsChartGet.');
        }

        if (requestParameters.scale === null || requestParameters.scale === undefined) {
            throw new runtime.RequiredError('scale','Required parameter requestParameters.scale was null or undefined when calling reportWmTopUpsChartGet.');
        }

        if (requestParameters.settledDateTimeFrom === null || requestParameters.settledDateTimeFrom === undefined) {
            throw new runtime.RequiredError('settledDateTimeFrom','Required parameter requestParameters.settledDateTimeFrom was null or undefined when calling reportWmTopUpsChartGet.');
        }

        if (requestParameters.settledDateTimeTo === null || requestParameters.settledDateTimeTo === undefined) {
            throw new runtime.RequiredError('settledDateTimeTo','Required parameter requestParameters.settledDateTimeTo was null or undefined when calling reportWmTopUpsChartGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        if (requestParameters.factType !== undefined) {
            queryParameters['factType'] = requestParameters.factType;
        }

        if (requestParameters.scale !== undefined) {
            queryParameters['scale'] = requestParameters.scale;
        }

        if (requestParameters.chartType !== undefined) {
            queryParameters['chartType'] = requestParameters.chartType;
        }

        if (requestParameters.settledDateTimeFrom !== undefined) {
            queryParameters['settledDateTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.settledDateTimeFrom as any);
        }

        if (requestParameters.settledDateTimeTo !== undefined) {
            queryParameters['settledDateTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.settledDateTimeTo as any);
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.reportTopUpType !== undefined) {
            queryParameters['reportTopUpType'] = requestParameters.reportTopUpType;
        }

        if (requestParameters.laundryUserId !== undefined) {
            queryParameters['laundryUserId'] = requestParameters.laundryUserId;
        }

        if (requestParameters.datatransPaymentMethod !== undefined) {
            queryParameters['datatransPaymentMethod'] = requestParameters.datatransPaymentMethod;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/top-ups/chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChartDataFromJSON(jsonValue));
    }

    /**
     * List Charting data for TopUps
     */
    async reportWmTopUpsChartGet(groupBy: TopUpChartGroupBy, factType: TopUpChartFactType, scale: ChartScale, settledDateTimeFrom: Date, settledDateTimeTo: Date, chartType?: ChartType, currency?: Currency, reportTopUpType?: ReportTopUpType, laundryUserId?: string, datatransPaymentMethod?: DatatransPaymentMethod, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChartData> {
        const response = await this.reportWmTopUpsChartGetRaw({ groupBy: groupBy, factType: factType, scale: scale, settledDateTimeFrom: settledDateTimeFrom, settledDateTimeTo: settledDateTimeTo, chartType: chartType, currency: currency, reportTopUpType: reportTopUpType, laundryUserId: laundryUserId, datatransPaymentMethod: datatransPaymentMethod }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a CSV file of TopUps
     */
    async reportWmTopUpsCsvGetRaw(requestParameters: ReportWmTopUpsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.settledDateTimeFrom === null || requestParameters.settledDateTimeFrom === undefined) {
            throw new runtime.RequiredError('settledDateTimeFrom','Required parameter requestParameters.settledDateTimeFrom was null or undefined when calling reportWmTopUpsCsvGet.');
        }

        if (requestParameters.settledDateTimeTo === null || requestParameters.settledDateTimeTo === undefined) {
            throw new runtime.RequiredError('settledDateTimeTo','Required parameter requestParameters.settledDateTimeTo was null or undefined when calling reportWmTopUpsCsvGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.settledDateTimeFrom !== undefined) {
            queryParameters['settledDateTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.settledDateTimeFrom as any);
        }

        if (requestParameters.settledDateTimeTo !== undefined) {
            queryParameters['settledDateTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.settledDateTimeTo as any);
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.reportTopUpType !== undefined) {
            queryParameters['reportTopUpType'] = requestParameters.reportTopUpType;
        }

        if (requestParameters.laundryUserId !== undefined) {
            queryParameters['laundryUserId'] = requestParameters.laundryUserId;
        }

        if (requestParameters.bankingReference !== undefined) {
            queryParameters['bankingReference'] = requestParameters.bankingReference;
        }

        if (requestParameters.datatransPaymentMethod !== undefined) {
            queryParameters['datatransPaymentMethod'] = requestParameters.datatransPaymentMethod;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/top-ups/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Returns a CSV file of TopUps
     */
    async reportWmTopUpsCsvGet(settledDateTimeFrom: Date, settledDateTimeTo: Date, currency?: Currency, reportTopUpType?: ReportTopUpType, laundryUserId?: string, bankingReference?: string, datatransPaymentMethod?: DatatransPaymentMethod, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.reportWmTopUpsCsvGetRaw({ settledDateTimeFrom: settledDateTimeFrom, settledDateTimeTo: settledDateTimeTo, currency: currency, reportTopUpType: reportTopUpType, laundryUserId: laundryUserId, bankingReference: bankingReference, datatransPaymentMethod: datatransPaymentMethod }, initOverrides);
        return await response.value();
    }

    /**
     * List TopUps, Result Media Type depending on Accept Header
     */
    async reportWmTopUpsGetRaw(requestParameters: ReportWmTopUpsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableTopUps>> {
        if (requestParameters.settledDateTimeFrom === null || requestParameters.settledDateTimeFrom === undefined) {
            throw new runtime.RequiredError('settledDateTimeFrom','Required parameter requestParameters.settledDateTimeFrom was null or undefined when calling reportWmTopUpsGet.');
        }

        if (requestParameters.settledDateTimeTo === null || requestParameters.settledDateTimeTo === undefined) {
            throw new runtime.RequiredError('settledDateTimeTo','Required parameter requestParameters.settledDateTimeTo was null or undefined when calling reportWmTopUpsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.settledDateTimeFrom !== undefined) {
            queryParameters['settledDateTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.settledDateTimeFrom as any);
        }

        if (requestParameters.settledDateTimeTo !== undefined) {
            queryParameters['settledDateTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.settledDateTimeTo as any);
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.reportTopUpType !== undefined) {
            queryParameters['reportTopUpType'] = requestParameters.reportTopUpType;
        }

        if (requestParameters.laundryUserId !== undefined) {
            queryParameters['laundryUserId'] = requestParameters.laundryUserId;
        }

        if (requestParameters.bankingReference !== undefined) {
            queryParameters['bankingReference'] = requestParameters.bankingReference;
        }

        if (requestParameters.datatransPaymentMethod !== undefined) {
            queryParameters['datatransPaymentMethod'] = requestParameters.datatransPaymentMethod;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/top-ups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableTopUpsFromJSON(jsonValue));
    }

    /**
     * List TopUps, Result Media Type depending on Accept Header
     */
    async reportWmTopUpsGet(settledDateTimeFrom: Date, settledDateTimeTo: Date, size?: number, page?: number, sort?: string, currency?: Currency, reportTopUpType?: ReportTopUpType, laundryUserId?: string, bankingReference?: string, datatransPaymentMethod?: DatatransPaymentMethod, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableTopUps> {
        const response = await this.reportWmTopUpsGetRaw({ settledDateTimeFrom: settledDateTimeFrom, settledDateTimeTo: settledDateTimeTo, size: size, page: page, sort: sort, currency: currency, reportTopUpType: reportTopUpType, laundryUserId: laundryUserId, bankingReference: bankingReference, datatransPaymentMethod: datatransPaymentMethod }, initOverrides);
        return await response.value();
    }

    /**
     * List TopUps Grouped, Result Media Type depending on Accept Header
     */
    async reportWmTopUpsGroupedGetRaw(requestParameters: ReportWmTopUpsGroupedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableTopUpsGrouped>> {
        if (requestParameters.groupBy === null || requestParameters.groupBy === undefined) {
            throw new runtime.RequiredError('groupBy','Required parameter requestParameters.groupBy was null or undefined when calling reportWmTopUpsGroupedGet.');
        }

        if (requestParameters.settledDateTimeFrom === null || requestParameters.settledDateTimeFrom === undefined) {
            throw new runtime.RequiredError('settledDateTimeFrom','Required parameter requestParameters.settledDateTimeFrom was null or undefined when calling reportWmTopUpsGroupedGet.');
        }

        if (requestParameters.settledDateTimeTo === null || requestParameters.settledDateTimeTo === undefined) {
            throw new runtime.RequiredError('settledDateTimeTo','Required parameter requestParameters.settledDateTimeTo was null or undefined when calling reportWmTopUpsGroupedGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.groupBy) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        if (requestParameters.settledDateTimeFrom !== undefined) {
            queryParameters['settledDateTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.settledDateTimeFrom as any);
        }

        if (requestParameters.settledDateTimeTo !== undefined) {
            queryParameters['settledDateTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.settledDateTimeTo as any);
        }

        if (requestParameters.laundryUserId !== undefined) {
            queryParameters['laundryUserId'] = requestParameters.laundryUserId;
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.reportTopUpType !== undefined) {
            queryParameters['reportTopUpType'] = requestParameters.reportTopUpType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/top-ups/grouped`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableTopUpsGroupedFromJSON(jsonValue));
    }

    /**
     * List TopUps Grouped, Result Media Type depending on Accept Header
     */
    async reportWmTopUpsGroupedGet(groupBy: Array<TopUpGroupBy>, settledDateTimeFrom: Date, settledDateTimeTo: Date, size?: number, page?: number, sort?: string, laundryUserId?: string, currency?: Currency, reportTopUpType?: ReportTopUpType, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableTopUpsGrouped> {
        const response = await this.reportWmTopUpsGroupedGetRaw({ groupBy: groupBy, settledDateTimeFrom: settledDateTimeFrom, settledDateTimeTo: settledDateTimeTo, size: size, page: page, sort: sort, laundryUserId: laundryUserId, currency: currency, reportTopUpType: reportTopUpType }, initOverrides);
        return await response.value();
    }

    /**
     * In comparison to /laundryusers/ref contains also \'Anonymous Laundryuser\' etc.
     * List Laundry User References
     */
    async reportWmTopUpsLaundryusersRefGetRaw(requestParameters: ReportWmTopUpsLaundryusersRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaundryUserReference>>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportWmTopUpsLaundryusersRefGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportWmTopUpsLaundryusersRefGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/top-ups/laundryusers/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaundryUserReferenceFromJSON));
    }

    /**
     * In comparison to /laundryusers/ref contains also \'Anonymous Laundryuser\' etc.
     * List Laundry User References
     */
    async reportWmTopUpsLaundryusersRefGet(startTimeFrom: Date, startTimeTo: Date, search?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaundryUserReference>> {
        const response = await this.reportWmTopUpsLaundryusersRefGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, search: search }, initOverrides);
        return await response.value();
    }

    /**
     * List Charting data for Usages
     */
    async reportWmUsagesChartGetRaw(requestParameters: ReportWmUsagesChartGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChartData>> {
        if (requestParameters.groupBy === null || requestParameters.groupBy === undefined) {
            throw new runtime.RequiredError('groupBy','Required parameter requestParameters.groupBy was null or undefined when calling reportWmUsagesChartGet.');
        }

        if (requestParameters.factType === null || requestParameters.factType === undefined) {
            throw new runtime.RequiredError('factType','Required parameter requestParameters.factType was null or undefined when calling reportWmUsagesChartGet.');
        }

        if (requestParameters.scale === null || requestParameters.scale === undefined) {
            throw new runtime.RequiredError('scale','Required parameter requestParameters.scale was null or undefined when calling reportWmUsagesChartGet.');
        }

        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportWmUsagesChartGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportWmUsagesChartGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        if (requestParameters.factType !== undefined) {
            queryParameters['factType'] = requestParameters.factType;
        }

        if (requestParameters.scale !== undefined) {
            queryParameters['scale'] = requestParameters.scale;
        }

        if (requestParameters.chartType !== undefined) {
            queryParameters['chartType'] = requestParameters.chartType;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.laundryUserId !== undefined) {
            queryParameters['laundryUserId'] = requestParameters.laundryUserId;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.paymentMethod !== undefined) {
            queryParameters['paymentMethod'] = requestParameters.paymentMethod;
        }

        if (requestParameters.datatransPaymentMethod !== undefined) {
            queryParameters['datatransPaymentMethod'] = requestParameters.datatransPaymentMethod;
        }

        if (requestParameters.machineCategory !== undefined) {
            queryParameters['machineCategory'] = requestParameters.machineCategory;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/usages/chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChartDataFromJSON(jsonValue));
    }

    /**
     * List Charting data for Usages
     */
    async reportWmUsagesChartGet(groupBy: UsageChartGroupBy, factType: UsageChartFactType, scale: ChartScale, startTimeFrom: Date, startTimeTo: Date, chartType?: ChartType, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, laundryUserId?: string, currency?: Currency, paymentMethod?: PaymentMethod, datatransPaymentMethod?: DatatransPaymentMethod, machineCategory?: MachineCategory, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChartData> {
        const response = await this.reportWmUsagesChartGetRaw({ groupBy: groupBy, factType: factType, scale: scale, startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, chartType: chartType, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId, laundryUserId: laundryUserId, currency: currency, paymentMethod: paymentMethod, datatransPaymentMethod: datatransPaymentMethod, machineCategory: machineCategory }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a CSV file of single usages
     */
    async reportWmUsagesCsvGetRaw(requestParameters: ReportWmUsagesCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportWmUsagesCsvGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportWmUsagesCsvGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.laundryUserId !== undefined) {
            queryParameters['laundryUserId'] = requestParameters.laundryUserId;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.paymentMethod !== undefined) {
            queryParameters['paymentMethod'] = requestParameters.paymentMethod;
        }

        if (requestParameters.datatransPaymentMethod !== undefined) {
            queryParameters['datatransPaymentMethod'] = requestParameters.datatransPaymentMethod;
        }

        if (requestParameters.machineCategory !== undefined) {
            queryParameters['machineCategory'] = requestParameters.machineCategory;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/usages/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Returns a CSV file of single usages
     */
    async reportWmUsagesCsvGet(startTimeFrom: Date, startTimeTo: Date, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, laundryUserId?: string, currency?: Currency, paymentMethod?: PaymentMethod, datatransPaymentMethod?: DatatransPaymentMethod, machineCategory?: MachineCategory, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.reportWmUsagesCsvGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId, laundryUserId: laundryUserId, currency: currency, paymentMethod: paymentMethod, datatransPaymentMethod: datatransPaymentMethod, machineCategory: machineCategory }, initOverrides);
        return await response.value();
    }

    /**
     * List Single Usages
     */
    async reportWmUsagesGetRaw(requestParameters: ReportWmUsagesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableWMUsages>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportWmUsagesGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportWmUsagesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.laundryUserId !== undefined) {
            queryParameters['laundryUserId'] = requestParameters.laundryUserId;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.paymentMethod !== undefined) {
            queryParameters['paymentMethod'] = requestParameters.paymentMethod;
        }

        if (requestParameters.datatransPaymentMethod !== undefined) {
            queryParameters['datatransPaymentMethod'] = requestParameters.datatransPaymentMethod;
        }

        if (requestParameters.machineCategory !== undefined) {
            queryParameters['machineCategory'] = requestParameters.machineCategory;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/usages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableWMUsagesFromJSON(jsonValue));
    }

    /**
     * List Single Usages
     */
    async reportWmUsagesGet(startTimeFrom: Date, startTimeTo: Date, size?: number, page?: number, sort?: string, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, laundryUserId?: string, currency?: Currency, paymentMethod?: PaymentMethod, datatransPaymentMethod?: DatatransPaymentMethod, machineCategory?: MachineCategory, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableWMUsages> {
        const response = await this.reportWmUsagesGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, size: size, page: page, sort: sort, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId, laundryUserId: laundryUserId, currency: currency, paymentMethod: paymentMethod, datatransPaymentMethod: datatransPaymentMethod, machineCategory: machineCategory }, initOverrides);
        return await response.value();
    }

    /**
     * There will be always a grouped by Currency
     * List Usages Grouped by some attributes, Result Media Type depending on Accept Header
     */
    async reportWmUsagesGroupedGetRaw(requestParameters: ReportWmUsagesGroupedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableUsagesGrouped>> {
        if (requestParameters.groupBy === null || requestParameters.groupBy === undefined) {
            throw new runtime.RequiredError('groupBy','Required parameter requestParameters.groupBy was null or undefined when calling reportWmUsagesGroupedGet.');
        }

        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportWmUsagesGroupedGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportWmUsagesGroupedGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.groupBy) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.laundryUserId !== undefined) {
            queryParameters['laundryUserId'] = requestParameters.laundryUserId;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.paymentMethod !== undefined) {
            queryParameters['paymentMethod'] = requestParameters.paymentMethod;
        }

        if (requestParameters.datatransPaymentMethod !== undefined) {
            queryParameters['datatransPaymentMethod'] = requestParameters.datatransPaymentMethod;
        }

        if (requestParameters.machineCategory !== undefined) {
            queryParameters['machineCategory'] = requestParameters.machineCategory;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/usages/grouped`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableUsagesGroupedFromJSON(jsonValue));
    }

    /**
     * There will be always a grouped by Currency
     * List Usages Grouped by some attributes, Result Media Type depending on Accept Header
     */
    async reportWmUsagesGroupedGet(groupBy: Array<UsageGroupBy>, startTimeFrom: Date, startTimeTo: Date, size?: number, page?: number, sort?: string, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, laundryUserId?: string, currency?: Currency, paymentMethod?: PaymentMethod, datatransPaymentMethod?: DatatransPaymentMethod, machineCategory?: MachineCategory, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableUsagesGrouped> {
        const response = await this.reportWmUsagesGroupedGetRaw({ groupBy: groupBy, startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, size: size, page: page, sort: sort, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId, laundryUserId: laundryUserId, currency: currency, paymentMethod: paymentMethod, datatransPaymentMethod: datatransPaymentMethod, machineCategory: machineCategory }, initOverrides);
        return await response.value();
    }

    /**
     * List Laundry References
     */
    async reportWmUsagesLaundriesRefGetRaw(requestParameters: ReportWmUsagesLaundriesRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaundryReferenceSimple>>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportWmUsagesLaundriesRefGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportWmUsagesLaundriesRefGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.laundryGroupId) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.organizationId) {
            queryParameters['organizationId'] = requestParameters.organizationId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/usages/laundries/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaundryReferenceSimpleFromJSON));
    }

    /**
     * List Laundry References
     */
    async reportWmUsagesLaundriesRefGet(startTimeFrom: Date, startTimeTo: Date, search?: string, laundryGroupId?: Array<string>, organizationId?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaundryReferenceSimple>> {
        const response = await this.reportWmUsagesLaundriesRefGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, search: search, laundryGroupId: laundryGroupId, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     * List Laundry Group References
     */
    async reportWmUsagesLaundrygroupsRefGetRaw(requestParameters: ReportWmUsagesLaundrygroupsRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaundryGroupReference>>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportWmUsagesLaundrygroupsRefGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportWmUsagesLaundrygroupsRefGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.organizationId) {
            queryParameters['organizationId'] = requestParameters.organizationId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/usages/laundrygroups/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaundryGroupReferenceFromJSON));
    }

    /**
     * List Laundry Group References
     */
    async reportWmUsagesLaundrygroupsRefGet(startTimeFrom: Date, startTimeTo: Date, search?: string, organizationId?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaundryGroupReference>> {
        const response = await this.reportWmUsagesLaundrygroupsRefGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, search: search, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     * In comparison to /laundryusers/ref contains also \'anonymous Laundryuser\', \'App Clip User\', \'Instant App User\' etc.
     * List Laundry User References
     */
    async reportWmUsagesLaundryusersRefGetRaw(requestParameters: ReportWmUsagesLaundryusersRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaundryUserReference>>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportWmUsagesLaundryusersRefGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportWmUsagesLaundryusersRefGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.laundryGroupId) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/usages/laundryusers/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaundryUserReferenceFromJSON));
    }

    /**
     * In comparison to /laundryusers/ref contains also \'anonymous Laundryuser\', \'App Clip User\', \'Instant App User\' etc.
     * List Laundry User References
     */
    async reportWmUsagesLaundryusersRefGet(startTimeFrom: Date, startTimeTo: Date, search?: string, laundryGroupId?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaundryUserReference>> {
        const response = await this.reportWmUsagesLaundryusersRefGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, search: search, laundryGroupId: laundryGroupId }, initOverrides);
        return await response.value();
    }

    /**
     * List Machine References
     */
    async reportWmUsagesMachinesRefGetRaw(requestParameters: ReportWmUsagesMachinesRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MachineReference>>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportWmUsagesMachinesRefGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportWmUsagesMachinesRefGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.laundryId) {
            queryParameters['laundryId'] = requestParameters.laundryId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.laundryGroupId) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.organizationId) {
            queryParameters['organizationId'] = requestParameters.organizationId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/usages/machines/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineReferenceFromJSON));
    }

    /**
     * List Machine References
     */
    async reportWmUsagesMachinesRefGet(startTimeFrom: Date, startTimeTo: Date, search?: string, laundryId?: Array<string>, laundryGroupId?: Array<string>, organizationId?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MachineReference>> {
        const response = await this.reportWmUsagesMachinesRefGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, search: search, laundryId: laundryId, laundryGroupId: laundryGroupId, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     * List Organizations References
     */
    async reportWmUsagesOrganizationsRefGetRaw(requestParameters: ReportWmUsagesOrganizationsRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrganizationReference>>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportWmUsagesOrganizationsRefGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportWmUsagesOrganizationsRefGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/usages/organizations/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationReferenceFromJSON));
    }

    /**
     * List Organizations References
     */
    async reportWmUsagesOrganizationsRefGet(startTimeFrom: Date, startTimeTo: Date, search?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrganizationReference>> {
        const response = await this.reportWmUsagesOrganizationsRefGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, search: search }, initOverrides);
        return await response.value();
    }

}
