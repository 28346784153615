import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { useTranslate } from 'src/i18n/useMessageSource'
import { CURRENCIES, CurrencyObject } from 'src/service/view-model/base/payment/Currencies'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

export interface CurrencyAutoCompleteProps {
  onChange: (option: CurrencyObject | undefined) => void
  name: string
  value: CurrencyObject | undefined
  textFieldClassName?: string
  disabled: boolean
  error?: boolean
}

export const CurrencyAutoComplete: React.FC<CurrencyAutoCompleteProps> = ({
  onChange,
  value,
  name,
  disabled = false,
  textFieldClassName,
  error,
}) => {
  const translate = useTranslate()

  const handleOnChange = (_event: React.SyntheticEvent, object: CurrencyObject | null) => {
    onChange(object ? object : undefined)
  }

  return (
    <Autocomplete
      id="currency-autocomplete"
      openOnFocus={true}
      options={CURRENCIES}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      autoHighlight
      disableClearable={false}
      multiple={false}
      freeSolo={false}
      onChange={handleOnChange}
      disabled={disabled}
      value={value}
      groupBy={(option) => option.group}
      renderInput={(params) => (
        <TextFieldDefault
          name={name}
          className={textFieldClassName}
          error={error}
          {...params}
          label={translate('currency')}
        />
      )}
    />
  )
}
