import React, { FC, ReactElement, useState } from 'react'
import { Icon } from '@mdi/react'
import { Box, Button, Grid, Stack, Typography, styled } from '@mui/material'
import PublishIcon from '@mui/icons-material/Publish'
import { mdiFileCancelOutline, mdiFileCheckOutline } from '@mdi/js'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { FileFormat } from 'src/ui-shared/constants/Constants'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'

const DropZone = styled(Box)<{
  dragover: boolean
}>(({ dragover, theme }) => ({
  width: '100%',
  padding: '10%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column nowrap',
  border: '2px black dashed',
  borderColor: dragover ? theme.palette.primary.main : 'lightgray',
  borderRadius: '12px',
  '& .MuiTypography-root': {
    zIndex: -1,
    color: dragover ? theme.palette.primary.main : 'lightgray',
  },
  '& .MuiSvgIcon-root': {
    zIndex: -1,
    color: dragover ? theme.palette.primary.main : 'lightgray',
  },
  zIndex: 1,
}))

interface Props {
  acceptedFileFormat: FileFormat[]
  acceptedFileSizeMB: number
  acceptMultipleFiles: boolean
  setFiles: (files: File[] | null) => void
}

export const UploadFileField: FC<Props> = ({
  acceptedFileFormat,
  acceptedFileSizeMB,
  acceptMultipleFiles,
  setFiles,
}): ReactElement => {
  const translate = useTranslate()

  // state
  const [dragover, setDragover] = useState<boolean>(false)
  const [fileNames, setFileNames] = useState<string[] | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // validators
  const isAcceptedFormat = (fileName: string): boolean => {
    return acceptedFileFormat.some((format) => fileName.endsWith(format))
  }

  const isAcceptedSize = (fileSizeB: number): boolean => {
    const fileSizeMB = fileSizeB / 1000000
    return fileSizeMB <= acceptedFileSizeMB
  }

  // handle events
  function handleDragEnter(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()

    setDragover(true)
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()

    const { files } = e.dataTransfer

    handleValidateUploadedFiles(files)
  }

  function handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()

    setDragover(false)
  }

  function handleChooseFile(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target

    if (files) {
      handleValidateUploadedFiles(files)
    }
  }

  const handleValidateUploadedFiles = (files: FileList) => {
    const filesLength = files.length
    const validFiles: File[] = []

    setErrorMessage(null)
    setFileNames(null)
    setDragover(false)

    if (files.length !== 1 && !acceptMultipleFiles) {
      setErrorMessage(translate('nmbFilesUploaded.valid'))
      setFiles(null)
      return
    }

    for (let i = 0; i < filesLength; i++) {
      const file = files.item(i)

      if (file) {
        // atomic transaction, if one file does not pass the acceptance criteria then fail all
        if (!isAcceptedFormat(file.name)) {
          setErrorMessage(translate('fileFormat.valid', file.name, acceptedFileFormat.join(', ')))
          break
        }

        if (!isAcceptedSize(file.size)) {
          setErrorMessage(translate('fileSize.valid', file.name, acceptedFileSizeMB))
          break
        }

        validFiles.push(file)
      }
    }

    if (validFiles.length !== files.length) {
      setFiles(null)
      setFileNames(null)
    } else {
      setFiles(validFiles)
      setFileNames(validFiles.map((file) => file.name))
    }
  }

  return (
    <>
      {errorMessage && <ErrorMessage message={errorMessage} />}

      <Grid container spacing={2} mt={1} mb={2}>
        <Grid item {...ITEM_BREAKPOINTS} md={12} lg={6}>
          <Stack direction="column" height={'100%'} spacing={2} alignItems={'center'} justifyContent={'center'}>
            {/* Accepted file formats message */}
            <Typography variant="h5" fontWeight={500} pl={1} color={'gray'}>
              {translate('sourceFile', acceptedFileFormat.join(', ').toUpperCase())}
            </Typography>

            {/* Browse files button */}
            <Button variant="outlined" size="large" component="label" color="primary">
              {translate('button.chooseFile')}
              <input type="file" hidden multiple={acceptMultipleFiles} onChange={handleChooseFile} />
            </Button>

            {/* Uploaded files */}
            <Box>
              {fileNames === null ? (
                <Box display={'flex'}>
                  <Icon path={mdiFileCancelOutline} size={1} color={'gray'} />
                  <Typography variant="h6" fontWeight={500} pl={1} color={'gray'}>
                    {translate('noFileSelected')}
                  </Typography>
                </Box>
              ) : (
                fileNames.map((fileName) => {
                  return (
                    <Box display={'flex'} key={fileName}>
                      <Icon path={mdiFileCheckOutline} size={1} color={'gray'} />
                      <Typography variant="h6" fontWeight={500} pl={1} color={'gray'}>
                        {fileName}
                      </Typography>
                    </Box>
                  )
                })
              )}
            </Box>

            {/* Accepted file size message */}
            <Typography variant="subtitle2" fontWeight={500} pl={1} color={'gray'}>
              {translate('maxFileSize', acceptedFileSizeMB)}
            </Typography>
          </Stack>
        </Grid>

        <Grid item {...ITEM_BREAKPOINTS} md={12} lg={6}>
          <DropZone
            dragover={dragover}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragLeave}
          >
            <PublishIcon sx={{ fontSize: 70 }} />
            <Typography variant="h6" fontWeight={500}>
              {translate('dropFile')}
            </Typography>
          </DropZone>
        </Grid>
      </Grid>
    </>
  )
}
