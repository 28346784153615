/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { WMUsage } from './WMUsage';
import {
    WMUsageFromJSON,
    WMUsageFromJSONTyped,
    WMUsageToJSON,
} from './WMUsage';

/**
 * 
 * @export
 * @interface PageableWMUsages
 */
export interface PageableWMUsages {
    /**
     * 
     * @type {Array<WMUsage>}
     * @memberof PageableWMUsages
     */
    result: Array<WMUsage>;
    /**
     * 
     * @type {Page}
     * @memberof PageableWMUsages
     */
    page: Page;
}

/**
 * Check if a given object implements the PageableWMUsages interface.
 */
export function instanceOfPageableWMUsages(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageableWMUsagesFromJSON(json: any): PageableWMUsages {
    return PageableWMUsagesFromJSONTyped(json, false);
}

export function PageableWMUsagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableWMUsages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(WMUsageFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageableWMUsagesToJSON(value?: PageableWMUsages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(WMUsageToJSON)),
        'page': PageToJSON(value.page),
    };
}

