import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import {
  Configuration,
  LaundryGroupSubscription,
  LaundryGroupSubscriptionUpdate,
  LaundryGroupSubscriptionUpdateServicePackagePlanOptionsInner,
  ServicePackageOption,
  ServicePackagePlan,
  ServicePackagePlanReference,
  SubscriptionsLaundryGroupsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatAmountForLocale } from 'src/service/utils/NumberFormatUtils'
import { areEqual } from 'src/service/utils/SetUtils'
import { getPricingTitle } from 'src/service/view-model/base/payment/Payments'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { HeadCells } from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { ServicePackagePlanCard } from 'src/ui/layout-page/service-package-plan/details/ServicePackagePlanCard'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { getUserLanguageForLinks } from 'src/user/SupportedLanguage'
import { useUser, useUserRegionLocale } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'select',
    label: '',
    noSort: true,
  },
  {
    id: 'name',
    label: 'subscription.name',
    noSort: true,
  },
  {
    id: 'description',
    label: 'description',
    noSort: true,
  },
  {
    id: 'pricing',
    label: 'pricing',
    noSort: true,
  },
]

export const ServicePackagePlanUpgradePage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const { classes: tableClasses } = useTableStyles()
  const translate = useTranslate()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useUser()
  const regionLocale = useUserRegionLocale()
  const showSnackbar = useShowSnackbar()
  const { servicePackagePlanId } = useParams()
  const { laundryGroupId } = useRequiredParams(['laundryGroupId'])

  const appId = useAppId()

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack
  const language = getUserLanguageForLinks(user.locale, true, false)

  const httpConfiguration: Configuration = useContext(HttpContext)
  const subscriptionLaundryGroupsApi = new SubscriptionsLaundryGroupsApi(httpConfiguration)

  // state
  const [laundryGroupSubscription, setLaundryGroupSubscription] = useState<LaundryGroupSubscription | null>(null)
  const [selectedPlan, setSelectedPlan] = useState<ServicePackagePlan | undefined>(undefined)
  const [selectedOptions, setSelectedOptions] = useState<Set<string>>(new Set())
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // derived state
  const additionalOptions = laundryGroupSubscription?.servicePackagePlanOptions
  const activePackagePlan = laundryGroupSubscription?.activatedServicePackagePlan
  const termsUrl = `https://www.schulthess.ch/${language}/washmaster/B2Bterms`
  const stepScreen = servicePackagePlanId === undefined

  // load data
  useEffect(() => {
    subscriptionLaundryGroupsApi
      .subscriptionsLaundrygroupsLaundryGroupIdGet(laundryGroupId)
      .then((data) => {
        const packagePlans = data.servicePackagePlans
        const options = data.servicePackagePlanOptions
        const activePlanId = data.activatedServicePackagePlan.id

        // plan to be preselected
        const alreadySelectedPlan = stepScreen
          ? packagePlans.find((packagePlan) => packagePlan.id === activePlanId)
          : packagePlans.find((packagePlan) => packagePlan.id === servicePackagePlanId)

        // additional options to be checked in table
        const alreadyActiveOptions = options.filter((option) => option.activated).map((option) => option.id)

        setSelectedOptions(new Set(alreadyActiveOptions))
        setSelectedPlan(alreadySelectedPlan)
        setLaundryGroupSubscription(data)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }, [laundryGroupId])

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${AppId.WASH_MASTER}/service-package-plan/${laundryGroupId}/view`)
  }

  // handlers
  const handleSelectPlan = (plan: ServicePackagePlan) => {
    setSelectedPlan(plan)
  }

  const handleSelectOption = (selectedOption: ServicePackageOption, checked: boolean) => {
    const setOfSelectedOptions = new Set(selectedOptions)

    if (checked) {
      setOfSelectedOptions.add(selectedOption.id)
    } else {
      setOfSelectedOptions.delete(selectedOption.id)
    }
    setSelectedOptions(setOfSelectedOptions)
  }

  const handleDisableActiveButton = (): boolean => {
    const alreadyActiveOptions = additionalOptions?.filter((option) => option.activated).map((option) => option.id)

    // if not step screen do standard validation
    if (!stepScreen) {
      const dirty = isActivePackagePlan(selectedPlan) && areEqual(new Set(alreadyActiveOptions), selectedOptions)
      const noPlanSelected = selectedPlan === undefined

      return noPlanSelected || dirty
    }

    // step screen is part of creating laundry group and all fields are prefilled from be
    return false
  }

  const handleActivate = (): void => {
    if (selectedPlan) {
      // new plan selected
      const activatedServicePackagePlan: ServicePackagePlanReference = {
        id: selectedPlan.id,
        name: selectedPlan.name,
      }

      // additional features which are newly activated
      const newActiveOptions = additionalOptions?.filter((option) => isOptionSelected(option)) || []
      const activeOptions: Array<LaundryGroupSubscriptionUpdateServicePackagePlanOptionsInner> | undefined =
        newActiveOptions.map((option) => {
          const activeOption: LaundryGroupSubscriptionUpdateServicePackagePlanOptionsInner = {
            id: option.id,
          }

          return activeOption
        })

      // payload
      const laundryGroupSubscriptionUpdate: LaundryGroupSubscriptionUpdate = {
        activatedServicePackagePlan: activatedServicePackagePlan,
        servicePackagePlanOptions: activeOptions,
      }

      subscriptionLaundryGroupsApi
        .subscriptionsLaundrygroupsLaundryGroupIdPut(laundryGroupId, undefined, laundryGroupSubscriptionUpdate)
        .then(() => {
          const successMessage = translate('laundryGroup.submitSuccess')
          showSnackbar(successMessage, 'success')

          setTimeout(() => {
            navigate(`/${appId}/laundry-groups/${laundryGroupId}/view/service-plans`)
          }, 1000)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
        })
    }
  }

  const isOptionSelected = (additionalOption: ServicePackageOption): boolean => {
    return selectedOptions.has(additionalOption.id)
  }

  const isActivePackagePlan = (plan?: ServicePackagePlan): boolean => {
    if (laundryGroupSubscription && plan) {
      return activePackagePlan?.id === plan.id
    }
    return false
  }

  // JSX
  const displayHeaders = headCells.map((item) => {
    return <TableCell key={item.id}>{translate(item.label)}</TableCell>
  })

  const nonEmptySelectableRows =
    additionalOptions &&
    additionalOptions.map((item, index) => {
      return (
        <TableRow
          className={tableClasses.tableRow}
          key={index}
          onClick={() => handleSelectOption(item, !isOptionSelected(item))}
          selected={isOptionSelected(item)}
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              onChange={(_, checked) => handleSelectOption(item, checked)}
              onClick={(e) => e.stopPropagation()}
              checked={isOptionSelected(item)}
            />
          </TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.description}</TableCell>
          <TableCell>
            {getPricingTitle(translate, 'additionalPricingPerUsage', regionLocale, item.pricing?.usagePricing)}
          </TableCell>
        </TableRow>
      )
    })

  const displaySelectableOptionsTable = (
    <Paper elevation={0}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>{displayHeaders}</TableRow>
          </TableHead>
          <TableBody>{nonEmptySelectableRows}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )

  const calculateTotalPrice = (): number => {
    let totalPrice = 0

    // plan pricing
    if (selectedPlan) {
      const planUsagePricing = selectedPlan.pricing?.usagePricing
      const planPriceAmount = planUsagePricing?.amount || 0
      totalPrice += planPriceAmount
    }

    // additional features pricing
    if (selectedOptions.size > 0) {
      additionalOptions &&
        additionalOptions
          .filter((option) => isOptionSelected(option))
          .forEach((option) => {
            const optionPriceAmount = option.pricing?.usagePricing?.amount || 0
            totalPrice += optionPriceAmount
          })
    }

    return totalPrice
  }

  const displayTotalPrice = (): string => {
    const planUsagePricing = selectedPlan?.pricing?.usagePricing
    const featurePricing =
      additionalOptions && additionalOptions.length > 0 ? additionalOptions[0].pricing?.usagePricing : undefined
    const currency = planUsagePricing?.currency.toString() || featurePricing?.currency.toString() || ''
    const totalPrice = calculateTotalPrice()

    return `${formatAmountForLocale(totalPrice, regionLocale, currency)}`
  }

  return (
    <ScreenLayout
      title={translate(stepScreen ? 'selectServicePlan' : 'manageServicePlan')}
      onBack={navigateBack}
      actionsWidth={30}
    >
      <Paper elevation={0}>
        <Divider />
        {errorMessage && <ErrorMessage message={errorMessage} />}
        <Box pt={2}>
          {laundryGroupSubscription && (
            <>
              <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                <ServicePackagePlanCard
                  laundryGroupSubscription={laundryGroupSubscription}
                  mode={stepScreen ? 'create' : 'edit'}
                  handleSelectPlan={handleSelectPlan}
                  selectedPlan={selectedPlan}
                />

                <Grid item xs={12} mt={3}>
                  <Typography className={sharedClasses.SectionTitle}>{translate('additionalOptions')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  {displaySelectableOptionsTable}
                </Grid>

                <Grid item xs={12} mt={3}>
                  <Typography className={sharedClasses.SectionTitle}>{translate('calculatedPrice')}</Typography>
                  <Typography variant={'subtitle2'}>
                    {translate('agreeToTermsAndConditions', translate('termsAndConditions'))}
                    <Link href={termsUrl} target="_blank" ml={1}>
                      {translate('termsAndConditions')}
                      <OpenInNewIcon
                        fontSize="small"
                        sx={{
                          position: 'relative',
                          top: '5px',
                          left: '5px',
                        }}
                      />
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={3}>
                  <Box display={'flex'} justifyContent={'space-between'} mb={1}>
                    <Typography variant={'h4'}>{translate('pricePerWashDry')}</Typography>
                    <Typography variant={'h3'}>{displayTotalPrice()}</Typography>
                  </Box>
                </Grid>
              </Grid>

              <Divider className={sharedClasses.Divider} />
              <Box mt={3} display="flex" justifyContent="flex-end">
                <Button
                  id="cancelButton"
                  variant="text"
                  color="primary"
                  size="large"
                  className={sharedClasses.ButtonMargin}
                  onClick={navigateBack}
                >
                  {translate('button.cancel')}
                </Button>

                <Button
                  id="submitButton"
                  disabled={handleDisableActiveButton()}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleActivate}
                >
                  {translate('button.activateNow')}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </ScreenLayout>
  )
}
