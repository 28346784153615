/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';

/**
 * 
 * @export
 * @interface LaundryGroupPriceListExternal
 */
export interface LaundryGroupPriceListExternal {
    /**
     * 
     * @type {string}
     * @memberof LaundryGroupPriceListExternal
     */
    name: string;
    /**
     * 
     * @type {MachineType}
     * @memberof LaundryGroupPriceListExternal
     */
    machineType: MachineType;
    /**
     * 
     * @type {Currency}
     * @memberof LaundryGroupPriceListExternal
     */
    currency: Currency;
    /**
     * Drum Size of machine in kg (Optional)
     * @type {number}
     * @memberof LaundryGroupPriceListExternal
     */
    drumSize?: number | null;
    /**
     * Unit of Weight ('kg' | 'lbs')
     * @type {string}
     * @memberof LaundryGroupPriceListExternal
     */
    drumSizeUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroupPriceListExternal
     */
    readonly regularPriceListId: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroupPriceListExternal
     */
    readonly happyHourPriceListId: string;
    /**
     * 
     * @type {number}
     * @memberof LaundryGroupPriceListExternal
     */
    regularAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LaundryGroupPriceListExternal
     */
    happyHourAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LaundryGroupPriceListExternal
     */
    amountPerUnit: number;
    /**
     * in sec
     * @type {number}
     * @memberof LaundryGroupPriceListExternal
     */
    maxProgramTime: number;
}

/**
 * Check if a given object implements the LaundryGroupPriceListExternal interface.
 */
export function instanceOfLaundryGroupPriceListExternal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "machineType" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "regularPriceListId" in value;
    isInstance = isInstance && "happyHourPriceListId" in value;
    isInstance = isInstance && "regularAmount" in value;
    isInstance = isInstance && "happyHourAmount" in value;
    isInstance = isInstance && "amountPerUnit" in value;
    isInstance = isInstance && "maxProgramTime" in value;

    return isInstance;
}

export function LaundryGroupPriceListExternalFromJSON(json: any): LaundryGroupPriceListExternal {
    return LaundryGroupPriceListExternalFromJSONTyped(json, false);
}

export function LaundryGroupPriceListExternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupPriceListExternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'machineType': MachineTypeFromJSON(json['machineType']),
        'currency': CurrencyFromJSON(json['currency']),
        'drumSize': !exists(json, 'drumSize') ? undefined : json['drumSize'],
        'drumSizeUnit': !exists(json, 'drumSizeUnit') ? undefined : json['drumSizeUnit'],
        'regularPriceListId': json['regularPriceListId'],
        'happyHourPriceListId': json['happyHourPriceListId'],
        'regularAmount': json['regularAmount'],
        'happyHourAmount': json['happyHourAmount'],
        'amountPerUnit': json['amountPerUnit'],
        'maxProgramTime': json['maxProgramTime'],
    };
}

export function LaundryGroupPriceListExternalToJSON(value?: LaundryGroupPriceListExternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'machineType': MachineTypeToJSON(value.machineType),
        'currency': CurrencyToJSON(value.currency),
        'drumSize': value.drumSize,
        'drumSizeUnit': value.drumSizeUnit,
        'regularAmount': value.regularAmount,
        'happyHourAmount': value.happyHourAmount,
        'amountPerUnit': value.amountPerUnit,
        'maxProgramTime': value.maxProgramTime,
    };
}

