import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, Notification, NotificationsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getLastMonths, removeTimeFromDate, removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'
import { Data, OrderBy } from 'src/service/view-model/base/Data'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { NoNotifications } from 'src/ui-shared/notification/NoNotifications'
import { NotificationItem } from 'src/ui-shared/notification/NotificationItem'
import {
  DEFAULT_DATA,
  TableData,
  TableSettings,
  getDefaultTableSettings,
  tableSettingsSort,
} from 'src/ui-shared/table/Table.const'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'

interface NotificationTableSettings extends TableSettings {
  orderBy: OrderBy<keyof Notification>
  startTimeFrom?: Date
  startTimeTo?: Date
}

export const NotificationTable = (): ReactElement => {
  const translate = useTranslate()
  const location = useLocation()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const notificationsApi = new NotificationsApi(httpConfiguration)

  const [data, setData] = useState<TableData<Notification>>(DEFAULT_DATA)

  const maxNotificationsInTable = 100
  const lastMonths = 3
  const dateNow = removeTimeFromDateEndOfDay(new Date())
  const dateLastMonth = removeTimeFromDate(getLastMonths(dateNow, lastMonths))

  const defaultTableSettings: NotificationTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'createdAt',
    orderDir: 'desc',
    size: maxNotificationsInTable,
    startTimeFrom: dateLastMonth,
    startTimeTo: dateNow,
  }
  const [tableSettings, setTableSettings] = useState<NotificationTableSettings>(defaultTableSettings)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // load data on state change
  useEffect(() => {
    let active = true
    if (tableSettings.startTimeFrom && tableSettings.startTimeTo) {
      setLoading(true)

      notificationsApi
        .notificationsGet(
          tableSettings.startTimeFrom,
          tableSettings.startTimeTo,
          tableSettings.size,
          tableSettings.page,
          tableSettingsSort(tableSettings),
        )
        .then((data) => {
          if (active) {
            updateTableSettingsFromData(data as Data<any>, tableSettings)
            setTableSettings(tableSettings)

            setData(mapData(data as Data<any>))
            setErrorMessage(null)
            setLoading(false)
          }
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setData(DEFAULT_DATA)
          setLoading(false)
        })
    }
    return () => {
      active = false
    }
  }, [tableSettings])

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  // generic JSX
  const displayNotifications = data.data.map((item, index) => {
    return (
      <NotificationItem
        key={item.id}
        title={item.title}
        message={item.message}
        action={item.action}
        status={item.status}
        severity={item.severity}
        date={item.createdAt}
        first={index === 0}
      />
    )
  })

  const displayPageContent = <>{data.totalElements > 0 ? displayNotifications : <NoNotifications />}</>

  return (
    <>
      <LoadingIndicator loading={loading} />
      {errorMessage ? (
        <ErrorMessage message={errorMessage} />
      ) : (
        <Box pt={2}>{!loading ? displayPageContent : null}</Box>
      )}
    </>
  )
}
