import { CalendarEntryType, OpeningType } from 'src/service/backend/api'
import { CalendarEntrySlot } from 'src/service/view-model/base/calendar/Calendar.const'

export function userCalendarIsSlotSelectable(slot: CalendarEntrySlot): boolean {
  const calendarEntryType = slot.calendarEntry?.calendarEntryType
  const calendarOpeningType = slot.laundryCalendar?.openingType
  if (
    calendarEntryType === CalendarEntryType.MAINTENANCE ||
    calendarEntryType === CalendarEntryType.AD_HOC_RESERVATION ||
    calendarEntryType === CalendarEntryType.ACTIVE_USAGE ||
    calendarOpeningType === OpeningType.AD_HOC ||
    calendarOpeningType === OpeningType.BLOCKED ||
    calendarOpeningType === OpeningType.CLOSED
  ) {
    return false
  }
  return true
}

export function userCalendarIsDeleteModal(calendarEntryType?: CalendarEntryType): boolean {
  if (calendarEntryType === CalendarEntryType.RESERVATION) {
    return true
  }
  return false
}

export function machineCalendarIsDeleteModal(calendarEntryType?: CalendarEntryType): boolean {
  if (calendarEntryType === CalendarEntryType.MAINTENANCE) {
    return true
  }
  return false
}
