/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineReference } from './MachineReference';
import {
    MachineReferenceFromJSON,
    MachineReferenceFromJSONTyped,
    MachineReferenceToJSON,
} from './MachineReference';
import type { SettingField } from './SettingField';
import {
    SettingFieldFromJSON,
    SettingFieldFromJSONTyped,
    SettingFieldToJSON,
} from './SettingField';

/**
 * 
 * @export
 * @interface MachineParameters
 */
export interface MachineParameters {
    /**
     * 
     * @type {MachineReference}
     * @memberof MachineParameters
     */
    machine: MachineReference;
    /**
     * 
     * @type {Array<SettingField>}
     * @memberof MachineParameters
     */
    fields: Array<SettingField>;
}

/**
 * Check if a given object implements the MachineParameters interface.
 */
export function instanceOfMachineParameters(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "machine" in value;
    isInstance = isInstance && "fields" in value;

    return isInstance;
}

export function MachineParametersFromJSON(json: any): MachineParameters {
    return MachineParametersFromJSONTyped(json, false);
}

export function MachineParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machine': MachineReferenceFromJSON(json['machine']),
        'fields': ((json['fields'] as Array<any>).map(SettingFieldFromJSON)),
    };
}

export function MachineParametersToJSON(value?: MachineParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machine': MachineReferenceToJSON(value.machine),
        'fields': ((value.fields as Array<any>).map(SettingFieldToJSON)),
    };
}

