import { Data } from 'src/service/view-model/base/Data'
import { TableData, TableSettings } from 'src/ui-shared/table/Table.const'

export const mapData = <T>(repoData: Data<T>): TableData<T> => {
  const totalElements = repoData.page.totalElements
  const result = repoData.result

  const r: TableData<T> = { data: result, totalElements }
  return r
}

export function updateTableSettingsFromData(repoData: Data<any>, tableSettings: TableSettings): void {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (repoData.page.size !== undefined) {
    tableSettings.size = repoData.page.size as number
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (repoData.page.number !== undefined) {
    tableSettings.page = repoData.page.number as number
  }
}
