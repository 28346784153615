import React, { ReactElement, Suspense, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Autocomplete, Box, Grid, Stack, Typography, styled } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { ChartData, Configuration, ConsumptionChartFactType, ReportConsumptionsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { findItemAndSelect } from 'src/service/view-model/base/Reference.utils'
import { CHART_SCALE_OBJECTS, getChartScaleObject } from 'src/service/view-model/base/chart/Chart.const'
import { MACHINE_CATEGORIES, MachineCategoryObject } from 'src/service/view-model/machine/Machines'
import {
  CONSUMPTION_CHART_GROUP_BY_OBJECTS,
  ConsumptionTotalsChartSettings,
  DEFAULT_CONSUMPTION_TOTALS_CHART_SETTINGS,
  getConsumptionGroupByObject,
} from 'src/service/view-model/usage-chart/ConsumptionChartViewModel'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { useDataSettingsUrlSync } from 'src/ui-shared/base/hooks/useDataSettingsUrlSync'
import { ChartDataFilter } from 'src/ui-shared/chart/ChartDataFilter'
import { ChartDataSelection } from 'src/ui-shared/chart/ChartDataSelection'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { withOrganization } from 'src/ui-shared/table/Table.const'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'

const StyledChart = styled(Box)(({ theme }) => ({
  width: '49%',
  [theme.breakpoints.down('lg')]: {
    width: '99%',
  },
}))

const AsyncChart = React.lazy(() => import('src/ui-shared/chart/Chart').then(({ Chart }) => ({ default: Chart })))

export const ConsumptionTotalsChart = (): ReactElement => {
  const { classes: textFieldClasses } = useTextFieldStyles()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportConsumptionApi = new ReportConsumptionsApi(httpConfiguration)

  const translate = useTranslate()

  const location = useLocation()
  const activeOrganization = useActiveOrganization()

  // state
  const defaultConsumptionTotalsChartSettings: ConsumptionTotalsChartSettings = {
    ...DEFAULT_CONSUMPTION_TOTALS_CHART_SETTINGS,
  }
  const [consumptionTotalsChartSettings, setConsumptionTotalsChartSettings] = useState<ConsumptionTotalsChartSettings>(
    withOrganization(defaultConsumptionTotalsChartSettings, activeOrganization),
  )
  const [machineCategory, setMachineCategory] = useState<MachineCategoryObject | null>(null)

  useEffect(() => {
    findItemAndSelect(
      consumptionTotalsChartSettings.machineCategory,
      setMachineCategory,
      machineCategory,
      MACHINE_CATEGORIES,
    )
  }, [consumptionTotalsChartSettings.machineCategory])

  const loadChart = (factType: ConsumptionChartFactType): Promise<ChartData> => {
    const dateStartTimeFrom = new Date(consumptionTotalsChartSettings.startDateFrom)
    const dateStartTimeTo = new Date(consumptionTotalsChartSettings.startDateTo)

    return reportConsumptionApi.reportSmConsumptionsChartGet(
      consumptionTotalsChartSettings.groupBy,
      factType,
      consumptionTotalsChartSettings.chartScale,
      dateStartTimeFrom,
      dateStartTimeTo,
      undefined,
      consumptionTotalsChartSettings.organizationId,
      consumptionTotalsChartSettings.laundryGroupId,
      consumptionTotalsChartSettings.laundryId,
      consumptionTotalsChartSettings.machineId,
      consumptionTotalsChartSettings.machineCategory,
    )
  }

  // generic reactivity

  // update state from url
  useDataSettingsUrlSync(
    location,
    setConsumptionTotalsChartSettings,
    consumptionTotalsChartSettings,
    defaultConsumptionTotalsChartSettings,
  )

  const handleMachineCategoryChange = (_: unknown, machineCategoryObject: MachineCategoryObject | null) => {
    setMachineCategory(machineCategoryObject)
    setConsumptionTotalsChartSettings({
      ...consumptionTotalsChartSettings,
      machineCategory: machineCategoryObject?.id,
    })
  }

  return (
    <Box mt={1}>
      {/* Groupings */}
      <Box mt={1}>
        {/*Input fields for data selection: factType, groupBy and chartScale*/}
        <ChartDataSelection
          chartSettings={consumptionTotalsChartSettings}
          setChartSettings={setConsumptionTotalsChartSettings}
          groupBy={'groupBy'}
          scale={'chartScale'}
          groupByOptions={CONSUMPTION_CHART_GROUP_BY_OBJECTS}
          groupByOptionsLabel={(option) => translate(option.name)}
          groupByValue={getConsumptionGroupByObject}
          scaleOptions={CHART_SCALE_OBJECTS}
          scaleOptionsLabel={(option) => translate(option.name)}
          scaleValue={getChartScaleObject}
        >
          {/*Input fields for selecting date range */}
          <TableDatePickerForm
            key={`${consumptionTotalsChartSettings.startDateFrom}${consumptionTotalsChartSettings.startDateTo}`}
            firstDatePropName="startDateFrom"
            secondDatePropName="startDateTo"
            tableSettings={consumptionTotalsChartSettings}
            setTableSettings={setConsumptionTotalsChartSettings}
          />
        </ChartDataSelection>
      </Box>

      {/* Filters */}
      <Box mt={1}>
        {/*Input fields for data filter: organization, laundry group, laundry and machine */}
        <ChartDataFilter
          chartSettings={consumptionTotalsChartSettings}
          setChartSettings={setConsumptionTotalsChartSettings}
          organization={'organizationId'}
          laundryGroup={'laundryGroupId'}
          laundry={'laundryId'}
          machine={'machineId'}
          sameLine={true}
          additionalFilterKeys={['machineCategory']}
        >
          <Grid item {...ITEM_BREAKPOINTS} lg={true}>
            <Autocomplete
              openOnFocus={true}
              multiple={false}
              freeSolo={false}
              disableClearable={false}
              options={MACHINE_CATEGORIES}
              getOptionLabel={(option) => translate(option.name) || ''}
              isOptionEqualToValue={(option) => option.id === consumptionTotalsChartSettings.machineCategory}
              value={machineCategory}
              onChange={handleMachineCategoryChange}
              noOptionsText={translate('autocompleteNoOptions')}
              loadingText={translate('autocompleteLoading')}
              renderInput={(params) => (
                <TextFieldDefault
                  {...params}
                  label={translate('machineCategory')}
                  className={textFieldClasses.TextFieldSmall}
                />
              )}
            />
          </Grid>
        </ChartDataFilter>
      </Box>
      <Suspense fallback={<div style={{ textAlign: 'center' }}>{translate('autocompleteLoading')}</div>}>
        <Stack
          flexDirection={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <StyledChart>
            <AsyncChart
              loadChart={() => loadChart(ConsumptionChartFactType.WATER)}
              chartSettings={consumptionTotalsChartSettings}
            />
          </StyledChart>
          <StyledChart>
            <AsyncChart
              loadChart={() => loadChart(ConsumptionChartFactType.ENERGY)}
              chartSettings={consumptionTotalsChartSettings}
            />
          </StyledChart>
        </Stack>
        <Box mt={2}>
          <Typography variant="caption">*{translate('consumptionValuesApproximations')}</Typography>
        </Box>
      </Suspense>
    </Box>
  )
}
