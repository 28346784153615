import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Badge, Box, Button, Divider, Typography, styled } from '@mui/material'
import { NotificationsOutlined } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, Notification, NotificationsApi, PageableNotifications } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getLastMonths, removeTimeFromDate, removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'
import { truncateText } from 'src/service/utils/TextUtils'
import { Data, OrderBy } from 'src/service/view-model/base/Data'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useNewNotifications } from 'src/ui-shared/notification/NewNotificationsProvider'
import { NoNotifications } from 'src/ui-shared/notification/NoNotifications'
import { NotificationItem } from 'src/ui-shared/notification/NotificationItem'
import {
  DEFAULT_DATA,
  TableData,
  TableSettings,
  getDefaultTableSettings,
  tableSettingsSort,
} from 'src/ui-shared/table/Table.const'
import { mapData } from 'src/ui-shared/table/Table.utils'
import { MenuContent } from 'src/ui/layout/appbar-menu/MenuContent'
import { PopperTitle, StyledMenuItem } from 'src/ui/layout/appbar-menu/MenuStyle'

interface NotificationTableSettings extends TableSettings {
  orderBy: OrderBy<keyof Notification>
  startTimeFrom?: Date
  startTimeTo?: Date
}

export const StyledDivider = styled(Divider)(() => ({
  height: 2,
}))

const StyledContent = styled(Box)(({ theme }) => ({
  minHeight: '100px',
  width: '450px',
  [theme.breakpoints.down('md')]: {
    width: '350px',
  },
  [theme.breakpoints.down('xs')]: {
    width: '250px',
  },
}))

const StyledBadge = styled(Badge)<{
  active?: number
}>(({ active, theme }) => ({
  '& .MuiBadge-badge': {
    left: '8px',
    fontWeight: 600,
    backgroundColor: !active ? theme.palette.action.selected : theme.palette.primary.main,
  },
}))

const ErrorMessageStyle = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  '& .MuiPaper-root': {
    boxShadow: 'none',
  },
}))

export const NotificationMenu: React.FC = () => {
  const translate = useTranslate()
  const appId = useAppId()
  const { newNotifications, readAllNotifications } = useNewNotifications()

  const navigate = useNavigate()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const notificationsApi = new NotificationsApi(httpConfiguration)

  const [data, setData] = useState<TableData<Notification>>(DEFAULT_DATA)

  const maxNotificationsInMenu = 3
  const lastMonths = 2
  const dateNow = removeTimeFromDateEndOfDay(new Date())
  const dateLastMonth = removeTimeFromDate(getLastMonths(dateNow, lastMonths))
  const tableSettings: NotificationTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'createdAt',
    orderDir: 'desc',
    size: maxNotificationsInMenu,
    startTimeFrom: dateLastMonth,
    startTimeTo: dateNow,
  }

  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const open = Boolean(anchorEl)

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const navigateToNotificationsPage = () => {
    closeMenu()
    navigate(`/${appId}/notifications`)
  }

  // load data on state change
  useEffect(() => {
    let active = true
    if (tableSettings.startTimeFrom && tableSettings.startTimeTo) {
      if (open) {
        setLoading(true)
        notificationsApi
          .notificationsGet(
            tableSettings.startTimeFrom,
            tableSettings.startTimeTo,
            tableSettings.size,
            tableSettings.page,
            tableSettingsSort(tableSettings),
          )
          .then((data: PageableNotifications) => {
            if (active) {
              setData(mapData(data as Data<any>))
              setErrorMessage(null)

              readAllNotifications()

              setLoading(false)
            }
          })
          .catch((err) => {
            const errorMessage = errorMapper(err, translate)
            console.error(errorMessage, err)
            setErrorMessage(errorMessage)
            setData(DEFAULT_DATA)
            setLoading(false)
          })
      }
    }
    return () => {
      active = false
    }
  }, [open])

  // ---- generic code, no modification pass this point ----

  // generic event handlers
  const handleClose = () => {
    setAnchorEl(null)
  }

  const openPopperHandle = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  // generic JSX
  const displayNotifications = data.data.map((item, index) => {
    return (
      <NotificationItem
        key={item.id}
        title={item.title}
        message={truncateText(250, item.message)}
        action={item.action}
        status={item.status}
        severity={item.severity}
        date={item.createdAt}
        menuItem={true}
        first={index === 0}
        afterActionClick={closeMenu}
      />
    )
  })

  return (
    <>
      <StyledMenuItem active={open ? 1 : 0} onClick={openPopperHandle}>
        <StyledBadge active={open ? 1 : 0} badgeContent={newNotifications}>
          <NotificationsOutlined />
        </StyledBadge>
      </StyledMenuItem>
      <MenuContent open={open} anchorEl={anchorEl} handleClose={handleClose}>
        <StyledContent>
          <PopperTitle mb={0}>
            <Typography variant="h3">{translate('notifications')}</Typography>
          </PopperTitle>
          {errorMessage ? (
            <ErrorMessageStyle>
              <ErrorMessage message={errorMessage} />
            </ErrorMessageStyle>
          ) : (
            <>
              {loading ? (
                <>
                  <Box p={3}>
                    <LoadingIndicator loading={loading} />
                  </Box>
                  {/* Space for the all notifcation link, prevents rectangle from changing too much size  */}
                  <Box p={3}>&nbsp;</Box>
                </>
              ) : (
                <>
                  <Box sx={{ maxHeight: '70vh', maxWidth: '100vh', overflow: 'auto' }}>
                    {data.totalElements > 0 ? displayNotifications : <NoNotifications />}
                  </Box>
                  <Divider sx={{ height: 2 }} />
                  <Box p={1} pl={1} display="flex" justifyContent="flex-start">
                    <Button variant="text" color="primary" size="large" onClick={navigateToNotificationsPage}>
                      {translate('button.showAllNotifications')}
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}
        </StyledContent>
      </MenuContent>
    </>
  )
}
