import React, { ReactElement } from 'react'
import { TranslateFunction } from 'src/i18n/useMessageSource'
import { isValidPositiveNumber } from 'src/service/utils/NumberUtils'
import { DataSettings } from 'src/service/view-model/base/DataSettings'
import { TableFilterTextField } from 'src/ui-shared/table/TableFilterTextField'

export const getProgramTableFilters = (
  headCellId: string,
  translate: TranslateFunction,
  tableSettings: DataSettings,
  setTableSettings: React.Dispatch<React.SetStateAction<any>>,
): ReactElement | undefined => {
  let filter
  if (headCellId === 'programInfo.programName' || headCellId === 'programName') {
    filter = (
      <TableFilterTextField
        title={translate('filterByProgramName')}
        label={translate('programName')}
        filter={'programName'}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        delay={400}
      />
    )
  } else if (headCellId === 'programInfo.temperature' || headCellId === 'temperature') {
    filter = (
      <TableFilterTextField
        title={translate('filterByTemperature')}
        label={translate('temperature')}
        filter={'temperature'}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        delay={400}
        isValid={(value) => {
          return isValidPositiveNumber(value)
        }}
        errorText={translate('validation.valid.temperature')}
      />
    )
  } else if (headCellId === 'programInfo.programMode' || headCellId === 'programMode') {
    filter = (
      <TableFilterTextField
        title={translate('filterByProgramMode')}
        label={translate('programMode')}
        filter={'programMode'}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        delay={400}
      />
    )
  }
  return filter
}
