/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface TopUpGrouped
 */
export interface TopUpGrouped {
    /**
     * group by attribute values (ex. for groupBy=LAUNDRY_USER)
     * @type {string}
     * @memberof TopUpGrouped
     */
    groupLabel: string;
    /**
     * 
     * @type {number}
     * @memberof TopUpGrouped
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof TopUpGrouped
     */
    sumAmount: number;
    /**
     * 
     * @type {number}
     * @memberof TopUpGrouped
     */
    avgAmount: number;
    /**
     * 
     * @type {number}
     * @memberof TopUpGrouped
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof TopUpGrouped
     */
    maxAmount: number;
    /**
     * 
     * @type {Currency}
     * @memberof TopUpGrouped
     */
    currency: Currency;
}

/**
 * Check if a given object implements the TopUpGrouped interface.
 */
export function instanceOfTopUpGrouped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "groupLabel" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "sumAmount" in value;
    isInstance = isInstance && "avgAmount" in value;
    isInstance = isInstance && "minAmount" in value;
    isInstance = isInstance && "maxAmount" in value;
    isInstance = isInstance && "currency" in value;

    return isInstance;
}

export function TopUpGroupedFromJSON(json: any): TopUpGrouped {
    return TopUpGroupedFromJSONTyped(json, false);
}

export function TopUpGroupedFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopUpGrouped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupLabel': json['groupLabel'],
        'count': json['count'],
        'sumAmount': json['sumAmount'],
        'avgAmount': json['avgAmount'],
        'minAmount': json['minAmount'],
        'maxAmount': json['maxAmount'],
        'currency': CurrencyFromJSON(json['currency']),
    };
}

export function TopUpGroupedToJSON(value?: TopUpGrouped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupLabel': value.groupLabel,
        'count': value.count,
        'sumAmount': value.sumAmount,
        'avgAmount': value.avgAmount,
        'minAmount': value.minAmount,
        'maxAmount': value.maxAmount,
        'currency': CurrencyToJSON(value.currency),
    };
}

