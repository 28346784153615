import React, { FC, ReactElement } from 'react'
import { useNavigate } from 'react-router'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { Box, Link, Typography } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { GERMAN, getUserLanguageForLinks } from 'src/user/SupportedLanguage'
import { useUser } from 'src/user/UserContext'

interface Props {
  hideLayout?: boolean
}

export const ErrorPage: FC<Props> = ({ hideLayout }): ReactElement => {
  const translate = useTranslate()
  const navigate = useNavigate()
  const user = useUser()

  const language = getUserLanguageForLinks(user.locale, true, false)
  const contactUrl = `https://www.schulthess.ch/${language === GERMAN.value ? '' : language}/services/contact`

  const error = useRouteError()

  const serviceContactLink = (
    <Typography variant={'subtitle2'} display={'inline-block'}>
      <Link href={contactUrl} target="_blank" sx={{ display: 'flex', alignItems: 'center' }}>
        {translate('contactCenter')}
        <OpenInNew sx={{ ml: 0.3, fontSize: '14px' }} />
      </Link>
    </Typography>
  )

  const displayErrorMessage = (): ReactElement => {
    let errorDetailsMessage = ''
    const isRouteError = isRouteErrorResponse(error)

    // error thrown from an action or loader
    if (isRouteError) {
      errorDetailsMessage = `${error.status} ${error.statusText} ${error.data?.message ?? error.data.message}`
    }
    // error thrown from element
    else if (error instanceof Error) {
      errorDetailsMessage = error.message
    } else if (typeof error === 'string') {
      errorDetailsMessage = error
    } else {
      errorDetailsMessage = translate('unknownError')
    }

    return (
      <>
        {/* default error message */}
        <ErrorMessage
          alertTitle={translate('applicationError')}
          message={translate('errorOccurred')}
          action={serviceContactLink}
        />

        {/* error technical details */}
        <Box pt={2}>
          <ErrorMessage message={errorDetailsMessage} hideIcon variant={'outlined'} />
        </Box>
      </>
    )
  }
  return !hideLayout ? (
    <ScreenLayout title={translate('applicationError')} onBack={() => navigate(-1)}>
      {displayErrorMessage()}
    </ScreenLayout>
  ) : (
    displayErrorMessage()
  )
}
