import React, { ReactElement } from 'react'
import { Box, DialogActions, DialogContent } from '@mui/material'
import Button from '@mui/material/Button'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

interface Props {
  open: boolean
  onConfirmDraft: () => void
  onCancelDraft: () => void
  onDiscardDraft: () => void
}

export const DraftModalDialog = ({ open, onDiscardDraft, onConfirmDraft, onCancelDraft }: Props): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  return (
    <ModalDialog open={open} onClose={onCancelDraft} title={translate('draftModalTitle')} maxWidth="sm">
      <DialogContent>{translate('draftModalContent')}</DialogContent>
      <DialogActions>
        <Box p={2} pt={0} display="flex" justifyContent="flex-end">
          <Button variant="text" color="primary" size="medium" onClick={onCancelDraft}>
            {translate('button.cancel')}
          </Button>
          <Button
            variant="text"
            color="primary"
            size="medium"
            className={sharedClasses.ButtonMargin}
            onClick={onDiscardDraft}
          >
            {translate('discard')}
          </Button>
          <Button variant="contained" color="primary" size="medium" type="submit" onClick={onConfirmDraft}>
            {translate('apply')}
          </Button>
        </Box>
      </DialogActions>
    </ModalDialog>
  )
}
