/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppId } from './AppId';
import {
    AppIdFromJSON,
    AppIdFromJSONTyped,
    AppIdToJSON,
} from './AppId';
import type { CreateNotificationContentInner } from './CreateNotificationContentInner';
import {
    CreateNotificationContentInnerFromJSON,
    CreateNotificationContentInnerFromJSONTyped,
    CreateNotificationContentInnerToJSON,
} from './CreateNotificationContentInner';
import type { ErrorSeverity } from './ErrorSeverity';
import {
    ErrorSeverityFromJSON,
    ErrorSeverityFromJSONTyped,
    ErrorSeverityToJSON,
} from './ErrorSeverity';

/**
 * 
 * @export
 * @interface CreateNotification
 */
export interface CreateNotification {
    /**
     * 
     * @type {Array<CreateNotificationContentInner>}
     * @memberof CreateNotification
     */
    content: Array<CreateNotificationContentInner>;
    /**
     * 
     * @type {ErrorSeverity}
     * @memberof CreateNotification
     */
    severity: ErrorSeverity;
    /**
     * 
     * @type {Date}
     * @memberof CreateNotification
     */
    expiryAt?: Date;
    /**
     * apps to target (to filter recipients)
     * @type {Array<AppId>}
     * @memberof CreateNotification
     */
    apps?: Array<AppId>;
    /**
     * roles to target (to filter recipients)
     * @type {Array<string>}
     * @memberof CreateNotification
     */
    roles?: Array<string>;
    /**
     * organizations to target (to filter recipients)
     * @type {Array<string>}
     * @memberof CreateNotification
     */
    organizations?: Array<string>;
}

/**
 * Check if a given object implements the CreateNotification interface.
 */
export function instanceOfCreateNotification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "severity" in value;

    return isInstance;
}

export function CreateNotificationFromJSON(json: any): CreateNotification {
    return CreateNotificationFromJSONTyped(json, false);
}

export function CreateNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': ((json['content'] as Array<any>).map(CreateNotificationContentInnerFromJSON)),
        'severity': ErrorSeverityFromJSON(json['severity']),
        'expiryAt': !exists(json, 'expiryAt') ? undefined : (new Date(json['expiryAt'])),
        'apps': !exists(json, 'apps') ? undefined : ((json['apps'] as Array<any>).map(AppIdFromJSON)),
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'organizations': !exists(json, 'organizations') ? undefined : json['organizations'],
    };
}

export function CreateNotificationToJSON(value?: CreateNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': ((value.content as Array<any>).map(CreateNotificationContentInnerToJSON)),
        'severity': ErrorSeverityToJSON(value.severity),
        'expiryAt': value.expiryAt === undefined ? undefined : (value.expiryAt.toISOString()),
        'apps': value.apps === undefined ? undefined : ((value.apps as Array<any>).map(AppIdToJSON)),
        'roles': value.roles,
        'organizations': value.organizations,
    };
}

