import { TranslateFunction } from 'src/i18n/useMessageSource'
import { ErrorSeverity } from 'src/service/backend/api/models'
import { getReferenceName } from 'src/service/view-model/base/Reference.utils'

export interface ErrorSeverityObject {
  severity: string
  name: string
}

export const ERROR_SEVERITY: ErrorSeverityObject[] = [
  { severity: ErrorSeverity.WARNING, name: 'severity.warning' },
  { severity: ErrorSeverity.INFO, name: 'severity.info' },
  { severity: ErrorSeverity.ERROR, name: 'severity.error' },
]

export const getErrorSeverityName = (errorSeverityName: string | undefined, translate: TranslateFunction): string => {
  return getReferenceName(ERROR_SEVERITY, translate, errorSeverityName, 'severity')
}
