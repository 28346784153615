import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router'
import { Add } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Permission } from 'src/service/backend/api/models'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryTable } from 'src/ui/page/common/laundry/list/LaundryTable'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const LaundryListPage = (): ReactElement => {
  const translate = useTranslate()

  const navigate = useNavigate()
  const user = useUser()
  const appId = useAppId()

  const hasEditPermission = hasPermission(user, Permission.LAUNDRY_WRITE)

  // handle events
  const handleRowClick = (laundryId?: string) => {
    navigate(`/${appId}/laundries/${laundryId}/view`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleCreateButtonClick = () => {
    navigate(`/${appId}/laundries/create`)
  }

  // JSX
  const createNewAction = (
    <ListingButton onClick={handleCreateButtonClick} variant="outlined" color="primary" startIcon={<Add />}>
      {translate('createNew')}
    </ListingButton>
  )

  const actions = hasEditPermission ? createNewAction : <></>

  return (
    <ScreenLayout title={translate('laundries')} actions={actions}>
      <LaundryTable navigateToItem={handleRowClick}></LaundryTable>
    </ScreenLayout>
  )
}
