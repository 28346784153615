import { AlertColor } from '@mui/material'
import { Location } from 'history'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { TranslateFunction } from 'src/i18n/useMessageSource'
import {
  CalendarEntry,
  LaundryGroupReference,
  LaundryReference,
  MachineCategory,
  MachineReference,
  MachineType,
} from 'src/service/backend/api/models'
import { queryStringUtils } from 'src/service/utils/QueryStringUtils'
import { CalendarSettings } from 'src/service/view-model/base/calendar/Calendar.const'

export const handleAnchorDate = (
  date: Date | null,
  calendarSettings: CalendarSettings,
  setCalendarSettings: React.Dispatch<React.SetStateAction<CalendarSettings>>,
): void => {
  const newDate = date?.toDateString() ? new Date(date.toString()) : new Date()
  setCalendarSettings({
    ...calendarSettings,
    anchorDate: newDate,
  })
}

export const selectCalendarEntryForDelete = (
  calendarEntry: CalendarEntry,
  setCalendarEntryForDelete: React.Dispatch<React.SetStateAction<CalendarEntry | null>>,
  setConfirmDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
): void => {
  setConfirmDeleteModal(true)
  setCalendarEntryForDelete(calendarEntry)
}

export const onCancelConfirmDeleteModalHandle = (
  setCalendarEntryForDelete: React.Dispatch<React.SetStateAction<CalendarEntry | null>>,
  setConfirmDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
): void => {
  setCalendarEntryForDelete(null)
  setConfirmDeleteModal(false)
}

export const onSubmitReject = (
  err: Error | unknown,
  translate: TranslateFunction,
  showSnackbar: (message: string, status: AlertColor) => void,
): void => {
  const errorMessage = errorMapper(err, translate)
  console.error(errorMessage, err)
  showSnackbar(errorMessage, 'error')
}

export const onRequestReject = (
  err: Error | unknown,
  translate: TranslateFunction,
  setErrorMessage: React.Dispatch<React.SetStateAction<any>>,
): void => {
  const errorMessage = errorMapper(err, translate)
  console.error(errorMessage, err)
  setErrorMessage(errorMessage)
}

export const updateStateFromUrl = (location: Location, calendarSettings: CalendarSettings): CalendarSettings => {
  const queryParams = queryStringUtils.parse(location.search)
  const queryDate = queryParams.date
  const queryLaundryId = queryParams.laundryId
  const queryMachineId = queryParams.machineId
  const stateDate = queryDate instanceof Date ? (queryDate as Date) : new Date()
  const machineReference: MachineReference | undefined = queryMachineId
    ? {
        id: queryMachineId.toString(),
        name: '',
        category: MachineCategory.WA,
        type: MachineType.SMAG_STANDARD_WA,
        serialNumber: '',
      }
    : undefined

  const laundryGroupRef: LaundryGroupReference = {
    id: '',
    name: '',
  }
  const laundryReference: LaundryReference | undefined = queryLaundryId
    ? {
        id: queryLaundryId.toString(),
        name: '',
        laundryGroup: laundryGroupRef,
      }
    : undefined

  const newCalendarSetting: CalendarSettings = {
    ...calendarSettings,
    anchorDate: stateDate,
    selectedLaundry: laundryReference,
    selectedMachine: machineReference,
  }
  return newCalendarSetting
}

export const updateUrlFromState = (calendarSettings: CalendarSettings): void => {
  const url = window.location.href
  const queryStringText = queryStringUtils.stringifyUrl(url, {
    date: calendarSettings.anchorDate,
    laundryId: calendarSettings.selectedLaundry?.id,
    machineId: calendarSettings.selectedMachine?.id,
  })
  window.history.replaceState(null, '', queryStringText)
}
