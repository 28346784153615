/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';

/**
 * 
 * @export
 * @interface LaundryReference
 */
export interface LaundryReference {
    /**
     * UUID of the Laundry
     * @type {string}
     * @memberof LaundryReference
     */
    id: string;
    /**
     * Name of the Laundry
     * @type {string}
     * @memberof LaundryReference
     */
    readonly name: string | null;
    /**
     * 
     * @type {LaundryGroupReference}
     * @memberof LaundryReference
     */
    laundryGroup?: LaundryGroupReference;
}

/**
 * Check if a given object implements the LaundryReference interface.
 */
export function instanceOfLaundryReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function LaundryReferenceFromJSON(json: any): LaundryReference {
    return LaundryReferenceFromJSONTyped(json, false);
}

export function LaundryReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'laundryGroup': !exists(json, 'laundryGroup') ? undefined : LaundryGroupReferenceFromJSON(json['laundryGroup']),
    };
}

export function LaundryReferenceToJSON(value?: LaundryReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'laundryGroup': LaundryGroupReferenceToJSON(value.laundryGroup),
    };
}

