/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectionQualityType } from './ConnectionQualityType';
import {
    ConnectionQualityTypeFromJSON,
    ConnectionQualityTypeFromJSONTyped,
    ConnectionQualityTypeToJSON,
} from './ConnectionQualityType';
import type { ConnectivityFirmwareType } from './ConnectivityFirmwareType';
import {
    ConnectivityFirmwareTypeFromJSON,
    ConnectivityFirmwareTypeFromJSONTyped,
    ConnectivityFirmwareTypeToJSON,
} from './ConnectivityFirmwareType';
import type { DoorStatusType } from './DoorStatusType';
import {
    DoorStatusTypeFromJSON,
    DoorStatusTypeFromJSONTyped,
    DoorStatusTypeToJSON,
} from './DoorStatusType';
import type { MachineStatusType } from './MachineStatusType';
import {
    MachineStatusTypeFromJSON,
    MachineStatusTypeFromJSONTyped,
    MachineStatusTypeToJSON,
} from './MachineStatusType';
import type { ModuleType } from './ModuleType';
import {
    ModuleTypeFromJSON,
    ModuleTypeFromJSONTyped,
    ModuleTypeToJSON,
} from './ModuleType';
import type { ProgramStatus } from './ProgramStatus';
import {
    ProgramStatusFromJSON,
    ProgramStatusFromJSONTyped,
    ProgramStatusToJSON,
} from './ProgramStatus';
import type { Step } from './Step';
import {
    StepFromJSON,
    StepFromJSONTyped,
    StepToJSON,
} from './Step';

/**
 * 
 * @export
 * @interface MachineStatus
 */
export interface MachineStatus {
    /**
     * 
     * @type {boolean}
     * @memberof MachineStatus
     */
    online: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MachineStatus
     */
    lastOnline: Date;
    /**
     * 
     * @type {Date}
     * @memberof MachineStatus
     */
    lastOffline: Date;
    /**
     * 
     * @type {ConnectionQualityType}
     * @memberof MachineStatus
     */
    connectionQuality: ConnectionQualityType;
    /**
     * 
     * @type {number}
     * @memberof MachineStatus
     */
    rssi: number;
    /**
     * 
     * @type {string}
     * @memberof MachineStatus
     */
    connectivityFirmwareVersion: string;
    /**
     * 
     * @type {ConnectivityFirmwareType}
     * @memberof MachineStatus
     */
    connectivityFirmwareType: ConnectivityFirmwareType;
    /**
     * 
     * @type {string}
     * @memberof MachineStatus
     */
    machineFirmwareVersion?: string | null;
    /**
     * 
     * @type {DoorStatusType}
     * @memberof MachineStatus
     */
    doorStatus?: DoorStatusType;
    /**
     * 
     * @type {MachineStatusType}
     * @memberof MachineStatus
     */
    machineStatus?: MachineStatusType;
    /**
     * 
     * @type {ModuleType}
     * @memberof MachineStatus
     */
    currentModule?: ModuleType;
    /**
     * 
     * @type {Step}
     * @memberof MachineStatus
     */
    currentStep?: Step;
    /**
     * 
     * @type {ProgramStatus}
     * @memberof MachineStatus
     */
    currentProgram?: ProgramStatus;
    /**
     * 
     * @type {number}
     * @memberof MachineStatus
     */
    programCounter?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineStatus
     */
    operatingHours?: number;
    /**
     * 
     * @type {Date}
     * @memberof MachineStatus
     */
    rtcLastUpdate?: Date;
}

/**
 * Check if a given object implements the MachineStatus interface.
 */
export function instanceOfMachineStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "online" in value;
    isInstance = isInstance && "lastOnline" in value;
    isInstance = isInstance && "lastOffline" in value;
    isInstance = isInstance && "connectionQuality" in value;
    isInstance = isInstance && "rssi" in value;
    isInstance = isInstance && "connectivityFirmwareVersion" in value;
    isInstance = isInstance && "connectivityFirmwareType" in value;

    return isInstance;
}

export function MachineStatusFromJSON(json: any): MachineStatus {
    return MachineStatusFromJSONTyped(json, false);
}

export function MachineStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'online': json['online'],
        'lastOnline': (new Date(json['lastOnline'])),
        'lastOffline': (new Date(json['lastOffline'])),
        'connectionQuality': ConnectionQualityTypeFromJSON(json['connectionQuality']),
        'rssi': json['rssi'],
        'connectivityFirmwareVersion': json['connectivityFirmwareVersion'],
        'connectivityFirmwareType': ConnectivityFirmwareTypeFromJSON(json['connectivityFirmwareType']),
        'machineFirmwareVersion': !exists(json, 'machineFirmwareVersion') ? undefined : json['machineFirmwareVersion'],
        'doorStatus': !exists(json, 'doorStatus') ? undefined : DoorStatusTypeFromJSON(json['doorStatus']),
        'machineStatus': !exists(json, 'machineStatus') ? undefined : MachineStatusTypeFromJSON(json['machineStatus']),
        'currentModule': !exists(json, 'currentModule') ? undefined : ModuleTypeFromJSON(json['currentModule']),
        'currentStep': !exists(json, 'currentStep') ? undefined : StepFromJSON(json['currentStep']),
        'currentProgram': !exists(json, 'currentProgram') ? undefined : ProgramStatusFromJSON(json['currentProgram']),
        'programCounter': !exists(json, 'programCounter') ? undefined : json['programCounter'],
        'operatingHours': !exists(json, 'operatingHours') ? undefined : json['operatingHours'],
        'rtcLastUpdate': !exists(json, 'rtcLastUpdate') ? undefined : (new Date(json['rtcLastUpdate'])),
    };
}

export function MachineStatusToJSON(value?: MachineStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'online': value.online,
        'lastOnline': (value.lastOnline.toISOString()),
        'lastOffline': (value.lastOffline.toISOString()),
        'connectionQuality': ConnectionQualityTypeToJSON(value.connectionQuality),
        'rssi': value.rssi,
        'connectivityFirmwareVersion': value.connectivityFirmwareVersion,
        'connectivityFirmwareType': ConnectivityFirmwareTypeToJSON(value.connectivityFirmwareType),
        'machineFirmwareVersion': value.machineFirmwareVersion,
        'doorStatus': DoorStatusTypeToJSON(value.doorStatus),
        'machineStatus': MachineStatusTypeToJSON(value.machineStatus),
        'currentModule': ModuleTypeToJSON(value.currentModule),
        'currentStep': StepToJSON(value.currentStep),
        'currentProgram': ProgramStatusToJSON(value.currentProgram),
        'programCounter': value.programCounter,
        'operatingHours': value.operatingHours,
        'rtcLastUpdate': value.rtcLastUpdate === undefined ? undefined : (value.rtcLastUpdate.toISOString()),
    };
}

