/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpeningType } from './OpeningType';
import {
    OpeningTypeFromJSON,
    OpeningTypeFromJSONTyped,
    OpeningTypeToJSON,
} from './OpeningType';
import type { PriceCondition } from './PriceCondition';
import {
    PriceConditionFromJSON,
    PriceConditionFromJSONTyped,
    PriceConditionToJSON,
} from './PriceCondition';

/**
 * 
 * @export
 * @interface LaundryGroupSingleCalendarEntry
 */
export interface LaundryGroupSingleCalendarEntry {
    /**
     * 
     * @type {Date}
     * @memberof LaundryGroupSingleCalendarEntry
     */
    startTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof LaundryGroupSingleCalendarEntry
     */
    endTime: Date;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroupSingleCalendarEntry
     */
    reason?: string;
    /**
     * 
     * @type {OpeningType}
     * @memberof LaundryGroupSingleCalendarEntry
     */
    openingType: OpeningType;
    /**
     * 
     * @type {PriceCondition}
     * @memberof LaundryGroupSingleCalendarEntry
     */
    priceCondition?: PriceCondition;
}

/**
 * Check if a given object implements the LaundryGroupSingleCalendarEntry interface.
 */
export function instanceOfLaundryGroupSingleCalendarEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "endTime" in value;
    isInstance = isInstance && "openingType" in value;

    return isInstance;
}

export function LaundryGroupSingleCalendarEntryFromJSON(json: any): LaundryGroupSingleCalendarEntry {
    return LaundryGroupSingleCalendarEntryFromJSONTyped(json, false);
}

export function LaundryGroupSingleCalendarEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupSingleCalendarEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startTime': (new Date(json['startTime'])),
        'endTime': (new Date(json['endTime'])),
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'openingType': OpeningTypeFromJSON(json['openingType']),
        'priceCondition': !exists(json, 'priceCondition') ? undefined : PriceConditionFromJSON(json['priceCondition']),
    };
}

export function LaundryGroupSingleCalendarEntryToJSON(value?: LaundryGroupSingleCalendarEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startTime': (value.startTime.toISOString()),
        'endTime': (value.endTime.toISOString()),
        'reason': value.reason,
        'openingType': OpeningTypeToJSON(value.openingType),
        'priceCondition': PriceConditionToJSON(value.priceCondition),
    };
}

