/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { SMUsage } from './SMUsage';
import {
    SMUsageFromJSON,
    SMUsageFromJSONTyped,
    SMUsageToJSON,
} from './SMUsage';

/**
 * 
 * @export
 * @interface PageableSMUsages
 */
export interface PageableSMUsages {
    /**
     * 
     * @type {Array<SMUsage>}
     * @memberof PageableSMUsages
     */
    result: Array<SMUsage>;
    /**
     * 
     * @type {Page}
     * @memberof PageableSMUsages
     */
    page: Page;
}

/**
 * Check if a given object implements the PageableSMUsages interface.
 */
export function instanceOfPageableSMUsages(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageableSMUsagesFromJSON(json: any): PageableSMUsages {
    return PageableSMUsagesFromJSONTyped(json, false);
}

export function PageableSMUsagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableSMUsages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(SMUsageFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageableSMUsagesToJSON(value?: PageableSMUsages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(SMUsageToJSON)),
        'page': PageToJSON(value.page),
    };
}

