import React from 'react'
import { Location } from 'history'
import { correctIntParam, correctOrderDirParam } from 'src/service/view-model/base/DataSettings.utils'
import { useDataSettingsUrlSyncMain } from 'src/ui-shared/base/hooks/useDataSettingsUrlSync'
import { MAX_PAGE_SIZE, MIN_PAGE_SIZE, TableSettings, getDefaultTableSettings } from 'src/ui-shared/table/Table.const'

/**
 * Hook for updating the table settings state object with values from URL query parameters and vice versa,
 * updating url query parameters when the table settings state changes in UI.
 * @param location the window location
 * @param setTableSettings set state for the table
 * @param tableSettings current state for the table
 * @param defaultTableSettings default state for the table
 */
export const useTableSettingsUrlSync = (
  location: Location,
  setTableSettings: React.Dispatch<React.SetStateAction<any>>,
  tableSettings: TableSettings,
  defaultTableSettings: TableSettings,
): void => {
  useDataSettingsUrlSyncMain(location, setTableSettings, tableSettings, defaultTableSettings, correctTableQueryParams)
}

const correctTableQueryParams = (queryObject: Record<string, any>): Record<string, any> => {
  const search = queryObject.search
  if (search !== null && search !== undefined) {
    queryObject.search = search.toString()
  }

  const defaultTableSettings = getDefaultTableSettings()

  const page = correctIntParam(queryObject.page, 0, 1000, defaultTableSettings.page)
  if (page !== undefined) {
    queryObject.page = page
  }

  const size = correctIntParam(queryObject.size, MIN_PAGE_SIZE, MAX_PAGE_SIZE, defaultTableSettings.size)
  if (size !== undefined) {
    queryObject.size = size
  }

  const orderDir = correctOrderDirParam(queryObject.orderDir, defaultTableSettings.orderDir)
  if (orderDir !== undefined) {
    queryObject.orderDir = orderDir
  }

  return { ...queryObject }
}
