import React, { ReactElement, useContext } from 'react'
import { Box } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { isPathAllowedForUser } from 'src/routing/Routing.utils'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { UserContext } from 'src/user/UserContext'

interface Props {
  path: string
  component: React.FC
}

export const RestrictedRoute = ({ path, component: Component, ...forwardProps }: Props): ReactElement => {
  const { user } = useContext(UserContext)
  const appId = useAppId()
  const translate = useTranslate()

  if (isPathAllowedForUser(path, user, appId)) {
    return <Component {...forwardProps} />
  }

  return (
    <Box p={3}>
      <ErrorMessage message={translate('authorization-error')} />
    </Box>
  )
}
