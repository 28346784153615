import { DashboardAssetsApi } from 'src/service/backend/api'
import { mocked } from 'src/service/backend/api-mock/MockUtils'
import { DashboardAssetList } from 'src/service/backend/api/models'
import { InitOverrideFunction } from 'src/service/backend/api/runtime'

const assetsJson =
  '{"result":[{"type":"SMAG_PROFESSIONAL_WA","total":3,"openIssues":0},{"type":"OTHERS_STANDARD_WT","total":2,"openIssues":1},{"type":"SMAG_DOS","total":1,"openIssues":0},{"type":"OTHERS_WT_ASSET","total":2,"openIssues":0},{"type":"OTHERS_STANDARD_RLWT","total":4,"openIssues":0},{"type":"OTHERS_FIXED_ASSET","total":1,"openIssues":0},{"type":"SMAG_STANDARD_WA","total":8,"openIssues":5},{"type":"SMAG_STANDARD_WT","total":5,"openIssues":13},{"type":"SMAG_PROFESSIONAL_WE20","total":1,"openIssues":0},{"type":"SMAG_PROFESSIONAL_WT20","total":1,"openIssues":0},{"type":"GMP_ROT_IRON","total":1,"openIssues":0},{"type":"OTHERS_STANDARD_WAT","total":1,"openIssues":0},{"type":"OTHERS_WA_ASSET","total":3,"openIssues":3},{"type":"OTHERS_STANDARD_WA","total":9,"openIssues":1},{"type":"OTHERS_IRON_ASSET","total":3,"openIssues":0},{"type":"SMAG_PROFESSIONAL_WT","total":1,"openIssues":0}]}'

export class DashboardAssetsApiMock extends DashboardAssetsApi {
  dashboardSmAssetsGet(
    organizationId?: string,
    initOverrides?: RequestInit | InitOverrideFunction,
  ): Promise<DashboardAssetList> {
    const json = assetsJson

    return mocked(JSON.parse(json)) as Promise<DashboardAssetList>
  }
}
