/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PageableProgramInstallations,
  ProgramInstalled,
  ProgramLiquidDetergentUpdate,
  ProgramType,
} from '../models';
import {
    PageableProgramInstallationsFromJSON,
    PageableProgramInstallationsToJSON,
    ProgramInstalledFromJSON,
    ProgramInstalledToJSON,
    ProgramLiquidDetergentUpdateFromJSON,
    ProgramLiquidDetergentUpdateToJSON,
    ProgramTypeFromJSON,
    ProgramTypeToJSON,
} from '../models';

export interface MachinesMachineIdProgramsGetRequest {
    machineId: string;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    programType?: ProgramType;
    programMode?: string;
    programName?: string;
    temperature?: number;
}

export interface MachinesMachineIdProgramsProgramIdDeleteRequest {
    programId: string;
    machineId: string;
}

export interface MachinesMachineIdProgramsProgramIdGetRequest {
    programId: string;
    machineId: string;
}

export interface MachinesMachineIdProgramsProgramIdLiquidDetergentDeleteRequest {
    machineId: string;
    programId: string;
}

export interface MachinesMachineIdProgramsProgramIdLiquidDetergentPutRequest {
    machineId: string;
    programId: string;
    programLiquidDetergentUpdate?: ProgramLiquidDetergentUpdate | null;
}

export interface MachinesMachineIdProgramsUploadCustomPostRequest {
    machineId: string;
    filename?: Array<Blob>;
}

/**
 * 
 */
export class ProgramsMachineApi extends runtime.BaseAPI {

    /**
     * Get a list of standard and/or custom programs for machine
     */
    async machinesMachineIdProgramsGetRaw(requestParameters: MachinesMachineIdProgramsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableProgramInstallations>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdProgramsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.programType !== undefined) {
            queryParameters['programType'] = requestParameters.programType;
        }

        if (requestParameters.programMode !== undefined) {
            queryParameters['programMode'] = requestParameters.programMode;
        }

        if (requestParameters.programName !== undefined) {
            queryParameters['programName'] = requestParameters.programName;
        }

        if (requestParameters.temperature !== undefined) {
            queryParameters['temperature'] = requestParameters.temperature;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/{machineId}/programs`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableProgramInstallationsFromJSON(jsonValue));
    }

    /**
     * Get a list of standard and/or custom programs for machine
     */
    async machinesMachineIdProgramsGet(machineId: string, size?: number, page?: number, sort?: string, search?: string, programType?: ProgramType, programMode?: string, programName?: string, temperature?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableProgramInstallations> {
        const response = await this.machinesMachineIdProgramsGetRaw({ machineId: machineId, size: size, page: page, sort: sort, search: search, programType: programType, programMode: programMode, programName: programName, temperature: temperature }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a custom program
     */
    async machinesMachineIdProgramsProgramIdDeleteRaw(requestParameters: MachinesMachineIdProgramsProgramIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.programId === null || requestParameters.programId === undefined) {
            throw new runtime.RequiredError('programId','Required parameter requestParameters.programId was null or undefined when calling machinesMachineIdProgramsProgramIdDelete.');
        }

        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdProgramsProgramIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/{machineId}/programs/{programId}`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters.programId))).replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a custom program
     */
    async machinesMachineIdProgramsProgramIdDelete(programId: string, machineId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.machinesMachineIdProgramsProgramIdDeleteRaw({ programId: programId, machineId: machineId }, initOverrides);
    }

    /**
     * Get a standard/custom program info for machine.
     */
    async machinesMachineIdProgramsProgramIdGetRaw(requestParameters: MachinesMachineIdProgramsProgramIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramInstalled>> {
        if (requestParameters.programId === null || requestParameters.programId === undefined) {
            throw new runtime.RequiredError('programId','Required parameter requestParameters.programId was null or undefined when calling machinesMachineIdProgramsProgramIdGet.');
        }

        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdProgramsProgramIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/{machineId}/programs/{programId}`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters.programId))).replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramInstalledFromJSON(jsonValue));
    }

    /**
     * Get a standard/custom program info for machine.
     */
    async machinesMachineIdProgramsProgramIdGet(programId: string, machineId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramInstalled> {
        const response = await this.machinesMachineIdProgramsProgramIdGetRaw({ programId: programId, machineId: machineId }, initOverrides);
        return await response.value();
    }

    /**
     * Reset liquid detergent settings for a standard program for a machine
     */
    async machinesMachineIdProgramsProgramIdLiquidDetergentDeleteRaw(requestParameters: MachinesMachineIdProgramsProgramIdLiquidDetergentDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramInstalled>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdProgramsProgramIdLiquidDetergentDelete.');
        }

        if (requestParameters.programId === null || requestParameters.programId === undefined) {
            throw new runtime.RequiredError('programId','Required parameter requestParameters.programId was null or undefined when calling machinesMachineIdProgramsProgramIdLiquidDetergentDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/{machineId}/programs/{programId}/liquid-detergent`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))).replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters.programId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramInstalledFromJSON(jsonValue));
    }

    /**
     * Reset liquid detergent settings for a standard program for a machine
     */
    async machinesMachineIdProgramsProgramIdLiquidDetergentDelete(machineId: string, programId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramInstalled> {
        const response = await this.machinesMachineIdProgramsProgramIdLiquidDetergentDeleteRaw({ machineId: machineId, programId: programId }, initOverrides);
        return await response.value();
    }

    /**
     * Update liquid detergent settings for a standard program for a machine
     */
    async machinesMachineIdProgramsProgramIdLiquidDetergentPutRaw(requestParameters: MachinesMachineIdProgramsProgramIdLiquidDetergentPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramInstalled>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdProgramsProgramIdLiquidDetergentPut.');
        }

        if (requestParameters.programId === null || requestParameters.programId === undefined) {
            throw new runtime.RequiredError('programId','Required parameter requestParameters.programId was null or undefined when calling machinesMachineIdProgramsProgramIdLiquidDetergentPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines/{machineId}/programs/{programId}/liquid-detergent`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))).replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters.programId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProgramLiquidDetergentUpdateToJSON(requestParameters.programLiquidDetergentUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramInstalledFromJSON(jsonValue));
    }

    /**
     * Update liquid detergent settings for a standard program for a machine
     */
    async machinesMachineIdProgramsProgramIdLiquidDetergentPut(machineId: string, programId: string, programLiquidDetergentUpdate?: ProgramLiquidDetergentUpdate | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramInstalled> {
        const response = await this.machinesMachineIdProgramsProgramIdLiquidDetergentPutRaw({ machineId: machineId, programId: programId, programLiquidDetergentUpdate: programLiquidDetergentUpdate }, initOverrides);
        return await response.value();
    }

    /**
     * Upload new Custom Programs for given Machine
     */
    async machinesMachineIdProgramsUploadCustomPostRaw(requestParameters: MachinesMachineIdProgramsUploadCustomPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableProgramInstallations>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdProgramsUploadCustomPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.filename) {
            requestParameters.filename.forEach((element) => {
                formParams.append('filename', element as any);
            })
        }

        const response = await this.request({
            path: `/machines/{machineId}/programs/upload-custom`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableProgramInstallationsFromJSON(jsonValue));
    }

    /**
     * Upload new Custom Programs for given Machine
     */
    async machinesMachineIdProgramsUploadCustomPost(machineId: string, filename?: Array<Blob>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableProgramInstallations> {
        const response = await this.machinesMachineIdProgramsUploadCustomPostRaw({ machineId: machineId, filename: filename }, initOverrides);
        return await response.value();
    }

}
