/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';
import type { ServicePackageOption } from './ServicePackageOption';
import {
    ServicePackageOptionFromJSON,
    ServicePackageOptionFromJSONTyped,
    ServicePackageOptionToJSON,
} from './ServicePackageOption';
import type { ServicePackagePlan } from './ServicePackagePlan';
import {
    ServicePackagePlanFromJSON,
    ServicePackagePlanFromJSONTyped,
    ServicePackagePlanToJSON,
} from './ServicePackagePlan';
import type { ServicePackagePlanReference } from './ServicePackagePlanReference';
import {
    ServicePackagePlanReferenceFromJSON,
    ServicePackagePlanReferenceFromJSONTyped,
    ServicePackagePlanReferenceToJSON,
} from './ServicePackagePlanReference';

/**
 * 
 * @export
 * @interface LaundryGroupSubscription
 */
export interface LaundryGroupSubscription {
    /**
     * 
     * @type {LaundryGroupReference}
     * @memberof LaundryGroupSubscription
     */
    laundryGroup: LaundryGroupReference;
    /**
     * 
     * @type {ServicePackagePlanReference}
     * @memberof LaundryGroupSubscription
     */
    activatedServicePackagePlan: ServicePackagePlanReference;
    /**
     * 
     * @type {Array<ServicePackagePlan>}
     * @memberof LaundryGroupSubscription
     */
    servicePackagePlans: Array<ServicePackagePlan>;
    /**
     * 
     * @type {Array<ServicePackageOption>}
     * @memberof LaundryGroupSubscription
     */
    servicePackagePlanOptions: Array<ServicePackageOption>;
}

/**
 * Check if a given object implements the LaundryGroupSubscription interface.
 */
export function instanceOfLaundryGroupSubscription(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "laundryGroup" in value;
    isInstance = isInstance && "activatedServicePackagePlan" in value;
    isInstance = isInstance && "servicePackagePlans" in value;
    isInstance = isInstance && "servicePackagePlanOptions" in value;

    return isInstance;
}

export function LaundryGroupSubscriptionFromJSON(json: any): LaundryGroupSubscription {
    return LaundryGroupSubscriptionFromJSONTyped(json, false);
}

export function LaundryGroupSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'laundryGroup': LaundryGroupReferenceFromJSON(json['laundryGroup']),
        'activatedServicePackagePlan': ServicePackagePlanReferenceFromJSON(json['activatedServicePackagePlan']),
        'servicePackagePlans': ((json['servicePackagePlans'] as Array<any>).map(ServicePackagePlanFromJSON)),
        'servicePackagePlanOptions': ((json['servicePackagePlanOptions'] as Array<any>).map(ServicePackageOptionFromJSON)),
    };
}

export function LaundryGroupSubscriptionToJSON(value?: LaundryGroupSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'laundryGroup': LaundryGroupReferenceToJSON(value.laundryGroup),
        'activatedServicePackagePlan': ServicePackagePlanReferenceToJSON(value.activatedServicePackagePlan),
        'servicePackagePlans': ((value.servicePackagePlans as Array<any>).map(ServicePackagePlanToJSON)),
        'servicePackagePlanOptions': ((value.servicePackagePlanOptions as Array<any>).map(ServicePackageOptionToJSON)),
    };
}

