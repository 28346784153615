export function getDownloadFilename(response: Response): string | null {
  const filename = response.headers.get('file-name')
  return filename
}

export function downloadFile(blob: Blob, filename: string | null = null, newTab = false): void {
  // Create blob link to download
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!link) {
    throw new Error('Error creating download link')
  }

  link.href = url
  if (filename) {
    link.setAttribute('download', filename)
  }

  if (newTab) {
    link.setAttribute('target', '_blank')
  }

  // Append to html link element page
  document.body.appendChild(link)

  // Start download
  link.click()

  // Clean up and remove the link
  link.parentNode?.removeChild(link)

  setTimeout(function () {
    // Firefox, necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(url)
  }, 100)
}
