/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrganizationType } from './OrganizationType';
import {
    OrganizationTypeFromJSON,
    OrganizationTypeFromJSONTyped,
    OrganizationTypeToJSON,
} from './OrganizationType';

/**
 * 
 * @export
 * @interface OrganizationReference
 */
export interface OrganizationReference {
    /**
     * UUID of the Organization
     * @type {string}
     * @memberof OrganizationReference
     */
    id: string;
    /**
     * Name of the Organization
     * @type {string}
     * @memberof OrganizationReference
     */
    readonly name: string | null;
    /**
     * 
     * @type {OrganizationType}
     * @memberof OrganizationReference
     */
    type?: OrganizationType | null;
}

/**
 * Check if a given object implements the OrganizationReference interface.
 */
export function instanceOfOrganizationReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function OrganizationReferenceFromJSON(json: any): OrganizationReference {
    return OrganizationReferenceFromJSONTyped(json, false);
}

export function OrganizationReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': !exists(json, 'type') ? undefined : OrganizationTypeFromJSON(json['type']),
    };
}

export function OrganizationReferenceToJSON(value?: OrganizationReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': OrganizationTypeToJSON(value.type),
    };
}

