/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModuleLiquidDetergentSettings } from './ModuleLiquidDetergentSettings';
import {
    ModuleLiquidDetergentSettingsFromJSON,
    ModuleLiquidDetergentSettingsFromJSONTyped,
    ModuleLiquidDetergentSettingsToJSON,
} from './ModuleLiquidDetergentSettings';

/**
 * 
 * @export
 * @interface ProgramLiquidDetergentUpdate
 */
export interface ProgramLiquidDetergentUpdate {
    /**
     * 
     * @type {Array<ModuleLiquidDetergentSettings>}
     * @memberof ProgramLiquidDetergentUpdate
     */
    liquidDetergentSteps: Array<ModuleLiquidDetergentSettings>;
}

/**
 * Check if a given object implements the ProgramLiquidDetergentUpdate interface.
 */
export function instanceOfProgramLiquidDetergentUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "liquidDetergentSteps" in value;

    return isInstance;
}

export function ProgramLiquidDetergentUpdateFromJSON(json: any): ProgramLiquidDetergentUpdate {
    return ProgramLiquidDetergentUpdateFromJSONTyped(json, false);
}

export function ProgramLiquidDetergentUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramLiquidDetergentUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'liquidDetergentSteps': ((json['liquidDetergentSteps'] as Array<any>).map(ModuleLiquidDetergentSettingsFromJSON)),
    };
}

export function ProgramLiquidDetergentUpdateToJSON(value?: ProgramLiquidDetergentUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'liquidDetergentSteps': ((value.liquidDetergentSteps as Array<any>).map(ModuleLiquidDetergentSettingsToJSON)),
    };
}

