import React, { ReactElement, useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { GetApp } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { NavigateState } from 'src/routing/Routing'
import { ApiResponse, Configuration, FHMonitoringsApi, Permission } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getRemoveAcceptHeaderInit } from 'src/service/backend/http/HttpUtils'
import { downloadFile, getDownloadFilename } from 'src/service/utils/FileDownloadUtils'
import { getLastMonth, removeTimeFromDate, removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { getDefaultTableSettings, withOrganization } from 'src/ui-shared/table/Table.const'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { ErrorLogTable, ErrorLogTableSettings } from 'src/ui/page/sm/error-log/list/ErrorLogTable'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

const dateNow = removeTimeFromDateEndOfDay(new Date())
const dateLastMonth = removeTimeFromDate(getLastMonth(dateNow))

export const ErrorLogListPage = (): ReactElement => {
  const translate = useTranslate()

  const user = useUser()
  const navigate = useNavigate()
  const appId = useAppId()
  const activeOrganization = useActiveOrganization()

  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const fhMonitoringApi = new FHMonitoringsApi(httpConfiguration)

  const defaultTableSettings: ErrorLogTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'errorTime',
    orderDir: 'desc',
    startTimeFrom: dateLastMonth.toISOString(),
    startTimeTo: dateNow.toISOString(),
  }
  const [tableSettings, setTableSettings] = useState<ErrorLogTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )

  // handle events
  const handleRowClick = (messageId?: string, timestamp?: number) => {
    navigate(`/${appId}/error-log/${messageId}/${timestamp}/view`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleExportButtonClick = () => {
    if (!tableSettings.startTimeFrom || !tableSettings.startTimeTo) {
      return
    }
    const dateStartTimeFrom: Date = new Date(tableSettings.startTimeFrom)
    const dateStartTimeTo: Date = new Date(tableSettings.startTimeTo)
    let filename: string | null

    fhMonitoringApi
      .fhMonitoringsCsvGetRaw(
        {
          startTimeFrom: dateStartTimeFrom,
          startTimeTo: dateStartTimeTo,
          organizationId: tableSettings.organizationId,
          laundryGroupId: tableSettings.laundryGroupId,
          laundryId: tableSettings.laundryId,
          machineId: tableSettings.machineId,
        },
        getRemoveAcceptHeaderInit(),
      )
      .then(async (response: ApiResponse<Blob>) => {
        filename = getDownloadFilename(response.raw)
        return response.value()
      })
      .then((blob: Blob) => {
        downloadFile(blob, filename)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  const exportCsvAction = (
    <ListingButton onClick={handleExportButtonClick} variant="outlined" color="primary" startIcon={<GetApp />}>
      {translate('exportCSV')}
    </ListingButton>
  )

  const actions = hasPermission(user, Permission.ERROR_LOG_READ) ? exportCsvAction : <></>

  return (
    <ScreenLayout title={translate('errorMessages')} actions={actions}>
      <ErrorLogTable
        navigateToItem={handleRowClick}
        tableSettings={tableSettings}
        defaultTableSettings={defaultTableSettings}
        setTableSettings={setTableSettings}
      />
    </ScreenLayout>
  )
}
