import React, { ReactElement, useEffect, useState } from 'react'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

interface Props {
  filter: string
  label: string
  tableSettings: any
  delay?: number
  // TODO pst 2022-12-29: generic async text field should not know about table settings
  setTableSettings: (value: any) => void
  isValid?: (value: string) => boolean
  errorText?: string
  textFieldClassName?: string
}

export const TextFieldAsync = ({
  filter,
  label,
  tableSettings,
  delay,
  setTableSettings,
  isValid,
  errorText,
  textFieldClassName,
}: Props): ReactElement => {
  const filterValue = tableSettings[filter]

  const [timeoutState, setTimeoutState] = useState<any>(null)
  const [freeTextValue, setFreeTextValue] = useState<string>(filterValue ?? '')
  const [valid, setValid] = useState<boolean>(true)

  useEffect(() => {
    setFreeTextValue(tableSettings[filter])
  }, [filterValue])

  const handleFreeInputChange = (event: any): void => {
    const value = event.target.value
    const textValue = value.toString()

    setFreeTextValue(textValue)

    if (timeoutState) {
      clearTimeout(timeoutState)
    }

    // validate the input if specified
    if (isValid !== undefined) {
      const valid = isValid(textValue)
      setValid(valid)

      if (!valid) {
        return
      }
    }

    // update table settings
    setTimeoutState(
      setTimeout(
        () => {
          setTableSettings({
            ...tableSettings,
            [filter]: value,
          })
        },
        delay ? delay : 300,
      ),
    )
  }

  return (
    <TextFieldDefault
      label={label}
      value={freeTextValue}
      onChange={handleFreeInputChange}
      error={!valid}
      helperText={!valid ? errorText : ''}
      autoComplete="off"
      className={textFieldClassName}
    />
  )
}
