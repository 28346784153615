import React, { ReactElement, useMemo } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { Box, FormHelperText } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { CurrencyObject } from 'src/service/view-model/base/payment/Currencies'
import { CurrencyAutoComplete, CurrencyAutoCompleteProps } from 'src/ui-shared/form/control/CurrencyAutoComplete'

interface CurrencyAutoCompleteField extends CurrencyAutoCompleteProps {
  validate?: any
}

type ExposeCurrencyAutoCompleteFieldProps = Omit<CurrencyAutoCompleteField, 'onChange' | 'value'>

type AutoCompleteFieldValueType = CurrencyObject | undefined

const CustomCurrencyAutoComplete = (props: FieldRenderProps<AutoCompleteFieldValueType>): ReactElement => {
  const {
    input: { name, value, onChange: onChangeFinalForm },
    meta,
    disabled,
    ...rest
  } = props

  const { children, render, ...autoCompleteProps } = rest

  const { touched, error: errorObject } = meta

  const translate = useTranslate()

  const error = errorObject && translate(errorObject.errorKey, errorObject.params)

  const invalid = Boolean(touched && error)

  const onChangeMemo = useMemo<(optionValue: AutoCompleteFieldValueType) => void>(
    () =>
      (optionValue: AutoCompleteFieldValueType): void => {
        onChangeFinalForm(optionValue)
      },
    [onChangeFinalForm],
  )

  const finalAutoCompleteProps: CurrencyAutoCompleteProps = {
    ...autoCompleteProps,
    onChange: onChangeMemo,
    disabled,
    name: name,
    error: invalid,
    value,
  }

  return (
    <Box>
      <CurrencyAutoComplete {...finalAutoCompleteProps} />
      {invalid && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}

export const CurrencyAutoCompleteValidate = (props: ExposeCurrencyAutoCompleteFieldProps): ReactElement => (
  <Field component={CustomCurrencyAutoComplete} {...props} />
)
