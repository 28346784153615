import React, { ReactElement } from 'react'
import { Box } from '@mui/material'
import { FeedbackForm } from 'src/ui/layout/feedback-form/FeedbackForm'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { OnboardingSection } from 'src/ui/layout/onboarding-slideshow/OnboardingSection'
import { DashboardSm } from 'src/ui/page/sm/index/DashboardSm'
import { SLIDES_SM } from 'src/ui/page/sm/index/OnboardingSlidesSm'

export const IndexPageSm = (): ReactElement => {
  return (
    <ScreenLayout>
      <Box>
        <DashboardSm />
      </Box>

      <OnboardingSection slides={SLIDES_SM} />
      <FeedbackForm />
    </ScreenLayout>
  )
}
