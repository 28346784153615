/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LaundryGroupRecurringCalendar,
  LaundryGroupRecurringCalendarDay,
  LaundryGroupRecurringCalendarEntry,
  LaundryGroupSingleCalendar,
  LaundryGroupSingleCalendarDay,
  LaundryGroupSingleCalendarEntry,
} from '../models';
import {
    LaundryGroupRecurringCalendarFromJSON,
    LaundryGroupRecurringCalendarToJSON,
    LaundryGroupRecurringCalendarDayFromJSON,
    LaundryGroupRecurringCalendarDayToJSON,
    LaundryGroupRecurringCalendarEntryFromJSON,
    LaundryGroupRecurringCalendarEntryToJSON,
    LaundryGroupSingleCalendarFromJSON,
    LaundryGroupSingleCalendarToJSON,
    LaundryGroupSingleCalendarDayFromJSON,
    LaundryGroupSingleCalendarDayToJSON,
    LaundryGroupSingleCalendarEntryFromJSON,
    LaundryGroupSingleCalendarEntryToJSON,
} from '../models';

export interface LaundrygroupsLaundryGroupIdCalendarRecurringDayOfWeekDeleteRequest {
    laundryGroupId: string;
    dayOfWeek: number;
}

export interface LaundrygroupsLaundryGroupIdCalendarRecurringGetRequest {
    laundryGroupId: string;
}

export interface LaundrygroupsLaundryGroupIdCalendarRecurringPostRequest {
    laundryGroupId: string;
    laundryGroupRecurringCalendarEntry?: LaundryGroupRecurringCalendarEntry;
}

export interface LaundrygroupsLaundryGroupIdCalendarSingleDeleteRequest {
    laundryGroupId: string;
    startDate: Date;
}

export interface LaundrygroupsLaundryGroupIdCalendarSingleGetRequest {
    laundryGroupId: string;
    from?: Date;
    to?: Date;
}

export interface LaundrygroupsLaundryGroupIdCalendarSinglePostRequest {
    laundryGroupId: string;
    laundryGroupSingleCalendarEntry?: LaundryGroupSingleCalendarEntry;
}

/**
 * 
 */
export class LaundryGroupsCalendarApi extends runtime.BaseAPI {

    /**
     * Delete recurring opening hours for a Laundry Group and day of week
     */
    async laundrygroupsLaundryGroupIdCalendarRecurringDayOfWeekDeleteRaw(requestParameters: LaundrygroupsLaundryGroupIdCalendarRecurringDayOfWeekDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdCalendarRecurringDayOfWeekDelete.');
        }

        if (requestParameters.dayOfWeek === null || requestParameters.dayOfWeek === undefined) {
            throw new runtime.RequiredError('dayOfWeek','Required parameter requestParameters.dayOfWeek was null or undefined when calling laundrygroupsLaundryGroupIdCalendarRecurringDayOfWeekDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/calendar/recurring/{dayOfWeek}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))).replace(`{${"dayOfWeek"}}`, encodeURIComponent(String(requestParameters.dayOfWeek))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete recurring opening hours for a Laundry Group and day of week
     */
    async laundrygroupsLaundryGroupIdCalendarRecurringDayOfWeekDelete(laundryGroupId: string, dayOfWeek: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.laundrygroupsLaundryGroupIdCalendarRecurringDayOfWeekDeleteRaw({ laundryGroupId: laundryGroupId, dayOfWeek: dayOfWeek }, initOverrides);
    }

    /**
     * Get the recurring calendar settings of the Laundry Group
     */
    async laundrygroupsLaundryGroupIdCalendarRecurringGetRaw(requestParameters: LaundrygroupsLaundryGroupIdCalendarRecurringGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupRecurringCalendar>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdCalendarRecurringGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/calendar/recurring`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupRecurringCalendarFromJSON(jsonValue));
    }

    /**
     * Get the recurring calendar settings of the Laundry Group
     */
    async laundrygroupsLaundryGroupIdCalendarRecurringGet(laundryGroupId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupRecurringCalendar> {
        const response = await this.laundrygroupsLaundryGroupIdCalendarRecurringGetRaw({ laundryGroupId: laundryGroupId }, initOverrides);
        return await response.value();
    }

    /**
     * Set new recurring opening hours for a Laundry Group
     */
    async laundrygroupsLaundryGroupIdCalendarRecurringPostRaw(requestParameters: LaundrygroupsLaundryGroupIdCalendarRecurringPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupRecurringCalendarDay>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdCalendarRecurringPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/calendar/recurring`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryGroupRecurringCalendarEntryToJSON(requestParameters.laundryGroupRecurringCalendarEntry),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupRecurringCalendarDayFromJSON(jsonValue));
    }

    /**
     * Set new recurring opening hours for a Laundry Group
     */
    async laundrygroupsLaundryGroupIdCalendarRecurringPost(laundryGroupId: string, laundryGroupRecurringCalendarEntry?: LaundryGroupRecurringCalendarEntry, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupRecurringCalendarDay> {
        const response = await this.laundrygroupsLaundryGroupIdCalendarRecurringPostRaw({ laundryGroupId: laundryGroupId, laundryGroupRecurringCalendarEntry: laundryGroupRecurringCalendarEntry }, initOverrides);
        return await response.value();
    }

    /**
     * Delete single opening hours for a Laundry Group and date
     */
    async laundrygroupsLaundryGroupIdCalendarSingleDeleteRaw(requestParameters: LaundrygroupsLaundryGroupIdCalendarSingleDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdCalendarSingleDelete.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling laundrygroupsLaundryGroupIdCalendarSingleDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/calendar/single`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete single opening hours for a Laundry Group and date
     */
    async laundrygroupsLaundryGroupIdCalendarSingleDelete(laundryGroupId: string, startDate: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.laundrygroupsLaundryGroupIdCalendarSingleDeleteRaw({ laundryGroupId: laundryGroupId, startDate: startDate }, initOverrides);
    }

    /**
     * Get the single opening hours of the Laundry Group
     */
    async laundrygroupsLaundryGroupIdCalendarSingleGetRaw(requestParameters: LaundrygroupsLaundryGroupIdCalendarSingleGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupSingleCalendar>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdCalendarSingleGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/calendar/single`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupSingleCalendarFromJSON(jsonValue));
    }

    /**
     * Get the single opening hours of the Laundry Group
     */
    async laundrygroupsLaundryGroupIdCalendarSingleGet(laundryGroupId: string, from?: Date, to?: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupSingleCalendar> {
        const response = await this.laundrygroupsLaundryGroupIdCalendarSingleGetRaw({ laundryGroupId: laundryGroupId, from: from, to: to }, initOverrides);
        return await response.value();
    }

    /**
     * Add new single opening hours for a Laundry Group
     */
    async laundrygroupsLaundryGroupIdCalendarSinglePostRaw(requestParameters: LaundrygroupsLaundryGroupIdCalendarSinglePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupSingleCalendarDay>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdCalendarSinglePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/calendar/single`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryGroupSingleCalendarEntryToJSON(requestParameters.laundryGroupSingleCalendarEntry),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupSingleCalendarDayFromJSON(jsonValue));
    }

    /**
     * Add new single opening hours for a Laundry Group
     */
    async laundrygroupsLaundryGroupIdCalendarSinglePost(laundryGroupId: string, laundryGroupSingleCalendarEntry?: LaundryGroupSingleCalendarEntry, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupSingleCalendarDay> {
        const response = await this.laundrygroupsLaundryGroupIdCalendarSinglePostRaw({ laundryGroupId: laundryGroupId, laundryGroupSingleCalendarEntry: laundryGroupSingleCalendarEntry }, initOverrides);
        return await response.value();
    }

}
