import { AppId } from 'src/app/AppId'
import { Permission } from 'src/service/backend/api'
import { User } from 'src/user/User'

// the roles should not be public and indirectly with the isAdmin / isOperator function
// if there is permissions for specific check, that should be used instead of cheking role
export const SMAG_ADMIN = 'SMAG_ADMIN'
export const OPERATOR_ADMIN = 'OPERATOR_ADMIN'
export const OPERATOR_TECHNICIAN = 'OPERATOR_TECHNICIAN'
export const OPERATOR_USER = 'OPERATOR_USER'
export const OPERATOR_FINANCE = 'OPERATOR_FINANCE'

export const hasPermission = (user: User, permission: Permission): boolean => {
  // users without any roles should not be allowed to anything
  if (user.roles.length === 0) {
    return false
  }

  // admin should be allowed to any UI part
  if (isAdmin(user)) {
    return true
  }

  return user.permissions.some((userPermission) => userPermission === permission)
}

export const isAdmin = (user: User): boolean => {
  return user.roles.some((role) => role && role === SMAG_ADMIN)
}

export const isOperator = (user: User): boolean => {
  return user.roles.some(
    (role) =>
      role &&
      (role === OPERATOR_ADMIN || role === OPERATOR_USER || role === OPERATOR_TECHNICIAN || role === OPERATOR_FINANCE),
  )
}

export const hasApp = (user: User, appId: AppId): boolean => {
  return user.availableApps.includes(appId)
}

export const hasApps = (user: User, appIds: AppId[]): boolean => {
  for (const appId of appIds) {
    if (user.availableApps.includes(appId)) {
      return true
    }
  }
  return false
}

export const hasAnyApp = (user: User): boolean => {
  const result = hasApps(user, [AppId.WASH_MASTER, AppId.SERVICE_MASTER, AppId.EASY_SET])
  return result
}

export const hasMoreThanOneApp = (user: User): boolean => {
  return user.availableApps.length > 1
}
