import { IdAndName } from 'src/service/view-model/base/IdAndName'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'

export interface TempUnitObject extends IdAndName {}

export const TEMP_UNIT_CELSIUS: TempUnitObject = { name: 'temperatureUnitCelsius', id: '°C' }

export const TEMP_UNIT_FAHRENHEIT: TempUnitObject = { name: 'temperatureUnitFahrenheit', id: '°F' }

export const SUPPORTED_TEMP_UNITS: TempUnitObject[] = [TEMP_UNIT_CELSIUS, TEMP_UNIT_FAHRENHEIT]

export function getTempUnitObject(temp: string | undefined): TempUnitObject | undefined {
  return findReferenceObject(SUPPORTED_TEMP_UNITS, temp, 'id')
}
