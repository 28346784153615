import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Divider, Grid, Paper } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, MachineType, ProgramGroup, ProgramsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { StyledTabs } from 'src/ui-shared/base/tab-panel/StyledTabs'
import { TabPanel } from 'src/ui-shared/base/tab-panel/TabPanel'
import { ViewMode } from 'src/ui-shared/constants/Constants'
import { getGridTabBreakpointsForDynamicTabs } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ProgramGroupTable } from 'src/ui/page/es/program-group/list/ProgramGroupTable'
import { generateMachineTypeTabs } from 'src/ui/page/es/program/MachineTypeTabs'

interface Props {
  view?: ViewMode
  fixedOrganizationId?: string
  selectProgramGroup?: (programGroup: ProgramGroup | null) => void
  activeTabOnly?: MachineType
}

export const ProgramGroupList: FC<Props> = ({
  view = 'page',
  fixedOrganizationId,
  selectProgramGroup,
  activeTabOnly,
}): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const appId = useAppId()

  const { tabNameParam } = useParams()
  const tabName = tabNameParam && view === 'page' ? getEnumFromString(tabNameParam, MachineType) : undefined

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programsApi = new ProgramsApi(httpConfiguration)

  const [tabs, setTabs] = useState<MachineType[]>()
  const [activeTab, setActiveTab] = useState<MachineType>()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const pageView = view === 'page'

  // load data
  useEffect(() => {
    setActiveTab(tabName)
  }, [])

  useEffect(() => {
    let active = true
    setLoading(true)

    programsApi
      .programsEditableMachineTypesGet()
      .then((data) => {
        if (active) {
          setTabs(data.map((item) => item.machineType))
          setInitialActiveTab(data.map((item) => item.machineType))
          setLoading(false)
          setErrorMessage(null)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLoading(false)
        setTabs([])
      })
    return () => {
      active = false
    }
  }, [])

  const setInitialActiveTab = (data: MachineType[]) => {
    // select the tab which should be active only
    if (activeTabOnly) {
      setActiveTab(activeTabOnly)
    }

    // if on initial render tab is not selected or listing is in modal view, select the first available one.
    else if (!pageView || (!tabName && data.length > 0)) {
      handleTabChange({} as React.SyntheticEvent, data[0])
    }
  }

  // handle events
  const handleTabChange = (_: React.SyntheticEvent, newValue: MachineType) => {
    if (pageView) {
      const pathToNavigate = `/${appId}/program-groups/${newValue}/`
      navigate(pathToNavigate, { replace: true })

      // reset query parameters when switching tab
      location.search = ''
    }
    // reset state for selected program group when switching tabs in modal view
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    else if (!pageView && selectProgramGroup) {
      selectProgramGroup(null)
    }

    setActiveTab(newValue)
  }

  const handleRowClick = (programGroupId?: string) => {
    navigate(`/${appId}/program-groups/${activeTab}/${programGroupId}/view`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const generateTabsContent = () => {
    if (!tabs) {
      return <></>
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return tabs.map((item) => (
      <TabPanel key={item} value={activeTab ?? ''} index={item}>
        <ProgramGroupTable
          key={item}
          machineType={item}
          fixedOrganizationId={fixedOrganizationId}
          navigateToItem={handleRowClick}
          view={view}
          selectProgramGroup={selectProgramGroup}
        />
      </TabPanel>
    ))
  }

  return (
    <Paper elevation={0}>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <>
          {errorMessage ? (
            <ErrorMessage message={errorMessage} />
          ) : (
            <>
              <Grid container className={sharedClasses.TabsContainer}>
                <Grid item {...getGridTabBreakpointsForDynamicTabs(tabs?.length)}>
                  {tabs && tabs.length > 0 ? (
                    <StyledTabs
                      value={activeTab}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      onChange={handleTabChange}
                    >
                      {generateMachineTypeTabs(translate, tabs, activeTabOnly)}
                    </StyledTabs>
                  ) : null}
                </Grid>
              </Grid>
              <Divider />
              {generateTabsContent()}
            </>
          )}
        </>
      )}
    </Paper>
  )
}
