import { InitOverrideFunction } from 'src/service/backend/api'

export function getRemoveAcceptHeaderInit(): InitOverrideFunction {
  return getRemoveHeaderInit('Accept')
}

export function getRemoveHeaderInit(headerName: string): InitOverrideFunction {
  const initOverrides: InitOverrideFunction = ({ init }) => {
    return new Promise((resolve, _) => {
      if (init.headers) {
        delete init.headers[headerName]
      }
      resolve({})
    })
  }

  return initOverrides
}
