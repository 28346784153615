import { DateUtils } from 'src/service/utils/DateUtils'

// transform Date to ISO string format to be stored in local storage
export const JSON_DATE_SERIALIZER = (key: string, value: any): any => {
  if (value) {
    if (value instanceof Date) {
      return value.toISOString()
    }
  }

  // for non-Date values, return the value as-is
  return value
}

// return Date from ISO string format in local storage when reading
export const JSON_DATE_DESERIALIZER = (key: string, value: any): any => {
  if (value) {
    if (typeof value === 'string') {
      const isValidISODateFormat = DateUtils.isValidISODateForm(value)

      if (isValidISODateFormat) {
        const isDateValid = Date.parse(value)
        let date: Date | undefined

        if (!isNaN(isDateValid)) {
          date = new Date(value)

          return date
        }
      }
    }
  }

  // for non-Date values, return the value as-is
  return value
}
