/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MachineStatusType {
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    INVALID = 'INVALID',
    INITIALIZATION = 'INITIALIZATION',
    SETTINGS_MENU = 'SETTINGS_MENU',
    PROGRAM_SELECTION = 'PROGRAM_SELECTION',
    OPTION_SELECTION = 'OPTION_SELECTION',
    WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
    PROGRAM_RUNNING = 'PROGRAM_RUNNING',
    TEST_PROGRAM_RUNNING = 'TEST_PROGRAM_RUNNING',
    SUB_PROGRAM_RUNNING = 'SUB_PROGRAM_RUNNING',
    PROGRAM_PAUSED = 'PROGRAM_PAUSED',
    PROGRAM_ABORTING = 'PROGRAM_ABORTING',
    CRINKLE_CARE = 'CRINKLE_CARE',
    PROGRAM_END = 'PROGRAM_END',
    FATAL_ERROR = 'FATAL_ERROR',
    STANDBY = 'STANDBY'
}


export function MachineStatusTypeFromJSON(json: any): MachineStatusType {
    return MachineStatusTypeFromJSONTyped(json, false);
}

export function MachineStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineStatusType {
    return json as MachineStatusType;
}

export function MachineStatusTypeToJSON(value?: MachineStatusType | null): any {
    return value as any;
}

