import React, { FC, ReactElement, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Divider, Grid } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LaundryGroupPriceListExternal } from 'src/service/backend/api/models'
import { DateUtils } from 'src/service/utils/DateUtils'
import { formatAmountForLocale } from 'src/service/utils/NumberFormatUtils'
import { getDrumSizeLabel, getMachineTypeName } from 'src/service/view-model/machine/Machines'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { useUserRegionData, useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  priceList: LaundryGroupPriceListExternal | null | undefined
}

export const LaundryGroupPriceListExternalView: FC<Props> = ({ priceList }): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const { state } = useLocation()
  const showSnackbar = useShowSnackbar()
  const regionLocale = useUserRegionLocale()
  const regionData = useUserRegionData()

  // Show snackbar if there were changes in the prices
  useEffect(() => {
    if (state && state.showSnackbar) {
      const infoMessage = translate('appliedPriceChanges')
      showSnackbar(infoMessage, 'info')

      // removes the State in the url without re-render, if we don't do this if we reload the page it shows the snackbar again
      window.history.replaceState({}, document.title)
    }
  }, [])

  return (
    <>
      <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
        <Grid item xs={3}>
          <DetailsTextField
            value={getMachineTypeName(priceList?.machineType, translate)}
            label={translate('assignedMachineType')}
          />
        </Grid>
        <Grid item xs={3}>
          <DetailsTextField value={priceList?.name} label={translate('member.pricelist')} />
        </Grid>
        <Grid item xs={3}>
          <DetailsTextField
            value={getDrumSizeLabel(priceList?.drumSize, priceList?.drumSizeUnit)}
            label={translate('member.machinesize')}
          />
        </Grid>
        <Grid item xs={3}>
          <DetailsTextField value={priceList?.currency} label={translate('currency')} />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
        <Grid item xs={6}>
          <DetailsTextField
            value={formatAmountForLocale(priceList?.regularAmount, regionLocale, priceList?.currency)}
            label={translate('priceStandard')}
          />
        </Grid>
        <Grid item xs={6}>
          <DetailsTextField
            value={formatAmountForLocale(priceList?.happyHourAmount, regionLocale, priceList?.currency)}
            label={translate('priceHappyHour')}
          />
        </Grid>
        <Grid item xs={6}>
          <DetailsTextField
            value={DateUtils.convertSecondsToMinutes(priceList?.maxProgramTime)}
            label={translate('maxProgramTime')}
          />
        </Grid>
        <Grid item xs={6}>
          <DetailsTextField value={priceList?.amountPerUnit} label={translate('billingIncrement')} />
        </Grid>
      </Grid>
    </>
  )
}
