/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TopUpChartFactType {
    COUNT = 'COUNT',
    AMOUNT_SUM = 'AMOUNT_SUM'
}


export function TopUpChartFactTypeFromJSON(json: any): TopUpChartFactType {
    return TopUpChartFactTypeFromJSONTyped(json, false);
}

export function TopUpChartFactTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopUpChartFactType {
    return json as TopUpChartFactType;
}

export function TopUpChartFactTypeToJSON(value?: TopUpChartFactType | null): any {
    return value as any;
}

