import { TranslateFunction } from 'src/i18n/useMessageSource'
import { AuthenticationError } from 'src/service/backend/error/AuthenticationError'
import { AuthorizationError } from 'src/service/backend/error/AuthorizationError'
import { ClientError } from 'src/service/backend/error/ClientError'
import { HttpResponseError } from 'src/service/backend/error/HttpResponseError'
import { ServerError } from 'src/service/backend/error/ServerError'

const defaultErrorMessageKey = 'unknown-error'

export const errorMapper = (error: Error | unknown, translate: TranslateFunction): string => {
  let translatedErrorMessage: string | undefined = undefined

  // check if we already have translated error message from backend
  if (error instanceof HttpResponseError) {
    const httpErr = error as HttpResponseError
    if (httpErr.hasErrorMessageFromBackend) {
      translatedErrorMessage = httpErr.message
    }
  }

  if (error instanceof Error) {
    if (!translatedErrorMessage) {
      // no message from backend, get local one based on error type
      const errorMessageKey = errorMapperKey(error)

      translatedErrorMessage = translate(errorMessageKey)

      // if the error is of unknown type, also included the original error message
      if (errorMessageKey === defaultErrorMessageKey && error.message) {
        translatedErrorMessage += ': ' + error.message
      }
    }
  } else {
    if (typeof error === 'string') {
      translatedErrorMessage = error
    }
  }

  return translatedErrorMessage ?? 'unknown'
}

export const errorMapperKey = (error: Error | unknown): string => {
  console.error(error)

  let msg

  if (error instanceof HttpResponseError) {
    const httpErr = error as HttpResponseError
    const statusCode = httpErr.statusCode

    if (error instanceof ServerError) {
      if (statusCode === 501) {
        msg = 'server-error-not-implemented'
      } else {
        msg = 'server-error'
      }
    } else if (error instanceof ClientError) {
      if (statusCode === 404) {
        msg = 'client-error-not-found'
      } else if (statusCode === 428) {
        msg = 'client-error-precondition-required'
      } else {
        msg = 'client-error'
      }
    } else if (error instanceof AuthenticationError) {
      msg = 'authentication-error'
    } else if (error instanceof AuthorizationError) {
      msg = 'authorization-error'
    }
  }

  if (!msg) {
    msg = defaultErrorMessageKey
  }

  return msg
}

export const errorMapperReporting = (error: Error, translate: TranslateFunction): string => {
  let errorMessage: string | undefined
  if (error instanceof HttpResponseError) {
    const httpErr = error as HttpResponseError
    if (httpErr.statusCode && (httpErr.statusCode === 422 || httpErr.statusCode === 428)) {
      errorMessage = translate('errorReportingDisabled')
    }
  }

  if (!errorMessage) {
    errorMessage = errorMapper(error, translate)
  }
  return errorMessage
}
