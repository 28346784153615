import { SettingField } from 'src/service/backend/api'

export interface SettingFieldsWrapper {
  fields: SettingField[]
}

/**
 * Hook form path to the fields array above.
 * Must be in sync with the name of the variable above.
 */
export const SETTING_FIELDS_WRAPPER_FIELDS_PATH = 'fields'
