import React, { ReactElement, useMemo } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { Box, FormHelperText } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { CountryObject } from 'src/service/view-model/base/country/Countries'
import { CountryAutoComplete, CountryAutoCompleteProps } from 'src/ui-shared/form/control/CountryAutoComplete'

interface CountryAutoCompleteField extends CountryAutoCompleteProps {
  validate?: any
}

type ExposeCountryAutoCompleteFieldProps = Omit<CountryAutoCompleteField, 'onChange' | 'value'>

type AutoCompleteFieldValueType = CountryObject | undefined

const CustomCountryAutoComplete = (props: FieldRenderProps<AutoCompleteFieldValueType>): ReactElement => {
  const {
    input: { name, value, onChange: onChangeFinalForm },
    meta,
    disabled,
    ...rest
  } = props

  const { children, render, ...autoCompleteProps } = rest

  const { touched, error: errorObject } = meta

  const translate = useTranslate()

  const error = errorObject && translate(errorObject.errorKey, errorObject.params)

  const invalid = Boolean(touched && error)

  const onChangeMemo = useMemo<(optionValue: AutoCompleteFieldValueType) => void>(
    () =>
      (optionValue: AutoCompleteFieldValueType): void => {
        onChangeFinalForm(optionValue)
      },
    [onChangeFinalForm],
  )

  const finalAutoCompleteProps: CountryAutoCompleteProps = {
    ...autoCompleteProps,
    onChange: onChangeMemo,
    disabled,
    name: name,
    error: invalid,
    value,
  }

  return (
    <Box>
      <CountryAutoComplete {...finalAutoCompleteProps} />
      {invalid && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}

export const CountryAutoCompleteValidate = (props: ExposeCountryAutoCompleteFieldProps): ReactElement => (
  <Field component={CustomCountryAutoComplete} {...props} />
)
