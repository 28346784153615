/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectivityType } from './ConnectivityType';
import {
    ConnectivityTypeFromJSON,
    ConnectivityTypeFromJSONTyped,
    ConnectivityTypeToJSON,
} from './ConnectivityType';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';
import type { LaundryReference } from './LaundryReference';
import {
    LaundryReferenceFromJSON,
    LaundryReferenceFromJSONTyped,
    LaundryReferenceToJSON,
} from './LaundryReference';
import type { MachineAction } from './MachineAction';
import {
    MachineActionFromJSON,
    MachineActionFromJSONTyped,
    MachineActionToJSON,
} from './MachineAction';
import type { MachineCategory } from './MachineCategory';
import {
    MachineCategoryFromJSON,
    MachineCategoryFromJSONTyped,
    MachineCategoryToJSON,
} from './MachineCategory';
import type { MachineConnectivityType } from './MachineConnectivityType';
import {
    MachineConnectivityTypeFromJSON,
    MachineConnectivityTypeFromJSONTyped,
    MachineConnectivityTypeToJSON,
} from './MachineConnectivityType';
import type { MachineInlineStatus } from './MachineInlineStatus';
import {
    MachineInlineStatusFromJSON,
    MachineInlineStatusFromJSONTyped,
    MachineInlineStatusToJSON,
} from './MachineInlineStatus';
import type { MachineProducts } from './MachineProducts';
import {
    MachineProductsFromJSON,
    MachineProductsFromJSONTyped,
    MachineProductsToJSON,
} from './MachineProducts';
import type { MachineReference } from './MachineReference';
import {
    MachineReferenceFromJSON,
    MachineReferenceFromJSONTyped,
    MachineReferenceToJSON,
} from './MachineReference';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';
import type { MachineWashmaster } from './MachineWashmaster';
import {
    MachineWashmasterFromJSON,
    MachineWashmasterFromJSONTyped,
    MachineWashmasterToJSON,
} from './MachineWashmaster';
import type { ManufacturerModelReference } from './ManufacturerModelReference';
import {
    ManufacturerModelReferenceFromJSON,
    ManufacturerModelReferenceFromJSONTyped,
    ManufacturerModelReferenceToJSON,
} from './ManufacturerModelReference';
import type { ManufacturerReference } from './ManufacturerReference';
import {
    ManufacturerReferenceFromJSON,
    ManufacturerReferenceFromJSONTyped,
    ManufacturerReferenceToJSON,
} from './ManufacturerReference';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';

/**
 * 
 * @export
 * @interface Machine
 */
export interface Machine {
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    readonly thingName?: string;
    /**
     * 
     * @type {ConnectivityType}
     * @memberof Machine
     */
    connectivityType?: ConnectivityType;
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    name: string;
    /**
     * 
     * @type {ManufacturerReference}
     * @memberof Machine
     */
    manufacturer?: ManufacturerReference;
    /**
     * 
     * @type {ManufacturerModelReference}
     * @memberof Machine
     */
    manufacturerModel: ManufacturerModelReference;
    /**
     * 
     * @type {MachineType}
     * @memberof Machine
     */
    type: MachineType;
    /**
     * 
     * @type {MachineCategory}
     * @memberof Machine
     */
    category: MachineCategory;
    /**
     * 
     * @type {MachineConnectivityType}
     * @memberof Machine
     */
    machineConnectivityType?: MachineConnectivityType;
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    serialNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof Machine
     */
    readonly productionDateTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof Machine
     */
    readonly operatingHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Machine
     */
    readonly programCounter?: number;
    /**
     * 
     * @type {LaundryReference}
     * @memberof Machine
     */
    laundry?: LaundryReference;
    /**
     * 
     * @type {LaundryGroupReference}
     * @memberof Machine
     */
    laundryGroup?: LaundryGroupReference;
    /**
     * 
     * @type {OrganizationReference}
     * @memberof Machine
     */
    organization?: OrganizationReference;
    /**
     * 
     * @type {MachineInlineStatus}
     * @memberof Machine
     */
    status?: MachineInlineStatus;
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    notes?: string;
    /**
     * Returned on a Primary, contains List of thingNames which are not yet used as Followers, if empty no follower available
     * @type {Array<string>}
     * @memberof Machine
     */
    unusedFollowers?: Array<string>;
    /**
     * on a primary, when followers are existing
     * @type {Array<MachineReference>}
     * @memberof Machine
     */
    followers?: Array<MachineReference>;
    /**
     * 
     * @type {MachineReference}
     * @memberof Machine
     */
    primary?: MachineReference;
    /**
     * Possible actions to execute on a Machine
     * @type {Array<MachineAction>}
     * @memberof Machine
     */
    readonly actions?: Array<MachineAction>;
    /**
     * 
     * @type {MachineProducts}
     * @memberof Machine
     */
    products?: MachineProducts;
    /**
     * 
     * @type {MachineWashmaster}
     * @memberof Machine
     */
    washmaster?: MachineWashmaster | null;
}

/**
 * Check if a given object implements the Machine interface.
 */
export function instanceOfMachine(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "manufacturerModel" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "serialNumber" in value;

    return isInstance;
}

export function MachineFromJSON(json: any): Machine {
    return MachineFromJSONTyped(json, false);
}

export function MachineFromJSONTyped(json: any, ignoreDiscriminator: boolean): Machine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'thingName': !exists(json, 'thingName') ? undefined : json['thingName'],
        'connectivityType': !exists(json, 'connectivityType') ? undefined : ConnectivityTypeFromJSON(json['connectivityType']),
        'name': json['name'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : ManufacturerReferenceFromJSON(json['manufacturer']),
        'manufacturerModel': ManufacturerModelReferenceFromJSON(json['manufacturerModel']),
        'type': MachineTypeFromJSON(json['type']),
        'category': MachineCategoryFromJSON(json['category']),
        'machineConnectivityType': !exists(json, 'machineConnectivityType') ? undefined : MachineConnectivityTypeFromJSON(json['machineConnectivityType']),
        'serialNumber': json['serialNumber'],
        'productionDateTime': !exists(json, 'productionDateTime') ? undefined : (new Date(json['productionDateTime'])),
        'operatingHours': !exists(json, 'operatingHours') ? undefined : json['operatingHours'],
        'programCounter': !exists(json, 'programCounter') ? undefined : json['programCounter'],
        'laundry': !exists(json, 'laundry') ? undefined : LaundryReferenceFromJSON(json['laundry']),
        'laundryGroup': !exists(json, 'laundryGroup') ? undefined : LaundryGroupReferenceFromJSON(json['laundryGroup']),
        'organization': !exists(json, 'organization') ? undefined : OrganizationReferenceFromJSON(json['organization']),
        'status': !exists(json, 'status') ? undefined : MachineInlineStatusFromJSON(json['status']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'unusedFollowers': !exists(json, 'unusedFollowers') ? undefined : json['unusedFollowers'],
        'followers': !exists(json, 'followers') ? undefined : ((json['followers'] as Array<any>).map(MachineReferenceFromJSON)),
        'primary': !exists(json, 'primary') ? undefined : MachineReferenceFromJSON(json['primary']),
        'actions': !exists(json, 'actions') ? undefined : ((json['actions'] as Array<any>).map(MachineActionFromJSON)),
        'products': !exists(json, 'products') ? undefined : MachineProductsFromJSON(json['products']),
        'washmaster': !exists(json, 'washmaster') ? undefined : MachineWashmasterFromJSON(json['washmaster']),
    };
}

export function MachineToJSON(value?: Machine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connectivityType': ConnectivityTypeToJSON(value.connectivityType),
        'name': value.name,
        'manufacturer': ManufacturerReferenceToJSON(value.manufacturer),
        'manufacturerModel': ManufacturerModelReferenceToJSON(value.manufacturerModel),
        'type': MachineTypeToJSON(value.type),
        'category': MachineCategoryToJSON(value.category),
        'machineConnectivityType': MachineConnectivityTypeToJSON(value.machineConnectivityType),
        'serialNumber': value.serialNumber,
        'laundry': LaundryReferenceToJSON(value.laundry),
        'laundryGroup': LaundryGroupReferenceToJSON(value.laundryGroup),
        'organization': OrganizationReferenceToJSON(value.organization),
        'status': MachineInlineStatusToJSON(value.status),
        'notes': value.notes,
        'unusedFollowers': value.unusedFollowers,
        'followers': value.followers === undefined ? undefined : ((value.followers as Array<any>).map(MachineReferenceToJSON)),
        'primary': MachineReferenceToJSON(value.primary),
        'products': MachineProductsToJSON(value.products),
        'washmaster': MachineWashmasterToJSON(value.washmaster),
    };
}

