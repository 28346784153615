/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConnectivityFirmwareType {
    DEFAULT = 'DEFAULT',
    WM = 'WM',
    HM = 'HM'
}


export function ConnectivityFirmwareTypeFromJSON(json: any): ConnectivityFirmwareType {
    return ConnectivityFirmwareTypeFromJSONTyped(json, false);
}

export function ConnectivityFirmwareTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectivityFirmwareType {
    return json as ConnectivityFirmwareType;
}

export function ConnectivityFirmwareTypeToJSON(value?: ConnectivityFirmwareType | null): any {
    return value as any;
}

