import {
  HMResult,
  HMState,
  HygieneMonitoringDetail,
  HygieneMonitoringInformation,
  LaundryReference,
  MachineCategory,
  MachineReference,
  MachineType,
  OrganizationReference,
  OrganizationType,
  Page,
  PageableHygieneMonitoringInformation,
  ReportHygieneMonitoringsApi,
  TimeseriesData,
  TimeseriesValueType,
} from 'src/service/backend/api'
import { MOCK_HM } from 'src/service/backend/api-mock/HygieneMockData'
import { removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'

const getHygieneMonitoringList = () => {
  const laundryRef: LaundryReference = {
    id: '1',
    name: 'Laundry',
    laundryGroup: {
      id: '1',
      name: 'Laundry Group',
    },
  }

  const machineRef: MachineReference = {
    id: '1',
    name: 'Machine',
    category: MachineCategory.RLWT,
    type: MachineType.OTHERS_STANDARD_RLWT,
    serialNumber: '12345',
  }

  const organizationRef: OrganizationReference = {
    id: '1',
    name: 'Organization',
    type: OrganizationType.SCHULTHESS,
  }

  const entity: HygieneMonitoringInformation = {
    id: '0000',
    organization: organizationRef,
    laundryGroup: laundryRef.laundryGroup!,
    laundry: laundryRef,
    machine: machineRef,
    startTime: removeTimeFromDateEndOfDay(new Date()),
    state: HMState.ANALYZING,
    result: undefined,
    programName: 'Cotton 30',
  }

  const entity1: HygieneMonitoringInformation = {
    id: '0001',
    organization: organizationRef,
    laundryGroup: laundryRef.laundryGroup!,
    laundry: laundryRef,
    machine: machineRef,
    startTime: removeTimeFromDateEndOfDay(new Date()),
    state: HMState.FINISHED,
    result: HMResult.SUCCESSFUL,
    programName: 'Cotton 60',
  }

  const entity2: HygieneMonitoringInformation = {
    id: '0002',
    organization: organizationRef,
    laundryGroup: laundryRef.laundryGroup!,
    laundry: laundryRef,
    machine: machineRef,
    startTime: removeTimeFromDateEndOfDay(new Date()),
    state: HMState.FINISHED,
    result: HMResult.FAILED,
    programName: 'Cotton 90',
    failedReason: 'Did not reach temp',
  }

  return [entity, entity1, entity2]
}

const getMachineTimeseriesData = (): Array<TimeseriesData> => {
  const waterTemperature = {
    title: 'Water Temperature',
    datasets: [
      {
        label: 'Water Temperature (°C)',
        valueType: TimeseriesValueType.NUMBER,
        values: [
          {
            startTime: new Date(Date.parse('2023-02-07 00:01:00')),
            endTime: null,
            valueState: null,
            valueNumber: 40,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:02:00')),
            endTime: null,
            valueState: null,
            valueNumber: 55,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:03:00')),
            endTime: null,
            valueState: null,
            valueNumber: 80,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:04:00')),
            endTime: null,
            valueState: null,
            valueNumber: 80,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:05:00')),
            endTime: null,
            valueState: null,
            valueNumber: 65,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:06:00')),
            endTime: null,
            valueState: null,
            valueNumber: 45,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:07:00')),
            endTime: null,
            valueState: null,
            valueNumber: 35,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:08:00')),
            endTime: null,
            valueState: null,
            valueNumber: 15,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:08:00')),
            endTime: null,
            valueState: null,
            valueNumber: 15,
          },
        ],
      },
      {
        label: 'SOLL Water Temperature (°C)',
        valueType: TimeseriesValueType.NUMBER,
        values: [
          {
            startTime: new Date(Date.parse('2023-02-07 00:01:00')),
            endTime: null,
            valueState: null,
            valueNumber: 90,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:02:00')),
            endTime: null,
            valueState: null,
            valueNumber: 90,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:04:00')),
            endTime: null,
            valueState: null,
            valueNumber: 90,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:06:00')),
            endTime: null,
            valueState: null,
            valueNumber: 60,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:08:00')),
            endTime: null,
            valueState: null,
            valueNumber: 50,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:10:00')),
            endTime: null,
            valueState: null,
            valueNumber: 50,
          },
        ],
      },
    ],
    valueStates: [],
  } as TimeseriesData

  const waterLevel = {
    title: 'Water Level',
    datasets: [
      {
        label: 'Water Level (mm)',
        valueType: TimeseriesValueType.NUMBER,
        values: [
          {
            startTime: new Date(Date.parse('2023-02-07 00:01:00')),
            endTime: null,
            valueState: null,
            valueNumber: 30,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:02:00')),
            endTime: null,
            valueState: null,
            valueNumber: 25,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:03:00')),
            endTime: null,
            valueState: null,
            valueNumber: 30,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:04:00')),
            endTime: null,
            valueState: null,
            valueNumber: 30,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:05:00')),
            endTime: null,
            valueState: null,
            valueNumber: 35,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:06:00')),
            endTime: null,
            valueState: null,
            valueNumber: 20,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:07:00')),
            endTime: null,
            valueState: null,
            valueNumber: 25,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:08:00')),
            endTime: null,
            valueState: null,
            valueNumber: 20,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:09:00')),
            endTime: null,
            valueState: null,
            valueNumber: 10,
          },
        ],
      },
      {
        label: 'SOLL Water Level (mm)',
        valueType: TimeseriesValueType.NUMBER,
        values: [
          {
            startTime: new Date(Date.parse('2023-02-07 00:01:00')),
            endTime: null,
            valueState: 'wl',
            valueNumber: 40,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:02:00')),
            endTime: null,
            valueState: 'wl',
            valueNumber: 40,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:04:00')),
            endTime: null,
            valueState: 'wl',
            valueNumber: 40,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:06:00')),
            endTime: null,
            valueState: 'wl',
            valueNumber: 40,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:08:00')),
            endTime: null,
            valueState: 'wl',
            valueNumber: 40,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:10:00')),
            endTime: null,
            valueState: 'wl',
            valueNumber: 40,
          },
        ],
      },
    ],
    valueStates: [{ valueState: 'wl', color: '#0000FF' }],
  } as TimeseriesData

  const relais = {
    title: 'Relais',
    datasets: [
      {
        label: 'Relais',
        valueType: TimeseriesValueType.NUMBER,
        values: [
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 1,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 1,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 1,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 1,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 1,
          },
        ],
      },
      {
        label: 'Relais 1',
        valueType: TimeseriesValueType.NUMBER,
        values: [
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 1,
          },
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
        ],
      },
    ],
    valueStates: [],
  } as TimeseriesData

  const relais2 = {
    title: 'Relais',
    datasets: [
      {
        label: 'Relais',
        valueType: TimeseriesValueType.NUMBER,
        values: [
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 30,
          },
          {
            startTime: new Date(Date.parse('2023-02-08 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 50,
          },
          {
            startTime: new Date(Date.parse('2023-02-09 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 60,
          },
          {
            startTime: new Date(Date.parse('2023-02-10 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 80,
          },
          {
            startTime: new Date(Date.parse('2023-02-11 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 90,
          },
          {
            startTime: new Date(Date.parse('2023-02-12 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 20,
          },
          {
            startTime: new Date(Date.parse('2023-02-13 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 11,
          },
          {
            startTime: new Date(Date.parse('2023-02-14 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 22,
          },
          {
            startTime: new Date(Date.parse('2023-02-15 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 43,
          },
        ],
      },
      {
        label: 'Relais 1',
        valueType: TimeseriesValueType.NUMBER,
        values: [
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 30,
          },
          {
            startTime: new Date(Date.parse('2023-02-15 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 50,
          },
        ],
      },
    ],
    valueStates: [],
  } as TimeseriesData

  const input = {
    title: 'Inputs',
    datasets: [
      {
        label: 'Inputs',
        valueType: TimeseriesValueType.NUMBER,
        values: [
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 30,
          },
          {
            startTime: new Date(Date.parse('2023-02-08 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 50,
          },
          {
            startTime: new Date(Date.parse('2023-02-09 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 60,
          },
          {
            startTime: new Date(Date.parse('2023-02-10 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 80,
          },
          {
            startTime: new Date(Date.parse('2023-02-11 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 90,
          },
          {
            startTime: new Date(Date.parse('2023-02-12 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 20,
          },
          {
            startTime: new Date(Date.parse('2023-02-13 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 11,
          },
          {
            startTime: new Date(Date.parse('2023-02-14 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 22,
          },
          {
            startTime: new Date(Date.parse('2023-02-16 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 43,
          },
        ],
      },
    ],
    valueStates: [],
  } as TimeseriesData

  const spinSpeed = {
    title: 'Spin Speed',
    datasets: [
      {
        label: 'Spin Speed',
        valueType: TimeseriesValueType.NUMBER,
        values: [
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-08 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-09 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-10 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 1100,
          },
          {
            startTime: new Date(Date.parse('2023-02-11 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 800,
          },
          {
            startTime: new Date(Date.parse('2023-02-12 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-13 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-14 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 1100,
          },
          {
            startTime: new Date(Date.parse('2023-02-15 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 900,
          },
        ],
      },
      {
        label: 'SOLL Spin Speed',
        valueType: TimeseriesValueType.NUMBER,
        values: [
          {
            startTime: new Date(Date.parse('2023-02-07 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-08 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-09 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-10 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 1200,
          },
          {
            startTime: new Date(Date.parse('2023-02-11 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 1200,
          },
          {
            startTime: new Date(Date.parse('2023-02-12 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-13 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 0,
          },
          {
            startTime: new Date(Date.parse('2023-02-14 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 1200,
          },
          {
            startTime: new Date(Date.parse('2023-02-15 00:00:00')),
            endTime: null,
            valueState: null,
            valueNumber: 1200,
          },
        ],
      },
    ],
    valueStates: [],
  } as TimeseriesData

  return [waterTemperature, waterLevel, spinSpeed, relais, relais2, input]
}

const mocked = (): Promise<PageableHygieneMonitoringInformation> => {
  const resultArray: HygieneMonitoringInformation[] = getHygieneMonitoringList()
  const page: Page = { size: 10, totalPages: 1, totalElements: 3, number: 0 }
  const mockRes: PageableHygieneMonitoringInformation = { page: page, result: resultArray }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(mockRes)
    }, 400)
  })
}

const mockedHygieneMonitoringDetails = (): Promise<HygieneMonitoringDetail> => {
  const hygieneInformation = getHygieneMonitoringList()[1]
  const data = getMachineTimeseriesData()

  let ee = {
    information: hygieneInformation,
    data: data,
  } as HygieneMonitoringDetail

  ee = MOCK_HM

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(ee)
    }, 400)
  })
}

export class ReportHygieneMonitoringsApiMock extends ReportHygieneMonitoringsApi {
  async reportSmHygieneMonitoringsGet(
    startTimeFrom: Date,
    startTimeTo: Date,
    size?: number,
    page?: number,
    sort?: string,
    organizationId?: string,
    laundryGroupId?: string,
    laundryId?: string,
    machineId?: string,
    initOverrides?: RequestInit,
  ): Promise<PageableHygieneMonitoringInformation> {
    return mocked()
  }

  async reportSmHygieneMonitoringsHmIdGet(hmId: string, initOverrides?: RequestInit): Promise<HygieneMonitoringDetail> {
    return mockedHygieneMonitoringDetails()
  }
}
