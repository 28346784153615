import React, { FC, ReactElement, useState } from 'react'
import { Form } from 'react-final-form'
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import moment from 'moment'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LaundryUser } from 'src/service/backend/api'
import { formatIsoDateStringToDayAndDate } from 'src/service/utils/DateFormatUtils'
import { CalendarEntrySlot, SlotAndDay } from 'src/service/view-model/base/calendar/Calendar.const'
import { TimeTextFieldValidate } from 'src/ui-shared/base/form/control/TimeTextFieldValidate'
import {
  composeValidators,
  required,
  validSlotEndTime,
  validSlotTime,
} from 'src/ui-shared/base/form/validation/Validators'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { isTwelveHourFormat } from 'src/user/RegionDataUtils'
import { useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  slotAndDay: SlotAndDay | undefined
  laundryUser?: LaundryUser
  closeModal: () => void
  // dayOfWeek is iso date - 2023-10-10
  updateCalendarEntry: (dayOfWeek: string, startTime: string, endTime: string, isRecurring: boolean) => void
  enableRecurring: boolean
}

export const CalendarCreateReservation: FC<Props> = ({
  slotAndDay,
  laundryUser,
  closeModal,
  updateCalendarEntry,
  enableRecurring,
}): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const regionLocale = useUserRegionLocale()

  // state
  const [recurring, setRecurring] = useState<boolean>(false)

  // props
  const dayOfWeek = slotAndDay?.dayOfWeek ? slotAndDay.dayOfWeek : ''
  const startTime = slotAndDay?.slot.startTime ? slotAndDay.slot.startTime : ''
  const slotDate = moment(`${dayOfWeek} ${startTime}`)

  const submitForm = (calendarEntrySlot: CalendarEntrySlot) => {
    closeModal()
    return updateCalendarEntry(dayOfWeek, calendarEntrySlot.startTime, calendarEntrySlot.endTime, recurring)
  }

  let currentDate = moment(new Date())
  currentDate = currentDate.subtract(currentDate.minutes() % 30, 'minute').startOf('minutes')
  const isSelectedDateBeforeCurrent = slotDate.isBefore(currentDate)

  return (
    <Box pt={1} p={3}>
      <Form
        initialValues={slotAndDay?.slot}
        onSubmit={submitForm}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Box>
                <Grid container spacing={1} sx={{ paddingBottom: 1 }}>
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      {laundryUser ? laundryUser.firstName + ' ' + laundryUser.lastName : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      {formatIsoDateStringToDayAndDate(dayOfWeek, regionLocale, translate)}
                    </Typography>
                  </Grid>
                </Grid>
                {isSelectedDateBeforeCurrent ? (
                  <Grid container spacing={1} sx={{ paddingBottom: 1 }}>
                    <Grid item xs={12}>
                      <Typography variant="body1" color="secondary">
                        {translate('pastSlotReservation')}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TimeTextFieldValidate
                      name="startTime"
                      label={translate('startTime')}
                      fullWidth
                      twelveHourFormat={isTwelveHourFormat(regionLocale)}
                      validate={composeValidators(required(), validSlotTime())}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TimeTextFieldValidate
                      name="endTime"
                      label={translate('endTime')}
                      fullWidth
                      twelveHourFormat={isTwelveHourFormat(regionLocale)}
                      validate={composeValidators(required(), validSlotTime(), validSlotEndTime())}
                    />
                  </Grid>
                </Grid>
                {enableRecurring ? (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={recurring}
                            onChange={() => setRecurring(!recurring)}
                            name="isRecurring"
                          />
                        }
                        label={translate('isRecurring')}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  color="primary"
                  size="large"
                  className={sharedClasses.ButtonMargin}
                  onClick={closeModal}
                >
                  {translate('button.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={submitting || isSelectedDateBeforeCurrent}
                >
                  {translate('apply')}
                </Button>
              </Box>
            </form>
          )
        }}
      />
    </Box>
  )
}
