import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Grid, Paper, TableCell, TableRow } from '@mui/material'
import { errorMapperReporting } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, Machine, ReportUsagesApi, SMUsage } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocale, formatSecondsToHumanReadableTime } from 'src/service/utils/DateFormatUtils'
import { getLastMonth, removeTimeFromDate, removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'
import { formatTemperatureOptional } from 'src/service/utils/NumberFormatUtils'
import { Data } from 'src/service/view-model/base/Data'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  getDefaultTableSettings,
  tableSettingsSort,
} from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { getProgramTableFilters } from 'src/ui/page/common/machine/program/ProgramTableFilters'
import { ProgramUsageTableSettings } from 'src/ui/page/sm/program-usage/list/ProgramUsageTable'
import { useUserRegionLocale } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'startTime',
    label: 'startDate',
  },
  {
    id: 'programIndex',
    label: 'programInfo.index',
    noSort: true,
  },
  {
    id: 'programInfo.temperature',
    label: 'temperatureShort',
  },
  {
    id: 'programInfo.programName',
    label: 'programName',
  },
  {
    id: 'programInfo.programMode',
    label: 'programMode',
  },
  {
    id: 'programDuration',
    label: 'programDuration',
    noSort: true,
  },
]

interface Props {
  machine: Machine
}

const dateNow = removeTimeFromDateEndOfDay(new Date())
const dateLastMonth = removeTimeFromDate(getLastMonth(dateNow))

export const MachineProgramUsageTab: FC<Props> = ({ machine }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const location = useLocation()
  const translate = useTranslate()
  const regionLocale = useUserRegionLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportsApi = new ReportUsagesApi(httpConfiguration)

  // state
  const [data, setData] = useState<TableData<SMUsage>>(DEFAULT_DATA)
  const defaultTableSettings: ProgramUsageTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'startTime',
    orderDir: 'desc',
    startTimeFrom: dateLastMonth.toISOString(),
    startTimeTo: dateNow.toISOString(),
  }
  const [tableSettings, setTableSettings] = useState<ProgramUsageTableSettings>(defaultTableSettings)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  // load data on state change
  useEffect(() => {
    let active = true

    setErrorMessage(null)

    if (tableSettings.startTimeFrom && tableSettings.startTimeTo) {
      const dateStartTimeFrom = new Date(tableSettings.startTimeFrom)
      const dateStartTimeTo = new Date(tableSettings.startTimeTo)

      setLoading(true)

      reportsApi
        .reportSmUsagesGet(
          dateStartTimeFrom,
          dateStartTimeTo,
          tableSettings.size,
          tableSettings.page,
          tableSettingsSort(tableSettings),
          undefined,
          undefined,
          undefined,
          machine.id,
          undefined,
          tableSettings.programMode,
          tableSettings.programName,
          tableSettings.temperature,
        )
        .then((data) => {
          if (active) {
            updateTableSettingsFromData(data as Data<any>, tableSettings)
            setTableSettings(tableSettings)

            setData(mapData(data as Data<any>))
            setLoading(false)
          }
        })
        .catch((err) => {
          const errorMessage = errorMapperReporting(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setData(DEFAULT_DATA)
          setLoading(false)
        })
      return () => {
        active = false
      }
    }
  }, [tableSettings])

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  // JSX
  const getFilter = (headCellId: string) => {
    return getProgramTableFilters(headCellId, translate, tableSettings, setTableSettings)
  }

  const nonEmptyRows = data.data.map((item, index) => {
    return (
      <TableRow className={tableClasses.tableRow} style={{ cursor: 'auto' }} key={index}>
        <TableCell width="15%">{formatDateTimeForLocale(item.startTime, regionLocale)}</TableCell>
        <TableCell width="10%">{item.programInfo?.programIndex}</TableCell>
        <TableCell width="10%">
          {formatTemperatureOptional(item.programInfo?.temperature, item.programInfo?.temperatureUnit)}
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={item.programInfo?.programName} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={item.programInfo?.programMode} />
        </TableCell>
        <TableCell>{item.programDuration && formatSecondsToHumanReadableTime(item.programDuration)}</TableCell>
      </TableRow>
    )
  })

  return (
    <>
      <Box mt={3} mb={3}>
        <Grid container spacing={2}>
          <TableDatePickerForm
            key={`${tableSettings.startTimeFrom}${tableSettings.startTimeTo}`}
            firstDatePropName="startTimeFrom"
            secondDatePropName="startTimeTo"
            setTableSettings={setTableSettings}
            tableSettings={tableSettings}
          />
        </Grid>
      </Box>

      <Paper elevation={0}>
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

        <DataTable
          headCells={headCells}
          data={data}
          nonEmptyRows={nonEmptyRows}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          getFilter={getFilter}
          loading={loading}
          translate={translate}
        />
      </Paper>
    </>
  )
}
