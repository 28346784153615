import React, { ReactElement } from 'react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { GoogleAnalyticsLoader } from 'src/analytics/GoogleAnalyticsLoader'
import { EnvironmentContextProvider } from 'src/env/EnvironmentContext'
import { MessageSourceLoader } from 'src/i18n/MessageSourceLoader'
import { AllRoutes } from 'src/routing/AllRoutes'
import { HttpContextProvider } from 'src/service/backend/http/HttpContext'
import { WM_THEME } from 'src/theme/Theme'
import { SnackbarProvider } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { NewNotificationsProvider } from 'src/ui-shared/notification/NewNotificationsProvider'
import { AddToHomeTooltip } from 'src/ui/layout/add-to-home-tooltip/AddToHomeTooltip'
import { VersionCheckProvider } from 'src/ui/layout/version-check/VersionCheckProvider'
import { InitUser } from 'src/user/InitUser'
import { UserContextProvider } from 'src/user/UserContext'

export const App = (): ReactElement => {
  return (
    <EnvironmentContextProvider>
      <ThemeProvider theme={WM_THEME}>
        <CssBaseline />
        <UserContextProvider>
          <MessageSourceLoader>
            <HttpContextProvider>
              <InitUser>
                <GoogleAnalyticsLoader>
                  <SnackbarProvider>
                    <VersionCheckProvider>
                      <NewNotificationsProvider>
                        <AddToHomeTooltip>
                          <AllRoutes />
                        </AddToHomeTooltip>
                      </NewNotificationsProvider>
                    </VersionCheckProvider>
                  </SnackbarProvider>
                </GoogleAnalyticsLoader>
              </InitUser>
            </HttpContextProvider>
          </MessageSourceLoader>
        </UserContextProvider>
      </ThemeProvider>
    </EnvironmentContextProvider>
  )
}
