import { ChartData as ChartJsData, ChartDataset as ChartJsDataset } from 'chart.js'
import { ChartData as ChartDataApi, ChartDataset as ChartDatasetApi, ChartType } from 'src/service/backend/api'
import { CHART_COLOR_PALETTE } from 'src/service/view-model/base/chart/Chart.const'

export const transformChartDataToChartJsData = (chartData: ChartDataApi, chartColors: string[]): ChartJsData => {
  const labels = chartData.xValues

  const transformedChartData: ChartJsData = {
    labels,
    datasets: transformChartDatasetsToChartDatasetsJS(chartData.datasets, chartColors, chartData.chartType),
  }
  return transformedChartData
}

export const transformChartDatasetsToChartDatasetsJS = (
  chartDataSets: Array<ChartDatasetApi>,
  chartColors: string[],
  chartType?: ChartType,
): Array<ChartJsDataset> => {
  const transformedChartDataSets = chartDataSets.map((item, index) => {
    const chartJsDataSet = mapChartDatasetToChartJsDataset(item, index, chartColors, chartType)
    return chartJsDataSet
  })

  return transformedChartDataSets
}

export const mapChartDatasetToChartJsDataset = (
  chartDataSet: ChartDatasetApi,
  datasetIndex: number,
  chartColors: string[],
  chartType?: ChartType,
): ChartJsDataset => {
  const randomColor = generateDatasetColor(datasetIndex, chartColors)
  const colorsArray: string[] = [randomColor]

  // for doughnut charts, we need  multiple colors, one for each of the datasets
  if (chartType && chartType === ChartType.DOUGHNUT) {
    for (let i = 1; i < 30; i++) {
      const randomColor2 = generateDatasetColor(i, chartColors)
      colorsArray.push(randomColor2)
    }
  }

  const chartJsDataSet: ChartJsDataset = {
    data: chartDataSet.yValues as any,
    backgroundColor: colorsArray,
    borderColor: colorsArray.length === 1 ? randomColor : undefined,
    label: chartDataSet.label,
  }

  return chartJsDataSet
}

// Extracts the title for the Y axis, if there are more YUnits, it returns undefined
export const extractYUnit = (chartData: ChartDataApi): string | undefined => {
  const chartYUnitArray = chartData.datasets.map((item) => item.yUnit)
  const yUnitsSet = new Set(chartYUnitArray)

  if (yUnitsSet.size === 1) {
    const iterator = yUnitsSet.values()
    const yUnit = iterator.next().value
    return yUnit
  }

  return undefined
}

export const generateDatasetColor = (elementIndex: number, chartColors: string[]): string => {
  if (elementIndex >= 0) {
    if (elementIndex < chartColors.length) {
      return chartColors[elementIndex]
    }

    const adjustedElementIndex = elementIndex - chartColors.length

    if (adjustedElementIndex < CHART_COLOR_PALETTE.length) {
      return CHART_COLOR_PALETTE[adjustedElementIndex]
    }
  }

  // random color is used for negative index, or index above chartColors.len + CHART_COLOR_PALETTE.len
  return generateRandomColor()
}

export const generateRandomColor = (): string => {
  const number = Math.floor(Math.random() * 10)
  const hue = number * 137.508 // use golden angle approximation
  return `hsl(${hue},50%,75%)`
}

export const groupDatasetsForDoughnutChart = (chartData: ChartDataApi, filterZeros?: boolean): ChartDataApi => {
  // make sure that the chart is DOUGHNUT
  if (chartData.chartType !== ChartType.DOUGHNUT) {
    console.warn('Unable to group chart')
    return chartData
  }

  const combined: ChartDatasetApi = {
    label: '',
    yUnit: '',
    yValues: [],
    color: undefined,
  }

  const labels: string[] = []
  const yUnits: string[] = []
  let colors = ''

  if (chartData.datasets.length > 0) {
    chartData.datasets.forEach((dataset) => {
      let sum = 0
      if (dataset.yValues.length > 0) {
        dataset.yValues.forEach((n) => (sum += n))
      }

      if (!filterZeros || sum !== 0) {
        const sumIsInteger = Number.isInteger(sum)
        const roundedSum = sumIsInteger ? sum : Number(sum.toFixed(2))
        combined.yValues.push(roundedSum)

        labels.push(dataset.label)

        if (dataset.yUnit) {
          yUnits.push(dataset.yUnit)
        }

        // only one string of color is returned for transformed DOUGHNUT chart data, concat all colors from datasets in one string
        if (dataset.color) {
          colors += dataset.color + ';'
        }
      }
    })
  }

  if (yUnits.length > 0) {
    combined.yUnit = yUnits[0]
  }

  combined.color = colors

  const labelsWithValues = labels.map((label, index) => {
    const value = combined.yValues.length > 0 ? combined.yValues[index] : undefined
    return label + ': ' + (value || '0')
  })

  const chartDataNew: ChartDataApi = {
    title: chartData.title,
    xValues: labelsWithValues,
    chartType: chartData.chartType,
    datasets: [combined],
  }

  return chartDataNew
}

const EMPTY_CHART_DATA: ChartDataApi = {
  datasets: [
    {
      yValues: [],
      label: '',
    },
  ],
  xValues: [],
}

export const EMPTY_CHART_DATA_PROMISE = new Promise<ChartDataApi>((resolve) => {
  resolve(EMPTY_CHART_DATA)
  return EMPTY_CHART_DATA
})

export const NOT_READY_CHART_DATA_PROMISE = new Promise<ChartDataApi>(() => {})
