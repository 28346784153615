import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, MembersApi, Role, RolesApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getLocaleName } from 'src/service/view-model/base/localization/Locales'
import {
  MemberViewModel,
  createMemberViewModel,
  mapMemberRolesUpdate,
  mapMemberToMemberViewModel,
} from 'src/service/view-model/member/MemberViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { Option } from 'src/ui-shared/base/form/control/Index'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

interface Props {
  isServicer?: boolean
}

export const MemberEditPage: FC<Props> = ({ isServicer }): ReactElement => {
  const navigate = useNavigate()
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()
  const { memberId } = useRequiredParams(['memberId'])
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const membersApi = new MembersApi(httpConfiguration)
  const rolesApi = new RolesApi(httpConfiguration)

  const [dirty, setDirty] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [memberViewModel, setMemberViewModel] = useState<MemberViewModel>(createMemberViewModel())
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [currentRole, setCurrentRole] = useState<Option | null>(null)
  const [roles, setRoles] = useState<Role[]>([])

  const screenRoute = isServicer ? 'servicers' : 'members'
  const memberFullName = memberViewModel.firstName + ' ' + memberViewModel.lastName

  useHotKeysForm()

  const getRoleOptions = (roles: Role[]) => {
    return roles.map((role) => getRoleOption(role))
  }

  const getRoleOption = (role: Role): Option => {
    const option: Option = {
      label: role.name || '',
      value: role.roleKey,
    }
    return option
  }

  // api calls
  const fetchRoles = async (organizationId: string) => {
    return rolesApi.rolesAvailableGet(organizationId)
  }

  const fetchMember = async () => {
    return membersApi.membersMemberIdGet(memberId)
  }

  const fetchData = async () => {
    try {
      const member = await fetchMember()
      const roles = await fetchRoles(member.organization.id)

      const viewModel = mapMemberToMemberViewModel(member)
      setMemberViewModel(viewModel)
      if (viewModel.roles.length > 0 && viewModel.roles[0]) {
        setCurrentRole(getRoleOption(member.memberRoles[0]))
      }
      setRoles(roles)
      setLoading(false)
    } catch (err) {
      const errorMessage = errorMapper(err, translate)
      console.error(errorMessage, err)
      setErrorMessage(errorMessage)
    }
  }

  // load data
  useEffect(() => {
    fetchData()
  }, [memberId])

  // handle events
  const navigateBack = () => {
    navigate(`/${appId}/${screenRoute}/${memberId}/view`)
  }

  const onSubmitSuccess = () => {
    setLoading(false)
    navigateBack()
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  const submitForm = () => {
    setLoading(true)
    setDirty(false)
    const newRoles: string[] = currentRole ? [currentRole.value.toString()] : []

    const memberRolesUpdate = mapMemberRolesUpdate(newRoles)

    membersApi
      .membersMemberIdPut(memberId, memberRolesUpdate)
      .then(() => {
        onSubmitSuccess()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  const handleRolesChange = (event: React.SyntheticEvent, value: Option | null): void => {
    setCurrentRole(value)
    setDirty(true)
  }

  return (
    <ScreenLayout title={memberFullName} onBack={navigateBack} actionsWidth={50}>
      <Paper elevation={0}>
        <LoadingIndicator loading={loading} />
        <Divider />

        <Box pt={2}>
          {errorMessage ? (
            <ErrorMessage message={errorMessage} />
          ) : (
            <Form<MemberViewModel>
              initialValues={memberViewModel}
              onSubmit={submitForm}
              render={({ handleSubmit, submitting, pristine }) => {
                return (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <DetailsTextField value={memberViewModel.firstName} label={translate('user.firstName')} />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <DetailsTextField value={memberViewModel.lastName} label={translate('user.lastName')} />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <DetailsTextField value={memberViewModel.email} label={translate('user.email')} />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <DetailsTextField value={getLocaleName(memberViewModel.locale)} label={translate('language')} />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                        <Divider className={sharedClasses.Divider} />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <DetailsTextField value={memberViewModel.organization} label={translate('organization')} />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <Autocomplete
                          datatype="string"
                          openOnFocus={true}
                          multiple={false}
                          onChange={handleRolesChange}
                          value={currentRole}
                          getOptionLabel={(option) => option.label}
                          options={getRoleOptions(roles)}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option.label}
                                {...getTagProps({ index })}
                                key={option.value}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextFieldDefault
                              {...params}
                              name="roles"
                              label={translate('roles')}
                              placeholder={translate('user.role')}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                        <Divider className={sharedClasses.Divider} />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <FormControl disabled component="fieldset">
                          <FormLabel component="legend">{translate('status')}</FormLabel>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox color="primary" checked={memberViewModel.blocked} disabled name="enabled" />
                              }
                              label={translate('blocked')}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider className={sharedClasses.Divider} />
                    <Box mt={3} display="flex" justifyContent="flex-end">
                      <Button
                        id="cancelButton"
                        variant="text"
                        color="primary"
                        size="large"
                        className={sharedClasses.ButtonMargin}
                        onClick={navigateBack}
                      >
                        {translate('button.cancel')}
                      </Button>
                      <Button
                        id="submitButton"
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={submitting || (pristine && !dirty)}
                      >
                        {translate('button.save')}
                      </Button>
                    </Box>
                  </form>
                )
              }}
            />
          )}
        </Box>
      </Paper>
    </ScreenLayout>
  )
}
