import { DashboardLaundryStateOverviewApi } from 'src/service/backend/api'
import { mocked } from 'src/service/backend/api-mock/MockUtils'
import { DashboardLaundryStateOverviews } from 'src/service/backend/api/models'
import { InitOverrideFunction } from 'src/service/backend/api/runtime'

const laundryStateJson =
  '{"laundryCharts":[{"laundry":{"id":"3ea93cce-30f6-49c6-b666-1dfd704ad644","name":"Demo Laundry Room","laundryGroup":null},"chart":{"title":"Current machine states","xValues":[],"chartType":"DOUGHNUT","datasets":[{"label":"Crinkle Care","yUnit":"Count","lineType":null,"color":"#9b91c1","yValues":[0]},{"label":"Error","yUnit":"Count","lineType":null,"color":"#ce0f69","yValues":[4]},{"label":"Idle","yUnit":"Count","lineType":null,"color":"#7a7c7f","yValues":[0]},{"label":"Offline","yUnit":"Count","lineType":null,"color":"#c7c9cc","yValues":[2]},{"label":"Program Paused","yUnit":"Count","lineType":null,"color":"#444241","yValues":[0]},{"label":"Running","yUnit":"Count","lineType":null,"color":"#745ba3","yValues":[0]}]}}]}'

export class DashboardLaundryStateOverviewApiMock extends DashboardLaundryStateOverviewApi {
  dashboardSmLaundryStateOverviewGet(
    laundryGroupId: string,
    initOverrides?: RequestInit | InitOverrideFunction,
  ): Promise<DashboardLaundryStateOverviews> {
    return mocked(JSON.parse(laundryStateJson) as DashboardLaundryStateOverviews)
  }
}
