import { makeStyles } from 'tss-react/mui'

export const useSharedStyles = makeStyles()((theme) => {
  return {
    GridWithTextField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    Divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    ButtonMargin: {
      marginRight: theme.spacing(2),
    },
    MenuItemPadding: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    Header: {
      paddingLeft: theme.spacing(2),
    },
    TabsContainer: {
      borderSizing: 'border-box',
      // height of tabs container must be the same as StyledTab minHeight
      height: 55,
    },
    SectionTitle: {
      fontWeight: 400,
      fontSize: '16px',
      color: theme.palette.texts.sectionTitle,
    },
  }
})
