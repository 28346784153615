import { TranslateFunction } from 'src/i18n/useMessageSource'
import { DatatransPaymentMethod } from 'src/service/backend/api'
import { WithName } from 'src/service/view-model/base/IdAndName'
import { findReferenceObject, getReferenceName } from 'src/service/view-model/base/Reference.utils'

export interface DatatransPaymentMethodObject extends WithName {
  id: DatatransPaymentMethod
}

export const DATATRANS_PAYMENT_METHOD_OBJECTS: DatatransPaymentMethodObject[] = [
  {
    id: DatatransPaymentMethod.VIS,
    name: 'Visa',
  },
  {
    id: DatatransPaymentMethod.ECA,
    name: 'MasterCard',
  },
  {
    id: DatatransPaymentMethod.MAU,
    name: 'Maestro',
  },
  {
    id: DatatransPaymentMethod.PFC,
    name: 'PostFinance',
  },
  {
    id: DatatransPaymentMethod.TWI,
    name: 'Twint',
  },
  {
    id: DatatransPaymentMethod.APL,
    name: 'Apple Pay',
  },
  {
    id: DatatransPaymentMethod.PAY,
    name: 'Google Pay',
  },
  {
    id: DatatransPaymentMethod.AZP,
    name: 'Amazon Pay',
  },
  {
    id: DatatransPaymentMethod.AMX,
    name: 'American Express',
  },
  {
    id: DatatransPaymentMethod.DIN,
    name: 'Diners',
  },
  {
    id: DatatransPaymentMethod.DIS,
    name: 'Discover',
  },
  {
    id: DatatransPaymentMethod.JCB,
    name: 'JCB',
  },
  {
    id: DatatransPaymentMethod.DNK,
    name: 'Dankort',
  },
]

export const getDatatransPaymentMethodObject = (
  datatransPaymentMethod: DatatransPaymentMethod | undefined,
): DatatransPaymentMethodObject | undefined => {
  return findReferenceObject(DATATRANS_PAYMENT_METHOD_OBJECTS, datatransPaymentMethod)
}

export const getDatatransPaymentMethodName = (
  datatransPaymentMethod: DatatransPaymentMethod | undefined,
  _translate?: TranslateFunction,
): string => {
  return getReferenceName(DATATRANS_PAYMENT_METHOD_OBJECTS, undefined, datatransPaymentMethod)
}
