/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { DatatransPaymentMethod } from './DatatransPaymentMethod';
import {
    DatatransPaymentMethodFromJSON,
    DatatransPaymentMethodFromJSONTyped,
    DatatransPaymentMethodToJSON,
} from './DatatransPaymentMethod';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';
import type { LaundryReference } from './LaundryReference';
import {
    LaundryReferenceFromJSON,
    LaundryReferenceFromJSONTyped,
    LaundryReferenceToJSON,
} from './LaundryReference';
import type { LaundryUserReference } from './LaundryUserReference';
import {
    LaundryUserReferenceFromJSON,
    LaundryUserReferenceFromJSONTyped,
    LaundryUserReferenceToJSON,
} from './LaundryUserReference';
import type { MachineReference } from './MachineReference';
import {
    MachineReferenceFromJSON,
    MachineReferenceFromJSONTyped,
    MachineReferenceToJSON,
} from './MachineReference';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface WMUsage
 */
export interface WMUsage {
    /**
     * 
     * @type {OrganizationReference}
     * @memberof WMUsage
     */
    organization: OrganizationReference;
    /**
     * 
     * @type {LaundryGroupReference}
     * @memberof WMUsage
     */
    laundryGroup: LaundryGroupReference;
    /**
     * 
     * @type {LaundryReference}
     * @memberof WMUsage
     */
    laundry: LaundryReference;
    /**
     * 
     * @type {MachineReference}
     * @memberof WMUsage
     */
    machine: MachineReference;
    /**
     * 
     * @type {LaundryUserReference}
     * @memberof WMUsage
     */
    laundryUser: LaundryUserReference;
    /**
     * 
     * @type {Date}
     * @memberof WMUsage
     */
    startTime: Date;
    /**
     * 
     * @type {string}
     * @memberof WMUsage
     */
    transactionId: string;
    /**
     * 
     * @type {number}
     * @memberof WMUsage
     */
    amount: number;
    /**
     * 
     * @type {Currency}
     * @memberof WMUsage
     */
    currency: Currency;
    /**
     * 
     * @type {boolean}
     * @memberof WMUsage
     */
    refundable?: boolean;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof WMUsage
     */
    paymentMethod: PaymentMethod;
    /**
     * 
     * @type {DatatransPaymentMethod}
     * @memberof WMUsage
     */
    datatransPaymentMethod: DatatransPaymentMethod;
}

/**
 * Check if a given object implements the WMUsage interface.
 */
export function instanceOfWMUsage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "laundryGroup" in value;
    isInstance = isInstance && "laundry" in value;
    isInstance = isInstance && "machine" in value;
    isInstance = isInstance && "laundryUser" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "transactionId" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "datatransPaymentMethod" in value;

    return isInstance;
}

export function WMUsageFromJSON(json: any): WMUsage {
    return WMUsageFromJSONTyped(json, false);
}

export function WMUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): WMUsage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization': OrganizationReferenceFromJSON(json['organization']),
        'laundryGroup': LaundryGroupReferenceFromJSON(json['laundryGroup']),
        'laundry': LaundryReferenceFromJSON(json['laundry']),
        'machine': MachineReferenceFromJSON(json['machine']),
        'laundryUser': LaundryUserReferenceFromJSON(json['laundryUser']),
        'startTime': (new Date(json['startTime'])),
        'transactionId': json['transactionId'],
        'amount': json['amount'],
        'currency': CurrencyFromJSON(json['currency']),
        'refundable': !exists(json, 'refundable') ? undefined : json['refundable'],
        'paymentMethod': PaymentMethodFromJSON(json['paymentMethod']),
        'datatransPaymentMethod': DatatransPaymentMethodFromJSON(json['datatransPaymentMethod']),
    };
}

export function WMUsageToJSON(value?: WMUsage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization': OrganizationReferenceToJSON(value.organization),
        'laundryGroup': LaundryGroupReferenceToJSON(value.laundryGroup),
        'laundry': LaundryReferenceToJSON(value.laundry),
        'machine': MachineReferenceToJSON(value.machine),
        'laundryUser': LaundryUserReferenceToJSON(value.laundryUser),
        'startTime': (value.startTime.toISOString()),
        'transactionId': value.transactionId,
        'amount': value.amount,
        'currency': CurrencyToJSON(value.currency),
        'refundable': value.refundable,
        'paymentMethod': PaymentMethodToJSON(value.paymentMethod),
        'datatransPaymentMethod': DatatransPaymentMethodToJSON(value.datatransPaymentMethod),
    };
}

