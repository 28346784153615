import deLocale from 'date-fns/locale/de'
import frLocale from 'date-fns/locale/fr'
import itLocale from 'date-fns/locale/it'

export const getDateFnsLocale = (localeCode: string): Locale | undefined => {
  if (localeCode === 'de') {
    return deLocale
  } else if (localeCode === 'fr') {
    return frLocale
  } else if (localeCode === 'it') {
    return itLocale
  }
  return undefined
}
