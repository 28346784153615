/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface RefundPrepaidByBankTransferTransaction
 */
export interface RefundPrepaidByBankTransferTransaction {
    /**
     * 
     * @type {string}
     * @memberof RefundPrepaidByBankTransferTransaction
     */
    iban?: string;
    /**
     * 
     * @type {string}
     * @memberof RefundPrepaidByBankTransferTransaction
     */
    holderName?: string;
    /**
     * 
     * @type {Address}
     * @memberof RefundPrepaidByBankTransferTransaction
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof RefundPrepaidByBankTransferTransaction
     */
    prepaidAccountId?: string;
}

/**
 * Check if a given object implements the RefundPrepaidByBankTransferTransaction interface.
 */
export function instanceOfRefundPrepaidByBankTransferTransaction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RefundPrepaidByBankTransferTransactionFromJSON(json: any): RefundPrepaidByBankTransferTransaction {
    return RefundPrepaidByBankTransferTransactionFromJSONTyped(json, false);
}

export function RefundPrepaidByBankTransferTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefundPrepaidByBankTransferTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'holderName': !exists(json, 'holderName') ? undefined : json['holderName'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'prepaidAccountId': !exists(json, 'prepaidAccountId') ? undefined : json['prepaidAccountId'],
    };
}

export function RefundPrepaidByBankTransferTransactionToJSON(value?: RefundPrepaidByBankTransferTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iban': value.iban,
        'holderName': value.holderName,
        'address': AddressToJSON(value.address),
        'prepaidAccountId': value.prepaidAccountId,
    };
}

