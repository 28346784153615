import React, { ReactElement, useContext, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  Configuration,
  LaundryGroupStatisticsData,
  LaundryGroupStatisticsFactType,
  PageableLaundryGroupStatisticsData,
  StatisticsWashMasterApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { DatePeriodType, formatDatePeriodForLocaleAndType } from 'src/service/utils/DatePeriodFormatUtils'
import { DateUtils } from 'src/service/utils/DateUtils'
import { formatAmountForLocale, formatPercentage } from 'src/service/utils/NumberFormatUtils'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DashboardLoadingIndicator } from 'src/ui/page/sm/index/DashboardLoadingIndicator'
import {
  DashboardPeriodType,
  getFromToForPeriodTypeFromLastFinishedMonth,
} from 'src/ui/page/wm/index/DashboardPeriodTypeUtils'
import { useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  useMockData: boolean
  dashboardPeriodType: DashboardPeriodType
  limitToActiveOrganization: boolean
}

const SORT_BY_REVENUE = 'data.revenueAbsolute'
const SORT_BY_GROWTH_PERCENTAGE = 'data.revenuePercentageChange'
const SORT_DIR_ASC = 'asc'
const SORT_DIR_DESC = 'desc'

export const DashboardTopLaundryGroupsWidget = ({
  useMockData,
  dashboardPeriodType,
  limitToActiveOrganization,
}: Props): ReactElement => {
  const translate = useTranslate()

  const activeOrganization = useActiveOrganization()
  const regionLocale = useUserRegionLocale()

  const dates = getFromToForPeriodTypeFromLastFinishedMonth(dashboardPeriodType)
  const fromDate = dates[0]
  const toDate = dates[1]

  // api
  const httpConfiguration: Configuration = useContext(HttpContext)
  const statisticsWashMasterApi = new StatisticsWashMasterApi(httpConfiguration)

  // state
  const [data, setData] = useState<PageableLaundryGroupStatisticsData | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [sort, setSort] = useState<string>(SORT_BY_REVENUE)
  const [sortDirection, setSortDirection] = useState<string>(SORT_DIR_DESC)

  let datePeriodType: DatePeriodType = DatePeriodType.DAYS

  const daysToSubtractForReferencePeriod = 0
  let monthsToSubtractForReferencePeriod = 0
  let yearsToSubtractForReferencePeriod = 0

  let subtractYearFromToDate = false
  if (dashboardPeriodType === DashboardPeriodType.MONTH) {
    datePeriodType = DatePeriodType.MONTHS
    monthsToSubtractForReferencePeriod = 1
  } else if (dashboardPeriodType === DashboardPeriodType.HALF_YEAR) {
    datePeriodType = DatePeriodType.MONTHS
    // monthsToSubtractForReferencePeriod = 6
    yearsToSubtractForReferencePeriod = 1
    subtractYearFromToDate = true
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (dashboardPeriodType === DashboardPeriodType.YEAR) {
    datePeriodType = DatePeriodType.YEARS
    yearsToSubtractForReferencePeriod = 1
    subtractYearFromToDate = true
  }

  // derived state
  // calculate reference period for the from / to dates so that backend can determine
  // growth or decline percentage compared to reference period
  const durationToSubtractFromDate: Duration = {
    days: daysToSubtractForReferencePeriod,
    months: monthsToSubtractForReferencePeriod,
    years: yearsToSubtractForReferencePeriod,
  }
  const referenceFrom: Date | undefined = DateUtils.subtract(fromDate, durationToSubtractFromDate)
  const referenceTo: Date | undefined = subtractYearFromToDate
    ? DateUtils.subtract(toDate, { years: 1, seconds: 1 })
    : DateUtils.subtract(fromDate, { seconds: 1 })

  // load data function
  const loadDashboardLaundryStatistics = () => {
    setData(null)
    setErrorMessage(null)

    // we are listing the top / or bottom 5 laundry groups, limit results
    const resultSize = 5

    // sort by revenue absolute number or percentage
    const factTypes: LaundryGroupStatisticsFactType[] = [
      LaundryGroupStatisticsFactType.ABSOLUTE,
      LaundryGroupStatisticsFactType.PERCENTAGE_CHANGE,
    ]
    const sortBy = sort + ',' + sortDirection

    // filter by active organization if needed
    const organizationId = limitToActiveOrganization ? activeOrganization?.id : undefined

    statisticsWashMasterApi
      .reportWmStatisticsLaundrygroupsGet(
        fromDate,
        toDate,
        factTypes,
        resultSize,
        undefined,
        sortBy,
        undefined,
        undefined,
        organizationId,
        referenceFrom,
        referenceTo,
      )
      .then((data) => {
        setData(data)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setData(null)
        setErrorMessage(errorMessage)
      })
  }

  const handleSortChange = (_: React.MouseEvent<HTMLElement>, newSort: string | null) => {
    if (newSort !== null) {
      setSort(newSort)
    }
  }

  const handleSortDirectionChange = (_: React.MouseEvent<HTMLElement>, newSortDir: string | null) => {
    if (newSortDir !== null) {
      setSortDirection(newSortDir)
    }
  }

  // load data on mount
  useEffect(() => {
    loadDashboardLaundryStatistics()
  }, [useMockData, dashboardPeriodType, sort, sortDirection, activeOrganization])

  // JSX

  const nonEmptyRows = (items: LaundryGroupStatisticsData[]) =>
    items.map((item) => {
      return (
        <TableRow key={item.laundryGroup.id}>
          <TableCell>
            <TruncatedTooltip maxChars={35} value={item.laundryGroup.name} />
          </TableCell>
          <TableCell>{formatAmountForLocale(item.data.revenueAbsolute, regionLocale, item.data.currency)}</TableCell>
          <TableCell>{formatPercentage(item.data.revenuePercentageChange, false)}</TableCell>
        </TableRow>
      )
    })

  return (
    <>
      <Typography variant="h4" mb={0}>
        {translate('parameterPerformance', translate('laundryGroup'))}
      </Typography>

      {errorMessage ? (
        <ErrorMessage message={errorMessage} />
      ) : data ? (
        <Grid container mb={2} spacing={1}>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="50%" sx={{ paddingBottom: '5px' }}>
                      <ToggleButtonGroup
                        value={sort}
                        onChange={handleSortChange}
                        exclusive
                        size="small"
                        sx={{ mr: 2, mt: 1 }}
                      >
                        <ToggleButton value={SORT_BY_REVENUE}>{translate('revenue')}</ToggleButton>
                        <ToggleButton value={SORT_BY_GROWTH_PERCENTAGE}>{translate('growth')}</ToggleButton>
                      </ToggleButtonGroup>
                      &nbsp;
                      <ToggleButtonGroup
                        value={sortDirection}
                        onChange={handleSortDirectionChange}
                        exclusive
                        size="small"
                        sx={{ mt: 1 }}
                      >
                        <ToggleButton value={SORT_DIR_DESC}>{translate('best')}</ToggleButton>
                        <ToggleButton value={SORT_DIR_ASC}>{translate('worst')}</ToggleButton>
                      </ToggleButtonGroup>
                    </TableCell>
                    <TableCell width="25%" sx={{ paddingBottom: '5px' }}>
                      <TableSortLabel
                        active={sort === SORT_BY_REVENUE}
                        direction={sortDirection === SORT_DIR_DESC ? SORT_DIR_DESC : 'asc'}
                        onClick={() => {
                          if (sort === SORT_BY_REVENUE) {
                            setSortDirection(sortDirection === SORT_DIR_DESC ? SORT_DIR_ASC : SORT_DIR_DESC)
                          }
                          setSort(SORT_BY_REVENUE)
                        }}
                      >
                        <Box>
                          {translate('revenue')}
                          <br />
                          {formatDatePeriodForLocaleAndType(fromDate, toDate, regionLocale, datePeriodType)}
                        </Box>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell width="25%" sx={{ paddingBottom: '5px' }}>
                      <TableSortLabel
                        active={sort === SORT_BY_GROWTH_PERCENTAGE}
                        direction={sortDirection === SORT_DIR_DESC ? SORT_DIR_DESC : 'asc'}
                        onClick={() => {
                          if (sort === SORT_BY_GROWTH_PERCENTAGE) {
                            setSortDirection(sortDirection === SORT_DIR_DESC ? SORT_DIR_ASC : SORT_DIR_DESC)
                          }
                          setSort(SORT_BY_GROWTH_PERCENTAGE)
                        }}
                      >
                        <Tooltip
                          title={
                            <Typography variant="body2">
                              {translate(
                                'comparedTo',
                                formatDatePeriodForLocaleAndType(
                                  referenceFrom,
                                  referenceTo,
                                  regionLocale,
                                  datePeriodType,
                                ),
                              )}
                              {/*  <br />
                              {combineDateRange(
                                formatDateForLocale(referenceFrom, regionLocale),
                                formatDateForLocale(referenceTo, regionLocale),
                              )} */}
                            </Typography>
                          }
                          placement="bottom"
                        >
                          <Box>
                            {translate('revenueIncreasePercent')} <br />
                            {}
                          </Box>
                        </Tooltip>
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{nonEmptyRows(data.result)}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : (
        <DashboardLoadingIndicator />
      )}
    </>
  )
}
