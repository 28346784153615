/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaundryGroupInvoice } from './LaundryGroupInvoice';
import {
    LaundryGroupInvoiceFromJSON,
    LaundryGroupInvoiceFromJSONTyped,
    LaundryGroupInvoiceToJSON,
} from './LaundryGroupInvoice';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * 
 * @export
 * @interface PageableLaundryGroupInvoices
 */
export interface PageableLaundryGroupInvoices {
    /**
     * 
     * @type {Array<LaundryGroupInvoice>}
     * @memberof PageableLaundryGroupInvoices
     */
    result: Array<LaundryGroupInvoice>;
    /**
     * 
     * @type {Page}
     * @memberof PageableLaundryGroupInvoices
     */
    page: Page;
}

/**
 * Check if a given object implements the PageableLaundryGroupInvoices interface.
 */
export function instanceOfPageableLaundryGroupInvoices(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageableLaundryGroupInvoicesFromJSON(json: any): PageableLaundryGroupInvoices {
    return PageableLaundryGroupInvoicesFromJSONTyped(json, false);
}

export function PageableLaundryGroupInvoicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableLaundryGroupInvoices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(LaundryGroupInvoiceFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageableLaundryGroupInvoicesToJSON(value?: PageableLaundryGroupInvoices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(LaundryGroupInvoiceToJSON)),
        'page': PageToJSON(value.page),
    };
}

