import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { AppId } from 'src/app/AppId'

interface AppContextValue {
  appId: AppId
}

const defaultAppContextValue: AppContextValue = {
  appId: AppId.WASH_MASTER,
}

export const AppContext = createContext<AppContextValue>(defaultAppContextValue)

export const useAppId = (): AppId => {
  const { appId } = useContext(AppContext)
  return appId
}

const getAppIdFromPath = (pathname: string): AppId | undefined => {
  const extractedAppId = pathname.substring(1, 3)

  let appId
  if (extractedAppId === AppId.WASH_MASTER) {
    appId = AppId.WASH_MASTER
  } else if (extractedAppId === AppId.SERVICE_MASTER) {
    appId = AppId.SERVICE_MASTER
  } else if (extractedAppId === AppId.EASY_SET) {
    appId = AppId.EASY_SET
  } else {
    if (extractedAppId != '') {
      console.warn(`The appId string from path '${extractedAppId}' is not recognized`)
    }
  }

  return appId
}

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()

  const [currentApp, setCurrentApp] = useState<AppId>(AppId.WASH_MASTER)

  useEffect(() => {
    const appId = getAppIdFromPath(location.pathname)
    if (appId && appId !== currentApp) {
      setCurrentApp(appId)
    }
  }, [location.pathname])

  const appContextValue = useMemo(() => {
    return { appId: currentApp }
  }, [currentApp])

  return <AppContext.Provider value={appContextValue}>{children}</AppContext.Provider>
}
