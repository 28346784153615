import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router'
import { AppId } from 'src/app/AppId'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { ServicePackagePlanLaundryGroupTable } from 'src/ui/layout-page/service-package-plan/list/ServicePackagePlanLaundryGroupTable'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

export const ServicePackagePlanLaundryGroupListPage = (): ReactElement => {
  const navigate = useNavigate()
  const translate = useTranslate()

  const handleRowClick = (laundryGroupId?: string) => {
    navigate(`/${AppId.WASH_MASTER}/service-package-plan/${laundryGroupId}/view`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  return (
    <ScreenLayout title={translate('laundryGroupServicePackagePlan')}>
      <ServicePackagePlanLaundryGroupTable navigateToItem={handleRowClick} />
    </ScreenLayout>
  )
}
