import React, { ReactElement } from 'react'
import { InputLabelProps, TextFieldProps } from '@mui/material'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

export const TableFilterTextFieldDefault = (props: TextFieldProps): ReactElement => {
  const { classes: textFieldClasses } = useTextFieldStyles()

  return (
    <TextFieldDefault
      {...props}
      variant="outlined"
      className={textFieldClasses.TextFieldSmall}
      InputLabelProps={{ defaultShrinkBehaviour: true } as InputLabelProps}
    />
  )
}
