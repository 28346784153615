/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RefundPaymentToPrepaidTransaction
 */
export interface RefundPaymentToPrepaidTransaction {
    /**
     * 
     * @type {string}
     * @memberof RefundPaymentToPrepaidTransaction
     */
    parentTransactionId?: string;
}

/**
 * Check if a given object implements the RefundPaymentToPrepaidTransaction interface.
 */
export function instanceOfRefundPaymentToPrepaidTransaction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RefundPaymentToPrepaidTransactionFromJSON(json: any): RefundPaymentToPrepaidTransaction {
    return RefundPaymentToPrepaidTransactionFromJSONTyped(json, false);
}

export function RefundPaymentToPrepaidTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefundPaymentToPrepaidTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentTransactionId': !exists(json, 'parentTransactionId') ? undefined : json['parentTransactionId'],
    };
}

export function RefundPaymentToPrepaidTransactionToJSON(value?: RefundPaymentToPrepaidTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parentTransactionId': value.parentTransactionId,
    };
}

