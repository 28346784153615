import React, { ReactElement, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Box, Button, DialogActions, DialogContent } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NumberIdAndName } from 'src/service/view-model/base/IdAndName'
import { getDrumSizeLabel } from 'src/service/view-model/machine/Machines'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { AutocompleteHookForm } from 'src/ui-shared/base/form/control-hook-form/AutocompleteHookForm'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

interface Props<T> {
  title: string
  open: boolean
  drumSizes: number[]
  drumSizeUnit?: string
  errorMessage?: string
  onConfirm: (drumSize: number) => Promise<T>
  handleClose: () => void
}

export const DrumSizeModalDialog = <T,>({
  title,
  open,
  drumSizes,
  drumSizeUnit,
  errorMessage,
  onConfirm,
  handleClose,
}: Props<T>): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  const { handleSubmit, control } = useForm<{ drumSize: number }>()

  const [loading, setLoading] = useState<boolean>(false)

  const drumSizeItems: NumberIdAndName[] = drumSizes.map((size) => {
    const option: NumberIdAndName = {
      id: size,
      name: getDrumSizeLabel(size, drumSizeUnit),
    }
    return option
  })

  const onSubmit: SubmitHandler<{ drumSize: number }> = (values) => {
    setLoading(true)

    onConfirm(values.drumSize)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <ModalDialog open={open} onClose={handleClose} title={translate(title)} maxWidth="xs">
      {errorMessage && (
        <Box paddingX={3}>
          <ErrorMessage message={errorMessage} />
        </Box>
      )}
      <LoadingIndicator loading={loading || (drumSizes.length === 0 && errorMessage === undefined)} />

      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'enter') {
            e.preventDefault()
          }
        }}
      >
        <DialogContent>
          <AutocompleteHookForm
            control={control}
            label={translate('selectDrumSize')}
            name="drumSize"
            disabled={loading || drumSizes.length === 0 || Boolean(errorMessage)}
            required={{ value: true, message: 'validation.required' }}
            transform={{ labelFieldName: 'name', valueFieldName: 'id', translateLabel: false }}
            options={drumSizeItems}
          />
        </DialogContent>
        <DialogActions>
          <Box mt={2} p={2} display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              color="primary"
              size="large"
              className={sharedClasses.ButtonMargin}
              onClick={() => handleClose()}
            >
              {translate('button.cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={loading || drumSizes.length === 0 || Boolean(errorMessage)}
            >
              {translate('export')}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </ModalDialog>
  )
}
