import React, { ReactElement, SetStateAction, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Grid, Paper, TableCell, TableRow } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  Configuration,
  FHMonitoringsApi,
  LaundriesApi,
  LaundryGroupsApi,
  MachinesApi,
  OrganizationsApi,
} from 'src/service/backend/api'
import {
  FhMonitoringEntry,
  LaundryGroupReference,
  LaundryReference,
  MachineReference,
  OrganizationReference,
} from 'src/service/backend/api/models'
import { MachineCategory } from 'src/service/backend/api/models/MachineCategory'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocale } from 'src/service/utils/DateFormatUtils'
import { Data, OrderBy } from 'src/service/view-model/base/Data'
import { getErrorSeverityName } from 'src/service/view-model/error-log/ErrorLogViewModel'
import {
  MACHINE_CATEGORIES,
  getMachineCategoryName,
  getMachineNameAndSerialNumber,
  getMachineOptionLabel,
} from 'src/service/view-model/machine/Machines'
import { EMPTY_ORGANIZATION_REF } from 'src/service/view-model/organization/Organizations'
import { getWashingStepName } from 'src/service/view-model/washing-step/WashingStep'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { ErrorSeverityIcon } from 'src/ui-shared/error-severity/ErrorSeverityIcon'
import { IconWithTooltip } from 'src/ui-shared/icons/Icons'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettingsWithOrganization,
  tableSettingsSort,
} from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { TableFilterAutocompleteOrganization } from 'src/ui-shared/table/TableFilterAutocompleteOrganization'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { useUserRegionLocale } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'errorTime',
    label: 'date',
  },
  {
    id: 'errorCode',
    label: 'error.code',
  },
  {
    id: 'errorSummary',
    label: 'error.summary',
    noSort: true,
  },
  {
    id: 'errorStep',
    label: 'step',
    noSort: true,
  },
  {
    id: 'machine.category',
    label: 'member.machinetype',
  },
  {
    id: 'machine.name',
    label: 'machine',
  },
  {
    id: 'laundry.name',
    label: 'laundry',
  },
  {
    id: 'laundryGroup.name',
    label: 'laundryGroup',
  },
  {
    id: 'organization.name',
    label: 'organization',
  },
]

export interface ErrorLogTableSettings extends TableSettingsWithOrganization {
  orderBy: OrderBy<keyof FhMonitoringEntry>
  startTimeFrom?: string
  startTimeTo?: string
  laundryGroupId?: string
  laundryId?: string
  machineId?: string
  machineCategory?: MachineCategory
}

interface Props {
  navigateToItem: (messageId?: string, timestamp?: number) => void
  tableSettings: ErrorLogTableSettings
  defaultTableSettings: ErrorLogTableSettings
  setTableSettings: React.Dispatch<SetStateAction<ErrorLogTableSettings>>
}

export const ErrorLogTable: React.FC<Props> = ({
  navigateToItem,
  tableSettings,
  setTableSettings,
  defaultTableSettings,
}): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  const location = useLocation()

  const translate = useTranslate()
  const activeOrganization = useActiveOrganization()
  const regionLocale = useUserRegionLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const fhMonitoringApi = new FHMonitoringsApi(httpConfiguration)
  const organizationsApi = new OrganizationsApi(httpConfiguration)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)
  const laundriesApi = new LaundriesApi(httpConfiguration)
  const machinesApi = new MachinesApi(httpConfiguration)

  const [organizations, setOrganizations] = useState<OrganizationReference[]>([])
  const [laundryGroups, setLaundryGroups] = useState<LaundryGroupReference[]>([])
  const [laundries, setLaundries] = useState<LaundryReference[]>([])
  const [machines, setMachines] = useState<MachineReference[]>([])

  // state
  const [data, setData] = useState<TableData<FhMonitoringEntry>>(DEFAULT_DATA)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)

  // static filtering values
  const machineCategories: Array<any> = []

  MACHINE_CATEGORIES.forEach((machine) => {
    machineCategories.push({ ...machine, name: translate(machine.name) })
  })

  // load data for filters
  useEffect(() => {
    organizationsApi.organizationsRefGet().then((data) => {
      data.unshift(EMPTY_ORGANIZATION_REF)
      setOrganizations(data)
    })

    laundryGroupsApi.laundrygroupsRefGet().then((data) => {
      setLaundryGroups(data)
    })

    laundriesApi.laundriesRefGet().then((data) => {
      setLaundries(data)
    })

    machinesApi.machinesRefGet().then((data) => {
      setMachines(data)
    })
  }, [])

  // load data on state change
  useEffect(() => {
    let active = true

    if (tableSettings.startTimeFrom && tableSettings.startTimeTo) {
      setLoading(true)
      const dateStartTimeFrom = new Date(tableSettings.startTimeFrom)
      const dateStartTimeTo = new Date(tableSettings.startTimeTo)

      fhMonitoringApi
        .fhMonitoringsGet(
          dateStartTimeFrom,
          dateStartTimeTo,
          tableSettings.size,
          tableSettings.page,
          tableSettingsSort(tableSettings),
          tableSettings.search,
          tableSettings.organizationId,
          tableSettings.laundryGroupId,
          tableSettings.laundryId,
          tableSettings.machineId,
          tableSettings.machineCategory,
        )
        .then((data) => {
          if (active) {
            updateTableSettingsFromData(data as Data<any>, tableSettings)
            setTableSettings(tableSettings)

            setData(mapData(data as Data<any>))
            setErrorMessage(null)
            setLoading(false)
          }
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setData(DEFAULT_DATA)
          setLoading(false)
        })
    }

    return () => {
      active = false
    }
  }, [tableSettings])

  // JSX
  const nonEmptyRows = data.data.map((item, index) => {
    return (
      <TableRow
        className={tableClasses.tableRow}
        key={index}
        onClick={navigateToItem.bind(this, item.messageId, item.errorTime.getTime())}
      >
        <TableCell>{formatDateTimeForLocale(item.errorTime, regionLocale)}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={item.errorCode} />
        </TableCell>
        <TableCell>
          <Box display={'flex'}>
            <IconWithTooltip
              icon={<ErrorSeverityIcon errorSeverity={item.errorSeverity} />}
              tooltip={getErrorSeverityName(item.errorSeverity, translate)}
            />
            <TruncatedTooltip maxChars={35} value={item.errorSummary} />
          </Box>
        </TableCell>
        <TableCell>{getWashingStepName(item.step, translate)}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={getMachineCategoryName(item.machine.category, translate)} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={getMachineNameAndSerialNumber(item.machine)} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={item.laundry.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={item.laundryGroup.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={item.organization.name} />
        </TableCell>
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string): ReactElement | undefined => {
    let filter

    if (headCellId === 'laundry.name') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundry')}
          label={translate('laundries')}
          filter={'laundryId'}
          labelFieldName="name"
          valueFieldName="id"
          options={laundries}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'laundryGroup.name') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundryGroup')}
          label={translate('laundryGroups')}
          filter={'laundryGroupId'}
          labelFieldName="name"
          valueFieldName="id"
          options={laundryGroups}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'organization.name') {
      filter = (
        <TableFilterAutocompleteOrganization
          options={organizations}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'machine.category') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByMachineType')}
          label={translate('member.machinetype')}
          filter={'machineCategory'}
          labelFieldName="name"
          valueFieldName="id"
          options={machineCategories}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'machine.name') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByMachine')}
          label={translate('machines')}
          filter={'machineId'}
          labelFieldName="name"
          getOptionLabel={getMachineOptionLabel}
          valueFieldName="id"
          options={machines}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }

    return filter
  }

  return (
    <Paper elevation={0}>
      <TableSearchForm handleSearch={handleSearch} inputSearchValue={inputSearchValue} />

      <Box mt={3} mb={3}>
        <Grid container spacing={2}>
          <TableDatePickerForm
            key={`${tableSettings.startTimeFrom}${tableSettings.startTimeTo}`}
            firstDatePropName="startTimeFrom"
            secondDatePropName="startTimeTo"
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
          />
        </Grid>
      </Box>

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        getFilter={getFilter}
        loading={loading}
        translate={translate}
      />
    </Paper>
  )
}
