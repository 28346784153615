import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Icon as MdiIcon } from '@mdi/react'
import {
  Box,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material'
import {
  Add,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  ContentCopyOutlined,
  CopyAll,
  DeleteOutlineOutlined,
  EditOutlined,
} from '@mui/icons-material'
import { mdiExportVariant } from '@mdi/js'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { ReactComponent as SmLogoImage } from 'src/assets/logo-servicemaster-icon.svg'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganizationMandatory } from 'src/organization/ActiveOrganizationProvider'
import { NavigateState } from 'src/routing/Routing'
import {
  Configuration,
  MachineType,
  OrganizationReference,
  Permission,
  Program,
  ProgramGroup,
  ProgramGroupCopyRequest,
  ProgramGroupsApi,
  ProgramType,
  ProgramWithPosition,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { formatDateTimeForLocaleOptional } from 'src/service/utils/DateFormatUtils'
import { formatTemperatureOptional } from 'src/service/utils/NumberFormatUtils'
import { getProgramNameAndTemperature, getProgramTypeName } from 'src/service/view-model/program/ProgramViewModel'
import { ButtonWithTooltip } from 'src/ui-shared/base/button/Buttons'
import { ListingButton, ListingButtonWithIcon } from 'src/ui-shared/base/button/ListingButton'
import { MenuButton } from 'src/ui-shared/base/button/MenuButton'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { HookForm } from 'src/ui-shared/base/form/control-hook-form/HookForm'
import { NumberTextFieldHookForm } from 'src/ui-shared/base/form/control-hook-form/NumberTextFieldHookForm'
import { TextFieldHookForm } from 'src/ui-shared/base/form/control-hook-form/TextFieldHookForm'
import { validPositiveInteger } from 'src/ui-shared/base/form/validation/HookFormValidators'
import { WithMandatoryActiveOrganization } from 'src/ui-shared/base/hoc/WithMandatoryActiveOrganization'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { usePrompt } from 'src/ui-shared/base/hooks/usePrompt'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { EditMode } from 'src/ui-shared/constants/Constants'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ProgramAddModal } from 'src/ui-shared/program/ProgramAddModal'
import { HeadCellsWidth } from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'
import { TableLoading } from 'src/ui-shared/table/TableLoading'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { SelectOrganizationDialog } from 'src/ui/layout/organization-selector/SelectOrganizationDialog'
import { ProgramGroupExportModal } from 'src/ui/page/es/program-group/details/ProgramGroupExportModal'
import { ProgramDetailsTab } from 'src/ui/page/es/program/details/ProgramDetailsTab'
import { hasApp, hasPermission } from 'src/user/RoleCheck'
import { useUser, useUserRegionLocale } from 'src/user/UserContext'

export const SmLogoImageStyled = styled(SmLogoImage)(() => ({
  marginBottom: '20px',
  fill: '#fff',
}))

const getHeadCells = (mode: EditMode): HeadCellsWidth[] => {
  const headCells: HeadCellsWidth[] = [
    {
      id: 'programIndex',
      label: 'programInfo.index',
      noSort: true,
      width: 7,
    },
    {
      id: 'customerIndex',
      label: 'programInfo.customerIndex',
      noSort: true,
      width: 10,
    },
    {
      id: 'screen',
      label: 'screen',
      noSort: true,
      width: 5,
    },
    {
      id: 'position',
      label: 'position',
      noSort: true,
      width: 10,
    },
    {
      id: 'temperature',
      label: 'temperature',
      noSort: true,
      width: 5,
    },
    {
      id: 'programName',
      label: 'programInfo.programName',
      noSort: true,
    },
    {
      id: 'programType',
      label: 'programInfo.type',
      noSort: true,
      width: 12,
    },
    {
      id: 'lastModified',
      label: 'programInfo.lastUpdate',
      noSort: true,
      width: 12,
    },
  ]

  if (mode !== 'view') {
    const reorderHeadCell: HeadCellsWidth = {
      id: 'reorder',
      label: '',
      noSort: true,
      width: 13,
    }
    const actionsHeadCell: HeadCellsWidth = {
      id: 'actions',
      label: '',
      noSort: true,
      width: 13,
    }
    headCells.unshift(reorderHeadCell)
    headCells.push(actionsHeadCell)
  }

  return headCells
}

interface Props {
  mode?: EditMode
}

export const ProgramGroupDetailsPage: FC<Props> = WithMandatoryActiveOrganization(({ mode = 'view' }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const navigate = useNavigate()
  const location = useLocation()
  const appId = useAppId()
  const user = useUser()
  const showSnackbar = useShowSnackbar()

  const activeOrganization = useActiveOrganizationMandatory()
  const regionLocale = useUserRegionLocale()
  const organizationId = activeOrganization.id

  const { programGroupId } = useParams()
  const { machineTypeParam } = useRequiredParams(['machineTypeParam'])
  const machineType = getEnumFromString(machineTypeParam, MachineType)

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programGroupApi = new ProgramGroupsApi(httpConfiguration)

  // state
  const [loading, setLoading] = useState(false)
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const [positionForAddProgram, setPositionForAddProgram] = useState<number | null>(null)
  const [positionForRemoval, setPositionForRemoval] = useState<number | null>(null)
  const [selectOrganizationModal, setSelectOrganizationModal] = useState<boolean>(false)
  const [selectedRow, setSelectedRow] = useState<number | null>(null)
  const [standardProgramModal, setStandardProgramModal] = useState<boolean>(false)
  const [exportModal, setExportModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const programGroupForm = useForm<ProgramGroup>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const { insert, remove, update } = useFieldArray({
    control: programGroupForm.control,
    name: 'programs',
  })

  // derived
  const viewMode = mode === 'view'
  const headCells = getHeadCells(mode)

  const hasServiceMaster = hasApp(user, AppId.SERVICE_MASTER)
  const hasProgramGroupWritePermission = hasPermission(user, Permission.EASYSET_PROGRAM_WRITE)
  const hasInstallProgramGroupPermission = hasServiceMaster && hasProgramGroupWritePermission

  const programGroup = programGroupForm.getValues()
  const programSlots = programGroupForm.formState.defaultValues ? programGroup.programs : []
  const allScreenPositions = programSlots.filter((position) => position.programPosition).length
  const availableScreenPositions = programSlots.filter((position) => !position.program).length

  usePrompt(translate('notification.form.unsaved.changes'), isFormDirty() && mode !== 'view')
  useHotKeysForm()

  // load data
  useEffect(() => {
    setLoading(true)
    setErrorMessage(null)

    // get template for creating program group
    if (mode == 'create') {
      programGroupApi
        .programGroupsTemplateGet(organizationId, machineType)
        .then((data) => {
          programGroupForm.reset(data)
          setLoading(false)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setLoading(false)
        })
    }

    // get existing program group
    if (mode === 'view' || mode === 'edit' || mode === 'copy') {
      programGroupApi
        .programGroupsProgramGroupIdGet(programGroupId!)
        .then((data) => {
          programGroupForm.reset(data)
          setLoading(false)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setLoading(false)
        })
    }
  }, [programGroupId, activeOrganization])

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/program-groups/${machineType}`)
  }

  const navigateTo = (mode: Extract<EditMode, 'view' | 'edit' | 'copy'>) => {
    navigate(`/${appId}/program-groups/${machineType}/${programGroupId}/${mode}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const navigateToItem = (programId: string | undefined) => {
    if (programId) {
      navigate(`/${appId}/programs/${machineType}/${programId}/view/${ProgramDetailsTab.SETTINGS}`)
    }
  }

  const handleInstallProgramGroupClick = () => {
    navigate(`/${AppId.SERVICE_MASTER}/laundry-groups`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  function isFormDirty() {
    const dirtyFields = programGroupForm.formState.dirtyFields
    return Object.keys(dirtyFields).length > 0
  }

  const resetDirtyForm = () => {
    programGroupForm.reset(undefined, {
      keepValues: true,
      keepIsSubmitted: true,
      keepSubmitCount: true,
    })
  }

  const openDeleteGroupModal = () => {
    setRemoveModal(true)
  }

  const openCustomProgramSelectModal = (index: number) => {
    setPositionForAddProgram(index)
    setSelectedRow(index)
  }

  const openStandardProgramSelectModal = () => {
    setStandardProgramModal(true)
  }

  const openDeleteProgramModal = (index: number) => {
    setPositionForRemoval(index)
    setSelectedRow(index)
  }

  const handleSelectModalOpen = () => {
    setSelectOrganizationModal(true)
  }

  const handleSelectModalClose = () => {
    setSelectOrganizationModal(false)
  }

  const handleExportModalOpen = () => {
    setExportModal(true)
  }

  const handleExportModalClose = () => {
    setExportModal(false)
  }

  const handleCopyProgramGroupToOrganization = (organization?: OrganizationReference) => {
    if (organization) {
      const programGroupCopyRequest: ProgramGroupCopyRequest = {
        targetOrganizationId: organization.id,
        programGroupIds: [programGroup.id],
      }

      return programGroupApi
        .programGroupsCopyPost(programGroupCopyRequest)
        .then(() => {
          handleSelectModalClose()
          showSnackbar(translate('programGroupCopySuccessful'), 'success')
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
          return err
        })
    }
  }

  const removeProgramGroup = (): void => {
    programGroupApi
      .programGroupsProgramGroupIdDelete(programGroup.id)
      .then(() => {
        navigateBack()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
        setRemoveModal(false)
      })
  }

  const handleAddProgramsToGroup = (programsToBeAdded: Program[]) => {
    if (programsToBeAdded.length > 0) {
      programsToBeAdded.forEach((program, index) => {
        // add single program at selected position (can be standard or custom)
        if (positionForAddProgram !== null) {
          const positionWithoutProgram = programSlots[positionForAddProgram]
          const newProgramWithPosition: ProgramWithPosition = {
            programPosition: positionWithoutProgram.programPosition,
            program: {
              id: program.metaData.id,
              metaData: program.metaData,
            },
            customerIndex: positionWithoutProgram.customerIndex,
          }

          update(positionForAddProgram, newProgramWithPosition)
          setSelectedRow(positionForAddProgram)
          setPositionForAddProgram(null)
        }

        // add multiple programs (can be standard or custom)
        else {
          // standard programs are added at end of list
          if (program.metaData.programType === ProgramType.STANDARD) {
            const newProgramWithoutPosition: ProgramWithPosition = {
              programPosition: null,
              program: {
                id: program.metaData.id,
                metaData: program.metaData,
              },
            }

            const lastPosition = programSlots.length

            insert(lastPosition, newProgramWithoutPosition)
            setSelectedRow(lastPosition + index)
          }

          // custom programs are added at available position
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          else if (program.metaData?.programType === ProgramType.CUSTOM) {
            const programs = programGroupForm.getValues().programs
            const firstAvailablePosition = programs.find((position) => !position.program)

            if (firstAvailablePosition) {
              const firstAvailablePositionIndex = programSlots.indexOf(firstAvailablePosition)

              const newProgramWithPosition: ProgramWithPosition = {
                programPosition: firstAvailablePosition.programPosition,
                program: {
                  id: program.metaData.id,
                  metaData: program.metaData,
                },
                customerIndex: firstAvailablePosition.customerIndex,
              }

              update(firstAvailablePositionIndex, newProgramWithPosition)
              setSelectedRow(firstAvailablePositionIndex)
            }
          }
        }
      })
    }
    programGroupForm.trigger('programs')
  }

  const handleRemoveProgramFromGroup = () => {
    if (positionForRemoval !== null) {
      const programWithPosition = programSlots[positionForRemoval]

      // if the selected row has a position on the machine then remove the program otherwise remove the whole row
      if (!programWithPosition.programPosition) {
        remove(positionForRemoval)
      } else {
        const newEmptyPosition: ProgramWithPosition = {
          program: undefined,
          programPosition: programWithPosition.programPosition,
        }
        update(positionForRemoval, newEmptyPosition)
      }
    }

    setSelectedRow(null)
    setPositionForRemoval(null)
    programGroupForm.trigger('programs')
  }

  const changePriorityAndSortSteps = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
    increment: boolean,
  ) => {
    event.stopPropagation()
    let replacementIndex: number

    if (increment) {
      // increment
      replacementIndex = index - 1
    } else {
      // decrement
      replacementIndex = index + 1
    }

    const selectedProgram = programSlots[index]
    const programForReplacement = programSlots[replacementIndex]

    // same object only changed programInformation from programForReplacement
    const selected: ProgramWithPosition = {
      ...selectedProgram,
      program: programForReplacement.program,
      customerIndex: programForReplacement.customerIndex,
    }

    // same object only changed programInformation from selectedProgram
    const replacement: ProgramWithPosition = {
      ...programForReplacement,
      program: selectedProgram.program,
      customerIndex: selectedProgram.customerIndex,
    }

    update(index, selected)
    update(replacementIndex, replacement)

    setSelectedRow(replacementIndex)
    programGroupForm.trigger('programs')
  }

  const isDisableReorderUpward = (index: number, item?: Program | null): boolean => {
    const isFirstPosition = index === 0
    const prevProgram = index > 0 ? programSlots[index - 1] : undefined
    const prevProgramHasNoPosition = !prevProgram?.programPosition
    return prevProgramHasNoPosition || isFirstPosition || viewMode || loading || !item
  }

  const isDisableReorderDownward = (index: number, item?: Program | null): boolean => {
    const isLastPosition = index === programSlots.length - 1
    const nextProgram = index < programSlots.length - 1 ? programSlots[index + 1] : undefined
    const nextProgramHasNoPosition = !nextProgram?.programPosition
    return nextProgramHasNoPosition || isLastPosition || viewMode || loading || !item
  }

  const submitProgramGroup = (programGroup: ProgramGroup) => {
    if (mode === 'edit') {
      return programGroupApi.programGroupsProgramGroupIdPut(programGroup.id, programGroup)
    }

    if (mode === 'copy') {
      const programGroupPayload: ProgramGroup = {
        ...programGroup,
        id: '',
        lastModified: undefined,
      }

      return programGroupApi.programGroupsPost(programGroupPayload)
    }

    return programGroupApi.programGroupsPost(programGroup)
  }

  const submitForm: SubmitHandler<ProgramGroup> = (programGroup) => {
    setLoading(true)
    resetDirtyForm()

    return submitProgramGroup(programGroup)
      .then((returnedPg) => {
        setLoading(false)
        // navigate to details screen for the created/updated program group
        navigate(`/${appId}/program-groups/${machineType}/${returnedPg.id}/view`)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setLoading(false)
        showSnackbar(errorMessage, 'error')
      })
  }

  // JSX
  const displayRows =
    programSlots.length > 0 ? (
      programSlots.map((item, index) => {
        const programMetadata = item.program?.metaData

        return (
          <TableRow
            className={tableClasses.tableRow}
            key={index}
            onClick={viewMode ? navigateToItem.bind(this, programMetadata?.id) : () => setSelectedRow(index)}
            selected={index === selectedRow}
            style={{ cursor: viewMode ? (item.program ? 'pointer' : 'default') : 'pointer' }}
          >
            {!viewMode && (
              <TableCell sx={{ pt: 0, pb: 0 }}>
                {/* only rows which have position can be reordered */}
                {item.programPosition && (
                  <>
                    <IconButton
                      disabled={isDisableReorderUpward(index, item.program)}
                      onClick={(event) => changePriorityAndSortSteps(event, index, true)}
                    >
                      <ArrowUpwardOutlined fontSize="small" />
                    </IconButton>
                    <IconButton
                      disabled={isDisableReorderDownward(index, item.program)}
                      onClick={(event) => changePriorityAndSortSteps(event, index, false)}
                    >
                      <ArrowDownwardOutlined fontSize="small" />
                    </IconButton>
                  </>
                )}
              </TableCell>
            )}
            <TableCell>{programMetadata?.programIndex}</TableCell>
            <TableCell>
              {!viewMode ? (
                <>
                  <NumberTextFieldHookForm
                    key={programMetadata?.id}
                    label=""
                    control={programGroupForm.control}
                    disabled={!item.program}
                    name={`programs.${index}.customerIndex`}
                    smallTextField
                    validate={{
                      validNumber: validPositiveInteger(),
                    }}
                  />
                </>
              ) : (
                item.customerIndex
              )}
            </TableCell>
            <TableCell>{item.programPosition?.screen}</TableCell>
            <TableCell>{item.programPosition?.position}</TableCell>
            <TableCell>
              {formatTemperatureOptional(programMetadata?.temperature, programMetadata?.temperatureUnit)}
            </TableCell>
            <TableCell>{programMetadata?.programName}</TableCell>
            <TableCell>{getProgramTypeName(programMetadata?.programType, translate)}</TableCell>
            <TableCell>{formatDateTimeForLocaleOptional(programMetadata?.lastModified, regionLocale)}</TableCell>
            {!viewMode && (
              <TableCell sx={{ pt: 0, pb: 0 }}>
                <>
                  <IconButton disabled={loading || !item.program} onClick={() => openDeleteProgramModal(index)}>
                    <DeleteOutlineOutlined fontSize="small" />
                  </IconButton>
                  {/* Add custom/standard program to the selected position */}
                  {item.programPosition && (
                    <IconButton
                      disabled={loading || !!item.program}
                      onClick={() => openCustomProgramSelectModal(index)}
                    >
                      <Add fontSize="small" />
                    </IconButton>
                  )}
                </>
              </TableCell>
            )}
          </TableRow>
        )
      })
    ) : (
      <TableEmpty colspan={headCells.length} />
    )

  const displayHeaders = headCells.map((item) => {
    return (
      <TableCell width={item.width ? item.width + '%' : 'auto'} key={item.id}>
        {translate(item.label)}
      </TableCell>
    )
  })

  // generic JSX
  const displayTable = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{displayHeaders}</TableRow>
        </TableHead>
        <TableBody>{loading ? <TableLoading colspan={headCells.length} /> : displayRows}</TableBody>
      </Table>
    </TableContainer>
  )

  const addProgramsButton = hasProgramGroupWritePermission && (
    <Box display={'flex'} justifyContent={'flex-end'}>
      <ButtonWithTooltip
        startIcon={<Add />}
        disabled={mode === 'view'}
        title={translate('switchToEditMode')}
        onClick={openStandardProgramSelectModal}
        variant="text"
        color="primary"
        size="medium"
      >
        {translate('addPrograms')}
      </ButtonWithTooltip>
    </Box>
  )

  const getActionButtons = (): ReactElement => {
    let actionButtons = <></>

    if (mode === 'view') {
      actionButtons = (
        <>
          {hasInstallProgramGroupPermission && (
            <ListingButtonWithIcon
              variant="contained"
              onClick={handleInstallProgramGroupClick}
              icon={
                <Icon>
                  <SmLogoImageStyled />
                </Icon>
              }
              label={translate('installProgramGroup')}
              smallScreenBreakpoint="lg"
            />
          )}

          <ListingButtonWithIcon
            id="exportButton"
            onClick={handleExportModalOpen}
            icon={<MdiIcon path={mdiExportVariant} size={0.92} />}
            label={translate('export')}
            smallScreenBreakpoint="lg"
          />

          {hasProgramGroupWritePermission && (
            <ListingButtonWithIcon
              id="editButton"
              onClick={() => navigateTo('edit')}
              icon={<EditOutlined />}
              label={translate('button.edit')}
              smallScreenBreakpoint="lg"
            />
          )}

          <MenuButton>
            {hasProgramGroupWritePermission && (
              <MenuItem onClick={() => navigateTo('copy')}>
                <ListItemIcon>
                  <ContentCopyOutlined fontSize="small" />
                </ListItemIcon>
                {translate('copy')}
              </MenuItem>
            )}

            {hasProgramGroupWritePermission && (
              <MenuItem onClick={openDeleteGroupModal}>
                <ListItemIcon>
                  <DeleteOutlineOutlined fontSize="small" />
                </ListItemIcon>
                {translate('button.delete')}
              </MenuItem>
            )}
            {/*
            <MenuItem onClick={handleExportModalOpen}>
              <ListItemIcon>
                <GetApp fontSize={'small'} />
              </ListItemIcon>
              {translate('export')}
            </MenuItem> */}

            {hasProgramGroupWritePermission && (
              <MenuItem onClick={handleSelectModalOpen}>
                <ListItemIcon>
                  <CopyAll fontSize="small" />
                </ListItemIcon>
                {translate('copyToOrganization')}
              </MenuItem>
            )}
          </MenuButton>
        </>
      )
    } else {
      actionButtons = (
        <ListingButton type="submit" id="submitButton" variant="outlined" color="primary">
          {translate('button.save')}
        </ListingButton>
      )
    }

    return actionButtons
  }

  const displayContent = (
    <>
      {addProgramsButton}
      <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
        <Grid item xs={12}>
          <TextFieldHookForm
            name="name"
            disabled={viewMode || loading}
            control={programGroupForm.control}
            required={{ value: true, message: 'validation.required' }}
            label={translate('programGroup.name')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldHookForm
            name="notes"
            disabled={viewMode || loading}
            control={programGroupForm.control}
            label={translate('notes')}
          />
        </Grid>
        <Grid item xs={12}>
          {displayTable}
        </Grid>
      </Grid>
    </>
  )

  // render
  const renderTitle = (): string => {
    let title = ''
    if (mode === 'create') {
      title = translate('createNewProgramGroup')
    } else if (mode === 'copy') {
      title = translate('createNewProgramGroupCopy')
    } else {
      const defaultValues = programGroupForm.formState.defaultValues
      title = defaultValues?.name || ''
    }

    return title
  }

  const renderProgramTitle = (programIndex: number): string => {
    let title = ''
    const programWithPosition = programSlots[programIndex]
    const program = programWithPosition.program
    title = getProgramNameAndTemperature(program) || ''
    return title
  }

  return (
    <>
      <HookForm onSubmit={programGroupForm.handleSubmit(submitForm)}>
        <ScreenLayout title={renderTitle()} onBack={navigateBack} actionsWidth={30} actions={getActionButtons()}>
          <Paper elevation={0}>{errorMessage ? <ErrorMessage message={errorMessage} /> : displayContent}</Paper>
        </ScreenLayout>
      </HookForm>

      {/* TODO DAV 2023.06.06 machine type on program group should be required, remove required operator */}
      {positionForAddProgram !== null && (
        <ProgramAddModal
          open={true}
          titleKey="addProgramToPosition"
          confirmationKey="button.add"
          onCancel={() => setPositionForAddProgram(null)}
          addSelectedRows={handleAddProgramsToGroup}
          machineType={programGroup.machineType!}
        />
      )}
      {standardProgramModal && (
        <ProgramAddModal
          open={true}
          titleKey="addProgramsToGroup"
          confirmationKey="button.add"
          onCancel={() => setStandardProgramModal(false)}
          allPositions={allScreenPositions}
          availablePositions={availableScreenPositions}
          addSelectedRows={handleAddProgramsToGroup}
          machineType={programGroup.machineType!}
          multipleSelect
        />
      )}
      {removeModal && (
        <ConfirmationModalDialog
          open={true}
          titleKey="deleteProgramGroup"
          confirmationKey="button.delete"
          onConfirm={removeProgramGroup}
          onCancel={() => setRemoveModal(false)}
        >
          {translate('deleteConfirm', programGroupForm.getValues().name)}
        </ConfirmationModalDialog>
      )}
      {positionForRemoval !== null && (
        <ConfirmationModalDialog
          open={true}
          titleKey="deleteProgramFromGroup"
          confirmationKey="button.delete"
          onConfirm={handleRemoveProgramFromGroup}
          onCancel={() => setPositionForRemoval(null)}
        >
          {translate('deleteConfirm', renderProgramTitle(positionForRemoval))}
        </ConfirmationModalDialog>
      )}
      {selectOrganizationModal && (
        <SelectOrganizationDialog
          open={true}
          setOpen={handleSelectModalClose}
          disabledOrganization={activeOrganization}
          onConfirm={handleCopyProgramGroupToOrganization}
          confirmText="copyProgramGroup"
          alertMessage="copyProgramGroupWarning"
        />
      )}
      {exportModal && (
        <ProgramGroupExportModal
          open={true}
          machineType={machineType}
          handleClose={handleExportModalClose}
          programGroupId={programGroupId!}
          title="exportProgramGroup"
        />
      )}
    </>
  )
})
