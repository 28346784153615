import React, {
  FC,
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, NotificationsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'

interface NewNotificationsContextValue {
  newNotifications: number
  readAllNotifications: () => void
}

const defaultNewNotificationsContextValue: NewNotificationsContextValue = {
  newNotifications: 0,
  readAllNotifications: () => null,
}

export const NewNotificationsContext = createContext<NewNotificationsContextValue>(defaultNewNotificationsContextValue)

export const useNewNotifications = (): NewNotificationsContextValue => {
  const notifications = useContext(NewNotificationsContext)
  return notifications
}

export const NewNotificationsProvider: FC<PropsWithChildren> = ({ children }): ReactElement => {
  const translate = useTranslate()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const notificationsApi = new NotificationsApi(httpConfiguration)

  // state
  const [newNotifications, setNewNotifications] = useState<number>(0)

  const getNotificationCount = () => {
    console.info('Checking for notifications')
    notificationsApi
      .notificationsCheckGet()
      .then((data) => {
        setNewNotifications(data.count)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
      })
  }

  useEffect(() => {
    // check for new version 3 seconds after first load
    setTimeout(getNotificationCount, 3000)
    const interval = setInterval(getNotificationCount, 60000)
    return () => clearInterval(interval)
  }, [httpConfiguration])

  const readAllNotifications = () => {
    notificationsApi
      .notificationsReadPost()
      .then(() => {
        setNewNotifications(0)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
      })
  }

  const newNotificationsContextValue: NewNotificationsContextValue = useMemo(() => {
    return {
      newNotifications,
      readAllNotifications,
    }
  }, [newNotifications])

  return (
    <NewNotificationsContext.Provider value={newNotificationsContextValue}>{children}</NewNotificationsContext.Provider>
  )
}
