/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BackendUserProfile,
  BackendUserProfileWrite,
  MeInitializeRequest,
  MeInitializeResponse,
  Message,
} from '../models';
import {
    BackendUserProfileFromJSON,
    BackendUserProfileToJSON,
    BackendUserProfileWriteFromJSON,
    BackendUserProfileWriteToJSON,
    MeInitializeRequestFromJSON,
    MeInitializeRequestToJSON,
    MeInitializeResponseFromJSON,
    MeInitializeResponseToJSON,
    MessageFromJSON,
    MessageToJSON,
} from '../models';

export interface MeCloseAccountPostRequest {
    body?: object;
}

export interface MeInitializePostRequest {
    meInitializeRequest?: MeInitializeRequest;
}

export interface MePersonalDataPostRequest {
    body?: object;
}

export interface MePutRequest {
    backendUserProfileWrite?: BackendUserProfileWrite;
}

/**
 * 
 */
export class MeApi extends runtime.BaseAPI {

    /**
     * Close My Account
     */
    async meCloseAccountPostRaw(requestParameters: MeCloseAccountPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Message>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/me/close-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageFromJSON(jsonValue));
    }

    /**
     * Close My Account
     */
    async meCloseAccountPost(body?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Message> {
        const response = await this.meCloseAccountPostRaw({ body: body }, initOverrides);
        return await response.value();
    }

    /**
     * Get Information about myself
     */
    async meGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BackendUserProfile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BackendUserProfileFromJSON(jsonValue));
    }

    /**
     * Get Information about myself
     */
    async meGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BackendUserProfile> {
        const response = await this.meGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Initialize my User in the Webapp and optionally add my Notification Token
     */
    async meInitializePostRaw(requestParameters: MeInitializePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeInitializeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/me/initialize`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MeInitializeRequestToJSON(requestParameters.meInitializeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeInitializeResponseFromJSON(jsonValue));
    }

    /**
     * Initialize my User in the Webapp and optionally add my Notification Token
     */
    async meInitializePost(meInitializeRequest?: MeInitializeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeInitializeResponse> {
        const response = await this.meInitializePostRaw({ meInitializeRequest: meInitializeRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Request my personal data
     */
    async mePersonalDataPostRaw(requestParameters: MePersonalDataPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Message>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/me/personal-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageFromJSON(jsonValue));
    }

    /**
     * Request my personal data
     */
    async mePersonalDataPost(body?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Message> {
        const response = await this.mePersonalDataPostRaw({ body: body }, initOverrides);
        return await response.value();
    }

    /**
     * Update Information about myself
     */
    async mePutRaw(requestParameters: MePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BackendUserProfile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/me`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BackendUserProfileWriteToJSON(requestParameters.backendUserProfileWrite),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BackendUserProfileFromJSON(jsonValue));
    }

    /**
     * Update Information about myself
     */
    async mePut(backendUserProfileWrite?: BackendUserProfileWrite, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BackendUserProfile> {
        const response = await this.mePutRaw({ backendUserProfileWrite: backendUserProfileWrite }, initOverrides);
        return await response.value();
    }

    /**
     * Triggers a password reset
     */
    async meResetPasswordPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/me/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Triggers a password reset
     */
    async meResetPasswordPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meResetPasswordPostRaw(initOverrides);
    }

}
