import { useEffect } from 'react'

export const useHotKeysForm = (cancelPageHandler?: () => void): void => {
  useEffect(() => {
    window.addEventListener('keydown', keyPressHandler)

    return () => {
      window.removeEventListener('keydown', keyPressHandler)
    }
  }, [])

  const keyPressHandler = (event: KeyboardEvent) => {
    // prevents hitting the event if the user is typing in input fields
    if ((event.target as HTMLElement).tagName !== 'BODY') {
      return
    }

    if (event.key === 'Escape') {
      const cancelButton = getButton('cancelButton')
      if (cancelButton) {
        cancelButton.click()
      } else if (cancelPageHandler) {
        cancelPageHandler()
      }
    } else if (event.ctrlKey && event.key === 's') {
      event.preventDefault()
      const submitButton = getButton('submitButton')
      submitButton?.click()
    } else if (event.key === 'e') {
      const editButton = getButton('editButton')
      editButton?.click()
    }
  }

  const getButton = (buttonName: string): HTMLElement | null => {
    const button = document.getElementById(buttonName)
    return button
  }
}
