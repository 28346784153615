/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DashboardLaundryStateOverview } from './DashboardLaundryStateOverview';
import {
    DashboardLaundryStateOverviewFromJSON,
    DashboardLaundryStateOverviewFromJSONTyped,
    DashboardLaundryStateOverviewToJSON,
} from './DashboardLaundryStateOverview';

/**
 * 
 * @export
 * @interface DashboardLaundryStateOverviews
 */
export interface DashboardLaundryStateOverviews {
    /**
     * 
     * @type {Array<DashboardLaundryStateOverview>}
     * @memberof DashboardLaundryStateOverviews
     */
    laundryCharts: Array<DashboardLaundryStateOverview>;
}

/**
 * Check if a given object implements the DashboardLaundryStateOverviews interface.
 */
export function instanceOfDashboardLaundryStateOverviews(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "laundryCharts" in value;

    return isInstance;
}

export function DashboardLaundryStateOverviewsFromJSON(json: any): DashboardLaundryStateOverviews {
    return DashboardLaundryStateOverviewsFromJSONTyped(json, false);
}

export function DashboardLaundryStateOverviewsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardLaundryStateOverviews {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'laundryCharts': ((json['laundryCharts'] as Array<any>).map(DashboardLaundryStateOverviewFromJSON)),
    };
}

export function DashboardLaundryStateOverviewsToJSON(value?: DashboardLaundryStateOverviews | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'laundryCharts': ((value.laundryCharts as Array<any>).map(DashboardLaundryStateOverviewToJSON)),
    };
}

