import React from 'react'
import { useNavigate } from 'react-router'
import QRCode from 'qrcode.react'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { ReactComponent as QRCodeIcon } from 'src/assets/qrcode-scan.svg'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Machine, Permission } from 'src/service/backend/api/models'
import { formatDateTimeForLocaleOptional } from 'src/service/utils/DateFormatUtils'
import {
  getMachineConnectivityTypeName,
  getMachineTypeName,
  isConnectedMachineType,
} from 'src/service/view-model/machine/Machines'
import { isOrganizationTypeServicePartner } from 'src/service/view-model/organization/Organizations'
import { getProgramInstallationStatus } from 'src/service/view-model/program/ProgramViewModel'
import { ButtonWithTooltip } from 'src/ui-shared/base/button/Buttons'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { DeleteIcon } from 'src/ui-shared/icons/Icons'
import { LaundryGroupTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupTab'
import { MachineStatusChip } from 'src/ui/page/common/machine/list/MachineStatusChip'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser, useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  machine: Machine
  setRemoveCodeModal: (value: boolean) => void
  removeCodeModal: boolean
  onQrCodeRemoveHandler: () => void
  onEditHandle: () => void
  unpairModal: boolean
  setUnpairModal: (value: boolean) => void
  onMachineUnpairHandler: () => void
  removeModal: boolean
  setRemoveModal: (value: boolean) => void
  onRemoveHandler: () => void
  onActionStartHandler: () => void
}

export const MachineOverviewTab: React.FC<Props> = ({
  machine,
  setRemoveCodeModal,
  removeCodeModal,
  onQrCodeRemoveHandler,
  onEditHandle,
  unpairModal,
  setUnpairModal,
  onMachineUnpairHandler,
  removeModal,
  setRemoveModal,
  onRemoveHandler,
  onActionStartHandler,
}) => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  const user = useUser()
  const navigate = useNavigate()
  const appId = useAppId()
  const regionLocale = useUserRegionLocale()

  useHotKeysForm()

  const isConnectedMachine = isConnectedMachineType(machine.machineConnectivityType)

  const hasStartUsageActionPermission = hasPermission(user, Permission.MACHINE_ACTION_START_USAGE)

  const navigateToScanner = () => {
    navigate(`/${appId}/machines/${machine.id}/scanner`)
  }

  const getStatusChip = (status?: boolean) => {
    return <Chip color={status ? 'primary' : 'default'} label={status ? translate('online') : translate('offline')} />
  }

  const unpairButton = (
    <ButtonWithTooltip
      startIcon={<DeleteIcon />}
      variant="outlined"
      color="primary"
      size="large"
      className={sharedClasses.ButtonMargin}
      disabled={!machine.laundry?.id}
      title={machine.laundry?.id ? undefined : translate('machineIsNotPaired')}
      onClick={() => setUnpairModal(true)}
    >
      {translate('button.unpair')}
    </ButtonWithTooltip>
  )

  const deleteUnconnectedMachine = (
    <ButtonWithTooltip
      startIcon={<DeleteIcon />}
      variant="outlined"
      color="primary"
      size="large"
      className={sharedClasses.ButtonMargin}
      onClick={() => setRemoveModal(true)}
    >
      {translate('button.delete')}
    </ButtonWithTooltip>
  )

  const connectivityActions = isConnectedMachine ? unpairButton : deleteUnconnectedMachine

  return (
    <Box pt={2}>
      <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
        <Grid item {...ITEM_BREAKPOINTS}>
          <DetailsTextField value={machine.name} label={translate('machineName')} />
        </Grid>
        <Grid item {...ITEM_BREAKPOINTS}>
          <DetailsTextField
            value={getMachineTypeName(machine.type, translate)}
            label={translate('member.machinetype')}
          />
        </Grid>
        <Grid item {...ITEM_BREAKPOINTS}>
          <DetailsTextField value={machine.serialNumber} label={translate('serialNumber')} />
        </Grid>
        <Grid item {...ITEM_BREAKPOINTS}>
          <DetailsTextField
            value={machine.organization?.name}
            label={translate('organization')}
            link={
              isOrganizationTypeServicePartner(machine.organization?.type)
                ? `/${appId}/service-partners/${machine.organization?.id}/view`
                : `/${appId}/organizations/${machine.organization?.id}/view`
            }
          />
        </Grid>
        <Grid item {...ITEM_BREAKPOINTS}>
          <DetailsTextField
            value={machine.laundryGroup?.name}
            label={translate('laundryGroup')}
            link={`/${appId}/laundry-groups/${machine.laundryGroup?.id}/view/${LaundryGroupTab.OVERVIEW}`}
          />
        </Grid>
        <Grid item {...ITEM_BREAKPOINTS}>
          <DetailsTextField
            value={machine.laundry?.name}
            label={translate('laundry')}
            link={`/${appId}/laundries/${machine.laundry?.id}/view`}
          />
        </Grid>
      </Grid>
      <Divider className={sharedClasses.Divider} />
      <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
        <Grid item {...ITEM_BREAKPOINTS}>
          <DetailsTextField value={machine.manufacturer?.name} label={translate('manufacturerName')} />
        </Grid>
        <Grid item {...ITEM_BREAKPOINTS}>
          <DetailsTextField
            label={translate('manufacturerProductNumber')}
            value={machine.manufacturerModel.productNumber}
            link={`/${appId}/machines/${machine.id}/manufacturer-model/${machine.manufacturerModel.id}/`}
          />
        </Grid>

        {appId === AppId.SERVICE_MASTER && isConnectedMachine && (
          <>
            <Grid item {...ITEM_BREAKPOINTS}>
              <DetailsTextField
                value={formatDateTimeForLocaleOptional(machine.productionDateTime, regionLocale)}
                label={translate('productionDateTime')}
              />
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS}>
              <DetailsTextField
                value={getMachineConnectivityTypeName(machine.machineConnectivityType, translate)}
                label={translate('connectivityType')}
              />
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS}>
              <DetailsTextField
                value={machine.operatingHours ? machine.operatingHours : ''}
                label={translate('operatingHours')}
              />
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS}>
              <DetailsTextField
                value={machine.programCounter ? machine.programCounter : ''}
                label={translate('programCounter')}
              />
            </Grid>
          </>
        )}

        {isConnectedMachine && (
          <>
            <Grid item {...ITEM_BREAKPOINTS}>
              <DetailsTextField
                value={formatDateTimeForLocaleOptional(machine.status?.lastOnline, regionLocale)}
                label={translate('lastOnline')}
              />
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS}>
              <DetailsTextField
                value={formatDateTimeForLocaleOptional(machine.status?.lastOffline, regionLocale)}
                label={translate('lastOffline')}
              />
            </Grid>
            {/* Additional fields */}
            <Grid item {...ITEM_BREAKPOINTS}>
              <DetailsTextField
                label={translate('status')}
                inputProps={{
                  startAdornment: (
                    <Box mt={3}>
                      {appId === AppId.SERVICE_MASTER ? (
                        <MachineStatusChip status={machine.status?.utilizationMachineState} />
                      ) : (
                        getStatusChip(machine.status?.online)
                      )}
                    </Box>
                  ),
                }}
              />
            </Grid>
            {hasPermission(user, Permission.MACHINE_THING_NAME_READ) ? (
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={machine.thingName} label={translate('thingName')} />
              </Grid>
            ) : (
              <Grid {...ITEM_BREAKPOINTS} />
            )}
            <Grid item {...ITEM_BREAKPOINTS}>
              <DetailsTextField value={machine.status?.machineFirmwareVersion} label={translate('firmware')} />
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS}>
              <DetailsTextField
                value={
                  machine.status?.connectivityFirmwareVersion +
                  ' (type: ' +
                  machine.status?.connectivityFirmwareType +
                  ')'
                }
                label={translate('connectivityFirmware')}
              />
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS}>
              <DetailsTextField
                value={getProgramInstallationStatus(machine.status?.programInstallationStatus, translate)}
                label={translate('programInstallationStatus')}
              />
            </Grid>
          </>
        )}

        <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
          <DetailsTextField value={machine.notes || ''} label={translate('notes')} />
        </Grid>
      </Grid>

      {isConnectedMachine && (
        <>
          <Divider className={sharedClasses.Divider} />
          <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
            <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
              <DetailsTextField
                value={machine.washmaster?.tag?.contentUrl ? machine.washmaster.tag.contentUrl : ''}
                label={translate('tag')}
              />
            </Grid>
            {machine.washmaster?.tag?.contentUrl ? (
              <Grid item sm={12}>
                <QRCode
                  value={machine.washmaster.tag.contentUrl}
                  size={128}
                  renderAs="canvas"
                  level={'L'}
                  imageSettings={{
                    src: '/washmaster-qr-code-icon.png',
                    height: 24,
                    width: 24,
                    excavate: true,
                  }}
                />
              </Grid>
            ) : null}
            <Grid item container alignContent="center">
              <ButtonWithTooltip
                variant="contained"
                size="large"
                color="primary"
                startIcon={<QRCodeIcon />}
                className={sharedClasses.ButtonMargin}
                disabled={!machine.laundry?.id}
                title={machine.laundry?.id ? undefined : translate('unpairedMachineNoEdit')}
                onClick={navigateToScanner}
              >
                {translate('scan')}
              </ButtonWithTooltip>

              {machine.washmaster?.tag && (
                <Button
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                  color="primary"
                  size="large"
                  className={sharedClasses.ButtonMargin}
                  onClick={() => setRemoveCodeModal(true)}
                >
                  {translate('button.remove')}
                </Button>
              )}
            </Grid>
          </Grid>
          <Divider className={sharedClasses.Divider} />
          <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
            <Grid item {...ITEM_BREAKPOINTS}>
              <FormControl disabled component="fieldset">
                <FormLabel component="legend">{translate('features')}</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={machine.products?.washMasterEnabled}
                        disabled
                        name="washMasterEnabled"
                      />
                    }
                    label={translate('washMasterEnabled')}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={machine.washmaster?.freeUsage} disabled name="freeUsage" />
                    }
                    label={translate('freeUsage')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {hasStartUsageActionPermission ? (
              <>
                <Grid item sm={12}>
                  <Divider className={sharedClasses.Divider} />
                  <Typography className={sharedClasses.SectionTitle}>{translate('machineActions')}</Typography>
                </Grid>
                <Grid item {...ITEM_BREAKPOINTS}>
                  <Button
                    startIcon={<PlayArrowIcon />}
                    variant="contained"
                    color="primary"
                    size="large"
                    className={sharedClasses.ButtonMargin}
                    sx={{ mb: 1 }}
                    onClick={onActionStartHandler}
                  >
                    {translate('startMachineUsage')}
                  </Button>
                </Grid>
              </>
            ) : null}
          </Grid>
        </>
      )}

      <Divider className={sharedClasses.Divider} />
      <Box mt={3} display="flex" justifyContent="space-between">
        <Box>{connectivityActions}</Box>
        <Box display="flex" justifyContent="flex-end">
          <ButtonWithTooltip
            id="editButton"
            variant="contained"
            color="primary"
            size="large"
            disabled={isConnectedMachine ? !machine.laundry?.id : false}
            title={machine.laundry?.id ? undefined : translate('unpairedMachineNoEdit')}
            onClick={onEditHandle}
          >
            {translate('button.edit')}
          </ButtonWithTooltip>
        </Box>
      </Box>
      <ConfirmationModalDialog
        titleKey="removeQrCode"
        confirmationKey="button.remove"
        open={removeCodeModal}
        onCancel={() => setRemoveCodeModal(false)}
        onConfirm={onQrCodeRemoveHandler}
      >
        {translate('removeQrCodeConfirm')} &quot;{machine.name}&quot;?
      </ConfirmationModalDialog>
      <ConfirmationModalDialog
        titleKey="unpairMachine"
        confirmationKey="button.unpair"
        open={unpairModal}
        onCancel={() => setUnpairModal(false)}
        onConfirm={onMachineUnpairHandler}
      >
        {translate('unpairMachineMessage')}
      </ConfirmationModalDialog>
      <ConfirmationModalDialog
        titleKey="deleteUnconnectedMachine"
        confirmationKey="button.delete"
        open={removeModal}
        onConfirm={onRemoveHandler}
        onCancel={() => setRemoveModal(false)}
      >
        {translate('deleteConfirm', machine.name)}
      </ConfirmationModalDialog>
    </Box>
  )
}
