/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Manufacturer,
  ManufacturerModel,
  ManufacturerModelReference,
  PageableManufacturerModels,
  PageableManufacturers,
} from '../models';
import {
    ManufacturerFromJSON,
    ManufacturerToJSON,
    ManufacturerModelFromJSON,
    ManufacturerModelToJSON,
    ManufacturerModelReferenceFromJSON,
    ManufacturerModelReferenceToJSON,
    PageableManufacturerModelsFromJSON,
    PageableManufacturerModelsToJSON,
    PageableManufacturersFromJSON,
    PageableManufacturersToJSON,
} from '../models';

export interface ManufacturerModelsGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    manufacturerId?: string;
    productNumber?: string;
}

export interface ManufacturerModelsManufacturerModelIdGetRequest {
    manufacturerModelId: string;
}

export interface ManufacturerModelsManufacturerModelIdPutRequest {
    manufacturerModelId: string;
    manufacturerModel?: ManufacturerModel;
}

export interface ManufacturerModelsPostRequest {
    manufacturerModel?: ManufacturerModel;
}

export interface ManufacturerModelsRefGetRequest {
    size?: number;
    search?: string;
    manufacturerId?: string;
    productNumber?: string;
}

export interface ManufacturersGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
}

export interface ManufacturersManufacturerIdGetRequest {
    manufacturerId: string;
}

export interface ManufacturersManufacturerIdPutRequest {
    manufacturerId: string;
    manufacturer?: Manufacturer;
}

export interface ManufacturersPostRequest {
    manufacturer?: Manufacturer;
}

export interface ManufacturersRefGetRequest {
    size?: number;
    search?: string;
}

/**
 * 
 */
export class ManufacturerModelsApi extends runtime.BaseAPI {

    /**
     * List Manufacturer Model Data
     */
    async manufacturerModelsGetRaw(requestParameters: ManufacturerModelsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableManufacturerModels>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.manufacturerId !== undefined) {
            queryParameters['manufacturerId'] = requestParameters.manufacturerId;
        }

        if (requestParameters.productNumber !== undefined) {
            queryParameters['productNumber'] = requestParameters.productNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manufacturer-models`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableManufacturerModelsFromJSON(jsonValue));
    }

    /**
     * List Manufacturer Model Data
     */
    async manufacturerModelsGet(size?: number, page?: number, sort?: string, search?: string, manufacturerId?: string, productNumber?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableManufacturerModels> {
        const response = await this.manufacturerModelsGetRaw({ size: size, page: page, sort: sort, search: search, manufacturerId: manufacturerId, productNumber: productNumber }, initOverrides);
        return await response.value();
    }

    /**
     * Get Manufacturer Model Data
     */
    async manufacturerModelsManufacturerModelIdGetRaw(requestParameters: ManufacturerModelsManufacturerModelIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManufacturerModel>> {
        if (requestParameters.manufacturerModelId === null || requestParameters.manufacturerModelId === undefined) {
            throw new runtime.RequiredError('manufacturerModelId','Required parameter requestParameters.manufacturerModelId was null or undefined when calling manufacturerModelsManufacturerModelIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manufacturer-models/{manufacturerModelId}`.replace(`{${"manufacturerModelId"}}`, encodeURIComponent(String(requestParameters.manufacturerModelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManufacturerModelFromJSON(jsonValue));
    }

    /**
     * Get Manufacturer Model Data
     */
    async manufacturerModelsManufacturerModelIdGet(manufacturerModelId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManufacturerModel> {
        const response = await this.manufacturerModelsManufacturerModelIdGetRaw({ manufacturerModelId: manufacturerModelId }, initOverrides);
        return await response.value();
    }

    /**
     * Update Manufacturer Model Data
     */
    async manufacturerModelsManufacturerModelIdPutRaw(requestParameters: ManufacturerModelsManufacturerModelIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManufacturerModel>> {
        if (requestParameters.manufacturerModelId === null || requestParameters.manufacturerModelId === undefined) {
            throw new runtime.RequiredError('manufacturerModelId','Required parameter requestParameters.manufacturerModelId was null or undefined when calling manufacturerModelsManufacturerModelIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/manufacturer-models/{manufacturerModelId}`.replace(`{${"manufacturerModelId"}}`, encodeURIComponent(String(requestParameters.manufacturerModelId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ManufacturerModelToJSON(requestParameters.manufacturerModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManufacturerModelFromJSON(jsonValue));
    }

    /**
     * Update Manufacturer Model Data
     */
    async manufacturerModelsManufacturerModelIdPut(manufacturerModelId: string, manufacturerModel?: ManufacturerModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManufacturerModel> {
        const response = await this.manufacturerModelsManufacturerModelIdPutRaw({ manufacturerModelId: manufacturerModelId, manufacturerModel: manufacturerModel }, initOverrides);
        return await response.value();
    }

    /**
     * create manufacturer model
     */
    async manufacturerModelsPostRaw(requestParameters: ManufacturerModelsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManufacturerModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/manufacturer-models`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManufacturerModelToJSON(requestParameters.manufacturerModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManufacturerModelFromJSON(jsonValue));
    }

    /**
     * create manufacturer model
     */
    async manufacturerModelsPost(manufacturerModel?: ManufacturerModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManufacturerModel> {
        const response = await this.manufacturerModelsPostRaw({ manufacturerModel: manufacturerModel }, initOverrides);
        return await response.value();
    }

    /**
     * List Manufacturer Model Ref Data
     */
    async manufacturerModelsRefGetRaw(requestParameters: ManufacturerModelsRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ManufacturerModelReference>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.manufacturerId !== undefined) {
            queryParameters['manufacturerId'] = requestParameters.manufacturerId;
        }

        if (requestParameters.productNumber !== undefined) {
            queryParameters['productNumber'] = requestParameters.productNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manufacturer-models/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ManufacturerModelReferenceFromJSON));
    }

    /**
     * List Manufacturer Model Ref Data
     */
    async manufacturerModelsRefGet(size?: number, search?: string, manufacturerId?: string, productNumber?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ManufacturerModelReference>> {
        const response = await this.manufacturerModelsRefGetRaw({ size: size, search: search, manufacturerId: manufacturerId, productNumber: productNumber }, initOverrides);
        return await response.value();
    }

    /**
     * List manufacturers
     */
    async manufacturersGetRaw(requestParameters: ManufacturersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableManufacturers>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manufacturers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableManufacturersFromJSON(jsonValue));
    }

    /**
     * List manufacturers
     */
    async manufacturersGet(size?: number, page?: number, sort?: string, search?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableManufacturers> {
        const response = await this.manufacturersGetRaw({ size: size, page: page, sort: sort, search: search }, initOverrides);
        return await response.value();
    }

    /**
     * Get Manufacturer Data
     */
    async manufacturersManufacturerIdGetRaw(requestParameters: ManufacturersManufacturerIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Manufacturer>> {
        if (requestParameters.manufacturerId === null || requestParameters.manufacturerId === undefined) {
            throw new runtime.RequiredError('manufacturerId','Required parameter requestParameters.manufacturerId was null or undefined when calling manufacturersManufacturerIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manufacturers/{manufacturerId}`.replace(`{${"manufacturerId"}}`, encodeURIComponent(String(requestParameters.manufacturerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManufacturerFromJSON(jsonValue));
    }

    /**
     * Get Manufacturer Data
     */
    async manufacturersManufacturerIdGet(manufacturerId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Manufacturer> {
        const response = await this.manufacturersManufacturerIdGetRaw({ manufacturerId: manufacturerId }, initOverrides);
        return await response.value();
    }

    /**
     * Update Manufacturer Data
     */
    async manufacturersManufacturerIdPutRaw(requestParameters: ManufacturersManufacturerIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Manufacturer>> {
        if (requestParameters.manufacturerId === null || requestParameters.manufacturerId === undefined) {
            throw new runtime.RequiredError('manufacturerId','Required parameter requestParameters.manufacturerId was null or undefined when calling manufacturersManufacturerIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/manufacturers/{manufacturerId}`.replace(`{${"manufacturerId"}}`, encodeURIComponent(String(requestParameters.manufacturerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ManufacturerToJSON(requestParameters.manufacturer),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManufacturerFromJSON(jsonValue));
    }

    /**
     * Update Manufacturer Data
     */
    async manufacturersManufacturerIdPut(manufacturerId: string, manufacturer?: Manufacturer, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Manufacturer> {
        const response = await this.manufacturersManufacturerIdPutRaw({ manufacturerId: manufacturerId, manufacturer: manufacturer }, initOverrides);
        return await response.value();
    }

    /**
     * create manufacturer
     */
    async manufacturersPostRaw(requestParameters: ManufacturersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Manufacturer>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/manufacturers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManufacturerToJSON(requestParameters.manufacturer),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManufacturerFromJSON(jsonValue));
    }

    /**
     * create manufacturer
     */
    async manufacturersPost(manufacturer?: Manufacturer, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Manufacturer> {
        const response = await this.manufacturersPostRaw({ manufacturer: manufacturer }, initOverrides);
        return await response.value();
    }

    /**
     * List manufacturers
     */
    async manufacturersRefGetRaw(requestParameters: ManufacturersRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Manufacturer>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manufacturers/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ManufacturerFromJSON));
    }

    /**
     * List manufacturers
     */
    async manufacturersRefGet(size?: number, search?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Manufacturer>> {
        const response = await this.manufacturersRefGetRaw({ size: size, search: search }, initOverrides);
        return await response.value();
    }

}
