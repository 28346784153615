import React, { ReactElement, useContext } from 'react'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ActiveOrganizationContext } from 'src/organization/ActiveOrganizationProvider'
import { OrganizationReference } from 'src/service/backend/api'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'

interface Props {
  options: OrganizationReference[]
  label?: string
  tableSettings: any
  setTableSettings: (value: any) => void
}

export const TableFilterAutocompleteOrganization = ({
  options,
  label,
  tableSettings,
  setTableSettings,
}: Props): ReactElement => {
  const translate = useTranslate()
  const { setActiveOrganizationId } = useContext(ActiveOrganizationContext)

  return (
    <TableFilterAutocomplete
      title={translate('filterByOrganization')}
      label={label ? label : translate('organization')}
      filter={'organizationId'}
      labelFieldName="name"
      valueFieldName="id"
      options={options}
      tableSettings={tableSettings}
      setTableSettings={setTableSettings}
      onItemSelected={(item) => setActiveOrganizationId(item?.id)}
    />
  )
}
