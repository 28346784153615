import React, { ReactElement, memo } from 'react'
import { UseFormReturn, useFieldArray } from 'react-hook-form'
import { Box, Divider, Grid } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Program, ProgramType } from 'src/service/backend/api'
import { formatDateTimeForLocaleOptional } from 'src/service/utils/DateFormatUtils'
import { MACHINES } from 'src/service/view-model/machine/Machines'
import { PROGRAM_TYPES, getProgramModesTranslated } from 'src/service/view-model/program/ProgramViewModel'
import { SettingWithExpandableGroups } from 'src/service/view-model/setting-field/SettingExpandableGroupsUtils'
import { AutocompleteHookForm } from 'src/ui-shared/base/form/control-hook-form/AutocompleteHookForm'
import { NumberTextFieldHookForm } from 'src/ui-shared/base/form/control-hook-form/NumberTextFieldHookForm'
import { TextFieldHookForm } from 'src/ui-shared/base/form/control-hook-form/TextFieldHookForm'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { DynamicSettingFieldsHookForm } from 'src/ui-shared/base/form/dynamic-setting-form/DynamicSettingFieldsHookForm'
import { EditMode } from 'src/ui-shared/constants/Constants'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  programForm: UseFormReturn<Program, any>
  loading: boolean
  mode: EditMode
}

export const ProgramSettingsTab = memo(function ProgramSettingsTabInner({
  mode,
  loading,
  programForm,
}: Props): ReactElement {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const regionLocale = useUserRegionLocale()

  const { fields, update } = useFieldArray({
    control: programForm.control,
    name: 'details.programSettings',
  })

  const formDisabled = mode === 'view'

  const program = programForm.getValues()
  const programStandard = program.metaData.programType === ProgramType.STANDARD

  const checkIfExpandedGroupFieldsHaveError = (expandedGroupFields?: SettingWithExpandableGroups[]) => {
    const fieldIndexes = expandedGroupFields?.map((item) => item.fieldIndex) ?? []
    return fieldIndexes.some((fieldIndex) => programForm.getFieldState(`details.programSettings.${fieldIndex}`).error)
  }

  return (
    <Box pt={2}>
      <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
        <Grid item {...ITEM_BREAKPOINTS} lg={6}>
          {/* TODO: KBL 2023-06-12 needs to be clarified if should be editable on individual program */}
          <NumberTextFieldHookForm
            name="metaData.programIndex"
            disabled={formDisabled || loading || mode === 'edit' || (programStandard && mode !== 'copy')}
            control={programForm.control}
            label={translate('programInfo.programIndex')}
            hintText={translate('programInfo.indexHint')}
          />
        </Grid>
        <Grid item {...ITEM_BREAKPOINTS} lg={6}>
          <TextFieldHookForm
            name="metaData.organization.name"
            disabled
            control={programForm.control}
            label={translate('organization')}
          />
        </Grid>
        <Grid item {...ITEM_BREAKPOINTS} lg={6}>
          <TextFieldHookForm
            name="metaData.programName"
            disabled={formDisabled || loading || programStandard}
            control={programForm.control}
            label={translate('programInfo.name')}
          />
        </Grid>
        <Grid item {...ITEM_BREAKPOINTS} lg={6}>
          <AutocompleteHookForm
            name="metaData.programMode"
            freeSolo={true}
            disabled={formDisabled || loading || programStandard}
            options={getProgramModesTranslated(translate)}
            control={programForm.control}
            label={translate('programInfo.mode')}
          />
        </Grid>
        {mode === 'view' && (
          <>
            <Grid item {...ITEM_BREAKPOINTS} lg={6}>
              <AutocompleteHookForm
                name="metaData.programType"
                disabled
                transform={{
                  labelFieldName: 'name',
                  valueFieldName: 'type',
                  translateLabel: true,
                }}
                options={PROGRAM_TYPES}
                control={programForm.control}
                label={translate('programInfo.type')}
              />
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS} lg={6}>
              <AutocompleteHookForm
                name="metaData.machineType"
                disabled
                control={programForm.control}
                transform={{
                  labelFieldName: 'name',
                  valueFieldName: 'type',
                  translateLabel: true,
                }}
                options={MACHINES}
                label={translate('programInfo.machineType')}
              />
            </Grid>
          </>
        )}
        <Grid item {...ITEM_BREAKPOINTS} lg={6}>
          <NumberTextFieldHookForm
            name="metaData.temperature"
            disabled={formDisabled || loading || programStandard}
            control={programForm.control}
            label={translate('temperatureWithUnit', program.metaData.temperatureUnit ?? undefined)}
          />
        </Grid>
        <Grid item {...ITEM_BREAKPOINTS} lg={6}>
          {(mode === 'edit' || mode === 'view') && (
            <DetailsTextField
              label={translate('programInfo.lastUpdate')}
              value={formatDateTimeForLocaleOptional(program.metaData.lastModified, regionLocale)}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextFieldHookForm
            name="metaData.description"
            multiline={true}
            rows={3}
            disabled={formDisabled || loading || programStandard}
            control={programForm.control}
            label={translate('description')}
          />
        </Grid>
      </Grid>
      {fields.length > 0 && (
        <>
          <Divider className={sharedClasses.Divider} />
          <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
            <DynamicSettingFieldsHookForm
              fields={fields}
              formContext={{
                form: programForm,
                formDisabled: formDisabled,
                loading: loading,
                prefix: 'details.programSettings',
              }}
              update={update}
              checkIfExpandedGroupFieldsHaveError={checkIfExpandedGroupFieldsHaveError}
            />
          </Grid>
        </>
      )}
    </Box>
  )
})
