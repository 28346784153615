/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Language } from './Language';
import {
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
} from './Language';
import type { NotificationAction } from './NotificationAction';
import {
    NotificationActionFromJSON,
    NotificationActionFromJSONTyped,
    NotificationActionToJSON,
} from './NotificationAction';

/**
 * 
 * @export
 * @interface CreateNotificationContentInner
 */
export interface CreateNotificationContentInner {
    /**
     * 
     * @type {Language}
     * @memberof CreateNotificationContentInner
     */
    language: Language;
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationContentInner
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationContentInner
     */
    message: string;
    /**
     * 
     * @type {NotificationAction}
     * @memberof CreateNotificationContentInner
     */
    action?: NotificationAction;
}

/**
 * Check if a given object implements the CreateNotificationContentInner interface.
 */
export function instanceOfCreateNotificationContentInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function CreateNotificationContentInnerFromJSON(json: any): CreateNotificationContentInner {
    return CreateNotificationContentInnerFromJSONTyped(json, false);
}

export function CreateNotificationContentInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNotificationContentInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'language': LanguageFromJSON(json['language']),
        'title': json['title'],
        'message': json['message'],
        'action': !exists(json, 'action') ? undefined : NotificationActionFromJSON(json['action']),
    };
}

export function CreateNotificationContentInnerToJSON(value?: CreateNotificationContentInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'language': LanguageToJSON(value.language),
        'title': value.title,
        'message': value.message,
        'action': NotificationActionToJSON(value.action),
    };
}

