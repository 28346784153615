/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Currency,
  LaundryGroupStatisticsFactType,
  PageableLaundryGroupStatisticsData,
} from '../models';
import {
    CurrencyFromJSON,
    CurrencyToJSON,
    LaundryGroupStatisticsFactTypeFromJSON,
    LaundryGroupStatisticsFactTypeToJSON,
    PageableLaundryGroupStatisticsDataFromJSON,
    PageableLaundryGroupStatisticsDataToJSON,
} from '../models';

export interface ReportWmStatisticsLaundrygroupsGetRequest {
    from: Date;
    to: Date;
    factType: Array<LaundryGroupStatisticsFactType>;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    id?: string;
    organizationId?: string;
    referenceFrom?: Date;
    referenceTo?: Date;
    currency?: Currency;
}

/**
 * 
 */
export class StatisticsWashMasterApi extends runtime.BaseAPI {

    /**
     * List laundry group statistics
     */
    async reportWmStatisticsLaundrygroupsGetRaw(requestParameters: ReportWmStatisticsLaundrygroupsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableLaundryGroupStatisticsData>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling reportWmStatisticsLaundrygroupsGet.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling reportWmStatisticsLaundrygroupsGet.');
        }

        if (requestParameters.factType === null || requestParameters.factType === undefined) {
            throw new runtime.RequiredError('factType','Required parameter requestParameters.factType was null or undefined when calling reportWmStatisticsLaundrygroupsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = this.toIsoStringLocalTimezone(requestParameters.from as any);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = this.toIsoStringLocalTimezone(requestParameters.to as any);
        }

        if (requestParameters.referenceFrom !== undefined) {
            queryParameters['referenceFrom'] = this.toIsoStringLocalTimezone(requestParameters.referenceFrom as any);
        }

        if (requestParameters.referenceTo !== undefined) {
            queryParameters['referenceTo'] = this.toIsoStringLocalTimezone(requestParameters.referenceTo as any);
        }

        if (requestParameters.factType) {
            queryParameters['factType'] = requestParameters.factType;
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/wm/statistics/laundrygroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableLaundryGroupStatisticsDataFromJSON(jsonValue));
    }

    /**
     * List laundry group statistics
     */
    async reportWmStatisticsLaundrygroupsGet(from: Date, to: Date, factType: Array<LaundryGroupStatisticsFactType>, size?: number, page?: number, sort?: string, search?: string, id?: string, organizationId?: string, referenceFrom?: Date, referenceTo?: Date, currency?: Currency, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableLaundryGroupStatisticsData> {
        const response = await this.reportWmStatisticsLaundrygroupsGetRaw({ from: from, to: to, factType: factType, size: size, page: page, sort: sort, search: search, id: id, organizationId: organizationId, referenceFrom: referenceFrom, referenceTo: referenceTo, currency: currency }, initOverrides);
        return await response.value();
    }

}
