/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConsumptionChartFactType {
    WATER = 'TOTAL_WATER',
    ENERGY = 'TOTAL_ENERGY'
}


export function ConsumptionChartFactTypeFromJSON(json: any): ConsumptionChartFactType {
    return ConsumptionChartFactTypeFromJSONTyped(json, false);
}

export function ConsumptionChartFactTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumptionChartFactType {
    return json as ConsumptionChartFactType;
}

export function ConsumptionChartFactTypeToJSON(value?: ConsumptionChartFactType | null): any {
    return value as any;
}

