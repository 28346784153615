import { Address } from 'src/service/backend/api'

export function getAddress(address?: Address): string {
  if (!address) {
    return ''
  }
  return address.addressLine1 + (address.addressLine2 ? address.addressLine2 : '')
}

export function getCity(address?: Address): string {
  if (!address) {
    return ''
  }
  return address.city || ''
}
