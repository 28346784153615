import React, { ReactElement, useEffect, useState } from 'react'
import { TableFilterPopup } from 'src/ui-shared/table/TableFilterPopup'
import { TableFilterTextFieldDefault } from 'src/ui-shared/table/TableFilterTextFieldDefault'

interface Props {
  filter: string
  title: string
  label: string
  tableSettings: any
  delay?: number
  setTableSettings: (value: any) => void
  isValid?: (value: string) => boolean
  errorText?: string
}

export const TableFilterTextField: React.FC<Props> = ({
  filter,
  title,
  label,
  tableSettings,
  delay,
  setTableSettings,
  isValid,
  errorText,
}: Props): ReactElement => {
  const tableSettingsValue = tableSettings[filter]

  // state
  const [timeoutState, setTimeoutState] = useState<NodeJS.Timeout | null>(null)
  const [freeTextValue, setFreeTextValue] = useState<string>(tableSettingsValue ? tableSettingsValue : '')
  const [valid, setValid] = useState<boolean>(true)

  useEffect(() => {
    if (tableSettingsValue) {
      setFreeTextValue(tableSettingsValue)
    }
  }, [tableSettings])

  // handle events
  const handleFreeInputChange = (event: any): void => {
    const value = event.target.value
    const textValue = value.toString()

    setFreeTextValue(textValue)

    if (timeoutState) {
      clearTimeout(timeoutState)
    }

    // validate the input if specified
    if (isValid !== undefined) {
      const valid = isValid(textValue)
      setValid(valid)

      if (!valid) {
        return
      }
    }

    // update table settings
    setTimeoutState(
      setTimeout(
        () => {
          setTableSettings({
            ...tableSettings,
            [filter]: textValue,
          })
        },
        delay ? delay : 300,
      ),
    )
  }

  return (
    <TableFilterPopup title={title} active={Boolean(tableSettings[filter])}>
      <TableFilterTextFieldDefault
        label={label}
        value={freeTextValue}
        onChange={handleFreeInputChange}
        error={!valid}
        helperText={!valid ? errorText : ''}
        style={{ width: 300 }}
      />
    </TableFilterPopup>
  )
}
