/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaundryGroupPriceListExternal } from './LaundryGroupPriceListExternal';
import {
    LaundryGroupPriceListExternalFromJSON,
    LaundryGroupPriceListExternalFromJSONTyped,
    LaundryGroupPriceListExternalToJSON,
} from './LaundryGroupPriceListExternal';
import type { LaundryGroupPriceListIntegrated } from './LaundryGroupPriceListIntegrated';
import {
    LaundryGroupPriceListIntegratedFromJSON,
    LaundryGroupPriceListIntegratedFromJSONTyped,
    LaundryGroupPriceListIntegratedToJSON,
} from './LaundryGroupPriceListIntegrated';
import type { PriceListType } from './PriceListType';
import {
    PriceListTypeFromJSON,
    PriceListTypeFromJSONTyped,
    PriceListTypeToJSON,
} from './PriceListType';

/**
 * 
 * @export
 * @interface LaundryGroupPriceList
 */
export interface LaundryGroupPriceList {
    /**
     * 
     * @type {PriceListType}
     * @memberof LaundryGroupPriceList
     */
    priceListType: PriceListType;
    /**
     * 
     * @type {LaundryGroupPriceListIntegrated}
     * @memberof LaundryGroupPriceList
     */
    integrated?: LaundryGroupPriceListIntegrated | null;
    /**
     * 
     * @type {LaundryGroupPriceListExternal}
     * @memberof LaundryGroupPriceList
     */
    external?: LaundryGroupPriceListExternal | null;
}

/**
 * Check if a given object implements the LaundryGroupPriceList interface.
 */
export function instanceOfLaundryGroupPriceList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "priceListType" in value;

    return isInstance;
}

export function LaundryGroupPriceListFromJSON(json: any): LaundryGroupPriceList {
    return LaundryGroupPriceListFromJSONTyped(json, false);
}

export function LaundryGroupPriceListFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupPriceList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priceListType': PriceListTypeFromJSON(json['priceListType']),
        'integrated': !exists(json, 'integrated') ? undefined : LaundryGroupPriceListIntegratedFromJSON(json['integrated']),
        'external': !exists(json, 'external') ? undefined : LaundryGroupPriceListExternalFromJSON(json['external']),
    };
}

export function LaundryGroupPriceListToJSON(value?: LaundryGroupPriceList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priceListType': PriceListTypeToJSON(value.priceListType),
        'integrated': LaundryGroupPriceListIntegratedToJSON(value.integrated),
        'external': LaundryGroupPriceListExternalToJSON(value.external),
    };
}

