import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Divider, Grid, Hidden, Paper } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, LaundryUser, LaundryUsersApi, Permission } from 'src/service/backend/api'
import { HttpResponseError } from 'src/service/backend/error/HttpResponseError'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { StyledTab } from 'src/ui-shared/base/tab-panel/StyledTab'
import { StyledTabs } from 'src/ui-shared/base/tab-panel/StyledTabs'
import { TabPanel } from 'src/ui-shared/base/tab-panel/TabPanel'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { AccountBalanceIcon, ArticleIcon, CalendarMonthIcon } from 'src/ui-shared/icons/Icons'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryUserCalendarTab } from 'src/ui/page/wm/laundry-user/details/LaundryUserCalendarTab'
import { LaundryUserDetailsTab } from 'src/ui/page/wm/laundry-user/details/LaundryUserDetailsTab'
import { LaundryUserOverviewTab } from 'src/ui/page/wm/laundry-user/details/LaundryUserOverviewTab'
import { LaundryUserPaymentTab } from 'src/ui/page/wm/laundry-user/details/LaundryUserPaymentTab'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const LaundryUserDetailsPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()

  const user = useUser()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const navigate = useNavigate()
  const location = useLocation()
  const { laundryUserId, tabNameParam } = useRequiredParams(['laundryUserId', 'tabNameParam'])
  const tabName = getEnumFromString(tabNameParam, LaundryUserDetailsTab)

  const state = location.state as NavigateState | undefined

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryUsersApi = new LaundryUsersApi(httpConfiguration)

  const [activeTab, setActiveTab] = useState<LaundryUserDetailsTab>(LaundryUserDetailsTab.OVERVIEW)
  const [laundryUser, setLaundryUser] = useState<LaundryUser | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false)
  const [browserHistoryBack, setBrowserHistoryBack] = useState<boolean>()

  // derived state
  const fullName = laundryUser ? `${laundryUser.firstName} ${laundryUser.lastName}` : ''
  const hasCalendarReadPermission = hasPermission(user, Permission.CALENDAR_ENTRY_READ)

  useEffect(() => {
    setBrowserHistoryBack(state?.browserHistoryBack)
  }, [])

  useEffect(() => {
    setErrorMessage(null)

    laundryUsersApi
      .laundryusersLaundryUserIdGet(laundryUserId)
      .then((laundryUser) => {
        setLaundryUser(laundryUser)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })

    setActiveTab(tabName)
  }, [laundryUserId])

  const handleTabChange = (event: React.SyntheticEvent, newValue: LaundryUserDetailsTab) => {
    const pathToNavigate = `/${appId}/laundry-users/${laundryUserId}/view/${newValue}`
    navigate(pathToNavigate, { replace: true })
    setActiveTab(newValue)
  }

  const navigateToEditPage = () => {
    navigate(`/${appId}/laundry-users/${laundryUserId}/edit`)
  }

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/laundry-users`)
  }

  const onRemoveHandler = (): void => {
    if (laundryUser) {
      laundryUsersApi
        .laundryusersLaundryUserIdDelete(laundryUserId)
        .then(() => {
          navigateBack()
        })
        .catch((err) => {
          if (err instanceof HttpResponseError) {
            const httpError: HttpResponseError = err
            const statusCode = httpError.statusCode
            if (statusCode === 428) {
              console.warn('Deleting Laundry user without IBAN is not allowed')
            }
          }
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
          setRemoveModal(false)
        })
    }
  }

  const onResetPasswordHandler = (): void => {
    if (!laundryUser) {
      return
    }

    laundryUsersApi
      .laundryusersLaundryUserIdPasswordResetPost(laundryUserId)
      .then(() => {
        showSnackbar(translate('resetPasswordSuccess'), 'success')
        setResetPasswordModal(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
        setResetPasswordModal(false)
      })
  }

  // render
  let laundryUserOverview = null
  let laundryUserCalendar = null
  let laundryUserPayment = null

  if (laundryUser) {
    const laundryUserMandatory = laundryUser!
    laundryUserOverview = (
      <LaundryUserOverviewTab
        navigateToEditPage={navigateToEditPage}
        removeModal={removeModal}
        setRemoveModal={setRemoveModal}
        onRemoveHandler={onRemoveHandler}
        laundryUser={laundryUserMandatory}
        resetPasswordModal={resetPasswordModal}
        setResetPasswordModal={setResetPasswordModal}
        onResetPasswordHandler={onResetPasswordHandler}
      />
    )
    laundryUserCalendar = <LaundryUserCalendarTab laundryUser={laundryUserMandatory} />
    laundryUserPayment = <LaundryUserPaymentTab setErrorMessage={setErrorMessage} laundryUser={laundryUserMandatory} />
  }

  return (
    <ScreenLayout title={fullName} onBack={navigateBack} actionsWidth={50}>
      <Paper elevation={0}>
        <Grid container className={sharedClasses.TabsContainer}>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <StyledTabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={handleTabChange}
            >
              <StyledTab
                icon={<ArticleIcon />}
                iconPosition={'start'}
                label={<Hidden mdDown>{translate('company.overview')}</Hidden>}
                disabled={false}
                value={LaundryUserDetailsTab.OVERVIEW}
              />
              {hasCalendarReadPermission ? (
                <StyledTab
                  icon={<CalendarMonthIcon />}
                  iconPosition={'start'}
                  label={<Hidden mdDown>{translate('laundryGroupCalendar')}</Hidden>}
                  disabled={false}
                  value={LaundryUserDetailsTab.CALENDAR}
                />
              ) : null}
              <StyledTab
                icon={<AccountBalanceIcon />}
                iconPosition={'start'}
                label={<Hidden mdDown>{translate('prepaidAccount')}</Hidden>}
                disabled={false}
                value={LaundryUserDetailsTab.ACCOUNT}
              />
            </StyledTabs>
          </Grid>
        </Grid>
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <>
            <TabPanel value={activeTab} index={LaundryUserDetailsTab.OVERVIEW}>
              {laundryUserOverview}
            </TabPanel>
            <TabPanel value={activeTab} index={LaundryUserDetailsTab.CALENDAR}>
              {laundryUserCalendar}
            </TabPanel>
            <TabPanel value={activeTab} index={LaundryUserDetailsTab.ACCOUNT}>
              {laundryUserPayment}
            </TabPanel>
          </>
        )}
      </Paper>
    </ScreenLayout>
  )
}
