/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MachineType,
  PageablePrograms,
  Program,
  ProgramCopyRequest,
  ProgramModuleTemplate,
  ProgramType,
  ProgramsEditableMachineType,
} from '../models';
import {
    MachineTypeFromJSON,
    MachineTypeToJSON,
    PageableProgramsFromJSON,
    PageableProgramsToJSON,
    ProgramFromJSON,
    ProgramToJSON,
    ProgramCopyRequestFromJSON,
    ProgramCopyRequestToJSON,
    ProgramModuleTemplateFromJSON,
    ProgramModuleTemplateToJSON,
    ProgramTypeFromJSON,
    ProgramTypeToJSON,
    ProgramsEditableMachineTypeFromJSON,
    ProgramsEditableMachineTypeToJSON,
} from '../models';

export interface ProgramsCopyPostRequest {
    organizationId: string;
    machineType: MachineType;
    programCopyRequest?: ProgramCopyRequest;
}

export interface ProgramsExportGetRequest {
    organizationId: string;
    machineType: MachineType;
    drumSize: number | null;
    programId?: Array<string>;
}

export interface ProgramsGetRequest {
    organizationId: string;
    machineType: MachineType;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    programType?: ProgramType;
    programMode?: string;
    programName?: string;
    temperature?: number;
}

export interface ProgramsImportPostRequest {
    organizationId?: string;
    machineType?: MachineType;
    filename?: Array<Blob>;
}

export interface ProgramsModuleTemplateGetRequest {
    organizationId: string;
    machineType: MachineType;
}

export interface ProgramsPostRequest {
    organizationId: string;
    program?: Program;
}

export interface ProgramsProgramIdDeleteRequest {
    programId: string;
    organizationId: string;
    machineType: MachineType;
}

export interface ProgramsProgramIdExportGetRequest {
    programId: string;
    organizationId: string;
    machineType: MachineType;
    drumSize: number | null;
}

export interface ProgramsProgramIdGetRequest {
    programId: string;
    organizationId: string;
    machineType: MachineType;
}

export interface ProgramsProgramIdPutRequest {
    programId: string;
    organizationId: string;
    machineType: MachineType;
    program?: Program;
}

export interface ProgramsTemplateGetRequest {
    organizationId: string;
    machineType: MachineType;
    programId?: string;
}

/**
 * 
 */
export class ProgramsApi extends runtime.BaseAPI {

    /**
     * Copy a Program to organization
     */
    async programsCopyPostRaw(requestParameters: ProgramsCopyPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling programsCopyPost.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling programsCopyPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.machineType !== undefined) {
            queryParameters['machineType'] = requestParameters.machineType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/programs/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProgramCopyRequestToJSON(requestParameters.programCopyRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Copy a Program to organization
     */
    async programsCopyPost(organizationId: string, machineType: MachineType, programCopyRequest?: ProgramCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.programsCopyPostRaw({ organizationId: organizationId, machineType: machineType, programCopyRequest: programCopyRequest }, initOverrides);
    }

    /**
     * Get the Machine Types where program can be customized
     */
    async programsEditableMachineTypesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProgramsEditableMachineType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/programs/editable-machine-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProgramsEditableMachineTypeFromJSON));
    }

    /**
     * Get the Machine Types where program can be customized
     */
    async programsEditableMachineTypesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProgramsEditableMachineType>> {
        const response = await this.programsEditableMachineTypesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Export a set of standard and/or custom programs for / machineType / drumSize into a ZIP file
     */
    async programsExportGetRaw(requestParameters: ProgramsExportGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling programsExportGet.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling programsExportGet.');
        }

        if (requestParameters.drumSize === null || requestParameters.drumSize === undefined) {
            throw new runtime.RequiredError('drumSize','Required parameter requestParameters.drumSize was null or undefined when calling programsExportGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.machineType !== undefined) {
            queryParameters['machineType'] = requestParameters.machineType;
        }

        if (requestParameters.programId) {
            queryParameters['programId'] = requestParameters.programId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.drumSize !== undefined) {
            queryParameters['drumSize'] = requestParameters.drumSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/programs/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export a set of standard and/or custom programs for / machineType / drumSize into a ZIP file
     */
    async programsExportGet(organizationId: string, machineType: MachineType, drumSize: number | null, programId?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.programsExportGetRaw({ organizationId: organizationId, machineType: machineType, drumSize: drumSize, programId: programId }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of standard and/or custom programs for machineType
     */
    async programsGetRaw(requestParameters: ProgramsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageablePrograms>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling programsGet.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling programsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.machineType !== undefined) {
            queryParameters['machineType'] = requestParameters.machineType;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.programType !== undefined) {
            queryParameters['programType'] = requestParameters.programType;
        }

        if (requestParameters.programMode !== undefined) {
            queryParameters['programMode'] = requestParameters.programMode;
        }

        if (requestParameters.programName !== undefined) {
            queryParameters['programName'] = requestParameters.programName;
        }

        if (requestParameters.temperature !== undefined) {
            queryParameters['temperature'] = requestParameters.temperature;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/programs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableProgramsFromJSON(jsonValue));
    }

    /**
     * Get a list of standard and/or custom programs for machineType
     */
    async programsGet(organizationId: string, machineType: MachineType, size?: number, page?: number, sort?: string, search?: string, programType?: ProgramType, programMode?: string, programName?: string, temperature?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageablePrograms> {
        const response = await this.programsGetRaw({ organizationId: organizationId, machineType: machineType, size: size, page: page, sort: sort, search: search, programType: programType, programMode: programMode, programName: programName, temperature: temperature }, initOverrides);
        return await response.value();
    }

    /**
     * Import a set of standard and/or custom programs for machineType
     */
    async programsImportPostRaw(requestParameters: ProgramsImportPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageablePrograms>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.organizationId !== undefined) {
            formParams.append('organizationId', requestParameters.organizationId as any);
        }

        if (requestParameters.machineType !== undefined) {
            formParams.append('machineType', requestParameters.machineType as any);
        }

        if (requestParameters.filename) {
            requestParameters.filename.forEach((element) => {
                formParams.append('filename', element as any);
            })
        }

        const response = await this.request({
            path: `/programs/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableProgramsFromJSON(jsonValue));
    }

    /**
     * Import a set of standard and/or custom programs for machineType
     */
    async programsImportPost(organizationId?: string, machineType?: MachineType, filename?: Array<Blob>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageablePrograms> {
        const response = await this.programsImportPostRaw({ organizationId: organizationId, machineType: machineType, filename: filename }, initOverrides);
        return await response.value();
    }

    /**
     * Used for creating new program. Returns modules and module templates and for the specific machine type with the definition of the available modules and program settings.
     */
    async programsModuleTemplateGetRaw(requestParameters: ProgramsModuleTemplateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramModuleTemplate>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling programsModuleTemplateGet.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling programsModuleTemplateGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.machineType !== undefined) {
            queryParameters['machineType'] = requestParameters.machineType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/programs/module-template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramModuleTemplateFromJSON(jsonValue));
    }

    /**
     * Used for creating new program. Returns modules and module templates and for the specific machine type with the definition of the available modules and program settings.
     */
    async programsModuleTemplateGet(organizationId: string, machineType: MachineType, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramModuleTemplate> {
        const response = await this.programsModuleTemplateGetRaw({ organizationId: organizationId, machineType: machineType }, initOverrides);
        return await response.value();
    }

    /**
     * Create a Program
     */
    async programsPostRaw(requestParameters: ProgramsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Program>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling programsPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/programs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProgramToJSON(requestParameters.program),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramFromJSON(jsonValue));
    }

    /**
     * Create a Program
     */
    async programsPost(organizationId: string, program?: Program, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Program> {
        const response = await this.programsPostRaw({ organizationId: organizationId, program: program }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a program or reset to default
     */
    async programsProgramIdDeleteRaw(requestParameters: ProgramsProgramIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.programId === null || requestParameters.programId === undefined) {
            throw new runtime.RequiredError('programId','Required parameter requestParameters.programId was null or undefined when calling programsProgramIdDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling programsProgramIdDelete.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling programsProgramIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.machineType !== undefined) {
            queryParameters['machineType'] = requestParameters.machineType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/programs/{programId}`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters.programId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a program or reset to default
     */
    async programsProgramIdDelete(programId: string, organizationId: string, machineType: MachineType, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.programsProgramIdDeleteRaw({ programId: programId, organizationId: organizationId, machineType: machineType }, initOverrides);
    }

    /**
     * Export single program into binary format for specific drumSize
     */
    async programsProgramIdExportGetRaw(requestParameters: ProgramsProgramIdExportGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.programId === null || requestParameters.programId === undefined) {
            throw new runtime.RequiredError('programId','Required parameter requestParameters.programId was null or undefined when calling programsProgramIdExportGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling programsProgramIdExportGet.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling programsProgramIdExportGet.');
        }

        if (requestParameters.drumSize === null || requestParameters.drumSize === undefined) {
            throw new runtime.RequiredError('drumSize','Required parameter requestParameters.drumSize was null or undefined when calling programsProgramIdExportGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.machineType !== undefined) {
            queryParameters['machineType'] = requestParameters.machineType;
        }

        if (requestParameters.drumSize !== undefined) {
            queryParameters['drumSize'] = requestParameters.drumSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/programs/{programId}/export`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters.programId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export single program into binary format for specific drumSize
     */
    async programsProgramIdExportGet(programId: string, organizationId: string, machineType: MachineType, drumSize: number | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.programsProgramIdExportGetRaw({ programId: programId, organizationId: organizationId, machineType: machineType, drumSize: drumSize }, initOverrides);
        return await response.value();
    }

    /**
     * Get a standard/custom program info
     */
    async programsProgramIdGetRaw(requestParameters: ProgramsProgramIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Program>> {
        if (requestParameters.programId === null || requestParameters.programId === undefined) {
            throw new runtime.RequiredError('programId','Required parameter requestParameters.programId was null or undefined when calling programsProgramIdGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling programsProgramIdGet.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling programsProgramIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.machineType !== undefined) {
            queryParameters['machineType'] = requestParameters.machineType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/programs/{programId}`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters.programId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramFromJSON(jsonValue));
    }

    /**
     * Get a standard/custom program info
     */
    async programsProgramIdGet(programId: string, organizationId: string, machineType: MachineType, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Program> {
        const response = await this.programsProgramIdGetRaw({ programId: programId, organizationId: organizationId, machineType: machineType }, initOverrides);
        return await response.value();
    }

    /**
     * Update program settings for a program for a specific machineType
     */
    async programsProgramIdPutRaw(requestParameters: ProgramsProgramIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Program>> {
        if (requestParameters.programId === null || requestParameters.programId === undefined) {
            throw new runtime.RequiredError('programId','Required parameter requestParameters.programId was null or undefined when calling programsProgramIdPut.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling programsProgramIdPut.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling programsProgramIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.machineType !== undefined) {
            queryParameters['machineType'] = requestParameters.machineType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/programs/{programId}`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters.programId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProgramToJSON(requestParameters.program),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramFromJSON(jsonValue));
    }

    /**
     * Update program settings for a program for a specific machineType
     */
    async programsProgramIdPut(programId: string, organizationId: string, machineType: MachineType, program?: Program, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Program> {
        const response = await this.programsProgramIdPutRaw({ programId: programId, organizationId: organizationId, machineType: machineType, program: program }, initOverrides);
        return await response.value();
    }

    /**
     * Used for creating new program or copying existing program into new program. Returns a template program for the specific machine type with the definition of the available modules and program settings.
     */
    async programsTemplateGetRaw(requestParameters: ProgramsTemplateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Program>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling programsTemplateGet.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling programsTemplateGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.machineType !== undefined) {
            queryParameters['machineType'] = requestParameters.machineType;
        }

        if (requestParameters.programId !== undefined) {
            queryParameters['programId'] = requestParameters.programId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/programs/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProgramFromJSON(jsonValue));
    }

    /**
     * Used for creating new program or copying existing program into new program. Returns a template program for the specific machine type with the definition of the available modules and program settings.
     */
    async programsTemplateGet(organizationId: string, machineType: MachineType, programId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Program> {
        const response = await this.programsTemplateGetRaw({ organizationId: organizationId, machineType: machineType, programId: programId }, initOverrides);
        return await response.value();
    }

}
