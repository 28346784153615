import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Paper, TableCell, TableRow } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { Configuration, MembersApi, OrganizationsApi, Role, RolesApi } from 'src/service/backend/api'
import { Member, OrganizationReference } from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { Data, OrderBy } from 'src/service/view-model/base/Data'
import { getMemberRolesName } from 'src/service/view-model/member/MemberViewModel'
import { EMPTY_ORGANIZATION_REF, getOrganizationTypesByUser } from 'src/service/view-model/organization/Organizations'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettingsWithOrganization,
  getDefaultTableSettings,
  tableSettingsSort,
  withOrganization,
} from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { TableFilterAutocompleteOrganization } from 'src/ui-shared/table/TableFilterAutocompleteOrganization'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'

const getHeadCells = (isServicer: boolean) => {
  const cells: HeadCells[] = [
    {
      id: 'backendUser.email',
      label: 'email',
    },
    {
      id: 'backendUser.firstName',
      label: 'user.firstName',
    },
    {
      id: 'backendUser.lastName',
      label: 'user.lastName',
    },
    {
      id: 'organizationId',
      label: isServicer ? 'servicePartner' : 'organization',
    },
    {
      id: 'backendRoles',
      label: 'user.role',
      noSort: true,
    },
  ]
  return cells
}

interface MemberTableSettings extends TableSettingsWithOrganization {
  orderBy: OrderBy<string>
  backendRole?: string
}

interface Props {
  navigateToItem: (itemId?: string) => void
  isServicer?: boolean
}

export const MemberTable: React.FC<Props> = ({ navigateToItem, isServicer = false }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  const location = useLocation()
  const translate = useTranslate()
  const activeOrganization = useActiveOrganization()

  const headCells = getHeadCells(isServicer)

  const httpConfiguration: Configuration = useContext(HttpContext)
  const membersApi = new MembersApi(httpConfiguration)
  const organizationApi = new OrganizationsApi(httpConfiguration)
  const rolesApi = new RolesApi(httpConfiguration)

  const [data, setData] = useState<TableData<Member>>(DEFAULT_DATA)
  const defaultTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'backendUser.email',
  }
  const [tableSettings, setTableSettings] = useState<MemberTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )
  const [organizations, setOrganizations] = useState<OrganizationReference[]>([])
  const [roles, setRoles] = useState<Role[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)

  const organizationTypeFilter = getOrganizationTypesByUser(isServicer)

  // load data for filters
  useEffect(() => {
    organizationApi.organizationsRefGet(undefined, undefined, organizationTypeFilter).then((data) => {
      data.unshift(EMPTY_ORGANIZATION_REF)
      setOrganizations(data)
    })

    rolesApi.rolesGet(organizationTypeFilter).then((data) => {
      const roles = data.map(({ role }) => role)
      setRoles(roles)
    })
  }, [])

  // load data on state change
  useEffect(() => {
    let active = true
    setLoading(true)

    membersApi
      .membersGet(
        tableSettings.size,
        tableSettings.page,
        tableSettingsSort(tableSettings),
        tableSettings.search,
        undefined,
        tableSettings.organizationId,
        organizationTypeFilter,
        tableSettings.backendRole,
      )
      .then((data) => {
        if (active) {
          updateTableSettingsFromData(data as Data<any>, tableSettings)
          setTableSettings(tableSettings)

          setData(mapData(data as Data<any>))
          setErrorMessage(null)
          setLoading(false)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })
    return () => {
      active = false
    }
  }, [tableSettings])

  // JSX
  const nonEmptyRows = data.data.map((item) => {
    return (
      <TableRow className={tableClasses.tableRow} key={item.id} onClick={navigateToItem.bind(this, item.id)}>
        <TableCell>{item.backendUser.email}</TableCell>
        <TableCell>{item.backendUser.firstName}</TableCell>
        <TableCell>{item.backendUser.lastName}</TableCell>
        <TableCell>{item.organization.name}</TableCell>
        <TableCell>{getMemberRolesName(item)}</TableCell>
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string) => {
    let filter

    if (headCellId === 'organizationId') {
      filter = (
        <TableFilterAutocompleteOrganization
          options={organizations}
          label={translate(isServicer ? 'servicePartner' : 'organization')}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'backendRoles') {
      filter = (
        <TableFilterAutocomplete
          title={translate('user.role')}
          label={translate('user.role')}
          filter={'backendRole'}
          labelFieldName="name"
          valueFieldName="roleKey"
          options={roles}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }
    return filter
  }

  return (
    <Paper elevation={0}>
      <Box mt={2} mb={2}>
        <TableSearchForm handleSearch={handleSearch} inputSearchValue={inputSearchValue} />
      </Box>

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        getFilter={getFilter}
        loading={loading}
        translate={translate}
      />
    </Paper>
  )
}
