/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineData } from './MachineData';
import {
    MachineDataFromJSON,
    MachineDataFromJSONTyped,
    MachineDataToJSON,
} from './MachineData';
import type { ManufacturerReference } from './ManufacturerReference';
import {
    ManufacturerReferenceFromJSON,
    ManufacturerReferenceFromJSONTyped,
    ManufacturerReferenceToJSON,
} from './ManufacturerReference';

/**
 * 
 * @export
 * @interface ManufacturerModel
 */
export interface ManufacturerModel {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerModel
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerModel
     */
    productNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerModel
     */
    modelName: string;
    /**
     * 
     * @type {ManufacturerReference}
     * @memberof ManufacturerModel
     */
    manufacturer: ManufacturerReference;
    /**
     * 
     * @type {Array<MachineData>}
     * @memberof ManufacturerModel
     */
    readonly machineData?: Array<MachineData>;
    /**
     * When editable is true, the entry can be updated. If false the Data is imported and not changeable
     * @type {boolean}
     * @memberof ManufacturerModel
     */
    readonly editable?: boolean;
}

/**
 * Check if a given object implements the ManufacturerModel interface.
 */
export function instanceOfManufacturerModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "productNumber" in value;
    isInstance = isInstance && "modelName" in value;
    isInstance = isInstance && "manufacturer" in value;

    return isInstance;
}

export function ManufacturerModelFromJSON(json: any): ManufacturerModel {
    return ManufacturerModelFromJSONTyped(json, false);
}

export function ManufacturerModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManufacturerModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'productNumber': json['productNumber'],
        'modelName': json['modelName'],
        'manufacturer': ManufacturerReferenceFromJSON(json['manufacturer']),
        'machineData': !exists(json, 'machineData') ? undefined : ((json['machineData'] as Array<any>).map(MachineDataFromJSON)),
        'editable': !exists(json, 'editable') ? undefined : json['editable'],
    };
}

export function ManufacturerModelToJSON(value?: ManufacturerModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productNumber': value.productNumber,
        'modelName': value.modelName,
        'manufacturer': ManufacturerReferenceToJSON(value.manufacturer),
    };
}

