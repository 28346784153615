/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChartData,
  ChartScale,
  ChartType,
  ConsumptionChartFactType,
  ConsumptionChartGroupBy,
  ConsumptionCostChartFactType,
  MachineCategory,
} from '../models';
import {
    ChartDataFromJSON,
    ChartDataToJSON,
    ChartScaleFromJSON,
    ChartScaleToJSON,
    ChartTypeFromJSON,
    ChartTypeToJSON,
    ConsumptionChartFactTypeFromJSON,
    ConsumptionChartFactTypeToJSON,
    ConsumptionChartGroupByFromJSON,
    ConsumptionChartGroupByToJSON,
    ConsumptionCostChartFactTypeFromJSON,
    ConsumptionCostChartFactTypeToJSON,
    MachineCategoryFromJSON,
    MachineCategoryToJSON,
} from '../models';

export interface ReportSmConsumptionCostsChartGetRequest {
    groupBy: ConsumptionChartGroupBy;
    factType: ConsumptionCostChartFactType;
    scale: ChartScale;
    startTimeFrom: Date;
    startTimeTo: Date;
    chartType?: ChartType;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
    machineCategory?: MachineCategory;
}

export interface ReportSmConsumptionsChartGetRequest {
    groupBy: ConsumptionChartGroupBy;
    factType: ConsumptionChartFactType;
    scale: ChartScale;
    startTimeFrom: Date;
    startTimeTo: Date;
    chartType?: ChartType;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
    machineCategory?: MachineCategory;
}

/**
 * 
 */
export class ReportConsumptionsApi extends runtime.BaseAPI {

    /**
     * List Charting data for consumption-cost
     */
    async reportSmConsumptionCostsChartGetRaw(requestParameters: ReportSmConsumptionCostsChartGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChartData>> {
        if (requestParameters.groupBy === null || requestParameters.groupBy === undefined) {
            throw new runtime.RequiredError('groupBy','Required parameter requestParameters.groupBy was null or undefined when calling reportSmConsumptionCostsChartGet.');
        }

        if (requestParameters.factType === null || requestParameters.factType === undefined) {
            throw new runtime.RequiredError('factType','Required parameter requestParameters.factType was null or undefined when calling reportSmConsumptionCostsChartGet.');
        }

        if (requestParameters.scale === null || requestParameters.scale === undefined) {
            throw new runtime.RequiredError('scale','Required parameter requestParameters.scale was null or undefined when calling reportSmConsumptionCostsChartGet.');
        }

        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportSmConsumptionCostsChartGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportSmConsumptionCostsChartGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        if (requestParameters.factType !== undefined) {
            queryParameters['factType'] = requestParameters.factType;
        }

        if (requestParameters.scale !== undefined) {
            queryParameters['scale'] = requestParameters.scale;
        }

        if (requestParameters.chartType !== undefined) {
            queryParameters['chartType'] = requestParameters.chartType;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        if (requestParameters.machineCategory !== undefined) {
            queryParameters['machineCategory'] = requestParameters.machineCategory;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/consumption-costs/chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChartDataFromJSON(jsonValue));
    }

    /**
     * List Charting data for consumption-cost
     */
    async reportSmConsumptionCostsChartGet(groupBy: ConsumptionChartGroupBy, factType: ConsumptionCostChartFactType, scale: ChartScale, startTimeFrom: Date, startTimeTo: Date, chartType?: ChartType, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, machineCategory?: MachineCategory, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChartData> {
        const response = await this.reportSmConsumptionCostsChartGetRaw({ groupBy: groupBy, factType: factType, scale: scale, startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, chartType: chartType, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId, machineCategory: machineCategory }, initOverrides);
        return await response.value();
    }

    /**
     * List Charting data for consumption
     */
    async reportSmConsumptionsChartGetRaw(requestParameters: ReportSmConsumptionsChartGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChartData>> {
        if (requestParameters.groupBy === null || requestParameters.groupBy === undefined) {
            throw new runtime.RequiredError('groupBy','Required parameter requestParameters.groupBy was null or undefined when calling reportSmConsumptionsChartGet.');
        }

        if (requestParameters.factType === null || requestParameters.factType === undefined) {
            throw new runtime.RequiredError('factType','Required parameter requestParameters.factType was null or undefined when calling reportSmConsumptionsChartGet.');
        }

        if (requestParameters.scale === null || requestParameters.scale === undefined) {
            throw new runtime.RequiredError('scale','Required parameter requestParameters.scale was null or undefined when calling reportSmConsumptionsChartGet.');
        }

        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportSmConsumptionsChartGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportSmConsumptionsChartGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        if (requestParameters.factType !== undefined) {
            queryParameters['factType'] = requestParameters.factType;
        }

        if (requestParameters.scale !== undefined) {
            queryParameters['scale'] = requestParameters.scale;
        }

        if (requestParameters.chartType !== undefined) {
            queryParameters['chartType'] = requestParameters.chartType;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        if (requestParameters.machineCategory !== undefined) {
            queryParameters['machineCategory'] = requestParameters.machineCategory;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/consumptions/chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChartDataFromJSON(jsonValue));
    }

    /**
     * List Charting data for consumption
     */
    async reportSmConsumptionsChartGet(groupBy: ConsumptionChartGroupBy, factType: ConsumptionChartFactType, scale: ChartScale, startTimeFrom: Date, startTimeTo: Date, chartType?: ChartType, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, machineCategory?: MachineCategory, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChartData> {
        const response = await this.reportSmConsumptionsChartGetRaw({ groupBy: groupBy, factType: factType, scale: scale, startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, chartType: chartType, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId, machineCategory: machineCategory }, initOverrides);
        return await response.value();
    }

}
