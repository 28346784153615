import React, { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router'
import { ListItemIcon, MenuItem } from '@mui/material'
import { Add } from '@mui/icons-material'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { MenuButton } from 'src/ui-shared/base/button/MenuButton'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'
import { MachineTable } from 'src/ui/page/common/machine/list/MachineTable'
import { MachinePair } from 'src/ui/page/common/machine/pair-machine/MachinePair'

export const MachineListPage = (): ReactElement => {
  const translate = useTranslate()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const appId = useAppId()

  const navigate = useNavigate()

  const handleRowClick = (machineId: string) => {
    navigate(`/${appId}/machines/${machineId}/view/${MachineTab.OVERVIEW}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handlePairButtonClick = () => {
    setOpenModal(true)
  }

  const handleCreateUnconnectedMachine = () => {
    navigate(`/${appId}/machines/create`)
  }

  return (
    <>
      <ScreenLayout
        title={translate('machines')}
        actions={
          <>
            <ListingButton onClick={handlePairButtonClick} variant="outlined" color="primary" startIcon={<Add />}>
              {translate('pairMachine')}
            </ListingButton>
            {appId === AppId.SERVICE_MASTER && (
              <MenuButton>
                <MenuItem onClick={handleCreateUnconnectedMachine}>
                  <ListItemIcon>
                    <Add fontSize="small" />
                  </ListItemIcon>{' '}
                  {translate('createUnconnectedAsset')}
                </MenuItem>
              </MenuButton>
            )}
          </>
        }
      >
        <MachineTable navigateToItem={handleRowClick}></MachineTable>
      </ScreenLayout>
      <ModalDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={translate('pairMachineModalTitle')}
        maxWidth="sm"
      >
        <MachinePair setOpenModal={setOpenModal} />
      </ModalDialog>
    </>
  )
}
