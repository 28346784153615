import React, { ReactElement } from 'react'
import { Chip, useTheme } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { UtilizationMachineStateType } from 'src/service/backend/api/models'

interface Props {
  status?: UtilizationMachineStateType
}

export const MachineStatusChip: React.FC<Props> = ({ status }): ReactElement => {
  const theme = useTheme()
  const translate = useTranslate()

  if (!status) {
    return <></>
  }

  let labelKey = status.toString()
  let bgColor = '#C7C9CC'
  let fgColor = '000'
  if (status === UtilizationMachineStateType.OFFLINE) {
    labelKey = 'offline'
    bgColor = '#E2E2E2'
  } else if (status === UtilizationMachineStateType.IDLE) {
    labelKey = 'IDLE'
    bgColor = '#7A7C7F'
    fgColor = '#fff'
  } else if (status === UtilizationMachineStateType.RUNNING) {
    bgColor = theme.palette.primary.main
    labelKey = 'RUNNING'
    fgColor = '#fff'
  } else if (status === UtilizationMachineStateType.CRINKLE_CARE) {
    labelKey = 'CRINKLE_CARE'
    bgColor = '#9B91C1'
  } else if (status === UtilizationMachineStateType.PROGRAM_PAUSED) {
    labelKey = 'PROGRAM_PAUSED'
    bgColor = '#D6D1E4'
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (status === UtilizationMachineStateType.FATAL_ERROR) {
    labelKey = 'FATAL_ERROR'
    bgColor = '#CE0F69'
    fgColor = '#fff'
  }

  return <Chip label={translate(labelKey)} sx={{ backgroundColor: bgColor, color: fgColor, minWidth: '60px' }} />
}
