import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Box, Divider, Grid, Paper } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  Machine,
  MachineData,
  MachinesApi,
  ManufacturerModel,
  ManufacturerModelsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getMachineTypeName } from 'src/service/view-model/machine/Machines'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'

export const MachineProductDataPage = (): ReactElement => {
  const navigate = useNavigate()
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()
  const appId = useAppId()

  const { machineId } = useParams()
  const { manufacturerModelId } = useRequiredParams(['manufacturerModelId'])

  const httpConfiguration: Configuration = useContext(HttpContext)
  const machinesApi = new MachinesApi(httpConfiguration)
  const manufacturerModelsApi = new ManufacturerModelsApi(httpConfiguration)

  const [loading, setLoading] = useState<boolean>(true)
  const [machine, setMachine] = useState<Machine | null>(null)
  const [machineData, setMachineData] = useState<Array<MachineData>>([])
  const [manufacturerModel, setManufacturerModel] = useState<ManufacturerModel | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useHotKeysForm()

  // load data
  useEffect(() => {
    if (machineId) {
      machinesApi
        .machinesMachineIdGet(machineId, [])
        .then((machine) => {
          setMachine(machine)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
        })
    }

    manufacturerModelsApi
      .manufacturerModelsManufacturerModelIdGet(manufacturerModelId)
      .then((manufacturerModel) => {
        setManufacturerModel(manufacturerModel)
        if (manufacturerModel.machineData) {
          setMachineData(manufacturerModel.machineData)
        } else {
          setMachineData([])
        }
        setErrorMessage(null)
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLoading(false)
      })
  }, [machineId])

  // handle events
  const navigateBack = () => {
    if (machineId) {
      navigate(`/${appId}/machines/${machineId}/view/${MachineTab.OVERVIEW}`)
    } else {
      navigate(`/${appId}/machines`)
    }
  }

  const nonEmptyRows = machineData.map((item) => {
    return (
      <Grid item {...ITEM_BREAKPOINTS} key={item.label}>
        <DetailsTextField value={item.value} label={item.label} />
      </Grid>
    )
  })

  // render
  const renderTitle = (): string => {
    const title = ''
    if (manufacturerModel) {
      return `Product: ${manufacturerModel.manufacturer.name} ${manufacturerModel.modelName} ${manufacturerModel.productNumber}`
    }
    return title
  }

  return (
    <ScreenLayout title={renderTitle()} onBack={navigateBack} actionsWidth={50}>
      <Paper elevation={0}>
        <LoadingIndicator loading={loading} />
        <Divider />
        <Box pt={2}>
          {errorMessage ? (
            <ErrorMessage message={errorMessage} />
          ) : (
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              {machine ? (
                <>
                  <Grid item {...ITEM_BREAKPOINTS}>
                    <DetailsTextField value={machine.name} label={translate('machineName')} />
                  </Grid>
                  <Grid item {...ITEM_BREAKPOINTS}>
                    <DetailsTextField
                      value={getMachineTypeName(machine.type, translate)}
                      label={translate('member.machinetype')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {nonEmptyRows}
            </Grid>
          )}
        </Box>
      </Paper>
    </ScreenLayout>
  )
}
