import { useParams } from 'react-router'

export const useRequiredParams = (requiredParams: string[]): Readonly<Record<string, string>> => {
  const params = useParams()
  const paramKeys = Object.keys(params)

  if (requiredParams.length === 0) {
    throw new Error(`Required params must be passed in order to validate values`)
  }

  requiredParams.forEach((requiredParam) => {
    // invalid string passed as required param
    if (!paramKeys.includes(requiredParam)) {
      throw new Error(`RequiredParam: ${requiredParam} does not exist as param for this route`)
    }

    const requiredParamValue = params[requiredParam]

    // required param has value undefined
    if (!requiredParamValue) {
      throw new Error(`Required param: ${requiredParam} should not be: ${requiredParamValue}`)
    }
  })

  return params as Record<string, string>
}
