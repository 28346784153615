import React, { ReactElement } from 'react'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

export const NotFound = (): ReactElement => {
  const translate = useTranslate()
  return (
    <ScreenLayout title={'404'}>
      <ErrorMessage message={translate('error-page-not-found')} />
    </ScreenLayout>
  )
}
