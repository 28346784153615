import React from 'react'
import { Icon } from '@mdi/react'
import { GroupOutlined, HelpOutline, HomeWorkOutlined } from '@mui/icons-material'
import { mdiFinance } from '@mdi/js'
import { ReactComponent as WashmasterIcon } from 'src/assets/logo-washmaster-icon.svg'
import { ReactComponent as WasherIconWm } from 'src/assets/washer-wm.svg'
import { SlideItem } from 'src/ui/layout/onboarding-slideshow/OnboardingSlideshow'

export const SLIDES_WM: SlideItem[] = [
  {
    backgroundImage: 'slide1-background.png',
    title: 'welcomeToWashMaster',
    body: 'onboardingBodySlide1Wm',
    slideImage: <WashmasterIcon />,
  },
  {
    backgroundImage: 'slide2-background.png',
    title: 'laundryGroups',
    body: 'onboardingBodySlide2Wm',
    slideImage: <HomeWorkOutlined />,
  },
  {
    backgroundImage: 'slide3-background.png',
    title: 'laundriesAndMachines',
    body: 'onboardingBodySlide3Wm',
    slideImage: <WasherIconWm />,
  },
  {
    backgroundImage: 'slide4-background.png',
    title: 'laundryUsers',
    body: 'onboardingBodySlide4Wm',
    slideImage: <GroupOutlined />,
  },
  {
    backgroundImage: 'slide5-background.png',
    title: 'reports',
    body: 'onboardingBodySlide5Wm',
    slideImage: <Icon path={mdiFinance} color={'primary'} />,
  },
  {
    backgroundImage: 'slide1-background.png',
    title: 'helpCenter',
    body: 'onboardingBodySlide6Wm',
    slideImage: <HelpOutline color={'primary'} />,
  },
]
