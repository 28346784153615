/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettingNumberField
 */
export interface SettingNumberField {
    /**
     * 
     * @type {number}
     * @memberof SettingNumberField
     */
    readonly activeData?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingNumberField
     */
    data?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingNumberField
     */
    readonly factoryDefaultData?: number;
    /**
     * if data is a float/double/bigdecimal this will be true, if integer this will be false
     * @type {boolean}
     * @memberof SettingNumberField
     */
    isDecimal?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SettingNumberField
     */
    decimalPlaces?: number;
}

/**
 * Check if a given object implements the SettingNumberField interface.
 */
export function instanceOfSettingNumberField(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SettingNumberFieldFromJSON(json: any): SettingNumberField {
    return SettingNumberFieldFromJSONTyped(json, false);
}

export function SettingNumberFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingNumberField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeData': !exists(json, 'activeData') ? undefined : json['activeData'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'factoryDefaultData': !exists(json, 'factoryDefaultData') ? undefined : json['factoryDefaultData'],
        'isDecimal': !exists(json, 'isDecimal') ? undefined : json['isDecimal'],
        'decimalPlaces': !exists(json, 'decimalPlaces') ? undefined : json['decimalPlaces'],
    };
}

export function SettingNumberFieldToJSON(value?: SettingNumberField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'isDecimal': value.isDecimal,
        'decimalPlaces': value.decimalPlaces,
    };
}

