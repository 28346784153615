import React, { ReactElement } from 'react'
import { Chip } from '@mui/material'
import { AppId } from 'src/app/AppId'
import { TranslateFunction } from 'src/i18n/useMessageSource'
import { LaundryGroup, PaymentMethod, ServicePackagePlan } from 'src/service/backend/api'
import { getTextLength } from 'src/service/utils/TextUtils'
import { TooltipWrapper } from 'src/ui-shared/base/button/Buttons'

export const displayLaundryGroupFeatures = (
  item: LaundryGroup,
  appId: AppId,
  translate: TranslateFunction,
): ReactElement[] => {
  const washmaster = item.washmaster
  const servicemaster = item.servicemaster
  const featuresChip: string[] = []

  // activated features separate for WM and SM (old features)
  if (appId === AppId.WASH_MASTER && washmaster) {
    if (washmaster.publicLaundryGroup) {
      featuresChip.push('tag.publicLaundryGroup')
    }

    if (
      washmaster.enabledPaymentMethod === PaymentMethod.PREPAID ||
      washmaster.enabledPaymentMethod === PaymentMethod.DIRECTPAY
    ) {
      featuresChip.push('tag.' + washmaster.enabledPaymentMethod.toLowerCase())
    }
  }
  if (appId === AppId.SERVICE_MASTER && servicemaster) {
    if (servicemaster.directCareEnabled) {
      featuresChip.push('tag.directCare')
    }

    if (servicemaster.customProgramsEnabled) {
      featuresChip.push('tag.customPrograms')
    }

    if (servicemaster.usageReportsEnabled) {
      featuresChip.push('tag.usage')
    }

    if (servicemaster.utilizationReportsEnabled) {
      featuresChip.push('tag.utilization')
    }

    if (servicemaster.hygieneMonitoringEnabled) {
      featuresChip.push('tag.hygiene')
    }
  }

  return featuresChip.map((value) => {
    const name = translate(value)
    const showTooltip = getTextLength(name) > 10

    return (
      <TooltipWrapper showTooltip={showTooltip} title={name} key={value}>
        <Chip sx={{ margin: '5px', maxWidth: '80px' }} label={name} size={'small'} />
      </TooltipWrapper>
    )
  })
}

export const displayServicePackagePlan = (appId: AppId, servicePackagePlan?: ServicePackagePlan): ReactElement => {
  let packagePlanChip = <></>

  // tag for service package plan only WM
  if (appId === AppId.WASH_MASTER && servicePackagePlan) {
    const planColor = servicePackagePlan.colors
    const backgroundColor = planColor?.bgColor ? `#${planColor.bgColor}` : undefined
    const fontColor = planColor?.fgColor ? `#${planColor.fgColor}` : undefined
    const planName = servicePackagePlan.name
    const showTooltip = getTextLength(planName) > 15

    packagePlanChip = (
      <TooltipWrapper showTooltip={showTooltip} title={planName}>
        <Chip
          sx={{ backgroundColor: backgroundColor, color: fontColor, maxWidth: '130px' }}
          size={'small'}
          key={servicePackagePlan.id}
          label={planName}
        />
      </TooltipWrapper>
    )
  }
  return packagePlanChip
}
