/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChartData,
  ChartScale,
  ChartType,
  LaundryGroupReference,
  LaundryReferenceSimple,
  MachineCategory,
  MachineReference,
  OrganizationReference,
  PageableSMUsages,
  UtilizationChartFactType,
  UtilizationChartGroupBy,
} from '../models';
import {
    ChartDataFromJSON,
    ChartDataToJSON,
    ChartScaleFromJSON,
    ChartScaleToJSON,
    ChartTypeFromJSON,
    ChartTypeToJSON,
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceToJSON,
    LaundryReferenceSimpleFromJSON,
    LaundryReferenceSimpleToJSON,
    MachineCategoryFromJSON,
    MachineCategoryToJSON,
    MachineReferenceFromJSON,
    MachineReferenceToJSON,
    OrganizationReferenceFromJSON,
    OrganizationReferenceToJSON,
    PageableSMUsagesFromJSON,
    PageableSMUsagesToJSON,
    UtilizationChartFactTypeFromJSON,
    UtilizationChartFactTypeToJSON,
    UtilizationChartGroupByFromJSON,
    UtilizationChartGroupByToJSON,
} from '../models';

export interface ReportSmUsagesChartGetRequest {
    groupBy: UtilizationChartGroupBy;
    factType: UtilizationChartFactType;
    scale: ChartScale;
    startTimeFrom: Date;
    startTimeTo: Date;
    chartType?: ChartType;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
    machineCategory?: MachineCategory;
    programMode?: string;
    programName?: string;
    temperature?: number;
}

export interface ReportSmUsagesCsvGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
    machineCategory?: MachineCategory;
    programMode?: string;
    programName?: string;
    temperature?: number;
}

export interface ReportSmUsagesGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    size?: number;
    page?: number;
    sort?: string;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
    machineCategory?: MachineCategory;
    programMode?: string;
    programName?: string;
    temperature?: number;
}

export interface ReportSmUsagesLaundriesRefGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    search?: string;
    laundryGroupId?: Array<string>;
    organizationId?: Array<string>;
}

export interface ReportSmUsagesLaundrygroupsRefGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    search?: string;
    organizationId?: Array<string>;
}

export interface ReportSmUsagesMachinesRefGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    search?: string;
    laundryId?: Array<string>;
    laundryGroupId?: Array<string>;
    organizationId?: Array<string>;
}

export interface ReportSmUsagesOrganizationsRefGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    search?: string;
}

/**
 * 
 */
export class ReportUsagesApi extends runtime.BaseAPI {

    /**
     * List Charting data for Utilization
     */
    async reportSmUsagesChartGetRaw(requestParameters: ReportSmUsagesChartGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChartData>> {
        if (requestParameters.groupBy === null || requestParameters.groupBy === undefined) {
            throw new runtime.RequiredError('groupBy','Required parameter requestParameters.groupBy was null or undefined when calling reportSmUsagesChartGet.');
        }

        if (requestParameters.factType === null || requestParameters.factType === undefined) {
            throw new runtime.RequiredError('factType','Required parameter requestParameters.factType was null or undefined when calling reportSmUsagesChartGet.');
        }

        if (requestParameters.scale === null || requestParameters.scale === undefined) {
            throw new runtime.RequiredError('scale','Required parameter requestParameters.scale was null or undefined when calling reportSmUsagesChartGet.');
        }

        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportSmUsagesChartGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportSmUsagesChartGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        if (requestParameters.factType !== undefined) {
            queryParameters['factType'] = requestParameters.factType;
        }

        if (requestParameters.scale !== undefined) {
            queryParameters['scale'] = requestParameters.scale;
        }

        if (requestParameters.chartType !== undefined) {
            queryParameters['chartType'] = requestParameters.chartType;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.machineCategory !== undefined) {
            queryParameters['machineCategory'] = requestParameters.machineCategory;
        }

        if (requestParameters.programMode !== undefined) {
            queryParameters['programMode'] = requestParameters.programMode;
        }

        if (requestParameters.programName !== undefined) {
            queryParameters['programName'] = requestParameters.programName;
        }

        if (requestParameters.temperature !== undefined) {
            queryParameters['temperature'] = requestParameters.temperature;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/usages/chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChartDataFromJSON(jsonValue));
    }

    /**
     * List Charting data for Utilization
     */
    async reportSmUsagesChartGet(groupBy: UtilizationChartGroupBy, factType: UtilizationChartFactType, scale: ChartScale, startTimeFrom: Date, startTimeTo: Date, chartType?: ChartType, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, machineCategory?: MachineCategory, programMode?: string, programName?: string, temperature?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChartData> {
        const response = await this.reportSmUsagesChartGetRaw({ groupBy: groupBy, factType: factType, scale: scale, startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, chartType: chartType, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId, machineCategory: machineCategory, programMode: programMode, programName: programName, temperature: temperature }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a CSV file of single usages
     */
    async reportSmUsagesCsvGetRaw(requestParameters: ReportSmUsagesCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportSmUsagesCsvGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportSmUsagesCsvGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.machineCategory !== undefined) {
            queryParameters['machineCategory'] = requestParameters.machineCategory;
        }

        if (requestParameters.programMode !== undefined) {
            queryParameters['programMode'] = requestParameters.programMode;
        }

        if (requestParameters.programName !== undefined) {
            queryParameters['programName'] = requestParameters.programName;
        }

        if (requestParameters.temperature !== undefined) {
            queryParameters['temperature'] = requestParameters.temperature;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/usages/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Returns a CSV file of single usages
     */
    async reportSmUsagesCsvGet(startTimeFrom: Date, startTimeTo: Date, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, machineCategory?: MachineCategory, programMode?: string, programName?: string, temperature?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.reportSmUsagesCsvGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId, machineCategory: machineCategory, programMode: programMode, programName: programName, temperature: temperature }, initOverrides);
        return await response.value();
    }

    /**
     * List Single Usages
     */
    async reportSmUsagesGetRaw(requestParameters: ReportSmUsagesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableSMUsages>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportSmUsagesGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportSmUsagesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.machineCategory !== undefined) {
            queryParameters['machineCategory'] = requestParameters.machineCategory;
        }

        if (requestParameters.programMode !== undefined) {
            queryParameters['programMode'] = requestParameters.programMode;
        }

        if (requestParameters.programName !== undefined) {
            queryParameters['programName'] = requestParameters.programName;
        }

        if (requestParameters.temperature !== undefined) {
            queryParameters['temperature'] = requestParameters.temperature;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/usages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableSMUsagesFromJSON(jsonValue));
    }

    /**
     * List Single Usages
     */
    async reportSmUsagesGet(startTimeFrom: Date, startTimeTo: Date, size?: number, page?: number, sort?: string, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, machineCategory?: MachineCategory, programMode?: string, programName?: string, temperature?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableSMUsages> {
        const response = await this.reportSmUsagesGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, size: size, page: page, sort: sort, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId, machineCategory: machineCategory, programMode: programMode, programName: programName, temperature: temperature }, initOverrides);
        return await response.value();
    }

    /**
     * List Laundry References
     */
    async reportSmUsagesLaundriesRefGetRaw(requestParameters: ReportSmUsagesLaundriesRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaundryReferenceSimple>>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportSmUsagesLaundriesRefGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportSmUsagesLaundriesRefGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.laundryGroupId) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.organizationId) {
            queryParameters['organizationId'] = requestParameters.organizationId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/usages/laundries/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaundryReferenceSimpleFromJSON));
    }

    /**
     * List Laundry References
     */
    async reportSmUsagesLaundriesRefGet(startTimeFrom: Date, startTimeTo: Date, search?: string, laundryGroupId?: Array<string>, organizationId?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaundryReferenceSimple>> {
        const response = await this.reportSmUsagesLaundriesRefGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, search: search, laundryGroupId: laundryGroupId, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     * List Laundry Group References
     */
    async reportSmUsagesLaundrygroupsRefGetRaw(requestParameters: ReportSmUsagesLaundrygroupsRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaundryGroupReference>>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportSmUsagesLaundrygroupsRefGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportSmUsagesLaundrygroupsRefGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.organizationId) {
            queryParameters['organizationId'] = requestParameters.organizationId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/usages/laundrygroups/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaundryGroupReferenceFromJSON));
    }

    /**
     * List Laundry Group References
     */
    async reportSmUsagesLaundrygroupsRefGet(startTimeFrom: Date, startTimeTo: Date, search?: string, organizationId?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaundryGroupReference>> {
        const response = await this.reportSmUsagesLaundrygroupsRefGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, search: search, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     * List Machine References
     */
    async reportSmUsagesMachinesRefGetRaw(requestParameters: ReportSmUsagesMachinesRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MachineReference>>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportSmUsagesMachinesRefGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportSmUsagesMachinesRefGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.laundryId) {
            queryParameters['laundryId'] = requestParameters.laundryId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.laundryGroupId) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.organizationId) {
            queryParameters['organizationId'] = requestParameters.organizationId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/usages/machines/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineReferenceFromJSON));
    }

    /**
     * List Machine References
     */
    async reportSmUsagesMachinesRefGet(startTimeFrom: Date, startTimeTo: Date, search?: string, laundryId?: Array<string>, laundryGroupId?: Array<string>, organizationId?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MachineReference>> {
        const response = await this.reportSmUsagesMachinesRefGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, search: search, laundryId: laundryId, laundryGroupId: laundryGroupId, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     * List Organizations References
     */
    async reportSmUsagesOrganizationsRefGetRaw(requestParameters: ReportSmUsagesOrganizationsRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrganizationReference>>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportSmUsagesOrganizationsRefGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportSmUsagesOrganizationsRefGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/usages/organizations/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationReferenceFromJSON));
    }

    /**
     * List Organizations References
     */
    async reportSmUsagesOrganizationsRefGet(startTimeFrom: Date, startTimeTo: Date, search?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrganizationReference>> {
        const response = await this.reportSmUsagesOrganizationsRefGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, search: search }, initOverrides);
        return await response.value();
    }

}
