import { endOfMonth, startOfMonth, startOfYear } from 'date-fns'
import {
  getLastMonth,
  getLastMonths,
  removeTimeFromDate,
  removeTimeFromDateEndOfDay,
} from 'src/service/utils/MomentUtils'

export enum DashboardPeriodType {
  MONTH,
  HALF_YEAR,
  YEAR,
}

export const getFromToForPeriodTypeFromNow = (periodType: DashboardPeriodType): Date[] => {
  const now = new Date()
  let startDateFrom: Date = now
  let startDateTo: Date = now
  if (periodType === DashboardPeriodType.MONTH) {
    startDateFrom = startOfMonth(removeTimeFromDate(getLastMonth(now)))
  } else if (periodType === DashboardPeriodType.HALF_YEAR) {
    startDateFrom = startOfMonth(removeTimeFromDate(getLastMonths(now, 6)))
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (periodType === DashboardPeriodType.YEAR) {
    startDateFrom = startOfMonth(removeTimeFromDate(getLastMonths(now, 12)))
  }
  startDateTo = removeTimeFromDateEndOfDay(now)
  return [startDateFrom, startDateTo]
}

export const getFromToForPeriodTypeFromLastFinishedMonth = (periodType: DashboardPeriodType): Date[] => {
  const now = new Date()
  let startDateFrom: Date = now
  let startDateTo: Date = now
  const lastMonthEnd = endOfMonth(getLastMonth(now))

  if (periodType === DashboardPeriodType.MONTH) {
    startDateFrom = startOfMonth(removeTimeFromDate(lastMonthEnd))
  } else if (periodType === DashboardPeriodType.HALF_YEAR) {
    startDateFrom = startOfMonth(removeTimeFromDate(getLastMonths(now, 6)))
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (periodType === DashboardPeriodType.YEAR) {
    startDateFrom = startOfYear(lastMonthEnd)
  }
  startDateTo = removeTimeFromDateEndOfDay(lastMonthEnd)
  return [startDateFrom, startDateTo]
}
