import { OrderDir } from 'src/service/view-model/base/Data'

export function correctIntParam(
  param: string | number | boolean | (string | number | boolean | null | undefined)[] | null | undefined,
  min: number,
  max: number,
  defValue: number,
): number | undefined {
  if (param !== null && param !== undefined) {
    if (typeof param === 'number') {
      const numberValue: number = param as number
      if (numberValue < min) {
        return min
      } else if (numberValue > max) {
        return max
      }
    } else {
      return defValue
    }
  }
  return undefined
}

export function correctOrderDirParam(
  param: string | number | boolean | (string | number | boolean | null | undefined)[] | null | undefined,
  defOrderDir: OrderDir,
): OrderDir | undefined {
  if (param !== null && param !== undefined) {
    if (typeof param === 'string') {
      if (param !== 'asc' && param !== 'desc') {
        return defOrderDir
      }
    } else {
      return defOrderDir
    }
  }
  return undefined
}
