import React from 'react'
import Box from '@mui/material/Box'

interface TabPanelProps {
  children?: React.ReactNode
  index: string
  value: string
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, index, value }) => {
  let tabPanel = null

  if (index === value) {
    tabPanel = <Box>{children}</Box>
  }

  return tabPanel
}
