import React, { ReactElement } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  MobileStepper,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(7),
  paddingBottom: 0,
  maxHeight: '350px',
  minHeight: '350px',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: '500px',
    maxHeight: '500px',
  },
}))

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  cursor: 'pointer',
  width: '20px',
  color: theme.palette.primary.main,
}))

const StyledDialogBackground = styled(Box)<{
  image: string
}>(({ image }) => ({
  backgroundImage: 'url("' + image + '")',
  backgroundPosition: 'top 0px right 0px ',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: '100%',
}))

const SlideTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  mixBlendMode: 'multiply',
  marginBottom: theme.spacing(3),
  textAlign: 'left',
  fontWeight: 600,
}))

const SlideBody = styled(Typography)(() => ({
  whiteSpace: 'pre-line',
  textAlign: 'left',
}))

const SlideImage = styled(Box)(({ theme }) => ({
  '& svg': {
    width: '330px',
    height: '300px',
    color: 'primary',
    [theme.breakpoints.down('md')]: {
      width: '300px',
      height: '280px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '220px',
      height: '200px',
    },
  },
  '& svg:not([fill="none"])': {
    fill: theme.palette.primary.main + '!important',
  },
}))

const StyledStepper = styled(MobileStepper)(({ theme }) => ({
  '& .MuiMobileStepper-dots': {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    '& .MuiMobileStepper-dot': {
      padding: 5,
      margin: 6,
      [theme.breakpoints.down('md')]: {
        padding: 3,
        margin: 3,
      },
    },
  },
}))

export interface SlideItem {
  backgroundImage: string
  title: string
  body: string
  slideImage: ReactElement
}

interface Props {
  slides: SlideItem[]
  open: boolean
  onClose: () => void
}

export const OnboardingSlideshow = ({ slides, open, onClose }: Props): ReactElement => {
  const translate = useTranslate()
  const theme = useTheme()
  const mdOrLess = useMediaQuery(theme.breakpoints.down('md'))
  const buttonSize = mdOrLess ? 'medium' : 'large'

  const appId = useAppId()

  const [activeStep, setActiveStep] = React.useState(0)

  const slidesContent = slides

  const content = slidesContent[activeStep]
  const steps = slidesContent.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const getImagePath = (): string => {
    const imageName = content.backgroundImage
    return `/onboarding-slideshow/${appId}/${imageName}`
  }

  return (
    <Dialog open={open} maxWidth="md" scroll="paper">
      <StyledDialogBackground image={getImagePath()}>
        <DialogTitle display={'flex'} justifyContent={'right'}>
          <StyledCloseIcon onClick={onClose} />
        </DialogTitle>

        <StyledDialogContent>
          <Grid container>
            <Grid item {...ITEM_BREAKPOINTS} sm={6} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Box>
                <SlideTitle variant="h2">{translate(content.title)}</SlideTitle>
                <SlideBody variant="h6">{translate(content.body)}</SlideBody>
              </Box>
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS} sm={6} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <SlideImage>{content.slideImage}</SlideImage>
            </Grid>
          </Grid>
        </StyledDialogContent>
      </StyledDialogBackground>

      <StyledStepper
        steps={steps}
        position="static"
        activeStep={activeStep}
        nextButton={
          activeStep !== steps - 1 ? (
            <Button endIcon={<KeyboardArrowRight />} color="primary" size={buttonSize} onClick={handleNext}>
              {translate('button.next')}
            </Button>
          ) : (
            <Button color="primary" size={buttonSize} onClick={onClose}>
              {translate('button.finish')}
            </Button>
          )
        }
        backButton={
          <Button
            startIcon={<KeyboardArrowLeft />}
            color="primary"
            size={buttonSize}
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {translate('button.previous')}
          </Button>
        }
      />
    </Dialog>
  )
}
