/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * implicitly defines screenIndex (e.g. screen 2 position 1 => screenIndex=1004)
 * @export
 * @interface ProgramPosition
 */
export interface ProgramPosition {
    /**
     * 
     * @type {number}
     * @memberof ProgramPosition
     */
    screen: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramPosition
     */
    position: number;
}

/**
 * Check if a given object implements the ProgramPosition interface.
 */
export function instanceOfProgramPosition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "screen" in value;
    isInstance = isInstance && "position" in value;

    return isInstance;
}

export function ProgramPositionFromJSON(json: any): ProgramPosition {
    return ProgramPositionFromJSONTyped(json, false);
}

export function ProgramPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramPosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'screen': json['screen'],
        'position': json['position'],
    };
}

export function ProgramPositionToJSON(value?: ProgramPosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'screen': value.screen,
        'position': value.position,
    };
}

