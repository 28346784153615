/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChartDatasetLineType } from './ChartDatasetLineType';
import {
    ChartDatasetLineTypeFromJSON,
    ChartDatasetLineTypeFromJSONTyped,
    ChartDatasetLineTypeToJSON,
} from './ChartDatasetLineType';

/**
 * 
 * @export
 * @interface ChartDataset
 */
export interface ChartDataset {
    /**
     * requested data label
     * @type {string}
     * @memberof ChartDataset
     */
    label: string;
    /**
     * contains the fact unit for Y axis
     * @type {string}
     * @memberof ChartDataset
     */
    yUnit?: string;
    /**
     * 
     * @type {ChartDatasetLineType}
     * @memberof ChartDataset
     */
    lineType?: ChartDatasetLineType;
    /**
     * 
     * @type {string}
     * @memberof ChartDataset
     */
    color?: string;
    /**
     * contains the fact values for Y axis
     * @type {Array<number>}
     * @memberof ChartDataset
     */
    yValues: Array<number>;
}

/**
 * Check if a given object implements the ChartDataset interface.
 */
export function instanceOfChartDataset(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "yValues" in value;

    return isInstance;
}

export function ChartDatasetFromJSON(json: any): ChartDataset {
    return ChartDatasetFromJSONTyped(json, false);
}

export function ChartDatasetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChartDataset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'yUnit': !exists(json, 'yUnit') ? undefined : json['yUnit'],
        'lineType': !exists(json, 'lineType') ? undefined : ChartDatasetLineTypeFromJSON(json['lineType']),
        'color': !exists(json, 'color') ? undefined : json['color'],
        'yValues': json['yValues'],
    };
}

export function ChartDatasetToJSON(value?: ChartDataset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'yUnit': value.yUnit,
        'lineType': ChartDatasetLineTypeToJSON(value.lineType),
        'color': value.color,
        'yValues': value.yValues,
    };
}

