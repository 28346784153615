/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MachineInsightType {
    OPERATING_HOURS = 'OPERATING_HOURS',
    PROGRAM_COUNTER = 'PROGRAM_COUNTER',
    RSSI = 'RSSI'
}


export function MachineInsightTypeFromJSON(json: any): MachineInsightType {
    return MachineInsightTypeFromJSONTyped(json, false);
}

export function MachineInsightTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineInsightType {
    return json as MachineInsightType;
}

export function MachineInsightTypeToJSON(value?: MachineInsightType | null): any {
    return value as any;
}

