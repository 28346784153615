import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from 'src/App'
import { configureConsoleError } from 'src/dev/DevConsole'

configureConsoleError()

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(<App />)
