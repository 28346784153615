import React, { PropsWithChildren, ReactElement, useContext, useEffect, useState } from 'react'
import packageJson from '../../package.json'
import { AppId } from 'src/app/AppId'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  AppId as ApiAppId,
  ClientType,
  Configuration,
  InitializeResultType,
  MeApi,
  MeInitializeRequest,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { mapAppIdFromApiToInternal } from 'src/user/AppIdHelper'
import { KeycloakService } from 'src/user/KeycloakService'
import { User } from 'src/user/User'
import { UserContext } from 'src/user/UserContext'

export const InitUser = ({ children }: PropsWithChildren): ReactElement => {
  const translate = useTranslate()
  const { user, updateUser } = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(true)

  const httpConfiguration: Configuration = useContext(HttpContext)
  const meApi = new MeApi(httpConfiguration)

  // initialize user with the backend after login
  useEffect(() => {
    console.info('Initializing user')

    // if user logged out, skip initialization
    if (!user.id) {
      return
    }

    const initRequest: MeInitializeRequest = getMeInitRequest()
    meApi
      .meInitializePost(initRequest)
      .then((initResponse) => {
        const result = initResponse.result
        console.info('Initializing user result', result)
        setIsLoading(false)

        // get permissions form init response
        const userPermissions = initResponse.permissions

        // get available applications from init response
        const appsFromApi: ApiAppId[] = initResponse.applications.apps
        const availableApps = mapAppIdsFromApiToInternal(appsFromApi)

        const defaultAppFromApi = initResponse.applications.defaultApp
        const defaultApp = mapAppIdFromApiToInternal(defaultAppFromApi)

        const regionDataFromInit = initResponse.regionData

        const userWithPermissions: User = {
          ...user,
          permissions: userPermissions,
          availableApps: availableApps,
          defaultApp: defaultApp,
          regionData: regionDataFromInit,
        }
        updateUser(userWithPermissions)

        if (result !== InitializeResultType.OK) {
          // if blocked, immediately logout
          if (initResponse.result === InitializeResultType.BLOCK) {
            console.warn('User access blocked')
            // logout user
            KeycloakService.getKeycloakInstance().logout()
          } else {
            // show message from backend if any
            const data = initResponse.data
            const title = data?.title
            const message = data?.message
            const button = data?.button
            const buttonTitle = button?.title
            const buttonLink = button?.link
            console.debug('Info message to display', result, title, message)

            const hasMessage: boolean = title || message ? true : false
            const hasButton: boolean = buttonTitle && buttonLink ? true : false
            if (hasMessage) {
              const text =
                (title ? title + '\n' : '') +
                (message ? message : '') +
                (hasButton ? '\n\n' + buttonTitle + ': \n' + buttonLink : '')
              alert(text)
            }
          }
        }
      })
      .catch((err) => {
        setIsLoading(false)

        const errorMessage = translate('user-init-error')
        console.error('Failed to initialize user', err)
        alert(errorMessage + '\n' + err)
      })
  }, [user.id])

  return <>{!isLoading && children}</>
}

function getMeInitRequest() {
  const appVersion = packageJson.version
  const wn = window.navigator
  const platform = wn.platform.toString().toLowerCase()
  const userAgent = wn.userAgent

  const initRequest: MeInitializeRequest = {
    deviceId: 'webapp',
    clientType: ClientType.BROWSER,
    appVersion: appVersion,
    os: platform,
    meta: userAgent,
  }
  return initRequest
}

function mapAppIdsFromApiToInternal(appsIdFromApi: ApiAppId[]): AppId[] {
  if (appsIdFromApi.length === 0) {
    return []
  }

  const result = appsIdFromApi
    .map((appIdFromApi) => mapAppIdFromApiToInternal(appIdFromApi))
    .filter((appId) => appId !== undefined)

  return result as AppId[]
}
