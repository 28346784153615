/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProgramModuleSettings } from './ProgramModuleSettings';
import {
    ProgramModuleSettingsFromJSON,
    ProgramModuleSettingsFromJSONTyped,
    ProgramModuleSettingsToJSON,
} from './ProgramModuleSettings';

/**
 * 
 * @export
 * @interface ProgramModuleTemplateAvailableTemplatesInner
 */
export interface ProgramModuleTemplateAvailableTemplatesInner {
    /**
     * 
     * @type {string}
     * @memberof ProgramModuleTemplateAvailableTemplatesInner
     */
    label?: string;
    /**
     * when empty, selection of template is on root level,  when filled, templates with same categoryLabel are grouped and put into the 2nd level
     * @type {string}
     * @memberof ProgramModuleTemplateAvailableTemplatesInner
     */
    categoryLabel?: string;
    /**
     * 
     * @type {Array<ProgramModuleSettings>}
     * @memberof ProgramModuleTemplateAvailableTemplatesInner
     */
    steps?: Array<ProgramModuleSettings>;
}

/**
 * Check if a given object implements the ProgramModuleTemplateAvailableTemplatesInner interface.
 */
export function instanceOfProgramModuleTemplateAvailableTemplatesInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProgramModuleTemplateAvailableTemplatesInnerFromJSON(json: any): ProgramModuleTemplateAvailableTemplatesInner {
    return ProgramModuleTemplateAvailableTemplatesInnerFromJSONTyped(json, false);
}

export function ProgramModuleTemplateAvailableTemplatesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramModuleTemplateAvailableTemplatesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'categoryLabel': !exists(json, 'categoryLabel') ? undefined : json['categoryLabel'],
        'steps': !exists(json, 'steps') ? undefined : ((json['steps'] as Array<any>).map(ProgramModuleSettingsFromJSON)),
    };
}

export function ProgramModuleTemplateAvailableTemplatesInnerToJSON(value?: ProgramModuleTemplateAvailableTemplatesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'categoryLabel': value.categoryLabel,
        'steps': value.steps === undefined ? undefined : ((value.steps as Array<any>).map(ProgramModuleSettingsToJSON)),
    };
}

