import { makeStyles } from 'tss-react/mui'

export const useTableStyles = makeStyles()((theme) => {
  return {
    gridBar: {
      paddingTop: theme.spacing(3),
    },
    tableRow: {
      cursor: 'pointer',
    },
    gridTitle: {
      borderSizing: 'border-box',
      height: 75,
    },
    Header: {
      paddingLeft: theme.spacing(2),
    },
    gridSearch: {
      paddingTop: theme.spacing(1),
    },
    searchBar: {
      width: '100%',
    },
    noData: {
      color: '#999',
      fontSize: '1rem',
    },
  }
})
