import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Icon } from '@mdi/react'
import { Divider, Paper } from '@mui/material'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import { mdiBullhorn, mdiCashSync } from '@mdi/js'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { useIsProdEnvironment } from 'src/env/EnvironmentContext'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, LaundryGroupsApi } from 'src/service/backend/api'
import { AppId as ApiAppId, LaundryGroup, Permission } from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { StyledTab } from 'src/ui-shared/base/tab-panel/StyledTab'
import { StyledTabs } from 'src/ui-shared/base/tab-panel/StyledTabs'
import { TabPanel } from 'src/ui-shared/base/tab-panel/TabPanel'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import {
  ArticleIcon,
  CalendarMonthIcon,
  CloudUploadOutlinedIcon,
  SellIcon,
  SettingsIcon,
  ShoppingBagIcon,
} from 'src/ui-shared/icons/Icons'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryGroupCalendarTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupCalendarTab'
import { LaundryGroupInvoicesTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupInvoicesTab'
import { LaundryGroupMessagesTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupMessagesTab'
import { LaundryGroupOverviewTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupOverviewTab'
import { LaundryGroupPriceTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupPriceTab'
import { LaundryGroupProgramInstallationTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupProgramInstallationTab'
import { LaundryGroupServicePackagePlanTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupServicePackagePlanTab'
import { LaundryGroupSettingsTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupSettingsTab'
import { LaundryGroupTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupTab'
import { mapInternalAppIdToApi } from 'src/user/AppIdHelper'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const LaundryGroupDetailsPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()

  const user = useUser()
  const isProdEnvironment = useIsProdEnvironment()

  const navigate = useNavigate()
  const location = useLocation()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const state = location.state as NavigateState | undefined
  const { laundryGroupId, tabNameParam } = useRequiredParams(['laundryGroupId', 'tabNameParam'])
  const tabName = getEnumFromString(tabNameParam, LaundryGroupTab)

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)

  // state
  const [activeTab, setActiveTab] = useState<LaundryGroupTab>(LaundryGroupTab.OVERVIEW)
  const [laundryGroup, setLaundryGroup] = useState<LaundryGroup | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const [browserHistoryBack, setBrowserHistoryBack] = useState<boolean>()

  const apiAppId: ApiAppId | undefined = mapInternalAppIdToApi(appId)
  const apiAppsId: ApiAppId[] | undefined = apiAppId ? [apiAppId] : undefined

  // load data
  useEffect(() => {
    laundryGroupsApi
      .laundrygroupsLaundryGroupIdGet(laundryGroupId, apiAppsId)
      .then((group) => {
        setLaundryGroup(group)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })

    setBrowserHistoryBack(state?.browserHistoryBack)
    setActiveTab(tabName)
  }, [laundryGroupId])

  // handle events
  const handleTabChange = (event: React.SyntheticEvent, newValue: LaundryGroupTab) => {
    const pathToNavigate = `/${appId}/laundry-groups/${laundryGroupId}/view/${newValue}`
    navigate(pathToNavigate, { replace: true })
    setActiveTab(newValue)
  }

  const navigateToEditPage = () => {
    navigate(`/${appId}/laundry-groups/${laundryGroupId}/edit`)
  }

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/laundry-groups`)
  }

  const onRemoveHandler = (): void => {
    if (laundryGroup) {
      laundryGroupsApi
        .laundrygroupsLaundryGroupIdDelete(laundryGroupId)
        .then(() => {
          navigateBack()
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
        })
    }
    setRemoveModal(false)
  }

  // render
  let laundryGroupOverview = null
  let laundryGroupMessages = null
  let laundryGroupCalendar = null
  let laundryGroupPrices = null
  let laundryGroupBlockedDays = null
  let laundryGroupInvoices = null
  let laundryGroupProgramInstallation = null
  let laundryGroupPackagePlan = null

  if (laundryGroup !== null) {
    laundryGroupOverview = (
      <LaundryGroupOverviewTab
        navigateToEditPage={navigateToEditPage}
        laundryGroup={laundryGroup}
        onRemoveHandler={onRemoveHandler}
        removeModal={removeModal}
        setRemoveModal={setRemoveModal}
      />
    )

    laundryGroupMessages = <LaundryGroupMessagesTab laundryGroup={laundryGroup} />
    laundryGroupCalendar = <LaundryGroupCalendarTab laundryGroup={laundryGroup} />
    laundryGroupPrices = <LaundryGroupPriceTab laundryGroup={laundryGroup} />
    laundryGroupBlockedDays = <LaundryGroupSettingsTab laundryGroup={laundryGroup} />
    laundryGroupInvoices = <LaundryGroupInvoicesTab laundryGroup={laundryGroup} />
    laundryGroupProgramInstallation = <LaundryGroupProgramInstallationTab laundryGroup={laundryGroup} />
    laundryGroupPackagePlan = <LaundryGroupServicePackagePlanTab laundryGroup={laundryGroup} />
  }

  const hasPriceListReadPermission = hasPermission(user, Permission.PRICE_LIST_READ)
  const hasCalendarReadPermission = hasPermission(user, Permission.CALENDAR_ENTRY_READ)
  const hasInvoiceReadPermission = hasPermission(user, Permission.INVOICE_READ)

  return (
    <ScreenLayout title={laundryGroup?.name ? laundryGroup.name : ''} onBack={navigateBack} actionsWidth={30}>
      <Paper elevation={0}>
        <Grid container className={sharedClasses.TabsContainer}>
          <Grid item xs={12}>
            <StyledTabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={handleTabChange}
            >
              <StyledTab
                icon={<ArticleIcon />}
                iconPosition={'start'}
                label={<Hidden lgDown>{translate('company.overview')}</Hidden>}
                value={LaundryGroupTab.OVERVIEW}
              />
              {appId === AppId.WASH_MASTER && (
                <StyledTab
                  icon={<Icon path={mdiBullhorn} size={0.9} />}
                  iconPosition={'start'}
                  label={<Hidden lgDown>{translate('laundryGroupMessages')}</Hidden>}
                  value={LaundryGroupTab.MESSAGES}
                />
              )}
              {hasPriceListReadPermission && appId === AppId.WASH_MASTER && (
                <StyledTab
                  icon={<SellIcon />}
                  iconPosition={'start'}
                  label={<Hidden lgDown>{translate('laundryGroupPrices')}</Hidden>}
                  value={LaundryGroupTab.PRICES}
                />
              )}
              {appId === AppId.WASH_MASTER && (
                <StyledTab
                  icon={<ShoppingBagIcon />}
                  iconPosition={'start'}
                  label={<Hidden lgDown>{translate('servicePlans')}</Hidden>}
                  value={LaundryGroupTab.SERVICE_PLANS}
                />
              )}

              {appId === AppId.SERVICE_MASTER && (
                <StyledTab
                  icon={<CloudUploadOutlinedIcon />}
                  iconPosition={'start'}
                  label={<Hidden lgDown>{translate('programUploads')}</Hidden>}
                  value={LaundryGroupTab.PROGRAM_INSTALLATIONS}
                />
              )}
              {hasCalendarReadPermission ? (
                <StyledTab
                  icon={<CalendarMonthIcon />}
                  iconPosition={'start'}
                  label={<Hidden lgDown>{translate('laundryGroupCalendar')}</Hidden>}
                  value={LaundryGroupTab.CALENDAR}
                />
              ) : null}
              {appId === AppId.WASH_MASTER && hasInvoiceReadPermission && (
                <StyledTab
                  icon={<Icon path={mdiCashSync} size={1} />}
                  iconPosition={'start'}
                  label={<Hidden lgDown>{translate('invoices')}</Hidden>}
                  value={LaundryGroupTab.INVOICES}
                />
              )}
              {hasCalendarReadPermission ? (
                <StyledTab
                  icon={<SettingsIcon />}
                  iconPosition={'start'}
                  label={<Hidden lgDown>{translate('settings')}</Hidden>}
                  value={LaundryGroupTab.BLOCKED_DAYS}
                />
              ) : null}
            </StyledTabs>
          </Grid>
        </Grid>
        <Divider />
        <TabPanel value={activeTab} index={LaundryGroupTab.OVERVIEW}>
          {errorMessage ? <ErrorMessage message={errorMessage} /> : laundryGroupOverview}
        </TabPanel>
        {appId === AppId.WASH_MASTER && (
          <TabPanel value={activeTab} index={LaundryGroupTab.MESSAGES}>
            {errorMessage ? <ErrorMessage message={errorMessage} /> : laundryGroupMessages}
          </TabPanel>
        )}
        {appId === AppId.WASH_MASTER && (
          <TabPanel value={activeTab} index={LaundryGroupTab.PRICES}>
            {errorMessage ? <ErrorMessage message={errorMessage} /> : laundryGroupPrices}
          </TabPanel>
        )}
        {appId === AppId.WASH_MASTER && (
          <TabPanel value={activeTab} index={LaundryGroupTab.SERVICE_PLANS}>
            {errorMessage ? <ErrorMessage message={errorMessage} /> : laundryGroupPackagePlan}
          </TabPanel>
        )}
        {appId === AppId.SERVICE_MASTER && (
          <TabPanel value={activeTab} index={LaundryGroupTab.PROGRAM_INSTALLATIONS}>
            {errorMessage ? <ErrorMessage message={errorMessage} /> : laundryGroupProgramInstallation}
          </TabPanel>
        )}
        <TabPanel value={activeTab} index={LaundryGroupTab.CALENDAR}>
          {errorMessage ? <ErrorMessage message={errorMessage} /> : laundryGroupCalendar}
        </TabPanel>
        {appId === AppId.WASH_MASTER && (
          <TabPanel value={activeTab} index={LaundryGroupTab.INVOICES}>
            {errorMessage ? <ErrorMessage message={errorMessage} /> : laundryGroupInvoices}
          </TabPanel>
        )}
        <TabPanel value={activeTab} index={LaundryGroupTab.BLOCKED_DAYS}>
          {errorMessage ? <ErrorMessage message={errorMessage} /> : laundryGroupBlockedDays}
        </TabPanel>
      </Paper>
    </ScreenLayout>
  )
}
