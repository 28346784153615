/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ErrorSeverity } from './ErrorSeverity';
import {
    ErrorSeverityFromJSON,
    ErrorSeverityFromJSONTyped,
    ErrorSeverityToJSON,
} from './ErrorSeverity';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';
import type { LaundryReference } from './LaundryReference';
import {
    LaundryReferenceFromJSON,
    LaundryReferenceFromJSONTyped,
    LaundryReferenceToJSON,
} from './LaundryReference';
import type { MachineReference } from './MachineReference';
import {
    MachineReferenceFromJSON,
    MachineReferenceFromJSONTyped,
    MachineReferenceToJSON,
} from './MachineReference';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';
import type { Step } from './Step';
import {
    StepFromJSON,
    StepFromJSONTyped,
    StepToJSON,
} from './Step';

/**
 * 
 * @export
 * @interface FhMonitoringEntry
 */
export interface FhMonitoringEntry {
    /**
     * 
     * @type {string}
     * @memberof FhMonitoringEntry
     */
    readonly messageId?: string;
    /**
     * 
     * @type {Date}
     * @memberof FhMonitoringEntry
     */
    errorTime: Date;
    /**
     * 
     * @type {ErrorSeverity}
     * @memberof FhMonitoringEntry
     */
    errorSeverity?: ErrorSeverity;
    /**
     * 
     * @type {string}
     * @memberof FhMonitoringEntry
     */
    errorCode: string;
    /**
     * 
     * @type {string}
     * @memberof FhMonitoringEntry
     */
    errorSummary?: string;
    /**
     * 
     * @type {string}
     * @memberof FhMonitoringEntry
     */
    errorDescription: string;
    /**
     * 
     * @type {Step}
     * @memberof FhMonitoringEntry
     */
    step?: Step;
    /**
     * 
     * @type {string}
     * @memberof FhMonitoringEntry
     */
    solution?: string;
    /**
     * 
     * @type {OrganizationReference}
     * @memberof FhMonitoringEntry
     */
    organization: OrganizationReference;
    /**
     * 
     * @type {LaundryGroupReference}
     * @memberof FhMonitoringEntry
     */
    laundryGroup: LaundryGroupReference;
    /**
     * 
     * @type {LaundryReference}
     * @memberof FhMonitoringEntry
     */
    laundry: LaundryReference;
    /**
     * 
     * @type {MachineReference}
     * @memberof FhMonitoringEntry
     */
    machine: MachineReference;
}

/**
 * Check if a given object implements the FhMonitoringEntry interface.
 */
export function instanceOfFhMonitoringEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "errorTime" in value;
    isInstance = isInstance && "errorCode" in value;
    isInstance = isInstance && "errorDescription" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "laundryGroup" in value;
    isInstance = isInstance && "laundry" in value;
    isInstance = isInstance && "machine" in value;

    return isInstance;
}

export function FhMonitoringEntryFromJSON(json: any): FhMonitoringEntry {
    return FhMonitoringEntryFromJSONTyped(json, false);
}

export function FhMonitoringEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FhMonitoringEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
        'errorTime': (new Date(json['errorTime'])),
        'errorSeverity': !exists(json, 'errorSeverity') ? undefined : ErrorSeverityFromJSON(json['errorSeverity']),
        'errorCode': json['errorCode'],
        'errorSummary': !exists(json, 'errorSummary') ? undefined : json['errorSummary'],
        'errorDescription': json['errorDescription'],
        'step': !exists(json, 'step') ? undefined : StepFromJSON(json['step']),
        'solution': !exists(json, 'solution') ? undefined : json['solution'],
        'organization': OrganizationReferenceFromJSON(json['organization']),
        'laundryGroup': LaundryGroupReferenceFromJSON(json['laundryGroup']),
        'laundry': LaundryReferenceFromJSON(json['laundry']),
        'machine': MachineReferenceFromJSON(json['machine']),
    };
}

export function FhMonitoringEntryToJSON(value?: FhMonitoringEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorTime': (value.errorTime.toISOString()),
        'errorSeverity': ErrorSeverityToJSON(value.errorSeverity),
        'errorCode': value.errorCode,
        'errorSummary': value.errorSummary,
        'errorDescription': value.errorDescription,
        'step': StepToJSON(value.step),
        'solution': value.solution,
        'organization': OrganizationReferenceToJSON(value.organization),
        'laundryGroup': LaundryGroupReferenceToJSON(value.laundryGroup),
        'laundry': LaundryReferenceToJSON(value.laundry),
        'machine': MachineReferenceToJSON(value.machine),
    };
}

