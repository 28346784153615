/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';
import type { LaundryUserAddress } from './LaundryUserAddress';
import {
    LaundryUserAddressFromJSON,
    LaundryUserAddressFromJSONTyped,
    LaundryUserAddressToJSON,
} from './LaundryUserAddress';
import type { LaundryUserType } from './LaundryUserType';
import {
    LaundryUserTypeFromJSON,
    LaundryUserTypeFromJSONTyped,
    LaundryUserTypeToJSON,
} from './LaundryUserType';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';
import type { RFIDCardReference } from './RFIDCardReference';
import {
    RFIDCardReferenceFromJSON,
    RFIDCardReferenceFromJSONTyped,
    RFIDCardReferenceToJSON,
} from './RFIDCardReference';

/**
 * 
 * @export
 * @interface LaundryUser
 */
export interface LaundryUser {
    /**
     * 
     * @type {string}
     * @memberof LaundryUser
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryUser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryUser
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryUser
     */
    locale: string;
    /**
     * The ESR number
     * @type {string}
     * @memberof LaundryUser
     */
    readonly esrNumber: string;
    /**
     * The Creditor Reference - ISO 11649
     * @type {string}
     * @memberof LaundryUser
     */
    readonly creditorReference: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryUser
     */
    phoneNumber?: string;
    /**
     * 
     * @type {LaundryUserAddress}
     * @memberof LaundryUser
     */
    address: LaundryUserAddress;
    /**
     * 
     * @type {string}
     * @memberof LaundryUser
     */
    ibanNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryUser
     */
    ibanHolderName?: string;
    /**
     * 
     * @type {LaundryUserType}
     * @memberof LaundryUser
     */
    type?: LaundryUserType | null;
    /**
     * flag is set by the backend to
     *  - true if the current user can edit or delete this laundry User 
     *  - SMAG_* roles with correct Permission - OPERATOR_* roles with correct Permission and where the user is created by the operator is true
     * @type {boolean}
     * @memberof LaundryUser
     */
    readonly editable: boolean;
    /**
     * 
     * @type {Array<OrganizationReference>}
     * @memberof LaundryUser
     */
    readonly assignedOrganizations?: Array<OrganizationReference>;
    /**
     * 
     * @type {Array<LaundryGroupReference>}
     * @memberof LaundryUser
     */
    assignedLaundryGroups: Array<LaundryGroupReference>;
    /**
     * 
     * @type {Array<RFIDCardReference>}
     * @memberof LaundryUser
     */
    assignedRFIDCards: Array<RFIDCardReference>;
}

/**
 * Check if a given object implements the LaundryUser interface.
 */
export function instanceOfLaundryUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "locale" in value;
    isInstance = isInstance && "esrNumber" in value;
    isInstance = isInstance && "creditorReference" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "editable" in value;
    isInstance = isInstance && "assignedLaundryGroups" in value;
    isInstance = isInstance && "assignedRFIDCards" in value;

    return isInstance;
}

export function LaundryUserFromJSON(json: any): LaundryUser {
    return LaundryUserFromJSONTyped(json, false);
}

export function LaundryUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'locale': json['locale'],
        'esrNumber': json['esrNumber'],
        'creditorReference': json['creditorReference'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'address': LaundryUserAddressFromJSON(json['address']),
        'ibanNumber': !exists(json, 'ibanNumber') ? undefined : json['ibanNumber'],
        'ibanHolderName': !exists(json, 'ibanHolderName') ? undefined : json['ibanHolderName'],
        'type': !exists(json, 'type') ? undefined : LaundryUserTypeFromJSON(json['type']),
        'editable': json['editable'],
        'assignedOrganizations': !exists(json, 'assignedOrganizations') ? undefined : ((json['assignedOrganizations'] as Array<any>).map(OrganizationReferenceFromJSON)),
        'assignedLaundryGroups': ((json['assignedLaundryGroups'] as Array<any>).map(LaundryGroupReferenceFromJSON)),
        'assignedRFIDCards': ((json['assignedRFIDCards'] as Array<any>).map(RFIDCardReferenceFromJSON)),
    };
}

export function LaundryUserToJSON(value?: LaundryUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'locale': value.locale,
        'phoneNumber': value.phoneNumber,
        'address': LaundryUserAddressToJSON(value.address),
        'ibanNumber': value.ibanNumber,
        'ibanHolderName': value.ibanHolderName,
        'type': LaundryUserTypeToJSON(value.type),
        'assignedLaundryGroups': ((value.assignedLaundryGroups as Array<any>).map(LaundryGroupReferenceToJSON)),
        'assignedRFIDCards': ((value.assignedRFIDCards as Array<any>).map(RFIDCardReferenceToJSON)),
    };
}

