import React from 'react'
import { Icon } from '@mdi/react'
import { SvgIcon } from '@mui/material'
import {
  CleanHandsOutlined,
  DomainOutlined,
  FormatListNumberedRtl,
  HomeOutlined,
  HomeRepairServiceOutlined,
  HomeWorkOutlined,
  Language,
  MonitorHeartOutlined,
  SpaOutlined,
} from '@mui/icons-material'
import { mdiAccountHardHatOutline, mdiAccountTieOutline, mdiAlertOutline } from '@mdi/js'
import { ReactComponent as LaundryIcon } from 'src/assets/laundry-sm.svg'
import { ReactComponent as MachineIcon } from 'src/assets/washer-sm.svg'
import { SmMenuSections } from 'src/routing/MenuSections'
import { RoutesList } from 'src/routing/Routing'
import { APP_SHARED_ROUTES } from 'src/routing/SharedRoutes'
import { Permission } from 'src/service/backend/api/models'
import { LaundryGroupCreatePage } from 'src/ui/page/common/laundry-group/details/LaundryGroupCreatePage'
import { LaundryGroupDetailsPage } from 'src/ui/page/common/laundry-group/details/LaundryGroupDetailsPage'
import { LaundryGroupListPage } from 'src/ui/page/common/laundry-group/list/LaundryGroupListPage'
import { LaundryCreatePage } from 'src/ui/page/common/laundry/details/LaundryCreatePage'
import { LaundryDetailsPage } from 'src/ui/page/common/laundry/details/LaundryDetailsPage'
import { LaundryListPage } from 'src/ui/page/common/laundry/list/LaundryListPage'
import { MachineDetailsPage } from 'src/ui/page/common/machine/details/MachineDetailsPage'
import { MachineEditPage } from 'src/ui/page/common/machine/details/MachineEditPage'
import { MachineParametersEditPage } from 'src/ui/page/common/machine/details/MachineParametersEditPage'
import { MachineProductDataPage } from 'src/ui/page/common/machine/details/MachineProductDataPage'
import { MachineQrCodeScanner } from 'src/ui/page/common/machine/details/MachineQrCodeScanner'
import { MachineUnconnectedCreatePage } from 'src/ui/page/common/machine/details/MachineUnconnectedCreatePage'
import { MachineListPage } from 'src/ui/page/common/machine/list/MachineListPage'
import { MachineProgramDetailsPage } from 'src/ui/page/common/machine/program/MachineProgramDetailsPage'
import { MachineProgramImportPage } from 'src/ui/page/common/machine/program/MachineProgramImportPage'
import { MemberDetailsPage } from 'src/ui/page/common/member/details/MemberDetailsPage'
import { MemberEditPage } from 'src/ui/page/common/member/details/MemberEditPage'
import { MemberListPage } from 'src/ui/page/common/member/list/MemberListPage'
import { OrganizationCreatePage } from 'src/ui/page/common/organization/details/OrganizationCreatePage'
import { OrganizationDetailsPage } from 'src/ui/page/common/organization/details/OrganizationDetailsPage'
import { OrganizationListPage } from 'src/ui/page/common/organization/list/OrganizationListPage'
import { ServicePartnerCreatePage } from 'src/ui/page/common/service-partner/details/ServicePartnerCreatePage'
import { ServicePartnerDetailsPage } from 'src/ui/page/common/service-partner/details/ServicePartnerDetailsPage'
import { ServicePartnerListPage } from 'src/ui/page/common/service-partner/list/ServicePartnerListPage'
import { ConsumptionListPage } from 'src/ui/page/sm/consumption/list/ConsumptionListPage'
import { ErrorLogDetailsPage } from 'src/ui/page/sm/error-log/details/ErrorLogDetailsPage'
import { ErrorLogListPage } from 'src/ui/page/sm/error-log/list/ErrorLogListPage'
import { HygieneDetailsPage } from 'src/ui/page/sm/hygiene/details/HygieneDetailsPage'
import { HygieneListPage } from 'src/ui/page/sm/hygiene/list/HygieneListPage'
import { IndexPageSm } from 'src/ui/page/sm/index/IndexPageSm'
import { ProgramUsageListPage } from 'src/ui/page/sm/program-usage/list/ProgramUsageListPage'
import { ServiceRequestCreatePage } from 'src/ui/page/sm/service-request/details/ServiceRequestCreatePage'
import { ServiceRequestDetailsPage } from 'src/ui/page/sm/service-request/details/ServiceRequestDetailsPage'
import { ServiceRequestListPage } from 'src/ui/page/sm/service-request/list/ServiceRequestListPage'
import { ServicerDetailsPage } from 'src/ui/page/sm/servicer/details/ServicerDetailsPage'
import { ServicerEditPage } from 'src/ui/page/sm/servicer/details/ServicerEditPage'
import { ServicerListPage } from 'src/ui/page/sm/servicer/list/ServicerListPage'
import { UtilizationListPage } from 'src/ui/page/sm/utilization/list/UtilizationListPage'

export const SERVICE_MASTER_ROUTES: RoutesList = {
  IndexPageSm: {
    path: '',
    label: 'home',
    googleAnalyticsTitle: 'serviceMaster Home',
    Component: IndexPageSm,
    sectionLabel: SmMenuSections.OVERVIEWS,
    icon: <HomeOutlined />,
  },

  ServicePartnerListPage: {
    path: 'service-partners',
    label: 'servicePartners',
    googleAnalyticsTitle: 'serviceMaster Service Partners',
    Component: ServicePartnerListPage,
    permission: Permission.SERVICE_PARTNER_READ,
    sectionLabel: SmMenuSections.OVERVIEWS,
    icon: <Language />,
  },
  ServicePartnerDetailsPage: {
    path: 'service-partners/:organizationId/view',
    googleAnalyticsTitle: 'serviceMaster Service Partner Details',
    Component: ServicePartnerDetailsPage,
    permission: Permission.SERVICE_PARTNER_READ,
  },
  ServicePartnerCreatePage: {
    path: 'service-partners/create',
    googleAnalyticsTitle: 'serviceMaster Service Partner Create',
    Component: ServicePartnerCreatePage,
    permission: Permission.SERVICE_PARTNER_WRITE,
  },
  ServicePartnerEditPage: {
    path: 'service-partners/:organizationId/edit',
    googleAnalyticsTitle: 'serviceMaster Service Partner Edit',
    Component: ServicePartnerCreatePage,
    permission: Permission.SERVICE_PARTNER_WRITE,
  },

  OrganizationListPage: {
    path: 'organizations',
    label: 'organizations',
    googleAnalyticsTitle: 'serviceMaster Organizations',
    Component: OrganizationListPage,
    permission: Permission.ORGANIZATION_READ,
    sectionLabel: SmMenuSections.OVERVIEWS,
    icon: <DomainOutlined />,
  },
  OrganizationDetailsPage: {
    path: 'organizations/:organizationId/view',
    googleAnalyticsTitle: 'serviceMaster Organization Details',
    Component: OrganizationDetailsPage,
    permission: Permission.ORGANIZATION_READ,
  },
  OrganizationEditPage: {
    path: 'organizations/:organizationId/edit',
    googleAnalyticsTitle: 'serviceMaster Organization Edit',
    Component: OrganizationCreatePage,
    permission: Permission.ORGANIZATION_WRITE,
  },

  LaundryGroupListPage: {
    path: 'laundry-groups',
    label: 'laundryGroups',
    googleAnalyticsTitle: 'serviceMaster Laundry Groups',
    Component: LaundryGroupListPage,
    permission: Permission.LAUNDRY_GROUP_READ,
    sectionLabel: SmMenuSections.OVERVIEWS,
    icon: <HomeWorkOutlined />,
  },
  LaundryGroupDetailsPage: {
    path: 'laundry-groups/:laundryGroupId/view/:tabNameParam',
    googleAnalyticsTitle: 'serviceMaster Laundry Group Details',
    Component: LaundryGroupDetailsPage,
    permission: Permission.LAUNDRY_GROUP_READ,
  },
  LaundryGroupCreatePage: {
    path: 'laundry-groups/create',
    googleAnalyticsTitle: 'serviceMaster Laundry Group Create',
    Component: LaundryGroupCreatePage,
    permission: Permission.LAUNDRY_GROUP_WRITE,
  },
  LaundryGroupEditPage: {
    path: 'laundry-groups/:laundryGroupId/edit',
    googleAnalyticsTitle: 'serviceMaster Laundry Group Edit',
    Component: LaundryGroupCreatePage,
    permission: Permission.LAUNDRY_GROUP_WRITE,
  },

  LaundryListPage: {
    path: 'laundries',
    label: 'laundries',
    googleAnalyticsTitle: 'serviceMaster Laundries',
    Component: LaundryListPage,
    permission: Permission.LAUNDRY_READ,
    sectionLabel: SmMenuSections.OVERVIEWS,
    icon: (
      <SvgIcon>
        <LaundryIcon />
      </SvgIcon>
    ),
  },
  LaundryDetailsPage: {
    path: 'laundries/:laundryId/view',
    googleAnalyticsTitle: 'serviceMaster Laundry Details',
    Component: LaundryDetailsPage,
    permission: Permission.LAUNDRY_READ,
  },
  LaundryCreatePage: {
    path: 'laundries/create',
    googleAnalyticsTitle: 'serviceMaster Laundry Create',
    Component: LaundryCreatePage,
    permission: Permission.LAUNDRY_WRITE,
  },
  LaundryEditPage: {
    path: 'laundries/:laundryId/edit',
    googleAnalyticsTitle: 'serviceMaster Laundry Edit',
    Component: LaundryCreatePage,
    permission: Permission.LAUNDRY_WRITE,
  },

  MachineListPage: {
    path: 'machines',
    label: 'machines',
    googleAnalyticsTitle: 'serviceMaster Machines',
    Component: MachineListPage,
    permission: Permission.MACHINE_READ,
    sectionLabel: SmMenuSections.OVERVIEWS,
    icon: (
      <SvgIcon>
        <MachineIcon />
      </SvgIcon>
    ),
  },
  MachineUnconnectedCreatePage: {
    path: 'machines/create',
    googleAnalyticsTitle: 'serviceMaster Machine Create Unconnected',
    Component: MachineUnconnectedCreatePage,
    permission: Permission.MACHINE_WRITE,
  },
  MachineQrCodeScanner: {
    path: 'machines/:machineId/scanner',
    googleAnalyticsTitle: 'serviceMaster Machine QR Scanner',
    Component: MachineQrCodeScanner,
    permission: Permission.MACHINE_WRITE,
  },
  MachineDetailsPage: {
    path: 'machines/:machineId/view/:tabNameParam',
    googleAnalyticsTitle: 'serviceMaster Machine Details',
    Component: MachineDetailsPage,
    permission: Permission.MACHINE_READ,
  },
  MachineEditPage: {
    path: 'machines/:machineId/edit',
    googleAnalyticsTitle: 'serviceMaster Machine Edit',
    Component: MachineEditPage,
    permission: Permission.MACHINE_WRITE,
  },
  MachineProductDataPage: {
    path: 'machines/:machineId/manufacturer-model/:manufacturerModelId/',
    googleAnalyticsTitle: 'serviceMaster Machine Product Data',
    Component: MachineProductDataPage,
    permission: Permission.MACHINE_READ,
  },
  MachineProductDataPageProductOnly: {
    path: 'machines/manufacturer-model/:manufacturerModelId/',
    googleAnalyticsTitle: 'serviceMaster Machine Product Data',
    Component: MachineProductDataPage,
    permission: Permission.MACHINE_READ,
  },
  MachineProgramImportPage: {
    path: 'machines/:machineId/programs/:programTypeParam/import',
    googleAnalyticsTitle: 'serviceMaster Machine Program Import',
    Component: MachineProgramImportPage,
    permission: Permission.CUSTOM_PROGRAM_WRITE,
  },
  MachineProgramDetailsPage: {
    path: 'machines/:machineId/programs/:programTypeParam/:programId/view',
    googleAnalyticsTitle: 'serviceMaster Machine Program Details',
    Component: MachineProgramDetailsPage,
    permission: Permission.CUSTOM_PROGRAM_READ,
  },
  MachineParametersEditPage: {
    path: 'machines/:machineId/view/parameters/edit',
    googleAnalyticsTitle: 'Machine Parameters Edit',
    Component: MachineParametersEditPage,
    permission: Permission.MACHINE_WRITE_PARAMETERS,
  },

  MemberListPage: {
    path: 'members',
    label: 'members',
    googleAnalyticsTitle: 'serviceMaster Members',
    Component: MemberListPage,
    permission: Permission.MEMBER_READ,
    sectionLabel: SmMenuSections.USER_MANAGEMENT,
    icon: <Icon path={mdiAccountTieOutline} size={1} />,
  },
  MemberListPageTab: {
    path: 'members/:tabNameParam',
    googleAnalyticsTitle: 'serviceMaster Members',
    Component: MemberListPage,
    permission: Permission.MEMBER_READ,
  },
  MemberDetailsPage: {
    path: 'members/:memberId/view',
    googleAnalyticsTitle: 'serviceMaster Member Details',
    Component: MemberDetailsPage,
    permission: Permission.MEMBER_READ,
  },
  MemberEditPage: {
    path: 'members/:memberId/edit',
    googleAnalyticsTitle: 'serviceMaster Member Edit',
    Component: MemberEditPage,
    permission: Permission.MEMBER_WRITE,
  },

  ServicerListPage: {
    path: 'servicers',
    label: 'servicers',
    googleAnalyticsTitle: 'serviceMaster Servicers',
    Component: ServicerListPage,
    permission: Permission.SERVICE_MEMBER_READ,
    sectionLabel: SmMenuSections.USER_MANAGEMENT,
    icon: <Icon path={mdiAccountHardHatOutline} size={1} />,
  },
  ServicerListPageTab: {
    path: 'servicers/:tabNameParam',
    googleAnalyticsTitle: 'serviceMaster Servicers',
    Component: ServicerListPage,
    permission: Permission.SERVICE_MEMBER_READ,
  },
  ServicerDetailsPage: {
    path: 'servicers/:memberId/view',
    googleAnalyticsTitle: 'serviceMaster Servicer Details',
    Component: ServicerDetailsPage,
    permission: Permission.SERVICE_MEMBER_READ,
  },
  ServicerEditPage: {
    path: 'servicers/:memberId/edit',
    googleAnalyticsTitle: 'serviceMaster Servicer Edit',
    Component: ServicerEditPage,
    permission: Permission.SERVICE_MEMBER_WRITE,
  },

  ErrorLogListPage: {
    path: 'error-log',
    label: 'errorLog',
    googleAnalyticsTitle: 'serviceMaster Error Log',
    Component: ErrorLogListPage,
    permission: Permission.ERROR_LOG_READ,
    sectionLabel: SmMenuSections.MONITORING_SERVICE,
    icon: <Icon path={mdiAlertOutline} size={1} />,
  },
  ErrorLogDetailsPage: {
    path: 'error-log/:messageId/:timestamp/view/',
    googleAnalyticsTitle: 'serviceMaster Error Log Details',
    Component: ErrorLogDetailsPage,
    permission: Permission.ERROR_LOG_READ,
  },

  ServiceRequestListPage: {
    path: 'service-request',
    label: 'serviceRequests',
    googleAnalyticsTitle: 'serviceMaster Service Requests',
    Component: ServiceRequestListPage,
    permission: Permission.SERVICE_REQUEST_READ,
    sectionLabel: SmMenuSections.MONITORING_SERVICE,
    icon: <HomeRepairServiceOutlined />,
  },
  ServiceRequestDetailsPage: {
    path: 'service-request/:serviceRequestId/view',
    googleAnalyticsTitle: 'serviceMaster Service Request Details',
    Component: ServiceRequestDetailsPage,
    permission: Permission.SERVICE_REQUEST_READ,
  },
  ServiceRequestCreatePage: {
    path: 'service-request/create',
    googleAnalyticsTitle: 'serviceMaster Service Request Create',
    Component: ServiceRequestCreatePage,
    permission: Permission.SERVICE_REQUEST_WRITE,
  },

  ProgramUsageListPage: {
    path: 'program-usages/view',
    label: 'programUsages',
    googleAnalyticsTitle: 'serviceMaster Program Usages',
    Component: ProgramUsageListPage,
    permission: Permission.UTILIZATION_READ,
    sectionLabel: SmMenuSections.REPORTS_ANALYSES,
    icon: <FormatListNumberedRtl />,
  },

  ProgramUsageListPageTab: {
    path: 'program-usages/view/:tabNameParam',
    googleAnalyticsTitle: 'serviceMaster Program Usages',
    Component: ProgramUsageListPage,
    permission: Permission.UTILIZATION_READ,
  },

  ConsumptionListPage: {
    path: 'consumption/view',
    label: 'consumption',
    googleAnalyticsTitle: 'serviceMaster Consumption',
    Component: ConsumptionListPage,
    permission: Permission.CONSUMPTION_READ,
    sectionLabel: SmMenuSections.REPORTS_ANALYSES,
    icon: <SpaOutlined />,
  },

  ConsumptionListPageTab: {
    path: 'consumption/view/:tabNameParam',
    googleAnalyticsTitle: 'serviceMaster Consumption',
    Component: ConsumptionListPage,
    permission: Permission.CONSUMPTION_READ,
  },

  UtilizationListPage: {
    path: 'utilization/view',
    label: 'utilization',
    googleAnalyticsTitle: 'serviceMaster Utilization',
    Component: UtilizationListPage,
    permission: Permission.UTILIZATION_READ,
    sectionLabel: SmMenuSections.REPORTS_ANALYSES,
    icon: <MonitorHeartOutlined />,
  },

  UtilizationListPageTab: {
    path: 'utilization/view/:tabNameParam',
    googleAnalyticsTitle: 'serviceMaster Utilization',
    Component: UtilizationListPage,
    permission: Permission.UTILIZATION_READ,
  },

  HygieneListPage: {
    path: 'hygiene',
    label: 'hygiene',
    googleAnalyticsTitle: 'serviceMaster Hygiene',
    Component: HygieneListPage,
    permission: Permission.HYGIENEMONITORING_READ,
    sectionLabel: SmMenuSections.REPORTS_ANALYSES,
    icon: <CleanHandsOutlined />,
  },
  HygieneDetailsPage: {
    path: 'hygiene/:hygieneId/view',
    googleAnalyticsTitle: 'serviceMaster Hygiene Details',
    Component: HygieneDetailsPage,
    permission: Permission.HYGIENEMONITORING_READ,
  },

  ...APP_SHARED_ROUTES,
}
