import React, { FC, ReactElement, useContext } from 'react'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { Box, Button, Grid } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  LaundriesApi,
  LaundryGroupReference,
  ManualPairingInfo,
  Manufacturer,
  ManufacturerModelReference,
  ManufacturerModelsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { MachinePairManualPostViewModel } from 'src/service/view-model/machine/MachinePairViewModel'
import { getMachineTypesAsOptions } from 'src/service/view-model/machine/Machines'
import { AsyncAutoCompleteValidate } from 'src/ui-shared/base/form/control/AsyncAutoCompleteValidate'
import { AutoCompleteField } from 'src/ui-shared/base/form/control/AutoCompleteField'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import {
  composeValidators,
  required,
  validAmountMax,
  validAmountMin,
  validNumber,
} from 'src/ui-shared/base/form/validation/Validators'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

interface Props {
  setOpenModal: (value: boolean) => void
  onSubmit: (values: MachinePairManualPostViewModel) => void
  laundryGroupRef: LaundryGroupReference
  manualPairingInfo: ManualPairingInfo
  isSuccess: boolean
}

export const MachinePairManualForm: FC<Props> = ({
  setOpenModal,
  onSubmit,
  laundryGroupRef,
  manualPairingInfo,
  isSuccess,
}): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundriesApi = new LaundriesApi(httpConfiguration)
  const manufacturersApi = new ManufacturerModelsApi(httpConfiguration)

  // load data
  const laundrySearch = (searchText: string): Promise<LaundryGroupReference[]> => {
    return laundriesApi.laundriesRefGet(undefined, searchText, [laundryGroupRef.id])
  }

  const manufacturersSearch = (searchText: string): Promise<Manufacturer[]> => {
    return manufacturersApi.manufacturersRefGet(undefined, searchText)
  }

  const manufacturerModelsSearch = (
    searchText: string,
    manufacturerId?: string,
  ): Promise<ManufacturerModelReference[]> => {
    if (manufacturerId) {
      return manufacturersApi.manufacturerModelsRefGet(undefined, searchText, manufacturerId)
    }
    return Promise.resolve([])
  }

  return (
    <Box mt={2}>
      <Form<Partial<MachinePairManualPostViewModel>>
        initialValues={{}}
        onSubmit={onSubmit as (values: Partial<MachinePairManualPostViewModel>) => void}
        render={({ handleSubmit, submitting, pristine, values, form }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label={translate('machineName')}
                    fullWidth
                    autoFocus
                    disabled={submitting}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutoCompleteField
                    disabled={submitting}
                    label={translate('member.machinetype')}
                    name="type"
                    options={getMachineTypesAsOptions(manualPairingInfo.availableMachineTypes, translate)}
                    validate={required()}
                  />
                </Grid>

                <Grid item {...ITEM_BREAKPOINTS}>
                  <TextField name="serialNumber" label={translate('serialNumber')} fullWidth validate={required()} />
                </Grid>
                <Grid item {...ITEM_BREAKPOINTS}>
                  <TextField
                    name="drumSize"
                    label={translate('drumSize')}
                    fullWidth
                    validate={composeValidators(
                      validNumber(),
                      validAmountMin(undefined, 1),
                      validAmountMax(undefined, 99),
                    )}
                  />
                </Grid>

                <Grid item {...ITEM_BREAKPOINTS}>
                  <AsyncAutoCompleteValidate
                    disabled={submitting}
                    name="manufacturer"
                    validate={required()}
                    label={translate('manufacturer')}
                    delay={300}
                    labelFieldName="name"
                    loadOptionsFunction={manufacturersSearch}
                  />
                  <OnChange name="manufacturer">
                    {() => {
                      setTimeout(() => {
                        if (!values.manufacturer) {
                          form.change('manufacturerModel', undefined)
                        }
                      }, 0)
                    }}
                  </OnChange>
                </Grid>
                <Grid item {...ITEM_BREAKPOINTS}>
                  <AsyncAutoCompleteValidate
                    key={`${values.manufacturer?.id}`}
                    disabled={submitting || !values.manufacturer}
                    name="manufacturerModel"
                    validate={required()}
                    label={translate('manufacturerModel')}
                    delay={300}
                    labelFieldName="modelName"
                    getOptionLabel={(item) => {
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      if (!item) {
                        return ''
                      }
                      const modelRef = item as ManufacturerModelReference
                      return modelRef.modelName + ' (' + modelRef.productNumber + ')'
                    }}
                    minCharsForSearch={0}
                    loadOptionsFunction={(search) => manufacturerModelsSearch(search, values.manufacturer?.id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AsyncAutoCompleteValidate
                    disabled={submitting}
                    name="laundryRef"
                    validate={required()}
                    label={translate('laundry')}
                    delay={300}
                    labelFieldName="name"
                    loadOptionsFunction={laundrySearch}
                  />
                </Grid>
              </Grid>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  color="primary"
                  size="large"
                  className={sharedClasses.ButtonMargin}
                  onClick={() => setOpenModal(false)}
                >
                  {translate('button.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={pristine || submitting || isSuccess}
                >
                  {translate('button.pair')}
                </Button>
              </Box>
            </form>
          )
        }}
      />
    </Box>
  )
}
