/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MeInitializeResponseDataButton } from './MeInitializeResponseDataButton';
import {
    MeInitializeResponseDataButtonFromJSON,
    MeInitializeResponseDataButtonFromJSONTyped,
    MeInitializeResponseDataButtonToJSON,
} from './MeInitializeResponseDataButton';

/**
 * When 'result' is not 'OK', data is used to display information
 * @export
 * @interface MeInitializeResponseData
 */
export interface MeInitializeResponseData {
    /**
     * 
     * @type {string}
     * @memberof MeInitializeResponseData
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MeInitializeResponseData
     */
    message: string;
    /**
     * 
     * @type {MeInitializeResponseDataButton}
     * @memberof MeInitializeResponseData
     */
    button?: MeInitializeResponseDataButton | null;
}

/**
 * Check if a given object implements the MeInitializeResponseData interface.
 */
export function instanceOfMeInitializeResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function MeInitializeResponseDataFromJSON(json: any): MeInitializeResponseData {
    return MeInitializeResponseDataFromJSONTyped(json, false);
}

export function MeInitializeResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeInitializeResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'message': json['message'],
        'button': !exists(json, 'button') ? undefined : MeInitializeResponseDataButtonFromJSON(json['button']),
    };
}

export function MeInitializeResponseDataToJSON(value?: MeInitializeResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'message': value.message,
        'button': MeInitializeResponseDataButtonToJSON(value.button),
    };
}

