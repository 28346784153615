import React, { FC, ReactElement, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LaundryGroupPriceListElementIntegrated, LaundryGroupPriceListIntegrated } from 'src/service/backend/api'
import { formatAmountForLocale } from 'src/service/utils/NumberFormatUtils'
import { getDrumSizeLabel, getMachineTypeName } from 'src/service/view-model/machine/Machines'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'
import { useUserRegionLocale } from 'src/user/UserContext'

interface HeadCells {
  id: keyof LaundryGroupPriceListElementIntegrated | string
  label: string
}

export const PRICE_LIST_INTERNAL_HEADCELLS: HeadCells[] = [
  {
    id: 'index',
    label: 'programInfo.index',
  },
  {
    id: 'name',
    label: 'program',
  },
  {
    id: 'currency',
    label: 'currency',
  },
  {
    id: 'regularAmount',
    label: 'priceStandard',
  },
  {
    id: 'happyHourAmount',
    label: 'priceHappyHour',
  },
]

const headCells: HeadCells[] = PRICE_LIST_INTERNAL_HEADCELLS

interface Props {
  priceList: LaundryGroupPriceListIntegrated | null | undefined
}

export const LaundryGroupPriceListInternalView: FC<Props> = ({ priceList }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const { classes: sharedClasses } = useSharedStyles()
  const { state } = useLocation()
  const regionLocale = useUserRegionLocale()

  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  // Show snackbar if there were changes in the prices
  useEffect(() => {
    if (state && state.showSnackbar) {
      const infoMessage = translate('appliedPriceChanges')
      showSnackbar(infoMessage, 'info')

      // Removes the State in the url without re-render, if we don't do this if we reload the page it shows the snackbar again
      window.history.replaceState({}, document.title)
    }
  }, [])

  const prices = priceList?.prices as Array<LaundryGroupPriceListElementIntegrated>

  // JSX
  const displayRows =
    prices.length > 0 ? (
      prices.map((item, index) => {
        return (
          <TableRow className={tableClasses.tableRow} key={index}>
            <TableCell width="5%">{item.index}</TableCell>
            <TableCell width="60%">{item.name}</TableCell>
            <TableCell width="5%">{priceList?.currency}</TableCell>
            <TableCell width="15%">
              {formatAmountForLocale(item.regularAmount, regionLocale, priceList?.currency)}
            </TableCell>
            <TableCell width="15%">
              {formatAmountForLocale(item.happyHourAmount, regionLocale, priceList?.currency)}
            </TableCell>
          </TableRow>
        )
      })
    ) : (
      <TableEmpty colspan={headCells.length} />
    )

  const displayHeaders = headCells.map((item) => {
    return <TableCell key={item.id}>{translate(item.label)}</TableCell>
  })

  const displayTable = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{displayHeaders}</TableRow>
        </TableHead>
        <TableBody>{displayRows}</TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <>
      <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
        <Grid item xs={3}>
          <DetailsTextField
            value={getMachineTypeName(priceList?.machineType, translate)}
            label={translate('assignedMachineType')}
          />
        </Grid>
        <Grid item xs={3}>
          <DetailsTextField value={priceList?.name} label={translate('member.pricelist')} />
        </Grid>
        <Grid item xs={3}>
          <DetailsTextField
            value={getDrumSizeLabel(priceList?.drumSize, priceList?.drumSizeUnit)}
            label={translate('member.machinesize')}
          />
        </Grid>
        <Grid item xs={3}>
          <DetailsTextField value={priceList?.currency} label={translate('currency')} />
        </Grid>
      </Grid>
      <Divider />
      {displayTable}
    </>
  )
}
