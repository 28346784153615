/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { DatatransPaymentMethod } from './DatatransPaymentMethod';
import {
    DatatransPaymentMethodFromJSON,
    DatatransPaymentMethodFromJSONTyped,
    DatatransPaymentMethodToJSON,
} from './DatatransPaymentMethod';
import type { LaundryUserReference } from './LaundryUserReference';
import {
    LaundryUserReferenceFromJSON,
    LaundryUserReferenceFromJSONTyped,
    LaundryUserReferenceToJSON,
} from './LaundryUserReference';
import type { ReportTopUpType } from './ReportTopUpType';
import {
    ReportTopUpTypeFromJSON,
    ReportTopUpTypeFromJSONTyped,
    ReportTopUpTypeToJSON,
} from './ReportTopUpType';

/**
 * 
 * @export
 * @interface TopUp
 */
export interface TopUp {
    /**
     * 
     * @type {string}
     * @memberof TopUp
     */
    readonly transactionId: string;
    /**
     * 
     * @type {LaundryUserReference}
     * @memberof TopUp
     */
    laundryUser: LaundryUserReference;
    /**
     * 
     * @type {ReportTopUpType}
     * @memberof TopUp
     */
    reportTopUpType: ReportTopUpType;
    /**
     * 
     * @type {number}
     * @memberof TopUp
     */
    amount: number;
    /**
     * 
     * @type {Currency}
     * @memberof TopUp
     */
    currency: Currency;
    /**
     * 
     * @type {Date}
     * @memberof TopUp
     */
    settledDate: Date;
    /**
     * Banking Reference Number
     * @type {string}
     * @memberof TopUp
     */
    readonly bankingReference?: string;
    /**
     * 
     * @type {DatatransPaymentMethod}
     * @memberof TopUp
     */
    datatransPaymentMethod?: DatatransPaymentMethod;
}

/**
 * Check if a given object implements the TopUp interface.
 */
export function instanceOfTopUp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transactionId" in value;
    isInstance = isInstance && "laundryUser" in value;
    isInstance = isInstance && "reportTopUpType" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "settledDate" in value;

    return isInstance;
}

export function TopUpFromJSON(json: any): TopUp {
    return TopUpFromJSONTyped(json, false);
}

export function TopUpFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopUp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionId': json['transactionId'],
        'laundryUser': LaundryUserReferenceFromJSON(json['laundryUser']),
        'reportTopUpType': ReportTopUpTypeFromJSON(json['reportTopUpType']),
        'amount': json['amount'],
        'currency': CurrencyFromJSON(json['currency']),
        'settledDate': (new Date(json['settledDate'])),
        'bankingReference': !exists(json, 'bankingReference') ? undefined : json['bankingReference'],
        'datatransPaymentMethod': !exists(json, 'datatransPaymentMethod') ? undefined : DatatransPaymentMethodFromJSON(json['datatransPaymentMethod']),
    };
}

export function TopUpToJSON(value?: TopUp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'laundryUser': LaundryUserReferenceToJSON(value.laundryUser),
        'reportTopUpType': ReportTopUpTypeToJSON(value.reportTopUpType),
        'amount': value.amount,
        'currency': CurrencyToJSON(value.currency),
        'settledDate': (value.settledDate.toISOString()),
        'datatransPaymentMethod': DatatransPaymentMethodToJSON(value.datatransPaymentMethod),
    };
}

