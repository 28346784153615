import React, { ReactElement, Suspense, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Autocomplete, Box, Grid, Typography } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { ChartData, Configuration, ReportConsumptionsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { findItemAndSelect } from 'src/service/view-model/base/Reference.utils'
import { CHART_SCALE_OBJECTS, getChartScaleObject } from 'src/service/view-model/base/chart/Chart.const'
import { MACHINE_CATEGORIES, MachineCategoryObject } from 'src/service/view-model/machine/Machines'
import {
  CONSUMPTION_CHART_COST_FACTTYPE_OBJECTS,
  CONSUMPTION_CHART_GROUP_BY_OBJECTS,
  ConsumptionCostChartSettings,
  DEFAULT_CONSUMPTION_COST_CHART_SETTINGS,
  getConsumptionCostFactTypeObject,
  getConsumptionGroupByObject,
} from 'src/service/view-model/usage-chart/ConsumptionChartViewModel'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { useDataSettingsUrlSync } from 'src/ui-shared/base/hooks/useDataSettingsUrlSync'
import { ChartDataFilter } from 'src/ui-shared/chart/ChartDataFilter'
import { ChartDataSelection } from 'src/ui-shared/chart/ChartDataSelection'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { withOrganization } from 'src/ui-shared/table/Table.const'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'

const AsyncChart = React.lazy(() => import('src/ui-shared/chart/Chart').then(({ Chart }) => ({ default: Chart })))

export const ConsumptionCostChart = (): ReactElement => {
  const { classes: textFieldClasses } = useTextFieldStyles()

  const location = useLocation()
  const activeOrganization = useActiveOrganization()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportConsumptionApi = new ReportConsumptionsApi(httpConfiguration)

  const translate = useTranslate()

  // state
  const defaultConsumptionCostChartSettings: ConsumptionCostChartSettings = DEFAULT_CONSUMPTION_COST_CHART_SETTINGS
  const [consumptionCostChartSettings, setConsumptionTotalsChartSettings] = useState<ConsumptionCostChartSettings>(
    withOrganization(defaultConsumptionCostChartSettings, activeOrganization),
  )
  const [machineCategory, setMachineCategory] = useState<MachineCategoryObject | null>(null)

  useEffect(() => {
    findItemAndSelect(
      consumptionCostChartSettings.machineCategory,
      setMachineCategory,
      machineCategory,
      MACHINE_CATEGORIES,
    )
  }, [consumptionCostChartSettings.machineCategory])

  const loadChart = (): Promise<ChartData> => {
    const dateStartTimeFrom = new Date(consumptionCostChartSettings.startDateFrom)
    const dateStartTimeTo = new Date(consumptionCostChartSettings.startDateTo)

    return reportConsumptionApi.reportSmConsumptionCostsChartGet(
      consumptionCostChartSettings.groupBy,
      consumptionCostChartSettings.factType,
      consumptionCostChartSettings.chartScale,
      dateStartTimeFrom,
      dateStartTimeTo,
      undefined,
      consumptionCostChartSettings.organizationId,
      consumptionCostChartSettings.laundryGroupId,
      consumptionCostChartSettings.laundryId,
      consumptionCostChartSettings.machineId,
      consumptionCostChartSettings.machineCategory,
    )
  }

  // generic reactivity

  // update state from url / apply state to url
  useDataSettingsUrlSync(
    location,
    setConsumptionTotalsChartSettings,
    consumptionCostChartSettings,
    defaultConsumptionCostChartSettings,
  )

  const handleMachineCategoryChange = (
    ITEM_BREAKPOINTS: unknown,
    machineCategoryObject: MachineCategoryObject | null,
  ) => {
    setMachineCategory(machineCategoryObject)
    setConsumptionTotalsChartSettings({
      ...consumptionCostChartSettings,
      machineCategory: machineCategoryObject?.id,
    })
  }

  return (
    <Box mt={1}>
      {/* Groupings */}
      <Box mt={1}>
        {machineCategory?.id}
        {/*Input fields for data selection: factType, groupBy and chartScale*/}
        <ChartDataSelection
          chartSettings={consumptionCostChartSettings}
          setChartSettings={setConsumptionTotalsChartSettings}
          factType={'factType'}
          groupBy={'groupBy'}
          scale={'chartScale'}
          factTypeOptions={CONSUMPTION_CHART_COST_FACTTYPE_OBJECTS}
          factTypeOptionsLabel={(option) => translate(option.name)}
          factTypeValue={getConsumptionCostFactTypeObject}
          groupByOptions={CONSUMPTION_CHART_GROUP_BY_OBJECTS}
          groupByOptionsLabel={(option) => translate(option.name)}
          groupByValue={getConsumptionGroupByObject}
          scaleOptions={CHART_SCALE_OBJECTS}
          scaleOptionsLabel={(option) => translate(option.name)}
          scaleValue={getChartScaleObject}
        >
          {/*Input fields for selecting date range */}
          <TableDatePickerForm
            key={`${consumptionCostChartSettings.startDateFrom}${consumptionCostChartSettings.startDateTo}`}
            firstDatePropName="startDateFrom"
            secondDatePropName="startDateTo"
            tableSettings={consumptionCostChartSettings}
            setTableSettings={setConsumptionTotalsChartSettings}
          />
        </ChartDataSelection>
      </Box>

      {/* Filters */}
      <Box mt={1}>
        {/*Input fields for data filter: organization, laundry group, laundry and machine */}
        <ChartDataFilter
          chartSettings={consumptionCostChartSettings}
          setChartSettings={setConsumptionTotalsChartSettings}
          organization={'organizationId'}
          laundryGroup={'laundryGroupId'}
          laundry={'laundryId'}
          machine={'machineId'}
          sameLine
          defaultExpanded
          preselectFirstOrganizationNoActive={true}
          additionalFilterKeys={['machineCategory']}
        >
          <Grid item {...ITEM_BREAKPOINTS} lg={true}>
            <Autocomplete
              openOnFocus={true}
              multiple={false}
              freeSolo={false}
              disableClearable={false}
              options={MACHINE_CATEGORIES}
              getOptionLabel={(option) => translate(option.name) || ''}
              isOptionEqualToValue={(option) => option.id === consumptionCostChartSettings.machineCategory}
              value={machineCategory}
              onChange={handleMachineCategoryChange}
              noOptionsText={translate('autocompleteNoOptions')}
              loadingText={translate('autocompleteLoading')}
              renderInput={(params) => (
                <TextFieldDefault
                  {...params}
                  label={translate('machineCategory')}
                  className={textFieldClasses.TextFieldSmall}
                />
              )}
            />
          </Grid>
        </ChartDataFilter>
      </Box>
      <Suspense fallback={<div style={{ textAlign: 'center' }}>{translate('autocompleteLoading')}</div>}>
        <Box width="100%">
          <AsyncChart loadChart={loadChart} chartSettings={consumptionCostChartSettings} />
        </Box>
        <Box mt={2}>
          <Typography variant="caption">*{translate('consumptionValuesApproximations')}</Typography>
        </Box>
      </Suspense>
    </Box>
  )
}
