import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Divider, Grid, Paper } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, MachineType, ProgramsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { ProgramTypeFilter } from 'src/service/view-model/program/ProgramViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { StyledTabs } from 'src/ui-shared/base/tab-panel/StyledTabs'
import { TabPanel } from 'src/ui-shared/base/tab-panel/TabPanel'
import { getGridTabBreakpointsForDynamicTabs } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { generateMachineTypeTabs } from 'src/ui/page/es/program/MachineTypeTabs'
import { ProgramDetailsTab } from 'src/ui/page/es/program/details/ProgramDetailsTab'
import { ProgramTable } from 'src/ui/page/es/program/list/ProgramTable'

export const ProgramListPage = (): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const appId = useAppId()

  const { tabNameParam } = useParams()
  const tabName = tabNameParam ? getEnumFromString(tabNameParam, MachineType) : undefined

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programsApi = new ProgramsApi(httpConfiguration)

  const [activeTab, setActiveTab] = useState<MachineType>()
  const [tabs, setTabs] = useState<MachineType[]>()
  const [programTypeFilter, setProgramTypeFilter] = useState<ProgramTypeFilter>('all')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  // load data
  useEffect(() => {
    setActiveTab(tabName)
  }, [])

  useEffect(() => {
    let active = true
    setLoading(true)

    programsApi
      .programsEditableMachineTypesGet()
      .then((data) => {
        if (active) {
          setTabs(data.map((item) => item.machineType))
          setInitialActiveTab(data.map((item) => item.machineType))
          setLoading(false)
          setErrorMessage(null)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLoading(false)
        setTabs([])
      })

    return () => {
      active = false
    }
  }, [])

  const setInitialActiveTab = (data: MachineType[]) => {
    // if on initial render tab is not selected, select the first available one
    if (!tabName && data.length > 0) {
      handleTabChange({} as React.SyntheticEvent, data[0])
    }
  }

  // handle events
  const handleTabChange = (_: React.SyntheticEvent, newValue: MachineType) => {
    const pathToNavigate = `/${appId}/programs/${newValue}/`

    navigate(pathToNavigate, { replace: true })

    // reset query parameters when switching tab
    location.search = ''

    setActiveTab(newValue)
  }

  const handleRowClick = (programId?: string) => {
    navigate(`/${appId}/programs/${activeTab}/${programId}/view/${ProgramDetailsTab.SETTINGS}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const generateTabsContent = () => {
    if (!tabs) {
      return <></>
    }
    return tabs.map((item) => (
      <TabPanel key={item} value={activeTab ?? ''} index={item}>
        <ProgramTable
          key={item}
          machineType={item}
          navigateToItem={handleRowClick}
          programTypeFilter={programTypeFilter}
          setProgramType={setProgramTypeFilter}
        />
      </TabPanel>
    ))
  }

  return (
    <ScreenLayout title={translate('programs')} actionsWidth={170}>
      <Paper elevation={0}>
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          <>
            {errorMessage ? (
              <ErrorMessage message={errorMessage} />
            ) : (
              <>
                <Grid container className={sharedClasses.TabsContainer}>
                  <Grid item {...getGridTabBreakpointsForDynamicTabs(tabs?.length)}>
                    {tabs && tabs.length > 0 ? (
                      <StyledTabs
                        value={activeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        onChange={handleTabChange}
                      >
                        {generateMachineTypeTabs(translate, tabs)}
                      </StyledTabs>
                    ) : null}
                  </Grid>
                </Grid>
                <Divider />
                {generateTabsContent()}
              </>
            )}
          </>
        )}
      </Paper>
    </ScreenLayout>
  )
}
