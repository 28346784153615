import { Address, Country } from 'src/service/backend/api'
import { CountryObject, getCountryObject } from 'src/service/view-model/base/country/Countries'

export interface ViewModelWithAddress {
  addressLine1: string
  addressLine2: string
  zipCode: string
  city: string
  latitude: string
  longitude: string
  countryCode?: CountryObject
}

export function mapAddress(viewModel: ViewModelWithAddress): Address {
  const countryCodeEnum = viewModel.countryCode as CountryObject | undefined
  const countryCodeString: string | undefined = countryCodeEnum ? countryCodeEnum.code : undefined

  const result: Address = {
    addressLine1: viewModel.addressLine1 || undefined,
    addressLine2: viewModel.addressLine2 || undefined,
    city: viewModel.city || undefined,
    zipCode: viewModel.zipCode || undefined,
    // TODO pst: country is not optional any more in the API, check if countryCodeString can ever be undefined
    countryCode: countryCodeString ? (countryCodeString as Country) : Country.CH,
    latitude: viewModel.latitude ? parseFloat(viewModel.latitude) : undefined,
    longitude: viewModel.longitude ? parseFloat(viewModel.longitude) : undefined,
  }

  return result
}

export function loadAddressViewModel(address?: Address): ViewModelWithAddress {
  const result: ViewModelWithAddress = {
    addressLine1: address?.addressLine1 || '',
    addressLine2: address?.addressLine2 || '',
    zipCode: address?.zipCode || '',
    city: address?.city || '',
    latitude: address?.latitude?.toString() || '',
    longitude: address?.longitude?.toString() || '',
    countryCode: getCountryObject(address?.countryCode),
  }
  return result
}
