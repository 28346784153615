import React, { FC, ReactElement } from 'react'
import { Field, Form } from 'react-final-form'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LaundryGroupRecurringCalendarEntry, OpeningType, PriceCondition } from 'src/service/backend/api'
import {
  LaundryGroupRecurringCalendarViewModel,
  createLaundryGroupRecurringCalendarViewModel,
  mapLaundryGroupRecurringViewModelToCalendarEntry,
} from 'src/service/view-model/laundry-group/LaundryGroupRecurringCalendarViewModel'
import { CheckboxField } from 'src/ui-shared/base/form/control/CheckboxField'
import { TimeTextFieldValidate } from 'src/ui-shared/base/form/control/TimeTextFieldValidate'
import {
  composeValidators,
  required,
  validSlotEndTime,
  validSlotTime,
} from 'src/ui-shared/base/form/validation/Validators'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { isTwelveHourFormat } from 'src/user/RegionDataUtils'
import { useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  slot: LaundryGroupRecurringCalendarEntry
  updateRecurringCalendar: (values: LaundryGroupRecurringCalendarEntry) => Promise<void>
  closeModal: () => void
}

export const LaundryGroupCalendarEditSlot: FC<Props> = ({
  slot,
  updateRecurringCalendar,
  closeModal,
}): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const appId = useAppId()
  const regionLocale = useUserRegionLocale()

  const submitForm = (viewModel: LaundryGroupRecurringCalendarViewModel) => {
    const calendarEntry = mapLaundryGroupRecurringViewModelToCalendarEntry(viewModel)
    closeModal()
    return updateRecurringCalendar(calendarEntry)
  }

  const noAdHockOpeningType = appId === AppId.SERVICE_MASTER
  const noPriceConditionOptions = appId === AppId.SERVICE_MASTER
  const viewModel = createLaundryGroupRecurringCalendarViewModel(slot, noAdHockOpeningType)

  return (
    <Box p={3}>
      <Form
        initialValues={viewModel}
        onSubmit={submitForm}
        render={({ handleSubmit, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Box my={2}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TimeTextFieldValidate
                      name="startTime"
                      label={translate('startTime')}
                      fullWidth
                      disabled={values.allDay}
                      blank={values.allDay}
                      blankValue="00:00"
                      twelveHourFormat={isTwelveHourFormat(regionLocale)}
                      validate={composeValidators(required(), validSlotTime())}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TimeTextFieldValidate
                      name="endTime"
                      label={translate('endTime')}
                      fullWidth
                      disabled={values.allDay}
                      blank={values.allDay}
                      blankValue="00:00"
                      twelveHourFormat={isTwelveHourFormat(regionLocale)}
                      validate={composeValidators(required(), validSlotTime(), validSlotEndTime())}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxField name="allDay" label={translate('allDay')} color="primary" />
                  </Grid>
                </Grid>
              </Box>
              <Box my={2}>
                <Field
                  name="openingType"
                  render={(props) => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        <Typography variant="h6">
                          {appId === AppId.SERVICE_MASTER
                            ? translate('laundryAvailability')
                            : translate('reservability')}
                        </Typography>
                      </FormLabel>
                      <RadioGroup aria-label="reservability" {...props.input}>
                        <FormControlLabel
                          value={OpeningType.FREE}
                          control={<Radio color="primary" />}
                          label={appId === AppId.SERVICE_MASTER ? translate('laundryOpen') : translate('freeSlot')}
                        />
                        {noAdHockOpeningType ? null : (
                          <FormControlLabel
                            value={OpeningType.AD_HOC}
                            control={<Radio color="primary" />}
                            label={translate('adHoc')}
                          />
                        )}
                        <FormControlLabel
                          value={OpeningType.CLOSED}
                          control={<Radio color="primary" />}
                          label={appId === AppId.SERVICE_MASTER ? translate('laundryClosed') : translate('blockedSlot')}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Box>

              {noPriceConditionOptions ? null : (
                <Box my={2}>
                  <Field
                    name="priceCondition"
                    render={(props) => (
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          <Typography variant="h6">{translate('laundryGroupPrices')}</Typography>
                        </FormLabel>
                        <RadioGroup aria-label="price Type" {...props.input}>
                          <FormControlLabel
                            value={PriceCondition.REGULAR}
                            control={<Radio color="primary" />}
                            label={translate('normal')}
                          />
                          <FormControlLabel
                            value={PriceCondition.HAPPY}
                            control={<Radio color="primary" />}
                            label={translate('happyHour')}
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                </Box>
              )}
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  color="primary"
                  size="large"
                  className={sharedClasses.ButtonMargin}
                  onClick={closeModal}
                >
                  {translate('button.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  {translate('apply')}
                </Button>
              </Box>
            </form>
          )
        }}
      />
    </Box>
  )
}
