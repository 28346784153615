/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeseriesValue
 */
export interface TimeseriesValue {
    /**
     * contains the time when NUMBER happened or STATE starts
     * @type {Date}
     * @memberof TimeseriesValue
     */
    startTime: Date;
    /**
     * contains an optional endTime when ValueType is STATE
     * @type {Date}
     * @memberof TimeseriesValue
     */
    endTime?: Date | null;
    /**
     * contains a State string when ValueType is STATE
     * @type {string}
     * @memberof TimeseriesValue
     */
    valueState?: string | null;
    /**
     * contains a number string when ValueType is NUMBER
     * @type {number}
     * @memberof TimeseriesValue
     */
    valueNumber?: number | null;
}

/**
 * Check if a given object implements the TimeseriesValue interface.
 */
export function instanceOfTimeseriesValue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startTime" in value;

    return isInstance;
}

export function TimeseriesValueFromJSON(json: any): TimeseriesValue {
    return TimeseriesValueFromJSONTyped(json, false);
}

export function TimeseriesValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeseriesValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startTime': (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (json['endTime'] === null ? null : new Date(json['endTime'])),
        'valueState': !exists(json, 'valueState') ? undefined : json['valueState'],
        'valueNumber': !exists(json, 'valueNumber') ? undefined : json['valueNumber'],
    };
}

export function TimeseriesValueToJSON(value?: TimeseriesValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startTime': (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime === null ? null : value.endTime.toISOString()),
        'valueState': value.valueState,
        'valueNumber': value.valueNumber,
    };
}

