import React from 'react'
import { Tooltip, Typography } from '@mui/material'
import { getTextLength, truncateText } from 'src/service/utils/TextUtils'

interface TruncatedToolTipProps {
  value?: string | null
  maxChars: number
  style?: React.CSSProperties | undefined
}

export const TruncatedTooltip: React.FC<TruncatedToolTipProps> = ({ value, maxChars, style }) => {
  const length = getTextLength(value)
  return length > maxChars ? (
    <Tooltip title={<Typography variant="body2">{value}</Typography>} placement="bottom">
      <div style={style}>{truncateText(maxChars, value)}</div>
    </Tooltip>
  ) : (
    <div style={style}>{value}</div>
  )
}
