/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProgramModuleSettings } from './ProgramModuleSettings';
import {
    ProgramModuleSettingsFromJSON,
    ProgramModuleSettingsFromJSONTyped,
    ProgramModuleSettingsToJSON,
} from './ProgramModuleSettings';
import type { SettingField } from './SettingField';
import {
    SettingFieldFromJSON,
    SettingFieldFromJSONTyped,
    SettingFieldToJSON,
} from './SettingField';

/**
 * 
 * @export
 * @interface ProgramDetails
 */
export interface ProgramDetails {
    /**
     * 
     * @type {Array<SettingField>}
     * @memberof ProgramDetails
     */
    programSettings: Array<SettingField>;
    /**
     * If yes, modules/steps/order are fixed/readOnly. If false modules/steps/order can be changed.
     * @type {boolean}
     * @memberof ProgramDetails
     */
    readonly fixed: boolean;
    /**
     * 
     * @type {Array<ProgramModuleSettings>}
     * @memberof ProgramDetails
     */
    steps: Array<ProgramModuleSettings>;
}

/**
 * Check if a given object implements the ProgramDetails interface.
 */
export function instanceOfProgramDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "programSettings" in value;
    isInstance = isInstance && "fixed" in value;
    isInstance = isInstance && "steps" in value;

    return isInstance;
}

export function ProgramDetailsFromJSON(json: any): ProgramDetails {
    return ProgramDetailsFromJSONTyped(json, false);
}

export function ProgramDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'programSettings': ((json['programSettings'] as Array<any>).map(SettingFieldFromJSON)),
        'fixed': json['fixed'],
        'steps': ((json['steps'] as Array<any>).map(ProgramModuleSettingsFromJSON)),
    };
}

export function ProgramDetailsToJSON(value?: ProgramDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'programSettings': ((value.programSettings as Array<any>).map(SettingFieldToJSON)),
        'steps': ((value.steps as Array<any>).map(ProgramModuleSettingsToJSON)),
    };
}

