const TABLE_PAGE_SIZE_STORAGE_KEY = 'default_page_size'
const DRAWER_MINIMIZED_STORAGE_KEY = 'drawer_minimized'
const ONBOARDING_SEEN_STORAGE_KEY = 'onboarding_seen'
const ADD_TO_HOME_TOOLTIP_LAST_SEEN = 'add_to_home_last_seen'
const ACTIVE_ORGANIZATION_STORAGE_KEY = 'active_org'

export const storeTablePageSizeToStorage = (pageSize: number): void => {
  localStorage.setItem(TABLE_PAGE_SIZE_STORAGE_KEY, pageSize.toString())
}

export const getTablePageSizeFromStorage = (): number | undefined => {
  let pageSize
  const pageSizeFromStorage = localStorage.getItem(TABLE_PAGE_SIZE_STORAGE_KEY)
  if (pageSizeFromStorage) {
    const parsedPageSizeFromStorage = parseInt(pageSizeFromStorage)
    if (!isNaN(parsedPageSizeFromStorage)) {
      pageSize = parsedPageSizeFromStorage
    }
  }
  return pageSize
}

export const storeIsDrawerMinimizedToStorage = (drawerMinimized: boolean): void => {
  localStorage.setItem(DRAWER_MINIMIZED_STORAGE_KEY, drawerMinimized.toString())
}

export const getIsDrawerMinimizedFromStorage = (): boolean => {
  let isDrawerMinimized = false
  const drawerMinimizedFromStorage = localStorage.getItem(DRAWER_MINIMIZED_STORAGE_KEY)
  if (drawerMinimizedFromStorage && drawerMinimizedFromStorage === 'true') {
    isDrawerMinimized = true
  }
  return isDrawerMinimized
}

export const storeIsOnboardingSeenToStorage = (user: string): void => {
  const onboardingFromStorage = localStorage.getItem(ONBOARDING_SEEN_STORAGE_KEY)
  const onboardingList: Array<string> = JSON.parse(onboardingFromStorage || '[]')
  if (!onboardingList.includes(user)) {
    onboardingList.push(user)
    localStorage.setItem(ONBOARDING_SEEN_STORAGE_KEY, JSON.stringify(onboardingList))
  }
}

export const getIsOnboardingSeenFromStorage = (user: string): boolean => {
  const onboardingFromStorage = localStorage.getItem(ONBOARDING_SEEN_STORAGE_KEY)
  const onboardingList: Array<string> = JSON.parse(onboardingFromStorage || '[]')
  return onboardingList.includes(user)
}

export const getAddToHomeTooltipLastSeen = (): Date | undefined => {
  const addToHomeTooltipLastSeenString = localStorage.getItem(ADD_TO_HOME_TOOLTIP_LAST_SEEN)
  let result: Date | undefined = undefined
  if (addToHomeTooltipLastSeenString) {
    try {
      result = new Date(parseInt(addToHomeTooltipLastSeenString))
    } catch (err) {
      console.error('Error date from ' + addToHomeTooltipLastSeenString, err)
      result = undefined
    }
  }
  return result
}

export const storeAddToHomeTooltipLastSeen = (date: Date): void => {
  localStorage.setItem(ADD_TO_HOME_TOOLTIP_LAST_SEEN, date.getTime().toString())
}

export const storeActiveOrganization = (id: string | undefined): void => {
  if (id) {
    localStorage.setItem(ACTIVE_ORGANIZATION_STORAGE_KEY, id)
  } else {
    localStorage.removeItem(ACTIVE_ORGANIZATION_STORAGE_KEY)
  }
}

export const getActiveOrganizationFromStorage = (): string | undefined => {
  const idInStorage = localStorage.getItem(ACTIVE_ORGANIZATION_STORAGE_KEY)
  return idInStorage || undefined
}
