/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';

/**
 * 
 * @export
 * @interface FollowerPairingInfo
 */
export interface FollowerPairingInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof FollowerPairingInfo
     */
    followerThingNames: Array<string>;
    /**
     * 
     * @type {Array<MachineType>}
     * @memberof FollowerPairingInfo
     */
    availableMachineTypes: Array<MachineType>;
    /**
     * total numbers of possible followers
     * @type {number}
     * @memberof FollowerPairingInfo
     */
    availableMachines: number;
}

/**
 * Check if a given object implements the FollowerPairingInfo interface.
 */
export function instanceOfFollowerPairingInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "followerThingNames" in value;
    isInstance = isInstance && "availableMachineTypes" in value;
    isInstance = isInstance && "availableMachines" in value;

    return isInstance;
}

export function FollowerPairingInfoFromJSON(json: any): FollowerPairingInfo {
    return FollowerPairingInfoFromJSONTyped(json, false);
}

export function FollowerPairingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowerPairingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'followerThingNames': json['followerThingNames'],
        'availableMachineTypes': ((json['availableMachineTypes'] as Array<any>).map(MachineTypeFromJSON)),
        'availableMachines': json['availableMachines'],
    };
}

export function FollowerPairingInfoToJSON(value?: FollowerPairingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'followerThingNames': value.followerThingNames,
        'availableMachineTypes': ((value.availableMachineTypes as Array<any>).map(MachineTypeToJSON)),
        'availableMachines': value.availableMachines,
    };
}

