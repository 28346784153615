import React, { FC, PropsWithChildren, ReactElement } from 'react'
import { ControllerFieldState } from 'react-hook-form'
import { FormHelperText } from '@mui/material'
import { MessageParams, TranslateFunction } from 'src/i18n/useMessageSource'
import { HookFormFieldError } from 'src/ui-shared/base/form/validation/HookFormFieldError'

type Props = PropsWithChildren & React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>

export const HookForm: FC<Props> = ({ children, ...props }): ReactElement => {
  const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    // Don't submit form when pressing enter
    const target = e.target as HTMLInputElement | HTMLTextAreaElement
    if (e.key === 'Enter' && !['TEXTAREA'].includes(target.tagName)) {
      e.preventDefault()
    }
  }

  return (
    <form {...props} onKeyDown={checkKeyDown}>
      {children}
    </form>
  )
}

export const getHintOrError = (
  fieldState: ControllerFieldState,
  translate: TranslateFunction,
  hintText: string | undefined,
): string | undefined => {
  // if field doesn't have error return hint
  if (!fieldState.error) {
    return hintText
  }

  const errorDetails = fieldState.error as HookFormFieldError
  return translate(fieldState.error.message!, errorDetails.translationKeys as MessageParams)
}

export const renderHelperText = (
  fieldState: ControllerFieldState,
  translate: TranslateFunction,
  hintText: string | undefined,
): ReactElement => {
  const fieldStateError = fieldState.error
  const isErrorMessage = !!fieldStateError?.message
  const helperText = getHintOrError(fieldState, translate, hintText)
  return <>{helperText && <FormHelperText error={isErrorMessage}>{helperText}</FormHelperText>}</>
}

export const getActiveValueLabel = (
  translate: TranslateFunction,
  originalLabel: string,
  activeValue: any | undefined,
): string => {
  if (activeValue === undefined) {
    return originalLabel
  }

  return originalLabel + ' (' + translate('activeValue') + ': ' + activeValue + ')'
}

export const getDefaultValueLabel = (
  translate: TranslateFunction,
  originalLabel: string,
  activeValue: any | undefined,
): string => {
  if (activeValue === undefined) {
    return originalLabel
  }

  return originalLabel + ' (' + translate('defaultValue') + ': ' + activeValue + ')'
}
