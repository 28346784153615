import React from 'react'
import { CalendarEntry, LaundryUser } from 'src/service/backend/api'
import { SlotAndDay } from 'src/service/view-model/base/calendar/Calendar.const'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { CalendarCreateReservation } from 'src/ui-shared/calendar/CalendarCreateReservation'
import { CalendarDeleteReservation } from 'src/ui-shared/calendar/CalendarDeleteReservation'

interface CalendarModal {
  open: boolean
  handleCancelModal: () => void
  isDeleteModal: boolean
  reservationTitle: string
  deleteTitle: string
  slotAndDay?: SlotAndDay
  laundryUser?: LaundryUser
  selectCalendarEntryForDelete: (calendarEntry: CalendarEntry) => void
  updateCalendarEntry: (dayOfWeek: string, startTime: string, endTime: string, isRecurring: boolean) => void
  enableRecurring?: boolean
}

export const CalendarModal: React.FC<CalendarModal> = ({
  open,
  handleCancelModal,
  isDeleteModal,
  reservationTitle,
  deleteTitle,
  slotAndDay,
  laundryUser,
  selectCalendarEntryForDelete,
  updateCalendarEntry,
  enableRecurring,
}) => {
  return (
    <ModalDialog
      open={open}
      onClose={handleCancelModal}
      title={isDeleteModal ? deleteTitle : reservationTitle}
      maxWidth="xs"
    >
      {isDeleteModal ? (
        <CalendarDeleteReservation
          slotAndDay={slotAndDay}
          selectCalendarEntryForDelete={selectCalendarEntryForDelete}
          closeModal={handleCancelModal}
        />
      ) : (
        <CalendarCreateReservation
          slotAndDay={slotAndDay}
          laundryUser={laundryUser!}
          enableRecurring={enableRecurring ? enableRecurring : false}
          updateCalendarEntry={updateCalendarEntry}
          closeModal={handleCancelModal}
        />
      )}
    </ModalDialog>
  )
}
