import React, { FC, ReactElement } from 'react'
import { makeStyles } from 'tss-react/mui'
import { styled } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { SearchIcon } from 'src/ui-shared/icons/Icons'

export const StyledSearchTextField = styled(TextField)(() => ({
  maxWidth: '380px',
  '& .MuiInputBase-root': {
    height: '2.6em',
  },
  '& .MuiFilledInput-input': {
    padding: '12px 12px 10px',
  },

  '& .MuiInputBase-root.MuiFilledInput-root': {
    backgroundColor: '#f2f2f2',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}))

const useStyles = makeStyles()(() => {
  return {
    searchIconStyle: {
      color: 'rgba(0, 0, 0, 0.3)',
    },
  }
})

interface Props {
  size?: 'medium' | 'small' | undefined
  fullWidth?: boolean | undefined
  placeholder?: string | undefined
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  value?: unknown
}

export const SearchTextField: FC<Props> = ({ size, fullWidth, placeholder, onChange, value }): ReactElement => {
  const { classes } = useStyles()

  return (
    <StyledSearchTextField
      size={size ? size : 'small'}
      fullWidth={fullWidth ? fullWidth : true}
      variant="filled"
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment sx={{ marginTop: '0px !important' }} position="start">
            <SearchIcon className={classes.searchIconStyle} />
          </InputAdornment>
        ),
      }}
      placeholder={placeholder ? placeholder : 'Search...'}
      onChange={onChange}
      value={value}
    />
  )
}
