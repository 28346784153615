/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Money } from './Money';
import {
    MoneyFromJSON,
    MoneyFromJSONTyped,
    MoneyToJSON,
} from './Money';

/**
 * 
 * @export
 * @interface ServicePackagePricing
 */
export interface ServicePackagePricing {
    /**
     * 
     * @type {Money}
     * @memberof ServicePackagePricing
     */
    usagePricing?: Money | null;
    /**
     * 
     * @type {Money}
     * @memberof ServicePackagePricing
     */
    monthlyPricing?: Money | null;
    /**
     * 
     * @type {Money}
     * @memberof ServicePackagePricing
     */
    yearlyPricing?: Money | null;
}

/**
 * Check if a given object implements the ServicePackagePricing interface.
 */
export function instanceOfServicePackagePricing(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServicePackagePricingFromJSON(json: any): ServicePackagePricing {
    return ServicePackagePricingFromJSONTyped(json, false);
}

export function ServicePackagePricingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServicePackagePricing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usagePricing': !exists(json, 'usagePricing') ? undefined : MoneyFromJSON(json['usagePricing']),
        'monthlyPricing': !exists(json, 'monthlyPricing') ? undefined : MoneyFromJSON(json['monthlyPricing']),
        'yearlyPricing': !exists(json, 'yearlyPricing') ? undefined : MoneyFromJSON(json['yearlyPricing']),
    };
}

export function ServicePackagePricingToJSON(value?: ServicePackagePricing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usagePricing': MoneyToJSON(value.usagePricing),
        'monthlyPricing': MoneyToJSON(value.monthlyPricing),
        'yearlyPricing': MoneyToJSON(value.yearlyPricing),
    };
}

