import React, { FC, ReactElement, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LaundryGroupRecurringCalendarEntry } from 'src/service/backend/api'
import { LaundryGroupRecurringCalendarDay } from 'src/service/backend/api/models/LaundryGroupRecurringCalendarDay'
import { OpeningType } from 'src/service/backend/api/models/OpeningType'
import { PriceCondition } from 'src/service/backend/api/models/PriceCondition'
import { formatTimeStringForLocale, getNameOfDay } from 'src/service/utils/DateFormatUtils'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { calendarStyles } from 'src/ui-shared/calendar/Calendar.style'
import { LaundryGroupCalendarEditSlot } from 'src/ui/page/common/laundry-group/details/LaundryGroupCalendarEditSlot'
import { useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  day: LaundryGroupRecurringCalendarDay
  dayTitlesOnly: boolean
  updateRecurringCalendar: (values: LaundryGroupRecurringCalendarEntry) => Promise<void>
}

export const LaundryGroupCalendarDay: FC<Props> = ({ day, dayTitlesOnly, updateRecurringCalendar }): ReactElement => {
  const translate = useTranslate()
  const { classes } = calendarStyles()
  const appId = useAppId()
  const regionLocale = useUserRegionLocale()

  // state
  const [open, setOpen] = useState<boolean>(false)
  const [selectedSlot, setSelectedSlot] = useState<LaundryGroupRecurringCalendarEntry | undefined>()

  const noAdHockOpeningType = appId === AppId.SERVICE_MASTER
  const noPriceConditionOptions = appId === AppId.SERVICE_MASTER

  const getClassForOpeningType = (openingType: OpeningType, noAdHockOpeningType: boolean) => {
    let daySlotClass
    if (openingType === OpeningType.FREE) {
      daySlotClass = classes.daySlotOpen
    } else if (openingType === OpeningType.AD_HOC) {
      daySlotClass = classes.daySlotAdhoc
    } else {
      daySlotClass = classes.daySlotBlocked
    }

    if (noAdHockOpeningType && openingType === OpeningType.AD_HOC) {
      daySlotClass = classes.daySlotOpen
    }

    return daySlotClass
  }

  const getHappyHourClass = (openingType: OpeningType) => {
    let priceClass
    if (openingType === OpeningType.FREE) {
      priceClass = classes.happyHourSlotMarker
    } else if (openingType === OpeningType.AD_HOC) {
      priceClass = classes.happyHourSlotMarker
    }
    return priceClass
  }

  // event handling
  const handleCancel = () => {
    setOpen(false)
  }

  const handleSlotClick = (slot: LaundryGroupRecurringCalendarEntry) => {
    setSelectedSlot(slot)
    setOpen(true)
  }

  const slotEntries = day.entries

  // render
  const slotRenders = slotEntries.map((slot) => {
    const daySlotClass = getClassForOpeningType(slot.openingType, noAdHockOpeningType)

    return (
      <Box className={daySlotClass} key={slot.from} my={0.5} onClick={handleSlotClick.bind(this, slot)}>
        {formatTimeStringForLocale(slot.from, regionLocale)}{' '}
        {slot.priceCondition === PriceCondition.HAPPY && !noPriceConditionOptions ? (
          <span className={getHappyHourClass(slot.openingType)}>%</span>
        ) : null}
      </Box>
    )
  })

  return (
    <>
      <Grid item xs>
        {dayTitlesOnly ? (
          <Box className={classes.dayTitle}>{translate(getNameOfDay(day.dayOfWeek))}</Box>
        ) : (
          <div className={classes.daySlots}>{slotRenders}</div>
        )}
      </Grid>

      <ModalDialog open={open} onClose={handleCancel} title={translate(getNameOfDay(day.dayOfWeek))} maxWidth="xs">
        <LaundryGroupCalendarEditSlot
          closeModal={handleCancel}
          updateRecurringCalendar={updateRecurringCalendar}
          slot={selectedSlot!}
        ></LaundryGroupCalendarEditSlot>
      </ModalDialog>
    </>
  )
}
