import React, { FC, ReactElement, useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { Box, Button, Grid, Icon } from '@mui/material'
import { GetApp } from '@mui/icons-material'
import { AppId } from 'src/app/AppId'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import {
  Configuration,
  InstallProgramGroupRequest,
  LaundryGroup,
  Permission,
  ProgramGroup,
  ProgramGroupsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { EsLogoIcon } from 'src/ui-shared/icons/Icons'
import { ProgramGroupAddModal } from 'src/ui-shared/program-group/ProgramGroupAddModal'
import { ProgramInstallationTable } from 'src/ui-shared/program/ProgramInstallationTable'
import { hasApp, hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

interface Props {
  laundryGroup: LaundryGroup
}

export const LaundryGroupProgramInstallationTab: FC<Props> = ({ laundryGroup }): ReactElement => {
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const user = useUser()
  const navigate = useNavigate()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programGroupsApi = new ProgramGroupsApi(httpConfiguration)

  const [reloadTableFlag, setReloadTableFlag] = useState<boolean>(false)
  const [programGroupAddModal, setProgramGroupAddModal] = useState<boolean>(false)

  const hasEasySet = hasApp(user, AppId.EASY_SET)
  const hasProgramGroupReadPermission = hasPermission(user, Permission.EASYSET_PROGRAM_READ)
  const hasInstallProgramGroupPermission = hasEasySet && hasProgramGroupReadPermission

  // handlers
  const handleOpenEsClickButton = () => {
    navigate(`/${AppId.EASY_SET}/program-groups`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleOpenProgramGroupAddModal = () => {
    setProgramGroupAddModal(true)
  }

  const handleCloseProgramGroupAddModal = () => {
    setProgramGroupAddModal(false)
  }

  const handleInstallProgramGroup = (programGroup: ProgramGroup) => {
    const installProgramGroupRequest: InstallProgramGroupRequest = {
      programGroupId: programGroup.id,
    }

    // install program group on all related machines in a laundry group
    return programGroupsApi
      .laundrygroupsLaundryGroupIdProgramsInstallProgramGroupPost(laundryGroup.id, installProgramGroupRequest)
      .then(() => {
        setReloadTableFlag((prevState: boolean) => !prevState)
        handleCloseProgramGroupAddModal()
        const successMessage = translate('programGroupInstallSuccess')
        showSnackbar(successMessage, 'success')
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
        return err
      })
  }

  return (
    <>
      <Box pt={2}>
        <Box pb={3} pt={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12} display={'flex'} justifyContent={'flex-end'}>
              {hasInstallProgramGroupPermission && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<GetApp />}
                  onClick={handleOpenProgramGroupAddModal}
                >
                  {translate('installProgramGroup')}
                </Button>
              )}

              {hasEasySet && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={
                    <Icon>
                      <EsLogoIcon />
                    </Icon>
                  }
                  onClick={handleOpenEsClickButton}
                  sx={{ ml: 2 }}
                >
                  {translate('openEasySet')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
        <ProgramInstallationTable key={reloadTableFlag.toString()} laundryGroupId={laundryGroup.id} />
      </Box>

      {programGroupAddModal && (
        <ProgramGroupAddModal
          open={true}
          onCancel={handleCloseProgramGroupAddModal}
          fixedOrganizationId={laundryGroup.organization.id}
          addSelectedRow={handleInstallProgramGroup}
          titleKey={'installProgramGroupOnLaundryGroup'}
          confirmationKey={'button.install'}
        />
      )}
    </>
  )
}
