/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * - on OK app can continue normally, 
 *  - on BLOCK app should block startup, 
 *  - on UPDATE_FORCED a update is required to continue, 
 *  - on UPDATE a update is available (but not blocking), 
 *  - on INFO a informational message should be shown.
 * @export
 * @enum {string}
 */
export enum InitializeResultType {
    OK = 'OK',
    BLOCK = 'BLOCK',
    UPDATE_FORCED = 'UPDATE_FORCED',
    UPDATE = 'UPDATE',
    INFO = 'INFO'
}


export function InitializeResultTypeFromJSON(json: any): InitializeResultType {
    return InitializeResultTypeFromJSONTyped(json, false);
}

export function InitializeResultTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitializeResultType {
    return json as InitializeResultType;
}

export function InitializeResultTypeToJSON(value?: InitializeResultType | null): any {
    return value as any;
}

