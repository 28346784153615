/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManualPairingBody } from './ManualPairingBody';
import {
    ManualPairingBodyFromJSON,
    ManualPairingBodyFromJSONTyped,
    ManualPairingBodyToJSON,
} from './ManualPairingBody';
import type { PairingType } from './PairingType';
import {
    PairingTypeFromJSON,
    PairingTypeFromJSONTyped,
    PairingTypeToJSON,
} from './PairingType';
import type { PrefilledPairingBody } from './PrefilledPairingBody';
import {
    PrefilledPairingBodyFromJSON,
    PrefilledPairingBodyFromJSONTyped,
    PrefilledPairingBodyToJSON,
} from './PrefilledPairingBody';

/**
 * 
 * @export
 * @interface MachinesPairingBody
 */
export interface MachinesPairingBody {
    /**
     * ID (6 digit) or Mac of pairing
     * @type {string}
     * @memberof MachinesPairingBody
     */
    pairingId: string;
    /**
     * 
     * @type {string}
     * @memberof MachinesPairingBody
     */
    laundryId: string;
    /**
     * 
     * @type {PairingType}
     * @memberof MachinesPairingBody
     */
    type: PairingType;
    /**
     * 
     * @type {PrefilledPairingBody}
     * @memberof MachinesPairingBody
     */
    prefilledInfo?: PrefilledPairingBody;
    /**
     * 
     * @type {ManualPairingBody}
     * @memberof MachinesPairingBody
     */
    manualInfo?: ManualPairingBody;
}

/**
 * Check if a given object implements the MachinesPairingBody interface.
 */
export function instanceOfMachinesPairingBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pairingId" in value;
    isInstance = isInstance && "laundryId" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function MachinesPairingBodyFromJSON(json: any): MachinesPairingBody {
    return MachinesPairingBodyFromJSONTyped(json, false);
}

export function MachinesPairingBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachinesPairingBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pairingId': json['pairingId'],
        'laundryId': json['laundryId'],
        'type': PairingTypeFromJSON(json['type']),
        'prefilledInfo': !exists(json, 'prefilledInfo') ? undefined : PrefilledPairingBodyFromJSON(json['prefilledInfo']),
        'manualInfo': !exists(json, 'manualInfo') ? undefined : ManualPairingBodyFromJSON(json['manualInfo']),
    };
}

export function MachinesPairingBodyToJSON(value?: MachinesPairingBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pairingId': value.pairingId,
        'laundryId': value.laundryId,
        'type': PairingTypeToJSON(value.type),
        'prefilledInfo': PrefilledPairingBodyToJSON(value.prefilledInfo),
        'manualInfo': ManualPairingBodyToJSON(value.manualInfo),
    };
}

