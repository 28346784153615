/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PrepaidBankTransferDownloadRequest,
  PrepaidBankTransferEmailRequest,
  PrepaidBankTransferMailingConfirmRequest,
  PrepaidBankTransferMailingRequest,
  PrepaidBankTransferMailingResponse,
} from '../models';
import {
    PrepaidBankTransferDownloadRequestFromJSON,
    PrepaidBankTransferDownloadRequestToJSON,
    PrepaidBankTransferEmailRequestFromJSON,
    PrepaidBankTransferEmailRequestToJSON,
    PrepaidBankTransferMailingConfirmRequestFromJSON,
    PrepaidBankTransferMailingConfirmRequestToJSON,
    PrepaidBankTransferMailingRequestFromJSON,
    PrepaidBankTransferMailingRequestToJSON,
    PrepaidBankTransferMailingResponseFromJSON,
    PrepaidBankTransferMailingResponseToJSON,
} from '../models';

export interface LaundryusersLaundryUserIdPrepaidTopUpBanktransferDownloadPostRequest {
    laundryUserId: string;
    prepaidBankTransferDownloadRequest?: PrepaidBankTransferDownloadRequest;
}

export interface LaundryusersLaundryUserIdPrepaidTopUpBanktransferEmailPostRequest {
    laundryUserId: string;
    prepaidBankTransferEmailRequest?: PrepaidBankTransferEmailRequest;
}

export interface LaundryusersLaundryUserIdPrepaidTopUpBanktransferMailingConfirmPostRequest {
    laundryUserId: string;
    prepaidBankTransferMailingConfirmRequest?: PrepaidBankTransferMailingConfirmRequest;
}

export interface LaundryusersLaundryUserIdPrepaidTopUpBanktransferMailingPostRequest {
    laundryUserId: string;
    prepaidBankTransferMailingRequest?: PrepaidBankTransferMailingRequest;
}

/**
 * 
 */
export class LaundryUsersPrepaidBankTransferApi extends runtime.BaseAPI {

    /**
     * Download the bank transfer payment slip
     */
    async laundryusersLaundryUserIdPrepaidTopUpBanktransferDownloadPostRaw(requestParameters: LaundryusersLaundryUserIdPrepaidTopUpBanktransferDownloadPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.laundryUserId === null || requestParameters.laundryUserId === undefined) {
            throw new runtime.RequiredError('laundryUserId','Required parameter requestParameters.laundryUserId was null or undefined when calling laundryusersLaundryUserIdPrepaidTopUpBanktransferDownloadPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundryusers/{laundryUserId}/prepaid/top-up/banktransfer/download`.replace(`{${"laundryUserId"}}`, encodeURIComponent(String(requestParameters.laundryUserId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrepaidBankTransferDownloadRequestToJSON(requestParameters.prepaidBankTransferDownloadRequest),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download the bank transfer payment slip
     */
    async laundryusersLaundryUserIdPrepaidTopUpBanktransferDownloadPost(laundryUserId: string, prepaidBankTransferDownloadRequest?: PrepaidBankTransferDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.laundryusersLaundryUserIdPrepaidTopUpBanktransferDownloadPostRaw({ laundryUserId: laundryUserId, prepaidBankTransferDownloadRequest: prepaidBankTransferDownloadRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Send a email to the laundry users email address containing the bank transfer payment slip
     */
    async laundryusersLaundryUserIdPrepaidTopUpBanktransferEmailPostRaw(requestParameters: LaundryusersLaundryUserIdPrepaidTopUpBanktransferEmailPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryUserId === null || requestParameters.laundryUserId === undefined) {
            throw new runtime.RequiredError('laundryUserId','Required parameter requestParameters.laundryUserId was null or undefined when calling laundryusersLaundryUserIdPrepaidTopUpBanktransferEmailPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundryusers/{laundryUserId}/prepaid/top-up/banktransfer/email`.replace(`{${"laundryUserId"}}`, encodeURIComponent(String(requestParameters.laundryUserId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrepaidBankTransferEmailRequestToJSON(requestParameters.prepaidBankTransferEmailRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send a email to the laundry users email address containing the bank transfer payment slip
     */
    async laundryusersLaundryUserIdPrepaidTopUpBanktransferEmailPost(laundryUserId: string, prepaidBankTransferEmailRequest?: PrepaidBankTransferEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.laundryusersLaundryUserIdPrepaidTopUpBanktransferEmailPostRaw({ laundryUserId: laundryUserId, prepaidBankTransferEmailRequest: prepaidBankTransferEmailRequest }, initOverrides);
    }

    /**
     * Confirm the order of the payment slips
     */
    async laundryusersLaundryUserIdPrepaidTopUpBanktransferMailingConfirmPostRaw(requestParameters: LaundryusersLaundryUserIdPrepaidTopUpBanktransferMailingConfirmPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryUserId === null || requestParameters.laundryUserId === undefined) {
            throw new runtime.RequiredError('laundryUserId','Required parameter requestParameters.laundryUserId was null or undefined when calling laundryusersLaundryUserIdPrepaidTopUpBanktransferMailingConfirmPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundryusers/{laundryUserId}/prepaid/top-up/banktransfer/mailing/confirm`.replace(`{${"laundryUserId"}}`, encodeURIComponent(String(requestParameters.laundryUserId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrepaidBankTransferMailingConfirmRequestToJSON(requestParameters.prepaidBankTransferMailingConfirmRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirm the order of the payment slips
     */
    async laundryusersLaundryUserIdPrepaidTopUpBanktransferMailingConfirmPost(laundryUserId: string, prepaidBankTransferMailingConfirmRequest?: PrepaidBankTransferMailingConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.laundryusersLaundryUserIdPrepaidTopUpBanktransferMailingConfirmPostRaw({ laundryUserId: laundryUserId, prepaidBankTransferMailingConfirmRequest: prepaidBankTransferMailingConfirmRequest }, initOverrides);
    }

    /**
     * Trigger the order for postal mailing of payment slips
     */
    async laundryusersLaundryUserIdPrepaidTopUpBanktransferMailingPostRaw(requestParameters: LaundryusersLaundryUserIdPrepaidTopUpBanktransferMailingPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrepaidBankTransferMailingResponse>> {
        if (requestParameters.laundryUserId === null || requestParameters.laundryUserId === undefined) {
            throw new runtime.RequiredError('laundryUserId','Required parameter requestParameters.laundryUserId was null or undefined when calling laundryusersLaundryUserIdPrepaidTopUpBanktransferMailingPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundryusers/{laundryUserId}/prepaid/top-up/banktransfer/mailing`.replace(`{${"laundryUserId"}}`, encodeURIComponent(String(requestParameters.laundryUserId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrepaidBankTransferMailingRequestToJSON(requestParameters.prepaidBankTransferMailingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrepaidBankTransferMailingResponseFromJSON(jsonValue));
    }

    /**
     * Trigger the order for postal mailing of payment slips
     */
    async laundryusersLaundryUserIdPrepaidTopUpBanktransferMailingPost(laundryUserId: string, prepaidBankTransferMailingRequest?: PrepaidBankTransferMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrepaidBankTransferMailingResponse> {
        const response = await this.laundryusersLaundryUserIdPrepaidTopUpBanktransferMailingPostRaw({ laundryUserId: laundryUserId, prepaidBankTransferMailingRequest: prepaidBankTransferMailingRequest }, initOverrides);
        return await response.value();
    }

}
