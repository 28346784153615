import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Permission } from 'src/service/backend/api'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { ServiceRequestTable } from 'src/ui/page/sm/service-request/list/ServiceRequestTable'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const ServiceRequestListPage = (): ReactElement => {
  const translate = useTranslate()

  const navigate = useNavigate()

  const user = useUser()
  const appId = useAppId()

  const handleRowClick = (serviceRequestId?: string) => {
    navigate(`/${appId}/service-request/${serviceRequestId}/view`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleCreateButtonClick = () => {
    navigate(`/${appId}/service-request/create`)
  }

  const createNewAction = (
    <>
      <ListingButton onClick={handleCreateButtonClick} variant="outlined" color="primary" startIcon={<Add />}>
        {translate('createNew')}
      </ListingButton>
    </>
  )

  const actions = hasPermission(user, Permission.SERVICE_REQUEST_WRITE) ? createNewAction : <></>

  return (
    <ScreenLayout title={translate('serviceRequests')} actions={actions}>
      <ServiceRequestTable navigateToItem={handleRowClick}></ServiceRequestTable>
    </ScreenLayout>
  )
}
