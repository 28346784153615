import React, { FC, ReactElement, useContext } from 'react'
import { Form } from 'react-final-form'
import { Box, Button, Grid } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { Configuration, LaundryGroupReference, LaundryGroupsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { MachinePairGetViewModel } from 'src/service/view-model/machine/MachinePairViewModel'
import { AsyncAutoCompleteValidate } from 'src/ui-shared/base/form/control/AsyncAutoCompleteValidate'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { composeValidators, required, validPairingId } from 'src/ui-shared/base/form/validation/Validators'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

interface Props {
  setOpenModal: (value: boolean) => void
  onSubmit: (values: MachinePairGetViewModel) => void
}

export const MachinePairGetForm: FC<Props> = ({ setOpenModal, onSubmit }): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()
  const activeOrganization = useActiveOrganization()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)

  const laundryGroupSearch = (searchText: string): Promise<LaundryGroupReference[]> => {
    const organizationId = activeOrganization?.id
    const organizationIds = organizationId ? [organizationId] : undefined
    return laundryGroupsApi.laundrygroupsRefGet(undefined, searchText, organizationIds)
  }

  return (
    <Box mt={2}>
      <Form<MachinePairGetViewModel>
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="pairingId"
                    label={translate('pairingCode')}
                    fullWidth
                    autoFocus
                    validate={composeValidators(required(), validPairingId())}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AsyncAutoCompleteValidate
                    disabled={submitting}
                    name="laundryGroupRef"
                    validate={required()}
                    label={translate('laundryGroup')}
                    delay={300}
                    labelFieldName="name"
                    loadOptionsFunction={laundryGroupSearch}
                  />
                </Grid>
              </Grid>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  color="primary"
                  size="large"
                  className={sharedClasses.ButtonMargin}
                  onClick={() => setOpenModal(false)}
                >
                  {translate('button.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  {translate('button.next')}
                </Button>
              </Box>
            </form>
          )
        }}
      />
    </Box>
  )
}
