import { formatDateForLocale, formatMonthForLocale, formatYearForLocale } from 'src/service/utils/DateFormatUtils'
import { SupportedLocale } from 'src/service/view-model/base/localization/SupportedLocale'

export enum DatePeriodType {
  DAYS,
  MONTHS,
  YEARS,
}

/**
 * Formats the given from and to date and locale to the locale format and combines them.
 * @param {Date} date - the passed date
 * @param {SupportedLocale} locale - given locale
 * @return {string} - the formatted date
 */
export const formatDatePeriodForLocale = (fromDate: Date, toDate: Date, locale: SupportedLocale): string => {
  const fromDateFormatted = formatDateForLocale(fromDate, locale)
  const toDateFormatted = formatDateForLocale(toDate, locale)
  return combineDateRange(fromDateFormatted, toDateFormatted)
}

export const combineDateRange = (from: string, to: string): string => {
  // if from and to are the same, it is the same date, month, or year, no need for combination
  if (from === to) {
    return from
  }

  const combined = from + ' - ' + to

  return combined
}

const isSameYear = (fromDate: Date, toDate: Date, locale: SupportedLocale): boolean => {
  // compare the formatted dates to take into account the formatting to local timezone
  const fromYearFormatted = formatYearForLocale(fromDate, locale)
  const toYearFormatted = formatYearForLocale(toDate, locale)
  const isSameYear = fromYearFormatted === toYearFormatted
  return isSameYear
}

/**
 * Formats the given from and to date and locale to the locale format and combines them.
 * @param {Date} date - the passed date
 * @param {SupportedLocale} locale - given locale
 * @return {string} - the formatted date
 */
export const formatDatePeriodForLocaleAndType = (
  fromDate: Date,
  toDate: Date,
  locale: SupportedLocale,
  datePeriodType: DatePeriodType,
): string => {
  let fromFormatted: string = ''
  let toFormatted: string = ''

  // const isSameMonthResult = isSameMonth(fromDate, toDate, locale)
  // const isSameDayResult = isSameDay(fromDate, toDate, locale)

  switch (datePeriodType) {
    case DatePeriodType.DAYS: {
      fromFormatted = formatDateForLocale(fromDate, locale)
      toFormatted = formatDateForLocale(toDate, locale)
      break
    }

    case DatePeriodType.MONTHS: {
      fromFormatted = formatMonthForLocale(fromDate, locale)
      toFormatted = formatMonthForLocale(toDate, locale)

      if (fromFormatted !== toFormatted) {
        const isSameYearResult = isSameYear(fromDate, toDate, locale)
        if (isSameYearResult) {
          fromFormatted = formatMonthForLocale(fromDate, locale, false)
        }
      }
      break
    }

    case DatePeriodType.YEARS: {
      fromFormatted = formatYearForLocale(fromDate, locale)
      toFormatted = formatYearForLocale(toDate, locale)
      break
    }
  }

  const result = toFormatted ? combineDateRange(fromFormatted, toFormatted) : fromFormatted
  return result
}
