/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AppId,
  FollowerPairingInfo,
  Machine,
  MachineCategory,
  MachineConnectivityType,
  MachinePairingInfo,
  MachineParameters,
  MachineParametersExternal,
  MachineReference,
  MachineStatus,
  MachineType,
  MachinesPairBody,
  MachinesPairingBody,
  ManualFollowerPairingBody,
  PageableMachines,
  PairingInfo,
  SelectAndStartProgramRequest,
} from '../models';
import {
    AppIdFromJSON,
    AppIdToJSON,
    FollowerPairingInfoFromJSON,
    FollowerPairingInfoToJSON,
    MachineFromJSON,
    MachineToJSON,
    MachineCategoryFromJSON,
    MachineCategoryToJSON,
    MachineConnectivityTypeFromJSON,
    MachineConnectivityTypeToJSON,
    MachinePairingInfoFromJSON,
    MachinePairingInfoToJSON,
    MachineParametersFromJSON,
    MachineParametersToJSON,
    MachineParametersExternalFromJSON,
    MachineParametersExternalToJSON,
    MachineReferenceFromJSON,
    MachineReferenceToJSON,
    MachineStatusFromJSON,
    MachineStatusToJSON,
    MachineTypeFromJSON,
    MachineTypeToJSON,
    MachinesPairBodyFromJSON,
    MachinesPairBodyToJSON,
    MachinesPairingBodyFromJSON,
    MachinesPairingBodyToJSON,
    ManualFollowerPairingBodyFromJSON,
    ManualFollowerPairingBodyToJSON,
    PageableMachinesFromJSON,
    PageableMachinesToJSON,
    PairingInfoFromJSON,
    PairingInfoToJSON,
    SelectAndStartProgramRequestFromJSON,
    SelectAndStartProgramRequestToJSON,
} from '../models';

export interface MachinesGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    id?: string;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    appId?: Array<AppId>;
    thingName?: string;
    productNumber?: string;
    manufacturerName?: string;
    type?: MachineType;
    category?: MachineCategory;
    serialNumber?: string;
    machineConnectivityType?: Array<MachineConnectivityType>;
}

export interface MachinesMachineIdDeleteRequest {
    machineId: string;
}

export interface MachinesMachineIdFollowerPairingGetRequest {
    machineId: string;
}

export interface MachinesMachineIdFollowerPairingPostRequest {
    machineId: string;
    manualFollowerPairingBody?: ManualFollowerPairingBody;
}

export interface MachinesMachineIdGetRequest {
    machineId: string;
    appId?: Array<AppId>;
}

export interface MachinesMachineIdLabCancelProgramPostRequest {
    machineId: string;
    body?: object;
}

export interface MachinesMachineIdLabSelectAndStartProgramPostRequest {
    machineId: string;
    selectAndStartProgramRequest?: SelectAndStartProgramRequest;
}

export interface MachinesMachineIdLabStartEnduranceTestPostRequest {
    machineId: string;
    body?: object;
}

export interface MachinesMachineIdParametersExternalGetRequest {
    machineId: string;
}

export interface MachinesMachineIdParametersExternalPutRequest {
    machineId: string;
    machineParametersExternal?: MachineParametersExternal;
}

export interface MachinesMachineIdParametersGetRequest {
    machineId: string;
}

export interface MachinesMachineIdParametersPutRequest {
    machineId: string;
    machineParameters?: MachineParameters;
}

export interface MachinesMachineIdPutRequest {
    machineId: string;
    appId?: Array<AppId>;
    machine?: Machine;
}

export interface MachinesMachineIdStartPostRequest {
    machineId: string;
    body?: object;
}

export interface MachinesMachineIdStatusGetRequest {
    machineId: string;
}

export interface MachinesMachineIdUnpairPostRequest {
    machineId: string;
}

export interface MachinesPairGetRequest {
    pairingId: number;
}

export interface MachinesPairPostRequest {
    machinesPairBody?: MachinesPairBody;
}

export interface MachinesPairingGetRequest {
    pairingId: string;
}

export interface MachinesPairingPostRequest {
    machinesPairingBody?: MachinesPairingBody;
}

export interface MachinesPostRequest {
    machine?: Machine;
}

export interface MachinesRefGetRequest {
    size?: number;
    search?: string;
    laundryId?: Array<string>;
    laundryGroupId?: Array<string>;
    organizationId?: Array<string>;
    machineConnectivityType?: Array<MachineConnectivityType>;
}

/**
 * 
 */
export class MachinesApi extends runtime.BaseAPI {

    /**
     * List Machines
     */
    async machinesGetRaw(requestParameters: MachinesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableMachines>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        if (requestParameters.thingName !== undefined) {
            queryParameters['thingName'] = requestParameters.thingName;
        }

        if (requestParameters.productNumber !== undefined) {
            queryParameters['productNumber'] = requestParameters.productNumber;
        }

        if (requestParameters.manufacturerName !== undefined) {
            queryParameters['manufacturerName'] = requestParameters.manufacturerName;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.serialNumber !== undefined) {
            queryParameters['serialNumber'] = requestParameters.serialNumber;
        }

        if (requestParameters.machineConnectivityType) {
            queryParameters['machineConnectivityType'] = requestParameters.machineConnectivityType.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableMachinesFromJSON(jsonValue));
    }

    /**
     * List Machines
     */
    async machinesGet(size?: number, page?: number, sort?: string, search?: string, id?: string, organizationId?: string, laundryGroupId?: string, laundryId?: string, appId?: Array<AppId>, thingName?: string, productNumber?: string, manufacturerName?: string, type?: MachineType, category?: MachineCategory, serialNumber?: string, machineConnectivityType?: Array<MachineConnectivityType>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableMachines> {
        const response = await this.machinesGetRaw({ size: size, page: page, sort: sort, search: search, id: id, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, appId: appId, thingName: thingName, productNumber: productNumber, manufacturerName: manufacturerName, type: type, category: category, serialNumber: serialNumber, machineConnectivityType: machineConnectivityType }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a Machine
     */
    async machinesMachineIdDeleteRaw(requestParameters: MachinesMachineIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Machine
     */
    async machinesMachineIdDelete(machineId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.machinesMachineIdDeleteRaw({ machineId: machineId }, initOverrides);
    }

    /**
     * Get Information about possible Machine Followers
     */
    async machinesMachineIdFollowerPairingGetRaw(requestParameters: MachinesMachineIdFollowerPairingGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FollowerPairingInfo>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdFollowerPairingGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/{machineId}/follower-pairing`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowerPairingInfoFromJSON(jsonValue));
    }

    /**
     * Get Information about possible Machine Followers
     */
    async machinesMachineIdFollowerPairingGet(machineId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FollowerPairingInfo> {
        const response = await this.machinesMachineIdFollowerPairingGetRaw({ machineId: machineId }, initOverrides);
        return await response.value();
    }

    /**
     * Pair a Machine as Follower
     */
    async machinesMachineIdFollowerPairingPostRaw(requestParameters: MachinesMachineIdFollowerPairingPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Machine>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdFollowerPairingPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines/{machineId}/follower-pairing`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManualFollowerPairingBodyToJSON(requestParameters.manualFollowerPairingBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineFromJSON(jsonValue));
    }

    /**
     * Pair a Machine as Follower
     */
    async machinesMachineIdFollowerPairingPost(machineId: string, manualFollowerPairingBody?: ManualFollowerPairingBody, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Machine> {
        const response = await this.machinesMachineIdFollowerPairingPostRaw({ machineId: machineId, manualFollowerPairingBody: manualFollowerPairingBody }, initOverrides);
        return await response.value();
    }

    /**
     * Get a Machine
     */
    async machinesMachineIdGetRaw(requestParameters: MachinesMachineIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Machine>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineFromJSON(jsonValue));
    }

    /**
     * Get a Machine
     */
    async machinesMachineIdGet(machineId: string, appId?: Array<AppId>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Machine> {
        const response = await this.machinesMachineIdGetRaw({ machineId: machineId, appId: appId }, initOverrides);
        return await response.value();
    }

    /**
     * Cancel / Stop a running Program on a Machine
     */
    async machinesMachineIdLabCancelProgramPostRaw(requestParameters: MachinesMachineIdLabCancelProgramPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdLabCancelProgramPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines/{machineId}/lab/cancel-program`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel / Stop a running Program on a Machine
     */
    async machinesMachineIdLabCancelProgramPost(machineId: string, body?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.machinesMachineIdLabCancelProgramPostRaw({ machineId: machineId, body: body }, initOverrides);
    }

    /**
     * Select and Start a Program on a Machine
     */
    async machinesMachineIdLabSelectAndStartProgramPostRaw(requestParameters: MachinesMachineIdLabSelectAndStartProgramPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdLabSelectAndStartProgramPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines/{machineId}/lab/select-and-start-program`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectAndStartProgramRequestToJSON(requestParameters.selectAndStartProgramRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Select and Start a Program on a Machine
     */
    async machinesMachineIdLabSelectAndStartProgramPost(machineId: string, selectAndStartProgramRequest?: SelectAndStartProgramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.machinesMachineIdLabSelectAndStartProgramPostRaw({ machineId: machineId, selectAndStartProgramRequest: selectAndStartProgramRequest }, initOverrides);
    }

    /**
     * Start the Machine Endurance-Test Mode on a Machine
     */
    async machinesMachineIdLabStartEnduranceTestPostRaw(requestParameters: MachinesMachineIdLabStartEnduranceTestPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdLabStartEnduranceTestPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines/{machineId}/lab/start-endurance-test`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Start the Machine Endurance-Test Mode on a Machine
     */
    async machinesMachineIdLabStartEnduranceTestPost(machineId: string, body?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.machinesMachineIdLabStartEnduranceTestPostRaw({ machineId: machineId, body: body }, initOverrides);
    }

    /**
     * Get parameters of external machine (connectivity box)
     */
    async machinesMachineIdParametersExternalGetRaw(requestParameters: MachinesMachineIdParametersExternalGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MachineParametersExternal>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdParametersExternalGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/{machineId}/parameters/external`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineParametersExternalFromJSON(jsonValue));
    }

    /**
     * Get parameters of external machine (connectivity box)
     */
    async machinesMachineIdParametersExternalGet(machineId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MachineParametersExternal> {
        const response = await this.machinesMachineIdParametersExternalGetRaw({ machineId: machineId }, initOverrides);
        return await response.value();
    }

    /**
     * Update parameters for external machine (connectivity box)
     */
    async machinesMachineIdParametersExternalPutRaw(requestParameters: MachinesMachineIdParametersExternalPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdParametersExternalPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines/{machineId}/parameters/external`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MachineParametersExternalToJSON(requestParameters.machineParametersExternal),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update parameters for external machine (connectivity box)
     */
    async machinesMachineIdParametersExternalPut(machineId: string, machineParametersExternal?: MachineParametersExternal, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.machinesMachineIdParametersExternalPutRaw({ machineId: machineId, machineParametersExternal: machineParametersExternal }, initOverrides);
    }

    /**
     * Get parameters of internal or external machine
     */
    async machinesMachineIdParametersGetRaw(requestParameters: MachinesMachineIdParametersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MachineParameters>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdParametersGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/{machineId}/parameters`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineParametersFromJSON(jsonValue));
    }

    /**
     * Get parameters of internal or external machine
     */
    async machinesMachineIdParametersGet(machineId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MachineParameters> {
        const response = await this.machinesMachineIdParametersGetRaw({ machineId: machineId }, initOverrides);
        return await response.value();
    }

    /**
     * Update parameters for internal or external machine
     */
    async machinesMachineIdParametersPutRaw(requestParameters: MachinesMachineIdParametersPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MachineParameters>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdParametersPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines/{machineId}/parameters`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MachineParametersToJSON(requestParameters.machineParameters),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineParametersFromJSON(jsonValue));
    }

    /**
     * Update parameters for internal or external machine
     */
    async machinesMachineIdParametersPut(machineId: string, machineParameters?: MachineParameters, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MachineParameters> {
        const response = await this.machinesMachineIdParametersPutRaw({ machineId: machineId, machineParameters: machineParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Update Machine
     */
    async machinesMachineIdPutRaw(requestParameters: MachinesMachineIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Machine>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MachineToJSON(requestParameters.machine),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineFromJSON(jsonValue));
    }

    /**
     * Update Machine
     */
    async machinesMachineIdPut(machineId: string, appId?: Array<AppId>, machine?: Machine, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Machine> {
        const response = await this.machinesMachineIdPutRaw({ machineId: machineId, appId: appId, machine: machine }, initOverrides);
        return await response.value();
    }

    /**
     * Start a Machine with a Anonymous and Free Usage without a Payment
     */
    async machinesMachineIdStartPostRaw(requestParameters: MachinesMachineIdStartPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdStartPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines/{machineId}/start`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Start a Machine with a Anonymous and Free Usage without a Payment
     */
    async machinesMachineIdStartPost(machineId: string, body?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.machinesMachineIdStartPostRaw({ machineId: machineId, body: body }, initOverrides);
    }

    /**
     * Get the status of an Machine
     */
    async machinesMachineIdStatusGetRaw(requestParameters: MachinesMachineIdStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MachineStatus>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdStatusGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/{machineId}/status`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineStatusFromJSON(jsonValue));
    }

    /**
     * Get the status of an Machine
     */
    async machinesMachineIdStatusGet(machineId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MachineStatus> {
        const response = await this.machinesMachineIdStatusGetRaw({ machineId: machineId }, initOverrides);
        return await response.value();
    }

    /**
     * Unpair a Machine from an Laundry
     */
    async machinesMachineIdUnpairPostRaw(requestParameters: MachinesMachineIdUnpairPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesMachineIdUnpairPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/{machineId}/unpair`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unpair a Machine from an Laundry
     */
    async machinesMachineIdUnpairPost(machineId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.machinesMachineIdUnpairPostRaw({ machineId: machineId }, initOverrides);
    }

    /**
     * Get Information about the Machine Pairing Request
     */
    async machinesPairGetRaw(requestParameters: MachinesPairGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MachinePairingInfo>> {
        if (requestParameters.pairingId === null || requestParameters.pairingId === undefined) {
            throw new runtime.RequiredError('pairingId','Required parameter requestParameters.pairingId was null or undefined when calling machinesPairGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.pairingId !== undefined) {
            queryParameters['pairingId'] = requestParameters.pairingId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/pair`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachinePairingInfoFromJSON(jsonValue));
    }

    /**
     * Get Information about the Machine Pairing Request
     */
    async machinesPairGet(pairingId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MachinePairingInfo> {
        const response = await this.machinesPairGetRaw({ pairingId: pairingId }, initOverrides);
        return await response.value();
    }

    /**
     * Pair a Machine to an Laundry
     */
    async machinesPairPostRaw(requestParameters: MachinesPairPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Machine>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines/pair`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachinesPairBodyToJSON(requestParameters.machinesPairBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineFromJSON(jsonValue));
    }

    /**
     * Pair a Machine to an Laundry
     */
    async machinesPairPost(machinesPairBody?: MachinesPairBody, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Machine> {
        const response = await this.machinesPairPostRaw({ machinesPairBody: machinesPairBody }, initOverrides);
        return await response.value();
    }

    /**
     * Get Information about the Machine Pairing Request
     */
    async machinesPairingGetRaw(requestParameters: MachinesPairingGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PairingInfo>> {
        if (requestParameters.pairingId === null || requestParameters.pairingId === undefined) {
            throw new runtime.RequiredError('pairingId','Required parameter requestParameters.pairingId was null or undefined when calling machinesPairingGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.pairingId !== undefined) {
            queryParameters['pairingId'] = requestParameters.pairingId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/pairing`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PairingInfoFromJSON(jsonValue));
    }

    /**
     * Get Information about the Machine Pairing Request
     */
    async machinesPairingGet(pairingId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PairingInfo> {
        const response = await this.machinesPairingGetRaw({ pairingId: pairingId }, initOverrides);
        return await response.value();
    }

    /**
     * Pair a Machine to an Laundry
     */
    async machinesPairingPostRaw(requestParameters: MachinesPairingPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Machine>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines/pairing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachinesPairingBodyToJSON(requestParameters.machinesPairingBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineFromJSON(jsonValue));
    }

    /**
     * Pair a Machine to an Laundry
     */
    async machinesPairingPost(machinesPairingBody?: MachinesPairingBody, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Machine> {
        const response = await this.machinesPairingPostRaw({ machinesPairingBody: machinesPairingBody }, initOverrides);
        return await response.value();
    }

    /**
     * Create Machine (Only Other Types)
     */
    async machinesPostRaw(requestParameters: MachinesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Machine>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/machines`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineToJSON(requestParameters.machine),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineFromJSON(jsonValue));
    }

    /**
     * Create Machine (Only Other Types)
     */
    async machinesPost(machine?: Machine, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Machine> {
        const response = await this.machinesPostRaw({ machine: machine }, initOverrides);
        return await response.value();
    }

    /**
     * List Machine References
     */
    async machinesRefGetRaw(requestParameters: MachinesRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MachineReference>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.laundryId) {
            queryParameters['laundryId'] = requestParameters.laundryId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.laundryGroupId) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.organizationId) {
            queryParameters['organizationId'] = requestParameters.organizationId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.machineConnectivityType) {
            queryParameters['machineConnectivityType'] = requestParameters.machineConnectivityType.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/machines/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineReferenceFromJSON));
    }

    /**
     * List Machine References
     */
    async machinesRefGet(size?: number, search?: string, laundryId?: Array<string>, laundryGroupId?: Array<string>, organizationId?: Array<string>, machineConnectivityType?: Array<MachineConnectivityType>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MachineReference>> {
        const response = await this.machinesRefGetRaw({ size: size, search: search, laundryId: laundryId, laundryGroupId: laundryGroupId, organizationId: organizationId, machineConnectivityType: machineConnectivityType }, initOverrides);
        return await response.value();
    }

}
