/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Is derived from programIndex (1-399 STANDARD, 400+ for CUSTOM), cannot be changed
 * @export
 * @enum {string}
 */
export enum ProgramType {
    STANDARD = 'STANDARD',
    CUSTOM = 'CUSTOM'
}


export function ProgramTypeFromJSON(json: any): ProgramType {
    return ProgramTypeFromJSONTyped(json, false);
}

export function ProgramTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramType {
    return json as ProgramType;
}

export function ProgramTypeToJSON(value?: ProgramType | null): any {
    return value as any;
}

