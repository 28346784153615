import React, { ReactElement, useContext } from 'react'
import { Grid, ListSubheader, styled } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { getRoutesForApp } from 'src/routing/Routing.utils'
import { ListItemLink } from 'src/ui/layout/sidebar/ListItemLink'
import { hasPermission } from 'src/user/RoleCheck'
import { UserContext } from 'src/user/UserContext'

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  lineHeight: theme.spacing(2),
  paddingTop: theme.spacing(2),
  backgroundColor: theme.palette.menu.backgroundColor,
}))

interface Props {
  minimizedDrawer: boolean
  closeDrawer?: () => void
}

export const Sidebar: React.FC<Props> = ({ minimizedDrawer, closeDrawer }): ReactElement => {
  const translate = useTranslate()
  const appId = useAppId()

  const { user } = useContext(UserContext)

  // get only routes that should be shown in main navigation (have label)
  const firstLevelRoutes = Object.values(getRoutesForApp(appId)).filter(({ label }) => label !== undefined)

  // remove routes that the user has no access to
  const filteredRoutes = Object.values(firstLevelRoutes).filter(
    ({ permission }) => !permission || hasPermission(user, permission),
  )

  const renderMenuSectionLabel = (
    sectionLabel: string | undefined,
    prevSectionLabel: string | undefined,
    minimizedDrawer: boolean,
  ): ReactElement | null => {
    if (sectionLabel && sectionLabel !== prevSectionLabel && !minimizedDrawer) {
      return <StyledListSubheader color="primary">{translate(sectionLabel)}</StyledListSubheader>
    }
    return null
  }

  const renderListItemLink = (
    label: string | undefined,
    path: string,
    minimizedDrawer: boolean,
    icon?: ReactElement,
  ): ReactElement => {
    const linkLabel = label && !minimizedDrawer ? translate(label as string) : undefined

    return <ListItemLink closeDrawer={closeDrawer} to={path} icon={icon} primary={linkLabel} />
  }

  // render routes list
  const displayMenu = Object.values(filteredRoutes).map(({ path, label, sectionLabel, icon }, index) => {
    const prevSectionLabel = filteredRoutes[index - 1] ? filteredRoutes[index - 1].sectionLabel : undefined
    return (
      <Grid key={label}>
        {renderMenuSectionLabel(sectionLabel, prevSectionLabel, minimizedDrawer)}
        {renderListItemLink(label, path, minimizedDrawer, icon)}
      </Grid>
    )
  })

  return <>{displayMenu}</>
}
