import React from 'react'
import { MessageSourceContext, MessageSourceContextShape } from 'src/i18n/MessageSourceContext'

export type MessageParams = string | number | undefined

export type TranslateFunction = (key: string, ...params: MessageParams[]) => string

export interface MessageSourceApi {
  /**
   * Retrieves a text message.
   *
   * Example usage:
   * let name, lastName;
   * ...
   * const message = getMessage('message.key', name, lastName);
   *
   * @param key the key of the message.
   * @param params an optional parameters (param0, param1 ...).
   */
  getMessage: TranslateFunction
}

/**
 * Retrieves a text message.
 *
 * @param textMessages a map which holds the translation pairs.
 * @param textKey the key of the message.
 * @param params optional placeholder parameters.
 * @returns {string} the message or the key itself.
 */
export function getMessageWithParams(
  textMessages: MessageSourceContextShape,
  textKey: string,
  ...params: MessageParams[]
): string | null {
  const message = textMessages[textKey]

  if (!message) {
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (params === undefined || params === null) {
    return message
  }

  const paramsArray = params.flat()

  const result = paramsArray.reduce((previous, current, index) => {
    const previousString = previous?.toString() ?? ''
    const currentString = current?.toString() ?? ''
    return previousString.replace(`{${index}}`, currentString)
  }, message)

  return result?.toString() ?? null
}

/**
 * A Hook which which provides the MessageSourceApi.
 *
 * @param keyPrefix an optional prefix which will be prepended to the lookup key.
 */
export function useMessageSource(): MessageSourceApi {
  const textKeys = React.useContext(MessageSourceContext)
  return React.useMemo<MessageSourceApi>(() => {
    return {
      getMessage(key: string, ...params: MessageParams[]) {
        if (key === '') {
          return ''
        }
        const message = getMessageWithParams(textKeys, key, ...params)

        if (message === null) {
          // translation not found
          console.warn('No translation for text key', key)
          return '! ' + key
        }
        return message
      },
    }
  }, [textKeys])
}

export const useTranslate = (): TranslateFunction => {
  const { getMessage } = useMessageSource()
  return getMessage
}
