import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Alert, Button, Divider, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, ManufacturerModel, ManufacturerModelsApi, Permission } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const ManufacturerModelDetailsPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()

  const navigate = useNavigate()
  const location = useLocation()
  const appId = useAppId()
  const user = useUser()

  const { manufacturerModelId } = useRequiredParams(['manufacturerModelId'])

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack

  const httpConfiguration: Configuration = useContext(HttpContext)
  const manufacturerModelsApi = new ManufacturerModelsApi(httpConfiguration)

  const [manufacturerModel, setManufacturerModel] = useState<ManufacturerModel | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [readonly, setReadonly] = useState<boolean>(false)

  const canEdit = hasPermission(user, Permission.MANUFACTURER_MODEL_WRITE)

  useHotKeysForm()

  // load data
  useEffect(() => {
    let active = true

    manufacturerModelsApi
      .manufacturerModelsManufacturerModelIdGet(manufacturerModelId)
      .then((manufacturerModel) => {
        if (!active) return
        setReadonly(!manufacturerModel.editable)
        setManufacturerModel(manufacturerModel)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })

    return () => {
      active = false
    }
  }, [])

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/manufacturer-models`)
  }

  const navigateToEditPage = () => {
    navigate(`/${appId}/manufacturer-models/${manufacturerModelId}/edit`)
  }

  // JSX
  const editButton = (
    <Button
      disabled={readonly}
      id="editButton"
      variant="contained"
      color="primary"
      size="large"
      onClick={navigateToEditPage}
    >
      {translate('button.edit')}
    </Button>
  )

  const editAction = canEdit ? editButton : <></>

  return (
    <ScreenLayout
      title={manufacturerModel?.modelName ? manufacturerModel.modelName : ''}
      onBack={navigateBack}
      actions={<></>}
      actionsWidth={50}
    >
      <Paper elevation={0}>
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <Box pt={2}>
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              {readonly ? (
                <Grid item md={12} lg={12}>
                  <Alert severity={'warning'}>{translate('manufacturerModel.nonEditable')}</Alert>
                </Grid>
              ) : null}
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={manufacturerModel?.modelName} label={translate('manufacturerModel.name')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  value={manufacturerModel?.productNumber}
                  label={translate('manufacturerProductNumber')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={12} md={12}>
                <DetailsTextField value={manufacturerModel?.manufacturer.name} label={translate('manufacturer')} />
              </Grid>
            </Grid>
            <Divider sx={{ mb: 1 }} />
            <Box display="flex" justifyContent="flex-end">
              {editAction}
            </Box>
          </Box>
        )}
      </Paper>
    </ScreenLayout>
  )
}
