/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ErrorSeverity } from './ErrorSeverity';
import {
    ErrorSeverityFromJSON,
    ErrorSeverityFromJSONTyped,
    ErrorSeverityToJSON,
} from './ErrorSeverity';
import type { IssueAction } from './IssueAction';
import {
    IssueActionFromJSON,
    IssueActionFromJSONTyped,
    IssueActionToJSON,
} from './IssueAction';
import type { IssueCategoryReference } from './IssueCategoryReference';
import {
    IssueCategoryReferenceFromJSON,
    IssueCategoryReferenceFromJSONTyped,
    IssueCategoryReferenceToJSON,
} from './IssueCategoryReference';
import type { IssueCreator } from './IssueCreator';
import {
    IssueCreatorFromJSON,
    IssueCreatorFromJSONTyped,
    IssueCreatorToJSON,
} from './IssueCreator';
import type { IssueCreatorType } from './IssueCreatorType';
import {
    IssueCreatorTypeFromJSON,
    IssueCreatorTypeFromJSONTyped,
    IssueCreatorTypeToJSON,
} from './IssueCreatorType';
import type { IssueStateData } from './IssueStateData';
import {
    IssueStateDataFromJSON,
    IssueStateDataFromJSONTyped,
    IssueStateDataToJSON,
} from './IssueStateData';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';
import type { LaundryReference } from './LaundryReference';
import {
    LaundryReferenceFromJSON,
    LaundryReferenceFromJSONTyped,
    LaundryReferenceToJSON,
} from './LaundryReference';
import type { MachineReference } from './MachineReference';
import {
    MachineReferenceFromJSON,
    MachineReferenceFromJSONTyped,
    MachineReferenceToJSON,
} from './MachineReference';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';
import type { ServiceType } from './ServiceType';
import {
    ServiceTypeFromJSON,
    ServiceTypeFromJSONTyped,
    ServiceTypeToJSON,
} from './ServiceType';

/**
 * 
 * @export
 * @interface IssueEntry
 */
export interface IssueEntry {
    /**
     * 
     * @type {string}
     * @memberof IssueEntry
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof IssueEntry
     */
    readonly ticketKey: string;
    /**
     * 
     * @type {Date}
     * @memberof IssueEntry
     */
    readonly creationTimestamp: Date;
    /**
     * 
     * @type {Date}
     * @memberof IssueEntry
     */
    readonly closingTimestamp?: Date | null;
    /**
     * 
     * @type {IssueStateData}
     * @memberof IssueEntry
     */
    issueState: IssueStateData;
    /**
     * 
     * @type {ErrorSeverity}
     * @memberof IssueEntry
     */
    errorSeverity?: ErrorSeverity;
    /**
     * 
     * @type {ServiceType}
     * @memberof IssueEntry
     */
    serviceType: ServiceType;
    /**
     * 
     * @type {IssueCategoryReference}
     * @memberof IssueEntry
     */
    category?: IssueCategoryReference;
    /**
     * 
     * @type {string}
     * @memberof IssueEntry
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof IssueEntry
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IssueEntry
     */
    contactPhoneNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof IssueEntry
     */
    callbackTimeSlotFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof IssueEntry
     */
    callbackTimeSlotTo?: Date | null;
    /**
     * 
     * @type {Array<IssueAction>}
     * @memberof IssueEntry
     */
    readonly actions: Array<IssueAction>;
    /**
     * 
     * @type {IssueCreatorType}
     * @memberof IssueEntry
     */
    issueCreatorType?: IssueCreatorType;
    /**
     * 
     * @type {IssueCreator}
     * @memberof IssueEntry
     */
    issueCreator?: IssueCreator;
    /**
     * 
     * @type {OrganizationReference}
     * @memberof IssueEntry
     */
    organization?: OrganizationReference;
    /**
     * 
     * @type {LaundryGroupReference}
     * @memberof IssueEntry
     */
    laundryGroup?: LaundryGroupReference;
    /**
     * 
     * @type {LaundryReference}
     * @memberof IssueEntry
     */
    laundry?: LaundryReference;
    /**
     * 
     * @type {MachineReference}
     * @memberof IssueEntry
     */
    machine: MachineReference;
}

/**
 * Check if a given object implements the IssueEntry interface.
 */
export function instanceOfIssueEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "ticketKey" in value;
    isInstance = isInstance && "creationTimestamp" in value;
    isInstance = isInstance && "issueState" in value;
    isInstance = isInstance && "serviceType" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "actions" in value;
    isInstance = isInstance && "machine" in value;

    return isInstance;
}

export function IssueEntryFromJSON(json: any): IssueEntry {
    return IssueEntryFromJSONTyped(json, false);
}

export function IssueEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ticketKey': json['ticketKey'],
        'creationTimestamp': (new Date(json['creationTimestamp'])),
        'closingTimestamp': !exists(json, 'closingTimestamp') ? undefined : (json['closingTimestamp'] === null ? null : new Date(json['closingTimestamp'])),
        'issueState': IssueStateDataFromJSON(json['issueState']),
        'errorSeverity': !exists(json, 'errorSeverity') ? undefined : ErrorSeverityFromJSON(json['errorSeverity']),
        'serviceType': ServiceTypeFromJSON(json['serviceType']),
        'category': !exists(json, 'category') ? undefined : IssueCategoryReferenceFromJSON(json['category']),
        'description': json['description'],
        'contactName': !exists(json, 'contactName') ? undefined : json['contactName'],
        'contactPhoneNumber': !exists(json, 'contactPhoneNumber') ? undefined : json['contactPhoneNumber'],
        'callbackTimeSlotFrom': !exists(json, 'callbackTimeSlotFrom') ? undefined : (json['callbackTimeSlotFrom'] === null ? null : new Date(json['callbackTimeSlotFrom'])),
        'callbackTimeSlotTo': !exists(json, 'callbackTimeSlotTo') ? undefined : (json['callbackTimeSlotTo'] === null ? null : new Date(json['callbackTimeSlotTo'])),
        'actions': ((json['actions'] as Array<any>).map(IssueActionFromJSON)),
        'issueCreatorType': !exists(json, 'issueCreatorType') ? undefined : IssueCreatorTypeFromJSON(json['issueCreatorType']),
        'issueCreator': !exists(json, 'issueCreator') ? undefined : IssueCreatorFromJSON(json['issueCreator']),
        'organization': !exists(json, 'organization') ? undefined : OrganizationReferenceFromJSON(json['organization']),
        'laundryGroup': !exists(json, 'laundryGroup') ? undefined : LaundryGroupReferenceFromJSON(json['laundryGroup']),
        'laundry': !exists(json, 'laundry') ? undefined : LaundryReferenceFromJSON(json['laundry']),
        'machine': MachineReferenceFromJSON(json['machine']),
    };
}

export function IssueEntryToJSON(value?: IssueEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issueState': IssueStateDataToJSON(value.issueState),
        'errorSeverity': ErrorSeverityToJSON(value.errorSeverity),
        'serviceType': ServiceTypeToJSON(value.serviceType),
        'category': IssueCategoryReferenceToJSON(value.category),
        'description': value.description,
        'contactName': value.contactName,
        'contactPhoneNumber': value.contactPhoneNumber,
        'callbackTimeSlotFrom': value.callbackTimeSlotFrom === undefined ? undefined : (value.callbackTimeSlotFrom === null ? null : value.callbackTimeSlotFrom.toISOString()),
        'callbackTimeSlotTo': value.callbackTimeSlotTo === undefined ? undefined : (value.callbackTimeSlotTo === null ? null : value.callbackTimeSlotTo.toISOString()),
        'issueCreatorType': IssueCreatorTypeToJSON(value.issueCreatorType),
        'issueCreator': IssueCreatorToJSON(value.issueCreator),
        'organization': OrganizationReferenceToJSON(value.organization),
        'laundryGroup': LaundryGroupReferenceToJSON(value.laundryGroup),
        'laundry': LaundryReferenceToJSON(value.laundry),
        'machine': MachineReferenceToJSON(value.machine),
    };
}

