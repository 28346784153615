import React, { useEffect, useRef } from 'react'
import { NavigateFunction, useNavigate } from 'react-router'
import { Location } from 'history'
import {
  DataSettings,
  getDataSettingsFromUrl,
  updateUrlFromDataSettings,
} from 'src/service/view-model/base/DataSettings'
import { useDidComponentUpdate } from 'src/ui-shared/base/hooks/useDidComponentUpdate'

/**
 * Hook for updating the data settings state object with values from URL query parameters and vice versa,
 * updating url query parameters when the data settings state changes in UI.
 * @param location the window location
 * @param setDataSettings setter for new state for the data
 * @param dataSettings current state for the data
 * @param defaultDataSettings default state for the data
 */
export const useDataSettingsUrlSyncMain = (
  location: Location,
  setDataSettings: React.Dispatch<React.SetStateAction<any>>,
  dataSettings: DataSettings,
  defaultDataSettings: DataSettings,
  queryParamsCorrectionFunction?: (queryParams: Record<string, any>) => Record<string, any>,
): void => {
  const navigate = useNavigate()

  const ref = useRef(false)

  // update state from url on url query parameter changes
  useEffect(() => {
    // console.log('location.search changed', location.pathname + location.search)

    const newDataSettings = getStateFromUrl(location, dataSettings, queryParamsCorrectionFunction)
    // console.log('newDataSettings', newDataSettings)

    if (newDataSettings) {
      // reset organization if 'reset' parameter is present
      const newDataSettingsAny = newDataSettings as any
      if (newDataSettingsAny.reset) {
        delete newDataSettingsAny.organizationId
        delete newDataSettingsAny.reset
      }
      setDataSettings(newDataSettings)
    }

    // update url from state on the first render
    if (!ref.current) {
      ref.current = true
      const latestDataSettings = newDataSettings ? newDataSettings : dataSettings
      updateUrlFromState(location, navigate, latestDataSettings, defaultDataSettings)
    }
  }, [location.search])

  // update url from state on data settings change
  useDidComponentUpdate(() => {
    // console.log('dataSettings changed')
    updateUrlFromState(location, navigate, dataSettings, defaultDataSettings)
  }, [dataSettings])
}

export const useDataSettingsUrlSync = (
  location: Location,
  setDataSettings: React.Dispatch<React.SetStateAction<any>>,
  dataSettings: DataSettings,
  defaultDataSettings: DataSettings,
): void => {
  useDataSettingsUrlSyncMain(location, setDataSettings, dataSettings, defaultDataSettings, undefined)
}

function getStateFromUrl(
  location: Location,
  dataSettings: DataSettings,
  queryParamsCorrectionFunction?: (queryParams: Record<string, any>) => Record<string, any>,
): DataSettings | undefined {
  return getDataSettingsFromUrl(location, dataSettings, queryParamsCorrectionFunction)
}

function updateUrlFromState(
  location: Location,
  navigate: NavigateFunction,
  dataSettings: DataSettings,
  defaultDataSettings?: DataSettings,
): void {
  updateUrlFromDataSettings(location, navigate, dataSettings, defaultDataSettings)
}
