import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { Configuration, LaundryGroupReference, LaundryGroupsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

interface Props {
  laundryGroup?: LaundryGroupReference | null
  setLaundryGroup: (lg: LaundryGroupReference | null) => void
  preselectFirstLaundryGroup?: boolean
  limitToActiveOrganization?: boolean
}

export const LaundryAutocomplete = ({
  laundryGroup,
  setLaundryGroup,
  preselectFirstLaundryGroup,
  limitToActiveOrganization,
}: Props): ReactElement => {
  const { classes: textFieldClasses } = useTextFieldStyles()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)

  const translate = useTranslate()

  const activeOrganization = useActiveOrganization()

  //state
  const [laundryGroups, setLaundryGroups] = useState<LaundryGroupReference[]>([])
  const [selectedLaundryGroup, setSelectedLaundryGroup] = useState<LaundryGroupReference | null>(null)

  const loadLaundryGroups = () => {
    // optionally filter laundry groups only by active organization
    const organizations = limitToActiveOrganization && activeOrganization ? [activeOrganization.id] : undefined
    laundryGroupsApi
      .laundrygroupsRefGet(undefined, undefined, organizations)
      .then((laundryGroups) => {
        setLaundryGroups(laundryGroups)

        // preselect first laundry group if needed
        if (preselectFirstLaundryGroup) {
          const firstLaundryGroup = laundryGroups.length > 0 ? laundryGroups[0] : null
          setSelectedLaundryGroup(firstLaundryGroup)
          handleLaundryGroupChange({} as React.SyntheticEvent, firstLaundryGroup)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
      })
  }

  // Initializing autocompletes
  useEffect(() => {
    loadLaundryGroups()
  }, [activeOrganization])

  const handleLaundryGroupChange = (event: React.SyntheticEvent, laundryGroupRef: LaundryGroupReference | null) => {
    setSelectedLaundryGroup(laundryGroupRef)
    setLaundryGroup(laundryGroupRef)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={true}>
        <Autocomplete
          openOnFocus={true}
          multiple={false}
          freeSolo={false}
          disableClearable={false}
          options={laundryGroups}
          getOptionLabel={(option) => option.name || ''}
          isOptionEqualToValue={(option) => option.id === laundryGroup?.id}
          onChange={handleLaundryGroupChange}
          value={selectedLaundryGroup}
          noOptionsText={translate('autocompleteNoOptions')}
          loadingText={translate('autocompleteLoading')}
          renderInput={(params) => (
            <TextFieldDefault
              {...params}
              label={translate('laundryGroup')}
              className={textFieldClasses.TextFieldSmall}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
