import React, { PropsWithChildren, ReactElement, useImperativeHandle, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Popover } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import { FilterListIcon } from 'src/ui-shared/icons/Icons'

const useTableFilterFieldStyles = makeStyles()((theme) => {
  return {
    Paper: {
      boxSizing: 'border-box',
      padding: theme.spacing(3),
    },
    FilterIconButton: {
      padding: 0,
    },
    FilterIconButtonActive: {
      padding: 0,
      background: '#ddd',
    },
  }
})

interface Props extends PropsWithChildren {
  title: string
  active: boolean
}

export interface TableFilterPopupWithClose {
  close: () => void
}

export const TableFilterPopup = React.forwardRef<TableFilterPopupWithClose, Props>(function TableFilterPopup(
  props: Props,
  ref,
): ReactElement {
  // expand properties
  const { title, active, children } = props

  const { classes: filterClasses } = useTableFilterFieldStyles()

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)

  // events
  const open = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const close = () => {
    setAnchorEl(null)
  }

  // expose close function to parent components
  useImperativeHandle(ref, () => ({
    close() {
      close()
    },
  }))

  return (
    <>
      <Tooltip title={title} placement="top">
        <IconButton
          className={active ? filterClasses.FilterIconButtonActive : filterClasses.FilterIconButton}
          aria-label="filter list"
          onClick={open}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={close}
      >
        <Paper className={filterClasses.Paper}>{children}</Paper>
      </Popover>
    </>
  )
})
