import React, { FC, ReactElement } from 'react'
import { Grid } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import {
  composeValidators,
  required,
  validCoordinates,
  validZipCode,
  validZipCodeSize,
} from 'src/ui-shared/base/form/validation/Validators'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { CountryAutoCompleteValidate } from 'src/ui-shared/form/control/CountryAutoCompleteValidate'

export type AddressType = 'billing' | 'site' | ''

interface Props {
  submitting: boolean
  editMode: boolean
  addressType?: AddressType
  disabled?: boolean
  disableCountryEdit?: boolean
  hideCoordinates?: boolean
  addressMandatory?: boolean
  cityMandatory?: boolean
}

export const AddressSectionEdit: FC<Props> = ({
  submitting,
  editMode,
  addressType,
  disabled = false,
  disableCountryEdit,
  hideCoordinates,
  addressMandatory = true,
  cityMandatory = true,
}): ReactElement => {
  const translate = useTranslate()

  let labelSuffix = ''

  if (addressType === 'billing') {
    labelSuffix = 'Billing'
  }

  let namePrefix = ''
  if (addressType === 'billing') {
    namePrefix = 'billingAddress.'
  } else if (addressType === 'site') {
    namePrefix = 'siteAddress.'
  }
  return (
    <>
      <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
        <TextField
          label={translate(`address1${labelSuffix}`)}
          type="text"
          name={namePrefix + 'addressLine1'}
          fullWidth
          disabled={submitting || disabled}
          validate={addressMandatory ? required() : undefined}
        />
      </Grid>
      <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
        <TextField
          label={translate(`address2${labelSuffix}`)}
          type="text"
          name={namePrefix + 'addressLine2'}
          fullWidth
          disabled={submitting || disabled}
        />
      </Grid>
      <Grid item {...ITEM_BREAKPOINTS}>
        <TextField
          label={translate(`zipCode${labelSuffix}`)}
          type="text"
          name={namePrefix + 'zipCode'}
          fullWidth
          disabled={submitting || disabled}
          validate={
            cityMandatory
              ? composeValidators(required(), validZipCode(), validZipCodeSize())
              : composeValidators(validZipCode(), validZipCodeSize())
          }
        />
      </Grid>
      <Grid item {...ITEM_BREAKPOINTS}>
        <TextField
          label={translate(`city${labelSuffix}`)}
          type="text"
          name={namePrefix + 'city'}
          fullWidth
          disabled={submitting || disabled}
          validate={cityMandatory ? required() : undefined}
        />
      </Grid>
      <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
        {editMode && disableCountryEdit ? (
          <TextField
            label={translate(`country${labelSuffix}`)}
            type="text"
            name={namePrefix + 'countryCode.name'}
            fullWidth
            disabled
          />
        ) : (
          <CountryAutoCompleteValidate
            name={namePrefix + 'countryCode'}
            disabled={submitting || disabled}
            validate={required()}
          />
        )}
      </Grid>
      {hideCoordinates ? null : (
        <>
          <Grid item {...ITEM_BREAKPOINTS} md={6} lg={3}>
            <TextField
              label={translate(`latitude${labelSuffix}`)}
              type="text"
              name={namePrefix + 'latitude'}
              fullWidth
              disabled={submitting || disabled}
              validate={validCoordinates(-90, 90)}
            />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS} md={6} lg={3}>
            <TextField
              label={translate(`longitude${labelSuffix}`)}
              type="text"
              name={namePrefix + 'longitude'}
              fullWidth
              disabled={submitting || disabled}
              validate={validCoordinates(-180, 180)}
            />
          </Grid>
        </>
      )}
    </>
  )
}
