import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Icon } from '@mdi/react'
import { makeStyles } from 'tss-react/mui'
import { Box, Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { OpenInNew } from '@mui/icons-material'
import { mdiAccountCancel, mdiNoteMultiple } from '@mdi/js'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { BackendUserProfile, Configuration, MeApi, Region } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { CheckboxField } from 'src/ui-shared/base/form/control/CheckboxField'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { VerticalDivider } from 'src/ui-shared/base/form/control/VerticalDivider'
import { required } from 'src/ui-shared/base/form/validation/Validators'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { CloseAccountDialog } from 'src/ui/layout-page/profile/CloseAccountDialog'
import { CloseAccountEmailConfirmDialog } from 'src/ui/layout-page/profile/CloseAccountEmailConfirmDialog'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { KeycloakService } from 'src/user/KeycloakService'
import { handleLogout } from 'src/user/Logout'
import { RegionObject, SUPPORTED_REGIONS, getRegionObject } from 'src/user/Regions'
import { SUPPORTED_LANGUAGES, SupportedLanguage, getSupportedLanguage } from 'src/user/SupportedLanguage'
import { SUPPORTED_TEMP_UNITS, TEMP_UNIT_CELSIUS, TempUnitObject, getTempUnitObject } from 'src/user/TemperatureUnits'
import { UserContext } from 'src/user/UserContext'
import { SUPPORTED_WEIGHT_UNITS, WEIGHT_UNIT_KG, WeightUnitObject, getWeightUnitObject } from 'src/user/WeightUnits'

const useStyles = makeStyles()(() => {
  return {
    button: {
      minWidth: 150,
      marginRight: 32,
      '&:first-of-type': {
        marginRight: 'auto',
      },
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  }
})

export const ProfilePage: FC = (): ReactElement => {
  const { classes } = useStyles()
  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const { user, updateUser, changeToken } = useContext(UserContext)

  const httpConfiguration: Configuration = useContext(HttpContext)
  const meApi = new MeApi(httpConfiguration)

  // state
  const [me, setMe] = useState<BackendUserProfile>(user)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [passwordResetModal, setPasswordResetModal] = useState<boolean>(false)
  const [closeAccountModal, setCloseAccountModal] = useState<boolean>(false)
  const [closeAccountEmailConfirmModal, setCloseAccountEmailConfirmModal] = useState<boolean>(false)
  const [emailConfirmMessage, setEmailConfirmMessage] = useState<string>('')

  const defaultSelectedLanguage: SupportedLanguage = getSupportedLanguage(user.locale)
  const [selectedLanguage, setSelectedLanguage] = useState<SupportedLanguage>(defaultSelectedLanguage)

  const defaultSelectedRegion: RegionObject | undefined = getRegionObject(user.regionData?.region)
  const [selectedRegion, setSelectedRegion] = useState<RegionObject | undefined>(defaultSelectedRegion)

  const defaultWeightUnit: WeightUnitObject | undefined = getWeightUnitObject(user.regionData?.weight)
  const [selectedWeightUnit, setSelectedWeightUnit] = useState<WeightUnitObject | undefined>(defaultWeightUnit)

  const defaultTempUnit: TempUnitObject | undefined = getTempUnitObject(user.regionData?.temperature)
  const [selectedTempUnit, setSelectedTempUnit] = useState<TempUnitObject | undefined>(defaultTempUnit)

  // derived state
  const disableFields = !editMode

  useHotKeysForm()

  // load data
  useEffect(() => {
    setLoading(true)

    meApi
      .meGet()
      .then((backendUserProfile) => {
        setLoading(false)
        setMe(backendUserProfile)
      })
      .catch((err) => {
        setLoading(false)
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }, [user])

  // events

  const setSelectedRegionInternal = (newRegion: RegionObject | undefined) => {
    setSelectedRegion(newRegion)
    if (newRegion) {
      setSelectedTempUnit(newRegion.tempUnit)
      setSelectedWeightUnit(newRegion.weightUnit)
    }
  }

  const doSave = (editedMe: BackendUserProfile) => {
    setLoading(true)

    const newLanguage = editedMe.locale
    const oldLanguage = defaultSelectedLanguage.value
    const languageChange = newLanguage !== oldLanguage

    meApi
      .mePut(editedMe)
      .then((updatedMe) => {
        setLoading(false)
        setEditMode(false)
        setMe(updatedMe)

        updateUser(updatedMe)

        const successMessage = translate('profileEditSuccess')
        showSnackbar(successMessage, 'success')

        // update the login token on language change
        if (languageChange) {
          const keycloakInstance = KeycloakService.getKeycloakInstance()
          keycloakInstance
            .updateToken(60 * 60)
            .then(function (refreshed) {
              if (refreshed) {
                const newToken = keycloakInstance.token
                if (newToken) {
                  changeToken(newToken)
                }
              }
            })
            .catch((err) => {
              console.error('Failed to get new token', err)
            })
        }
      })
      .catch((err) => {
        setLoading(false)

        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)

        showSnackbar(errorMessage, 'error')
      })
  }

  const doPasswordReset = () => {
    setPasswordResetModal(false)
    setErrorMessage(null)
    meApi
      .meResetPasswordPost()
      .then(() => {
        const successMessage = translate('passwordResetSuccess')
        showSnackbar(successMessage, 'success')
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)

        showSnackbar(errorMessage, 'error')
      })
  }

  const requestPersonalData = () => {
    meApi
      .mePersonalDataPost()
      .then(({ message }) => {
        showSnackbar(message, 'success')
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)

        showSnackbar(errorMessage, 'error')
      })
  }

  const doCloseAccount = () => {
    meApi
      .meCloseAccountPost()
      .then((data) => {
        setEmailConfirmMessage(data.message)
        setCloseAccountEmailConfirmModal(true)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
        setCloseAccountModal(false)
      })
    setCloseAccountModal(false)
  }

  const doCloseAccountEmailConfirm = () => {
    setCloseAccountEmailConfirmModal(false)
    handleLogout()
  }
  // events

  const submitForm = (editedMe: BackendUserProfile) => {
    editedMe.locale = selectedLanguage.value

    editedMe.regionData = {
      region: selectedRegion?.id as Region,
      temperature: selectedTempUnit?.id ?? TEMP_UNIT_CELSIUS.id,
      weight: selectedWeightUnit?.id ?? WEIGHT_UNIT_KG.id,
    }

    doSave(editedMe)
  }

  const handleCancel = () => {
    setSelectedLanguage(defaultSelectedLanguage)
    setEditMode(false)
  }

  const handlePasswordReset = () => {
    setPasswordResetModal(true)
  }

  // JSX

  const displayBoth = (s1: string, s2: string): string => {
    if (s1 === s2) {
      return s1
    } else {
      return s1 + ' / ' + s2
    }
  }

  const getUserRoles = (): string => {
    let roles
    const globalRoles = me.globalRoles
    const memberRoles = me.memberRolesAll

    // if roles from meApi request not initialized, show the ones from keycloak
    if (globalRoles.length > 0 || memberRoles.length > 0) {
      roles = [...globalRoles, ...memberRoles].map((role) => role.name).join(', ')
    } else {
      roles = user.roles
        .filter((role) => role === role.toUpperCase())
        .map((role) => translate(role.toUpperCase()))
        .join(', ')
    }
    return roles
  }

  const displayButtons = editMode ? (
    <Box display="flex" justifyContent="flex-end">
      <Box>
        <Button
          id="cancelButton"
          variant="text"
          color="primary"
          size="large"
          disabled={loading}
          className={classes.button}
          onClick={handleCancel}
        >
          {translate('button.cancel')}
        </Button>
      </Box>

      <Box>
        <Button
          id="submitButton"
          variant="contained"
          color="primary"
          size="large"
          disabled={loading}
          className={classes.button}
          type="submit"
        >
          {translate('button.save')}
        </Button>
      </Box>
    </Box>
  ) : (
    <Box display="flex" justifyContent="flex-end">
      <Button
        id="editButton"
        variant="contained"
        color="primary"
        size="large"
        className={classes.button}
        onClick={() => setEditMode(true)}
      >
        {translate('button.edit')}
      </Button>
    </Box>
  )

  return (
    <ScreenLayout title={translate('user.settings')} actionsWidth={50}>
      <Paper elevation={0}>
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
        <Form<BackendUserProfile>
          initialValues={me}
          onSubmit={submitForm}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Box>
                  <Grid container rowSpacing={0} columnSpacing={2} className={sharedClasses.GridWithTextField}>
                    <Grid item {...ITEM_BREAKPOINTS}>
                      <TextField
                        name="firstName"
                        fullWidth
                        disabled={disableFields || submitting}
                        label={translate('user.firstName')}
                        validate={required()}
                      />
                    </Grid>
                    <Grid item {...ITEM_BREAKPOINTS}>
                      <TextField
                        name="lastName"
                        fullWidth
                        disabled={disableFields || submitting}
                        label={translate('user.lastName')}
                        validate={required()}
                      />
                    </Grid>
                  </Grid>
                  <Grid container rowSpacing={0} columnSpacing={2} className={sharedClasses.GridWithTextField}>
                    <Grid item {...ITEM_BREAKPOINTS}>
                      <DetailsTextField value={getUserRoles()} label={translate('user.role')} />
                    </Grid>
                    <Grid item {...ITEM_BREAKPOINTS}>
                      <Autocomplete
                        options={SUPPORTED_LANGUAGES}
                        openOnFocus={true}
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        getOptionLabel={(option): string => option?.label ?? ''}
                        multiple={false}
                        freeSolo={false}
                        disableClearable
                        value={selectedLanguage}
                        onChange={(_event, newValue) => {
                          setSelectedLanguage(newValue)
                        }}
                        disabled={disableFields || submitting}
                        renderInput={(params) => <TextFieldDefault {...params} label={translate('user.language')} />}
                      />
                    </Grid>
                  </Grid>
                  <Grid container rowSpacing={0} columnSpacing={2} className={sharedClasses.GridWithTextField}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <CheckboxField
                        name="allowsAdvertisements"
                        defaultChecked={false}
                        disabled={disableFields || submitting}
                        label={translate('user.allowsAdvertisements')}
                      />
                    </Grid>
                  </Grid>
                  <Typography variant="h4" mt={2}>
                    {translate('regionalSettings')}
                  </Typography>
                  <Grid container rowSpacing={0} columnSpacing={2} className={sharedClasses.GridWithTextField}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Autocomplete
                        options={SUPPORTED_REGIONS}
                        openOnFocus={true}
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        getOptionLabel={(option): string => translate(option.name) ?? ''}
                        multiple={false}
                        freeSolo={false}
                        disableClearable
                        value={selectedRegion}
                        onChange={(_event, newValue) => {
                          setSelectedRegionInternal(newValue)
                        }}
                        disabled={disableFields || submitting}
                        renderInput={(params) => (
                          <TextFieldDefault
                            {...params}
                            label={translate('region')}
                            helperText={translate('regionHelpText')}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <Autocomplete
                        options={SUPPORTED_WEIGHT_UNITS}
                        openOnFocus={true}
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        getOptionLabel={(option): string => translate(option.name) ?? ''}
                        multiple={false}
                        freeSolo={false}
                        disableClearable
                        value={selectedWeightUnit}
                        onChange={(_event, newValue) => {
                          setSelectedWeightUnit(newValue)
                        }}
                        disabled={disableFields || submitting}
                        renderInput={(params) => <TextFieldDefault {...params} label={translate('weightUnit')} />}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <Autocomplete
                        options={SUPPORTED_TEMP_UNITS}
                        openOnFocus={true}
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        getOptionLabel={(option): string => translate(option.name) ?? ''}
                        multiple={false}
                        freeSolo={false}
                        disableClearable
                        value={selectedTempUnit}
                        onChange={(_event, newValue) => {
                          setSelectedTempUnit(newValue)
                        }}
                        disabled={disableFields || submitting}
                        renderInput={(params) => <TextFieldDefault {...params} label={translate('temperatureUnit')} />}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={2} mb={3}>
                  {displayButtons}
                </Box>

                <Divider />
                <Box mt={2} mb={2}>
                  <Typography variant="h4">{translate('user.email')}</Typography>

                  <Grid container spacing={4}>
                    <Grid item {...ITEM_BREAKPOINTS}>
                      <Typography variant="subtitle1" pt={1}>
                        {displayBoth(user.email, me.email)}
                      </Typography>
                    </Grid>

                    <Grid item {...ITEM_BREAKPOINTS}>
                      <Stack
                        direction={{ sm: 'column', md: 'row' }}
                        justifyContent="flex-end"
                        alignItems="flex-start"
                        spacing={1}
                        divider={<VerticalDivider />}
                      >
                        <Button
                          variant="text"
                          color="primary"
                          size="large"
                          onClick={requestPersonalData}
                          startIcon={<Icon path={mdiNoteMultiple} size={1} />}
                        >
                          {translate('requestPersonalData')}
                        </Button>
                        <Button
                          variant="text"
                          color="primary"
                          size="large"
                          onClick={() => setCloseAccountModal(true)}
                          startIcon={<Icon path={mdiAccountCancel} size={1} />}
                        >
                          {translate('closeAccount')}
                        </Button>
                        <Button
                          variant="text"
                          color="primary"
                          size="large"
                          onClick={handlePasswordReset}
                          startIcon={<OpenInNew />}
                        >
                          {translate('passwordReset')}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </form>
            )
          }}
        />
      </Paper>

      <CloseAccountDialog
        key={closeAccountModal + ''}
        closeAccountModal={closeAccountModal}
        setCloseAccountModal={setCloseAccountModal}
        onConfirm={doCloseAccount}
        label={translate('closeAccountConfirm', me.firstName + ' ' + me.lastName)}
      />

      <CloseAccountEmailConfirmDialog
        closeAccountEmailConfirmModal={closeAccountEmailConfirmModal}
        emailConfirmMessage={emailConfirmMessage}
        closeAccount={doCloseAccountEmailConfirm}
      />

      <ConfirmationModalDialog
        titleKey="passwordReset"
        confirmationKey="passwordReset"
        open={passwordResetModal}
        onConfirm={doPasswordReset}
        onCancel={() => setPasswordResetModal(false)}
      >
        {translate('passwordResetConfirm')}
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
