/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Laundry user Import State
 * @export
 * @enum {string}
 */
export enum LaundryUserImportState {
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
    NO_ACTION = 'NO_ACTION',
    ERROR = 'ERROR'
}


export function LaundryUserImportStateFromJSON(json: any): LaundryUserImportState {
    return LaundryUserImportStateFromJSONTyped(json, false);
}

export function LaundryUserImportStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryUserImportState {
    return json as LaundryUserImportState;
}

export function LaundryUserImportStateToJSON(value?: LaundryUserImportState | null): any {
    return value as any;
}

