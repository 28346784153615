/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';
import type { Role } from './Role';
import {
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface Invitation
 */
export interface Invitation {
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    readonly id: string;
    /**
     * 
     * @type {OrganizationReference}
     * @memberof Invitation
     */
    organization?: OrganizationReference;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    readonly token: string;
    /**
     * 
     * @type {Date}
     * @memberof Invitation
     */
    readonly validity: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Invitation
     */
    readonly expired: boolean;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    email: string;
    /**
     * 
     * @type {Role}
     * @memberof Invitation
     */
    proposedMemberRole: Role;
}

/**
 * Check if a given object implements the Invitation interface.
 */
export function instanceOfInvitation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "validity" in value;
    isInstance = isInstance && "expired" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "proposedMemberRole" in value;

    return isInstance;
}

export function InvitationFromJSON(json: any): Invitation {
    return InvitationFromJSONTyped(json, false);
}

export function InvitationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invitation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'organization': !exists(json, 'organization') ? undefined : OrganizationReferenceFromJSON(json['organization']),
        'token': json['token'],
        'validity': (new Date(json['validity'])),
        'expired': json['expired'],
        'email': json['email'],
        'proposedMemberRole': RoleFromJSON(json['proposedMemberRole']),
    };
}

export function InvitationToJSON(value?: Invitation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization': OrganizationReferenceToJSON(value.organization),
        'email': value.email,
        'proposedMemberRole': RoleToJSON(value.proposedMemberRole),
    };
}

