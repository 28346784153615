/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChartDataset } from './ChartDataset';
import {
    ChartDatasetFromJSON,
    ChartDatasetFromJSONTyped,
    ChartDatasetToJSON,
} from './ChartDataset';
import type { ChartType } from './ChartType';
import {
    ChartTypeFromJSON,
    ChartTypeFromJSONTyped,
    ChartTypeToJSON,
} from './ChartType';

/**
 * 
 * @export
 * @interface ChartData
 */
export interface ChartData {
    /**
     * 
     * @type {string}
     * @memberof ChartData
     */
    title?: string;
    /**
     * contains the values shown on the X axis, on doughnut will add an inner ring of different time/timescale
     * @type {Array<string>}
     * @memberof ChartData
     */
    xValues: Array<string>;
    /**
     * 
     * @type {ChartType}
     * @memberof ChartData
     */
    chartType?: ChartType;
    /**
     * contains values sorted on xValue
     * @type {Array<ChartDataset>}
     * @memberof ChartData
     */
    datasets: Array<ChartDataset>;
}

/**
 * Check if a given object implements the ChartData interface.
 */
export function instanceOfChartData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "xValues" in value;
    isInstance = isInstance && "datasets" in value;

    return isInstance;
}

export function ChartDataFromJSON(json: any): ChartData {
    return ChartDataFromJSONTyped(json, false);
}

export function ChartDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChartData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'xValues': json['xValues'],
        'chartType': !exists(json, 'chartType') ? undefined : ChartTypeFromJSON(json['chartType']),
        'datasets': ((json['datasets'] as Array<any>).map(ChartDatasetFromJSON)),
    };
}

export function ChartDataToJSON(value?: ChartData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'xValues': value.xValues,
        'chartType': ChartTypeToJSON(value.chartType),
        'datasets': ((value.datasets as Array<any>).map(ChartDatasetToJSON)),
    };
}

