import React, { FC, ReactElement, useState } from 'react'
import { Box, DialogActions, DialogContent } from '@mui/material'
import Button from '@mui/material/Button'
import { useTranslate } from 'src/i18n/useMessageSource'
import { MachineType, ProgramGroup } from 'src/service/backend/api'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ProgramGroupListModal } from 'src/ui/page/es/program-group/list/ProgramGroupListModal'

export interface Props {
  open: boolean
  titleKey: string
  confirmationKey: string
  fixedOrganizationId?: string
  addSelectedRow: (programGroup: ProgramGroup) => Promise<any>
  onCancel: () => void
  activeTabOnly?: MachineType
}

export const ProgramGroupAddModal: FC<Props> = ({
  open,
  onCancel,
  titleKey,
  confirmationKey,
  fixedOrganizationId,
  addSelectedRow,
  activeTabOnly,
}): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const [selectedProgramGroup, setSelectedProgramGroup] = useState<ProgramGroup | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleSelectProgramGroup = (programGroup: ProgramGroup | null) => {
    setSelectedProgramGroup(programGroup)
  }

  const handleSubmit = () => {
    if (selectedProgramGroup !== null) {
      setLoading(true)
      addSelectedRow(selectedProgramGroup).finally(() => {
        setLoading(false)
      })
    }
  }

  return (
    <ModalDialog open={open} onClose={onCancel} title={translate(titleKey)} maxWidth="md">
      <LoadingIndicator loading={loading} />
      <DialogContent>
        <ProgramGroupListModal
          fixedOrganizationId={fixedOrganizationId}
          selectProgramGroup={handleSelectProgramGroup}
          activeTabOnly={activeTabOnly}
        />
      </DialogContent>
      <DialogActions>
        <Box mt={2} p={2} display="flex" justifyContent="flex-end">
          <Button variant="text" color="primary" size="large" className={sharedClasses.ButtonMargin} onClick={onCancel}>
            {translate('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={selectedProgramGroup === null || loading}
            onClick={handleSubmit}
          >
            {translate(confirmationKey)}
          </Button>
        </Box>
      </DialogActions>
    </ModalDialog>
  )
}
