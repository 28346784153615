/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserReactionCategoryReference,
  UserReactionRequest,
  UserReactionType,
} from '../models';
import {
    UserReactionCategoryReferenceFromJSON,
    UserReactionCategoryReferenceToJSON,
    UserReactionRequestFromJSON,
    UserReactionRequestToJSON,
    UserReactionTypeFromJSON,
    UserReactionTypeToJSON,
} from '../models';

export interface AppReactionsCategoriesUserReactionTypeGetRequest {
    userReactionType: UserReactionType;
}

export interface AppReactionsPostRequest {
    userReactionRequest?: UserReactionRequest;
}

/**
 * 
 */
export class AppApi extends runtime.BaseAPI {

    /**
     * List predefined user reaction category reference
     */
    async appReactionsCategoriesUserReactionTypeGetRaw(requestParameters: AppReactionsCategoriesUserReactionTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserReactionCategoryReference>>> {
        if (requestParameters.userReactionType === null || requestParameters.userReactionType === undefined) {
            throw new runtime.RequiredError('userReactionType','Required parameter requestParameters.userReactionType was null or undefined when calling appReactionsCategoriesUserReactionTypeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/app/reactions/categories/{userReactionType}`.replace(`{${"userReactionType"}}`, encodeURIComponent(String(requestParameters.userReactionType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserReactionCategoryReferenceFromJSON));
    }

    /**
     * List predefined user reaction category reference
     */
    async appReactionsCategoriesUserReactionTypeGet(userReactionType: UserReactionType, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserReactionCategoryReference>> {
        const response = await this.appReactionsCategoriesUserReactionTypeGetRaw({ userReactionType: userReactionType }, initOverrides);
        return await response.value();
    }

    /**
     * Create a user reaction or a problem report
     */
    async appReactionsPostRaw(requestParameters: AppReactionsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/app/reactions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserReactionRequestToJSON(requestParameters.userReactionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a user reaction or a problem report
     */
    async appReactionsPost(userReactionRequest?: UserReactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appReactionsPostRaw({ userReactionRequest: userReactionRequest }, initOverrides);
    }

}
