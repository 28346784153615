import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Divider, Grid, Hidden, Paper } from '@mui/material'
import { GetApp } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { ApiResponse, Configuration, ReportWashMasterApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getRemoveAcceptHeaderInit } from 'src/service/backend/http/HttpUtils'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { downloadFile, getDownloadFilename } from 'src/service/utils/FileDownloadUtils'
import { getLastMonth, removeTimeFromDate, removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { StyledTab } from 'src/ui-shared/base/tab-panel/StyledTab'
import { StyledTabs } from 'src/ui-shared/base/tab-panel/StyledTabs'
import { TabPanel } from 'src/ui-shared/base/tab-panel/TabPanel'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ArticleIcon, InsertChartOutlinedIcon } from 'src/ui-shared/icons/Icons'
import { getDefaultTableSettings, withOrganization } from 'src/ui-shared/table/Table.const'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { UsageChart } from 'src/ui/page/wm/usage/list/UsageChart'
import { UsageTab } from 'src/ui/page/wm/usage/list/UsageTab'
import { UsageTable, UsageTableSettings } from 'src/ui/page/wm/usage/list/UsageTable'

const dateNow = removeTimeFromDateEndOfDay(new Date())
const dateLastMonth = removeTimeFromDate(getLastMonth(dateNow))

export const UsageListPage = (): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  const navigate = useNavigate()
  const appId = useAppId()
  const activeOrganization = useActiveOrganization()
  const showSnackbar = useShowSnackbar()
  const { tabNameParam } = useParams()
  const tabName = tabNameParam ? getEnumFromString(tabNameParam, UsageTab) : UsageTab.DATA

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportApi = new ReportWashMasterApi(httpConfiguration)

  const defaultTableSettings: UsageTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'startTime',
    orderDir: 'desc',
    startTimeFrom: dateLastMonth.toISOString(),
    startTimeTo: dateNow.toISOString(),
  }

  const [activeTab, setActiveTab] = useState<UsageTab>(UsageTab.DATA)
  const [tableSettings, setTableSettings] = useState<UsageTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )

  // load data
  useEffect(() => {
    setActiveTab(tabName)
  }, [])

  // handle events
  const handleExportButtonClick = async () => {
    if (!tableSettings.startTimeFrom || !tableSettings.startTimeTo) {
      return
    }
    const dateStartTimeFrom: Date = new Date(tableSettings.startTimeFrom)
    const dateStartTimeTo: Date = new Date(tableSettings.startTimeTo)

    let filename: string | null
    reportApi
      .reportWmUsagesCsvGetRaw(
        {
          startTimeFrom: dateStartTimeFrom,
          startTimeTo: dateStartTimeTo,
          organizationId: tableSettings.organizationId,
          laundryGroupId: tableSettings.laundryGroupId,
          laundryId: tableSettings.laundryId,
          machineId: tableSettings.machineId,
          laundryUserId: tableSettings.laundryUserId,
          currency: undefined,
          paymentMethod: tableSettings.paymentMethod,
          machineCategory: tableSettings.machineCategory,
        },
        getRemoveAcceptHeaderInit(),
      )
      .then(async (response: ApiResponse<Blob>) => {
        filename = getDownloadFilename(response.raw)
        return response.value()
      })
      .then((blob: Blob) => {
        downloadFile(blob, filename)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: UsageTab) => {
    const pathToNavigate = `/${appId}/usages/view/${newValue}`
    navigate(pathToNavigate, { replace: true })
    setActiveTab(newValue)
  }

  return (
    <ScreenLayout
      title={translate('usages')}
      actionsWidth={170}
      actions={
        <>
          {activeTab === 'data' ? (
            <ListingButton
              onClick={handleExportButtonClick}
              variant="outlined"
              color="primary"
              startIcon={<GetApp />}
              disabled={!tableSettings.startTimeFrom || !tableSettings.startTimeTo}
            >
              {translate('exportCSV')}
            </ListingButton>
          ) : null}
        </>
      }
    >
      <Paper elevation={0}>
        <Grid container className={sharedClasses.TabsContainer}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <StyledTabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={handleTabChange}
            >
              <StyledTab
                icon={<ArticleIcon />}
                iconPosition={'start'}
                value={UsageTab.DATA}
                label={<Hidden mdDown>{translate('data')}</Hidden>}
              />
              <StyledTab
                icon={<InsertChartOutlinedIcon />}
                iconPosition={'start'}
                value={UsageTab.DASHBOARD}
                label={<Hidden mdDown>{translate('dashboard')}</Hidden>}
              />
            </StyledTabs>
          </Grid>
        </Grid>
        <Divider />
        <TabPanel value={activeTab} index={UsageTab.DATA}>
          <UsageTable
            tableSettings={tableSettings}
            defaultTableSettings={defaultTableSettings}
            setTableSettings={setTableSettings}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={UsageTab.DASHBOARD}>
          <UsageChart />
        </TabPanel>
      </Paper>
    </ScreenLayout>
  )
}
