import React from 'react'
import { FormatListNumberedRtlOutlined, HomeOutlined, Inventory2Outlined } from '@mui/icons-material'
import { EsMenuSections } from 'src/routing/MenuSections'
import { RoutesList } from 'src/routing/Routing'
import { APP_SHARED_ROUTES } from 'src/routing/SharedRoutes'
import { Permission } from 'src/service/backend/api'
import { IndexPageEs } from 'src/ui/page/es/index/IndexPageEs'
import { ProgramGroupCopyPage } from 'src/ui/page/es/program-group/details/ProgramGroupCopyPage'
import { ProgramGroupCreatePage } from 'src/ui/page/es/program-group/details/ProgramGroupCreatePage'
import { ProgramGroupDetailsPage } from 'src/ui/page/es/program-group/details/ProgramGroupDetailsPage'
import { ProgramGroupEditPage } from 'src/ui/page/es/program-group/details/ProgramGroupEditPage'
import { ProgramGroupListPage } from 'src/ui/page/es/program-group/list/ProgramGroupListPage'
import { ProgramCopyPage } from 'src/ui/page/es/program/details/ProgramCopyPage'
import { ProgramCreatePage } from 'src/ui/page/es/program/details/ProgramCreatePage'
import { ProgramDetailsPage } from 'src/ui/page/es/program/details/ProgramDetailsPage'
import { ProgramEditPage } from 'src/ui/page/es/program/details/ProgramEditPage'
import { ProgramImportPage } from 'src/ui/page/es/program/details/ProgramImportPage'
import { ProgramListPage } from 'src/ui/page/es/program/list/ProgramListPage'

export const EASY_SET_ROUTES: RoutesList = {
  IndexPageEs: {
    path: '',
    label: 'home',
    googleAnalyticsTitle: 'easySet Home',
    Component: IndexPageEs,
    sectionLabel: EsMenuSections.WASHING_PROGRAMS,
    icon: <HomeOutlined />,
  },
  ProgramListPage: {
    path: 'programs',
    label: 'programs',
    googleAnalyticsTitle: 'easySet Programs',
    Component: ProgramListPage,
    icon: <FormatListNumberedRtlOutlined />,
    permission: Permission.EASYSET_PROGRAM_READ,
  },
  ProgramListPageTab: {
    path: 'programs/:tabNameParam',
    googleAnalyticsTitle: 'easySet Programs',
    Component: ProgramListPage,
    permission: Permission.EASYSET_PROGRAM_READ,
  },
  ProgramDetailsPage: {
    path: 'programs/:machineTypeParam/:programId/view/:tabNameParam',
    googleAnalyticsTitle: 'easySet Program Details',
    Component: ProgramDetailsPage,
    permission: Permission.EASYSET_PROGRAM_READ,
  },
  ProgramCreatePage: {
    path: 'programs/:machineTypeParam/create/:tabNameParam',
    googleAnalyticsTitle: 'easySet Program Create',
    Component: ProgramCreatePage,
    permission: Permission.EASYSET_PROGRAM_WRITE,
  },
  ProgramEditPage: {
    path: 'programs/:machineTypeParam/:programId/edit/:tabNameParam',
    googleAnalyticsTitle: 'easySet Program Edit',
    Component: ProgramEditPage,
    permission: Permission.EASYSET_PROGRAM_WRITE,
  },
  ProgramCopyPage: {
    path: 'programs/:machineTypeParam/:programId/copy/:tabNameParam',
    googleAnalyticsTitle: 'easySet Program Copy',
    Component: ProgramCopyPage,
    permission: Permission.EASYSET_PROGRAM_WRITE,
  },
  ProgramImportPage: {
    path: 'programs/:tabNameParam/import',
    googleAnalyticsTitle: 'easySet Program Import',
    Component: ProgramImportPage,
    permission: Permission.EASYSET_PROGRAM_WRITE,
  },

  ProgramGroupsListPage: {
    path: 'program-groups',
    label: 'programGroups',
    googleAnalyticsTitle: 'easySet Program Groups',
    Component: ProgramGroupListPage,
    icon: <Inventory2Outlined />,
    permission: Permission.EASYSET_PROGRAM_READ,
  },
  ProgramGroupsListPageTab: {
    path: 'program-groups/:tabNameParam',
    googleAnalyticsTitle: 'easySet Program Groups',
    Component: ProgramGroupListPage,
    permission: Permission.EASYSET_PROGRAM_READ,
  },
  ProgramGroupDetailsPage: {
    path: 'program-groups/:machineTypeParam/:programGroupId/view',
    googleAnalyticsTitle: 'easySet Program Group Details',
    Component: ProgramGroupDetailsPage,
    permission: Permission.EASYSET_PROGRAM_READ,
  },
  ProgramGroupCreatePage: {
    path: 'program-groups/:machineTypeParam/create',
    googleAnalyticsTitle: 'easySet Program Group Create',
    Component: ProgramGroupCreatePage,
    permission: Permission.EASYSET_PROGRAM_WRITE,
  },
  ProgramGroupEditPage: {
    path: 'program-groups/:machineTypeParam/:programGroupId/edit',
    googleAnalyticsTitle: 'easySet Program Group Edit',
    Component: ProgramGroupEditPage,
    permission: Permission.EASYSET_PROGRAM_WRITE,
  },
  ProgramGroupCopyPage: {
    path: 'program-groups/:machineTypeParam/:programGroupId/copy',
    googleAnalyticsTitle: 'easySet Program Group Copy',
    Component: ProgramGroupCopyPage,
    permission: Permission.EASYSET_PROGRAM_WRITE,
  },

  ...APP_SHARED_ROUTES,
}
