import React, { ReactElement, useEffect } from 'react'
import { ThemeProvider, styled } from '@mui/material'
import { ReactComponent as LogoImage } from 'src/assets/logo-washmaster.svg'
import { WM_THEME } from 'src/theme/Theme'
import { Layout } from 'src/ui/layout/main-layout/Layout'

const WmLogo = styled(LogoImage)(({ theme }) => ({
  height: '31.06px',
  width: '180.8px',
  [theme.breakpoints.up('md')]: {
    width: '214px',
  },
}))

export const WmLayout = (): ReactElement => {
  useEffect(() => {
    document.title = 'washMaster'
  }, [])
  return (
    <ThemeProvider theme={WM_THEME}>
      <Layout appLogo={<WmLogo />} enableActiveOrganizationSelector organizationIsMandatory={false} />
    </ThemeProvider>
  )
}
