import React, { PropsWithChildren, ReactElement } from 'react'
import { DialogActions, DialogContent } from '@mui/material'
import Button from '@mui/material/Button'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'

interface ConfirmationModalDialogProps extends PropsWithChildren {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
  titleKey: string
  contentKey?: string
  confirmationKey?: string
  titleTranslated?: boolean
  confirmationTranslated?: boolean
}

export const ConfirmationModalDialog = ({
  open,
  onCancel,
  onConfirm,
  titleKey,
  titleTranslated,
  children,
  confirmationKey,
  confirmationTranslated,
}: ConfirmationModalDialogProps): ReactElement => {
  const translate = useTranslate()

  return (
    <ModalDialog
      open={open}
      onClose={onCancel}
      title={titleTranslated ? titleKey : translate(titleKey)}
      maxWidth="xs"
      withCloseIcon={true}
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" variant="text">
          {translate('button.cancel')}
        </Button>
        <Button autoFocus onClick={onConfirm} color="primary" variant="text">
          {confirmationTranslated ? confirmationKey : translate(confirmationKey ? confirmationKey : 'button.confirm')}
        </Button>
      </DialogActions>
    </ModalDialog>
  )
}
