import { Region } from 'src/service/backend/api/models'
import { WithName } from 'src/service/view-model/base/IdAndName'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'
import { TEMP_UNIT_CELSIUS, TEMP_UNIT_FAHRENHEIT, TempUnitObject } from 'src/user/TemperatureUnits'
import { WEIGHT_UNIT_KG, WEIGHT_UNIT_POUNDS, WeightUnitObject } from 'src/user/WeightUnits'

export interface RegionObject extends WithName {
  id: Region
  tempUnit: TempUnitObject
  weightUnit: WeightUnitObject
}

export const REGION_EU: RegionObject = {
  name: 'regionEurope',
  id: Region.EU,
  tempUnit: TEMP_UNIT_CELSIUS,
  weightUnit: WEIGHT_UNIT_KG,
}

export const REGION_US: RegionObject = {
  name: 'regionUsa',
  id: Region.US,
  tempUnit: TEMP_UNIT_FAHRENHEIT,
  weightUnit: WEIGHT_UNIT_POUNDS,
}

export const SUPPORTED_REGIONS: RegionObject[] = [REGION_EU, REGION_US]

export function getRegionObject(region: Region | undefined): RegionObject | undefined {
  return findReferenceObject(SUPPORTED_REGIONS, region, 'id')
}
