import React, { ReactElement, Suspense, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Box, Button, styled } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, MachineTag, MachinesApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { EMPTY_UUID } from 'src/ui-shared/constants/Constants'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'

type FacingMode = 'user' | 'environment'

const BoxContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(9),
  },
}))

const QrReaderParentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },
}))

const onResize = (qrReaderParent: HTMLElement, qrReader: HTMLElement) => {
  const parentWidth = qrReaderParent.clientWidth
  const parentHeight = qrReaderParent.clientHeight

  let qrReaderWidth = 100
  if (parentWidth > parentHeight) {
    const calculatedWidthPercentage = (parentHeight / parentWidth) * 100
    qrReaderWidth = Math.ceil(calculatedWidthPercentage)
  }

  const qrReaderWidthString = `${qrReaderWidth}%`
  qrReader.style.width = qrReaderWidthString
}

const AsyncQrReader = React.lazy(() => import('react-qr-reader').then(({ QrReader }) => ({ default: QrReader })))

export const MachineQrCodeScanner = (): ReactElement => {
  const navigate = useNavigate()
  const translate = useTranslate()
  const { machineId } = useRequiredParams(['machineId'])
  const { classes: sharedClasses } = useSharedStyles()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const machinesApi = new MachinesApi(httpConfiguration)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [facingMode, setFacingMode] = useState<FacingMode>('environment')
  const [updatingMachineQrCode, setUpdatingMachineQrCode] = useState<boolean>(false)

  useEffect(() => {
    const qrReaderParent = document.getElementById('qrReaderParent')
    const qrReader = document.getElementById('qrReader')
    if (qrReaderParent && qrReader) {
      window.addEventListener('resize', onResize.bind(this, qrReaderParent, qrReader))
    }
  }, [])

  const createMachineTag = (qrCode: string) => {
    const tag: MachineTag = {
      id: EMPTY_UUID,
      contentUrl: qrCode,
    }
    return tag
  }

  const updateMachineQrCode = async (qrCode: string) => {
    setUpdatingMachineQrCode(true)
    try {
      const machine = await machinesApi.machinesMachineIdGet(machineId, []).then((machine) => machine)
      if (machine.washmaster !== null && machine.washmaster !== undefined) {
        machine.washmaster.tag = createMachineTag(qrCode)
      }
      machinesApi
        .machinesMachineIdPut(machineId, [], machine)
        .then((_data) => {
          navigate(`/${appId}/machines/${machineId}/view/${MachineTab.OVERVIEW}`)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
          setUpdatingMachineQrCode(false)
        })
      setUpdatingMachineQrCode(false)
    } catch (err) {
      const errorMessage = errorMapper(err, translate)
      console.error(errorMessage, err)
      showSnackbar(errorMessage, 'error')
      setUpdatingMachineQrCode(false)
    }
  }

  const onScanHandle = (qrCode: string | null) => {
    if (qrCode && !updatingMachineQrCode) {
      updateMachineQrCode(qrCode)
    }
  }

  const scannerErrorHandle = (err: Error | undefined | null) => {
    if (err !== undefined && err !== null) {
      console.error(err.message, err)
      setErrorMessage(err.message)
    } else {
      console.error(err)
    }
  }

  const handleFacingModeChange = () => {
    setFacingMode(facingMode === 'user' ? 'environment' : 'user')
  }

  return (
    <BoxContainer mt={2}>
      <Box px={3} mb={2}>
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          className={sharedClasses.ButtonMargin}
          variant="contained"
          color="primary"
          onClick={handleFacingModeChange}
        >
          {facingMode === 'user' ? translate('frontCamera') : translate('backCamera')}
        </Button>
      </Box>

      <Box id="qrReaderParent" height="70vh">
        <Box display="flex" justifyContent="center">
          <LoadingIndicator loading={updatingMachineQrCode} />
          <QrReaderParentBox id="qrReader" width="45%">
            <Suspense fallback={<div style={{ textAlign: 'center' }}>{translate('autocompleteLoading')}</div>}>
              <AsyncQrReader
                scanDelay={800}
                constraints={{
                  facingMode,
                }}
                onResult={(result, error) => {
                  if (result === null && error) {
                    scannerErrorHandle(error)
                  } else if (result) {
                    onScanHandle(result.getText())
                  }
                }}
              />
            </Suspense>
          </QrReaderParentBox>
        </Box>
      </Box>
    </BoxContainer>
  )
}
