/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ManualPrepaidAdjustment,
  ManualPrepaidAdjustmentTransaction,
  PrepaidBalance,
  TopupPrepaidByOperator,
  TopupPrepaidByOperatorTransaction,
} from '../models';
import {
    ManualPrepaidAdjustmentFromJSON,
    ManualPrepaidAdjustmentToJSON,
    ManualPrepaidAdjustmentTransactionFromJSON,
    ManualPrepaidAdjustmentTransactionToJSON,
    PrepaidBalanceFromJSON,
    PrepaidBalanceToJSON,
    TopupPrepaidByOperatorFromJSON,
    TopupPrepaidByOperatorToJSON,
    TopupPrepaidByOperatorTransactionFromJSON,
    TopupPrepaidByOperatorTransactionToJSON,
} from '../models';

export interface LaundryusersLaundryUserIdPrepaidAdjustmentPostRequest {
    laundryUserId: string;
    manualPrepaidAdjustment?: ManualPrepaidAdjustment;
}

export interface LaundryusersLaundryUserIdPrepaidBalanceGetRequest {
    laundryUserId: string;
}

export interface LaundryusersLaundryUserIdPrepaidTopUpPostRequest {
    laundryUserId: string;
    topupPrepaidByOperator?: TopupPrepaidByOperator;
}

/**
 * 
 */
export class LaundryUsersPrepaidApi extends runtime.BaseAPI {

    /**
     * Manual Adjust the prepaid account of the laundry user
     */
    async laundryusersLaundryUserIdPrepaidAdjustmentPostRaw(requestParameters: LaundryusersLaundryUserIdPrepaidAdjustmentPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManualPrepaidAdjustmentTransaction>> {
        if (requestParameters.laundryUserId === null || requestParameters.laundryUserId === undefined) {
            throw new runtime.RequiredError('laundryUserId','Required parameter requestParameters.laundryUserId was null or undefined when calling laundryusersLaundryUserIdPrepaidAdjustmentPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundryusers/{laundryUserId}/prepaid/adjustment`.replace(`{${"laundryUserId"}}`, encodeURIComponent(String(requestParameters.laundryUserId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManualPrepaidAdjustmentToJSON(requestParameters.manualPrepaidAdjustment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualPrepaidAdjustmentTransactionFromJSON(jsonValue));
    }

    /**
     * Manual Adjust the prepaid account of the laundry user
     */
    async laundryusersLaundryUserIdPrepaidAdjustmentPost(laundryUserId: string, manualPrepaidAdjustment?: ManualPrepaidAdjustment, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManualPrepaidAdjustmentTransaction> {
        const response = await this.laundryusersLaundryUserIdPrepaidAdjustmentPostRaw({ laundryUserId: laundryUserId, manualPrepaidAdjustment: manualPrepaidAdjustment }, initOverrides);
        return await response.value();
    }

    /**
     * Get Prepaid Balance for an Laundry User
     */
    async laundryusersLaundryUserIdPrepaidBalanceGetRaw(requestParameters: LaundryusersLaundryUserIdPrepaidBalanceGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrepaidBalance>> {
        if (requestParameters.laundryUserId === null || requestParameters.laundryUserId === undefined) {
            throw new runtime.RequiredError('laundryUserId','Required parameter requestParameters.laundryUserId was null or undefined when calling laundryusersLaundryUserIdPrepaidBalanceGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundryusers/{laundryUserId}/prepaid/balance`.replace(`{${"laundryUserId"}}`, encodeURIComponent(String(requestParameters.laundryUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrepaidBalanceFromJSON(jsonValue));
    }

    /**
     * Get Prepaid Balance for an Laundry User
     */
    async laundryusersLaundryUserIdPrepaidBalanceGet(laundryUserId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrepaidBalance> {
        const response = await this.laundryusersLaundryUserIdPrepaidBalanceGetRaw({ laundryUserId: laundryUserId }, initOverrides);
        return await response.value();
    }

    /**
     * Top Up the prepaid account of the laundry user
     */
    async laundryusersLaundryUserIdPrepaidTopUpPostRaw(requestParameters: LaundryusersLaundryUserIdPrepaidTopUpPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopupPrepaidByOperatorTransaction>> {
        if (requestParameters.laundryUserId === null || requestParameters.laundryUserId === undefined) {
            throw new runtime.RequiredError('laundryUserId','Required parameter requestParameters.laundryUserId was null or undefined when calling laundryusersLaundryUserIdPrepaidTopUpPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundryusers/{laundryUserId}/prepaid/top-up`.replace(`{${"laundryUserId"}}`, encodeURIComponent(String(requestParameters.laundryUserId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TopupPrepaidByOperatorToJSON(requestParameters.topupPrepaidByOperator),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopupPrepaidByOperatorTransactionFromJSON(jsonValue));
    }

    /**
     * Top Up the prepaid account of the laundry user
     */
    async laundryusersLaundryUserIdPrepaidTopUpPost(laundryUserId: string, topupPrepaidByOperator?: TopupPrepaidByOperator, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopupPrepaidByOperatorTransaction> {
        const response = await this.laundryusersLaundryUserIdPrepaidTopUpPostRaw({ laundryUserId: laundryUserId, topupPrepaidByOperator: topupPrepaidByOperator }, initOverrides);
        return await response.value();
    }

}
