/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface ManualPrepaidAdjustment
 */
export interface ManualPrepaidAdjustment {
    /**
     * 
     * @type {number}
     * @memberof ManualPrepaidAdjustment
     */
    amount: number;
    /**
     * 
     * @type {Currency}
     * @memberof ManualPrepaidAdjustment
     */
    currency: Currency;
    /**
     * 
     * @type {string}
     * @memberof ManualPrepaidAdjustment
     */
    reason: string;
}

/**
 * Check if a given object implements the ManualPrepaidAdjustment interface.
 */
export function instanceOfManualPrepaidAdjustment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function ManualPrepaidAdjustmentFromJSON(json: any): ManualPrepaidAdjustment {
    return ManualPrepaidAdjustmentFromJSONTyped(json, false);
}

export function ManualPrepaidAdjustmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualPrepaidAdjustment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'currency': CurrencyFromJSON(json['currency']),
        'reason': json['reason'],
    };
}

export function ManualPrepaidAdjustmentToJSON(value?: ManualPrepaidAdjustment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'currency': CurrencyToJSON(value.currency),
        'reason': value.reason,
    };
}

