/**
 * Given 2 objects, old and new state, it returns a new object where all the keys that are same between the old and the new object are not present and only the keys with different value are present.
 * The values are taken from the new state object.
 * The comparison is done only on first level (shallow compare)
 * @param oldO object with keys and values representing the old state
 * @param newO object with keys and values represneting the new state
 * @returns
 */
export const diffStateObjects = (oldO: Record<string, any>, newO: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {}
  for (const key in oldO) {
    const oldValue = oldO[key]
    const newValue = newO[key]

    if (oldValue != newValue) {
      result[key] = newValue
    }
  }

  for (const key in newO) {
    const oldValue = oldO[key]
    const newValue = newO[key]

    if (newValue != oldValue) {
      result[key] = newValue
    }
  }
  return result
}
