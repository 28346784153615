import React, { ReactElement, useMemo } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { Box, FormHelperText } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LocaleObject } from 'src/service/view-model/base/localization/Locales'
import { LocaleAutoComplete, LocaleAutoCompleteProps } from 'src/ui-shared/form/control/LocaleAutoComplete'

interface LocaleAutoCompleteField extends LocaleAutoCompleteProps {
  validate?: any
}

type ExposeLocaleAutoCompleteFieldProps = Omit<LocaleAutoCompleteField, 'onChange' | 'value'>

type AutoCompleteFieldValueType = LocaleObject | undefined

const CustomLocaleAutoComplete = (props: FieldRenderProps<AutoCompleteFieldValueType>): ReactElement => {
  const {
    input: { name, value, onChange: onChangeFinalForm },
    meta,
    disabled,
    ...rest
  } = props

  const { children, render, ...autoCompleteProps } = rest

  const { touched, error: errorObject } = meta

  const translate = useTranslate()

  const error = errorObject && translate(errorObject.errorKey, errorObject.params)

  const invalid = Boolean(touched && error)

  const onChangeMemo = useMemo<(optionValue: AutoCompleteFieldValueType) => void>(
    () =>
      (optionValue: AutoCompleteFieldValueType): void => {
        onChangeFinalForm(optionValue)
      },
    [onChangeFinalForm],
  )

  const finalAutoCompleteProps = {
    ...autoCompleteProps,
    onChange: onChangeMemo,
    disabled,
    name: name,
    error: invalid,
    value,
  } as LocaleAutoCompleteProps

  return (
    <Box>
      <LocaleAutoComplete {...finalAutoCompleteProps} />
      {invalid && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}

export const LocaleAutoCompleteValidate = (props: ExposeLocaleAutoCompleteFieldProps): ReactElement => (
  <Field component={CustomLocaleAutoComplete} {...props} />
)
