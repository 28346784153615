import { TranslateFunction } from 'src/i18n/useMessageSource'
import { Money, PaymentMethod } from 'src/service/backend/api'
import { formatAmountForLocale } from 'src/service/utils/NumberFormatUtils'
import { IdAndName } from 'src/service/view-model/base/IdAndName'
import { getReferenceName } from 'src/service/view-model/base/Reference.utils'
import { SupportedLocale } from 'src/service/view-model/base/localization/SupportedLocale'

export type PaymentMethodObject = IdAndName

export const PAYMENT_METHODS: PaymentMethodObject[] = [
  {
    id: PaymentMethod.DIRECTPAY,
    name: 'directPay',
  },
  {
    id: PaymentMethod.PREPAID,
    name: 'prepaid',
  },
  {
    id: PaymentMethod.REFUND,
    name: 'transactionRefund',
  },
  {
    id: PaymentMethod.FREE,
    name: 'free',
  },
]

export const getPaymentMethodName = (paymentMethod: string | undefined, translate: TranslateFunction): string => {
  return getReferenceName(PAYMENT_METHODS, translate, paymentMethod)
}

export const getPricingTitle = (
  translate: TranslateFunction,
  pricingTranslationKey: string,
  regionLocale: SupportedLocale,
  money?: Money | null,
): string => {
  let pricingTitle: string | undefined = undefined

  if (money) {
    const amount = money.amount
    const currency = money.currency
    if (amount > 0) {
      pricingTitle = translate(pricingTranslationKey, formatAmountForLocale(amount, regionLocale, currency))
    }
  }

  // if amount is 0 or Money is not passed, then display 'Free' title
  if (pricingTitle === undefined) {
    pricingTitle = translate('free')
  }

  return pricingTitle
}
