import React, { FC, ReactElement, useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import { SettingField, SettingFieldType } from 'src/service/backend/api'
import {
  SettingWithExpandableGroups,
  transformProgramSettingsWithExpandableGroups,
} from 'src/service/view-model/setting-field/SettingExpandableGroupsUtils'
import { SettingFieldWithIndex } from 'src/service/view-model/setting-field/SettingFieldUtils'
import { filterHiddenSettingFieldInputs } from 'src/service/view-model/setting-field/SettingVisibilityUtils'
import { FormAccordion, FormAccordionDetails, FormAccordionSummary } from 'src/ui-shared/base/accordion/FormAccordion'

export interface DynamicSettingFieldsFormProps {
  renderFormInput: (field: SettingFieldWithIndex) => ReactElement
  fields: SettingField[]
  handleOpeningExpandableGroup: (field: SettingWithExpandableGroups) => void
  checkIfExpandedGroupFieldsHaveError?: (expandedGroupFields?: SettingWithExpandableGroups[]) => boolean
}

export const DynamicSettingFieldsForm: FC<DynamicSettingFieldsFormProps> = ({
  renderFormInput,
  fields: fields,
  handleOpeningExpandableGroup,
  checkIfExpandedGroupFieldsHaveError,
}): ReactElement => {
  // state
  const fieldsWithExpandableGroups = useMemo(() => {
    const visibleSettingInputs = filterHiddenSettingFieldInputs(fields, true)
    const withExpandableGroups = transformProgramSettingsWithExpandableGroups(visibleSettingInputs)
    return withExpandableGroups
  }, [fields])

  return (
    <>
      {fieldsWithExpandableGroups.map((field) => {
        if (field.fieldType === SettingFieldType.GROUP && field.groupField?.expandable && !field.groupField.hidden) {
          return (
            <Grid key={field.settingId} item xs={12} mt={1}>
              <FormAccordion expanded={field.groupField.expanded}>
                <FormAccordionSummary onClick={() => handleOpeningExpandableGroup(field)}>
                  <Typography
                    color={
                      checkIfExpandedGroupFieldsHaveError
                        ? checkIfExpandedGroupFieldsHaveError(field.inputsForGroup)
                          ? 'error'
                          : 'default'
                        : 'default'
                    }
                    variant="subtitle1"
                  >
                    {field.label}
                  </Typography>
                </FormAccordionSummary>
              </FormAccordion>
              <FormAccordionDetails>
                <Grid container spacing={2}>
                  {field.groupField.expanded
                    ? field.inputsForGroup?.map((subField) => {
                        return renderFormInput(subField)
                      })
                    : null}
                </Grid>
              </FormAccordionDetails>
            </Grid>
          )
        } else {
          return renderFormInput(field)
        }
      })}
    </>
  )
}
