import React, { ReactElement } from 'react'
import { Box, Button, Typography, styled } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { handleLogout } from 'src/user/Logout'

const TextContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: 'rgb(250, 250, 250)',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.25),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
  },
}))

export const NoAccessToOrganizationText = (): ReactElement => {
  const translate = useTranslate()

  const buildRequestAccessLink = () => {
    const mailTo = 'smart@schulthess.ch'
    const subject = 'Smart Schulthess'
    const body = 'Hello, I would like access.'

    const link = `mailto:${mailTo}?subject=${subject}&body=${body}`
    return link
  }

  return (
    <TextContainer alignSelf="center">
      <Typography variant="h4">{translate('landingPage.noAccessOrganization')}</Typography>
      <Typography variant="h5">
        {translate('landingPage.becomingCustomer')}{' '}
        <a href={buildRequestAccessLink()}>{translate('landingPage.requestAccess')}</a>
      </Typography>

      <Button variant="contained" size="large" onClick={handleLogout} sx={{ marginTop: 4 }}>
        {translate('logout')}
      </Button>
    </TextContainer>
  )
}
