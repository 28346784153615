/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettingSelectFieldOption
 */
export interface SettingSelectFieldOption {
    /**
     * 
     * @type {string}
     * @memberof SettingSelectFieldOption
     */
    data: string;
    /**
     * 
     * @type {string}
     * @memberof SettingSelectFieldOption
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof SettingSelectFieldOption
     */
    showGroup?: string;
    /**
     * List of groups which can be hidden
     * @type {Array<string>}
     * @memberof SettingSelectFieldOption
     */
    hideGroups?: Array<string>;
}

/**
 * Check if a given object implements the SettingSelectFieldOption interface.
 */
export function instanceOfSettingSelectFieldOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "label" in value;

    return isInstance;
}

export function SettingSelectFieldOptionFromJSON(json: any): SettingSelectFieldOption {
    return SettingSelectFieldOptionFromJSONTyped(json, false);
}

export function SettingSelectFieldOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingSelectFieldOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': json['data'],
        'label': json['label'],
        'showGroup': !exists(json, 'showGroup') ? undefined : json['showGroup'],
        'hideGroups': !exists(json, 'hideGroups') ? undefined : json['hideGroups'],
    };
}

export function SettingSelectFieldOptionToJSON(value?: SettingSelectFieldOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'label': value.label,
        'showGroup': value.showGroup,
        'hideGroups': value.hideGroups,
    };
}

