import React, { ReactElement, useEffect, useState } from 'react'
import { CircularProgress, InputLabelProps } from '@mui/material'
import Autocomplete, { AutocompleteInputChangeReason, AutocompleteProps } from '@mui/material/Autocomplete'
import { useTranslate } from 'src/i18n/useMessageSource'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { useFirstRender } from 'src/ui-shared/base/hooks/useFirstRender'

type PropsToExclude = 'name' | 'label' | 'required' | 'error' | 'disabled' | 'options' | 'renderInput' | 'onChange'

type AutoCompletePropsClean = Omit<AutocompleteProps<any, false, false, false>, PropsToExclude>

interface InputLabelPropsExtended extends InputLabelProps {
  defaultShrinkBehaviour?: boolean
}

export interface AsyncAutocompleteProps<T> extends AutoCompletePropsClean {
  name: string
  label: string
  loadOptionsFunction: (search: string) => Promise<T[]>
  onChange: (option: T | null) => void
  labelFieldName: keyof T
  getOptionLabel?: (option: T) => string
  optionKey?: keyof T
  required?: boolean
  disabled?: boolean
  error?: boolean
  delay?: number
  minCharsForSearch?: number
  preselectOption?: boolean
  loadAll?: boolean
  textFieldClassName?: string
  textFieldVariant?: 'standard' | 'filled' | 'outlined'
  textFieldInputLabelProps?: InputLabelPropsExtended
}

const DEFAULT_OPTION_KEY = 'id'

export const AsyncAutoComplete = <T,>({
  name,
  loadOptionsFunction,
  labelFieldName,
  getOptionLabel,
  label,
  error,
  onChange,
  optionKey,
  delay = 300,
  minCharsForSearch = 1,
  disabled = false,
  loadAll = true,
  preselectOption = true,
  value,
  textFieldClassName,
  textFieldVariant,
  textFieldInputLabelProps,
  ...restProps
}: AsyncAutocompleteProps<T>): ReactElement => {
  const [options, setOptions] = useState<T[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [loadState, setLoadState] = useState<boolean>(false)
  const [timeoutState, setTimeoutState] = useState<any>(null)

  const translate = useTranslate()
  const isFirstRender = useFirstRender()

  const key = optionKey || (DEFAULT_OPTION_KEY as keyof T)

  // when search text changes
  useEffect(() => {
    let active = true

    if (shouldLoadOptions()) {
      setLoadState(true)
      loadOptionsFunction(searchText)
        .then((options) => {
          if (active) {
            onLoadOptionsSuccess(options)
          }
        })
        .catch((err) => {
          const errorMessage = err.message
          console.error(errorMessage, err)
          setLoadState(false)
        })
    }

    return () => {
      active = false
    }
  }, [searchText])

  const shouldLoadOptions = () => {
    if (isFirstRender) {
      // on first render load all options if enabled
      if (loadAll) {
        return true
      }

      // there is preselected text even on first render
      if (searchText !== '') {
        return true
      }
    }

    // if the component is disabled there is no point in loading options after first render
    if (disabled) {
      return false
    }

    // normal state, user typing
    if (isMinTextLengthExceeded()) {
      return true
    }

    return false
  }

  const isMinTextLengthExceeded = () => {
    return searchText.length >= minCharsForSearch
  }

  const onLoadOptionsSuccess = (options: T[]) => {
    setLoadState(false)
    setOptions(options)

    if (isFirstRender) {
      maybeSelectOnlyOption(options)
    }
  }

  const maybeSelectOnlyOption = (options: T[]) => {
    if (preselectOption && options.length === 1) {
      handleOnChange(null, options[0])
    }
  }

  const handleSearch = (
    event: React.ChangeEvent<any> | undefined,
    newValue: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    // don't search the backend on option selection
    if (reason === 'input' || reason === 'clear') {
      let text = newValue

      // if the event is null, this means that the component is loading the initial value
      // no keystroke by the user has been made yet
      // in this case load the complete list of options
      if (!event) {
        return
      }

      text = text.toLowerCase().trim()

      if (timeoutState) {
        clearTimeout(timeoutState)
      }

      setTimeoutState(
        setTimeout(() => {
          setSearchText(text)
        }, delay),
      )
    }
  }

  const handleOnChange = (_event: React.SyntheticEvent | null, object: T | null) => {
    onChange(object)
  }

  const getOptionSelected = (option: T, value: T) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (option && option[key] && value && value[key]) {
      return option[key] === value[key]
    }
    return option === value
  }

  const getNoOptionsText = () => {
    let noOptionsText = translate('autocompleteNoOptions')
    if (!loadAll && searchText.length < minCharsForSearch) {
      noOptionsText = translate('autocompleteMinCharacters', minCharsForSearch)
    }
    return noOptionsText
  }

  const renderOptionLabel = (option: T): string => {
    if (getOptionLabel) {
      return getOptionLabel(option)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return option[labelFieldName] ? option[labelFieldName] + '' : ''
    }
  }

  return (
    <>
      <Autocomplete
        id="asynchronous-autocomplete"
        openOnFocus={true}
        onChange={handleOnChange}
        onInputChange={handleSearch}
        isOptionEqualToValue={getOptionSelected}
        getOptionLabel={renderOptionLabel}
        noOptionsText={getNoOptionsText()}
        loadingText={translate('autocompleteLoading')}
        disabled={disabled}
        loading={loadState}
        multiple={false}
        freeSolo={false}
        disableClearable={false}
        value={value}
        {...restProps}
        options={options}
        renderInput={(params) => (
          <TextFieldDefault
            className={textFieldClassName}
            variant={textFieldVariant}
            {...params}
            InputLabelProps={textFieldInputLabelProps}
            label={label}
            name={name}
            key={'act'}
            disabled={disabled}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadState ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {renderOptionLabel(option)}
          </li>
        )}
      />
    </>
  )
}
