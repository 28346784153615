import React, { FC, ReactElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, FilledInputProps, InputAdornment, Link, SxProps } from '@mui/material'
import { Theme } from '@mui/system'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

interface Props {
  value?: string | number | null
  label?: string
  name?: string
  placeholder?: string
  multiline?: boolean
  rows?: number
  link?: string
  inputProps?: Partial<FilledInputProps>
  sx?: SxProps<Theme> | undefined
}

const renderLink = (value?: string | number | null, link?: string): ReactElement | undefined => {
  if (value && link) {
    return (
      <InputAdornment position="start">
        <Box mt={2}>
          <Link color="primary" component={RouterLink} to={link} state={{ browserHistoryBack: true }} underline="hover">
            {value}
          </Link>
        </Box>
      </InputAdornment>
    )
  }
}

export const DetailsTextField: FC<Props> = ({
  value,
  label,
  name,
  placeholder,
  multiline,
  rows,
  link,
  inputProps,
  sx,
}): ReactElement => {
  return (
    <TextFieldDefault
      InputProps={{
        readOnly: true,
        disableUnderline: true,
        startAdornment: renderLink(value, link),
        ...inputProps,
      }}
      name={name}
      value={!link ? value : null}
      disabled={true}
      multiline={multiline}
      rows={rows}
      label={label}
      placeholder={placeholder}
      fullWidth
      sx={sx}
    />
  )
}
