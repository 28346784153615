/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Member } from './Member';
import {
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * 
 * @export
 * @interface PageableMembers
 */
export interface PageableMembers {
    /**
     * 
     * @type {Array<Member>}
     * @memberof PageableMembers
     */
    result: Array<Member>;
    /**
     * 
     * @type {Page}
     * @memberof PageableMembers
     */
    page: Page;
}

/**
 * Check if a given object implements the PageableMembers interface.
 */
export function instanceOfPageableMembers(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageableMembersFromJSON(json: any): PageableMembers {
    return PageableMembersFromJSONTyped(json, false);
}

export function PageableMembersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableMembers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(MemberFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageableMembersToJSON(value?: PageableMembers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(MemberToJSON)),
        'page': PageToJSON(value.page),
    };
}

