/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PriceCondition {
    REGULAR = 'REGULAR',
    HAPPY = 'HAPPY'
}


export function PriceConditionFromJSON(json: any): PriceCondition {
    return PriceConditionFromJSONTyped(json, false);
}

export function PriceConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceCondition {
    return json as PriceCondition;
}

export function PriceConditionToJSON(value?: PriceCondition | null): any {
    return value as any;
}

