import { SettingField, SettingGroupField } from 'src/service/backend/api'
import { isGroupStart } from 'src/service/view-model/setting-field/SettingFieldUtils'

export interface SettingGroupFieldWithDefault extends SettingGroupField {
  defaultHidden: boolean
}

/**
 * Adds the default value to the group fields given settings.
 * It mutates the current given settings.
 * @param fields the given setting fields
 * @returns {void}
 */
export const modifyGroupFieldsAddDefaultHiddenValue = (fields: SettingField[]): void => {
  for (const settingField of fields) {
    if (isGroupStart(settingField) && settingField.groupField) {
      const groupFieldModified: SettingGroupFieldWithDefault = {
        ...settingField.groupField,
        defaultHidden: settingField.groupField.hidden ?? false,
      }

      settingField.groupField = groupFieldModified
    }
  }
}
