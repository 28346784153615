import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { OrganizationTable } from 'src/ui/page/common/organization/list/OrganizationTable'

export const OrganizationListPage = (): ReactElement => {
  const translate = useTranslate()

  const navigate = useNavigate()
  const appId = useAppId()

  const handleRowClick = (organizationId?: string) => {
    navigate(`/${appId}/organizations/${organizationId}/view`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  return (
    <ScreenLayout title={translate('organizations')}>
      <OrganizationTable navigateToItem={handleRowClick}></OrganizationTable>
    </ScreenLayout>
  )
}
