import React from 'react'
import { FormatListNumberedRtlOutlined, HelpOutline, Inventory2Outlined } from '@mui/icons-material'
import { ReactComponent as EasySetIcon } from 'src/assets/logo-easyset-icon-dark.svg'
import { SlideItem } from 'src/ui/layout/onboarding-slideshow/OnboardingSlideshow'

export const SLIDES_ES: SlideItem[] = [
  {
    backgroundImage: 'slide1-background.png',
    title: 'onboardingEsTitle1',
    body: 'onboardingEsContent1',
    slideImage: <EasySetIcon />,
  },
  {
    backgroundImage: 'slide2-background.png',
    title: 'onboardingEsTitle2',
    body: 'onboardingEsContent2',
    slideImage: <FormatListNumberedRtlOutlined />,
  },
  {
    backgroundImage: 'slide3-background.png',
    title: 'onboardingEsTitle3',
    body: 'onboardingEsContent3',
    slideImage: <Inventory2Outlined />,
  },
  {
    backgroundImage: 'slide4-background.png',
    title: 'helpCenter',
    body: 'onboardingBodySlide6Wm',
    slideImage: <HelpOutline color={'primary'} />,
  },
]
