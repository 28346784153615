/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrganizationType {
    SCHULTHESS = 'SCHULTHESS',
    PARTNER = 'PARTNER',
    COMPANY = 'COMPANY'
}


export function OrganizationTypeFromJSON(json: any): OrganizationType {
    return OrganizationTypeFromJSONTyped(json, false);
}

export function OrganizationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationType {
    return json as OrganizationType;
}

export function OrganizationTypeToJSON(value?: OrganizationType | null): any {
    return value as any;
}

