/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionStatus {
    INITIALIZED = 'INITIALIZED',
    AUTHORIZED = 'AUTHORIZED',
    AUTHORIZE_FAILED = 'AUTHORIZE_FAILED',
    SETTLED = 'SETTLED',
    UNSETTLED = 'UNSETTLED',
    SETTLE_FAILED = 'SETTLE_FAILED',
    REFUNDED = 'REFUNDED',
    REFUND_FAILED = 'REFUND_FAILED',
    CANCELED = 'CANCELED'
}


export function TransactionStatusFromJSON(json: any): TransactionStatus {
    return TransactionStatusFromJSONTyped(json, false);
}

export function TransactionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionStatus {
    return json as TransactionStatus;
}

export function TransactionStatusToJSON(value?: TransactionStatus | null): any {
    return value as any;
}

