/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettingBitFlagsField
 */
export interface SettingBitFlagsField {
    /**
     * 
     * @type {number}
     * @memberof SettingBitFlagsField
     */
    readonly activeData?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingBitFlagsField
     */
    data?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingBitFlagsField
     */
    readonly factoryDefaultData?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingBitFlagsField
     */
    flags?: Array<string>;
}

/**
 * Check if a given object implements the SettingBitFlagsField interface.
 */
export function instanceOfSettingBitFlagsField(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SettingBitFlagsFieldFromJSON(json: any): SettingBitFlagsField {
    return SettingBitFlagsFieldFromJSONTyped(json, false);
}

export function SettingBitFlagsFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingBitFlagsField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeData': !exists(json, 'activeData') ? undefined : json['activeData'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'factoryDefaultData': !exists(json, 'factoryDefaultData') ? undefined : json['factoryDefaultData'],
        'flags': !exists(json, 'flags') ? undefined : json['flags'],
    };
}

export function SettingBitFlagsFieldToJSON(value?: SettingBitFlagsField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'flags': value.flags,
    };
}

