import React, { ReactElement } from 'react'
import { TextFieldProps } from '@mui/material'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers'
import { getDateFormatForLocale } from 'src/service/utils/DateFormatUtils'
import { SupportedLocale } from 'src/service/view-model/base/localization/SupportedLocale'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

interface CustomProps {
  textField?: React.ElementType<TextFieldProps>
  textFieldClassName?: string
  error?: boolean
  locale: SupportedLocale
}

type DatePickerPropsClean = Omit<DatePickerProps<any>, 'renderInput'>
type DatePickerPropsExtended = DatePickerPropsClean & CustomProps

export const DatePickerDefault = (
  props: DatePickerPropsExtended & React.RefAttributes<HTMLDivElement>,
): ReactElement => {
  const { textField, textFieldClassName, locale, error, ...rest } = props

  return (
    <DatePicker
      key={rest.value}
      format={getDateFormatForLocale(locale)}
      slots={{
        textField: textField ? textField : TextFieldDefault,
      }}
      slotProps={{
        textField: {
          error: error,
        },
      }}
      className={textFieldClassName}
      {...rest}
    />
  )
}
