/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FhMonitoringEntry } from './FhMonitoringEntry';
import {
    FhMonitoringEntryFromJSON,
    FhMonitoringEntryFromJSONTyped,
    FhMonitoringEntryToJSON,
} from './FhMonitoringEntry';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * 
 * @export
 * @interface PageableFhMonitoringEntries
 */
export interface PageableFhMonitoringEntries {
    /**
     * 
     * @type {Array<FhMonitoringEntry>}
     * @memberof PageableFhMonitoringEntries
     */
    result: Array<FhMonitoringEntry>;
    /**
     * 
     * @type {Page}
     * @memberof PageableFhMonitoringEntries
     */
    page: Page;
}

/**
 * Check if a given object implements the PageableFhMonitoringEntries interface.
 */
export function instanceOfPageableFhMonitoringEntries(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageableFhMonitoringEntriesFromJSON(json: any): PageableFhMonitoringEntries {
    return PageableFhMonitoringEntriesFromJSONTyped(json, false);
}

export function PageableFhMonitoringEntriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableFhMonitoringEntries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(FhMonitoringEntryFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageableFhMonitoringEntriesToJSON(value?: PageableFhMonitoringEntries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(FhMonitoringEntryToJSON)),
        'page': PageToJSON(value.page),
    };
}

