import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Box, Divider, Grid, Paper } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, FHMonitoringsApi } from 'src/service/backend/api'
import { FhMonitoringEntry } from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocale } from 'src/service/utils/DateFormatUtils'
import { getErrorSeverityName } from 'src/service/view-model/error-log/ErrorLogViewModel'
import { getMachineNameAndSerialNumber } from 'src/service/view-model/machine/Machines'
import { isOrganizationTypeServicePartner } from 'src/service/view-model/organization/Organizations'
import { getWashingStepName } from 'src/service/view-model/washing-step/WashingStep'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ErrorSeverityIcon } from 'src/ui-shared/error-severity/ErrorSeverityIcon'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryGroupTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupTab'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'
import { useUserRegionLocale } from 'src/user/UserContext'

export const ErrorLogDetailsPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()

  const navigate = useNavigate()
  const location = useLocation()
  const appId = useAppId()
  const regionLocale = useUserRegionLocale()
  const { messageId, timestamp } = useRequiredParams(['messageId', 'timestamp'])

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack

  const httpConfiguration: Configuration = useContext(HttpContext)
  const fhMonitoringApi = new FHMonitoringsApi(httpConfiguration)

  const [errorLog, setErrorLog] = useState<FhMonitoringEntry | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  // load data
  useEffect(() => {
    setLoading(true)

    fhMonitoringApi
      .fhMonitoringsMessageIdTimestampGet(messageId, Number(timestamp))
      .then((data) => {
        setErrorLog(data)
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLoading(false)
      })
  }, [])

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/error-log`)
  }

  // render
  const renderTitle = (): string => {
    let title = ''
    if (errorLog) {
      title = translate('errorMessageDetails', errorLog.errorCode || '', errorLog.errorSummary || '')
    }
    return title
  }

  return (
    <ScreenLayout title={renderTitle()} onBack={navigateBack} actions={<></>} actionsWidth={50}>
      <Paper elevation={0}>
        <LoadingIndicator loading={loading} />
        <Divider />
        {errorMessage && <ErrorMessage message={errorMessage} />}

        {errorLog && (
          <Box pt={2}>
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                <DetailsTextField value={errorLog.errorCode} label={translate('error.errorCode')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                <DetailsTextField
                  value={getErrorSeverityName(errorLog.errorSeverity, translate)}
                  label={translate('error.severity')}
                  inputProps={{
                    startAdornment: (
                      <Box mt={3} mr={1}>
                        <ErrorSeverityIcon errorSeverity={errorLog.errorSeverity} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                <DetailsTextField
                  value={formatDateTimeForLocale(errorLog.errorTime, regionLocale)}
                  label={translate('error.errorTime')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                <DetailsTextField value={getWashingStepName(errorLog.step, translate)} label={translate('step')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                <DetailsTextField multiline={true} value={errorLog.errorSummary} label={translate('error.summary')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                <DetailsTextField
                  multiline={true}
                  value={errorLog.errorDescription}
                  label={translate('error.description')}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                <DetailsTextField multiline={true} value={errorLog.solution} label={translate('error.solution')} />
              </Grid>
            </Grid>
            <Divider className={sharedClasses.Divider} />
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('machineName') + ' / ' + translate('serialNumber')}
                  value={getMachineNameAndSerialNumber(errorLog.machine)}
                  link={`/${appId}/machines/${errorLog.machine.id}/view/${MachineTab.OVERVIEW}`}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('organization')}
                  value={errorLog.organization.name}
                  link={
                    isOrganizationTypeServicePartner(errorLog.organization.type)
                      ? `/${appId}/service-partners/${errorLog.organization.id}/view`
                      : `/${appId}/organizations/${errorLog.organization.id}/view`
                  }
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('laundryGroupName')}
                  value={errorLog.laundryGroup.name}
                  link={`/${appId}/laundry-groups/${errorLog.laundryGroup.id}/view/${LaundryGroupTab.OVERVIEW}`}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  label={translate('laundry.name')}
                  value={errorLog.laundry.name}
                  link={`/${appId}/laundries/${errorLog.laundry.id}/view`}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </ScreenLayout>
  )
}
