import React, { FC, PropsWithChildren, ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ArrowBack } from '@mui/icons-material'

const HEIGHT = '48px'

const ScreenHeader = styled(Box)(({ theme }) => ({
  height: HEIGHT,
  zIndex: 500,
  width: '100%',
  paddingLeft: theme.spacing(3),
  paddingTop: theme.spacing(2.5),
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}))

const ScreenBody = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}))

const TypographyStyled = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  wordWrap: 'break-word',
  whiteSpace: 'normal',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
}))

const Icon = styled(Box)(({ theme }) => ({
  width: '30px',
  display: 'flex',
  marginRight: theme.spacing(2),
  alignItems: 'center',
}))

interface ScreenLayoutProps extends PropsWithChildren {
  onBack?: () => void
  title?: string
  actions?: ReactElement
  actionsWidth?: number
}

const ArrowBackIconStyled = styled(ArrowBack)({
  cursor: 'pointer',
})

export const ScreenLayout: FC<ScreenLayoutProps> = ({ children, onBack, title, actions, actionsWidth }) => {
  // determine width for the action buttons container
  let actionsContainerWidth = undefined
  // if there are no actions, actions container width should be 0
  if (!actions) {
    actionsContainerWidth = 0
  }
  const header = (
    <ScreenHeader>
      {onBack ? <Icon>{<ArrowBackIconStyled onClick={onBack} />}</Icon> : null}
      <TypographyStyled variant="h3">{title}</TypographyStyled>
      <Box sx={{ mr: { xs: 0, sm: 2 } }}>
        <Box
          display="flex"
          justifyContent="end"
          width={actionsContainerWidth ? actionsContainerWidth + 'px' : undefined}
        >
          {actions}
        </Box>
      </Box>
    </ScreenHeader>
  )

  const headerBlock = title ? header : <></>

  return (
    <>
      {headerBlock}
      <ScreenBody>{children}</ScreenBody>
    </>
  )
}
