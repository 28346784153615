import React, {
  FC,
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Button } from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached'
import packageJson from '../../../../package.json'
import { isDevMode } from 'src/env/EnvironmentService'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Snackbar } from 'src/ui-shared/base/snackbar/Snackbar'

interface VersionCheckContextValue {
  newVersion?: string
}

const defaultVersionCheckContextValue: VersionCheckContextValue = {
  newVersion: undefined,
}

export const VersionCheckContext = createContext<VersionCheckContextValue>(defaultVersionCheckContextValue)

export const useVersionCheck = (): VersionCheckContextValue => {
  return useContext(VersionCheckContext)
}

export const VersionCheckProvider: FC<PropsWithChildren> = ({ children }): ReactElement => {
  const translate = useTranslate()
  const appVersion = packageJson.version

  // state
  const [newVersion, setNewVersion] = useState<string | undefined>(undefined)

  const fetchNewVersion = () => {
    // do not check for new version in dev mode
    if (isDevMode()) {
      return
    }

    console.info('Checking for new version')
    const url = '/version.txt?t=' + new Date().getTime()
    fetch(url)
      .then((response) => response.text())
      .then((latestVersionValue) => {
        const latestVersion = latestVersionValue ? latestVersionValue.trim() : latestVersionValue
        const hasNewVersion = Boolean(latestVersion && latestVersion.includes('.') && appVersion !== latestVersion)
        console.info(
          `Latest version: '${latestVersionValue}' Current version: '${appVersion}' Has new version: ${hasNewVersion}`,
        )
        if (hasNewVersion) {
          setNewVersion(latestVersionValue)
        }
      })
      .catch((err) => {
        console.error('Error checking for new version', err)
      })
  }

  useEffect(() => {
    // check for new version 6 seconds after first load, give time for other resources to load
    const timeout = setTimeout(fetchNewVersion, 6000)
    const interval = setInterval(fetchNewVersion, 181000)
    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [])

  const newVersionContextValue: VersionCheckContextValue = useMemo(() => {
    return {
      newVersion,
    }
  }, [newVersion])

  const closeSnackbar = () => {
    setNewVersion(undefined)
  }

  return (
    <VersionCheckContext.Provider value={newVersionContextValue}>
      {children}

      <Snackbar
        open={Boolean(newVersion)}
        onClose={closeSnackbar}
        severity="info"
        action={
          <Button
            color="inherit"
            onClick={() => {
              window.location.reload()
            }}
          >
            {translate('refresh')}&nbsp;
            <CachedIcon />
          </Button>
        }
        persistent={true}
      >
        {translate('newVersionAvailable')}: {newVersion}
      </Snackbar>
    </VersionCheckContext.Provider>
  )
}
