import {
  Currency,
  LaundryGroupReference,
  ManualPrepaidAdjustment,
  TopupPrepaidByOperator,
} from 'src/service/backend/api/models'

export interface LaundryUserTopupViewModel {
  amount?: number
  currency: Currency
  laundryGroupRef?: LaundryGroupReference
  reason?: string
}

export const mapLaundryUserTopupViewModelToTopupPrepaidByOperator = (
  viewModel: LaundryUserTopupViewModel,
): TopupPrepaidByOperator => {
  return {
    amount: viewModel.amount || 0,
    currency: viewModel.currency,
    billingLaundryGroupId: viewModel.laundryGroupRef?.id,
  }
}

export const mapLaundryUserTopupViewModelToManualPrepaidAdjustment = (
  viewModel: LaundryUserTopupViewModel,
): ManualPrepaidAdjustment => {
  return {
    amount: viewModel.amount || 0,
    currency: viewModel.currency,
    reason: viewModel.reason || '',
  }
}
