/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MachineCategory,
  MachineInsightType,
  TimeseriesData,
} from '../models';
import {
    MachineCategoryFromJSON,
    MachineCategoryToJSON,
    MachineInsightTypeFromJSON,
    MachineInsightTypeToJSON,
    TimeseriesDataFromJSON,
    TimeseriesDataToJSON,
} from '../models';

export interface ReportSmMachineInsightsChartGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    organizationId?: string;
    laundryGroupId?: string;
    laundryId?: string;
    machineId?: string;
    machineCategory?: MachineCategory;
    insights?: Array<MachineInsightType>;
}

/**
 * 
 */
export class ReportMachineInsightsApi extends runtime.BaseAPI {

    /**
     * List Timeseries Charting data for machine insights like operatingHours, programCounter, rssi
     */
    async reportSmMachineInsightsChartGetRaw(requestParameters: ReportSmMachineInsightsChartGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TimeseriesData>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling reportSmMachineInsightsChartGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling reportSmMachineInsightsChartGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        if (requestParameters.machineCategory !== undefined) {
            queryParameters['machineCategory'] = requestParameters.machineCategory;
        }

        if (requestParameters.insights) {
            queryParameters['insights'] = requestParameters.insights.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/report/sm/machine-insights/chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimeseriesDataFromJSON(jsonValue));
    }

    /**
     * List Timeseries Charting data for machine insights like operatingHours, programCounter, rssi
     */
    async reportSmMachineInsightsChartGet(startTimeFrom: Date, startTimeTo: Date, organizationId?: string, laundryGroupId?: string, laundryId?: string, machineId?: string, machineCategory?: MachineCategory, insights?: Array<MachineInsightType>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TimeseriesData> {
        const response = await this.reportSmMachineInsightsChartGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, organizationId: organizationId, laundryGroupId: laundryGroupId, laundryId: laundryId, machineId: machineId, machineCategory: machineCategory, insights: insights }, initOverrides);
        return await response.value();
    }

}
