import React, { ReactElement } from 'react'
import { Hidden } from '@mui/material'
import { TranslateFunction } from 'src/i18n/useMessageSource'
import { MachineType } from 'src/service/backend/api'
import { getMachineTypeNameWithTechSuffix } from 'src/service/view-model/machine/Machines'
import { StyledTabNotUppercase } from 'src/ui-shared/base/tab-panel/StyledTab'
import { ArticleIcon } from 'src/ui-shared/icons/Icons'

/**
 * Generates MUI Tab elements for each machine type.
 * This is not a component since MUI Tabs component selected value does not work
 * if the tabs are nested in root component <></>.
 * @param tabs
 * @returns
 */
export const generateMachineTypeTabs = (
  translate: TranslateFunction,
  tabs?: MachineType[],
  singleTabToEnable?: MachineType,
): ReactElement[] | null => {
  if (!tabs) {
    return null
  }

  return tabs.map((machineType) => (
    <StyledTabNotUppercase
      key={machineType}
      icon={<ArticleIcon />}
      iconPosition={'start'}
      value={machineType}
      disabled={singleTabToEnable && singleTabToEnable !== machineType}
      label={<Hidden lgDown>{getMachineTypeNameWithTechSuffix(machineType, translate)}</Hidden>}
    />
  ))
}
