import { useCallback, useState } from 'react'

export const useForceRender = (): (() => void) => {
  const [_value, setValue] = useState<boolean>(false)

  const forceRender = useCallback(() => {
    setValue((lastState) => !lastState)
  }, [setValue])

  return forceRender
}
