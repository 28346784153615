/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProgramStatus
 */
export interface ProgramStatus {
    /**
     * 
     * @type {string}
     * @memberof ProgramStatus
     */
    programId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramStatus
     */
    programName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProgramStatus
     */
    programIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramStatus
     */
    temperature?: number;
    /**
     * Unit of Temperatures ('В°C' | 'В°F')
     * @type {string}
     * @memberof ProgramStatus
     */
    temperatureUnit?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProgramStatus
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProgramStatus
     */
    estimatedEndTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProgramStatus
     */
    rpmSpin?: number;
}

/**
 * Check if a given object implements the ProgramStatus interface.
 */
export function instanceOfProgramStatus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProgramStatusFromJSON(json: any): ProgramStatus {
    return ProgramStatusFromJSONTyped(json, false);
}

export function ProgramStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'programId': !exists(json, 'programId') ? undefined : json['programId'],
        'programName': !exists(json, 'programName') ? undefined : json['programName'],
        'programIndex': !exists(json, 'programIndex') ? undefined : json['programIndex'],
        'temperature': !exists(json, 'temperature') ? undefined : json['temperature'],
        'temperatureUnit': !exists(json, 'temperatureUnit') ? undefined : json['temperatureUnit'],
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'estimatedEndTime': !exists(json, 'estimatedEndTime') ? undefined : (new Date(json['estimatedEndTime'])),
        'rpmSpin': !exists(json, 'rpmSpin') ? undefined : json['rpmSpin'],
    };
}

export function ProgramStatusToJSON(value?: ProgramStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'programId': value.programId,
        'programName': value.programName,
        'programIndex': value.programIndex,
        'temperature': value.temperature,
        'temperatureUnit': value.temperatureUnit,
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'estimatedEndTime': value.estimatedEndTime === undefined ? undefined : (value.estimatedEndTime.toISOString()),
        'rpmSpin': value.rpmSpin,
    };
}

