import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useNavigate, useParams } from 'react-router'
import { Box, Button, Divider, Grid, Paper } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  Organization,
  OrganizationReference,
  OrganizationType,
  OrganizationsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getMainLocales } from 'src/service/view-model/base/localization/Locales'
import {
  OrganizationViewModel,
  createOrganizationViewModel,
  mapViewModelToOrganization,
} from 'src/service/view-model/organization/OrganizationViewModel'
import { SERVICER_ORGANIZATION_TYPES } from 'src/service/view-model/organization/Organizations'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { AsyncAutoCompleteValidate } from 'src/ui-shared/base/form/control/AsyncAutoCompleteValidate'
import { AutoCompleteField } from 'src/ui-shared/base/form/control/AutoCompleteField'
import { CheckboxField } from 'src/ui-shared/base/form/control/CheckboxField'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { required } from 'src/ui-shared/base/form/validation/Validators'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { usePrompt } from 'src/ui-shared/base/hooks/usePrompt'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { AddressSectionEdit } from 'src/ui-shared/form/address-section/AddressSectionEdit'
import { LocaleAutoCompleteValidate } from 'src/ui-shared/form/control/LocaleAutoCompleteValidate'
import { TimezoneAutoCompleteValidate } from 'src/ui-shared/form/control/TimezoneAutoCompleteValidate'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

interface Props {
  isServicePartner?: boolean
}

export const OrganizationCreatePage: FC<Props> = ({ isServicePartner }): ReactElement => {
  const { organizationId } = useParams()
  const editMode = !!organizationId

  const httpConfiguration: Configuration = useContext(HttpContext)
  const organizationApi = new OrganizationsApi(httpConfiguration)

  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()
  const navigate = useNavigate()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  // state
  const [loading, setLoading] = useState(false)
  const [organizationViewModel, setOrganizationViewModel] = useState<OrganizationViewModel>(
    createOrganizationViewModel(),
  )
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [dirty, setDirty] = useState<boolean>(false)

  useHotKeysForm()

  usePrompt(translate('notification.form.unsaved.changes'), dirty)

  // load data
  useEffect(() => {
    if (editMode) {
      organizationApi
        .organizationsOrganizationIdGet(organizationId!, [])
        .then((organization) => {
          const organizationViewModel = createOrganizationViewModel(organization)
          setOrganizationViewModel(organizationViewModel)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
        })
    }
  }, [editMode, organizationId])

  const servicePartnerSearch = (searchText: string): Promise<OrganizationReference[]> => {
    return organizationApi.organizationsRefGet(undefined, searchText, SERVICER_ORGANIZATION_TYPES)
  }

  const updateOrInsert = (organization: Organization) => {
    if (editMode) {
      return organizationApi.organizationsOrganizationIdPut(organizationId!, organization)
    } else {
      return organizationApi.organizationsPost(organization)
    }
  }

  const onSubmitSuccess = () => {
    setDirty(false)
    setLoading(false)
    navigateBack()
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  const submitForm = async (viewModel: OrganizationViewModel) => {
    setLoading(true)
    setDirty(false)

    // set type to PARTNER when creating new service partner.
    if (!editMode && isServicePartner) {
      viewModel.type = OrganizationType.PARTNER
    }

    const organization = mapViewModelToOrganization(viewModel)

    return updateOrInsert(organization)
      .then((_data) => {
        onSubmitSuccess()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  const navigateBack = () => {
    const screenRoute = isServicePartner ? 'service-partners' : 'organizations'
    if (organizationId) {
      navigate(`/${appId}/${screenRoute}/${organizationId}/view`)
    } else {
      navigate(`/${appId}/${screenRoute}`)
    }
  }

  const handleOnCancel = () => {
    navigateBack()
  }

  return (
    <ScreenLayout
      title={organizationId ? organizationViewModel.name : translate('createServicePartner')}
      onBack={navigateBack}
      actionsWidth={50}
    >
      <LoadingIndicator loading={loading} />
      <Paper elevation={0}>
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <Box pt={1}>
            <Form<OrganizationViewModel>
              initialValues={organizationViewModel}
              onSubmit={submitForm}
              render={({ handleSubmit, submitting, pristine }) => {
                return (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TextField
                          name="name"
                          label={translate('company.name')}
                          disabled={submitting || !isServicePartner}
                          validate={required()}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TextField
                          name="ifsCustomerNumber"
                          label={translate('company.id')}
                          validate={required()}
                          disabled={submitting || editMode}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                        <TextField name="email" label={translate('email')} disabled={submitting || !isServicePartner} />
                      </Grid>
                      {isServicePartner && (
                        <>
                          <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                            <TextField name="supportEmail" label={translate('supportEmail')} disabled={submitting} />
                          </Grid>

                          <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                            <CheckboxField
                              name="ticketingSystemAvailable"
                              label={translate('ticketingSystemAvailable')}
                              color="primary"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                        <TextField
                          name="phoneNumber"
                          label={translate('phonenumber')}
                          disabled={submitting || !isServicePartner}
                          validate={required()}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <LocaleAutoCompleteValidate
                          options={getMainLocales()}
                          name="locale"
                          validate={required()}
                          disabled={submitting || !isServicePartner}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TimezoneAutoCompleteValidate
                          name="timeZone"
                          disabled={submitting || !isServicePartner}
                          validate={required()}
                        />
                      </Grid>
                    </Grid>

                    <Divider />

                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      <AddressSectionEdit submitting={submitting} editMode={editMode} disabled={!isServicePartner} />
                    </Grid>

                    <Divider />

                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      {!isServicePartner && (
                        <>
                          <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                            <AsyncAutoCompleteValidate
                              disabled={submitting}
                              name="servicePartner"
                              validate={required()}
                              label={translate('servicePartner')}
                              delay={300}
                              labelFieldName="name"
                              loadOptionsFunction={servicePartnerSearch}
                            />
                          </Grid>
                        </>
                      )}
                      {editMode && (
                        <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                          <TextField name="vatNumber" label={translate('company.vatnumber')} disabled />
                        </Grid>
                      )}
                      {editMode && (
                        <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                          <AutoCompleteField
                            label={translate('company.type')}
                            name="type"
                            options={[
                              {
                                label: translate('organization.typeCompany'),
                                value: OrganizationType.COMPANY,
                              },

                              {
                                label: translate('organization.typeSchulthess'),
                                value: OrganizationType.SCHULTHESS,
                              },
                              {
                                label: translate('organization.typePartner'),
                                value: OrganizationType.PARTNER,
                              },
                            ]}
                            validate={required()}
                            disabled
                          />
                        </Grid>
                      )}
                    </Grid>
                    {editMode && <Divider className={sharedClasses.Divider} />}

                    <Box pt={2} display="flex" justifyContent="flex-end">
                      <Button
                        id="cancelButton"
                        variant="text"
                        color="primary"
                        size="large"
                        className={sharedClasses.ButtonMargin}
                        onClick={handleOnCancel}
                      >
                        {translate('button.cancel')}
                      </Button>

                      <Button
                        id="submitButton"
                        disabled={submitting || pristine}
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                      >
                        {translate('button.save')}
                      </Button>
                    </Box>
                    <FormSpy
                      subscription={{ dirty: true }}
                      onChange={(props) => {
                        setTimeout(() => setDirty(props.dirty), 0)
                      }}
                    />
                  </form>
                )
              }}
            />
          </Box>
        )}
      </Paper>
    </ScreenLayout>
  )
}
