/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineCategory } from './MachineCategory';
import {
    MachineCategoryFromJSON,
    MachineCategoryFromJSONTyped,
    MachineCategoryToJSON,
} from './MachineCategory';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';

/**
 * 
 * @export
 * @interface MachineReference
 */
export interface MachineReference {
    /**
     * 
     * @type {string}
     * @memberof MachineReference
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MachineReference
     */
    readonly name: string;
    /**
     * 
     * @type {MachineCategory}
     * @memberof MachineReference
     */
    category: MachineCategory;
    /**
     * 
     * @type {MachineType}
     * @memberof MachineReference
     */
    type: MachineType;
    /**
     * 
     * @type {string}
     * @memberof MachineReference
     */
    readonly serialNumber: string;
}

/**
 * Check if a given object implements the MachineReference interface.
 */
export function instanceOfMachineReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "serialNumber" in value;

    return isInstance;
}

export function MachineReferenceFromJSON(json: any): MachineReference {
    return MachineReferenceFromJSONTyped(json, false);
}

export function MachineReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'category': MachineCategoryFromJSON(json['category']),
        'type': MachineTypeFromJSON(json['type']),
        'serialNumber': json['serialNumber'],
    };
}

export function MachineReferenceToJSON(value?: MachineReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'category': MachineCategoryToJSON(value.category),
        'type': MachineTypeToJSON(value.type),
    };
}

