import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useNavigate, useParams } from 'react-router'
import { Autocomplete, Box, Button, Chip, Divider, Grid, Paper } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, LaundriesApi, Laundry, LaundryGroupReference, LaundryGroupsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  LaundryViewModel,
  createLaundryViewModel,
  mapLaundryViewModelToLaundry,
} from 'src/service/view-model/laundry/LaundryViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { AsyncAutoCompleteValidate } from 'src/ui-shared/base/form/control/AsyncAutoCompleteValidate'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { maxChar, required } from 'src/ui-shared/base/form/validation/Validators'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { usePrompt } from 'src/ui-shared/base/hooks/usePrompt'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { TEXT_LENGTH } from 'src/ui-shared/constants/Constants'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { AddressSectionEdit } from 'src/ui-shared/form/address-section/AddressSectionEdit'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

export const LaundryCreatePage = (): ReactElement => {
  const { laundryId } = useParams()
  const editMode = !!laundryId

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundriesApi = new LaundriesApi(httpConfiguration)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)

  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()
  const navigate = useNavigate()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  // state
  const [loading, setLoading] = useState(false)
  const [laundryViewModel, setLaundryViewModel] = useState<LaundryViewModel>(createLaundryViewModel())
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [posTerminalIds, setPosTerminalIds] = useState<string[]>([])
  const [posTerminalIdsTouched, setPosTerminalIdsTouched] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  useHotKeysForm()

  usePrompt(translate('notification.form.unsaved.changes'), dirty)

  // load data
  useEffect(() => {
    if (editMode) {
      laundriesApi
        .laundriesLaundryIdGet(laundryId!)
        .then((laundry) => {
          const laundryViewModel = createLaundryViewModel(laundry)
          setPosTerminalIds(laundryViewModel.posTerminalIds)
          setLaundryViewModel(laundryViewModel)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
        })
    }
  }, [editMode, laundryId])

  const updateOrInsert = (laundry: Laundry) => {
    if (editMode) {
      return laundriesApi.laundriesLaundryIdPut(laundryId!, [], laundry)
    } else {
      return laundriesApi.laundriesPost([], laundry)
    }
  }

  // handle events
  const laundryGroupSearch = (searchText: string): Promise<LaundryGroupReference[]> => {
    return laundryGroupsApi.laundrygroupsRefGet(undefined, searchText)
  }

  const onSubmitSuccess = () => {
    setDirty(false)
    setLoading(false)
    navigateBack()
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  const submitForm = async (viewModel: LaundryViewModel) => {
    setLoading(true)
    setDirty(false)
    viewModel.posTerminalIds = posTerminalIds
    const laundry = mapLaundryViewModelToLaundry(viewModel)
    return updateOrInsert(laundry)
      .then((_data) => {
        onSubmitSuccess()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  const navigateBack = () => {
    if (laundryId) {
      navigate(`/${appId}/laundries/${laundryId}/view`)
    } else {
      navigate(`/${appId}/laundries`)
    }
  }

  const handleOnCancel = () => {
    navigateBack()
  }

  const handlePosTerminalIdsChange = (event: React.SyntheticEvent, value: string[]) => {
    setPosTerminalIdsTouched(true)
    setPosTerminalIds(value)

    if (!editMode && value.length === 0) {
      setPosTerminalIdsTouched(false)
    }
  }

  const handlePosTerminalIdsBlur = (event?: React.FocusEvent<any>): void => {
    if (event && event.target && event.target.value) {
      const text: string | undefined = event.target.value.toString().trim()
      if (text) {
        const foundItem = posTerminalIds.find((item) => item === text)
        if (!foundItem) {
          posTerminalIds.push(text)
          setPosTerminalIdsTouched(true)
        }
      }
    }
  }

  return (
    <ScreenLayout
      title={laundryId ? laundryViewModel.name : translate('createLaundry')}
      onBack={navigateBack}
      actions={<></>}
      actionsWidth={50}
    >
      <LoadingIndicator loading={loading} />
      <Paper elevation={0}>
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <Box pt={2}>
            <Form<LaundryViewModel>
              initialValues={laundryViewModel}
              onSubmit={submitForm}
              render={({ handleSubmit, submitting, pristine }) => {
                return (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TextField
                          label={translate('laundry.name')}
                          type="text"
                          name="name"
                          fullWidth
                          autoFocus={!editMode}
                          disabled={submitting}
                          validate={required()}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <AsyncAutoCompleteValidate
                          disabled={submitting || editMode}
                          name="laundryGroup"
                          validate={required()}
                          label={translate('laundryGroup')}
                          delay={300}
                          labelFieldName="name"
                          loadOptionsFunction={laundryGroupSearch}
                        />
                      </Grid>
                    </Grid>
                    <Divider className={sharedClasses.Divider} />

                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      <AddressSectionEdit submitting={submitting} editMode={editMode} />
                    </Grid>

                    <Divider className={sharedClasses.Divider} />

                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                        <TextField
                          label={translate('notes')}
                          type="text"
                          name="notes"
                          fullWidth
                          multiline
                          maxRows={4}
                          disabled={submitting}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TextField
                          label={translate('laundry.wifiSSID')}
                          type="text"
                          name="wifiSSID"
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TextField
                          label={translate('laundry.wifiSecret')}
                          type="text"
                          name="wifiSecret"
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                        <TextField
                          label={translate('terminalSerialNumber')}
                          type="text"
                          name="terminalSerialNumber"
                          fullWidth
                          disabled={submitting}
                          validate={maxChar(TEXT_LENGTH.S)}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                        <TextField
                          label={translate('terminalPassword')}
                          type="text"
                          name="terminalPassword"
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS} lg={12} md={12}>
                        <Autocomplete
                          onChange={handlePosTerminalIdsChange}
                          onBlur={handlePosTerminalIdsBlur}
                          multiple={true}
                          freeSolo={true}
                          disabled={submitting}
                          value={posTerminalIds}
                          options={posTerminalIds}
                          getOptionLabel={() => ''}
                          open={false}
                          clearOnBlur={true}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                              const label = option || ''
                              return <Chip variant="outlined" label={label} {...getTagProps({ index })} key={label} />
                            })
                          }
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                name="posTerminalIds"
                                label={translate('posTerminalIds')}
                                placeholder={translate('posTerminalIds')}
                              />
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Divider className={sharedClasses.Divider} />

                    <Box pt={2} display="flex" justifyContent="flex-end">
                      <Button
                        id="cancelButton"
                        variant="text"
                        color="primary"
                        size="large"
                        className={sharedClasses.ButtonMargin}
                        onClick={handleOnCancel}
                      >
                        {translate('button.cancel')}
                      </Button>

                      <Button
                        id="submitButton"
                        disabled={(submitting || pristine) && !posTerminalIdsTouched}
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                      >
                        {translate('button.save')}
                      </Button>
                    </Box>
                    <FormSpy
                      subscription={{ dirty: true }}
                      onChange={(props) => {
                        setTimeout(() => setDirty(props.dirty), 0)
                      }}
                    />
                  </form>
                )
              }}
            />
          </Box>
        )}
      </Paper>
    </ScreenLayout>
  )
}
