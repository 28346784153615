import React, { PropsWithChildren, memo, useContext, useEffect, useState } from 'react'
import { Provider as MessageSourceProvider } from 'src/i18n/MessageSourceContext'
import { TRANSLATIONS_VERSION } from 'src/i18n/TranslationsVersion'
import { SUPPORTED_LOCALES } from 'src/user/SupportedLanguage'
import { UserContext, useUserLocale } from 'src/user/UserContext'

const MessageSourceLoader = memo(function MessageSourceLoader({ children }: PropsWithChildren) {
  const locale = useUserLocale()
  const [translations, setTranslations] = useState<Record<string, string>>({})
  const { user } = useContext(UserContext)
  const email = user.email

  useEffect(() => {
    const executeChange = async () => {
      try {
        // cache the URL if the translations content has not changed
        const cache = TRANSLATIONS_VERSION

        const translationFile = getTranslationsFile(locale)
        const translationFileUrl = `/${translationFile}?version=${cache}`

        const result = await fetch(translationFileUrl).then((response) => response.json())

        if (result) {
          setTranslations(result)
        }
      } catch (err) {
        const errorMessage = 'Error loading translations'
        console.error(errorMessage, err)
        throw new Error(errorMessage)
      }
    }
    executeChange()
  }, [locale, setTranslations, email])
  return <MessageSourceProvider value={translations}>{children}</MessageSourceProvider>
})
export { MessageSourceLoader }

const getTranslationsFile = (locale: string) => {
  let translationFile

  if (SUPPORTED_LOCALES.includes(locale)) {
    translationFile = `translations-${locale}.json`
  } else {
    console.warn('Unsupported locale: ', locale)
    translationFile = 'translations-en.json'
  }
  return translationFile
}
