/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { InvoiceState } from './InvoiceState';
import {
    InvoiceStateFromJSON,
    InvoiceStateFromJSONTyped,
    InvoiceStateToJSON,
} from './InvoiceState';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';

/**
 * 
 * @export
 * @interface LaundryGroupInvoice
 */
export interface LaundryGroupInvoice {
    /**
     * 
     * @type {string}
     * @memberof LaundryGroupInvoice
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroupInvoice
     */
    readonly billingMonth: string;
    /**
     * 
     * @type {Date}
     * @memberof LaundryGroupInvoice
     */
    readonly invoiceDate: Date;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroupInvoice
     */
    payoutReference?: string;
    /**
     * 
     * @type {number}
     * @memberof LaundryGroupInvoice
     */
    amount: number;
    /**
     * 
     * @type {Currency}
     * @memberof LaundryGroupInvoice
     */
    currency: Currency;
    /**
     * 
     * @type {InvoiceState}
     * @memberof LaundryGroupInvoice
     */
    invoiceState: InvoiceState;
    /**
     * 
     * @type {LaundryGroupReference}
     * @memberof LaundryGroupInvoice
     */
    laundryGroup: LaundryGroupReference;
}

/**
 * Check if a given object implements the LaundryGroupInvoice interface.
 */
export function instanceOfLaundryGroupInvoice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "billingMonth" in value;
    isInstance = isInstance && "invoiceDate" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "invoiceState" in value;
    isInstance = isInstance && "laundryGroup" in value;

    return isInstance;
}

export function LaundryGroupInvoiceFromJSON(json: any): LaundryGroupInvoice {
    return LaundryGroupInvoiceFromJSONTyped(json, false);
}

export function LaundryGroupInvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupInvoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'billingMonth': json['billingMonth'],
        'invoiceDate': (new Date(json['invoiceDate'])),
        'payoutReference': !exists(json, 'payoutReference') ? undefined : json['payoutReference'],
        'amount': json['amount'],
        'currency': CurrencyFromJSON(json['currency']),
        'invoiceState': InvoiceStateFromJSON(json['invoiceState']),
        'laundryGroup': LaundryGroupReferenceFromJSON(json['laundryGroup']),
    };
}

export function LaundryGroupInvoiceToJSON(value?: LaundryGroupInvoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payoutReference': value.payoutReference,
        'amount': value.amount,
        'currency': CurrencyToJSON(value.currency),
        'invoiceState': InvoiceStateToJSON(value.invoiceState),
        'laundryGroup': LaundryGroupReferenceToJSON(value.laundryGroup),
    };
}

