import React, { ReactElement } from 'react'
import { FieldValues } from 'react-hook-form'
import { Grid } from '@mui/material'
import { SettingFieldType } from 'src/service/backend/api'
import { SettingFieldWithIndex, getFieldLabel } from 'src/service/view-model/setting-field/SettingFieldUtils'
import { AutocompleteHookForm } from 'src/ui-shared/base/form/control-hook-form/AutocompleteHookForm'
import { BitFieldHookForm } from 'src/ui-shared/base/form/control-hook-form/BitFieldHookForm'
import { CheckboxHookForm } from 'src/ui-shared/base/form/control-hook-form/CheckboxHookForm'
import { NumberTextFieldHookForm } from 'src/ui-shared/base/form/control-hook-form/NumberTextFieldHookForm'
import { TextFieldHookForm } from 'src/ui-shared/base/form/control-hook-form/TextFieldHookForm'
import { DynamicSettingFieldsFormContext } from 'src/ui-shared/base/form/dynamic-setting-form/DynamicSettingFieldsFormContext'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'

export interface DynamicSettingFieldsFormInputsProps<T extends FieldValues> extends DynamicSettingFieldsFormContext<T> {
  field: SettingFieldWithIndex
  changeShownGroupFieldsBoolean: (field: SettingFieldWithIndex) => void
  changeShownGroupFieldsSelect: (field: SettingFieldWithIndex) => void
}

export const DynamicSettingFieldsFormInputs = <T extends FieldValues>({
  prefix,
  field,
  formDisabled,
  loading,
  form,
  changeShownGroupFieldsBoolean,
  changeShownGroupFieldsSelect,
}: DynamicSettingFieldsFormInputsProps<T>): ReactElement => {
  if (field.fieldType === SettingFieldType.SELECT) {
    return (
      <Grid item {...ITEM_BREAKPOINTS} key={field.fieldIndex}>
        <AutocompleteHookForm
          disabled={formDisabled || field.readOnly || loading}
          control={form.control}
          // @ts-ignore
          name={`${prefix}.${field.fieldIndex}.selectField.data`}
          transform={{
            labelFieldName: 'label',
            valueFieldName: 'data',
          }}
          label={getFieldLabel(field)}
          activeValue={field.selectField?.activeData}
          disableClearable={field.validation?.required}
          options={field.selectField?.options || []}
          hintText={field.hint}
          onChangeEventListener={() => changeShownGroupFieldsSelect(field)}
        />
      </Grid>
    )
  } else if (field.fieldType === SettingFieldType.NUMBER) {
    return (
      <Grid item {...ITEM_BREAKPOINTS} key={field.fieldIndex}>
        <NumberTextFieldHookForm
          label={getFieldLabel(field)}
          disabled={formDisabled || field.readOnly || loading}
          control={form.control}
          activeValue={field.numberField?.activeData}
          defaultValue={field.numberField?.factoryDefaultData}
          decimal={field.numberField?.isDecimal}
          // @ts-ignore
          name={`${prefix}.${field.fieldIndex}.numberField.data`}
          hintText={field.hint}
        />
      </Grid>
    )
  } else if (field.fieldType === SettingFieldType.BOOLEAN) {
    return (
      <Grid item {...ITEM_BREAKPOINTS} key={field.fieldIndex}>
        <CheckboxHookForm
          label={getFieldLabel(field)}
          disabled={formDisabled || field.readOnly || loading}
          control={form.control}
          // @ts-ignore
          name={`${prefix}.${field.fieldIndex}.booleanField.data`}
          hintText={field.hint}
          onChangeEventListener={() => changeShownGroupFieldsBoolean(field)}
        />
      </Grid>
    )
  } else if (field.fieldType === SettingFieldType.TEXT) {
    return (
      <Grid item {...ITEM_BREAKPOINTS} key={field.fieldIndex}>
        <TextFieldHookForm
          label={getFieldLabel(field)}
          disabled={formDisabled || field.readOnly || loading}
          control={form.control}
          activeValue={field.textField?.activeData}
          defaultValue={field.textField?.factoryDefaultData}
          // @ts-ignore
          name={`${prefix}.${field.fieldIndex}.textField.data`}
          hintText={field.hint}
        />
      </Grid>
    )
  } else if (field.fieldType === SettingFieldType.BIT_FLAGS) {
    return (
      <Grid item {...ITEM_BREAKPOINTS} key={field.fieldIndex}>
        <BitFieldHookForm
          label={getFieldLabel(field)}
          disabled={formDisabled || field.readOnly || loading}
          control={form.control}
          activeValue={field.bitFlagsField?.activeData}
          defaultValue={field.bitFlagsField?.factoryDefaultData}
          flags={field.bitFlagsField?.flags}
          // @ts-ignore
          name={`${prefix}.${field.fieldIndex}.bitFlagsField.data`}
          hintText={field.hint}
        />
      </Grid>
    )
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (field.fieldType === SettingFieldType.GROUP) {
    // ignore
    return <></>
  } else {
    return <>Unknown field type: {field.fieldType}</>
  }
}
