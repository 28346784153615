import { LaundryUser, LaundryUserReference, LaundryUserType } from 'src/service/backend/api'

export function mapLanudryUserToReference(laundryUser: LaundryUser): LaundryUserReference {
  const result: LaundryUserReference = {
    id: laundryUser.id,
    firstName: laundryUser.firstName,
    lastName: laundryUser.lastName,
    email: laundryUser.email,
  }
  return result
}

export const getUserName = (user: LaundryUserReference | undefined): string => {
  if (!user) {
    return ''
  }
  return user.firstName + ' ' + user.lastName
}

export const getLaundryUserType = (type?: LaundryUserType | null): string | undefined => {
  if (!type) {
    return undefined
  }

  switch (type) {
    case LaundryUserType.APP_USER:
      return 'App User'
    case LaundryUserType.APPCLIP_USER:
      return 'App Clip User'
    case LaundryUserType.INSTANTAPP_USER:
      return 'Instant App User'
    case LaundryUserType.APP_POS_USER:
      return 'POS User'
    case LaundryUserType.CARD_ONLY_USER:
      return 'Card Only User'
  }
}
