import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Divider, Grid, Hidden, Paper } from '@mui/material'
import { GetApp } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { ApiResponse, Configuration, ReportWashMasterApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getRemoveAcceptHeaderInit } from 'src/service/backend/http/HttpUtils'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { downloadFile, getDownloadFilename } from 'src/service/utils/FileDownloadUtils'
import { getLastMonth, removeTimeFromDate, removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { StyledTab } from 'src/ui-shared/base/tab-panel/StyledTab'
import { StyledTabs } from 'src/ui-shared/base/tab-panel/StyledTabs'
import { TabPanel } from 'src/ui-shared/base/tab-panel/TabPanel'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ArticleIcon, InsertChartOutlinedIcon } from 'src/ui-shared/icons/Icons'
import { getDefaultTableSettings, withOrganization } from 'src/ui-shared/table/Table.const'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { TopUpChart } from 'src/ui/page/wm/topup/list/TopUpChart'
import { TopUpTab } from 'src/ui/page/wm/topup/list/TopUpTab'
import { TopUpTable, TopUpTableSettings } from 'src/ui/page/wm/topup/list/TopUpTable'

const dateNow = removeTimeFromDateEndOfDay(new Date())
const dateLastMonth = removeTimeFromDate(getLastMonth(dateNow))

export const TopUpListPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const navigate = useNavigate()
  const appId = useAppId()
  const activeOranization = useActiveOrganization()

  const showSnackbar = useShowSnackbar()
  const { tabNameParam } = useParams()
  const tabName = tabNameParam ? getEnumFromString(tabNameParam, TopUpTab) : TopUpTab.DATA

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportApi = new ReportWashMasterApi(httpConfiguration)

  const defaultTableSettings: TopUpTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'settledDateTime',
    orderDir: 'asc',
    settledDateTimeFrom: dateLastMonth.toISOString(),
    settledDateTimeTo: dateNow.toISOString(),
  }

  const [activeTab, setActiveTab] = useState<TopUpTab>(TopUpTab.DATA)
  const [tableSettings, setTableSettings] = useState<TopUpTableSettings>(
    withOrganization(defaultTableSettings, activeOranization),
  )

  // load data
  useEffect(() => {
    setActiveTab(tabName)
  }, [])

  // handle events
  const handleExportButtonClick = () => {
    if (!tableSettings.settledDateTimeFrom || !tableSettings.settledDateTimeTo) {
      return
    }

    const dateStartTimeFrom: Date = new Date(tableSettings.settledDateTimeFrom)
    const dateStartTimeTo: Date = new Date(tableSettings.settledDateTimeTo)

    let filename: string | null = null
    reportApi
      .reportWmTopUpsCsvGetRaw(
        {
          settledDateTimeFrom: dateStartTimeFrom,
          settledDateTimeTo: dateStartTimeTo,
          reportTopUpType: tableSettings.reportTopUpType,
          laundryUserId: tableSettings.laundryUserId,
          datatransPaymentMethod: tableSettings.datatransPaymentMethod,
        },
        getRemoveAcceptHeaderInit(),
      )
      .then(async (response: ApiResponse<Blob>) => {
        filename = getDownloadFilename(response.raw)
        return response.value()
      })
      .then((blob: Blob) => {
        downloadFile(blob, filename)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: TopUpTab) => {
    const pathToNavigate = `/${appId}/top-ups/view/${newValue}`
    navigate(pathToNavigate, { replace: true })
    setActiveTab(newValue)
  }

  return (
    <ScreenLayout
      title={translate('topups')}
      actionsWidth={170}
      actions={
        <>
          {activeTab === 'data' ? (
            <ListingButton
              onClick={handleExportButtonClick}
              variant="outlined"
              color="primary"
              startIcon={<GetApp />}
              disabled={!tableSettings.settledDateTimeFrom || !tableSettings.settledDateTimeTo}
            >
              {translate('exportCSV')}
            </ListingButton>
          ) : null}
        </>
      }
    >
      <Paper elevation={0}>
        <Grid container className={sharedClasses.TabsContainer}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <StyledTabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={handleTabChange}
            >
              <StyledTab
                icon={<ArticleIcon />}
                iconPosition={'start'}
                value={TopUpTab.DATA}
                label={<Hidden mdDown>{translate('data')}</Hidden>}
              />
              <StyledTab
                icon={<InsertChartOutlinedIcon />}
                iconPosition={'start'}
                value={TopUpTab.DASHBOARD}
                label={<Hidden mdDown>{translate('dashboard')}</Hidden>}
              />
            </StyledTabs>
          </Grid>
        </Grid>
        <Divider />
        <TabPanel value={activeTab} index={TopUpTab.DATA}>
          <TopUpTable
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            defaultTableSettings={defaultTableSettings}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={TopUpTab.DASHBOARD}>
          <TopUpChart />
        </TabPanel>
      </Paper>
    </ScreenLayout>
  )
}
