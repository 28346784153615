// strict-mode-find-node is caused by material-ui. in the current version 4.x.x they are not react strict mode compliant
// but they aim to be in 5.x.x
const KNOWN_VIOLATIONS = ['https://reactjs.org/link/strict-mode-find-node']

export const configureConsoleError = (silenceKnownErrors = true): void => {
  if (silenceKnownErrors) {
    const oldError = console.error

    console.error = (...args) => {
      const firstArg = args[0]

      if (typeof firstArg === 'string' && KNOWN_VIOLATIONS.some((v) => firstArg.includes(v))) {
        return
      }
      oldError(...args)
    }
  }
}
