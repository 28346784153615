import { ModuleLiquidDetergentSettings, Step } from 'src/service/backend/api'

export interface LiquidDetergentViewModel {
  stepNumber: number
  displayStep: Step
  detergentPumpA: string
  detergentPumpB: string
  dosageA: string
  dosageB: string
  rinsingInTime: string
}

export const mapLiquidDetergentStepsToLiquidDetergentStepsViewModel = (
  steps: Array<ModuleLiquidDetergentSettings> | undefined,
): Array<LiquidDetergentViewModel> => {
  const stepsViewModel = steps?.map((step) => {
    return {
      stepNumber: step.stepNumber,
      displayStep: step.displayStep,
      detergentPumpA: step.detergentPumpA.toString(),
      detergentPumpB: step.detergentPumpB.toString(),
      dosageA: step.dosageA.toString(),
      dosageB: step.dosageB.toString(),
      rinsingInTime: step.rinsingInTime.toString(),
    }
  })
  return stepsViewModel as Array<LiquidDetergentViewModel>
}

export const mapLiquidDetergentStepsViewModelToLiquidDetergentSteps = (
  detergentStepsViewModel: Array<LiquidDetergentViewModel>,
): Array<ModuleLiquidDetergentSettings> => {
  const steps = detergentStepsViewModel.map((stepViewModel) => {
    return {
      stepNumber: stepViewModel.stepNumber,
      displayStep: stepViewModel.displayStep,
      detergentPumpA: +stepViewModel.detergentPumpA,
      detergentPumpB: +stepViewModel.detergentPumpB,
      dosageA: +stepViewModel.dosageA,
      dosageB: +stepViewModel.dosageB,
      rinsingInTime: +stepViewModel.rinsingInTime,
    }
  })
  return steps as Array<ModuleLiquidDetergentSettings>
}
