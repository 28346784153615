/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LaundryGroupProducts
 */
export interface LaundryGroupProducts {
    /**
     * 
     * @type {boolean}
     * @memberof LaundryGroupProducts
     */
    washMaster: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LaundryGroupProducts
     */
    serviceMaster: boolean;
}

/**
 * Check if a given object implements the LaundryGroupProducts interface.
 */
export function instanceOfLaundryGroupProducts(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "washMaster" in value;
    isInstance = isInstance && "serviceMaster" in value;

    return isInstance;
}

export function LaundryGroupProductsFromJSON(json: any): LaundryGroupProducts {
    return LaundryGroupProductsFromJSONTyped(json, false);
}

export function LaundryGroupProductsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupProducts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'washMaster': json['washMaster'],
        'serviceMaster': json['serviceMaster'],
    };
}

export function LaundryGroupProductsToJSON(value?: LaundryGroupProducts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'washMaster': value.washMaster,
        'serviceMaster': value.serviceMaster,
    };
}

