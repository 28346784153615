import {
  CalendarEntry,
  CalendarEntryType,
  LaundryGroupRecurringCalendarEntry,
  LaundryReference,
  MachineReference,
} from 'src/service/backend/api'
import { DataSettings } from 'src/service/view-model/base/DataSettings'

export interface CalendarEntrySlot {
  startTime: string
  endTime: string
  duration: number
  laundryCalendar?: LaundryGroupRecurringCalendarEntry
  calendarEntry?: CalendarEntry
  isActiveUser?: boolean
}

export interface CalendarEntrySingleDay {
  dayOfWeek: string
  entries: CalendarEntrySlot[]
}

// Data transfer interface
export interface SlotAndDay {
  slot: CalendarEntrySlot
  dayOfWeek: string
}

export interface CalendarSettings extends DataSettings {
  anchorDate: Date
  selectedLaundry?: LaundryReference
  selectedMachine?: MachineReference
}

export const DEFAULT_CALENDAR_SETTINGS: CalendarSettings = {
  anchorDate: new Date(),
}

export const CALENDAR_ENTRY_TYPE_PRIORITY = new Map<CalendarEntryType, number>([
  [CalendarEntryType.RESERVATION, 0],
  [CalendarEntryType.AD_HOC_RESERVATION, 1],
  [CalendarEntryType.MAINTENANCE, 2],
  [CalendarEntryType.ACTIVE_USAGE, 3],
])
