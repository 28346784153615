import React, { ReactElement } from 'react'
import { Box, Step, StepLabel, Stepper, useMediaQuery, useTheme } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'

interface Props {
  steps: string[]
  activeStep: number
}

export const ScreenSteps = ({ steps, activeStep }: Props): ReactElement => {
  const translate = useTranslate()
  const theme = useTheme()
  const isMediumScreenSize = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Box pt={3} justifyContent={'center'} alignItems={'center'} display={'flex'}>
      <Stepper activeStep={activeStep} sx={{ width: '60%' }} alternativeLabel={isMediumScreenSize}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{translate(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
