import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LOCALES, LocaleObject } from 'src/service/view-model/base/localization/Locales'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

export interface LocaleAutoCompleteProps {
  onChange: (option: LocaleObject | undefined) => void
  name: string
  value: LocaleObject | undefined
  options?: LocaleObject[]
  disabled: boolean
  error?: boolean
}

export const LocaleAutoComplete: React.FC<LocaleAutoCompleteProps> = ({
  onChange,
  value,
  name,
  options,
  disabled = false,
  error,
}) => {
  const translate = useTranslate()

  const onHandleChange = (event: React.SyntheticEvent, object: LocaleObject | null) => {
    onChange(object ? object : undefined)
  }

  return (
    <Autocomplete
      id="locale-autocomplete"
      openOnFocus={true}
      options={options ? options : LOCALES}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      autoHighlight
      disableClearable={false}
      multiple={false}
      freeSolo={false}
      onChange={onHandleChange}
      disabled={disabled}
      value={value}
      groupBy={(option) => option.group}
      renderInput={(params) => <TextFieldDefault name={name} error={error} {...params} label={translate('language')} />}
    />
  )
}
