import React, { PropsWithChildren, ReactElement } from 'react'
import { ClickAwayListener, Paper, Popper, styled, useScrollTrigger } from '@mui/material'
import { SCROLL_OPTIONS } from 'src/ui-shared/constants/PageScroll.const'

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiPopperUnstyled-root': {
    zIndex: '999999',
  },
  '& .MuiPaper-root': {
    zIndex: '999999',
    borderRadius: 0,
    boxShadow: theme.palette.menu.shadow,
  },
}))

interface Props extends PropsWithChildren {
  open: boolean
  anchorEl: HTMLLIElement | null
  handleClose: () => void
}

export const MenuContent: React.FC<Props> = ({ children, open, anchorEl, handleClose }): ReactElement => {
  const scrollTrigger = useScrollTrigger(SCROLL_OPTIONS)

  return (
    <StyledPopper open={open} placement="bottom-end" disablePortal={true} anchorEl={anchorEl}>
      <ClickAwayListener onClickAway={handleClose}>
        <Paper elevation={0} style={{ display: scrollTrigger ? 'none' : '' }}>
          {children}
        </Paper>
      </ClickAwayListener>
    </StyledPopper>
  )
}
