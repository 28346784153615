import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, ListItemButton, ListItemText } from '@mui/material'
import { PersonOutline } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { MenuContent } from 'src/ui/layout/appbar-menu/MenuContent'
import { StyledDivider, StyledMenuItem } from 'src/ui/layout/appbar-menu/MenuStyle'
import { handleLogout } from 'src/user/Logout'

export const ProfileMenu: React.FC = () => {
  const translate = useTranslate()
  const appId = useAppId()

  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null)

  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openPopperHandle = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  return (
    <>
      <StyledMenuItem active={open ? 1 : 0} onClick={openPopperHandle}>
        <PersonOutline sx={{ color: 'white' }} />
      </StyledMenuItem>
      <MenuContent open={open} anchorEl={anchorEl} handleClose={handleClose}>
        <Box p={2} width={'250px'}>
          <ListItemButton onClick={handleClose} to={`/${appId}/profile`} component={Link}>
            <ListItemText>{translate('user.settings')}</ListItemText>
          </ListItemButton>
          <StyledDivider />

          <ListItemButton onClick={handleLogout}>
            <ListItemText>{translate('logout')}</ListItemText>
          </ListItemButton>
        </Box>
      </MenuContent>
    </>
  )
}
