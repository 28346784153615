import {
  LaundryGroupReference,
  LaundryReference,
  MachinePairingInfo,
  MachineType,
  MachinesPairingBody,
  ManualPairingBody,
  ManufacturerModelReference,
  ManufacturerReference,
  PairingType,
  PrefilledPairingBody,
} from 'src/service/backend/api/models'

export interface MachinePairGetViewModel {
  pairingId: string
  laundryGroupRef: LaundryGroupReference
}

export interface MachinePairPostViewModel {
  name: string
  type: MachineType
  serialNumber: string
  // laundryGroupRef: LaundryGroupReference
  laundryRef?: LaundryReference
}

export interface MachinePairManualPostViewModel extends MachinePairPostViewModel {
  drumSize?: number
  manufacturer: ManufacturerReference
  manufacturerModel: ManufacturerModelReference
}

export interface MachinesPairBody {
  pairingId: number
  laundryId: string
  name: string
}

export const mapMachinePairingInfoToMachinePairPostViewModel = (
  machinePairingInfo: MachinePairingInfo,
): MachinePairPostViewModel => {
  return {
    name: '',
    type: machinePairingInfo.type,
    serialNumber: machinePairingInfo.serialNumber,
  }
}

// export const mapMachinePairPostViewModelToMachinesPairBody = (
//   machinePairPostViewModel: MachinePairPostViewModel,
//   pairingId: string,
// ): MachinesPairBody => {
//   let pairingCode: number = parseInt(pairingId)
//   if (!pairingCode || isNaN(pairingCode)) {
//     pairingCode = 0
//   }
//   return {
//     pairingId: pairingCode,
//     laundryId: machinePairPostViewModel.laundryRef?.id || '',
//     name: machinePairPostViewModel.name || '',
//   }
// }

export const mapMachinePairPostViewModelToMachinesPairingBody = (
  machinePairPostViewModel: MachinePairPostViewModel | undefined,
  machinePairManualPostViewModel: MachinePairManualPostViewModel | undefined,
  pairingId: string,
): MachinesPairingBody => {
  if (!machinePairPostViewModel && !machinePairManualPostViewModel) {
    throw Error('At least one pairing view model required')
  }
  if (!pairingId) {
    throw Error('Pairing id required')
  }

  let manualInfo: ManualPairingBody | undefined
  let prefilledInfo: PrefilledPairingBody | undefined
  let pairingType: PairingType = PairingType.PREFILLED

  let laundryRef: LaundryReference | undefined
  if (machinePairPostViewModel) {
    laundryRef = machinePairPostViewModel.laundryRef
    pairingType = PairingType.PREFILLED
    prefilledInfo = {
      name: machinePairPostViewModel.name || '',
    }
  }

  if (machinePairManualPostViewModel) {
    pairingType = PairingType.MANUAL
    laundryRef = machinePairManualPostViewModel.laundryRef
    manualInfo = {
      primary: {
        name: machinePairManualPostViewModel.name || '',
        manufacturer: machinePairManualPostViewModel.manufacturer,
        manufacturerModel: machinePairManualPostViewModel.manufacturerModel,
        machineType: machinePairManualPostViewModel.type,
        serialNumber: machinePairManualPostViewModel.serialNumber,
        // drumSize: machinePairManualPostViewModel.drumSize,
      },
    }
  }
  const result: MachinesPairingBody = {
    pairingId: pairingId,
    laundryId: laundryRef?.id || '',
    type: pairingType,
    prefilledInfo: prefilledInfo,
    manualInfo: manualInfo,
  }

  return result
}
