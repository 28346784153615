/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { RecurringMachineReservation } from './RecurringMachineReservation';
import {
    RecurringMachineReservationFromJSON,
    RecurringMachineReservationFromJSONTyped,
    RecurringMachineReservationToJSON,
} from './RecurringMachineReservation';

/**
 * 
 * @export
 * @interface PageableRecurringMachineReservations
 */
export interface PageableRecurringMachineReservations {
    /**
     * 
     * @type {Array<RecurringMachineReservation>}
     * @memberof PageableRecurringMachineReservations
     */
    result: Array<RecurringMachineReservation>;
    /**
     * 
     * @type {Page}
     * @memberof PageableRecurringMachineReservations
     */
    page: Page;
}

/**
 * Check if a given object implements the PageableRecurringMachineReservations interface.
 */
export function instanceOfPageableRecurringMachineReservations(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageableRecurringMachineReservationsFromJSON(json: any): PageableRecurringMachineReservations {
    return PageableRecurringMachineReservationsFromJSONTyped(json, false);
}

export function PageableRecurringMachineReservationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableRecurringMachineReservations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(RecurringMachineReservationFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageableRecurringMachineReservationsToJSON(value?: PageableRecurringMachineReservations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(RecurringMachineReservationToJSON)),
        'page': PageToJSON(value.page),
    };
}

