import React, { ReactElement } from 'react'
import { Box, Grid } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { SearchTextField } from 'src/ui-shared/base/form/control/SearchTextField'
import { useTableStyles } from 'src/ui-shared/table/Table.style'

interface Props {
  inputSearchValue: string
  handleSearch: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
}

export const TableSearchForm: React.FC<Props> = ({ inputSearchValue, handleSearch }): ReactElement => {
  const translate = useTranslate()
  const { classes: tableClasses } = useTableStyles()

  return (
    <Grid container direction="column">
      <Grid container className={tableClasses.gridSearch}>
        <Box className={tableClasses.searchBar}>
          <SearchTextField
            onChange={handleSearch}
            value={inputSearchValue}
            placeholder={translate('search.placeholder')}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
