import React, { ReactElement } from 'react'
import { TableCell, TableRow } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useTableStyles } from 'src/ui-shared/table/Table.style'

interface Props {
  colspan: number
}

export const TableEmpty: React.FC<Props> = ({ colspan }): ReactElement => {
  const translate = useTranslate()
  const { classes: tableClasses } = useTableStyles()
  return (
    <TableRow>
      <TableCell className={tableClasses.noData} colSpan={colspan}>
        {translate('table.noDataFound')}
      </TableCell>
    </TableRow>
  )
}
