import React, { ReactElement } from 'react'
import { Control, Controller, ControllerFieldState, ControllerRenderProps, FieldValues, Path } from 'react-hook-form'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material'

interface CheckboxHookFormProps<T extends FieldValues, FieldPropertyName extends Path<T>> {
  name: FieldPropertyName
  control: Control<T, any>
  label: string
  disabled?: boolean
  hintText?: string
  onChangeEventListener?: (field: ControllerRenderProps<T, FieldPropertyName>, fieldState: ControllerFieldState) => void
}

export const CheckboxHookForm = <T extends FieldValues, FieldPropertyName extends Path<T>>({
  control,
  name,
  label,
  disabled,
  hintText,
  onChangeEventListener,
}: CheckboxHookFormProps<T, FieldPropertyName>): ReactElement => {
  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<T, FieldPropertyName>,
    fieldState: ControllerFieldState,
  ) => {
    field.onChange(event.target.checked)

    if (onChangeEventListener) {
      onChangeEventListener(field, fieldState)
    }
  }

  return (
    <>
      <FormControl disabled={disabled} component="fieldset">
        <FormGroup>
          <FormControlLabel
            disabled={disabled}
            control={
              <Controller
                name={name}
                control={control}
                render={({ field, fieldState }) => (
                  <Checkbox
                    {...field}
                    disabled={disabled}
                    checked={Boolean(field.value)}
                    onChange={(e) => handleOnChange(e, field, fieldState)}
                  />
                )}
              />
            }
            label={label}
          />
        </FormGroup>
      </FormControl>
      {hintText && <FormHelperText>{hintText}</FormHelperText>}
    </>
  )
}
