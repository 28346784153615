import {
  HygieneMonitoringDetail,
  TimeseriesAnnotations,
  TimeseriesData,
  TimeseriesDataset,
  TimeseriesValueState,
} from 'src/service/backend/api'
import { filterTimeseriesDataWithZeroValue } from 'src/service/view-model/base/chart/ChartSeries.utils'
import { APEX_CHART_TITLE_STYLE, NO_DECIMAL_FORMATTER } from 'src/ui-shared/chart/ChartSeries.const'

export const getHygieneWaterChartYAxis = (
  datasets: Array<TimeseriesDataset>,
  combined: boolean,
): ApexYAxis[] | undefined => {
  // datasets
  const waterTempDataset = datasets[0]
  // const waterTempSetpointDataset = datasets[1]
  const waterLevelDataset = datasets[2]
  // const waterLevelSetpointDataset = datasets[3]
  const rpmDataset = datasets.length > 5 ? datasets[4] : undefined
  // const rpmSetpointDataset = datasets[5]

  const waterTempLabel = waterTempDataset.label
  const waterLevelLabel = waterLevelDataset.label
  const rmpLabel = rpmDataset?.label

  const waterTempAxis: ApexYAxis = {
    seriesName: waterTempLabel,
    min: 0,
    // max for temperature 100 degrees
    max: (max) => (max > 100 ? max : 100),
    labels: {
      formatter: NO_DECIMAL_FORMATTER,
    },
    title: {
      text: waterTempLabel,
      style: APEX_CHART_TITLE_STYLE,
    },
  }

  const waterLevelAxis: ApexYAxis = {
    seriesName: waterLevelLabel,
    opposite: true,
    labels: {
      formatter: NO_DECIMAL_FORMATTER,
    },
    title: {
      text: waterLevelLabel,
      style: APEX_CHART_TITLE_STYLE,
    },
  }

  const yAxis: ApexYAxis[] = [
    // axis 1, temperature
    { ...waterTempAxis, show: false },

    // axis 2, temperature setpoint
    waterTempAxis,

    // axis 3, mm
    {
      ...waterLevelAxis,
      show: false,
    },

    // axis 4, mm setpoint
    waterLevelAxis,
  ]

  if (combined) {
    const rmpAxis: ApexYAxis = {
      seriesName: rmpLabel,
      opposite: true,
      labels: {
        formatter: NO_DECIMAL_FORMATTER,
      },
      title: {
        text: rmpLabel,
        style: APEX_CHART_TITLE_STYLE,
      },
    }

    const yAxisAdditional: ApexYAxis[] = [
      // axis 5, rpm
      {
        ...rmpAxis,
        show: false,
      },

      // axis 6, rpm setpoint
      rmpAxis,
    ]

    yAxis.push(yAxisAdditional[0])
    yAxis.push(yAxisAdditional[1])

    // relay axis
    const relayAxis: ApexYAxis = {
      seriesName: 'relay',
      opposite: true,
      min: 0,
      // the relays have value 0 or 1, show them at 10% of the vertical height of the chart
      max: (max) => (max > 10 ? max : 10),
      show: false,
      labels: {
        formatter: NO_DECIMAL_FORMATTER,
      },
      title: {
        text: 'On  / Off',
        style: APEX_CHART_TITLE_STYLE,
      },
    }

    for (let i = 0; i < datasets.length - 6; i++) {
      yAxis.push(relayAxis)
    }
  }

  return yAxis
}

/**
 * Combine charts, by specified indexes passed as value, into multiple or one chart
 * @param hygiene the HygieneMonitoringDetail
 * @param chartIndexToCombine list of chart positions which should be combined, if none are passed, combine all charts into one
 * @returns TimeseriesData data array from combined charts
 */
export const combineHygieneCharts = (
  hygiene: HygieneMonitoringDetail | null,
  chartIndexToCombine?: number[],
): TimeseriesData[] => {
  // check if backend response is received
  if (!hygiene) {
    return []
  }

  const timeSeriesData = hygiene.data

  // if combinedChartPositions is empty or undefined, combine all charts into one
  const timeseriesToCombine =
    chartIndexToCombine && chartIndexToCombine.length > 0
      ? chartIndexToCombine
      : timeSeriesData.map((_, position) => position)

  // can not combine if passed list of positions is larger than time series available in hygiene
  if (timeSeriesData.length < timeseriesToCombine.length) {
    throw new Error('Invalid value for hygiene charts to combine')
  }

  // combine all the charts passed as positions
  let combinedTitle = ''
  let combinedDatasets: TimeseriesDataset[] = []
  let combinedValueStates: TimeseriesValueState[] = []
  let combinedAnnotations: TimeseriesAnnotations | undefined = undefined

  timeseriesToCombine.forEach((position, index) => {
    if (position >= 0 && position <= timeseriesToCombine.length - 1) {
      const chartTimeSeriesData: TimeseriesData | undefined = timeSeriesData[position]
      const chartDatasets = chartTimeSeriesData.datasets
      const chartTitle = chartTimeSeriesData.title
      const chartValueStatues = chartTimeSeriesData.valueStates
      const chartYAxisAnnotations = chartTimeSeriesData.annotations?.yAxis ?? []

      combinedTitle += index === 0 ? chartTitle : ` - ${chartTitle}`
      combinedDatasets = combinedDatasets.concat(chartDatasets)
      combinedValueStates = combinedValueStates.concat(chartValueStatues)
      combinedAnnotations = {
        ...combinedAnnotations,
        yAxis: combinedAnnotations?.yAxis?.concat(chartYAxisAnnotations),
      }
    }
  })

  const combined: TimeseriesData = {
    title: combinedTitle,
    datasets: combinedDatasets,
    valueStates: combinedValueStates,
    annotations: combinedAnnotations,
  }

  // put combined chart as first
  const result: TimeseriesData[] = [combined]

  // add the rest of the charts
  timeSeriesData.forEach((timeSeriesData, index) => {
    if (!timeseriesToCombine.includes(index)) {
      result.push(timeSeriesData)
    }
  })

  return result
}

/**
 * Removes the datasets with only 0 values.
 * The first N TimeseriesData optionally are unchanged if the skipDatasets is used.
 *
 * @param hygiene the HygieneMonitoringDetail to filter
 * @param skipDatasets how many TimeseriesData to skip for filtering
 * @returns the original HygieneMonitoringDetail if nothing is chagned, or HygieneMonitoringDetail with less datasets than the original
 */
export const filterHygieneDatasetsWithZeroValue = (
  hygiene: HygieneMonitoringDetail,
  skipDatasets: number,
): HygieneMonitoringDetail => {
  const originalTimeseriesData = hygiene.data

  if (skipDatasets >= originalTimeseriesData.length) {
    console.warn('skipDatasets=' + skipDatasets + ', received: ', originalTimeseriesData.length)
    return hygiene
  }

  // skip the first N timeseries data
  const originalTimeseriesDataN = originalTimeseriesData.slice(skipDatasets)

  // filter the timeseries data
  const modifiedTimeseriesDataN = filterTimeseriesDataWithZeroValue(originalTimeseriesDataN)

  // if nothing was filtered, return original
  if (originalTimeseriesDataN === modifiedTimeseriesDataN) {
    return hygiene
  }

  // combine the first N timeseries data with the filtered ones
  const modifiedData: TimeseriesData[] = originalTimeseriesData.slice(0, skipDatasets)
  modifiedData.push(...modifiedTimeseriesDataN)

  const result: HygieneMonitoringDetail = {
    ...hygiene,
    data: modifiedData,
  }

  return result
}
