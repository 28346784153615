import { AppId } from 'src/app/AppId'
import { AppId as ApiAppId } from 'src/service/backend/api'

export function mapAppIdFromApiToInternal(appIdFromApi: ApiAppId | undefined): AppId | undefined {
  if (appIdFromApi === ApiAppId.WM_WEB) {
    return AppId.WASH_MASTER
  } else if (appIdFromApi === ApiAppId.SM_WEB) {
    return AppId.SERVICE_MASTER
  } else if (appIdFromApi === ApiAppId.ES_WEB) {
    return AppId.EASY_SET
  } else if (appIdFromApi === undefined) {
    return undefined
  } else {
    console.warn('Unhandled AppId from API', appIdFromApi)
    return undefined
  }
}

export function mapInternalAppIdToApi(appId: AppId | undefined): ApiAppId | undefined {
  if (appId === AppId.WASH_MASTER) {
    return ApiAppId.WM_WEB
  } else if (appId === AppId.SERVICE_MASTER) {
    return ApiAppId.SM_WEB
  } else if (appId === AppId.EASY_SET) {
    return ApiAppId.ES_WEB
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (appId === undefined) {
    return undefined
  } else {
    console.warn('Unhandled internal AppId ', appId)
    return undefined
  }
}
