import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useNavigate, useParams } from 'react-router'
import { Alert, Box, Button, Chip, Divider, Grid, Paper } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  LaundryGroupReference,
  LaundryGroupsApi,
  LaundryReference,
  LaundryUser,
  LaundryUsersApi,
} from 'src/service/backend/api'
import { HttpResponseError } from 'src/service/backend/error/HttpResponseError'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  LaundryUserViewModel,
  RFIDCardViewModel,
  createLaundryUserViewModel,
  mapLaundryUserViewModelToLaundryUser,
} from 'src/service/view-model/laundry-user/LaundryUserViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { MultipleChoiceAutoCompleteValidate } from 'src/ui-shared/base/form/control/MultipleChoiceAutoCompleteValidate'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import {
  composeValidators,
  required,
  validEmail,
  validIban,
  validIbanChecksum,
  validPhoneNumber,
  validRFIDCard,
} from 'src/ui-shared/base/form/validation/Validators'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { usePrompt } from 'src/ui-shared/base/hooks/usePrompt'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { AddressSectionEdit } from 'src/ui-shared/form/address-section/AddressSectionEdit'
import { LocaleAutoCompleteValidate } from 'src/ui-shared/form/control/LocaleAutoCompleteValidate'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryUserDetailsTab } from 'src/ui/page/wm/laundry-user/details/LaundryUserDetailsTab'
import { isAdmin, isOperator } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const LaundryUserCreatePage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()

  const user = useUser()

  const translate = useTranslate()
  const navigate = useNavigate()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const { laundryUserId } = useParams()
  const editMode = !!laundryUserId

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryUsersApi = new LaundryUsersApi(httpConfiguration)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)

  const [loading, setLoading] = useState(false)
  const [laundryUserViewModel, setLaundryUserViewModel] = useState<LaundryUserViewModel>(createLaundryUserViewModel())
  const [laundryGroupsReferences, setLaundryGroupsReferences] = useState<LaundryGroupReference[]>([])

  const [currentRFIDCards, setCurrentRFIDCards] = useState<RFIDCardViewModel[]>([])
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [rfidsTouched, setRfidsTouched] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  const [laundryGroupEditedWarningModal, setLaundryGroupEditedWarningModal] = useState<boolean>(false)

  let originalLaundryGroups: LaundryReference[] | undefined = undefined

  const [stagedViewModel, setStagedViewModel] = useState<LaundryUserViewModel | undefined>(undefined)

  // derived state
  const pageTitle =
    laundryUserViewModel.firstName !== ''
      ? `${laundryUserViewModel.firstName} ${laundryUserViewModel.lastName}`
      : translate('createLaundryUser')

  const isAddressEditable: boolean = laundryUserViewModel.editable || !editMode

  useHotKeysForm()

  usePrompt(translate('notification.form.unsaved.changes'), dirty)

  useEffect(() => {
    if (editMode) {
      laundryUsersApi
        .laundryusersLaundryUserIdGet(laundryUserId!)
        .then((laundryUser) => {
          const laundryUserViewModel = createLaundryUserViewModel(laundryUser)
          originalLaundryGroups = { ...laundryUserViewModel.assignedLaundryGroups }
          setCurrentRFIDCards(laundryUserViewModel.assignedRFIDCards)
          setLaundryUserViewModel(laundryUserViewModel)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
        })
    }
    laundryGroupsApi
      .laundrygroupsRefGet()
      .then((data) => {
        setLaundryGroupsReferences(data)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }, [editMode, laundryUserId])

  const onSubmitSuccess = () => {
    setDirty(false)
    setLoading(false)
    navigateBack()
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  const updateOrInsert = (laundryUser: LaundryUser) => {
    if (editMode) {
      return laundryUsersApi.laundryusersLaundryUserIdPut(laundryUserId!, laundryUser)
    } else {
      return laundryUsersApi.laundryusersPost(laundryUser)
    }
  }

  const submitForm = async (viewModel: LaundryUserViewModel) => {
    if (!isAdmin(user) && isOperator(user)) {
      const assignedLaundryGroups: LaundryReference[] = viewModel.assignedLaundryGroups
      // if the operator has removed laundry groups from the user show warning
      if (
        assignedLaundryGroups.length === 0 ||
        (originalLaundryGroups && originalLaundryGroups.length > assignedLaundryGroups.length)
      ) {
        setStagedViewModel(viewModel)
        setLaundryGroupEditedWarningModal(true)
        return
      }
    }

    submitFormFinal(viewModel)
  }

  const onConfirmHandler = () => {
    setLaundryGroupEditedWarningModal(false)
    if (stagedViewModel) {
      submitFormFinal(stagedViewModel)
    }
  }

  const submitFormFinal = async (viewModel: LaundryUserViewModel) => {
    setLoading(true)
    setDirty(false)
    viewModel.assignedRFIDCards = currentRFIDCards as RFIDCardViewModel[]
    const laundryUser = mapLaundryUserViewModelToLaundryUser(viewModel)

    return updateOrInsert(laundryUser)
      .then(() => {
        onSubmitSuccess()
      })
      .catch((err) => {
        if (err instanceof HttpResponseError) {
          const httpErr = err as HttpResponseError
          const statusCode = httpErr.statusCode
          if (statusCode === 404 && stagedViewModel) {
            setDirty(false)
            setLoading(false)
            navigateToUsers()
            return
          }
        }
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  const navigateBack = () => {
    if (laundryUserId) {
      navigate(`/${appId}/laundry-users/${laundryUserId}/view/${LaundryUserDetailsTab.OVERVIEW}`)
    } else {
      navigateToUsers()
    }
  }

  const navigateToUsers = () => {
    navigate(`/${appId}/laundry-users`)
  }

  const handleOnCancel = () => {
    navigateBack()
  }

  const handleRFIDCardsChange = (event: React.SyntheticEvent, value: (string | RFIDCardViewModel)[]): void => {
    const cards: RFIDCardViewModel[] = value.map((item) => {
      if (typeof item === 'string') {
        return { id: null, cardNumber: item }
      }
      return item
    })
    setCurrentRFIDCards(cards)
    setRfidsTouched(true)
    if (!editMode && value.length === 0) {
      setRfidsTouched(false)
    }
  }

  const handleRFIDCardsBlur = (event?: React.FocusEvent<any>): void => {
    if (event && event.target && event.target.value) {
      const text: string | undefined = event.target.value.toString().trim()
      if (text && text.length === 8) {
        const foundItem = currentRFIDCards.find((item) => item.cardNumber && item.cardNumber === text)
        if (!foundItem) {
          currentRFIDCards.push({ id: null, cardNumber: text })
          setRfidsTouched(true)
        }
      }
    }
  }

  return (
    <>
      <ScreenLayout
        title={pageTitle}
        onBack={navigateBack}
        actions={<>{/* <EditButton onClick={onEdit} /> */}</>}
        actionsWidth={50}
      >
        <Paper elevation={0}>
          <LoadingIndicator loading={loading} />
          <Divider />
          {errorMessage ? (
            <ErrorMessage message={errorMessage} />
          ) : (
            <Box pt={2}>
              <Form<LaundryUserViewModel>
                initialValues={laundryUserViewModel}
                onSubmit={submitForm}
                render={({ handleSubmit, submitting, pristine }) => {
                  return (
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                        <Grid item {...ITEM_BREAKPOINTS}>
                          <TextField
                            label={translate('user.firstName')}
                            type="text"
                            name="firstName"
                            fullWidth
                            autoFocus={!editMode}
                            disabled={submitting}
                            validate={required()}
                          />
                        </Grid>
                        <Grid item {...ITEM_BREAKPOINTS}>
                          <TextField
                            label={translate('user.lastName')}
                            type="text"
                            name="lastName"
                            fullWidth
                            disabled={submitting}
                            validate={required()}
                          />
                        </Grid>
                        <Grid item {...ITEM_BREAKPOINTS}>
                          <TextField
                            label={translate('user.email')}
                            type="text"
                            name="email"
                            fullWidth
                            disabled={submitting}
                            validate={composeValidators(required(), validEmail())}
                          />
                        </Grid>
                        <Grid item {...ITEM_BREAKPOINTS}>
                          <TextField
                            label={translate('laundryUser.type')}
                            type="text"
                            name="laundryUserType"
                            disabled
                          />
                        </Grid>
                        <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                          <TextField
                            label={translate('phonenumber')}
                            type="text"
                            name="phoneNumber"
                            fullWidth
                            disabled={submitting}
                            validate={validPhoneNumber()}
                          />
                        </Grid>
                        <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                          <LocaleAutoCompleteValidate name="locale" disabled={submitting} validate={required()} />
                        </Grid>
                      </Grid>

                      <Divider className={sharedClasses.Divider} />

                      <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                        {!isAddressEditable ? (
                          <Grid item md={12} lg={12}>
                            <Alert severity={'warning'}>{translate('laundryUser.nonEditable.address')}</Alert>
                          </Grid>
                        ) : null}
                        <AddressSectionEdit
                          submitting={submitting}
                          editMode={editMode}
                          disableCountryEdit
                          disabled={!isAddressEditable}
                          hideCoordinates
                          addressMandatory={false}
                          cityMandatory={false}
                        />
                      </Grid>

                      <Divider className={sharedClasses.Divider} />

                      <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                        <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                          <TextField
                            label={translate('iban')}
                            type="text"
                            name="ibanNumber"
                            fullWidth
                            disabled={submitting}
                            validate={composeValidators(validIban(), validIbanChecksum())}
                          />
                        </Grid>
                        <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                          <TextField
                            label={translate('ibanHolderName')}
                            type="text"
                            name="ibanHolderName"
                            fullWidth
                            disabled={submitting}
                          />
                        </Grid>

                        <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                          <Divider className={sharedClasses.Divider} />
                        </Grid>

                        <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                          <Autocomplete
                            onChange={handleRFIDCardsChange}
                            onBlur={handleRFIDCardsBlur}
                            multiple={true}
                            freeSolo={true}
                            value={currentRFIDCards}
                            options={currentRFIDCards}
                            getOptionLabel={() => ''}
                            open={false}
                            clearOnBlur={true}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                const label = option.cardNumber ? option.cardNumber : option.toString()
                                return <Chip variant="outlined" label={label} {...getTagProps({ index })} key={label} />
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="assignedRFIDCards"
                                label={translate('rfids')}
                                placeholder={translate('rfids')}
                                validate={validRFIDCard()}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                          <MultipleChoiceAutoCompleteValidate
                            label={translate('laundryGroups')}
                            options={laundryGroupsReferences}
                            name="assignedLaundryGroups"
                            labelFieldName="name"
                            disabled={submitting}
                          />
                        </Grid>
                      </Grid>
                      <Divider className={sharedClasses.Divider} />
                      <Box pt={2} display="flex" justifyContent="flex-end">
                        <Button
                          id="cancelButton"
                          variant="text"
                          color="primary"
                          size="large"
                          className={sharedClasses.ButtonMargin}
                          onClick={handleOnCancel}
                        >
                          {translate('button.cancel')}
                        </Button>

                        <Button
                          id="submitButton"
                          disabled={submitting || (pristine && !rfidsTouched)}
                          variant="contained"
                          size="large"
                          color="primary"
                          type="submit"
                        >
                          {translate('button.save')}
                        </Button>
                      </Box>
                      <FormSpy
                        subscription={{ dirty: true }}
                        onChange={(props) => {
                          setTimeout(() => setDirty(props.dirty), 0)
                        }}
                      />
                    </form>
                  )
                }}
              />
            </Box>
          )}
        </Paper>

        <ConfirmationModalDialog
          titleKey="warning"
          confirmationKey="button.save"
          open={laundryGroupEditedWarningModal}
          onConfirm={onConfirmHandler}
          onCancel={() => setLaundryGroupEditedWarningModal(false)}
        >
          {translate('laundryGroupEditedWarning')}
        </ConfirmationModalDialog>
      </ScreenLayout>
    </>
  )
}
