import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Resolver, SubmitHandler, useFieldArray, useForm, useFormState } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { Alert, AlertColor, Box, Button, Divider, Grid, Paper } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, MachineParameters, MachinesApi, Permission, SettingField } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  SettingWithExpandableGroups,
  flattenGroups,
  updateSettingFieldsWithValues,
} from 'src/service/view-model/setting-field/SettingExpandableGroupsUtils'
import {
  getNameForDynamicFieldTypeDefaultImplementation,
  validateDynamicFields,
} from 'src/service/view-model/setting-field/SettingFieldValidation'
import { modifyGroupFieldsAddDefaultHiddenValue } from 'src/service/view-model/setting-field/SettingVisibilityDefaultUtils'
import { hideOrShowGroupsForAllSelectOrBooleanFields } from 'src/service/view-model/setting-field/SettingVisibilityUtils'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { HookForm } from 'src/ui-shared/base/form/control-hook-form/HookForm'
import { DynamicSettingFieldsHookForm } from 'src/ui-shared/base/form/dynamic-setting-form/DynamicSettingFieldsHookForm'
import {
  SETTING_FIELDS_WRAPPER_FIELDS_PATH,
  SettingFieldsWrapper,
} from 'src/ui-shared/base/form/dynamic-setting-form/SettingFieldsWrapper'
import { HookFormFieldError } from 'src/ui-shared/base/form/validation/HookFormFieldError'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

const machineParametersValidationResolver: Resolver<SettingFieldsWrapper> = (values, _context, { names }) => {
  let errors: Record<string, HookFormFieldError> = {}
  if (names) {
    // @ts-ignore
    errors = { ...errors, ...validateDynamicFields(values.fields, getNameForDynamicFieldTypeDefaultImplementation, '') }
  }

  return { values, errors }
}

export const MachineParametersEditPage = (): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  const appId = useAppId()
  const user = useUser()

  const navigate = useNavigate()
  const showSnackbar = useShowSnackbar()

  const { machineId } = useRequiredParams(['machineId'])

  const httpConfiguration: Configuration = useContext(HttpContext)
  const machinesApi = new MachinesApi(httpConfiguration)

  // form
  const settingsForm = useForm<SettingFieldsWrapper>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: machineParametersValidationResolver,
  })
  const prefix = SETTING_FIELDS_WRAPPER_FIELDS_PATH
  const { update } = useFieldArray({
    control: settingsForm.control,
    name: prefix,
  })

  // state
  const [machineParameters, setMachineParameters] = useState<MachineParameters | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error')
  const { isDirty } = useFormState({
    control: settingsForm.control,
  })

  const hasEditPermission = hasPermission(user, Permission.MACHINE_WRITE_PARAMETERS)

  // load data
  useEffect(() => {
    setLoading(true)
    setErrorMessage(null)

    machinesApi
      .machinesMachineIdParametersGet(machineId!)
      .then((data) => {
        setLoading(false)
        setMachineParameters(data)
      })
      .catch((err) => {
        setLoading(false)
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setAlertSeverity('error')
      })
  }, [machineId])

  // load form after machineParameters is loaded
  useEffect(() => {
    const fields = machineParameters ? machineParameters.fields : undefined

    if (fields) {
      // clone the fields to prevent modification
      const clonedFields: SettingField[] = JSON.parse(JSON.stringify(fields))
      setFieldsInForm(clonedFields)
    }
  }, [machineParameters])

  const setFieldsInForm = (fields: SettingField[]) => {
    const flatFields = flattenGroups(fields)

    const newFormValues: SettingFieldsWrapper = { fields: flatFields }
    modifyGroupFieldsAddDefaultHiddenValue(flatFields)
    hideOrShowGroupsForAllSelectOrBooleanFields(flatFields)
    settingsForm.reset(newFormValues)
  }

  useHotKeysForm()
  // usePrompt(translate('notification.form.unsaved.changes'), isDirty)

  const checkIfExpandedGroupFieldsHaveError = (expandedGroupFields?: SettingWithExpandableGroups[]) => {
    const fieldIndexes = expandedGroupFields?.map((item) => item.fieldIndex) ?? []
    return fieldIndexes.some((fieldIndex) => settingsForm.getFieldState(`${prefix}.${fieldIndex}`).error)
  }

  // events
  const navigateBack = () => {
    navigate(`/${appId}/machines/${machineId}/view/${MachineTab.PARAMETERS}`)
  }

  const resetDirtyForm = () => {
    settingsForm.reset(undefined, {
      keepDirty: false,
      keepValues: true,
      keepIsSubmitted: true,
      keepSubmitCount: true,
    })
  }

  const onSubmit: SubmitHandler<SettingFieldsWrapper> = async (data) => {
    if (!machineParameters) {
      return
    }
    if (!hasEditPermission) {
      return
    }

    setLoading(true)

    updateSettingFieldsWithValues(machineParameters.fields, data.fields)

    const newMachineParameters: MachineParameters = {
      machine: machineParameters.machine,
      fields: machineParameters.fields,
    }

    machinesApi
      .machinesMachineIdParametersPut(machineId, newMachineParameters)
      .then(() => {
        onSubmitSuccess()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  const onSubmitSuccess = () => {
    setLoading(false)
    resetDirtyForm()
    showSnackbar(translate('machineParametersSaved'), 'success')
    setTimeout(() => navigateBack(), 500)
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  const renderTitle = (): string => {
    let title = ''
    if (machineParameters?.machine.name) {
      title = `${machineParameters.machine.name} ${translate('parametersConfiguration')}`
    } else {
      title = translate('parametersConfiguration')
    }
    return title
  }

  return (
    <ScreenLayout title={renderTitle()} onBack={navigateBack} actionsWidth={50}>
      <Paper elevation={0}>
        <LoadingIndicator loading={loading} />
        <Divider />
        <Box pt={2}>
          {errorMessage ? (
            <ErrorMessage severity={alertSeverity} message={errorMessage} />
          ) : (
            <HookForm onSubmit={settingsForm.handleSubmit(onSubmit)}>
              <Box pt={2}>
                <Alert severity="info">{translate('machineParametersInfo')}</Alert>
                <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                  {machineParameters ? (
                    <DynamicSettingFieldsHookForm
                      fields={settingsForm.getValues().fields}
                      formContext={{
                        form: settingsForm,
                        formDisabled: loading,
                        loading: loading,
                        prefix: prefix,
                      }}
                      update={update}
                      checkIfExpandedGroupFieldsHaveError={checkIfExpandedGroupFieldsHaveError}
                    />
                  ) : null}

                  {/* <Grid item>
                    <pre>{JSON.stringify(settingsForm, null, 2)}</pre>
                  </Grid> */}
                </Grid>
                <Divider className={sharedClasses.Divider} />
                <Box mt={3} display="flex" justifyContent="flex-end">
                  <Button
                    id="cancelButton"
                    variant="text"
                    color="primary"
                    size="large"
                    className={sharedClasses.ButtonMargin}
                    onClick={navigateBack}
                  >
                    {translate('button.cancel')}
                  </Button>
                  <Button
                    id="submitButton"
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={!isDirty}
                  >
                    {translate('button.save')}
                  </Button>
                </Box>
              </Box>
            </HookForm>
          )}
        </Box>
      </Paper>
    </ScreenLayout>
  )
}
