import React, { FC, ReactElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Divider, Grid, Link, Typography, styled } from '@mui/material'
import { ErrorSeverity, NotificationAction, NotificationState } from 'src/service/backend/api'
import { getRelativeTimeFromNow } from 'src/service/utils/DateFormatUtils'
import { ErrorSeverityIcon } from 'src/ui-shared/error-severity/ErrorSeverityIcon'
import { useUserLocale } from 'src/user/UserContext'

interface Props {
  title: string
  message: string
  action?: NotificationAction
  status: NotificationState
  severity: ErrorSeverity
  date: Date
  menuItem?: boolean
  first?: boolean
  afterActionClick?: () => void
}

const NotificationBody = styled(Grid)<{
  read: number
}>(({ read, theme }) => ({
  backgroundColor: read ? '' : theme.palette.menu.backgroundColor,
  borderLeft: read ? '' : '3px solid',
  borderLeftColor: read ? '' : theme.palette.primary.main,
}))

const NotificationHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexGrow: 1,
  paddingBottom: theme.spacing(1),
}))

const NotificationIcon = styled(Box)<{
  read: number
}>(({ read, theme }) => ({
  paddingRight: theme.spacing(1),
  display: 'flex',
  alignSelf: 'center',
  justifyContent: 'center',
  '& .MuiSvgIcon-root': {
    opacity: read ? 0.6 : 0.8,
  },
}))

const NotificationTitle = styled(Box)(() => ({
  flexGrow: 1,
  alignSelf: 'start',
}))

const NotificationTime = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  flexShrink: 1,
  display: 'flex',
  alignSelf: 'start',
  justifyContent: 'end',
}))

const NotificationTitleTypography = styled(Typography)<{
  read: number
}>(({ read }) => ({
  lineHeight: 1.2,
  textAlign: 'left',
  wordSpacing: 1.2,
  fontWeight: 600,
}))

export const NotificationItem: FC<Props> = ({
  title,
  message,
  action,
  status,
  severity,
  date,
  menuItem,
  first,
  afterActionClick,
}): ReactElement => {
  const locale = useUserLocale()
  const read: boolean = status === NotificationState.READ

  const url = action?.url
  const urlLowercase = url?.toLowerCase()
  const externalLink = urlLowercase && (urlLowercase.startsWith('http://') || urlLowercase.startsWith('https://'))

  // do not open links in new tab if it is the same base URL
  const host = window.location.origin
  const sameTab = urlLowercase && host && urlLowercase.startsWith(host.toLowerCase())

  return (
    <NotificationBody read={read ? 1 : 0}>
      <Divider sx={{ height: 2 }} />
      <Box pr={2} pl={menuItem || !read ? 2 : 0} pt={!menuItem && !first ? 1 : 0}>
        <Box pt={menuItem ? 1.5 : 2} pb={menuItem ? 1.5 : 2}>
          <NotificationHeader>
            <NotificationIcon read={read ? 1 : 0}>
              <ErrorSeverityIcon errorSeverity={severity} />
            </NotificationIcon>

            <NotificationTitle>
              <NotificationTitleTypography read={read ? 1 : 0} variant={'subtitle1'}>
                {title}
              </NotificationTitleTypography>
            </NotificationTitle>

            <NotificationTime>
              <NotificationTitleTypography read={read ? 1 : 0} variant={'caption'}>
                {getRelativeTimeFromNow(locale, date)}
              </NotificationTitleTypography>
            </NotificationTime>
          </NotificationHeader>
          <Typography>{message}</Typography>
          {action ? (
            <Typography pt={menuItem ? 0 : 1}>
              {externalLink ? (
                <Link color="primary" underline="hover" href={action.url} target={sameTab ? undefined : 'blank'}>
                  {action.label}
                </Link>
              ) : (
                <Link
                  color="primary"
                  component={RouterLink}
                  underline="hover"
                  to={action.url}
                  onClick={() => {
                    if (afterActionClick) {
                      afterActionClick()
                    }
                  }}
                >
                  {action.label}
                </Link>
              )}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </NotificationBody>
  )
}
