/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimeseriesValue } from './TimeseriesValue';
import {
    TimeseriesValueFromJSON,
    TimeseriesValueFromJSONTyped,
    TimeseriesValueToJSON,
} from './TimeseriesValue';
import type { TimeseriesValueType } from './TimeseriesValueType';
import {
    TimeseriesValueTypeFromJSON,
    TimeseriesValueTypeFromJSONTyped,
    TimeseriesValueTypeToJSON,
} from './TimeseriesValueType';

/**
 * 
 * @export
 * @interface TimeseriesDataset
 */
export interface TimeseriesDataset {
    /**
     * dataset label
     * @type {string}
     * @memberof TimeseriesDataset
     */
    label: string;
    /**
     * contains an optional fact unit for values
     * @type {string}
     * @memberof TimeseriesDataset
     */
    unit?: string | null;
    /**
     * 
     * @type {TimeseriesValueType}
     * @memberof TimeseriesDataset
     */
    valueType: TimeseriesValueType;
    /**
     * contains the fact values
     * @type {Array<TimeseriesValue>}
     * @memberof TimeseriesDataset
     */
    values: Array<TimeseriesValue>;
}

/**
 * Check if a given object implements the TimeseriesDataset interface.
 */
export function instanceOfTimeseriesDataset(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "valueType" in value;
    isInstance = isInstance && "values" in value;

    return isInstance;
}

export function TimeseriesDatasetFromJSON(json: any): TimeseriesDataset {
    return TimeseriesDatasetFromJSONTyped(json, false);
}

export function TimeseriesDatasetFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeseriesDataset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'valueType': TimeseriesValueTypeFromJSON(json['valueType']),
        'values': ((json['values'] as Array<any>).map(TimeseriesValueFromJSON)),
    };
}

export function TimeseriesDatasetToJSON(value?: TimeseriesDataset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'unit': value.unit,
        'valueType': TimeseriesValueTypeToJSON(value.valueType),
        'values': ((value.values as Array<any>).map(TimeseriesValueToJSON)),
    };
}

