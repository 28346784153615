import { useEffect, useRef } from 'react'

export function useInterval(callback: () => void, delay: number): void {
  const savedCallback = useRef<() => void>(() => {})

  // remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}
