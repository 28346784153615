/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DatatransPaymentMethod {
    ACC = 'ACC',
    ALP = 'ALP',
    APL = 'APL',
    AMX = 'AMX',
    AZP = 'AZP',
    BON = 'BON',
    CFY = 'CFY',
    CSY = 'CSY',
    CUP = 'CUP',
    DIN = 'DIN',
    DII = 'DII',
    DIB = 'DIB',
    DIS = 'DIS',
    DNK = 'DNK',
    ECA = 'ECA',
    ELV = 'ELV',
    EPS = 'EPS',
    ESY = 'ESY',
    INT = 'INT',
    JCB = 'JCB',
    JEL = 'JEL',
    KLN = 'KLN',
    MAU = 'MAU',
    MDP = 'MDP',
    MFX = 'MFX',
    MPX = 'MPX',
    MYO = 'MYO',
    PAP = 'PAP',
    PAY = 'PAY',
    PEF = 'PEF',
    PFC = 'PFC',
    PSC = 'PSC',
    REK = 'REK',
    SAM = 'SAM',
    SWB = 'SWB',
    SCX = 'SCX',
    SWP = 'SWP',
    TWI = 'TWI',
    UAP = 'UAP',
    VIS = 'VIS',
    WEC = 'WEC'
}


export function DatatransPaymentMethodFromJSON(json: any): DatatransPaymentMethod {
    return DatatransPaymentMethodFromJSONTyped(json, false);
}

export function DatatransPaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatatransPaymentMethod {
    return json as DatatransPaymentMethod;
}

export function DatatransPaymentMethodToJSON(value?: DatatransPaymentMethod | null): any {
    return value as any;
}

