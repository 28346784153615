/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Colors } from './Colors';
import {
    ColorsFromJSON,
    ColorsFromJSONTyped,
    ColorsToJSON,
} from './Colors';
import type { ServicePackagePlanFeature } from './ServicePackagePlanFeature';
import {
    ServicePackagePlanFeatureFromJSON,
    ServicePackagePlanFeatureFromJSONTyped,
    ServicePackagePlanFeatureToJSON,
} from './ServicePackagePlanFeature';
import type { ServicePackagePricing } from './ServicePackagePricing';
import {
    ServicePackagePricingFromJSON,
    ServicePackagePricingFromJSONTyped,
    ServicePackagePricingToJSON,
} from './ServicePackagePricing';

/**
 * 
 * @export
 * @interface ServicePackagePlan
 */
export interface ServicePackagePlan {
    /**
     * 
     * @type {string}
     * @memberof ServicePackagePlan
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ServicePackagePlan
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof ServicePackagePlan
     */
    readonly description: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServicePackagePlan
     */
    readonly legacyPlan: boolean;
    /**
     * 
     * @type {ServicePackagePricing}
     * @memberof ServicePackagePlan
     */
    pricing: ServicePackagePricing | null;
    /**
     * 
     * @type {Array<ServicePackagePlanFeature>}
     * @memberof ServicePackagePlan
     */
    features: Array<ServicePackagePlanFeature>;
    /**
     * 
     * @type {Colors}
     * @memberof ServicePackagePlan
     */
    colors: Colors | null;
}

/**
 * Check if a given object implements the ServicePackagePlan interface.
 */
export function instanceOfServicePackagePlan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "legacyPlan" in value;
    isInstance = isInstance && "pricing" in value;
    isInstance = isInstance && "features" in value;
    isInstance = isInstance && "colors" in value;

    return isInstance;
}

export function ServicePackagePlanFromJSON(json: any): ServicePackagePlan {
    return ServicePackagePlanFromJSONTyped(json, false);
}

export function ServicePackagePlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServicePackagePlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'legacyPlan': json['legacyPlan'],
        'pricing': ServicePackagePricingFromJSON(json['pricing']),
        'features': ((json['features'] as Array<any>).map(ServicePackagePlanFeatureFromJSON)),
        'colors': ColorsFromJSON(json['colors']),
    };
}

export function ServicePackagePlanToJSON(value?: ServicePackagePlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pricing': ServicePackagePricingToJSON(value.pricing),
        'features': ((value.features as Array<any>).map(ServicePackagePlanFeatureToJSON)),
        'colors': ColorsToJSON(value.colors),
    };
}

