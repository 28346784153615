import React, { ReactElement, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import {
  Configuration,
  InitOverrideFunction,
  PageablePrograms,
  ProgramType,
  ProgramsMachineApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getRemoveHeaderInit } from 'src/service/backend/http/HttpUtils'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { FileFormat } from 'src/ui-shared/constants/Constants'
import { ProgramImport } from 'src/ui-shared/program/ProgramImport'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'

export const MachineProgramImportPage = (): ReactElement => {
  const translate = useTranslate()
  const location = useLocation()
  const appId = useAppId()
  const navigate = useNavigate()

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack
  const { machineId, programTypeParam } = useRequiredParams(['machineId', 'programTypeParam'])
  const programType = getEnumFromString(programTypeParam, ProgramType)

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programsMachineApi = new ProgramsMachineApi(httpConfiguration)

  const acceptedFileFormats: FileFormat[] = ['WPB']
  const acceptedFileSizeMB = 1
  const acceptMultipleFiles = true
  const disclaimerTextForPage = `${translate(`programType.${programType.toLowerCase()}`)} ${translate('programs')}`

  const handleSubmit = (files: File[]): Promise<PageablePrograms> => {
    // delete the content type header so that the browser's defined content-type
    // based on body to add boundary value for the multipart/form-data encoding
    const initOverrides: InitOverrideFunction = getRemoveHeaderInit('Content-Type')

    return programsMachineApi
      .machinesMachineIdProgramsUploadCustomPost(machineId, files, initOverrides)
      .then((data) => {
        const importedPrograms: PageablePrograms = {
          ...data,
          result: data.result.map((item) => item.program),
        }

        return importedPrograms
      })
  }

  const handleOnCancel = () => {
    navigateBack()
  }

  const getPageTitle = (): string => {
    let pageTitle = ''
    const uploadProgramType = programType.toLowerCase()
    pageTitle = translate(`${uploadProgramType}ProgramsImport`)

    return pageTitle
  }

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/machines/${machineId}/view/${MachineTab.PROGRAMS}`)
  }

  return (
    <ScreenLayout title={getPageTitle()} onBack={navigateBack} actionsWidth={50}>
      <ProgramImport
        acceptedFileFormats={acceptedFileFormats}
        acceptedFileSizeMB={acceptedFileSizeMB}
        acceptMultipleFiles={acceptMultipleFiles}
        handleCancel={handleOnCancel}
        handleSubmit={handleSubmit}
        handleFinish={navigateBack}
        disclaimerTextFor={disclaimerTextForPage}
      />
    </ScreenLayout>
  )
}
