import React, { FC, ReactElement } from 'react'
import { ButtonProps, ClickAwayListener, Paper, Popper, Tooltip, Typography, styled } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

const StyledMenu = styled(Popper)(({ theme }) => ({
  '& .MuiPaper-root': {
    zIndex: '999999',
    boxShadow: theme.palette.menu.shadow,
    border: '1px solid #ccc',
    marginTop: theme.spacing(0.5),
    minWidth: 180,
  },
}))

export interface MenuButtonProp extends ButtonProps {
  menuTitle?: string
  disableTooltipTitle?: string
}

export const MenuButton: FC<MenuButtonProp> = ({ menuTitle, disableTooltipTitle, ...buttonProps }): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)
  // the menu items
  const children: React.ReactNode = buttonProps.children

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // replace the onClick method on each child menu item with custom one that closes the menu
  const clonedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.props.hidden) {
        return null
      }
      const originalOnClick = child.props.onClick
      return React.cloneElement(child, {
        // @ts-ignore
        onClick: () => {
          handleClose()
          originalOnClick()
        },
        className: sharedClasses.MenuItemPadding,
      })
    }
    return child
  })

  return (
    <>
      <Tooltip
        title={
          buttonProps.disabled && disableTooltipTitle && <Typography variant="body2">{disableTooltipTitle}</Typography>
        }
        placement="bottom"
      >
        <ListingButton
          variant="outlined"
          onClick={handleClick}
          {...buttonProps}
          sx={{ minWidth: 'auto', ...buttonProps.sx }}
        >
          {menuTitle ? menuTitle : <MoreHorizIcon />}
        </ListingButton>
      </Tooltip>

      <StyledMenu anchorEl={anchorEl} open={open} placement="bottom-end" disablePortal={true}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={0}>{clonedChildren}</Paper>
        </ClickAwayListener>
      </StyledMenu>
    </>
  )
}
