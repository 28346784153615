import { friendlyFormatIBAN } from 'ibantools'
import { LaundryGroupReference, LaundryReference, LaundryUser, LaundryUserType } from 'src/service/backend/api'
import { RFIDCardReference } from 'src/service/backend/api/models/RFIDCardReference'
import {
  ViewModelWithAddress,
  loadAddressViewModel,
  mapAddress,
} from 'src/service/view-model/base/address/AddressViewModel'
import { LOCALES, LocaleObject, getLocaleObject } from 'src/service/view-model/base/localization/Locales'
import { getLaundryUserType } from 'src/service/view-model/laundry-user/LaundryUserUtils'

export interface RFIDCardViewModel {
  id: string | null
  cardNumber: string
}

export interface LaundryUserViewModel extends ViewModelWithAddress {
  firstName: string
  lastName: string
  email: string
  laundryUserType?: string
  phoneNumber: string
  locale?: LocaleObject
  ibanNumber: string
  ibanHolderName: string
  esrNumber: string
  editable: boolean
  assignedLaundryGroups: LaundryReference[]
  assignedRFIDCards: RFIDCardViewModel[]
}

export const mapLaundryUserToLaundryUserViewModel = (laundryUser: LaundryUser): LaundryUserViewModel => {
  const viewModelWithAddress = loadAddressViewModel(laundryUser.address)

  const viewModel: LaundryUserViewModel = {
    ...viewModelWithAddress,
    firstName: laundryUser.firstName || '',
    lastName: laundryUser.lastName || '',
    email: laundryUser.email || '',
    laundryUserType: getLaundryUserType(laundryUser.type) || '',
    editable: laundryUser.editable || false,
    phoneNumber: laundryUser.phoneNumber || '',
    locale: getLocaleObject(laundryUser.locale) || LOCALES[0],
    ibanNumber: laundryUser.ibanNumber || '',
    ibanHolderName: laundryUser.ibanHolderName || '',
    esrNumber: laundryUser.esrNumber || '',
    assignedLaundryGroups: laundryUser.assignedLaundryGroups,
    assignedRFIDCards: mapRFIDCardReferenceToRFIDCardViewModel(laundryUser.assignedRFIDCards),
  }

  return viewModel
}

const mapRFIDCardReferenceToRFIDCardViewModel = (RFIDCards: RFIDCardReference[] | undefined): RFIDCardViewModel[] => {
  const cards = RFIDCards?.map((item) => {
    return { id: item.id, cardNumber: item.cardNumber.toString() }
  })
  return cards as RFIDCardViewModel[]
}

export const mapLaundryUserViewModelToLaundryUser = (viewModel: LaundryUserViewModel): LaundryUser => {
  const localeObject = viewModel.locale as LocaleObject | undefined
  return {
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    email: viewModel.email,
    phoneNumber: viewModel.phoneNumber || undefined,
    locale: localeObject ? localeObject.code : LOCALES[0],
    ibanNumber: viewModel.ibanNumber ? friendlyFormatIBAN(viewModel.ibanNumber) : undefined,
    ibanHolderName: viewModel.ibanHolderName || undefined,
    esrNumber: viewModel.esrNumber,
    address: mapAddress(viewModel),
    assignedLaundryGroups: viewModel.assignedLaundryGroups as LaundryGroupReference[],
    assignedRFIDCards: mapRFIDCardViewModelToRFIDCardReference(viewModel.assignedRFIDCards) as RFIDCardReference[],
  } as LaundryUser
}

const mapRFIDCardViewModelToRFIDCardReference = (RFIDCards: RFIDCardViewModel[]): RFIDCardReference[] => {
  const cards = RFIDCards.map((item) => {
    return { id: item.id, cardNumber: parseInt(item.cardNumber) }
  })
  return cards as RFIDCardReference[]
}

export const createLaundryUserViewModel = (laundryUser?: LaundryUser): LaundryUserViewModel => {
  if (laundryUser) {
    return mapLaundryUserToLaundryUserViewModel(laundryUser)
  }
  return {
    firstName: '',
    lastName: '',
    email: '',
    laundryUserType: getLaundryUserType(LaundryUserType.CARD_ONLY_USER),
    editable: true,
    phoneNumber: '',
    locale: undefined,
    ibanNumber: '',
    ibanHolderName: '',
    esrNumber: '',
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    city: '',
    latitude: '',
    longitude: '',
    countryCode: undefined,
    assignedLaundryGroups: [],
    assignedRFIDCards: [],
  }
}
