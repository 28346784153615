/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LaundryGroupServicemaster
 */
export interface LaundryGroupServicemaster {
    /**
     * 
     * @type {boolean}
     * @memberof LaundryGroupServicemaster
     */
    directCareEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LaundryGroupServicemaster
     */
    customProgramsEnabled: boolean;
    /**
     * Operator can view program reports
     * @type {boolean}
     * @memberof LaundryGroupServicemaster
     */
    usageReportsEnabled: boolean;
    /**
     * Operator can view utilization reports
     * @type {boolean}
     * @memberof LaundryGroupServicemaster
     */
    utilizationReportsEnabled: boolean;
    /**
     * Operator can fetch hygiene reports
     * @type {boolean}
     * @memberof LaundryGroupServicemaster
     */
    hygieneMonitoringEnabled: boolean;
    /**
     * Laundry Group Currency / m^3
     * @type {number}
     * @memberof LaundryGroupServicemaster
     */
    priceWater: number;
    /**
     * Laundry Group Currency / kWh
     * @type {number}
     * @memberof LaundryGroupServicemaster
     */
    priceElectricity: number;
}

/**
 * Check if a given object implements the LaundryGroupServicemaster interface.
 */
export function instanceOfLaundryGroupServicemaster(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "directCareEnabled" in value;
    isInstance = isInstance && "customProgramsEnabled" in value;
    isInstance = isInstance && "usageReportsEnabled" in value;
    isInstance = isInstance && "utilizationReportsEnabled" in value;
    isInstance = isInstance && "hygieneMonitoringEnabled" in value;
    isInstance = isInstance && "priceWater" in value;
    isInstance = isInstance && "priceElectricity" in value;

    return isInstance;
}

export function LaundryGroupServicemasterFromJSON(json: any): LaundryGroupServicemaster {
    return LaundryGroupServicemasterFromJSONTyped(json, false);
}

export function LaundryGroupServicemasterFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupServicemaster {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'directCareEnabled': json['directCareEnabled'],
        'customProgramsEnabled': json['customProgramsEnabled'],
        'usageReportsEnabled': json['usageReportsEnabled'],
        'utilizationReportsEnabled': json['utilizationReportsEnabled'],
        'hygieneMonitoringEnabled': json['hygieneMonitoringEnabled'],
        'priceWater': json['priceWater'],
        'priceElectricity': json['priceElectricity'],
    };
}

export function LaundryGroupServicemasterToJSON(value?: LaundryGroupServicemaster | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'directCareEnabled': value.directCareEnabled,
        'customProgramsEnabled': value.customProgramsEnabled,
        'usageReportsEnabled': value.usageReportsEnabled,
        'utilizationReportsEnabled': value.utilizationReportsEnabled,
        'hygieneMonitoringEnabled': value.hygieneMonitoringEnabled,
        'priceWater': value.priceWater,
        'priceElectricity': value.priceElectricity,
    };
}

