import React, { ReactElement } from 'react'
import { Alert, AlertColor, AlertTitle, Box } from '@mui/material'

interface Props {
  message: string
  alertTitle?: string
  severity?: AlertColor
  hideIcon?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  action?: ReactElement
}

export const ErrorMessage: React.FC<Props> = ({
  alertTitle,
  message,
  severity,
  hideIcon,
  variant,
  action,
}): ReactElement => {
  return (
    <Box mt={2}>
      <Alert severity={severity ? severity : 'error'} variant={variant} icon={hideIcon ? false : undefined}>
        <Box display={'inline'} mr={1} style={{ whiteSpace: 'pre-line' }}>
          {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
          {message}
        </Box>
        {action}
      </Alert>
    </Box>
  )
}
