/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Currency,
  PageableTransactions,
  RefundTransaction,
  Transaction,
  TransactionStatus,
  TransactionType,
} from '../models';
import {
    CurrencyFromJSON,
    CurrencyToJSON,
    PageableTransactionsFromJSON,
    PageableTransactionsToJSON,
    RefundTransactionFromJSON,
    RefundTransactionToJSON,
    TransactionFromJSON,
    TransactionToJSON,
    TransactionStatusFromJSON,
    TransactionStatusToJSON,
    TransactionTypeFromJSON,
    TransactionTypeToJSON,
} from '../models';

export interface TransactionsGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    id?: string;
    search?: string;
    transactionType?: TransactionType;
    currency?: Currency;
    status?: TransactionStatus;
    fromAuthorizedDate?: Date;
    toAuthorizedDate?: Date;
    fromSettledDate?: Date;
    toSettledDate?: Date;
    prepaidAccountId?: string;
    backendUserId?: string;
    paymentMethodId?: string;
    externalPaymentReference?: string;
    iban?: string;
    bankingReference?: string;
    parentTransactionId?: string;
}

export interface TransactionsTransactionIdGetRequest {
    transactionId: string;
}

export interface TransactionsTransactionIdRefundPostRequest {
    transactionId: string;
    refundTransaction?: RefundTransaction;
}

/**
 * 
 */
export class TransactionsApi extends runtime.BaseAPI {

    /**
     * Get list of Transactions
     */
    async transactionsGetRaw(requestParameters: TransactionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableTransactions>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.transactionType !== undefined) {
            queryParameters['transactionType'] = requestParameters.transactionType;
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.fromAuthorizedDate !== undefined) {
            queryParameters['fromAuthorizedDate'] = this.toIsoStringLocalTimezone(requestParameters.fromAuthorizedDate as any);
        }

        if (requestParameters.toAuthorizedDate !== undefined) {
            queryParameters['toAuthorizedDate'] = this.toIsoStringLocalTimezone(requestParameters.toAuthorizedDate as any);
        }

        if (requestParameters.fromSettledDate !== undefined) {
            queryParameters['fromSettledDate'] = this.toIsoStringLocalTimezone(requestParameters.fromSettledDate as any);
        }

        if (requestParameters.toSettledDate !== undefined) {
            queryParameters['toSettledDate'] = this.toIsoStringLocalTimezone(requestParameters.toSettledDate as any);
        }

        if (requestParameters.prepaidAccountId !== undefined) {
            queryParameters['prepaidAccountId'] = requestParameters.prepaidAccountId;
        }

        if (requestParameters.backendUserId !== undefined) {
            queryParameters['backendUserId'] = requestParameters.backendUserId;
        }

        if (requestParameters.paymentMethodId !== undefined) {
            queryParameters['paymentMethodId'] = requestParameters.paymentMethodId;
        }

        if (requestParameters.externalPaymentReference !== undefined) {
            queryParameters['externalPaymentReference'] = requestParameters.externalPaymentReference;
        }

        if (requestParameters.iban !== undefined) {
            queryParameters['iban'] = requestParameters.iban;
        }

        if (requestParameters.bankingReference !== undefined) {
            queryParameters['bankingReference'] = requestParameters.bankingReference;
        }

        if (requestParameters.parentTransactionId !== undefined) {
            queryParameters['parentTransactionId'] = requestParameters.parentTransactionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableTransactionsFromJSON(jsonValue));
    }

    /**
     * Get list of Transactions
     */
    async transactionsGet(size?: number, page?: number, sort?: string, id?: string, search?: string, transactionType?: TransactionType, currency?: Currency, status?: TransactionStatus, fromAuthorizedDate?: Date, toAuthorizedDate?: Date, fromSettledDate?: Date, toSettledDate?: Date, prepaidAccountId?: string, backendUserId?: string, paymentMethodId?: string, externalPaymentReference?: string, iban?: string, bankingReference?: string, parentTransactionId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableTransactions> {
        const response = await this.transactionsGetRaw({ size: size, page: page, sort: sort, id: id, search: search, transactionType: transactionType, currency: currency, status: status, fromAuthorizedDate: fromAuthorizedDate, toAuthorizedDate: toAuthorizedDate, fromSettledDate: fromSettledDate, toSettledDate: toSettledDate, prepaidAccountId: prepaidAccountId, backendUserId: backendUserId, paymentMethodId: paymentMethodId, externalPaymentReference: externalPaymentReference, iban: iban, bankingReference: bankingReference, parentTransactionId: parentTransactionId }, initOverrides);
        return await response.value();
    }

    /**
     * Get a Transaction by transactionId
     */
    async transactionsTransactionIdGetRaw(requestParameters: TransactionsTransactionIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Transaction>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling transactionsTransactionIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transactions/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionFromJSON(jsonValue));
    }

    /**
     * Get a Transaction by transactionId
     */
    async transactionsTransactionIdGet(transactionId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Transaction> {
        const response = await this.transactionsTransactionIdGetRaw({ transactionId: transactionId }, initOverrides);
        return await response.value();
    }

    /**
     * Add a refund transaction
     */
    async transactionsTransactionIdRefundPostRaw(requestParameters: TransactionsTransactionIdRefundPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Transaction>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling transactionsTransactionIdRefundPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/transactions/{transactionId}/refund`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefundTransactionToJSON(requestParameters.refundTransaction),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionFromJSON(jsonValue));
    }

    /**
     * Add a refund transaction
     */
    async transactionsTransactionIdRefundPost(transactionId: string, refundTransaction?: RefundTransaction, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Transaction> {
        const response = await this.transactionsTransactionIdRefundPostRaw({ transactionId: transactionId, refundTransaction: refundTransaction }, initOverrides);
        return await response.value();
    }

}
