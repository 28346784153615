/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachinesPairBody
 */
export interface MachinesPairBody {
    /**
     * 
     * @type {number}
     * @memberof MachinesPairBody
     */
    pairingId: number;
    /**
     * 
     * @type {string}
     * @memberof MachinesPairBody
     */
    laundryId: string;
    /**
     * 
     * @type {string}
     * @memberof MachinesPairBody
     */
    name: string;
}

/**
 * Check if a given object implements the MachinesPairBody interface.
 */
export function instanceOfMachinesPairBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pairingId" in value;
    isInstance = isInstance && "laundryId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function MachinesPairBodyFromJSON(json: any): MachinesPairBody {
    return MachinesPairBodyFromJSONTyped(json, false);
}

export function MachinesPairBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachinesPairBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pairingId': json['pairingId'],
        'laundryId': json['laundryId'],
        'name': json['name'],
    };
}

export function MachinesPairBodyToJSON(value?: MachinesPairBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pairingId': value.pairingId,
        'laundryId': value.laundryId,
        'name': value.name,
    };
}

