import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router'
import { Edit } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Permission } from 'src/service/backend/api'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { RolePermissionListTable } from 'src/ui/page/wm/role-permission/RolePermissionListTable'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const RolePermissionListPage = (): ReactElement => {
  const translate = useTranslate()

  const navigate = useNavigate()
  const appId = useAppId()
  const user = useUser()
  const hasWritePermission = hasPermission(user, Permission.ROLE_PERMISSION_WRITE)

  const handleEditButtonClick = () => {
    navigate(`/${appId}/roles/edit`)
  }

  const createNewAction = (
    <ListingButton onClick={handleEditButtonClick} variant="outlined" color="primary" startIcon={<Edit />}>
      {translate('button.edit')}
    </ListingButton>
  )

  const actions = hasWritePermission ? createNewAction : <></>

  return (
    <ScreenLayout title={translate('rolesAndPermissions')} actions={actions}>
      <RolePermissionListTable></RolePermissionListTable>
    </ScreenLayout>
  )
}
