/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineTag } from './MachineTag';
import {
    MachineTagFromJSON,
    MachineTagFromJSONTyped,
    MachineTagToJSON,
} from './MachineTag';

/**
 * 
 * @export
 * @interface MachineWashmaster
 */
export interface MachineWashmaster {
    /**
     * 
     * @type {MachineTag}
     * @memberof MachineWashmaster
     */
    tag?: MachineTag | null;
    /**
     * 
     * @type {boolean}
     * @memberof MachineWashmaster
     */
    freeUsage: boolean;
}

/**
 * Check if a given object implements the MachineWashmaster interface.
 */
export function instanceOfMachineWashmaster(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "freeUsage" in value;

    return isInstance;
}

export function MachineWashmasterFromJSON(json: any): MachineWashmaster {
    return MachineWashmasterFromJSONTyped(json, false);
}

export function MachineWashmasterFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineWashmaster {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tag': !exists(json, 'tag') ? undefined : MachineTagFromJSON(json['tag']),
        'freeUsage': json['freeUsage'],
    };
}

export function MachineWashmasterToJSON(value?: MachineWashmaster | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tag': MachineTagToJSON(value.tag),
        'freeUsage': value.freeUsage,
    };
}

