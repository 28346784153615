/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LaundryGroupNews,
} from '../models';
import {
    LaundryGroupNewsFromJSON,
    LaundryGroupNewsToJSON,
} from '../models';

export interface LaundrygroupsLaundryGroupIdNewsGetRequest {
    laundryGroupId: string;
}

export interface LaundrygroupsLaundryGroupIdNewsPostRequest {
    laundryGroupId: string;
    laundryGroupNews?: LaundryGroupNews;
}

/**
 * 
 */
export class LaundryGroupsNewsApi extends runtime.BaseAPI {

    /**
     * Get the news message
     */
    async laundrygroupsLaundryGroupIdNewsGetRaw(requestParameters: LaundrygroupsLaundryGroupIdNewsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupNews>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdNewsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/news`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupNewsFromJSON(jsonValue));
    }

    /**
     * Get the news message
     */
    async laundrygroupsLaundryGroupIdNewsGet(laundryGroupId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupNews> {
        const response = await this.laundrygroupsLaundryGroupIdNewsGetRaw({ laundryGroupId: laundryGroupId }, initOverrides);
        return await response.value();
    }

    /**
     * Create a news message
     */
    async laundrygroupsLaundryGroupIdNewsPostRaw(requestParameters: LaundrygroupsLaundryGroupIdNewsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdNewsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/news`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryGroupNewsToJSON(requestParameters.laundryGroupNews),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a news message
     */
    async laundrygroupsLaundryGroupIdNewsPost(laundryGroupId: string, laundryGroupNews?: LaundryGroupNews, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.laundrygroupsLaundryGroupIdNewsPostRaw({ laundryGroupId: laundryGroupId, laundryGroupNews: laundryGroupNews }, initOverrides);
    }

}
