import { DashboardIssuesApi } from 'src/service/backend/api'
import { mocked } from 'src/service/backend/api-mock/MockUtils'
import {
  ChartData,
  DashboardIssuesCountList,
  IssueCommonState,
  IssuesChartGroupBy,
} from 'src/service/backend/api/models'
import { InitOverrideFunction } from 'src/service/backend/api/runtime'

const issuesJson =
  '{"title":"Requests by Type","xValues":[],"chartType":"DOUGHNUT","datasets":[{"label":"In Progress","yUnit":"Count","lineType":null,"color":"#9b91c14d","yValues":[1]},{"label":"Open","yUnit":"Count","lineType":null,"color":"#745ba3","yValues":[20]},{"label":"Requested","yUnit":"Count","lineType":null,"color":"#9b91c1","yValues":[2]}]}'

const issueCountJson = '{"data":[{"label":"Total","count":3}]}'

export class DashboardIssuesApiMock extends DashboardIssuesApi {
  dashboardSmIssuesChartGet(
    groupBy: IssuesChartGroupBy,
    createdFrom?: Date,
    createdTo?: Date,
    closedFrom?: Date,
    closedTo?: Date,
    issueCommonStates?: IssueCommonState[],
    organizationId?: string,
    initOverrides?: RequestInit | InitOverrideFunction,
  ): Promise<ChartData> {
    return mocked(JSON.parse(issuesJson)) as Promise<ChartData>
  }

  dashboardSmIssuesCountGet(
    groupBy: IssuesChartGroupBy,
    createdFrom?: Date,
    createdTo?: Date,
    closedFrom?: Date,
    closedTo?: Date,
    issueCommonStates?: IssueCommonState[],
    organizationId?: string,
    initOverrides?: RequestInit | InitOverrideFunction,
  ): Promise<DashboardIssuesCountList> {
    return mocked(JSON.parse(issueCountJson)) as Promise<DashboardIssuesCountList>
  }
}
