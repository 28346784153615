// ex: 2023-12-01
export const DATE_ISO_FORMAT = 'yyyy-MM-dd'

// ex: 05.12.2023
export const DATE_FORMAT = 'dd.MM.yyyy'
// ex: 12/05/2023
export const DATE_FORMAT_US = 'MM/dd/yyyy'

// ex: 16:30
export const TIME_FORMAT = 'HH:mm'
// ex: 04:30 PM
export const TIME_FORMAT_12 = 'hh:mm a'
// ex: 04:30PM
export const TIME_FORMAT_12_APPENDED = 'hh:mma'

// ex: 16:30:15
export const TIME_FORMAT_SECONDS = 'HH:mm:ss'
// ex: 04:30:15 PM
export const TIME_FORMAT_SECONDS_12 = 'hh:mm:ss a'

// ex: 10.12.2023 10:00
export const DATE_TIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT
// ex: 10.12.2023 10:00:50
export const DATE_TIME_FORMAT_SECONDS = DATE_FORMAT + ' ' + TIME_FORMAT_SECONDS

// ex: Monday , Tuesday etc..
export const WEEKDAY_TEXT_FORMAT = 'EEEE'

// ex: August 2023
export const MONTH_FORMAT = 'MMMM yyyy'

// ex: August
export const MONTH_ONLY_FORMAT = 'MMMM'

// ex: 2023
export const YEAR_FORMAT = 'yyyy'
