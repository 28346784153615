import React, { FC, ReactElement } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { CalendarEntry } from 'src/service/backend/api'
import { formatIsoDateStringToDayAndDate, formatTimeForLocale } from 'src/service/utils/DateFormatUtils'
import { SlotAndDay } from 'src/service/view-model/base/calendar/Calendar.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  slotAndDay: SlotAndDay | undefined
  closeModal: () => void
  selectCalendarEntryForDelete: (calendarEntry: CalendarEntry) => void
}

export const CalendarDeleteReservation: FC<Props> = ({
  slotAndDay,
  closeModal,
  selectCalendarEntryForDelete,
}): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const regionLocale = useUserRegionLocale()

  // props
  const calendarEntry = slotAndDay?.slot.calendarEntry
  const dayOfWeek = slotAndDay?.dayOfWeek ? slotAndDay.dayOfWeek : ''

  const submitForm = () => {
    closeModal()
    const calendarEntry = slotAndDay?.slot.calendarEntry
    if (calendarEntry) {
      return selectCalendarEntryForDelete(calendarEntry)
    }
  }

  const showSlotStartAndEndTime = () => {
    if (calendarEntry) {
      return `${formatTimeForLocale(calendarEntry.startTime, regionLocale)} - ${formatTimeForLocale(
        calendarEntry.endTime,
        regionLocale,
      )}`
    } else {
      return ''
    }
  }

  return (
    <Box pt={1} p={3}>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {formatIsoDateStringToDayAndDate(dayOfWeek, regionLocale, translate)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {showSlotStartAndEndTime()}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="text"
          color="primary"
          size="large"
          className={sharedClasses.ButtonMargin}
          type="submit"
          onClick={submitForm}
        >
          {translate('button.delete')}
        </Button>

        <Button variant="contained" color="primary" size="large" onClick={closeModal}>
          {translate('button.cancel')}
        </Button>
      </Box>
    </Box>
  )
}
