/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserReactionCategoryReference
 */
export interface UserReactionCategoryReference {
    /**
     * Fragment key for translation
     * @type {string}
     * @memberof UserReactionCategoryReference
     */
    key: string;
    /**
     * Name of the user reaction category
     * @type {string}
     * @memberof UserReactionCategoryReference
     */
    readonly name: string;
}

/**
 * Check if a given object implements the UserReactionCategoryReference interface.
 */
export function instanceOfUserReactionCategoryReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function UserReactionCategoryReferenceFromJSON(json: any): UserReactionCategoryReference {
    return UserReactionCategoryReferenceFromJSONTyped(json, false);
}

export function UserReactionCategoryReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserReactionCategoryReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
    };
}

export function UserReactionCategoryReferenceToJSON(value?: UserReactionCategoryReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
    };
}

