/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectivityType } from './ConnectivityType';
import {
    ConnectivityTypeFromJSON,
    ConnectivityTypeFromJSONTyped,
    ConnectivityTypeToJSON,
} from './ConnectivityType';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';

/**
 * 
 * @export
 * @interface ManualPairingInfo
 */
export interface ManualPairingInfo {
    /**
     * 
     * @type {string}
     * @memberof ManualPairingInfo
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ManualPairingInfo
     */
    readonly thingName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ManualPairingInfo
     */
    followerThingNames?: Array<string>;
    /**
     * 
     * @type {ConnectivityType}
     * @memberof ManualPairingInfo
     */
    connectivityType: ConnectivityType;
    /**
     * 
     * @type {Array<MachineType>}
     * @memberof ManualPairingInfo
     */
    availableMachineTypes: Array<MachineType>;
    /**
     * total numbers of possible primary + followers (Minimum 1 for primary)
     * @type {number}
     * @memberof ManualPairingInfo
     */
    availableMachines: number;
}

/**
 * Check if a given object implements the ManualPairingInfo interface.
 */
export function instanceOfManualPairingInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "thingName" in value;
    isInstance = isInstance && "connectivityType" in value;
    isInstance = isInstance && "availableMachineTypes" in value;
    isInstance = isInstance && "availableMachines" in value;

    return isInstance;
}

export function ManualPairingInfoFromJSON(json: any): ManualPairingInfo {
    return ManualPairingInfoFromJSONTyped(json, false);
}

export function ManualPairingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualPairingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'thingName': json['thingName'],
        'followerThingNames': !exists(json, 'followerThingNames') ? undefined : json['followerThingNames'],
        'connectivityType': ConnectivityTypeFromJSON(json['connectivityType']),
        'availableMachineTypes': ((json['availableMachineTypes'] as Array<any>).map(MachineTypeFromJSON)),
        'availableMachines': json['availableMachines'],
    };
}

export function ManualPairingInfoToJSON(value?: ManualPairingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'followerThingNames': value.followerThingNames,
        'connectivityType': ConnectivityTypeToJSON(value.connectivityType),
        'availableMachineTypes': ((value.availableMachineTypes as Array<any>).map(MachineTypeToJSON)),
        'availableMachines': value.availableMachines,
    };
}

