/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppId } from './AppId';
import {
    AppIdFromJSON,
    AppIdFromJSONTyped,
    AppIdToJSON,
} from './AppId';

/**
 * 
 * @export
 * @interface Applications
 */
export interface Applications {
    /**
     * 
     * @type {Array<AppId>}
     * @memberof Applications
     */
    apps: Array<AppId>;
    /**
     * 
     * @type {AppId}
     * @memberof Applications
     */
    defaultApp?: AppId;
}

/**
 * Check if a given object implements the Applications interface.
 */
export function instanceOfApplications(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apps" in value;

    return isInstance;
}

export function ApplicationsFromJSON(json: any): Applications {
    return ApplicationsFromJSONTyped(json, false);
}

export function ApplicationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Applications {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apps': ((json['apps'] as Array<any>).map(AppIdFromJSON)),
        'defaultApp': !exists(json, 'defaultApp') ? undefined : AppIdFromJSON(json['defaultApp']),
    };
}

export function ApplicationsToJSON(value?: Applications | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apps': ((value.apps as Array<any>).map(AppIdToJSON)),
        'defaultApp': AppIdToJSON(value.defaultApp),
    };
}

