/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HMState {
    ANALYZING = 'ANALYZING',
    FINISHED = 'FINISHED'
}


export function HMStateFromJSON(json: any): HMState {
    return HMStateFromJSONTyped(json, false);
}

export function HMStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): HMState {
    return json as HMState;
}

export function HMStateToJSON(value?: HMState | null): any {
    return value as any;
}

