import { ProgramModuleSettings, SettingField } from 'src/service/backend/api'
import { compareNumbersAsc } from 'src/service/utils/NumberUtils'
import { getFieldValueByLabel } from 'src/service/view-model/setting-field/SettingFieldUtils'
import { HeadCells } from 'src/ui-shared/table/Table.const'

/**
 * Function that returns ordered head cells from setting values, based on showInOverview and overviewOrder parameters
 *
 * @param programSteps list of program steps, each with list of settings
 * @returns {Array} list of head cells to be shown in table
 */
export const getProgramSettingsHeadCells = (programSteps: ProgramModuleSettings[] | undefined): HeadCells[] => {
  let result: HeadCells[] = []

  if (programSteps && programSteps.length > 0) {
    const programSettingsHeadCells = new Map<string, number | undefined>()
    const programSettings = programSteps.flatMap((step) => step.settings)

    programSettings.forEach((setting) => {
      if (setting.showInOverview) {
        const columnName = setting.label

        // if column is not present in table, add to map
        if (!programSettingsHeadCells.has(columnName)) {
          const columnPosition = setting.overviewOrder
          programSettingsHeadCells.set(columnName, columnPosition)
        }
      }
    })

    // sort map by column position, column with missing position move at end of list (append last)
    const orderedHeadCellsMap = [...programSettingsHeadCells].sort((columnA, columnB) => {
      const columnPositionA = columnA[1]
      const columnPositionB = columnB[1]
      return compareNumbersAsc(columnPositionA, columnPositionB)
    })

    // convert map to HeadCells
    result = orderedHeadCellsMap.map((column) => {
      const columnName = column[0]
      const headCell: HeadCells = {
        id: columnName,
        label: columnName,
        noSort: true,
      }

      return headCell
    })
  }

  return result
}

/**
 * Function that returns list of strings representing table cell values for the given row for each head cell
 *
 * @param headCells list of head cells
 * @param fields list of program settings
 * @returns {Array} list of strings representing table cell values
 */
export const getSettingsTableRowValues = (headCells: HeadCells[], fields: SettingField[]): string[] => {
  const tableRowValues = headCells.map((column) => getFieldValueByLabel(column.label, fields))

  return tableRowValues
}
