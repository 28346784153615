import React, { ReactElement, useContext, useMemo, useState } from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { useNavigate } from 'react-router'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  Configuration,
  IssueCategoryReference,
  IssuesApi,
  LaundriesApi,
  LaundryGroupReference,
  LaundryGroupsApi,
  LaundryReference,
  MachineReference,
  MachinesApi,
  OrganizationReference,
  OrganizationsApi,
  ServiceType,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { DateUtils } from 'src/service/utils/DateUtils'
import { getTomorrow, removeTimeFromDate } from 'src/service/utils/MomentUtils'
import { getLocaleForDatePicker } from 'src/service/view-model/base/localization/Locales'
import { getMachineNameAndSerialNumber, getMachineOptionLabel } from 'src/service/view-model/machine/Machines'
import {
  ServiceRequestViewModel,
  TIME_SLOT_EVENING,
  TIME_SLOT_MORNING,
  createServiceRequestViewModel,
  getTimeSlotPeriodLabel,
  mapServiceRequestViewModelToServiceRequest,
} from 'src/service/view-model/service-request/ServiceRequestViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { AsyncAutoCompleteValidate } from 'src/ui-shared/base/form/control/AsyncAutoCompleteValidate'
import { DatePickerDefault } from 'src/ui-shared/base/form/control/DatePickerDefault'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { composeValidators, maxChar, required, validPhoneNumber } from 'src/ui-shared/base/form/validation/Validators'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { usePrompt } from 'src/ui-shared/base/hooks/usePrompt'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ScreenSteps } from 'src/ui-shared/base/stepper/ScreenSteps'
import { DisabledWarningTooltip } from 'src/ui-shared/base/tooltip/DisabledWarningTooltip'
import { TEXT_LENGTH } from 'src/ui-shared/constants/Constants'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { useUserFullName, useUserLocale, useUserRegionLocale } from 'src/user/UserContext'

export const ServiceRequestCreatePage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()
  const locale = useUserLocale()
  const navigate = useNavigate()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const activeOrganization = useActiveOrganization()
  const contactName = useUserFullName()
  const regionLocale = useUserRegionLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const issueApi = new IssuesApi(httpConfiguration)
  const organizationsApi = new OrganizationsApi(httpConfiguration)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)
  const laundryApi = new LaundriesApi(httpConfiguration)
  const machineApi = new MachinesApi(httpConfiguration)

  const dateTomorrow = removeTimeFromDate(getTomorrow(new Date()))

  // state
  const [activeStep, setActiveStep] = useState<number>(0)
  const [callbackDate, setCallbackDate] = useState<Date | null>(dateTomorrow)
  const [loading, setLoading] = useState(false)
  const [errorMessage] = useState<string | null>(null)
  const [dirty, setDirty] = useState<boolean>(false)

  const serviceRequestCreateSteps = ['step.contactInfo', 'step.description']

  const serviceRequestViewModel = useMemo(() => {
    const viewModel: ServiceRequestViewModel = {
      ...createServiceRequestViewModel(),
      organizationRef: activeOrganization ?? null,
      contactName: contactName,
    }
    return viewModel
  }, [activeOrganization, contactName])

  useHotKeysForm()

  usePrompt(translate('notification.form.unsaved.changes'), dirty)

  // load data
  const organizationSearch = (searchText: string): Promise<OrganizationReference[]> => {
    return organizationsApi.organizationsRefGet(undefined, searchText)
  }

  const laundryGroupSearch = (
    searchText: string,
    organizationId: string | undefined,
  ): Promise<LaundryGroupReference[]> => {
    const organizationsId = organizationId ? [organizationId] : undefined
    return laundryGroupsApi.laundrygroupsRefGet(undefined, searchText, organizationsId)
  }

  const laundrySearch = (searchText: string, laundryGroupId: string | undefined): Promise<LaundryReference[]> => {
    const laundryGroupsId = laundryGroupId ? [laundryGroupId] : undefined
    return laundryApi.laundriesRefGet(undefined, searchText, laundryGroupsId)
  }

  const machineSearch = (searchText: string, laundryId: string | undefined): Promise<MachineReference[]> => {
    const laundriesId = laundryId ? [laundryId] : undefined
    return machineApi.machinesRefGet(undefined, searchText, laundriesId)
  }

  const issueCategorySearch = (): Promise<Array<IssueCategoryReference>> => {
    return issueApi.issuesCategoriesGet()
  }

  // event handling
  const onSubmitSuccess = () => {
    setDirty(false)
    setLoading(false)
    navigateBack()
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  const handleDateChange = (date: Date | null) => {
    if (date && DateUtils.isValidDate(date)) {
      const callBackDate = removeTimeFromDate(date!)
      setCallbackDate(callBackDate)
    }
  }
  const navigateBack = () => {
    navigate(`/${appId}/service-request`)
  }

  const handleOnCancel = () => {
    navigateBack()
  }

  const handleOnNextStep = () => {
    setActiveStep((step) => step + 1)
  }

  const handleOnPreviousStep = () => {
    setActiveStep((step) => step - 1)
  }

  const submitForm = async (values: ServiceRequestViewModel) => {
    setLoading(true)
    setDirty(false)
    const viewModel: ServiceRequestViewModel = {
      ...values,
      callbackDate: callbackDate ?? new Date(),
    }

    const serviceRequest = mapServiceRequestViewModelToServiceRequest(viewModel)

    return issueApi
      .issuesPost(serviceRequest)
      .then((_data) => {
        onSubmitSuccess()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  const cancelButton = (
    <Button
      id="cancelButton"
      variant="text"
      color="primary"
      size="large"
      onClick={handleOnCancel}
      className={sharedClasses.ButtonMargin}
    >
      {translate('button.cancel')}
    </Button>
  )

  return (
    <>
      <ScreenLayout title={translate('createServiceRequest')} onBack={navigateBack} actions={<></>} actionsWidth={50}>
        <LoadingIndicator loading={loading} />
        <Paper elevation={0}>
          <Divider />
          <ScreenSteps steps={serviceRequestCreateSteps} activeStep={activeStep} />
          {errorMessage ? (
            <ErrorMessage message={errorMessage} />
          ) : (
            <Box pt={2}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={getLocaleForDatePicker(locale, regionLocale)}
              >
                <Form<ServiceRequestViewModel>
                  initialValues={serviceRequestViewModel}
                  initialValuesEqual={(a, b) => {
                    // keep view model the same if the user is on the second step
                    // if the user changes the global active organization
                    // then the form will use the last selected organization from step 1
                    return activeStep === 1 || a === b
                  }}
                  onSubmit={submitForm}
                  render={({ handleSubmit, submitting, pristine, values, form, invalid }) => {
                    return (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        {activeStep === 0 && (
                          <>
                            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                                <>
                                  <AsyncAutoCompleteValidate
                                    disabled={submitting}
                                    name="organizationRef"
                                    preselectOption
                                    label={translate('organization')}
                                    delay={300}
                                    labelFieldName="name"
                                    loadOptionsFunction={organizationSearch}
                                  />
                                  <OnChange name="organizationRef">
                                    {() => {
                                      setTimeout(() => {
                                        form.change('laundryGroupRef', undefined)
                                      }, 0)
                                    }}
                                  </OnChange>
                                </>
                              </Grid>
                              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                                <DisabledWarningTooltip
                                  disabled={loading || values.organizationRef != null}
                                  message={translate('selectOrganizationWarning')}
                                >
                                  <AsyncAutoCompleteValidate
                                    key={`${values.organizationRef?.id}`}
                                    disabled={submitting || values.organizationRef == null}
                                    name="laundryGroupRef"
                                    label={translate('laundryGroup')}
                                    delay={300}
                                    preselectOption
                                    labelFieldName="name"
                                    loadOptionsFunction={(search: string) =>
                                      laundryGroupSearch(search, values.organizationRef?.id)
                                    }
                                  />
                                  <OnChange name="laundryGroupRef">
                                    {() => {
                                      setTimeout(() => {
                                        form.change('laundryRef', undefined)
                                      }, 0)
                                    }}
                                  </OnChange>
                                </DisabledWarningTooltip>
                              </Grid>
                              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                                <DisabledWarningTooltip
                                  disabled={loading || values.laundryGroupRef != null}
                                  message={translate('selectLaundryGroupWarning')}
                                >
                                  <AsyncAutoCompleteValidate
                                    key={`${values.organizationRef?.id} ${values.laundryGroupRef?.id}`}
                                    disabled={submitting || values.laundryGroupRef == null}
                                    name="laundryRef"
                                    label={translate('laundry')}
                                    delay={300}
                                    labelFieldName="name"
                                    preselectOption
                                    loadOptionsFunction={(search: string) =>
                                      laundrySearch(search, values.laundryGroupRef?.id)
                                    }
                                  />
                                  <OnChange name="laundryRef">
                                    {() => {
                                      setTimeout(() => {
                                        form.change('machineRef', undefined)
                                      }, 0)
                                    }}
                                  </OnChange>
                                </DisabledWarningTooltip>
                              </Grid>
                              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                                <DisabledWarningTooltip
                                  disabled={loading || values.laundryRef != null}
                                  message={translate('selectLaundryWarning')}
                                >
                                  <AsyncAutoCompleteValidate
                                    key={`${values.organizationRef?.id} ${values.laundryGroupRef?.id}  ${values.laundryRef?.id}`}
                                    disabled={submitting || values.laundryRef == null}
                                    name="machineRef"
                                    validate={required()}
                                    label={translate('machine')}
                                    delay={300}
                                    labelFieldName="name"
                                    getOptionLabel={getMachineOptionLabel}
                                    preselectOption
                                    loadOptionsFunction={(search: string) =>
                                      machineSearch(search, values.laundryRef?.id)
                                    }
                                  />
                                </DisabledWarningTooltip>
                              </Grid>
                            </Grid>
                            <Divider className={sharedClasses.Divider} />
                            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                                <Field
                                  name="serviceType"
                                  render={(props) => (
                                    <FormControl component="fieldset" fullWidth>
                                      <FormLabel component="legend">
                                        <Typography variant="subtitle2">{translate('serviceType')}</Typography>
                                      </FormLabel>
                                      <RadioGroup aria-label="reservability" {...props.input}>
                                        <Stack
                                          direction="row"
                                          justifyContent="start"
                                          spacing={1}
                                          paddingTop={1}
                                          paddingRight={2}
                                        >
                                          <FormControlLabel
                                            value={ServiceType.CALLBACK}
                                            control={<Radio color="primary" />}
                                            label={translate('serviceType.callback')}
                                          />
                                          <FormControlLabel
                                            value={ServiceType.ONSITE}
                                            control={<Radio color="primary" />}
                                            label={translate('serviceType.onsite')}
                                          />
                                          <FormControlLabel
                                            value={ServiceType.MACHINE_ERROR}
                                            control={<Radio color="primary" />}
                                            label={translate('serviceType.machineError')}
                                          />
                                        </Stack>
                                      </RadioGroup>
                                    </FormControl>
                                  )}
                                />
                              </Grid>
                              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                                <TextField
                                  label={translate('serviceRequest.contact')}
                                  type="text"
                                  name="contactName"
                                  fullWidth
                                  disabled={submitting}
                                />
                              </Grid>

                              <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                                <TextField
                                  label={translate('serviceRequest.contactNmb')}
                                  type="text"
                                  name="contactPhoneNumber"
                                  fullWidth
                                  disabled={submitting}
                                  validate={validPhoneNumber()}
                                />
                              </Grid>
                            </Grid>
                            <Divider className={sharedClasses.Divider} />
                            <Box mt={3} display="flex" justifyContent="flex-end">
                              {cancelButton}
                              <Button
                                id="submitButton"
                                variant="contained"
                                size="large"
                                disabled={submitting || pristine || invalid}
                                color="primary"
                                type="submit"
                                onClick={handleOnNextStep}
                              >
                                {translate('button.next')}
                              </Button>
                            </Box>
                          </>
                        )}
                        {activeStep === 1 && (
                          <>
                            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                              <Grid item {...ITEM_BREAKPOINTS}>
                                <AsyncAutoCompleteValidate
                                  disabled={submitting}
                                  name="category"
                                  label={translate('serviceRequest.issueCategory')}
                                  delay={300}
                                  labelFieldName="name"
                                  optionKey="key"
                                  validate={required()}
                                  loadOptionsFunction={issueCategorySearch}
                                />
                              </Grid>
                              <Grid item {...ITEM_BREAKPOINTS}>
                                <DetailsTextField
                                  value={getMachineNameAndSerialNumber(values.machineRef)}
                                  label={translate('machine') + ' / ' + translate('serialNumber')}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  label={translate('description')}
                                  type="text"
                                  name="description"
                                  multiline
                                  rows={6}
                                  fullWidth
                                  disabled={submitting}
                                  validate={composeValidators(
                                    required(),
                                    maxChar(TEXT_LENGTH.SERVICE_REQUEST_DESCRIPTION),
                                  )}
                                />
                              </Grid>
                            </Grid>
                            <Divider className={sharedClasses.Divider} />
                            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                              <Grid item {...ITEM_BREAKPOINTS} sm={12} md={4} lg={3}>
                                <DatePickerDefault
                                  locale={regionLocale}
                                  label={translate('serviceRequest.callbackDate')}
                                  value={callbackDate}
                                  disablePast={true}
                                  minDate={dateTomorrow}
                                  onChange={handleDateChange}
                                />
                              </Grid>
                              <Grid item {...ITEM_BREAKPOINTS} sm={12} md={8} lg={9}>
                                <Field
                                  name="callbackTimeSlot"
                                  render={(props) => (
                                    <FormControl component="fieldset" fullWidth>
                                      <FormLabel component="legend">
                                        <Typography variant="subtitle2" paddingLeft={1} paddingTop={1}>
                                          {translate('serviceRequest.callbackTime')}
                                        </Typography>
                                      </FormLabel>
                                      <RadioGroup aria-label="reservability" {...props.input}>
                                        <Stack direction="row" spacing={2} paddingLeft={1} paddingTop={1}>
                                          <FormControlLabel
                                            value={TIME_SLOT_MORNING}
                                            control={<Radio color="primary" />}
                                            label={getTimeSlotPeriodLabel(TIME_SLOT_MORNING, regionLocale)}
                                          />
                                          <FormControlLabel
                                            value={TIME_SLOT_EVENING}
                                            control={<Radio color="primary" />}
                                            label={getTimeSlotPeriodLabel(TIME_SLOT_EVENING, regionLocale)}
                                          />
                                        </Stack>
                                      </RadioGroup>
                                    </FormControl>
                                  )}
                                />
                              </Grid>
                            </Grid>
                            <Divider className={sharedClasses.Divider} />
                            <Box mt={3} display="flex" justifyContent="space-between">
                              <Box>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="large"
                                  className={sharedClasses.ButtonMargin}
                                  onClick={handleOnPreviousStep}
                                >
                                  {translate('button.previous')}
                                </Button>
                              </Box>
                              <Box display="flex" justifyContent="flex-end">
                                {cancelButton}
                                <Button
                                  id="submitButton"
                                  disabled={submitting || pristine}
                                  variant="contained"
                                  size="large"
                                  color="primary"
                                  type="submit"
                                >
                                  {translate('button.save')}
                                </Button>
                              </Box>
                            </Box>
                          </>
                        )}
                        <FormSpy
                          subscription={{ dirty: true }}
                          onChange={(props) => {
                            setTimeout(() => setDirty(props.dirty), 0)
                          }}
                        />
                      </form>
                    )
                  }}
                />
              </LocalizationProvider>
            </Box>
          )}
        </Paper>
      </ScreenLayout>
    </>
  )
}
