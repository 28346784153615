import { Program, ProgramModuleTemplate } from 'src/service/backend/api'
import { modifyGroupFieldsAddDefaultHiddenValue } from 'src/service/view-model/setting-field/SettingVisibilityDefaultUtils'
import { hideOrShowGroupsForAllSelectOrBooleanFields } from 'src/service/view-model/setting-field/SettingVisibilityUtils'

/**
 * Applies the initial hidden / shown state to the groups based on the state of the
 * select or boolean fields that can show or hide groups, that are part of the program.
 *
 * @param program a program
 */
export const hideOrShowGroupsForAllSelectOrBooleanFieldsForProgram = (program: Program): void => {
  // apply to the program steps
  if (program.details?.steps) {
    const steps = program.details.steps
    for (let i = 0; i < steps.length; i++) {
      const step = steps[i]
      hideOrShowGroupsForAllSelectOrBooleanFields(step.settings)
    }
  }

  // apply to the general program settings
  if (program.details?.programSettings) {
    hideOrShowGroupsForAllSelectOrBooleanFields(program.details.programSettings)
  }
}

/**
 * Modifies group fields by adding additional defaultHidden property for given program.
 * It mutates the current given program.
 * @param program the given program.
 * @returns {void}
 */
export const modifyGroupFieldsAddDefaultHiddenValueForProgram = (program: Program): void => {
  const programSettings = program.details?.programSettings ?? []
  const moduleSteps = program.details?.steps ?? []

  modifyGroupFieldsAddDefaultHiddenValue(programSettings)

  for (const moduleStep of moduleSteps) {
    modifyGroupFieldsAddDefaultHiddenValue(moduleStep.settings)
  }
}

/**
 * Modifies group fields by adding additional defaultHidden property for given program module template.
 * It mutates the current given template.
 * @param template the given template.
 * @returns {void}
 */
export const modifyGroupFieldsAddDefaultHiddenValueForTemplate = (template: ProgramModuleTemplate): void => {
  const moduleSteps = template.availableModules ?? []
  const blockOfModuleSteps = template.availableTemplates ?? []

  for (const moduleStep of moduleSteps) {
    modifyGroupFieldsAddDefaultHiddenValue(moduleStep.settings)
  }

  for (const blockOfModule of blockOfModuleSteps) {
    const modulesForBlock = blockOfModule.steps ?? []

    for (const module of modulesForBlock) {
      modifyGroupFieldsAddDefaultHiddenValue(module.settings)
    }
  }
}
