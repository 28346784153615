import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from '@mui/material'
import {
  Add,
  ContentCopyOutlined,
  CopyAll,
  DeleteOutlineOutlined,
  EditOutlined,
  GetApp,
  RestartAlt,
  Upload,
} from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganizationMandatory } from 'src/organization/ActiveOrganizationProvider'
import { NavigateState } from 'src/routing/Routing'
import {
  Configuration,
  MachineType,
  OrganizationReference,
  Permission,
  Program,
  ProgramCopyRequest,
  ProgramType,
  ProgramsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocaleOptional } from 'src/service/utils/DateFormatUtils'
import { formatTemperatureOptional } from 'src/service/utils/NumberFormatUtils'
import { Data } from 'src/service/view-model/base/Data'
import {
  ProgramTypeFilter,
  getProgramNameAndTemperature,
  getProgramTypeName,
} from 'src/service/view-model/program/ProgramViewModel'
import { MenuButton } from 'src/ui-shared/base/button/MenuButton'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { WithMandatoryActiveOrganization } from 'src/ui-shared/base/hoc/WithMandatoryActiveOrganization'
import { useDidComponentUpdate } from 'src/ui-shared/base/hooks/useDidComponentUpdate'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { EditMode } from 'src/ui-shared/constants/Constants'
import {
  displayTableHeaders,
  displayTablePagination,
  displayTableRows,
  displayTableSelectedRows,
} from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettingsWithOrganization,
  getDefaultTableSettings,
  tableSettingsSort,
  withOrganization,
} from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { SelectOrganizationDialog } from 'src/ui/layout/organization-selector/SelectOrganizationDialog'
import { ProgramDetailsTab } from 'src/ui/page/es/program/details/ProgramDetailsTab'
import { ProgramExportModal } from 'src/ui/page/es/program/details/ProgramExportModal'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser, useUserRegionLocale } from 'src/user/UserContext'

export const ActionButtonsGrid = styled(Grid)(({ theme }) => ({
  display: 'inline-flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
}))

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  flexGrow: 1,
  '& .MuiToggleButton-root': {
    width: '100px',
  },
}))

export const PROGRAM_TABLE_HEAD_CELLS: HeadCells[] = [
  {
    id: 'metaData.programIndex',
    label: 'programInfo.index',
  },
  {
    id: 'metaData.temperature',
    label: 'temperatureShort',
  },
  {
    id: 'metaData.programName',
    label: 'programInfo.programName',
  },
  {
    id: 'metaData.programMode',
    label: 'programMode',
  },
  {
    id: 'programType',
    label: 'programInfo.type',
    noSort: true,
  },
  {
    id: 'lastModified',
    label: 'programInfo.lastUpdate',
    noSort: true,
  },
]

const actionHeadCell: HeadCells = {
  id: 'actions',
  label: '',
  noSort: true,
}
const headCells: HeadCells[] = [...PROGRAM_TABLE_HEAD_CELLS, actionHeadCell]

interface ProgramTableSettings extends TableSettingsWithOrganization {
  orderBy: string
  programType?: ProgramType
}

interface Props {
  machineType: MachineType
  navigateToItem: (programId: string) => void
  programTypeFilter: ProgramTypeFilter
  setProgramType: React.Dispatch<React.SetStateAction<ProgramTypeFilter>>
}

const ProgramTableInternal: FC<Props> = ({
  machineType,
  navigateToItem,
  programTypeFilter,
  setProgramType,
}): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const appId = useAppId()

  const activeOrganization = useActiveOrganizationMandatory()

  const translate = useTranslate()
  const location = useLocation()
  const navigate = useNavigate()
  const user = useUser()
  const regionLocale = useUserRegionLocale()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)

  const programsApi = new ProgramsApi(httpConfiguration)

  const [data, setData] = useState<TableData<Program>>(DEFAULT_DATA)
  const defaultTableSettings: ProgramTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'metaData.programIndex',
    orderDir: 'asc',
    programType: programTypeFilter === 'all' ? undefined : programTypeFilter,
  }
  const [tableSettings, setTableSettings] = useState<ProgramTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )
  const [selectOrganizationModal, setSelectOrganizationModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set())
  const [programForRemoval, setProgramForRemoval] = useState<Program | null>(null)
  const [programForReset, setProgramForReset] = useState<Program | null>(null)
  const [reloadDataFlag, setReloadDataFlag] = useState<boolean>(false)
  const [exportModal, setExportModal] = useState<boolean>(false)

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)

  // derived
  const hasProgramWritePermission = hasPermission(user, Permission.EASYSET_PROGRAM_WRITE)
  const organizationId = tableSettings.organizationId!

  // reactivity
  useEffect(() => {
    setTableSettings({
      ...tableSettings,
      organizationId: activeOrganization.id,
    })
  }, [activeOrganization])

  // load data on state change
  useEffect(() => {
    let active = true
    handleClearSelectedRows()

    setLoading(true)
    programsApi
      .programsGet(
        organizationId,
        machineType,
        tableSettings.size,
        tableSettings.page,
        tableSettingsSort(tableSettings),
        tableSettings.search,
        tableSettings.programType,
      )
      .then((data) => {
        if (active) {
          updateTableSettingsFromData(data as Data<any>, tableSettings)
          setTableSettings(tableSettings)

          setData(mapData(data as Data<any>))
          setErrorMessage(null)
          setLoading(false)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [tableSettings, reloadDataFlag])

  useDidComponentUpdate(() => {
    const newProgramTypeFilter: ProgramTypeFilter = tableSettings.programType ?? 'all'
    setProgramType(newProgramTypeFilter)
  }, [tableSettings.programType])

  // handle events
  const handleProgramTypeFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newProgramTypeFilter: ProgramTypeFilter | null,
  ) => {
    if (newProgramTypeFilter !== null) {
      setTableSettings((settings) => {
        return {
          ...settings,
          page: 0,
          programType: newProgramTypeFilter === 'all' ? undefined : newProgramTypeFilter,
        }
      })
    }
  }

  const handleSelectRow = (selectedRow: Program, checked: boolean) => {
    const programId = selectedRow.metaData.id

    if (checked) {
      const setOfSelectedRows = new Set(selectedRows)
      setOfSelectedRows.add(programId)
      setSelectedRows(setOfSelectedRows)
    } else {
      const setOfSelectedRows = new Set(selectedRows)
      setOfSelectedRows.delete(programId)
      setSelectedRows(setOfSelectedRows)
    }
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedRowsMap = new Set(selectedRows)

      data.data.forEach((item) => {
        newSelectedRowsMap.add(item.metaData.id)
      })

      setSelectedRows(newSelectedRowsMap)
      return
    }

    setSelectedRows(new Set())
  }

  const handleClearSelectedRows = () => {
    if (selectedRows.size > 0) {
      setSelectedRows(new Set())
    }
  }

  const handleNavigateTo = (
    mode: Extract<EditMode, 'edit' | 'copy'>,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    program: Program,
  ) => {
    event.stopPropagation()

    // if the program is standard go the modules tab
    let tabToUse = ProgramDetailsTab.SETTINGS
    if (program.metaData.programType === ProgramType.STANDARD) {
      tabToUse = ProgramDetailsTab.MODULES
    }

    navigate(`/${appId}/programs/${machineType}/${program.metaData.id}/${mode}/${tabToUse}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleDeleteModalOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, program: Program) => {
    event.stopPropagation()
    setProgramForRemoval(program)
  }

  const handleDeleteModalClose = () => {
    setProgramForRemoval(null)
  }

  const handleDeleteProgram = () => {
    if (programForRemoval) {
      programsApi
        .programsProgramIdDelete(programForRemoval.metaData.id, organizationId, machineType)
        .then(() => {
          handleDeleteModalClose()
          handleClearSelectedRows()
          setReloadDataFlag((prevState: boolean) => !prevState)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
          handleDeleteModalClose()
        })
    }
  }

  const handleResetModalOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, program: Program) => {
    event.stopPropagation()
    setProgramForReset(program)
  }

  const handleResetModalClose = () => {
    setProgramForReset(null)
  }

  const handleResetProgram = () => {
    if (programForReset) {
      programsApi
        .programsProgramIdDelete(programForReset.metaData.id, organizationId, machineType)
        .then(() => {
          handleResetModalClose()
          handleClearSelectedRows()
          showSnackbar(translate('programResetSuccess'), 'success')
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
          handleResetModalClose()
        })
    }
  }

  const handleSelectModalOpen = () => {
    setSelectOrganizationModal(true)
  }

  const handleSelectModalClose = () => {
    setSelectOrganizationModal(false)
  }

  const handleExportModalOpen = () => {
    setExportModal(true)
  }

  const handleExportModalClose = (clearSelected?: boolean) => {
    if (clearSelected) {
      setSelectedRows(new Set())
    }
    setExportModal(false)
  }

  const handleCopyProgramsToOrganization = (organization?: OrganizationReference) => {
    if (organization) {
      const programCopyRequest: ProgramCopyRequest = {
        targetOrganizationId: organization.id,
        programIds: Array.from(selectedRows),
      }

      return programsApi
        .programsCopyPost(organizationId, machineType, programCopyRequest)
        .then(() => {
          handleClearSelectedRows()
          handleSelectModalClose()
          showSnackbar(translate('programsCopySuccessful'), 'success')
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
          return err
        })
    }
  }

  const handleCreateClick = () => {
    navigate(`/${appId}/programs/${machineType}/create/${ProgramDetailsTab.SETTINGS}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const isRowSelected = (selectedRow: Program) => {
    return selectedRows.has(selectedRow.metaData.id)
  }

  const navigateToImportPage = () => {
    navigate(`/${appId}/programs/${machineType}/import`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  // JSX
  const nonEmptyRows = data.data.map((item, index) => {
    const programMetadata = item.metaData

    return (
      <TableRow
        className={tableClasses.tableRow}
        key={index}
        onClick={navigateToItem.bind(this, programMetadata.id)}
        selected={isRowSelected(item)}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            onChange={(_, checked) => handleSelectRow(item, checked)}
            onClick={(e) => e.stopPropagation()}
            checked={isRowSelected(item)}
          />
        </TableCell>
        <TableCell width="7%">{programMetadata.programIndex}</TableCell>
        <TableCell width="7%">
          {formatTemperatureOptional(programMetadata.temperature, programMetadata.temperatureUnit)}
        </TableCell>
        <TableCell>{programMetadata.programName}</TableCell>
        <TableCell>{programMetadata.programMode}</TableCell>
        <TableCell>{getProgramTypeName(programMetadata.programType, translate)}</TableCell>
        <TableCell width="15%">{formatDateTimeForLocaleOptional(programMetadata.lastModified, regionLocale)}</TableCell>
        <TableCell
          style={{
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: '0px',
            textAlign: 'right',
          }}
        >
          {hasProgramWritePermission && (
            <>
              <IconButton onClick={(event) => handleNavigateTo('edit', event, item)}>
                <EditOutlined fontSize="small" />
              </IconButton>
              <IconButton onClick={(event) => handleNavigateTo('copy', event, item)}>
                <ContentCopyOutlined fontSize="small" />
              </IconButton>
              {programMetadata.programType === ProgramType.CUSTOM ? (
                <IconButton onClick={(event) => handleDeleteModalOpen(event, item)}>
                  <DeleteOutlineOutlined fontSize="small" />
                </IconButton>
              ) : (
                <IconButton onClick={(event) => handleResetModalOpen(event, item)}>
                  <RestartAlt fontSize="small" />
                </IconButton>
              )}
            </>
          )}
        </TableCell>
      </TableRow>
    )
  })

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  // generic JSX

  const getFilter = (_headCellId: string) => {
    // TODO PST, 2023-08-22: enable program filters (name, temp, etc) when API implements them
    // return getProgramTableFilters(headCellId, translate, tableSettings, setTableSettings)
    return undefined
  }

  const displayTable = (
    <>
      <TableSearchForm handleSearch={handleSearch} inputSearchValue={inputSearchValue} />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {data.totalElements > 0 && (
                <TableCell key="selectable" sx={{ padding: '0 0 0 4px' }}>
                  <Checkbox
                    color="primary"
                    indeterminate={selectedRows.size > 0 && selectedRows.size < data.data.length}
                    checked={selectedRows.size > 0 && selectedRows.size === data.data.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}

              {displayTableHeaders(headCells, tableSettings, setTableSettings, translate, getFilter)}
            </TableRow>
          </TableHead>
          <TableBody>{displayTableRows(data, loading, headCells.length, nonEmptyRows)}</TableBody>
        </Table>
      </TableContainer>

      <Box display={'flex'} justifyContent={'space-between'}>
        {displayTableSelectedRows(selectedRows.size, translate)}
        {displayTablePagination(data.totalElements, tableSettings, setTableSettings, translate)}
      </Box>
    </>
  )

  const programActionButtons = (
    <Grid container spacing={2} pb={2}>
      <ActionButtonsGrid item xs={12}>
        <StyledToggleButtonGroup
          value={tableSettings.programType ?? 'all'}
          onChange={handleProgramTypeFilterChange}
          exclusive
          size="small"
        >
          <ToggleButton value={'all'}>{translate('all')}</ToggleButton>
          <ToggleButton value={ProgramType.STANDARD}>{translate('programType.standard')}</ToggleButton>
          <ToggleButton value={ProgramType.CUSTOM}>{translate('programType.custom')}</ToggleButton>
        </StyledToggleButtonGroup>

        <Button
          disabled={selectedRows.size === 0}
          onClick={handleExportModalOpen}
          variant="contained"
          color="primary"
          startIcon={<GetApp />}
        >
          {translate('exportSelected')}
        </Button>

        {hasProgramWritePermission && (
          <Button onClick={handleCreateClick} variant="outlined" color="primary" startIcon={<Add />}>
            {translate('createNew')}
          </Button>
        )}

        <MenuButton sx={{ m: 0 }}>
          <MenuItem onClick={handleSelectModalOpen} disabled={selectedRows.size === 0}>
            <ListItemIcon>
              <CopyAll fontSize="small" />
            </ListItemIcon>
            {translate('copyToOrganization')}
          </MenuItem>
          <MenuItem onClick={navigateToImportPage}>
            <ListItemIcon>
              <Upload fontSize="small" />
            </ListItemIcon>
            {translate('programsImport')}
          </MenuItem>
        </MenuButton>
      </ActionButtonsGrid>
    </Grid>
  )

  return (
    <Box pt={2}>
      <Paper elevation={0}>
        {programActionButtons}
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

        {displayTable}
      </Paper>

      {programForRemoval !== null && (
        <ConfirmationModalDialog
          titleKey="deleteProgram"
          confirmationKey="button.delete"
          open={true}
          onConfirm={handleDeleteProgram}
          onCancel={handleDeleteModalClose}
        >
          {translate('deleteConfirm', getProgramNameAndTemperature(programForRemoval))}
        </ConfirmationModalDialog>
      )}
      {programForReset !== null && (
        <ConfirmationModalDialog
          titleKey="resetProgramSettings"
          confirmationKey="button.resetToDefault"
          open={true}
          onConfirm={handleResetProgram}
          onCancel={handleResetModalClose}
        >
          {translate('resetProgramConfirm', getProgramNameAndTemperature(programForReset))}
        </ConfirmationModalDialog>
      )}
      {selectOrganizationModal && (
        <SelectOrganizationDialog
          open={true}
          setOpen={handleSelectModalClose}
          disabledOrganization={activeOrganization}
          onConfirm={handleCopyProgramsToOrganization}
          confirmText="copyPrograms"
          alertMessage="copyProgramsWarning"
        />
      )}
      {exportModal && (
        <ProgramExportModal
          title="exportPrograms"
          open={true}
          machineType={machineType}
          selectedProgramIds={Array.from(selectedRows)}
          handleClose={handleExportModalClose}
        />
      )}
    </Box>
  )
}

export const ProgramTable = WithMandatoryActiveOrganization(ProgramTableInternal)
