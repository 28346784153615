import React, { FC, MouseEventHandler, PropsWithChildren, ReactElement } from 'react'
import { Button, ButtonProps, Tooltip, Typography } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { DeleteIcon, EditIcon, SaveIcon } from 'src/ui-shared/icons/Icons'

export const EditButton: FC<Omit<ButtonProps, 'variant' | 'startIcon'>> = (props): ReactElement => {
  const translate = useTranslate()

  const defaultSaveMessage = translate('button.edit')
  const resolvedText = props.children ?? defaultSaveMessage
  const resolvedColor = props.color ?? 'primary'
  return (
    <Button variant="contained" startIcon={<EditIcon />} {...props} color={resolvedColor}>
      {resolvedText}
    </Button>
  )
}

export const SaveButton: FC<Omit<ButtonProps, 'variant' | 'startIcon'>> = (props): ReactElement => {
  const translate = useTranslate()

  const defaultSaveMessage = translate('button.save')
  const resolvedText = props.children ?? defaultSaveMessage
  const resolvedColor = props.color ?? 'primary'

  return (
    <Button variant="contained" startIcon={<SaveIcon />} {...props} color={resolvedColor}>
      {resolvedText}
    </Button>
  )
}

export const SaveAndBackButton: FC<Omit<ButtonProps, 'variant' | 'startIcon'>> = (props): ReactElement => {
  const translate = useTranslate()

  const defaultSaveMessage = translate('button.saveAndBack')
  const resolvedText = props.children ?? defaultSaveMessage
  const resolvedColor = props.color ?? 'primary'

  return (
    <Button variant="contained" startIcon={<SaveIcon />} {...props} color={resolvedColor}>
      {resolvedText}
    </Button>
  )
}

export const DeleteButton: FC<Omit<ButtonProps, 'variant' | 'startIcon'>> = (props): ReactElement => {
  const translate = useTranslate()

  const defaultSaveMessage = translate('button.delete')
  const resolvedText = props.children ?? defaultSaveMessage
  const resolvedColor = props.color ?? 'primary'

  return (
    <Button variant="contained" startIcon={<DeleteIcon />} {...props} color={resolvedColor}>
      {resolvedText}
    </Button>
  )
}

export interface ButtonWithTooltipProps extends ButtonProps {
  title?: string
  disabled?: boolean
  onClick?: MouseEventHandler
}

export const ButtonWithTooltip: FC<ButtonWithTooltipProps> = ({ title, disabled, onClick, ...other }): ReactElement => {
  const adjustedButtonProps = {
    disabled: disabled,
    onClick: disabled ? undefined : onClick,
  }
  return (
    <>
      {disabled ? (
        <Tooltip title={<Typography variant="body2">{title}</Typography>}>
          <div className="MuiButtonBase-root">
            <Button {...other} {...adjustedButtonProps} />
          </div>
        </Tooltip>
      ) : (
        <Button {...other} {...adjustedButtonProps} />
      )}
    </>
  )
}

export interface TooltipWrapperProp extends PropsWithChildren {
  title?: string
  showTooltip?: boolean
}

export const TooltipWrapper: FC<TooltipWrapperProp> = ({ title, showTooltip, children }): ReactElement => {
  return (
    <>
      {showTooltip ? (
        <Tooltip title={<Typography variant="body2">{title}</Typography>}>
          <div className="MuiButtonBase-root" style={{ display: 'inline' }}>
            {children}
          </div>
        </Tooltip>
      ) : (
        children
      )}
    </>
  )
}
