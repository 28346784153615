import React, { ReactElement } from 'react'
import { Box, Stack, styled } from '@mui/material'
import { AppId } from 'src/app/AppId'
import { ReactComponent as EasySetLogo } from 'src/assets/logo-easyset-dark.svg'
import { ReactComponent as SchulthessLogo } from 'src/assets/logo-schulthess-blue-black.svg'
import { ReactComponent as ServiceMasterLogo } from 'src/assets/logo-servicemaster-dark.svg'
import { ReactComponent as WashMasterLogo } from 'src/assets/logo-washmaster-dark-no-padding.svg'
import { NoAccessToOrganizationText } from 'src/ui/layout-page/landing/NoAccessToOrganizationText'

const WashMasterLogoSmall = styled(WashMasterLogo)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '400px',
  [theme.breakpoints.up('md')]: {
    width: '550px',
  },
}))

const ServiceMasterLogoSmall = styled(ServiceMasterLogo)(({ theme }) => ({
  width: '400px',
  [theme.breakpoints.up('md')]: {
    width: '550px',
  },
}))

const EasySetLogoSmall = styled(EasySetLogo)(({ theme }) => ({
  width: '400px',
  [theme.breakpoints.up('md')]: {
    width: '550px',
  },
}))

interface Props {
  app: AppId
}

export const LandingPage = ({ app = AppId.WASH_MASTER }: Props): ReactElement => {
  return (
    <Box>
      <Stack
        direction="column"
        spacing={3}
        justifyContent="center"
        sx={{ width: '80%', margin: '0 auto', paddingTop: 3 }}
      >
        <Box alignSelf="center">
          {app === AppId.WASH_MASTER && <WashMasterLogoSmall />}
          {app === AppId.SERVICE_MASTER && <ServiceMasterLogoSmall />}
          {app === AppId.EASY_SET && <EasySetLogoSmall />}
        </Box>
        <NoAccessToOrganizationText />
        <Box width="250px" alignSelf="center" sx={{ paddingTop: 2 }}>
          <SchulthessLogo />
        </Box>
      </Stack>
    </Box>
  )
}
