/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpeningType } from './OpeningType';
import {
    OpeningTypeFromJSON,
    OpeningTypeFromJSONTyped,
    OpeningTypeToJSON,
} from './OpeningType';
import type { PriceCondition } from './PriceCondition';
import {
    PriceConditionFromJSON,
    PriceConditionFromJSONTyped,
    PriceConditionToJSON,
} from './PriceCondition';

/**
 * 
 * @export
 * @interface LaundryGroupRecurringCalendarEntry
 */
export interface LaundryGroupRecurringCalendarEntry {
    /**
     * 1 = Monday, 7 = Sunday
     * @type {number}
     * @memberof LaundryGroupRecurringCalendarEntry
     */
    dayOfWeek: number;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroupRecurringCalendarEntry
     */
    from: string;
    /**
     * Duration in minutes
     * @type {number}
     * @memberof LaundryGroupRecurringCalendarEntry
     */
    duration: number;
    /**
     * 
     * @type {OpeningType}
     * @memberof LaundryGroupRecurringCalendarEntry
     */
    openingType: OpeningType;
    /**
     * 
     * @type {PriceCondition}
     * @memberof LaundryGroupRecurringCalendarEntry
     */
    priceCondition?: PriceCondition;
}

/**
 * Check if a given object implements the LaundryGroupRecurringCalendarEntry interface.
 */
export function instanceOfLaundryGroupRecurringCalendarEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dayOfWeek" in value;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "duration" in value;
    isInstance = isInstance && "openingType" in value;

    return isInstance;
}

export function LaundryGroupRecurringCalendarEntryFromJSON(json: any): LaundryGroupRecurringCalendarEntry {
    return LaundryGroupRecurringCalendarEntryFromJSONTyped(json, false);
}

export function LaundryGroupRecurringCalendarEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupRecurringCalendarEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dayOfWeek': json['dayOfWeek'],
        'from': json['from'],
        'duration': json['duration'],
        'openingType': OpeningTypeFromJSON(json['openingType']),
        'priceCondition': !exists(json, 'priceCondition') ? undefined : PriceConditionFromJSON(json['priceCondition']),
    };
}

export function LaundryGroupRecurringCalendarEntryToJSON(value?: LaundryGroupRecurringCalendarEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dayOfWeek': value.dayOfWeek,
        'from': value.from,
        'duration': value.duration,
        'openingType': OpeningTypeToJSON(value.openingType),
        'priceCondition': PriceConditionToJSON(value.priceCondition),
    };
}

