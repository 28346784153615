import { makeStyles } from 'tss-react/mui'
import { IconButton, TextField, styled } from '@mui/material'

export const TEXT_FIELD_DEFAULT_STYLE = {
  maxWidth: 'none',
  '& .MuiInputBase-root': {
    // search for .MuiInputBase-root to check for consistency in TextField heights
    minHeight: '5.0em',
  },
  '& .MuiInputBase-root.MuiFilledInput-root': {
    // also controlled in Theme globally for all TextFields
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  // remove bottom border on disabled TextFields
  '& .MuiInputBase-root.Mui-disabled::after': {
    borderBottom: 'none',
    transform: 'none',
    transition: 'none',
  },
  '& .MuiInputBase-root.Mui-disabled::before': {
    borderBottom: 'none',
    transform: 'none',
    transition: 'none',
  },
  // move the error text to be left aligned with the input text
  '& p': {
    marginLeft: 0,
  },

  // moves the calendar icon below the label and  extend the icon clickable area
  '& .MuiInputAdornment-root > .MuiButtonBase-root': {
    padding: '6px',
    marginTop: '18px',
    marginRight: '-6px',
  },
  '& .MuiInputAdornment-root': {
    top: 'auto',
  },

  // move the autocomplete dropdown icon to the bottom and extend the icon clickable area
  '& .MuiAutocomplete-endAdornment': {
    top: 'auto',
    position: 'absolute',
    paddingRight: 0,
  },
  '& .MuiAutocomplete-endAdornment > .MuiButtonBase-root': {
    padding: '6px',
  },
}

export const useTextFieldStyles = makeStyles()(() => {
  return {
    TextFieldSmall: {
      ...TEXT_FIELD_DEFAULT_STYLE,
      '& .MuiInputBase-root': {
        minHeight: 'auto',
      },
      // move the autocomplete dropdown icon to the bottom
      '& .MuiAutocomplete-endAdornment': {
        top: 'auto',
      },
    },
  }
})

// other seldom used styled TextField components

export const StyledTextFieldExtraSmall = styled(TextField)(() => ({
  ...TEXT_FIELD_DEFAULT_STYLE,
  '& .MuiInputBase-root': {
    minHeight: 'auto',
  },
  '& .MuiInputBase-input': {
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
}))

export const StyledIconButton = styled(IconButton)(() => ({
  padding: '2px',
}))
