import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, ListItemButton, ListSubheader, MenuItem, Popover, styled } from '@mui/material'
import { Apps } from '@mui/icons-material'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { ReactComponent as EsLogoImage } from 'src/assets/logo-easyset-dark.svg'
import { ReactComponent as PmLogoImage } from 'src/assets/logo-partsMaster-dark.svg'
import { ReactComponent as SmLogoImage } from 'src/assets/logo-servicemaster-dark.svg'
import { ReactComponent as QlLogoImage } from 'src/assets/logo-wash-q-lator-dark.svg'
import { ReactComponent as WmLogoImage } from 'src/assets/logo-washmaster-dark.svg'
import { useIsProdEnvironment } from 'src/env/EnvironmentContext'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Permission } from 'src/service/backend/api'
import { ListItemLink } from 'src/ui/layout/sidebar/ListItemLink'
import { hasApp, hasMoreThanOneApp, hasPermission } from 'src/user/RoleCheck'
import { getUserLanguage } from 'src/user/SupportedLanguage'
import { useUser } from 'src/user/UserContext'

const useStyles = makeStyles()(() => {
  return {
    LinkAppSwitcherPadding: {
      padding: '16px',
    },
  }
})

const Logo = styled(Box)<{
  active?: boolean
}>(({ active, theme }) => ({
  height: '31.06px',
  width: '197.8px',
  [theme.breakpoints.up('md')]: {
    width: '214px',
  },
  '& svg:not([fill="none"])': {
    fill: active && theme.palette.primary.main + '!important',
  },
}))

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    minHeight: '100%',
  },
  '& .MuiPaper-root': {
    top: '0px !important',
    left: '0px !important',
    width: '350px',
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
    borderRadius: 0,
    height: '100%',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}))

const StyledToolbar = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: '11px',
}))

const MainContent = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  flexGrow: 1,
}))

const IconButtonStyle = styled(MenuItem)<{
  active: number
}>(({ active, theme }) => ({
  color: active ? theme.palette.primary.main : 'white',
  padding: theme.spacing(1),
}))

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  lineHeight: theme.spacing(2),
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
}))

interface Props {
  open: boolean
  handleClose: () => void
}

export const AppSwitcher: React.FC<Props> = ({ open, handleClose }) => {
  const { classes } = useStyles()
  const translate = useTranslate()
  const appId = useAppId()
  const user = useUser()
  const isProdEnvironment = useIsProdEnvironment()

  const userHasMoreThanOneApp = hasMoreThanOneApp(user)
  const hasPermissionPartMaster = hasPermission(user, Permission.PART_MASTER_READ)
  const hasPermissionWashqulator = hasPermission(user, Permission.WASHQLATOR_READ)
  const hasDiscoverLinks = hasPermissionPartMaster || hasPermissionWashqulator

  const showAppSwitcher = userHasMoreThanOneApp || hasDiscoverLinks

  const userLanguage = getUserLanguage(user.locale)
  let washQLator: string
  if (userLanguage === 'en') {
    washQLator = `https://wash-q-lator.ch/en/realistic-cost-calculation`
  } else if (userLanguage === 'it') {
    washQLator = 'https://wash-q-lator.ch/it/calcolo-semplice'
  } else if (userLanguage === 'fr') {
    washQLator = 'https://wash-q-lator.ch/fr/demande-simple'
  } else {
    washQLator = 'https://wash-q-lator.ch/einfache-anfrage'
  }

  const partsMaster = isProdEnvironment
    ? 'https://spareparts.schulthess.ch/d2p/d2p?ALIAS=25004&BRAND=SCHULTHESS&CLIENT=SCHULTHESS&NAVGROUP=SCHULTHESS-WA'
    : 'https://schulthess.door2parts.com/d2p/d2p?ALIAS=25201&TIM=43d&BRAND=SCHULTHESS&CLIENT=SCHULTHESS'

  return (
    <>
      {showAppSwitcher && (
        <StyledPopover
          open={open}
          anchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleClose}
          marginThreshold={0}
        >
          <Box>
            <Box display={'flex'} justifyContent={'flex-start'}>
              <StyledToolbar>
                <IconButtonStyle active={1} onClick={handleClose}>
                  <Apps />
                </IconButtonStyle>
              </StyledToolbar>
            </Box>

            <MainContent>
              {userHasMoreThanOneApp && (
                <Box pt={2}>
                  <StyledListSubheader color="primary">{translate('switchTo')}</StyledListSubheader>

                  {hasApp(user, AppId.WASH_MASTER) && (
                    <ListItemLink
                      to={`/${AppId.WASH_MASTER}`}
                      className={classes.LinkAppSwitcherPadding}
                      icon={
                        <Logo active={appId === AppId.WASH_MASTER}>
                          <WmLogoImage />
                        </Logo>
                      }
                    />
                  )}

                  {hasApp(user, AppId.SERVICE_MASTER) && (
                    <ListItemLink
                      to={`/${AppId.SERVICE_MASTER}`}
                      className={classes.LinkAppSwitcherPadding}
                      icon={
                        <Logo active={appId === AppId.SERVICE_MASTER}>
                          <SmLogoImage />
                        </Logo>
                      }
                    />
                  )}

                  {hasApp(user, AppId.EASY_SET) && (
                    <ListItemLink
                      to={`/${AppId.EASY_SET}`}
                      className={classes.LinkAppSwitcherPadding}
                      icon={
                        <Logo active={appId === AppId.EASY_SET}>
                          <EsLogoImage
                            style={{
                              width: '120px',
                              height: '30px',
                            }}
                          />
                        </Logo>
                      }
                    />
                  )}
                </Box>
              )}
              {hasDiscoverLinks && (
                <Box pt={2}>
                  <StyledListSubheader color="primary">{translate('discover')}</StyledListSubheader>
                  {hasPermissionPartMaster && (
                    <ListItemButton
                      component="a"
                      href={partsMaster}
                      target={'_blank'}
                      className={classes.LinkAppSwitcherPadding}
                    >
                      <Logo>
                        <PmLogoImage />
                      </Logo>
                    </ListItemButton>
                  )}

                  {hasPermissionWashqulator && (
                    <ListItemButton
                      component="a"
                      href={washQLator}
                      target={'_blank'}
                      className={classes.LinkAppSwitcherPadding}
                    >
                      <Logo>
                        <QlLogoImage />
                      </Logo>
                    </ListItemButton>
                  )}
                </Box>
              )}
            </MainContent>
          </Box>
        </StyledPopover>
      )}
    </>
  )
}
