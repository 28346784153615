import { TranslateFunction } from 'src/i18n/useMessageSource'
import {
  Program,
  ProgramDetails,
  ProgramModuleSettings,
  ProgramModuleTemplateAvailableTemplatesInner,
} from 'src/service/backend/api'
import { HookFormFieldError } from 'src/ui-shared/base/form/validation/HookFormFieldError'

/**
 * Adds step numbers for the modules(steps) on program immutably.
 * Returns new program updated with changed step numbers.
 *
 * @param program the program where we need to insert the step numbers.
 * @returns {Program} new program with changed step numbers.
 */
export const addStepNumbersToModules = (program: Program): Program => {
  const oldSteps = program.details?.steps || []
  const newSteps = oldSteps.map((item, index) => {
    const newStep: ProgramModuleSettings = {
      ...item,
      stepNumber: index + 1,
    }
    return newStep
  })

  const newProgramDetails: ProgramDetails = {
    fixed: program.details?.fixed ?? false,
    programSettings: program.details?.programSettings ?? [],
    steps: newSteps,
  }

  const newProgram: Program = {
    metaData: program.metaData,
    details: newProgramDetails,
  }

  return newProgram
}

/**
 * Stringifies the program form errors which contain the section and module index where they happened.
 * Returns text: 'Settings and Modules 1,2,3' if there are errors on settings section and modules (with index 1,2,3)
 * Where 'Settings' and 'Modules' are the name of the sections where the errors happened
 *
 * @param errors the program errors
 * @param translate translation function
 * @returns {string} the stringified errors.
 */
export const stringifyProgramFormErrors = (
  errors: Record<string, HookFormFieldError>,
  translate: TranslateFunction,
): string => {
  const errorsArray = Object.values(errors)

  // contains the section name and the module indexes if they exist for the section name.
  const errorsSectionAndIndexMap = new Map<string, Set<number>>()

  errorsArray.forEach((item) => {
    const where = item.where
      ? item.where
      : {
          section: '',
        }
    if (!errorsSectionAndIndexMap.has(where.section)) {
      const setOfIndexes = where.moduleIndex ? new Set<number>([where.moduleIndex]) : new Set<number>()
      errorsSectionAndIndexMap.set(where.section, setOfIndexes)
    } else {
      if (where.moduleIndex) {
        errorsSectionAndIndexMap.get(where.section)?.add(where.moduleIndex)
      }
    }
  })

  const errorSections = Array.from(errorsSectionAndIndexMap.keys())

  let parsedText = ''

  errorSections.forEach((sectionName, index) => {
    const moduleIndexes = errorsSectionAndIndexMap.get(sectionName)
    parsedText += translate(sectionName)
    if (moduleIndexes && moduleIndexes.size > 0) {
      parsedText += ' '
      const sortedModuleIndexes = Array.from(moduleIndexes)
        .sort((a, b) => a - b)
        .join(', ')
      parsedText += sortedModuleIndexes
    }

    if (index < errorSections.length - 1) {
      parsedText += ' ' + translate('and') + ' '
    }
  })

  parsedText += '.'

  return parsedText
}

export const groupModuleTemplatesByCategory = (
  availableModuleBlocks: ProgramModuleTemplateAvailableTemplatesInner[],
): Record<string, ProgramModuleTemplateAvailableTemplatesInner[] | undefined> => {
  const availableModuleBlocksGroupedByCategory: Record<
    string,
    ProgramModuleTemplateAvailableTemplatesInner[] | undefined
  > = {}

  // map items by category
  for (const item of availableModuleBlocks) {
    let categoryLabel = item.categoryLabel

    // all items without category group with key empty string
    if (!categoryLabel) {
      categoryLabel = ''
    }

    let existing = availableModuleBlocksGroupedByCategory[categoryLabel]
    if (existing === undefined) {
      existing = []
    }

    existing.push(item)
    availableModuleBlocksGroupedByCategory[categoryLabel] = existing
  }

  return availableModuleBlocksGroupedByCategory
}
