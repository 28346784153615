import { PaletteOptions, ThemeOptions, createTheme } from '@mui/material/styles'
import BlinkerExtraLight from 'src/assets/font/blinker-v10-latin-300.ttf'
import BlinkerSemiBold from 'src/assets/font/blinker-v10-latin-600.ttf'
import BlinkerBold from 'src/assets/font/blinker-v10-latin-700.ttf'
import BlinkerRegular from 'src/assets/font/blinker-v10-latin-regular.ttf'

const WM_BRAND_COLOR = '#CE0F69'

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      light: '#e16fa5',
      main: WM_BRAND_COLOR,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#ff1744',
    },
    menu: {
      backgroundColor: '#F9F9F9',
      shadow: '0px 8px 10px -5px rgb(0 0 0 / 6%), 0px 16px 24px 2px rgb(0 0 0 / 6%), 0px 6px 30px 5px rgb(0 0 0 / 6%)',
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.4)',
    },
    texts: {
      sectionTitle: 'rgba(0, 0, 0, 0.6)',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0,
    action: {
      selected: 'rgba(158, 158, 158, 0.3)',
      hover: 'rgba(158, 158, 158, 0.3)',
      active: WM_BRAND_COLOR,
      hoverOpacity: 0,
      disabled: 'rgba(0, 0, 0, 0.4)',
    },
  },
  typography: {
    fontFamily: "'Blinker', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    h1: {
      fontSize: '2.1rem',
      fontWeight: '400',
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: '400',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '400',
    },
    h4: {
      fontSize: '1.25rem',
    },
    h5: {
      fontSize: '1.2rem',
    },
    h6: {
      fontSize: '1.1rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Blinker';
          font-style: normal;
          font-weight: 300;
          src: url(${BlinkerExtraLight}) format("truetype");
        }
        @font-face {
          font-family: 'Blinker';
          font-style: normal;
          font-weight: 400;
          src: url(${BlinkerRegular}) format("truetype");
        }
        @font-face {
          font-family: 'Blinker';
          font-style: normal;
          font-weight: 600;
          src: url(${BlinkerSemiBold}) format("truetype");
        }
        @font-face {
          font-family: 'Blinker';
          font-style: normal;
          font-weight: 700;
          src: url(${BlinkerBold}) format("truetype");
        }
        p {
          line-height: 1.2;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      `,
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '.MuiTouchRipple-rippleVisible': {
            color: 'rgba(158, 158, 158, 0.7)',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: WM_BRAND_COLOR,
            backgroundColor: 'rgba(158, 158, 158, 0.3)',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(158, 158, 158, 0.3)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '10rem',
          '&.MuiButton-sizeLarge': {
            fontSize: '1rem',
          },
          '&.MuiButton-text': {
            lineHeight: '1.2',
            textAlign: 'left',
          },
        },
        text: {
          minWidth: '5rem',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          // color for the Info Alert
          '& .MuiAlert-filledInfo': {
            backgroundColor: WM_BRAND_COLOR,
          },
          // color for the Success alert (currently using default)
          // '& .MuiAlert-filledSuccess': {
          //   backgroundColor: WM_BRAND_COLOR,
          // },
          // move the alert action bit below so that it is centralized
          '& .MuiAlert-action': {
            paddingTop: '0px',
            paddingLeft: '40px',
          },
        },
      },
    },
    MuiPaper: {
      // custom colors for Info alerts
      styleOverrides: {
        root: {
          '& .MuiAlert-standardInfo': {
            color: '#333333',
            background: '#F2F2F2',
          },
          '& .MuiAlert-standardInfo .MuiAlert-icon': {
            color: '#888888',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          ':disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)',
          },
        },
      },
    },
    MuiInputLabel: {
      // not added because we use shrinking labels in table filters
      // defaultProps: { shrink: true },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem',
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        // keep menu items above app drawer but below tooltips
        // https://mui.com/material-ui/customization/z-index/
        sx: { zIndex: '1350' },
      },
    },
    MuiFormControl: {
      // custom color for form control with disabled radio buttons
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.6) !important',
          },
          '& .MuiRadio-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.5)',
          },
          '& .MuiTypography-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.7) !important',
          },
        },
      },
    },
    MuiTab: {
      // custom color for disabled tab
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.2)',
          },
          '&.MuiTab-labelIcon': {
            padding: '0px',
          },
        },
      },
    },
  },
}

const defaultPalletOptions = themeOptions.palette as PaletteOptions

/**
 * serviceMaster Theme.
 */
const SM_BRAND_COLOR = '#745BA3'

const smThemeOptions: ThemeOptions = {
  ...themeOptions,
  palette: {
    primary: {
      light: '#ab9cc7',
      main: SM_BRAND_COLOR,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#ff1744',
    },
    menu: { ...defaultPalletOptions.menu },
    texts: { ...defaultPalletOptions.texts },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0,
    action: {
      selected: 'rgba(158, 158, 158, 0.3)',
      hover: 'rgba(158, 158, 158, 0.3)',
      active: SM_BRAND_COLOR,
      disabled: 'rgba(0, 0, 0, 0.4)',
      hoverOpacity: 0,
    },
  },
  components: {
    ...themeOptions.components,

    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: SM_BRAND_COLOR,
            backgroundColor: 'rgba(158, 158, 158, 0.3)',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(158, 158, 158, 0.3)',
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          // color for the Info Alert
          '& .MuiAlert-filledInfo': {
            backgroundColor: SM_BRAND_COLOR,
          },
          // color for the Success alert (currently using default)
          // '& .MuiAlert-filledSuccess': {
          //   backgroundColor: SM_BRAND_COLOR,
          // },
          '& .MuiAlert-action': {
            paddingTop: '0px',
            paddingLeft: '40px',
          },
        },
      },
    },
  },
}

/**
 * easySet Theme.
 */

const ES_BRAND_COLOR = '#0B79B8'

const esThemeOptions: ThemeOptions = {
  ...themeOptions,
  palette: {
    primary: {
      main: ES_BRAND_COLOR,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: ES_BRAND_COLOR,
    },
    menu: { ...defaultPalletOptions.menu },
    texts: { ...defaultPalletOptions.texts },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0,
    action: {
      selected: 'rgba(158, 158, 158, 0.3)',
      hover: 'rgba(158, 158, 158, 0.3)',
      active: ES_BRAND_COLOR,
      disabled: 'rgba(0, 0, 0, 0.4)',
      hoverOpacity: 0,
    },
  },
  components: {
    ...themeOptions.components,

    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: ES_BRAND_COLOR,
            backgroundColor: 'rgba(158, 158, 158, 0.3)',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(158, 158, 158, 0.3)',
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          // color for the Info Alert
          '& .MuiAlert-filledInfo': {
            backgroundColor: ES_BRAND_COLOR,
          },
          // color for the Success alert (currently using default)
          // '& .MuiAlert-filledSuccess': {
          //   backgroundColor: ES_BRAND_COLOR,
          // },
          '& .MuiAlert-action': {
            paddingTop: '0px',
            paddingLeft: '40px',
          },
        },
      },
    },
  },
}

export const SM_THEME = createTheme(smThemeOptions)
export const WM_THEME = createTheme(themeOptions)
export const ES_THEME = createTheme(esThemeOptions)
