import React, { FC, ReactElement, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { errorMapperReporting } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { PageablePrograms, Program } from 'src/service/backend/api'
import { formatDateTimeForLocaleOptional } from 'src/service/utils/DateFormatUtils'
import { formatTemperatureOptional } from 'src/service/utils/NumberFormatUtils'
import { Data } from 'src/service/view-model/base/Data'
import { getProgramTypeName } from 'src/service/view-model/program/ProgramViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ScreenSteps } from 'src/ui-shared/base/stepper/ScreenSteps'
import { FileFormat } from 'src/ui-shared/constants/Constants'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { DEFAULT_DATA, HeadCells, TableData } from 'src/ui-shared/table/Table.const'
import { mapData } from 'src/ui-shared/table/Table.utils'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'
import { UploadFileView } from 'src/ui-shared/upload-file-view/UploadFileView'
import { useUserRegionLocale } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'programIndex',
    label: 'programInfo.index',
    noSort: true,
  },
  {
    id: 'temperature',
    label: 'temperature',
    noSort: true,
  },
  {
    id: 'programName',
    label: 'programInfo.programName',
    noSort: true,
  },
  {
    id: 'programType',
    label: 'programInfo.type',
    noSort: true,
  },
  {
    id: 'lastModified',
    label: 'programInfo.lastUpdate',
    noSort: true,
  },
]

interface ProgramImportProp {
  acceptedFileFormats: FileFormat[]
  acceptedFileSizeMB: number
  acceptMultipleFiles: boolean
  disclaimerTextFor?: string
  handleCancel: () => void
  handleSubmit: (files: File[]) => Promise<PageablePrograms>
  handleFinish: () => void
}

export const ProgramImport: FC<ProgramImportProp> = ({
  acceptedFileFormats,
  acceptedFileSizeMB,
  acceptMultipleFiles,
  disclaimerTextFor,
  handleCancel,
  handleSubmit,
  handleFinish,
}): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const regionLocale = useUserRegionLocale()

  // state
  const [activeStep, setActiveStep] = useState<number>(0)
  const [data, setData] = useState<TableData<Program>>(DEFAULT_DATA)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const programImportSteps = ['step.uploadFile', 'step.importingData', 'step.results']

  // handle events
  const handleImportFiles = (files: File[]) => {
    setActiveStep(1)

    handleSubmit(files)
      .then((data) => {
        setData(mapData(data as Data<any>))
        setActiveStep(2)
      })
      .catch((err) => {
        const errorMessage = errorMapperReporting(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setActiveStep(2)
      })
  }

  // JSX
  const nonEmptyRows = data.data.map((item, index) => {
    const programMetadata = item.metaData

    return (
      <TableRow key={index} style={{ cursor: 'default' }}>
        <TableCell>{programMetadata.programIndex}</TableCell>
        <TableCell>{formatTemperatureOptional(programMetadata.temperature, programMetadata.temperatureUnit)}</TableCell>
        <TableCell>{programMetadata.programName}</TableCell>
        <TableCell>{getProgramTypeName(programMetadata.programType, translate)}</TableCell>
        <TableCell>{formatDateTimeForLocaleOptional(programMetadata.lastModified, regionLocale)}</TableCell>
      </TableRow>
    )
  })

  const tableHeaders = headCells.map((item) => {
    return <TableCell key={item.id}>{translate(item.label)}</TableCell>
  })

  const displayResultTable = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{tableHeaders}</TableRow>
        </TableHead>
        <TableBody>{data.totalElements !== -1 ? nonEmptyRows : <TableEmpty colspan={headCells.length} />}</TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <>
      <Divider className={sharedClasses.Divider} />
      <ScreenSteps steps={programImportSteps} activeStep={activeStep} />

      {activeStep === 0 && (
        <UploadFileView
          acceptedFileFormat={acceptedFileFormats}
          acceptedFileSizeMB={acceptedFileSizeMB}
          acceptMultipleFiles={acceptMultipleFiles}
          handleCancel={handleCancel}
          handleUploadFiles={handleImportFiles}
          disclaimerTextFor={disclaimerTextFor}
        />
      )}

      {activeStep === 1 && <LoadingIndicator loading={true} sx={{ marginLeft: 6 }} />}

      {activeStep === 2 && (
        <Box pt={4}>
          <Box pt={1} pb={3}>
            {errorMessage ? (
              <ErrorMessage message={errorMessage} />
            ) : (
              <Alert severity={'success'}>{translate('programUploadSuccess')}</Alert>
            )}

            {displayResultTable}
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
            <Button onClick={handleFinish} variant="contained">
              {translate('button.finish')}
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}
