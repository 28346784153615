import React, { FC, ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AlertColor } from '@mui/material'
import Box from '@mui/material/Box'
import locale from 'date-fns/locale/en-GB'
import { useTranslate } from 'src/i18n/useMessageSource'
import { CalendarEntriesApi, Configuration, LaundryGroupsCalendarApi } from 'src/service/backend/api'
import { CalendarEntry, CalendarEntryType, Machine } from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getEndOfWeek, getStartOfWeekUTC } from 'src/service/utils/MomentUtils'
import {
  CalendarEntrySingleDay,
  CalendarSettings,
  DEFAULT_CALENDAR_SETTINGS,
  SlotAndDay,
} from 'src/service/view-model/base/calendar/Calendar.const'
import {
  handleAnchorDate,
  onCancelConfirmDeleteModalHandle,
  onRequestReject,
  onSubmitReject,
  selectCalendarEntryForDelete,
  updateStateFromUrl,
  updateUrlFromState,
} from 'src/service/view-model/base/calendar/Calendar.event'
import {
  combineRecurringAndSingleCalendar,
  mapCalendarEntrySlotToCalendarEntry,
  slotifyEntries,
  sortCalendarEntriesByType,
} from 'src/service/view-model/base/calendar/Calendar.utils'
import { machineCalendarIsDeleteModal } from 'src/service/view-model/base/calendar/CalendarRestrictions.utils'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useDidComponentUpdate } from 'src/ui-shared/base/hooks/useDidComponentUpdate'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { Calendar } from 'src/ui-shared/calendar/Calendar'
import { CalendarModal } from 'src/ui-shared/calendar/CalendarModal'
import { WeekPicker } from 'src/ui-shared/form/control/WeekPicker'
import { MachineCalendarLegend } from 'src/ui/page/common/machine/details/MachineCalendarLegend'

interface Props {
  machine: Machine
}

if (locale.options) {
  locale.options.weekStartsOn = 1
}

export const MachineCalendarTab: FC<Props> = ({ machine }): ReactElement => {
  const translate = useTranslate()
  const location = useLocation()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const calendarEntriesApi = new CalendarEntriesApi(httpConfiguration)
  const laundryGroupsCalendarApi = new LaundryGroupsCalendarApi(httpConfiguration)

  // state
  const [weekdayCalendar, setWeekdayCalendar] = useState<CalendarEntrySingleDay[]>([])
  const [calendarSettings, setCalendarSettings] = useState<CalendarSettings>(DEFAULT_CALENDAR_SETTINGS)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false)
  const [calendarEntryForDelete, setCalendarEntryForDelete] = useState<CalendarEntry | null>(null)
  const [selectedSlotAndDay, setSelectedSlotAndDay] = useState<SlotAndDay | undefined>()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error')
  const [loading, setLoading] = useState<boolean>(false)

  // derived state
  const selectedCalendarEntryType = selectedSlotAndDay?.slot.calendarEntry?.calendarEntryType
  const machineId = machine.id
  const laundryGroupId = machine.laundryGroup?.id ? machine.laundryGroup.id : undefined

  // read state from url on component mount
  useEffect(() => {
    const updatedCalendarSettings = updateStateFromUrl(location, calendarSettings)
    setCalendarSettings(updatedCalendarSettings)
  }, [])

  // load data
  useDidComponentUpdate(() => {
    updateUrlFromState(calendarSettings)

    let active = true
    if (laundryGroupId) {
      getCalendar(active)
    } else {
      setErrorMessage(translate('unpairedMachineNoCalendar'))
      setAlertSeverity('info')
    }
    return () => {
      active = false
    }
  }, [calendarSettings.anchorDate])

  const getRecurringAndSingleCalendarRequests = (startDate: Date, endDate: Date) => {
    const recurringCalendarRequest = laundryGroupsCalendarApi.laundrygroupsLaundryGroupIdCalendarRecurringGet(
      laundryGroupId!,
    )
    const singleCalendarRequest = calendarEntriesApi.calendarViewGet(
      laundryGroupId || '',
      startDate,
      endDate,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      machineId,
    )
    return { recurringCalendarRequest, singleCalendarRequest }
  }

  const getCalendar = (active: boolean) => {
    setLoading(true)
    const startDate = getStartOfWeekUTC(calendarSettings.anchorDate)
    const endDate = getEndOfWeek(calendarSettings.anchorDate)

    const { recurringCalendarRequest, singleCalendarRequest } = getRecurringAndSingleCalendarRequests(
      startDate,
      endDate,
    )

    Promise.all([recurringCalendarRequest, singleCalendarRequest])
      .then((array) => {
        if (active) {
          const recurringCalendar = array[0].days

          // enables slotifyEntries() to draw first the Reservation than Active Usage Slots (since some of them can overlap)
          const calendarEntries = sortCalendarEntriesByType(array[1].result)
          const singleCalendar = slotifyEntries(startDate, calendarEntries)
          const combinedCalendar = combineRecurringAndSingleCalendar(recurringCalendar, singleCalendar)
          setWeekdayCalendar(combinedCalendar)
          setLoading(false)
        }
      })
      .catch((err) => {
        onRequestReject(err, translate, setErrorMessage)
        setAlertSeverity('error')
        setLoading(false)
      })
  }

  const updateCalendarEntry = (dayOfWeek: string, startTime: string, endTime: string, _isRecurring: boolean) => {
    const infoMessage = translate('appliedChanges')
    showSnackbar(infoMessage, 'info')

    const calendarEntry = mapCalendarEntrySlotToCalendarEntry(
      dayOfWeek,
      startTime,
      endTime,
      CalendarEntryType.MAINTENANCE,
      machineId,
    )

    calendarEntriesApi
      .calendarEntriesPost(calendarEntry)
      .then((_data) => {
        getCalendar(true)
      })
      .catch((err) => {
        onSubmitReject(err, translate, showSnackbar)
      })
  }

  const deleteCalendarEntry = () => {
    if (calendarEntryForDelete) {
      const calendarEntryId = calendarEntryForDelete.id
      calendarEntriesApi
        .calendarEntriesCalendarEntryIdDelete(calendarEntryId)
        .then((_data) => {
          getCalendar(true)
        })
        .catch((err) => {
          onSubmitReject(err, translate, showSnackbar)
        })
    }
    setConfirmDeleteModal(false)
  }

  // event handling
  const selectSlotAndDay = (slotAndDay: SlotAndDay) => {
    setSelectedSlotAndDay(slotAndDay)
    setOpenModal(true)
  }

  const selectSlotAndDayHandle = useCallback(selectSlotAndDay, [weekdayCalendar])

  const handleCancelModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      {errorMessage ? (
        <ErrorMessage severity={alertSeverity} message={errorMessage} />
      ) : (
        <Box pt={2}>
          <LoadingIndicator loading={loading} />
          <WeekPicker
            key={calendarSettings.anchorDate.toISOString()}
            delay={500}
            startDate={calendarSettings.anchorDate}
            onChange={(date) => handleAnchorDate(date, calendarSettings, setCalendarSettings)}
          />

          <Calendar selectSlotAndDay={selectSlotAndDayHandle} week={weekdayCalendar} legend={MachineCalendarLegend} />

          <CalendarModal
            open={openModal}
            updateCalendarEntry={updateCalendarEntry}
            slotAndDay={selectedSlotAndDay!}
            selectCalendarEntryForDelete={(calendarEntry) =>
              selectCalendarEntryForDelete(calendarEntry, setCalendarEntryForDelete, setConfirmDeleteModal)
            }
            reservationTitle={translate('createMaintenanceReservation')}
            deleteTitle={translate('maintenanceReservation')}
            handleCancelModal={handleCancelModal}
            isDeleteModal={machineCalendarIsDeleteModal(selectedCalendarEntryType)}
          />

          {/* Confirm Delete Reservation Modal */}
          <ConfirmationModalDialog
            titleKey="deleteReservation"
            confirmationKey="button.delete"
            open={confirmDeleteModal}
            onConfirm={deleteCalendarEntry}
            onCancel={() => onCancelConfirmDeleteModalHandle(setCalendarEntryForDelete, setConfirmDeleteModal)}
          >
            {translate('deleteConfirm', translate('maintenance'))}
          </ConfirmationModalDialog>
        </Box>
      )}
    </>
  )
}
