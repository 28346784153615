import React, { ReactElement } from 'react'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NotificationTable } from 'src/ui/layout-page/notifications/list/NotificationTable'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

export const NotificationListPage = (): ReactElement => {
  const translate = useTranslate()

  return (
    <ScreenLayout title={translate('notifications')}>
      <NotificationTable />
    </ScreenLayout>
  )
}
