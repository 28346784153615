import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  styled,
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

export const FormAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

export const FormAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  padding: '0',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  // shrink the button that wraps the accordion summary and limit max size for less clickable area
  width: 'fit-content',
  minWidth: '200px',
  paddingRight: '50px',
  justifyContent: 'left',
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: theme.spacing(1),
    // shink the accordion summary
    flexGrow: 0,
    flexShrink: 1,
  },
}))

export const FormAccordionDetails = styled(AccordionDetails)(() => ({
  padding: '0',
}))
