import { IdAndName } from 'src/service/view-model/base/IdAndName'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'

export interface WeightUnitObject extends IdAndName {}

export const WEIGHT_UNIT_KG: WeightUnitObject = { name: 'weightUnitKg', id: 'kg' }

export const WEIGHT_UNIT_POUNDS: WeightUnitObject = { name: 'weightUnitPound', id: 'lbs' }

export const SUPPORTED_WEIGHT_UNITS: WeightUnitObject[] = [WEIGHT_UNIT_KG, WEIGHT_UNIT_POUNDS]

export function getWeightUnitObject(weight: string | undefined): WeightUnitObject | undefined {
  return findReferenceObject(SUPPORTED_WEIGHT_UNITS, weight, 'id')
}
