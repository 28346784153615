/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SettingBitFlagsField } from './SettingBitFlagsField';
import {
    SettingBitFlagsFieldFromJSON,
    SettingBitFlagsFieldFromJSONTyped,
    SettingBitFlagsFieldToJSON,
} from './SettingBitFlagsField';
import type { SettingBooleanField } from './SettingBooleanField';
import {
    SettingBooleanFieldFromJSON,
    SettingBooleanFieldFromJSONTyped,
    SettingBooleanFieldToJSON,
} from './SettingBooleanField';
import type { SettingFieldType } from './SettingFieldType';
import {
    SettingFieldTypeFromJSON,
    SettingFieldTypeFromJSONTyped,
    SettingFieldTypeToJSON,
} from './SettingFieldType';
import type { SettingFieldValidation } from './SettingFieldValidation';
import {
    SettingFieldValidationFromJSON,
    SettingFieldValidationFromJSONTyped,
    SettingFieldValidationToJSON,
} from './SettingFieldValidation';
import type { SettingGroupField } from './SettingGroupField';
import {
    SettingGroupFieldFromJSON,
    SettingGroupFieldFromJSONTyped,
    SettingGroupFieldToJSON,
} from './SettingGroupField';
import type { SettingNumberField } from './SettingNumberField';
import {
    SettingNumberFieldFromJSON,
    SettingNumberFieldFromJSONTyped,
    SettingNumberFieldToJSON,
} from './SettingNumberField';
import type { SettingSelectField } from './SettingSelectField';
import {
    SettingSelectFieldFromJSON,
    SettingSelectFieldFromJSONTyped,
    SettingSelectFieldToJSON,
} from './SettingSelectField';
import type { SettingTextField } from './SettingTextField';
import {
    SettingTextFieldFromJSON,
    SettingTextFieldFromJSONTyped,
    SettingTextFieldToJSON,
} from './SettingTextField';

/**
 * 
 * @export
 * @interface SettingField
 */
export interface SettingField {
    /**
     * 
     * @type {string}
     * @memberof SettingField
     */
    settingId: string;
    /**
     * 
     * @type {string}
     * @memberof SettingField
     */
    label: string;
    /**
     * 
     * @type {SettingFieldType}
     * @memberof SettingField
     */
    fieldType: SettingFieldType;
    /**
     * 
     * @type {string}
     * @memberof SettingField
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingField
     */
    hint?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SettingField
     */
    readOnly?: boolean;
    /**
     * (eS) When true this setting-value will be showed in the Module List Overview
     * @type {boolean}
     * @memberof SettingField
     */
    showInOverview?: boolean;
    /**
     * (eS) Defines the position in the Module List Overview, where 0 is left most position
     * @type {number}
     * @memberof SettingField
     */
    overviewOrder?: number;
    /**
     * 
     * @type {SettingFieldValidation}
     * @memberof SettingField
     */
    validation?: SettingFieldValidation;
    /**
     * 
     * @type {SettingNumberField}
     * @memberof SettingField
     */
    numberField?: SettingNumberField;
    /**
     * 
     * @type {SettingTextField}
     * @memberof SettingField
     */
    textField?: SettingTextField;
    /**
     * 
     * @type {SettingSelectField}
     * @memberof SettingField
     */
    selectField?: SettingSelectField;
    /**
     * 
     * @type {SettingBooleanField}
     * @memberof SettingField
     */
    booleanField?: SettingBooleanField;
    /**
     * 
     * @type {SettingBitFlagsField}
     * @memberof SettingField
     */
    bitFlagsField?: SettingBitFlagsField;
    /**
     * 
     * @type {SettingGroupField}
     * @memberof SettingField
     */
    groupField?: SettingGroupField;
}

/**
 * Check if a given object implements the SettingField interface.
 */
export function instanceOfSettingField(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "settingId" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "fieldType" in value;

    return isInstance;
}

export function SettingFieldFromJSON(json: any): SettingField {
    return SettingFieldFromJSONTyped(json, false);
}

export function SettingFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settingId': json['settingId'],
        'label': json['label'],
        'fieldType': SettingFieldTypeFromJSON(json['fieldType']),
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'hint': !exists(json, 'hint') ? undefined : json['hint'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
        'showInOverview': !exists(json, 'showInOverview') ? undefined : json['showInOverview'],
        'overviewOrder': !exists(json, 'overviewOrder') ? undefined : json['overviewOrder'],
        'validation': !exists(json, 'validation') ? undefined : SettingFieldValidationFromJSON(json['validation']),
        'numberField': !exists(json, 'numberField') ? undefined : SettingNumberFieldFromJSON(json['numberField']),
        'textField': !exists(json, 'textField') ? undefined : SettingTextFieldFromJSON(json['textField']),
        'selectField': !exists(json, 'selectField') ? undefined : SettingSelectFieldFromJSON(json['selectField']),
        'booleanField': !exists(json, 'booleanField') ? undefined : SettingBooleanFieldFromJSON(json['booleanField']),
        'bitFlagsField': !exists(json, 'bitFlagsField') ? undefined : SettingBitFlagsFieldFromJSON(json['bitFlagsField']),
        'groupField': !exists(json, 'groupField') ? undefined : SettingGroupFieldFromJSON(json['groupField']),
    };
}

export function SettingFieldToJSON(value?: SettingField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settingId': value.settingId,
        'label': value.label,
        'fieldType': SettingFieldTypeToJSON(value.fieldType),
        'unit': value.unit,
        'hint': value.hint,
        'readOnly': value.readOnly,
        'showInOverview': value.showInOverview,
        'overviewOrder': value.overviewOrder,
        'validation': SettingFieldValidationToJSON(value.validation),
        'numberField': SettingNumberFieldToJSON(value.numberField),
        'textField': SettingTextFieldToJSON(value.textField),
        'selectField': SettingSelectFieldToJSON(value.selectField),
        'booleanField': SettingBooleanFieldToJSON(value.booleanField),
        'bitFlagsField': SettingBitFlagsFieldToJSON(value.bitFlagsField),
        'groupField': SettingGroupFieldToJSON(value.groupField),
    };
}

