import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Chip, Grid, Paper, TableCell, TableRow, useTheme } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  Configuration,
  IssuesApi,
  LaundriesApi,
  LaundryGroupsApi,
  MachineReference,
  MachinesApi,
  OrganizationsApi,
} from 'src/service/backend/api'
import {
  IssueEntry,
  LaundryGroupReference,
  LaundryReference,
  OrganizationReference,
} from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocale } from 'src/service/utils/DateFormatUtils'
import { getLastMonth, removeTimeFromDate, removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'
import { Data } from 'src/service/view-model/base/Data'
import { getMachineNameAndSerialNumber, getMachineOptionLabel } from 'src/service/view-model/machine/Machines'
import { EMPTY_ORGANIZATION_REF } from 'src/service/view-model/organization/Organizations'
import { getIssueCreatorTypeLabel } from 'src/service/view-model/service-request/ServiceRequestViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettingsWithOrganization,
  getDefaultTableSettings,
  tableSettingsSort,
  withOrganization,
} from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { TableFilterAutocompleteOrganization } from 'src/ui-shared/table/TableFilterAutocompleteOrganization'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { useUserRegionLocale } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'createdAt',
    label: 'date',
  },
  {
    id: 'description',
    label: 'description',
    noSort: true,
  },
  {
    id: 'organization',
    label: 'organization',
    noSort: true,
  },
  {
    id: 'laundryGroup',
    label: 'laundryGroup',
    noSort: true,
  },
  {
    id: 'laundry',
    label: 'laundry',
    noSort: true,
  },
  {
    id: 'machine',
    label: 'machine',
    noSort: true,
  },
  {
    id: 'issueCategory.issueCategoryKey',
    label: 'category',
  },
  {
    id: 'issueCreatorType',
    label: 'issueCreatorType',
    noSort: true,
  },
  {
    id: 'issueState',
    label: 'status',
  },
]

interface ServiceRequestTableSettings extends TableSettingsWithOrganization {
  orderBy: string
  startTimeFrom?: string
  startTimeTo?: string
  laundryGroupId?: string
  laundryId?: string
  machineId?: string
}

interface Props {
  navigateToItem: (machineId?: string) => void
}

const dateNow = removeTimeFromDateEndOfDay(new Date())
const dateLastMonth = removeTimeFromDate(getLastMonth(dateNow))

export const ServiceRequestTable: React.FC<Props> = ({ navigateToItem }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  const location = useLocation()
  const theme = useTheme()

  const activeOrganization = useActiveOrganization()
  const regionLocale = useUserRegionLocale()

  const translate = useTranslate()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const serviceRequestsApi = new IssuesApi(httpConfiguration)
  const organizationsApi = new OrganizationsApi(httpConfiguration)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)
  const laundriesApi = new LaundriesApi(httpConfiguration)
  const machinesApi = new MachinesApi(httpConfiguration)

  const [data, setData] = useState<TableData<IssueEntry>>(DEFAULT_DATA)
  const defaultTableSettings: ServiceRequestTableSettings = {
    ...getDefaultTableSettings(),
    startTimeFrom: dateLastMonth.toISOString(),
    startTimeTo: dateNow.toISOString(),
    orderBy: 'createdAt',
    orderDir: 'desc',
  }
  const [tableSettings, setTableSettings] = useState<ServiceRequestTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [organizations, setOrganizations] = useState<OrganizationReference[]>([])
  const [laundryGroups, setLaundryGroups] = useState<LaundryGroupReference[]>([])
  const [laundries, setLaundries] = useState<LaundryReference[]>([])
  const [machines, setMachines] = useState<MachineReference[]>([])

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)

  // reactivity

  // load data for filters
  useEffect(() => {
    organizationsApi.organizationsRefGet().then((data) => {
      data.unshift(EMPTY_ORGANIZATION_REF)
      setOrganizations(data)
    })

    laundryGroupsApi.laundrygroupsRefGet().then((data) => {
      setLaundryGroups(data)
    })

    laundriesApi.laundriesRefGet().then((data) => {
      setLaundries(data)
    })

    machinesApi.machinesRefGet().then((data) => {
      setMachines(data as MachineReference[])
    })
  }, [])

  // load data on state change
  useEffect(() => {
    let active = true

    if (tableSettings.startTimeFrom && tableSettings.startTimeTo) {
      setLoading(true)
      const dateStartTimeFrom = new Date(tableSettings.startTimeFrom)
      const dateStartTimeTo = new Date(tableSettings.startTimeTo)

      serviceRequestsApi
        .issuesGet(
          dateStartTimeFrom,
          dateStartTimeTo,
          tableSettings.size,
          tableSettings.page,
          tableSettingsSort(tableSettings),
          tableSettings.search,
          tableSettings.organizationId,
          tableSettings.laundryGroupId,
          tableSettings.laundryId,
          tableSettings.machineId,
        )
        .then((data) => {
          if (active) {
            updateTableSettingsFromData(data as Data<any>, tableSettings)
            setTableSettings(tableSettings)

            setData(mapData(data as Data<any>))
            setErrorMessage(null)
            setLoading(false)
          }
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setData(DEFAULT_DATA)
          setLoading(false)
        })
    }

    return () => {
      active = false
    }
  }, [tableSettings])

  const getChipColor = (issueColor?: string) => {
    const bgColor = issueColor || theme.palette.primary.main
    return { backgroundColor: bgColor, color: theme.palette.getContrastText(bgColor) }
  }

  const nonEmptyRows = data.data.map((item) => {
    return (
      <TableRow className={tableClasses.tableRow} key={item.id} onClick={navigateToItem.bind(this, item.id)}>
        <TableCell>{formatDateTimeForLocale(item.creationTimestamp, regionLocale)}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={35} value={item.description} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={item.organization?.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={item.laundryGroup?.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={item.laundry?.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={getMachineNameAndSerialNumber(item.machine)} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={17} value={item.category?.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={15} value={getIssueCreatorTypeLabel(item.issueCreatorType, translate)} />
        </TableCell>
        <TableCell style={{ paddingTop: '0px', paddingBottom: '0px' }}>
          <Chip sx={getChipColor(item.issueState.color)} label={item.issueState.text} />
        </TableCell>
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string): ReactElement | undefined => {
    let filter
    if (headCellId === 'organization') {
      filter = (
        <TableFilterAutocompleteOrganization
          options={organizations}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'laundryGroup') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundryGroup')}
          label={translate('laundryGroups')}
          filter={'laundryGroupId'}
          labelFieldName="name"
          valueFieldName="id"
          options={laundryGroups}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'laundry') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundry')}
          label={translate('laundries')}
          filter={'laundryId'}
          labelFieldName="name"
          valueFieldName="id"
          options={laundries}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'machine') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByMachine')}
          label={translate('machines')}
          filter={'machineId'}
          labelFieldName="name"
          valueFieldName="id"
          getOptionLabel={getMachineOptionLabel}
          options={machines}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }
    return filter
  }

  return (
    <Paper elevation={0}>
      <TableSearchForm handleSearch={handleSearch} inputSearchValue={inputSearchValue} />
      <Box mt={3} mb={3}>
        <Grid container spacing={2}>
          <TableDatePickerForm
            key={`${tableSettings.startTimeFrom}${tableSettings.startTimeTo}`}
            firstDatePropName="startTimeFrom"
            secondDatePropName="startTimeTo"
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
          />
        </Grid>
      </Box>

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        getFilter={getFilter}
        loading={loading}
        translate={translate}
        style={{
          whiteSpace: 'nowrap',
        }}
      />
    </Paper>
  )
}
