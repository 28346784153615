import { Path, Resolver } from 'react-hook-form'
import { Program, ProgramModuleSettings, ProgramType, SettingField, SettingFieldType } from 'src/service/backend/api'
import {
  required,
  validMaxChar,
  validMin,
  validNumber,
  validateDynamicFields,
} from 'src/service/view-model/setting-field/SettingFieldValidation'
import { HookFormFieldError, HookFormFieldErrorWhere } from 'src/ui-shared/base/form/validation/HookFormFieldError'
import { TEXT_LENGTH } from 'src/ui-shared/constants/Constants'

export const programValidationResolver: Resolver<Program> = (values, _context, { names }) => {
  let errors: Record<string, HookFormFieldError> = {}
  const program = values
  const moduleSteps = program.details?.steps

  if (names) {
    if (moduleSteps) {
      errors = { ...errors, ...validateProgramModules(moduleSteps) }
    }
    errors = { ...errors, ...validateProgramSettings(program) }
  }

  return { values, errors }
}

export const validateProgramModules = (modules: ProgramModuleSettings[]): Record<string, HookFormFieldError> => {
  let errors: Record<string, HookFormFieldError> = {}
  const section = 'modulesOnPosition'

  modules.forEach((step, stepIndex) => {
    errors = {
      ...errors,
      ...validateDynamicFields<Program>(step.settings, getNameForDynamicFieldTypeForProgram, section, stepIndex),
    }
  })

  return errors
}

export const validateProgramSettings = (program: Program): Record<string, HookFormFieldError> => {
  let errors: Record<string, HookFormFieldError> = {}

  const programMetaData = program.metaData
  const programSettingsDynamicFields = program.details?.programSettings ?? []

  const isCustomProgram = programMetaData.programType === ProgramType.CUSTOM

  const section = 'settings'
  const where: HookFormFieldErrorWhere = {
    section: section,
  }

  const validateMinIndex = isCustomProgram
    ? validMin('metaData.programIndex', 400, where, programMetaData.programIndex)
    : undefined

  errors = {
    ...errors,
    ...validateMinIndex,
    ...validNumber<Program>('metaData.programIndex', programMetaData.programIndex, where),
    ...required<Program>('metaData.programIndex', programMetaData.programIndex, where),
    ...required<Program>('metaData.programName', programMetaData.programName, where),
    ...required<Program>('metaData.programType', programMetaData.programType, where),
    ...required<Program>('metaData.machineType', programMetaData.machineType, where),
    ...validMin<Program>('metaData.temperature', 0, where, programMetaData.temperature),
    ...validNumber('metaData.temperature', programMetaData.temperature, where),
    ...validMaxChar<Program>(
      'metaData.description',
      TEXT_LENGTH.PROGRAM_DESCRIPTION,
      where,
      programMetaData.description,
    ),
    ...validateDynamicFields<Program>(programSettingsDynamicFields, getNameForDynamicFieldTypeForProgram, section),
  }

  return errors
}

export const getNameForDynamicFieldTypeForProgram = (
  field: SettingField,
  fieldIndex: number,
  stepIndex?: number,
): Path<Program> => {
  const stepIndexIsEmpty = stepIndex === undefined

  if (field.fieldType === SettingFieldType.NUMBER && field.numberField) {
    return stepIndexIsEmpty
      ? `details.programSettings.${fieldIndex}.numberField.data`
      : `details.steps.${stepIndex}.settings.${fieldIndex}.numberField.data`
  } else if (field.fieldType === SettingFieldType.SELECT && field.selectField) {
    return stepIndexIsEmpty
      ? `details.programSettings.${fieldIndex}.selectField.data`
      : `details.steps.${stepIndex}.settings.${fieldIndex}.selectField.data`
  } else if (field.fieldType === SettingFieldType.BOOLEAN && field.booleanField) {
    return stepIndexIsEmpty
      ? `details.programSettings.${fieldIndex}.booleanField.data`
      : `details.steps.${stepIndex}.settings.${fieldIndex}.booleanField.data`
  } else if (field.fieldType === SettingFieldType.TEXT && field.textField) {
    return stepIndexIsEmpty
      ? `details.programSettings.${fieldIndex}.textField.data`
      : `details.steps.${stepIndex}.settings.${fieldIndex}.textField.data`
  } else {
    throw new Error('Field type is not recognized or is null')
  }
}
