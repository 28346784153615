/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AppId,
  Country,
  Organization,
  OrganizationReference,
  OrganizationType,
  PageableOrganizations,
} from '../models';
import {
    AppIdFromJSON,
    AppIdToJSON,
    CountryFromJSON,
    CountryToJSON,
    OrganizationFromJSON,
    OrganizationToJSON,
    OrganizationReferenceFromJSON,
    OrganizationReferenceToJSON,
    OrganizationTypeFromJSON,
    OrganizationTypeToJSON,
    PageableOrganizationsFromJSON,
    PageableOrganizationsToJSON,
} from '../models';

export interface OrganizationsGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    id?: string;
    countryCode?: Country;
    type?: Array<OrganizationType>;
    appId?: Array<AppId>;
    ifsCustomerNumber?: string;
    vatNumber?: string;
}

export interface OrganizationsOrganizationIdDeleteRequest {
    organizationId: string;
}

export interface OrganizationsOrganizationIdGetRequest {
    organizationId: string;
    appId?: Array<AppId>;
}

export interface OrganizationsOrganizationIdPutRequest {
    organizationId: string;
    organization?: Organization;
}

export interface OrganizationsPostRequest {
    organization?: Organization;
}

export interface OrganizationsRefGetRequest {
    size?: number;
    search?: string;
    type?: Array<OrganizationType>;
}

/**
 * 
 */
export class OrganizationsApi extends runtime.BaseAPI {

    /**
     * List Organizations
     */
    async organizationsGetRaw(requestParameters: OrganizationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableOrganizations>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.countryCode !== undefined) {
            queryParameters['countryCode'] = requestParameters.countryCode;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        if (requestParameters.ifsCustomerNumber !== undefined) {
            queryParameters['ifsCustomerNumber'] = requestParameters.ifsCustomerNumber;
        }

        if (requestParameters.vatNumber !== undefined) {
            queryParameters['vatNumber'] = requestParameters.vatNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableOrganizationsFromJSON(jsonValue));
    }

    /**
     * List Organizations
     */
    async organizationsGet(size?: number, page?: number, sort?: string, search?: string, id?: string, countryCode?: Country, type?: Array<OrganizationType>, appId?: Array<AppId>, ifsCustomerNumber?: string, vatNumber?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableOrganizations> {
        const response = await this.organizationsGetRaw({ size: size, page: page, sort: sort, search: search, id: id, countryCode: countryCode, type: type, appId: appId, ifsCustomerNumber: ifsCustomerNumber, vatNumber: vatNumber }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a Organization
     */
    async organizationsOrganizationIdDeleteRaw(requestParameters: OrganizationsOrganizationIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Organization
     */
    async organizationsOrganizationIdDelete(organizationId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.organizationsOrganizationIdDeleteRaw({ organizationId: organizationId }, initOverrides);
    }

    /**
     * Get an Organization
     */
    async organizationsOrganizationIdGetRaw(requestParameters: OrganizationsOrganizationIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organization>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
    }

    /**
     * Get an Organization
     */
    async organizationsOrganizationIdGet(organizationId: string, appId?: Array<AppId>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organization> {
        const response = await this.organizationsOrganizationIdGetRaw({ organizationId: organizationId, appId: appId }, initOverrides);
        return await response.value();
    }

    /**
     * Update a Organization
     */
    async organizationsOrganizationIdPutRaw(requestParameters: OrganizationsOrganizationIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organization>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationToJSON(requestParameters.organization),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
    }

    /**
     * Update a Organization
     */
    async organizationsOrganizationIdPut(organizationId: string, organization?: Organization, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organization> {
        const response = await this.organizationsOrganizationIdPutRaw({ organizationId: organizationId, organization: organization }, initOverrides);
        return await response.value();
    }

    /**
     * Create a Organization (Only Service Partner allowed)
     */
    async organizationsPostRaw(requestParameters: OrganizationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organization>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationToJSON(requestParameters.organization),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
    }

    /**
     * Create a Organization (Only Service Partner allowed)
     */
    async organizationsPost(organization?: Organization, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organization> {
        const response = await this.organizationsPostRaw({ organization: organization }, initOverrides);
        return await response.value();
    }

    /**
     * List Organizations References
     */
    async organizationsRefGetRaw(requestParameters: OrganizationsRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrganizationReference>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationReferenceFromJSON));
    }

    /**
     * List Organizations References
     */
    async organizationsRefGet(size?: number, search?: string, type?: Array<OrganizationType>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrganizationReference>> {
        const response = await this.organizationsRefGetRaw({ size: size, search: search, type: type }, initOverrides);
        return await response.value();
    }

}
