import React from 'react'
import { CircularProgress, SxProps, Theme, styled } from '@mui/material'

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  position: 'absolute',
  top: '44%',
  left: '44%',
}))

interface Props {
  loading: boolean
  sx?: SxProps<Theme> | undefined
}

export const LoadingIndicator: React.FC<Props> = ({ loading, sx }) => {
  return loading ? <StyledCircularProgress color="primary" size={50} sx={sx} /> : null
}
