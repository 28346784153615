/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface PrepaidBankTransferMailingResponse
 */
export interface PrepaidBankTransferMailingResponse {
    /**
     * Amount to be printed on the payment slips
     * @type {number}
     * @memberof PrepaidBankTransferMailingResponse
     */
    readonly amount: number;
    /**
     * 
     * @type {Currency}
     * @memberof PrepaidBankTransferMailingResponse
     */
    currency: Currency;
    /**
     * Administrative fee for mailing service
     * @type {number}
     * @memberof PrepaidBankTransferMailingResponse
     */
    readonly fee?: number;
}

/**
 * Check if a given object implements the PrepaidBankTransferMailingResponse interface.
 */
export function instanceOfPrepaidBankTransferMailingResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;

    return isInstance;
}

export function PrepaidBankTransferMailingResponseFromJSON(json: any): PrepaidBankTransferMailingResponse {
    return PrepaidBankTransferMailingResponseFromJSONTyped(json, false);
}

export function PrepaidBankTransferMailingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrepaidBankTransferMailingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'currency': CurrencyFromJSON(json['currency']),
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
    };
}

export function PrepaidBankTransferMailingResponseToJSON(value?: PrepaidBankTransferMailingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': CurrencyToJSON(value.currency),
    };
}

