import { LaundryGroupRecurringCalendarEntry, OpeningType, PriceCondition } from 'src/service/backend/api'
import { formatTime } from 'src/service/utils/DateFormatUtils'
import { DateUtils } from 'src/service/utils/DateUtils'
import { calculateDuration } from 'src/service/utils/MomentUtils'

export interface LaundryGroupRecurringCalendarViewModel {
  dayOfWeek: number
  startTime: string
  endTime: string
  allDay: boolean
  openingType: OpeningType
  priceCondition?: PriceCondition
}

export const createLaundryGroupRecurringCalendarViewModel = (
  calendarEntry: LaundryGroupRecurringCalendarEntry,
  noAdHockOpeningType: boolean,
): LaundryGroupRecurringCalendarViewModel => {
  let openingType = calendarEntry.openingType
  if (noAdHockOpeningType && openingType == OpeningType.AD_HOC) {
    openingType = OpeningType.FREE
  }

  const endTimeAsDate = DateUtils.parseTime(calendarEntry.from)
  const endTimeIncremented = DateUtils.increment(endTimeAsDate, { minutes: 30 })
  const endTimeString = formatTime(endTimeIncremented)

  return {
    dayOfWeek: calendarEntry.dayOfWeek,
    startTime: calendarEntry.from,
    endTime: endTimeString,
    allDay: false,
    openingType: openingType,
    priceCondition: calendarEntry.priceCondition,
  } as LaundryGroupRecurringCalendarViewModel
}

export const mapLaundryGroupRecurringViewModelToCalendarEntry = (
  viewModel: LaundryGroupRecurringCalendarViewModel,
): LaundryGroupRecurringCalendarEntry => {
  const allDay = viewModel.allDay
  const startTime = allDay ? '00:00' : viewModel.startTime
  const duration = allDay ? 1440 : calculateDuration(viewModel.startTime, viewModel.endTime)
  return {
    dayOfWeek: viewModel.dayOfWeek,
    from: startTime,
    duration: duration,
    openingType: viewModel.openingType,
    priceCondition: viewModel.priceCondition,
  } as LaundryGroupRecurringCalendarEntry
}
