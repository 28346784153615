/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MachineType {
    SMAG_STANDARD_WA = 'SMAG_STANDARD_WA',
    SMAG_STANDARD_WA24 = 'SMAG_STANDARD_WA24',
    SMAG_STANDARD_WT = 'SMAG_STANDARD_WT',
    SMAG_PROFESSIONAL_WA = 'SMAG_PROFESSIONAL_WA',
    SMAG_PROFESSIONAL_WA24 = 'SMAG_PROFESSIONAL_WA24',
    SMAG_PROFESSIONAL_WT = 'SMAG_PROFESSIONAL_WT',
    SMAG_PROFESSIONAL_WE20 = 'SMAG_PROFESSIONAL_WE20',
    SMAG_PROFESSIONAL_WT20 = 'SMAG_PROFESSIONAL_WT20',
    SMAG_PROFESSIONAL_WE20_24_33 = 'SMAG_PROFESSIONAL_WE20_24_33',
    SMAG_PROFESSIONAL_WT20_24_33 = 'SMAG_PROFESSIONAL_WT20_24_33',
    OTHERS_STANDARD_WA = 'OTHERS_STANDARD_WA',
    OTHERS_STANDARD_WT = 'OTHERS_STANDARD_WT',
    OTHERS_STANDARD_RLWT = 'OTHERS_STANDARD_RLWT',
    OTHERS_STANDARD_WAT = 'OTHERS_STANDARD_WAT',
    SMAG_DOS = 'SMAG_DOS',
    GMP_ROT_IRON = 'GMP_ROT_IRON',
    OTHERS_IRON_ASSET = 'OTHERS_IRON_ASSET',
    OTHERS_WA_ASSET = 'OTHERS_WA_ASSET',
    OTHERS_WT_ASSET = 'OTHERS_WT_ASSET',
    OTHERS_FIXED_ASSET = 'OTHERS_FIXED_ASSET'
}


export function MachineTypeFromJSON(json: any): MachineType {
    return MachineTypeFromJSONTyped(json, false);
}

export function MachineTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineType {
    return json as MachineType;
}

export function MachineTypeToJSON(value?: MachineType | null): any {
    return value as any;
}

