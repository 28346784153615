import React, { PropsWithChildren, ReactElement, useContext, useEffect } from 'react'
import { EnvironmentContext } from 'src/env/EnvironmentContext'

export const GoogleAnalyticsLoader = ({ children }: PropsWithChildren): ReactElement => {
  const { googleAnalyticsTrackingId } = useContext(EnvironmentContext)

  useEffect(() => {
    if (googleAnalyticsTrackingId) {
      initializeGoogleAnalyticsScript(googleAnalyticsTrackingId)
    } else {
      console.info('Google Analytics Tracking ID is not setup')
    }
  }, [])

  return <>{children}</>
}

const initializeGoogleAnalyticsScript = (trackingId: string) => {
  const script = document.createElement('script')
  const secondScript = document.createElement('script')
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!script || !secondScript) {
    console.warn('Could not create Google Analytics script elements in document')
    return
  }
  script.async = true
  script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingId}`)

  secondScript.text = `window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    window.gtag = gtag
    gtag('js', new Date())

    gtag('config', '${trackingId}', { send_page_view: false })`

  const headElement = document.head
  headElement.appendChild(script)
  headElement.appendChild(secondScript)
}
