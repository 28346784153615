/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DashboardIssuesCount } from './DashboardIssuesCount';
import {
    DashboardIssuesCountFromJSON,
    DashboardIssuesCountFromJSONTyped,
    DashboardIssuesCountToJSON,
} from './DashboardIssuesCount';

/**
 * 
 * @export
 * @interface DashboardIssuesCountList
 */
export interface DashboardIssuesCountList {
    /**
     * 
     * @type {Array<DashboardIssuesCount>}
     * @memberof DashboardIssuesCountList
     */
    data: Array<DashboardIssuesCount>;
}

/**
 * Check if a given object implements the DashboardIssuesCountList interface.
 */
export function instanceOfDashboardIssuesCountList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function DashboardIssuesCountListFromJSON(json: any): DashboardIssuesCountList {
    return DashboardIssuesCountListFromJSONTyped(json, false);
}

export function DashboardIssuesCountListFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardIssuesCountList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(DashboardIssuesCountFromJSON)),
    };
}

export function DashboardIssuesCountListToJSON(value?: DashboardIssuesCountList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(DashboardIssuesCountToJSON)),
    };
}

