import { formatDistanceStrict } from 'date-fns'
import { getDateFnsLocale } from 'src/service/utils/DateFnsLocales'
import { formatDateTimeSecondsForLocale } from 'src/service/utils/DateFormatUtils'
import { SupportedLocale } from 'src/service/view-model/base/localization/SupportedLocale'

export interface ChartRelativeTimeSettings {
  locale: string
  startDate: Date
}

export const getColoredCustomTooltip = (
  regionLocale: SupportedLocale,
  relativeTimeSettings?: ChartRelativeTimeSettings,
): ApexTooltip => {
  const customTooltip: ApexTooltip = {
    custom: function (params) {
      const { seriesIndex, dataPointIndex, w, series } = params

      const time = w.globals.seriesX[seriesIndex][dataPointIndex]
      const date = new Date(time)

      // labels
      let relativeTime = ''
      // display the relative duration
      if (relativeTimeSettings) {
        const startDate = relativeTimeSettings.startDate
        const duration = formatDistanceStrict(startDate, date, {
          locale: getDateFnsLocale(relativeTimeSettings.locale),
          roundingMethod: 'floor',
        })
        relativeTime = `<div>${duration}</div>`
      }

      const seriesXLabel = formatDateTimeSecondsForLocale(date, regionLocale) + ' ' + (relativeTime ? relativeTime : '')
      let seriesYLabel = ''

      // loop all known series
      for (let index = 0; index < series.length; index++) {
        // skip hidden series
        if (w.globals.collapsedSeriesIndices && w.globals.collapsedSeriesIndices.includes(index)) {
          continue
        }

        const serie = series[index]
        const seriesLabelColor = w.globals.seriesColors[index]
        const seriesLabel = w.globals.initialSeries[index].name + ': '

        let value = undefined

        // for the series that the user hovers onto, get the value directly
        if (index === seriesIndex) {
          const seriesValue = serie[dataPointIndex]
          value = seriesValue
        } else {
          // for the other series, aproximate the value based on the time
          // find the value closest in time
          // start from oldest time point until we reach the current selected time
          const data = w.globals.initialSeries[index].data

          if (data.length > 0) {
            // initialize the value to the first value in the data
            const firstValue = data[0][1]
            value = firstValue

            for (let i = 0; i < data.length; i++) {
              const singleDataPoint = data[i]
              const seriesTime = singleDataPoint[0]
              if (seriesTime > time) {
                break
              }
              const seriesValue = singleDataPoint[1]
              value = seriesValue
            }
          }
        }

        if (value !== undefined) {
          seriesYLabel +=
            `<div><span class="apexcharts-legend-marker" style="background: ${seriesLabelColor}; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-radius: 12px;"></span>` +
            `<span>${seriesLabel}</span>${value}</div>`
        }
      }

      return '<div class="apexcharts-custom-tooltip">' + seriesXLabel + seriesYLabel + '</div>'
    },
  }

  return customTooltip
}
