import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Icon } from '@mdi/react'
import { Divider, Paper } from '@mui/material'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import { FormatListNumberedRtl } from '@mui/icons-material'
import { mdiAlertOutline, mdiChartTimelineVariantShimmer, mdiFormatListGroupPlus, mdiTestTube } from '@mdi/js'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { useIsProdEnvironment } from 'src/env/EnvironmentContext'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, MachinesApi } from 'src/service/backend/api'
import { Machine, Permission } from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { mapMachineToRemoveTagMachineViewModel } from 'src/service/view-model/machine/MachineViewModel'
import { isConnectedMachineType, isIntegratedMachineType } from 'src/service/view-model/machine/Machines'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { StyledTab } from 'src/ui-shared/base/tab-panel/StyledTab'
import { StyledTabs } from 'src/ui-shared/base/tab-panel/StyledTabs'
import { TabPanel } from 'src/ui-shared/base/tab-panel/TabPanel'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ArticleIcon, CalendarMonthIcon, CloudUploadOutlinedIcon, SettingsIcon } from 'src/ui-shared/icons/Icons'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { MachineCalendarTab } from 'src/ui/page/common/machine/details/MachineCalendarTab'
import { MachineErrorCodesTab } from 'src/ui/page/common/machine/details/MachineErrorCodesTab'
import { MachineInsightsTab } from 'src/ui/page/common/machine/details/MachineInsightsTab'
import { MachineLabActionsTab } from 'src/ui/page/common/machine/details/MachineLabActionsTab'
import { MachineOverviewTab } from 'src/ui/page/common/machine/details/MachineOverviewTab'
import { MachineParametersTab } from 'src/ui/page/common/machine/details/MachineParametersTab'
import { MachineProgramInstallationTab } from 'src/ui/page/common/machine/details/MachineProgramInstallationTab'
import { MachineProgramTab } from 'src/ui/page/common/machine/details/MachineProgramTab'
import { MachineProgramUsageTab } from 'src/ui/page/common/machine/details/MachineProgramUsageTab'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const MachineDetailsPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()

  const user = useUser()
  const navigate = useNavigate()
  const appId = useAppId()
  const isProdEnvironment = useIsProdEnvironment()

  const location = useLocation()
  const showSnackbar = useShowSnackbar()

  const state = location.state as NavigateState | undefined
  const { machineId, tabNameParam } = useRequiredParams(['machineId', 'tabNameParam'])
  const tabName = getEnumFromString(tabNameParam, MachineTab)

  const httpConfiguration: Configuration = useContext(HttpContext)
  const machinesApi = new MachinesApi(httpConfiguration)

  // state
  const [activeTab, setActiveTab] = useState<MachineTab>(MachineTab.OVERVIEW)
  const [machine, setMachine] = useState<Machine | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [removeCodeModal, setRemoveCodeModal] = useState<boolean>(false)
  const [unpairModal, setUnpairModal] = useState<boolean>(false)
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const [browserHistoryBack, setBrowserHistoryBack] = useState<boolean>()

  const isConnectedMachine = isConnectedMachineType(machine?.machineConnectivityType)
  const isIntegratedMachine = isIntegratedMachineType(machine?.machineConnectivityType)
  const hasCalendarReadPermission = hasPermission(user, Permission.CALENDAR_ENTRY_READ)
  const hasLabActionsPermission = hasPermission(user, Permission.MACHINE_LAB_ACTIONS)

  // load data
  useEffect(() => {
    setErrorMessage(null)

    machinesApi
      .machinesMachineIdGet(machineId, [])
      .then((machine) => {
        setMachine(machine)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })

    setBrowserHistoryBack(state?.browserHistoryBack)
    setActiveTab(tabName)
  }, [machineId])

  // handle events
  const handleTabChange = (event: React.SyntheticEvent, newValue: MachineTab) => {
    const pathToNavigate = `/${appId}/machines/${machineId}/view/${newValue}`
    navigate(pathToNavigate, { replace: true })
    setActiveTab(newValue)
  }

  const navigateToEditPage = () => {
    navigate(`/${appId}/machines/${machineId}/edit`)
  }

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/machines`)
  }

  const onQrCodeRemoveHandler = () => {
    const viewModel = mapMachineToRemoveTagMachineViewModel(machine as Machine)
    machinesApi
      .machinesMachineIdPut(machineId, [], viewModel)
      .then((machine) => {
        setMachine(machine)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
    setRemoveCodeModal(false)
  }

  const onMachineUnpairHandler = () => {
    machinesApi
      .machinesMachineIdUnpairPost(machineId)
      .then(() => {
        navigate(`/${appId}/machines`)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  const onRemoveHandler = () => {
    machinesApi
      .machinesMachineIdDelete(machineId)
      .then(() => {
        navigate(`/${appId}/machines`)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
    setRemoveModal(false)
  }

  const onActionStartHandler = () => {
    machinesApi
      .machinesMachineIdStartPost(machineId, undefined)
      .then(() => {
        showSnackbar(translate('machineStartSuccess'), 'success')
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
    setRemoveModal(false)
  }
  // render
  let machineOverview = null
  let machineCalendar = null
  let machineErrorCodes = null
  let machinePrograms = null
  let machineProgramHistory = null
  let machineLabActions = null
  let machineParameters = null
  let machineInsights = null
  let machineProgramInstallations

  if (machine !== null) {
    machineOverview = (
      <MachineOverviewTab
        onEditHandle={navigateToEditPage}
        removeCodeModal={removeCodeModal}
        setRemoveCodeModal={setRemoveCodeModal}
        onQrCodeRemoveHandler={onQrCodeRemoveHandler}
        unpairModal={unpairModal}
        setUnpairModal={setUnpairModal}
        onMachineUnpairHandler={onMachineUnpairHandler}
        removeModal={removeModal}
        setRemoveModal={setRemoveModal}
        onRemoveHandler={onRemoveHandler}
        onActionStartHandler={onActionStartHandler}
        machine={machine}
      />
    )
    machineCalendar = <MachineCalendarTab machine={machine} />
    machineErrorCodes = <MachineErrorCodesTab machine={machine} />
    machinePrograms = <MachineProgramTab machine={machine} />
    machineProgramHistory = <MachineProgramUsageTab machine={machine} />
    machineLabActions = <MachineLabActionsTab machine={machine} />
    machineParameters = <MachineParametersTab machine={machine} />
    machineInsights = <MachineInsightsTab machine={machine} />
    machineProgramInstallations = <MachineProgramInstallationTab machine={machine} />
  }

  return (
    <ScreenLayout title={machine?.name ? machine.name : ''} onBack={navigateBack} actionsWidth={50}>
      <Paper elevation={0}>
        {isConnectedMachine && (
          <Grid container className={sharedClasses.TabsContainer}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <StyledTabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={handleTabChange}
              >
                <StyledTab
                  icon={<ArticleIcon />}
                  iconPosition={'start'}
                  label={<Hidden mdDown>{translate('company.overview')}</Hidden>}
                  value={MachineTab.OVERVIEW}
                />
                {appId === AppId.SERVICE_MASTER && (
                  <StyledTab
                    icon={<Icon path={mdiAlertOutline} size={1} />}
                    iconPosition={'start'}
                    label={<Hidden mdDown>{translate('errorCodes')}</Hidden>}
                    value={MachineTab.ERROR_CODES}
                  />
                )}
                {appId === AppId.SERVICE_MASTER && (
                  <StyledTab
                    icon={<Icon path={mdiFormatListGroupPlus} size={1} />}
                    iconPosition={'start'}
                    label={<Hidden mdDown>{translate('programs')}</Hidden>}
                    value={MachineTab.PROGRAMS}
                  />
                )}
                {appId === AppId.SERVICE_MASTER && (
                  <StyledTab
                    icon={<CloudUploadOutlinedIcon />}
                    iconPosition={'start'}
                    label={<Hidden lgDown>{translate('programUploads')}</Hidden>}
                    value={MachineTab.PROGRAM_INSTALLATIONS}
                  />
                )}
                {appId === AppId.SERVICE_MASTER && (
                  <StyledTab
                    icon={<FormatListNumberedRtl />}
                    iconPosition={'start'}
                    label={<Hidden mdDown>{translate('programUsages')}</Hidden>}
                    value={MachineTab.PROGRAM_HISTORY}
                  />
                )}
                {appId === AppId.SERVICE_MASTER && hasLabActionsPermission && isIntegratedMachine && (
                  <StyledTab
                    icon={<Icon path={mdiTestTube} size={1} />}
                    iconPosition={'start'}
                    label={<Hidden mdDown>{translate('labActionsTabLabel')}</Hidden>}
                    value={MachineTab.LAB_ACTIONS}
                  />
                )}
                {appId === AppId.WASH_MASTER && hasCalendarReadPermission ? (
                  <StyledTab
                    icon={<CalendarMonthIcon />}
                    iconPosition={'start'}
                    label={<Hidden mdDown>{translate('laundryGroupCalendar')}</Hidden>}
                    value={MachineTab.CALENDAR}
                  />
                ) : null}

                <StyledTab
                  icon={<SettingsIcon />}
                  iconPosition={'start'}
                  label={<Hidden mdDown>{translate('parameters')}</Hidden>}
                  value={MachineTab.PARAMETERS}
                />
                <StyledTab
                  icon={<Icon path={mdiChartTimelineVariantShimmer} size={1} />}
                  iconPosition={'start'}
                  label={<Hidden mdDown>{translate('machineInsights')}</Hidden>}
                  value={MachineTab.MACHINE_INSIGHTS}
                />
              </StyledTabs>
            </Grid>
          </Grid>
        )}
        <Divider />
        <TabPanel value={activeTab} index={MachineTab.OVERVIEW}>
          {errorMessage ? <ErrorMessage message={errorMessage} /> : machineOverview}
        </TabPanel>
        {isConnectedMachine && (
          <>
            <TabPanel value={activeTab} index={MachineTab.CALENDAR}>
              {errorMessage ? <ErrorMessage message={errorMessage} /> : machineCalendar}
            </TabPanel>
            {appId === AppId.SERVICE_MASTER && (
              <TabPanel value={activeTab} index={MachineTab.ERROR_CODES}>
                {errorMessage ? <ErrorMessage message={errorMessage} /> : machineErrorCodes}
              </TabPanel>
            )}
            {appId === AppId.SERVICE_MASTER && (
              <TabPanel value={activeTab} index={MachineTab.PROGRAMS}>
                {errorMessage ? <ErrorMessage message={errorMessage} /> : machinePrograms}
              </TabPanel>
            )}

            <TabPanel value={activeTab} index={MachineTab.PARAMETERS}>
              {errorMessage ? <ErrorMessage message={errorMessage} /> : machineParameters}
            </TabPanel>

            {appId === AppId.SERVICE_MASTER && (
              <TabPanel value={activeTab} index={MachineTab.PROGRAM_HISTORY}>
                {errorMessage ? <ErrorMessage message={errorMessage} /> : machineProgramHistory}
              </TabPanel>
            )}
            {appId === AppId.SERVICE_MASTER && hasLabActionsPermission && isIntegratedMachine && (
              <TabPanel value={activeTab} index={MachineTab.LAB_ACTIONS}>
                {errorMessage ? <ErrorMessage message={errorMessage} /> : machineLabActions}
              </TabPanel>
            )}
            {appId === AppId.SERVICE_MASTER && (
              <TabPanel value={activeTab} index={MachineTab.PROGRAM_INSTALLATIONS}>
                {errorMessage ? <ErrorMessage message={errorMessage} /> : machineProgramInstallations}
              </TabPanel>
            )}
            <TabPanel value={activeTab} index={MachineTab.MACHINE_INSIGHTS}>
              {errorMessage ? <ErrorMessage message={errorMessage} /> : machineInsights}
            </TabPanel>
          </>
        )}
      </Paper>
    </ScreenLayout>
  )
}
