import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Grid, Paper, TableCell, TableRow } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  Configuration,
  HygieneMonitoringInformation,
  LaundriesApi,
  LaundryGroupReference,
  LaundryGroupsApi,
  LaundryReference,
  MachineCategory,
  MachineReference,
  MachinesApi,
  OrganizationReference,
  OrganizationsApi,
  ReportHygieneMonitoringsApi,
} from 'src/service/backend/api'
import { ReportHygieneMonitoringsApiMock } from 'src/service/backend/api-mock/ReportHygieneMonitoringsApiMock'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocale } from 'src/service/utils/DateFormatUtils'
import { getLastMonth, removeTimeFromDate, removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'
import { Data } from 'src/service/view-model/base/Data'
import { getHygieneStateName } from 'src/service/view-model/hygiene/HygineViewModel'
import {
  getMachineCategoryName,
  getMachineNameAndSerialNumber,
  getMachineOptionLabel,
} from 'src/service/view-model/machine/Machines'
import { EMPTY_ORGANIZATION_REF } from 'src/service/view-model/organization/Organizations'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DemoDataSwitch } from 'src/ui-shared/icons/DemoDataSwitch'
import { IconWithTooltip } from 'src/ui-shared/icons/Icons'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettingsWithOrganization,
  getDefaultTableSettings,
  tableSettingsSort,
  withOrganization,
} from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { TableFilterAutocompleteOrganization } from 'src/ui-shared/table/TableFilterAutocompleteOrganization'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { HygieneResultStatusChip, HygieneStateIcon } from 'src/ui/page/sm/hygiene/HygieneDataDisplay'
import { useUserRegionLocale } from 'src/user/UserContext'

interface HygieneTableSettings extends TableSettingsWithOrganization {
  orderBy: string
  startTimeFrom?: string
  startTimeTo?: string
  laundryGroupId?: string
  laundryId?: string
  machineId?: string
  machineCategory?: MachineCategory
}

const dateNow = removeTimeFromDateEndOfDay(new Date())
const dateLastMonth = removeTimeFromDate(getLastMonth(dateNow))

const headCells: HeadCells[] = [
  {
    id: 'createdAt',
    label: 'date',
  },
  {
    id: 'organization.name',
    label: 'organization',
  },
  {
    id: 'laundryGroup.name',
    label: 'laundryGroup',
  },
  {
    id: 'laundry.name',
    label: 'laundry',
  },
  {
    id: 'machine.category',
    label: 'member.machinetype',
    noSort: true,
  },
  {
    id: 'machine.name',
    label: 'machine',
  },
  {
    id: 'programName',
    label: 'program',
  },
  {
    id: 'state',
    label: 'state',
    noSort: true,
  },
  {
    id: 'result',
    label: 'result',
    noSort: true,
  },
]

interface Props {
  navigateToItem: (hygieneId?: string) => void
}

export const HygieneTable: React.FC<Props> = ({ navigateToItem }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const location = useLocation()
  const translate = useTranslate()

  const activeOrganization = useActiveOrganization()
  const regionLocale = useUserRegionLocale()

  // NOTE mock data / demo data for hygiene monitoring is intentional
  const [useMockData, setUseMockData] = useState<boolean>(false)

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportHygieneMonitoringApi = useMockData
    ? new ReportHygieneMonitoringsApiMock(httpConfiguration)
    : new ReportHygieneMonitoringsApi(httpConfiguration)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)
  const laundriesApi = new LaundriesApi(httpConfiguration)
  const machinesApi = new MachinesApi(httpConfiguration)
  const organizationsApi = new OrganizationsApi(httpConfiguration)

  const [organizations, setOrganizations] = useState<OrganizationReference[]>([])
  const [laundryGroups, setLaundryGroups] = useState<LaundryGroupReference[]>([])
  const [laundries, setLaundries] = useState<LaundryReference[]>([])
  const [machines, setMachines] = useState<MachineReference[]>([])

  // state
  const [data, setData] = useState<TableData<HygieneMonitoringInformation>>(DEFAULT_DATA)

  const defaultTableSettings: HygieneTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'createdAt',
    orderDir: 'desc',
    startTimeFrom: dateLastMonth.toISOString(),
    startTimeTo: dateNow.toISOString(),
  }
  const [tableSettings, setTableSettings] = useState<HygieneTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // load data for filters
  useEffect(() => {
    laundryGroupsApi.laundrygroupsRefGet().then((data) => {
      setLaundryGroups(data)
    })

    laundriesApi.laundriesRefGet().then((data) => {
      setLaundries(data)
    })

    machinesApi.machinesRefGet().then((data) => {
      setMachines(data)
    })

    organizationsApi.organizationsRefGet().then((data) => {
      data.unshift(EMPTY_ORGANIZATION_REF)
      setOrganizations(data)
    })
  }, [])

  // load data on state change
  useEffect(() => {
    let active = true

    if (tableSettings.startTimeFrom && tableSettings.startTimeTo) {
      setLoading(true)
      const dateStartTimeFrom = new Date(tableSettings.startTimeFrom)
      const dateStartTimeTo = new Date(tableSettings.startTimeTo)

      reportHygieneMonitoringApi
        .reportSmHygieneMonitoringsGet(
          dateStartTimeFrom,
          dateStartTimeTo,
          tableSettings.size,
          tableSettings.page,
          tableSettingsSort(tableSettings),
          tableSettings.organizationId,
          tableSettings.laundryGroupId,
          tableSettings.laundryId,
          tableSettings.machineId,
        )
        .then((data) => {
          if (active) {
            updateTableSettingsFromData(data as Data<any>, tableSettings)
            setTableSettings(tableSettings)

            setData(mapData(data as Data<any>))
            setErrorMessage(null)
            setLoading(false)
          }
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setData(DEFAULT_DATA)
          setLoading(false)
        })
    }

    return () => {
      active = false
    }
  }, [tableSettings, useMockData])

  // JSX
  const nonEmptyRows = data.data.map((item, index) => {
    return (
      <TableRow className={tableClasses.tableRow} key={index} onClick={navigateToItem.bind(this, item.id)}>
        <TableCell>{formatDateTimeForLocale(item.startTime, regionLocale)}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={item.organization.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={item.laundryGroup.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={item.laundry.name} />
        </TableCell>
        <TableCell>{getMachineCategoryName(item.machine.category, translate)}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={getMachineNameAndSerialNumber(item.machine)} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={item.programName} />
        </TableCell>
        <TableCell>
          <IconWithTooltip
            icon={<HygieneStateIcon hygieneState={item.state} result={item.result} />}
            tooltip={getHygieneStateName(item.state, item.result, translate)}
          />
        </TableCell>
        <TableCell style={{ paddingTop: '0px', paddingBottom: '0px' }}>
          <HygieneResultStatusChip result={item.result} translate={translate} />
        </TableCell>
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string): ReactElement | undefined => {
    let filter

    if (headCellId === 'laundry.name') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundry')}
          label={translate('laundries')}
          filter={'laundryId'}
          labelFieldName="name"
          valueFieldName="id"
          options={laundries}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'laundryGroup.name') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundryGroup')}
          label={translate('laundryGroups')}
          filter={'laundryGroupId'}
          labelFieldName="name"
          valueFieldName="id"
          options={laundryGroups}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'organization.name') {
      filter = (
        <TableFilterAutocompleteOrganization
          options={organizations}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'machine.name') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByMachine')}
          label={translate('machines')}
          filter={'machineId'}
          labelFieldName="name"
          getOptionLabel={getMachineOptionLabel}
          valueFieldName="id"
          options={machines}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }

    return filter
  }

  return (
    <Paper elevation={0}>
      <Box mt={3} mb={3}>
        <Grid container spacing={2}>
          <TableDatePickerForm
            key={`${tableSettings.startTimeFrom}${tableSettings.startTimeTo}`}
            firstDatePropName="startTimeFrom"
            secondDatePropName="startTimeTo"
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
          />
        </Grid>
      </Box>

      <Box mt={2}>
        <Grid container justifyContent="flex-end">
          <DemoDataSwitch useMockData={useMockData} setUseMockData={setUseMockData} fullWidth />
        </Grid>
      </Box>

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        getFilter={getFilter}
        loading={loading}
        translate={translate}
      />
    </Paper>
  )
}
