import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Divider, Grid, Hidden, Paper } from '@mui/material'
import { Add, MailOutline } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Permission } from 'src/service/backend/api'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { StyledTab } from 'src/ui-shared/base/tab-panel/StyledTab'
import { StyledTabs } from 'src/ui-shared/base/tab-panel/StyledTabs'
import { TabPanel } from 'src/ui-shared/base/tab-panel/TabPanel'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ArticleIcon } from 'src/ui-shared/icons/Icons'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { MemberInvitationTable } from 'src/ui/page/common/member/list/MemberInvitationTable'
import { MemberTable } from 'src/ui/page/common/member/list/MemberTable'
import { MemberInviteModal } from 'src/ui/page/common/member/list/invite/MemberInviteModal'
import { ServicerTab } from 'src/ui/page/sm/servicer/list/ServicerTab'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const ServicerListPage = (): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  const user = useUser()
  const appId = useAppId()
  const navigate = useNavigate()
  const { tabNameParam } = useParams()
  const tabName = tabNameParam ? getEnumFromString(tabNameParam, ServicerTab) : ServicerTab.OVERVIEW

  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false)
  const [submittedInvite, setSubmittedInvite] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<ServicerTab>(ServicerTab.OVERVIEW)

  const hasInvitationReadPermission = hasPermission(user, Permission.INVITATION_READ)

  useEffect(() => {
    setActiveTab(tabName)
  }, [])

  const handleRowClick = (memberId?: string) => {
    navigate(`/${appId}/servicers/${memberId}/view`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleCreateButtonClick = () => {
    setOpenInviteModal(true)
  }

  const inviteAction = (
    <ListingButton onClick={handleCreateButtonClick} variant="outlined" color="primary" startIcon={<Add />}>
      {translate('invite')}
    </ListingButton>
  )

  const handleTabChange = (event: React.SyntheticEvent, newValue: ServicerTab) => {
    const pathToNavigate = `/${appId}/servicers/${newValue}`
    navigate(pathToNavigate, { replace: true })
    setActiveTab(newValue)
  }

  const actions = hasPermission(user, Permission.INVITATION_WRITE) ? inviteAction : <></>

  return (
    <>
      <ScreenLayout title={translate('servicers')} actions={actions}>
        <Paper elevation={0}>
          <Grid container className={sharedClasses.TabsContainer}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <StyledTabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={handleTabChange}
              >
                <StyledTab
                  icon={<ArticleIcon />}
                  iconPosition={'start'}
                  value={ServicerTab.OVERVIEW}
                  label={<Hidden mdDown>{translate('servicers')}</Hidden>}
                />
                {hasInvitationReadPermission && (
                  <StyledTab
                    icon={<MailOutline />}
                    iconPosition={'start'}
                    value={ServicerTab.INVITATIONS}
                    label={<Hidden mdDown>{translate('invitiations')}</Hidden>}
                  />
                )}
              </StyledTabs>
            </Grid>
          </Grid>
          <Divider />
          <TabPanel value={activeTab} index={ServicerTab.OVERVIEW}>
            <MemberTable navigateToItem={handleRowClick} isServicer />
          </TabPanel>
          {hasInvitationReadPermission && (
            <TabPanel value={activeTab} index={ServicerTab.INVITATIONS}>
              <MemberInvitationTable submittedInvite={submittedInvite} isServicer />
            </TabPanel>
          )}
        </Paper>
      </ScreenLayout>

      <MemberInviteModal
        key={openInviteModal.toString()}
        openInviteModal={openInviteModal}
        setOpenInviteModal={setOpenInviteModal}
        setSubmittedInvite={setSubmittedInvite}
        isServicer
      />
    </>
  )
}
