import { Manufacturer } from 'src/service/backend/api'

export interface ManufacturerViewModel {
  name: string
}

export const mapViewModelToManufacturer = (viewModel: ManufacturerViewModel): Manufacturer => {
  return {
    name: viewModel.name,
  } as Manufacturer
}

export const createManufacturerViewModel = (manufacturer?: Manufacturer): ManufacturerViewModel => {
  if (!manufacturer) {
    return {
      name: '',
    }
  }
  return {
    name: manufacturer.name,
  }
}
