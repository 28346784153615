import { TranslateFunction } from 'src/i18n/useMessageSource'
import { LaundryUserImportState } from 'src/service/backend/api'
import { getReferenceName } from 'src/service/view-model/base/Reference.utils'

export interface LaundryUserImportStateObject {
  state: string
  name: string
}

export const LAUNDRY_USER_IMPORT_STATE: LaundryUserImportStateObject[] = [
  { state: LaundryUserImportState.CREATED, name: 'import.created' },
  { state: LaundryUserImportState.UPDATED, name: 'import.updated' },
  { state: LaundryUserImportState.NO_ACTION, name: 'import.noAction' },
  { state: LaundryUserImportState.ERROR, name: 'import.error' },
]

export const getLaundryUserImportStateName = (
  importStateName: string | undefined,
  translate: TranslateFunction,
): string => {
  return getReferenceName(LAUNDRY_USER_IMPORT_STATE, translate, importStateName, 'state')
}
