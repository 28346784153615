/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaundryGroup } from './LaundryGroup';
import {
    LaundryGroupFromJSON,
    LaundryGroupFromJSONTyped,
    LaundryGroupToJSON,
} from './LaundryGroup';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * 
 * @export
 * @interface PageableLaundryGroups
 */
export interface PageableLaundryGroups {
    /**
     * 
     * @type {Array<LaundryGroup>}
     * @memberof PageableLaundryGroups
     */
    result: Array<LaundryGroup>;
    /**
     * 
     * @type {Page}
     * @memberof PageableLaundryGroups
     */
    page: Page;
}

/**
 * Check if a given object implements the PageableLaundryGroups interface.
 */
export function instanceOfPageableLaundryGroups(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageableLaundryGroupsFromJSON(json: any): PageableLaundryGroups {
    return PageableLaundryGroupsFromJSONTyped(json, false);
}

export function PageableLaundryGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableLaundryGroups {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(LaundryGroupFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageableLaundryGroupsToJSON(value?: PageableLaundryGroups | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(LaundryGroupToJSON)),
        'page': PageToJSON(value.page),
    };
}

