import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Chip, Paper, TableCell, TableRow } from '@mui/material'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { Configuration, LaundriesApi, LaundryGroupsApi, MachinesApi, OrganizationsApi } from 'src/service/backend/api'
import {
  LaundryGroupReference,
  LaundryReference,
  Machine,
  MachineConnectivityType,
  MachineType,
  OrganizationReference,
} from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { Data, OrderBy } from 'src/service/view-model/base/Data'
import {
  CONNECTED_MACHINE_TYPES,
  getMachineConnectivityTypeName,
  getMachineTypeName,
} from 'src/service/view-model/machine/Machines'
import { EMPTY_ORGANIZATION_REF } from 'src/service/view-model/organization/Organizations'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettingsWithOrganization,
  getDefaultTableSettings,
  tableSettingsSort,
  withOrganization,
} from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { TableFilterAutocompleteOrganization } from 'src/ui-shared/table/TableFilterAutocompleteOrganization'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { MachineStatusChip } from 'src/ui/page/common/machine/list/MachineStatusChip'

const getHeadCells = (appId: AppId): HeadCells[] => {
  const result: HeadCells[] = [
    {
      id: 'serialNumber',
      label: 'serialNumber',
    },
    {
      id: 'name',
      label: 'organization.name',
    },
    {
      id: 'type',
      label: 'member.machinetype',
    },
    {
      id: 'laundry.laundryGroup.organization.name',
      label: 'organization',
    },
    {
      id: 'laundry.laundryGroup.name',
      label: 'laundryGroup',
    },
    {
      id: 'laundry',
      label: 'laundry',
    },
    {
      id: 'machineConnectivityType',
      label: 'connectivityType',
      noSort: true,
    },
  ]

  const statusHeadCell: HeadCells = {
    id: 'status',
    label: 'status',
    noSort: true,
  }

  const utilizationStateHeadCell: HeadCells = {
    id: 'state',
    label: 'state',
    noSort: true,
  }

  if (appId === AppId.SERVICE_MASTER) {
    result.push(utilizationStateHeadCell)
  } else {
    result.push(statusHeadCell)
  }
  return result
}

interface MachineTableSettings extends TableSettingsWithOrganization {
  orderBy: OrderBy<keyof Machine>
  laundryGroupId?: string
  laundryId?: string
  filterByMachineStatus?: boolean
  type?: MachineType
  machineConnectivityType?: MachineConnectivityType
}

interface Props {
  navigateToItem: (machineId: string) => void
}

export const MachineTable: React.FC<Props> = ({ navigateToItem }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  const location = useLocation()
  const translate = useTranslate()

  const appId = useAppId()
  const activeOrganization = useActiveOrganization()
  const headCells = getHeadCells(appId)

  const httpConfiguration: Configuration = useContext(HttpContext)
  const machinesApi = new MachinesApi(httpConfiguration)
  const organizationsApi = new OrganizationsApi(httpConfiguration)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)
  const laundriesApi = new LaundriesApi(httpConfiguration)

  const [data, setData] = useState<TableData<Machine>>(DEFAULT_DATA)
  const defaultTableSettings: MachineTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'serialNumber',
    organizationId: undefined,
    laundryGroupId: undefined,
    laundryId: undefined,
    filterByMachineStatus: undefined,
  }
  const [tableSettings, setTableSettings] = useState<MachineTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )
  const [organizations, setOrganizations] = useState<OrganizationReference[]>([])
  const [laundryGroups, setLaundryGroups] = useState<LaundryGroupReference[]>([])
  const [laundries, setLaundries] = useState<LaundryReference[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)
  const defaultConnectivityTypes =
    appId === AppId.WASH_MASTER ? CONNECTED_MACHINE_TYPES : Object.values(MachineConnectivityType)

  // static filtering values
  const machineTypes: Array<any> = []
  Object.keys(MachineType).forEach((machineTypeKey) => {
    const machineType: MachineType = MachineType[machineTypeKey as keyof typeof MachineType]
    machineTypes.push({ name: getMachineTypeName(machineType, translate), id: machineType })
  })

  const machineConnectivityTypes: Array<any> = []
  defaultConnectivityTypes.forEach((type) => {
    machineConnectivityTypes.push({
      name: getMachineConnectivityTypeName(type, translate),
      id: type,
    })
  })

  // reactivity
  // load data for filters
  useEffect(() => {
    organizationsApi.organizationsRefGet().then((data) => {
      data.unshift(EMPTY_ORGANIZATION_REF)
      setOrganizations(data)
    })

    laundryGroupsApi.laundrygroupsRefGet().then((data) => {
      setLaundryGroups(data)
    })

    laundriesApi.laundriesRefGet().then((data) => {
      setLaundries(data)
    })
  }, [])

  // load data on state change
  useEffect(() => {
    let active = true
    setLoading(true)

    machinesApi
      .machinesGet(
        tableSettings.size,
        tableSettings.page,
        tableSettingsSort(tableSettings),
        tableSettings.search,
        undefined,
        tableSettings.organizationId,
        tableSettings.laundryGroupId,
        tableSettings.laundryId,
        [],
        undefined,
        undefined,
        undefined,
        tableSettings.type,
        undefined,
        undefined,
        tableSettings.machineConnectivityType ? [tableSettings.machineConnectivityType] : defaultConnectivityTypes,
      )
      .then((data) => {
        if (active) {
          updateTableSettingsFromData(data as Data<any>, tableSettings)
          setTableSettings(tableSettings)

          setData(mapData(data as Data<any>))
          setErrorMessage(null)
          setLoading(false)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })
    return () => {
      active = false
    }
  }, [tableSettings])

  // JSX

  const getStatusChip = (status?: boolean) => {
    return (
      <Chip
        color={status ? 'primary' : 'default'}
        label={status ? translate('online') : translate('offline')}
        sx={{ minWidth: '60px' }}
      />
    )
  }

  const nonEmptyRows = data.data.map((item) => {
    return (
      <TableRow className={tableClasses.tableRow} key={item.id} onClick={navigateToItem.bind(this, item.id)}>
        <TableCell>{item.serialNumber}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={30} value={item.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={getMachineTypeName(item.type, translate)} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={item.organization?.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={item.laundryGroup?.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={15} value={item.laundry?.name} />
        </TableCell>
        <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', textAlign: 'center' }}>
          {getMachineConnectivityTypeName(item.machineConnectivityType, translate)}
        </TableCell>
        {appId === AppId.SERVICE_MASTER ? (
          <TableCell style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <MachineStatusChip status={item.status?.utilizationMachineState} />
          </TableCell>
        ) : null}
        {appId === AppId.WASH_MASTER ? (
          <TableCell style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            {getStatusChip(item.status?.online)}
          </TableCell>
        ) : null}
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string): ReactElement | undefined => {
    let filter
    if (headCellId === 'laundry.laundryGroup.organization.name') {
      filter = (
        <TableFilterAutocompleteOrganization
          options={organizations}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'type') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByMachineType')}
          label={translate('member.machinetype')}
          filter={'type'}
          labelFieldName="name"
          valueFieldName="id"
          options={machineTypes}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'laundry.laundryGroup.name') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundryGroup')}
          label={translate('laundryGroups')}
          filter={'laundryGroupId'}
          labelFieldName="name"
          valueFieldName="id"
          options={laundryGroups}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'laundry') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundry')}
          label={translate('laundries')}
          filter={'laundryId'}
          labelFieldName="name"
          valueFieldName="id"
          options={laundries}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'machineConnectivityType') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByConnectivity')}
          label={translate('connectivityTypes')}
          filter={'machineConnectivityType'}
          labelFieldName="name"
          valueFieldName="id"
          options={machineConnectivityTypes}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }
    return filter
  }

  return (
    <Paper elevation={0}>
      <TableSearchForm handleSearch={handleSearch} inputSearchValue={inputSearchValue} />

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        getFilter={getFilter}
        loading={loading}
        translate={translate}
      />
    </Paper>
  )
}
