import { Path } from 'react-hook-form'
import { SettingField, SettingFieldType } from 'src/service/backend/api'
import { filterHiddenSettingFieldInputs } from 'src/service/view-model/setting-field/SettingVisibilityUtils'
import { HookFormFieldError, HookFormFieldErrorWhere } from 'src/ui-shared/base/form/validation/HookFormFieldError'
import { TextLength } from 'src/ui-shared/constants/Constants'

export const required = <T>(
  name: Path<T>,
  value: unknown,
  where?: HookFormFieldErrorWhere,
): Record<string, HookFormFieldError> => {
  if (value === '' || value === null || value === undefined) {
    return {
      [name]: {
        type: 'required',
        message: `validation.required`,
        where: where,
      },
    }
  }
  return {}
}

const realNumberRegex = /^-?[0-9]\d*(\.\d+)?$/

export const validNumber = <T>(
  name: Path<T>,
  value: unknown,
  where?: HookFormFieldErrorWhere,
): Record<string, HookFormFieldError> => {
  if (value === '' || value === undefined || value === null) {
    return {}
  }
  if (realNumberRegex.test(value as string)) {
    return {}
  }
  return {
    [name]: {
      type: 'validate',
      message: 'validation.valid.number',
      where: where,
    },
  }
}

const validMax = <T>(
  name: Path<T>,
  maxValue: number,
  where?: HookFormFieldErrorWhere,
  value?: number | null,
  allowZero?: boolean,
): Record<string, HookFormFieldError> => {
  const numValue = parseFloat(value as any)
  const validZeroValue = allowZero === true && numValue === 0
  const isEmptyValue = value === undefined || value === null

  // since value is optional it is not required hence (undefined | null) is a valid value
  // if not undefined, value should be smaller or equal to maxValue or equal to 0 if it is allowed
  if (isEmptyValue || (!isNaN(numValue) && (numValue <= maxValue || validZeroValue))) {
    return {}
  }

  return {
    [name]: {
      type: 'max',
      message: allowZero === true ? `validation.valid.amountMaxOrZero` : `validation.valid.amountMax`,
      translationKeys: [maxValue],
      where: where,
    },
  }
}

export const validMin = <T>(
  name: Path<T>,
  minValue: number,
  where?: HookFormFieldErrorWhere,
  value?: number | null,
  allowZero?: boolean,
): Record<string, HookFormFieldError> => {
  const numValue = parseFloat(value as any)
  const validZeroValue = allowZero === true && value === 0
  const isEmptyValue = value === undefined || value === null

  // since value is optional it is not required hence (undefined | null) is a valid value
  // if not undefined, value should be larger or equal to minValue or equal to 0 if it is allowed
  if (isEmptyValue || (!isNaN(numValue) && (numValue >= minValue || validZeroValue))) {
    return {}
  }

  return {
    [name]: {
      type: 'min',
      message: allowZero === true ? `validation.valid.amountMinOrZero` : `validation.valid.amountMin`,
      translationKeys: [minValue],
      where: where,
    },
  }
}

export const validMaxChar = <T>(
  name: Path<T>,
  maxChar: TextLength,
  where?: HookFormFieldErrorWhere,
  value?: string | null,
): Record<string, HookFormFieldError> => {
  const isEmptyValue = value === undefined || value === null || value === ''
  const allowedChars = maxChar.value
  const errorKey = `validation.max.char.${maxChar.key}`

  if (isEmptyValue) {
    return {}
  }

  if (value.length > allowedChars) {
    return {
      [name]: {
        type: 'validate',
        message: errorKey,
        translationKeys: [allowedChars],
        where: where,
      },
    }
  }

  return {}
}

export const getFieldValue = (field: SettingField): string | number | boolean | undefined => {
  if (field.fieldType === SettingFieldType.NUMBER && field.numberField) {
    return field.numberField.data
  } else if (field.fieldType === SettingFieldType.SELECT && field.selectField) {
    return field.selectField.data
  } else if (field.fieldType === SettingFieldType.BOOLEAN && field.booleanField) {
    return field.booleanField.data
  } else if (field.fieldType === SettingFieldType.TEXT && field.textField) {
    return field.textField.data
  } else if (field.fieldType === SettingFieldType.BIT_FLAGS && field.bitFlagsField) {
    return field.bitFlagsField.data
  } else {
    throw new Error('Field type is not recognized or is null')
  }
}

export const validateField = <T>(
  field: SettingField,
  name: Path<T>,
  where?: HookFormFieldErrorWhere,
): Record<string, HookFormFieldError> => {
  let errors = {}
  const fieldValue = getFieldValue(field)

  if (field.validation?.required) {
    errors = { ...errors, ...required(name, fieldValue, where) }
  }

  if (!(field.validation?.max === undefined)) {
    errors = {
      ...errors,
      ...validMax(name, field.validation.max, where, fieldValue as number, field.validation.allowZero),
    }
  }

  if (!(field.validation?.min === undefined)) {
    errors = {
      ...errors,
      ...validMin(name, field.validation.min, where, fieldValue as number, field.validation.allowZero),
    }
  }

  if (field.numberField) {
    errors = { ...errors, ...validNumber(name, fieldValue, where) }
  }

  return errors
}

export const validateDynamicFields = <T>(
  fields: SettingField[],
  getNameForDynamicFieldType: (field: SettingField, fieldIndex: number, stepIndex?: number) => Path<T>,
  section: string,
  stepIndex?: number,
): Record<string, HookFormFieldError> => {
  let errors: Record<string, HookFormFieldError> = {}
  const stepIndexIsEmpty = stepIndex === undefined

  const visibleFields = filterHiddenSettingFieldInputs(fields, false)

  visibleFields.forEach((field) => {
    const fieldName = getNameForDynamicFieldType(field, field.fieldIndex, stepIndex)
    const newStepIndex = !stepIndexIsEmpty ? stepIndex + 1 : undefined
    const where: HookFormFieldErrorWhere = {
      section: section,
      moduleIndex: newStepIndex,
    }
    errors = {
      ...errors,
      ...validateField<T>(field, fieldName, where),
    }
  })
  return errors
}

export const getNameForDynamicFieldTypeDefaultImplementation = (
  field: SettingField,
  fieldIndex: number,
  stepIndex: number | undefined,
): Path<any> => {
  if (field.fieldType === SettingFieldType.NUMBER && field.numberField) {
    return `fields.${fieldIndex}.numberField.data`
  } else if (field.fieldType === SettingFieldType.SELECT && field.selectField) {
    return `fields.${fieldIndex}.selectField.data`
  } else if (field.fieldType === SettingFieldType.BOOLEAN && field.booleanField) {
    return `fields.${fieldIndex}.booleanField.data`
  } else if (field.fieldType === SettingFieldType.TEXT && field.textField) {
    return `fields.${fieldIndex}.textField.data`
  } else if (field.fieldType === SettingFieldType.BIT_FLAGS && field.bitFlagsField) {
    return `fields.${fieldIndex}.bitFlagsField.data`
  } else {
    throw new Error('Field type is not recognized or is null')
  }
}
