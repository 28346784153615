import { friendlyFormatIBAN } from 'ibantools'
import {
  Address,
  Currency,
  LaundryGroup,
  LaundryGroupServicemaster,
  LaundryGroupWashmaster,
  OrganizationReference,
  PaymentMethod,
  ServicePackageOption,
  ServicePackagePlan,
  TimeZone,
} from 'src/service/backend/api/models'
import {
  ViewModelWithAddress,
  loadAddressViewModel,
  mapAddress,
} from 'src/service/view-model/base/address/AddressViewModel'
import { LOCALES, LocaleObject, getLocaleObject } from 'src/service/view-model/base/localization/Locales'
import { TimezoneObject, getTimezoneObject } from 'src/service/view-model/base/localization/Timezones'
import { CurrencyObject, getCurrencyObject } from 'src/service/view-model/base/payment/Currencies'

export interface LaundryGroupViewModel {
  wlNumber: string
  name: string
  locale?: LocaleObject
  iban: string
  ibanHolderName: string
  externalPayoutReference: string
  siteAddress: ViewModelWithAddress
  billingAddress?: ViewModelWithAddress
  useSiteAddressForBilling: boolean
  timeZone?: TimezoneObject
  currency?: CurrencyObject
  phoneNumber: string
  emails: string[]
  notes: string
  organization: OrganizationReference | string
  washmaster: {
    washmasterEnabled: boolean
    publicLaundryGroup: boolean
    calendarEnabled: boolean
    newsEnabled: boolean
    advancedReportsEnabled: boolean
    pushNotificationsEnabled: boolean
    payPerUseEnabled: boolean
  }
  servicemaster: {
    directCareEnabled: boolean
    customProgramsEnabled: boolean
    usageReportsEnabled: boolean
    utilizationReportsEnabled: boolean
    hygieneMonitoringEnabled: boolean
    priceElectricity: number
    priceWater: number
  }
  servicePackagePlan?: ServicePackagePlan
  servicePackagePlanOptions?: Array<ServicePackageOption>
  enabledPaymentMethod: PaymentMethod
}

export const mapLaundryGroupToViewModel = (laundryGroup: LaundryGroup): LaundryGroupViewModel => {
  const { organization, washmaster, servicemaster } = laundryGroup

  const useSiteAddressForBilling = Boolean(!laundryGroup.siteAddress)

  const billingAddressViewModel = loadAddressViewModel(laundryGroup.billingAddress)

  const siteAddressViewModel = loadAddressViewModel(
    laundryGroup.siteAddress ? laundryGroup.siteAddress : laundryGroup.billingAddress,
  )

  const viewModel: LaundryGroupViewModel = {
    wlNumber: laundryGroup.wlNumber || '',
    iban: laundryGroup.iban || '',
    ibanHolderName: laundryGroup.ibanHolderName || '',
    externalPayoutReference: laundryGroup.externalPayoutReference || '',
    siteAddress: siteAddressViewModel,
    billingAddress: billingAddressViewModel,
    name: laundryGroup.name || '',
    locale: getLocaleObject(laundryGroup.locale),
    useSiteAddressForBilling: useSiteAddressForBilling,
    timeZone: getTimezoneObject(laundryGroup.timeZone),
    currency: getCurrencyObject(laundryGroup.currency),
    phoneNumber: laundryGroup.phoneNumber || '',
    emails: laundryGroup.emails || [],
    notes: laundryGroup.notes || '',
    organization: organization,
    washmaster: {
      washmasterEnabled: washmaster?.washmasterEnabled || true,
      publicLaundryGroup: washmaster?.publicLaundryGroup || false,
      calendarEnabled: washmaster?.calendarEnabled || false,
      newsEnabled: washmaster?.newsEnabled || false,
      advancedReportsEnabled: washmaster?.advancedReportsEnabled || false,
      pushNotificationsEnabled: washmaster?.pushNotificationsEnabled || false,
      payPerUseEnabled: washmaster?.payPerUseEnabled || false,
    },
    servicemaster: {
      directCareEnabled: servicemaster?.directCareEnabled || false,
      customProgramsEnabled: servicemaster?.customProgramsEnabled || false,
      usageReportsEnabled: servicemaster?.usageReportsEnabled || false,
      utilizationReportsEnabled: servicemaster?.utilizationReportsEnabled || false,
      hygieneMonitoringEnabled: servicemaster?.hygieneMonitoringEnabled || false,
      priceElectricity: servicemaster?.priceElectricity || 0.0,
      priceWater: servicemaster?.priceWater || 0.0,
    },
    servicePackagePlan: laundryGroup.servicePackagePlan,
    servicePackagePlanOptions: laundryGroup.servicePackagePlanOptions,
    enabledPaymentMethod: washmaster?.enabledPaymentMethod || PaymentMethod.DIRECTPAY,
  }

  return viewModel
}

export const mapViewModelToLaundryGroup = (viewModel: LaundryGroupViewModel): LaundryGroup => {
  const currencyCodeEnum = viewModel.currency ? (viewModel.currency as CurrencyObject) : undefined
  const localeObject = viewModel.locale as LocaleObject | undefined
  const timezoneObject = viewModel.timeZone as TimezoneObject

  let billingAddress: Address | undefined
  let siteAddress: Address | undefined

  let useSiteAddressForApi = false
  if (viewModel.useSiteAddressForBilling) {
    billingAddress = mapAddress(viewModel.siteAddress)
    siteAddress = undefined
    useSiteAddressForApi = false
  } else {
    billingAddress = mapAddress(viewModel.billingAddress!)
    siteAddress = mapAddress(viewModel.siteAddress)
    useSiteAddressForApi = true
  }

  return {
    name: viewModel.name,
    locale: localeObject ? localeObject.code : LOCALES[0],
    wlNumber: viewModel.wlNumber,
    iban: viewModel.iban ? friendlyFormatIBAN(viewModel.iban) : undefined,
    ibanHolderName: viewModel.ibanHolderName,
    externalPayoutReference: viewModel.externalPayoutReference,
    siteAddress: siteAddress,
    billingAddress: billingAddress,
    useSiteAddress: useSiteAddressForApi,
    timeZone: timezoneObject.code as TimeZone,
    currency: currencyCodeEnum?.code as Currency,
    phoneNumber: viewModel.phoneNumber || undefined,
    emails: viewModel.emails.length && viewModel.emails[0] ? viewModel.emails : undefined,
    notes: viewModel.notes,
    organization: viewModel.organization,
    washmaster: {
      washmasterEnabled: viewModel.washmaster.washmasterEnabled,
      publicLaundryGroup: viewModel.washmaster.publicLaundryGroup,
      calendarEnabled: viewModel.washmaster.calendarEnabled,
      enabledPaymentMethod: viewModel.enabledPaymentMethod,
      newsEnabled: viewModel.washmaster.newsEnabled,
      advancedReportsEnabled: viewModel.washmaster.advancedReportsEnabled,
      pushNotificationsEnabled: viewModel.washmaster.pushNotificationsEnabled,
      payPerUseEnabled: viewModel.washmaster.payPerUseEnabled,
    } as LaundryGroupWashmaster,
    servicemaster: {
      directCareEnabled: viewModel.servicemaster.directCareEnabled,
      customProgramsEnabled: viewModel.servicemaster.customProgramsEnabled,
      usageReportsEnabled: viewModel.servicemaster.usageReportsEnabled,
      utilizationReportsEnabled: viewModel.servicemaster.utilizationReportsEnabled,
      hygieneMonitoringEnabled: viewModel.servicemaster.hygieneMonitoringEnabled,
      priceWater: viewModel.servicemaster.priceWater,
      priceElectricity: viewModel.servicemaster.priceElectricity,
    } as LaundryGroupServicemaster,
    servicePackagePlan: viewModel.servicePackagePlan,
    servicePackagePlanOptions: viewModel.servicePackagePlanOptions,
  } as LaundryGroup
}

export const createLaundryGroupViewModel = (laundryGroup?: LaundryGroup): LaundryGroupViewModel => {
  if (laundryGroup) {
    return mapLaundryGroupToViewModel(laundryGroup)
  }
  return {
    wlNumber: '',
    iban: '',
    ibanHolderName: '',
    externalPayoutReference: '',
    name: '',
    locale: undefined,
    billingAddress: undefined,
    siteAddress: {
      addressLine1: '',
      addressLine2: '',
      zipCode: '',
      city: '',
      latitude: '',
      longitude: '',
      countryCode: undefined,
    },
    useSiteAddressForBilling: true,
    timeZone: undefined,
    currency: undefined,
    phoneNumber: '',
    emails: [],
    notes: '',
    organization: '',
    washmaster: {
      washmasterEnabled: true,
      publicLaundryGroup: false,
      calendarEnabled: false,
      newsEnabled: false,
      advancedReportsEnabled: false,
      pushNotificationsEnabled: false,
      payPerUseEnabled: false,
    },
    servicemaster: {
      directCareEnabled: false,
      customProgramsEnabled: false,
      usageReportsEnabled: false,
      utilizationReportsEnabled: false,
      hygieneMonitoringEnabled: false,
      priceElectricity: 0.0,
      priceWater: 0.0,
    },
    servicePackagePlan: undefined,
    servicePackagePlanOptions: undefined,
    enabledPaymentMethod: PaymentMethod.PREPAID,
  }
}
