/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';

/**
 * 
 * @export
 * @interface LaundryGroupPriceListReference
 */
export interface LaundryGroupPriceListReference {
    /**
     * 
     * @type {string}
     * @memberof LaundryGroupPriceListReference
     */
    name: string;
    /**
     * 
     * @type {MachineType}
     * @memberof LaundryGroupPriceListReference
     */
    machineType: MachineType;
    /**
     * Drum Size of machine in kg (Optional)
     * @type {number}
     * @memberof LaundryGroupPriceListReference
     */
    drumSize?: number | null;
    /**
     * Unit of Weight ('kg' | 'lbs')
     * @type {string}
     * @memberof LaundryGroupPriceListReference
     */
    drumSizeUnit?: string;
    /**
     * 
     * @type {Currency}
     * @memberof LaundryGroupPriceListReference
     */
    currency: Currency;
    /**
     * 
     * @type {Date}
     * @memberof LaundryGroupPriceListReference
     */
    readonly lastModified: Date;
}

/**
 * Check if a given object implements the LaundryGroupPriceListReference interface.
 */
export function instanceOfLaundryGroupPriceListReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "machineType" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "lastModified" in value;

    return isInstance;
}

export function LaundryGroupPriceListReferenceFromJSON(json: any): LaundryGroupPriceListReference {
    return LaundryGroupPriceListReferenceFromJSONTyped(json, false);
}

export function LaundryGroupPriceListReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupPriceListReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'machineType': MachineTypeFromJSON(json['machineType']),
        'drumSize': !exists(json, 'drumSize') ? undefined : json['drumSize'],
        'drumSizeUnit': !exists(json, 'drumSizeUnit') ? undefined : json['drumSizeUnit'],
        'currency': CurrencyFromJSON(json['currency']),
        'lastModified': (new Date(json['lastModified'])),
    };
}

export function LaundryGroupPriceListReferenceToJSON(value?: LaundryGroupPriceListReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'machineType': MachineTypeToJSON(value.machineType),
        'drumSize': value.drumSize,
        'drumSizeUnit': value.drumSizeUnit,
        'currency': CurrencyToJSON(value.currency),
    };
}

