import React, { ReactElement } from 'react'
import { Box } from '@mui/material'
import { FeedbackForm } from 'src/ui/layout/feedback-form/FeedbackForm'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { OnboardingSection } from 'src/ui/layout/onboarding-slideshow/OnboardingSection'
import { DashboardWm } from 'src/ui/page/wm/index/DashboardWm'
import { SLIDES_WM } from 'src/ui/page/wm/index/OnboardingSlidesWm'

export const IndexPageWm = (): ReactElement => {
  return (
    <ScreenLayout>
      <Box>
        <DashboardWm />
      </Box>

      <OnboardingSection slides={SLIDES_WM} />

      <FeedbackForm />

      {/*  <InfoCenterSection /> */}
    </ScreenLayout>
  )
}
