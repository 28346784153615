import { ValidateResult } from 'react-hook-form'

export const validPositiveInteger =
  (errorText = 'validation.valid.number') =>
  (value?: string | number | null): ValidateResult => {
    const numberRegex = /^[0-9]*$/
    if (
      numberRegex.test(value as unknown as string) ||
      value === null ||
      (value as unknown) === '' ||
      value === undefined
    ) {
      return true
    }
    return errorText
  }
