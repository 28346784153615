import { Region } from 'src/service/backend/api/models'
import { SupportedLocale } from 'src/service/view-model/base/localization/SupportedLocale'

export const mapRegionToLocale = (region: Region): SupportedLocale => {
  switch (region) {
    case Region.US:
      return 'en-US'
    case Region.EU:
      return 'de-CH'
    default:
      console.warn(`Region ${region} is not supported, fallback to CH region`)
      return 'de-CH'
  }
}

export const isTwelveHourFormat = (locale: SupportedLocale): boolean => {
  return locale === 'en-US'
}
