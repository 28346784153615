import React, { FC, ReactElement } from 'react'
import { useNavigate } from 'react-router'
import {
  Button,
  Chip,
  Grid,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material'
import { SettingsOutlined } from '@mui/icons-material'
import { AppId } from 'src/app/AppId'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { LaundryGroupSubscription, ServicePackageOption } from 'src/service/backend/api'
import { getPricingTitle } from 'src/service/view-model/base/payment/Payments'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { HeadCells } from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'
import { useUserRegionLocale } from 'src/user/UserContext'

const getHeadCells = (showPricing: boolean): HeadCells[] => {
  const result: HeadCells[] = [
    {
      id: 'name',
      label: 'subscription.name',
      noSort: true,
    },
    {
      id: 'description',
      label: 'description',
      noSort: true,
    },
    {
      id: 'activated',
      label: 'activated',
      noSort: true,
    },
  ]

  if (showPricing) {
    const pricingHeadCell: HeadCells = {
      id: 'pricing',
      label: 'pricing',
      noSort: true,
    }

    result.push(pricingHeadCell)
  }

  return result
}

interface Prop {
  laundryGroupSubscription: LaundryGroupSubscription
  showPricing?: boolean
}

export const ServicePackagePlanAdditionalOptionTable: FC<Prop> = ({
  laundryGroupSubscription,
  showPricing = true,
}): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const { classes: tableClasses } = useTableStyles()
  const navigate = useNavigate()
  const translate = useTranslate()
  const regionLocale = useUserRegionLocale()
  const headCells = getHeadCells(showPricing)

  // derived state
  const additionalOptions = laundryGroupSubscription.servicePackagePlanOptions
  const activatedPackagePlanId = laundryGroupSubscription.activatedServicePackagePlan.id
  const laundryGroupId = laundryGroupSubscription.laundryGroup.id

  const navigateToManageOptions = () => {
    navigate(`/${AppId.WASH_MASTER}/service-package-plan/${laundryGroupId}/view/upgrade/${activatedPackagePlanId}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  // JSX
  const displayStatusChip = (item: ServicePackageOption) => {
    const active = item.activated
    let chipStyle: SxProps<Theme> | undefined = undefined

    // if option is not active display default color
    if (active) {
      const optionColor = item.colors
      const backgroundColor = optionColor?.bgColor ? `#${optionColor.bgColor}` : undefined
      const color = optionColor?.fgColor ? `#${optionColor.fgColor}` : undefined

      chipStyle = { backgroundColor: backgroundColor, color: color }
    }

    return <Chip sx={chipStyle} label={translate(active ? 'active' : 'inactive')} />
  }

  const displayAdditionalOptionRows =
    additionalOptions.length > 0 ? (
      additionalOptions.map((item, index) => {
        return (
          <TableRow className={tableClasses.tableRow} key={index} style={{ cursor: 'default' }}>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.description}</TableCell>
            <TableCell style={{ paddingTop: '0px', paddingBottom: '0px' }}>{displayStatusChip(item)}</TableCell>
            {showPricing && (
              <TableCell>
                {getPricingTitle(translate, 'additionalPricingPerUsage', regionLocale, item.pricing?.usagePricing)}
              </TableCell>
            )}
          </TableRow>
        )
      })
    ) : (
      <TableEmpty colspan={headCells.length} />
    )

  const displayHeaders = headCells.map((item) => {
    return <TableCell key={item.id}>{translate(item.label)}</TableCell>
  })

  const displayAdditionalOptionsTable = (
    <Paper elevation={0}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>{displayHeaders}</TableRow>
          </TableHead>
          <TableBody>{displayAdditionalOptionRows}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )

  return (
    <>
      <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography className={sharedClasses.SectionTitle}>{translate('additionalOptions')}</Typography>
        <Button
          variant="text"
          color="primary"
          size="large"
          onClick={navigateToManageOptions}
          startIcon={<SettingsOutlined />}
        >
          {translate('button.manageOptions')}
        </Button>
      </Grid>
      <Grid item lg={12}>
        {displayAdditionalOptionsTable}
      </Grid>
    </>
  )
}
