import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Paper, TableCell, TableRow } from '@mui/material'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  Address,
  Configuration,
  LaundryGroup,
  LaundryGroupsApi,
  OrganizationReference,
  OrganizationsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { Data, OrderBy } from 'src/service/view-model/base/Data'
import { getAddress, getCity } from 'src/service/view-model/base/address/AddressUtils'
import { getCountryName } from 'src/service/view-model/base/country/Countries'
import { EMPTY_ORGANIZATION_REF } from 'src/service/view-model/organization/Organizations'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettingsWithOrganization,
  getDefaultTableSettings,
  tableSettingsSort,
  withOrganization,
} from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableFilterAutocompleteOrganization } from 'src/ui-shared/table/TableFilterAutocompleteOrganization'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import {
  displayLaundryGroupFeatures,
  displayServicePackagePlan,
} from 'src/ui/page/common/laundry-group/list/LaundryGroupFeatures'
import { useUserLocale } from 'src/user/UserContext'

const getHeadCells = (wmApp: boolean): HeadCells[] => {
  const result: HeadCells[] = [
    {
      id: 'name',
      label: 'laundryGroup.name',
    },
    {
      id: 'organization',
      label: 'organization',
    },
    {
      id: 'wlNumber',
      label: 'wlNumber',
    },
    {
      id: 'billingAddress.addressLine1',
      label: 'billingAddress',
    },
    {
      id: 'billingAddress.city',
      label: 'city',
    },
    {
      id: 'billingAddress.countryCode',
      label: 'country',
    },
  ]

  if (wmApp) {
    const servicePackagePlanCell: HeadCells = {
      id: 'servicePackagePlan',
      label: 'servicePackagePlan',
      noSort: true,
    }
    result.push(servicePackagePlanCell)
  }

  const featuresCell: HeadCells = {
    id: 'washmaster',
    label: 'features',
    noSort: true,
  }
  result.push(featuresCell)

  return result
}

interface LaundryGroupTableSettings extends TableSettingsWithOrganization {
  orderBy: OrderBy<keyof LaundryGroup | keyof Address>
}

interface Props {
  navigateToItem: (laundryGroupId?: string) => void
}

export const LaundryGroupTable: React.FC<Props> = ({ navigateToItem }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const location = useLocation()
  const translate = useTranslate()
  const userLocale = useUserLocale()

  const appId = useAppId()
  const activeOrganization = useActiveOrganization()

  // http
  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)
  const organizationsApi = new OrganizationsApi(httpConfiguration)

  const headCells = getHeadCells(appId === AppId.WASH_MASTER)

  // state
  const [data, setData] = useState<TableData<LaundryGroup>>(DEFAULT_DATA)
  const defaultTableSettings: LaundryGroupTableSettings = {
    ...getDefaultTableSettings(),
  }
  const [tableSettings, setTableSettings] = useState<LaundryGroupTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [organizations, setOrganizations] = useState<OrganizationReference[]>([])

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)

  // load data for filters
  useEffect(() => {
    organizationsApi.organizationsRefGet().then((data) => {
      data.unshift(EMPTY_ORGANIZATION_REF)
      setOrganizations(data)
    })
  }, [])

  // load data on state change
  useEffect(() => {
    let active = true
    setLoading(true)

    laundryGroupsApi
      .laundrygroupsGet(
        tableSettings.size,
        tableSettings.page,
        tableSettingsSort(tableSettings),
        tableSettings.search,
        undefined,
        tableSettings.organizationId,
        undefined,
        [],
      )
      .then((data) => {
        if (active) {
          updateTableSettingsFromData(data as Data<any>, tableSettings)
          setTableSettings(tableSettings)

          setData(mapData(data as Data<any>))
          setErrorMessage(null)
          setLoading(false)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })
    return () => {
      active = false
    }
  }, [tableSettings])

  // JSX

  const nonEmptyRows = data.data.map((item) => {
    return (
      <TableRow className={tableClasses.tableRow} key={item.id} onClick={navigateToItem.bind(this, item.id)}>
        <TableCell>
          <TruncatedTooltip maxChars={35} value={item.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={30} value={item.organization.name} />
        </TableCell>
        <TableCell>{item.wlNumber}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={30} value={getAddress(item.billingAddress)} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={getCity(item.billingAddress)} />
        </TableCell>
        <TableCell>{getCountryName(item.billingAddress.countryCode, userLocale)}</TableCell>
        {appId === AppId.WASH_MASTER && (
          <TableCell
            style={{
              paddingTop: '0px',
              paddingBottom: '0px',
            }}
          >
            {displayServicePackagePlan(appId, item.servicePackagePlan)}
          </TableCell>
        )}
        <TableCell
          style={{
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
        >
          {displayLaundryGroupFeatures(item, appId, translate)}
        </TableCell>
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string): ReactElement | undefined => {
    let filter
    if (headCellId === 'organization') {
      filter = (
        <TableFilterAutocompleteOrganization
          options={organizations}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }
    return filter
  }

  return (
    <Paper elevation={0}>
      <TableSearchForm handleSearch={handleSearch} inputSearchValue={inputSearchValue} />

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        getFilter={getFilter}
        loading={loading}
        translate={translate}
      />
    </Paper>
  )
}
