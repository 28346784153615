/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TimeseriesValueType {
    STATE = 'STATE',
    NUMBER = 'NUMBER'
}


export function TimeseriesValueTypeFromJSON(json: any): TimeseriesValueType {
    return TimeseriesValueTypeFromJSONTyped(json, false);
}

export function TimeseriesValueTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeseriesValueType {
    return json as TimeseriesValueType;
}

export function TimeseriesValueTypeToJSON(value?: TimeseriesValueType | null): any {
    return value as any;
}

