/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SettingFieldType {
    NUMBER = 'NUMBER',
    TEXT = 'TEXT',
    SELECT = 'SELECT',
    BOOLEAN = 'BOOLEAN',
    BIT_FLAGS = 'BIT_FLAGS',
    GROUP = 'GROUP'
}


export function SettingFieldTypeFromJSON(json: any): SettingFieldType {
    return SettingFieldTypeFromJSONTyped(json, false);
}

export function SettingFieldTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingFieldType {
    return json as SettingFieldType;
}

export function SettingFieldTypeToJSON(value?: SettingFieldType | null): any {
    return value as any;
}

