import React, { FC, ReactElement } from 'react'
import { FieldArrayWithId, Path, UseFieldArrayUpdate, UseFormReturn } from 'react-hook-form'
import { Grid } from '@mui/material'
import { Program, ProgramModuleSettings, SettingFieldType } from 'src/service/backend/api'
import { SettingWithExpandableGroups } from 'src/service/view-model/setting-field/SettingExpandableGroupsUtils'
import { SettingFieldWithIndex, getFieldLabel } from 'src/service/view-model/setting-field/SettingFieldUtils'
import {
  hideOrShowGroupsForBooleanField,
  hideOrShowGroupsForSelectField,
} from 'src/service/view-model/setting-field/SettingVisibilityUtils'
import { AutocompleteHookForm } from 'src/ui-shared/base/form/control-hook-form/AutocompleteHookForm'
import { CheckboxHookForm } from 'src/ui-shared/base/form/control-hook-form/CheckboxHookForm'
import { NumberTextFieldHookForm } from 'src/ui-shared/base/form/control-hook-form/NumberTextFieldHookForm'
import { TextFieldHookForm } from 'src/ui-shared/base/form/control-hook-form/TextFieldHookForm'
import { DynamicSettingFieldsForm } from 'src/ui-shared/base/form/dynamic-setting-form/DynamicSettingFieldsForm'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'

interface Props {
  selectedModule: FieldArrayWithId<Program, 'details.steps', 'id'>
  disabled?: boolean
  programForm: UseFormReturn<Program, any>
  selectedModuleIndex: number
  updateModule: UseFieldArrayUpdate<Program, 'details.steps'>
  forceRenderParent: () => void
}

export const ProgramModuleFields: FC<Props> = ({
  selectedModule,
  programForm,
  disabled,
  selectedModuleIndex,
  updateModule,
  forceRenderParent,
}): ReactElement => {
  const control = programForm.control

  const changeShownGroupFieldsSelect = (fieldIndex: number) => {
    const fieldName: Path<Program> = `details.steps.${selectedModuleIndex}.settings.${fieldIndex}.selectField`
    const selectField = programForm.getValues(fieldName)

    const updatedFields = hideOrShowGroupsForSelectField(selectedModule.settings, selectField)

    if (updatedFields) {
      const copyModule: ProgramModuleSettings = {
        ...selectedModule,
        settings: updatedFields,
      }
      updateModule(selectedModuleIndex, copyModule)
    }
  }

  const changeShownGroupFieldsBoolean = (fieldIndex: number) => {
    const fieldName: Path<Program> = `details.steps.${selectedModuleIndex}.settings.${fieldIndex}.booleanField`
    const booleanField = programForm.getValues(fieldName)

    const updatedFields = hideOrShowGroupsForBooleanField(selectedModule.settings, booleanField)

    if (updatedFields) {
      const copyModule: ProgramModuleSettings = {
        ...selectedModule,
        settings: updatedFields,
      }
      updateModule(selectedModuleIndex, copyModule)
    }
  }

  const updateModuleFieldInOverview = (field: SettingFieldWithIndex) => {
    if (!field.showInOverview) {
      return
    }

    forceRenderParent()
  }

  const renderFormInput = (field: SettingFieldWithIndex) => {
    if (field.fieldType === SettingFieldType.SELECT) {
      return (
        <Grid item {...ITEM_BREAKPOINTS} key={field.fieldIndex + selectedModule.id}>
          <AutocompleteHookForm
            disabled={disabled || field.readOnly}
            control={control}
            name={`details.steps.${selectedModuleIndex}.settings.${field.fieldIndex}.selectField.data`}
            transform={{
              labelFieldName: 'label',
              valueFieldName: 'data',
            }}
            disableClearable={field.validation?.required}
            label={getFieldLabel(field)}
            options={field.selectField?.options || []}
            hintText={field.hint}
            onChangeEventListener={() => {
              changeShownGroupFieldsSelect(field.fieldIndex)
              updateModuleFieldInOverview(field)
            }}
          />
        </Grid>
      )
    } else if (field.fieldType === SettingFieldType.NUMBER) {
      return (
        <Grid item {...ITEM_BREAKPOINTS} key={field.fieldIndex + selectedModule.id}>
          <NumberTextFieldHookForm
            label={getFieldLabel(field)}
            disabled={disabled || field.readOnly}
            control={control}
            decimal={field.numberField?.isDecimal}
            name={`details.steps.${selectedModuleIndex}.settings.${field.fieldIndex}.numberField.data`}
            hintText={field.hint}
            onChangeEventListener={() => {
              updateModuleFieldInOverview(field)
            }}
          />
        </Grid>
      )
    } else if (field.fieldType === SettingFieldType.BOOLEAN) {
      return (
        <Grid item {...ITEM_BREAKPOINTS} key={field.fieldIndex + selectedModule.id}>
          <CheckboxHookForm
            label={getFieldLabel(field)}
            disabled={disabled || field.readOnly}
            control={control}
            name={`details.steps.${selectedModuleIndex}.settings.${field.fieldIndex}.booleanField.data`}
            hintText={field.hint}
            onChangeEventListener={() => {
              changeShownGroupFieldsBoolean(field.fieldIndex)
              updateModuleFieldInOverview(field)
            }}
          />
        </Grid>
      )
    } else if (field.fieldType === SettingFieldType.TEXT) {
      return (
        <Grid item {...ITEM_BREAKPOINTS} key={field.fieldIndex + selectedModule.id}>
          <TextFieldHookForm
            label={getFieldLabel(field)}
            disabled={disabled || field.readOnly}
            control={control}
            name={`details.steps.${selectedModuleIndex}.settings.${field.fieldIndex}.textField.data`}
            hintText={field.hint}
            onChangeEventListener={() => {
              updateModuleFieldInOverview(field)
            }}
          />
        </Grid>
      )
    } else {
      return <></>
    }
  }

  const checkIfExpandedGroupFieldsHaveError = (expandedGroupFields?: SettingWithExpandableGroups[]) => {
    const fieldIndexes = expandedGroupFields?.map((item) => item.fieldIndex) ?? []
    return fieldIndexes.some(
      (fieldIndex) => programForm.getFieldState(`details.steps.${selectedModuleIndex}.settings.${fieldIndex}`).error,
    )
  }

  const handleOpeningExpandableGroup = (field: SettingWithExpandableGroups) => {
    if (field.groupField) {
      field.groupField.expanded = !field.groupField.expanded
      forceRenderParent()
    }
  }

  return (
    <DynamicSettingFieldsForm
      fields={selectedModule.settings}
      checkIfExpandedGroupFieldsHaveError={checkIfExpandedGroupFieldsHaveError}
      handleOpeningExpandableGroup={handleOpeningExpandableGroup}
      renderFormInput={renderFormInput}
    />
  )
}
