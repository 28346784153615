/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PageableRecurringMachineReservations,
  RecurringMachineReservation,
} from '../models';
import {
    PageableRecurringMachineReservationsFromJSON,
    PageableRecurringMachineReservationsToJSON,
    RecurringMachineReservationFromJSON,
    RecurringMachineReservationToJSON,
} from '../models';

export interface CalendarRecurringReservationsGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    id?: string;
    machineId?: string;
    laundryId?: string;
    laundryGroupId?: string;
    laundryUserId?: string;
    dayOfWeek?: number;
}

export interface CalendarRecurringReservationsPostRequest {
    recurringMachineReservation?: RecurringMachineReservation;
}

export interface CalendarRecurringReservationsRecurringMachineReservationIdDeleteRequest {
    recurringMachineReservationId: string;
}

export interface CalendarRecurringReservationsRecurringMachineReservationIdGetRequest {
    recurringMachineReservationId: string;
}

/**
 * 
 */
export class CalendarRecurringMachineReservationsApi extends runtime.BaseAPI {

    /**
     * Get RecurringMachineReservations
     */
    async calendarRecurringReservationsGetRaw(requestParameters: CalendarRecurringReservationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableRecurringMachineReservations>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryUserId !== undefined) {
            queryParameters['laundryUserId'] = requestParameters.laundryUserId;
        }

        if (requestParameters.dayOfWeek !== undefined) {
            queryParameters['dayOfWeek'] = requestParameters.dayOfWeek;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar/recurring-reservations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableRecurringMachineReservationsFromJSON(jsonValue));
    }

    /**
     * Get RecurringMachineReservations
     */
    async calendarRecurringReservationsGet(size?: number, page?: number, sort?: string, search?: string, id?: string, machineId?: string, laundryId?: string, laundryGroupId?: string, laundryUserId?: string, dayOfWeek?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableRecurringMachineReservations> {
        const response = await this.calendarRecurringReservationsGetRaw({ size: size, page: page, sort: sort, search: search, id: id, machineId: machineId, laundryId: laundryId, laundryGroupId: laundryGroupId, laundryUserId: laundryUserId, dayOfWeek: dayOfWeek }, initOverrides);
        return await response.value();
    }

    /**
     * Create a RecurringMachineReservation
     */
    async calendarRecurringReservationsPostRaw(requestParameters: CalendarRecurringReservationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecurringMachineReservation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/calendar/recurring-reservations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecurringMachineReservationToJSON(requestParameters.recurringMachineReservation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecurringMachineReservationFromJSON(jsonValue));
    }

    /**
     * Create a RecurringMachineReservation
     */
    async calendarRecurringReservationsPost(recurringMachineReservation?: RecurringMachineReservation, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecurringMachineReservation> {
        const response = await this.calendarRecurringReservationsPostRaw({ recurringMachineReservation: recurringMachineReservation }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a RecurringMachineReservation
     */
    async calendarRecurringReservationsRecurringMachineReservationIdDeleteRaw(requestParameters: CalendarRecurringReservationsRecurringMachineReservationIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recurringMachineReservationId === null || requestParameters.recurringMachineReservationId === undefined) {
            throw new runtime.RequiredError('recurringMachineReservationId','Required parameter requestParameters.recurringMachineReservationId was null or undefined when calling calendarRecurringReservationsRecurringMachineReservationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar/recurring-reservations/{recurringMachineReservationId}`.replace(`{${"recurringMachineReservationId"}}`, encodeURIComponent(String(requestParameters.recurringMachineReservationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a RecurringMachineReservation
     */
    async calendarRecurringReservationsRecurringMachineReservationIdDelete(recurringMachineReservationId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.calendarRecurringReservationsRecurringMachineReservationIdDeleteRaw({ recurringMachineReservationId: recurringMachineReservationId }, initOverrides);
    }

    /**
     * Get a RecurringMachineReservation
     */
    async calendarRecurringReservationsRecurringMachineReservationIdGetRaw(requestParameters: CalendarRecurringReservationsRecurringMachineReservationIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecurringMachineReservation>> {
        if (requestParameters.recurringMachineReservationId === null || requestParameters.recurringMachineReservationId === undefined) {
            throw new runtime.RequiredError('recurringMachineReservationId','Required parameter requestParameters.recurringMachineReservationId was null or undefined when calling calendarRecurringReservationsRecurringMachineReservationIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar/recurring-reservations/{recurringMachineReservationId}`.replace(`{${"recurringMachineReservationId"}}`, encodeURIComponent(String(requestParameters.recurringMachineReservationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecurringMachineReservationFromJSON(jsonValue));
    }

    /**
     * Get a RecurringMachineReservation
     */
    async calendarRecurringReservationsRecurringMachineReservationIdGet(recurringMachineReservationId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecurringMachineReservation> {
        const response = await this.calendarRecurringReservationsRecurringMachineReservationIdGetRaw({ recurringMachineReservationId: recurringMachineReservationId }, initOverrides);
        return await response.value();
    }

}
