import React, { FC, ReactElement } from 'react'
import { Box, Button, DialogContent, Typography } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ErrorModalDialog } from 'src/ui-shared/base/model-dialog/ErrorModalDialog'

interface Props {
  errorsText: string
  openDialog: boolean
  closeDialog: () => void
}

export const ProgramErrorsModal: FC<Props> = ({ errorsText, openDialog, closeDialog }): ReactElement => {
  const translate = useTranslate()

  return (
    <ErrorModalDialog maxWidth="sm" open={openDialog} onClose={closeDialog} withCloseIcon>
      <DialogContent dividers>
        <Box>
          <Typography variant="body1">
            {translate('program.saveErrors')} <b>{errorsText}</b>
          </Typography>
          <Typography variant="body1">{translate('program.fixErrorsBeforeSave')}</Typography>
        </Box>
      </DialogContent>

      <Box pt={1} pb={1} display="flex" justifyContent="flex-end">
        <Button variant="text" color="primary" size="large" onClick={closeDialog}>
          {translate('button.close')}
        </Button>
      </Box>
    </ErrorModalDialog>
  )
}
