import React, { ReactElement } from 'react'
import { Outlet } from 'react-router'
import { AppProvider } from 'src/app/AppProvider'
import { ActiveOrganizationContextProvider } from 'src/organization/ActiveOrganizationProvider'

export const AppWithRoutes = (): ReactElement => {
  return (
    <AppProvider>
      <ActiveOrganizationContextProvider>
        <Outlet />
      </ActiveOrganizationContextProvider>
    </AppProvider>
  )
}
