/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';

/**
 * 
 * @export
 * @interface OrganizationServicemaster
 */
export interface OrganizationServicemaster {
    /**
     * 
     * @type {OrganizationReference}
     * @memberof OrganizationServicemaster
     */
    servicePartner?: OrganizationReference;
    /**
     * Service partner has a issue tracking system
     * @type {boolean}
     * @memberof OrganizationServicemaster
     */
    ticketingSystemAvailable?: boolean;
}

/**
 * Check if a given object implements the OrganizationServicemaster interface.
 */
export function instanceOfOrganizationServicemaster(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrganizationServicemasterFromJSON(json: any): OrganizationServicemaster {
    return OrganizationServicemasterFromJSONTyped(json, false);
}

export function OrganizationServicemasterFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationServicemaster {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'servicePartner': !exists(json, 'servicePartner') ? undefined : OrganizationReferenceFromJSON(json['servicePartner']),
        'ticketingSystemAvailable': !exists(json, 'ticketingSystemAvailable') ? undefined : json['ticketingSystemAvailable'],
    };
}

export function OrganizationServicemasterToJSON(value?: OrganizationServicemaster | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'servicePartner': OrganizationReferenceToJSON(value.servicePartner),
        'ticketingSystemAvailable': value.ticketingSystemAvailable,
    };
}

