/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';
import type { ProgramWithPosition } from './ProgramWithPosition';
import {
    ProgramWithPositionFromJSON,
    ProgramWithPositionFromJSONTyped,
    ProgramWithPositionToJSON,
} from './ProgramWithPosition';

/**
 * 
 * @export
 * @interface ProgramGroup
 */
export interface ProgramGroup {
    /**
     * 
     * @type {string}
     * @memberof ProgramGroup
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramGroup
     */
    notes?: string;
    /**
     * 
     * @type {OrganizationReference}
     * @memberof ProgramGroup
     */
    organization: OrganizationReference;
    /**
     * 
     * @type {MachineType}
     * @memberof ProgramGroup
     */
    machineType: MachineType;
    /**
     * 
     * @type {Array<ProgramWithPosition>}
     * @memberof ProgramGroup
     */
    programs: Array<ProgramWithPosition>;
    /**
     * 
     * @type {Date}
     * @memberof ProgramGroup
     */
    readonly lastModified?: Date;
}

/**
 * Check if a given object implements the ProgramGroup interface.
 */
export function instanceOfProgramGroup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "machineType" in value;
    isInstance = isInstance && "programs" in value;

    return isInstance;
}

export function ProgramGroupFromJSON(json: any): ProgramGroup {
    return ProgramGroupFromJSONTyped(json, false);
}

export function ProgramGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'organization': OrganizationReferenceFromJSON(json['organization']),
        'machineType': MachineTypeFromJSON(json['machineType']),
        'programs': ((json['programs'] as Array<any>).map(ProgramWithPositionFromJSON)),
        'lastModified': !exists(json, 'lastModified') ? undefined : (new Date(json['lastModified'])),
    };
}

export function ProgramGroupToJSON(value?: ProgramGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'notes': value.notes,
        'organization': OrganizationReferenceToJSON(value.organization),
        'machineType': MachineTypeToJSON(value.machineType),
        'programs': ((value.programs as Array<any>).map(ProgramWithPositionToJSON)),
    };
}

