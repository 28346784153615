/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { TopUpGrouped } from './TopUpGrouped';
import {
    TopUpGroupedFromJSON,
    TopUpGroupedFromJSONTyped,
    TopUpGroupedToJSON,
} from './TopUpGrouped';

/**
 * 
 * @export
 * @interface PageableTopUpsGrouped
 */
export interface PageableTopUpsGrouped {
    /**
     * 
     * @type {Array<TopUpGrouped>}
     * @memberof PageableTopUpsGrouped
     */
    result: Array<TopUpGrouped>;
    /**
     * 
     * @type {Page}
     * @memberof PageableTopUpsGrouped
     */
    page: Page;
}

/**
 * Check if a given object implements the PageableTopUpsGrouped interface.
 */
export function instanceOfPageableTopUpsGrouped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageableTopUpsGroupedFromJSON(json: any): PageableTopUpsGrouped {
    return PageableTopUpsGroupedFromJSONTyped(json, false);
}

export function PageableTopUpsGroupedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableTopUpsGrouped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(TopUpGroupedFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageableTopUpsGroupedToJSON(value?: PageableTopUpsGrouped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(TopUpGroupedToJSON)),
        'page': PageToJSON(value.page),
    };
}

