import Keycloak, { KeycloakRoles } from 'keycloak-js'
import {
  DEFAULT_APP,
  DEFAULT_AVAILABLE_APPS,
  DEFAULT_USER_LOCALE,
  DEFAULT_USER_PERMISSIONS,
  DEFAULT_USER_REGION_DATA,
  DEFAULT_USER_ROLES,
  User,
} from 'src/user/User'

let keycloakInstance: Keycloak.KeycloakInstance

function initKeycloak(keycloakConfigFileUrl: string, onTokenRefreshed: (newToken: string) => void): Promise<boolean> {
  // console.debug('Initializing Keycloak')
  keycloakInstance = new Keycloak(keycloakConfigFileUrl)

  keycloakInstance.onTokenExpired = () => {
    console.info('Token expired')
    keycloakInstance
      .updateToken(60)
      .then((refreshed) => {
        if (refreshed) {
          console.info('Token was successfully refreshed')
          const newToken = keycloakInstance.token
          if (newToken) {
            onTokenRefreshed(newToken)
          }
        } else {
          console.info('Token is still valid')
        }
      })
      .catch((err) => {
        const errorMessage = 'Failed to refresh the token, or the session has expired'
        console.error(errorMessage, err)
        keycloakInstance.clearToken()
        keycloakInstance.logout()
      })
  }

  const keycloakPromise = keycloakInstance.init({
    // keycloak options
    onLoad: 'login-required',
  })

  return keycloakPromise
}

function initKeycloakAndGetUser(
  keycloakConfigFileUrl: string,
  onTokenRefreshed: (newToken: string) => void,
): Promise<User> {
  const initPromise: Promise<boolean> = initKeycloak(keycloakConfigFileUrl, onTokenRefreshed)

  const userPromise: Promise<User> = initPromise.then(function (authenticated) {
    console.info('User authenticated: ', authenticated)
    if (!authenticated) {
      throw new Error('Not authenticated in Keycloak')
    }
    const keycloakInstance = getKeycloakInstance()
    const currentUser = loadUserFromKeycloak(keycloakInstance)
    return currentUser
  })

  return userPromise
}

function getKeycloakInstance(): Keycloak.KeycloakInstance {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (keycloakInstance === undefined) {
    throw new Error('Keycloak is not initialized yet')
  }
  return keycloakInstance
}

function loadUserFromKeycloak(keycloak: Keycloak.KeycloakInstance): User {
  // console.debug('Keycloak subject', keycloak.subject)
  // console.debug('Keycloak object', keycloak)

  const userId = keycloak.subject ? (keycloak.subject as string) : ''

  const idTokenParsed = keycloak.idTokenParsed as any
  const username = idTokenParsed.preferred_username
  const userEmail = idTokenParsed.email
  const userFirstName = idTokenParsed.given_name
  const userLastName = idTokenParsed.family_name
  const userLocale = idTokenParsed.locale

  const tokenParsed = keycloak.tokenParsed
  const token = keycloak.token as string

  const keycloakRoles: KeycloakRoles | undefined = tokenParsed?.realm_access
  const userRoles: string[] | undefined = keycloakRoles?.roles

  // @ts-ignore
  const organizationRoles = tokenParsed.organization_roles as any
  // console.debug('Organization roles', organizationRoles)

  const userRolesSet = new Set(userRoles ? [...userRoles] : DEFAULT_USER_ROLES)
  if (organizationRoles) {
    for (const [organizationId, organizationRolesObject] of Object.entries(organizationRoles)) {
      const organizationRolesArray: string[] = organizationRolesObject as string[]
      // console.debug('Organization ' + organizationId + ', roles: ' + organizationRolesArray)
      organizationRolesArray.forEach((organizationRole) => userRolesSet.add(organizationRole))
    }
  }

  const userRolesToUse = Array.from(userRolesSet)
  // console.debug('User roles', userRolesToUse)

  // NOTE: permissions initialized in InitUser.tsx
  const currentUser: User = {
    id: userId,
    email: userEmail,
    firstName: userFirstName,
    lastName: userLastName,
    locale: userLocale ? (userLocale as string) : DEFAULT_USER_LOCALE,
    username: username,
    permissions: DEFAULT_USER_PERMISSIONS,
    availableApps: DEFAULT_AVAILABLE_APPS,
    defaultApp: DEFAULT_APP,
    regionData: DEFAULT_USER_REGION_DATA,
    roles: userRolesToUse,
    token: token,
    blocked: false,
    globalRoles: [],
    memberRolesAll: [],
  }

  return currentUser
}

export const KeycloakService = { initKeycloakAndGetUser, getKeycloakInstance }
