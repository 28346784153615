import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { Configuration, DashboardAssetInfo, DashboardAssetList, DashboardAssetsApi } from 'src/service/backend/api'
import { DashboardAssetsApiMock } from 'src/service/backend/api-mock/DashboardAssetsApiMock'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getMachineTypeName } from 'src/service/view-model/machine/Machines'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { DashboardLoadingIndicator } from 'src/ui/page/sm/index/DashboardLoadingIndicator'
import { useUserLocale } from 'src/user/UserContext'

interface Props {
  useMockData: boolean
}

export const DashboardAssetsWidget = ({ useMockData }: Props): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const translate = useTranslate()
  const locale = useUserLocale()
  const activeOrganization = useActiveOrganization()

  // api
  const httpConfiguration: Configuration = useContext(HttpContext)
  const dashboardAssetsApi = useMockData ? new DashboardAssetsApiMock() : new DashboardAssetsApi(httpConfiguration)

  // state
  const [dashboardAssets, setDashboardAssets] = useState<DashboardAssetList | null>(null)
  const [dashboardAssetsErrorMessage, setDashboardAssetsErrorMessage] = useState<string | null>(null)

  // derived state
  const activeOrganizationId = activeOrganization?.id

  // load data function
  const loadDashboardAssets = () => {
    setDashboardAssetsErrorMessage(null)
    dashboardAssetsApi
      .dashboardSmAssetsGet(activeOrganizationId)
      .then((data) => {
        setDashboardAssets(data)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setDashboardAssets(null)
        setDashboardAssetsErrorMessage(errorMessage)
      })
  }

  // load data on mount
  useEffect(() => {
    loadDashboardAssets()
  }, [useMockData, activeOrganizationId])

  const getTranslatedAndSortedAssetsInfo = (): DashboardAssetInfo[] => {
    let result: DashboardAssetInfo[] = []
    const assetsInfoTranslated = dashboardAssets?.result.map(
      (assetInfo) =>
        ({
          ...assetInfo,
          type: getMachineTypeName(assetInfo.type, translate),
        }) as DashboardAssetInfo,
    )

    const assetsInfoTranslatedAndSorted = assetsInfoTranslated?.sort((assetInfo1, assetInfo2) =>
      assetInfo1.type.localeCompare(assetInfo2.type, locale),
    )

    if (assetsInfoTranslatedAndSorted) {
      result = assetsInfoTranslatedAndSorted
    }

    return result
  }

  return (
    <>
      {dashboardAssetsErrorMessage ? (
        <ErrorMessage message={dashboardAssetsErrorMessage} />
      ) : dashboardAssets?.result ? (
        <>
          <Typography variant="h5">{activeOrganization?.name}</Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{translate('connected')}</TableCell>
                  <TableCell>{translate('openRequests')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getTranslatedAndSortedAssetsInfo().map((item) => {
                  return (
                    <TableRow className={tableClasses.tableRow} key={item.type}>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>{item.total}</TableCell>
                      <TableCell>{item.openIssues}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <DashboardLoadingIndicator />
      )}
    </>
  )
}
