import React, { FC, useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Box, Button, Grid } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { CalendarApi, CalendarConfig, Configuration, LaundryGroup } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  LaundryGroupCalendarConfigViewModel,
  createLaundryGroupCalendarConfigViewModel,
  mapLaundryGroupCalendarConfigViewModalToCalendarConfig,
} from 'src/service/view-model/laundry-group/LaundryGroupCalendarConfigViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { composeValidators, required, validNumberRange } from 'src/ui-shared/base/form/validation/Validators'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

interface Props {
  laundryGroup: LaundryGroup
  closeModal: (successMessage?: string) => void
}

export const LaundryGroupCalendarConfig: FC<Props> = ({ laundryGroup, closeModal }) => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const calendarConfigApi = new CalendarApi(httpConfiguration)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [calendarConfigViewModel, setCalendarConfigViewModel] = useState<LaundryGroupCalendarConfigViewModel>(
    createLaundryGroupCalendarConfigViewModel(),
  )

  // load data
  useEffect(() => {
    let active = true
    setLoading(true)
    setErrorMessage(null)

    calendarConfigApi
      .calendarConfigLaundryGroupIdGet(laundryGroup.id)
      .then((data) => {
        if (active) {
          setLoading(false)
          const viewModel = createLaundryGroupCalendarConfigViewModel(data)
          setCalendarConfigViewModel(viewModel)
        }
      })
      .catch((err) => {
        setLoading(false)
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })

    return () => {
      active = false
    }
  }, [])

  const updateCalendarConfig = (calendarConfig: CalendarConfig) => {
    calendarConfigApi
      .calendarConfigLaundryGroupIdPut(laundryGroup.id, calendarConfig)
      .then((_data) => {
        closeModal(translate('calendarConfigChanged'))
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  const submitForm = (viewModel: LaundryGroupCalendarConfigViewModel) => {
    const calendarConfig = mapLaundryGroupCalendarConfigViewModalToCalendarConfig(viewModel)
    updateCalendarConfig(calendarConfig)
  }

  return (
    <Box p={3}>
      <LoadingIndicator loading={loading} />
      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
      <Form<LaundryGroupCalendarConfigViewModel>
        initialValues={calendarConfigViewModel}
        onSubmit={submitForm}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="maxReservationHoursPerMonth"
                      label={translate('maxReservationHoursPerMonth')}
                      validate={composeValidators(
                        required(),
                        validNumberRange(0, 168, 'validation.valid.maxReservationsPerMonth'),
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="maxReservationTime"
                      label={translate('maxReservationHoursPerDay')}
                      validate={composeValidators(
                        required(),
                        validNumberRange(0, 24, 'validation.valid.maxReservationsPerDay'),
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  color="primary"
                  size="large"
                  className={sharedClasses.ButtonMargin}
                  onClick={() => closeModal()}
                >
                  {translate('button.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={submitting || pristine}
                >
                  {translate('apply')}
                </Button>
              </Box>
            </form>
          )
        }}
      />
    </Box>
  )
}
