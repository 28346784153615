import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, LaundryGroupsCalendarApi } from 'src/service/backend/api'
import { LaundryGroup, LaundryGroupRecurringCalendarEntry } from 'src/service/backend/api/models'
import { LaundryGroupRecurringCalendarDay } from 'src/service/backend/api/models/LaundryGroupRecurringCalendarDay'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { LaundryGroupCalendarWeek } from 'src/ui/page/common/laundry-group/details/LaundryGroupCalendarWeek'

interface Props {
  laundryGroup: LaundryGroup
}

export const LaundryGroupCalendarTab: FC<Props> = ({ laundryGroup }): ReactElement => {
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupsCalendarApi = new LaundryGroupsCalendarApi(httpConfiguration)

  // props
  const laundryGroupId = laundryGroup.id ? laundryGroup.id : ''

  // state
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [weekdayCalendar, setWeekdayCalendar] = useState<LaundryGroupRecurringCalendarDay[]>([])

  // derived state
  const calendarEnabled = laundryGroup.washmaster?.calendarEnabled

  // load data
  useEffect(() => {
    let active = true
    getRecurringCalendar(active)
    return () => {
      active = false
    }
  }, [])

  const getRecurringCalendar = async (active: boolean) => {
    setLoading(true)
    laundryGroupsCalendarApi
      .laundrygroupsLaundryGroupIdCalendarRecurringGet(laundryGroupId)
      .then((data) => {
        if (active) {
          setWeekdayCalendar(data.days)
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }

  const updateRecurringCalendar = async (values: LaundryGroupRecurringCalendarEntry): Promise<void> => {
    return laundryGroupsCalendarApi
      .laundrygroupsLaundryGroupIdCalendarRecurringPost(laundryGroupId, values)
      .then((_data) => {
        getRecurringCalendar(true)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  return (
    <>
      <LoadingIndicator loading={loading} />

      {!calendarEnabled ? <ErrorMessage severity="info" message={translate('errorCalendarDisabled')} /> : null}

      {errorMessage ? (
        <ErrorMessage message={errorMessage} />
      ) : (
        <Box pt={2}>
          <LaundryGroupCalendarWeek updateRecurringCalendar={updateRecurringCalendar} week={weekdayCalendar} />
        </Box>
      )}
    </>
  )
}
