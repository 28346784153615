import React, { FC, ReactElement, useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Box, Button, Grid, Icon, ToggleButton, ToggleButtonGroup, styled } from '@mui/material'
import { Add, GetApp } from '@mui/icons-material'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { NavigateState } from 'src/routing/Routing'
import {
  Configuration,
  InstallProgramGroupRequest,
  Machine,
  Permission,
  ProgramGroup,
  ProgramGroupsApi,
  ProgramMetaData,
  ProgramType,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { OrderBy } from 'src/service/view-model/base/Data'
import { getMachineTypeName, isProgramCompatibleMachineType } from 'src/service/view-model/machine/Machines'
import { ProgramTypeFilter } from 'src/service/view-model/program/ProgramViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { EsLogoIcon } from 'src/ui-shared/icons/Icons'
import { ProgramGroupAddModal } from 'src/ui-shared/program-group/ProgramGroupAddModal'
import { TableSettings, getDefaultTableSettings, withOrganization } from 'src/ui-shared/table/Table.const'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { MachineProgramTable } from 'src/ui/page/common/machine/program/MachineProgramTable'
import { hasApp, hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  flexGrow: 1,
  '& .MuiToggleButton-root': {
    width: '100px',
  },
}))

export const ActionButtonsGrid = styled(Grid)(({ theme }) => ({
  display: 'inline-flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
}))

export interface ProgramInstalledTableSettings extends TableSettings {
  orderBy: OrderBy<keyof ProgramMetaData>
  programType?: ProgramType
}

interface Props {
  machine: Machine
}

export const MachineProgramTab: FC<Props> = ({ machine }): ReactElement => {
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const user = useUser()
  const navigate = useNavigate()
  const appId = useAppId()
  const location = useLocation()
  const activeOrganization = useActiveOrganization()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programGroupsApi = new ProgramGroupsApi(httpConfiguration)

  const defaultTableSettings: ProgramInstalledTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'programIndex',
  }

  const [programGroupAddModal, setProgramGroupAddModal] = useState<boolean>(false)
  const [tableSettings, setTableSettings] = useState<ProgramInstalledTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )

  const hasEasySet = hasApp(user, AppId.EASY_SET)
  const hasProgramGroupReadPermission = hasPermission(user, Permission.EASYSET_PROGRAM_READ)
  const hasInstallProgramGroupPermission = hasEasySet && hasProgramGroupReadPermission

  const hasCustomProgramWritePermission = hasPermission(user, Permission.CUSTOM_PROGRAM_WRITE)
  const isProgramCompatibleMachine = isProgramCompatibleMachineType(machine.status?.programInstallationStatus)

  // handle events
  const handleOpenEasySet = () => {
    navigate(`/${AppId.EASY_SET}/programs/${machine.type}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const navigateToImportPage = () => {
    navigate(`/${appId}/machines/${machine.id}/programs/${ProgramType.CUSTOM}/import`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleRowClick = (programId?: string, programType?: ProgramType) => {
    navigate(`/${appId}/machines/${machine.id}/programs/${programType}/${programId}/view`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleOpenProgramGroupAddModal = () => {
    setProgramGroupAddModal(true)
  }

  const handleCloseProgramGroupAddModal = () => {
    setProgramGroupAddModal(false)
  }

  const handleProgramTypeFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newProgramTypeFilter: ProgramTypeFilter | null,
  ) => {
    if (newProgramTypeFilter !== null) {
      setTableSettings((settings) => {
        return {
          ...settings,
          page: 0,
          programType: newProgramTypeFilter === 'all' ? undefined : newProgramTypeFilter,
        }
      })
    }
  }

  const handleInstallProgramGroup = (programGroup: ProgramGroup) => {
    const installProgramGroupRequest: InstallProgramGroupRequest = {
      programGroupId: programGroup.id,
    }

    // install program group on specific machine
    return programGroupsApi
      .machinesMachineIdProgramsInstallProgramGroupPost(machine.id, installProgramGroupRequest)
      .then(() => {
        const successMessage = translate('programGroupInstallSuccess')
        showSnackbar(successMessage, 'success')
        handleCloseProgramGroupAddModal()

        // trigger rerender and fetch in table below
        setTableSettings({ ...tableSettings })
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
        return err
      })
  }

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  // JSX
  const displayActions = (
    <Grid container spacing={2} pb={2} pt={2}>
      <ActionButtonsGrid item xs={12}>
        <StyledToggleButtonGroup
          value={tableSettings.programType ?? 'all'}
          onChange={handleProgramTypeFilterChange}
          exclusive
          size="small"
        >
          <ToggleButton value={'all'}>{translate('all')}</ToggleButton>
          <ToggleButton value={ProgramType.STANDARD}>{translate('programType.standard')}</ToggleButton>
          <ToggleButton value={ProgramType.CUSTOM}>{translate('programType.custom')}</ToggleButton>
        </StyledToggleButtonGroup>

        {hasInstallProgramGroupPermission && (
          <Button variant="outlined" color="primary" startIcon={<GetApp />} onClick={handleOpenProgramGroupAddModal}>
            {translate('installProgramGroup')}
          </Button>
        )}

        {hasEasySet && (
          <Button
            variant="outlined"
            color="primary"
            startIcon={
              <Icon>
                <EsLogoIcon />
              </Icon>
            }
            onClick={handleOpenEasySet}
          >
            {translate('openEasySet')}
          </Button>
        )}

        {hasCustomProgramWritePermission && (
          <Button onClick={navigateToImportPage} variant="outlined" color="primary" startIcon={<Add />}>
            {translate('programsImport')}
          </Button>
        )}
      </ActionButtonsGrid>
    </Grid>
  )

  return (
    <>
      {!isProgramCompatibleMachine ? (
        <ErrorMessage
          severity={'info'}
          message={translate('programIncompatibleMachine', getMachineTypeName(machine.type, translate))}
        />
      ) : (
        <Box pt={2}>
          {displayActions}

          <MachineProgramTable
            machine={machine}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            navigateToItem={handleRowClick}
          />

          {programGroupAddModal && (
            <ProgramGroupAddModal
              open={true}
              fixedOrganizationId={machine.organization?.id}
              onCancel={handleCloseProgramGroupAddModal}
              addSelectedRow={handleInstallProgramGroup}
              titleKey={'installProgramGroupOnMachine'}
              confirmationKey={'button.install'}
              activeTabOnly={machine.type}
            />
          )}
        </Box>
      )}
    </>
  )
}
