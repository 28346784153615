import { LaundryGroupSettings } from 'src/service/backend/api'

export interface LaundryGroupGenericSettingsViewModel {
  revenueChangeLimit: string
  badTrendUtilizationLimit: string
  dailyGoalUtilizationLimit: string
}

export const createLaundryGroupGenericSettingsViewModel = (
  settings?: LaundryGroupSettings,
): LaundryGroupGenericSettingsViewModel => {
  if (!settings) {
    return {
      revenueChangeLimit: '-20.0',
      badTrendUtilizationLimit: '0',
      dailyGoalUtilizationLimit: '0',
    }
  }

  return {
    revenueChangeLimit: settings.revenueChangeLimit.toString(),
    badTrendUtilizationLimit: settings.badTrendUtilizationLimit.toString(),
    dailyGoalUtilizationLimit: settings.dailyGoalUtilizationLimit.toString(),
  }
}

export const mapLaundryGroupGenericSettingsViewModelToLaundryGroupSettings = (
  viewModel: LaundryGroupGenericSettingsViewModel,
): LaundryGroupSettings => ({
  revenueChangeLimit: parseFloat(viewModel.revenueChangeLimit),
  badTrendUtilizationLimit: parseFloat(viewModel.badTrendUtilizationLimit),
  dailyGoalUtilizationLimit: parseFloat(viewModel.dailyGoalUtilizationLimit),
})
