import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Paper, TableCell, TableRow } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, Manufacturer, ManufacturerModelsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { Data } from 'src/service/view-model/base/Data'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettings,
  getDefaultTableSettings,
  tableSettingsSort,
} from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'

const headCells: HeadCells[] = [
  {
    id: 'name',
    label: 'manufacturerName',
  },
]

interface Props {
  navigateToItem: (manufacturerId?: string) => void
}

export const ManufacturerTable: FC<Props> = ({ navigateToItem }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  const location = useLocation()

  const translate = useTranslate()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const manufacturerApi = new ManufacturerModelsApi(httpConfiguration)

  // state
  const [data, setData] = useState<TableData<Manufacturer>>(DEFAULT_DATA)
  const defaultTableSettings = getDefaultTableSettings()
  const [tableSettings, setTableSettings] = useState<TableSettings>(defaultTableSettings)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)

  // reactivity
  useEffect(() => {
    let active = true

    setLoading(true)

    manufacturerApi
      .manufacturersGet(tableSettings.size, tableSettings.page, tableSettingsSort(tableSettings), tableSettings.search)
      .then((data) => {
        if (!active) return
        updateTableSettingsFromData(data as Data<any>, tableSettings)
        setTableSettings(tableSettings)

        setData(mapData(data as Data<any>))
        setErrorMessage(null)
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [tableSettings])

  // JSX
  const nonEmptyRows = data.data.map((item) => {
    return (
      <TableRow className={tableClasses.tableRow} key={item.id} onClick={navigateToItem.bind(this, item.id)}>
        <TableCell>
          <TruncatedTooltip maxChars={35} value={item.name} />
        </TableCell>
      </TableRow>
    )
  })

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  return (
    <Paper elevation={0}>
      <TableSearchForm inputSearchValue={inputSearchValue} handleSearch={handleSearch} />
      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        loading={loading}
        translate={translate}
      />
    </Paper>
  )
}
