import React, { ReactElement, Suspense, useContext, useState } from 'react'
import { useLocation } from 'react-router'
import { Box, Grid } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { ChartData, Configuration, Currency, ReportWashMasterApi, TopUpChartFactType } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'
import { CHART_SCALE_OBJECTS, getChartScaleObject } from 'src/service/view-model/base/chart/Chart.const'
import { CURRENCIES, CurrencyObject } from 'src/service/view-model/base/payment/Currencies'
import {
  DATATRANS_PAYMENT_METHOD_OBJECTS,
  DatatransPaymentMethodObject,
} from 'src/service/view-model/base/payment/Datatrans'
import {
  DEFAULT_TOPUP_CHART_SETTINGS,
  TOPUP_CHART_FACT_TYPE_OBJECTS,
  TopUpChartFactTypeObject,
  TopUpChartGroupByObject,
  TopUpChartSettings,
  getTopUpChartFactTypeObject,
  getTopUpChartGroupByObject,
} from 'src/service/view-model/topup/TopupChartViewModel'
import { REPORT_TOP_UP_TYPES_OBJECTS, ReportTopUpTypeObject } from 'src/service/view-model/topup/TopupViewModel'
import { FormAccordion, FormAccordionDetails } from 'src/ui-shared/base/accordion/FormAccordion'
import { FormAccordionSummaryDataFilter } from 'src/ui-shared/base/accordion/FormAccordionSummaryDataFilter'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { useDataSettingsUrlSync } from 'src/ui-shared/base/hooks/useDataSettingsUrlSync'
import { ChartDataSelection } from 'src/ui-shared/chart/ChartDataSelection'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { CurrencyAutoComplete } from 'src/ui-shared/form/control/CurrencyAutoComplete'
import { withOrganization } from 'src/ui-shared/table/Table.const'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'

const AsyncChart = React.lazy(() => import('src/ui-shared/chart/Chart').then(({ Chart }) => ({ default: Chart })))

export const TopUpChart = (): ReactElement => {
  const { classes: textFieldClasses } = useTextFieldStyles()
  const translate = useTranslate()

  const location = useLocation()

  const activeOrganization = useActiveOrganization()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportsApi = new ReportWashMasterApi(httpConfiguration)

  const defaultTopUpChartSettings: TopUpChartSettings = { ...DEFAULT_TOPUP_CHART_SETTINGS }
  const [topUpChartSettings, setTopUpChartSettings] = useState<TopUpChartSettings>(
    withOrganization(defaultTopUpChartSettings, activeOrganization),
  )

  // derived state
  const activeFilter = Boolean(
    topUpChartSettings.currency || topUpChartSettings.dataTransPaymentMethod || topUpChartSettings.topUpType,
  )

  const loadChart = (): Promise<ChartData> => {
    const dateStartTimeFrom = new Date(topUpChartSettings.startDateFrom)
    const dateStartTimeTo = new Date(topUpChartSettings.startDateTo)

    return reportsApi.reportWmTopUpsChartGet(
      topUpChartSettings.groupBy,
      topUpChartSettings.factType,
      topUpChartSettings.chartScale,
      dateStartTimeFrom,
      dateStartTimeTo,
      undefined,
      topUpChartSettings.currency,
      topUpChartSettings.topUpType,
      topUpChartSettings.laundryUserId,
      topUpChartSettings.dataTransPaymentMethod,
    )
  }

  // generic reactivity

  // update state from url
  useDataSettingsUrlSync(location, setTopUpChartSettings, topUpChartSettings, defaultTopUpChartSettings)

  // handl events
  const handleCurrencyChange = (option: CurrencyObject | undefined) => {
    setTopUpChartSettings({
      ...topUpChartSettings,
      currency: option?.code as Currency,
    })
  }

  const handleReportTopUpTypeChange = (
    _event: React.SyntheticEvent,
    reportTopUpTypeObject: ReportTopUpTypeObject | null,
  ) => {
    setTopUpChartSettings({
      ...topUpChartSettings,
      topUpType: reportTopUpTypeObject?.id,
    })
  }

  const handleTopUpDatatransPaymentMethod = (
    event: unknown,
    topUpTransactionStatusObject: DatatransPaymentMethodObject | null,
  ) => {
    setTopUpChartSettings({
      ...topUpChartSettings,
      dataTransPaymentMethod: topUpTransactionStatusObject?.id,
    })
  }

  // render
  const renderFactTypeOptionLabel = (option: TopUpChartFactTypeObject) => {
    if (option.id === TopUpChartFactType.AMOUNT_SUM) {
      return `${translate(option.name)} ($)`
    }
    return `${translate(option.name)} (#)`
  }

  return (
    <Box mt={1}>
      {/* Groupings */}
      <Box>
        {/*Input fields for data selection: factType, groupBy and chartScale*/}
        <ChartDataSelection
          chartSettings={topUpChartSettings}
          setChartSettings={setTopUpChartSettings}
          factType={'factType'}
          groupBy={'groupBy'}
          scale={'chartScale'}
          factTypeOptions={TOPUP_CHART_FACT_TYPE_OBJECTS}
          factTypeOptionsLabel={renderFactTypeOptionLabel}
          factTypeValue={getTopUpChartFactTypeObject}
          groupByOptions={[getTopUpChartGroupByObject(topUpChartSettings.groupBy)] as TopUpChartGroupByObject[]}
          groupByOptionsLabel={(option) => translate(option.name)}
          groupByValue={getTopUpChartGroupByObject}
          scaleOptions={CHART_SCALE_OBJECTS}
          scaleOptionsLabel={(option) => translate(option.name)}
          scaleValue={getChartScaleObject}
        >
          {/*Input fields for selecting date range */}
          <TableDatePickerForm
            key={`${topUpChartSettings.startDateFrom}${topUpChartSettings.startDateTo}`}
            firstDatePropName="startDateFrom"
            secondDatePropName="startDateTo"
            tableSettings={topUpChartSettings}
            setTableSettings={setTopUpChartSettings}
          />
        </ChartDataSelection>
      </Box>

      {/* Filters */}
      <Box mt={1}>
        <FormAccordion>
          <FormAccordionSummaryDataFilter activeFilter={activeFilter} />
          <FormAccordionDetails>
            <Grid container spacing={2}>
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={4}>
                <CurrencyAutoComplete
                  key={topUpChartSettings.currency}
                  onChange={handleCurrencyChange}
                  name="currency-autocomplete"
                  textFieldClassName={textFieldClasses.TextFieldSmall}
                  value={findReferenceObject(CURRENCIES, topUpChartSettings.currency?.toString(), 'code')}
                  disabled={false}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} sm={6} md={6} lg={4}>
                <Autocomplete
                  key={topUpChartSettings.topUpType}
                  openOnFocus={true}
                  options={REPORT_TOP_UP_TYPES_OBJECTS}
                  getOptionLabel={(option) => translate(option.name) || ''}
                  isOptionEqualToValue={(option) => option.id === topUpChartSettings.topUpType}
                  disableClearable={false}
                  multiple={false}
                  freeSolo={false}
                  value={findReferenceObject(REPORT_TOP_UP_TYPES_OBJECTS, topUpChartSettings.topUpType)}
                  onChange={handleReportTopUpTypeChange}
                  noOptionsText={translate('autocompleteNoOptions')}
                  loadingText={translate('autocompleteLoading')}
                  renderInput={(params) => (
                    <TextFieldDefault
                      {...params}
                      label={translate('topUpType')}
                      className={textFieldClasses.TextFieldSmall}
                    />
                  )}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} sm={6} md={6} lg={4}>
                <Autocomplete
                  key={topUpChartSettings.dataTransPaymentMethod}
                  openOnFocus={true}
                  options={DATATRANS_PAYMENT_METHOD_OBJECTS}
                  getOptionLabel={(option) => option.name || ''}
                  isOptionEqualToValue={(option) => option.id === topUpChartSettings.dataTransPaymentMethod}
                  onChange={handleTopUpDatatransPaymentMethod}
                  value={findReferenceObject(
                    DATATRANS_PAYMENT_METHOD_OBJECTS,
                    topUpChartSettings.dataTransPaymentMethod,
                  )}
                  noOptionsText={translate('autocompleteNoOptions')}
                  loadingText={translate('autocompleteLoading')}
                  renderInput={(params) => {
                    return (
                      <TextFieldDefault
                        {...params}
                        label={translate('datatransPaymentMethod')}
                        className={textFieldClasses.TextFieldSmall}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>
          </FormAccordionDetails>
        </FormAccordion>
      </Box>
      <Suspense fallback={<div style={{ textAlign: 'center' }}>{translate('autocompleteLoading')}</div>}>
        <AsyncChart loadChart={loadChart} chartSettings={topUpChartSettings} />
      </Suspense>
    </Box>
  )
}
