import { AppId } from 'src/app/AppId'
import { DEFAULT_USER_REGION_DATA, User } from 'src/user/User'

function initFakeLogin(): Promise<User> {
  return new Promise((resolve) => {
    setTimeout(() => {
      const demoUser: User = {
        id: '11111111-2222-3333-4444-555555555555',
        firstName: 'Demo',
        lastName: 'User',
        email: 'demo@schulthess.online',
        locale: 'en',
        username: 'demo',
        roles: ['SMAG_ADMIN', 'offline_access', 'uma_authorization'],
        availableApps: [AppId.WASH_MASTER, AppId.SERVICE_MASTER],
        defaultApp: AppId.WASH_MASTER,
        permissions: [],
        token: 'fake-user-token',
        blocked: false,
        globalRoles: [],
        memberRolesAll: [],
        regionData: DEFAULT_USER_REGION_DATA,
      }

      console.warn('Fake login user: ', demoUser)
      resolve(demoUser)
    }, 100)
  })
}

export const FakeLoginService = { initFakeLogin }
