import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Grid, Paper, TableCell, TableRow } from '@mui/material'
import { errorMapperReporting } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  Configuration,
  LaundriesApi,
  LaundryGroupsApi,
  MachinesApi,
  OrganizationsApi,
  ReportUsagesApi,
} from 'src/service/backend/api'
import {
  LaundryGroupReference,
  LaundryReference,
  MachineCategory,
  MachineReference,
  OrganizationReference,
  SMUsage,
} from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocale, formatSecondsToHumanReadableTime } from 'src/service/utils/DateFormatUtils'
import { formatTemperatureOptional } from 'src/service/utils/NumberFormatUtils'
import { isValidPositiveNumber } from 'src/service/utils/NumberUtils'
import { Data } from 'src/service/view-model/base/Data'
import {
  MACHINE_CATEGORIES,
  getMachineCategoryName,
  getMachineOptionLabel,
} from 'src/service/view-model/machine/Machines'
import { EMPTY_ORGANIZATION_REF } from 'src/service/view-model/organization/Organizations'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettingsWithOrganization,
  tableSettingsSort,
  withOrganization,
} from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { TableFilterAutocompleteOrganization } from 'src/ui-shared/table/TableFilterAutocompleteOrganization'
import { TableFilterTextField } from 'src/ui-shared/table/TableFilterTextField'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { useUserRegionLocale } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'startTime',
    label: 'startDate',
  },
  {
    id: 'organization.name',
    label: 'organization',
  },
  {
    id: 'laundryGroup.name',
    label: 'laundryGroup',
  },
  {
    id: 'laundry.name',
    label: 'laundry',
  },
  {
    id: 'machine.category',
    label: 'member.machinetype',
  },
  {
    id: 'machine.name',
    label: 'machine',
  },
  {
    id: 'programInfo.temperature',
    label: 'temperatureShort',
  },
  {
    id: 'programInfo.programName',
    label: 'programName',
  },
  {
    id: 'programInfo.programMode',
    label: 'programMode',
  },
  {
    id: 'programDuration',
    label: 'programDuration',
    noSort: true,
  },
]

export interface ProgramUsageTableSettings extends TableSettingsWithOrganization {
  orderBy: string
  startTimeFrom?: string
  startTimeTo?: string
  laundryGroupId?: string
  laundryId?: string
  machineId?: string
  machineCategory?: MachineCategory
  programMode?: string
  temperature?: number
  programName?: string
}

interface Props {
  tableSettings: ProgramUsageTableSettings
  setTableSettings: React.Dispatch<React.SetStateAction<ProgramUsageTableSettings>>
  defaultTableSettings: ProgramUsageTableSettings
}

export const ProgramUsageTable: FC<Props> = ({
  tableSettings,
  setTableSettings,
  defaultTableSettings,
}): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  const location = useLocation()

  const activeOrganization = useActiveOrganization()
  const regionLocale = useUserRegionLocale()

  const translate = useTranslate()

  const [organizations, setOrganizations] = useState<OrganizationReference[]>([])
  const [laundryGroups, setLaundryGroups] = useState<LaundryGroupReference[]>([])
  const [laundries, setLaundries] = useState<LaundryReference[]>([])
  const [machines, setMachines] = useState<MachineReference[]>([])

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportsApi = new ReportUsagesApi(httpConfiguration)
  const organizationsApi = new OrganizationsApi(httpConfiguration)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)
  const laundriesApi = new LaundriesApi(httpConfiguration)
  const machinesApi = new MachinesApi(httpConfiguration)

  // state
  const [data, setData] = useState<TableData<SMUsage>>(DEFAULT_DATA)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  // reactivity
  const secondsLabel = ' ' + translate('seconds')

  // load data for filters
  useEffect(() => {
    tableSettings = withOrganization(defaultTableSettings, activeOrganization)

    organizationsApi.organizationsRefGet().then((data) => {
      data.unshift(EMPTY_ORGANIZATION_REF)
      setOrganizations(data)
    })

    laundryGroupsApi.laundrygroupsRefGet().then((data) => {
      setLaundryGroups(data)
    })

    laundriesApi.laundriesRefGet().then((data) => {
      setLaundries(data)
    })

    machinesApi.machinesRefGet().then((data) => {
      setMachines(data as MachineReference[])
    })
  }, [])

  // load data on state change
  useEffect(() => {
    let active = true

    setErrorMessage(null)

    if (tableSettings.startTimeFrom && tableSettings.startTimeTo) {
      const dateStartTimeFrom = new Date(tableSettings.startTimeFrom)
      const dateStartTimeTo = new Date(tableSettings.startTimeTo)

      setLoading(true)

      reportsApi
        .reportSmUsagesGet(
          dateStartTimeFrom!,
          dateStartTimeTo!,
          tableSettings.size,
          tableSettings.page,
          tableSettingsSort(tableSettings),
          tableSettings.organizationId,
          tableSettings.laundryGroupId,
          tableSettings.laundryId,
          tableSettings.machineId,
          tableSettings.machineCategory,
          tableSettings.programMode,
          tableSettings.programName,
          tableSettings.temperature,
        )
        .then((data) => {
          if (active) {
            updateTableSettingsFromData(data as Data<any>, tableSettings)
            setTableSettings(tableSettings)

            setData(mapData(data as Data<any>))
            setLoading(false)
          }
        })
        .catch((err) => {
          const errorMessage = errorMapperReporting(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setData(DEFAULT_DATA)
          setLoading(false)
        })
    }

    return () => {
      active = false
    }
  }, [tableSettings])

  // JSX
  const nonEmptyRows = data.data.map((item, index) => {
    return (
      <TableRow className={tableClasses.tableRow} style={{ cursor: 'auto' }} key={index}>
        <TableCell>{formatDateTimeForLocale(item.startTime, regionLocale)}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={item.organization.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={item.laundryGroup.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={item.laundry.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={getMachineCategoryName(item.machine.category, translate)} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={`${item.machine.name} / ${item.machine.serialNumber}`} />
        </TableCell>
        <TableCell>
          {formatTemperatureOptional(item.programInfo?.temperature, item.programInfo?.temperatureUnit)}
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={item.programInfo?.programName} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={item.programInfo?.programMode} />
        </TableCell>
        <TableCell title={item.programDuration ? item.programDuration + secondsLabel : undefined}>
          {item.programDuration ? formatSecondsToHumanReadableTime(item.programDuration) : ''}
        </TableCell>
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string) => {
    let filter
    if (headCellId === 'organization.name') {
      filter = (
        <TableFilterAutocompleteOrganization
          options={organizations}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'laundryGroup.name') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundryGroup')}
          label={translate('laundryGroups')}
          filter={'laundryGroupId'}
          labelFieldName="name"
          valueFieldName="id"
          options={laundryGroups}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'laundry.name') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundry')}
          label={translate('laundries')}
          filter={'laundryId'}
          labelFieldName="name"
          valueFieldName="id"
          options={laundries}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'machine.category') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByMachineType')}
          label={translate('member.machinetype')}
          filter={'machineCategory'}
          labelFieldName="name"
          valueFieldName="id"
          options={MACHINE_CATEGORIES.map((machine) => {
            return { ...machine, name: translate(machine.name) }
          })}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'machine.name') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByMachine')}
          label={translate('machines')}
          filter={'machineId'}
          labelFieldName="name"
          getOptionLabel={getMachineOptionLabel}
          valueFieldName="id"
          options={machines}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'programInfo.programName') {
      filter = (
        <TableFilterTextField
          title={translate('filterByProgramName')}
          label={translate('programName')}
          filter={'programName'}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          delay={500}
        />
      )
    } else if (headCellId === 'programInfo.temperature') {
      filter = (
        <TableFilterTextField
          title={translate('filterByTemperature')}
          label={translate('temperature')}
          filter={'temperature'}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          delay={500}
          isValid={(value) => {
            return isValidPositiveNumber(value)
          }}
          errorText={translate('validation.valid.temperature')}
        />
      )
    } else if (headCellId === 'programInfo.programMode') {
      filter = (
        <TableFilterTextField
          title={translate('filterByProgramMode')}
          label={translate('programMode')}
          filter={'programMode'}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          delay={500}
        />
      )
    }
    return filter
  }

  return (
    <>
      <Box mt={3} mb={3}>
        <Grid container spacing={2}>
          <TableDatePickerForm
            key={`${tableSettings.startTimeFrom}${tableSettings.startTimeTo}`}
            firstDatePropName="startTimeFrom"
            secondDatePropName="startTimeTo"
            setTableSettings={setTableSettings}
            tableSettings={tableSettings}
          />
        </Grid>
      </Box>

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <Paper elevation={0}>
        <DataTable
          headCells={headCells}
          data={data}
          nonEmptyRows={nonEmptyRows}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          getFilter={getFilter}
          loading={loading}
          translate={translate}
        />
      </Paper>
    </>
  )
}
