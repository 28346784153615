/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateNotification,
  Notification,
  NotificationCheck,
  PageableNotifications,
} from '../models';
import {
    CreateNotificationFromJSON,
    CreateNotificationToJSON,
    NotificationFromJSON,
    NotificationToJSON,
    NotificationCheckFromJSON,
    NotificationCheckToJSON,
    PageableNotificationsFromJSON,
    PageableNotificationsToJSON,
} from '../models';

export interface NotificationsGetRequest {
    startTimeFrom: Date;
    startTimeTo: Date;
    size?: number;
    page?: number;
    sort?: string;
}

export interface NotificationsNotificationIdPutRequest {
    notificationId: string;
    notification?: Notification;
}

export interface NotificationsReadPostRequest {
    body?: object;
}

export interface NotificationsSendPostRequest {
    createNotification?: CreateNotification;
}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     * Check for new notifications
     */
    async notificationsCheckGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationCheck>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notifications/check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationCheckFromJSON(jsonValue));
    }

    /**
     * Check for new notifications
     */
    async notificationsCheckGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationCheck> {
        const response = await this.notificationsCheckGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * List notifications
     */
    async notificationsGetRaw(requestParameters: NotificationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableNotifications>> {
        if (requestParameters.startTimeFrom === null || requestParameters.startTimeFrom === undefined) {
            throw new runtime.RequiredError('startTimeFrom','Required parameter requestParameters.startTimeFrom was null or undefined when calling notificationsGet.');
        }

        if (requestParameters.startTimeTo === null || requestParameters.startTimeTo === undefined) {
            throw new runtime.RequiredError('startTimeTo','Required parameter requestParameters.startTimeTo was null or undefined when calling notificationsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.startTimeFrom !== undefined) {
            queryParameters['startTimeFrom'] = this.toIsoStringLocalTimezone(requestParameters.startTimeFrom as any);
        }

        if (requestParameters.startTimeTo !== undefined) {
            queryParameters['startTimeTo'] = this.toIsoStringLocalTimezone(requestParameters.startTimeTo as any);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableNotificationsFromJSON(jsonValue));
    }

    /**
     * List notifications
     */
    async notificationsGet(startTimeFrom: Date, startTimeTo: Date, size?: number, page?: number, sort?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableNotifications> {
        const response = await this.notificationsGetRaw({ startTimeFrom: startTimeFrom, startTimeTo: startTimeTo, size: size, page: page, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Update a notification
     */
    async notificationsNotificationIdPutRaw(requestParameters: NotificationsNotificationIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling notificationsNotificationIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notifications/{notificationId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationToJSON(requestParameters.notification),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a notification
     */
    async notificationsNotificationIdPut(notificationId: string, notification?: Notification, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsNotificationIdPutRaw({ notificationId: notificationId, notification: notification }, initOverrides);
    }

    /**
     * Set all notification as read
     */
    async notificationsReadPostRaw(requestParameters: NotificationsReadPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notifications/read`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set all notification as read
     */
    async notificationsReadPost(body?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsReadPostRaw({ body: body }, initOverrides);
    }

    /**
     * Send a notification
     */
    async notificationsSendPostRaw(requestParameters: NotificationsSendPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notifications/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNotificationToJSON(requestParameters.createNotification),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send a notification
     */
    async notificationsSendPost(createNotification?: CreateNotification, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsSendPostRaw({ createNotification: createNotification }, initOverrides);
    }

}
