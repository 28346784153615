import React, { FC, ReactElement, useContext } from 'react'
import { Form } from 'react-final-form'
import { Box, Button, Grid } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, LaundriesApi, LaundryGroupReference, MachinePairingInfo } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  MachinePairPostViewModel,
  mapMachinePairingInfoToMachinePairPostViewModel,
} from 'src/service/view-model/machine/MachinePairViewModel'
import { getMachineTypeName } from 'src/service/view-model/machine/Machines'
import { AsyncAutoCompleteValidate } from 'src/ui-shared/base/form/control/AsyncAutoCompleteValidate'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { required } from 'src/ui-shared/base/form/validation/Validators'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

interface Props {
  setOpenModal: (value: boolean) => void
  onSubmit: (values: MachinePairPostViewModel) => void
  laundryGroupRef: LaundryGroupReference
  prefilledPairingInfo: MachinePairingInfo
  isSuccess: boolean
}

export const MachinePairPrefilledForm: FC<Props> = ({
  setOpenModal,
  onSubmit,
  laundryGroupRef,
  prefilledPairingInfo: prefilledPairingInfo,
  isSuccess,
}): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundriesApi = new LaundriesApi(httpConfiguration)

  const machinePairPostViewModel = mapMachinePairingInfoToMachinePairPostViewModel(prefilledPairingInfo)

  // load data
  const laundrySearch = (searchText: string): Promise<LaundryGroupReference[]> => {
    return laundriesApi.laundriesRefGet(undefined, searchText, [laundryGroupRef.id])
  }

  return (
    <Box mt={2}>
      <Form<MachinePairPostViewModel>
        initialValues={machinePairPostViewModel}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label={translate('machineName')}
                    fullWidth
                    autoFocus
                    disabled={submitting}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DetailsTextField
                    label={translate('member.machinetype')}
                    value={getMachineTypeName(machinePairPostViewModel.type, translate)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DetailsTextField label={translate('serialNumber')} value={machinePairPostViewModel.serialNumber} />
                </Grid>
                <Grid item xs={12}>
                  <AsyncAutoCompleteValidate
                    disabled={submitting}
                    name="laundryRef"
                    validate={required()}
                    label={translate('laundry')}
                    delay={300}
                    labelFieldName="name"
                    loadOptionsFunction={laundrySearch}
                  />
                </Grid>
              </Grid>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  color="primary"
                  size="large"
                  className={sharedClasses.ButtonMargin}
                  onClick={() => setOpenModal(false)}
                >
                  {translate('button.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={pristine || submitting || isSuccess}
                >
                  {translate('button.pair')}
                </Button>
              </Box>
            </form>
          )
        }}
      />
    </Box>
  )
}
