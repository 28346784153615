const OVERVIEWS = 'overviews'
const USER_MANAGEMENT = 'userManagement'
const REPORTS_ANALYSES = 'reportsAndAnalyses'
const ADMINISTRATION = 'administration'
const MONITORING_SERVICE = 'monitoringAndService'

const WASHING_PROGRAMS = 'washingPrograms'

export const WmMenuSections = {
  OVERVIEWS,
  USER_MANAGEMENT,
  REPORTS_ANALYSES,
  ADMINISTRATION,
}

export const SmMenuSections = {
  OVERVIEWS,
  USER_MANAGEMENT,
  REPORTS_ANALYSES,
  MONITORING_SERVICE,
}

export const EsMenuSections = {
  WASHING_PROGRAMS,
}
