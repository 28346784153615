import React, { ReactElement } from 'react'
import { TextField, TextFieldProps, styled } from '@mui/material'
import { TEXT_FIELD_DEFAULT_STYLE } from 'src/ui-shared/base/form/control/TextField.style'

const StyledTextFieldDefault = styled(TextField)(() => ({
  ...TEXT_FIELD_DEFAULT_STYLE,
}))

export const TextFieldDefault = (props: TextFieldProps): ReactElement => {
  return (
    <StyledTextFieldDefault
      {...props}
      variant={props.variant || 'filled'}
      fullWidth={props.fullWidth || true}
      InputLabelProps={
        // default to shrinking the label if InputLabelProps.defaultShrinkBehaviour is provided explicitly
        // otherwise we used shrinked label for all forms
        (props.InputLabelProps as any)?.defaultShrinkBehaviour
          ? props.InputLabelProps
          : { ...props.InputLabelProps, shrink: true }
      }
    />
  )
}
