import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Link, Paper, TableCell, TableRow } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  Configuration,
  LaundryGroupLog,
  LaundryGroupsApi,
  Machine,
  MachineReference,
  MachinesApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocale } from 'src/service/utils/DateFormatUtils'
import { Data, OrderBy } from 'src/service/view-model/base/Data'
import { getMachineOptionLabel } from 'src/service/view-model/machine/Machines'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettings,
  getDefaultTableSettings,
  tableSettingsSort,
  withOrganization,
} from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'
import { useUserRegionLocale } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'description',
    label: 'event',
  },
  {
    id: 'machine',
    label: 'resource',
  },
  {
    id: 'time',
    label: 'date',
  },
]

interface LaundryGroupLogTableSettings extends TableSettings {
  orderBy: OrderBy<keyof LaundryGroupLog>
  machineId?: string
}

interface Props {
  laundryGroupId: string
  machine?: Machine
}

export const ProgramInstallationTable: FC<Props> = ({ laundryGroupId, machine }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const translate = useTranslate()

  const location = useLocation()
  const appId = useAppId()
  const activeOrganization = useActiveOrganization()
  const regionLocale = useUserRegionLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)

  const machinesApi = new MachinesApi(httpConfiguration)

  const machineId = machine?.id

  // state
  const defaultTableSettings: LaundryGroupLogTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'time',
    orderDir: 'desc',
  }
  const [tableSettings, setTableSettings] = useState<LaundryGroupLogTableSettings>({
    ...withOrganization(defaultTableSettings, activeOrganization),
    machineId: machineId,
  })
  const [data, setData] = useState<TableData<LaundryGroupLog>>(DEFAULT_DATA)
  const [machines, setMachines] = useState<MachineReference[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    machinesApi.machinesRefGet().then((data) => {
      setMachines(data)
    })
  }, [])

  // load data
  useEffect(() => {
    let active = true

    setLoading(true)
    setErrorMessage(null)

    laundryGroupsApi
      .laundrygroupsLaundryGroupIdLogGet(
        laundryGroupId,
        tableSettings.size,
        tableSettings.page,
        tableSettingsSort(tableSettings),
        machineId,
      )
      .then((data) => {
        if (active) {
          updateTableSettingsFromData(data as Data<any>, tableSettings)
          setTableSettings(tableSettings)

          setData(mapData(data as Data<any>))
          setLoading(false)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [tableSettings, laundryGroupId])

  const getFilter = (headCellId: string): ReactElement | undefined => {
    let filter
    if (headCellId === 'machine' && !machine) {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByMachine')}
          label={translate('machines')}
          filter={'machineId'}
          labelFieldName="name"
          getOptionLabel={getMachineOptionLabel}
          valueFieldName="id"
          options={machines}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }
    return filter
  }

  // JSX
  const nonEmptyRows = data.data.map((item, index) => {
    const machine = item.machine

    return (
      <TableRow className={tableClasses.tableRow} key={index} style={{ cursor: 'default' }}>
        <TableCell>{item.description}</TableCell>
        <TableCell>
          {machine && (
            <Link
              color="primary"
              component={RouterLink}
              to={`/${appId}/machines/${machine.id}/view/${MachineTab.OVERVIEW}`}
              state={{ browserHistoryBack: true }}
              underline="hover"
            >
              {machine.name}
            </Link>
          )}
        </TableCell>
        <TableCell>{formatDateTimeForLocale(item.time, regionLocale)}</TableCell>
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  return (
    <Paper elevation={0}>
      {errorMessage && <ErrorMessage message={errorMessage} />}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        getFilter={getFilter}
        loading={loading}
        translate={translate}
      />
    </Paper>
  )
}
