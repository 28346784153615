import React, { ComponentType, ReactElement, useContext, useState } from 'react'
import { Alert, Button, Paper } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ActiveOrganizationContext, useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { OrganizationReference } from 'src/service/backend/api'
import { SelectOrganizationDialog } from 'src/ui/layout/organization-selector/SelectOrganizationDialog'

export const WithMandatoryActiveOrganization = <T,>(Component: ComponentType<T>) => {
  return function WrappedComponent(componentProps: T): ReactElement {
    const translate = useTranslate()
    const activeOrganization = useActiveOrganization()

    const { setActiveOrganizationId } = useContext(ActiveOrganizationContext)

    const [openModal, setOpenModal] = useState<boolean>(false)

    const changeActiveOrganization = (newActiveOrganization?: OrganizationReference) => {
      setActiveOrganizationId(newActiveOrganization?.id)
      setOpenModal(false)
    }

    // if active organization is not set, the user sees a modal and info message for selecting and organization
    if (!activeOrganization) {
      return (
        <>
          <Paper elevation={0} sx={{ pt: 2, px: 0.5 }}>
            <Alert severity="info">{translate('selectOrganizationInfo')}</Alert>
            <Button sx={{ mt: 2 }} variant="outlined" onClick={() => setOpenModal(true)}>
              {translate('selectOrganization')}
            </Button>
          </Paper>

          <SelectOrganizationDialog
            open={openModal}
            setOpen={setOpenModal}
            onConfirm={changeActiveOrganization}
            showNoneOrganizationOption={true}
            preselectFirstIfSingle={true}
            displayActions={false}
            modalTitle={'activeOrganization'}
          />
        </>
      )
    }

    return <Component key="" {...(componentProps as T)} />
  }
}
