import { differenceInDays, differenceInHours, differenceInMinutes, format, parse } from 'date-fns'
import { TranslateFunction } from 'src/i18n/useMessageSource'
import {
  DATE_FORMAT,
  DATE_FORMAT_US,
  DATE_ISO_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_SECONDS,
  MONTH_FORMAT,
  MONTH_ONLY_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_12,
  TIME_FORMAT_SECONDS,
  TIME_FORMAT_SECONDS_12,
  WEEKDAY_TEXT_FORMAT,
  YEAR_FORMAT,
} from 'src/service/utils/DateFnsFormatsConstants'
import { DateUtils } from 'src/service/utils/DateUtils'
import { SupportedLocale } from 'src/service/view-model/base/localization/SupportedLocale'

export const getDateFormatForLocale = (locale: SupportedLocale): string => {
  if (locale === 'en-US') {
    return DATE_FORMAT_US
  }
  return DATE_FORMAT
}

const getTimeFormatForLocale = (locale: SupportedLocale): string => {
  if (locale === 'en-US') {
    return TIME_FORMAT_12
  }
  return TIME_FORMAT
}

const getTimeSecondsFormatForLocale = (locale: SupportedLocale): string => {
  if (locale === 'en-US') {
    return TIME_FORMAT_SECONDS_12
  }
  return TIME_FORMAT_SECONDS
}

const getDateTimeFormatForLocale = (locale: SupportedLocale): string => {
  return getDateFormatForLocale(locale) + ' ' + getTimeFormatForLocale(locale)
}

const getDateTimeSecondsFormatForLocale = (locale: SupportedLocale): string => {
  return getDateFormatForLocale(locale) + ' ' + getTimeSecondsFormatForLocale(locale)
}

/**
 * Formats the date in format: 01.12.2023
 * @param {Date} date - the passed date
 * @return {string} - the formatted date
 */
export const formatDate = (date: Date): string => {
  return format(date, DATE_FORMAT)
}

/**
 * Formats the date in format: 01.12.2023 15:00
 * @param {Date} date - the passed date
 * @return {string} - the formatted date time
 */
export const formatDateTime = (dateTime: Date): string => {
  return format(dateTime, DATE_TIME_FORMAT)
}

/**
 * Formats the date in format: 01.12.2023 15:00:00
 * @param {Date} date - the passed date
 * @return {string} - the formatted date time
 */
export const formatDateTimeSeconds = (dateTime: Date): string => {
  return format(dateTime, DATE_TIME_FORMAT_SECONDS)
}

/**
 * Formats the date in format: 15:00
 * @param {Date} date - the passed date
 * @return {string} - the formatted time
 */
export const formatTime = (dateTime: Date): string => {
  return format(dateTime, TIME_FORMAT)
}

/**
 * Formats the date in iso format: 2023-12-01
 * @param {Date} date - the passed date
 * @return {string} - the formatted date
 */
export const formatIsoDate = (date: Date): string => {
  return format(date, DATE_ISO_FORMAT)
}

/**
 * Formats the given date and locale to the locale format
 * for example US locale returns 12/24/2023 and europe locale return 24.12.2023
 * Returns empty string if date is empty
 * @param {Date} date - the passed date which could be null
 * @param {SupportedLocale} locale - given locale
 * @return {string} - the formatted date
 */
export const formatDateForLocaleOptional = (date: Date | null | undefined, locale: SupportedLocale): string => {
  if (date == null) {
    return ''
  }
  return formatDateForLocale(date, locale)
}

/**
 * Formats the given date and locale to the locale format
 * for example US locale returns 12/24/2023 and europe locale return 24.12.2023
 * @param {Date} date - the passed date
 * @param {SupportedLocale} locale - given locale
 * @return {string} - the formatted date
 */
export const formatDateForLocale = (date: Date, locale: SupportedLocale): string => {
  const dateFormat = getDateFormatForLocale(locale)
  return format(date, dateFormat)
}

/**
 * Formats the given date to month and year.
 * @param {Date} date - the passed date
 * @param {SupportedLocale} locale - given locale, not used for now (formatted the same in all supported locales)
 * @param includeYear to include the year in the format, or just return the month without year
 * @return {string} - the formatted date
 */
export const formatMonthForLocale = (date: Date, _: SupportedLocale, includeYear: boolean = true): string => {
  return format(date, includeYear ? MONTH_FORMAT : MONTH_ONLY_FORMAT)
}

/**
 * Formats the given date to year.
 * @param {Date} date - the passed date
 * @param {SupportedLocale} locale - given locale, not used for now (formatted the same in all supported locales)
 * @return {string} - the formatted date
 */
export const formatYearForLocale = (date: Date, _: SupportedLocale): string => {
  return format(date, YEAR_FORMAT)
}

/**
 * Formats the given date time and locale to the locale format
 * for example US locale returns 12/24/2023 03:00 PM and europe locale return 24.12.2023 15:00
 * Returns empty string if date is empty
 * @param {Date} dateTime - the passed date time which could be empty
 * @param {SupportedLocale} locale - given locale
 * @return {string} - the formatted date time
 */
export const formatDateTimeForLocaleOptional = (date: Date | null | undefined, locale: SupportedLocale): string => {
  if (date == null) {
    return ''
  }
  return formatDateTimeForLocale(date, locale)
}

/**
 * Formats the given date time and locale to the locale format
 * for example US locale returns 12/24/2023 03:00 PM and europe locale return 24.12.2023 15:00
 * @param {Date} dateTime - the passed date time
 * @param {SupportedLocale} locale - given locale
 * @return {string} - the formatted date time
 */
export const formatDateTimeForLocale = (dateTime: Date, locale: SupportedLocale): string => {
  const dateTimeFormat = getDateTimeFormatForLocale(locale)
  return format(dateTime, dateTimeFormat)
}

/**
 * Formats the given date time and locale to the locale format
 * for example US locale returns 12/24/2023 03:00:00 PM and europe locale return 24.12.2023 15:00:00
 * @param {Date} dateTime - the passed date time
 * @param {SupportedLocale} locale - given locale
 * @return {string} - the formatted date time
 */
export const formatDateTimeSecondsForLocale = (dateTime: Date, locale: SupportedLocale): string => {
  const dateTimeFormat = getDateTimeSecondsFormatForLocale(locale)
  return format(dateTime, dateTimeFormat)
}

/**
 * Formats the given date and locale to the locale format
 * for example US locale returns 03:00 PM and europe locale returns 15:00
 * @param {Date} time - the passed date
 * @param {SupportedLocale} locale - given locale
 * @return {string} - the formatted time
 */
export const formatTimeForLocale = (time: Date, locale: SupportedLocale): string => {
  const timeFormat = getTimeFormatForLocale(locale)
  return format(time, timeFormat)
}

/**
 * Formats the given date to time with 12 hour format.
 * @param {Date} time - the passed time date
 * @return {string} - the formatted time ex: 03:00 AM
 */
export const formatTime12Hours = (time: Date): string => {
  return format(time, TIME_FORMAT_12)
}

/**
 * Formats given time string in iso format (15:00) and locale to the locale format
 * and formats it to the corresponding locale
 * for example US locale returns 03:00 PM and europe locale returns 15:00
 * @param {string} timeString - the passed time string
 * @param {SupportedLocale} locale - given locale
 * @return {string} - the formatted time
 */
export const formatTimeStringForLocale = (timeString: string, locale: SupportedLocale): string => {
  const timeDate = parse(timeString, TIME_FORMAT, 0)
  return formatTimeForLocale(timeDate, locale)
}

/**
 * Formats the given timeString and format to time with 24 hour format.
 * @param {string} timeString - the passed time string
 * @param {string} formatPattern - the given format
 * @return {string} - the formatted time ex: 15:00
 */
export const formatTimeStringFromFormatPatternTo24Hour = (timeString: string, formatPattern: string): string => {
  const time = parse(timeString, formatPattern, 0)
  return format(time, TIME_FORMAT)
}

/**
 * Formats the given 12hour timeString to time with 24 hour format.
 * @param {string} timeString - the passed time string (12:00 AM format)
 * @return {string} - the formatted time ex: 15:00
 */
export const formatTimeStringTo24Hour = (timeString: string): string => {
  const time = parse(timeString, TIME_FORMAT_12, 0)
  return format(time, TIME_FORMAT)
}

/**
 * Formats the given 24hour timeString to time with 12 hour format.
 * @param {string} timeString - the passed time string
 * @return {string} - the formatted time ex: 03:00 PM
 */
export const formatTimeStringTo12Hour = (timeString: string): string => {
  const time = parse(timeString, TIME_FORMAT, 0)
  return format(time, TIME_FORMAT_12)
}

/**
 * Formats the given iso date string (ex: 2023-12-01) to localized day and date (ex: Monday, 01.12.2023).
 * @param {string} isoDate - the iso date string
 * @param {SupportedLocale} locale - given locale
 * @return {string} - translation function for the day
 */
export const formatIsoDateStringToDayAndDate = (
  isoDate: string,
  locale: SupportedLocale,
  translate: TranslateFunction,
): string => {
  const date = DateUtils.parseDateIso(isoDate)
  const day = translate(getDayTextForDate(date).toLowerCase())
  const dateFormatted = formatDateForLocale(date, locale)

  return `${day}, ${dateFormatted}`
}

const formatWithZero = (n: number): string => {
  return (n < 10 ? '0' : '') + n.toString()
}

export const formatSecondsToHumanReadableTime = (seconds: number): string => {
  if (seconds < 60) {
    return formatWithZero(seconds) + 's'
  }

  const minutes = Math.floor(seconds / 60)

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes - hours * 60

  return formatWithZero(hours) + ':' + formatWithZero(remainingMinutes)
}

export const getRelativeTime = (locales: string, date: Date, now: Date): string => {
  const rtf = new Intl.RelativeTimeFormat(locales, {
    numeric: 'auto',
  })

  const minutes = differenceInMinutes(now, date)

  if (minutes > -60 && minutes < 60) {
    return rtf.format(-minutes, 'minute')
  }

  const hours = differenceInHours(now, date)
  if (hours > -24 && hours < 24) {
    return rtf.format(-hours, 'hour')
  }

  const days = differenceInDays(now, date)
  if (days > -7 && days <= 7) {
    return rtf.format(-days, 'day')
  } else {
    return formatDate(date)
  }
}

export const getRelativeTimeFromNow = (locales: string, date: Date): string => {
  return getRelativeTime(locales, date, new Date())
}

export const getDayTextForDate = (date: Date): string => {
  return format(date, WEEKDAY_TEXT_FORMAT)
}

export const getNameOfDay = (dayOfWeek: number): string => {
  if (dayOfWeek === 1) {
    return 'monday'
  } else if (dayOfWeek === 2) {
    return 'tuesday'
  } else if (dayOfWeek === 3) {
    return 'wednesday'
  } else if (dayOfWeek === 4) {
    return 'thursday'
  } else if (dayOfWeek === 5) {
    return 'friday'
  } else if (dayOfWeek === 6) {
    return 'saturday'
  } else if (dayOfWeek === 7) {
    return 'sunday'
  } else {
    throw new Error(`Invalid day of week number to get name, number from  1 to 7 expected, given: ${dayOfWeek}`)
  }
}
