import React, { FC, ReactElement, useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { Box, Button, Grid } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  LaundryGroupReference,
  LaundryUser,
  LaundryUsersPrepaidApi,
  PrepaidBalance,
  TopupPrepaidByOperator,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  LaundryUserTopupViewModel,
  mapLaundryUserTopupViewModelToTopupPrepaidByOperator,
} from 'src/service/view-model/laundry-user/LaundryUserTopupViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { AsyncAutoCompleteValidate } from 'src/ui-shared/base/form/control/AsyncAutoCompleteValidate'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { composeValidators, required, validAmount, validAmountMax } from 'src/ui-shared/base/form/validation/Validators'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

interface Props {
  laundryUser: LaundryUser
  balance: PrepaidBalance
  setOpenModal: (value: boolean) => void
  loadBalance: () => void
}

export const LaundryUserPaymentTopup: FC<Props> = ({
  laundryUser,
  balance,
  setOpenModal,
  loadBalance,
}): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryUserPrepaidApi = new LaundryUsersPrepaidApi(httpConfiguration)

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const assignedLaundryGroups = laundryUser.assignedLaundryGroups

  const defaultViewModel: LaundryUserTopupViewModel = {
    amount: undefined,
    currency: balance.currency,
    laundryGroupRef: assignedLaundryGroups.length > 0 ? laundryUser.assignedLaundryGroups[0] : undefined,
  }

  const topupSubmit = (values: LaundryUserTopupViewModel) => {
    const topup: TopupPrepaidByOperator = mapLaundryUserTopupViewModelToTopupPrepaidByOperator(values)
    setErrorMessage(null)
    setLoading(true)

    laundryUserPrepaidApi
      .laundryusersLaundryUserIdPrepaidTopUpPost(laundryUser.id, topup)
      .then(() => {
        setOpenModal(false)
        setLoading(false)
        const message = translate('topupSuccessful')
        showSnackbar(message, 'success')
        loadBalance()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLoading(false)
      })
  }

  const laundryGroupSearch = (): Promise<LaundryGroupReference[]> => {
    return new Promise<LaundryGroupReference[]>((resolve) => {
      resolve(assignedLaundryGroups)
    })
  }

  const maxAmount = 200
  return (
    <Box px={3} pb={3}>
      <LoadingIndicator loading={loading} />
      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
      <Box mt={2}>
        <Form<LaundryUserTopupViewModel>
          initialValues={defaultViewModel}
          onSubmit={topupSubmit}
          render={({ handleSubmit, submitting, pristine }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="amount"
                      label={translate('topupAmount')}
                      fullWidth
                      autoFocus
                      validate={composeValidators(
                        required(),
                        validAmount('validation.valid.amount'),
                        validAmountMax(undefined, maxAmount),
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DetailsTextField value={defaultViewModel.currency} label={translate('currency')} />
                  </Grid>
                  <Grid item xs={12}>
                    <AsyncAutoCompleteValidate
                      disabled={submitting}
                      name="laundryGroupRef"
                      validate={required()}
                      label={translate('laundryGroup')}
                      delay={300}
                      labelFieldName="name"
                      loadOptionsFunction={laundryGroupSearch}
                    />
                  </Grid>
                </Grid>
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    color="primary"
                    size="large"
                    className={sharedClasses.ButtonMargin}
                    onClick={() => setOpenModal(false)}
                  >
                    {translate('button.cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    {translate('button.topup')}
                  </Button>
                </Box>
              </form>
            )
          }}
        />
      </Box>
    </Box>
  )
}
