/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CalendarEntry,
  CalendarEntryType,
  PageableCalendarEntries,
} from '../models';
import {
    CalendarEntryFromJSON,
    CalendarEntryToJSON,
    CalendarEntryTypeFromJSON,
    CalendarEntryTypeToJSON,
    PageableCalendarEntriesFromJSON,
    PageableCalendarEntriesToJSON,
} from '../models';

export interface CalendarEntriesCalendarEntryIdDeleteRequest {
    calendarEntryId: string;
}

export interface CalendarEntriesCalendarEntryIdGetRequest {
    calendarEntryId: string;
}

export interface CalendarEntriesGetRequest {
    laundryGroupId: string;
    from: Date;
    to: Date;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    id?: string;
    machineId?: string;
    laundryId?: string;
    laundryUserId?: string;
    calendarEntryType?: CalendarEntryType;
}

export interface CalendarEntriesPostRequest {
    calendarEntry?: CalendarEntry;
}

export interface CalendarViewGetRequest {
    laundryGroupId: string;
    from: Date;
    to: Date;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    id?: string;
    machineId?: string;
    laundryId?: string;
    laundryUserId?: string;
    calendarEntryType?: CalendarEntryType;
}

/**
 * 
 */
export class CalendarEntriesApi extends runtime.BaseAPI {

    /**
     * Delete a CalendarEntry
     */
    async calendarEntriesCalendarEntryIdDeleteRaw(requestParameters: CalendarEntriesCalendarEntryIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.calendarEntryId === null || requestParameters.calendarEntryId === undefined) {
            throw new runtime.RequiredError('calendarEntryId','Required parameter requestParameters.calendarEntryId was null or undefined when calling calendarEntriesCalendarEntryIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar/entries/{calendarEntryId}`.replace(`{${"calendarEntryId"}}`, encodeURIComponent(String(requestParameters.calendarEntryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a CalendarEntry
     */
    async calendarEntriesCalendarEntryIdDelete(calendarEntryId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.calendarEntriesCalendarEntryIdDeleteRaw({ calendarEntryId: calendarEntryId }, initOverrides);
    }

    /**
     * Get a CalendarEntry
     */
    async calendarEntriesCalendarEntryIdGetRaw(requestParameters: CalendarEntriesCalendarEntryIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarEntry>> {
        if (requestParameters.calendarEntryId === null || requestParameters.calendarEntryId === undefined) {
            throw new runtime.RequiredError('calendarEntryId','Required parameter requestParameters.calendarEntryId was null or undefined when calling calendarEntriesCalendarEntryIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar/entries/{calendarEntryId}`.replace(`{${"calendarEntryId"}}`, encodeURIComponent(String(requestParameters.calendarEntryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarEntryFromJSON(jsonValue));
    }

    /**
     * Get a CalendarEntry
     */
    async calendarEntriesCalendarEntryIdGet(calendarEntryId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarEntry> {
        const response = await this.calendarEntriesCalendarEntryIdGetRaw({ calendarEntryId: calendarEntryId }, initOverrides);
        return await response.value();
    }

    /**
     * Get CalendarEntries
     */
    async calendarEntriesGetRaw(requestParameters: CalendarEntriesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableCalendarEntries>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling calendarEntriesGet.');
        }

        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling calendarEntriesGet.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling calendarEntriesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryUserId !== undefined) {
            queryParameters['laundryUserId'] = requestParameters.laundryUserId;
        }

        if (requestParameters.calendarEntryType !== undefined) {
            queryParameters['calendarEntryType'] = requestParameters.calendarEntryType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar/entries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableCalendarEntriesFromJSON(jsonValue));
    }

    /**
     * Get CalendarEntries
     */
    async calendarEntriesGet(laundryGroupId: string, from: Date, to: Date, size?: number, page?: number, sort?: string, search?: string, id?: string, machineId?: string, laundryId?: string, laundryUserId?: string, calendarEntryType?: CalendarEntryType, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableCalendarEntries> {
        const response = await this.calendarEntriesGetRaw({ laundryGroupId: laundryGroupId, from: from, to: to, size: size, page: page, sort: sort, search: search, id: id, machineId: machineId, laundryId: laundryId, laundryUserId: laundryUserId, calendarEntryType: calendarEntryType }, initOverrides);
        return await response.value();
    }

    /**
     * Create a CalendarEntry
     */
    async calendarEntriesPostRaw(requestParameters: CalendarEntriesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarEntry>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/calendar/entries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalendarEntryToJSON(requestParameters.calendarEntry),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarEntryFromJSON(jsonValue));
    }

    /**
     * Create a CalendarEntry
     */
    async calendarEntriesPost(calendarEntry?: CalendarEntry, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarEntry> {
        const response = await this.calendarEntriesPostRaw({ calendarEntry: calendarEntry }, initOverrides);
        return await response.value();
    }

    /**
     *  For CalendarEntry:  - has id=null when generated from recurring  - has id = uuid, then it is a single entry  - has recurringReservationId = uuid, then is connected to a recurring else null
     * Get CalendarEntries including Recurring for Calendar View
     */
    async calendarViewGetRaw(requestParameters: CalendarViewGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableCalendarEntries>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling calendarViewGet.');
        }

        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling calendarViewGet.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling calendarViewGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.laundryId !== undefined) {
            queryParameters['laundryId'] = requestParameters.laundryId;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.laundryUserId !== undefined) {
            queryParameters['laundryUserId'] = requestParameters.laundryUserId;
        }

        if (requestParameters.calendarEntryType !== undefined) {
            queryParameters['calendarEntryType'] = requestParameters.calendarEntryType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar/view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableCalendarEntriesFromJSON(jsonValue));
    }

    /**
     *  For CalendarEntry:  - has id=null when generated from recurring  - has id = uuid, then it is a single entry  - has recurringReservationId = uuid, then is connected to a recurring else null
     * Get CalendarEntries including Recurring for Calendar View
     */
    async calendarViewGet(laundryGroupId: string, from: Date, to: Date, size?: number, page?: number, sort?: string, search?: string, id?: string, machineId?: string, laundryId?: string, laundryUserId?: string, calendarEntryType?: CalendarEntryType, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableCalendarEntries> {
        const response = await this.calendarViewGetRaw({ laundryGroupId: laundryGroupId, from: from, to: to, size: size, page: page, sort: sort, search: search, id: id, machineId: machineId, laundryId: laundryId, laundryUserId: laundryUserId, calendarEntryType: calendarEntryType }, initOverrides);
        return await response.value();
    }

}
