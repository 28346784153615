import { Box, Divider, MenuItem, styled } from '@mui/material'

export const StyledMenuItem = styled(MenuItem)<{
  active: number
}>(({ active, theme }) => ({
  minHeight: theme.spacing(8),
  minWidth: theme.spacing(8),
  borderRadius: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiButtonBase-root': {
    minHeight: theme.spacing(8),
  },
  backgroundColor: active ? 'white' : theme.palette.primary.main,
  '&:hover': {
    backgroundColor: active ? 'white' : theme.palette.action.hover,
  },
  '& .MuiSvgIcon-root': {
    color: active ? theme.palette.primary.main : 'white',
  },
}))

export const PopperTitle = styled(Box)(({ theme }) => ({
  zIndex: 500,
  width: '100%',
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'start',
  flexGrow: 1,
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  height: 2,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
}))
