/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Step {
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    SOAKING = 'SOAKING',
    PREWASH_FIRST = 'PREWASH_FIRST',
    PREWASH_SECOND = 'PREWASH_SECOND',
    PREWASH = 'PREWASH',
    WASHING = 'WASHING',
    PRE_RINSING = 'PRE_RINSING',
    RINSING = 'RINSING',
    EXTRA_RINSING = 'EXTRA_RINSING',
    FINISHING = 'FINISHING',
    RINSING_STOP = 'RINSING_STOP',
    DRAINING = 'DRAINING',
    SPINNING = 'SPINNING',
    DEWRINKLING = 'DEWRINKLING',
    LOOSENING_UP = 'LOOSENING_UP',
    FORWARDING = 'FORWARDING',
    CANCELLING = 'CANCELLING',
    FOAM_CONTROL = 'FOAM_CONTROL',
    HEATER_CHECK = 'HEATER_CHECK',
    DRYING = 'DRYING',
    ADDITIONAL_DRYING = 'ADDITIONAL_DRYING',
    EXTRA_DRYING = 'EXTRA_DRYING',
    TIMED_DRYING = 'TIMED_DRYING',
    SELF_CLEANING = 'SELF_CLEANING',
    COOL_DOWN = 'COOL_DOWN',
    CRINKLE_CARE = 'CRINKLE_CARE',
    END = 'END'
}


export function StepFromJSON(json: any): Step {
    return StepFromJSONTyped(json, false);
}

export function StepFromJSONTyped(json: any, ignoreDiscriminator: boolean): Step {
    return json as Step;
}

export function StepToJSON(value?: Step | null): any {
    return value as any;
}

