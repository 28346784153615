import React, { ReactElement, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganizationMandatory } from 'src/organization/ActiveOrganizationProvider'
import { NavigateState } from 'src/routing/Routing'
import {
  Configuration,
  InitOverrideFunction,
  MachineType,
  PageablePrograms,
  ProgramsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getRemoveHeaderInit } from 'src/service/backend/http/HttpUtils'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { FileFormat } from 'src/ui-shared/constants/Constants'
import { ProgramImport } from 'src/ui-shared/program/ProgramImport'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

export const ProgramImportPage = (): ReactElement => {
  const translate = useTranslate()
  const location = useLocation()
  const appId = useAppId()
  const navigate = useNavigate()

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack
  const activeOrganization = useActiveOrganizationMandatory()

  const { tabNameParam } = useRequiredParams(['tabNameParam'])
  const machineType = getEnumFromString(tabNameParam, MachineType)

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programsMachineApi = new ProgramsApi(httpConfiguration)

  const acceptedFileFormats: FileFormat[] = ['WPB']
  const acceptedFileSizeMB = 1
  const acceptMultipleFiles = true

  // handle events
  const handleOnCancel = () => {
    navigateBack()
  }

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/programs/${machineType}/`)
  }

  const handleSubmit = (files: File[]): Promise<PageablePrograms> => {
    // delete the content type header so that the browser's defined content-type
    // based on body to add boundary value for the multipart/form-data encoding
    const initOverrides: InitOverrideFunction = getRemoveHeaderInit('Content-Type')

    return programsMachineApi.programsImportPost(activeOrganization.id, machineType, files, initOverrides)
  }

  return (
    <ScreenLayout title={translate('programsImport')} onBack={navigateBack} actionsWidth={50}>
      <ProgramImport
        acceptedFileFormats={acceptedFileFormats}
        acceptedFileSizeMB={acceptedFileSizeMB}
        acceptMultipleFiles={acceptMultipleFiles}
        handleCancel={handleOnCancel}
        handleSubmit={handleSubmit}
        handleFinish={navigateBack}
        disclaimerTextFor={translate('programs')}
      />
    </ScreenLayout>
  )
}
