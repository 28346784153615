/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MachineCategory {
    WA = 'WA',
    WT = 'WT',
    WAT = 'WAT',
    RLWT = 'RLWT',
    DOS = 'DOS',
    IRON = 'IRON',
    OTHER_ASSET = 'OTHER_ASSET'
}


export function MachineCategoryFromJSON(json: any): MachineCategory {
    return MachineCategoryFromJSONTyped(json, false);
}

export function MachineCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineCategory {
    return json as MachineCategory;
}

export function MachineCategoryToJSON(value?: MachineCategory | null): any {
    return value as any;
}

