/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectionQualityType } from './ConnectionQualityType';
import {
    ConnectionQualityTypeFromJSON,
    ConnectionQualityTypeFromJSONTyped,
    ConnectionQualityTypeToJSON,
} from './ConnectionQualityType';
import type { ConnectivityFirmwareType } from './ConnectivityFirmwareType';
import {
    ConnectivityFirmwareTypeFromJSON,
    ConnectivityFirmwareTypeFromJSONTyped,
    ConnectivityFirmwareTypeToJSON,
} from './ConnectivityFirmwareType';
import type { ProgramInstallationStatus } from './ProgramInstallationStatus';
import {
    ProgramInstallationStatusFromJSON,
    ProgramInstallationStatusFromJSONTyped,
    ProgramInstallationStatusToJSON,
} from './ProgramInstallationStatus';
import type { UtilizationMachineStateType } from './UtilizationMachineStateType';
import {
    UtilizationMachineStateTypeFromJSON,
    UtilizationMachineStateTypeFromJSONTyped,
    UtilizationMachineStateTypeToJSON,
} from './UtilizationMachineStateType';

/**
 * 
 * @export
 * @interface MachineInlineStatus
 */
export interface MachineInlineStatus {
    /**
     * 
     * @type {boolean}
     * @memberof MachineInlineStatus
     */
    online: boolean;
    /**
     * 
     * @type {UtilizationMachineStateType}
     * @memberof MachineInlineStatus
     */
    utilizationMachineState?: UtilizationMachineStateType;
    /**
     * 
     * @type {Date}
     * @memberof MachineInlineStatus
     */
    lastOnline: Date;
    /**
     * 
     * @type {Date}
     * @memberof MachineInlineStatus
     */
    lastOffline: Date;
    /**
     * 
     * @type {ConnectionQualityType}
     * @memberof MachineInlineStatus
     */
    connectionQuality: ConnectionQualityType;
    /**
     * 
     * @type {string}
     * @memberof MachineInlineStatus
     */
    connectivityFirmwareVersion?: string;
    /**
     * 
     * @type {ConnectivityFirmwareType}
     * @memberof MachineInlineStatus
     */
    connectivityFirmwareType?: ConnectivityFirmwareType;
    /**
     * 
     * @type {string}
     * @memberof MachineInlineStatus
     */
    machineFirmwareVersion?: string | null;
    /**
     * 
     * @type {ProgramInstallationStatus}
     * @memberof MachineInlineStatus
     */
    programInstallationStatus?: ProgramInstallationStatus;
}

/**
 * Check if a given object implements the MachineInlineStatus interface.
 */
export function instanceOfMachineInlineStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "online" in value;
    isInstance = isInstance && "lastOnline" in value;
    isInstance = isInstance && "lastOffline" in value;
    isInstance = isInstance && "connectionQuality" in value;

    return isInstance;
}

export function MachineInlineStatusFromJSON(json: any): MachineInlineStatus {
    return MachineInlineStatusFromJSONTyped(json, false);
}

export function MachineInlineStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineInlineStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'online': json['online'],
        'utilizationMachineState': !exists(json, 'utilizationMachineState') ? undefined : UtilizationMachineStateTypeFromJSON(json['utilizationMachineState']),
        'lastOnline': (new Date(json['lastOnline'])),
        'lastOffline': (new Date(json['lastOffline'])),
        'connectionQuality': ConnectionQualityTypeFromJSON(json['connectionQuality']),
        'connectivityFirmwareVersion': !exists(json, 'connectivityFirmwareVersion') ? undefined : json['connectivityFirmwareVersion'],
        'connectivityFirmwareType': !exists(json, 'connectivityFirmwareType') ? undefined : ConnectivityFirmwareTypeFromJSON(json['connectivityFirmwareType']),
        'machineFirmwareVersion': !exists(json, 'machineFirmwareVersion') ? undefined : json['machineFirmwareVersion'],
        'programInstallationStatus': !exists(json, 'programInstallationStatus') ? undefined : ProgramInstallationStatusFromJSON(json['programInstallationStatus']),
    };
}

export function MachineInlineStatusToJSON(value?: MachineInlineStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'online': value.online,
        'utilizationMachineState': UtilizationMachineStateTypeToJSON(value.utilizationMachineState),
        'lastOnline': (value.lastOnline.toISOString()),
        'lastOffline': (value.lastOffline.toISOString()),
        'connectionQuality': ConnectionQualityTypeToJSON(value.connectionQuality),
        'connectivityFirmwareVersion': value.connectivityFirmwareVersion,
        'connectivityFirmwareType': ConnectivityFirmwareTypeToJSON(value.connectivityFirmwareType),
        'machineFirmwareVersion': value.machineFirmwareVersion,
        'programInstallationStatus': ProgramInstallationStatusToJSON(value.programInstallationStatus),
    };
}

