import 'date-fns/format'
import {
  Currency,
  LaundryGroupPriceList,
  LaundryGroupPriceListElementIntegrated,
  LaundryGroupPriceListExternal,
  LaundryGroupPriceListIntegrated,
  MachineType,
  PriceListType,
} from 'src/service/backend/api/models'
import { DateUtils } from 'src/service/utils/DateUtils'
import { formatAmount } from 'src/service/utils/NumberFormatUtils'
import { CurrencyObject, getCurrencyObject } from 'src/service/view-model/base/payment/Currencies'

export interface LaundryGroupPriceListExternalViewModel {
  name: string
  machineType: string
  currency: CurrencyObject | string
  regularAmount: string
  happyHourAmount: string
  amountPerUnit: string
  maxProgramTime: string
  regularPriceListId: string
  happyHourPriceListId: string
}

export const mapLaungryGroupPriceListExternalToLaundryGroupPriceListExternalViewModel = (
  laundryGroupPriceListExternal: LaundryGroupPriceListExternal,
): LaundryGroupPriceListExternalViewModel => {
  return {
    name: laundryGroupPriceListExternal.name || '',
    machineType: laundryGroupPriceListExternal.machineType,
    currency: getCurrencyObject(laundryGroupPriceListExternal.currency) || '',
    regularAmount: formatAmount(laundryGroupPriceListExternal.regularAmount) || '',
    happyHourAmount: formatAmount(laundryGroupPriceListExternal.happyHourAmount) || '',
    amountPerUnit: laundryGroupPriceListExternal.amountPerUnit.toString() || '',
    maxProgramTime: DateUtils.convertSecondsToMinutes(laundryGroupPriceListExternal.maxProgramTime).toString() || '',
    regularPriceListId: laundryGroupPriceListExternal.regularPriceListId || '',
    happyHourPriceListId: laundryGroupPriceListExternal.happyHourPriceListId || '',
  }
}

export const createLaundryGroupPriceListExternalViewModel = (
  laundryGroupPriceListExternal?: LaundryGroupPriceListExternal,
): LaundryGroupPriceListExternalViewModel => {
  if (laundryGroupPriceListExternal) {
    return mapLaungryGroupPriceListExternalToLaundryGroupPriceListExternalViewModel(laundryGroupPriceListExternal)
  }
  return {
    name: '',
    machineType: '',
    currency: '',
    regularAmount: '',
    happyHourAmount: '',
    amountPerUnit: '',
    maxProgramTime: '',
    regularPriceListId: '',
    happyHourPriceListId: '',
  }
}

export const mapLaundryGroupPriceListExternalViewModelToLaundryGroupPriceList = (
  laundryGroupPriceListExternalViewModel: LaundryGroupPriceListExternalViewModel,
): LaundryGroupPriceList => {
  const currencyObject = laundryGroupPriceListExternalViewModel.currency as CurrencyObject
  return {
    priceListType: PriceListType.EXTERNAL,
    integrated: null,
    external: {
      name: laundryGroupPriceListExternalViewModel.name,
      machineType: laundryGroupPriceListExternalViewModel.machineType as MachineType,
      currency: currencyObject.code as Currency,
      regularAmount: parseFloat(laundryGroupPriceListExternalViewModel.regularAmount),
      happyHourAmount: parseFloat(laundryGroupPriceListExternalViewModel.happyHourAmount),
      amountPerUnit: parseFloat(laundryGroupPriceListExternalViewModel.amountPerUnit),
      maxProgramTime: DateUtils.convertMinutesToSeconds(
        parseInt(laundryGroupPriceListExternalViewModel.maxProgramTime),
      ),
      regularPriceListId: laundryGroupPriceListExternalViewModel.regularPriceListId,
      happyHourPriceListId: laundryGroupPriceListExternalViewModel.happyHourPriceListId,
    },
  }
}
export interface LaundryGroupPriceListInternalViewModel {
  name: string
  machineType: string
  currency: CurrencyObject | string
  standardPriceChange?: string
  happyHourPriceChange?: string
  prices: Array<LaundryGroupPriceListInternalElementViewModel>
  regularPriceListId: string
  happyHourPriceListId: string
}

export interface LaundryGroupPriceListInternalElementViewModel {
  index: number
  name: string
  regularAmount: string
  happyHourAmount: string
}

export const mapLaundryGroupPriceListInternalToLaundryGroupPriceListInternalViewModel = (
  laundryGroupPriceListInternal: LaundryGroupPriceListIntegrated,
): LaundryGroupPriceListInternalViewModel => {
  return {
    name: laundryGroupPriceListInternal.name || '',
    machineType: laundryGroupPriceListInternal.machineType,
    currency: getCurrencyObject(laundryGroupPriceListInternal.currency) || '',
    prices: mapIntegratedElementToInternalViewModel(laundryGroupPriceListInternal.prices),
    regularPriceListId: laundryGroupPriceListInternal.regularPriceListId || '',
    happyHourPriceListId: laundryGroupPriceListInternal.happyHourPriceListId || '',
  }
}

export const mapIntegratedElementToInternalViewModel = (
  elements: LaundryGroupPriceListElementIntegrated[] | undefined,
): LaundryGroupPriceListInternalElementViewModel[] => {
  const mappedElements = elements?.map((item) => {
    return {
      index: item.index,
      name: item.name,
      regularAmount: formatAmount(item.regularAmount),
      happyHourAmount: formatAmount(item.happyHourAmount),
    }
  })

  return mappedElements as LaundryGroupPriceListInternalElementViewModel[]
}

export const createLaundryGroupPriceListInternalViewModel = (
  laundryGroupPriceListInternal?: LaundryGroupPriceListIntegrated,
): LaundryGroupPriceListInternalViewModel => {
  if (laundryGroupPriceListInternal) {
    return mapLaundryGroupPriceListInternalToLaundryGroupPriceListInternalViewModel(laundryGroupPriceListInternal)
  }
  return {
    name: '',
    currency: '',
    machineType: '',
    prices: [],
    regularPriceListId: '',
    happyHourPriceListId: '',
  }
}

export const mapLaundryGroupPriceListInternalViewModelToLaundryGroupPriceList = (
  laundryGroupPriceListInternalViewModel: LaundryGroupPriceListInternalViewModel,
): LaundryGroupPriceList => {
  const currencyObject = laundryGroupPriceListInternalViewModel.currency as CurrencyObject
  return {
    priceListType: PriceListType.INTEGRATED,
    external: null,
    integrated: {
      name: laundryGroupPriceListInternalViewModel.name,
      machineType: laundryGroupPriceListInternalViewModel.machineType as MachineType,
      currency: currencyObject.code as Currency,
      prices: mapInternalViewModelToIntegratedElement(laundryGroupPriceListInternalViewModel.prices),
      regularPriceListId: laundryGroupPriceListInternalViewModel.regularPriceListId,
      happyHourPriceListId: laundryGroupPriceListInternalViewModel.happyHourPriceListId,
    },
  }
}

export const mapInternalViewModelToIntegratedElement = (
  elements: LaundryGroupPriceListInternalElementViewModel[] | undefined,
): LaundryGroupPriceListElementIntegrated[] => {
  const mappedElements = elements?.map((item) => {
    return {
      index: item.index,
      name: item.name,
      regularAmount: parseFloat(item.regularAmount),
      happyHourAmount: parseFloat(item.happyHourAmount),
    }
  })

  return mappedElements as LaundryGroupPriceListElementIntegrated[]
}
