import React, { FC, ReactElement, useState } from 'react'
import { Form } from 'react-final-form'
import { Box, Button, Grid } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { DateUtils } from 'src/service/utils/DateUtils'
import { removeTimeFromDate } from 'src/service/utils/MomentUtils'
import { getLocaleForDatePicker } from 'src/service/view-model/base/localization/Locales'
import {
  LaundryGroupBlockedDaysViewModel,
  createLaundryGroupBlockedDaysViewModel,
} from 'src/service/view-model/laundry-group/LaundryGroupBlockedDaysViewModel'
import { DatePickerDefault } from 'src/ui-shared/base/form/control/DatePickerDefault'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { useUserLocale, useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  createBlockedDay: (blockedDaysViewModel: LaundryGroupBlockedDaysViewModel) => void
  closeModal: (successMessage?: string) => void
}

export const LaundryGroupCreateBlockedDay: FC<Props> = ({ createBlockedDay, closeModal }): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()
  const locale = useUserLocale()
  const regionLocale = useUserRegionLocale()

  const [dateToBlock, setDateToBlock] = useState<Date>(removeTimeFromDate(new Date()))

  const submitForm = (values: LaundryGroupBlockedDaysViewModel) => {
    const viewModel: LaundryGroupBlockedDaysViewModel = {
      ...values,
      dateToBlock: dateToBlock,
    }
    closeModal()
    return createBlockedDay(viewModel)
  }

  const handleDateChange = (date: Date | null) => {
    if (date && DateUtils.isValidDate(date)) {
      const newDateToBlockWithoutTime = removeTimeFromDate(date!)
      setDateToBlock(newDateToBlockWithoutTime)
    }
  }

  return (
    <Box p={3}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocaleForDatePicker(locale, regionLocale)}>
        <Form<LaundryGroupBlockedDaysViewModel>
          initialValues={createLaundryGroupBlockedDaysViewModel()}
          onSubmit={submitForm}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DatePickerDefault
                        locale={regionLocale}
                        label={translate('date')}
                        value={dateToBlock}
                        onChange={handleDateChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField name="reason" label={translate('reason')} />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    color="primary"
                    size="large"
                    className={sharedClasses.ButtonMargin}
                    onClick={() => closeModal()}
                  >
                    {translate('button.cancel')}
                  </Button>
                  <Button variant="contained" color="primary" size="large" type="submit" disabled={submitting}>
                    {translate('apply')}
                  </Button>
                </Box>
              </form>
            )
          }}
        />
      </LocalizationProvider>
    </Box>
  )
}
