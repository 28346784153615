import React, { FC, ReactElement } from 'react'
import { Form } from 'react-final-form'
import { Box, Button, Grid } from '@mui/material'
import Alert from '@mui/material/Alert'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LaundryGroupReference } from 'src/service/backend/api'
import { AutoCompleteField } from 'src/ui-shared/base/form/control/AutoCompleteField'
import { Option } from 'src/ui-shared/base/form/control/Index'
import { required } from 'src/ui-shared/base/form/validation/Validators'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

interface Props {
  setOpenModal: (value: boolean) => void
  laundryGroups: LaundryGroupReference[]
  doExport: (assignedLaundryGroupId: string) => void
}

interface LaundryUserExportViewModel {
  selectedLaundryGroup?: string
}

export const LaundryUserExportDialog: FC<Props> = ({ setOpenModal, laundryGroups, doExport }): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  // derived state
  const laundryGroupsOptions: Option[] = laundryGroups.map((laundryGroup) => {
    const option: Option = { label: laundryGroup.name || '', value: laundryGroup.id }
    return option
  })

  const exportSubmit = (values: LaundryUserExportViewModel) => {
    const selectedLaundryGroup = values.selectedLaundryGroup
    if (selectedLaundryGroup) {
      // close dialog
      setOpenModal(false)

      // start export
      doExport(selectedLaundryGroup)
    }
  }

  return (
    <Box px={3} pb={3}>
      <Box mt={2}>
        <Alert severity={'info'}>{translate('laundryUserExportInfo')}</Alert>
      </Box>

      <Box mt={2}>
        <Form<LaundryUserExportViewModel>
          onSubmit={exportSubmit}
          render={({ handleSubmit, submitting, pristine }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AutoCompleteField
                      label={translate('laundryGroup')}
                      name="selectedLaundryGroup"
                      options={laundryGroupsOptions}
                      preselectOption={true}
                      validate={required()}
                    />
                  </Grid>
                </Grid>
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    color="primary"
                    size="large"
                    className={sharedClasses.ButtonMargin}
                    onClick={() => setOpenModal(false)}
                  >
                    {translate('button.cancel')}
                  </Button>

                  <Box ml={2}>
                    <Button
                      startIcon={<FileDownloadIcon />}
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={pristine || submitting}
                    >
                      {translate('exportCSV')}
                    </Button>
                  </Box>
                </Box>
              </form>
            )
          }}
        />
      </Box>
    </Box>
  )
}
