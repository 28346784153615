export class HttpResponseError extends Error {
  statusCode: number
  hasErrorMessageFromBackend?: boolean

  constructor(message: string, code: number, hasErrorMessageFromBackend?: boolean) {
    super(message)
    this.statusCode = code
    this.hasErrorMessageFromBackend = hasErrorMessageFromBackend
  }
}
