import React, { FC, ReactElement, useState } from 'react'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { AccountBalance } from '@mui/icons-material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LaundryUser, Permission } from 'src/service/backend/api'
import { getLocaleName } from 'src/service/view-model/base/localization/Locales'
import { getLaundryUserType } from 'src/service/view-model/laundry-user/LaundryUserUtils'
import { ButtonWithTooltip } from 'src/ui-shared/base/button/Buttons'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { AddressSection } from 'src/ui-shared/form/address-section/AddressSection'
import { DeleteIcon } from 'src/ui-shared/icons/Icons'
import { LaundryUserGenerateBankSlipDialog } from 'src/ui/page/wm/laundry-user/details/LaundryUserGenerateBankSlipDialog'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

interface Props {
  laundryUser: LaundryUser
  removeModal: boolean
  setRemoveModal: (value: boolean) => void
  onRemoveHandler: () => void
  navigateToEditPage: () => void
  resetPasswordModal: boolean
  setResetPasswordModal: (value: boolean) => void
  onResetPasswordHandler: () => void
}

export const LaundryUserOverviewTab: FC<Props> = ({
  laundryUser,
  removeModal,
  setRemoveModal,
  navigateToEditPage,
  onRemoveHandler,
  resetPasswordModal,
  setResetPasswordModal,
  onResetPasswordHandler,
}): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()

  const user = useUser()

  const [generateBankSlipModal, setGenerateBankSlipModal] = useState<boolean>(false)

  const canEdit = hasPermission(user, Permission.LAUNDRY_USER_WRITE)
  useHotKeysForm()

  // JSX
  const editButton = (
    <Button id="editButton" variant="contained" color="primary" size="large" onClick={navigateToEditPage}>
      {translate('button.edit')}
    </Button>
  )

  const resetPasswordButton = (
    <Button id="resetPasswordButton" color="primary" size="large" onClick={() => setResetPasswordModal(true)}>
      {translate('button.reset-password')}
    </Button>
  )

  const editAction = canEdit ? editButton : <></>

  const deleteButton = (
    <Button
      startIcon={<DeleteIcon />}
      variant="outlined"
      color="primary"
      size="large"
      onClick={() => setRemoveModal(true)}
    >
      {translate('button.delete')}
    </Button>
  )

  const deleteAction = canEdit && laundryUser.editable ? deleteButton : <></>

  const generateBankSlipButton = (
    <ButtonWithTooltip
      startIcon={<AccountBalance />}
      variant="text"
      color="primary"
      size="large"
      onClick={() => setGenerateBankSlipModal(true)}
    >
      {translate('button.generateBankSlip')}
    </ButtonWithTooltip>
  )

  const canGenerateBankSlip = hasPermission(user, Permission.LAUNDRY_USER_GENERATE_BANK_SLIP)

  const generateBankSlipButtonAction = canGenerateBankSlip ? generateBankSlipButton : null

  return (
    <>
      <Box pt={2}>
        <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField value={laundryUser.firstName} label={translate('user.firstName')} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField value={laundryUser.lastName} label={translate('user.lastName')} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField value={laundryUser.email} label={translate('user.email')} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField
              value={laundryUser.type ? getLaundryUserType(laundryUser.type) : ''}
              label={translate('laundryUser.type')}
            />
          </Grid>

          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField name={'phoneNumber'} value={laundryUser.phoneNumber} label={translate('phonenumber')} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField name={'locale'} value={getLocaleName(laundryUser.locale)} label={translate('language')} />
          </Grid>
        </Grid>

        <Divider className={sharedClasses.Divider} />

        <AddressSection address={laundryUser.address} hideCoordinates />

        <Divider className={sharedClasses.Divider} />

        <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField value={laundryUser.esrNumber} label={translate('esr')} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField value={laundryUser.creditorReference} label={translate('bankingReference')} />
            {generateBankSlipButtonAction}
          </Grid>

          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField value={laundryUser.ibanNumber} label={translate('iban')} />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS}>
            <DetailsTextField value={laundryUser.ibanHolderName} label={translate('ibanHolderName')} />
          </Grid>

          <Grid item lg={12}>
            <Divider className={sharedClasses.Divider} />
          </Grid>

          <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
            <DetailsTextField
              value={laundryUser.assignedRFIDCards.map((item) => item.cardNumber).join(', ')}
              label={translate('rfids')}
            />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
            <DetailsTextField
              multiline
              value={laundryUser.assignedLaundryGroups.map((item) => item.name).join(', ')}
              label={translate('laundryGroups')}
            />
          </Grid>
        </Grid>
        <Divider className={sharedClasses.Divider} />
        <Box pt={2} display="flex" justifyContent="space-between">
          <Box>{deleteAction}</Box>
          <Box gap="1rem" display="flex" justifyContent="flex-end">
            {resetPasswordButton}
            {editAction}
          </Box>
        </Box>
      </Box>
      <ConfirmationModalDialog
        titleKey="deleteLaundryUser"
        confirmationKey="button.delete"
        open={removeModal}
        onConfirm={onRemoveHandler}
        onCancel={() => setRemoveModal(false)}
      >
        {translate('deleteConfirm', laundryUser.firstName + ' ' + laundryUser.lastName)}
      </ConfirmationModalDialog>
      <ConfirmationModalDialog
        titleKey="resetPasswordLaundryUser"
        confirmationKey="button.reset-password"
        open={resetPasswordModal}
        onCancel={() => setResetPasswordModal(false)}
        onConfirm={onResetPasswordHandler}
      >
        {translate('resetPasswordConfirm', laundryUser.firstName + ' ' + laundryUser.lastName)}
      </ConfirmationModalDialog>

      {generateBankSlipButtonAction ? (
        <ModalDialog
          open={generateBankSlipModal}
          onClose={() => setGenerateBankSlipModal(false)}
          title={translate('button.generateBankSlip')}
          maxWidth="md"
        >
          <LaundryUserGenerateBankSlipDialog setOpenModal={setGenerateBankSlipModal} laundryUser={laundryUser} />
        </ModalDialog>
      ) : null}
    </>
  )
}
