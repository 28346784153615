/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserReactionType {
    FEEDBACK = 'FEEDBACK',
    PROBLEM = 'PROBLEM'
}


export function UserReactionTypeFromJSON(json: any): UserReactionType {
    return UserReactionTypeFromJSONTyped(json, false);
}

export function UserReactionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserReactionType {
    return json as UserReactionType;
}

export function UserReactionTypeToJSON(value?: UserReactionType | null): any {
    return value as any;
}

