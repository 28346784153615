import React, { PropsWithChildren, ReactElement } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import { ErrorOutline } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslate } from 'src/i18n/useMessageSource'

const StyledCloseIcon = styled(CloseIcon)({
  cursor: 'pointer',
  width: '20px',
})

interface ErrorModalDialogProps extends PropsWithChildren {
  open: boolean
  onClose?: () => void
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  fullWidth?: boolean
  withCloseIcon?: boolean
  title?: string
  minHeight?: number
}

export const ErrorModalDialog = ({
  children,
  maxWidth = 'md',
  open,
  title,
  onClose,
  fullWidth = true,
  withCloseIcon = false,
  minHeight,
}: ErrorModalDialogProps): ReactElement => {
  const translate = useTranslate()
  return (
    <Dialog
      scroll="paper"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      sx={
        minHeight
          ? {
              '& .MuiDialog-paper': {
                minHeight: 'calc(' + minHeight + '% - 64px)',
              },
            }
          : undefined
      }
    >
      <DialogTitle sx={{ backgroundColor: 'error.dark', color: 'whitesmoke' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="row" alignItems="center">
            <ErrorOutline />
            <Typography paddingLeft={1} color="whitesmoke" variant="h4">
              {title ?? translate('modalDialog.errorTitle')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            {withCloseIcon && <StyledCloseIcon onClick={onClose} />}
          </Box>
        </Stack>
      </DialogTitle>
      {children}
    </Dialog>
  )
}
