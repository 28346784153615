import React, { FC, ReactElement, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { UploadFileField } from 'src/ui-shared/base/form/control/UploadFileField'
import { FileFormat } from 'src/ui-shared/constants/Constants'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

interface Props {
  acceptedFileFormat: FileFormat[]
  acceptedFileSizeMB: number
  acceptMultipleFiles: boolean
  handleCancel: () => void
  handleUploadFiles: (files: File[]) => void
  disclaimerTextFor?: string
}

export const UploadFileView: FC<Props> = ({
  acceptedFileFormat,
  acceptedFileSizeMB,
  acceptMultipleFiles,
  handleCancel,
  handleUploadFiles,
  disclaimerTextFor,
}): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()

  // state
  const [files, setFiles] = useState<File[] | null>(null)

  // handle events
  const handleOnSubmit = (files: File[] | null) => {
    if (files) {
      handleUploadFiles(files)
    } else {
      throw new Error('Files must be uploaded')
    }
  }

  // JSX
  const displayDisclaimerMessage = (): string => {
    const fileFormatsConcat = acceptedFileFormat.join(', ').toUpperCase()
    const disclaimerMessage = translate('importFileDisclaimer', disclaimerTextFor, fileFormatsConcat)

    return disclaimerMessage
  }

  return (
    <>
      {disclaimerTextFor && (
        <Grid container spacing={2} mt={1} mb={2}>
          <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
            <Typography variant="h5" fontWeight={500}>
              {displayDisclaimerMessage()}
            </Typography>
          </Grid>
        </Grid>
      )}

      <UploadFileField
        acceptedFileFormat={acceptedFileFormat}
        acceptedFileSizeMB={acceptedFileSizeMB}
        acceptMultipleFiles={acceptMultipleFiles}
        setFiles={setFiles}
      />

      {acceptedFileFormat.includes('csv' as FileFormat) && (
        <RadioGroup aria-label="separator">
          <FormLabel component="legend">
            <Typography variant="subtitle2">{translate('csvSeparatorType')}</Typography>
          </FormLabel>

          <Stack direction="row" spacing={1} paddingTop={1}>
            <FormControlLabel
              value={';'}
              control={<Radio color="primary" />}
              label={translate('csvSeparatorSemicolon') + ' ; '}
              checked
            />
            <FormControlLabel
              value={','}
              control={<Radio color="primary" />}
              label={translate('csvSeparatorComma') + ' ,'}
              disabled
            />
          </Stack>
        </RadioGroup>
      )}

      <Divider className={sharedClasses.Divider} />

      <Box pt={2} display="flex" justifyContent="flex-end">
        <Button
          id="cancelButton"
          variant="text"
          color="primary"
          size="large"
          onClick={handleCancel}
          className={sharedClasses.ButtonMargin}
        >
          {translate('button.cancel')}
        </Button>

        <Button
          id="submitButton"
          variant="contained"
          size="large"
          color="primary"
          type="submit"
          disabled={files === null || files.length === 0}
          onClick={() => handleOnSubmit(files)}
        >
          {translate('button.next')}
        </Button>
      </Box>
    </>
  )
}
