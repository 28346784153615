/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BackendUserProfile } from './BackendUserProfile';
import {
    BackendUserProfileFromJSON,
    BackendUserProfileFromJSONTyped,
    BackendUserProfileToJSON,
} from './BackendUserProfile';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';
import type { Role } from './Role';
import {
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface Member
 */
export interface Member {
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    id: string;
    /**
     * 
     * @type {BackendUserProfile}
     * @memberof Member
     */
    backendUser: BackendUserProfile;
    /**
     * 
     * @type {OrganizationReference}
     * @memberof Member
     */
    organization: OrganizationReference;
    /**
     * 
     * @type {Date}
     * @memberof Member
     */
    memberSince: Date;
    /**
     * 
     * @type {Array<Role>}
     * @memberof Member
     */
    memberRoles: Array<Role>;
}

/**
 * Check if a given object implements the Member interface.
 */
export function instanceOfMember(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "backendUser" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "memberSince" in value;
    isInstance = isInstance && "memberRoles" in value;

    return isInstance;
}

export function MemberFromJSON(json: any): Member {
    return MemberFromJSONTyped(json, false);
}

export function MemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): Member {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'backendUser': BackendUserProfileFromJSON(json['backendUser']),
        'organization': OrganizationReferenceFromJSON(json['organization']),
        'memberSince': (new Date(json['memberSince'])),
        'memberRoles': ((json['memberRoles'] as Array<any>).map(RoleFromJSON)),
    };
}

export function MemberToJSON(value?: Member | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'backendUser': BackendUserProfileToJSON(value.backendUser),
        'organization': OrganizationReferenceToJSON(value.organization),
        'memberSince': (value.memberSince.toISOString()),
        'memberRoles': ((value.memberRoles as Array<any>).map(RoleToJSON)),
    };
}

