import React, { ReactElement, useState } from 'react'
import { Grid } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue'
import { useTranslate } from 'src/i18n/useMessageSource'
import { DateUtils } from 'src/service/utils/DateUtils'
import {
  getLastMonth,
  getWeekBefore,
  removeTimeFromDate,
  removeTimeFromDateEndOfDay,
} from 'src/service/utils/MomentUtils'
import { getLocaleForDatePicker } from 'src/service/view-model/base/localization/Locales'
import { DatePickerDefault } from 'src/ui-shared/base/form/control/DatePickerDefault'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useUserLocale, useUserRegionLocale } from 'src/user/UserContext'

interface Props<T> {
  firstDatePropName: keyof T
  secondDatePropName: keyof T
  tableSettings: T
  setTableSettings: (value: T) => void
  useLastWeek?: boolean
  minDate?: Date
  disableFuture?: boolean
}

const initializeDate = (tableSettings: any, propName: any, defaultDate: Date): Date => {
  let date
  const dateFromTableSettings = new Date(tableSettings[propName])
  if (DateUtils.isValidDate(dateFromTableSettings)) {
    date = dateFromTableSettings
  }
  return date ? date : defaultDate
}

export const TableDatePickerForm = <T,>({
  firstDatePropName,
  secondDatePropName,
  tableSettings,
  setTableSettings,
  useLastWeek,
  minDate,
  disableFuture = true,
}: Props<T>): ReactElement => {
  const { classes: textFieldClasses } = useTextFieldStyles()

  const locale = useUserLocale()
  const regionLocale = useUserRegionLocale()
  const translate = useTranslate()

  const now = new Date()

  // state
  const [selectedFromDate, setSelectedFromDate] = useState<Date>(() => {
    const defaultFromDate = useLastWeek ? getWeekBefore(now) : getLastMonth(now)
    return initializeDate(tableSettings, firstDatePropName, removeTimeFromDate(defaultFromDate))
  })
  const [selectedToDate, setSelectedToDate] = useState<Date>(() => {
    const defaultToDate = now
    return initializeDate(tableSettings, secondDatePropName, removeTimeFromDateEndOfDay(defaultToDate))
  })

  // handle events
  const handleFromDateChange = (date: any, context: PickerChangeHandlerContext<any>) => {
    if (!date || context.validationError) {
      return
    }

    if (DateUtils.isValidDate(date)) {
      const dateOnly = removeTimeFromDate(date)
      setSelectedFromDate(dateOnly)

      if (!isDateBeforeMinDate(date)) {
        setTableSettings({
          ...tableSettings,
          [firstDatePropName]: dateOnly.toISOString(),
        })
      }
    }
  }

  const handleToDateChange = (date: Date | null, context: PickerChangeHandlerContext<any>) => {
    if (!date || context.validationError) {
      return
    }

    if (DateUtils.isValidDate(date)) {
      const dateOnly = removeTimeFromDateEndOfDay(date)
      setSelectedToDate(dateOnly)

      setTableSettings({
        ...tableSettings,
        [secondDatePropName]: dateOnly.toISOString(),
      })
    }
  }

  // date validators
  const isDateBeforeMinDate = (date: Date): boolean => {
    if (minDate !== undefined) {
      return DateUtils.before(date, minDate)
    }

    return false
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocaleForDatePicker(locale, regionLocale)}>
      <Grid item {...ITEM_BREAKPOINTS} sm={6} md={6} lg={3}>
        <DatePickerDefault
          locale={regionLocale}
          label={translate('dateFrom')}
          value={selectedFromDate}
          onChange={handleFromDateChange}
          textFieldClassName={textFieldClasses.TextFieldSmall}
          error={DateUtils.after(selectedFromDate, selectedToDate) || isDateBeforeMinDate(selectedFromDate)}
          minDate={minDate}
          disableFuture={disableFuture}
        />
      </Grid>

      <Grid item {...ITEM_BREAKPOINTS} sm={6} md={6} lg={3}>
        <DatePickerDefault
          locale={regionLocale}
          label={translate('dateTo')}
          value={selectedToDate}
          onChange={handleToDateChange}
          textFieldClassName={textFieldClasses.TextFieldSmall}
          error={DateUtils.after(selectedFromDate, selectedToDate)}
          disableFuture={disableFuture}
        />
      </Grid>
    </LocalizationProvider>
  )
}
