import React, { PropsWithChildren, ReactElement, useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  LaundriesApi,
  LaundryGroupReference,
  LaundryGroupsApi,
  LaundryReference,
  MachineReference,
  MachinesApi,
  OrganizationReference,
  OrganizationsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { DataSettings } from 'src/service/view-model/base/DataSettings'
import { findItemAndSelect } from 'src/service/view-model/base/Reference.utils'
import { CONNECTED_MACHINE_TYPES, getMachineOptionLabel } from 'src/service/view-model/machine/Machines'
import { FormAccordion, FormAccordionDetails } from 'src/ui-shared/base/accordion/FormAccordion'
import { FormAccordionSummaryDataFilter } from 'src/ui-shared/base/accordion/FormAccordionSummaryDataFilter'
import { AsyncAutoComplete } from 'src/ui-shared/base/form/control/AsyncAutoComplete'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'

interface Props<T extends DataSettings> extends PropsWithChildren {
  chartSettings: T
  setChartSettings: (value: T) => void
  organization?: keyof T
  laundryGroup?: keyof T
  laundry?: keyof T
  machine?: keyof T
  preselectFirstOrganizationNoActive?: boolean
  sameLine?: boolean
  defaultExpanded?: boolean
  noTitle?: boolean
  additionalFilterKeys?: (keyof T)[]
}

export const ChartDataFilter = <T extends DataSettings>({
  chartSettings,
  setChartSettings,
  organization,
  laundryGroup,
  laundry,
  machine,
  children,
  sameLine,
  defaultExpanded,
  noTitle,
  additionalFilterKeys,
}: Props<T>): ReactElement => {
  const { classes: textFieldClasses } = useTextFieldStyles()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const organizationsApi = new OrganizationsApi(httpConfiguration)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)
  const laundriesApi = new LaundriesApi(httpConfiguration)
  const machineApi = new MachinesApi(httpConfiguration)

  const translate = useTranslate()

  // count the number of filters (used for layout resizing)
  const getFiltersCount = () => {
    let count = 0
    if (organization) {
      count++
    }
    if (laundryGroup) {
      count++
    }
    if (laundry) {
      count++
    }
    if (machine) {
      count++
    }
    return count
  }

  const filtersCount = getFiltersCount()

  //state
  const [organizations, setOrganizations] = useState<OrganizationReference[]>([])
  const [laundryGroups, setLaundryGroups] = useState<LaundryGroupReference[]>([])
  const [laundries, setLaundries] = useState<LaundryReference[]>([])

  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationReference | null>(null)
  const [selectedLaundryGroup, setSelectedLaundryGroup] = useState<LaundryGroupReference | null>(null)
  const [selectedLaundry, setSelectedLaundry] = useState<LaundryReference | null>(null)
  const [selectedMachine, setSelectedMachine] = useState<MachineReference | null>(null)

  // derived state
  const additionalFiltersActive = additionalFilterKeys?.some((k) => {
    return (chartSettings[k] as any) ? true : false
  })

  const activeFilter = Boolean(
    selectedOrganization || selectedLaundryGroup || selectedLaundry || selectedMachine || additionalFiltersActive,
  )

  const organizationFilterValue = organization ? chartSettings[organization] : undefined
  const laundryGroupFilterValue = laundryGroup ? chartSettings[laundryGroup] : undefined
  const laundryFilterValue = laundry ? chartSettings[laundry] : undefined
  const machineFilterValue = machine ? chartSettings[machine] : undefined

  // Initializing autocompletes
  useEffect(() => {
    if (organization) {
      organizationsApi
        .organizationsRefGet()
        .then((organizations) => {
          setOrganizations(organizations)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
        })
    }

    if (laundryGroup) {
      laundryGroupsApi
        .laundrygroupsRefGet()
        .then((laundryGroups) => {
          setLaundryGroups(laundryGroups)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
        })
    }

    if (laundry) {
      laundriesApi
        .laundriesRefGet()
        .then((laundries) => {
          setLaundries(laundries)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
        })
    }
  }, [])

  // load state from URL, organization
  useEffect(() => {
    if (organization) {
      const organizationId = organizationFilterValue as unknown as string | undefined
      findItemAndSelect(organizationId, setSelectedOrganization, selectedOrganization, organizations)
    }
  }, [organizationFilterValue, organizations])

  // load state from URL, laundryGroup
  useEffect(() => {
    if (laundryGroup) {
      const laundryGroupId = laundryGroupFilterValue as unknown as string | undefined
      findItemAndSelect(laundryGroupId, setSelectedLaundryGroup, selectedLaundryGroup, laundryGroups)
    }
  }, [laundryGroupFilterValue, laundryGroups])

  // load state from URL, laundry
  useEffect(() => {
    if (laundry) {
      const laundryId = laundryFilterValue as unknown as string | undefined
      findItemAndSelect(laundryId, setSelectedLaundry, selectedLaundry, laundries)
    }
  }, [laundryFilterValue, laundries])

  // load state from URL, machine
  useEffect(() => {
    if (machine) {
      const machineId = machineFilterValue as unknown as string | undefined

      if (!machineId) {
        if (selectedMachine !== null) {
          setSelectedMachine(null)
        }
      } else {
        machineApi.machinesMachineIdGet(machineId).then((foundMachine) => {
          if (selectedMachine?.id !== foundMachine.id) {
            setSelectedMachine(foundMachine)
          }
        })
      }
    }
  }, [machineFilterValue])

  const machineSearch = (searchText: string): Promise<MachineReference[]> => {
    return machineApi.machinesRefGet(undefined, searchText, undefined, undefined, undefined, CONNECTED_MACHINE_TYPES)
  }

  // events
  const handleOrganizationChange = (event: React.SyntheticEvent, organizationRef: OrganizationReference | null) => {
    if (organization) {
      setSelectedOrganization(organizationRef)
      setChartSettings({
        ...chartSettings,
        [organization]: organizationRef?.id,
      })
    }
  }

  const handleLaundryGroupChange = (event: React.SyntheticEvent, laundryGroupRef: LaundryGroupReference | null) => {
    if (laundryGroup) {
      setChartSettings({
        ...chartSettings,
        [laundryGroup]: laundryGroupRef?.id,
      })
    }
  }

  const handleLaundryChange = (event: React.SyntheticEvent, laundryRef: LaundryReference | null) => {
    if (laundry) {
      setChartSettings({
        ...chartSettings,
        [laundry]: laundryRef?.id,
      })
    }
  }

  const handleMachineChange = (machineRef: MachineReference | null) => {
    if (machine) {
      setChartSettings({
        ...chartSettings,
        [machine]: machineRef?.id || undefined,
      })
    }
  }

  return (
    <FormAccordion defaultExpanded={defaultExpanded || noTitle}>
      {noTitle ? null : <FormAccordionSummaryDataFilter activeFilter={activeFilter} />}
      <FormAccordionDetails>
        <Grid container spacing={2}>
          {organization && (
            <Grid item {...ITEM_BREAKPOINTS} md={filtersCount <= 2 ? true : 6} lg={true}>
              <Autocomplete
                openOnFocus={true}
                multiple={false}
                freeSolo={false}
                disableClearable={false}
                options={organizations}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.id === (organizationFilterValue as any)}
                onChange={handleOrganizationChange}
                value={selectedOrganization}
                noOptionsText={translate('autocompleteNoOptions')}
                loadingText={translate('autocompleteLoading')}
                renderInput={(params) => (
                  <TextFieldDefault
                    {...params}
                    label={translate('organization')}
                    className={textFieldClasses.TextFieldSmall}
                  />
                )}
              />
            </Grid>
          )}
          {laundryGroup && (
            <Grid item {...ITEM_BREAKPOINTS} md={filtersCount <= 2 ? true : 6} lg={true}>
              <Autocomplete
                openOnFocus={true}
                multiple={false}
                freeSolo={false}
                disableClearable={false}
                options={laundryGroups}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.id === (laundryGroupFilterValue as any)}
                onChange={handleLaundryGroupChange}
                value={selectedLaundryGroup}
                noOptionsText={translate('autocompleteNoOptions')}
                loadingText={translate('autocompleteLoading')}
                renderInput={(params) => (
                  <TextFieldDefault
                    {...params}
                    label={translate('laundryGroup')}
                    className={textFieldClasses.TextFieldSmall}
                  />
                )}
              />
            </Grid>
          )}
          {laundry && (
            <Grid item {...ITEM_BREAKPOINTS} md={filtersCount <= 2 ? true : 6} lg={true}>
              <Autocomplete
                openOnFocus={true}
                multiple={false}
                freeSolo={false}
                disableClearable={false}
                options={laundries}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.id === (laundryFilterValue as any)}
                onChange={handleLaundryChange}
                value={selectedLaundry}
                noOptionsText={translate('autocompleteNoOptions')}
                loadingText={translate('autocompleteLoading')}
                renderInput={(params) => (
                  <TextFieldDefault
                    {...params}
                    label={translate('laundry')}
                    className={textFieldClasses.TextFieldSmall}
                  />
                )}
              />
            </Grid>
          )}
          {machine && (
            <Grid item {...ITEM_BREAKPOINTS} md={filtersCount <= 2 ? true : 6} lg={true}>
              <AsyncAutoComplete
                label={translate('machine')}
                labelFieldName="name"
                getOptionLabel={getMachineOptionLabel}
                delay={300}
                disabled={false}
                name="machines"
                value={selectedMachine}
                textFieldClassName={textFieldClasses.TextFieldSmall}
                loadOptionsFunction={machineSearch}
                onChange={handleMachineChange}
              />
            </Grid>
          )}

          {sameLine ? children : null}
        </Grid>
        <Grid container spacing={2} mt={0.5}>
          {!sameLine ? children : null}
        </Grid>
      </FormAccordionDetails>
    </FormAccordion>
  )
}
