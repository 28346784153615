/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Step } from './Step';
import {
    StepFromJSON,
    StepFromJSONTyped,
    StepToJSON,
} from './Step';

/**
 * 
 * @export
 * @interface ModuleLiquidDetergentSettings
 */
export interface ModuleLiquidDetergentSettings {
    /**
     * 
     * @type {number}
     * @memberof ModuleLiquidDetergentSettings
     */
    stepNumber: number;
    /**
     * 
     * @type {Step}
     * @memberof ModuleLiquidDetergentSettings
     */
    displayStep: Step;
    /**
     * 
     * @type {number}
     * @memberof ModuleLiquidDetergentSettings
     */
    detergentPumpA: number;
    /**
     * 
     * @type {number}
     * @memberof ModuleLiquidDetergentSettings
     */
    detergentPumpB: number;
    /**
     * 
     * @type {number}
     * @memberof ModuleLiquidDetergentSettings
     */
    dosageA: number;
    /**
     * 
     * @type {number}
     * @memberof ModuleLiquidDetergentSettings
     */
    dosageB: number;
    /**
     * 
     * @type {number}
     * @memberof ModuleLiquidDetergentSettings
     */
    rinsingInTime: number;
}

/**
 * Check if a given object implements the ModuleLiquidDetergentSettings interface.
 */
export function instanceOfModuleLiquidDetergentSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stepNumber" in value;
    isInstance = isInstance && "displayStep" in value;
    isInstance = isInstance && "detergentPumpA" in value;
    isInstance = isInstance && "detergentPumpB" in value;
    isInstance = isInstance && "dosageA" in value;
    isInstance = isInstance && "dosageB" in value;
    isInstance = isInstance && "rinsingInTime" in value;

    return isInstance;
}

export function ModuleLiquidDetergentSettingsFromJSON(json: any): ModuleLiquidDetergentSettings {
    return ModuleLiquidDetergentSettingsFromJSONTyped(json, false);
}

export function ModuleLiquidDetergentSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModuleLiquidDetergentSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stepNumber': json['stepNumber'],
        'displayStep': StepFromJSON(json['displayStep']),
        'detergentPumpA': json['detergentPumpA'],
        'detergentPumpB': json['detergentPumpB'],
        'dosageA': json['dosageA'],
        'dosageB': json['dosageB'],
        'rinsingInTime': json['rinsingInTime'],
    };
}

export function ModuleLiquidDetergentSettingsToJSON(value?: ModuleLiquidDetergentSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stepNumber': value.stepNumber,
        'displayStep': StepToJSON(value.displayStep),
        'detergentPumpA': value.detergentPumpA,
        'detergentPumpB': value.detergentPumpB,
        'dosageA': value.dosageA,
        'dosageB': value.dosageB,
        'rinsingInTime': value.rinsingInTime,
    };
}

