import React, { useEffect, useState } from 'react'
import { TableSettings } from 'src/ui-shared/table/Table.const'

interface TableSearchHookObject {
  inputSearchValue: string
  handleSearch: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

export const useTableSearch = <T extends TableSettings>(
  tableSettings: TableSettings,
  setTableSettings: React.Dispatch<React.SetStateAction<T>>,
): TableSearchHookObject => {
  // link inputSearchValue with tableSettings.search
  useEffect(() => {
    setInputSearchValue(tableSettings.search)
  }, [tableSettings])

  const [timeoutState, setTimeoutState] = useState<NodeJS.Timeout | null>(null)
  const [inputSearchValue, setInputSearchValue] = useState<string>('')

  const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const text = event.target.value

    setInputSearchValue(text)

    if (timeoutState) {
      clearTimeout(timeoutState)
    }

    setTimeoutState(
      setTimeout(() => {
        setTableSettings((tableSettings) => {
          return { ...tableSettings, search: text, page: 0 }
        })
      }, 300),
    )
  }

  return { inputSearchValue, handleSearch }
}
