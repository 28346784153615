import React, { FC, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Box, Grid, Paper, TableCell, TableRow } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, FHMonitoringsApi, FhMonitoringEntry, Machine } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocale } from 'src/service/utils/DateFormatUtils'
import { getLastMonth, removeTimeFromDate, removeTimeFromDateEndOfDay } from 'src/service/utils/MomentUtils'
import { Data } from 'src/service/view-model/base/Data'
import { getErrorSeverityName } from 'src/service/view-model/error-log/ErrorLogViewModel'
import { getWashingStepName } from 'src/service/view-model/washing-step/WashingStep'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { ErrorSeverityIcon } from 'src/ui-shared/error-severity/ErrorSeverityIcon'
import { IconWithTooltip } from 'src/ui-shared/icons/Icons'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettings,
  getDefaultTableSettings,
  tableSettingsSort,
} from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { useUserRegionLocale } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'errorTime',
    label: 'date',
  },
  {
    id: 'errorCode',
    label: 'error.code',
  },
  {
    id: 'errorSummary',
    label: 'error.summary',
    noSort: true,
  },
  {
    id: 'step',
    label: 'step',
    noSort: true,
  },
]

interface ErrorCodesTableSettings extends TableSettings {
  startTimeFrom?: string
  startTimeTo?: string
}

interface Props {
  machine: Machine
}

const dateNow = removeTimeFromDateEndOfDay(new Date())
const dateLastMonth = removeTimeFromDate(getLastMonth(dateNow))

export const MachineErrorCodesTab: FC<Props> = ({ machine }) => {
  const { classes: tableClasses } = useTableStyles()

  const translate = useTranslate()
  const location = useLocation()
  const navigate = useNavigate()
  const regionLocale = useUserRegionLocale()

  const appId = useAppId()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const fhMonitoringApi = new FHMonitoringsApi(httpConfiguration)

  const [data, setData] = useState<TableData<FhMonitoringEntry>>(DEFAULT_DATA)
  const defaultTableSettings: ErrorCodesTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'errorTime',
    orderDir: 'desc',
    startTimeFrom: dateLastMonth.toISOString(),
    startTimeTo: dateNow.toISOString(),
  }
  const [tableSettings, setTableSettings] = useState<ErrorCodesTableSettings>(defaultTableSettings)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    let active = true
    setLoading(true)

    if (tableSettings.startTimeFrom && tableSettings.startTimeTo) {
      const dateStartTimeFrom = new Date(tableSettings.startTimeFrom)
      const dateStartTimeTo = new Date(tableSettings.startTimeTo)

      fhMonitoringApi
        .fhMonitoringsGet(
          dateStartTimeFrom,
          dateStartTimeTo,
          tableSettings.size,
          tableSettings.page,
          tableSettingsSort(tableSettings),
          undefined,
          undefined,
          undefined,
          undefined,
          machine.id,
        )
        .then((data) => {
          if (active) {
            updateTableSettingsFromData(data as Data<any>, tableSettings)
            setTableSettings(tableSettings)

            setData(mapData(data as Data<any>))
            setErrorMessage(null)
            setLoading(false)
          }
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setData(DEFAULT_DATA)
          setLoading(false)
        })
    }

    return () => {
      active = false
    }
  }, [tableSettings])

  const handleRowClick = (item: FhMonitoringEntry) => {
    navigate(`/${appId}/error-log/${item.messageId}/${item.errorTime.getTime()}/view`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  // JSX
  const nonEmptyRows = data.data.map((item) => {
    return (
      <TableRow className={tableClasses.tableRow} key={item.messageId} onClick={handleRowClick.bind(this, item)}>
        <TableCell>{formatDateTimeForLocale(item.errorTime, regionLocale)}</TableCell>
        <TableCell>{item.errorCode}</TableCell>
        <TableCell>
          <Box display={'flex'}>
            <IconWithTooltip
              icon={<ErrorSeverityIcon errorSeverity={item.errorSeverity} />}
              tooltip={getErrorSeverityName(item.errorSeverity, translate)}
            />
            <TruncatedTooltip maxChars={50} value={item.errorSummary} />
          </Box>
        </TableCell>
        <TableCell>{getWashingStepName(item.step, translate)}</TableCell>
      </TableRow>
    )
  })

  return (
    <>
      <Box mt={3} mb={3}>
        <Grid container spacing={2}>
          <TableDatePickerForm
            key={`${tableSettings.startTimeFrom}${tableSettings.startTimeTo}`}
            firstDatePropName="startTimeFrom"
            secondDatePropName="startTimeTo"
            setTableSettings={setTableSettings}
            tableSettings={tableSettings}
          />
        </Grid>
      </Box>

      <Paper elevation={0}>
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

        <DataTable
          headCells={headCells}
          data={data}
          nonEmptyRows={nonEmptyRows}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          loading={loading}
          translate={translate}
        />
      </Paper>
    </>
  )
}
