/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';
import type { ProgramType } from './ProgramType';
import {
    ProgramTypeFromJSON,
    ProgramTypeFromJSONTyped,
    ProgramTypeToJSON,
} from './ProgramType';

/**
 * Deprecated, will be migrated to ProgramMetaData/ProgramInstalled
 * @export
 * @interface ProgramInformation
 */
export interface ProgramInformation {
    /**
     * 
     * @type {string}
     * @memberof ProgramInformation
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramInformation
     */
    readonly organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramInformation
     */
    readonly laundryGroupId?: string;
    /**
     * 
     * @type {MachineType}
     * @memberof ProgramInformation
     */
    machineType: MachineType;
    /**
     * Drum Size of machine in kg (Optional)
     * @type {number}
     * @memberof ProgramInformation
     */
    drumSize?: number | null;
    /**
     * Unit of Weight ('kg' | 'lbs')
     * @type {string}
     * @memberof ProgramInformation
     */
    drumSizeUnit?: string;
    /**
     * master program Index
     * @type {number}
     * @memberof ProgramInformation
     */
    readonly programIndex: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramInformation
     */
    readonly programName: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramInformation
     */
    readonly programMode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProgramInformation
     */
    readonly temperature?: number | null;
    /**
     * Unit of Temperatures ('В°C' | 'В°F')
     * @type {string}
     * @memberof ProgramInformation
     */
    temperatureUnit?: string;
    /**
     * 
     * @type {ProgramType}
     * @memberof ProgramInformation
     */
    programType: ProgramType;
    /**
     * 
     * @type {Date}
     * @memberof ProgramInformation
     */
    readonly lastModified?: Date;
}

/**
 * Check if a given object implements the ProgramInformation interface.
 */
export function instanceOfProgramInformation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "machineType" in value;
    isInstance = isInstance && "programIndex" in value;
    isInstance = isInstance && "programName" in value;
    isInstance = isInstance && "programType" in value;

    return isInstance;
}

export function ProgramInformationFromJSON(json: any): ProgramInformation {
    return ProgramInformationFromJSONTyped(json, false);
}

export function ProgramInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'laundryGroupId': !exists(json, 'laundryGroupId') ? undefined : json['laundryGroupId'],
        'machineType': MachineTypeFromJSON(json['machineType']),
        'drumSize': !exists(json, 'drumSize') ? undefined : json['drumSize'],
        'drumSizeUnit': !exists(json, 'drumSizeUnit') ? undefined : json['drumSizeUnit'],
        'programIndex': json['programIndex'],
        'programName': json['programName'],
        'programMode': !exists(json, 'programMode') ? undefined : json['programMode'],
        'temperature': !exists(json, 'temperature') ? undefined : json['temperature'],
        'temperatureUnit': !exists(json, 'temperatureUnit') ? undefined : json['temperatureUnit'],
        'programType': ProgramTypeFromJSON(json['programType']),
        'lastModified': !exists(json, 'lastModified') ? undefined : (new Date(json['lastModified'])),
    };
}

export function ProgramInformationToJSON(value?: ProgramInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machineType': MachineTypeToJSON(value.machineType),
        'drumSize': value.drumSize,
        'drumSizeUnit': value.drumSizeUnit,
        'temperatureUnit': value.temperatureUnit,
        'programType': ProgramTypeToJSON(value.programType),
    };
}

