/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Role } from './Role';
import {
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface MemberRolesUpdate
 */
export interface MemberRolesUpdate {
    /**
     * 
     * @type {Array<Role>}
     * @memberof MemberRolesUpdate
     */
    memberRoles: Array<Role>;
}

/**
 * Check if a given object implements the MemberRolesUpdate interface.
 */
export function instanceOfMemberRolesUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "memberRoles" in value;

    return isInstance;
}

export function MemberRolesUpdateFromJSON(json: any): MemberRolesUpdate {
    return MemberRolesUpdateFromJSONTyped(json, false);
}

export function MemberRolesUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberRolesUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberRoles': ((json['memberRoles'] as Array<any>).map(RoleFromJSON)),
    };
}

export function MemberRolesUpdateToJSON(value?: MemberRolesUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberRoles': ((value.memberRoles as Array<any>).map(RoleToJSON)),
    };
}

