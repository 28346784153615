/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Country,
  LaundryUser,
  LaundryUserBulkResponse,
  LaundryUserReference,
  PageableLaundryUsers,
} from '../models';
import {
    CountryFromJSON,
    CountryToJSON,
    LaundryUserFromJSON,
    LaundryUserToJSON,
    LaundryUserBulkResponseFromJSON,
    LaundryUserBulkResponseToJSON,
    LaundryUserReferenceFromJSON,
    LaundryUserReferenceToJSON,
    PageableLaundryUsersFromJSON,
    PageableLaundryUsersToJSON,
} from '../models';

export interface LaundryusersBulkGetRequest {
    assignedLaundryGroupId?: string;
}

export interface LaundryusersBulkPutRequest {
    body?: Blob;
}

export interface LaundryusersGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    id?: string;
    countryCode?: Country;
    organizationId?: string;
    email?: string;
    esrNumber?: string;
    creditorReference?: string;
    assignedLaundryGroupId?: string;
    assignedRFIDCardNumber?: number;
}

export interface LaundryusersLaundryUserIdDeleteRequest {
    laundryUserId: string;
}

export interface LaundryusersLaundryUserIdGetRequest {
    laundryUserId: string;
}

export interface LaundryusersLaundryUserIdPasswordResetPostRequest {
    laundryUserId: string;
    body?: object;
}

export interface LaundryusersLaundryUserIdPutRequest {
    laundryUserId: string;
    laundryUser?: LaundryUser;
}

export interface LaundryusersPostRequest {
    laundryUser?: LaundryUser;
}

export interface LaundryusersRefGetRequest {
    size?: number;
    search?: string;
    laundryGroupId?: Array<string>;
}

/**
 * 
 */
export class LaundryUsersApi extends runtime.BaseAPI {

    /**
     * Returns a CSV file of laundry users
     */
    async laundryusersBulkGetRaw(requestParameters: LaundryusersBulkGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.assignedLaundryGroupId !== undefined) {
            queryParameters['assignedLaundryGroupId'] = requestParameters.assignedLaundryGroupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundryusers/bulk`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Returns a CSV file of laundry users
     */
    async laundryusersBulkGet(assignedLaundryGroupId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.laundryusersBulkGetRaw({ assignedLaundryGroupId: assignedLaundryGroupId }, initOverrides);
        return await response.value();
    }

    /**
     * Update laundry users
     */
    async laundryusersBulkPutRaw(requestParameters: LaundryusersBulkPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaundryUserBulkResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/csv';

        const response = await this.request({
            path: `/laundryusers/bulk`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaundryUserBulkResponseFromJSON));
    }

    /**
     * Update laundry users
     */
    async laundryusersBulkPut(body?: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaundryUserBulkResponse>> {
        const response = await this.laundryusersBulkPutRaw({ body: body }, initOverrides);
        return await response.value();
    }

    /**
     * List Laundry Users
     */
    async laundryusersGetRaw(requestParameters: LaundryusersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableLaundryUsers>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.countryCode !== undefined) {
            queryParameters['countryCode'] = requestParameters.countryCode;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.esrNumber !== undefined) {
            queryParameters['esrNumber'] = requestParameters.esrNumber;
        }

        if (requestParameters.creditorReference !== undefined) {
            queryParameters['creditorReference'] = requestParameters.creditorReference;
        }

        if (requestParameters.assignedLaundryGroupId !== undefined) {
            queryParameters['assignedLaundryGroupId'] = requestParameters.assignedLaundryGroupId;
        }

        if (requestParameters.assignedRFIDCardNumber !== undefined) {
            queryParameters['assignedRFIDCardNumber'] = requestParameters.assignedRFIDCardNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundryusers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableLaundryUsersFromJSON(jsonValue));
    }

    /**
     * List Laundry Users
     */
    async laundryusersGet(size?: number, page?: number, sort?: string, search?: string, id?: string, countryCode?: Country, organizationId?: string, email?: string, esrNumber?: string, creditorReference?: string, assignedLaundryGroupId?: string, assignedRFIDCardNumber?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableLaundryUsers> {
        const response = await this.laundryusersGetRaw({ size: size, page: page, sort: sort, search: search, id: id, countryCode: countryCode, organizationId: organizationId, email: email, esrNumber: esrNumber, creditorReference: creditorReference, assignedLaundryGroupId: assignedLaundryGroupId, assignedRFIDCardNumber: assignedRFIDCardNumber }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a Laundry User
     */
    async laundryusersLaundryUserIdDeleteRaw(requestParameters: LaundryusersLaundryUserIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryUserId === null || requestParameters.laundryUserId === undefined) {
            throw new runtime.RequiredError('laundryUserId','Required parameter requestParameters.laundryUserId was null or undefined when calling laundryusersLaundryUserIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundryusers/{laundryUserId}`.replace(`{${"laundryUserId"}}`, encodeURIComponent(String(requestParameters.laundryUserId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Laundry User
     */
    async laundryusersLaundryUserIdDelete(laundryUserId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.laundryusersLaundryUserIdDeleteRaw({ laundryUserId: laundryUserId }, initOverrides);
    }

    /**
     * Get a Laundry User
     */
    async laundryusersLaundryUserIdGetRaw(requestParameters: LaundryusersLaundryUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryUser>> {
        if (requestParameters.laundryUserId === null || requestParameters.laundryUserId === undefined) {
            throw new runtime.RequiredError('laundryUserId','Required parameter requestParameters.laundryUserId was null or undefined when calling laundryusersLaundryUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundryusers/{laundryUserId}`.replace(`{${"laundryUserId"}}`, encodeURIComponent(String(requestParameters.laundryUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryUserFromJSON(jsonValue));
    }

    /**
     * Get a Laundry User
     */
    async laundryusersLaundryUserIdGet(laundryUserId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryUser> {
        const response = await this.laundryusersLaundryUserIdGetRaw({ laundryUserId: laundryUserId }, initOverrides);
        return await response.value();
    }

    /**
     * Reset the password of the laundry user
     */
    async laundryusersLaundryUserIdPasswordResetPostRaw(requestParameters: LaundryusersLaundryUserIdPasswordResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryUserId === null || requestParameters.laundryUserId === undefined) {
            throw new runtime.RequiredError('laundryUserId','Required parameter requestParameters.laundryUserId was null or undefined when calling laundryusersLaundryUserIdPasswordResetPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundryusers/{laundryUserId}/password-reset`.replace(`{${"laundryUserId"}}`, encodeURIComponent(String(requestParameters.laundryUserId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reset the password of the laundry user
     */
    async laundryusersLaundryUserIdPasswordResetPost(laundryUserId: string, body?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.laundryusersLaundryUserIdPasswordResetPostRaw({ laundryUserId: laundryUserId, body: body }, initOverrides);
    }

    /**
     * Update a Laundry User
     */
    async laundryusersLaundryUserIdPutRaw(requestParameters: LaundryusersLaundryUserIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryUser>> {
        if (requestParameters.laundryUserId === null || requestParameters.laundryUserId === undefined) {
            throw new runtime.RequiredError('laundryUserId','Required parameter requestParameters.laundryUserId was null or undefined when calling laundryusersLaundryUserIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundryusers/{laundryUserId}`.replace(`{${"laundryUserId"}}`, encodeURIComponent(String(requestParameters.laundryUserId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryUserToJSON(requestParameters.laundryUser),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryUserFromJSON(jsonValue));
    }

    /**
     * Update a Laundry User
     */
    async laundryusersLaundryUserIdPut(laundryUserId: string, laundryUser?: LaundryUser, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryUser> {
        const response = await this.laundryusersLaundryUserIdPutRaw({ laundryUserId: laundryUserId, laundryUser: laundryUser }, initOverrides);
        return await response.value();
    }

    /**
     * Register a new Laundry User
     */
    async laundryusersPostRaw(requestParameters: LaundryusersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryUser>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundryusers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryUserToJSON(requestParameters.laundryUser),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryUserFromJSON(jsonValue));
    }

    /**
     * Register a new Laundry User
     */
    async laundryusersPost(laundryUser?: LaundryUser, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryUser> {
        const response = await this.laundryusersPostRaw({ laundryUser: laundryUser }, initOverrides);
        return await response.value();
    }

    /**
     * List Laundry User References
     */
    async laundryusersRefGetRaw(requestParameters: LaundryusersRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaundryUserReference>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.laundryGroupId) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundryusers/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaundryUserReferenceFromJSON));
    }

    /**
     * List Laundry User References
     */
    async laundryusersRefGet(size?: number, search?: string, laundryGroupId?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaundryUserReference>> {
        const response = await this.laundryusersRefGetRaw({ size: size, search: search, laundryGroupId: laundryGroupId }, initOverrides);
        return await response.value();
    }

}
