import React, { ReactElement } from 'react'
import { Alert, Button } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'

interface Props {
  useMockData: boolean
  setUseMockData: (value: boolean) => void
  fullWidth?: boolean
}
export const DemoDataSwitch = ({ useMockData, setUseMockData, fullWidth }: Props): ReactElement => {
  const translate = useTranslate()

  return !useMockData ? (
    <Button variant="outlined" onClick={() => setUseMockData(true)}>
      {translate('demoData')}
    </Button>
  ) : (
    <Alert
      severity={'warning'}
      action={
        <Button color="inherit" onClick={() => setUseMockData(false)}>
          {translate('disableDemoData')}
        </Button>
      }
      sx={{ paddingTop: '0px', paddingBottom: '0px', flexGrow: fullWidth ? 1 : 0 }}
    >
      {translate('demoData')}
    </Alert>
  )
}
