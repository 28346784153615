import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router'
import { ShoppingBagOutlined } from '@mui/icons-material'
import { AppId } from 'src/app/AppId'
import { StyledMenuItem } from 'src/ui/layout/appbar-menu/MenuStyle'

export const ServicePackagePlanMenu = (): ReactElement => {
  const navigate = useNavigate()

  const navigateToServicePackagePlanView = () => {
    navigate(`/${AppId.WASH_MASTER}/service-package-plan`)
  }

  return (
    <StyledMenuItem active={0} onClick={navigateToServicePackagePlanView}>
      <ShoppingBagOutlined />
    </StyledMenuItem>
  )
}
