/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { ProgramGroup } from './ProgramGroup';
import {
    ProgramGroupFromJSON,
    ProgramGroupFromJSONTyped,
    ProgramGroupToJSON,
} from './ProgramGroup';

/**
 * 
 * @export
 * @interface PageableProgramGroups
 */
export interface PageableProgramGroups {
    /**
     * 
     * @type {Array<ProgramGroup>}
     * @memberof PageableProgramGroups
     */
    result: Array<ProgramGroup>;
    /**
     * 
     * @type {Page}
     * @memberof PageableProgramGroups
     */
    page: Page;
}

/**
 * Check if a given object implements the PageableProgramGroups interface.
 */
export function instanceOfPageableProgramGroups(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageableProgramGroupsFromJSON(json: any): PageableProgramGroups {
    return PageableProgramGroupsFromJSONTyped(json, false);
}

export function PageableProgramGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableProgramGroups {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(ProgramGroupFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageableProgramGroupsToJSON(value?: PageableProgramGroups | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(ProgramGroupToJSON)),
        'page': PageToJSON(value.page),
    };
}

