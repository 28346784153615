import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router'
import { Add } from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Permission } from 'src/service/backend/api'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { ManufacturerTable } from 'src/ui/page/wm/manufacturer/list/ManufacturerTable'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const ManufacturerListPage = (): ReactElement => {
  const translate = useTranslate()

  const navigate = useNavigate()
  const appId = useAppId()
  const user = useUser()

  const hasWritePermission = hasPermission(user, Permission.MANUFACTURER_WRITE)

  const handleRowClick = (manufacturerId?: string) => {
    navigate(`/${appId}/manufacturers/${manufacturerId}/view`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleCreateButtonClick = () => {
    navigate(`/${appId}/manufacturers/create`)
  }

  const createNewAction = (
    <ListingButton onClick={handleCreateButtonClick} variant="outlined" color="primary" startIcon={<Add />}>
      {translate('createNew')}
    </ListingButton>
  )

  const actions = hasWritePermission ? createNewAction : <></>

  return (
    <ScreenLayout title={translate('manufacturer')} actions={actions}>
      <ManufacturerTable navigateToItem={handleRowClick}></ManufacturerTable>
    </ScreenLayout>
  )
}
