import React, { FC, useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { AlertColor, Box, Divider, Grid } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  Machine,
  MachineParameters,
  MachinesApi,
  Permission,
  SettingField,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { isConnectedMachineType } from 'src/service/view-model/machine/Machines'
import { flattenGroups } from 'src/service/view-model/setting-field/SettingExpandableGroupsUtils'
import { modifyGroupFieldsAddDefaultHiddenValue } from 'src/service/view-model/setting-field/SettingVisibilityDefaultUtils'
import { hideOrShowGroupsForAllSelectOrBooleanFields } from 'src/service/view-model/setting-field/SettingVisibilityUtils'
import { ButtonWithTooltip } from 'src/ui-shared/base/button/Buttons'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DynamicSettingFieldsHookForm } from 'src/ui-shared/base/form/dynamic-setting-form/DynamicSettingFieldsHookForm'
import {
  SETTING_FIELDS_WRAPPER_FIELDS_PATH,
  SettingFieldsWrapper,
} from 'src/ui-shared/base/form/dynamic-setting-form/SettingFieldsWrapper'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

interface Props {
  machine: Machine
}

export const MachineParametersTab: FC<Props> = ({ machine }) => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  const navigate = useNavigate()
  const appId = useAppId()
  const user = useUser()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const machinesApi = new MachinesApi(httpConfiguration)

  const machineId = machine.id

  // form
  const settingsForm = useForm<SettingFieldsWrapper>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const prefix = SETTING_FIELDS_WRAPPER_FIELDS_PATH
  const { update } = useFieldArray({
    control: settingsForm.control,
    name: prefix,
  })

  // state
  const [machineParameters, setMachineParameters] = useState<MachineParameters | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error')

  const hasEditPermission = hasPermission(user, Permission.MACHINE_WRITE_PARAMETERS)
  const isConnectedMachine = isConnectedMachineType(machine.machineConnectivityType)

  // load data
  useEffect(() => {
    if (!isConnectedMachine) {
      setErrorMessage(translate('parametersIncompatibleMachine'))
      setAlertSeverity('info')
    } else {
      setLoading(true)
      setErrorMessage(null)

      machinesApi
        .machinesMachineIdParametersGet(machineId!)
        .then((data) => {
          setLoading(false)
          setMachineParameters(data)
        })
        .catch((err) => {
          setLoading(false)
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setAlertSeverity('error')
        })
    }
  }, [machineId])

  // load form after machineParameters is loaded
  useEffect(() => {
    const fields = machineParameters ? machineParameters.fields : undefined

    if (fields) {
      setFieldsInForm(fields)
    }
  }, [machineParameters])

  const setFieldsInForm = (fields: SettingField[]) => {
    const flatFields = flattenGroups(fields)

    const newFormValues: SettingFieldsWrapper = { fields: flatFields }
    modifyGroupFieldsAddDefaultHiddenValue(flatFields)
    hideOrShowGroupsForAllSelectOrBooleanFields(flatFields)
    settingsForm.reset(newFormValues)
  }

  useHotKeysForm()

  // handle events
  const navigateToEditPage = () => {
    navigate(`/${appId}/machines/${machine.id}/view/parameters/edit`)
  }

  return (
    <>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : errorMessage ? (
        <ErrorMessage severity={alertSeverity} message={errorMessage} />
      ) : (
        <Box pt={2}>
          <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
            {machineParameters ? (
              <DynamicSettingFieldsHookForm
                fields={settingsForm.getValues().fields}
                formContext={{
                  form: settingsForm,
                  formDisabled: true,
                  loading: loading,
                  prefix: prefix,
                }}
                update={update}
              />
            ) : null}
          </Grid>

          {hasEditPermission && (
            <>
              <Divider className={sharedClasses.Divider} />

              <Box mt={3} display="flex" justifyContent="flex-end">
                <ButtonWithTooltip
                  id="editButton"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={navigateToEditPage}
                >
                  {translate('button.edit')}
                </ButtonWithTooltip>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  )
}
