import moment from 'moment'
import {
  LaundryGroupSingleCalendar,
  LaundryGroupSingleCalendarEntry,
  OpeningType,
  PriceCondition,
} from 'src/service/backend/api'

export interface LaundryGroupBlockedDaysViewModel {
  dateToBlock: Date
  reason?: string
}

export const createLaundryGroupBlockedDaysViewModel = (): LaundryGroupBlockedDaysViewModel => {
  const blockedDaysViewModel: LaundryGroupBlockedDaysViewModel = {
    dateToBlock: new Date(),
    reason: undefined,
  }
  return blockedDaysViewModel
}

export const mapBlockedDaysViewModelToLaundryGroupSingleCalendarEntry = (
  viewModel: LaundryGroupBlockedDaysViewModel,
): LaundryGroupSingleCalendarEntry => {
  const laundryGroupSingleCalendarEntry: LaundryGroupSingleCalendarEntry = {
    startTime: viewModel.dateToBlock,
    endTime: moment(viewModel.dateToBlock).add(1, 'day').toDate(),
    openingType: OpeningType.BLOCKED,
    priceCondition: PriceCondition.REGULAR,
    reason: viewModel.reason,
  }

  return laundryGroupSingleCalendarEntry
}

export const transformLaundryGroupSingleCalendarToEntries = (
  singleCalendar: LaundryGroupSingleCalendar,
): LaundryGroupSingleCalendarEntry[] => {
  const calendarDays = singleCalendar.days
  const calendarEntries = calendarDays.map((item) => item.entries).flatMap((item) => item)
  return calendarEntries
}
