/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CalendarConfig,
} from '../models';
import {
    CalendarConfigFromJSON,
    CalendarConfigToJSON,
} from '../models';

export interface CalendarConfigLaundryGroupIdGetRequest {
    laundryGroupId: string;
}

export interface CalendarConfigLaundryGroupIdPutRequest {
    laundryGroupId: string;
    calendarConfig?: CalendarConfig;
}

/**
 * 
 */
export class CalendarApi extends runtime.BaseAPI {

    /**
     * Get calendar config
     */
    async calendarConfigLaundryGroupIdGetRaw(requestParameters: CalendarConfigLaundryGroupIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarConfig>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling calendarConfigLaundryGroupIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar/config/{laundryGroupId}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarConfigFromJSON(jsonValue));
    }

    /**
     * Get calendar config
     */
    async calendarConfigLaundryGroupIdGet(laundryGroupId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarConfig> {
        const response = await this.calendarConfigLaundryGroupIdGetRaw({ laundryGroupId: laundryGroupId }, initOverrides);
        return await response.value();
    }

    /**
     * Set calendar config
     */
    async calendarConfigLaundryGroupIdPutRaw(requestParameters: CalendarConfigLaundryGroupIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarConfig>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling calendarConfigLaundryGroupIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/calendar/config/{laundryGroupId}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CalendarConfigToJSON(requestParameters.calendarConfig),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarConfigFromJSON(jsonValue));
    }

    /**
     * Set calendar config
     */
    async calendarConfigLaundryGroupIdPut(laundryGroupId: string, calendarConfig?: CalendarConfig, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarConfig> {
        const response = await this.calendarConfigLaundryGroupIdPutRaw({ laundryGroupId: laundryGroupId, calendarConfig: calendarConfig }, initOverrides);
        return await response.value();
    }

}
