export interface GridItemBreakpointsObject {
  xs: number
  sm: number
  md: number
  lg: number
}

export const ITEM_BREAKPOINTS: GridItemBreakpointsObject = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
}

export const getGridTabBreakpointsForDynamicTabs = (tabsLength?: number): GridItemBreakpointsObject => {
  const defaultBreakpoints: GridItemBreakpointsObject = {
    lg: 12,
    md: 12,
    sm: 12,
    xs: 12,
  }

  if (tabsLength === 1) {
    defaultBreakpoints.lg = 4
    defaultBreakpoints.md = 4
  }
  if (tabsLength === 2) {
    defaultBreakpoints.lg = 8
    defaultBreakpoints.md = 8
  }

  return defaultBreakpoints
}
