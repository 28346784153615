export function isValidPositiveNumber(value: string): boolean {
  const trimmed = value.trim()
  if (trimmed === '') {
    return true
  }
  const onlyNumbers = trimmed.replace(/[^0-9]/gi, '')
  if (trimmed !== onlyNumbers) {
    return false
  }

  const parsed = parseInt(value)
  return !isNaN(parsed) && parsed > 0
}

/**
 * Compare two number in ascending order, if undefined value then move at last position
 *
 * @param number1 the first element for comparison
 * @param number2 the second element for comparison
 * @returns {number} value to be return in sort function
 */
export function compareNumbersAsc(number1?: number | null, number2?: number | null): number {
  if ((number1 === null || number1 === undefined) && (number2 === null || number2 === undefined)) {
    return 0
  }

  if (number1 === null || number1 === undefined) {
    return 1
  }

  if (number2 === null || number2 === undefined) {
    return -1
  }

  if (number1 === number2) {
    return 0
  }

  return number1 < number2 ? -1 : 1
}
