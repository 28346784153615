import React, { FC, ReactElement, useContext } from 'react'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganizationMandatory } from 'src/organization/ActiveOrganizationProvider'
import { ApiResponse, Configuration, MachineType, ProgramsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getRemoveAcceptHeaderInit } from 'src/service/backend/http/HttpUtils'
import { downloadFile, getDownloadFilename } from 'src/service/utils/FileDownloadUtils'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ProgramExportModalParent } from 'src/ui/page/es/program/details/ProgramExportModalParent'

interface Props {
  title: string
  open: boolean
  selectedProgramIds: string[]
  machineType: MachineType
  handleClose: (clearSelected?: boolean) => void
}

export const ProgramExportModal: FC<Props> = ({
  title,
  open,
  handleClose,
  selectedProgramIds,
  machineType,
}): ReactElement => {
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const activeOrganization = useActiveOrganizationMandatory()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programsApi = new ProgramsApi(httpConfiguration)

  const singleOrMultipleProgramExport = (drumSize: number): Promise<ApiResponse<Blob>> => {
    if (selectedProgramIds.length === 1) {
      // single program export
      const [programSelected] = selectedProgramIds
      return programsApi.programsProgramIdExportGetRaw(
        {
          programId: programSelected,
          organizationId: activeOrganization.id,
          machineType: machineType,
          drumSize,
        },
        getRemoveAcceptHeaderInit(),
      )
    } else {
      // multiple programs export
      const programsSelected = [...selectedProgramIds]
      return programsApi.programsExportGetRaw(
        {
          programId: programsSelected,
          organizationId: activeOrganization.id,
          machineType: machineType,
          drumSize,
        },
        getRemoveAcceptHeaderInit(),
      )
    }
  }

  const onConfirm = (drumSize: number) => {
    let filename: string | null

    return singleOrMultipleProgramExport(drumSize)
      .then(async (response: ApiResponse<Blob>) => {
        filename = getDownloadFilename(response.raw)
        return response.value()
      })
      .then((blob: Blob) => {
        downloadFile(blob, filename)
        handleClose(true)
        return blob
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
        return err
      })
  }

  return (
    <ProgramExportModalParent
      onConfirm={onConfirm}
      machineType={machineType}
      open={open}
      handleClose={handleClose}
      title={title}
    />
  )
}
