import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Paper, TableCell, TableRow } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, Permission, RoleDetail, RolesApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { OrderBy, OrderDir } from 'src/service/view-model/base/Data'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettings,
  getDefaultTableSettings,
} from 'src/ui-shared/table/Table.const'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'

interface Props {}

interface RolePermission {
  key: string
  name?: string
  permission: Permission
}

const headCells: HeadCells[] = [
  {
    id: 'key',
    label: 'role.key',
  },
  {
    id: 'name',
    label: 'role.name',
  },
  {
    id: 'permission',
    label: 'role.permission',
  },
]

interface RolePermissionTableSettings extends TableSettings {
  roleKey?: string
  permission?: Permission
}

const sort = (orderDir: OrderDir, orderBy: OrderBy<keyof RolePermission>) => {
  switch (orderDir) {
    case 'asc':
      return (a: RolePermission, b: RolePermission): number => a[orderBy]!.localeCompare(b[orderBy]!)
    case 'desc':
      return (a: RolePermission, b: RolePermission): number => b[orderBy]!.localeCompare(a[orderBy]!)
  }
}

export const RolePermissionListTable: React.FC<Props> = (): ReactElement => {
  const translate = useTranslate()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const rolesApi = new RolesApi(httpConfiguration)

  // state
  const defaultTableSettings: RolePermissionTableSettings = { roleKey: '', ...getDefaultTableSettings() }
  const [loading, setLoading] = useState<boolean>(false)
  const [rolePermissionList, setRolePermissionList] = useState<RolePermission[]>([])
  const [data, setData] = useState<TableData<RolePermission>>(DEFAULT_DATA)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [tableSettings, setTableSettings] = useState<RolePermissionTableSettings>(defaultTableSettings)

  const map: (roleDetails: RoleDetail[]) => RolePermission[] = (roleDetails) =>
    roleDetails.flatMap(({ role, permissions }) =>
      permissions.map((permission) => ({
        key: role.roleKey,
        name: role.name,
        permission,
      })),
    )

  // load data once
  useEffect(() => {
    let active = true
    setLoading(true)
    rolesApi
      .rolesGet()
      .then((roleDetails) => {
        if (!active) return
        const data = map(roleDetails)
        setRolePermissionList(data)
        setTableSettings({ ...tableSettings })
        setLoading(false)
        setErrorMessage(null)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [])

  useEffect(() => {
    const { roleKey, permission, page, size, orderDir, orderBy } = tableSettings
    const firstElementIndex = page * size
    const lastElementIndex = firstElementIndex + size

    const filteredData = rolePermissionList
      .filter((rolePermission) => !roleKey || rolePermission.key === roleKey)
      .filter((rolePermission) => !permission || rolePermission.permission === permission)
      .sort(sort(orderDir, orderBy))

    const slicedData = filteredData.slice(firstElementIndex, lastElementIndex)

    setData({ data: slicedData, totalElements: filteredData.length })
  }, [tableSettings])

  const KEY_OPTIONS = [...new Set(rolePermissionList.map(({ key }) => key))].map((key) => ({ key }))
  const PERMISSION_OPTIONS = [...new Set(rolePermissionList.map(({ permission }) => permission))].map((permission) => ({
    permission,
  }))

  const getFilter = (headCellId: string): ReactElement | undefined => {
    if (headCellId === 'key') {
      return (
        <TableFilterAutocomplete
          options={KEY_OPTIONS}
          title={translate('role.key.filter')}
          label={translate('role.key')}
          filter={'roleKey'}
          labelFieldName={'key'}
          valueFieldName={'key'}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'permission') {
      return (
        <TableFilterAutocomplete
          options={PERMISSION_OPTIONS}
          title={translate('role.permission.filter')}
          label={translate('role.permission')}
          filter={'permission'}
          labelFieldName={'permission'}
          valueFieldName={'permission'}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }
  }

  const nonEmptyRows = data.data.map(({ key, name, permission }) => (
    <TableRow key={key + permission}>
      <TableCell>
        <TruncatedTooltip maxChars={35} value={key} />
      </TableCell>
      <TableCell>
        <TruncatedTooltip maxChars={30} value={name} />
      </TableCell>
      <TableCell>
        <TruncatedTooltip maxChars={30} value={permission} />
      </TableCell>
    </TableRow>
  ))

  return (
    <Paper elevation={0}>
      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
      <DataTable
        headCells={headCells}
        data={data}
        getFilter={getFilter}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        loading={loading}
        translate={translate}
      />
    </Paper>
  )
}
