import React, { PropsWithChildren, ReactElement, useContext, useMemo } from 'react'
import { EnvironmentContext } from 'src/env/EnvironmentContext'
import {
  Configuration,
  ConfigurationParameters,
  FetchParams,
  HTTPHeaders,
  Middleware,
  RequestContext,
  ResponseContext,
} from 'src/service/backend/api/runtime'
import { AuthenticationError } from 'src/service/backend/error/AuthenticationError'
import { AuthorizationError } from 'src/service/backend/error/AuthorizationError'
import { ClientError } from 'src/service/backend/error/ClientError'
import { HttpResponseError } from 'src/service/backend/error/HttpResponseError'
import { ServerError } from 'src/service/backend/error/ServerError'
import { KeycloakService } from 'src/user/KeycloakService'
import { UserContext } from 'src/user/UserContext'

const defaultConfiguration: Configuration = new Configuration()

export const HttpContext = React.createContext<Configuration>(defaultConfiguration)

export const HttpContextProvider = ({ children }: PropsWithChildren): ReactElement => {
  const appConfig = useContext(EnvironmentContext)
  const { user } = useContext(UserContext)

  const httpConfiguration = useMemo(
    () => createConfiguration(appConfig.backendUrl, user.token),
    [appConfig.backendUrl, user.token],
  )

  return <HttpContext.Provider value={httpConfiguration}>{children}</HttpContext.Provider>
}

function createConfiguration(basePath: string, token: string): Configuration {
  // add cors mode
  const middleware1: Middleware = {
    pre(context: RequestContext): Promise<FetchParams> {
      const fetchParams: FetchParams = {
        url: context.url,
        init: context.init,
      }

      fetchParams.init.mode = 'cors'

      return new Promise<FetchParams>((resolve) => {
        resolve(fetchParams)
      })
    },
  }

  // error handling
  const middleware2: Middleware = {
    post(context: ResponseContext): Promise<Response> {
      return new Promise<Response>((resolve, reject) => {
        const response = context.response

        if (!response.ok) {
          // backend reported error

          // check status code and throw appropriate error
          if (response.status === 401) {
            console.warn('401 clearing token')
            KeycloakService.getKeycloakInstance().clearToken()
            reject(
              new AuthenticationError(
                'You are not authenticated to access the resource, please login.',
                response.status,
              ),
            )
          } else if (response.status === 403) {
            reject(new AuthorizationError("You don't have permissions to access the resource!", response.status))
          } else if (response.status === 404) {
            reject(new ClientError('The request source could not be found', response.status))
          } else if (response.status >= 400 && response.status <= 451) {
            parseValidationError(response)
              .then((validationError) => {
                reject(new ClientError(getMessageText(validationError), response.status, true))
              })
              .catch((e) => {
                console.error('Error parsing response body for 4xx status code', e)
                reject(new ClientError('Client error occurred', response.status, false))
              })
          } else if (response.status === 501) {
            reject(new ServerError('Server error, not implemented', response.status))
          } else if (response.status >= 500 && response.status <= 599) {
            parseValidationError(response)
              .then((validationError) => {
                reject(
                  new ServerError('Server error occurred: ' + getMessageText(validationError), response.status, true),
                )
              })
              .catch((e) => {
                console.error('Error parsing response body for 5xx status code', e)
                reject(new ServerError('Server error occurred', response.status))
              })
          } else {
            reject(new HttpResponseError('Unhandled HTTP response error', response.status))
          }
        } else {
          resolve(response)
        }
      })
    },
  }

  const middleware: Middleware[] = [middleware1, middleware2]

  // authentication token
  const accessToken = token

  // default headers
  const headers: HTTPHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + accessToken,
  }

  // construct HTTP configuration
  const configParams: ConfigurationParameters = {
    basePath,
    middleware,
    headers,
    accessToken,
  }
  const configuration = new Configuration(configParams)

  return configuration
}

interface ValidationMessage {
  key?: string
  field?: string
  message: string
}

function parseValidationError(response: Response): Promise<ValidationMessage> {
  return new Promise<ValidationMessage>((resolve, reject) => {
    response
      .text()
      .then((text) => {
        let validationError
        try {
          const json = JSON.parse(text)

          try {
            validationError = parseClientErrorBodyFromJson(json)
          } catch (err) {
            console.error('Error parsing validation error from JSON', err)
          }
        } catch {
          // probably not json
          validationError = createClientErrorBodyFromText(text)
        }

        if (validationError && validationError.message) {
          resolve(validationError)
        } else {
          reject('Could not parse validation error')
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

function parseClientErrorBodyFromJson(json: any): ValidationMessage | undefined {
  let message
  let key
  let field
  if (json && json.entries && json.entries.length && json.entries.length > 0) {
    const entries = json.entries
    const item = entries[0]
    key = item.key
    field = item.field
    message = item.value
  }

  const result = message ? { key, field, message } : undefined
  return result
}

function createClientErrorBodyFromText(text: any): ValidationMessage | undefined {
  if (text) {
    const validationMessage: ValidationMessage = {
      message: text,
    }
    return validationMessage
  }
  return undefined
}

function getMessageText(validationError: ValidationMessage): string {
  let messageText
  if (validationError.field) {
    let field = validationError.field
    if (field.length > 1) {
      field = field.charAt(0).toUpperCase() + field.substring(1)
    }
    messageText = field + ' '
  } else {
    messageText = ''
  }

  messageText += validationError.message

  return messageText
}
