import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, LaundryGroup, LaundryGroupNews, LaundryGroupsNewsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { maxChar } from 'src/ui-shared/base/form/validation/Validators'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { EMPTY_UUID, TEXT_LENGTH } from 'src/ui-shared/constants/Constants'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

interface Props {
  laundryGroup: LaundryGroup
}

const getEmptyLaundryGroupNews = (): LaundryGroupNews => {
  const emptyLaundryGroupNews: LaundryGroupNews = {
    id: EMPTY_UUID,
    content: '',
    subject: '',
    date: new Date(),
  }
  return emptyLaundryGroupNews
}

export const LaundryGroupMessagesTab: FC<Props> = ({ laundryGroup }): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupsNewsApi = new LaundryGroupsNewsApi(httpConfiguration)

  // state
  const [laundryGroupNews, setLaundryGroupNews] = useState<LaundryGroupNews>(getEmptyLaundryGroupNews())
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [removeModal, setRemoveModal] = useState<boolean>(false)

  // load data
  useEffect(() => {
    let active = true

    getLaundryGroupNews(active)

    return () => {
      active = false
    }
  }, [])

  const getLaundryGroupNews = (active: boolean) => {
    setErrorMessage(null)

    laundryGroupsNewsApi
      .laundrygroupsLaundryGroupIdNewsGet(laundryGroup.id)
      .then((data) => {
        if (active) {
          setLaundryGroupNews(data)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }

  // handle events
  const postLaundryGroupNews = (laundryGroupNews: LaundryGroupNews, isEmptyNews?: boolean) => {
    setLoading(true)
    setErrorMessage(null)

    laundryGroupsNewsApi
      .laundrygroupsLaundryGroupIdNewsPost(laundryGroup.id, laundryGroupNews)
      .then(() => {
        setLoading(false)
        onSubmitSuccess(isEmptyNews)
        getLaundryGroupNews(true)
      })
      .catch((err) => {
        setLoading(false)
        onSubmitReject(err)
      })
  }

  const onSubmitSuccess = (isEmptyNews?: boolean) => {
    const successMessage = isEmptyNews ? translate('messageCleared') : translate('messageCreated')
    showSnackbar(successMessage, 'success')
  }

  const onSubmitReject = (err: Error | unknown) => {
    const errorMessage = errorMapper(err, translate)
    console.error(errorMessage, err)
    showSnackbar(errorMessage, 'error')
  }

  const submitForm = (laundryGroupNews: LaundryGroupNews) => {
    const hasSubject = Boolean(laundryGroupNews.subject)
    const hasContent = Boolean(laundryGroupNews.content)

    // validate that if we have subject or content we have both
    if ((hasSubject && !hasContent) || (!hasSubject && hasContent)) {
      let errorMessage
      if (!hasSubject) {
        errorMessage = translate('messageTitleRequired')
      } else {
        errorMessage = translate('messageRequired')
      }

      setErrorMessage(errorMessage)
    } else {
      postLaundryGroupNews(laundryGroupNews)
    }
  }

  const onClearMessageButtonHandle = () => {
    setErrorMessage(null)
    setRemoveModal(false)
    const emptyLaundryGroupNews = getEmptyLaundryGroupNews()
    postLaundryGroupNews(emptyLaundryGroupNews, true)
  }

  return (
    <>
      <Box pt={2}>
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
        <LoadingIndicator loading={loading} />

        <Form<LaundryGroupNews>
          initialValues={laundryGroupNews}
          onSubmit={submitForm}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                  <Grid item xs={12}>
                    <TextField
                      label={translate('messageTitle')}
                      type="text"
                      name="subject"
                      fullWidth
                      disabled={submitting}
                      validate={maxChar(TEXT_LENGTH.NEWS_TITLE)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                  <Grid item xs={12}>
                    <TextField
                      label={translate('message')}
                      type="text"
                      name="content"
                      multiline
                      rows={4}
                      fullWidth
                      disabled={submitting}
                      validate={maxChar(TEXT_LENGTH.NEWS_CONTENT)}
                    />
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    size="large"
                    color="primary"
                    className={sharedClasses.ButtonMargin}
                    onClick={() => setRemoveModal(true)}
                    disabled={submitting}
                  >
                    {translate('clearMessage')}
                  </Button>
                  <Button variant="contained" color="primary" size="large" type="submit">
                    {translate('activateMessage')}
                  </Button>
                </Box>
              </form>
            )
          }}
        />
      </Box>

      <ConfirmationModalDialog
        titleKey="clearMessage"
        confirmationKey="button.clear"
        open={removeModal}
        onConfirm={onClearMessageButtonHandle}
        onCancel={() => setRemoveModal(false)}
      >
        {translate('clearMessageConfirm')}
      </ConfirmationModalDialog>
    </>
  )
}
