/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Member,
  MemberReference,
  MemberRolesUpdate,
  OrganizationType,
  PageableMembers,
} from '../models';
import {
    MemberFromJSON,
    MemberToJSON,
    MemberReferenceFromJSON,
    MemberReferenceToJSON,
    MemberRolesUpdateFromJSON,
    MemberRolesUpdateToJSON,
    OrganizationTypeFromJSON,
    OrganizationTypeToJSON,
    PageableMembersFromJSON,
    PageableMembersToJSON,
} from '../models';

export interface MembersGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    id?: string;
    organizationId?: string;
    type?: Array<OrganizationType>;
    backendRole?: string;
    backendUserId?: string;
}

export interface MembersMemberIdDeleteRequest {
    memberId: string;
}

export interface MembersMemberIdGetRequest {
    memberId: string;
}

export interface MembersMemberIdPutRequest {
    memberId: string;
    memberRolesUpdate?: MemberRolesUpdate;
}

export interface MembersRefGetRequest {
    size?: number;
    search?: string;
    type?: Array<OrganizationType>;
}

/**
 * 
 */
export class MembersApi extends runtime.BaseAPI {

    /**
     * List Members
     */
    async membersGetRaw(requestParameters: MembersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableMembers>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.backendRole !== undefined) {
            queryParameters['backendRole'] = requestParameters.backendRole;
        }

        if (requestParameters.backendUserId !== undefined) {
            queryParameters['backendUserId'] = requestParameters.backendUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/members`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableMembersFromJSON(jsonValue));
    }

    /**
     * List Members
     */
    async membersGet(size?: number, page?: number, sort?: string, search?: string, id?: string, organizationId?: string, type?: Array<OrganizationType>, backendRole?: string, backendUserId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableMembers> {
        const response = await this.membersGetRaw({ size: size, page: page, sort: sort, search: search, id: id, organizationId: organizationId, type: type, backendRole: backendRole, backendUserId: backendUserId }, initOverrides);
        return await response.value();
    }

    /**
     * Remove a Member from my Organization
     */
    async membersMemberIdDeleteRaw(requestParameters: MembersMemberIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling membersMemberIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/members/{memberId}`.replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a Member from my Organization
     */
    async membersMemberIdDelete(memberId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.membersMemberIdDeleteRaw({ memberId: memberId }, initOverrides);
    }

    /**
     * Get a Member
     */
    async membersMemberIdGetRaw(requestParameters: MembersMemberIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Member>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling membersMemberIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/members/{memberId}`.replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberFromJSON(jsonValue));
    }

    /**
     * Get a Member
     */
    async membersMemberIdGet(memberId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Member> {
        const response = await this.membersMemberIdGetRaw({ memberId: memberId }, initOverrides);
        return await response.value();
    }

    /**
     * Update Roles of a Member
     */
    async membersMemberIdPutRaw(requestParameters: MembersMemberIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Member>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling membersMemberIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/members/{memberId}`.replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MemberRolesUpdateToJSON(requestParameters.memberRolesUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberFromJSON(jsonValue));
    }

    /**
     * Update Roles of a Member
     */
    async membersMemberIdPut(memberId: string, memberRolesUpdate?: MemberRolesUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Member> {
        const response = await this.membersMemberIdPutRaw({ memberId: memberId, memberRolesUpdate: memberRolesUpdate }, initOverrides);
        return await response.value();
    }

    /**
     * List Member References
     */
    async membersRefGetRaw(requestParameters: MembersRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberReference>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/members/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberReferenceFromJSON));
    }

    /**
     * List Member References
     */
    async membersRefGet(size?: number, search?: string, type?: Array<OrganizationType>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberReference>> {
        const response = await this.membersRefGetRaw({ size: size, search: search, type: type }, initOverrides);
        return await response.value();
    }

}
