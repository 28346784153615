/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UsageChartGroupBy {
    TOTAL = 'TOTAL',
    ORGANIZATION = 'ORGANIZATION',
    LAUNDRY_GROUP = 'LAUNDRY_GROUP',
    LAUNDRY = 'LAUNDRY',
    MACHINE = 'MACHINE',
    MACHINE_CATEGORY = 'MACHINE_CATEGORY',
    LAUNDRY_USER = 'LAUNDRY_USER',
    PAYMENT_TYPE = 'PAYMENT_TYPE'
}


export function UsageChartGroupByFromJSON(json: any): UsageChartGroupBy {
    return UsageChartGroupByFromJSONTyped(json, false);
}

export function UsageChartGroupByFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsageChartGroupBy {
    return json as UsageChartGroupBy;
}

export function UsageChartGroupByToJSON(value?: UsageChartGroupBy | null): any {
    return value as any;
}

