import React, { FC, ReactElement } from 'react'
import { Button } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { LocationOnIcon } from 'src/ui-shared/icons/Icons'

interface Props {
  latitude: number | undefined | null
  longitude: number | undefined | null
}

export const LocationButton: FC<Props> = ({ latitude, longitude }): ReactElement => {
  const translate = useTranslate()

  const openLocationHandler = (): void => {
    const coordinates = `${latitude},${longitude}`
    const url = `https://www.google.com/maps/@${coordinates},15z`
    window.open(url)
  }

  return (
    <Button
      startIcon={<LocationOnIcon />}
      variant="text"
      onClick={openLocationHandler}
      color="primary"
      disabled={latitude && longitude ? false : true}
    >
      {translate('openLocationInGoogleMaps')}
    </Button>
  )
}
