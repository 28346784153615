/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ErrorSeverity {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR'
}


export function ErrorSeverityFromJSON(json: any): ErrorSeverity {
    return ErrorSeverityFromJSONTyped(json, false);
}

export function ErrorSeverityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorSeverity {
    return json as ErrorSeverity;
}

export function ErrorSeverityToJSON(value?: ErrorSeverity | null): any {
    return value as any;
}

