import React, { ReactElement } from 'react'
import { Error, Info, Warning } from '@mui/icons-material'
import { ErrorSeverity } from 'src/service/backend/api'

interface Props {
  errorSeverity?: ErrorSeverity
}

export const ErrorSeverityIcon = ({ errorSeverity }: Props): ReactElement | null => {
  let icon: ReactElement | null = null
  switch (errorSeverity) {
    case ErrorSeverity.ERROR:
      icon = <Error sx={{ color: 'primary.dark' }} />
      break
    case ErrorSeverity.WARNING:
      icon = <Warning sx={{ color: 'primary.dark' }} />
      break
    case ErrorSeverity.INFO:
      icon = <Info sx={{ color: 'primary.dark' }} />
      break
  }
  return icon
}
