import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Grid, Paper, TableCell, TableRow } from '@mui/material'
import { errorMapperReporting } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, LaundryUsersApi, ReportWashMasterApi } from 'src/service/backend/api'
import { DatatransPaymentMethod, ReportTopUpType, TopUp } from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocale } from 'src/service/utils/DateFormatUtils'
import { formatAmountForLocale } from 'src/service/utils/NumberFormatUtils'
import { Data } from 'src/service/view-model/base/Data'
import {
  DATATRANS_PAYMENT_METHOD_OBJECTS,
  getDatatransPaymentMethodName,
} from 'src/service/view-model/base/payment/Datatrans'
import { getUserName } from 'src/service/view-model/laundry-user/LaundryUserUtils'
import { REPORT_TOP_UP_TYPES_OBJECTS, getReportTopUpTypeName } from 'src/service/view-model/topup/TopupViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DataTable } from 'src/ui-shared/table/DataTable'
import { DEFAULT_DATA, HeadCells, TableData, TableSettings, tableSettingsSort } from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { TableFilterAutocompleteAsync } from 'src/ui-shared/table/TableFilterAutocompleteAsync'
import { TableFilterTextField } from 'src/ui-shared/table/TableFilterTextField'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { useUserRegionLocale } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'settledDateTime',
    label: 'settledDate',
  },
  {
    id: 'laundryUser.firstName',
    label: 'laundryUser',
    noSort: true,
  },
  {
    id: 'topUpType',
    label: 'topUpType',
    noSort: true,
  },
  {
    id: 'amount',
    label: 'amount',
  },
  {
    id: 'source.datatransPaymentMethod',
    label: 'datatransPaymentMethod',
  },
  {
    // TODO pst: check if this should be bankingReference
    id: 'source.esrNumber',
    label: 'bankingReference',
  },
]

export interface TopUpTableSettings extends TableSettings {
  orderBy: string
  settledDateTimeFrom?: string
  settledDateTimeTo?: string
  laundryUserId?: string
  reportTopUpType?: ReportTopUpType
  datatransPaymentMethod?: DatatransPaymentMethod
  bankingReference?: string
}

interface Props {
  tableSettings: TopUpTableSettings
  defaultTableSettings: TopUpTableSettings
  setTableSettings: React.Dispatch<React.SetStateAction<TopUpTableSettings>>
}

export const TopUpTable: FC<Props> = ({ tableSettings, setTableSettings, defaultTableSettings }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  const location = useLocation()
  const translate = useTranslate()
  const regionLocale = useUserRegionLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportsApi = new ReportWashMasterApi(httpConfiguration)
  const laundryUsersApi = new LaundryUsersApi(httpConfiguration)

  // state
  const [data, setData] = useState<TableData<TopUp>>(DEFAULT_DATA)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  // load data
  useEffect(() => {
    let active = true

    setErrorMessage(null)

    if (tableSettings.settledDateTimeFrom && tableSettings.settledDateTimeTo) {
      const dateStartTimeFrom = new Date(tableSettings.settledDateTimeFrom)
      const dateStartTimeTo = new Date(tableSettings.settledDateTimeTo)

      setLoading(true)

      reportsApi
        .reportWmTopUpsGet(
          dateStartTimeFrom,
          dateStartTimeTo,
          tableSettings.size,
          tableSettings.page,
          tableSettingsSort(tableSettings),
          undefined,
          tableSettings.reportTopUpType,
          tableSettings.laundryUserId,
          tableSettings.bankingReference
            ? typeof tableSettings.bankingReference === 'string'
              ? tableSettings.bankingReference.trim()
              : tableSettings.bankingReference
            : undefined,
          tableSettings.datatransPaymentMethod,
        )
        .then((data) => {
          if (active) {
            updateTableSettingsFromData(data as Data<any>, tableSettings)
            setTableSettings(tableSettings)

            setData(mapData(data as Data<any>))
            setLoading(false)
          }
        })
        .catch((err) => {
          const errorMessage = errorMapperReporting(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setData(DEFAULT_DATA)
          setLoading(false)
        })
    }

    return () => {
      active = false
    }
  }, [tableSettings])

  // handle events
  const searchLaundryUsers = async (search: string) => {
    const laundryUsers = await laundryUsersApi.laundryusersRefGet(undefined, search)
    const mappedLaundryUsers = laundryUsers.map((user) => {
      return { ...user, name: getUserName(user) }
    })
    return mappedLaundryUsers
  }

  // JSX
  const nonEmptyRows = data.data.map((item, index) => {
    return (
      <TableRow className={tableClasses.tableRow} style={{ cursor: 'auto' }} key={index}>
        <TableCell>{formatDateTimeForLocale(item.settledDate, regionLocale)}</TableCell>
        <TableCell>{getUserName(item.laundryUser)}</TableCell>
        <TableCell>{getReportTopUpTypeName(item.reportTopUpType, translate)}</TableCell>
        <TableCell>{formatAmountForLocale(item.amount, regionLocale, item.currency)}</TableCell>
        <TableCell>
          {item.datatransPaymentMethod ? getDatatransPaymentMethodName(item.datatransPaymentMethod, translate) : '-'}
        </TableCell>
        <TableCell>{item.bankingReference || '-'}</TableCell>
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string) => {
    let filter
    if (headCellId === 'laundryUser.firstName') {
      filter = (
        <TableFilterAutocompleteAsync
          title={translate('filterByLaundryUser')}
          label={translate('laundryUsers')}
          filter={'laundryUserId'}
          labelFieldName="name"
          valueFieldName="id"
          loadOptions={searchLaundryUsers}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          loadAll={false}
        />
      )
    } else if (headCellId === 'topUpType') {
      filter = (
        <TableFilterAutocomplete
          title={translate('topUpType')}
          label={translate('topUpType')}
          filter={'reportTopUpType'}
          labelFieldName="name"
          valueFieldName="id"
          translate={translate}
          options={REPORT_TOP_UP_TYPES_OBJECTS}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'source.datatransPaymentMethod') {
      filter = (
        <TableFilterAutocomplete
          title={translate('datatransPaymentMethod')}
          label={translate('datatransPaymentMethod')}
          filter={'datatransPaymentMethod'}
          labelFieldName="name"
          valueFieldName="id"
          options={DATATRANS_PAYMENT_METHOD_OBJECTS}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'source.esrNumber') {
      filter = (
        <TableFilterTextField
          title={translate('bankingReference')}
          label={translate('bankingReference')}
          filter={'bankingReference'}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          delay={500}
        />
      )
    }
    return filter
  }

  return (
    <>
      <Box mt={3} mb={3}>
        <Grid container spacing={2}>
          <TableDatePickerForm
            key={`${tableSettings.settledDateTimeFrom}${tableSettings.settledDateTimeTo}`}
            firstDatePropName="settledDateTimeFrom"
            secondDatePropName="settledDateTimeTo"
            setTableSettings={setTableSettings}
            tableSettings={tableSettings}
          />
        </Grid>
      </Box>

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <Paper elevation={0}>
        <DataTable
          headCells={headCells}
          data={data}
          nonEmptyRows={nonEmptyRows}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          getFilter={getFilter}
          loading={loading}
          translate={translate}
        />
      </Paper>
    </>
  )
}
