/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarEntryType } from './CalendarEntryType';
import {
    CalendarEntryTypeFromJSON,
    CalendarEntryTypeFromJSONTyped,
    CalendarEntryTypeToJSON,
} from './CalendarEntryType';
import type { LaundryUserReference } from './LaundryUserReference';
import {
    LaundryUserReferenceFromJSON,
    LaundryUserReferenceFromJSONTyped,
    LaundryUserReferenceToJSON,
} from './LaundryUserReference';
import type { MachineReference } from './MachineReference';
import {
    MachineReferenceFromJSON,
    MachineReferenceFromJSONTyped,
    MachineReferenceToJSON,
} from './MachineReference';

/**
 * 
 * @export
 * @interface CalendarEntry
 */
export interface CalendarEntry {
    /**
     * 
     * @type {string}
     * @memberof CalendarEntry
     */
    readonly id: string;
    /**
     * 
     * @type {MachineReference}
     * @memberof CalendarEntry
     */
    machine: MachineReference;
    /**
     * 
     * @type {LaundryUserReference}
     * @memberof CalendarEntry
     */
    laundryUser?: LaundryUserReference;
    /**
     * 
     * @type {CalendarEntryType}
     * @memberof CalendarEntry
     */
    calendarEntryType: CalendarEntryType;
    /**
     * 
     * @type {string}
     * @memberof CalendarEntry
     */
    recurringReservationId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CalendarEntry
     */
    startTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof CalendarEntry
     */
    endTime: Date;
}

/**
 * Check if a given object implements the CalendarEntry interface.
 */
export function instanceOfCalendarEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "machine" in value;
    isInstance = isInstance && "calendarEntryType" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "endTime" in value;

    return isInstance;
}

export function CalendarEntryFromJSON(json: any): CalendarEntry {
    return CalendarEntryFromJSONTyped(json, false);
}

export function CalendarEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'machine': MachineReferenceFromJSON(json['machine']),
        'laundryUser': !exists(json, 'laundryUser') ? undefined : LaundryUserReferenceFromJSON(json['laundryUser']),
        'calendarEntryType': CalendarEntryTypeFromJSON(json['calendarEntryType']),
        'recurringReservationId': !exists(json, 'recurringReservationId') ? undefined : json['recurringReservationId'],
        'startTime': (new Date(json['startTime'])),
        'endTime': (new Date(json['endTime'])),
    };
}

export function CalendarEntryToJSON(value?: CalendarEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machine': MachineReferenceToJSON(value.machine),
        'laundryUser': LaundryUserReferenceToJSON(value.laundryUser),
        'calendarEntryType': CalendarEntryTypeToJSON(value.calendarEntryType),
        'recurringReservationId': value.recurringReservationId,
        'startTime': (value.startTime.toISOString()),
        'endTime': (value.endTime.toISOString()),
    };
}

