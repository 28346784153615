/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportTopUpType {
    TOP_UP_PREPAID_BY_ESR = 'TOP_UP_PREPAID_BY_ESR',
    TOP_UP_PREPAID_BY_DATATRANS = 'TOP_UP_PREPAID_BY_DATATRANS',
    TOP_UP_PREPAID_BY_OPERATOR = 'TOP_UP_PREPAID_BY_OPERATOR',
    TOP_UP_PREPAID_BY_SEPA = 'TOP_UP_PREPAID_BY_SEPA',
    TOP_UP_PREPAID_BY_SWISS_QR = 'TOP_UP_PREPAID_BY_SWISS_QR',
    UNSUCCESSFUL_TOP_UP_BY_ESR = 'UNSUCCESSFUL_TOP_UP_BY_ESR',
    UNSUCCESSFUL_TOP_UP_BY_SEPA = 'UNSUCCESSFUL_TOP_UP_BY_SEPA',
    UNSUCCESSFUL_TOP_UP_SWISS_QR = 'UNSUCCESSFUL_TOP_UP_SWISS_QR',
    REFUND_PREPAID_BY_DATATRANS = 'REFUND_PREPAID_BY_DATATRANS',
    REFUND_PREPAID_BY_OPERATOR = 'REFUND_PREPAID_BY_OPERATOR',
    REFUND_PREPAID_BY_BANKTRANSFER = 'REFUND_PREPAID_BY_BANKTRANSFER',
    MANUAL_ADJUSTMENT = 'MANUAL_ADJUSTMENT'
}


export function ReportTopUpTypeFromJSON(json: any): ReportTopUpType {
    return ReportTopUpTypeFromJSONTyped(json, false);
}

export function ReportTopUpTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportTopUpType {
    return json as ReportTopUpType;
}

export function ReportTopUpTypeToJSON(value?: ReportTopUpType | null): any {
    return value as any;
}

