/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';
import type { ManufacturerModelReference } from './ManufacturerModelReference';
import {
    ManufacturerModelReferenceFromJSON,
    ManufacturerModelReferenceFromJSONTyped,
    ManufacturerModelReferenceToJSON,
} from './ManufacturerModelReference';
import type { ManufacturerReference } from './ManufacturerReference';
import {
    ManufacturerReferenceFromJSON,
    ManufacturerReferenceFromJSONTyped,
    ManufacturerReferenceToJSON,
} from './ManufacturerReference';

/**
 * 
 * @export
 * @interface ManualMachineInfo
 */
export interface ManualMachineInfo {
    /**
     * 
     * @type {string}
     * @memberof ManualMachineInfo
     */
    name: string;
    /**
     * 
     * @type {ManufacturerReference}
     * @memberof ManualMachineInfo
     */
    manufacturer: ManufacturerReference;
    /**
     * 
     * @type {ManufacturerModelReference}
     * @memberof ManualMachineInfo
     */
    manufacturerModel: ManufacturerModelReference;
    /**
     * 
     * @type {MachineType}
     * @memberof ManualMachineInfo
     */
    machineType: MachineType;
    /**
     * 
     * @type {string}
     * @memberof ManualMachineInfo
     */
    serialNumber: string;
}

/**
 * Check if a given object implements the ManualMachineInfo interface.
 */
export function instanceOfManualMachineInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "manufacturer" in value;
    isInstance = isInstance && "manufacturerModel" in value;
    isInstance = isInstance && "machineType" in value;
    isInstance = isInstance && "serialNumber" in value;

    return isInstance;
}

export function ManualMachineInfoFromJSON(json: any): ManualMachineInfo {
    return ManualMachineInfoFromJSONTyped(json, false);
}

export function ManualMachineInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualMachineInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'manufacturer': ManufacturerReferenceFromJSON(json['manufacturer']),
        'manufacturerModel': ManufacturerModelReferenceFromJSON(json['manufacturerModel']),
        'machineType': MachineTypeFromJSON(json['machineType']),
        'serialNumber': json['serialNumber'],
    };
}

export function ManualMachineInfoToJSON(value?: ManualMachineInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'manufacturer': ManufacturerReferenceToJSON(value.manufacturer),
        'manufacturerModel': ManufacturerModelReferenceToJSON(value.manufacturerModel),
        'machineType': MachineTypeToJSON(value.machineType),
        'serialNumber': value.serialNumber,
    };
}

