/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';

/**
 * 
 * @export
 * @interface ProgramsEditableMachineType
 */
export interface ProgramsEditableMachineType {
    /**
     * 
     * @type {MachineType}
     * @memberof ProgramsEditableMachineType
     */
    machineType: MachineType;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProgramsEditableMachineType
     */
    drumSizes: Array<number>;
    /**
     * Unit of Weight ('kg' | 'lbs')
     * @type {string}
     * @memberof ProgramsEditableMachineType
     */
    drumSizeUnit?: string;
}

/**
 * Check if a given object implements the ProgramsEditableMachineType interface.
 */
export function instanceOfProgramsEditableMachineType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "machineType" in value;
    isInstance = isInstance && "drumSizes" in value;

    return isInstance;
}

export function ProgramsEditableMachineTypeFromJSON(json: any): ProgramsEditableMachineType {
    return ProgramsEditableMachineTypeFromJSONTyped(json, false);
}

export function ProgramsEditableMachineTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramsEditableMachineType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machineType': MachineTypeFromJSON(json['machineType']),
        'drumSizes': json['drumSizes'],
        'drumSizeUnit': !exists(json, 'drumSizeUnit') ? undefined : json['drumSizeUnit'],
    };
}

export function ProgramsEditableMachineTypeToJSON(value?: ProgramsEditableMachineType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machineType': MachineTypeToJSON(value.machineType),
        'drumSizes': value.drumSizes,
        'drumSizeUnit': value.drumSizeUnit,
    };
}

