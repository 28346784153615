/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineType } from './MachineType';
import {
    MachineTypeFromJSON,
    MachineTypeFromJSONTyped,
    MachineTypeToJSON,
} from './MachineType';

/**
 * 
 * @export
 * @interface DashboardAssetInfo
 */
export interface DashboardAssetInfo {
    /**
     * 
     * @type {MachineType}
     * @memberof DashboardAssetInfo
     */
    type: MachineType;
    /**
     * total count of type
     * @type {number}
     * @memberof DashboardAssetInfo
     */
    total: number;
    /**
     * total count of open Issues for type
     * @type {number}
     * @memberof DashboardAssetInfo
     */
    openIssues: number;
}

/**
 * Check if a given object implements the DashboardAssetInfo interface.
 */
export function instanceOfDashboardAssetInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "openIssues" in value;

    return isInstance;
}

export function DashboardAssetInfoFromJSON(json: any): DashboardAssetInfo {
    return DashboardAssetInfoFromJSONTyped(json, false);
}

export function DashboardAssetInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardAssetInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': MachineTypeFromJSON(json['type']),
        'total': json['total'],
        'openIssues': json['openIssues'],
    };
}

export function DashboardAssetInfoToJSON(value?: DashboardAssetInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': MachineTypeToJSON(value.type),
        'total': value.total,
        'openIssues': value.openIssues,
    };
}

