import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Path, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Alert, Box, Divider, ListItemIcon, MenuItem, Paper } from '@mui/material'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import {
  AutoModeOutlined,
  ContentCopyOutlined,
  CopyAll,
  DeleteOutlineOutlined,
  EditOutlined,
  GetApp,
  RestartAlt,
  SettingsOutlined,
} from '@mui/icons-material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganizationMandatory } from 'src/organization/ActiveOrganizationProvider'
import { NavigateState } from 'src/routing/Routing'
import {
  Configuration,
  MachineType,
  OrganizationReference,
  Permission,
  Program,
  ProgramCopyRequest,
  ProgramDetail,
  ProgramMetaData,
  ProgramModuleTemplate,
  ProgramType,
  ProgramsApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  DRAFT_SAVE_INTERVAL_MS,
  getDraftFromStorage,
  getDraftStatus,
  removeDraftFromStorage,
  saveDraftToStorage,
} from 'src/service/local-storage/DraftStorageService'
import { getDeepKeys, getEnumFromString } from 'src/service/utils/CommonUtils'
import {
  hideOrShowGroupsForAllSelectOrBooleanFieldsForProgram,
  modifyGroupFieldsAddDefaultHiddenValueForProgram,
  modifyGroupFieldsAddDefaultHiddenValueForTemplate,
} from 'src/service/view-model/program/ProgramSettingVisibilityDefaultUtils'
import { addStepNumbersToModules, stringifyProgramFormErrors } from 'src/service/view-model/program/ProgramUtils'
import { programValidationResolver } from 'src/service/view-model/program/ProgramValidation'
import {
  getProgramIndexNameAndTemperature,
  getProgramNameAndTemperature,
} from 'src/service/view-model/program/ProgramViewModel'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { MenuButton } from 'src/ui-shared/base/button/MenuButton'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { HookForm } from 'src/ui-shared/base/form/control-hook-form/HookForm'
import { HookFormFieldError } from 'src/ui-shared/base/form/validation/HookFormFieldError'
import { WithMandatoryActiveOrganization } from 'src/ui-shared/base/hoc/WithMandatoryActiveOrganization'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { useInterval } from 'src/ui-shared/base/hooks/useInterval'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { DraftModalDialog } from 'src/ui-shared/base/model-dialog/DraftModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { StyledTab } from 'src/ui-shared/base/tab-panel/StyledTab'
import { StyledTabs } from 'src/ui-shared/base/tab-panel/StyledTabs'
import { TabPanel } from 'src/ui-shared/base/tab-panel/TabPanel'
import { EMPTY_UUID, EditMode } from 'src/ui-shared/constants/Constants'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { SelectOrganizationDialog } from 'src/ui/layout/organization-selector/SelectOrganizationDialog'
import { ProgramDetailsTab } from 'src/ui/page/es/program/details/ProgramDetailsTab'
import { ProgramErrorsModal } from 'src/ui/page/es/program/details/ProgramErrorsModal'
import { ProgramExportModal } from 'src/ui/page/es/program/details/ProgramExportModal'
import { ProgramModulesTab } from 'src/ui/page/es/program/details/ProgramModulesTab'
import { ProgramSettingsTab } from 'src/ui/page/es/program/details/ProgramSettingsTab'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

interface Props {
  mode?: EditMode
}

export const ProgramDetailsPage: FC<Props> = WithMandatoryActiveOrganization(({ mode = 'view' }): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const navigate = useNavigate()
  const location = useLocation()
  const user = useUser()
  const appId = useAppId()

  const activeOrganization = useActiveOrganizationMandatory()
  const organizationId = activeOrganization.id
  const { programId } = useParams()
  const { machineTypeParam, tabNameParam } = useRequiredParams(['machineTypeParam', 'tabNameParam'])
  const tabName = getEnumFromString(tabNameParam, ProgramDetailsTab)
  const machineType = getEnumFromString(machineTypeParam, MachineType)

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programsApi = new ProgramsApi(httpConfiguration)

  // state
  const [activeTab, setActiveTab] = useState<ProgramDetailsTab>(ProgramDetailsTab.SETTINGS)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [availableModulesAndBlocks, setAvailableModulesAndBlocks] = useState<ProgramModuleTemplate>({})
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const [resetModal, setResetModal] = useState<boolean>(false)
  const [selectOrganizationModal, setSelectOrganizationModal] = useState<boolean>(false)
  const [openErrorsModal, setOpenErrorsModal] = useState<boolean>(false)
  const [errorsModalText, setErrorsModalText] = useState<string>('')
  const [exportModal, setExportModal] = useState<boolean>(false)
  const [draftProgramModal, setDraftProgramModal] = useState<boolean>(false)
  const [draftJSON, setDraftJSON] = useState<string>('')

  const programForm = useForm<Program>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: programValidationResolver,
  })

  // derived state
  const program = programForm.getValues()
  const draftProgramStorageKey = `program-${programId ?? EMPTY_UUID}-${mode}`

  // usePrompt(translate('notification.form.unsaved.changes'), isFormDirty() && mode !== 'view')
  useHotKeysForm()

  const hasProgramWritePermission = hasPermission(user, Permission.EASYSET_PROGRAM_WRITE)

  // load data
  useEffect(() => {
    fetchProgramAndModules()

    setActiveTab(tabName)
  }, [programId, activeOrganization])

  const saveDraftCallback = () => {
    if (mode !== 'view') {
      handleSaveDraft()
    }
  }

  useInterval(saveDraftCallback, DRAFT_SAVE_INTERVAL_MS)

  const fetchProgramAndModules = () => {
    setErrorMessage(null)
    setLoading(true)

    const loadProgramPromise = mode === 'edit' || mode === 'view' ? fetchProgram(programId!) : fetchTemplate(programId)

    // don't fetch modules and templates on view
    if (mode !== 'view') {
      programsApi
        .programsModuleTemplateGet(organizationId, machineType)
        .then((data) => {
          modifyGroupFieldsAddDefaultHiddenValueForTemplate(data)
          setAvailableModulesAndBlocks(data)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
        })
    }

    loadProgramPromise
      .then((data) => {
        setProgramInForm(data)
        setLoading(false)

        // maybe load draft from storage if the form is in edit mode
        if (mode !== 'view') {
          handleReadFromStorage()
        }
      })
      .catch((err) => {
        setLoading(false)
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }

  const fetchProgram = (aProgramId: string): Promise<Program> => {
    return programsApi.programsProgramIdGet(aProgramId, organizationId, machineType)
  }

  const fetchTemplate = (aProgramId?: string): Promise<Program> => {
    return programsApi.programsTemplateGet(organizationId, machineType, aProgramId)
  }

  const setProgramInForm = (program: Program) => {
    modifyGroupFieldsAddDefaultHiddenValueForProgram(program)
    hideOrShowGroupsForAllSelectOrBooleanFieldsForProgram(program)
    programForm.reset(program)
  }

  // handle events
  const handleTabChange = (event: React.SyntheticEvent, newValue: ProgramDetailsTab) => {
    let pathToNavigate = `/${appId}/programs/${machineType}/${programId}/${mode}/${newValue}`
    if (mode === 'create') {
      pathToNavigate = `/${appId}/programs/${machineType}/${mode}/${newValue}`
    }

    // NOTE: used from the browser because navigate triggers usePrompt
    window.history.replaceState(null, '', pathToNavigate)
    // navigate(pathToNavigate, { replace: true, state: state })
    setActiveTab(newValue)
  }

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/programs/${machineType}`)
  }

  const navigateTo = (mode: Extract<EditMode, 'view' | 'edit' | 'copy'>) => {
    // if the program is standard go the modules tab
    let tabToUse = activeTab
    if (program.metaData.programType === ProgramType.STANDARD) {
      tabToUse = ProgramDetailsTab.MODULES
    }

    navigate(`/${appId}/programs/${machineType}/${programId}/${mode}/${tabToUse}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleDeleteModalOpen = () => {
    setRemoveModal(true)
  }

  const handleDeleteModalClose = () => {
    setRemoveModal(false)
  }

  const handleDeleteProgram = () => {
    const programForRemovalMetadata = program.metaData

    programsApi
      .programsProgramIdDelete(programForRemovalMetadata.id, organizationId, programForRemovalMetadata.machineType)
      .then(() => {
        handleDeleteModalClose()
        navigateBack()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
        handleDeleteModalClose()
      })
  }

  const handleReadFromStorage = () => {
    const draftStatus = getDraftStatus(draftProgramStorageKey)
    if (draftStatus.hasDraft) {
      if (!draftStatus.isExpired) {
        setDraftProgramModal(true)
      } else {
        // draft is expired, remove it
        removeDraftFromStorage(draftProgramStorageKey)
      }
    }
  }

  const handleSaveDraft = () => {
    if (mode !== 'view' && isFormDirty()) {
      const currentProgram = programForm.getValues()
      const newDraftJSON = saveDraftToStorage<Program>(draftProgramStorageKey, currentProgram, draftJSON, user.username)

      if (newDraftJSON) {
        setDraftJSON(newDraftJSON)
      }
    }
  }

  const handleApplyDraft = () => {
    const draft = getDraftFromStorage<Program>(draftProgramStorageKey, true)
    const draftPayload = draft?.payload

    if (draftPayload && typeof draftPayload === 'object') {
      setProgramInForm(draftPayload)
    }

    handleDraftModalClose()
  }

  const handleDiscardDraft = () => {
    removeDraftFromStorage(draftProgramStorageKey)

    handleDraftModalClose()
  }

  const handleDraftModalClose = () => {
    setDraftProgramModal(false)
  }

  const handleResetModalOpen = () => {
    setResetModal(true)
  }

  const handleResetModalClose = () => {
    setResetModal(false)
  }

  const handleResetProgram = () => {
    const programForResetMetadata = program.metaData

    programsApi
      .programsProgramIdDelete(programForResetMetadata.id, organizationId, programForResetMetadata.machineType)
      .then(() => {
        fetchProgramAndModules()
        showSnackbar(translate('programResetSuccess'), 'success')
        handleResetModalClose()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
        handleResetModalClose()
      })
  }

  const handleSelectModalOpen = () => {
    setSelectOrganizationModal(true)
  }

  const handleSelectModalClose = () => {
    setSelectOrganizationModal(false)
  }

  const handleExportModalOpen = () => {
    setExportModal(true)
  }

  const handleExportModalClose = () => {
    setExportModal(false)
  }

  const handleCopyProgramToOrganization = (organization?: OrganizationReference) => {
    if (organization) {
      const programToBeCopied = program.metaData

      const programCopyRequest: ProgramCopyRequest = {
        targetOrganizationId: organization.id,
        programIds: [programToBeCopied.id],
      }

      return programsApi
        .programsCopyPost(organizationId, machineType, programCopyRequest)
        .then(() => {
          handleSelectModalClose()
          showSnackbar(translate('programCopySuccessful'), 'success')
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
          return err
        })
    }
  }

  function isFormDirty() {
    const dirtyFields = programForm.formState.dirtyFields
    return Object.keys(dirtyFields).length > 0
  }

  const resetDirtyForm = () => {
    programForm.reset(undefined, {
      keepValues: true,
      keepIsSubmitted: true,
      keepSubmitCount: true,
    })
  }

  const submitProgram = (program: Program) => {
    let newProgram = program

    if (mode === 'edit') {
      const programId = program.metaData.id
      return programsApi.programsProgramIdPut(programId, organizationId, newProgram.metaData.machineType, newProgram)
    }

    if (mode === 'copy') {
      const newProgramMetadata: ProgramMetaData = {
        ...program.metaData,
        id: '',
        lastModified: undefined,
      }

      newProgram = {
        ...program,
        metaData: newProgramMetadata,
      }
    }

    // new program
    return programsApi.programsPost(organizationId, newProgram)
  }

  const onSubmit: SubmitHandler<Program> = (data) => {
    const program = addStepNumbersToModules(data)

    setLoading(true)
    resetDirtyForm()

    return submitProgram(program)
      .then((program) => {
        setLoading(false)
        try {
          handleDiscardDraft()
        } catch (e) {
          console.error('Error discarding draft', e)
        }
        // navigate to details screen for the created/updated program
        navigate(`/${appId}/programs/${machineType}/${program.metaData.id}/view/${activeTab}`)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setLoading(false)
        showSnackbar(errorMessage, 'error')
      })
  }

  const onError: SubmitErrorHandler<ProgramDetail> | undefined = (errors) => {
    showErrors(errors as Record<string, HookFormFieldError>)

    // after submit form and changing an invalid field, the error stayed even if the field is valid (this is a fix).
    programForm.clearErrors()
    programForm.trigger(getFieldsForValidation() as any)
  }

  const getFieldsForValidation = () => {
    const triggerValues: Path<ProgramDetail>[] = getDeepKeys(program) as Path<ProgramDetail>[]
    return triggerValues
  }

  const showErrors = (errors: Record<string, HookFormFieldError>) => {
    const errorsText = stringifyProgramFormErrors(errors, translate)

    setErrorsModalText(errorsText)
    setOpenErrorsModal(true)
  }

  // render
  let programSettings = null
  let programModules = null

  if (programForm.formState.defaultValues) {
    programModules = (
      <ProgramModulesTab
        loading={loading}
        mode={mode}
        programForm={programForm}
        availableModulesAndBlocks={availableModulesAndBlocks}
      />
    )
    programSettings = <ProgramSettingsTab loading={loading} mode={mode} programForm={programForm} />
  }

  const deleteOrResetButton = (): ReactElement => {
    if (programForm.formState.defaultValues) {
      const programType = program.metaData.programType

      if (programType === ProgramType.CUSTOM) {
        return (
          <MenuItem onClick={handleDeleteModalOpen}>
            <ListItemIcon>
              <DeleteOutlineOutlined fontSize="small" />
            </ListItemIcon>
            {translate('button.delete')}
          </MenuItem>
        )
      } else {
        return (
          <MenuItem onClick={handleResetModalOpen}>
            <ListItemIcon>
              <RestartAlt fontSize="small" />
            </ListItemIcon>
            {translate('button.resetToDefault')}
          </MenuItem>
        )
      }
    }
    return <></>
  }

  const getActionButtons = (): ReactElement => {
    let actionButtons = <></>

    if (mode === 'view') {
      actionButtons = (
        <>
          {hasProgramWritePermission && (
            <ListingButton
              id="editButton"
              onClick={() => navigateTo('edit')}
              variant="outlined"
              color="primary"
              startIcon={<EditOutlined />}
            >
              {translate(
                program.metaData.programType === ProgramType.STANDARD ? 'editLiquidDetergentSteps' : 'button.edit',
              )}
            </ListingButton>
          )}
          <MenuButton>
            {hasProgramWritePermission && (
              <MenuItem onClick={() => navigateTo('copy')}>
                <ListItemIcon>
                  <ContentCopyOutlined fontSize="small" />
                </ListItemIcon>
                {translate('copy')}
              </MenuItem>
            )}

            {hasProgramWritePermission && deleteOrResetButton()}

            <MenuItem onClick={handleExportModalOpen}>
              <ListItemIcon>
                <GetApp fontSize={'small'} />
              </ListItemIcon>
              {translate('export')}
            </MenuItem>

            {hasProgramWritePermission && (
              <MenuItem onClick={handleSelectModalOpen}>
                <ListItemIcon>
                  <CopyAll fontSize="small" />
                </ListItemIcon>
                {translate('copyToOrganization')}
              </MenuItem>
            )}
          </MenuButton>
        </>
      )
    } else {
      actionButtons = (
        <>
          <ListingButton type="submit" id="submitButton" variant="outlined" color="primary">
            {translate('button.save')}
          </ListingButton>
        </>
      )
    }

    return actionButtons
  }

  // render
  const renderTitle = (): string => {
    let title = ''
    if (mode === 'create') {
      title = translate('createNewProgram')
    } else if (mode === 'copy') {
      title = translate('createNewProgramCopy')
    } else {
      title = getProgramIndexNameAndTemperature(programForm.formState.defaultValues as Program) || ''
    }
    return title
  }

  return (
    <>
      <HookForm onSubmit={programForm.handleSubmit(onSubmit, onError)}>
        <ScreenLayout
          title={renderTitle()}
          onBack={navigateBack}
          actionsWidth={30}
          actions={programForm.formState.defaultValues && getActionButtons()}
        >
          <LoadingIndicator loading={loading} />

          <Paper elevation={0}>
            {mode === 'copy' ? (
              <Box mb={1}>
                <Alert severity="warning">{translate('programCopyOverwriteWarning')}</Alert>
              </Box>
            ) : null}
            <Grid container className={sharedClasses.TabsContainer}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <StyledTabs
                  value={activeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  onChange={handleTabChange}
                >
                  <StyledTab
                    icon={<SettingsOutlined />}
                    iconPosition={'start'}
                    label={<Hidden lgDown>{translate('settings')}</Hidden>}
                    value={ProgramDetailsTab.SETTINGS}
                  />
                  <StyledTab
                    icon={<AutoModeOutlined />}
                    iconPosition={'start'}
                    label={<Hidden lgDown>{translate('modules')}</Hidden>}
                    value={ProgramDetailsTab.MODULES}
                  />
                </StyledTabs>
              </Grid>
            </Grid>
            <Divider />
            {errorMessage ? (
              <ErrorMessage message={errorMessage} />
            ) : (
              <>
                <TabPanel value={activeTab} index={ProgramDetailsTab.SETTINGS}>
                  {programSettings}
                </TabPanel>
                <TabPanel value={activeTab} index={ProgramDetailsTab.MODULES}>
                  {programModules}
                </TabPanel>
              </>
            )}
          </Paper>
        </ScreenLayout>
      </HookForm>

      {openErrorsModal && (
        <ProgramErrorsModal
          errorsText={errorsModalText}
          openDialog={openErrorsModal}
          closeDialog={() => setOpenErrorsModal(false)}
        />
      )}
      {removeModal && (
        <ConfirmationModalDialog
          titleKey="deleteProgram"
          confirmationKey="button.delete"
          open={true}
          onConfirm={handleDeleteProgram}
          onCancel={handleDeleteModalClose}
        >
          {translate('deleteConfirm', getProgramNameAndTemperature(program))}
        </ConfirmationModalDialog>
      )}
      {resetModal && (
        <ConfirmationModalDialog
          titleKey="resetProgramSettings"
          confirmationKey="button.resetToDefault"
          open={true}
          onConfirm={handleResetProgram}
          onCancel={handleResetModalClose}
        >
          {translate('resetProgramConfirm', getProgramNameAndTemperature(program))}
        </ConfirmationModalDialog>
      )}
      {draftProgramModal && (
        <DraftModalDialog
          open={true}
          onCancelDraft={handleDraftModalClose}
          onConfirmDraft={handleApplyDraft}
          onDiscardDraft={handleDiscardDraft}
        />
      )}
      {selectOrganizationModal && (
        <SelectOrganizationDialog
          open={true}
          setOpen={handleSelectModalClose}
          disabledOrganization={activeOrganization}
          onConfirm={handleCopyProgramToOrganization}
          confirmText="copyProgram"
          alertMessage="copyProgramWarning"
        />
      )}
      {exportModal && (
        <ProgramExportModal
          title="exportProgram"
          open={true}
          machineType={machineType}
          selectedProgramIds={programId ? [programId] : []}
          handleClose={handleExportModalClose}
        />
      )}
    </>
  )
})
