import React, { ReactElement, useContext, useState } from 'react'
import { Box, Button, Link, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, InvitationAcceptBody, InvitationsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { useUser } from 'src/user/UserContext'

export const InvitationAcceptPage = (): ReactElement => {
  const { token } = useRequiredParams(['token'])

  const translate = useTranslate()
  const user = useUser()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const invitationsApi = new InvitationsApi(httpConfiguration)

  // state
  const [loading, setLoading] = useState(false)
  const [accepted, setAccepted] = useState(false)

  // derived state
  const validInvitation = user.id

  // data
  const doAccept = () => {
    setLoading(true)

    const body: InvitationAcceptBody = { token: token }

    invitationsApi
      .invitationsAcceptPost(body)
      .then(() => {
        setLoading(false)
        // const info = getSubmitResult(translate('invitationAcceptSuccess'), 'success')
        // setOpenSnackbar(true)
        // setSubmitResult(info)
        setAccepted(true)
        // redirect to home after 1 seconds
        setTimeout(() => {
          window.location.replace('/')
        }, 2000)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setLoading(false)
        showSnackbar(errorMessage, 'error')
      })
  }

  // events
  const handleAccept = () => {
    doAccept()
  }

  return (
    <ScreenLayout title={translate('invitationAccept')}>
      {validInvitation ? (
        <>
          <Box mb={2}>
            <Typography variant="h6">{translate('invitationAcceptIntroTitle')}</Typography>
          </Box>
          <p>{translate('invitationAcceptIntroMessage')}</p>
          <Box pb={3} pt={3} pl={5}>
            <Button variant="contained" color="primary" onClick={handleAccept} disabled={loading || accepted}>
              {translate('button.accept')}
            </Button>
          </Box>

          {accepted ? (
            <>
              <Alert severity="success">{translate('invitationAcceptSuccess')}</Alert>
              {/* <p>{translate('redirectingPleaseWait')}</p> */}
            </>
          ) : (
            <p>
              {translate('invitationAcceptFooter') + ' '}
              <Link href="https://www.schulthess.ch">{translate('visitSMAG')}</Link>
              {' ' + translate('findOutMore')}
            </p>
          )}
        </>
      ) : (
        <ErrorMessage message={translate('client-error-not-found')} />
      )}
    </ScreenLayout>
  )
}
