import { AppId } from 'src/app/AppId'
import { EASY_SET_ROUTES } from 'src/routing/EsRoutes'
import { RoutesList } from 'src/routing/Routing'
import { SERVICE_MASTER_ROUTES } from 'src/routing/SmRoutes'
import { WASHMASTER_ROUTES } from 'src/routing/WmRoutes'
import { hasPermission, isAdmin } from 'src/user/RoleCheck'
import { User } from 'src/user/User'

export const WM_PATH = '/wm'

export const SM_PATH = '/sm'

export const ES_PATH = '/es'

export const getRoutesForApp = (appId: AppId): RoutesList => {
  let routes
  switch (appId) {
    case AppId.WASH_MASTER:
      routes = WASHMASTER_ROUTES
      break
    case AppId.SERVICE_MASTER:
      routes = SERVICE_MASTER_ROUTES
      break
    case AppId.EASY_SET:
      routes = EASY_SET_ROUTES
      break
    default:
      throw new Error(`No routes for appId '${appId}'`)
  }
  return routes
}

export const isPathAllowedForUser = (path: string, user: User, appId: AppId): boolean => {
  const routesForApp = getRoutesForApp(appId)
  const routeKeyMatch = Object.keys(routesForApp).find((key) => routesForApp[key].path === path)

  if (!routeKeyMatch) {
    return false
  }

  const route = routesForApp[routeKeyMatch]

  if (route.adminOnly && !isAdmin(user)) {
    return false
  }

  const routePermission = route.permission
  if (!routePermission) {
    return true
  }

  return hasPermission(user, routePermission)
}
