import { TranslateFunction } from 'src/i18n/useMessageSource'
import { Program, ProgramInstallationStatus, ProgramType } from 'src/service/backend/api'
import { formatTemperature } from 'src/service/utils/NumberFormatUtils'
import { IdAndName } from 'src/service/view-model/base/IdAndName'
import { getReferenceName } from 'src/service/view-model/base/Reference.utils'

export type ProgramTypeFilter = ProgramType | 'all'

export interface ProgramOptionObject {
  type: string
  name: string
}

export const PROGRAM_TYPES: ProgramOptionObject[] = [
  { type: ProgramType.CUSTOM, name: 'programType.custom' },
  { type: ProgramType.STANDARD, name: 'programType.standard' },
]

export const PROGRAM_INSTALLATION_STATUS: ProgramOptionObject[] = [
  { type: ProgramInstallationStatus.IDLE, name: 'idle' },
  { type: ProgramInstallationStatus.DOWNLOAD_PENDING, name: 'downloadPending' },
  { type: ProgramInstallationStatus.DOWNLOAD_IN_PROGRESS, name: 'downloadInProgress' },
  { type: ProgramInstallationStatus.INSTALLATION_PENDING, name: 'installationPending' },
  { type: ProgramInstallationStatus.INSTALLATION_IN_PROGRESS, name: 'installationInProgress' },
  { type: ProgramInstallationStatus.INSTALLATION_SUCCESSFUL, name: 'installationSuccessful' },
  { type: ProgramInstallationStatus.INSTALLATION_ERROR, name: 'installationError' },
  { type: ProgramInstallationStatus.INCOMPATIBLE, name: 'incompatible' },
]

export const PROGRAM_MODES: IdAndName[] = [
  { id: 'normal', name: 'programMode.normal' },
  { id: 'special', name: 'programMode.special' },
  { id: 'express', name: 'programMode.express' },
  { id: 'prewash', name: 'programMode.prewash' },
  { id: 'antiBac', name: 'programMode.antiBac' },
  { id: 'intensive', name: 'programMode.intensive' },
  { id: 'disinfectionNormal', name: 'programMode.disinfectionNormal' },
  { id: 'disinfection', name: 'programMode.disinfection' },
  { id: 'disinfectionPrewash', name: 'programMode.disinfectionPrewash' },
  { id: 'eco', name: 'programMode.eco' },
]

export const getProgramTypeName = (programType: string | undefined, translate: TranslateFunction): string => {
  return getReferenceName(PROGRAM_TYPES, translate, programType, 'type')
}

export const getProgramInstallationStatus = (
  installationStatus: string | undefined,
  translate: TranslateFunction,
): string => {
  if (!installationStatus) {
    return ''
  }
  const programTypeObject = PROGRAM_INSTALLATION_STATUS.find((program) => program.type === installationStatus)
  return programTypeObject ? translate(programTypeObject.name) : installationStatus
}

export const getProgramModesTranslated = (translate: TranslateFunction): string[] => {
  return PROGRAM_MODES.map((m) => translate(m.name))
}

export const getProgramTemperatureLabel = (value?: number | null, unit?: string | null): string => {
  const temperatureUnit = unit ?? ''
  return value ? value + temperatureUnit : ''
}

export const getProgramNameAndTemperature = (program?: Program | null): string | undefined => {
  const programMetadata = program?.metaData
  let text: string | undefined = undefined

  if (programMetadata) {
    text = programMetadata.programName

    if (programMetadata.temperature != null) {
      text = `${programMetadata.programName} ${formatTemperature(
        programMetadata.temperature,
        programMetadata.temperatureUnit,
      )}`
    }
  }

  return text
}

export const getProgramIndexNameAndTemperature = (program?: Program | null): string | undefined => {
  const programMetadata = program?.metaData
  return programMetadata
    ? '[ ' + programMetadata.programIndex + ' ] ' + getProgramNameAndTemperature(program)
    : undefined
}
