import React, { FC, ReactElement } from 'react'
import { Icon, Typography, styled } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { FormAccordionSummary } from 'src/ui-shared/base/accordion/FormAccordion'
import { FilterListIcon } from 'src/ui-shared/icons/Icons'

const StyledIcon = styled(Icon)(({ theme }) => ({
  borderRadius: '50%',
  alignItems: 'center',
  verticalAlign: 'center',
  display: 'inline-flex',
  background: '#ddd',
  color: theme.palette.primary.main,
}))

interface Props {
  activeFilter?: boolean
}

export const FormAccordionSummaryDataFilter: FC<Props> = ({ activeFilter = false }): ReactElement => {
  const translate = useTranslate()
  return (
    <FormAccordionSummary>
      <Typography variant="subtitle1">{translate('dataFilter')}</Typography>

      {activeFilter ? (
        <StyledIcon
          sx={{
            ml: 1,
            mr: 1,
          }}
        >
          <FilterListIcon />
        </StyledIcon>
      ) : null}
    </FormAccordionSummary>
  )
}
