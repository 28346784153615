import {
  Currency,
  DatatransPaymentMethod,
  ReportTopUpType,
  TopUpChartFactType,
  TopUpChartGroupBy,
} from 'src/service/backend/api'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'
import { ChartSettings, DEFAULT_CHART_SETTINGS } from 'src/service/view-model/base/chart/ChartViewModel'

export interface TopUpChartSettings extends ChartSettings {
  groupBy: TopUpChartGroupBy
  factType: TopUpChartFactType
  currency?: Currency
  topUpType?: ReportTopUpType
  dataTransPaymentMethod?: DatatransPaymentMethod
  laundryUserId?: string
}

export const DEFAULT_TOPUP_CHART_SETTINGS: TopUpChartSettings = {
  ...DEFAULT_CHART_SETTINGS,
  groupBy: TopUpChartGroupBy.TOPUP_TYPE,
  factType: TopUpChartFactType.COUNT,
}

export interface TopUpChartGroupByObject {
  id: TopUpChartGroupBy
  name: string
}

export interface TopUpChartFactTypeObject {
  id: TopUpChartFactType
  name: string
}

export const TOPUP_CHART_GROUPBY_OBJECTS: TopUpChartGroupByObject[] = [
  {
    id: TopUpChartGroupBy.TOTAL,
    name: 'total',
  },
  {
    id: TopUpChartGroupBy.LAUNDRY_USER,
    name: 'laundryUser',
  },
  {
    id: TopUpChartGroupBy.TOPUP_TYPE,
    name: 'topUpType',
  },
]

export const getTopUpChartGroupByObject = (
  topUpChartGroupBy: TopUpChartGroupBy | undefined,
): TopUpChartGroupByObject | undefined => {
  return findReferenceObject(TOPUP_CHART_GROUPBY_OBJECTS, topUpChartGroupBy)
}

export const TOPUP_CHART_FACT_TYPE_OBJECTS: TopUpChartFactTypeObject[] = [
  {
    id: TopUpChartFactType.COUNT,
    name: 'numOfTopUps',
  },
  {
    id: TopUpChartFactType.AMOUNT_SUM,
    name: 'amount',
  },
]

export const getTopUpChartFactTypeObject = (
  topUpChartFactType: TopUpChartFactType | undefined,
): TopUpChartFactTypeObject | undefined => {
  return findReferenceObject(TOPUP_CHART_FACT_TYPE_OBJECTS, topUpChartFactType)
}
