import React, { ReactElement, Suspense, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { GridItemBreakpointsObject } from 'src/ui-shared/constants/GridItem.const'
import { DemoDataSwitch } from 'src/ui-shared/icons/DemoDataSwitch'
import { DashboardAssetsWidget } from 'src/ui/page/sm/index/DashboardAssetsWidget'
import { DashboardIssuesWidget } from 'src/ui/page/sm/index/DashboardIssuesWidget'
import { DashboardLaundryStateWidget } from 'src/ui/page/sm/index/DashboardLaundryStateWidget'

export const DashboardSm = (): ReactElement => {
  const translate = useTranslate()

  // NOTE mock data / demo data for dashboard is intentional
  const [useMockData, setUseMockData] = useState<boolean>(false)

  const DASHBOARD_ITEMS_BREAKPOINTS: GridItemBreakpointsObject = {
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
  }

  return (
    <>
      <Suspense fallback={<div style={{ textAlign: 'center' }}>{translate('autocompleteLoading')}</div>}>
        <Grid container spacing={3} pt={1}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h2">{translate('appDashboard', 'serviceMaster')}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} display={'flex'} justifyContent="flex-end" alignItems="flex-end">
            <DemoDataSwitch useMockData={useMockData} setUseMockData={setUseMockData} />
          </Grid>
          <Grid item {...DASHBOARD_ITEMS_BREAKPOINTS} md={12}>
            <DashboardAssetsWidget useMockData={useMockData} />
          </Grid>

          <Grid item {...DASHBOARD_ITEMS_BREAKPOINTS} md={12}>
            <DashboardIssuesWidget useMockData={useMockData} />
          </Grid>

          <Grid item xs={12} mt={1}>
            <DashboardLaundryStateWidget useMockData={useMockData} />
          </Grid>
        </Grid>
      </Suspense>
    </>
  )
}
