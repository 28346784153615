import React, { ReactElement } from 'react'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { AppWithRoutes } from 'src/AppWithRoutes'
import { GoogleAnalyticsPageViewEvent } from 'src/analytics/GoogleAnalyticsPageViewEvent'
import { AppId } from 'src/app/AppId'
import { RedirectToDefaultApp } from 'src/routing/RedirectToDefaultApp'
import { RestrictedRoute } from 'src/routing/RestrictedRoute'
import { RouteModel, RoutesList } from 'src/routing/Routing'
import { ES_PATH, SM_PATH, WM_PATH, getRoutesForApp } from 'src/routing/Routing.utils'
import { LANDING_ROUTES_ES, LANDING_ROUTES_SM, LANDING_ROUTES_WM } from 'src/routing/SharedRoutes'
import { ErrorPage } from 'src/ui/layout-page/special/ErrorPage'
import { NotFound } from 'src/ui/layout-page/special/NotFoundPage'
import { EsLayout } from 'src/ui/layout/main-layout/EsLayout'
import { SmLayout } from 'src/ui/layout/main-layout/SmLayout'
import { WmLayout } from 'src/ui/layout/main-layout/WmLayout'
import { hasApp } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

const mapRoutesI = (routes: RouteModel[]): ReactElement[] => {
  return routes.map(({ path, permission, googleAnalyticsTitle, Component }) =>
    permission ? (
      <Route
        path={path}
        key={path}
        errorElement={<ErrorPage />}
        element={
          <GoogleAnalyticsPageViewEvent googleAnalyticsTitle={googleAnalyticsTitle}>
            <RestrictedRoute key={path} path={path} component={Component} />
          </GoogleAnalyticsPageViewEvent>
        }
      />
    ) : (
      <Route
        path={path}
        key={path}
        errorElement={<ErrorPage />}
        element={
          <GoogleAnalyticsPageViewEvent googleAnalyticsTitle={googleAnalyticsTitle}>
            <Component key={path} path={path} />
          </GoogleAnalyticsPageViewEvent>
        }
      />
    ),
  )
}

const mapRoutes = (routes: RoutesList): ReactElement[] => {
  return mapRoutesI(Object.values(routes))
}

export const AllRoutes = (): ReactElement => {
  const user = useUser()

  const hasWashMaster = hasApp(user, AppId.WASH_MASTER)
  const hasServiceMaster = -hasApp(user, AppId.SERVICE_MASTER)
  const hasEasySet = hasApp(user, AppId.EASY_SET)

  const routesFromElements = createRoutesFromElements(
    <Route element={<AppWithRoutes />}>
      {hasWashMaster && (
        <Route path={WM_PATH} element={<WmLayout />}>
          {mapRoutes(getRoutesForApp(AppId.WASH_MASTER))}
          <Route element={<NotFound />} path="*" />
        </Route>
      )}
      {hasServiceMaster && (
        <Route path={SM_PATH} element={<SmLayout />}>
          {mapRoutes(getRoutesForApp(AppId.SERVICE_MASTER))}
          <Route element={<NotFound />} path="*" />
        </Route>
      )}
      {hasEasySet && (
        <Route path={ES_PATH} element={<EsLayout />}>
          {mapRoutes(getRoutesForApp(AppId.EASY_SET))}
          <Route element={<NotFound />} path="*" />
        </Route>
      )}
      {/* routes when the user has no access to any app, needed to accept invitation, logout etc. */}
      {!hasWashMaster && !hasServiceMaster && !hasEasySet ? (
        <>
          <Route path={WM_PATH} element={<WmLayout />}>
            {mapRoutes(LANDING_ROUTES_WM)}
          </Route>
          <Route path={SM_PATH} element={<SmLayout />}>
            {mapRoutes(LANDING_ROUTES_SM)}
          </Route>
          <Route path={ES_PATH} element={<EsLayout />}>
            {mapRoutes(LANDING_ROUTES_ES)}
          </Route>
        </>
      ) : null}

      <Route path="/" element={<RedirectToDefaultApp />} />
      <Route element={<NotFound />} path="/*" />
    </Route>,
  )

  const router = createBrowserRouter(routesFromElements)

  return <RouterProvider router={router} />
}
