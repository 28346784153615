import {
  Currency,
  PrepaidBankTransferDownloadRequest,
  PrepaidBankTransferMailingConfirmRequest,
} from 'src/service/backend/api/models'

export interface LaundryUserGenerateBankSlipViewModel {
  amount?: number
  action?: string
}

export const mapLaundryUserGenerateBankSlipViewModelToPrepaidBankTransferDownloadRequest = (
  viewModel: LaundryUserGenerateBankSlipViewModel,
  currency: Currency,
): PrepaidBankTransferDownloadRequest => {
  return {
    amount: viewModel.amount || 0,
    currency: currency,
  }
}

export const mapLaundryUserGenerateBankSlipViewModelToPrepaidBankTransferMailingConfirmRequest = (
  viewModel: LaundryUserGenerateBankSlipViewModel,
  currency: Currency,
): PrepaidBankTransferMailingConfirmRequest => {
  return {
    amount: viewModel.amount || 0,
    currency: currency,
  }
}
