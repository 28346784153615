import {
  DatatransPaymentMethod,
  MachineCategory,
  PaymentMethod,
  UsageChartFactType,
  UsageChartGroupBy,
} from 'src/service/backend/api'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'
import { ChartSettings, DEFAULT_CHART_SETTINGS } from 'src/service/view-model/base/chart/ChartViewModel'

export interface UsageChartSettings extends ChartSettings {
  groupBy: UsageChartGroupBy
  factType: UsageChartFactType
  organizationId?: string
  laundryGroupId?: string
  laundryId?: string
  machineId?: string
  machineCategory?: MachineCategory
  paymentMethod?: PaymentMethod
  dataTransPaymentMethod?: DatatransPaymentMethod
}

export const DEFAULT_USAGE_CHART_SETTINGS: UsageChartSettings = {
  ...DEFAULT_CHART_SETTINGS,
  groupBy: UsageChartGroupBy.TOTAL,
  factType: UsageChartFactType.COUNT,
}

export interface UsageChartGroupByObject {
  id: UsageChartGroupBy
  name: string
}

export interface UsageChartFactTypeObject {
  id: UsageChartFactType
  name: string
}

export const USAGE_CHART_GROUPBY_OBJECTS: UsageChartGroupByObject[] = [
  {
    id: UsageChartGroupBy.TOTAL,
    name: 'total',
  },
  {
    id: UsageChartGroupBy.ORGANIZATION,
    name: 'organization',
  },
  {
    id: UsageChartGroupBy.LAUNDRY_GROUP,
    name: 'laundryGroup',
  },
  {
    id: UsageChartGroupBy.LAUNDRY,
    name: 'laundry',
  },
  {
    id: UsageChartGroupBy.LAUNDRY_USER,
    name: 'laundryUser',
  },
  {
    id: UsageChartGroupBy.MACHINE,
    name: 'machine',
  },
  {
    id: UsageChartGroupBy.MACHINE_CATEGORY,
    name: 'machineCategory',
  },
  {
    id: UsageChartGroupBy.PAYMENT_TYPE,
    name: 'paymentType',
  },
]

export const getUsageGroupByObject = (
  usageGroupBy: UsageChartGroupBy | undefined,
): UsageChartGroupByObject | undefined => {
  return findReferenceObject(USAGE_CHART_GROUPBY_OBJECTS, usageGroupBy)
}

export const USAGE_CHART_FACTTYPE_OBJECTS: UsageChartFactTypeObject[] = [
  {
    id: UsageChartFactType.COUNT,
    name: 'numOfUsages',
  },
  {
    id: UsageChartFactType.AMOUNT_SUM,
    name: 'amount',
  },
]

export const getUsageFactTypeObject = (
  usageFactType: UsageChartFactType | undefined,
): UsageChartFactTypeObject | undefined => {
  return findReferenceObject(USAGE_CHART_FACTTYPE_OBJECTS, usageFactType)
}
