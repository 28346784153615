import { add, isAfter, isBefore, isValid, parse, sub } from 'date-fns'
import {
  DATE_FORMAT,
  DATE_ISO_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_12,
} from 'src/service/utils/DateFnsFormatsConstants'

const parseDateWithFormat = (dateString: string, formatPattern: string): Date => parse(dateString, formatPattern, 0)
const parseDate = (dateString: string): Date => parse(dateString, DATE_FORMAT, 0)
const parseDateIso = (dateString: string): Date => parse(dateString, DATE_ISO_FORMAT, 0)
const parseDateTime = (dateTimeString: string): Date => parse(dateTimeString, DATE_TIME_FORMAT, 0)
const parse12HTime = (time: string): Date => parse(time, TIME_FORMAT_12, 0)
const parseTime = (time: string): Date => parse(time, TIME_FORMAT, 0)

const after = (date: Date, dateToCompare: Date): boolean => isAfter(date, dateToCompare)
const before = (date: Date, dateToCompare: Date): boolean => isBefore(date, dateToCompare)
const valid = (date: Date): boolean => isValid(date)

const subtract = (date: Date, duration: Duration): Date => {
  return sub(date, duration)
}
const increment = (date: Date, duration: Duration): Date => {
  return add(date, duration)
}

const convertMinutesToSeconds = (minutes?: number): number => {
  if (minutes === undefined) {
    return 0
  }
  return minutes * 60
}

const convertSecondsToMinutes = (seconds?: number): number => {
  if (seconds === undefined) {
    return 0
  }
  return seconds / 60
}

const isValidDate = (date: Date | undefined | null): boolean => {
  // @ts-ignore
  return date && date instanceof Date && isFinite(date)
}

const isValidISODateForm = (dateString: string): boolean => {
  return (
    dateString.length >= 20 &&
    dateString.charAt(10) === 'T' &&
    (dateString.startsWith('2') || dateString.startsWith('1')) &&
    (dateString.endsWith(':00') || dateString.endsWith('Z'))
  )
}

export const DateUtils = {
  parseDateWithFormat,
  parseDate,
  parseDateIso,
  parseDateTime,
  parseTime,
  parse12HTime,
  after,
  before,
  valid,
  subtract,
  increment,
  convertMinutesToSeconds,
  convertSecondsToMinutes,
  isValidDate,
  isValidISODateForm,
}
