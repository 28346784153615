import { TranslateFunction } from 'src/i18n/useMessageSource'
import { HMResult, HMState } from 'src/service/backend/api'
import { getReferenceName } from 'src/service/view-model/base/Reference.utils'

export interface HygieneObject {
  state: string
  name: string
}

export const HYGIENE_STATE: HygieneObject[] = [
  { state: HMState.FINISHED, name: 'hmstate.finished' },
  { state: HMState.ANALYZING, name: 'hmstate.analyzing' },
]

export const getHygieneStateName = (
  hygieneStateName: HMState | undefined,
  result: HMResult | undefined,
  translate: TranslateFunction,
): string => {
  if (!hygieneStateName) {
    return ''
  }

  let label = getReferenceName(HYGIENE_STATE, translate, hygieneStateName, 'state')

  if (result) {
    const resultLabel = getHygieneResultName(result, translate)
    label += ', ' + resultLabel
  }

  return label
}

export const HYGIENE_RESULT: HygieneObject[] = [
  { state: HMResult.SUCCESSFUL, name: 'hmresult.successful' },
  { state: HMResult.FAILED, name: 'hmresult.failed' },
]

export const getHygieneResultName = (hygieneResultName: HMResult | undefined, translate: TranslateFunction): string => {
  return getReferenceName(HYGIENE_RESULT, translate, hygieneResultName, 'state')
}
