import { useEffect, useRef } from 'react'

export const useDidComponentUpdate = (func: () => void, deps: any): void => {
  const ref = useRef(false)

  useEffect(() => {
    if (ref.current) {
      func()
    } else {
      ref.current = true
    }
  }, deps)
}
