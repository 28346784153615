/**
 * Subtract two arrays by comparing passed key values
 *
 * @param arrayMinuend the array which is subtracted from
 * @param arraySubtrahend the array that is being subtracted
 * @param equalityCheck an optional function that checks if two objects are the same, if not passed as prop we compare by 'id' property
 * @returns {Array} subtracted elements.
 */
export const subtractObjArray = <T, K extends keyof T>(
  arrayMinuend: T[],
  arraySubtrahend: T[],
  equalityCheck?: (o1: T, o2: T) => boolean,
): T[] => {
  let resultArray: T[] = []

  if (arrayMinuend.length === 0) {
    return resultArray
  }
  if (arraySubtrahend.length === 0) {
    return arrayMinuend
  }

  resultArray = arrayMinuend.filter((elementMinuend) => {
    return !arraySubtrahend.some((elementSubtrahend) => {
      if (equalityCheck) {
        return equalityCheck(elementMinuend, elementSubtrahend)
      } else {
        return elementMinuend['id' as keyof T] === elementSubtrahend['id' as keyof T]
      }
    })
  })

  return resultArray
}
