import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useNavigate, useParams } from 'react-router'
import { Alert, Button, Divider, Paper } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/system'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, Manufacturer, ManufacturerModelsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  ManufacturerViewModel,
  createManufacturerViewModel,
  mapViewModelToManufacturer,
} from 'src/service/view-model/manufacturer/ManufacturerViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { required } from 'src/ui-shared/base/form/validation/Validators'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { usePrompt } from 'src/ui-shared/base/hooks/usePrompt'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

export const ManufacturerCreatePage = (): ReactElement => {
  const { manufacturerId } = useParams()
  const editMode = !!manufacturerId

  const httpConfiguration: Configuration = useContext(HttpContext)
  const manufacturerApi = new ManufacturerModelsApi(httpConfiguration)

  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()
  const navigate = useNavigate()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  // state
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [manufacturerViewModel, setManufacturerViewModel] = useState<ManufacturerViewModel>(
    createManufacturerViewModel(),
  )
  const [readonly, setReadonly] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  useHotKeysForm()

  usePrompt(translate('notification.form.unsaved.changes'), dirty)

  // load data
  useEffect(() => {
    let active = true
    if (editMode) {
      manufacturerApi
        .manufacturersManufacturerIdGet(manufacturerId)
        .then((manufacturer) => {
          if (!active) return
          const manufacturerViewModel = createManufacturerViewModel(manufacturer)
          setReadonly(!manufacturer.editable)
          setManufacturerViewModel(manufacturerViewModel)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
        })
    }

    return () => {
      active = false
    }
  }, [editMode, manufacturerId])

  // handle events
  const navigateBack = () => {
    if (manufacturerId) {
      navigate(`/${appId}/manufacturers/${manufacturerId}/view`)
    } else {
      navigate(`/${appId}/manufacturers`)
    }
  }

  const handleOnCancel = () => {
    navigateBack()
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  const onSubmitSuccess = () => {
    setDirty(false)
    setLoading(false)
    navigateBack()
  }

  const updateOrInsert = (manufacturer: Manufacturer): Promise<Manufacturer> => {
    if (editMode) {
      return manufacturerApi.manufacturersManufacturerIdPut(manufacturerId, manufacturer)
    } else {
      return manufacturerApi.manufacturersPost(manufacturer)
    }
  }

  const submitForm = async (viewModel: ManufacturerViewModel) => {
    setLoading(true)
    setDirty(false)

    const manufacturer = mapViewModelToManufacturer(viewModel)

    return updateOrInsert(manufacturer)
      .then(() => {
        onSubmitSuccess()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  return (
    <ScreenLayout
      title={manufacturerId ? manufacturerViewModel.name : translate('createManufacturer')}
      onBack={navigateBack}
      actionsWidth={50}
    >
      <LoadingIndicator loading={loading} />
      <Paper elevation={0}>
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <Box>
            <Form<ManufacturerViewModel>
              initialValues={manufacturerViewModel}
              onSubmit={submitForm}
              render={({ handleSubmit, submitting, pristine }) => {
                return (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      {readonly ? (
                        <Grid item md={12} lg={12}>
                          <Alert severity={'warning'}>{translate('manufacturer.nonEditable')}</Alert>
                        </Grid>
                      ) : null}

                      <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                        <TextField
                          label={translate('manufacturerName')}
                          type="text"
                          name="name"
                          fullWidth
                          autoFocus={!editMode}
                          disabled={submitting || readonly}
                          validate={required()}
                        />
                      </Grid>
                    </Grid>
                    <Divider className={sharedClasses.Divider} />
                    <Box mt={3} display="flex" justifyContent="flex-end">
                      <Button
                        id="cancelButton"
                        variant="text"
                        color="primary"
                        size="large"
                        className={sharedClasses.ButtonMargin}
                        onClick={handleOnCancel}
                      >
                        {translate('button.cancel')}
                      </Button>
                      <Button
                        id="submitButton"
                        disabled={(submitting || pristine) && readonly}
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                      >
                        {translate('button.save')}
                      </Button>
                    </Box>

                    <FormSpy
                      subscription={{ dirty: true }}
                      onChange={(props) => {
                        setTimeout(() => setDirty(props.dirty), 0)
                      }}
                    />
                  </form>
                )
              }}
            />
          </Box>
        )}
      </Paper>
    </ScreenLayout>
  )
}
