import React, { ReactElement } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { TranslateFunction } from 'src/i18n/useMessageSource'
import { HeadCells, PAGE_SIZES, TableData, TableSettings } from 'src/ui-shared/table/Table.const'
import { handlePageChange, handlePageSizeChange, handleSortChange } from 'src/ui-shared/table/Table.event'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'
import { TableLoading } from 'src/ui-shared/table/TableLoading'

export interface DataTableProp {
  headCells: HeadCells[]
  data: TableData<any>
  nonEmptyRows: JSX.Element | JSX.Element[]
  tableSettings: TableSettings
  setTableSettings: React.Dispatch<React.SetStateAction<TableSettings>>
  getFilter?: (headCellId: string) => JSX.Element | undefined
  loading: boolean
  translate: TranslateFunction
  style?: React.CSSProperties
  selectedRows?: number
  withPagination?: boolean
}

export const DataTable: React.FC<DataTableProp> = ({
  headCells,
  data,
  tableSettings,
  setTableSettings,
  translate,
  getFilter,
  nonEmptyRows,
  loading,
  style,
  selectedRows,
  withPagination = true,
}): ReactElement => {
  const appliedFilter = getFilter ? getFilter : () => undefined

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {displayTableHeaders(headCells, tableSettings, setTableSettings, translate, appliedFilter, style)}
            </TableRow>
          </TableHead>
          <TableBody>{displayTableRows(data, loading, headCells.length, nonEmptyRows)}</TableBody>
        </Table>
      </TableContainer>
      <Box display={'flex'} justifyContent={'space-between'}>
        {selectedRows !== undefined && displayTableSelectedRows(selectedRows, translate)}
        {withPagination && (
          <Box flexGrow={1}>
            {displayTablePagination(data.totalElements, tableSettings, setTableSettings, translate)}
          </Box>
        )}
      </Box>
    </>
  )
}

export const displayTableRows = (
  tableData: TableData<any>,
  loading: boolean,
  colspan: number,
  nonEmptyRows: JSX.Element | JSX.Element[],
): JSX.Element | JSX.Element[] => {
  let displayRows
  if (tableData.totalElements === 0) {
    displayRows = <TableEmpty colspan={colspan} />
  } else if (loading && tableData.totalElements === -1) {
    displayRows = <TableLoading colspan={colspan} />
  } else {
    displayRows = nonEmptyRows
  }
  return displayRows
}

export const displayTablePagination = (
  totalElementsCount: number,
  tableSettings: TableSettings,
  setTableSettings: React.Dispatch<React.SetStateAction<TableSettings>>,
  translate: TranslateFunction,
): JSX.Element => {
  const displayPagination = (
    <TablePagination
      labelRowsPerPage={translate('rowsPerPage') + ':'}
      labelDisplayedRows={({ from, to, count }) => {
        return `${from}-${to} ${translate('of')} ${count !== -1 ? count : `${translate('moreThan')} ${to}`}`
      }}
      rowsPerPageOptions={PAGE_SIZES}
      component="div"
      count={totalElementsCount}
      rowsPerPage={tableSettings.size}
      page={tableSettings.page}
      onPageChange={(event, newPage) => handlePageChange(event, newPage, tableSettings, setTableSettings)}
      onRowsPerPageChange={(event) => handlePageSizeChange(event, tableSettings, setTableSettings)}
    />
  )

  return displayPagination
}

export const displayTableSelectedRows = (selectedRows: number, translate: TranslateFunction): JSX.Element => {
  const displayCounter = (
    <Typography variant={'body2'} p={2} pl={0}>
      {selectedRows > 0 && translate('selectedRows', selectedRows)}
    </Typography>
  )
  return displayCounter
}

export const displayTableHeaders = (
  headCells: HeadCells[],
  tableSettings: TableSettings,
  setTableSettings: React.Dispatch<React.SetStateAction<TableSettings>>,
  translate: TranslateFunction,
  getFilter: (headCellId: string) => JSX.Element | undefined,
  style?: React.CSSProperties,
): JSX.Element[] => {
  const tableHeaders = headCells.map((item) => {
    const filterEl = getFilter(item.id)
    return (
      <TableCell
        style={style}
        key={item.id}
        sortDirection={tableSettings.orderBy === item.id ? tableSettings.orderDir : false}
      >
        {item.noSort ? (
          translate(item.label)
        ) : (
          <TableSortLabel
            active={tableSettings.orderBy === item.id}
            direction={tableSettings.orderBy === item.id ? tableSettings.orderDir : 'asc'}
            onClick={(event) => handleSortChange(event, item.id, tableSettings, setTableSettings)}
          >
            {translate(item.label)}
          </TableSortLabel>
        )}
        {filterEl ? <>&nbsp;&nbsp;{filterEl}</> : null}
      </TableCell>
    )
  })

  return tableHeaders
}
