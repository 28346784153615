import React, { ReactElement, Suspense, useContext, useState } from 'react'
import { useLocation } from 'react-router'
import { Box } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { ChartData, Configuration, ReportWashMasterApi, UsageChartFactType } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { CHART_SCALE_OBJECTS, getChartScaleObject } from 'src/service/view-model/base/chart/Chart.const'
import {
  DEFAULT_USAGE_CHART_SETTINGS,
  USAGE_CHART_FACTTYPE_OBJECTS,
  USAGE_CHART_GROUPBY_OBJECTS,
  UsageChartFactTypeObject,
  UsageChartSettings,
  getUsageFactTypeObject,
  getUsageGroupByObject,
} from 'src/service/view-model/usage-chart/UsageChartViewModel'
import { useDataSettingsUrlSync } from 'src/ui-shared/base/hooks/useDataSettingsUrlSync'
import { ChartDataFilter } from 'src/ui-shared/chart/ChartDataFilter'
import { ChartDataSelection } from 'src/ui-shared/chart/ChartDataSelection'
import { withOrganization } from 'src/ui-shared/table/Table.const'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'

const AsyncChart = React.lazy(() => import('src/ui-shared/chart/Chart').then(({ Chart }) => ({ default: Chart })))

export const UsageChart = (): ReactElement => {
  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportsApi = new ReportWashMasterApi(httpConfiguration)

  const translate = useTranslate()

  const location = useLocation()

  const activeOrganization = useActiveOrganization()

  const defaultUsageChartSettings: UsageChartSettings = { ...DEFAULT_USAGE_CHART_SETTINGS }
  const [usageChartSettings, setUsageChartSettings] = useState<UsageChartSettings>(
    withOrganization(defaultUsageChartSettings, activeOrganization),
  )

  const loadChart = (): Promise<ChartData> => {
    const dateStartTimeFrom = new Date(usageChartSettings.startDateFrom)
    const dateStartTimeTo = new Date(usageChartSettings.startDateTo)

    return reportsApi.reportWmUsagesChartGet(
      usageChartSettings.groupBy,
      usageChartSettings.factType,
      usageChartSettings.chartScale,
      dateStartTimeFrom,
      dateStartTimeTo,
      undefined,
      usageChartSettings.organizationId,
      usageChartSettings.laundryGroupId,
      usageChartSettings.laundryId,
      usageChartSettings.machineId,
    )
  }

  // generic reactivity

  // update state from url
  useDataSettingsUrlSync(location, setUsageChartSettings, usageChartSettings, defaultUsageChartSettings)

  // render
  const renderFactTypeOptionLabel = (option: UsageChartFactTypeObject) => {
    if (option.id === UsageChartFactType.AMOUNT_SUM) {
      return `${translate(option.name)} ($)`
    }
    return `${translate(option.name)} (#)`
  }

  return (
    <Box mt={1}>
      {/* Groupings */}
      <Box>
        {/*Input fields for data selection: factType, groupBy and chartScale*/}
        <ChartDataSelection
          chartSettings={usageChartSettings}
          setChartSettings={setUsageChartSettings}
          factType={'factType'}
          groupBy={'groupBy'}
          scale={'chartScale'}
          factTypeOptions={USAGE_CHART_FACTTYPE_OBJECTS}
          factTypeOptionsLabel={renderFactTypeOptionLabel}
          factTypeValue={getUsageFactTypeObject}
          groupByOptions={USAGE_CHART_GROUPBY_OBJECTS}
          groupByOptionsLabel={(option) => translate(option.name)}
          groupByValue={getUsageGroupByObject}
          scaleOptions={CHART_SCALE_OBJECTS}
          scaleOptionsLabel={(option) => translate(option.name)}
          scaleValue={getChartScaleObject}
        >
          {/*Input fields for selecting date range */}
          <TableDatePickerForm
            key={`${usageChartSettings.startDateFrom}${usageChartSettings.startDateTo}`}
            firstDatePropName="startDateFrom"
            secondDatePropName="startDateTo"
            tableSettings={usageChartSettings}
            setTableSettings={setUsageChartSettings}
          />
        </ChartDataSelection>
      </Box>

      {/* Filters */}
      <Box mt={1}>
        {/*Input fields for data filter: organization, laundry group, laundry and machine */}
        <ChartDataFilter
          chartSettings={usageChartSettings}
          setChartSettings={setUsageChartSettings}
          organization={'organizationId'}
          laundryGroup={'laundryGroupId'}
          laundry={'laundryId'}
          machine={'machineId'}
        />
      </Box>
      <Suspense fallback={<div style={{ textAlign: 'center' }}>{translate('autocompleteLoading')}</div>}>
        <AsyncChart loadChart={loadChart} chartSettings={usageChartSettings} />
      </Suspense>
    </Box>
  )
}
