import { SupportedLocale } from 'src/service/view-model/base/localization/SupportedLocale'

export const formatNumber = (number: number): string => {
  if (Number.isInteger(number)) {
    return number.toString()
  }

  return number.toFixed(2)
}

// used in view models (or forms where we need to fix the value to 2 decimals)
export const formatAmount = (amount: number | null | undefined): string => {
  if (amount == null) {
    return ''
  }
  return amount.toFixed(2)
}

export const formatAmountForLocale = (
  amount: number | null | undefined,
  locale: SupportedLocale,
  unit: string | null | undefined,
): string => {
  if (amount == null) {
    return ''
  }

  const formattedAmount = Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
    amount,
  )

  const amountUnit = unit ?? ''

  const amountAndUnit = `${formattedAmount} ${amountUnit}`

  return amountAndUnit.trim()
}

export const formatPercentage = (percentageAmount: number | null | undefined, decimals = true): string => {
  if (percentageAmount == null) {
    return ''
  }
  const percentageFormatted = decimals ? percentageAmount.toFixed(1) : percentageAmount.toFixed(0)
  return percentageFormatted + '%'
}

export const formatTemperatureOptional = (temp?: number | null, unit?: string | null): string => {
  if (temp == null) {
    return ''
  }
  return formatTemperature(temp, unit)
}

export const formatTemperature = (temp: number, unit?: string | null): string => {
  const tempUnit = unit ?? ''

  const formattedNumber = formatNumber(temp)

  const tempAndUnit = `${formattedNumber}${tempUnit}`
  return tempAndUnit.trim()
}

export const formatWeight = (weight: number, unit?: string | undefined | null): string => {
  const weightUnit = unit ?? ''

  const formattedNumber = formatNumber(weight)
  const weightAndUnit = `${formattedNumber} ${weightUnit}`
  return weightAndUnit.trim()
}
