/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AppId,
  Country,
  LaundryGroup,
  LaundryGroupIdMoveBody,
  LaundryGroupReference,
  LaundryGroupSettings,
  PageableLaundryGroupLogs,
  PageableLaundryGroups,
} from '../models';
import {
    AppIdFromJSON,
    AppIdToJSON,
    CountryFromJSON,
    CountryToJSON,
    LaundryGroupFromJSON,
    LaundryGroupToJSON,
    LaundryGroupIdMoveBodyFromJSON,
    LaundryGroupIdMoveBodyToJSON,
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceToJSON,
    LaundryGroupSettingsFromJSON,
    LaundryGroupSettingsToJSON,
    PageableLaundryGroupLogsFromJSON,
    PageableLaundryGroupLogsToJSON,
    PageableLaundryGroupsFromJSON,
    PageableLaundryGroupsToJSON,
} from '../models';

export interface LaundrygroupsGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    id?: string;
    organizationId?: string;
    countryCode?: Country;
    appId?: Array<AppId>;
    wlNumber?: string;
    _public?: boolean;
}

export interface LaundrygroupsLaundryGroupIdDeleteRequest {
    laundryGroupId: string;
}

export interface LaundrygroupsLaundryGroupIdGetRequest {
    laundryGroupId: string;
    appId?: Array<AppId>;
}

export interface LaundrygroupsLaundryGroupIdLogGetRequest {
    laundryGroupId: string;
    size?: number;
    page?: number;
    sort?: string;
    machineId?: string;
}

export interface LaundrygroupsLaundryGroupIdMovePostRequest {
    laundryGroupId: string;
    laundryGroupIdMoveBody?: LaundryGroupIdMoveBody;
}

export interface LaundrygroupsLaundryGroupIdPutRequest {
    laundryGroupId: string;
    appId?: Array<AppId>;
    laundryGroup?: LaundryGroup;
}

export interface LaundrygroupsLaundryGroupIdSettingsGetRequest {
    laundryGroupId: string;
}

export interface LaundrygroupsLaundryGroupIdSettingsPutRequest {
    laundryGroupId: string;
    laundryGroupSettings?: LaundryGroupSettings;
}

export interface LaundrygroupsPostRequest {
    appId?: AppId;
    laundryGroup?: LaundryGroup;
}

export interface LaundrygroupsRefGetRequest {
    size?: number;
    search?: string;
    organizationId?: Array<string>;
}

/**
 * 
 */
export class LaundryGroupsApi extends runtime.BaseAPI {

    /**
     * List Laundry Groups
     */
    async laundrygroupsGetRaw(requestParameters: LaundrygroupsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableLaundryGroups>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.countryCode !== undefined) {
            queryParameters['countryCode'] = requestParameters.countryCode;
        }

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        if (requestParameters.wlNumber !== undefined) {
            queryParameters['wlNumber'] = requestParameters.wlNumber;
        }

        if (requestParameters._public !== undefined) {
            queryParameters['public'] = requestParameters._public;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableLaundryGroupsFromJSON(jsonValue));
    }

    /**
     * List Laundry Groups
     */
    async laundrygroupsGet(size?: number, page?: number, sort?: string, search?: string, id?: string, organizationId?: string, countryCode?: Country, appId?: Array<AppId>, wlNumber?: string, _public?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableLaundryGroups> {
        const response = await this.laundrygroupsGetRaw({ size: size, page: page, sort: sort, search: search, id: id, organizationId: organizationId, countryCode: countryCode, appId: appId, wlNumber: wlNumber, _public: _public }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a LaundryGroup
     */
    async laundrygroupsLaundryGroupIdDeleteRaw(requestParameters: LaundrygroupsLaundryGroupIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a LaundryGroup
     */
    async laundrygroupsLaundryGroupIdDelete(laundryGroupId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.laundrygroupsLaundryGroupIdDeleteRaw({ laundryGroupId: laundryGroupId }, initOverrides);
    }

    /**
     * Get an Laundry Group
     */
    async laundrygroupsLaundryGroupIdGetRaw(requestParameters: LaundrygroupsLaundryGroupIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroup>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupFromJSON(jsonValue));
    }

    /**
     * Get an Laundry Group
     */
    async laundrygroupsLaundryGroupIdGet(laundryGroupId: string, appId?: Array<AppId>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroup> {
        const response = await this.laundrygroupsLaundryGroupIdGetRaw({ laundryGroupId: laundryGroupId, appId: appId }, initOverrides);
        return await response.value();
    }

    /**
     * List logs for a laundry group
     */
    async laundrygroupsLaundryGroupIdLogGetRaw(requestParameters: LaundrygroupsLaundryGroupIdLogGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableLaundryGroupLogs>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdLogGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/log`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableLaundryGroupLogsFromJSON(jsonValue));
    }

    /**
     * List logs for a laundry group
     */
    async laundrygroupsLaundryGroupIdLogGet(laundryGroupId: string, size?: number, page?: number, sort?: string, machineId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableLaundryGroupLogs> {
        const response = await this.laundrygroupsLaundryGroupIdLogGetRaw({ laundryGroupId: laundryGroupId, size: size, page: page, sort: sort, machineId: machineId }, initOverrides);
        return await response.value();
    }

    /**
     * Move a Laundry Group with subentities to other Organization/Laundry Group
     */
    async laundrygroupsLaundryGroupIdMovePostRaw(requestParameters: LaundrygroupsLaundryGroupIdMovePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdMovePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/move`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryGroupIdMoveBodyToJSON(requestParameters.laundryGroupIdMoveBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Move a Laundry Group with subentities to other Organization/Laundry Group
     */
    async laundrygroupsLaundryGroupIdMovePost(laundryGroupId: string, laundryGroupIdMoveBody?: LaundryGroupIdMoveBody, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.laundrygroupsLaundryGroupIdMovePostRaw({ laundryGroupId: laundryGroupId, laundryGroupIdMoveBody: laundryGroupIdMoveBody }, initOverrides);
    }

    /**
     * Update a Laundry Group
     */
    async laundrygroupsLaundryGroupIdPutRaw(requestParameters: LaundrygroupsLaundryGroupIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroup>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryGroupToJSON(requestParameters.laundryGroup),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupFromJSON(jsonValue));
    }

    /**
     * Update a Laundry Group
     */
    async laundrygroupsLaundryGroupIdPut(laundryGroupId: string, appId?: Array<AppId>, laundryGroup?: LaundryGroup, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroup> {
        const response = await this.laundrygroupsLaundryGroupIdPutRaw({ laundryGroupId: laundryGroupId, appId: appId, laundryGroup: laundryGroup }, initOverrides);
        return await response.value();
    }

    /**
     * Get laundry group generic config
     */
    async laundrygroupsLaundryGroupIdSettingsGetRaw(requestParameters: LaundrygroupsLaundryGroupIdSettingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupSettings>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdSettingsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/settings`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupSettingsFromJSON(jsonValue));
    }

    /**
     * Get laundry group generic config
     */
    async laundrygroupsLaundryGroupIdSettingsGet(laundryGroupId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupSettings> {
        const response = await this.laundrygroupsLaundryGroupIdSettingsGetRaw({ laundryGroupId: laundryGroupId }, initOverrides);
        return await response.value();
    }

    /**
     * Set laundry group generic config
     */
    async laundrygroupsLaundryGroupIdSettingsPutRaw(requestParameters: LaundrygroupsLaundryGroupIdSettingsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupSettings>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdSettingsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/settings`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryGroupSettingsToJSON(requestParameters.laundryGroupSettings),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupSettingsFromJSON(jsonValue));
    }

    /**
     * Set laundry group generic config
     */
    async laundrygroupsLaundryGroupIdSettingsPut(laundryGroupId: string, laundryGroupSettings?: LaundryGroupSettings, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupSettings> {
        const response = await this.laundrygroupsLaundryGroupIdSettingsPutRaw({ laundryGroupId: laundryGroupId, laundryGroupSettings: laundryGroupSettings }, initOverrides);
        return await response.value();
    }

    /**
     * Create a Laundry Group
     */
    async laundrygroupsPostRaw(requestParameters: LaundrygroupsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroup>> {
        const queryParameters: any = {};

        if (requestParameters.appId !== undefined) {
            queryParameters['appId'] = requestParameters.appId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundrygroups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryGroupToJSON(requestParameters.laundryGroup),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupFromJSON(jsonValue));
    }

    /**
     * Create a Laundry Group
     */
    async laundrygroupsPost(appId?: AppId, laundryGroup?: LaundryGroup, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroup> {
        const response = await this.laundrygroupsPostRaw({ appId: appId, laundryGroup: laundryGroup }, initOverrides);
        return await response.value();
    }

    /**
     * List Laundry Group References
     */
    async laundrygroupsRefGetRaw(requestParameters: LaundrygroupsRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaundryGroupReference>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.organizationId) {
            queryParameters['organizationId'] = requestParameters.organizationId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaundryGroupReferenceFromJSON));
    }

    /**
     * List Laundry Group References
     */
    async laundrygroupsRefGet(size?: number, search?: string, organizationId?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaundryGroupReference>> {
        const response = await this.laundrygroupsRefGetRaw({ size: size, search: search, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

}
