import React, { ReactElement, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapperReporting } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import {
  Configuration,
  LaundryUserBulkResponse,
  LaundryUserImportState,
  LaundryUsersApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocaleOptional } from 'src/service/utils/DateFormatUtils'
import { getLaundryUserImportStateName } from 'src/service/view-model/laundry-user/LaundryUserImportViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ScreenSteps } from 'src/ui-shared/base/stepper/ScreenSteps'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { FileFormat } from 'src/ui-shared/constants/Constants'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { HeadCells } from 'src/ui-shared/table/Table.const'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'
import { UploadFileView } from 'src/ui-shared/upload-file-view/UploadFileView'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { useUserRegionLocale } from 'src/user/UserContext'

const headCells: HeadCells[] = [
  {
    id: 'email',
    label: 'email',
    noSort: true,
  },
  {
    id: 'firstName',
    label: 'user.firstName',
    noSort: true,
  },
  {
    id: 'lastName',
    label: 'user.lastName',
    noSort: true,
  },
  {
    id: 'assignedLaundryGroups',
    label: 'laundryGroups',
    noSort: true,
  },
  {
    id: 'assignedRFIDCards',
    label: 'rfidNo',
    noSort: true,
  },
  {
    id: 'lastUsage',
    label: 'lastUsage',
    noSort: true,
  },
  {
    id: 'importState',
    label: 'state',
    noSort: true,
  },
  {
    id: 'errorMessage',
    label: 'errorMessage',
    noSort: true,
  },
]

export const LaundryUserImportPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()
  const navigate = useNavigate()
  const appId = useAppId()
  const location = useLocation()
  const regionLocale = useUserRegionLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryUsersApi = new LaundryUsersApi(httpConfiguration)

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack
  const [activeStep, setActiveStep] = useState<number>(0)
  const [importedLaundryUsers, setImportedLaundryUsers] = useState<Array<LaundryUserBulkResponse>>()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const laundryUserImportSteps = ['step.uploadFile', 'step.importingData', 'step.results']
  const acceptedFileFormats: FileFormat[] = ['csv']
  const acceptedFileSizeMB = 1

  // handle events
  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/laundry-users`)
  }

  const handleOnCancel = () => {
    navigateBack()
  }

  const handleImportLaundryUsers = (files: File[] | null) => {
    if (files) {
      setActiveStep(1)
      laundryUsersApi
        .laundryusersBulkPut(files[0]!)
        .then((data) => {
          setImportedLaundryUsers(data)
          setActiveStep(2)
        })
        .catch((err) => {
          const errorMessage = errorMapperReporting(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setActiveStep(2)
        })
    }
  }

  const getRowColor = (state: LaundryUserImportState): string => {
    if (state === LaundryUserImportState.ERROR) {
      return 'rgba(255, 0, 0, 0.05)'
    } else if (state === LaundryUserImportState.CREATED || state === LaundryUserImportState.UPDATED) {
      return 'rgba(0, 255, 0, 0.05)'
    }

    return 'rgba(255, 0, 0, 0.05)'
  }

  // JSX
  const nonEmptyRows = importedLaundryUsers?.map((item) => {
    const laundryColumn = item.assignedLaundryGroups.map((laundryGroup) => laundryGroup.name).join(', ')
    const rfidCardsColumn = item.assignedRFIDCards?.map((rfid) => rfid.cardNumber).join(', ')

    return (
      <TableRow key={item.id} sx={{ background: getRowColor(item.importState) }}>
        <TableCell>{item.email}</TableCell>
        <TableCell>{item.firstName}</TableCell>
        <TableCell>{item.lastName}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={30} value={laundryColumn} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={rfidCardsColumn} />
        </TableCell>
        <TableCell>{formatDateTimeForLocaleOptional(item.lastUsage, regionLocale)}</TableCell>
        <TableCell>{getLaundryUserImportStateName(item.importState, translate)}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={30} value={item.errorMessage} />
        </TableCell>
      </TableRow>
    )
  })

  const tableHeaders = headCells.map((item) => {
    return <TableCell key={item.id}>{translate(item.label)}</TableCell>
  })

  return (
    <ScreenLayout title={translate('importLaundryUsers')} onBack={navigateBack} actionsWidth={50}>
      <Divider className={sharedClasses.Divider} />

      <ScreenSteps steps={laundryUserImportSteps} activeStep={activeStep} />
      {activeStep === 0 && (
        <UploadFileView
          acceptedFileFormat={acceptedFileFormats}
          acceptedFileSizeMB={acceptedFileSizeMB}
          acceptMultipleFiles={false}
          handleCancel={handleOnCancel}
          handleUploadFiles={handleImportLaundryUsers}
          disclaimerTextFor={translate('laundryUsers')}
        />
      )}

      {activeStep === 1 && <LoadingIndicator loading={true} sx={{ marginLeft: 6 }} />}

      {activeStep === 2 && (
        <Box pt={4}>
          {errorMessage && <ErrorMessage message={errorMessage} />}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>{tableHeaders}</TableRow>
              </TableHead>
              <TableBody>{importedLaundryUsers ? nonEmptyRows : <TableEmpty colspan={headCells.length} />}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </ScreenLayout>
  )
}
