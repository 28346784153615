import React, { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { AppId } from 'src/app/AppId'
import { ES_PATH, SM_PATH, WM_PATH } from 'src/routing/Routing.utils'
import { hasApp } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const RedirectToDefaultApp = (): ReactElement => {
  const user = useUser()
  const defaultApp = user.defaultApp
  const noDefaultApp = defaultApp === undefined

  const getDefaultApp = () => {
    if ((defaultApp === AppId.WASH_MASTER || noDefaultApp) && hasApp(user, AppId.WASH_MASTER)) {
      return <Navigate to={WM_PATH} />
    } else if ((defaultApp === AppId.SERVICE_MASTER || noDefaultApp) && hasApp(user, AppId.SERVICE_MASTER)) {
      return <Navigate to={SM_PATH} />
    } else if ((defaultApp === AppId.EASY_SET || noDefaultApp) && hasApp(user, AppId.EASY_SET)) {
      return <Navigate to={ES_PATH} />
    } else {
      return <Navigate to={WM_PATH} />
    }
  }

  return <>{getDefaultApp()}</>
}
