/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * how to draw the chart data
 * @export
 * @enum {string}
 */
export enum ChartType {
    BAR = 'BAR',
    STACKED_BAR = 'STACKED_BAR',
    LINE = 'LINE',
    DOUGHNUT = 'DOUGHNUT'
}


export function ChartTypeFromJSON(json: any): ChartType {
    return ChartTypeFromJSONTyped(json, false);
}

export function ChartTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChartType {
    return json as ChartType;
}

export function ChartTypeToJSON(value?: ChartType | null): any {
    return value as any;
}

