import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, MachineType, ProgramsApi, ProgramsEditableMachineType } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  findEditableMachineType,
  getDrumSizeUnitForEditableMachineType,
  getDrumSizesForEditableMachineType,
} from 'src/service/view-model/machine/Machines'
import { DrumSizeModalDialog } from 'src/ui-shared/base/model-dialog/DrumSizeModalDialog'

interface Props<T> {
  title: string
  open: boolean
  machineType: MachineType
  onConfirm: (drumSize: number) => Promise<T>
  handleClose: (clearSelected?: boolean) => void
}

export const ProgramExportModalParent = <T,>({
  title,
  open,
  handleClose,
  onConfirm,
  machineType,
}: Props<T>): ReactElement => {
  const translate = useTranslate()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programsApi = new ProgramsApi(httpConfiguration)

  const [editableMachineType, setEditableMachineType] = useState<ProgramsEditableMachineType | undefined>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  useEffect(() => {
    programsApi
      .programsEditableMachineTypesGet()
      .then((data) => {
        const foundEditableMachineType = findEditableMachineType(data, machineType)
        setEditableMachineType(foundEditableMachineType)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }, [])

  const getDrumSizes = (): number[] => {
    return getDrumSizesForEditableMachineType(editableMachineType)
  }

  const getDrumSizeUnit = (): string | undefined => {
    return getDrumSizeUnitForEditableMachineType(editableMachineType)
  }

  return (
    <DrumSizeModalDialog
      drumSizes={getDrumSizes()}
      drumSizeUnit={getDrumSizeUnit()}
      onConfirm={onConfirm}
      open={open}
      handleClose={handleClose}
      title={title}
      errorMessage={errorMessage}
    />
  )
}
