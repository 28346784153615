import { CalendarConfig } from 'src/service/backend/api'

export interface LaundryGroupCalendarConfigViewModel {
  blockUsageOnPublicHoliday: boolean
  maxReservationHoursPerMonth: string
  maxReservationTime: string
}

export const createLaundryGroupCalendarConfigViewModel = (
  calendarConfig?: CalendarConfig,
): LaundryGroupCalendarConfigViewModel => {
  const viewModel: LaundryGroupCalendarConfigViewModel = {
    blockUsageOnPublicHoliday: calendarConfig?.blockUsageOnPublicHoliday || false,
    maxReservationHoursPerMonth: calendarConfig?.maxReservationHoursPerMonth.toString() || '',
    maxReservationTime: calendarConfig?.maxReservationTime.toString() || '',
  }
  return viewModel
}

export const mapLaundryGroupCalendarConfigViewModalToCalendarConfig = (
  viewModel: LaundryGroupCalendarConfigViewModel,
): CalendarConfig => {
  const calendarConfig: CalendarConfig = {
    blockUsageOnPublicHoliday: viewModel.blockUsageOnPublicHoliday,
    maxReservationHoursPerMonth: parseInt(viewModel.maxReservationHoursPerMonth),
    maxReservationTime: parseInt(viewModel.maxReservationTime),
  }
  return calendarConfig
}
