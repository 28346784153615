import React, { FC, ReactElement, useContext } from 'react'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ApiResponse, Configuration, MachineType, ProgramGroupsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getRemoveAcceptHeaderInit } from 'src/service/backend/http/HttpUtils'
import { downloadFile, getDownloadFilename } from 'src/service/utils/FileDownloadUtils'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ProgramExportModalParent } from 'src/ui/page/es/program/details/ProgramExportModalParent'

interface Props {
  title: string
  open: boolean
  programGroupId: string
  machineType: MachineType
  handleClose: (clearSelected?: boolean) => void
}

export const ProgramGroupExportModal: FC<Props> = ({
  title,
  open,
  handleClose,
  programGroupId,
  machineType,
}): ReactElement => {
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programGroupApi = new ProgramGroupsApi(httpConfiguration)

  const onConfirm = (drumSize: number) => {
    let filename: string | null

    return programGroupApi
      .programGroupsProgramGroupIdExportGetRaw(
        {
          programGroupId: programGroupId!,
          drumSize,
        },
        getRemoveAcceptHeaderInit(),
      )
      .then(async (response: ApiResponse<Blob>) => {
        filename = getDownloadFilename(response.raw)
        return response.value()
      })
      .then((blob: Blob) => {
        downloadFile(blob, filename)
        handleClose()
        return blob
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
        return err
      })
  }

  return (
    <ProgramExportModalParent
      onConfirm={onConfirm}
      machineType={machineType}
      open={open}
      handleClose={handleClose}
      title={title}
    />
  )
}
