import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ListItemIcon, MenuItem } from '@mui/material'
import { Add, GetApp } from '@mui/icons-material'
import PublishIcon from '@mui/icons-material/Publish'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import {
  ApiResponse,
  Configuration,
  LaundryGroupReference,
  LaundryGroupsApi,
  LaundryUsersApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getRemoveAcceptHeaderInit } from 'src/service/backend/http/HttpUtils'
import { downloadFile, getDownloadFilename } from 'src/service/utils/FileDownloadUtils'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { MenuButton } from 'src/ui-shared/base/button/MenuButton'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryUserDetailsTab } from 'src/ui/page/wm/laundry-user/details/LaundryUserDetailsTab'
import { LaundryUserExportDialog } from 'src/ui/page/wm/laundry-user/details/LaundryUserExportDialog'
import { LaundryUsersTable } from 'src/ui/page/wm/laundry-user/list/LaundryUserTable'

export const LaundryUsersListPage = (): ReactElement => {
  const translate = useTranslate()

  const navigate = useNavigate()
  const appId = useAppId()

  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)

  const laundryUsersApi = new LaundryUsersApi(httpConfiguration)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)

  // state
  const [exportModal, setExportModal] = useState<boolean>(false)
  const [laundryGroups, setLaundryGroups] = useState<LaundryGroupReference[]>([])

  useEffect(() => {
    let active = true

    laundryGroupsApi.laundrygroupsRefGet().then((data) => {
      if (active) {
        setLaundryGroups(data)
      }
    })

    return () => {
      active = false
    }
  }, [])

  // handle events
  const handleRowClick = (laundryUserId?: string) => {
    navigate(`/${appId}/laundry-users/${laundryUserId}/view/${LaundryUserDetailsTab.OVERVIEW}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleCreateButtonClick = () => {
    navigate(`/${appId}/laundry-users/create`)
  }

  const handleExportButtonClick = () => {
    setExportModal(true)
  }

  const doExport = (assignedLaundryGroupId: string) => {
    let filename: string | null = null
    laundryUsersApi
      .laundryusersBulkGetRaw(
        {
          assignedLaundryGroupId: assignedLaundryGroupId,
        },
        getRemoveAcceptHeaderInit(),
      )
      .then(async (response: ApiResponse<Blob>) => {
        filename = getDownloadFilename(response.raw)
        return response.value()
      })
      .then((blob: Blob) => {
        downloadFile(blob, filename)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        showSnackbar(errorMessage, 'error')
      })
  }

  const handleImportButtonClick = () => {
    navigate(`/${appId}/laundry-users/import`)
  }

  return (
    <ScreenLayout
      title={translate('laundryUsers')}
      actions={
        <>
          <ListingButton onClick={handleCreateButtonClick} variant="outlined" color="primary" startIcon={<Add />}>
            {translate('createNew')}
          </ListingButton>
          <MenuButton>
            <MenuItem onClick={handleExportButtonClick}>
              <ListItemIcon>
                <GetApp fontSize="small" />
              </ListItemIcon>{' '}
              {translate('exportCSV')}
            </MenuItem>
            <MenuItem onClick={handleImportButtonClick}>
              <ListItemIcon>
                <PublishIcon fontSize="small" />
              </ListItemIcon>{' '}
              {translate('importCSV')}
            </MenuItem>
          </MenuButton>
        </>
      }
    >
      <LaundryUsersTable navigateToItem={handleRowClick}></LaundryUsersTable>

      <ModalDialog
        open={exportModal}
        onClose={() => setExportModal(false)}
        title={translate('exportCSV')}
        maxWidth="sm"
      >
        <LaundryUserExportDialog setOpenModal={setExportModal} laundryGroups={laundryGroups} doExport={doExport} />
      </ModalDialog>
    </ScreenLayout>
  )
}
