import React, { ReactElement } from 'react'
import { FieldArrayPath, FieldValues, UseFieldArrayUpdate } from 'react-hook-form'
import { SettingWithExpandableGroups } from 'src/service/view-model/setting-field/SettingExpandableGroupsUtils'
import { SettingFieldWithIndex } from 'src/service/view-model/setting-field/SettingFieldUtils'
import {
  hideOrShowGroupsForBooleanField,
  hideOrShowGroupsForSelectField,
} from 'src/service/view-model/setting-field/SettingVisibilityUtils'
import {
  DynamicSettingFieldsForm,
  DynamicSettingFieldsFormProps,
} from 'src/ui-shared/base/form/dynamic-setting-form/DynamicSettingFieldsForm'
import { DynamicSettingFieldsFormContext } from 'src/ui-shared/base/form/dynamic-setting-form/DynamicSettingFieldsFormContext'
import { DynamicSettingFieldsFormInputs } from 'src/ui-shared/base/form/dynamic-setting-form/DynamicSettingFieldsFormInputs'

interface Props<T extends FieldValues, K extends FieldArrayPath<T>>
  extends Omit<DynamicSettingFieldsFormProps, 'handleOpeningExpandableGroup' | 'renderFormInput'> {
  formContext: DynamicSettingFieldsFormContext<T>
  update: UseFieldArrayUpdate<T, K>
}

export const DynamicSettingFieldsHookForm = <T extends FieldValues, K extends FieldArrayPath<T>>({
  formContext,
  update,
  ...rest
}: Props<T, K>): ReactElement => {
  const fields = rest.fields
  const form = formContext.form
  const prefix = formContext.prefix

  // events
  const handleOpeningExpandableGroupInternal = (field: SettingFieldWithIndex) => {
    if (field.groupField) {
      const updatedField: SettingWithExpandableGroups = {
        ...field,
        groupField: {
          ...field.groupField,
          // toggle expanded
          expanded: !field.groupField.expanded,
        },
      }

      // @ts-ignore
      update(field.fieldIndex, updatedField)
    }
  }

  const changeShownGroupFieldsBoolean = (field: SettingFieldWithIndex) => {
    const fieldIndex = field.fieldIndex
    const fieldName = `${prefix}.${fieldIndex}.booleanField`

    // @ts-ignore
    const booleanField = form.getValues(fieldName)
    const updatedFields = hideOrShowGroupsForBooleanField(fields, booleanField)
    if (updatedFields) {
      fields.forEach((_item, index) => {
        // @ts-ignore
        update(index, updatedFields[index])
      })
    }
  }

  const changeShownGroupFieldsSelect = (field: SettingFieldWithIndex) => {
    const fieldIndex = field.fieldIndex
    const fieldName = `${prefix}.${fieldIndex}.selectField`
    // @ts-ignore
    const selectField = form.getValues(fieldName)
    const updatedFields = hideOrShowGroupsForSelectField(fields, selectField)
    if (updatedFields) {
      fields.forEach((_item, index) => {
        // @ts-ignore
        update(index, updatedFields[index])
      })
    }
  }

  const renderFormInputInternal = (field: SettingFieldWithIndex) => {
    return (
      <DynamicSettingFieldsFormInputs
        field={field}
        form={formContext.form}
        formDisabled={formContext.formDisabled}
        loading={formContext.loading}
        prefix={formContext.prefix}
        changeShownGroupFieldsBoolean={changeShownGroupFieldsBoolean}
        changeShownGroupFieldsSelect={changeShownGroupFieldsSelect}
      />
    )
  }

  return (
    <>
      <DynamicSettingFieldsForm
        {...rest}
        renderFormInput={renderFormInputInternal}
        handleOpeningExpandableGroup={handleOpeningExpandableGroupInternal}
      />
    </>
  )
}
