import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Paper, TableCell, TableRow } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { Configuration, LaundryGroupsApi, LaundryUsersApi, OrganizationsApi } from 'src/service/backend/api'
import { LaundryGroupReference, LaundryUser, OrganizationReference } from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { isValidPositiveNumber } from 'src/service/utils/NumberUtils'
import { Data, OrderBy } from 'src/service/view-model/base/Data'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettingsWithOrganization,
  getDefaultTableSettings,
  tableSettingsSort,
  withOrganization,
} from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { TableFilterAutocompleteOrganization } from 'src/ui-shared/table/TableFilterAutocompleteOrganization'
import { TableFilterTextField } from 'src/ui-shared/table/TableFilterTextField'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'

const headCells: HeadCells[] = [
  {
    id: 'email',
    label: 'email',
  },
  {
    id: 'firstName',
    label: 'user.firstName',
  },
  {
    id: 'lastName',
    label: 'user.lastName',
  },
  {
    id: 'assignedLaundryGroups',
    label: 'laundryGroups',
    noSort: true,
  },
  {
    id: 'assignedOrganizations',
    label: 'organizations',
    noSort: true,
  },
  {
    id: 'assignedRFIDCards',
    label: 'rfidNo',
    noSort: true,
  },
]

interface LaundryUsersTableSettings extends TableSettingsWithOrganization {
  orderBy: OrderBy<keyof LaundryUser>
  assignedLaundryGroupId?: string
  assignedRFIDCardNumber?: string
}

interface Props {
  navigateToItem: (laundryUserId?: string) => void
}

export const LaundryUsersTable: React.FC<Props> = ({ navigateToItem }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  const location = useLocation()
  const translate = useTranslate()

  const activeOrganization = useActiveOrganization()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryUsersApi = new LaundryUsersApi(httpConfiguration)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)
  const organizationsApi = new OrganizationsApi(httpConfiguration)

  // state
  const [data, setData] = useState<TableData<LaundryUser>>(DEFAULT_DATA)
  const defaultTableSettings: LaundryUsersTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'email',
  }
  const [tableSettings, setTableSettings] = useState<LaundryUsersTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [laundryGroups, setLaundryGroups] = useState<LaundryGroupReference[]>([])
  const [organizations, setOrganizations] = useState<OrganizationReference[]>([])

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)

  // load data for filters
  useEffect(() => {
    laundryGroupsApi.laundrygroupsRefGet().then((data) => {
      setLaundryGroups(data)
    })
  }, [])

  useEffect(() => {
    organizationsApi.organizationsRefGet().then((data) => {
      setOrganizations(data)
    })
  }, [])

  // load data
  useEffect(() => {
    let active = true
    setLoading(true)

    laundryUsersApi
      .laundryusersGet(
        tableSettings.size,
        tableSettings.page,
        tableSettingsSort(tableSettings),
        tableSettings.search,
        undefined,
        undefined,
        tableSettings.organizationId,
        undefined,
        undefined,
        undefined,
        tableSettings.assignedLaundryGroupId,
        tableSettings.assignedRFIDCardNumber ? parseInt(tableSettings.assignedRFIDCardNumber) : undefined,
      )
      .then((data) => {
        if (active) {
          updateTableSettingsFromData(data as Data<any>, tableSettings)
          setTableSettings(tableSettings)

          setData(mapData(data as Data<any>))
          setErrorMessage(null)
          setLoading(false)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })
    return () => {
      active = false
    }
  }, [tableSettings])

  // JSX
  const nonEmptyRows = data.data.map((item) => {
    const laundryGroupsColumn = item.assignedLaundryGroups.map((laundryGroup) => laundryGroup.name).join(', ')
    const organizationsColumn = item.assignedOrganizations
      ? item.assignedOrganizations.map((organization) => organization.name).join(', ')
      : ''
    const rfidCardsColumn = item.assignedRFIDCards.map((rfid) => rfid.cardNumber).join(', ')

    return (
      <TableRow className={tableClasses.tableRow} key={item.id} onClick={navigateToItem.bind(this, item.id)}>
        <TableCell>{item.email}</TableCell>
        <TableCell>{item.firstName}</TableCell>
        <TableCell>{item.lastName}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={30} value={laundryGroupsColumn} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={30} value={organizationsColumn} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={20} value={rfidCardsColumn} />
        </TableCell>
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string) => {
    let filter
    if (headCellId === 'assignedLaundryGroups') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundryGroup')}
          label={translate('laundryGroups')}
          filter={'assignedLaundryGroupId'}
          options={laundryGroups}
          labelFieldName="name"
          valueFieldName="id"
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'assignedOrganizations') {
      filter = (
        <TableFilterAutocompleteOrganization
          options={organizations}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'assignedRFIDCards') {
      filter = (
        <TableFilterTextField
          title={translate('filterByRfid')}
          label={translate('rfids')}
          filter={'assignedRFIDCardNumber'}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          delay={500}
          isValid={(value) => {
            return isValidPositiveNumber(value)
          }}
          errorText={translate('validation.valid.rfidCard')}
        />
      )
    }
    return filter
  }

  return (
    <Paper elevation={0}>
      <TableSearchForm handleSearch={handleSearch} inputSearchValue={inputSearchValue} />

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        getFilter={getFilter}
        loading={loading}
        translate={translate}
      />
    </Paper>
  )
}
