import React, { FC, ReactElement } from 'react'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'

export interface Props {
  closeAccountEmailConfirmModal: boolean
  emailConfirmMessage: string
  closeAccount: () => void
}

export const CloseAccountEmailConfirmDialog: FC<Props> = ({
  closeAccountEmailConfirmModal,
  emailConfirmMessage,
  closeAccount,
}): ReactElement => {
  const translate = useTranslate()

  return (
    <ModalDialog open={closeAccountEmailConfirmModal} title={translate('closeAccount')} withCloseIcon={false}>
      <DialogContent>
        <Typography variant={'subtitle1'} fontWeight={400} mb={1}>
          {emailConfirmMessage}
        </Typography>
        <Typography variant={'subtitle1'} fontWeight={700} mb={1}>
          {translate('closeAccountEmailDisclaimer')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" size="large" onClick={closeAccount}>
          {translate('logout')}
        </Button>
      </DialogActions>
    </ModalDialog>
  )
}
