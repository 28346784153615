import React, { ReactElement, useContext } from 'react'
import { EnvironmentContext } from 'src/env/EnvironmentContext'
import { useTranslate } from 'src/i18n/useMessageSource'
import { FeedbackForm } from 'src/ui/layout/feedback-form/FeedbackForm'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { OnboardingSection } from 'src/ui/layout/onboarding-slideshow/OnboardingSection'
import { SLIDES_ES } from 'src/ui/page/es/index/OnboardingSlidesEs'

export const IndexPageEs = (): ReactElement => {
  const translate = useTranslate()

  const appConfig = useContext(EnvironmentContext)

  const homeIframeUrl = appConfig.homeIframeUrl

  return (
    <ScreenLayout title={translate('homePageWelcomeText', translate('easySet'))}>
      <iframe
        src={homeIframeUrl}
        title="easySet"
        width="100%"
        style={{ height: '400px', width: '100%', border: 'none' }}
      ></iframe>

      <OnboardingSection slides={SLIDES_ES} />
      <FeedbackForm />
    </ScreenLayout>
  )
}
