import React, { PropsWithChildren, ReactElement } from 'react'
import { Grid } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ChartScaleObject } from 'src/service/view-model/base/chart/Chart.const'
import { FormAccordion, FormAccordionDetails } from 'src/ui-shared/base/accordion/FormAccordion'
import { FormAccordionSummaryDataFilter } from 'src/ui-shared/base/accordion/FormAccordionSummaryDataFilter'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'

interface Props<T extends { id?: any }, K extends { id?: any }, L> extends PropsWithChildren {
  chartSettings: L
  setChartSettings: (value: L) => void
  factType?: keyof L
  groupBy: keyof L
  scale: keyof L
  factTypeOptions?: T[]
  factTypeOptionsLabel?: (option: T) => string
  factTypeValue?: (value: any) => NonNullable<T> | undefined
  groupByOptions: K[]
  groupByOptionsLabel: (option: K) => string
  groupByValue: (value: any) => NonNullable<K> | undefined
  scaleOptions: ChartScaleObject[]
  scaleOptionsLabel: (option: ChartScaleObject) => string
  scaleValue: (value: any) => NonNullable<ChartScaleObject> | undefined
}

export const ChartDataSelection = <T extends { id?: any }, K extends { id?: any }, L>({
  chartSettings,
  setChartSettings,
  factType,
  groupBy,
  scale,
  factTypeOptions,
  factTypeOptionsLabel,
  factTypeValue,
  groupByOptions,
  groupByOptionsLabel,
  groupByValue,
  scaleOptions,
  scaleOptionsLabel,
  scaleValue,
  children,
}: Props<T, K, L>): ReactElement => {
  const { classes: textFieldClasses } = useTextFieldStyles()
  const translate = useTranslate()

  const handleGroupByChange = (_event: React.SyntheticEvent, groupByObject: K) => {
    setChartSettings({
      ...chartSettings,
      [groupBy]: groupByObject.id,
    })
  }

  const handleFactTypeChange = (_event: React.SyntheticEvent, factTypeObject: T) => {
    if (factType) {
      setChartSettings({
        ...chartSettings,
        [factType]: factTypeObject.id,
      })
    }
  }

  const handleChartScaleChange = (_event: React.SyntheticEvent, scaleObject: ChartScaleObject) => {
    setChartSettings({
      ...chartSettings,
      [scale]: scaleObject.id,
    })
  }

  return (
    <>
      <FormAccordion defaultExpanded={true}>
        <FormAccordionSummaryDataFilter />
        <FormAccordionDetails>
          <Grid container spacing={2}>
            {factType && factTypeValue && factTypeOptions ? (
              <Grid item {...ITEM_BREAKPOINTS} sm={6} md={4} lg={true}>
                <Autocomplete
                  openOnFocus={true}
                  options={factTypeOptions}
                  getOptionLabel={factTypeOptionsLabel}
                  value={factTypeValue(chartSettings[factType])}
                  disabled={factTypeOptions.length === 1}
                  disableClearable={true}
                  multiple={false}
                  freeSolo={false}
                  onChange={handleFactTypeChange}
                  noOptionsText={translate('autocompleteNoOptions')}
                  loadingText={translate('autocompleteLoading')}
                  renderInput={(params) => (
                    <TextFieldDefault
                      {...params}
                      label={translate('data')}
                      className={textFieldClasses.TextFieldSmall}
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item {...ITEM_BREAKPOINTS} sm={6} md={4} lg={true}>
              <Autocomplete
                openOnFocus={true}
                options={groupByOptions}
                getOptionLabel={groupByOptionsLabel}
                value={groupByValue(chartSettings[groupBy])}
                disabled={groupByOptions.length === 1}
                disableClearable={true}
                multiple={false}
                freeSolo={false}
                onChange={handleGroupByChange}
                noOptionsText={translate('autocompleteNoOptions')}
                loadingText={translate('autocompleteLoading')}
                renderInput={(params) => (
                  <TextFieldDefault
                    {...params}
                    label={translate('groupBy')}
                    className={textFieldClasses.TextFieldSmall}
                  />
                )}
              />
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS} sm={6} md={4} lg={true}>
              <Autocomplete
                openOnFocus={true}
                options={scaleOptions}
                getOptionLabel={scaleOptionsLabel}
                value={scaleValue(chartSettings[scale])}
                disabled={scaleOptions.length === 1}
                disableClearable={true}
                multiple={false}
                freeSolo={false}
                onChange={handleChartScaleChange}
                noOptionsText={translate('autocompleteNoOptions')}
                loadingText={translate('autocompleteLoading')}
                renderInput={(params) => (
                  <TextFieldDefault
                    {...params}
                    label={translate('chartScale')}
                    className={textFieldClasses.TextFieldSmall}
                  />
                )}
              />
            </Grid>
            {children}
          </Grid>
        </FormAccordionDetails>
      </FormAccordion>
    </>
  )
}
