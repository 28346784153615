/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Permission {
    AUDIT_EVENT_READ = 'AUDIT_EVENT_READ',
    BACKEND_USER_READ = 'BACKEND_USER_READ',
    CALENDAR_ENTRY_READ = 'CALENDAR_ENTRY_READ',
    CALENDAR_ENTRY_WRITE = 'CALENDAR_ENTRY_WRITE',
    CONSUMPTION_READ = 'CONSUMPTION_READ',
    CUSTOM_PROGRAM_READ = 'CUSTOM_PROGRAM_READ',
    CUSTOM_PROGRAM_WRITE = 'CUSTOM_PROGRAM_WRITE',
    DAILY_USAGE_READ = 'DAILY_USAGE_READ',
    EASYSET_PROGRAM_READ = 'EASYSET_PROGRAM_READ',
    EASYSET_PROGRAM_WRITE = 'EASYSET_PROGRAM_WRITE',
    ERROR_LOG_READ = 'ERROR_LOG_READ',
    HYGIENEMONITORING_READ = 'HYGIENEMONITORING_READ',
    INVITATION_READ = 'INVITATION_READ',
    INVITATION_WRITE = 'INVITATION_WRITE',
    INVOICE_ACTIONS = 'INVOICE_ACTIONS',
    INVOICE_CORRECTION = 'INVOICE_CORRECTION',
    INVOICE_READ = 'INVOICE_READ',
    ISSUE_STATE_UPDATE = 'ISSUE_STATE_UPDATE',
    ISSUE_ACTION_TO_APPROVE = 'ISSUE_ACTION_TO_APPROVE',
    ISSUE_ACTION_TO_IN_PROGRESS = 'ISSUE_ACTION_TO_IN_PROGRESS',
    ISSUE_ACTION_TO_CLOSED = 'ISSUE_ACTION_TO_CLOSED',
    ISSUE_ACTION_UNKNOWN_TO_CLOSED = 'ISSUE_ACTION_UNKNOWN_TO_CLOSED',
    LAUNDRY_GROUP_READ = 'LAUNDRY_GROUP_READ',
    LAUNDRY_GROUP_WRITE = 'LAUNDRY_GROUP_WRITE',
    LAUNDRY_GROUP_WRITE_FEATURE_TOGGLE = 'LAUNDRY_GROUP_WRITE_FEATURE_TOGGLE',
    LAUNDRY_READ = 'LAUNDRY_READ',
    LAUNDRY_USER_BULK_IMPORT = 'LAUNDRY_USER_BULK_IMPORT',
    LAUNDRY_USER_GENERATE_BANK_SLIP = 'LAUNDRY_USER_GENERATE_BANK_SLIP',
    LAUNDRY_USER_RESET_PASSWORD = 'LAUNDRY_USER_RESET_PASSWORD',
    LAUNDRY_USER_READ = 'LAUNDRY_USER_READ',
    LAUNDRY_USER_WRITE = 'LAUNDRY_USER_WRITE',
    LAUNDRY_WRITE = 'LAUNDRY_WRITE',
    MACHINE_ACTION_START_USAGE = 'MACHINE_ACTION_START_USAGE',
    MACHINE_LAB_ACTIONS = 'MACHINE_LAB_ACTIONS',
    MACHINE_READ = 'MACHINE_READ',
    MACHINE_THING_NAME_READ = 'MACHINE_THING_NAME_READ',
    MACHINE_WRITE = 'MACHINE_WRITE',
    MACHINE_WRITE_PARAMETERS = 'MACHINE_WRITE_PARAMETERS',
    MANUFACTURER_READ = 'MANUFACTURER_READ',
    MANUFACTURER_WRITE = 'MANUFACTURER_WRITE',
    MANUFACTURER_MODEL_READ = 'MANUFACTURER_MODEL_READ',
    MANUFACTURER_MODEL_WRITE = 'MANUFACTURER_MODEL_WRITE',
    MANUFACTURER_MODEL_DETAIL_READ = 'MANUFACTURER_MODEL_DETAIL_READ',
    MEMBER_READ = 'MEMBER_READ',
    MEMBER_WRITE = 'MEMBER_WRITE',
    NOTIFICATION_SEND = 'NOTIFICATION_SEND',
    ORGANIZATION_READ = 'ORGANIZATION_READ',
    ORGANIZATION_WRITE = 'ORGANIZATION_WRITE',
    PART_MASTER_READ = 'PART_MASTER_READ',
    RFID_CARD_READ = 'RFID_CARD_READ',
    PREPAID_BALANCE_ADJUST = 'PREPAID_BALANCE_ADJUST',
    PREPAID_BALANCE_TOPUP = 'PREPAID_BALANCE_TOPUP',
    PRICE_LIST_READ = 'PRICE_LIST_READ',
    PRICE_LIST_WRITE = 'PRICE_LIST_WRITE',
    PROGRAM_HISTORY_READ = 'PROGRAM_HISTORY_READ',
    ROLE_PERMISSION_READ = 'ROLE_PERMISSION_READ',
    ROLE_PERMISSION_WRITE = 'ROLE_PERMISSION_WRITE',
    SERVICE_MEMBER_READ = 'SERVICE_MEMBER_READ',
    SERVICE_MEMBER_WRITE = 'SERVICE_MEMBER_WRITE',
    SERVICE_PARTNER_READ = 'SERVICE_PARTNER_READ',
    SERVICE_PARTNER_WRITE = 'SERVICE_PARTNER_WRITE',
    SERVICE_REQUEST_READ = 'SERVICE_REQUEST_READ',
    SERVICE_REQUEST_WRITE = 'SERVICE_REQUEST_WRITE',
    SUBSCRIPTION_LAUNDRY_GROUP_READ = 'SUBSCRIPTION_LAUNDRY_GROUP_READ',
    SUBSCRIPTION_LAUNDRY_GROUP_WRITE = 'SUBSCRIPTION_LAUNDRY_GROUP_WRITE',
    TOP_UP_READ = 'TOP_UP_READ',
    TRANSACTION_READ = 'TRANSACTION_READ',
    TRANSACTION_REFUND = 'TRANSACTION_REFUND',
    USAGE_READ = 'USAGE_READ',
    UTILIZATION_READ = 'UTILIZATION_READ',
    WASHQLATOR_READ = 'WASHQLATOR_READ'
}


export function PermissionFromJSON(json: any): Permission {
    return PermissionFromJSONTyped(json, false);
}

export function PermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permission {
    return json as Permission;
}

export function PermissionToJSON(value?: Permission | null): any {
    return value as any;
}

