/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface InvoiceCorrectionRequest
 */
export interface InvoiceCorrectionRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceCorrectionRequest
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCorrectionRequest
     */
    amount: number;
    /**
     * 
     * @type {Currency}
     * @memberof InvoiceCorrectionRequest
     */
    currency: Currency;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCorrectionRequest
     */
    additionalInfo: string;
}

/**
 * Check if a given object implements the InvoiceCorrectionRequest interface.
 */
export function instanceOfInvoiceCorrectionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "additionalInfo" in value;

    return isInstance;
}

export function InvoiceCorrectionRequestFromJSON(json: any): InvoiceCorrectionRequest {
    return InvoiceCorrectionRequestFromJSONTyped(json, false);
}

export function InvoiceCorrectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceCorrectionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quantity': json['quantity'],
        'amount': json['amount'],
        'currency': CurrencyFromJSON(json['currency']),
        'additionalInfo': json['additionalInfo'],
    };
}

export function InvoiceCorrectionRequestToJSON(value?: InvoiceCorrectionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quantity': value.quantity,
        'amount': value.amount,
        'currency': CurrencyToJSON(value.currency),
        'additionalInfo': value.additionalInfo,
    };
}

