import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { useTranslate } from 'src/i18n/useMessageSource'
import { TIMEZONES, TimezoneObject } from 'src/service/view-model/base/localization/Timezones'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

export interface TimezoneAutoCompleteProps {
  onChange: (option: TimezoneObject | undefined) => void
  name: string
  value: TimezoneObject | undefined
  disabled: boolean
  error?: boolean
}

export const TimezoneAutoComplete: React.FC<TimezoneAutoCompleteProps> = ({
  onChange,
  value,
  name,
  disabled = false,
  error,
}) => {
  const translate = useTranslate()

  const onHandleChange = (event: React.SyntheticEvent, object: TimezoneObject | null) => {
    onChange(object ? object : undefined)
  }

  return (
    <Autocomplete
      id="timezone-autocomplete"
      openOnFocus={true}
      options={TIMEZONES}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      autoHighlight
      disableClearable={false}
      multiple={false}
      freeSolo={false}
      onChange={onHandleChange}
      disabled={disabled}
      value={value}
      groupBy={(option) => option.group}
      renderInput={(params) => <TextFieldDefault name={name} error={error} {...params} label={translate('timezone')} />}
    />
  )
}
