export enum LaundryGroupTab {
  OVERVIEW = 'overview',
  MESSAGES = 'messages',
  PRICES = 'prices',
  SERVICE_PLANS = 'service-plans',
  PROGRAM_INSTALLATIONS = 'program-installations',
  CALENDAR = 'calendar',
  INVOICES = 'invoices',
  BLOCKED_DAYS = 'blocked-days',
}
