import React, { FC, ReactElement, useState } from 'react'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { CheckboxGroup } from 'src/ui-shared/base/form/control/CheckboxGroup'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'

export interface Props {
  closeAccountModal: boolean
  setCloseAccountModal: (value: boolean) => void
  onConfirm: () => void
  label?: string
}

export const CloseAccountDialog: FC<Props> = ({
  closeAccountModal,
  setCloseAccountModal,
  onConfirm,
  label,
}): ReactElement => {
  const translate = useTranslate()

  const [agreements, setAgreements] = useState({
    closeAccountAgreement1: false,
    closeAccountAgreement2: false,
  })

  const handleAgreementChange = (selectedAgreements: any) => {
    setAgreements(selectedAgreements)
  }

  const disableConfirmationButton = (): boolean => {
    return !Object.values(agreements).every((item) => item)
  }

  return (
    <ModalDialog
      open={closeAccountModal}
      onClose={() => setCloseAccountModal(false)}
      title={translate('closeAccount')}
      maxWidth="xs"
      withCloseIcon={true}
    >
      <DialogContent>
        {label && (
          <Typography variant={'subtitle1'} fontWeight={700} mb={1}>
            {label}
          </Typography>
        )}
        <Typography mb={1}>{translate('closeAccountDisclaimer')}</Typography>
        <CheckboxGroup value={agreements} onChange={handleAgreementChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setCloseAccountModal(false)} color="primary" variant="text">
          {translate('button.cancel')}
        </Button>
        <Button autoFocus onClick={onConfirm} color="primary" variant="text" disabled={disableConfirmationButton()}>
          {translate('button.delete')}
        </Button>
      </DialogActions>
    </ModalDialog>
  )
}
