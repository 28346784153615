export enum MachineTab {
  OVERVIEW = 'overview',
  ERROR_CODES = 'error-codes',
  PROGRAM_HISTORY = 'program-history',
  CALENDAR = 'calendar',
  PARAMETERS = 'parameters',
  MACHINE_INSIGHTS = 'machine-insights',
  PROGRAMS = 'programs',
  PROGRAM_INSTALLATIONS = 'program-installations',
  LAB_ACTIONS = 'lab-actions',
}
