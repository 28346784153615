/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * size of current result
     * @type {number}
     * @memberof Page
     */
    size: number;
    /**
     * total count of all elements
     * @type {number}
     * @memberof Page
     */
    totalElements: number;
    /**
     * total count of all pages
     * @type {number}
     * @memberof Page
     */
    totalPages: number;
    /**
     * page number of current result
     * @type {number}
     * @memberof Page
     */
    number: number;
}

/**
 * Check if a given object implements the Page interface.
 */
export function instanceOfPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "totalElements" in value;
    isInstance = isInstance && "totalPages" in value;
    isInstance = isInstance && "number" in value;

    return isInstance;
}

export function PageFromJSON(json: any): Page {
    return PageFromJSONTyped(json, false);
}

export function PageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Page {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'number': json['number'],
    };
}

export function PageToJSON(value?: Page | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'size': value.size,
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
        'number': value.number,
    };
}

