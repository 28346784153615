/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaundryGroupRecurringCalendarEntry } from './LaundryGroupRecurringCalendarEntry';
import {
    LaundryGroupRecurringCalendarEntryFromJSON,
    LaundryGroupRecurringCalendarEntryFromJSONTyped,
    LaundryGroupRecurringCalendarEntryToJSON,
} from './LaundryGroupRecurringCalendarEntry';

/**
 * 
 * @export
 * @interface LaundryGroupRecurringCalendarDay
 */
export interface LaundryGroupRecurringCalendarDay {
    /**
     * 1 = Monday, 7 = Sunday
     * @type {number}
     * @memberof LaundryGroupRecurringCalendarDay
     */
    dayOfWeek: number;
    /**
     * 
     * @type {Array<LaundryGroupRecurringCalendarEntry>}
     * @memberof LaundryGroupRecurringCalendarDay
     */
    entries: Array<LaundryGroupRecurringCalendarEntry>;
}

/**
 * Check if a given object implements the LaundryGroupRecurringCalendarDay interface.
 */
export function instanceOfLaundryGroupRecurringCalendarDay(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dayOfWeek" in value;
    isInstance = isInstance && "entries" in value;

    return isInstance;
}

export function LaundryGroupRecurringCalendarDayFromJSON(json: any): LaundryGroupRecurringCalendarDay {
    return LaundryGroupRecurringCalendarDayFromJSONTyped(json, false);
}

export function LaundryGroupRecurringCalendarDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupRecurringCalendarDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dayOfWeek': json['dayOfWeek'],
        'entries': ((json['entries'] as Array<any>).map(LaundryGroupRecurringCalendarEntryFromJSON)),
    };
}

export function LaundryGroupRecurringCalendarDayToJSON(value?: LaundryGroupRecurringCalendarDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dayOfWeek': value.dayOfWeek,
        'entries': ((value.entries as Array<any>).map(LaundryGroupRecurringCalendarEntryToJSON)),
    };
}

