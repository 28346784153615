import React from 'react'
import { Icon } from '@mdi/react'
import { SvgIcon } from '@mui/material'
import {
  DomainOutlined,
  GroupOutlined,
  HomeOutlined,
  HomeWorkOutlined,
  Language,
  SettingsInputSvideo,
} from '@mui/icons-material'
import ChecklistIcon from '@mui/icons-material/Checklist'
import { mdiAccountTieOutline, mdiFinance, mdiKey, mdiPiggyBankOutline } from '@mdi/js'
import { ReactComponent as LaundryIcon } from 'src/assets/laundry-wm.svg'
import { ReactComponent as MachineIcon } from 'src/assets/washer-wm.svg'
import { WmMenuSections } from 'src/routing/MenuSections'
import { RoutesList } from 'src/routing/Routing'
import { APP_SHARED_ROUTES } from 'src/routing/SharedRoutes'
import { Permission } from 'src/service/backend/api/models'
import { ServicePackagePlanDetailsPage } from 'src/ui/layout-page/service-package-plan/details/ServicePackagePlanDetailsPage'
import { ServicePackagePlanUpgradePage } from 'src/ui/layout-page/service-package-plan/details/ServicePackagePlanUpgradePage'
import { ServicePackagePlanLaundryGroupListPage } from 'src/ui/layout-page/service-package-plan/list/ServicePackagePlanLaundryGroupListPage'
import { LaundryGroupCreatePage } from 'src/ui/page/common/laundry-group/details/LaundryGroupCreatePage'
import { LaundryGroupDetailsPage } from 'src/ui/page/common/laundry-group/details/LaundryGroupDetailsPage'
import { LaundryGroupListPage } from 'src/ui/page/common/laundry-group/list/LaundryGroupListPage'
import { LaundryGroupPriceListEditPage } from 'src/ui/page/common/laundry-group/price-list/LaundryGroupPriceListEditPage'
import { LaundryGroupPriceListPage } from 'src/ui/page/common/laundry-group/price-list/LaundryGroupPriceListPage'
import { LaundryCreatePage } from 'src/ui/page/common/laundry/details/LaundryCreatePage'
import { LaundryDetailsPage } from 'src/ui/page/common/laundry/details/LaundryDetailsPage'
import { LaundryListPage } from 'src/ui/page/common/laundry/list/LaundryListPage'
import { MachineDetailsPage } from 'src/ui/page/common/machine/details/MachineDetailsPage'
import { MachineEditPage } from 'src/ui/page/common/machine/details/MachineEditPage'
import { MachineParametersEditPage } from 'src/ui/page/common/machine/details/MachineParametersEditPage'
import { MachineProductDataPage } from 'src/ui/page/common/machine/details/MachineProductDataPage'
import { MachineQrCodeScanner } from 'src/ui/page/common/machine/details/MachineQrCodeScanner'
import { MachineListPage } from 'src/ui/page/common/machine/list/MachineListPage'
import { MemberDetailsPage } from 'src/ui/page/common/member/details/MemberDetailsPage'
import { MemberEditPage } from 'src/ui/page/common/member/details/MemberEditPage'
import { MemberListPage } from 'src/ui/page/common/member/list/MemberListPage'
import { OrganizationCreatePage } from 'src/ui/page/common/organization/details/OrganizationCreatePage'
import { OrganizationDetailsPage } from 'src/ui/page/common/organization/details/OrganizationDetailsPage'
import { OrganizationListPage } from 'src/ui/page/common/organization/list/OrganizationListPage'
import { ServicePartnerCreatePage } from 'src/ui/page/common/service-partner/details/ServicePartnerCreatePage'
import { ServicePartnerDetailsPage } from 'src/ui/page/common/service-partner/details/ServicePartnerDetailsPage'
import { ServicePartnerListPage } from 'src/ui/page/common/service-partner/list/ServicePartnerListPage'
import { IndexPageWm } from 'src/ui/page/wm/index/IndexPageWm'
import { LaundryUserCreatePage } from 'src/ui/page/wm/laundry-user/details/LaundryUserCreatePage'
import { LaundryUserDetailsPage } from 'src/ui/page/wm/laundry-user/details/LaundryUserDetailsPage'
import { LaundryUserImportPage } from 'src/ui/page/wm/laundry-user/details/LaundryUserImportPage'
import { LaundryUsersListPage } from 'src/ui/page/wm/laundry-user/list/LaundryUserListPage'
import { ManufacturerModelCreatePage } from 'src/ui/page/wm/manufacturer-model/details/ManufacturerModelCreatePage'
import { ManufacturerModelDetailsPage } from 'src/ui/page/wm/manufacturer-model/details/ManufacturerModelDetailsPage'
import { ManufacturerModelListPage } from 'src/ui/page/wm/manufacturer-model/list/ManufacturerModelListPage'
import { ManufacturerCreatePage } from 'src/ui/page/wm/manufacturer/details/ManufacturerCreatePage'
import { ManufacturerDetailsPage } from 'src/ui/page/wm/manufacturer/details/ManufacturerDetailsPage'
import { ManufacturerListPage } from 'src/ui/page/wm/manufacturer/list/ManufacturerListPage'
import { RolePermissionEditPage } from 'src/ui/page/wm/role-permission/RolePermissionEditPage'
import { RolePermissionListPage } from 'src/ui/page/wm/role-permission/RolePermissionListPage'
import { TopUpListPage } from 'src/ui/page/wm/topup/list/TopUpListPage'
import { UsageListPage } from 'src/ui/page/wm/usage/list/UsageListPage'

export const WASHMASTER_ROUTES: RoutesList = {
  IndexPageWm: {
    path: '',
    label: 'home',
    googleAnalyticsTitle: 'Home',
    Component: IndexPageWm,
    sectionLabel: WmMenuSections.OVERVIEWS,
    icon: <HomeOutlined />,
  },
  ServicePartnerListPage: {
    path: 'service-partners',
    label: 'servicePartners',
    googleAnalyticsTitle: 'Service Partners',
    Component: ServicePartnerListPage,
    permission: Permission.SERVICE_PARTNER_READ,
    sectionLabel: WmMenuSections.OVERVIEWS,
    icon: <Language />,
  },
  ServicePartnerDetailsPage: {
    path: 'service-partners/:organizationId/view',
    googleAnalyticsTitle: 'Service Partner Details',
    Component: ServicePartnerDetailsPage,
    permission: Permission.SERVICE_PARTNER_READ,
  },
  ServicePartnerCreatePage: {
    path: 'service-partners/create',
    googleAnalyticsTitle: 'Service Partner Create',
    Component: ServicePartnerCreatePage,
    permission: Permission.SERVICE_PARTNER_WRITE,
  },
  ServicePartnerEditPage: {
    path: 'service-partners/:organizationId/edit',
    googleAnalyticsTitle: 'Service Partner Edit',
    Component: ServicePartnerCreatePage,
    permission: Permission.SERVICE_PARTNER_WRITE,
  },
  OrganizationListPage: {
    path: 'organizations',
    label: 'organizations',
    googleAnalyticsTitle: 'Organizations',
    Component: OrganizationListPage,
    permission: Permission.ORGANIZATION_READ,
    sectionLabel: WmMenuSections.OVERVIEWS,
    icon: <DomainOutlined />,
  },
  OrganizationDetailsPage: {
    path: 'organizations/:organizationId/view',
    googleAnalyticsTitle: 'Organization Details',
    Component: OrganizationDetailsPage,
    permission: Permission.ORGANIZATION_READ,
  },
  OrganizationEditPage: {
    path: 'organizations/:organizationId/edit',
    googleAnalyticsTitle: 'Organization Edit',
    Component: OrganizationCreatePage,
    permission: Permission.ORGANIZATION_WRITE,
  },
  LaundryGroupListPage: {
    path: 'laundry-groups',
    label: 'laundryGroups',
    googleAnalyticsTitle: 'Laundry Groups',
    Component: LaundryGroupListPage,
    permission: Permission.LAUNDRY_GROUP_READ,
    sectionLabel: WmMenuSections.OVERVIEWS,
    icon: <HomeWorkOutlined />,
  },
  LaundryGroupDetailsPage: {
    path: 'laundry-groups/:laundryGroupId/view/:tabNameParam',
    googleAnalyticsTitle: 'Laundry Group Details',
    Component: LaundryGroupDetailsPage,
    permission: Permission.LAUNDRY_GROUP_READ,
  },
  LaundryGroupCreatePage: {
    path: 'laundry-groups/create',
    googleAnalyticsTitle: 'Laundry Group Create',
    Component: LaundryGroupCreatePage,
    permission: Permission.LAUNDRY_GROUP_WRITE,
  },
  LaundryGroupEditPage: {
    path: 'laundry-groups/:laundryGroupId/edit',
    googleAnalyticsTitle: 'Laundry Group Edit',
    Component: LaundryGroupCreatePage,
    permission: Permission.LAUNDRY_GROUP_WRITE,
  },
  LaundryGroupPriceListPage: {
    path: 'laundry-groups/:laundryGroupId/view/prices/:machineTypeParam',
    googleAnalyticsTitle: 'Laundry Group PriceList',
    Component: LaundryGroupPriceListPage,
    permission: Permission.PRICE_LIST_READ,
  },
  LaundryGroupPriceListEditPage: {
    path: 'laundry-groups/:laundryGroupId/view/prices/:machineTypeParam/edit',
    googleAnalyticsTitle: 'Laundry Group PriceList Edit',
    Component: LaundryGroupPriceListEditPage,
    permission: Permission.PRICE_LIST_WRITE,
  },

  LaundryListPage: {
    path: 'laundries',
    label: 'laundries',
    googleAnalyticsTitle: 'Laundries',
    Component: LaundryListPage,
    permission: Permission.LAUNDRY_READ,
    sectionLabel: WmMenuSections.OVERVIEWS,
    icon: (
      <SvgIcon>
        <LaundryIcon />
      </SvgIcon>
    ),
  },
  LaundryDetailsPage: {
    path: 'laundries/:laundryId/view',
    googleAnalyticsTitle: 'Laundry Details',
    Component: LaundryDetailsPage,
    permission: Permission.LAUNDRY_READ,
  },
  LaundryCreatePage: {
    path: 'laundries/create',
    googleAnalyticsTitle: 'Laundry Create',
    Component: LaundryCreatePage,
    permission: Permission.LAUNDRY_WRITE,
  },
  LaundryEditPage: {
    path: 'laundries/:laundryId/edit',
    googleAnalyticsTitle: 'Laundry Edit',
    Component: LaundryCreatePage,
    permission: Permission.LAUNDRY_WRITE,
  },
  MachineListPage: {
    path: 'machines',
    label: 'machines',
    googleAnalyticsTitle: 'Machines',
    Component: MachineListPage,
    permission: Permission.MACHINE_READ,
    sectionLabel: WmMenuSections.OVERVIEWS,
    icon: (
      <SvgIcon>
        <MachineIcon />
      </SvgIcon>
    ),
  },
  MachineQrCodeScanner: {
    path: 'machines/:machineId/scanner',
    googleAnalyticsTitle: 'Machine QR Scanner',
    Component: MachineQrCodeScanner,
    permission: Permission.MACHINE_WRITE,
  },
  MachineDetailsPage: {
    path: 'machines/:machineId/view/:tabNameParam',
    googleAnalyticsTitle: 'Machine Details',
    Component: MachineDetailsPage,
    permission: Permission.MACHINE_READ,
  },
  MachineEditPage: {
    path: 'machines/:machineId/edit',
    googleAnalyticsTitle: 'Machine Edit',
    Component: MachineEditPage,
    permission: Permission.MACHINE_WRITE,
  },
  MachineProductDataPage: {
    path: 'machines/:machineId/manufacturer-model/:manufacturerModelId/',
    googleAnalyticsTitle: 'Machine Product Data',
    Component: MachineProductDataPage,
    permission: Permission.MACHINE_READ,
  },
  MachineProductDataPageModelOnly: {
    path: 'machines/manufacturer-model/:manufacturerModelId/',
    googleAnalyticsTitle: 'Machine Product Data',
    Component: MachineProductDataPage,
    permission: Permission.MACHINE_READ,
  },
  MachineParametersEditPage: {
    path: 'machines/:machineId/view/parameters/edit',
    googleAnalyticsTitle: 'Machine Parameters Edit',
    Component: MachineParametersEditPage,
    permission: Permission.MACHINE_WRITE_PARAMETERS,
  },

  LaundryUsersListPage: {
    path: 'laundry-users',
    label: 'laundryUsers',
    googleAnalyticsTitle: 'Laundry Users',
    Component: LaundryUsersListPage,
    permission: Permission.LAUNDRY_USER_READ,
    sectionLabel: WmMenuSections.USER_MANAGEMENT,
    icon: <GroupOutlined />,
  },
  LaundryUserDetailsPage: {
    path: 'laundry-users/:laundryUserId/view/:tabNameParam',
    googleAnalyticsTitle: 'Laundry Users Details',
    Component: LaundryUserDetailsPage,
    permission: Permission.LAUNDRY_USER_READ,
  },
  LaundryUserCreatePage: {
    path: 'laundry-users/create',
    googleAnalyticsTitle: 'Laundry User Create',
    Component: LaundryUserCreatePage,
    permission: Permission.LAUNDRY_USER_WRITE,
  },
  LaundryUserEditPage: {
    path: 'laundry-users/:laundryUserId/edit',
    googleAnalyticsTitle: 'Laundry User Edit',
    Component: LaundryUserCreatePage,
    permission: Permission.LAUNDRY_USER_WRITE,
  },
  LaundryUserImportPage: {
    path: 'laundry-users/import',
    googleAnalyticsTitle: 'Laundry User Import',
    Component: LaundryUserImportPage,
    permission: Permission.LAUNDRY_USER_BULK_IMPORT,
  },

  MemberListPage: {
    path: 'members',
    label: 'members',
    googleAnalyticsTitle: 'Members',
    Component: MemberListPage,
    permission: Permission.MEMBER_READ,
    sectionLabel: WmMenuSections.USER_MANAGEMENT,
    icon: <Icon path={mdiAccountTieOutline} size={1} />,
  },
  MemberListPageTab: {
    path: 'members/:tabNameParam',
    googleAnalyticsTitle: 'Members',
    Component: MemberListPage,
    permission: Permission.MEMBER_READ,
  },
  MemberDetailsPage: {
    path: 'members/:memberId/view',
    googleAnalyticsTitle: 'Member Details',
    Component: MemberDetailsPage,
    permission: Permission.MEMBER_READ,
  },
  MemberEditPage: {
    path: 'members/:memberId/edit',
    googleAnalyticsTitle: 'Member Edit',
    Component: MemberEditPage,
    permission: Permission.MEMBER_WRITE,
  },

  UsageListPage: {
    path: 'usages/view',
    googleAnalyticsTitle: 'Usages',
    label: 'usages',
    Component: UsageListPage,
    permission: Permission.USAGE_READ,
    sectionLabel: WmMenuSections.REPORTS_ANALYSES,
    icon: <Icon path={mdiFinance} size={1} />,
  },

  UsageListPageTab: {
    path: 'usages/view/:tabNameParam',
    googleAnalyticsTitle: 'Usages',
    Component: UsageListPage,
    permission: Permission.USAGE_READ,
  },

  TopUpListPage: {
    path: 'top-ups/view',
    label: 'topups',
    googleAnalyticsTitle: 'TopUps',
    Component: TopUpListPage,
    permission: Permission.TOP_UP_READ,
    sectionLabel: WmMenuSections.REPORTS_ANALYSES,
    icon: <Icon path={mdiPiggyBankOutline} size={1} />,
  },

  TopUpListPageTab: {
    path: 'top-ups/view/:tabNameParam',
    googleAnalyticsTitle: 'TopUps',
    Component: TopUpListPage,
    permission: Permission.TOP_UP_READ,
  },

  ServicePackagePlanListPage: {
    path: 'service-package-plan',
    googleAnalyticsTitle: 'Service Package Plan',
    Component: ServicePackagePlanLaundryGroupListPage,
  },
  ServicePackagePlanDetailsPage: {
    path: 'service-package-plan/:laundryGroupId/view',
    googleAnalyticsTitle: 'Service Package Plan Details',
    Component: ServicePackagePlanDetailsPage,
  },
  ServicePackagePlanUpgradePage: {
    path: 'service-package-plan/:laundryGroupId/view/upgrade/:servicePackagePlanId',
    googleAnalyticsTitle: 'Service Package Plan Upgrade',
    Component: ServicePackagePlanUpgradePage,
  },
  ServicePackagePlanUpgradeStep: {
    path: 'service-package-plan/:laundryGroupId/step/upgrade',
    googleAnalyticsTitle: 'Service Package Plan Upgrade Step',
    Component: ServicePackagePlanUpgradePage,
  },
  RolesAndPermissionsPage: {
    path: 'roles',
    googleAnalyticsTitle: 'Roles and Permissions',
    label: 'rolesAndPermissions',
    Component: RolePermissionListPage,
    permission: Permission.ROLE_PERMISSION_READ,
    sectionLabel: WmMenuSections.ADMINISTRATION,
    icon: <Icon path={mdiKey} size={1} />,
  },
  RolesAndPermissionsEditPage: {
    path: 'roles/edit',
    googleAnalyticsTitle: 'Roles and Permissions Edit',
    Component: RolePermissionEditPage,
    permission: Permission.ROLE_PERMISSION_WRITE,
  },
  ManufacturerListPage: {
    path: 'manufacturers',
    googleAnalyticsTitle: 'Manufacturers',
    label: 'manufacturer',
    Component: ManufacturerListPage,
    permission: Permission.MANUFACTURER_WRITE,
    sectionLabel: WmMenuSections.ADMINISTRATION,
    icon: <ChecklistIcon />,
  },
  ManufacturerDetailsPage: {
    path: 'manufacturers/:manufacturerId/view',
    googleAnalyticsTitle: 'Manufacturer Details',
    Component: ManufacturerDetailsPage,
    permission: Permission.MANUFACTURER_WRITE,
  },
  ManufacturerCreatePage: {
    path: 'manufacturers/create',
    googleAnalyticsTitle: 'Manufacturer Details Create',
    Component: ManufacturerCreatePage,
    permission: Permission.MANUFACTURER_WRITE,
  },
  ManufacturerEditPage: {
    path: 'manufacturers/:manufacturerId/edit',
    googleAnalyticsTitle: 'Manufacturer Details Edit',
    Component: ManufacturerCreatePage,
    permission: Permission.MANUFACTURER_WRITE,
  },
  ManufacturerModelListPage: {
    path: 'manufacturer-models',
    googleAnalyticsTitle: 'Manufacturer Models',
    label: 'manufacturerModel',
    Component: ManufacturerModelListPage,
    permission: Permission.MANUFACTURER_MODEL_WRITE,
    sectionLabel: WmMenuSections.ADMINISTRATION,
    icon: <SettingsInputSvideo />,
  },
  ManufacturerModelDetailsPage: {
    path: 'manufacturer-models/:manufacturerModelId/view',
    googleAnalyticsTitle: 'Manufacturer Model Details',
    Component: ManufacturerModelDetailsPage,
    permission: Permission.MANUFACTURER_MODEL_WRITE,
  },
  ManufacturerModelEditPage: {
    path: 'manufacturer-models/:manufacturerModelId/edit',
    googleAnalyticsTitle: 'Manufacturer Model Edit',
    Component: ManufacturerModelCreatePage,
    permission: Permission.MANUFACTURER_MODEL_WRITE,
  },
  ManufacturerModelCreatePage: {
    path: 'manufacturer-models/create',
    googleAnalyticsTitle: 'Manufacturer Model Create',
    Component: ManufacturerModelCreatePage,
    permission: Permission.MANUFACTURER_MODEL_WRITE,
  },

  ...APP_SHARED_ROUTES,
}
