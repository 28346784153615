import React, { FC, ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import locale from 'date-fns/locale/en-GB'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  CalendarEntriesApi,
  CalendarEntry,
  CalendarEntryType,
  CalendarRecurringMachineReservationsApi,
  Configuration,
  LaundriesApi,
  LaundryGroupsCalendarApi,
  LaundryReference,
  LaundryUser,
  MachineReference,
  MachinesApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getEndOfWeek, getStartOfWeekUTC } from 'src/service/utils/MomentUtils'
import {
  CalendarEntrySingleDay,
  CalendarSettings,
  DEFAULT_CALENDAR_SETTINGS,
  SlotAndDay,
} from 'src/service/view-model/base/calendar/Calendar.const'
import {
  handleAnchorDate,
  onCancelConfirmDeleteModalHandle,
  onRequestReject,
  onSubmitReject,
  selectCalendarEntryForDelete,
  updateStateFromUrl,
  updateUrlFromState,
} from 'src/service/view-model/base/calendar/Calendar.event'
import {
  appendIsActiveUserPropertyToEntries,
  combineRecurringAndSingleCalendar,
  mapCalendarEntrySlotToCalendarEntry,
  mapCalendarEntrySlotToRecurringMachineReservation,
  slotifyEntries,
  sortCalendarEntriesByType,
} from 'src/service/view-model/base/calendar/Calendar.utils'
import {
  userCalendarIsDeleteModal,
  userCalendarIsSlotSelectable,
} from 'src/service/view-model/base/calendar/CalendarRestrictions.utils'
import { mapLanudryUserToReference } from 'src/service/view-model/laundry-user/LaundryUserUtils'
import { CONNECTED_MACHINE_TYPES, getMachineOptionLabel } from 'src/service/view-model/machine/Machines'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { useDidComponentUpdate } from 'src/ui-shared/base/hooks/useDidComponentUpdate'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { Calendar } from 'src/ui-shared/calendar/Calendar'
import { CalendarModal } from 'src/ui-shared/calendar/CalendarModal'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { WeekPicker } from 'src/ui-shared/form/control/WeekPicker'
import { LaundryUserCalendarLegend } from 'src/ui/page/wm/laundry-user/details/LaundryUserCalendarLegend'

interface Props {
  laundryUser: LaundryUser
}

if (locale.options) {
  locale.options.weekStartsOn = 1
}

export const LaundryUserCalendarTab: FC<Props> = ({ laundryUser }): ReactElement => {
  const { classes: textFieldClasses } = useTextFieldStyles()

  const translate = useTranslate()
  const location = useLocation()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const calendarEntriesApi = new CalendarEntriesApi(httpConfiguration)
  const calendarRecurringApi = new CalendarRecurringMachineReservationsApi(httpConfiguration)
  const laundryGroupsCalendarApi = new LaundryGroupsCalendarApi(httpConfiguration)
  const laundriesApi = new LaundriesApi(httpConfiguration)
  const machinesApi = new MachinesApi(httpConfiguration)

  // props
  const laundryUserId = laundryUser.id

  // state
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [weekdayCalendar, setWeekdayCalendar] = useState<CalendarEntrySingleDay[]>([])
  const [laundries, setLaundries] = useState<LaundryReference[]>([])
  const [machines, setMachines] = useState<MachineReference[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false)
  const [calendarEntryForDelete, setCalendarEntryForDelete] = useState<CalendarEntry | null>(null)
  const [calendarSettings, setCalendarSettings] = useState<CalendarSettings>(DEFAULT_CALENDAR_SETTINGS)
  const [selectedSlotAndDay, setSelectedSlotAndDay] = useState<SlotAndDay | undefined>()

  // derived state
  const selectedCalendarEntryType = selectedSlotAndDay?.slot.calendarEntry?.calendarEntryType

  // read calendar settings from url and load laundries
  useEffect(() => {
    const updatedCalendarSettings = updateStateFromUrl(location, calendarSettings)
    setCalendarSettings(updatedCalendarSettings)
    loadLaundries(updatedCalendarSettings.selectedLaundry?.id)
  }, [laundryUserId])

  // url changes when calendar settings change
  useDidComponentUpdate(() => {
    updateUrlFromState(calendarSettings)
  }, [calendarSettings])

  // when selected laundry changes, load machines
  useDidComponentUpdate(() => {
    // reset machines
    setMachines([])

    // check if we have the laundry data loaded
    if (!calendarSettings.selectedLaundry?.id || !calendarSettings.selectedLaundry.laundryGroup?.id) {
      setWeekdayCalendar([])
      return
    }

    // resets machineId if selected laundry changes
    setCalendarSettings((currentState) => ({
      ...currentState,
      selectedMachine: undefined,
    }))

    // load machines for the selected laundry
    loadMachines()
  }, [calendarSettings.selectedLaundry])

  // Load calendar when machine or date change
  useDidComponentUpdate(() => {
    if (hasValidCalendarSettings()) {
      getCalendar(true)
    } else {
      setWeekdayCalendar([])
    }
  }, [calendarSettings.anchorDate, calendarSettings.selectedMachine])

  const hasValidCalendarSettings = () => {
    return (
      calendarSettings.selectedLaundry?.id &&
      calendarSettings.selectedLaundry.laundryGroup?.id &&
      calendarSettings.selectedMachine?.id
    )
  }

  // autocomplete data functions
  const loadLaundries = (selectedLaundryId: string | undefined) => {
    const assignedLaundryGroups = laundryUser.assignedLaundryGroups.map((item) => item.id)

    // get laundries only if the user has assigned laundry groups
    if (assignedLaundryGroups.length > 0) {
      laundriesApi
        .laundriesRefGet(undefined, undefined, assignedLaundryGroups)
        .then((data) => {
          setLaundries(data)

          // update settings with the actual laundry ref data
          const foundLaundry = findSelectedLaundry(selectedLaundryId, data)

          setCalendarSettings((currentState) => ({
            ...currentState,
            selectedLaundry: foundLaundry,
          }))
        })
        .catch((err) => {
          console.error('Error loading laundries list', err)
        })
    }
  }

  const findSelectedLaundry = (laundryIdToFind: string | undefined, data: LaundryReference[]) => {
    let foundLaundry
    if (data.length > 0) {
      // find the laundry from url parameter if any
      if (laundryIdToFind) {
        foundLaundry = data.find((laundryRef) => laundryRef.id === laundryIdToFind)
      }

      // pre-selects the first laundry if there is only one
      if (!foundLaundry && data.length === 1) {
        foundLaundry = data[0]
      }
    }

    return foundLaundry
  }

  const loadMachines = () => {
    const selectedLaundryId = calendarSettings.selectedLaundry?.id
    machinesApi
      .machinesRefGet(
        undefined,
        undefined,
        selectedLaundryId ? [selectedLaundryId] : undefined,
        undefined,
        undefined,
        CONNECTED_MACHINE_TYPES,
      )
      .then((data) => {
        setMachines(data)

        // update the selected machine with real machine reference
        const foundMachine = findMachine(calendarSettings.selectedMachine?.id, data)
        if (foundMachine !== calendarSettings.selectedMachine) {
          setCalendarSettings((currentState) => ({
            ...currentState,
            selectedMachine: foundMachine,
          }))
        }
      })
      .catch((err) => {
        console.error('Error loading machines list', err)
      })
  }

  const findMachine = (machineIdToFind: string | undefined, data: MachineReference[]): MachineReference | undefined => {
    let foundMachine: MachineReference | undefined
    if (data.length > 0) {
      if (machineIdToFind) {
        foundMachine = data.find((machineRef) => machineRef.id === machineIdToFind)
      }

      // preselect the fist machine if there is only one
      if (!foundMachine && data.length === 1) {
        foundMachine = data[0]
      }
    }
    return foundMachine
  }

  // calendar data functions

  const getRecurringAndSingleCalendarRequests = (startDate: Date, endDate: Date) => {
    // precondition, check if we have all needed params
    if (!hasValidCalendarSettings()) {
      throw new Error('Laundry and laundry group must be selected to get recurring calendar')
    }

    const laundryGroupId = calendarSettings.selectedLaundry!.laundryGroup!.id
    const laundryId = calendarSettings.selectedLaundry!.id
    const machineId = calendarSettings.selectedMachine!.id

    const recurringCalendarRequest =
      laundryGroupsCalendarApi.laundrygroupsLaundryGroupIdCalendarRecurringGet(laundryGroupId)

    const singleCalendarRequest = calendarEntriesApi.calendarViewGet(
      laundryGroupId,
      startDate,
      endDate,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      machineId,
      laundryId,
    )
    return { recurringCalendarRequest, singleCalendarRequest }
  }

  const getCalendar = (active: boolean) => {
    if (!hasValidCalendarSettings()) {
      throw new Error('Laundry, laundry group and machine must be selected to get user calendar')
    }

    setLoading(true)
    setErrorMessage(null)

    const startDate = getStartOfWeekUTC(calendarSettings.anchorDate)
    const endDate = getEndOfWeek(calendarSettings.anchorDate)

    const { recurringCalendarRequest, singleCalendarRequest } = getRecurringAndSingleCalendarRequests(
      startDate,
      endDate,
    )

    Promise.all([recurringCalendarRequest, singleCalendarRequest])
      .then((array) => {
        if (active) {
          const recurringCalendar = array[0].days

          // enables slotifyEntries() to draw first the Reservation than Active Usage Slots (since some of them can overlap)
          const calendarEntries = sortCalendarEntriesByType(array[1].result)
          const singleCalendar = slotifyEntries(startDate, calendarEntries)
          const combinedCalendar = combineRecurringAndSingleCalendar(recurringCalendar, singleCalendar)
          const calendarWithActiveUser = appendIsActiveUserPropertyToEntries(laundryUserId, combinedCalendar)
          setWeekdayCalendar(calendarWithActiveUser)
          setLoading(false)
        }
      })
      .catch((err) => {
        onRequestReject(err, translate, setErrorMessage)
        setLoading(false)
      })
  }

  const updateCalendarEntry = (dayOfWeek: string, startTime: string, endTime: string, isRecurring: boolean) => {
    const infoMessage = translate('appliedChanges')
    showSnackbar(infoMessage, 'info')

    const laundryUserReference = mapLanudryUserToReference(laundryUser)

    if (isRecurring) {
      const recurringMachineReservation = mapCalendarEntrySlotToRecurringMachineReservation(
        dayOfWeek,
        startTime,
        endTime,
        calendarSettings.selectedMachine!.id,
        laundryUserReference,
      )
      calendarRecurringApi
        .calendarRecurringReservationsPost(recurringMachineReservation)
        .then((_data) => {
          getCalendar(true)
        })
        .catch((err) => {
          onSubmitReject(err, translate, showSnackbar)
        })
    } else {
      const calendarEntry = mapCalendarEntrySlotToCalendarEntry(
        dayOfWeek,
        startTime,
        endTime,
        CalendarEntryType.RESERVATION,
        calendarSettings.selectedMachine!.id,
        laundryUserReference,
      )

      calendarEntriesApi
        .calendarEntriesPost(calendarEntry)
        .then((_data) => {
          getCalendar(true)
        })
        .catch((err) => {
          onSubmitReject(err, translate, showSnackbar)
        })
    }
  }

  const deleteCalendarEntry = () => {
    if (calendarEntryForDelete) {
      const recurringReservationId = calendarEntryForDelete.recurringReservationId
      const calendarEntryId = calendarEntryForDelete.id

      if (recurringReservationId) {
        calendarRecurringApi
          .calendarRecurringReservationsRecurringMachineReservationIdDelete(recurringReservationId)
          .then((_data) => {
            getCalendar(true)
          })
          .catch((err) => {
            onSubmitReject(err, translate, showSnackbar)
          })
      } else {
        calendarEntriesApi
          .calendarEntriesCalendarEntryIdDelete(calendarEntryId)
          .then((_data) => {
            getCalendar(true)
          })
          .catch((err) => {
            onSubmitReject(err, translate, showSnackbar)
          })
      }
      setConfirmDeleteModal(false)
    }
  }

  //  event handling
  const changeLaundryIdHandle = (event: React.SyntheticEvent, laundryRef: LaundryReference | null) => {
    setCalendarSettings({
      ...calendarSettings,
      selectedLaundry: laundryRef || undefined,
    })
  }

  const changeMachineIdHandle = (event: React.SyntheticEvent, machineRef: MachineReference | null) => {
    setCalendarSettings({
      ...calendarSettings,
      selectedMachine: machineRef || undefined,
    })
  }

  const selectSlotAndDay = (slotAndDay: SlotAndDay) => {
    if (userCalendarIsSlotSelectable(slotAndDay.slot)) {
      setSelectedSlotAndDay(slotAndDay)
      setOpenModal(true)
    }
  }

  const selectSlotAndDayHandle = useCallback(selectSlotAndDay, [weekdayCalendar])

  const handleCancelModal = () => {
    setOpenModal(false)
  }

  // render
  const renderUserName = () => {
    const selectedLaundryUser = selectedSlotAndDay?.slot.calendarEntry?.laundryUser
    return `${selectedLaundryUser?.firstName} ${selectedLaundryUser?.lastName}`
  }

  return (
    <>
      <Box pt={3}>
        <LoadingIndicator loading={loading} />
        <Grid container spacing={2} pb={2}>
          <Grid item {...ITEM_BREAKPOINTS} lg={4}>
            <WeekPicker
              key={calendarSettings.anchorDate.toISOString()}
              delay={500}
              startDate={calendarSettings.anchorDate}
              onChange={(date) => handleAnchorDate(date, calendarSettings, setCalendarSettings)}
            />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS} lg={4}>
            <Autocomplete
              key={calendarSettings.selectedLaundry?.id}
              openOnFocus={true}
              options={laundries}
              getOptionLabel={(option) => option.name || ''}
              value={laundries.find((item) => item.id === calendarSettings.selectedLaundry?.id) || null}
              groupBy={(option) => option.laundryGroup?.name || ''}
              onChange={changeLaundryIdHandle}
              noOptionsText={translate('autocompleteNoOptions')}
              loadingText={translate('autocompleteLoading')}
              renderInput={(params) => (
                <TextFieldDefault
                  {...params}
                  label={translate('laundry')}
                  className={textFieldClasses.TextFieldSmall}
                />
              )}
            />
          </Grid>
          <Grid item {...ITEM_BREAKPOINTS} lg={4}>
            <Autocomplete
              key={calendarSettings.selectedMachine?.id}
              openOnFocus={true}
              options={machines}
              getOptionLabel={getMachineOptionLabel}
              value={machines.find((item) => item.id === calendarSettings.selectedMachine?.id) || null}
              onChange={changeMachineIdHandle}
              noOptionsText={translate('autocompleteNoOptions')}
              loadingText={translate('autocompleteLoading')}
              renderInput={(params) => (
                <TextFieldDefault
                  {...params}
                  label={translate('machine')}
                  className={textFieldClasses.TextFieldSmall}
                />
              )}
            />
          </Grid>
        </Grid>

        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <>
            <Calendar
              key={laundryUserId}
              selectSlotAndDay={selectSlotAndDayHandle}
              week={weekdayCalendar}
              legend={LaundryUserCalendarLegend}
            />

            <CalendarModal
              open={openModal}
              updateCalendarEntry={updateCalendarEntry}
              slotAndDay={selectedSlotAndDay!}
              laundryUser={laundryUser}
              selectCalendarEntryForDelete={(calendarEntry) =>
                selectCalendarEntryForDelete(calendarEntry, setCalendarEntryForDelete, setConfirmDeleteModal)
              }
              reservationTitle={translate('createReservation')}
              deleteTitle={renderUserName()}
              handleCancelModal={handleCancelModal}
              enableRecurring
              isDeleteModal={userCalendarIsDeleteModal(selectedCalendarEntryType)}
            />

            {/* Confirm Delete Reservation Modal */}
            <ConfirmationModalDialog
              titleKey="deleteReservation"
              confirmationKey="button.delete"
              open={confirmDeleteModal}
              onConfirm={deleteCalendarEntry}
              onCancel={() => onCancelConfirmDeleteModalHandle(setCalendarEntryForDelete, setConfirmDeleteModal)}
            >
              {translate('deleteConfirm', translate('userReservation'))}
            </ConfirmationModalDialog>
          </>
        )}
      </Box>
    </>
  )
}
