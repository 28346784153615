import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Add, Delete, DisplaySettingsOutlined, SettingsOutlined } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  LaundryGroup,
  LaundryGroupSingleCalendarEntry,
  LaundryGroupsCalendarApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateForLocale, formatDateForLocaleOptional } from 'src/service/utils/DateFormatUtils'
import { DateUtils } from 'src/service/utils/DateUtils'
import { getDateUTC, getFirstDayOfYearDate, getLastDayOfYearDate } from 'src/service/utils/MomentUtils'
import { getLocaleForDatePicker } from 'src/service/view-model/base/localization/Locales'
import {
  LaundryGroupBlockedDaysViewModel,
  mapBlockedDaysViewModelToLaundryGroupSingleCalendarEntry,
  transformLaundryGroupSingleCalendarToEntries,
} from 'src/service/view-model/laundry-group/LaundryGroupBlockedDaysViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DatePickerDefault } from 'src/ui-shared/base/form/control/DatePickerDefault'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'
import { LaundryGroupCalendarConfig } from 'src/ui/page/common/laundry-group/details/LaundryGroupCalendarConfig'
import { LaundryGroupCreateBlockedDay } from 'src/ui/page/common/laundry-group/details/LaundryGroupCreateBlockedDay'
import { LaundryGroupEnergyConfig } from 'src/ui/page/common/laundry-group/details/LaundryGroupEnergyConfig'
import { LaundryGroupGenericConfig } from 'src/ui/page/common/laundry-group/details/LaundryGroupGenericConfig'
import { useUserLocale, useUserRegionLocale } from 'src/user/UserContext'

interface Props {
  laundryGroup: LaundryGroup
}

interface HeadCells {
  id: keyof LaundryGroupSingleCalendarEntry | 'delete'
  label: string
  width?: number
}

const headCells: HeadCells[] = [
  {
    id: 'startTime',
    label: 'date',
    width: 15,
  },
  {
    id: 'reason',
    label: 'reason',
    width: 75,
  },
  {
    id: 'delete',
    label: '',
    width: 10,
  },
]

type ModalType = 'reservation_config' | 'energy_config' | 'block' | 'generic_settings'

interface OpenModal {
  open: boolean
  type?: ModalType
}

export const LaundryGroupSettingsTab: FC<Props> = ({ laundryGroup }) => {
  const { classes: tableClasses } = useTableStyles()
  const { classes: sharedClasses } = useSharedStyles()
  const { classes: textFieldClasses } = useTextFieldStyles()
  const translate = useTranslate()
  const regionLocale = useUserRegionLocale()

  const locale = useUserLocale()
  const showSnackbar = useShowSnackbar()
  const appId = useAppId()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupsCalendarApi = new LaundryGroupsCalendarApi(httpConfiguration)

  const [calendarEntries, setCalendarEntries] = useState<LaundryGroupSingleCalendarEntry[]>([])
  const [openModal, setOpenModal] = useState<OpenModal>({ open: false })
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(getFirstDayOfYearDate(new Date()))
  const [endDate, setEndDate] = useState<Date | null>(getLastDayOfYearDate(moment().add(1, 'year').toDate()))
  const [dateForRemoval, setDateForRemoval] = useState<Date | null>(null)

  useEffect(() => {
    getBlockedCalendarDays()
  }, [startDate, endDate])

  const getBlockedCalendarDays = () => {
    const formattedStartDate = getDateUTC(startDate!)
    const formattedEndDate = getDateUTC(endDate!)

    laundryGroupsCalendarApi
      .laundrygroupsLaundryGroupIdCalendarSingleGet(laundryGroup.id, formattedStartDate, formattedEndDate)
      .then((data) => {
        const calendarEntries = transformLaundryGroupSingleCalendarToEntries(data)
        setCalendarEntries(calendarEntries)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }

  const blockCalendarDay = (blockedDaysViewModel: LaundryGroupBlockedDaysViewModel) => {
    const laundryGroupSingleCalendarEntry: LaundryGroupSingleCalendarEntry =
      mapBlockedDaysViewModelToLaundryGroupSingleCalendarEntry(blockedDaysViewModel)

    laundryGroupsCalendarApi
      .laundrygroupsLaundryGroupIdCalendarSinglePost(laundryGroup.id, laundryGroupSingleCalendarEntry)
      .then((_data) => {
        getBlockedCalendarDays()
      })
      .catch((err) => {
        onSubmitReject(err)
      })
  }

  const deleteBlockedDay = () => {
    setRemoveModal(false)
    if (dateForRemoval) {
      const dateForRemovalFormatted = getDateUTC(dateForRemoval)

      laundryGroupsCalendarApi
        .laundrygroupsLaundryGroupIdCalendarSingleDelete(laundryGroup.id, dateForRemovalFormatted)
        .then(() => {
          getBlockedCalendarDays()
        })
        .catch((err) => {
          onSubmitReject(err)
        })
    }

    setDateForRemoval(null)
  }

  const onSubmitReject = (err: Error | unknown) => {
    const errorMessage = errorMapper(err, translate)
    console.error(errorMessage, err)
    showSnackbar(errorMessage, 'error')
  }

  //  event handling
  const handleFromDateChange = (date: Date | null) => {
    setStartDate(date && DateUtils.isValidDate(date) ? date : null)
  }

  const handleToDateChange = (date: Date | null) => {
    setEndDate(date && DateUtils.isValidDate(date) ? date : null)
  }

  const openBlockDayRemovalDialog = (dateToDelete: Date) => {
    setDateForRemoval(dateToDelete)
    setRemoveModal(true)
  }

  const closeModal = (successMessage?: string) => {
    if (successMessage) {
      showSnackbar(successMessage, 'success')
    }

    setOpenModal({
      ...openModal,
      open: false,
    })
  }

  // JSX
  const displayRows =
    calendarEntries.length > 0 ? (
      calendarEntries.map((item, index) => {
        return (
          <TableRow className={tableClasses.tableRow} key={index}>
            <TableCell>{formatDateForLocale(item.startTime, regionLocale)}</TableCell>
            <TableCell>{item.reason}</TableCell>
            <TableCell>
              <IconButton style={{ padding: '4px' }} onClick={openBlockDayRemovalDialog.bind(this, item.startTime)}>
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        )
      })
    ) : (
      <TableEmpty colspan={headCells.length} />
    )

  const displayHeaders = headCells.map((item) => {
    return (
      <TableCell key={item.id} width={item.width ? item.width + '%' : 'auto'}>
        {translate(item.label)}
      </TableCell>
    )
  })

  const displayTable = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{displayHeaders}</TableRow>
        </TableHead>
        <TableBody>{displayRows}</TableBody>
      </Table>
    </TableContainer>
  )

  //render
  const modalTitle = () => {
    let title!: string
    switch (openModal.type) {
      case 'generic_settings': {
        title = translate('controlSettings')
        break
      }
      case 'block': {
        title = translate('blockDay')
        break
      }
      case 'reservation_config': {
        title = translate('configureReservations')
        break
      }
      case 'energy_config': {
        title = translate('configureEnergyUse')
        break
      }
    }
    return title
  }

  const modalContent = () => {
    let content: ReactElement | null = null
    switch (openModal.type) {
      case 'generic_settings': {
        content = <LaundryGroupGenericConfig laundryGroup={laundryGroup} closeModal={closeModal} />
        break
      }
      case 'block': {
        content = <LaundryGroupCreateBlockedDay closeModal={closeModal} createBlockedDay={blockCalendarDay} />
        break
      }
      case 'reservation_config': {
        content = <LaundryGroupCalendarConfig laundryGroup={laundryGroup} closeModal={closeModal} />
        break
      }
      case 'energy_config': {
        content = <LaundryGroupEnergyConfig laundryGroup={laundryGroup} closeModal={closeModal} />
        break
      }
    }
    return content
  }

  return (
    <Box pt={3}>
      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocaleForDatePicker(locale, regionLocale)}>
        <Box pb={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item {...ITEM_BREAKPOINTS} md={6} lg={3} order={{ xs: 2, sm: 2, lg: 1 }}>
              <DatePickerDefault
                locale={regionLocale}
                label={translate('dateFrom')}
                value={startDate}
                onChange={handleFromDateChange}
                textFieldClassName={textFieldClasses.TextFieldSmall}
              />
            </Grid>

            <Grid item {...ITEM_BREAKPOINTS} md={6} lg={3} order={{ xs: 3, sm: 3, lg: 2 }}>
              <DatePickerDefault
                locale={regionLocale}
                label={translate('dateTo')}
                value={endDate}
                onChange={handleToDateChange}
                textFieldClassName={textFieldClasses.TextFieldSmall}
              />
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS} md={12} order={{ xs: 1, sm: 1, lg: 3 }}>
              <Box display="flex" justifyContent="right">
                {appId === AppId.WASH_MASTER ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    className={sharedClasses.ButtonMargin}
                    startIcon={<SettingsOutlined />}
                    onClick={() =>
                      setOpenModal({
                        open: true,
                        type: 'reservation_config',
                      })
                    }
                  >
                    {translate('configureReservationsButton')}
                  </Button>
                ) : null}
                {appId === AppId.SERVICE_MASTER ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    className={sharedClasses.ButtonMargin}
                    startIcon={<DisplaySettingsOutlined />}
                    onClick={() =>
                      setOpenModal({
                        open: true,
                        type: 'generic_settings',
                      })
                    }
                  >
                    {translate('thresholds')}
                  </Button>
                ) : null}
                {appId === AppId.SERVICE_MASTER ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    className={sharedClasses.ButtonMargin}
                    startIcon={<SettingsOutlined />}
                    onClick={() =>
                      setOpenModal({
                        open: true,
                        type: 'energy_config',
                      })
                    }
                  >
                    {translate('configureEnergyUseButton')}
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => setOpenModal({ open: true, type: 'block' })}
                >
                  {translate('blockDay')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
      <ModalDialog open={openModal.open} onClose={() => closeModal()} title={modalTitle()} maxWidth="xs">
        {modalContent()}
      </ModalDialog>
      <ConfirmationModalDialog
        titleKey="deleteBlockedDay"
        confirmationKey="button.delete"
        open={removeModal}
        onConfirm={deleteBlockedDay}
        onCancel={() => setRemoveModal(false)}
      >
        {translate('deleteConfirm', formatDateForLocaleOptional(dateForRemoval, regionLocale))}
      </ConfirmationModalDialog>
      {displayTable}
    </Box>
  )
}
