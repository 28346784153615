/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Notification } from './Notification';
import {
    NotificationFromJSON,
    NotificationFromJSONTyped,
    NotificationToJSON,
} from './Notification';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * 
 * @export
 * @interface PageableNotifications
 */
export interface PageableNotifications {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof PageableNotifications
     */
    result: Array<Notification>;
    /**
     * 
     * @type {Page}
     * @memberof PageableNotifications
     */
    page: Page;
}

/**
 * Check if a given object implements the PageableNotifications interface.
 */
export function instanceOfPageableNotifications(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageableNotificationsFromJSON(json: any): PageableNotifications {
    return PageableNotificationsFromJSONTyped(json, false);
}

export function PageableNotificationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableNotifications {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(NotificationFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageableNotificationsToJSON(value?: PageableNotifications | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(NotificationToJSON)),
        'page': PageToJSON(value.page),
    };
}

