/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineProducts
 */
export interface MachineProducts {
    /**
     * 
     * @type {boolean}
     * @memberof MachineProducts
     */
    washMasterEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MachineProducts
     */
    serviceMasterEnabled: boolean;
}

/**
 * Check if a given object implements the MachineProducts interface.
 */
export function instanceOfMachineProducts(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "washMasterEnabled" in value;
    isInstance = isInstance && "serviceMasterEnabled" in value;

    return isInstance;
}

export function MachineProductsFromJSON(json: any): MachineProducts {
    return MachineProductsFromJSONTyped(json, false);
}

export function MachineProductsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineProducts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'washMasterEnabled': json['washMasterEnabled'],
        'serviceMasterEnabled': json['serviceMasterEnabled'],
    };
}

export function MachineProductsToJSON(value?: MachineProducts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'washMasterEnabled': value.washMasterEnabled,
        'serviceMasterEnabled': value.serviceMasterEnabled,
    };
}

