/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { OrganizationServicemaster } from './OrganizationServicemaster';
import {
    OrganizationServicemasterFromJSON,
    OrganizationServicemasterFromJSONTyped,
    OrganizationServicemasterToJSON,
} from './OrganizationServicemaster';
import type { OrganizationType } from './OrganizationType';
import {
    OrganizationTypeFromJSON,
    OrganizationTypeFromJSONTyped,
    OrganizationTypeToJSON,
} from './OrganizationType';
import type { TimeZone } from './TimeZone';
import {
    TimeZoneFromJSON,
    TimeZoneFromJSONTyped,
    TimeZoneToJSON,
} from './TimeZone';

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    ifsCustomerNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    vatNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    email?: string | null;
    /**
     * 
     * @type {TimeZone}
     * @memberof Organization
     */
    timeZone: TimeZone;
    /**
     * 
     * @type {Address}
     * @memberof Organization
     */
    address: Address;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    locale: string;
    /**
     * 
     * @type {OrganizationType}
     * @memberof Organization
     */
    type?: OrganizationType | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    supportEmail?: string | null;
    /**
     * 
     * @type {OrganizationServicemaster}
     * @memberof Organization
     */
    servicemaster?: OrganizationServicemaster | null;
}

/**
 * Check if a given object implements the Organization interface.
 */
export function instanceOfOrganization(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "ifsCustomerNumber" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "phoneNumber" in value;
    isInstance = isInstance && "timeZone" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "locale" in value;

    return isInstance;
}

export function OrganizationFromJSON(json: any): Organization {
    return OrganizationFromJSONTyped(json, false);
}

export function OrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ifsCustomerNumber': json['ifsCustomerNumber'],
        'vatNumber': !exists(json, 'vatNumber') ? undefined : json['vatNumber'],
        'name': json['name'],
        'phoneNumber': json['phoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'timeZone': TimeZoneFromJSON(json['timeZone']),
        'address': AddressFromJSON(json['address']),
        'locale': json['locale'],
        'type': !exists(json, 'type') ? undefined : OrganizationTypeFromJSON(json['type']),
        'supportEmail': !exists(json, 'supportEmail') ? undefined : json['supportEmail'],
        'servicemaster': !exists(json, 'servicemaster') ? undefined : OrganizationServicemasterFromJSON(json['servicemaster']),
    };
}

export function OrganizationToJSON(value?: Organization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ifsCustomerNumber': value.ifsCustomerNumber,
        'vatNumber': value.vatNumber,
        'name': value.name,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
        'timeZone': TimeZoneToJSON(value.timeZone),
        'address': AddressToJSON(value.address),
        'locale': value.locale,
        'type': OrganizationTypeToJSON(value.type),
        'supportEmail': value.supportEmail,
        'servicemaster': OrganizationServicemasterToJSON(value.servicemaster),
    };
}

