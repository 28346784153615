/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AppId,
  Country,
  Laundry,
  LaundryReference,
  PageableLaundries,
} from '../models';
import {
    AppIdFromJSON,
    AppIdToJSON,
    CountryFromJSON,
    CountryToJSON,
    LaundryFromJSON,
    LaundryToJSON,
    LaundryReferenceFromJSON,
    LaundryReferenceToJSON,
    PageableLaundriesFromJSON,
    PageableLaundriesToJSON,
} from '../models';

export interface LaundriesGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    id?: string;
    laundryGroupId?: string;
    organizationId?: string;
    countryCode?: Country;
    appId?: Array<AppId>;
    latitudeFrom?: number;
    latitudeTo?: number;
    longitudeFrom?: number;
    longitudeTo?: number;
}

export interface LaundriesLaundryIdDeleteRequest {
    laundryId: string;
}

export interface LaundriesLaundryIdGetRequest {
    laundryId: string;
}

export interface LaundriesLaundryIdPutRequest {
    laundryId: string;
    appId?: Array<AppId>;
    laundry?: Laundry;
}

export interface LaundriesPostRequest {
    appId?: Array<AppId>;
    laundry?: Laundry;
}

export interface LaundriesRefGetRequest {
    size?: number;
    search?: string;
    laundryGroupId?: Array<string>;
    organizationId?: Array<string>;
}

/**
 * 
 */
export class LaundriesApi extends runtime.BaseAPI {

    /**
     * List Laundries
     */
    async laundriesGetRaw(requestParameters: LaundriesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableLaundries>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.laundryGroupId !== undefined) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.countryCode !== undefined) {
            queryParameters['countryCode'] = requestParameters.countryCode;
        }

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        if (requestParameters.latitudeFrom !== undefined) {
            queryParameters['latitudeFrom'] = requestParameters.latitudeFrom;
        }

        if (requestParameters.latitudeTo !== undefined) {
            queryParameters['latitudeTo'] = requestParameters.latitudeTo;
        }

        if (requestParameters.longitudeFrom !== undefined) {
            queryParameters['longitudeFrom'] = requestParameters.longitudeFrom;
        }

        if (requestParameters.longitudeTo !== undefined) {
            queryParameters['longitudeTo'] = requestParameters.longitudeTo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableLaundriesFromJSON(jsonValue));
    }

    /**
     * List Laundries
     */
    async laundriesGet(size?: number, page?: number, sort?: string, search?: string, id?: string, laundryGroupId?: string, organizationId?: string, countryCode?: Country, appId?: Array<AppId>, latitudeFrom?: number, latitudeTo?: number, longitudeFrom?: number, longitudeTo?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableLaundries> {
        const response = await this.laundriesGetRaw({ size: size, page: page, sort: sort, search: search, id: id, laundryGroupId: laundryGroupId, organizationId: organizationId, countryCode: countryCode, appId: appId, latitudeFrom: latitudeFrom, latitudeTo: latitudeTo, longitudeFrom: longitudeFrom, longitudeTo: longitudeTo }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a Laundry
     */
    async laundriesLaundryIdDeleteRaw(requestParameters: LaundriesLaundryIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryId === null || requestParameters.laundryId === undefined) {
            throw new runtime.RequiredError('laundryId','Required parameter requestParameters.laundryId was null or undefined when calling laundriesLaundryIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundries/{laundryId}`.replace(`{${"laundryId"}}`, encodeURIComponent(String(requestParameters.laundryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Laundry
     */
    async laundriesLaundryIdDelete(laundryId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.laundriesLaundryIdDeleteRaw({ laundryId: laundryId }, initOverrides);
    }

    /**
     * Get a Laundry
     */
    async laundriesLaundryIdGetRaw(requestParameters: LaundriesLaundryIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Laundry>> {
        if (requestParameters.laundryId === null || requestParameters.laundryId === undefined) {
            throw new runtime.RequiredError('laundryId','Required parameter requestParameters.laundryId was null or undefined when calling laundriesLaundryIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundries/{laundryId}`.replace(`{${"laundryId"}}`, encodeURIComponent(String(requestParameters.laundryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryFromJSON(jsonValue));
    }

    /**
     * Get a Laundry
     */
    async laundriesLaundryIdGet(laundryId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Laundry> {
        const response = await this.laundriesLaundryIdGetRaw({ laundryId: laundryId }, initOverrides);
        return await response.value();
    }

    /**
     * Update a Laundry
     */
    async laundriesLaundryIdPutRaw(requestParameters: LaundriesLaundryIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Laundry>> {
        if (requestParameters.laundryId === null || requestParameters.laundryId === undefined) {
            throw new runtime.RequiredError('laundryId','Required parameter requestParameters.laundryId was null or undefined when calling laundriesLaundryIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundries/{laundryId}`.replace(`{${"laundryId"}}`, encodeURIComponent(String(requestParameters.laundryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryToJSON(requestParameters.laundry),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryFromJSON(jsonValue));
    }

    /**
     * Update a Laundry
     */
    async laundriesLaundryIdPut(laundryId: string, appId?: Array<AppId>, laundry?: Laundry, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Laundry> {
        const response = await this.laundriesLaundryIdPutRaw({ laundryId: laundryId, appId: appId, laundry: laundry }, initOverrides);
        return await response.value();
    }

    /**
     * Create a Laundry
     */
    async laundriesPostRaw(requestParameters: LaundriesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Laundry>> {
        const queryParameters: any = {};

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryToJSON(requestParameters.laundry),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryFromJSON(jsonValue));
    }

    /**
     * Create a Laundry
     */
    async laundriesPost(appId?: Array<AppId>, laundry?: Laundry, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Laundry> {
        const response = await this.laundriesPostRaw({ appId: appId, laundry: laundry }, initOverrides);
        return await response.value();
    }

    /**
     * List Laundry References
     */
    async laundriesRefGetRaw(requestParameters: LaundriesRefGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaundryReference>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.laundryGroupId) {
            queryParameters['laundryGroupId'] = requestParameters.laundryGroupId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.organizationId) {
            queryParameters['organizationId'] = requestParameters.organizationId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundries/ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaundryReferenceFromJSON));
    }

    /**
     * List Laundry References
     */
    async laundriesRefGet(size?: number, search?: string, laundryGroupId?: Array<string>, organizationId?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaundryReference>> {
        const response = await this.laundriesRefGetRaw({ size: size, search: search, laundryGroupId: laundryGroupId, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

}
