import React, { ReactElement, useRef, useState } from 'react'
import { TranslateFunction } from 'src/i18n/useMessageSource'
import { AsyncAutoComplete } from 'src/ui-shared/base/form/control/AsyncAutoComplete'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TableFilterPopup, TableFilterPopupWithClose } from 'src/ui-shared/table/TableFilterPopup'

interface Props<T> {
  filter: string
  title: string
  label: string
  labelFieldName: keyof T
  valueFieldName: keyof T
  tableSettings: any
  loadOptions: (search: string) => Promise<T[]>
  getOptionLabel?: (option: T) => string
  setTableSettings: (value: any) => void
  translate?: TranslateFunction
  minCharsForSearch?: number
  loadAll?: boolean
}

export const TableFilterAutocompleteAsync = <T,>({
  loadOptions,
  filter,
  title,
  label,
  labelFieldName,
  valueFieldName,
  getOptionLabel,
  tableSettings,
  setTableSettings,
  loadAll = true,
  minCharsForSearch = 1,
}: Props<T>): ReactElement => {
  const { classes: textFieldClasses } = useTextFieldStyles()

  const popupRef = useRef<TableFilterPopupWithClose | null>(null)

  // state
  const [selectedOption, setSelectedOption] = useState<T | null>(null)

  // handle events
  const changeSelectedOption = (object: T | null) => {
    setSelectedOption(object)

    setTableSettings({
      ...tableSettings,
      [filter]: object ? object[valueFieldName] : undefined,
      page: 0,
    })

    if (object !== null) {
      popupRef.current?.close()
    }
  }

  const getSelectedOptionFromTableSettings = () => {
    const tableSettingsValue = tableSettings[filter]
    return tableSettingsValue
  }

  const loadOptionsAndSelectOption = async (search: string) => {
    const options = await loadOptions(search)
    const selectedOption = options.find((item) => item[valueFieldName] === tableSettings[filter]) || null
    setSelectedOption(selectedOption)
    return options
  }

  return (
    <TableFilterPopup ref={popupRef} title={title} active={Boolean(tableSettings[filter])}>
      <AsyncAutoComplete
        name="async-filter-autocomplete"
        label={label}
        labelFieldName={labelFieldName}
        loadOptionsFunction={loadOptionsAndSelectOption}
        onChange={changeSelectedOption}
        value={selectedOption}
        loadAll={getSelectedOptionFromTableSettings() !== undefined || loadAll}
        getOptionLabel={getOptionLabel}
        minCharsForSearch={minCharsForSearch}
        style={{ width: 300, height: 56 }}
        textFieldVariant="outlined"
        textFieldClassName={textFieldClasses.TextFieldSmall}
        textFieldInputLabelProps={{ defaultShrinkBehaviour: true }}
      />
    </TableFilterPopup>
  )
}
