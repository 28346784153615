import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, IconButton, Paper, TableCell, TableRow } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  Configuration,
  Invitation,
  InvitationsApi,
  OrganizationReference,
  OrganizationsApi,
  PageableInvitations,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocale } from 'src/service/utils/DateFormatUtils'
import { Data, OrderBy } from 'src/service/view-model/base/Data'
import { getInvitationRoleName } from 'src/service/view-model/member/MemberViewModel'
import { EMPTY_ORGANIZATION_REF, getOrganizationTypesByUser } from 'src/service/view-model/organization/Organizations'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettingsWithOrganization,
  getDefaultTableSettings,
  tableSettingsSort,
  withOrganization,
} from 'src/ui-shared/table/Table.const'
import { useTableSearch } from 'src/ui-shared/table/Table.hooks'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableFilterAutocompleteOrganization } from 'src/ui-shared/table/TableFilterAutocompleteOrganization'
import { TableSearchForm } from 'src/ui-shared/table/TableSearchForm'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { useUserRegionLocale } from 'src/user/UserContext'

const getHeadCells = (isServicer: boolean) => {
  const cells: HeadCells[] = [
    {
      id: 'validity',
      label: 'dataExpiration',
    },
    {
      id: 'email',
      label: 'email',
    },
    {
      id: 'organization',
      label: isServicer ? 'servicePartner' : 'organization',
    },
    {
      id: 'role',
      label: 'user.role',
      noSort: true,
    },
    {
      id: 'delete',
      label: '',
    },
  ]
  return cells
}

interface InvitationTableSettings extends TableSettingsWithOrganization {
  orderBy: OrderBy<string>
}

interface Props {
  submittedInvite: boolean
  isServicer?: boolean
}

export const MemberInvitationTable: FC<Props> = ({ submittedInvite, isServicer = false }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  const location = useLocation()
  const activeOrganization = useActiveOrganization()
  const showSnackbar = useShowSnackbar()
  const translate = useTranslate()
  const regionLocale = useUserRegionLocale()

  const headCells = getHeadCells(isServicer)

  const httpConfiguration: Configuration = useContext(HttpContext)
  const invitationApi = new InvitationsApi(httpConfiguration)
  const organizationApi = new OrganizationsApi(httpConfiguration)

  const defaultTableSettings: InvitationTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'validity',
    orderDir: 'desc',
  }

  const [tableSettings, setTableSettings] = useState<InvitationTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )
  const [data, setData] = useState<TableData<Invitation>>(DEFAULT_DATA)
  const [deleteInvitationModal, setDeleteInvitationModal] = useState<boolean>(false)
  const [invitationForRemoval, setInvitationForRemoval] = useState<Invitation | null>(null)
  const [organizations, setOrganizations] = useState<OrganizationReference[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { inputSearchValue, handleSearch } = useTableSearch(tableSettings, setTableSettings)

  const organizationTypeFilter = getOrganizationTypesByUser(isServicer)

  // load data for filters
  useEffect(() => {
    organizationApi.organizationsRefGet(undefined, undefined, organizationTypeFilter).then((data) => {
      data.unshift(EMPTY_ORGANIZATION_REF)
      setOrganizations(data)
    })
  }, [])

  // load data on state change
  useEffect(() => {
    let active = true

    setLoading(true)

    getInvitations()
      .then((data) => {
        if (active) {
          handleGetInvitationsSuccess(data)
        }
      })
      .catch((err) => {
        handleGetInvitationsError(err)
      })

    return () => {
      active = false
    }
  }, [tableSettings, submittedInvite])

  const getInvitations = (): Promise<PageableInvitations> => {
    return invitationApi.invitationsGet(
      tableSettings.size,
      tableSettings.page,
      tableSettingsSort(tableSettings),
      tableSettings.search,
      undefined,
      tableSettings.organizationId,
      organizationTypeFilter,
    )
  }

  // handle events
  const handleGetInvitationsSuccess = (data: PageableInvitations) => {
    updateTableSettingsFromData(data as Data<any>, tableSettings)
    setTableSettings(tableSettings)

    setData(mapData(data as Data<any>))
    setErrorMessage(null)
    setLoading(false)
  }

  const handleGetInvitationsError = (err: Error | unknown) => {
    const errorMessage = errorMapper(err, translate)
    console.error(errorMessage, err)
    setErrorMessage(errorMessage)
    setData(DEFAULT_DATA)
    setLoading(false)
  }

  const deleteInvitation = () => {
    setDeleteInvitationModal(false)
    if (invitationForRemoval) {
      invitationApi
        .invitationsInvitationIdDelete(invitationForRemoval.id)
        .then(() => {
          getInvitations()
            .then((data) => {
              handleGetInvitationsSuccess(data)
            })
            .catch((err) => {
              handleGetInvitationsError(err)
            })
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
        })
    }
    setInvitationForRemoval(null)
  }

  const openDeleteInvitationDialog = (invitationToBeDeleted: Invitation) => {
    setDeleteInvitationModal(true)
    setInvitationForRemoval(invitationToBeDeleted)
  }

  // JSX
  const nonEmptyRows = data.data.map((item) => {
    return (
      <TableRow className={tableClasses.tableRow} key={item.id} style={{ cursor: 'default' }}>
        <TableCell>{formatDateTimeForLocale(item.validity, regionLocale)}</TableCell>
        <TableCell>{item.email}</TableCell>
        <TableCell>{item.organization?.name}</TableCell>
        <TableCell>{getInvitationRoleName(item)}</TableCell>
        <TableCell>
          <IconButton style={{ padding: '4px' }} onClick={openDeleteInvitationDialog.bind(this, item)}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string) => {
    let filter
    if (headCellId === 'organization') {
      filter = (
        <TableFilterAutocompleteOrganization
          options={organizations}
          label={translate(isServicer ? 'servicePartner' : 'organization')}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }
    return filter
  }

  return (
    <Paper elevation={0}>
      <Box mt={2} mb={2}>
        <TableSearchForm handleSearch={handleSearch} inputSearchValue={inputSearchValue} />
      </Box>

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <DataTable
        headCells={headCells}
        data={data}
        nonEmptyRows={nonEmptyRows}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        getFilter={getFilter}
        loading={loading}
        translate={translate}
      />

      <ConfirmationModalDialog
        titleKey="deleteInvitation"
        confirmationKey="button.delete"
        open={deleteInvitationModal}
        onConfirm={deleteInvitation}
        onCancel={() => setDeleteInvitationModal(false)}
      >
        {translate(
          'deleteConfirm',
          invitationForRemoval
            ? translate('invitationFor').toLowerCase() + ' ' + invitationForRemoval.email
            : undefined,
        )}
      </ConfirmationModalDialog>
    </Paper>
  )
}
