/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LaundryGroupPriceList,
  LaundryGroupPriceListReferenceList,
  MachineType,
} from '../models';
import {
    LaundryGroupPriceListFromJSON,
    LaundryGroupPriceListToJSON,
    LaundryGroupPriceListReferenceListFromJSON,
    LaundryGroupPriceListReferenceListToJSON,
    MachineTypeFromJSON,
    MachineTypeToJSON,
} from '../models';

export interface LaundrygroupsLaundryGroupIdPricelistsGetRequest {
    laundryGroupId: string;
}

export interface LaundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeDeleteRequest {
    laundryGroupId: string;
    machineType: MachineType;
    drumSize?: number | null;
}

export interface LaundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeGetRequest {
    laundryGroupId: string;
    machineType: MachineType;
    drumSize?: number | null;
}

export interface LaundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypePutRequest {
    laundryGroupId: string;
    machineType: MachineType;
    drumSize?: number | null;
    laundryGroupPriceList?: LaundryGroupPriceList;
}

/**
 * 
 */
export class LaundryGroupPriceListsApi extends runtime.BaseAPI {

    /**
     * Get the Price Lists (Name of pricelists, not full pricelist) of a Laundry Group
     */
    async laundrygroupsLaundryGroupIdPricelistsGetRaw(requestParameters: LaundrygroupsLaundryGroupIdPricelistsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupPriceListReferenceList>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdPricelistsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/pricelists`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupPriceListReferenceListFromJSON(jsonValue));
    }

    /**
     * Get the Price Lists (Name of pricelists, not full pricelist) of a Laundry Group
     */
    async laundrygroupsLaundryGroupIdPricelistsGet(laundryGroupId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupPriceListReferenceList> {
        const response = await this.laundrygroupsLaundryGroupIdPricelistsGetRaw({ laundryGroupId: laundryGroupId }, initOverrides);
        return await response.value();
    }

    /**
     * Delete the Laundry Group Price Lists (Regular / Happy Hour) and resets it by Default Pricings by Machine Type
     */
    async laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeDeleteRaw(requestParameters: LaundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeDelete.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.drumSize !== undefined) {
            queryParameters['drumSize'] = requestParameters.drumSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/pricelists/machineType/{machineType}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))).replace(`{${"machineType"}}`, encodeURIComponent(String(requestParameters.machineType))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the Laundry Group Price Lists (Regular / Happy Hour) and resets it by Default Pricings by Machine Type
     */
    async laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeDelete(laundryGroupId: string, machineType: MachineType, drumSize?: number | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeDeleteRaw({ laundryGroupId: laundryGroupId, machineType: machineType, drumSize: drumSize }, initOverrides);
    }

    /**
     * Get the Price Lists (Regular / Happy Hour) of a Laundry Group by Machine Type
     */
    async laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeGetRaw(requestParameters: LaundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupPriceList>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeGet.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.drumSize !== undefined) {
            queryParameters['drumSize'] = requestParameters.drumSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/pricelists/machineType/{machineType}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))).replace(`{${"machineType"}}`, encodeURIComponent(String(requestParameters.machineType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupPriceListFromJSON(jsonValue));
    }

    /**
     * Get the Price Lists (Regular / Happy Hour) of a Laundry Group by Machine Type
     */
    async laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeGet(laundryGroupId: string, machineType: MachineType, drumSize?: number | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupPriceList> {
        const response = await this.laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeGetRaw({ laundryGroupId: laundryGroupId, machineType: machineType, drumSize: drumSize }, initOverrides);
        return await response.value();
    }

    /**
     * Update the Laundry Group Price Lists (Regular / Happy Hour) by Machine Type
     */
    async laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypePutRaw(requestParameters: LaundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupPriceList>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypePut.');
        }

        if (requestParameters.machineType === null || requestParameters.machineType === undefined) {
            throw new runtime.RequiredError('machineType','Required parameter requestParameters.machineType was null or undefined when calling laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypePut.');
        }

        const queryParameters: any = {};

        if (requestParameters.drumSize !== undefined) {
            queryParameters['drumSize'] = requestParameters.drumSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/laundrygroups/{laundryGroupId}/pricelists/machineType/{machineType}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))).replace(`{${"machineType"}}`, encodeURIComponent(String(requestParameters.machineType))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryGroupPriceListToJSON(requestParameters.laundryGroupPriceList),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupPriceListFromJSON(jsonValue));
    }

    /**
     * Update the Laundry Group Price Lists (Regular / Happy Hour) by Machine Type
     */
    async laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypePut(laundryGroupId: string, machineType: MachineType, drumSize?: number | null, laundryGroupPriceList?: LaundryGroupPriceList, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupPriceList> {
        const response = await this.laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypePutRaw({ laundryGroupId: laundryGroupId, machineType: machineType, drumSize: drumSize, laundryGroupPriceList: laundryGroupPriceList }, initOverrides);
        return await response.value();
    }

}
