/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Country } from './Country';
import {
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './Country';

/**
 * 
 * @export
 * @interface LaundryUserAddress
 */
export interface LaundryUserAddress {
    /**
     * 
     * @type {string}
     * @memberof LaundryUserAddress
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryUserAddress
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryUserAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryUserAddress
     */
    zipCode?: string;
    /**
     * 
     * @type {Country}
     * @memberof LaundryUserAddress
     */
    countryCode: Country;
    /**
     * 
     * @type {number}
     * @memberof LaundryUserAddress
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LaundryUserAddress
     */
    longitude?: number | null;
}

/**
 * Check if a given object implements the LaundryUserAddress interface.
 */
export function instanceOfLaundryUserAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "countryCode" in value;

    return isInstance;
}

export function LaundryUserAddressFromJSON(json: any): LaundryUserAddress {
    return LaundryUserAddressFromJSONTyped(json, false);
}

export function LaundryUserAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryUserAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'countryCode': CountryFromJSON(json['countryCode']),
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function LaundryUserAddressToJSON(value?: LaundryUserAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'city': value.city,
        'zipCode': value.zipCode,
        'countryCode': CountryToJSON(value.countryCode),
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

