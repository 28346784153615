import React, { FC, ReactElement } from 'react'
import {
  AlertColor,
  Snackbar as SnackBar,
  SnackbarCloseReason,
  SnackbarContentProps,
  SnackbarOrigin,
  ThemeProvider,
} from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { SM_THEME, WM_THEME } from 'src/theme/Theme'

interface Props {
  children?: React.ReactNode
  open?: boolean
  severity?: AlertColor | undefined
  anchorOrigin?: SnackbarOrigin | undefined
  onClose: ((event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => void) | undefined
  action?: SnackbarContentProps['action']
  persistent?: boolean
}

export const Snackbar: FC<Props> = ({
  children,
  open,
  anchorOrigin,
  severity,
  onClose,
  action,
  persistent,
}): ReactElement => {
  // TODO pst, 20221230 find a way not to use app specific logic in Snackbar
  // the current issue is that the snackbars have different colors for info alert for the different apps
  const appId = useAppId()

  return (
    <ThemeProvider theme={appId === AppId.SERVICE_MASTER ? SM_THEME : WM_THEME}>
      <SnackBar
        anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'right' }}
        onClose={onClose}
        open={open}
        autoHideDuration={persistent ? null : 5000}
      >
        <MuiAlert
          elevation={6}
          onClose={(e) => {
            if (onClose) {
              onClose(e, 'clickaway')
            }
          }}
          variant="filled"
          severity={severity}
          action={action}
        >
          {children}
        </MuiAlert>
      </SnackBar>
    </ThemeProvider>
  )
}
