/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineParametersControlType } from './MachineParametersControlType';
import {
    MachineParametersControlTypeFromJSON,
    MachineParametersControlTypeFromJSONTyped,
    MachineParametersControlTypeToJSON,
} from './MachineParametersControlType';
import type { MachineParametersStateCheckType } from './MachineParametersStateCheckType';
import {
    MachineParametersStateCheckTypeFromJSON,
    MachineParametersStateCheckTypeFromJSONTyped,
    MachineParametersStateCheckTypeToJSON,
} from './MachineParametersStateCheckType';
import type { MachineReference } from './MachineReference';
import {
    MachineReferenceFromJSON,
    MachineReferenceFromJSONTyped,
    MachineReferenceToJSON,
} from './MachineReference';

/**
 * 
 * @export
 * @interface MachineParametersExternal
 */
export interface MachineParametersExternal {
    /**
     * 
     * @type {MachineReference}
     * @memberof MachineParametersExternal
     */
    machine?: MachineReference;
    /**
     * 
     * @type {MachineParametersControlType}
     * @memberof MachineParametersExternal
     */
    machineControl: MachineParametersControlType;
    /**
     * 
     * @type {MachineParametersStateCheckType}
     * @memberof MachineParametersExternal
     */
    stateCheck: MachineParametersStateCheckType;
    /**
     * Pulse duration in seconds
     * @type {number}
     * @memberof MachineParametersExternal
     */
    pulseDuration?: number;
    /**
     * Start delay in seconds
     * @type {number}
     * @memberof MachineParametersExternal
     */
    startDelay?: number;
    /**
     * Stop delay in seconds
     * @type {number}
     * @memberof MachineParametersExternal
     */
    stopDelay?: number;
    /**
     * Power limit in watt (unit of power)
     * @type {number}
     * @memberof MachineParametersExternal
     */
    powerLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MachineParametersExternal
     */
    doorButtonAvailable: boolean;
    /**
     * Door open duration in seconds
     * @type {number}
     * @memberof MachineParametersExternal
     */
    doorOpenDuration?: number;
}

/**
 * Check if a given object implements the MachineParametersExternal interface.
 */
export function instanceOfMachineParametersExternal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "machineControl" in value;
    isInstance = isInstance && "stateCheck" in value;
    isInstance = isInstance && "doorButtonAvailable" in value;

    return isInstance;
}

export function MachineParametersExternalFromJSON(json: any): MachineParametersExternal {
    return MachineParametersExternalFromJSONTyped(json, false);
}

export function MachineParametersExternalFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineParametersExternal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machine': !exists(json, 'machine') ? undefined : MachineReferenceFromJSON(json['machine']),
        'machineControl': MachineParametersControlTypeFromJSON(json['machineControl']),
        'stateCheck': MachineParametersStateCheckTypeFromJSON(json['stateCheck']),
        'pulseDuration': !exists(json, 'pulseDuration') ? undefined : json['pulseDuration'],
        'startDelay': !exists(json, 'startDelay') ? undefined : json['startDelay'],
        'stopDelay': !exists(json, 'stopDelay') ? undefined : json['stopDelay'],
        'powerLimit': !exists(json, 'powerLimit') ? undefined : json['powerLimit'],
        'doorButtonAvailable': json['doorButtonAvailable'],
        'doorOpenDuration': !exists(json, 'doorOpenDuration') ? undefined : json['doorOpenDuration'],
    };
}

export function MachineParametersExternalToJSON(value?: MachineParametersExternal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machine': MachineReferenceToJSON(value.machine),
        'machineControl': MachineParametersControlTypeToJSON(value.machineControl),
        'stateCheck': MachineParametersStateCheckTypeToJSON(value.stateCheck),
        'pulseDuration': value.pulseDuration,
        'startDelay': value.startDelay,
        'stopDelay': value.stopDelay,
        'powerLimit': value.powerLimit,
        'doorButtonAvailable': value.doorButtonAvailable,
        'doorOpenDuration': value.doorOpenDuration,
    };
}

