/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';
import type { LaundryUserAddress } from './LaundryUserAddress';
import {
    LaundryUserAddressFromJSON,
    LaundryUserAddressFromJSONTyped,
    LaundryUserAddressToJSON,
} from './LaundryUserAddress';
import type { LaundryUserImportState } from './LaundryUserImportState';
import {
    LaundryUserImportStateFromJSON,
    LaundryUserImportStateFromJSONTyped,
    LaundryUserImportStateToJSON,
} from './LaundryUserImportState';
import type { RFIDCardReference } from './RFIDCardReference';
import {
    RFIDCardReferenceFromJSON,
    RFIDCardReferenceFromJSONTyped,
    RFIDCardReferenceToJSON,
} from './RFIDCardReference';

/**
 * 
 * @export
 * @interface LaundryUserBulkResponse
 */
export interface LaundryUserBulkResponse {
    /**
     * 
     * @type {string}
     * @memberof LaundryUserBulkResponse
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryUserBulkResponse
     */
    readonly firstName: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryUserBulkResponse
     */
    readonly lastName: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryUserBulkResponse
     */
    readonly email: string;
    /**
     * 
     * @type {LaundryUserAddress}
     * @memberof LaundryUserBulkResponse
     */
    address: LaundryUserAddress;
    /**
     * 
     * @type {Array<LaundryGroupReference>}
     * @memberof LaundryUserBulkResponse
     */
    assignedLaundryGroups: Array<LaundryGroupReference>;
    /**
     * 
     * @type {Array<RFIDCardReference>}
     * @memberof LaundryUserBulkResponse
     */
    assignedRFIDCards?: Array<RFIDCardReference>;
    /**
     * 
     * @type {number}
     * @memberof LaundryUserBulkResponse
     */
    readonly prepaidAmount: number;
    /**
     * 
     * @type {Date}
     * @memberof LaundryUserBulkResponse
     */
    lastUsage?: Date;
    /**
     * 
     * @type {LaundryUserImportState}
     * @memberof LaundryUserBulkResponse
     */
    importState: LaundryUserImportState;
    /**
     * 
     * @type {string}
     * @memberof LaundryUserBulkResponse
     */
    readonly errorMessage?: string;
}

/**
 * Check if a given object implements the LaundryUserBulkResponse interface.
 */
export function instanceOfLaundryUserBulkResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "assignedLaundryGroups" in value;
    isInstance = isInstance && "prepaidAmount" in value;
    isInstance = isInstance && "importState" in value;

    return isInstance;
}

export function LaundryUserBulkResponseFromJSON(json: any): LaundryUserBulkResponse {
    return LaundryUserBulkResponseFromJSONTyped(json, false);
}

export function LaundryUserBulkResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryUserBulkResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'address': LaundryUserAddressFromJSON(json['address']),
        'assignedLaundryGroups': ((json['assignedLaundryGroups'] as Array<any>).map(LaundryGroupReferenceFromJSON)),
        'assignedRFIDCards': !exists(json, 'assignedRFIDCards') ? undefined : ((json['assignedRFIDCards'] as Array<any>).map(RFIDCardReferenceFromJSON)),
        'prepaidAmount': json['prepaidAmount'],
        'lastUsage': !exists(json, 'lastUsage') ? undefined : (new Date(json['lastUsage'])),
        'importState': LaundryUserImportStateFromJSON(json['importState']),
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function LaundryUserBulkResponseToJSON(value?: LaundryUserBulkResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': LaundryUserAddressToJSON(value.address),
        'assignedLaundryGroups': ((value.assignedLaundryGroups as Array<any>).map(LaundryGroupReferenceToJSON)),
        'assignedRFIDCards': value.assignedRFIDCards === undefined ? undefined : ((value.assignedRFIDCards as Array<any>).map(RFIDCardReferenceToJSON)),
        'lastUsage': value.lastUsage === undefined ? undefined : (value.lastUsage.toISOString()),
        'importState': LaundryUserImportStateToJSON(value.importState),
    };
}

