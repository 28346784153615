/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaundryGroupStatisticsData } from './LaundryGroupStatisticsData';
import {
    LaundryGroupStatisticsDataFromJSON,
    LaundryGroupStatisticsDataFromJSONTyped,
    LaundryGroupStatisticsDataToJSON,
} from './LaundryGroupStatisticsData';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * 
 * @export
 * @interface PageableLaundryGroupStatisticsData
 */
export interface PageableLaundryGroupStatisticsData {
    /**
     * 
     * @type {Array<LaundryGroupStatisticsData>}
     * @memberof PageableLaundryGroupStatisticsData
     */
    result: Array<LaundryGroupStatisticsData>;
    /**
     * 
     * @type {Page}
     * @memberof PageableLaundryGroupStatisticsData
     */
    page: Page;
}

/**
 * Check if a given object implements the PageableLaundryGroupStatisticsData interface.
 */
export function instanceOfPageableLaundryGroupStatisticsData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageableLaundryGroupStatisticsDataFromJSON(json: any): PageableLaundryGroupStatisticsData {
    return PageableLaundryGroupStatisticsDataFromJSONTyped(json, false);
}

export function PageableLaundryGroupStatisticsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableLaundryGroupStatisticsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(LaundryGroupStatisticsDataFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageableLaundryGroupStatisticsDataToJSON(value?: PageableLaundryGroupStatisticsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(LaundryGroupStatisticsDataToJSON)),
        'page': PageToJSON(value.page),
    };
}

