/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { LaundryGroupProducts } from './LaundryGroupProducts';
import {
    LaundryGroupProductsFromJSON,
    LaundryGroupProductsFromJSONTyped,
    LaundryGroupProductsToJSON,
} from './LaundryGroupProducts';
import type { LaundryGroupServicemaster } from './LaundryGroupServicemaster';
import {
    LaundryGroupServicemasterFromJSON,
    LaundryGroupServicemasterFromJSONTyped,
    LaundryGroupServicemasterToJSON,
} from './LaundryGroupServicemaster';
import type { LaundryGroupWashmaster } from './LaundryGroupWashmaster';
import {
    LaundryGroupWashmasterFromJSON,
    LaundryGroupWashmasterFromJSONTyped,
    LaundryGroupWashmasterToJSON,
} from './LaundryGroupWashmaster';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';
import type { ServicePackageOption } from './ServicePackageOption';
import {
    ServicePackageOptionFromJSON,
    ServicePackageOptionFromJSONTyped,
    ServicePackageOptionToJSON,
} from './ServicePackageOption';
import type { ServicePackagePlan } from './ServicePackagePlan';
import {
    ServicePackagePlanFromJSON,
    ServicePackagePlanFromJSONTyped,
    ServicePackagePlanToJSON,
} from './ServicePackagePlan';
import type { TimeZone } from './TimeZone';
import {
    TimeZoneFromJSON,
    TimeZoneFromJSONTyped,
    TimeZoneToJSON,
} from './TimeZone';

/**
 * 
 * @export
 * @interface LaundryGroup
 */
export interface LaundryGroup {
    /**
     * 
     * @type {string}
     * @memberof LaundryGroup
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroup
     */
    readonly wlNumber: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroup
     */
    readonly ifsCustomerNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroup
     */
    locale: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroup
     */
    iban?: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroup
     */
    ibanHolderName?: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroup
     */
    externalPayoutReference?: string;
    /**
     * 
     * @type {Address}
     * @memberof LaundryGroup
     */
    billingAddress: Address;
    /**
     * 
     * @type {Address}
     * @memberof LaundryGroup
     */
    siteAddress?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof LaundryGroup
     */
    useSiteAddress: boolean;
    /**
     * 
     * @type {TimeZone}
     * @memberof LaundryGroup
     */
    timeZone: TimeZone;
    /**
     * 
     * @type {Currency}
     * @memberof LaundryGroup
     */
    currency?: Currency;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroup
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LaundryGroup
     */
    notes?: string;
    /**
     * 
     * @type {OrganizationReference}
     * @memberof LaundryGroup
     */
    organization: OrganizationReference;
    /**
     * 
     * @type {Array<string>}
     * @memberof LaundryGroup
     */
    emails?: Array<string>;
    /**
     * 
     * @type {LaundryGroupProducts}
     * @memberof LaundryGroup
     */
    products?: LaundryGroupProducts;
    /**
     * 
     * @type {LaundryGroupWashmaster}
     * @memberof LaundryGroup
     */
    washmaster?: LaundryGroupWashmaster | null;
    /**
     * 
     * @type {LaundryGroupServicemaster}
     * @memberof LaundryGroup
     */
    servicemaster?: LaundryGroupServicemaster | null;
    /**
     * 
     * @type {ServicePackagePlan}
     * @memberof LaundryGroup
     */
    servicePackagePlan?: ServicePackagePlan;
    /**
     * 
     * @type {Array<ServicePackageOption>}
     * @memberof LaundryGroup
     */
    servicePackagePlanOptions?: Array<ServicePackageOption>;
}

/**
 * Check if a given object implements the LaundryGroup interface.
 */
export function instanceOfLaundryGroup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "wlNumber" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "locale" in value;
    isInstance = isInstance && "billingAddress" in value;
    isInstance = isInstance && "useSiteAddress" in value;
    isInstance = isInstance && "timeZone" in value;
    isInstance = isInstance && "organization" in value;

    return isInstance;
}

export function LaundryGroupFromJSON(json: any): LaundryGroup {
    return LaundryGroupFromJSONTyped(json, false);
}

export function LaundryGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'wlNumber': json['wlNumber'],
        'ifsCustomerNumber': !exists(json, 'ifsCustomerNumber') ? undefined : json['ifsCustomerNumber'],
        'name': json['name'],
        'locale': json['locale'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'ibanHolderName': !exists(json, 'ibanHolderName') ? undefined : json['ibanHolderName'],
        'externalPayoutReference': !exists(json, 'externalPayoutReference') ? undefined : json['externalPayoutReference'],
        'billingAddress': AddressFromJSON(json['billingAddress']),
        'siteAddress': !exists(json, 'siteAddress') ? undefined : AddressFromJSON(json['siteAddress']),
        'useSiteAddress': json['useSiteAddress'],
        'timeZone': TimeZoneFromJSON(json['timeZone']),
        'currency': !exists(json, 'currency') ? undefined : CurrencyFromJSON(json['currency']),
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'organization': OrganizationReferenceFromJSON(json['organization']),
        'emails': !exists(json, 'emails') ? undefined : json['emails'],
        'products': !exists(json, 'products') ? undefined : LaundryGroupProductsFromJSON(json['products']),
        'washmaster': !exists(json, 'washmaster') ? undefined : LaundryGroupWashmasterFromJSON(json['washmaster']),
        'servicemaster': !exists(json, 'servicemaster') ? undefined : LaundryGroupServicemasterFromJSON(json['servicemaster']),
        'servicePackagePlan': !exists(json, 'servicePackagePlan') ? undefined : ServicePackagePlanFromJSON(json['servicePackagePlan']),
        'servicePackagePlanOptions': !exists(json, 'servicePackagePlanOptions') ? undefined : ((json['servicePackagePlanOptions'] as Array<any>).map(ServicePackageOptionFromJSON)),
    };
}

export function LaundryGroupToJSON(value?: LaundryGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'locale': value.locale,
        'iban': value.iban,
        'ibanHolderName': value.ibanHolderName,
        'externalPayoutReference': value.externalPayoutReference,
        'billingAddress': AddressToJSON(value.billingAddress),
        'siteAddress': AddressToJSON(value.siteAddress),
        'useSiteAddress': value.useSiteAddress,
        'timeZone': TimeZoneToJSON(value.timeZone),
        'currency': CurrencyToJSON(value.currency),
        'phoneNumber': value.phoneNumber,
        'notes': value.notes,
        'organization': OrganizationReferenceToJSON(value.organization),
        'emails': value.emails,
        'products': LaundryGroupProductsToJSON(value.products),
        'washmaster': LaundryGroupWashmasterToJSON(value.washmaster),
        'servicemaster': LaundryGroupServicemasterToJSON(value.servicemaster),
        'servicePackagePlan': ServicePackagePlanToJSON(value.servicePackagePlan),
        'servicePackagePlanOptions': value.servicePackagePlanOptions === undefined ? undefined : ((value.servicePackagePlanOptions as Array<any>).map(ServicePackageOptionToJSON)),
    };
}

