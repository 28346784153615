import React, { ReactElement, useContext } from 'react'
import { Box, Button, Divider, Grid, Typography, styled } from '@mui/material'
import packageJson from '../../../../package.json'
import { ReactComponent as SmagLogo } from 'src/assets/logo-schulthess-blue-black.svg'
import { EnvironmentContext, useIsProdEnvironment } from 'src/env/EnvironmentContext'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'

const SmLogoSmall = styled(SmagLogo)(({ theme }) => ({
  width: '200px',
  marginTop: '-8px',
  [theme.breakpoints.up('md')]: {
    width: '250px',
  },
}))

interface Props {
  aboutDialogOpen: boolean
  setAboutDialogOpen: (value: boolean) => void
}

export const AboutUsDialog = ({ aboutDialogOpen, setAboutDialogOpen }: Props): ReactElement => {
  const translate = useTranslate()
  const { environmentId } = useContext(EnvironmentContext)
  const isProdEnvironment = useIsProdEnvironment()

  const yearString = new Date().getFullYear().toString()
  const appVersion = packageJson.version

  return (
    <ModalDialog
      open={aboutDialogOpen}
      onClose={() => setAboutDialogOpen(false)}
      title={translate('aboutTitle')}
      maxWidth="md"
    >
      <Box px={3} mb={2}>
        <Grid container spacing={1}>
          <Grid item xs={6} sx={{}}>
            <Typography variant="h2">Smart Schulthess</Typography>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <SmLogoSmall />
          </Grid>

          {/* show environment id only if not in prod */}
          <Grid item xs={12}>
            {translate('version')}: <span>{appVersion}</span> {!isProdEnvironment ? '(' + environmentId + ')' : null}
          </Grid>

          <Grid item xs={12}>
            &#169; {translate('copyrightYear', yearString)} {translate('copyrightOrganization')}
          </Grid>

          <Grid item xs={12} mt={1}>
            <Typography variant="h3">{translate('legalNotice')}</Typography>
            <p style={{ whiteSpace: 'pre-line' }}>{translate('legalNoticeText')}</p>
          </Grid>
        </Grid>
      </Box>
      <Divider />

      <Box pt={1} pb={1} display="flex" justifyContent="flex-end">
        <Button variant="text" color="primary" size="large" onClick={() => setAboutDialogOpen(false)}>
          {translate('button.close')}
        </Button>
      </Box>
    </ModalDialog>
  )
}
