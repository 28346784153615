/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProgramInstallationStatus {
    IDLE = 'IDLE',
    DOWNLOAD_PENDING = 'DOWNLOAD_PENDING',
    DOWNLOAD_IN_PROGRESS = 'DOWNLOAD_IN_PROGRESS',
    INSTALLATION_PENDING = 'INSTALLATION_PENDING',
    INSTALLATION_IN_PROGRESS = 'INSTALLATION_IN_PROGRESS',
    INSTALLATION_SUCCESSFUL = 'INSTALLATION_SUCCESSFUL',
    INSTALLATION_ERROR = 'INSTALLATION_ERROR',
    INCOMPATIBLE = 'INCOMPATIBLE'
}


export function ProgramInstallationStatusFromJSON(json: any): ProgramInstallationStatus {
    return ProgramInstallationStatusFromJSONTyped(json, false);
}

export function ProgramInstallationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramInstallationStatus {
    return json as ProgramInstallationStatus;
}

export function ProgramInstallationStatusToJSON(value?: ProgramInstallationStatus | null): any {
    return value as any;
}

