/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManualMachineInfo } from './ManualMachineInfo';
import {
    ManualMachineInfoFromJSON,
    ManualMachineInfoFromJSONTyped,
    ManualMachineInfoToJSON,
} from './ManualMachineInfo';

/**
 * 
 * @export
 * @interface ManualPairingBody
 */
export interface ManualPairingBody {
    /**
     * 
     * @type {ManualMachineInfo}
     * @memberof ManualPairingBody
     */
    primary: ManualMachineInfo;
    /**
     * 
     * @type {Array<ManualMachineInfo>}
     * @memberof ManualPairingBody
     */
    followers?: Array<ManualMachineInfo>;
}

/**
 * Check if a given object implements the ManualPairingBody interface.
 */
export function instanceOfManualPairingBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "primary" in value;

    return isInstance;
}

export function ManualPairingBodyFromJSON(json: any): ManualPairingBody {
    return ManualPairingBodyFromJSONTyped(json, false);
}

export function ManualPairingBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualPairingBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'primary': ManualMachineInfoFromJSON(json['primary']),
        'followers': !exists(json, 'followers') ? undefined : ((json['followers'] as Array<any>).map(ManualMachineInfoFromJSON)),
    };
}

export function ManualPairingBodyToJSON(value?: ManualPairingBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'primary': ManualMachineInfoToJSON(value.primary),
        'followers': value.followers === undefined ? undefined : ((value.followers as Array<any>).map(ManualMachineInfoToJSON)),
    };
}

