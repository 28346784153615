import React, { ReactElement, ReactNode, useMemo } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { Box, FormHelperText } from '@mui/material'
import Autocomplete, { AutocompleteProps as MuiAutoCompleteProps } from '@mui/material/Autocomplete'
import { useTranslate } from 'src/i18n/useMessageSource'
import { FinalFormInput, Option } from 'src/ui-shared/base/form/control/Index'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

type MuiAutoCompletePropsToBeExcluded = FinalFormInput | 'renderInput' | 'getOptionValue'

type AutoCompleteFieldProps = Omit<
  MuiAutoCompleteProps<Option, false, false, false>,
  MuiAutoCompletePropsToBeExcluded
> & {
  name: string
  label: string
  options: Option[]
  validate?: any
  required?: boolean
  preselectOption?: boolean
}

type AutoCompleteFieldValueType = Option | null | string

const CustomAutoComplete = (props: FieldRenderProps<AutoCompleteFieldValueType>): ReactElement => {
  const {
    input: { value, onBlur, onChange: onChangeFinalForm, onFocus },
    meta,
    required,
    preselectOption,
    ...rest
  } = props
  const { label, options, ...restAutoCompleteProps } = rest as Pick<AutoCompleteFieldProps, 'options' | 'label'>
  const { touched, error: errorObject } = meta

  const translate = useTranslate()

  const error = errorObject && translate(errorObject.errorKey, errorObject.params)

  const invalid = Boolean(touched && error)
  const onChangeMemo = useMemo<(_event: React.SyntheticEvent, optionValue: AutoCompleteFieldValueType) => void>(
    () =>
      (_event: React.SyntheticEvent, optionValue: AutoCompleteFieldValueType): void => {
        const newValue = typeof optionValue === 'string' ? optionValue : optionValue?.value ?? null
        onChangeFinalForm(newValue)
      },
    [onChangeFinalForm],
  )

  const firstOption = preselectOption && options.length == 1 ? options[0] : null
  const resolvedOptionValue =
    options.find((x) => (typeof value === 'string' ? value === x.value : x.value === value?.value)) || firstOption

  return (
    <Box>
      <Autocomplete
        {...restAutoCompleteProps}
        openOnFocus={true}
        onBlur={onBlur}
        options={options}
        onFocus={onFocus}
        getOptionLabel={(option: Option | undefined): string => option?.label ?? ''}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        value={resolvedOptionValue}
        onChange={onChangeMemo}
        multiple={false}
        disableClearable={false}
        freeSolo={false}
        renderInput={(params): ReactNode => (
          <TextFieldDefault {...params} required={required} fullWidth label={label} onBlur={onBlur} error={invalid} />
        )}
      />
      {invalid && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}

export const AutoCompleteField = (props: AutoCompleteFieldProps): ReactElement => (
  <Field component={CustomAutoComplete} {...props} />
)
