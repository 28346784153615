import { FC, default as React, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Box, Button, Grid, Icon, IconButton, Paper, TableCell, TableContainer, TableRow, styled } from '@mui/material'
import { Add, ContentCopyOutlined, DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { ReactComponent as SmLogoImage } from 'src/assets/logo-servicemaster-icon.svg'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, MachineType, Permission, ProgramGroup, ProgramGroupsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocaleOptional } from 'src/service/utils/DateFormatUtils'
import { Data, OrderBy } from 'src/service/view-model/base/Data'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { EditMode, ViewMode } from 'src/ui-shared/constants/Constants'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettingsWithOrganization,
  getDefaultTableSettings,
  tableSettingsSort,
  withOrganization,
} from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { hasApp, hasPermission } from 'src/user/RoleCheck'
import { useUser, useUserRegionLocale } from 'src/user/UserContext'

export const SmLogoImageStyled = styled(SmLogoImage)(() => ({
  marginBottom: '20px',
  fill: '#fff',
}))

export const ResponsiveTableContainer = styled(TableContainer)<{
  modalView: boolean
}>(({ theme, modalView }) => ({
  ...(modalView && {
    height: '380px',
    [theme.breakpoints.down('lg')]: {
      height: '300px',
    },
    [theme.breakpoints.down('md')]: {
      height: '250px',
    },
  }),
}))

export const ActionButtonsGrid = styled(Grid)(() => ({
  display: 'inline-flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  '& .MuiButton-root': {
    marginTop: 5,
  },
}))

const headCells: HeadCells[] = [
  {
    id: 'name',
    label: 'programGroup.name',
  },
  {
    id: 'programs.size',
    label: 'programs',
    noSort: true,
  },
  {
    id: 'lastModified',
    label: 'programGroup.lastUpdate',
    noSort: true,
  },
  {
    id: 'actions',
    label: '',
    noSort: true,
  },
]

interface ProgramInfoTableSettings extends TableSettingsWithOrganization {
  orderBy: OrderBy<keyof ProgramGroup>
}

interface Props {
  view?: ViewMode
  machineType: MachineType
  fixedOrganizationId?: string
  navigateToItem: (programGroupId: string) => void
  selectProgramGroup?: (programGroup: ProgramGroup | null) => void
}

export const ProgramGroupTable: FC<Props> = ({
  view = 'page',
  machineType,
  fixedOrganizationId,
  navigateToItem,
  selectProgramGroup,
}): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const translate = useTranslate()
  const location = useLocation()
  const navigate = useNavigate()
  const user = useUser()
  const appId = useAppId()
  const regionLocale = useUserRegionLocale()
  const showSnackbar = useShowSnackbar()

  const activeOrganization = useActiveOrganization()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const programGroupApi = new ProgramGroupsApi(httpConfiguration)

  // state
  const [data, setData] = useState<TableData<ProgramGroup>>(DEFAULT_DATA)
  const defaultTableSettings: ProgramInfoTableSettings = {
    ...getDefaultTableSettings(),
    orderBy: 'name',
    orderDir: 'asc',
  }

  const [tableSettings, setTableSettings] = useState<ProgramInfoTableSettings>(
    withOrganization(defaultTableSettings, activeOrganization),
  )
  const [selectedRow, setSelectedRow] = useState<ProgramGroup | null>(null)
  const [programGroupForRemoval, setProgramGroupForRemoval] = useState<ProgramGroup | null>(null)
  const [reloadDataFlag, setReloadDataFlag] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  const hasServiceMaster = hasApp(user, AppId.SERVICE_MASTER)

  const hasProgramGroupWritePermission = hasPermission(user, Permission.EASYSET_PROGRAM_WRITE)
  const hasInstallProgramGroupPermission = hasServiceMaster && hasProgramGroupWritePermission

  // derived state
  const pageView = view === 'page'
  const organizationId = fixedOrganizationId ? fixedOrganizationId : tableSettings.organizationId

  // load data on state change
  useEffect(() => {
    let active = true

    setLoading(true)

    programGroupApi
      .programGroupsGet(
        tableSettings.size,
        tableSettings.page,
        tableSettingsSort(tableSettings),
        undefined,
        organizationId,
        machineType,
      )
      .then((data) => {
        if (active) {
          updateTableSettingsFromData(data as Data<any>, tableSettings)
          setTableSettings(tableSettings)

          setData(mapData(data as Data<any>))
          setErrorMessage(null)
          setLoading(false)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData(DEFAULT_DATA)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [tableSettings, reloadDataFlag])

  // handle events
  const navigateTo = (
    mode: Extract<EditMode, 'edit' | 'copy'>,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    programGroup: ProgramGroup,
  ) => {
    event.stopPropagation()

    navigate(`/${appId}/program-groups/${machineType}/${programGroup.id}/${mode}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleCreateClick = () => {
    navigate(`/${appId}/program-groups/${machineType}/create`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleInstallProgramGroupClick = () => {
    let url = `/${AppId.SERVICE_MASTER}/laundry-groups`
    if (activeOrganization) {
      url += '?organizationId=' + activeOrganization.id
    }
    navigate(url, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleDeleteModalOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    programGroup: ProgramGroup,
  ) => {
    event.stopPropagation()
    setProgramGroupForRemoval(programGroup)
  }

  const handleDeleteModalClose = () => {
    setProgramGroupForRemoval(null)
  }

  const handleSelectRow = (programGroup: ProgramGroup) => {
    if (selectProgramGroup) {
      if (isRowSelected(programGroup.id)) {
        setSelectedRow(null)
        selectProgramGroup(null)
      } else {
        setSelectedRow(programGroup)
        selectProgramGroup(programGroup)
      }
    }
  }

  const removeProgramGroup = (): void => {
    if (programGroupForRemoval) {
      programGroupApi
        .programGroupsProgramGroupIdDelete(programGroupForRemoval.id)
        .then(() => {
          handleDeleteModalClose()
          setReloadDataFlag((prevState: boolean) => !prevState)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
          handleDeleteModalClose()
        })
    }
  }

  const isRowSelected = (programGroupId: string) => {
    return selectedRow?.id === programGroupId
  }

  // JSX
  const nonEmptyRows = data.data.map((item, index) => {
    const programPositions = item.programs
    // count screens positions which only have program assigned to it
    const programsInGroup = programPositions.filter((i) => i.program).length

    return (
      <TableRow
        className={tableClasses.tableRow}
        key={index}
        onClick={pageView ? navigateToItem.bind(this, item.id) : () => handleSelectRow(item)}
        selected={isRowSelected(item.id)}
      >
        <TableCell width={'60%'}>{item.name}</TableCell>
        <TableCell>{programsInGroup}</TableCell>
        <TableCell>{formatDateTimeForLocaleOptional(item.lastModified, regionLocale)}</TableCell>
        <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', textAlign: 'right' }}>
          {hasProgramGroupWritePermission && pageView && (
            <>
              <IconButton onClick={(event) => navigateTo('edit', event, item)}>
                <EditOutlined fontSize="small" />
              </IconButton>
              <IconButton onClick={(event) => navigateTo('copy', event, item)}>
                <ContentCopyOutlined fontSize="small" />
              </IconButton>
              <IconButton onClick={(event) => handleDeleteModalOpen(event, item)}>
                <DeleteOutlineOutlined fontSize="small" />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
    )
  })

  // don't update url if listing of program groups is in modal dialog
  if (view === 'page') {
    // update state from url / apply state to url
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)
  }

  const programGroupActionButtons = (
    <Grid container spacing={2} pb={2}>
      <ActionButtonsGrid item xs={12}>
        {hasInstallProgramGroupPermission && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleInstallProgramGroupClick}
              startIcon={
                <Icon>
                  <SmLogoImageStyled />
                </Icon>
              }
            >
              {translate('installProgramGroup')}
            </Button>
            <Button onClick={handleCreateClick} variant="outlined" color="primary" startIcon={<Add />}>
              {translate('createNew')}
            </Button>
          </>
        )}
      </ActionButtonsGrid>
    </Grid>
  )

  return (
    <Box pt={2}>
      <Paper elevation={0}>
        {pageView && programGroupActionButtons}
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

        <ResponsiveTableContainer modalView={view === 'modal'}>
          <DataTable
            headCells={headCells}
            data={data}
            nonEmptyRows={nonEmptyRows}
            selectedRows={selectedRow ? 1 : 0}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            loading={loading}
            translate={translate}
          />
        </ResponsiveTableContainer>
      </Paper>

      {programGroupForRemoval !== null && (
        <ConfirmationModalDialog
          titleKey="deleteProgramGroup"
          confirmationKey="button.delete"
          open={true}
          onConfirm={removeProgramGroup}
          onCancel={handleDeleteModalClose}
        >
          {translate('deleteConfirm', programGroupForRemoval.name)}
        </ConfirmationModalDialog>
      )}
    </Box>
  )
}
