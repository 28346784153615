/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MachineAction {
    MACHINE_START_USAGE = 'ACTION_MACHINE_START_USAGE',
    LAB_MACHINE_CANCEL = 'ACTION_LAB_MACHINE_CANCEL',
    LAB_MACHINE_SELECT_AND_START_PROGRAM = 'ACTION_LAB_MACHINE_SELECT_AND_START_PROGRAM',
    LAB_MACHINE_START_ENDURANCE_TEST = 'ACTION_LAB_MACHINE_START_ENDURANCE_TEST'
}


export function MachineActionFromJSON(json: any): MachineAction {
    return MachineActionFromJSONTyped(json, false);
}

export function MachineActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineAction {
    return json as MachineAction;
}

export function MachineActionToJSON(value?: MachineAction | null): any {
    return value as any;
}

