import React, { FC, ReactElement } from 'react'
import { useNavigate } from 'react-router'
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
  styled,
} from '@mui/material'
import { CheckCircleOutlined, CheckRounded, DoneAllRounded } from '@mui/icons-material'
import { AppId } from 'src/app/AppId'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { LaundryGroupSubscription, ServicePackagePlan, ServicePackagePlanFeature } from 'src/service/backend/api'
import { subtractObjArray } from 'src/service/utils/ArrayUtils'
import { getPricingTitle } from 'src/service/view-model/base/payment/Payments'
import { EditMode } from 'src/ui-shared/constants/Constants'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { useUserRegionLocale } from 'src/user/UserContext'

const PackagePlanContainer = styled(Box)<{
  background: string
  editable: number
  legacyPlan: number
}>(({ theme, background, editable, legacyPlan }) => ({
  minHeight: legacyPlan === 1 ? '200px' : '500px',
  cursor: editable ? 'pointer' : 'default',
  border: '2px solid',
  borderColor: theme.palette.action.selected,
  borderRadius: '23px',
  borderBottom: '7px solid',
  borderBottomColor: background,
  width: '100%',
  alignItems: 'center',
  position: 'relative',
  ...(editable && {
    ':hover': {
      borderColor: theme.palette.primary.main,
    },
  }),
}))

const PackagePlanHeader = styled(Box)<{
  background: string
  font: string
}>(({ background, font }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100px',
  padding: 10,
  borderTopLeftRadius: '21px',
  borderTopRightRadius: '21px',
  backgroundColor: background,
  textAlign: 'center',
  color: font,
}))

const PackagePlanBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(7),
}))

const PackagePlanFooter = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(3),
  right: '10%',
  left: '10%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

interface Prop {
  laundryGroupSubscription: LaundryGroupSubscription
  mode?: EditMode
  selectedPlan?: ServicePackagePlan
  handleSelectPlan?: (plan: ServicePackagePlan) => void
}

export const ServicePackagePlanCard: FC<Prop> = ({
  laundryGroupSubscription,
  mode = 'view',
  selectedPlan,
  handleSelectPlan,
}): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const navigate = useNavigate()
  const regionLocale = useUserRegionLocale()

  // derived state
  const packagePlans = laundryGroupSubscription.servicePackagePlans
  const laundryGroupId = laundryGroupSubscription.laundryGroup.id
  const selectionDisabled = mode === 'view'

  const navigateToUpgradePlan = (selectedPackagePlanId: string) => {
    navigate(`/${AppId.WASH_MASTER}/service-package-plan/${laundryGroupId}/view/upgrade/${selectedPackagePlanId}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const isSelectedPackagePlan = (plan: ServicePackagePlan): boolean => {
    let selectedPlanId = ''

    if (selectedPlan) {
      selectedPlanId = selectedPlan.id
    }

    return selectedPlanId === plan.id
  }

  const isActivePackagePlan = (plan: ServicePackagePlan): boolean => {
    const activePlan = laundryGroupSubscription.activatedServicePackagePlan.id

    return activePlan === plan.id
  }

  const handleClickOnPlan = (plan: ServicePackagePlan) => {
    const canSelectPackagePlan = !selectionDisabled && handleSelectPlan

    if (canSelectPackagePlan) {
      handleSelectPlan(plan)
    }
  }

  // JSX
  const displayPackagePlanFeaturesCard = (
    planFeatures: Array<ServicePackagePlanFeature>,
    upgradeFromPlan: ServicePackagePlan | undefined,
    bgColor: string,
  ): ReactElement => {
    let result = <></>
    const upgradeFromPlanFeatures = upgradeFromPlan?.features || []
    const upgradeFromPlanActiveFeatures = upgradeFromPlanFeatures.filter((feature) => feature.activated)
    const activePlanFeatures = planFeatures.filter((feature) => feature.activated)

    const checkEqualFeatures = (
      elementMinuend: ServicePackagePlanFeature,
      elementSubtrahend: ServicePackagePlanFeature,
    ): boolean => {
      return (
        elementMinuend.name === elementSubtrahend.name && elementMinuend.description === elementSubtrahend.description
      )
    }

    const uniquePlanFeatures = subtractObjArray(activePlanFeatures, upgradeFromPlanActiveFeatures, checkEqualFeatures)

    if (planFeatures.length > 0) {
      result = (
        <List dense>
          {upgradeFromPlan && (
            <ListItem sx={{ pt: 0, pb: 0 }}>
              <ListItemIcon>
                <DoneAllRounded color={'primary'} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    {translate('includesAllFeaturesFrom')}
                    <Typography variant={'h6'} color={bgColor} display={'inline'}>
                      {` ${upgradeFromPlan.name} `}
                    </Typography>
                    {translate('plus')}:
                  </>
                }
              />
            </ListItem>
          )}

          {uniquePlanFeatures.map((feature, index) => {
            const featureDescription = feature.description === '-' ? undefined : feature.description
            return (
              <ListItem sx={{ pt: 0, pb: 0 }} key={index}>
                <ListItemIcon>
                  <CheckRounded color={'primary'} />
                </ListItemIcon>
                <ListItemText primary={feature.name} secondary={featureDescription} />
              </ListItem>
            )
          })}
        </List>
      )
    }

    return result
  }

  const displayPlanFooter = (plan: ServicePackagePlan): ReactElement => {
    const activePlan = isActivePackagePlan(plan)
    const selectedPlan = isSelectedPackagePlan(plan)
    let title: '' | 'active' | 'activate' | 'alreadyActive' | 'selectedForActivation' = ''

    // in create mode (step screen), only show title for selection, no plan is active at that point in time for the user
    if (mode === 'create' && (activePlan || selectedPlan)) {
      title = 'selectedForActivation'
    }

    // in edit mode, if plan is selected but is already active display appropriate title
    else if (mode === 'edit' && selectedPlan && activePlan) {
      title = 'alreadyActive'
    }

    // in view mode, display title for active plan
    else if (mode === 'view' && activePlan) {
      title = 'active'
    }

    // title for selected plan
    else if (!selectionDisabled && selectedPlan) {
      title = 'selectedForActivation'
    }

    // in edit mode show label for plan to be activated
    else if (!selectionDisabled && !selectedPlan) {
      title = 'activate'
    }

    const activePlanIndicator = (
      <Chip icon={<CheckCircleOutlined fontSize={'small'} />} color={'primary'} label={translate(title)} />
    )

    const activatePlanButton = (
      <Button
        onClick={() => navigateToUpgradePlan(plan.id)}
        disabled={plan.legacyPlan}
        variant="outlined"
        color="primary"
        fullWidth
      >
        {translate('button.activateNow')}
      </Button>
    )

    const radioButtonForSelection = (
      <FormControlLabel control={<Radio checked={selectedPlan} />} label={translate(title)} />
    )

    // in view mode display active indicator or button to navigate to edit mode
    if (selectionDisabled) {
      if (activePlan) {
        return activePlanIndicator
      } else {
        return activatePlanButton
      }
    }
    // in edit mode show radio buttons for selection or for already active
    else {
      return radioButtonForSelection
    }
  }

  const displayServicePackagePlanCards =
    packagePlans.length > 0 &&
    packagePlans
      // filter legacy plans (except when a legacy plan is active)
      .filter((plan) => isActivePackagePlan(plan) || !plan.legacyPlan)
      .map((plan, index) => {
        const planColor = plan.colors
        const bgColor = planColor?.bgColor ? `#${planColor.bgColor}` : 'rgba(158, 158, 158, 0.35)'
        const fgColor = planColor?.fgColor ? `#${planColor.fgColor}` : '#000'
        const previousPlan = index > 0 ? packagePlans[index - 1] : undefined
        const upgradeFromPlan = previousPlan && !previousPlan.legacyPlan ? previousPlan : undefined

        return (
          <>
            <Grid key={index} item {...ITEM_BREAKPOINTS} md={12} lg={4} display={'flex'} justifyContent={'center'}>
              <PackagePlanContainer
                background={bgColor}
                editable={selectionDisabled ? 0 : 1}
                legacyPlan={plan.legacyPlan ? 1 : 0}
                onClick={() => handleClickOnPlan(plan)}
              >
                <PackagePlanHeader background={bgColor} font={fgColor}>
                  <Box>
                    <Typography variant={'h4'}>{plan.name}</Typography>
                    <Typography variant={'subtitle2'}>{plan.description !== '-' ? plan.description : ''}</Typography>
                  </Box>
                </PackagePlanHeader>
                <PackagePlanBody>
                  <Typography variant={'h6'} textAlign={'center'} mb={3}>
                    {getPricingTitle(translate, 'pricingPerUsage', regionLocale, plan.pricing?.usagePricing)}
                  </Typography>
                  {!plan.legacyPlan ? displayPackagePlanFeaturesCard(plan.features, upgradeFromPlan, bgColor) : null}
                </PackagePlanBody>
                <PackagePlanFooter>{displayPlanFooter(plan)}</PackagePlanFooter>
              </PackagePlanContainer>
            </Grid>

            {plan.legacyPlan ? <Grid item lg={8}></Grid> : null}
          </>
        )
      })

  return (
    <>
      {!selectionDisabled && (
        <Grid item xs={12}>
          <Typography className={sharedClasses.SectionTitle}>{translate('selectServicePackagePlan')}</Typography>
        </Grid>
      )}
      {displayServicePackagePlanCards}
    </>
  )
}
