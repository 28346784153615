/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { ManualPrepaidAdjustmentTransaction } from './ManualPrepaidAdjustmentTransaction';
import {
    ManualPrepaidAdjustmentTransactionFromJSON,
    ManualPrepaidAdjustmentTransactionFromJSONTyped,
    ManualPrepaidAdjustmentTransactionToJSON,
} from './ManualPrepaidAdjustmentTransaction';
import type { PaymentWithPSPTransaction } from './PaymentWithPSPTransaction';
import {
    PaymentWithPSPTransactionFromJSON,
    PaymentWithPSPTransactionFromJSONTyped,
    PaymentWithPSPTransactionToJSON,
} from './PaymentWithPSPTransaction';
import type { PaymentWithPrepaidTransaction } from './PaymentWithPrepaidTransaction';
import {
    PaymentWithPrepaidTransactionFromJSON,
    PaymentWithPrepaidTransactionFromJSONTyped,
    PaymentWithPrepaidTransactionToJSON,
} from './PaymentWithPrepaidTransaction';
import type { RefundPaymentToPSPTransaction } from './RefundPaymentToPSPTransaction';
import {
    RefundPaymentToPSPTransactionFromJSON,
    RefundPaymentToPSPTransactionFromJSONTyped,
    RefundPaymentToPSPTransactionToJSON,
} from './RefundPaymentToPSPTransaction';
import type { RefundPaymentToPrepaidTransaction } from './RefundPaymentToPrepaidTransaction';
import {
    RefundPaymentToPrepaidTransactionFromJSON,
    RefundPaymentToPrepaidTransactionFromJSONTyped,
    RefundPaymentToPrepaidTransactionToJSON,
} from './RefundPaymentToPrepaidTransaction';
import type { RefundPrepaidByBankTransferTransaction } from './RefundPrepaidByBankTransferTransaction';
import {
    RefundPrepaidByBankTransferTransactionFromJSON,
    RefundPrepaidByBankTransferTransactionFromJSONTyped,
    RefundPrepaidByBankTransferTransactionToJSON,
} from './RefundPrepaidByBankTransferTransaction';
import type { RefundPrepaidByPSPTransaction } from './RefundPrepaidByPSPTransaction';
import {
    RefundPrepaidByPSPTransactionFromJSON,
    RefundPrepaidByPSPTransactionFromJSONTyped,
    RefundPrepaidByPSPTransactionToJSON,
} from './RefundPrepaidByPSPTransaction';
import type { TopupPrepaidByBankingReferenceTransaction } from './TopupPrepaidByBankingReferenceTransaction';
import {
    TopupPrepaidByBankingReferenceTransactionFromJSON,
    TopupPrepaidByBankingReferenceTransactionFromJSONTyped,
    TopupPrepaidByBankingReferenceTransactionToJSON,
} from './TopupPrepaidByBankingReferenceTransaction';
import type { TopupPrepaidByOperatorTransaction } from './TopupPrepaidByOperatorTransaction';
import {
    TopupPrepaidByOperatorTransactionFromJSON,
    TopupPrepaidByOperatorTransactionFromJSONTyped,
    TopupPrepaidByOperatorTransactionToJSON,
} from './TopupPrepaidByOperatorTransaction';
import type { TopupPrepaidByPSPTransaction } from './TopupPrepaidByPSPTransaction';
import {
    TopupPrepaidByPSPTransactionFromJSON,
    TopupPrepaidByPSPTransactionFromJSONTyped,
    TopupPrepaidByPSPTransactionToJSON,
} from './TopupPrepaidByPSPTransaction';
import type { TransactionStatus } from './TransactionStatus';
import {
    TransactionStatusFromJSON,
    TransactionStatusFromJSONTyped,
    TransactionStatusToJSON,
} from './TransactionStatus';
import type { TransactionType } from './TransactionType';
import {
    TransactionTypeFromJSON,
    TransactionTypeFromJSONTyped,
    TransactionTypeToJSON,
} from './TransactionType';

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    readonly id: string;
    /**
     * 
     * @type {TransactionType}
     * @memberof Transaction
     */
    transactionType: TransactionType;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    amount: number;
    /**
     * 
     * @type {Currency}
     * @memberof Transaction
     */
    currency: Currency;
    /**
     * 
     * @type {TransactionStatus}
     * @memberof Transaction
     */
    status: TransactionStatus;
    /**
     * 
     * @type {Date}
     * @memberof Transaction
     */
    readonly authorizedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Transaction
     */
    readonly settledDate?: Date;
    /**
     * 
     * @type {ManualPrepaidAdjustmentTransaction}
     * @memberof Transaction
     */
    manualPrepaidAdjustment?: ManualPrepaidAdjustmentTransaction;
    /**
     * 
     * @type {PaymentWithPrepaidTransaction}
     * @memberof Transaction
     */
    paymentWithPrepaid?: PaymentWithPrepaidTransaction;
    /**
     * 
     * @type {PaymentWithPSPTransaction}
     * @memberof Transaction
     */
    paymentWithPSP?: PaymentWithPSPTransaction;
    /**
     * 
     * @type {RefundPaymentToPrepaidTransaction}
     * @memberof Transaction
     */
    refundPaymentToPrepaid?: RefundPaymentToPrepaidTransaction;
    /**
     * 
     * @type {RefundPaymentToPSPTransaction}
     * @memberof Transaction
     */
    refundPaymentToPSP?: RefundPaymentToPSPTransaction;
    /**
     * 
     * @type {RefundPrepaidByBankTransferTransaction}
     * @memberof Transaction
     */
    refundPrepaidByBankTransfer?: RefundPrepaidByBankTransferTransaction;
    /**
     * 
     * @type {RefundPrepaidByPSPTransaction}
     * @memberof Transaction
     */
    refundPrepaidByPSP?: RefundPrepaidByPSPTransaction;
    /**
     * 
     * @type {TopupPrepaidByBankingReferenceTransaction}
     * @memberof Transaction
     */
    topupPrepaidByBankingReference?: TopupPrepaidByBankingReferenceTransaction;
    /**
     * 
     * @type {TopupPrepaidByOperatorTransaction}
     * @memberof Transaction
     */
    topupPrepaidByOperator?: TopupPrepaidByOperatorTransaction;
    /**
     * 
     * @type {TopupPrepaidByPSPTransaction}
     * @memberof Transaction
     */
    topupPrepaidByPSP?: TopupPrepaidByPSPTransaction;
}

/**
 * Check if a given object implements the Transaction interface.
 */
export function instanceOfTransaction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "transactionType" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function TransactionFromJSON(json: any): Transaction {
    return TransactionFromJSONTyped(json, false);
}

export function TransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'transactionType': TransactionTypeFromJSON(json['transactionType']),
        'amount': json['amount'],
        'currency': CurrencyFromJSON(json['currency']),
        'status': TransactionStatusFromJSON(json['status']),
        'authorizedDate': !exists(json, 'authorizedDate') ? undefined : (new Date(json['authorizedDate'])),
        'settledDate': !exists(json, 'settledDate') ? undefined : (new Date(json['settledDate'])),
        'manualPrepaidAdjustment': !exists(json, 'manualPrepaidAdjustment') ? undefined : ManualPrepaidAdjustmentTransactionFromJSON(json['manualPrepaidAdjustment']),
        'paymentWithPrepaid': !exists(json, 'paymentWithPrepaid') ? undefined : PaymentWithPrepaidTransactionFromJSON(json['paymentWithPrepaid']),
        'paymentWithPSP': !exists(json, 'paymentWithPSP') ? undefined : PaymentWithPSPTransactionFromJSON(json['paymentWithPSP']),
        'refundPaymentToPrepaid': !exists(json, 'refundPaymentToPrepaid') ? undefined : RefundPaymentToPrepaidTransactionFromJSON(json['refundPaymentToPrepaid']),
        'refundPaymentToPSP': !exists(json, 'refundPaymentToPSP') ? undefined : RefundPaymentToPSPTransactionFromJSON(json['refundPaymentToPSP']),
        'refundPrepaidByBankTransfer': !exists(json, 'refundPrepaidByBankTransfer') ? undefined : RefundPrepaidByBankTransferTransactionFromJSON(json['refundPrepaidByBankTransfer']),
        'refundPrepaidByPSP': !exists(json, 'refundPrepaidByPSP') ? undefined : RefundPrepaidByPSPTransactionFromJSON(json['refundPrepaidByPSP']),
        'topupPrepaidByBankingReference': !exists(json, 'topupPrepaidByBankingReference') ? undefined : TopupPrepaidByBankingReferenceTransactionFromJSON(json['topupPrepaidByBankingReference']),
        'topupPrepaidByOperator': !exists(json, 'topupPrepaidByOperator') ? undefined : TopupPrepaidByOperatorTransactionFromJSON(json['topupPrepaidByOperator']),
        'topupPrepaidByPSP': !exists(json, 'topupPrepaidByPSP') ? undefined : TopupPrepaidByPSPTransactionFromJSON(json['topupPrepaidByPSP']),
    };
}

export function TransactionToJSON(value?: Transaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionType': TransactionTypeToJSON(value.transactionType),
        'amount': value.amount,
        'currency': CurrencyToJSON(value.currency),
        'status': TransactionStatusToJSON(value.status),
        'manualPrepaidAdjustment': ManualPrepaidAdjustmentTransactionToJSON(value.manualPrepaidAdjustment),
        'paymentWithPrepaid': PaymentWithPrepaidTransactionToJSON(value.paymentWithPrepaid),
        'paymentWithPSP': PaymentWithPSPTransactionToJSON(value.paymentWithPSP),
        'refundPaymentToPrepaid': RefundPaymentToPrepaidTransactionToJSON(value.refundPaymentToPrepaid),
        'refundPaymentToPSP': RefundPaymentToPSPTransactionToJSON(value.refundPaymentToPSP),
        'refundPrepaidByBankTransfer': RefundPrepaidByBankTransferTransactionToJSON(value.refundPrepaidByBankTransfer),
        'refundPrepaidByPSP': RefundPrepaidByPSPTransactionToJSON(value.refundPrepaidByPSP),
        'topupPrepaidByBankingReference': TopupPrepaidByBankingReferenceTransactionToJSON(value.topupPrepaidByBankingReference),
        'topupPrepaidByOperator': TopupPrepaidByOperatorTransactionToJSON(value.topupPrepaidByOperator),
        'topupPrepaidByPSP': TopupPrepaidByPSPTransactionToJSON(value.topupPrepaidByPSP),
    };
}

