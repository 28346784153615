import { Tab, styled } from '@mui/material'

export const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: 55,
  minWidth: 20,
  '& .MuiTab-iconWrapper': {
    minWidth: '30px',
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiTab-iconWrapper': {
      marginRight: '0px',
    },
    '&.MuiTab-labelIcon': {
      padding: '0px',
    },
  },
}))

export const StyledTabNotUppercase = styled(StyledTab)(() => ({
  textTransform: 'none',
}))
