import React from 'react'
import { HomeOutlined } from '@mui/icons-material'
import { RoutesList } from 'src/routing/Routing'
import { LandingPage } from 'src/ui/layout-page/landing/LandingPage'
import { LandingPageEs } from 'src/ui/layout-page/landing/LandingPageEs'
import { LandingPageSm } from 'src/ui/layout-page/landing/LandingPageSm'
import { NotificationListPage } from 'src/ui/layout-page/notifications/list/NotificationListPage'
import { ProfilePage } from 'src/ui/layout-page/profile/ProfilePage'
import { InvitationAcceptPage } from 'src/ui/page/common/member/InvitationAcceptPage'

const REQUIRED_ROUTES: RoutesList = {
  ProfilePage: {
    path: 'profile',
    googleAnalyticsTitle: 'User Profile',
    Component: ProfilePage,
  },
  Logout: {
    path: 'logout',
    googleAnalyticsTitle: 'Logout',
    Component: ProfilePage,
  },
  InvitationAcceptPage: {
    path: 'invitation-accept/:token',
    googleAnalyticsTitle: 'Member Invitation Accept',
    Component: InvitationAcceptPage,
  },
}

export const LANDING_ROUTES_WM: RoutesList = {
  Index: {
    path: '',
    label: 'home',
    googleAnalyticsTitle: 'Home',
    Component: LandingPage,
    icon: <HomeOutlined />,
  },
  ...REQUIRED_ROUTES,
}

export const LANDING_ROUTES_SM: RoutesList = {
  Index: {
    path: '',
    label: 'home',
    googleAnalyticsTitle: 'Home',
    Component: LandingPageSm,
    icon: <HomeOutlined />,
  },
  ...REQUIRED_ROUTES,
}

export const LANDING_ROUTES_ES: RoutesList = {
  Index: {
    path: '',
    label: '',
    googleAnalyticsTitle: 'Home',
    Component: LandingPageEs,
  },
  ...REQUIRED_ROUTES,
}

export const APP_SHARED_ROUTES: RoutesList = {
  ...REQUIRED_ROUTES,
  NotificationsPage: {
    path: 'notifications',
    googleAnalyticsTitle: 'Notifications',
    Component: NotificationListPage,
  },
}
