/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecurringMachineReservation
 */
export interface RecurringMachineReservation {
    /**
     * 
     * @type {string}
     * @memberof RecurringMachineReservation
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringMachineReservation
     */
    machineId: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringMachineReservation
     */
    laundryUserId: string;
    /**
     * 1 = Monday, 7 = Sunday
     * @type {number}
     * @memberof RecurringMachineReservation
     */
    dayOfWeek: number;
    /**
     * 
     * @type {string}
     * @memberof RecurringMachineReservation
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof RecurringMachineReservation
     */
    endTime: string;
}

/**
 * Check if a given object implements the RecurringMachineReservation interface.
 */
export function instanceOfRecurringMachineReservation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "machineId" in value;
    isInstance = isInstance && "laundryUserId" in value;
    isInstance = isInstance && "dayOfWeek" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "endTime" in value;

    return isInstance;
}

export function RecurringMachineReservationFromJSON(json: any): RecurringMachineReservation {
    return RecurringMachineReservationFromJSONTyped(json, false);
}

export function RecurringMachineReservationFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecurringMachineReservation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'machineId': json['machineId'],
        'laundryUserId': json['laundryUserId'],
        'dayOfWeek': json['dayOfWeek'],
        'startTime': json['startTime'],
        'endTime': json['endTime'],
    };
}

export function RecurringMachineReservationToJSON(value?: RecurringMachineReservation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machineId': value.machineId,
        'laundryUserId': value.laundryUserId,
        'dayOfWeek': value.dayOfWeek,
        'startTime': value.startTime,
        'endTime': value.endTime,
    };
}

