/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OpeningType {
    FREE = 'FREE',
    AD_HOC = 'AD_HOC',
    BLOCKED = 'BLOCKED',
    CLOSED = 'CLOSED'
}


export function OpeningTypeFromJSON(json: any): OpeningType {
    return OpeningTypeFromJSONTyped(json, false);
}

export function OpeningTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpeningType {
    return json as OpeningType;
}

export function OpeningTypeToJSON(value?: OpeningType | null): any {
    return value as any;
}

