import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useNavigate } from 'react-router'
import { Box, Button, Divider, FormControl, FormGroup, FormLabel, Grid, Paper } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  LaundriesApi,
  LaundryReference,
  MachineConnectivityType,
  MachinesApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  MachineViewModel,
  createMachineViewModel,
  mapMachineToMachineViewModel,
  mapMachineViewModelToMachine,
} from 'src/service/view-model/machine/MachineViewModel'
import { getMachineTypeName, isConnectedMachineType } from 'src/service/view-model/machine/Machines'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { AsyncAutoCompleteValidate } from 'src/ui-shared/base/form/control/AsyncAutoCompleteValidate'
import { CheckboxField } from 'src/ui-shared/base/form/control/CheckboxField'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import {
  composeValidators,
  required,
  validTagLength,
  validTagPrefix,
} from 'src/ui-shared/base/form/validation/Validators'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { usePrompt } from 'src/ui-shared/base/hooks/usePrompt'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { MachineTab } from 'src/ui/page/common/machine/details/MachineTab'

export const MachineEditPage = (): ReactElement => {
  const translate = useTranslate()
  const { classes: sharedClasses } = useSharedStyles()

  const navigate = useNavigate()
  const { machineId } = useRequiredParams(['machineId'])
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const machinesApi = new MachinesApi(httpConfiguration)
  const laundriesApi = new LaundriesApi(httpConfiguration)

  const [loading, setLoading] = useState<boolean>(false)
  const [machineViewModel, setMachineViewModel] = useState<MachineViewModel>(createMachineViewModel())
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [dirty, setDirty] = useState<boolean>(false)

  const isConnectedMachine = isConnectedMachineType(machineViewModel.connectivityType as MachineConnectivityType)

  useHotKeysForm()
  usePrompt(translate('notification.form.unsaved.changes'), dirty)

  // load data
  const laundriesSearch = (searchText: string): Promise<LaundryReference[]> => {
    const laundryGroupId = machineViewModel.laundryGroupRef?.id
    const laundryGroupIds = laundryGroupId ? [laundryGroupId] : undefined
    return laundriesApi.laundriesRefGet(undefined, searchText, laundryGroupIds)
  }

  useEffect(() => {
    machinesApi
      .machinesMachineIdGet(machineId, [])
      .then((machine) => {
        const viewModel = mapMachineToMachineViewModel(machine)
        setMachineViewModel(viewModel)
        setErrorMessage(null)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }, [machineId])

  const onSubmitSuccess = () => {
    setDirty(false)
    setLoading(false)
    navigateBack()
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  // handle events
  const navigateBack = () => {
    navigate(`/${appId}/machines/${machineId}/view/${MachineTab.OVERVIEW}`)
  }

  const submitForm = (viewModel: MachineViewModel) => {
    setLoading(true)
    setDirty(false)
    const machine = mapMachineViewModelToMachine(viewModel)
    machinesApi
      .machinesMachineIdPut(machineId, [], machine)
      .then(() => {
        onSubmitSuccess()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  return (
    <ScreenLayout title={machineViewModel.name ? machineViewModel.name : ''} onBack={navigateBack} actionsWidth={50}>
      <Paper elevation={0}>
        <LoadingIndicator loading={loading} />
        <Divider />

        <Box pt={2}>
          {errorMessage ? (
            <ErrorMessage message={errorMessage} />
          ) : (
            machineViewModel.id && (
              <Form<MachineViewModel>
                initialValues={machineViewModel}
                onSubmit={submitForm}
                render={({ handleSubmit, submitting, pristine }) => {
                  return (
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                        <Grid item {...ITEM_BREAKPOINTS}>
                          <TextField name="name" fullWidth label={translate('machineName')} validate={required()} />
                        </Grid>
                        <Grid item {...ITEM_BREAKPOINTS}>
                          <DetailsTextField
                            value={getMachineTypeName(machineViewModel.type, translate)}
                            label={translate('member.machinetype')}
                          />
                        </Grid>
                        <Grid item {...ITEM_BREAKPOINTS}>
                          <DetailsTextField
                            label={translate('laundryGroup')}
                            value={machineViewModel.laundryGroupRef?.name || ''}
                          />
                        </Grid>
                        <Grid item {...ITEM_BREAKPOINTS}>
                          {machineViewModel.laundryGroupRef && machineViewModel.laundryGroupRef.id ? (
                            <AsyncAutoCompleteValidate
                              disabled={submitting}
                              name="laundryRef"
                              validate={required()}
                              label={translate('laundry')}
                              delay={300}
                              labelFieldName="name"
                              loadOptionsFunction={laundriesSearch}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                      <Divider className={sharedClasses.Divider} />
                      <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                        <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                          <TextField
                            label={translate('notes')}
                            type="text"
                            name="notes"
                            fullWidth
                            multiline
                            maxRows={4}
                            disabled={submitting}
                          />
                        </Grid>
                      </Grid>
                      {isConnectedMachine && (
                        <>
                          <Divider className={sharedClasses.Divider} />
                          <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                            <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                              <TextField
                                name="washmaster.tag.contentUrl"
                                fullWidth
                                label={translate('tag')}
                                validate={composeValidators(validTagPrefix(), validTagLength())}
                              />
                            </Grid>
                          </Grid>
                          <Divider className={sharedClasses.Divider} />
                          <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                            <Grid item {...ITEM_BREAKPOINTS} lg={6}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">{translate('features')}</FormLabel>
                                <FormGroup>
                                  <CheckboxField
                                    name="products.washMasterEnabled"
                                    label={translate('washMasterEnabled')}
                                    color="primary"
                                  />

                                  <CheckboxField
                                    name="washmaster.freeUsage"
                                    label={translate('freeUsage')}
                                    color="primary"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      <Divider className={sharedClasses.Divider} />
                      <Box mt={3} display="flex" justifyContent="flex-end">
                        <Button
                          id="cancelButton"
                          variant="text"
                          color="primary"
                          size="large"
                          className={sharedClasses.ButtonMargin}
                          onClick={navigateBack}
                        >
                          {translate('button.cancel')}
                        </Button>
                        <Button
                          id="submitButton"
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          disabled={submitting || pristine}
                        >
                          {translate('button.save')}
                        </Button>
                      </Box>
                      <FormSpy
                        subscription={{ dirty: true }}
                        onChange={(props) => {
                          setTimeout(() => setDirty(props.dirty), 0)
                        }}
                      />
                    </form>
                  )
                }}
              />
            )
          )}
        </Box>
      </Paper>
    </ScreenLayout>
  )
}
