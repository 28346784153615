import React, { FC, ReactElement } from 'react'
import { useNavigate } from 'react-router'
import {
  Button,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  alpha,
  styled,
} from '@mui/material'
import { CheckCircle, CheckCircleOutlined, Clear } from '@mui/icons-material'
import { AppId } from 'src/app/AppId'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { LaundryGroupSubscription, ServicePackagePlan, ServicePackagePlanFeature } from 'src/service/backend/api'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'
import { HeadCells } from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'

const FeatureTableCell = styled(TableCell)<{
  selected: boolean
  position: 'header' | 'body' | 'footer'
}>(({ theme, selected, position }) => ({
  textAlign: 'center',

  ...(position === 'body' && {
    padding: 0,
  }),

  ...(position === 'footer' && {
    border: 'none',
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  }),

  ...(selected && {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    fontSize: 'large',
    color: theme.palette.primary.main,
    border: '0',
    width: '25%',

    ...(position === 'header' && {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    }),

    ...(position === 'footer' && {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    }),
  }),
}))

const headCells: HeadCells[] = [
  {
    id: 'feature',
    label: 'feature',
    noSort: true,
  },
]

interface Prop {
  laundryGroupSubscription: LaundryGroupSubscription
}

export const ServicePackagePlanTable: FC<Prop> = ({ laundryGroupSubscription }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const navigate = useNavigate()
  const translate = useTranslate()

  const legacyPlanFilter = (plan: ServicePackagePlan): boolean => {
    return !plan.legacyPlan || plan.id === activePlanId
  }

  // derived state
  const packagePlans = laundryGroupSubscription.servicePackagePlans
  const activePlanId = laundryGroupSubscription.activatedServicePackagePlan.id
  const filteredPackagePlans = packagePlans.length > 0 ? packagePlans.filter(legacyPlanFilter) : []

  // we combine all features from all plans into a single set
  const packagePlanFeatures: ServicePackagePlanFeature[] = []
  const allFeatures = laundryGroupSubscription.servicePackagePlans
    .filter((plan) => !plan.legacyPlan || plan.id === activePlanId)
    .flatMap((plan) => plan.features)
  allFeatures.forEach((feature) => {
    if (!findReferenceObject(packagePlanFeatures, feature.name, 'name')) {
      packagePlanFeatures.push(feature)
    }
  })

  const laundryGroupId = laundryGroupSubscription.laundryGroup.id

  const navigateToUpgradePlan = (selectedPackagePlanId: string) => {
    navigate(`/${AppId.WASH_MASTER}/service-package-plan/${laundryGroupId}/view/upgrade/${selectedPackagePlanId}`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const isActivePackagePlan = (plan: ServicePackagePlan): boolean => {
    return activePlanId === plan.id
  }

  const displayHeaders = (
    <>
      {/* standard headers */}
      {headCells.map((item) => {
        return <TableCell key={item.id}>{translate(item.label)}</TableCell>
      })}

      {/* package plan names as headers */}
      {filteredPackagePlans.map((plan) => {
        const isPlanActive = isActivePackagePlan(plan)
        return (
          <FeatureTableCell key={plan.id} selected={isPlanActive} position={'header'}>
            {plan.name}
          </FeatureTableCell>
        )
      })}
    </>
  )

  const displayPackagePlanFeaturesRows =
    packagePlanFeatures.length > 0 ? (
      <>
        {/* features with checkmarks */}
        {packagePlanFeatures.map((feature, index) => {
          return (
            <TableRow className={tableClasses.tableRow} key={index} style={{ cursor: 'default' }}>
              <TableCell key={index}>{feature.name}</TableCell>
              {filteredPackagePlans.map((plan) => {
                const featureInPlan = findReferenceObject(plan.features, feature.name, 'name')
                const isFeatureActive = featureInPlan && featureInPlan.activated
                const isPlanActive = isActivePackagePlan(plan)
                return (
                  <FeatureTableCell key={plan.id} selected={isPlanActive} position={'body'}>
                    {isFeatureActive ? <CheckCircle color={'primary'} /> : <Clear color={'disabled'} />}
                  </FeatureTableCell>
                )
              })}
            </TableRow>
          )
        })}

        {/* last row with buttons for upgrade plan */}
        <TableRow className={tableClasses.tableRow} key={'actionRow'} style={{ cursor: 'default' }}>
          <TableCell style={{ border: 'none' }}></TableCell>
          {filteredPackagePlans.map((plan) => {
            const isPlanActive = isActivePackagePlan(plan)
            return (
              <FeatureTableCell key={plan.id} selected={isPlanActive} position={'footer'}>
                {isPlanActive ? (
                  <Chip
                    icon={<CheckCircleOutlined fontSize={'small'} />}
                    color={'primary'}
                    label={translate('active')}
                  />
                ) : (
                  <Button
                    onClick={() => navigateToUpgradePlan(plan.id)}
                    variant="outlined"
                    disabled={plan.legacyPlan}
                    color="primary"
                    sx={{ minWidth: 0 }}
                  >
                    {translate('button.activateNow')}
                  </Button>
                )}
              </FeatureTableCell>
            )
          })}
        </TableRow>
      </>
    ) : (
      <TableEmpty colspan={headCells.length} />
    )

  const displayServicePackagePlansTable = (
    <Grid item sm={12}>
      <Paper elevation={0}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>{displayHeaders}</TableRow>
            </TableHead>
            <TableBody>{displayPackagePlanFeaturesRows}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  )

  return displayServicePackagePlansTable
}
