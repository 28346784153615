import React, { ReactElement, useEffect } from 'react'
import { ThemeProvider, styled } from '@mui/material'
import { ReactComponent as LogoImage } from 'src/assets/logo-servicemaster-white.svg'
import { SM_THEME } from 'src/theme/Theme'
import { Layout } from 'src/ui/layout/main-layout/Layout'

const SmLogo = styled(LogoImage)(({ theme }) => ({
  height: '23.06px',
  width: '180.8px',
  [theme.breakpoints.up('md')]: {
    width: '214px',
  },
}))

export const SmLayout = (): ReactElement => {
  useEffect(() => {
    document.title = 'serviceMaster'
  }, [])

  return (
    <ThemeProvider theme={SM_THEME}>
      <Layout appLogo={<SmLogo />} enableActiveOrganizationSelector />
    </ThemeProvider>
  )
}
