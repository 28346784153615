/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProgramModuleDocument } from './ProgramModuleDocument';
import {
    ProgramModuleDocumentFromJSON,
    ProgramModuleDocumentFromJSONTyped,
    ProgramModuleDocumentToJSON,
} from './ProgramModuleDocument';
import type { SettingField } from './SettingField';
import {
    SettingFieldFromJSON,
    SettingFieldFromJSONTyped,
    SettingFieldToJSON,
} from './SettingField';

/**
 * 
 * @export
 * @interface ProgramModuleSettings
 */
export interface ProgramModuleSettings {
    /**
     * 
     * @type {string}
     * @memberof ProgramModuleSettings
     */
    moduleId: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramModuleSettings
     */
    moduleLabel?: string;
    /**
     * 
     * @type {number}
     * @memberof ProgramModuleSettings
     */
    stepNumber: number;
    /**
     * 
     * @type {Array<SettingField>}
     * @memberof ProgramModuleSettings
     */
    settings: Array<SettingField>;
    /**
     * 
     * @type {Array<ProgramModuleDocument>}
     * @memberof ProgramModuleSettings
     */
    documentation?: Array<ProgramModuleDocument>;
}

/**
 * Check if a given object implements the ProgramModuleSettings interface.
 */
export function instanceOfProgramModuleSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "moduleId" in value;
    isInstance = isInstance && "stepNumber" in value;
    isInstance = isInstance && "settings" in value;

    return isInstance;
}

export function ProgramModuleSettingsFromJSON(json: any): ProgramModuleSettings {
    return ProgramModuleSettingsFromJSONTyped(json, false);
}

export function ProgramModuleSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramModuleSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'moduleId': json['moduleId'],
        'moduleLabel': !exists(json, 'moduleLabel') ? undefined : json['moduleLabel'],
        'stepNumber': json['stepNumber'],
        'settings': ((json['settings'] as Array<any>).map(SettingFieldFromJSON)),
        'documentation': !exists(json, 'documentation') ? undefined : ((json['documentation'] as Array<any>).map(ProgramModuleDocumentFromJSON)),
    };
}

export function ProgramModuleSettingsToJSON(value?: ProgramModuleSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'moduleId': value.moduleId,
        'moduleLabel': value.moduleLabel,
        'stepNumber': value.stepNumber,
        'settings': ((value.settings as Array<any>).map(SettingFieldToJSON)),
        'documentation': value.documentation === undefined ? undefined : ((value.documentation as Array<any>).map(ProgramModuleDocumentToJSON)),
    };
}

