import React, { ReactElement, Suspense, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Autocomplete, Box, Grid } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { Configuration, ReportDailyUsagesApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { findItemAndSelect } from 'src/service/view-model/base/Reference.utils'
import { MACHINE_CATEGORIES, MachineCategoryObject } from 'src/service/view-model/machine/Machines'
import {
  DEFAULT_DAILY_USAGE_CHART_SETTINGS,
  UtilizationDailyUsageChartSettings,
} from 'src/service/view-model/usage-chart/UtilizationDailyUsageChartViewModel'
import { FormAccordion, FormAccordionDetails } from 'src/ui-shared/base/accordion/FormAccordion'
import { FormAccordionSummaryDataFilter } from 'src/ui-shared/base/accordion/FormAccordionSummaryDataFilter'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { useDataSettingsUrlSync } from 'src/ui-shared/base/hooks/useDataSettingsUrlSync'
import { useFirstRender } from 'src/ui-shared/base/hooks/useFirstRender'
import { ChartDataFilter } from 'src/ui-shared/chart/ChartDataFilter'
import { ChartTimelineResult, DEFAULT_TIMESERIES_DATA } from 'src/ui-shared/chart/ChartSeries.const'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { withOrganization } from 'src/ui-shared/table/Table.const'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'

const AsyncChartTimeline = React.lazy(() =>
  import('src/ui-shared/chart/ChartSeriesRangeBar').then(({ ChartSeries }) => ({ default: ChartSeries })),
)

export const UtilizationDailyUsageChart = (): ReactElement => {
  const { classes: textFieldClasses } = useTextFieldStyles()

  const translate = useTranslate()
  const location = useLocation()
  const activeOrganization = useActiveOrganization()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportDailyUsage = new ReportDailyUsagesApi(httpConfiguration)

  // state
  const defaultDailyUsageChartSettings: UtilizationDailyUsageChartSettings = { ...DEFAULT_DAILY_USAGE_CHART_SETTINGS }
  const [dailyUsageChartSettings, setDailyUsageChartSettings] = useState<UtilizationDailyUsageChartSettings>(
    withOrganization(defaultDailyUsageChartSettings, activeOrganization),
  )
  const [machineCategory, setMachineCategory] = useState<MachineCategoryObject | null>(null)

  useEffect(() => {
    findItemAndSelect(dailyUsageChartSettings.machineCategory, setMachineCategory, machineCategory, MACHINE_CATEGORIES)
  }, [dailyUsageChartSettings.machineCategory])

  const isFirstRender = useFirstRender()

  const loadChart = (): Promise<ChartTimelineResult> => {
    const dateStartTimeFrom = new Date(dailyUsageChartSettings.startDateFrom)
    const dateStartTimeTo = new Date(dailyUsageChartSettings.startDateTo)

    // backend requires organization filter to be applied
    if (isFirstRender && !dailyUsageChartSettings.organizationId) {
      return new Promise((resolve) => {
        resolve({
          timeseriesData: DEFAULT_TIMESERIES_DATA,
          fromDate: dateStartTimeFrom,
          toDate: dateStartTimeTo,
        })
      })
    }

    const apiPromise = reportDailyUsage.reportSmDailyUsagesChartGet(
      dateStartTimeFrom,
      dateStartTimeTo,
      dailyUsageChartSettings.organizationId,
      dailyUsageChartSettings.laundryGroupId,
      dailyUsageChartSettings.laundryId,
      dailyUsageChartSettings.machineId,
      dailyUsageChartSettings.machineCategory,
    )

    return apiPromise.then((data) => {
      const result: ChartTimelineResult = {
        timeseriesData: data,
        fromDate: dateStartTimeFrom,
        toDate: dateStartTimeTo,
      }
      return result
    })
  }

  // generic reactivity

  // update state from url
  useDataSettingsUrlSync(location, setDailyUsageChartSettings, dailyUsageChartSettings, defaultDailyUsageChartSettings)

  const handleMachineCategoryChange = (_: unknown, machineCategoryObject: MachineCategoryObject | null) => {
    setMachineCategory(machineCategoryObject)
    setDailyUsageChartSettings({
      ...dailyUsageChartSettings,
      machineCategory: machineCategoryObject?.id,
    })
  }
  return (
    <Box mt={1}>
      {/* Groupings */}
      <Box mt={1}>
        <FormAccordion defaultExpanded={true}>
          <FormAccordionSummaryDataFilter />
          <FormAccordionDetails>
            <Grid container spacing={2}>
              {/*Input fields for selecting date range */}
              <TableDatePickerForm
                key={`${dailyUsageChartSettings.startDateFrom}${dailyUsageChartSettings.startDateTo}`}
                firstDatePropName="startDateFrom"
                secondDatePropName="startDateTo"
                tableSettings={dailyUsageChartSettings}
                setTableSettings={setDailyUsageChartSettings}
                useLastWeek={true}
              />
            </Grid>
          </FormAccordionDetails>
        </FormAccordion>
      </Box>

      {/* Filters */}
      <Box mt={1}>
        {/*Input fields for data filter: organization, laundry group, laundry and machine */}
        <ChartDataFilter
          chartSettings={dailyUsageChartSettings}
          setChartSettings={setDailyUsageChartSettings}
          organization={'organizationId'}
          laundryGroup={'laundryGroupId'}
          laundry={'laundryId'}
          machine={'machineId'}
          sameLine={true}
          preselectFirstOrganizationNoActive={true}
          defaultExpanded
          additionalFilterKeys={['machineCategory']}
        >
          <Grid item {...ITEM_BREAKPOINTS} md={6} lg={true}>
            <Autocomplete
              openOnFocus={true}
              multiple={false}
              freeSolo={false}
              disableClearable={false}
              options={MACHINE_CATEGORIES}
              getOptionLabel={(option) => translate(option.name) || ''}
              isOptionEqualToValue={(option) => option.id === dailyUsageChartSettings.machineCategory}
              value={machineCategory}
              onChange={handleMachineCategoryChange}
              noOptionsText={translate('autocompleteNoOptions')}
              loadingText={translate('autocompleteLoading')}
              renderInput={(params) => (
                <TextFieldDefault
                  {...params}
                  label={translate('machineCategory')}
                  className={textFieldClasses.TextFieldSmall}
                />
              )}
            />
          </Grid>
        </ChartDataFilter>
      </Box>
      <Suspense fallback={<div style={{ textAlign: 'center' }}>{translate('autocompleteLoading')}</div>}>
        <Box width="100%">
          <AsyncChartTimeline loadChart={loadChart} chartSettings={dailyUsageChartSettings} />
        </Box>
      </Suspense>
    </Box>
  )
}
