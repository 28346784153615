import { SettingField, SettingFieldType } from 'src/service/backend/api'
import { SettingFieldWithIndex } from 'src/service/view-model/setting-field/SettingFieldUtils'

export interface SettingWithExpandableGroups extends SettingFieldWithIndex {
  inputsForGroup?: SettingFieldWithIndex[]
}

/**
 * Transforms the settings to settings with expandable groups
 * Groups which are expandable, will have the inputs related to that group field allocated in the field and removed from the list to
 * prevent copies
 * @param fields the settings (fields).
 * @returns {SettingFieldWithIndex} new array of the settings with expandable groups with the assigned inputs per expandable group.
 */
export const transformProgramSettingsWithExpandableGroups = (
  fields: SettingFieldWithIndex[],
): SettingWithExpandableGroups[] => {
  const fieldsWithExpandableGroups: SettingWithExpandableGroups[] = []

  // go through all fields
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]

    // when group field which is expandable is found extend that group with the fields which
    // are associated with that group
    if (field.fieldType === SettingFieldType.GROUP && field.groupField?.expandable) {
      const groupFieldWithAssociatedInputs: SettingWithExpandableGroups = {
        ...field,
        inputsForGroup: [],
      }

      // the index of the first input for the associated group
      let indexOfGroupInput = i + 1

      let endOfGroup: SettingFieldWithIndex | undefined = undefined

      // stops when end of group is found or the index is more than the fields length
      while (indexOfGroupInput < fields.length) {
        const groupInputField = fields[indexOfGroupInput]

        // if the group is ended by another group assign the group to endOfGroup variable
        if (groupInputField.fieldType === SettingFieldType.GROUP) {
          endOfGroup = groupInputField
          break
        }
        groupFieldWithAssociatedInputs.inputsForGroup?.push(groupInputField)

        indexOfGroupInput++
      }

      // continue looping through the fields ignoring the ones already added in the expandable group field
      i = indexOfGroupInput

      fieldsWithExpandableGroups.push(groupFieldWithAssociatedInputs)

      // if the expandable group is ended by another group field, insert the group in the array after
      // the expandable group
      if (endOfGroup) {
        fieldsWithExpandableGroups.push(endOfGroup)
      }
    } else {
      fieldsWithExpandableGroups.push(field)
    }
  }

  return fieldsWithExpandableGroups
}

const GROUP_END_OBJECT: SettingField = {
  settingId: 'END',
  label: '',
  fieldType: SettingFieldType.GROUP,
  readOnly: true,
}

export const flattenGroups = (fields: SettingField[]): SettingField[] => {
  const stack: SettingField[] = []

  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]
    const fieldType = field.fieldType
    stack.push(field)

    if (fieldType === SettingFieldType.GROUP && field.groupField && field.groupField.fields) {
      const groupSubFields = flattenGroups(field.groupField.fields)
      field.groupField.fields = undefined
      for (let k = 0; k < groupSubFields.length; k++) {
        stack.push(groupSubFields[k])
      }
      stack.push(GROUP_END_OBJECT)
    }
  }

  return stack
}

export const updateSettingFieldsWithValues = (originalFields: SettingField[], flatFields: SettingField[]): void => {
  for (let i = 0; i < flatFields.length; i++) {
    const field = flatFields[i]
    const fieldType = field.fieldType
    const settingId = field.settingId

    if (fieldType !== SettingFieldType.GROUP) {
      findAndUpdateField(originalFields, settingId, field)
    }
  }
}

const findAndUpdateField = (fields: SettingField[], settingIdToFind: string, newField: SettingField) => {
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]
    const fieldType = field.fieldType
    const settingId = field.settingId

    if (fieldType === SettingFieldType.GROUP && field.groupField && field.groupField.fields) {
      findAndUpdateField(field.groupField.fields, settingIdToFind, newField)
    } else {
      if (settingId === settingIdToFind) {
        fields[i] = newField
      }
    }
  }
}
