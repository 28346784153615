import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Alert, Button, Divider, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, Manufacturer, ManufacturerModelsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

export const ManufacturerDetailsPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()

  const navigate = useNavigate()
  const location = useLocation()
  const appId = useAppId()

  const { manufacturerId } = useRequiredParams(['manufacturerId'])

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack

  const httpConfiguration: Configuration = useContext(HttpContext)
  const manufacturerApi = new ManufacturerModelsApi(httpConfiguration)

  // state
  const [manufacturer, setManufacturer] = useState<Manufacturer | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [readonly, setReadonly] = useState<boolean>(false)

  useHotKeysForm()

  // load data
  useEffect(() => {
    let active = true

    manufacturerApi
      .manufacturersManufacturerIdGet(manufacturerId)
      .then((manufacturer) => {
        setReadonly(!manufacturer.editable)
        setManufacturer(manufacturer)
      })
      .catch((err) => {
        if (!active) return
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
    return () => {
      active = false
    }
  }, [])

  // handle events
  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/manufacturers`)
  }

  const navigateToEditPage = () => {
    navigate(`/${appId}/manufacturers/${manufacturerId}/edit`)
  }

  // JSX
  const editButton = (
    <Button
      disabled={readonly}
      id="editButton"
      variant="contained"
      color="primary"
      size="large"
      onClick={navigateToEditPage}
    >
      {translate('button.edit')}
    </Button>
  )

  return (
    <ScreenLayout
      title={manufacturer?.name ? manufacturer.name : ''}
      onBack={navigateBack}
      actions={<></>}
      actionsWidth={50}
    >
      <Paper elevation={0}>
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <Box pt={2}>
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              {readonly ? (
                <Grid item md={12} lg={12}>
                  <Alert severity={'warning'}>{translate('manufacturer.nonEditable')}</Alert>
                </Grid>
              ) : null}
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                <DetailsTextField value={manufacturer?.name} label={translate('manufacturerName')} />
              </Grid>
            </Grid>
            <Divider sx={{ mb: 1 }} />
            <Box pt={2} display="flex" justifyContent={'end'}>
              <Box display="flex" justifyContent="flex-end">
                {editButton}
              </Box>
            </Box>
          </Box>
        )}
      </Paper>
    </ScreenLayout>
  )
}
