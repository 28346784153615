import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import {
  Alert,
  Box,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, OrganizationReference, OrganizationsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

interface Props {
  open: boolean
  setOpen: (value: boolean) => void
  showNoneOrganizationOption?: boolean
  onConfirm: (item?: OrganizationReference) => Promise<any> | void
  disabledOrganization?: OrganizationReference
  preselectedOrganization?: OrganizationReference
  preselectFirstIfSingle?: boolean
  displayActions?: boolean
  modalTitle?: string
  helperText?: string
  confirmText?: string
  alertMessage?: string
}

export const SelectOrganizationDialog: FC<Props> = ({
  open,
  setOpen,
  showNoneOrganizationOption = false,
  onConfirm,
  preselectedOrganization,
  preselectFirstIfSingle,
  disabledOrganization,
  displayActions = true,
  modalTitle,
  helperText,
  confirmText,
  alertMessage,
}): ReactElement => {
  const translate = useTranslate()
  const { classes: textFieldClasses } = useTextFieldStyles()
  const { classes: sharedClasses } = useSharedStyles()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const organizationsApi = new OrganizationsApi(httpConfiguration)

  // state
  const [loading, setLoading] = useState<boolean>(true)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [organizations, setOrganizations] = useState<OrganizationReference[]>([])
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationReference | undefined>(
    preselectedOrganization,
  )
  const [searchText, setSearchText] = useState<string>('')

  const searchTextLowercase = searchText.toLowerCase().trim()

  // load data on state change
  useEffect(() => {
    let active = true

    setLoading(true)

    organizationsApi
      .organizationsRefGet()
      .then((data) => {
        if (active) {
          setOrganizations(data)
          setLoading(false)
          // pre-select the organization if it is single one
          if (preselectFirstIfSingle && data.length === 1) {
            const firstOrg = data[0]
            setSelectedOrganization(firstOrg)
            onConfirm(firstOrg)
          }
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setLoading(false)
      })

    return () => {
      active = false
    }
  }, [])

  // handler
  const handleSubmit = (): void => {
    const onConfirmPromise = onConfirm(selectedOrganization)
    if (onConfirmPromise instanceof Promise) {
      setSubmitLoading(true)
      onConfirmPromise.finally(() => {
        setSubmitLoading(false)
      })
    }
  }

  const selectOrganization = (organization?: OrganizationReference) => {
    // if modal actions are not show, then submit selected organization on row click
    if (!displayActions) {
      onConfirm(organization)
    }
    // else modify state and submit on action button click
    else {
      setSelectedOrganization(organization)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const disabledSubmitButton = (): boolean => {
    if (preselectedOrganization) {
      return selectedOrganization === preselectedOrganization
    } else {
      return selectedOrganization === undefined
    }
  }

  // JSX
  const renderTitle = (): string => {
    let title = ''
    if (modalTitle) {
      title = translate(modalTitle)
    } else {
      title = translate('selectOrganization')
    }

    return title
  }

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      title={renderTitle()}
      maxWidth="xs"
      withCloseIcon={true}
      minHeight={100}
    >
      <DialogContent>
        <LoadingIndicator loading={submitLoading} />
        {alertMessage && (
          <Alert severity={'warning'} sx={{ paddingTop: '0px', paddingBottom: '0px', flexGrow: 1 }}>
            {translate(alertMessage)}
          </Alert>
        )}
        {helperText && <Typography mb={1}>{translate(helperText)}</Typography>}
        <List>
          <ListSubheader sx={{ padding: 0 }}>
            <TextFieldDefault
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value || '')
              }}
              disabled={submitLoading}
              label={translate('search.placeholder')}
              className={textFieldClasses.TextFieldSmall}
              sx={{ marginBottom: 3 }}
            />
          </ListSubheader>

          {showNoneOrganizationOption ? (
            <>
              <ListItemButton
                key={'none'}
                disabled={submitLoading}
                selected={!selectedOrganization}
                onClick={() => selectOrganization(undefined)}
              >
                <ListItemText primary={translate('all')} secondary={translate('allOrganizationsDisplayed')} />
              </ListItemButton>
              <Divider component="li" sx={{ marginTop: 1, marginBottom: 1 }} />
            </>
          ) : null}

          {loading ? (
            <>
              <Box p={10} sx={{ marginTop: 4 }}>
                <LoadingIndicator loading={loading} />
              </Box>
            </>
          ) : (
            organizations
              .filter((item) => {
                if (searchTextLowercase === '') {
                  return true
                }
                return item.name?.toLowerCase().includes(searchTextLowercase)
              })
              .map((item) => {
                return (
                  <ListItemButton
                    key={item.name}
                    selected={selectedOrganization?.id === item.id}
                    disabled={item.id === disabledOrganization?.id || submitLoading}
                    onClick={() => selectOrganization(item)}
                  >
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                )
              })
          )}
        </List>
      </DialogContent>

      {displayActions && (
        <DialogActions>
          <Box mt={2} p={2} display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              color="primary"
              size="large"
              className={sharedClasses.ButtonMargin}
              onClick={() => setOpen(false)}
            >
              {translate('button.cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={disabledSubmitButton() || submitLoading}
              onClick={handleSubmit}
            >
              {confirmText ? translate(confirmText) : translate('button.confirm')}
            </Button>
          </Box>
        </DialogActions>
      )}
    </ModalDialog>
  )
}
