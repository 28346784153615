/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Invitation,
  InvitationAcceptBody,
  OrganizationType,
  PageableInvitations,
} from '../models';
import {
    InvitationFromJSON,
    InvitationToJSON,
    InvitationAcceptBodyFromJSON,
    InvitationAcceptBodyToJSON,
    OrganizationTypeFromJSON,
    OrganizationTypeToJSON,
    PageableInvitationsFromJSON,
    PageableInvitationsToJSON,
} from '../models';

export interface InvitationsAcceptPostRequest {
    invitationAcceptBody?: InvitationAcceptBody;
}

export interface InvitationsGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    id?: string;
    organizationId?: string;
    type?: Array<OrganizationType>;
}

export interface InvitationsInvitationIdDeleteRequest {
    invitationId: string;
}

export interface InvitationsPostRequest {
    invitation?: Invitation;
}

/**
 * 
 */
export class InvitationsApi extends runtime.BaseAPI {

    /**
     * Accept an Invitation
     */
    async invitationsAcceptPostRaw(requestParameters: InvitationsAcceptPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invitations/accept`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvitationAcceptBodyToJSON(requestParameters.invitationAcceptBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept an Invitation
     */
    async invitationsAcceptPost(invitationAcceptBody?: InvitationAcceptBody, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invitationsAcceptPostRaw({ invitationAcceptBody: invitationAcceptBody }, initOverrides);
    }

    /**
     * List Invitations
     */
    async invitationsGetRaw(requestParameters: InvitationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableInvitations>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invitations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableInvitationsFromJSON(jsonValue));
    }

    /**
     * List Invitations
     */
    async invitationsGet(size?: number, page?: number, sort?: string, search?: string, id?: string, organizationId?: string, type?: Array<OrganizationType>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableInvitations> {
        const response = await this.invitationsGetRaw({ size: size, page: page, sort: sort, search: search, id: id, organizationId: organizationId, type: type }, initOverrides);
        return await response.value();
    }

    /**
     * Delete Invitation
     */
    async invitationsInvitationIdDeleteRaw(requestParameters: InvitationsInvitationIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling invitationsInvitationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invitations/{invitationId}`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Invitation
     */
    async invitationsInvitationIdDelete(invitationId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invitationsInvitationIdDeleteRaw({ invitationId: invitationId }, initOverrides);
    }

    /**
     * Create a Invitation
     */
    async invitationsPostRaw(requestParameters: InvitationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invitation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invitations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvitationToJSON(requestParameters.invitation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationFromJSON(jsonValue));
    }

    /**
     * Create a Invitation
     */
    async invitationsPost(invitation?: Invitation, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invitation> {
        const response = await this.invitationsPostRaw({ invitation: invitation }, initOverrides);
        return await response.value();
    }

}
