import React, { FC, ReactElement, Suspense, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import { ChartData, Configuration, ReportUsagesApi, UtilizationChartFactType } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { isValidPositiveNumber } from 'src/service/utils/NumberUtils'
import { findItemAndSelect } from 'src/service/view-model/base/Reference.utils'
import { CHART_SCALE_OBJECTS, getChartScaleObject } from 'src/service/view-model/base/chart/Chart.const'
import { MACHINE_CATEGORIES, MachineCategoryObject } from 'src/service/view-model/machine/Machines'
import {
  COMPARE_MACHINE_CHART_FACTTYPE_OBJECTS,
  DEFAULT_PROGRAM_USAGE_CHART_SETTINGS,
  OUTPUT_CHART_FACTTYPE_OBJECTS,
  PROGRAM_USAGE_CHART_FACTTYPE_OBJECTS,
  PROGRAM_USAGE_CHART_GROUPBY_OBJECTS,
  ProgramUsageChartFactTypeObject,
  ProgramUsageChartSettings,
  getProgramUsageFactTypeObject,
  getProgramUsageGroupByObject,
} from 'src/service/view-model/usage-chart/ProgramUsageChartViewModel'
import { useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldAsync } from 'src/ui-shared/base/form/control/TextFieldAsync'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'
import { useDataSettingsUrlSync } from 'src/ui-shared/base/hooks/useDataSettingsUrlSync'
import { ChartDataFilter } from 'src/ui-shared/chart/ChartDataFilter'
import { ChartDataSelection } from 'src/ui-shared/chart/ChartDataSelection'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { withOrganization } from 'src/ui-shared/table/Table.const'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'
import { useUserRegionData } from 'src/user/UserContext'

interface Props {
  isUtilizationChart?: boolean
  isOutputChart?: boolean
}

const AsyncChart = React.lazy(() => import('src/ui-shared/chart/Chart').then(({ Chart }) => ({ default: Chart })))

export const ProgramUsageChart: FC<Props> = ({ isUtilizationChart = false, isOutputChart = false }): ReactElement => {
  const { classes: textFieldClasses } = useTextFieldStyles()
  const translate = useTranslate()

  const location = useLocation()
  const activeOrganization = useActiveOrganization()
  const regionData = useUserRegionData()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportsApi = new ReportUsagesApi(httpConfiguration)

  let factType: UtilizationChartFactType
  let factTypeOptions: ProgramUsageChartFactTypeObject[]

  if (isUtilizationChart) {
    // utilization chart (%), compare machine groups
    factType = UtilizationChartFactType.UTILIZATION
    factTypeOptions = COMPARE_MACHINE_CHART_FACTTYPE_OBJECTS
  } else if (isOutputChart) {
    // output chart (kg)
    factType = UtilizationChartFactType.OUTPUT
    factTypeOptions = OUTPUT_CHART_FACTTYPE_OBJECTS
  } else {
    // program usage chart (#)
    factType = UtilizationChartFactType.COUNT
    factTypeOptions = PROGRAM_USAGE_CHART_FACTTYPE_OBJECTS
  }

  // state
  const defaultProgramUsageSettings: ProgramUsageChartSettings = {
    ...DEFAULT_PROGRAM_USAGE_CHART_SETTINGS,
    factType: factType,
  }
  const [programUsageSettings, setProgramUsageSettings] = useState<ProgramUsageChartSettings>(
    withOrganization(defaultProgramUsageSettings, activeOrganization),
  )
  const [machineCategory, setMachineCategory] = useState<MachineCategoryObject | null>(null)

  useEffect(() => {
    findItemAndSelect(programUsageSettings.machineCategory, setMachineCategory, machineCategory, MACHINE_CATEGORIES)
  }, [programUsageSettings.machineCategory])

  const loadChart = (): Promise<ChartData> => {
    const dateStartTimeFrom = new Date(programUsageSettings.startDateFrom)
    const dateStartTimeTo = new Date(programUsageSettings.startDateTo)

    return reportsApi.reportSmUsagesChartGet(
      programUsageSettings.groupBy,
      programUsageSettings.factType,
      programUsageSettings.chartScale,
      dateStartTimeFrom,
      dateStartTimeTo,
      undefined,
      programUsageSettings.organizationId,
      programUsageSettings.laundryGroupId,
      programUsageSettings.laundryId,
      programUsageSettings.machineId,
      programUsageSettings.machineCategory,
      programUsageSettings.programMode,
      programUsageSettings.programName,
      programUsageSettings.temperature,
    )
  }

  // generic reactivity

  // update state from url / apply state to url
  useDataSettingsUrlSync(location, setProgramUsageSettings, programUsageSettings, defaultProgramUsageSettings)

  const handleMachineCategoryChange = (_: unknown, machineCategoryObject: MachineCategoryObject | null) => {
    setMachineCategory(machineCategoryObject)
    setProgramUsageSettings({
      ...programUsageSettings,
      machineCategory: machineCategoryObject?.id,
    })
  }

  // render
  const renderFactTypeOptionLabel = (option: ProgramUsageChartFactTypeObject) => {
    if (option.id === UtilizationChartFactType.COUNT) {
      return `${translate(option.name)} (#)`
    } else if (option.id === UtilizationChartFactType.OUTPUT) {
      return `${translate(option.name)} ${regionData.weight}`
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (option.id === UtilizationChartFactType.UTILIZATION) {
      return `${translate(option.name)} (%)`
    }
    return translate(option.name)
  }

  return (
    <Box mt={1}>
      {/* Groupings */}
      <Box>
        {/*Input fields for data selection: factType, groupBy and chartScale*/}
        <ChartDataSelection
          chartSettings={programUsageSettings}
          setChartSettings={setProgramUsageSettings}
          factType={'factType'}
          groupBy={'groupBy'}
          scale={'chartScale'}
          factTypeOptions={factTypeOptions}
          factTypeOptionsLabel={renderFactTypeOptionLabel}
          factTypeValue={(factType) => getProgramUsageFactTypeObject(factType, isUtilizationChart, isOutputChart)}
          groupByOptions={PROGRAM_USAGE_CHART_GROUPBY_OBJECTS}
          groupByOptionsLabel={(option) => translate(option.name)}
          groupByValue={getProgramUsageGroupByObject}
          scaleOptions={CHART_SCALE_OBJECTS}
          scaleOptionsLabel={(option) => translate(option.name)}
          scaleValue={getChartScaleObject}
        >
          {/*Input fields for selecting date range */}
          <TableDatePickerForm
            key={`${programUsageSettings.startDateFrom}${programUsageSettings.startDateTo}`}
            firstDatePropName="startDateFrom"
            secondDatePropName="startDateTo"
            tableSettings={programUsageSettings}
            setTableSettings={setProgramUsageSettings}
          />
        </ChartDataSelection>
      </Box>

      {/* Filters */}
      <Box mt={1}>
        {/*Input fields for data filter: organization, laundry group, laundry and machine */}
        <ChartDataFilter
          chartSettings={programUsageSettings}
          setChartSettings={setProgramUsageSettings}
          organization={'organizationId'}
          laundryGroup={'laundryGroupId'}
          laundry={'laundryId'}
          machine={'machineId'}
          sameLine={isUtilizationChart}
          additionalFilterKeys={['machineCategory', 'programMode', 'programName', 'temperature']}
        >
          <Grid item {...ITEM_BREAKPOINTS} sm={6} md={6} lg={isUtilizationChart ? true : 3}>
            <Autocomplete
              openOnFocus={true}
              multiple={false}
              freeSolo={false}
              disableClearable={false}
              options={MACHINE_CATEGORIES}
              getOptionLabel={(option) => translate(option.name) || ''}
              isOptionEqualToValue={(option) => option.id === programUsageSettings.machineCategory}
              value={machineCategory}
              onChange={handleMachineCategoryChange}
              noOptionsText={translate('autocompleteNoOptions')}
              loadingText={translate('autocompleteLoading')}
              renderInput={(params) => (
                <TextFieldDefault
                  {...params}
                  label={translate('machineCategory')}
                  className={textFieldClasses.TextFieldSmall}
                />
              )}
            />
          </Grid>

          {isUtilizationChart ? null : (
            <>
              <Grid item {...ITEM_BREAKPOINTS} sm={6} md={6} lg={3}>
                <TextFieldAsync
                  tableSettings={programUsageSettings}
                  setTableSettings={setProgramUsageSettings}
                  filter={'programMode'}
                  label={translate('programMode')}
                  delay={500}
                  textFieldClassName={textFieldClasses.TextFieldSmall}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} sm={6} md={6} lg={3}>
                <TextFieldAsync
                  tableSettings={programUsageSettings}
                  setTableSettings={setProgramUsageSettings}
                  filter={'programName'}
                  label={translate('programName')}
                  delay={500}
                  textFieldClassName={textFieldClasses.TextFieldSmall}
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} sm={6} md={6} lg={3}>
                <TextFieldAsync
                  tableSettings={programUsageSettings}
                  setTableSettings={setProgramUsageSettings}
                  filter={'temperature'}
                  label={translate('temperature')}
                  delay={500}
                  isValid={(value: string) => {
                    return isValidPositiveNumber(value)
                  }}
                  errorText={translate('validation.valid.temperature')}
                  textFieldClassName={textFieldClasses.TextFieldSmall}
                />
              </Grid>
            </>
          )}
        </ChartDataFilter>

        {/*Other input fields for data filter*/}
        {/* <Grid container spacing={2} mt={0.5}></Grid> */}
      </Box>
      <Suspense fallback={<div style={{ textAlign: 'center' }}>{translate('autocompleteLoading')}</div>}>
        <AsyncChart loadChart={loadChart} chartSettings={programUsageSettings} />
      </Suspense>
    </Box>
  )
}
