import React, { ReactElement } from 'react'
import { Tooltip, Typography } from '@mui/material'

interface TruncatedToolTipProps {
  toolTipText?: string | null
  component: ReactElement
}

export const WrapperTooltip: React.FC<TruncatedToolTipProps> = ({ toolTipText, component }) => {
  const showToolTip = toolTipText !== undefined && toolTipText !== null && toolTipText.length > 0

  return showToolTip ? (
    <Tooltip title={<Typography variant="body2">{toolTipText}</Typography>} placement="bottom">
      {component}
    </Tooltip>
  ) : (
    component
  )
}
