import React, { FC, ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Icon } from '@mdi/react'
import { Box, Grid, IconButton, Paper, TableCell, TableRow } from '@mui/material'
import { mdiCashRefund } from '@mdi/js'
import { errorMapperReporting } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { useActiveOrganization } from 'src/organization/ActiveOrganizationProvider'
import {
  Configuration,
  LaundriesApi,
  LaundryGroupsApi,
  LaundryUsersApi,
  MachinesApi,
  OrganizationsApi,
  ReportWashMasterApi,
} from 'src/service/backend/api'
import {
  LaundryGroupReference,
  LaundryReference,
  MachineCategory,
  OrganizationReference,
  PaymentMethod,
  Permission,
  WMUsage,
} from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocale } from 'src/service/utils/DateFormatUtils'
import { formatAmountForLocale } from 'src/service/utils/NumberFormatUtils'
import { Data } from 'src/service/view-model/base/Data'
import { PAYMENT_METHODS, getPaymentMethodName } from 'src/service/view-model/base/payment/Payments'
import { getUserName } from 'src/service/view-model/laundry-user/LaundryUserUtils'
import {
  MACHINE_CATEGORIES,
  getMachineCategoryName,
  getMachineOptionLabel,
} from 'src/service/view-model/machine/Machines'
import { EMPTY_ORGANIZATION_REF } from 'src/service/view-model/organization/Organizations'
import { TooltipWrapper } from 'src/ui-shared/base/button/Buttons'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { TruncatedTooltip } from 'src/ui-shared/base/tooltip/TruncatedTooltip'
import { DataTable } from 'src/ui-shared/table/DataTable'
import {
  DEFAULT_DATA,
  HeadCells,
  TableData,
  TableSettingsWithOrganization,
  tableSettingsSort,
  withOrganization,
} from 'src/ui-shared/table/Table.const'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { mapData, updateTableSettingsFromData } from 'src/ui-shared/table/Table.utils'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'
import { TableFilterAutocomplete } from 'src/ui-shared/table/TableFilterAutocomplete'
import { TableFilterAutocompleteAsync } from 'src/ui-shared/table/TableFilterAutocompleteAsync'
import { TableFilterAutocompleteOrganization } from 'src/ui-shared/table/TableFilterAutocompleteOrganization'
import { useTableSettingsUrlSync } from 'src/ui-shared/table/TableSettings.hooks'
import { TransactionRefundModal } from 'src/ui/page/wm/usage/list/TransactionRefundModal'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser, useUserRegionLocale } from 'src/user/UserContext'

const getHeadCells = (canRefund: boolean): HeadCells[] => {
  const result: HeadCells[] = [
    {
      id: 'organization.name',
      label: 'organization',
    },
    {
      id: 'laundryGroup.name',
      label: 'laundryGroup',
    },
    {
      id: 'laundry.name',
      label: 'laundry',
    },
    {
      id: 'laundryUser.firstName',
      label: 'laundryUser',
    },
    {
      id: 'startTime',
      label: 'startDate',
    },
    {
      id: 'machine.category',
      label: 'member.machinetype',
    },
    {
      id: 'machine.name',
      label: 'machineName',
    },
    {
      id: 'amount',
      label: 'amount',
    },
    {
      id: 'payment.paymentMethod',
      label: 'paymentType',
    },
  ]

  if (canRefund) {
    const refundHeadCell: HeadCells = {
      id: 'refund',
      label: 'transactionRefund',
      noSort: true,
    }
    result.push(refundHeadCell)
  }
  return result
}

export interface UsageTableSettings extends TableSettingsWithOrganization {
  orderBy: string
  startTimeFrom?: string
  startTimeTo?: string
  laundryGroupId?: string
  laundryId?: string
  machineId?: string
  laundryUserId?: string
  paymentMethod?: PaymentMethod
  machineCategory?: MachineCategory
}

interface Props {
  tableSettings: UsageTableSettings
  defaultTableSettings: UsageTableSettings
  setTableSettings: React.Dispatch<React.SetStateAction<UsageTableSettings>>
}

export const UsageTable: FC<Props> = ({ tableSettings, setTableSettings, defaultTableSettings }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()

  const location = useLocation()
  const translate = useTranslate()

  const activeOrganization = useActiveOrganization()
  const user = useUser()
  const regionLocale = useUserRegionLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const reportsApi = new ReportWashMasterApi(httpConfiguration)
  const organizationsApi = new OrganizationsApi(httpConfiguration)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)
  const laundriesApi = new LaundriesApi(httpConfiguration)
  const laundryUsersApi = new LaundryUsersApi(httpConfiguration)
  const machinesApi = new MachinesApi(httpConfiguration)

  // state
  const [data, setData] = useState<TableData<WMUsage>>(DEFAULT_DATA)
  const [reloadDataFlag, setReloadDataFlag] = useState<boolean>(false)
  const [organizations, setOrganizations] = useState<OrganizationReference[]>([])
  const [laundryGroups, setLaundryGroups] = useState<LaundryGroupReference[]>([])
  const [laundries, setLaundries] = useState<LaundryReference[]>([])
  const [selectedRefundItem, setSelectedRefundItem] = useState<WMUsage | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const initialRender = useRef(true)

  const canRefund = hasPermission(user, Permission.TRANSACTION_REFUND)
  const headCells = getHeadCells(canRefund)

  // reactivity

  // load data for filters
  useEffect(() => {
    tableSettings = withOrganization(defaultTableSettings, activeOrganization)

    organizationsApi.organizationsRefGet().then((data) => {
      data.unshift(EMPTY_ORGANIZATION_REF)
      setOrganizations(data)
    })

    laundryGroupsApi.laundrygroupsRefGet().then((data) => {
      setLaundryGroups(data)
    })

    laundriesApi.laundriesRefGet().then((data) => {
      setLaundries(data)
    })
  }, [])

  // load data
  useEffect(() => {
    let active = true

    setErrorMessage(null)

    if (tableSettings.startTimeFrom && tableSettings.startTimeTo) {
      const dateStartTimeFrom = new Date(tableSettings.startTimeFrom)
      const dateStartTimeTo = new Date(tableSettings.startTimeTo)

      setLoading(true)
      setData(DEFAULT_DATA)

      reportsApi
        .reportWmUsagesGet(
          dateStartTimeFrom!,
          dateStartTimeTo!,
          tableSettings.size,
          tableSettings.page,
          tableSettingsSort(tableSettings),
          tableSettings.organizationId,
          tableSettings.laundryGroupId,
          tableSettings.laundryId,
          tableSettings.machineId,
          tableSettings.laundryUserId,
          undefined,
          tableSettings.paymentMethod,
          undefined,
          tableSettings.machineCategory,
        )
        .then((data) => {
          if (active) {
            updateTableSettingsFromData(data as Data<any>, tableSettings)

            if (initialRender.current) {
              initialRender.current = false
            } else {
              setTableSettings(tableSettings)
            }

            setData(mapData(data as Data<any>))
            setLoading(false)
          }
        })
        .catch((err) => {
          const errorMessage = errorMapperReporting(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
          setData(DEFAULT_DATA)
          setLoading(false)
        })
    }

    return () => {
      active = false
    }
  }, [tableSettings, reloadDataFlag])

  // handle events
  const searchLaundryUsers = async (search: string) => {
    const laundryUsers = await laundryUsersApi.laundryusersRefGet(undefined, search)
    const mappedLaundryUsers = laundryUsers.map((user) => {
      return { ...user, name: getUserName(user) }
    })
    return mappedLaundryUsers
  }

  const searchMachines = async (search: string) => {
    return machinesApi.machinesRefGet(undefined, search)
  }

  const showRefund = (usage: WMUsage) => {
    setSelectedRefundItem(usage)
  }

  const setShowRefundModal = (_open: boolean) => {
    setSelectedRefundItem(null)
  }

  const reloadDataOnSubmitRefund = () => {
    setReloadDataFlag((prevState: boolean) => !prevState)
  }

  // JSX
  const nonEmptyRows = data.data.map((item, index) => {
    return (
      <TableRow className={tableClasses.tableRow} style={{ cursor: 'auto' }} key={index}>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={item.organization.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={item.laundryGroup.name} />
        </TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={item.laundry.name} />
        </TableCell>
        <TableCell>{getUserName(item.laundryUser)}</TableCell>
        <TableCell>{formatDateTimeForLocale(item.startTime, regionLocale)}</TableCell>
        <TableCell>{getMachineCategoryName(item.machine.category, translate)}</TableCell>
        <TableCell>
          <TruncatedTooltip maxChars={25} value={item.machine.name} />
        </TableCell>
        <TableCell>{formatAmountForLocale(item.amount, regionLocale, item.currency)}</TableCell>
        <TableCell>{getPaymentMethodName(item.paymentMethod, translate)}</TableCell>
        {canRefund && (
          <TableCell style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <TooltipWrapper showTooltip={!item.refundable} title={translate('usageNotRefundable')}>
              <IconButton color="primary" onClick={showRefund.bind(this, item)} disabled={!item.refundable}>
                <Icon path={mdiCashRefund} size={1} />
              </IconButton>
            </TooltipWrapper>
          </TableCell>
        )}
      </TableRow>
    )
  })

  // ---- generic code, no modification pass this point ----

  // generic reactivity

  // update state from url / apply state to url
  useTableSettingsUrlSync(location, setTableSettings, tableSettings, defaultTableSettings)

  const getFilter = (headCellId: string) => {
    let filter
    if (headCellId === 'organization.name') {
      filter = (
        <TableFilterAutocompleteOrganization
          options={organizations}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'laundryGroup.name') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundryGroup')}
          label={translate('laundryGroups')}
          filter={'laundryGroupId'}
          labelFieldName="name"
          valueFieldName="id"
          options={laundryGroups}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'laundry.name') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByLaundry')}
          label={translate('laundries')}
          filter={'laundryId'}
          labelFieldName="name"
          valueFieldName="id"
          options={laundries}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'laundryUser.firstName') {
      filter = (
        <TableFilterAutocompleteAsync
          title={translate('filterByLaundryUser')}
          label={translate('laundryUsers')}
          filter={'laundryUserId'}
          labelFieldName="name"
          valueFieldName="id"
          loadOptions={searchLaundryUsers}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          loadAll={false}
        />
      )
    } else if (headCellId === 'machine.category') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByMachineType')}
          label={translate('member.machinetype')}
          filter={'machineCategory'}
          labelFieldName="name"
          valueFieldName="id"
          options={MACHINE_CATEGORIES.map((machine) => {
            return { ...machine, name: translate(machine.name) }
          })}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'machine.name') {
      filter = (
        <TableFilterAutocompleteAsync
          title={translate('filterByMachine')}
          label={translate('machines')}
          filter={'machineId'}
          labelFieldName="name"
          valueFieldName="id"
          getOptionLabel={getMachineOptionLabel}
          loadOptions={searchMachines}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    } else if (headCellId === 'payment.paymentMethod') {
      filter = (
        <TableFilterAutocomplete
          title={translate('filterByPaymentMethod')}
          label={translate('paymentType')}
          filter={'paymentMethod'}
          labelFieldName="name"
          valueFieldName="id"
          options={PAYMENT_METHODS.map((method) => {
            return { ...method, name: translate(method.name) }
          })}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
        />
      )
    }
    return filter
  }

  return (
    <>
      <Box mt={3} mb={3}>
        <Grid container spacing={2}>
          <TableDatePickerForm
            key={`${tableSettings.startTimeFrom}${tableSettings.startTimeTo}`}
            firstDatePropName="startTimeFrom"
            secondDatePropName="startTimeTo"
            setTableSettings={setTableSettings}
            tableSettings={tableSettings}
          />
        </Grid>
      </Box>

      {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

      <Paper elevation={0}>
        <DataTable
          headCells={headCells}
          data={data}
          nonEmptyRows={nonEmptyRows}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          getFilter={getFilter}
          loading={loading}
          translate={translate}
        />
      </Paper>

      {selectedRefundItem ? (
        <ModalDialog
          open={true}
          onClose={() => {
            setSelectedRefundItem(null)
          }}
          title={translate('transactionRefund')}
          maxWidth="xs"
        >
          <TransactionRefundModal
            setOpenModal={setShowRefundModal}
            usage={selectedRefundItem}
            handleReloadDataOnSubmit={reloadDataOnSubmitRefund}
          />
        </ModalDialog>
      ) : null}
    </>
  )
}
