/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface PrepaidBankTransferMailingConfirmRequest
 */
export interface PrepaidBankTransferMailingConfirmRequest {
    /**
     * Amount to be printed on the payment slips
     * @type {number}
     * @memberof PrepaidBankTransferMailingConfirmRequest
     */
    amount: number;
    /**
     * 
     * @type {Currency}
     * @memberof PrepaidBankTransferMailingConfirmRequest
     */
    currency: Currency;
}

/**
 * Check if a given object implements the PrepaidBankTransferMailingConfirmRequest interface.
 */
export function instanceOfPrepaidBankTransferMailingConfirmRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;

    return isInstance;
}

export function PrepaidBankTransferMailingConfirmRequestFromJSON(json: any): PrepaidBankTransferMailingConfirmRequest {
    return PrepaidBankTransferMailingConfirmRequestFromJSONTyped(json, false);
}

export function PrepaidBankTransferMailingConfirmRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrepaidBankTransferMailingConfirmRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'currency': CurrencyFromJSON(json['currency']),
    };
}

export function PrepaidBankTransferMailingConfirmRequestToJSON(value?: PrepaidBankTransferMailingConfirmRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'currency': CurrencyToJSON(value.currency),
    };
}

