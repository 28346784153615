import React, { PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Configuration, OrganizationReference, OrganizationsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { storeActiveOrganization } from 'src/service/local-storage/LocalStorageService'
import { queryStringUtils } from 'src/service/utils/QueryStringUtils'
import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'

interface ActiveOrganizationContextValue {
  activeOrganization: OrganizationReference | undefined
  setActiveOrganizationId: (organizationId?: string) => void
  organizations?: OrganizationReference[]
}

const defaultActiveOrganizationContextValue: ActiveOrganizationContextValue = {
  activeOrganization: undefined,
  setActiveOrganizationId: (_: string | undefined) => {},
  organizations: undefined,
}

const activeOrganizationContext = React.createContext<ActiveOrganizationContextValue>(
  defaultActiveOrganizationContextValue,
)

export const useActiveOrganization = (): OrganizationReference | undefined | null => {
  const { activeOrganization } = useContext(activeOrganizationContext)
  return activeOrganization
}

// use on components wrapped with HOC WithMandatoryActiveOrganization
export const useActiveOrganizationMandatory = (): OrganizationReference => {
  const { activeOrganization } = useContext(activeOrganizationContext)

  if (!activeOrganization) {
    throw Error('Organization must be selected')
  }
  return activeOrganization
}

const ActiveOrganizationContextProvider = ({ children }: PropsWithChildren): ReactElement => {
  const navigate = useNavigate()

  const location = useLocation()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const organizationsApi = new OrganizationsApi(httpConfiguration)

  // state
  const [organizations, setOrganizations] = useState<OrganizationReference[] | undefined>(undefined)
  const [activeOrganization, setActiveOrganization] = useState<OrganizationReference | undefined>(undefined)

  const findAndSetActiveOrganization = (organizationsArray: OrganizationReference[], organizationId: string) => {
    const foundOrganization = findReferenceObject(organizationsArray, organizationId)
    if (foundOrganization) {
      setActiveOrganization(foundOrganization)
    } else {
      console.warn('Organization with id ' + organizationId + ' not found')
    }
  }

  const updateActiveOrganizationFromUrl = (organizationsArray?: OrganizationReference[]) => {
    const queryParams = queryStringUtils.parse(location.search)

    const organizationIdFromUrl = queryParams.organizationId?.toString()
    // console.log('organizationIdFromUrl', organizationIdFromUrl)

    // if there is on organizationId in url, ignore the update
    if (!organizationIdFromUrl) {
      // console.log('No active org in url, ignoring')
      return
    }

    if (organizationIdFromUrl !== activeOrganization?.id) {
      // try to find the organization id from URL in the array of organizations
      if (organizationsArray) {
        findAndSetActiveOrganization(organizationsArray, organizationIdFromUrl)
      } else {
        // organizations not loaded yet, ignore
      }
    } else {
      // organization in url is  is equal to the one in the  context, ignore
    }
  }

  // load all organizations on init
  useEffect(() => {
    organizationsApi
      .organizationsRefGet()
      .then((data) => {
        setOrganizations(data)
        updateActiveOrganizationFromUrl(data)
      })
      .catch((err) => {
        console.error('Error loading all org: ' + err, err)
        setOrganizations([])
      })
  }, [])

  const setActiveOrganizationIdInternal = useCallback(
    (newActiveOrganizationId?: string) => {
      // console.log('newActiveOrganizationId', newActiveOrganizationId)

      // remember active org selection in local storage
      storeActiveOrganization(newActiveOrganizationId)

      // set organizationId in query parameters, the context will be updated later from the URL change

      // keep old query params unchanged in url
      const search = window.location.search
      const queryParams = queryStringUtils.parse(search)

      const newQueryParams: Record<string, any> = { ...queryParams, organizationId: newActiveOrganizationId }

      if (!newActiveOrganizationId) {
        // console.log('Setting active org to undefined')
        setActiveOrganization(undefined)
        // put reset organization parameter 'reset' in query, used in useDataSettingsUrlSync
        newQueryParams.reset = true
      } else {
        // remove reset param if existing
        delete newQueryParams.reset
      }

      const path = window.location.pathname

      const newQueryStringText = queryStringUtils.stringifyUrl(path, newQueryParams)
      // console.log('queryStringText after active org', queryStringText)

      navigate(newQueryStringText, { replace: true })
    },
    [navigate],
  )

  // listen to query parameter organizationId change
  useEffect(() => {
    // console.log('context location.search changed', location.pathname + location.search)
    updateActiveOrganizationFromUrl(organizations)
  }, [location.search])

  const contextValue: ActiveOrganizationContextValue = useMemo(
    () => ({
      activeOrganization: activeOrganization,
      setActiveOrganizationId: setActiveOrganizationIdInternal,
      organizations: organizations,
    }),
    [activeOrganization, setActiveOrganizationIdInternal, organizations],
  )

  return <activeOrganizationContext.Provider value={contextValue}>{children}</activeOrganizationContext.Provider>
}

export { activeOrganizationContext as ActiveOrganizationContext, ActiveOrganizationContextProvider }
