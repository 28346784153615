import { NavigateFunction } from 'react-router'
import { Location } from 'history'
import { ParsedQuery } from 'query-string'
import { diffStateObjects } from 'src/service/utils/ObjectUtils'
import { queryStringUtils } from 'src/service/utils/QueryStringUtils'

/**
 * Parent Interface for parameters that are used for filtering and are stored in the URL as query params.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DataSettings {}

/**
 * Parses the URL query parameters and constructs new data settings
 * if at least one parameter is different, otherwise returns undefined (url in sync with params).
 * @param location the current location (url)
 * @param dataSettings existing data settings
 * @param queryParamsCorrectionFunction optional function to correct type of query parameters
 * @returns new data settings or undefined if all params are equal
 */
export function getDataSettingsFromUrl(
  location: Location,
  dataSettings: DataSettings,
  queryParamsCorrectionFunction?: (queryParams: Record<string, any>) => Record<string, any>,
): DataSettings | undefined {
  // console.debug('updateDataSettingsFromUrl')
  // console.debug('current dataSettings', dataSettings)

  const queryParams = parseQuery(location.search)
  // console.debug('queryParams before', queryParams)
  const correctedParams = queryParamsCorrectionFunction ? queryParamsCorrectionFunction(queryParams) : queryParams

  const newDataSettings: DataSettings = {
    ...dataSettings,
    ...correctedParams,
  }

  const oldJson = JSON.stringify(dataSettings)
  const newJson = JSON.stringify(newDataSettings)
  // console.debug('old', oldJson)
  // console.debug('new', newJson)
  const changed = newJson !== oldJson
  // console.debug('changed', changed)

  if (changed) {
    // console.debug('newDataSettings', newDataSettings)
    return newDataSettings
  }

  return undefined
}

export function updateUrlFromDataSettings(
  location: Location,
  navigate: NavigateFunction,
  dataSettings: DataSettings,
  defaultDataSettings?: DataSettings,
): void {
  // console.log('updateUrlFromDataSettings')
  // console.log('defaultDataSettings', defaultDataSettings)
  // console.log('dataSettings', dataSettings)

  const diffedDataSettings = defaultDataSettings ? diffStateObjects(defaultDataSettings, dataSettings) : dataSettings

  // console.log('diffedDataSettings', diffedDataSettings)

  const path = window.location.pathname

  const newPathAndQueryParams = queryStringUtils.stringifyUrl(path, diffedDataSettings)
  // console.log('path after', newPathAndQueryParams)

  navigate(newPathAndQueryParams, { replace: true })
}

function parseQuery(queryStringText: string): ParsedQuery<string | number | boolean | undefined> {
  const queryObject = queryStringUtils.parse(queryStringText)
  return queryObject
}
