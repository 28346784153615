import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useNavigate, useParams } from 'react-router'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { AppId } from 'src/app/AppId'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, LaundryGroupsApi, OrganizationsApi } from 'src/service/backend/api'
import { AppId as ApiAppId, LaundryGroup, OrganizationReference } from 'src/service/backend/api/models'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getMainLocales } from 'src/service/view-model/base/localization/Locales'
import {
  LaundryGroupViewModel,
  createLaundryGroupViewModel,
  mapViewModelToLaundryGroup,
} from 'src/service/view-model/laundry-group/LaundryGroupViewModel'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { AsyncAutoCompleteValidate } from 'src/ui-shared/base/form/control/AsyncAutoCompleteValidate'
import { AutoCompleteField } from 'src/ui-shared/base/form/control/AutoCompleteField'
import { CheckboxField } from 'src/ui-shared/base/form/control/CheckboxField'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import {
  areAllEmailsValid,
  composeValidators,
  isValidEmail,
  required,
  validIban,
  validIbanChecksum,
  validPhoneNumber,
} from 'src/ui-shared/base/form/validation/Validators'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { usePrompt } from 'src/ui-shared/base/hooks/usePrompt'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { AddressSectionEdit } from 'src/ui-shared/form/address-section/AddressSectionEdit'
import { LocaleAutoCompleteValidate } from 'src/ui-shared/form/control/LocaleAutoCompleteValidate'
import { TimezoneAutoCompleteValidate } from 'src/ui-shared/form/control/TimezoneAutoCompleteValidate'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryGroupTab } from 'src/ui/page/common/laundry-group/details/LaundryGroupTab'
import { mapInternalAppIdToApi } from 'src/user/AppIdHelper'

export const LaundryGroupCreatePage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()

  const translate = useTranslate()
  const navigate = useNavigate()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupsApi = new LaundryGroupsApi(httpConfiguration)
  const organizationsApi = new OrganizationsApi(httpConfiguration)

  // url params
  const { laundryGroupId } = useParams()
  const editMode = !!laundryGroupId

  // state
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [viewModel, setViewModel] = useState<LaundryGroupViewModel>(createLaundryGroupViewModel())
  const [emails, setEmails] = useState<string[]>([])
  const [emailsTouched, setEmailsTouched] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  const apiAppId: ApiAppId | undefined = mapInternalAppIdToApi(appId)
  const apiAppsId: ApiAppId[] | undefined = apiAppId ? [apiAppId] : undefined

  useHotKeysForm()

  usePrompt(translate('notification.form.unsaved.changes'), dirty)

  // load data
  useEffect(() => {
    if (editMode) {
      laundryGroupsApi
        .laundrygroupsLaundryGroupIdGet(laundryGroupId!, apiAppsId)
        .then((laundryGroup) => {
          const laundryGroupViewModel = createLaundryGroupViewModel(laundryGroup)
          setEmails(laundryGroupViewModel.emails)
          setViewModel(laundryGroupViewModel)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          setErrorMessage(errorMessage)
        })
    }
  }, [editMode, laundryGroupId])

  const updateOrInsert = (laundryGroup: LaundryGroup) => {
    if (editMode) {
      return laundryGroupsApi.laundrygroupsLaundryGroupIdPut(laundryGroupId!, apiAppsId, laundryGroup)
    } else {
      return laundryGroupsApi.laundrygroupsPost(apiAppId, laundryGroup)
    }
  }

  const organizationGroupSearch = (searchText: string): Promise<OrganizationReference[]> => {
    return organizationsApi.organizationsRefGet(undefined, searchText)
  }

  // handle events
  const onSubmitSuccess = (createdLaundryGroupId: string) => {
    setDirty(false)
    setLoading(false)

    // navigate to second step for selecting package plan (only on WM)
    if (!editMode && appId === AppId.WASH_MASTER) {
      navigateToUpgradePlanStep(createdLaundryGroupId)
    } else {
      navigateBack()
    }
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  const navigateBack = () => {
    if (laundryGroupId) {
      navigate(`/${appId}/laundry-groups/${laundryGroupId}/view/${LaundryGroupTab.OVERVIEW}`)
    } else {
      navigate(`/${appId}/laundry-groups`)
    }
  }

  const navigateToUpgradePlanStep = (createdLaundryGroupId: string) => {
    navigate(`/${AppId.WASH_MASTER}/service-package-plan/${createdLaundryGroupId}/step/upgrade`, {
      state: { browserHistoryBack: true } as NavigateState,
    })
  }

  const handleOnCancel = () => {
    navigateBack()
  }

  const submitForm = async (viewModel: LaundryGroupViewModel) => {
    setLoading(true)
    setDirty(false)
    viewModel.emails = emails
    const laundryGroup = mapViewModelToLaundryGroup(viewModel)

    return updateOrInsert(laundryGroup)
      .then((data) => {
        onSubmitSuccess(data.id)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  const handleEmailsChange = (event: React.SyntheticEvent, value: string[]): void => {
    setEmailsTouched(true)

    if (!areAllEmailsValid(value)) {
      // ignore
      return
    }
    setEmails(value)

    if (!editMode && value.length === 0) {
      setEmailsTouched(false)
    }
  }

  const handleEmailsBlur = (event?: React.FocusEvent<any>): void => {
    if (event && event.target && event.target.value) {
      const text: string | undefined = event.target.value.toString().trim()
      if (text && isValidEmail(text)) {
        const foundItem = emails.find((item) => item === text)
        if (!foundItem) {
          emails.push(text)
          setEmailsTouched(true)
        }
      }
    }
  }

  // JSX
  const wlNumberField = editMode && (
    <Grid item {...ITEM_BREAKPOINTS} lg={6}>
      <TextField label={translate('wlNumber')} type="text" name="wlNumber" fullWidth disabled={true} />
    </Grid>
  )

  return (
    <ScreenLayout
      title={laundryGroupId ? viewModel.name : translate('createLaundryGroup')}
      onBack={navigateBack}
      actions={<></>}
      actionsWidth={50}
    >
      <LoadingIndicator loading={loading} />
      <Paper elevation={0}>
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <Box>
            <Form<LaundryGroupViewModel>
              initialValues={viewModel}
              onSubmit={submitForm}
              render={({ handleSubmit, submitting, pristine, values, form }) => {
                return (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                        <TextField
                          label={translate('laundryGroupName')}
                          type="text"
                          name="name"
                          fullWidth
                          autoFocus={!editMode}
                          disabled={submitting}
                          validate={required()}
                        />
                      </Grid>
                      {wlNumberField}
                      <Grid item {...ITEM_BREAKPOINTS}>
                        {editMode ? (
                          <TextField
                            label={translate('organization')}
                            type="text"
                            name="organization.name"
                            fullWidth
                            disabled={true}
                          />
                        ) : (
                          <AsyncAutoCompleteValidate
                            disabled={submitting}
                            name="organization"
                            validate={required()}
                            label={translate('organization')}
                            delay={300}
                            labelFieldName="name"
                            loadOptionsFunction={organizationGroupSearch}
                          />
                        )}
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TextField
                          label={translate('phonenumber')}
                          type="text"
                          name="phoneNumber"
                          fullWidth
                          disabled={submitting}
                          validate={validPhoneNumber()}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <Autocomplete
                          onChange={handleEmailsChange}
                          onBlur={handleEmailsBlur}
                          multiple={true}
                          freeSolo={true}
                          disabled={submitting}
                          value={emails}
                          options={emails}
                          getOptionLabel={() => ''}
                          open={false}
                          clearOnBlur={true}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                              const label = option ? option : ''
                              return <Chip variant="outlined" label={label} {...getTagProps({ index })} key={label} />
                            })
                          }
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                name="emails"
                                label={translate('email')}
                                placeholder={translate('email')}
                              />
                            )
                          }}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <LocaleAutoCompleteValidate
                          options={getMainLocales()}
                          name="locale"
                          disabled={submitting}
                          validate={required()}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TimezoneAutoCompleteValidate name="timeZone" disabled={submitting} validate={required()} />
                      </Grid>
                    </Grid>
                    <Divider className={sharedClasses.Divider} />
                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      <Grid item sm={12}>
                        <Typography className={sharedClasses.SectionTitle}>{translate('siteAddress')}</Typography>
                      </Grid>
                      <AddressSectionEdit
                        addressType="site"
                        submitting={submitting}
                        editMode={editMode}
                        disableCountryEdit
                      />
                    </Grid>
                    <Divider className={sharedClasses.Divider} />
                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TextField
                          label={translate('iban')}
                          type="text"
                          name="iban"
                          fullWidth
                          disabled={submitting}
                          validate={composeValidators(validIban(), validIbanChecksum())}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TextField
                          label={translate('ibanHolderName')}
                          type="text"
                          name="ibanHolderName"
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <TextField
                          label={translate('laundryGroup.externalPayoutReference')}
                          type="text"
                          name="externalPayoutReference"
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                    </Grid>
                    <Divider className={sharedClasses.Divider} />
                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      <Grid item sm={12} alignItems={'end'}>
                        <Typography className={sharedClasses.SectionTitle}>{translate('addressBilling')}</Typography>
                        <CheckboxField
                          color="primary"
                          name="useSiteAddressForBilling"
                          label={translate('useSiteAddressForBilling')}
                          additonalOnChange={(value, previous) => {
                            if (value != previous) {
                              setTimeout(() => {
                                form.change('billingAddress', values.siteAddress)
                              }, 0)
                            }
                          }}
                        />
                      </Grid>
                      {!values.useSiteAddressForBilling ? (
                        <AddressSectionEdit
                          addressType="billing"
                          submitting={submitting}
                          editMode={editMode}
                          disableCountryEdit
                        />
                      ) : null}
                    </Grid>
                    <Divider className={sharedClasses.Divider} />
                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      {editMode && (
                        <Grid item {...ITEM_BREAKPOINTS}>
                          <TextField
                            label={translate('currency')}
                            type="text"
                            name="currency.name"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      )}
                      {appId === AppId.WASH_MASTER && (
                        <Grid item {...ITEM_BREAKPOINTS} lg={editMode ? 6 : 12}>
                          <AutoCompleteField
                            label={translate('washmaster.paymentMethod')}
                            name="enabledPaymentMethod"
                            disabled={submitting || editMode}
                            options={[
                              { label: translate('prepaid'), value: 'PREPAID' },
                              { label: translate('directPay'), value: 'DIRECTPAY' },
                            ]}
                            validate={required()}
                          />
                        </Grid>
                      )}
                      <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                        <TextField
                          label={translate('notes')}
                          type="text"
                          name="notes"
                          fullWidth
                          multiline
                          maxRows={4}
                          disabled={submitting}
                        />
                      </Grid>
                    </Grid>
                    <Divider className={sharedClasses.Divider} />
                    <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
                      <Grid item {...ITEM_BREAKPOINTS}>
                        <Typography className={sharedClasses.SectionTitle}>{translate('features')}</Typography>
                        {appId === AppId.WASH_MASTER ? (
                          <FormControl component="fieldset">
                            <FormGroup>
                              <CheckboxField
                                color="primary"
                                name="washmaster.publicLaundryGroup"
                                label={translate('publicLaundryGroup')}
                              />
                              <CheckboxField
                                color="primary"
                                name="washmaster.payPerUseEnabled"
                                label={translate('payPerUseEnabled')}
                              />
                            </FormGroup>
                          </FormControl>
                        ) : null}
                        {appId === AppId.SERVICE_MASTER ? (
                          <FormControl component="fieldset">
                            <FormGroup>
                              <CheckboxField
                                color="primary"
                                name="servicemaster.usageReportsEnabled"
                                label={translate('usageReportsEnabled')}
                              />
                              <CheckboxField
                                color="primary"
                                name="servicemaster.utilizationReportsEnabled"
                                label={translate('utilizationReportsEnabled')}
                              />
                              <CheckboxField
                                color="primary"
                                name="servicemaster.hygieneMonitoringEnabled"
                                label={translate('hygieneMonitoringEnabled')}
                              />
                              <CheckboxField
                                color="primary"
                                name="servicemaster.directCareEnabled"
                                label={translate('directCareEnabled')}
                              />
                              <CheckboxField
                                color="primary"
                                name="servicemaster.customProgramsEnabled"
                                label={translate('customProgramsEnabled')}
                              />
                              <CheckboxField
                                color="primary"
                                name="washmaster.payPerUseEnabled"
                                label={translate('payPerUseEnabled')}
                              />
                            </FormGroup>
                          </FormControl>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Divider className={sharedClasses.Divider} />
                    <Box mt={3} display="flex" justifyContent="flex-end">
                      <Button
                        id="cancelButton"
                        variant="text"
                        color="primary"
                        size="large"
                        className={sharedClasses.ButtonMargin}
                        onClick={handleOnCancel}
                      >
                        {translate('button.cancel')}
                      </Button>

                      <Button
                        id="submitButton"
                        disabled={(submitting || pristine) && !emailsTouched}
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                      >
                        {translate(editMode ? 'button.save' : 'button.next')}
                      </Button>
                    </Box>
                    <FormSpy
                      subscription={{ dirty: true }}
                      onChange={(props) => {
                        setTimeout(() => setDirty(props.dirty), 0)
                      }}
                    />
                  </form>
                )
              }}
            />
          </Box>
        )}
      </Paper>
    </ScreenLayout>
  )
}
