/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AppId,
  LaundryGroupSubscription,
  LaundryGroupSubscriptionUpdate,
  PageableLaundryGroupsSubscription,
} from '../models';
import {
    AppIdFromJSON,
    AppIdToJSON,
    LaundryGroupSubscriptionFromJSON,
    LaundryGroupSubscriptionToJSON,
    LaundryGroupSubscriptionUpdateFromJSON,
    LaundryGroupSubscriptionUpdateToJSON,
    PageableLaundryGroupsSubscriptionFromJSON,
    PageableLaundryGroupsSubscriptionToJSON,
} from '../models';

export interface SubscriptionsLaundrygroupsGetRequest {
    size?: number;
    page?: number;
    sort?: string;
    appId?: Array<AppId>;
    organizationId?: string;
}

export interface SubscriptionsLaundrygroupsLaundryGroupIdGetRequest {
    laundryGroupId: string;
    appId?: Array<AppId>;
}

export interface SubscriptionsLaundrygroupsLaundryGroupIdPutRequest {
    laundryGroupId: string;
    appId?: Array<AppId>;
    laundryGroupSubscriptionUpdate?: LaundryGroupSubscriptionUpdate;
}

/**
 * 
 */
export class SubscriptionsLaundryGroupsApi extends runtime.BaseAPI {

    /**
     * List laundry groups
     */
    async subscriptionsLaundrygroupsGetRaw(requestParameters: SubscriptionsLaundrygroupsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageableLaundryGroupsSubscription>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/subscriptions/laundrygroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableLaundryGroupsSubscriptionFromJSON(jsonValue));
    }

    /**
     * List laundry groups
     */
    async subscriptionsLaundrygroupsGet(size?: number, page?: number, sort?: string, appId?: Array<AppId>, organizationId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageableLaundryGroupsSubscription> {
        const response = await this.subscriptionsLaundrygroupsGetRaw({ size: size, page: page, sort: sort, appId: appId, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     * List service package plan information
     */
    async subscriptionsLaundrygroupsLaundryGroupIdGetRaw(requestParameters: SubscriptionsLaundrygroupsLaundryGroupIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupSubscription>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling subscriptionsLaundrygroupsLaundryGroupIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/subscriptions/laundrygroups/{laundryGroupId}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupSubscriptionFromJSON(jsonValue));
    }

    /**
     * List service package plan information
     */
    async subscriptionsLaundrygroupsLaundryGroupIdGet(laundryGroupId: string, appId?: Array<AppId>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupSubscription> {
        const response = await this.subscriptionsLaundrygroupsLaundryGroupIdGetRaw({ laundryGroupId: laundryGroupId, appId: appId }, initOverrides);
        return await response.value();
    }

    /**
     * Update the service package plan of the laundry group
     */
    async subscriptionsLaundrygroupsLaundryGroupIdPutRaw(requestParameters: SubscriptionsLaundrygroupsLaundryGroupIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaundryGroupSubscription>> {
        if (requestParameters.laundryGroupId === null || requestParameters.laundryGroupId === undefined) {
            throw new runtime.RequiredError('laundryGroupId','Required parameter requestParameters.laundryGroupId was null or undefined when calling subscriptionsLaundrygroupsLaundryGroupIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId) {
            queryParameters['appId'] = requestParameters.appId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/subscriptions/laundrygroups/{laundryGroupId}`.replace(`{${"laundryGroupId"}}`, encodeURIComponent(String(requestParameters.laundryGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LaundryGroupSubscriptionUpdateToJSON(requestParameters.laundryGroupSubscriptionUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaundryGroupSubscriptionFromJSON(jsonValue));
    }

    /**
     * Update the service package plan of the laundry group
     */
    async subscriptionsLaundrygroupsLaundryGroupIdPut(laundryGroupId: string, appId?: Array<AppId>, laundryGroupSubscriptionUpdate?: LaundryGroupSubscriptionUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaundryGroupSubscription> {
        const response = await this.subscriptionsLaundrygroupsLaundryGroupIdPutRaw({ laundryGroupId: laundryGroupId, appId: appId, laundryGroupSubscriptionUpdate: laundryGroupSubscriptionUpdate }, initOverrides);
        return await response.value();
    }

}
