import { MachineCategory } from 'src/service/backend/api'
import { getWeekBefore, removeTimeFromDate } from 'src/service/utils/MomentUtils'
import { ChartSettings, DEFAULT_CHART_SETTINGS } from 'src/service/view-model/base/chart/ChartViewModel'

export interface UtilizationDailyUsageChartSettings extends ChartSettings {
  organizationId?: string
  laundryGroupId?: string
  laundryId?: string
  machineId?: string
  machineCategory?: MachineCategory
}

export const DEFAULT_DAILY_USAGE_CHART_SETTINGS: UtilizationDailyUsageChartSettings = {
  ...DEFAULT_CHART_SETTINGS,
  startDateFrom: removeTimeFromDate(getWeekBefore(new Date())),
}
