import React, { FC, ReactElement, Suspense, useContext, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { Configuration, Machine, MachineInsightType, ReportMachineInsightsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  ChartSettingsWithoutScale,
  DEFAULT_CHART_TIME_RANGE_SETTINGS,
} from 'src/service/view-model/base/chart/ChartViewModel'
import { ChartTimelineResult } from 'src/ui-shared/chart/ChartSeries.const'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { TableDatePickerForm } from 'src/ui-shared/table/TableDatePickerForm'

interface Props {
  machine: Machine
}

const AsyncChartSeriesNumber = React.lazy(() =>
  import('src/ui-shared/chart/ChartSeriesNumber').then(({ ChartSeriesNumber }) => ({ default: ChartSeriesNumber })),
)

export const MachineInsightsTab: FC<Props> = ({ machine }): ReactElement => {
  const translate = useTranslate()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const machineInsightsApi = new ReportMachineInsightsApi(httpConfiguration)

  const [machineInsightsChartSettings, setMachineInsightsChartSettings] = useState<ChartSettingsWithoutScale>(
    DEFAULT_CHART_TIME_RANGE_SETTINGS,
  )

  const loadChartData = (machineInsightType: MachineInsightType): Promise<ChartTimelineResult> => {
    const dateStartTimeFrom = new Date(machineInsightsChartSettings.startDateFrom)
    const dateStartTimeTo = new Date(machineInsightsChartSettings.startDateTo)

    return machineInsightsApi
      .reportSmMachineInsightsChartGet(
        dateStartTimeFrom,
        dateStartTimeTo,
        undefined,
        undefined,
        undefined,
        machine.id,
        undefined,
        [machineInsightType],
      )
      .then((data) => {
        const result: ChartTimelineResult = {
          timeseriesData: data,
          fromDate: dateStartTimeFrom,
          toDate: dateStartTimeTo,
        }
        return result
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        return Promise.reject(new Error(errorMessage))
      })
  }

  return (
    <>
      <Box mt={3} mb={3}>
        <Grid container spacing={2}>
          <TableDatePickerForm
            key={`${machineInsightsChartSettings.startDateFrom}${machineInsightsChartSettings.startDateTo}`}
            firstDatePropName="startDateFrom"
            secondDatePropName="startDateTo"
            setTableSettings={setMachineInsightsChartSettings}
            tableSettings={machineInsightsChartSettings}
            minDate={DEFAULT_CHART_TIME_RANGE_SETTINGS.startDateFrom}
          />
        </Grid>

        <Grid container spacing={6} pt={3}>
          <Suspense fallback={<div style={{ textAlign: 'center' }}>{translate('autocompleteLoading')}</div>}>
            <Grid item {...ITEM_BREAKPOINTS} lg={6} md={12} display={'flex'} justifyContent={'flex-start'}>
              <Box width={'98%'}>
                <Typography variant="h4" align="center">
                  {translate('rssi')}
                </Typography>
                <AsyncChartSeriesNumber
                  loadChart={() => loadChartData(MachineInsightType.RSSI)}
                  chartSettings={machineInsightsChartSettings}
                  yMax={-65}
                  yMin={-86}
                />
              </Box>
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS} lg={6} md={12} display={'flex'} justifyContent={'flex-end'}>
              <Box width={'98%'}>
                <Typography variant="h4" align="center">
                  {translate('operatingHours')}
                </Typography>
                <AsyncChartSeriesNumber
                  loadChart={() => loadChartData(MachineInsightType.OPERATING_HOURS)}
                  chartSettings={machineInsightsChartSettings}
                />
              </Box>
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS} lg={6} md={12} justifyContent={'flex-start'}>
              <Box width={'98%'}>
                <Typography variant="h4" align="center">
                  {translate('programCounter')}
                </Typography>
                <AsyncChartSeriesNumber
                  loadChart={() => loadChartData(MachineInsightType.PROGRAM_COUNTER)}
                  chartSettings={machineInsightsChartSettings}
                />
              </Box>
            </Grid>
          </Suspense>
        </Grid>
      </Box>
    </>
  )
}
