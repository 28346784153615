import React, { FC, ReactElement } from 'react'
import { MachineType, ProgramGroup } from 'src/service/backend/api'
import { ProgramGroupList } from 'src/ui/page/es/program-group/list/ProgramGroupList'

interface Prop {
  fixedOrganizationId?: string
  selectProgramGroup: (programGroup: ProgramGroup | null) => void
  activeTabOnly?: MachineType
}

export const ProgramGroupListModal: FC<Prop> = ({
  selectProgramGroup,
  fixedOrganizationId,
  activeTabOnly,
}): ReactElement => {
  return (
    <ProgramGroupList
      view={'modal'}
      fixedOrganizationId={fixedOrganizationId}
      selectProgramGroup={selectProgramGroup}
      activeTabOnly={activeTabOnly}
    />
  )
}
