import React, { ReactElement } from 'react'
import { useTranslate } from 'src/i18n/useMessageSource'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { ProgramGroupList } from 'src/ui/page/es/program-group/list/ProgramGroupList'

export const ProgramGroupListPage = (): ReactElement => {
  const translate = useTranslate()
  return (
    <ScreenLayout title={translate('programGroups')} actionsWidth={170}>
      <ProgramGroupList view={'page'} />
    </ScreenLayout>
  )
}
