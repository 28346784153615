/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AppId {
    SM_WEB = 'SM_WEB',
    WM_MOBILE = 'WM_MOBILE',
    WM_WEB = 'WM_WEB',
    ES_WEB = 'ES_WEB'
}


export function AppIdFromJSON(json: any): AppId {
    return AppIdFromJSONTyped(json, false);
}

export function AppIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppId {
    return json as AppId;
}

export function AppIdToJSON(value?: AppId | null): any {
    return value as any;
}

