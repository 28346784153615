import React, { ReactElement } from 'react'
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  Validate,
  ValidationRule,
} from 'react-hook-form'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  getActiveValueLabel,
  getDefaultValueLabel,
  getHintOrError,
} from 'src/ui-shared/base/form/control-hook-form/HookForm'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

interface BitFieldWrapperProps<T extends FieldValues, FieldPropertyName extends Path<T>> {
  name: FieldPropertyName
  control: Control<T, any>
  label: string
  activeValue?: number
  defaultValue?: number
  flags?: Array<string>
  validate?:
    | Validate<PathValue<T, FieldPropertyName>, T>
    | Record<string, Validate<PathValue<T, FieldPropertyName>, T>>
    | undefined
  required?: ValidationRule<boolean>
  disabled?: boolean
  hintText?: string
  onChangeEventListener?: (field: ControllerRenderProps<T, FieldPropertyName>, fieldState: ControllerFieldState) => void
}

export const BitFieldHookForm = <T extends FieldValues, FieldPropertyName extends Path<T>>({
  control,
  name,
  label,
  activeValue,
  defaultValue,
  flags,
  validate,
  required,
  disabled,
  hintText,
  onChangeEventListener,
}: BitFieldWrapperProps<T, FieldPropertyName>): ReactElement => {
  const translate = useTranslate()

  const handleOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: ControllerRenderProps<T, FieldPropertyName>,
    fieldState: ControllerFieldState,
  ) => {
    handleOnChangeInternal(event.target.value, field, fieldState)
  }

  const handleOnChangeInternal = (
    newValue: any,
    field: ControllerRenderProps<T, FieldPropertyName>,
    fieldState: ControllerFieldState,
  ) => {
    field.onChange(newValue)

    if (onChangeEventListener) {
      onChangeEventListener(field, fieldState)
    }
  }
  const flagsCount = flags?.length || 0
  // const bitCount = field.bitFlagsField?.data !== undefined ? field.bitFlagsField.data.toString(2).length : 32
  const hint = 'Bit flags: ' + flags?.join(';')

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: validate, required: required }}
      render={({ field, fieldState }) => {
        const hint = getHintOrError(fieldState, translate, hintText)
        const errorState = !!fieldState.error

        const value = field.value

        let labelToUse = label
        if (activeValue !== undefined && activeValue !== value) {
          labelToUse = getActiveValueLabel(translate, label, activeValue)
        }

        if (defaultValue !== undefined && defaultValue !== value) {
          labelToUse += getDefaultValueLabel(translate, label, defaultValue)
        }

        const valueBinary = parseInt(value).toString(2)

        return (
          <>
            <TextFieldDefault
              label={labelToUse}
              {...field}
              value={value}
              onChange={(event) => handleOnChange(event, field, fieldState)}
              disabled={disabled}
              variant="filled"
              multiline={false}
              rows={1}
              helperText={hint}
              error={errorState}
            />
            <Typography variant="caption" display="block" gutterBottom>
              {valueBinary}
            </Typography>

            {flags?.map((flag, index) => {
              // number of bits
              const maskAll = new Array(flagsCount)
              for (let i = 0; i < flagsCount; i++) {
                maskAll[i] = 0
              }

              const bitPos = flagsCount - index - 1
              maskAll[bitPos] = 1

              const maskS = maskAll.join('')
              const mask = parseInt(maskS, 2)

              const fieldChecked = value !== undefined && Boolean(value & mask)

              return (
                <>
                  <FormControlLabel
                    label={flag.toString()}
                    disabled={disabled}
                    control={
                      <Checkbox
                        key={`${name}.${index}.checkbox`}
                        disabled={disabled}
                        checked={fieldChecked}
                        onClick={() => {
                          if (value !== undefined) {
                            const prevValue = parseInt(field.value)
                            const prevChecked = Boolean(prevValue & mask)
                            const newValue = prevChecked ? value & ~mask : value | mask
                            handleOnChangeInternal(newValue, field, fieldState)
                          }
                        }}
                      />
                    }
                  />
                </>
              )
            })}
          </>
        )
      }}
    />
  )
}
