import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Paper } from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, Member, MembersApi, Permission } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getLocaleName } from 'src/service/view-model/base/localization/Locales'
import { getMemberRolesName } from 'src/service/view-model/member/MemberViewModel'
import { isOrganizationTypeServicePartner } from 'src/service/view-model/organization/Organizations'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { DetailsTextField } from 'src/ui-shared/base/form/control/DetailsTextField'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { ConfirmationModalDialog } from 'src/ui-shared/base/model-dialog/ConfirmationModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ITEM_BREAKPOINTS } from 'src/ui-shared/constants/GridItem.const'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { DeleteIcon } from 'src/ui-shared/icons/Icons'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

interface Props {
  isServicer?: boolean
}

export const MemberDetailsPage: FC<Props> = ({ isServicer }): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()

  const navigate = useNavigate()
  const { memberId } = useRequiredParams(['memberId'])
  const appId = useAppId()
  const location = useLocation()
  const showSnackbar = useShowSnackbar()
  const user = useUser()

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack

  const httpConfiguration: Configuration = useContext(HttpContext)
  const membersApi = new MembersApi(httpConfiguration)

  // state
  const [member, setMember] = useState<Member | null>(null)
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const screenRoute = isServicer ? 'servicers' : 'members'
  const canEdit = hasPermission(user, Permission.MEMBER_WRITE)
  const memberFullName = member?.backendUser.firstName + ' ' + member?.backendUser.lastName

  useHotKeysForm()

  // load data
  useEffect(() => {
    membersApi
      .membersMemberIdGet(memberId)
      .then((member) => {
        setMember(member)
        setErrorMessage(null)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }, [memberId])

  // handle events
  const onRemoveHandler = (): void => {
    if (member) {
      membersApi
        .membersMemberIdDelete(memberId)
        .then(() => {
          navigate(`/${appId}/${screenRoute}`)
        })
        .catch((err) => {
          const errorMessage = errorMapper(err, translate)
          console.error(errorMessage, err)
          showSnackbar(errorMessage, 'error')
          setRemoveModal(false)
        })
    }
    setRemoveModal(false)
  }

  const navigateToEditPage = () => {
    navigate(`/${appId}/${screenRoute}/${memberId}/edit`)
  }

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${appId}/${screenRoute}`)
  }

  // JSX
  const editButton = (
    <Button id="editButton" variant="contained" color="primary" size="large" onClick={navigateToEditPage}>
      {translate('button.edit')}
    </Button>
  )
  const editAction = canEdit ? editButton : <></>

  const deleteButton = (
    <Button
      startIcon={<DeleteIcon />}
      variant="outlined"
      color="primary"
      size="large"
      className={sharedClasses.ButtonMargin}
      onClick={() => setRemoveModal(true)}
    >
      {translate('button.delete')}
    </Button>
  )

  const deleteAction = canEdit ? deleteButton : <></>

  return (
    <ScreenLayout title={member?.backendUser.firstName ? memberFullName : ''} onBack={navigateBack} actionsWidth={50}>
      <Paper elevation={0}>
        <Divider />
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <Box pt={2}>
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={member?.backendUser.firstName} label={translate('user.firstName')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={member?.backendUser.lastName} label={translate('user.lastName')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={member?.backendUser.email} label={translate('user.email')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={getLocaleName(member?.backendUser.locale)} label={translate('language')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                <Divider className={sharedClasses.Divider} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField
                  value={member?.organization.name}
                  label={translate('organization')}
                  link={
                    isOrganizationTypeServicePartner(member?.organization.type)
                      ? `/${appId}/service-partners/${member?.organization.id}/view`
                      : `/${appId}/organizations/${member?.organization.id}/view`
                  }
                />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <DetailsTextField value={member ? getMemberRolesName(member) : ''} label={translate('roles')} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
                <Divider className={sharedClasses.Divider} />
              </Grid>
              <Grid item {...ITEM_BREAKPOINTS}>
                <FormControl disabled component="fieldset">
                  <FormLabel component="legend">{translate('status')}</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox color="primary" checked={member?.backendUser.blocked} disabled name="enabled" />
                      }
                      label={translate('blocked')}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item {...ITEM_BREAKPOINTS} md={12} lg={12}>
              <Divider className={sharedClasses.Divider} />
            </Grid>
            <Box pt={2} display="flex" justifyContent="space-between">
              <Box>{deleteAction}</Box>
              <Box display="flex" justifyContent="flex-end">
                {editAction}
              </Box>
            </Box>
          </Box>
        )}
      </Paper>

      <ConfirmationModalDialog
        titleKey="deleteMember"
        confirmationKey="button.delete"
        open={removeModal}
        onConfirm={onRemoveHandler}
        onCancel={() => setRemoveModal(false)}
      >
        {translate('deleteConfirm', memberFullName)}
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
