import React, { ReactElement } from 'react'
import { Icon } from '@mdi/react'
import { Chip } from '@mui/material'
import { mdiCheckCircleOutline, mdiProgressClose, mdiProgressHelper } from '@mdi/js'
import { TranslateFunction } from 'src/i18n/useMessageSource'
import { HMResult, HMState } from 'src/service/backend/api'
import { getHygieneResultName } from 'src/service/view-model/hygiene/HygineViewModel'

interface Props {
  hygieneState?: HMState
  result?: HMResult
}

export const HygieneStateIcon = ({ hygieneState, result }: Props): ReactElement | null => {
  let icon: ReactElement | null = null
  switch (hygieneState) {
    case HMState.ANALYZING:
      icon = <Icon path={mdiProgressHelper} size={1} />
      break
    case HMState.FINISHED:
      if (result === HMResult.SUCCESSFUL) {
        icon = <Icon path={mdiCheckCircleOutline} size={1} />
      } else {
        icon = <Icon path={mdiProgressClose} size={1} />
      }
      break
  }
  return icon
}

interface ChipProps {
  result?: HMResult
  translate: TranslateFunction
}

export const HygieneResultStatusChip = ({ result, translate }: ChipProps): ReactElement | null => {
  let chip: ReactElement | null = null
  let chipColor: 'primary' | 'default' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined = 'primary'
  switch (result) {
    case HMResult.SUCCESSFUL:
      chipColor = 'primary'
      break
    case HMResult.FAILED:
      chipColor = 'default'
      break
  }
  const label = getHygieneResultName(result, translate)

  if (result) {
    chip = <Chip color={chipColor} label={label} />
  }

  return chip
}
