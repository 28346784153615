/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaundryGroupReference } from './LaundryGroupReference';
import {
    LaundryGroupReferenceFromJSON,
    LaundryGroupReferenceFromJSONTyped,
    LaundryGroupReferenceToJSON,
} from './LaundryGroupReference';
import type { LaundryReference } from './LaundryReference';
import {
    LaundryReferenceFromJSON,
    LaundryReferenceFromJSONTyped,
    LaundryReferenceToJSON,
} from './LaundryReference';
import type { MachineReference } from './MachineReference';
import {
    MachineReferenceFromJSON,
    MachineReferenceFromJSONTyped,
    MachineReferenceToJSON,
} from './MachineReference';
import type { OrganizationReference } from './OrganizationReference';
import {
    OrganizationReferenceFromJSON,
    OrganizationReferenceFromJSONTyped,
    OrganizationReferenceToJSON,
} from './OrganizationReference';
import type { ProgramInformation } from './ProgramInformation';
import {
    ProgramInformationFromJSON,
    ProgramInformationFromJSONTyped,
    ProgramInformationToJSON,
} from './ProgramInformation';

/**
 * 
 * @export
 * @interface SMUsage
 */
export interface SMUsage {
    /**
     * 
     * @type {OrganizationReference}
     * @memberof SMUsage
     */
    organization: OrganizationReference;
    /**
     * 
     * @type {LaundryGroupReference}
     * @memberof SMUsage
     */
    laundryGroup: LaundryGroupReference;
    /**
     * 
     * @type {LaundryReference}
     * @memberof SMUsage
     */
    laundry: LaundryReference;
    /**
     * 
     * @type {MachineReference}
     * @memberof SMUsage
     */
    machine: MachineReference;
    /**
     * 
     * @type {Date}
     * @memberof SMUsage
     */
    startTime: Date;
    /**
     * Program duration in seconds
     * @type {number}
     * @memberof SMUsage
     */
    programDuration?: number;
    /**
     * 
     * @type {ProgramInformation}
     * @memberof SMUsage
     */
    programInfo?: ProgramInformation | null;
}

/**
 * Check if a given object implements the SMUsage interface.
 */
export function instanceOfSMUsage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "laundryGroup" in value;
    isInstance = isInstance && "laundry" in value;
    isInstance = isInstance && "machine" in value;
    isInstance = isInstance && "startTime" in value;

    return isInstance;
}

export function SMUsageFromJSON(json: any): SMUsage {
    return SMUsageFromJSONTyped(json, false);
}

export function SMUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SMUsage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization': OrganizationReferenceFromJSON(json['organization']),
        'laundryGroup': LaundryGroupReferenceFromJSON(json['laundryGroup']),
        'laundry': LaundryReferenceFromJSON(json['laundry']),
        'machine': MachineReferenceFromJSON(json['machine']),
        'startTime': (new Date(json['startTime'])),
        'programDuration': !exists(json, 'programDuration') ? undefined : json['programDuration'],
        'programInfo': !exists(json, 'programInfo') ? undefined : ProgramInformationFromJSON(json['programInfo']),
    };
}

export function SMUsageToJSON(value?: SMUsage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization': OrganizationReferenceToJSON(value.organization),
        'laundryGroup': LaundryGroupReferenceToJSON(value.laundryGroup),
        'laundry': LaundryReferenceToJSON(value.laundry),
        'machine': MachineReferenceToJSON(value.machine),
        'startTime': (value.startTime.toISOString()),
        'programDuration': value.programDuration,
        'programInfo': ProgramInformationToJSON(value.programInfo),
    };
}

