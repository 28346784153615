import React, { useEffect, useState } from 'react'
import { FilledTextFieldProps, Grid, InputAdornment } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { StyledIconButton, useTextFieldStyles } from 'src/ui-shared/base/form/control/TextField.style'
import { TextFieldDefault } from 'src/ui-shared/base/form/control/TextFieldDefault'

type PropsToExclude = 'onChange' | 'value' | 'variant'

type TextFieldPropsClean = Omit<FilledTextFieldProps, PropsToExclude>

export interface AmountTextFieldProps extends TextFieldPropsClean {
  value: string | undefined
  onChange: (value: string) => void
}

export const AmountTextField: React.FC<AmountTextFieldProps> = ({ value, onChange, ...restProps }) => {
  const { classes: textFieldClasses } = useTextFieldStyles()

  const [amountState, setAmountState] = useState<string>(value ? value : '')

  useEffect(() => {
    setAmountState(value ? value : '')
  }, [value])

  const mapAmountStringToNumber = (amountState: string) => {
    return parseFloat(amountState)
  }

  const mapNumberToAmountString = (amountNumber: number) => {
    return amountNumber.toFixed(2).toString()
  }

  const incrementAmount = () => {
    let amountAsNumber = mapAmountStringToNumber(amountState)
    amountAsNumber += 0.1
    const amountAsString = mapNumberToAmountString(amountAsNumber)
    setAmountState(amountAsString)
    onChange(amountAsString)
  }

  const decrementAmount = () => {
    let amountAsNumber = mapAmountStringToNumber(amountState)
    amountAsNumber -= 0.1
    const amountAsString = mapNumberToAmountString(amountAsNumber)
    setAmountState(amountAsString)
    onChange(amountAsString)
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAmountState(event.target.value)
    onChange(event.target.value)
  }

  const regex = /^(-)?\d+(\.[0-9]([0-9])?)?$/
  const isTextInvalid = !regex.test(amountState)

  return (
    <TextFieldDefault
      value={amountState}
      className={textFieldClasses.TextFieldSmall}
      onChange={handleOnChange}
      {...restProps}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Grid container direction="column">
              <Grid item>
                <StyledIconButton disabled={isTextInvalid} onClick={incrementAmount}>
                  <ExpandLessIcon />
                </StyledIconButton>
              </Grid>
              <Grid item>
                <StyledIconButton disabled={isTextInvalid} onClick={decrementAmount}>
                  <ExpandMoreIcon />
                </StyledIconButton>
              </Grid>
            </Grid>
          </InputAdornment>
        ),
      }}
    />
  )
}
