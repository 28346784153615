import React, { Dispatch, FC, ReactElement, SetStateAction, useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { Box, Button, Grid } from '@mui/material'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  InvitationsApi,
  OrganizationReference,
  OrganizationsApi,
  Role,
  RolesApi,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import {
  MemberInvitationViewModel,
  createMemberInvitationViewModel,
  mapMemberInvitationViewModelToInvitation,
} from 'src/service/view-model/member/MemberViewModel'
import { getOrganizationTypesByUser } from 'src/service/view-model/organization/Organizations'
import { AsyncAutoCompleteValidate } from 'src/ui-shared/base/form/control/AsyncAutoCompleteValidate'
import { AutoCompleteField } from 'src/ui-shared/base/form/control/AutoCompleteField'
import { Option } from 'src/ui-shared/base/form/control/Index'
import { TextField } from 'src/ui-shared/base/form/control/TextFieldValidate'
import { composeValidators, required, validEmail } from 'src/ui-shared/base/form/validation/Validators'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { ModalDialog } from 'src/ui-shared/base/model-dialog/ModalDialog'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { DisabledWarningTooltip } from 'src/ui-shared/base/tooltip/DisabledWarningTooltip'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'

export interface Props {
  openInviteModal: boolean
  setOpenInviteModal: (value: boolean) => void
  setSubmittedInvite: Dispatch<SetStateAction<boolean>>
  isServicer?: boolean
}

export const MemberInviteModal: FC<Props> = ({
  openInviteModal,
  setOpenInviteModal,
  setSubmittedInvite,
  isServicer,
}): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const organizationsApi = new OrganizationsApi(httpConfiguration)
  const invitationsApi = new InvitationsApi(httpConfiguration)
  const rolesApi = new RolesApi(httpConfiguration)

  const [loading, setLoading] = useState<boolean>(false)
  const [memberInvitationViewModel] = useState<MemberInvitationViewModel>(createMemberInvitationViewModel())
  const [roles, setRoles] = useState<Role[]>([])

  const organizationTypeFilter = getOrganizationTypesByUser(isServicer)

  const getRoleOptions = (roles: Role[]) => {
    return roles.map((role) => getRoleOption(role))
  }

  const getRoleOption = (role: Role): Option => {
    const option: Option = {
      label: role.name!,
      value: role.roleKey,
    }
    return option
  }

  const getRolesByOrganization = (organizationId: string | undefined) => {
    setLoading(true)
    if (!organizationId) {
      setRoles([])
      setLoading(false)
      return
    }
    rolesApi
      .rolesAvailableGet(organizationId)
      .then((data) => {
        if (roles !== data) {
          setRoles(data)
        }
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setRoles([])
        setLoading(false)
      })
  }

  const organizationSearch = (searchText: string): Promise<OrganizationReference[]> => {
    return organizationsApi.organizationsRefGet(undefined, searchText, organizationTypeFilter)
  }

  const onSubmitSuccess = () => {
    setLoading(false)
    const successMessage = translate('notification.success.userInvited')
    showSnackbar(successMessage, 'success')
    setOpenInviteModal(false)
    setSubmittedInvite((prevState: boolean) => !prevState)
  }

  const onSubmitReject = (errorMessage: string) => {
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  const submitForm = (viewModel: MemberInvitationViewModel) => {
    setLoading(true)
    const invitation = mapMemberInvitationViewModelToInvitation(viewModel)

    invitationsApi
      .invitationsPost(invitation)
      .then(() => {
        onSubmitSuccess()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  return (
    <ModalDialog
      open={openInviteModal}
      onClose={() => setOpenInviteModal(false)}
      title={translate('inviteMember')}
      maxWidth="xs"
    >
      <Box px={3} pb={3}>
        <LoadingIndicator loading={loading} />

        <Form<MemberInvitationViewModel>
          initialValues={memberInvitationViewModel}
          onSubmit={submitForm}
          render={({ handleSubmit, submitting, pristine }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label={translate('email')}
                      fullWidth
                      autoFocus
                      validate={composeValidators(required(), validEmail())}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <>
                      <AsyncAutoCompleteValidate
                        disabled={submitting}
                        name="organizationRef"
                        validate={required()}
                        label={isServicer ? translate('servicePartner') : translate('organization')}
                        delay={300}
                        labelFieldName="name"
                        loadOptionsFunction={organizationSearch}
                      />
                      <OnChange name="organizationRef">
                        {(value: OrganizationReference | null, previous: OrganizationReference | null) => {
                          if (value !== previous) {
                            {
                              getRolesByOrganization(value?.id)
                            }
                          }
                        }}
                      </OnChange>
                    </>
                  </Grid>
                  <Grid item xs={12}>
                    <DisabledWarningTooltip
                      disabled={loading || roles.length !== 0}
                      message={translate(isServicer ? 'selectServicePartnerWarning' : 'selectOrganizationWarning')}
                    >
                      <AutoCompleteField
                        label={translate('user.role')}
                        name="role"
                        disabled={submitting || roles.length === 0}
                        options={getRoleOptions(roles)}
                        validate={required()}
                      />
                    </DisabledWarningTooltip>
                  </Grid>
                </Grid>
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    color="primary"
                    size="large"
                    className={sharedClasses.ButtonMargin}
                    onClick={() => setOpenInviteModal(false)}
                  >
                    {translate('button.cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={pristine || submitting || loading}
                  >
                    {translate('invite')}
                  </Button>
                </Box>
              </form>
            )
          }}
        />
      </Box>
    </ModalDialog>
  )
}
