import React from 'react'
import { Icon } from '@mdi/react'
import { HelpOutline, HomeRepairServiceOutlined, HomeWorkOutlined } from '@mui/icons-material'
import { mdiAccountTieOutline } from '@mdi/js'
import { ReactComponent as ServiceMasterIcon } from 'src/assets/logo-servicemaster-icon.svg'
import { ReactComponent as WasherIconSm } from 'src/assets/washer-sm.svg'
import { InsertChartOutlinedIcon } from 'src/ui-shared/icons/Icons'
import { SlideItem } from 'src/ui/layout/onboarding-slideshow/OnboardingSlideshow'

export const SLIDES_SM: SlideItem[] = [
  {
    backgroundImage: 'slide1-background.png',
    title: 'welcomeToServiceMaster',
    body: 'onboardingBodySlide1Sm',
    slideImage: <ServiceMasterIcon />,
  },
  {
    backgroundImage: 'slide2-background.png',
    title: 'laundryGroups',
    body: 'onboardingBodySlide2Sm',
    slideImage: <HomeWorkOutlined />,
  },
  {
    backgroundImage: 'slide3-background.png',
    title: 'laundriesAndMachines',
    body: 'onboardingBodySlide3Sm',
    slideImage: <WasherIconSm />,
  },
  {
    backgroundImage: 'slide4-background.png',
    title: 'serviceFeatures',
    body: 'onboardingBodySlide4Sm',
    slideImage: <HomeRepairServiceOutlined />,
  },
  {
    backgroundImage: 'slide5-background.png',
    title: 'members',
    body: 'onboardingBodySlide5Sm',
    slideImage: <Icon path={mdiAccountTieOutline} color={'primary'} />,
  },
  {
    backgroundImage: 'slide1-background.png',
    title: 'productivityTools',
    body: 'onboardingBodySlide6Sm',
    slideImage: <InsertChartOutlinedIcon />,
  },
  {
    backgroundImage: 'slide2-background.png',
    title: 'helpCenter',
    body: 'onboardingBodySlide7Sm',
    slideImage: <HelpOutline color={'primary'} />,
  },
]
