import { findReferenceObject } from 'src/service/view-model/base/Reference.utils'

export interface TimezoneObject {
  code: string
  name: string
  group: string
}

const group1 = ''
const group2 = 'Other'

export const TIMEZONES: TimezoneObject[] = [
  { code: 'Europe/Zurich', name: 'Zurich', group: group1 },
  { code: 'Europe/Berlin', name: 'Berlin', group: group1 },
  { code: 'Europe/Vienna', name: 'Vienna', group: group1 },
  { code: 'Europe/London', name: 'London', group: group1 },

  { code: 'Europe/Amsterdam', name: 'Europe / Amsterdam', group: group2 },
  { code: 'Europe/Andorra', name: 'Europe / Andorra', group: group2 },
  { code: 'Europe/Athens', name: 'Europe / Athens', group: group2 },
  { code: 'Europe/Belfast', name: 'Europe / Belfast', group: group2 },
  { code: 'Europe/Belgrade', name: 'Europe / Belgrade', group: group2 },
  { code: 'Europe/Bratislava', name: 'Europe / Bratislava', group: group2 },
  { code: 'Europe/Brussels', name: 'Europe / Brussels', group: group2 },
  { code: 'Europe/Bucharest', name: 'Europe / Bucharest', group: group2 },
  { code: 'Europe/Budapest', name: 'Europe / Budapest', group: group2 },
  { code: 'Europe/Chisinau', name: 'Europe / Chisinau', group: group2 },
  { code: 'Europe/Copenhagen', name: 'Europe / Copenhagen', group: group2 },
  { code: 'Europe/Dublin', name: 'Europe / Dublin', group: group2 },
  { code: 'Europe/Gibraltar', name: 'Europe / Gibraltar', group: group2 },
  { code: 'Europe/Guernsey', name: 'Europe / Guernsey', group: group2 },
  { code: 'Europe/Helsinki', name: 'Europe / Helsinki', group: group2 },
  { code: 'Europe/Isle_of_Man', name: 'Europe / Isle_of_Man', group: group2 },
  { code: 'Europe/Istanbul', name: 'Europe / Istanbul', group: group2 },
  { code: 'Europe/Jersey', name: 'Europe / Jersey', group: group2 },
  { code: 'Europe/Kaliningrad', name: 'Europe / Kaliningrad', group: group2 },
  { code: 'Europe/Kiev', name: 'Europe / Kiev', group: group2 },
  { code: 'Europe/Lisbon', name: 'Europe / Lisbon', group: group2 },
  { code: 'Europe/Ljubljana', name: 'Europe / Ljubljana', group: group2 },
  { code: 'Europe/Luxembourg', name: 'Europe / Luxembourg', group: group2 },
  { code: 'Europe/Madrid', name: 'Europe / Madrid', group: group2 },
  { code: 'Europe/Malta', name: 'Europe / Malta', group: group2 },
  { code: 'Europe/Mariehamn', name: 'Europe / Mariehamn', group: group2 },
  { code: 'Europe/Minsk', name: 'Europe / Minsk', group: group2 },
  { code: 'Europe/Monaco', name: 'Europe / Monaco', group: group2 },
  { code: 'Europe/Moscow', name: 'Europe / Moscow', group: group2 },
  { code: 'Europe/Nicosia', name: 'Europe / Nicosia', group: group2 },
  { code: 'Europe/Oslo', name: 'Europe / Oslo', group: group2 },
  { code: 'Europe/Paris', name: 'Europe / Paris', group: group2 },
  { code: 'Europe/Podgorica', name: 'Europe / Podgorica', group: group2 },
  { code: 'Europe/Prague', name: 'Europe / Prague', group: group2 },
  { code: 'Europe/Riga', name: 'Europe / Riga', group: group2 },
  { code: 'Europe/Rome', name: 'Europe / Rome', group: group2 },
  { code: 'Europe/Samara', name: 'Europe / Samara', group: group2 },
  { code: 'Europe/San_Marino', name: 'Europe / San_Marino', group: group2 },
  { code: 'Europe/Sarajevo', name: 'Europe / Sarajevo', group: group2 },
  { code: 'Europe/Simferopol', name: 'Europe / Simferopol', group: group2 },
  { code: 'Europe/Skopje', name: 'Europe / Skopje', group: group2 },
  { code: 'Europe/Sofia', name: 'Europe / Sofia', group: group2 },
  { code: 'Europe/Stockholm', name: 'Europe / Stockholm', group: group2 },
  { code: 'Europe/Tallinn', name: 'Europe / Tallinn', group: group2 },
  { code: 'Europe/Tirane', name: 'Europe / Tirane', group: group2 },
  { code: 'Europe/Tiraspol', name: 'Europe / Tiraspol', group: group2 },
  { code: 'Europe/Uzhgorod', name: 'Europe / Uzhgorod', group: group2 },
  { code: 'Europe/Vaduz', name: 'Europe / Vaduz', group: group2 },
  { code: 'Europe/Vatican', name: 'Europe / Vatican', group: group2 },
  { code: 'Europe/Vilnius', name: 'Europe / Vilnius', group: group2 },
  { code: 'Europe/Volgograd', name: 'Europe / Volgograd', group: group2 },
  { code: 'Europe/Warsaw', name: 'Europe / Warsaw', group: group2 },
  { code: 'Europe/Zagreb', name: 'Europe / Zagreb', group: group2 },
  { code: 'Europe/Zaporozhye', name: 'Europe / Zaporozhye', group: group2 },
  { code: 'America/Adak', name: 'America / Adak', group: group2 },
  { code: 'America/Anchorage', name: 'America / Anchorage', group: group2 },
  { code: 'America/Anguilla', name: 'America / Anguilla', group: group2 },
  { code: 'America/Antigua', name: 'America / Antigua', group: group2 },
  { code: 'America/Araguaina', name: 'America / Araguaina', group: group2 },
  { code: 'America/Argentina / Buenos_Aires', name: 'America / Argentina / Buenos_Aires', group: group2 },
  { code: 'America/Argentina / Catamarca', name: 'America / Argentina / Catamarca', group: group2 },
  { code: 'America/Argentina / ComodRivadavia', name: 'America / Argentina / ComodRivadavia', group: group2 },
  { code: 'America/Argentina / Cordoba', name: 'America / Argentina / Cordoba', group: group2 },
  { code: 'America/Argentina / Jujuy', name: 'America / Argentina / Jujuy', group: group2 },
  { code: 'America/Argentina / La_Rioja', name: 'America / Argentina / La_Rioja', group: group2 },
  { code: 'America/Argentina / Mendoza', name: 'America / Argentina / Mendoza', group: group2 },
  { code: 'America/Argentina / Rio_Gallegos', name: 'America / Argentina / Rio_Gallegos', group: group2 },
  { code: 'America/Argentina / Salta', name: 'America / Argentina / Salta', group: group2 },
  { code: 'America/Argentina / San_Juan', name: 'America / Argentina / San_Juan', group: group2 },
  { code: 'America/Argentina / San_Luis', name: 'America / Argentina / San_Luis', group: group2 },
  { code: 'America/Argentina / Tucuman', name: 'America / Argentina / Tucuman', group: group2 },
  { code: 'America/Argentina / Ushuaia', name: 'America / Argentina / Ushuaia', group: group2 },
  { code: 'America/Aruba', name: 'America / Aruba', group: group2 },
  { code: 'America/Asuncion', name: 'America / Asuncion', group: group2 },
  { code: 'America/Atikokan', name: 'America / Atikokan', group: group2 },
  { code: 'America/Atka', name: 'America / Atka', group: group2 },
  { code: 'America/Bahia', name: 'America / Bahia', group: group2 },
  { code: 'America/Barbados', name: 'America / Barbados', group: group2 },
  { code: 'America/Belem', name: 'America / Belem', group: group2 },
  { code: 'America/Belize', name: 'America / Belize', group: group2 },
  { code: 'America/Blanc - Sablon', name: 'America / Blanc - Sablon', group: group2 },
  { code: 'America/Boa_Vista', name: 'America / Boa_Vista', group: group2 },
  { code: 'America/Bogota', name: 'America / Bogota', group: group2 },
  { code: 'America/Boise', name: 'America / Boise', group: group2 },
  { code: 'America/Buenos_Aires', name: 'America / Buenos_Aires', group: group2 },
  { code: 'America/Cambridge_Bay', name: 'America / Cambridge_Bay', group: group2 },
  { code: 'America/Campo_Grande', name: 'America / Campo_Grande', group: group2 },
  { code: 'America/Cancun', name: 'America / Cancun', group: group2 },
  { code: 'America/Caracas', name: 'America / Caracas', group: group2 },
  { code: 'America/Catamarca', name: 'America / Catamarca', group: group2 },
  { code: 'America/Cayenne', name: 'America / Cayenne', group: group2 },
  { code: 'America/Cayman', name: 'America / Cayman', group: group2 },
  { code: 'America/Chicago', name: 'America / Chicago', group: group2 },
  { code: 'America/Chihuahua', name: 'America / Chihuahua', group: group2 },
  { code: 'America/Coral_Harbour', name: 'America / Coral_Harbour', group: group2 },
  { code: 'America/Cordoba', name: 'America / Cordoba', group: group2 },
  { code: 'America/Costa_Rica', name: 'America / Costa_Rica', group: group2 },
  { code: 'America/Cuiaba', name: 'America / Cuiaba', group: group2 },
  { code: 'America/Curacao', name: 'America / Curacao', group: group2 },
  { code: 'America/Danmarkshavn', name: 'America / Danmarkshavn', group: group2 },
  { code: 'America/Dawson', name: 'America / Dawson', group: group2 },
  { code: 'America/Dawson_Creek', name: 'America / Dawson_Creek', group: group2 },
  { code: 'America/Denver', name: 'America / Denver', group: group2 },
  { code: 'America/Detroit', name: 'America / Detroit', group: group2 },
  { code: 'America/Dominica', name: 'America / Dominica', group: group2 },
  { code: 'America/Edmonton', name: 'America / Edmonton', group: group2 },
  { code: 'America/Eirunepe', name: 'America / Eirunepe', group: group2 },
  { code: 'America/El_Salvador', name: 'America / El_Salvador', group: group2 },
  { code: 'America/Ensenada', name: 'America / Ensenada', group: group2 },
  { code: 'America/Fortaleza', name: 'America / Fortaleza', group: group2 },
  { code: 'America/Fort_Wayne', name: 'America / Fort_Wayne', group: group2 },
  { code: 'America/Glace_Bay', name: 'America / Glace_Bay', group: group2 },
  { code: 'America/Godthab', name: 'America / Godthab', group: group2 },
  { code: 'America/Goose_Bay', name: 'America / Goose_Bay', group: group2 },
  { code: 'America/Grand_Turk', name: 'America / Grand_Turk', group: group2 },
  { code: 'America/Grenada', name: 'America / Grenada', group: group2 },
  { code: 'America/Guadeloupe', name: 'America / Guadeloupe', group: group2 },
  { code: 'America/Guatemala', name: 'America / Guatemala', group: group2 },
  { code: 'America/Guayaquil', name: 'America / Guayaquil', group: group2 },
  { code: 'America/Guyana', name: 'America / Guyana', group: group2 },
  { code: 'America/Halifax', name: 'America / Halifax', group: group2 },
  { code: 'America/Havana', name: 'America / Havana', group: group2 },
  { code: 'America/Hermosillo', name: 'America / Hermosillo', group: group2 },
  { code: 'America/Indiana / Indianapolis', name: 'America / Indiana / Indianapolis', group: group2 },
  { code: 'America/Indiana / Knox', name: 'America / Indiana / Knox', group: group2 },
  { code: 'America/Indiana / Marengo', name: 'America / Indiana / Marengo', group: group2 },
  { code: 'America/Indiana / Petersburg', name: 'America / Indiana / Petersburg', group: group2 },
  { code: 'America/Indiana / Tell_City', name: 'America / Indiana / Tell_City', group: group2 },
  { code: 'America/Indiana / Vevay', name: 'America / Indiana / Vevay', group: group2 },
  { code: 'America/Indiana / Vincennes', name: 'America / Indiana / Vincennes', group: group2 },
  { code: 'America/Indiana / Winamac', name: 'America / Indiana / Winamac', group: group2 },
  { code: 'America/Indianapolis', name: 'America / Indianapolis', group: group2 },
  { code: 'America/Inuvik', name: 'America / Inuvik', group: group2 },
  { code: 'America/Iqaluit', name: 'America / Iqaluit', group: group2 },
  { code: 'America/Jamaica', name: 'America / Jamaica', group: group2 },
  { code: 'America/Jujuy', name: 'America / Jujuy', group: group2 },
  { code: 'America/Juneau', name: 'America / Juneau', group: group2 },
  { code: 'America/Kentucky / Louisville', name: 'America / Kentucky / Louisville', group: group2 },
  { code: 'America/Kentucky / Monticello', name: 'America / Kentucky / Monticello', group: group2 },
  { code: 'America/Knox_IN', name: 'America / Knox_IN', group: group2 },
  { code: 'America/La_Paz', name: 'America / La_Paz', group: group2 },
  { code: 'America/Lima', name: 'America / Lima', group: group2 },
  { code: 'America/Los_Angeles', name: 'America / Los_Angeles', group: group2 },
  { code: 'America/Louisville', name: 'America / Louisville', group: group2 },
  { code: 'America/Maceio', name: 'America / Maceio', group: group2 },
  { code: 'America/Managua', name: 'America / Managua', group: group2 },
  { code: 'America/Manaus', name: 'America / Manaus', group: group2 },
  { code: 'America/Marigot', name: 'America / Marigot', group: group2 },
  { code: 'America/Martinique', name: 'America / Martinique', group: group2 },
  { code: 'America/Matamoros', name: 'America / Matamoros', group: group2 },
  { code: 'America/Mazatlan', name: 'America / Mazatlan', group: group2 },
  { code: 'America/Mendoza', name: 'America / Mendoza', group: group2 },
  { code: 'America/Menominee', name: 'America / Menominee', group: group2 },
  { code: 'America/Merida', name: 'America / Merida', group: group2 },
  { code: 'America/Mexico_City', name: 'America / Mexico_City', group: group2 },
  { code: 'America/Miquelon', name: 'America / Miquelon', group: group2 },
  { code: 'America/Moncton', name: 'America / Moncton', group: group2 },
  { code: 'America/Monterrey', name: 'America / Monterrey', group: group2 },
  { code: 'America/Montevideo', name: 'America / Montevideo', group: group2 },
  { code: 'America/Montreal', name: 'America / Montreal', group: group2 },
  { code: 'America/Montserrat', name: 'America / Montserrat', group: group2 },
  { code: 'America/Nassau', name: 'America / Nassau', group: group2 },
  { code: 'America/New_York', name: 'America / New_York', group: group2 },
  { code: 'America/Nipigon', name: 'America / Nipigon', group: group2 },
  { code: 'America/Nome', name: 'America / Nome', group: group2 },
  { code: 'America/Noronha', name: 'America / Noronha', group: group2 },
  { code: 'America/North_Dakota / Center', name: 'America / North_Dakota / Center', group: group2 },
  { code: 'America/North_Dakota / New_Salem', name: 'America / North_Dakota / New_Salem', group: group2 },
  { code: 'America/Ojinaga', name: 'America / Ojinaga', group: group2 },
  { code: 'America/Panama', name: 'America / Panama', group: group2 },
  { code: 'America/Pangnirtung', name: 'America / Pangnirtung', group: group2 },
  { code: 'America/Paramaribo', name: 'America / Paramaribo', group: group2 },
  { code: 'America/Phoenix', name: 'America / Phoenix', group: group2 },
  { code: 'America/Port - au - Prince', name: 'America / Port - au - Prince', group: group2 },
  { code: 'America/Porto_Acre', name: 'America / Porto_Acre', group: group2 },
  { code: 'America/Port_of_Spain', name: 'America / Port_of_Spain', group: group2 },
  { code: 'America/Porto_Velho', name: 'America / Porto_Velho', group: group2 },
  { code: 'America/Puerto_Rico', name: 'America / Puerto_Rico', group: group2 },
  { code: 'America/Rainy_River', name: 'America / Rainy_River', group: group2 },
  { code: 'America/Rankin_Inlet', name: 'America / Rankin_Inlet', group: group2 },
  { code: 'America/Recife', name: 'America / Recife', group: group2 },
  { code: 'America/Regina', name: 'America / Regina', group: group2 },
  { code: 'America/Resolute', name: 'America / Resolute', group: group2 },
  { code: 'America/Rio_Branco', name: 'America / Rio_Branco', group: group2 },
  { code: 'America/Rosario', name: 'America / Rosario', group: group2 },
  { code: 'America/Santa_Isabel', name: 'America / Santa_Isabel', group: group2 },
  { code: 'America/Santarem', name: 'America / Santarem', group: group2 },
  { code: 'America/Santiago', name: 'America / Santiago', group: group2 },
  { code: 'America/Santo_Domingo', name: 'America / Santo_Domingo', group: group2 },
  { code: 'America/Sao_Paulo', name: 'America / Sao_Paulo', group: group2 },
  { code: 'America/Scoresbysund', name: 'America / Scoresbysund', group: group2 },
  { code: 'America/Shiprock', name: 'America / Shiprock', group: group2 },
  { code: 'America/St_Barthelemy', name: 'America / St_Barthelemy', group: group2 },
  { code: 'America/St_Johns', name: 'America / St_Johns', group: group2 },
  { code: 'America/St_Kitts', name: 'America / St_Kitts', group: group2 },
  { code: 'America/St_Lucia', name: 'America / St_Lucia', group: group2 },
  { code: 'America/St_Thomas', name: 'America / St_Thomas', group: group2 },
  { code: 'America/St_Vincent', name: 'America / St_Vincent', group: group2 },
  { code: 'America/Swift_Current', name: 'America / Swift_Current', group: group2 },
  { code: 'America/Tegucigalpa', name: 'America / Tegucigalpa', group: group2 },
  { code: 'America/Thule', name: 'America / Thule', group: group2 },
  { code: 'America/Thunder_Bay', name: 'America / Thunder_Bay', group: group2 },
  { code: 'America/Tijuana', name: 'America / Tijuana', group: group2 },
  { code: 'America/Toronto', name: 'America / Toronto', group: group2 },
  { code: 'America/Tortola', name: 'America / Tortola', group: group2 },
  { code: 'America/Vancouver', name: 'America / Vancouver', group: group2 },
  { code: 'America/Virgin', name: 'America / Virgin', group: group2 },
  { code: 'America/Whitehorse', name: 'America / Whitehorse', group: group2 },
  { code: 'America/Winnipeg', name: 'America / Winnipeg', group: group2 },
  { code: 'America/Yakutat', name: 'America / Yakutat', group: group2 },
  { code: 'America/Yellowknife', name: 'America / Yellowknife', group: group2 },
  { code: 'Asia/Aden', name: 'Asia / Aden', group: group2 },
  { code: 'Asia/Almaty', name: 'Asia / Almaty', group: group2 },
  { code: 'Asia/Amman', name: 'Asia / Amman', group: group2 },
  { code: 'Asia/Anadyr', name: 'Asia / Anadyr', group: group2 },
  { code: 'Asia/Aqtau', name: 'Asia / Aqtau', group: group2 },
  { code: 'Asia/Aqtobe', name: 'Asia / Aqtobe', group: group2 },
  { code: 'Asia/Ashgabat', name: 'Asia / Ashgabat', group: group2 },
  { code: 'Asia/Ashkhabad', name: 'Asia / Ashkhabad', group: group2 },
  { code: 'Asia/Baghdad', name: 'Asia / Baghdad', group: group2 },
  { code: 'Asia/Bahrain', name: 'Asia / Bahrain', group: group2 },
  { code: 'Asia/Baku', name: 'Asia / Baku', group: group2 },
  { code: 'Asia/Bangkok', name: 'Asia / Bangkok', group: group2 },
  { code: 'Asia/Beirut', name: 'Asia / Beirut', group: group2 },
  { code: 'Asia/Bishkek', name: 'Asia / Bishkek', group: group2 },
  { code: 'Asia/Brunei', name: 'Asia / Brunei', group: group2 },
  { code: 'Asia/Calcutta', name: 'Asia / Calcutta', group: group2 },
  { code: 'Asia/Choibalsan', name: 'Asia / Choibalsan', group: group2 },
  { code: 'Asia/Chongqing', name: 'Asia / Chongqing', group: group2 },
  { code: 'Asia/Chungking', name: 'Asia / Chungking', group: group2 },
  { code: 'Asia/Colombo', name: 'Asia / Colombo', group: group2 },
  { code: 'Asia/Dacca', name: 'Asia / Dacca', group: group2 },
  { code: 'Asia/Damascus', name: 'Asia / Damascus', group: group2 },
  { code: 'Asia/Dhaka', name: 'Asia / Dhaka', group: group2 },
  { code: 'Asia/Dili', name: 'Asia / Dili', group: group2 },
  { code: 'Asia/Dubai', name: 'Asia / Dubai', group: group2 },
  { code: 'Asia/Dushanbe', name: 'Asia / Dushanbe', group: group2 },
  { code: 'Asia/Gaza', name: 'Asia / Gaza', group: group2 },
  { code: 'Asia/Harbin', name: 'Asia / Harbin', group: group2 },
  { code: 'Asia/Ho_Chi_Minh', name: 'Asia / Ho_Chi_Minh', group: group2 },
  { code: 'Asia/Hong_Kong', name: 'Asia / Hong_Kong', group: group2 },
  { code: 'Asia/Hovd', name: 'Asia / Hovd', group: group2 },
  { code: 'Asia/Irkutsk', name: 'Asia / Irkutsk', group: group2 },
  { code: 'Asia/Istanbul', name: 'Asia / Istanbul', group: group2 },
  { code: 'Asia/Jakarta', name: 'Asia / Jakarta', group: group2 },
  { code: 'Asia/Jayapura', name: 'Asia / Jayapura', group: group2 },
  { code: 'Asia/Jerusalem', name: 'Asia / Jerusalem', group: group2 },
  { code: 'Asia/Kabul', name: 'Asia / Kabul', group: group2 },
  { code: 'Asia/Kamchatka', name: 'Asia / Kamchatka', group: group2 },
  { code: 'Asia/Karachi', name: 'Asia / Karachi', group: group2 },
  { code: 'Asia/Kashgar', name: 'Asia / Kashgar', group: group2 },
  { code: 'Asia/Kathmandu', name: 'Asia / Kathmandu', group: group2 },
  { code: 'Asia/Katmandu', name: 'Asia / Katmandu', group: group2 },
  { code: 'Asia/Kolkata', name: 'Asia / Kolkata', group: group2 },
  { code: 'Asia/Krasnoyarsk', name: 'Asia / Krasnoyarsk', group: group2 },
  { code: 'Asia/Kuala_Lumpur', name: 'Asia / Kuala_Lumpur', group: group2 },
  { code: 'Asia/Kuching', name: 'Asia / Kuching', group: group2 },
  { code: 'Asia/Kuwait', name: 'Asia / Kuwait', group: group2 },
  { code: 'Asia/Macao', name: 'Asia / Macao', group: group2 },
  { code: 'Asia/Macau', name: 'Asia / Macau', group: group2 },
  { code: 'Asia/Magadan', name: 'Asia / Magadan', group: group2 },
  { code: 'Asia/Makassar', name: 'Asia / Makassar', group: group2 },
  { code: 'Asia/Manila', name: 'Asia / Manila', group: group2 },
  { code: 'Asia/Muscat', name: 'Asia / Muscat', group: group2 },
  { code: 'Asia/Nicosia', name: 'Asia / Nicosia', group: group2 },
  { code: 'Asia/Novokuznetsk', name: 'Asia / Novokuznetsk', group: group2 },
  { code: 'Asia/Novosibirsk', name: 'Asia / Novosibirsk', group: group2 },
  { code: 'Asia/Omsk', name: 'Asia / Omsk', group: group2 },
  { code: 'Asia/Oral', name: 'Asia / Oral', group: group2 },
  { code: 'Asia/Phnom_Penh', name: 'Asia / Phnom_Penh', group: group2 },
  { code: 'Asia/Pontianak', name: 'Asia / Pontianak', group: group2 },
  { code: 'Asia/Pyongyang', name: 'Asia / Pyongyang', group: group2 },
  { code: 'Asia/Qatar', name: 'Asia / Qatar', group: group2 },
  { code: 'Asia/Qyzylorda', name: 'Asia / Qyzylorda', group: group2 },
  { code: 'Asia/Rangoon', name: 'Asia / Rangoon', group: group2 },
  { code: 'Asia/Riyadh', name: 'Asia / Riyadh', group: group2 },
  { code: 'Asia/Saigon', name: 'Asia / Saigon', group: group2 },
  { code: 'Asia/Sakhalin', name: 'Asia / Sakhalin', group: group2 },
  { code: 'Asia/Samarkand', name: 'Asia / Samarkand', group: group2 },
  { code: 'Asia/Seoul', name: 'Asia / Seoul', group: group2 },
  { code: 'Asia/Shanghai', name: 'Asia / Shanghai', group: group2 },
  { code: 'Asia/Singapore', name: 'Asia / Singapore', group: group2 },
  { code: 'Asia/Taipei', name: 'Asia / Taipei', group: group2 },
  { code: 'Asia/Tashkent', name: 'Asia / Tashkent', group: group2 },
  { code: 'Asia/Tbilisi', name: 'Asia / Tbilisi', group: group2 },
  { code: 'Asia/Tehran', name: 'Asia / Tehran', group: group2 },
  { code: 'Asia/Tel_Aviv', name: 'Asia / Tel_Aviv', group: group2 },
  { code: 'Asia/Thimbu', name: 'Asia / Thimbu', group: group2 },
  { code: 'Asia/Thimphu', name: 'Asia / Thimphu', group: group2 },
  { code: 'Asia/Tokyo', name: 'Asia / Tokyo', group: group2 },
  { code: 'Asia/Ujung_Pandang', name: 'Asia / Ujung_Pandang', group: group2 },
  { code: 'Asia/Ulaanbaatar', name: 'Asia / Ulaanbaatar', group: group2 },
  { code: 'Asia/Ulan_Bator', name: 'Asia / Ulan_Bator', group: group2 },
  { code: 'Asia/Urumqi', name: 'Asia / Urumqi', group: group2 },
  { code: 'Asia/Vientiane', name: 'Asia / Vientiane', group: group2 },
  { code: 'Asia/Vladivostok', name: 'Asia / Vladivostok', group: group2 },
  { code: 'Asia/Yakutsk', name: 'Asia / Yakutsk', group: group2 },
  { code: 'Asia/Yekaterinburg', name: 'Asia / Yekaterinburg', group: group2 },
  { code: 'Asia/Yerevan', name: 'Asia / Yerevan', group: group2 },
  { code: 'Africa/Abidjan', name: 'Africa / Abidjan', group: group2 },
  { code: 'Africa/Accra', name: 'Africa / Accra', group: group2 },
  { code: 'Africa/Addis_Ababa', name: 'Africa / Addis_Ababa', group: group2 },
  { code: 'Africa/Algiers', name: 'Africa / Algiers', group: group2 },
  { code: 'Africa/Asmara', name: 'Africa / Asmara', group: group2 },
  { code: 'Africa/Asmera', name: 'Africa / Asmera', group: group2 },
  { code: 'Africa/Bamako', name: 'Africa / Bamako', group: group2 },
  { code: 'Africa/Bangui', name: 'Africa / Bangui', group: group2 },
  { code: 'Africa/Banjul', name: 'Africa / Banjul', group: group2 },
  { code: 'Africa/Bissau', name: 'Africa / Bissau', group: group2 },
  { code: 'Africa/Blantyre', name: 'Africa / Blantyre', group: group2 },
  { code: 'Africa/Brazzaville', name: 'Africa / Brazzaville', group: group2 },
  { code: 'Africa/Bujumbura', name: 'Africa / Bujumbura', group: group2 },
  { code: 'Africa/Cairo', name: 'Africa / Cairo', group: group2 },
  { code: 'Africa/Casablanca', name: 'Africa / Casablanca', group: group2 },
  { code: 'Africa/Ceuta', name: 'Africa / Ceuta', group: group2 },
  { code: 'Africa/Conakry', name: 'Africa / Conakry', group: group2 },
  { code: 'Africa/Dakar', name: 'Africa / Dakar', group: group2 },
  { code: 'Africa/Dar_es_Salaam', name: 'Africa / Dar_es_Salaam', group: group2 },
  { code: 'Africa/Djibouti', name: 'Africa / Djibouti', group: group2 },
  { code: 'Africa/Douala', name: 'Africa / Douala', group: group2 },
  { code: 'Africa/El_Aaiun', name: 'Africa / El_Aaiun', group: group2 },
  { code: 'Africa/Freetown', name: 'Africa / Freetown', group: group2 },
  { code: 'Africa/Gaborone', name: 'Africa / Gaborone', group: group2 },
  { code: 'Africa/Harare', name: 'Africa / Harare', group: group2 },
  { code: 'Africa/Johannesburg', name: 'Africa / Johannesburg', group: group2 },
  { code: 'Africa/Kampala', name: 'Africa / Kampala', group: group2 },
  { code: 'Africa/Khartoum', name: 'Africa / Khartoum', group: group2 },
  { code: 'Africa/Kigali', name: 'Africa / Kigali', group: group2 },
  { code: 'Africa/Kinshasa', name: 'Africa / Kinshasa', group: group2 },
  { code: 'Africa/Lagos', name: 'Africa / Lagos', group: group2 },
  { code: 'Africa/Libreville', name: 'Africa / Libreville', group: group2 },
  { code: 'Africa/Lome', name: 'Africa / Lome', group: group2 },
  { code: 'Africa/Luanda', name: 'Africa / Luanda', group: group2 },
  { code: 'Africa/Lubumbashi', name: 'Africa / Lubumbashi', group: group2 },
  { code: 'Africa/Lusaka', name: 'Africa / Lusaka', group: group2 },
  { code: 'Africa/Malabo', name: 'Africa / Malabo', group: group2 },
  { code: 'Africa/Maputo', name: 'Africa / Maputo', group: group2 },
  { code: 'Africa/Maseru', name: 'Africa / Maseru', group: group2 },
  { code: 'Africa/Mbabane', name: 'Africa / Mbabane', group: group2 },
  { code: 'Africa/Mogadishu', name: 'Africa / Mogadishu', group: group2 },
  { code: 'Africa/Monrovia', name: 'Africa / Monrovia', group: group2 },
  { code: 'Africa/Nairobi', name: 'Africa / Nairobi', group: group2 },
  { code: 'Africa/Ndjamena', name: 'Africa / Ndjamena', group: group2 },
  { code: 'Africa/Niamey', name: 'Africa / Niamey', group: group2 },
  { code: 'Africa/Nouakchott', name: 'Africa / Nouakchott', group: group2 },
  { code: 'Africa/Ouagadougou', name: 'Africa / Ouagadougou', group: group2 },
  { code: 'Africa/Porto - Novo', name: 'Africa / Porto - Novo', group: group2 },
  { code: 'Africa/Sao_Tome', name: 'Africa / Sao_Tome', group: group2 },
  { code: 'Africa/Timbuktu', name: 'Africa / Timbuktu', group: group2 },
  { code: 'Africa/Tripoli', name: 'Africa / Tripoli', group: group2 },
  { code: 'Africa/Tunis', name: 'Africa / Tunis', group: group2 },
  { code: 'Africa/Windhoek', name: 'Africa / Windhoek', group: group2 },
  { code: 'Australia / ACT', name: 'Australia / ACT', group: group2 },
  { code: 'Australia / Adelaide', name: 'Australia / Adelaide', group: group2 },
  { code: 'Australia / Brisbane', name: 'Australia / Brisbane', group: group2 },
  { code: 'Australia / Broken_Hill', name: 'Australia / Broken_Hill', group: group2 },
  { code: 'Australia / Canberra', name: 'Australia / Canberra', group: group2 },
  { code: 'Australia / Currie', name: 'Australia / Currie', group: group2 },
  { code: 'Australia / Darwin', name: 'Australia / Darwin', group: group2 },
  { code: 'Australia / Eucla', name: 'Australia / Eucla', group: group2 },
  { code: 'Australia / Hobart', name: 'Australia / Hobart', group: group2 },
  { code: 'Australia / LHI', name: 'Australia / LHI', group: group2 },
  { code: 'Australia / Lindeman', name: 'Australia / Lindeman', group: group2 },
  { code: 'Australia / Lord_Howe', name: 'Australia / Lord_Howe', group: group2 },
  { code: 'Australia / Melbourne', name: 'Australia / Melbourne', group: group2 },
  { code: 'Australia / North', name: 'Australia / North', group: group2 },
  { code: 'Australia / NSW', name: 'Australia / NSW', group: group2 },
  { code: 'Australia / Perth', name: 'Australia / Perth', group: group2 },
  { code: 'Australia / Queensland', name: 'Australia / Queensland', group: group2 },
  { code: 'Australia / South', name: 'Australia / South', group: group2 },
  { code: 'Australia / Sydney', name: 'Australia / Sydney', group: group2 },
  { code: 'Australia / Tasmania', name: 'Australia / Tasmania', group: group2 },
  { code: 'Australia / Victoria', name: 'Australia / Victoria', group: group2 },
  { code: 'Australia / West', name: 'Australia / West', group: group2 },
  { code: 'Australia / Yancowinna', name: 'Australia / Yancowinna', group: group2 },
  { code: 'Indian/Antananarivo', name: 'Indian / Antananarivo', group: group2 },
  { code: 'Indian/Chagos', name: 'Indian / Chagos', group: group2 },
  { code: 'Indian/Christmas', name: 'Indian / Christmas', group: group2 },
  { code: 'Indian/Cocos', name: 'Indian / Cocos', group: group2 },
  { code: 'Indian/Comoro', name: 'Indian / Comoro', group: group2 },
  { code: 'Indian/Kerguelen', name: 'Indian / Kerguelen', group: group2 },
  { code: 'Indian/Mahe', name: 'Indian / Mahe', group: group2 },
  { code: 'Indian/Maldives', name: 'Indian / Maldives', group: group2 },
  { code: 'Indian/Mauritius', name: 'Indian / Mauritius', group: group2 },
  { code: 'Indian/Mayotte', name: 'Indian / Mayotte', group: group2 },
  { code: 'Indian/Reunion', name: 'Indian / Reunion', group: group2 },
  { code: 'Atlantic/Azores', name: 'Atlantic / Azores', group: group2 },
  { code: 'Atlantic/Bermuda', name: 'Atlantic / Bermuda', group: group2 },
  { code: 'Atlantic/Canary', name: 'Atlantic / Canary', group: group2 },
  { code: 'Atlantic/Cape_Verde', name: 'Atlantic / Cape_Verde', group: group2 },
  { code: 'Atlantic/Faeroe', name: 'Atlantic / Faeroe', group: group2 },
  { code: 'Atlantic/Faroe', name: 'Atlantic / Faroe', group: group2 },
  { code: 'Atlantic/Jan_Mayen', name: 'Atlantic / Jan_Mayen', group: group2 },
  { code: 'Atlantic/Madeira', name: 'Atlantic / Madeira', group: group2 },
  { code: 'Atlantic/Reykjavik', name: 'Atlantic / Reykjavik', group: group2 },
  { code: 'Atlantic/South_Georgia', name: 'Atlantic / South_Georgia', group: group2 },
  { code: 'Atlantic/Stanley', name: 'Atlantic / Stanley', group: group2 },
  { code: 'Atlantic/St_Helena', name: 'Atlantic / St_Helena', group: group2 },
  { code: 'Pacific/Apia', name: 'Pacific / Apia', group: group2 },
  { code: 'Pacific/Auckland', name: 'Pacific / Auckland', group: group2 },
  { code: 'Pacific/Chatham', name: 'Pacific / Chatham', group: group2 },
  { code: 'Pacific/Easter', name: 'Pacific / Easter', group: group2 },
  { code: 'Pacific/Efate', name: 'Pacific / Efate', group: group2 },
  { code: 'Pacific/Enderbury', name: 'Pacific / Enderbury', group: group2 },
  { code: 'Pacific/Fakaofo', name: 'Pacific / Fakaofo', group: group2 },
  { code: 'Pacific/Fiji', name: 'Pacific / Fiji', group: group2 },
  { code: 'Pacific/Funafuti', name: 'Pacific / Funafuti', group: group2 },
  { code: 'Pacific/Galapagos', name: 'Pacific / Galapagos', group: group2 },
  { code: 'Pacific/Gambier', name: 'Pacific / Gambier', group: group2 },
  { code: 'Pacific/Guadalcanal', name: 'Pacific / Guadalcanal', group: group2 },
  { code: 'Pacific/Guam', name: 'Pacific / Guam', group: group2 },
  { code: 'Pacific/Honolulu', name: 'Pacific / Honolulu', group: group2 },
  { code: 'Pacific/Johnston', name: 'Pacific / Johnston', group: group2 },
  { code: 'Pacific/Kiritimati', name: 'Pacific / Kiritimati', group: group2 },
  { code: 'Pacific/Kosrae', name: 'Pacific / Kosrae', group: group2 },
  { code: 'Pacific/Kwajalein', name: 'Pacific / Kwajalein', group: group2 },
  { code: 'Pacific/Majuro', name: 'Pacific / Majuro', group: group2 },
  { code: 'Pacific/Marquesas', name: 'Pacific / Marquesas', group: group2 },
  { code: 'Pacific/Midway', name: 'Pacific / Midway', group: group2 },
  { code: 'Pacific/Nauru', name: 'Pacific / Nauru', group: group2 },
  { code: 'Pacific/Niue', name: 'Pacific / Niue', group: group2 },
  { code: 'Pacific/Norfolk', name: 'Pacific / Norfolk', group: group2 },
  { code: 'Pacific/Noumea', name: 'Pacific / Noumea', group: group2 },
  { code: 'Pacific/Pago_Pago', name: 'Pacific / Pago_Pago', group: group2 },
  { code: 'Pacific/Palau', name: 'Pacific / Palau', group: group2 },
  { code: 'Pacific/Pitcairn', name: 'Pacific / Pitcairn', group: group2 },
  { code: 'Pacific/Ponape', name: 'Pacific / Ponape', group: group2 },
  { code: 'Pacific/Port_Moresby', name: 'Pacific / Port_Moresby', group: group2 },
  { code: 'Pacific/Rarotonga', name: 'Pacific / Rarotonga', group: group2 },
  { code: 'Pacific/Saipan', name: 'Pacific / Saipan', group: group2 },
  { code: 'Pacific/Samoa', name: 'Pacific / Samoa', group: group2 },
  { code: 'Pacific/Tahiti', name: 'Pacific / Tahiti', group: group2 },
  { code: 'Pacific/Tarawa', name: 'Pacific / Tarawa', group: group2 },
  { code: 'Pacific/Tongatapu', name: 'Pacific / Tongatapu', group: group2 },
  { code: 'Pacific/Truk', name: 'Pacific / Truk', group: group2 },
  { code: 'Pacific/Wake', name: 'Pacific / Wake', group: group2 },
  { code: 'Pacific/Wallis', name: 'Pacific / Wallis', group: group2 },
  { code: 'Pacific/Yap', name: 'Pacific / Yap', group: group2 },
  { code: 'Antarctica/Casey', name: 'Antarctica / Casey', group: group2 },
  { code: 'Antarctica/Davis', name: 'Antarctica / Davis', group: group2 },
  { code: 'Antarctica/DumontDUrville', name: 'Antarctica / DumontDUrville', group: group2 },
  { code: 'Antarctica/Macquarie', name: 'Antarctica / Macquarie', group: group2 },
  { code: 'Antarctica/Mawson', name: 'Antarctica / Mawson', group: group2 },
  { code: 'Antarctica/McMurdo', name: 'Antarctica / McMurdo', group: group2 },
  { code: 'Antarctica/Palmer', name: 'Antarctica / Palmer', group: group2 },
  { code: 'Antarctica/Rothera', name: 'Antarctica / Rothera', group: group2 },
  { code: 'Antarctica/South_Pole', name: 'Antarctica / South_Pole', group: group2 },
  { code: 'Antarctica/Syowa', name: 'Antarctica / Syowa', group: group2 },
  { code: 'Antarctica/Vostok', name: 'Antarctica / Vostok', group: group2 },
  { code: 'Arctic/Longyearbyen', name: 'Arctic / Longyearbyen', group: group2 },
]

export const getTimezoneObject = (timezoneCode: string | undefined): TimezoneObject | undefined => {
  return findReferenceObject(TIMEZONES, timezoneCode, 'code')
}
