/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PairingType {
    PREFILLED = 'PREFILLED',
    MANUAL = 'MANUAL'
}


export function PairingTypeFromJSON(json: any): PairingType {
    return PairingTypeFromJSONTyped(json, false);
}

export function PairingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PairingType {
    return json as PairingType;
}

export function PairingTypeToJSON(value?: PairingType | null): any {
    return value as any;
}

