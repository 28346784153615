import React, { PropsWithChildren, ReactElement, useContext, useState } from 'react'
import { IconButton, Snackbar, styled } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import IosShareIcon from '@mui/icons-material/IosShare'
import { differenceInDays } from 'date-fns'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  getAddToHomeTooltipLastSeen,
  storeAddToHomeTooltipLastSeen,
} from 'src/service/local-storage/LocalStorageService'
import { VersionCheckContext } from 'src/ui/layout/version-check/VersionCheckProvider'

const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
  left: '50%',
  right: 'auto',
  transform: 'translateX(-50%)',
  ['&	.MuiSnackbarContent-root']: {
    border: '1px solid #ddd',
    boxShadow: 'none',
    backgroundColor: '#fff',
    color: '#333',
    padding: 0,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
  },
  ['& .MuiSnackbarContent-message']: {
    position: 'realtive',
    fontSize: theme.typography.pxToRem(15),
    minWidth: '280px',
    maxWidth: '280px',
    paddingRight: '25px',
  },
  // triangle pointing down
  ['&	.MuiSnackbarContent-root:before']: {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: 0,
    zIndex: 1,
    borderStyle: 'solid',
    borderColor: '#ddd transparent',
    borderWidth: '9px 14px 0',
    bottom: '-9px',
    left: '50%',
    marginLeft: '-14px',
  },
  ['&	.MuiSnackbarContent-root:after']: {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: 0,
    zIndex: 1,
    borderStyle: 'solid',
    borderColor: '#fff transparent',
    borderWidth: '8px 12px 0',
    bottom: '-7px',
    left: '50%',
    marginLeft: '-12px',
  },
}))

function isIos() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const AddToHomeTooltip = ({ children }: PropsWithChildren): ReactElement => {
  const ios = isIos()

  const { newVersion } = useContext(VersionCheckContext)
  const translate = useTranslate()

  // show only if there is not new version of the app to prevent overlap of the snackbars
  let shouldShow = ios && !newVersion

  const [open, setOpen] = useState<boolean>(true)

  const now = new Date()

  if (shouldShow) {
    // check if the tooltip was recenly seen
    const addToHomeTooltipLastSeen = getAddToHomeTooltipLastSeen()
    if (addToHomeTooltipLastSeen) {
      const daysPassed = differenceInDays(now, addToHomeTooltipLastSeen)
      if (daysPassed < 30) {
        // do not show tooltip, it was recently shown
        shouldShow = false
      }
    }
  }

  // store when did we last showed the tooltip
  if (shouldShow) {
    storeAddToHomeTooltipLastSeen(now)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {children}
      {shouldShow ? (
        <CustomSnackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          onClose={handleClose}
          message={
            <React.Fragment>
              <>
                <IconButton onClick={handleClose} sx={{ color: '#666', position: 'absolute', right: '0', top: '0' }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
                {translate('touch')} <IosShareIcon />, {translate('then')} <AddCircleOutlineIcon />
                {' ' + translate('toAdd') + ' '} <b>Smart&nbsp;Schulthess</b>
                {' ' + translate('toYourHomeScreen')}
              </>
            </React.Fragment>
          }
          key={'addToHomeS'}
        ></CustomSnackbar>
      ) : null}
    </>
  )
}
