import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { NavigateOptions, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { Box, Divider, Paper } from '@mui/material'
import { State } from 'history'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  LaundryGroupPriceList,
  LaundryGroupPriceListsApi,
  MachineType,
  Permission,
  PriceListType,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { getEnumFromString } from 'src/service/utils/CommonUtils'
import {
  LaundryGroupPriceListExternalViewModel,
  LaundryGroupPriceListInternalViewModel,
  mapLaundryGroupPriceListExternalViewModelToLaundryGroupPriceList,
  mapLaundryGroupPriceListInternalViewModelToLaundryGroupPriceList,
} from 'src/service/view-model/laundry-group/LaundryGroupPriceListViewModel'
import { ListingButton } from 'src/ui-shared/base/button/ListingButton'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useHotKeysForm } from 'src/ui-shared/base/hooks/useHotKeysForm'
import { usePrompt } from 'src/ui-shared/base/hooks/usePrompt'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { LoadingIndicator } from 'src/ui-shared/base/loading-indicator/LoadingIndicator'
import { useShowSnackbar } from 'src/ui-shared/base/snackbar/SnackbarProvider'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'
import { LaundryGroupPriceListExternalEdit } from 'src/ui/page/common/laundry-group/price-list/LaundryGroupPriceListExternalEdit'
import { LaundryGroupPriceListInternalEdit } from 'src/ui/page/common/laundry-group/price-list/LaundryGroupPriceListInternalEdit'
import { hasPermission } from 'src/user/RoleCheck'
import { useUser } from 'src/user/UserContext'

export const LaundryGroupPriceListEditPage = (): ReactElement => {
  const { laundryGroupId, machineTypeParam } = useRequiredParams(['laundryGroupId', 'machineTypeParam'])
  const [searchParams] = useSearchParams()
  const drumSizeParam = searchParams.get('drumSize')
  const drumSize: number | undefined = drumSizeParam ? Number(drumSizeParam) : undefined
  const machineType = getEnumFromString(machineTypeParam, MachineType)

  const navigate = useNavigate()
  const user = useUser()
  const translate = useTranslate()
  const appId = useAppId()
  const showSnackbar = useShowSnackbar()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupPriceListsApi = new LaundryGroupPriceListsApi(httpConfiguration)

  const [laundryGroupPriceList, setLaundryGroupPriceList] = useState<LaundryGroupPriceList | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const [dirty, setDirty] = useState<boolean>(false)

  useHotKeysForm(navigateBack)

  usePrompt(translate('notification.form.unsaved.changes'), dirty)

  const onSubmitSuccess = () => {
    setLoading(false)
    navigateBack(true)
  }

  const onSubmitReject = (errorMessage: string) => {
    setDirty(true)
    setLoading(false)
    showSnackbar(errorMessage, 'error')
  }

  function navigateBack(showSnackbar?: boolean) {
    const state: State = { showSnackbar: showSnackbar }

    const navigateOptions: NavigateOptions = {
      state: state,
    }
    navigate(
      `/${appId}/laundry-groups/${laundryGroupId}/view/prices/${machineType}` +
        (drumSize ? '?drumSize=' + drumSize.toString() : ''),
      navigateOptions,
    )
  }

  const updateLaundryGroupPriceList = (laundryGroupPriceList: LaundryGroupPriceList) => {
    return laundryGroupPriceListsApi.laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypePut(
      laundryGroupId,
      machineType,
      drumSize,
      laundryGroupPriceList,
    )
  }

  const externalPricelistSubmit = (viewModel: LaundryGroupPriceListExternalViewModel) => {
    setDirty(false)
    setLoading(true)
    const laundryGroupPriceList = mapLaundryGroupPriceListExternalViewModelToLaundryGroupPriceList(viewModel)
    return updateLaundryGroupPriceList(laundryGroupPriceList)
      .then((_data) => {
        onSubmitSuccess()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  const internalPriecelistSubmit = (viewModel: LaundryGroupPriceListInternalViewModel) => {
    setDirty(false)
    setLoading(true)
    const laundryGroupPriceList = mapLaundryGroupPriceListInternalViewModelToLaundryGroupPriceList(viewModel)
    return updateLaundryGroupPriceList(laundryGroupPriceList)
      .then((_data) => {
        onSubmitSuccess()
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        onSubmitReject(errorMessage)
      })
  }

  const saveButtonAction = (
    <ListingButton
      id="submitButton"
      type="submit"
      form={
        laundryGroupPriceList?.priceListType === PriceListType.EXTERNAL
          ? 'laundryGroupPriceListExternalForm'
          : 'laundryGroupPriceListInternalForm'
      }
      variant="contained"
      color="primary"
    >
      {translate('button.save')}
    </ListingButton>
  )

  const actions = hasPermission(user, Permission.PRICE_LIST_WRITE) ? saveButtonAction : <></>

  useEffect(() => {
    laundryGroupPriceListsApi
      .laundrygroupsLaundryGroupIdPricelistsMachineTypeMachineTypeGet(laundryGroupId, machineType, drumSize)
      .then((data) => {
        setLaundryGroupPriceList(data)
        setLoading(false)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setLoading(false)
      })
  }, [])

  const pageTitle =
    laundryGroupPriceList?.priceListType === PriceListType.INTEGRATED
      ? laundryGroupPriceList.integrated?.name
      : laundryGroupPriceList?.external?.name || ''

  return (
    <ScreenLayout title={pageTitle ? pageTitle : ''} onBack={() => navigateBack()} actions={actions}>
      <Paper elevation={0}>
        <LoadingIndicator loading={loading} />
        <Divider />

        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
        <Box pt={2}>
          {laundryGroupPriceList ? (
            laundryGroupPriceList.priceListType === PriceListType.INTEGRATED ? (
              <LaundryGroupPriceListInternalEdit
                setDirty={setDirty}
                submitForm={internalPriecelistSubmit}
                priceList={laundryGroupPriceList.integrated}
              />
            ) : (
              <LaundryGroupPriceListExternalEdit
                setDirty={setDirty}
                submitForm={externalPricelistSubmit}
                priceList={laundryGroupPriceList.external}
              />
            )
          ) : null}
        </Box>
      </Paper>
    </ScreenLayout>
  )
}
