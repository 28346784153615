/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { ProgramInstalled } from './ProgramInstalled';
import {
    ProgramInstalledFromJSON,
    ProgramInstalledFromJSONTyped,
    ProgramInstalledToJSON,
} from './ProgramInstalled';

/**
 * 
 * @export
 * @interface PageableProgramInstallations
 */
export interface PageableProgramInstallations {
    /**
     * 
     * @type {Array<ProgramInstalled>}
     * @memberof PageableProgramInstallations
     */
    result: Array<ProgramInstalled>;
    /**
     * 
     * @type {Page}
     * @memberof PageableProgramInstallations
     */
    page: Page;
}

/**
 * Check if a given object implements the PageableProgramInstallations interface.
 */
export function instanceOfPageableProgramInstallations(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageableProgramInstallationsFromJSON(json: any): PageableProgramInstallations {
    return PageableProgramInstallationsFromJSONTyped(json, false);
}

export function PageableProgramInstallationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableProgramInstallations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(ProgramInstalledFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageableProgramInstallationsToJSON(value?: PageableProgramInstallations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(ProgramInstalledToJSON)),
        'page': PageToJSON(value.page),
    };
}

