import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Box, Divider, Grid, Paper } from '@mui/material'
import { AppId } from 'src/app/AppId'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import { NavigateState } from 'src/routing/Routing'
import { Configuration, LaundryGroupSubscription, SubscriptionsLaundryGroupsApi } from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useRequiredParams } from 'src/ui-shared/base/hooks/useRequiredParams'
import { useSharedStyles } from 'src/ui-shared/constants/Shared.style'
import { ServicePackagePlanAdditionalOptionTable } from 'src/ui/layout-page/service-package-plan/details/ServicePackagePlanAdditionalOptionTable'
import { ServicePackagePlanCard } from 'src/ui/layout-page/service-package-plan/details/ServicePackagePlanCard'
import { ScreenLayout } from 'src/ui/layout/main-layout/ScreenLayout'

export const ServicePackagePlanDetailsPage = (): ReactElement => {
  const { classes: sharedClasses } = useSharedStyles()
  const translate = useTranslate()
  const navigate = useNavigate()
  const location = useLocation()
  const { laundryGroupId } = useRequiredParams(['laundryGroupId'])

  const state = location.state as NavigateState | undefined
  const browserHistoryBack = state?.browserHistoryBack

  const httpConfiguration: Configuration = useContext(HttpContext)
  const subscriptionLaundryGroupsApi = new SubscriptionsLaundryGroupsApi(httpConfiguration)

  // state
  const [laundryGroupSubscription, setLaundryGroupSubscription] = useState<LaundryGroupSubscription | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // load data
  useEffect(() => {
    subscriptionLaundryGroupsApi
      .subscriptionsLaundrygroupsLaundryGroupIdGet(laundryGroupId)
      .then((data) => {
        setLaundryGroupSubscription(data)
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
      })
  }, [laundryGroupId])

  const navigateBack = () => {
    browserHistoryBack ? navigate(-1) : navigate(`/${AppId.WASH_MASTER}/service-package-plan`)
  }

  return (
    <ScreenLayout title={translate('servicePackagePlans')} onBack={navigateBack} actionsWidth={30}>
      <Paper elevation={0}>
        <Divider />
        {errorMessage && <ErrorMessage message={errorMessage} />}

        {laundryGroupSubscription && (
          <Box pt={2}>
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <ServicePackagePlanCard laundryGroupSubscription={laundryGroupSubscription} mode={'view'} />
            </Grid>
            <Grid container spacing={2} className={sharedClasses.GridWithTextField}>
              <ServicePackagePlanAdditionalOptionTable laundryGroupSubscription={laundryGroupSubscription} />
            </Grid>
          </Box>
        )}
      </Paper>
    </ScreenLayout>
  )
}
