/* tslint:disable */
/* eslint-disable */
/**
 * Web App core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LaundryGroupSettings
 */
export interface LaundryGroupSettings {
    /**
     * 
     * @type {number}
     * @memberof LaundryGroupSettings
     */
    revenueChangeLimit: number;
    /**
     * 
     * @type {number}
     * @memberof LaundryGroupSettings
     */
    badTrendUtilizationLimit: number;
    /**
     * 
     * @type {number}
     * @memberof LaundryGroupSettings
     */
    dailyGoalUtilizationLimit: number;
}

/**
 * Check if a given object implements the LaundryGroupSettings interface.
 */
export function instanceOfLaundryGroupSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "revenueChangeLimit" in value;
    isInstance = isInstance && "badTrendUtilizationLimit" in value;
    isInstance = isInstance && "dailyGoalUtilizationLimit" in value;

    return isInstance;
}

export function LaundryGroupSettingsFromJSON(json: any): LaundryGroupSettings {
    return LaundryGroupSettingsFromJSONTyped(json, false);
}

export function LaundryGroupSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaundryGroupSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'revenueChangeLimit': json['revenueChangeLimit'],
        'badTrendUtilizationLimit': json['badTrendUtilizationLimit'],
        'dailyGoalUtilizationLimit': json['dailyGoalUtilizationLimit'],
    };
}

export function LaundryGroupSettingsToJSON(value?: LaundryGroupSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'revenueChangeLimit': value.revenueChangeLimit,
        'badTrendUtilizationLimit': value.badTrendUtilizationLimit,
        'dailyGoalUtilizationLimit': value.dailyGoalUtilizationLimit,
    };
}

