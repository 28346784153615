import { AppId } from 'src/app/AppId'
import { Permission, Region, RegionData } from 'src/service/backend/api'
import { BackendUserProfile } from 'src/service/backend/api/models/BackendUserProfile'
import { TEMP_UNIT_CELSIUS } from 'src/user/TemperatureUnits'
import { WEIGHT_UNIT_KG } from 'src/user/WeightUnits'

export interface User extends BackendUserProfile {
  roles: Array<string>
  permissions: Array<Permission>
  availableApps: Array<AppId>
  defaultApp?: AppId
  username: string
  token: string
}

export const DEFAULT_USER_LOCALE = 'en'

export const DEFAULT_USER_REGION_DATA: RegionData = {
  region: Region.EU,
  temperature: TEMP_UNIT_CELSIUS.id,
  weight: WEIGHT_UNIT_KG.id,
}

export const DEFAULT_USER_ROLES: string[] = []

export const DEFAULT_USER_PERMISSIONS: Permission[] = []

export const DEFAULT_AVAILABLE_APPS: AppId[] = []

export const DEFAULT_APP: AppId | undefined = undefined

export const DEFAULT_USER: User = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  locale: DEFAULT_USER_LOCALE,
  roles: DEFAULT_USER_ROLES,
  permissions: DEFAULT_USER_PERMISSIONS,
  availableApps: DEFAULT_AVAILABLE_APPS,
  defaultApp: DEFAULT_APP,
  regionData: DEFAULT_USER_REGION_DATA,
  username: '',
  token: 'no-user-logged-in',
  blocked: false,
  globalRoles: [],
  memberRolesAll: [],
}
