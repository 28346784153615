import React, { ReactElement, useMemo } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { Box, FormHelperText } from '@mui/material'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  MultipleChoiceAutoComplete,
  MultipleChoiceAutoCompleteProps,
} from 'src/ui-shared/base/form/control/MultipleChoiceAutoComplete'

interface MultipleChoiceAutoCompleteField extends MultipleChoiceAutoCompleteProps {
  validate?: any
}

type ExposeMultipleChoiceAutoCompleteProps = Omit<MultipleChoiceAutoCompleteField, 'onChange' | 'error' | 'value'>

type AutoCompleteFieldValueType = any[]

const CustomMultipleChoiceAutoComplete = (props: FieldRenderProps<AutoCompleteFieldValueType>): ReactElement => {
  const {
    input: { value, onChange: onChangeFinalForm },
    meta,
    disabled,
    ...rest
  } = props

  const { children, render, ...autoCompleteProps } = rest

  const { touched, error: errorObject } = meta

  const translate = useTranslate()

  const error = errorObject && translate(errorObject.errorKey, errorObject.params)

  const invalid = Boolean(touched && error)

  const onChangeMemo = useMemo<(optionValue: any) => void>(
    () =>
      (optionValue: any): void => {
        onChangeFinalForm(optionValue)
      },
    [onChangeFinalForm],
  )

  const finalAutoCompleteProps = {
    disabled: autoCompleteProps.disabled,
    label: autoCompleteProps.label,
    name: autoCompleteProps.name,
    onChange: onChangeMemo,
    options: autoCompleteProps.options,
    value,
    error: invalid,
    labelFieldName: autoCompleteProps.labelFieldName,
  } as MultipleChoiceAutoCompleteProps

  return (
    <Box>
      <MultipleChoiceAutoComplete {...finalAutoCompleteProps} />
      {invalid && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}

export const MultipleChoiceAutoCompleteValidate = (props: ExposeMultipleChoiceAutoCompleteProps): ReactElement => (
  <Field component={CustomMultipleChoiceAutoComplete} {...props} />
)
