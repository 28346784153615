import React, { ReactElement } from 'react'
import { NavLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

interface ListItemLinkProps {
  primary?: string
  to: string
  icon?: React.ReactElement
  className?: string
  end?: boolean
  closeDrawer?: () => void
}
export const ListItemLink = ({
  icon,
  primary,
  to,
  className,
  closeDrawer,
  ...rest
}: ListItemLinkProps): ReactElement => {
  const LinkBehavior = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(function LinkBehavior(itemProps, ref) {
        return (
          <NavLink
            to={to}
            ref={ref}
            {...itemProps}
            className={({ isActive }) => (isActive ? itemProps.className + ' Mui-selected' : itemProps.className)}
            end={to === ''}
          />
        )
      }),
    [to],
  )

  return (
    <ListItemButton onClick={closeDrawer} className={className} component={LinkBehavior} {...rest}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      {primary && <ListItemText primary={primary} />}
    </ListItemButton>
  )
}
