import { TranslateFunction } from 'src/i18n/useMessageSource'

/**
 * Find object in array of objects.
 *
 * @param items a list of objects
 * @param itemIdValue the value that we are searching in the items array
 * @param itemKey the property name which we are searhing
 * @returns {Object} found translation pair.
 */
export const findReferenceObject = <T, K extends keyof T>(
  items: T[],
  itemIdValue?: T[K],
  itemKey: K = 'id' as K,
): T | undefined => {
  if (!itemIdValue) {
    return undefined
  }
  return items.find((item) => item[itemKey] === itemIdValue)
}

/**
 * Get translation from found translation pair.
 *
 * @param items a list of objects which represent a translation pair.
 * @param translate translation function.
 * @param searchedItemKey value which we want to translate.
 * @param itemKey object key in the translation pair for translation key.
 * @param itemName object key in the translation pair for translation value.
 * @returns {string} translated value.
 */
export const getReferenceName = <T, K extends keyof T>(
  items: T[],
  translate?: TranslateFunction,
  searchedItemKey?: T[K],
  itemKey: K = 'id' as K,
  itemName: K = 'name' as K,
): string => {
  if (!searchedItemKey) {
    return ''
  }

  const foundItem = findReferenceObject(items, searchedItemKey, itemKey)

  const labelKey = foundItem ? (foundItem[itemName] as unknown as string) : (searchedItemKey as unknown as string)

  // NOTE: DAV 2023-03-28 if translate is not passed then don't translate
  return translate ? translate(labelKey) : labelKey
}

/**
 * Tries to call the setSelectedItem method with item from the items array that matches the itemId.
 * @param itemId the id of the item to find in the items array
 * @param setSelectedItem method to call when the item is found
 * @param currentSelectedItem the current selected item, the setSelectedItem method will not be called if this parameter is the same as the one found
 * @param items the array of items
 * @param itemKey optional key by which to compare the items
 */
export const findItemAndSelect = <T, K extends keyof T>(
  itemId: T[K] | undefined,
  setSelectedItem: (newSelectedItem: T | null) => void,
  currentSelectedItem: T | null,
  items: T[],
  itemKey: K = 'id' as K,
): void => {
  if (!itemId) {
    if (currentSelectedItem) {
      setSelectedItem(null)
    }
  } else if (items.length > 0) {
    const foundItem = findReferenceObject(items, itemId)
    if (foundItem) {
      if (!currentSelectedItem || currentSelectedItem[itemKey] !== foundItem[itemKey]) {
        setSelectedItem(foundItem)
      }
    }
  }
}
