import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useAppId } from 'src/app/AppProvider'
import { errorMapper } from 'src/i18n/ErrorMapper'
import { useTranslate } from 'src/i18n/useMessageSource'
import {
  Configuration,
  LaundryGroup,
  LaundryGroupPriceListReference,
  LaundryGroupPriceListsApi,
  MachineType,
} from 'src/service/backend/api'
import { HttpContext } from 'src/service/backend/http/HttpContext'
import { formatDateTimeForLocaleOptional } from 'src/service/utils/DateFormatUtils'
import { getDrumSizeLabel, getMachineTypeName } from 'src/service/view-model/machine/Machines'
import { ErrorMessage } from 'src/ui-shared/base/error-message/ErrorMessage'
import { useTableStyles } from 'src/ui-shared/table/Table.style'
import { TableEmpty } from 'src/ui-shared/table/TableEmpty'
import { useUserRegionLocale } from 'src/user/UserContext'

interface HeadCells {
  id: keyof LaundryGroupPriceListReference
  label: string
}

const headCells: HeadCells[] = [
  {
    id: 'name',
    label: 'member.pricelist',
  },
  {
    id: 'machineType',
    label: 'member.machinetype',
  },
  {
    id: 'drumSize',
    label: 'member.machinesize',
  },
  {
    id: 'currency',
    label: 'currency',
  },
  {
    id: 'lastModified',
    label: 'member.lastmodified',
  },
]

interface Props {
  laundryGroup: LaundryGroup
}

export const LaundryGroupPriceTab: FC<Props> = ({ laundryGroup }): ReactElement => {
  const { classes: tableClasses } = useTableStyles()
  const translate = useTranslate()
  const navigate = useNavigate()
  const appId = useAppId()
  const regionLocale = useUserRegionLocale()

  const httpConfiguration: Configuration = useContext(HttpContext)
  const laundryGroupPriceListsApi = new LaundryGroupPriceListsApi(httpConfiguration)

  const [data, setData] = useState<LaundryGroupPriceListReference[] | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // load data
  useEffect(() => {
    let active = true

    laundryGroupPriceListsApi
      .laundrygroupsLaundryGroupIdPricelistsGet(laundryGroup.id ? laundryGroup.id : '')
      .then((data) => {
        if (active) {
          setData(data.result as LaundryGroupPriceListReference[])
          setErrorMessage(null)
        }
      })
      .catch((err) => {
        const errorMessage = errorMapper(err, translate)
        console.error(errorMessage, err)
        setErrorMessage(errorMessage)
        setData([])
      })

    return () => {
      active = false
    }
  }, [laundryGroup])

  // handle events
  const handleRowClick = (machineType: MachineType, drumSize: number | null | undefined) => {
    navigate(
      `/${appId}/laundry-groups/${laundryGroup.id}/view/prices/${machineType}` +
        (drumSize ? '?drumSize=' + drumSize.toString() : ''),
    )
  }

  // JSX
  const displayRows =
    data && data.length > 0 ? (
      data.map((item, index) => {
        return (
          <TableRow
            className={tableClasses.tableRow}
            key={index}
            onClick={handleRowClick.bind(this, item.machineType, item.drumSize)}
          >
            <TableCell>{item.name}</TableCell>
            <TableCell>{getMachineTypeName(item.machineType, translate)}</TableCell>
            <TableCell>{getDrumSizeLabel(item.drumSize, item.drumSizeUnit)}</TableCell>
            <TableCell>{item.currency}</TableCell>
            <TableCell>{formatDateTimeForLocaleOptional(item.lastModified, regionLocale)}</TableCell>
          </TableRow>
        )
      })
    ) : (
      <TableEmpty colspan={headCells.length} />
    )

  const displayHeaders = headCells.map((item) => {
    return <TableCell key={item.id}>{translate(item.label)}</TableCell>
  })

  return (
    <Box pt={2}>
      <Paper elevation={0}>
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>{displayHeaders}</TableRow>
            </TableHead>
            <TableBody>{data && displayRows}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}
